import { addLoading, removeLoading } from './loading';
import cutsMonitoRequests from '../../server/cutsMonito';

export const setCutsMonitoList = (cutsMonitoList) => ({
  type: 'SET_CUTS_MONITO_LIST',
  cutsMonitoList,
});

export const setChangeCutProductList = (productList) => ({
  type: 'SET_PRODUCT_LIST',
  productList,
});

export const cutsMonitoList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const cutsMonitoList = await cutsMonitoRequests.cutsMonitoList(tableParams);
    dispatch(setCutsMonitoList(cutsMonitoList ? cutsMonitoList.rows : []));
    dispatch(removeLoading());
    return cutsMonitoList || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const getCableCutProductList = (cableCutPlanId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const productList = await cutsMonitoRequests.getCableCutProductList(cableCutPlanId);
    dispatch(setChangeCutProductList(productList.length ? productList : []));
  } finally {
    dispatch(removeLoading());
  }
};

export const printCableCutLabel = (printerCode, type, code) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await cutsMonitoRequests.printCableCutLabel(printerCode, type, code);
  } finally {
    dispatch(removeLoading());
  }
};

export const finishCableCut = (cableCutPlanId, cableCutProductId, baptismLabel, printerCode = '') => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await cutsMonitoRequests.finishCableCut(cableCutPlanId, cableCutProductId, baptismLabel, printerCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteCableCut = (cableCutPlanId, cableCutProductId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await cutsMonitoRequests.deleteCableCut(cableCutPlanId, cableCutProductId);
  } finally {
    dispatch(removeLoading());
  }
};

export const userChangeCutResponsible = (cableCutPlanId, responsibleCutUserId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await cutsMonitoRequests.userChangeCutResponsible(cableCutPlanId, responsibleCutUserId);
  } finally {
    dispatch(removeLoading());
  }
};
