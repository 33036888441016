import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { authenticate } from '../../app/store/actions/app';
import addNotification from '../../components/notification';
import ChangePassword from '../../components/password/toChange';
import { PageSettings } from '../../config/page-settings';
import ROUTES from '../../config/routes';
import serverData from '../../data/server';

class Login extends React.PureComponent {
  static contextType = PageSettings;

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      hidePassword: true,
      showChangePassword: false,
      beehost: '',
    };
  }

  componentDidMount() {
    this.context.handleSetPageSidebar(false);
    this.context.handleSetPageHeader(false);
    this.context.handleSetBodyWhiteBg(true);
    this.renderPosts();
  }

  componentWillUnmount() {
    this.context.handleSetPageSidebar(true);
    this.context.handleSetPageHeader(true);
    this.context.handleSetBodyWhiteBg(false);
  }

  onChangeUsername = (e) => this.setState({ username: e.target.value });

  onChangePassword = (e) => this.setState({ password: e.target.value });

  onSubmit = async (e) => {
    e.preventDefault();

    if (this.state.username && this.state.password) {
      try {
        await this.props.authenticate(this.state.username, this.state.password);
        this.props.history.push(ROUTES.HOME);
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;
        if (error.code === 915) {
          this.setState({ showChangePassword: true });
        } else if (error && error.message) {
          const messageError = (error.code)
            ? `${error.code} - ${error.details || error.message}`
            : error.details || error.message;
          addNotification(
            'danger',
            I18n.t('BEE623' /* Login */),
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE623' /* Login */),
            I18n.t('BEE1077' /* Usuário ou senha incorretos ! */),
            'top-right',
          );
        }
      }
    }
  };

  renderPosts = async () => {
    try {
      this.setState({
        beehost: await serverData.getDisplayName(),
      });
    } catch (err) { /* */ }
  };

  createInputButton = (
    value,
    attr,
    label,
    name,
    autoComplete,
    placeholder,
    type = 'text',
    required,
    disabled,
    id = null,
    buttonLabel = '',
    buttonFunction = undefined,
    keypressFunction = undefined,
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={label} className="f-w-600 f-s-13">{label}</label>
      <div className="input-group">
        <input
          data-testid={`${name}-input`}
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          name={name}
          autoComplete={autoComplete}
          type={type}
          className="form-control form-control-lg"
          value={value || ''}
          onChange={this.onChangePassword}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          {buttonLabel && buttonFunction && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => buttonFunction()}
              style={{ minWidth: '41px' }}
            >
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  render() {
    let server;
    const { hidePassword, beehost } = this.state;

    if (window.location.host === '10.4.0.47:8080') {
      server = 'AMBIENTE DE HOMOLOGAÇÃO';
    } else if (window.location.host === '10.4.0.47:8082') {
      server = 'AMBIENTE DE DESENVOLVIMENTO';
    } else {
      server = '';
    }
    return (
      <div className="login login-with-news-feed">
        <div className="news-feed">
          <div className="news-image" style={{ backgroundImage: 'url(/assets/img/login.jpg)' }} />
        </div>
        <div className="right-content">
          <div>
            <h1 className="text-red bg-black-darker text-center">{server}</h1>
          </div>
          <div className="login-header">
            <div className="brand">
              <b className="logo-font text-primary">Bee</b>
              <b className="logo-font text-dark">Stock</b>
              <img
                src="/assets/img/fav-icon.png"
                className="ml-1 mb-2"
                style={{ objectFit: 'contain', width: '10%' }}
                alt=""
              />
            </div>
            <div className="brand">
              <b style={{ color: '#ff0000' }} className="logo-font">{beehost}</b>
            </div>
            <div className="icon">
              <i className="fa fa-sign-in" />
            </div>
          </div>
          <div className="login-content">
            <form className="margin-bottom-0" onSubmit={this.onSubmit}>
              <div className="form-group m-b-15">
                <label htmlFor="id-user-input" className="f-w-600 f-s-13">{I18n.t('BEE1' /* Usuário */)}</label>
                <input
                  id="id-user-input"
                  type="text"
                  data-testid="username-input"
                  className="form-control form-control-lg"
                  // placeholder={I18n.t('BEE1' /* Usuário */)}
                  required
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                />
              </div>
              <div className="form-group m-b-15">
                {this.createInputButton(
                  this.state.password,
                  'password',
                  I18n.t('BEE2' /* Senha */),
                  'password',
                  'on',
                  '',
                  (hidePassword) ? 'password' : 'text',
                  true,
                  false,
                  'id-password-input',
                  <i data-testid="reveal-password-button" className={`fa fa-eye${(hidePassword) ? '' : '-slash'}`} />,
                  () => this.setState({ hidePassword: !hidePassword }),
                )}
              </div>
              <div className="login-buttons">
                <button id="id-login-button" type="submit" className="btn btn-success btn-block btn-lg">
                  {I18n.t('BEE3600' /* Entrar no BeeStock */)}
                </button>
              </div>
              <hr />
              <p className="text-center text-grey-darker">
                &copy;
                {' '}
                {I18n.t('BEE1078' /* SZ Soluções Todos os direitos reservados 2020 */)}
              </p>
            </form>
          </div>
        </div>
        <ChangePassword
          expiredPassword
          username={this.state.username}
          isOpen={this.state.showChangePassword}
          toClose={() => this.setState({ showChangePassword: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapActionsToProps = (dispatch) => ({
  authenticate: (username, password) => dispatch(authenticate(username, password)),
});

export default connect(mapStateToProps, mapActionsToProps)(Login);
