import RestService from '../services/rest';

// GET
async function getAllResupplyDeposits() {
  return RestService.getAuthenticated('resupplyDeposits/all');
}

// POST
async function createResupplyDeposits(resupplyDeposits) {
  return RestService.postAuthenticated('resupplyDeposits/create', resupplyDeposits);
}

// PUT
async function updateResupplyDeposits(resupplyDeposits) {
  return RestService.putAuthenticated('resupplyDeposits/update', resupplyDeposits);
}

// DELETE
async function deleteResupplyDeposits(resupplyDepositsId) {
  return RestService.deleteAuthenticated('resupplyDeposits/delete', { id: resupplyDepositsId });
}

export default {
  getAllResupplyDeposits,
  createResupplyDeposits,
  updateResupplyDeposits,
  deleteResupplyDeposits,
};
