import CarrierSchedulesRequests from '../../server/carrierSchedules';
import { addLoading, removeLoading } from './loading';

export const getCarrierSchedulesList = (query) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const carrierSchedules = await CarrierSchedulesRequests.getCarrierSchedulesQuery(query);
    return carrierSchedules
  } finally {
    dispatch(removeLoading());
  }
};

export const createCarrierSchedule = carrierSchedule => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newCarrierSchedule = await CarrierSchedulesRequests.createCarrierSchedule(carrierSchedule);
    return newCarrierSchedule;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateCarrierSchedule = (carrierSchedule) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CarrierSchedulesRequests.updateCarrierSchedule(carrierSchedule);
  } finally {
    dispatch(removeLoading());
  }
}

export const getCarrierSchedule = (carrierScheduleId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CarrierSchedulesRequests.getCarrierSchedule(carrierScheduleId);
  } finally {
    dispatch(removeLoading());
  }
}

export const deleteCarrierSchedule = (carrierScheduleId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CarrierSchedulesRequests.deleteCarrierSchedule(carrierScheduleId);
  } finally {
    dispatch(removeLoading());
  }
}

export const getCarrierSchedulesOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CarrierSchedulesRequests.getCarrierSchedulesOptions();
  } finally {
    dispatch(removeLoading());
  }
};