import { I18n } from 'react-redux-i18n';

function productionOrderSituation(type) {
  if (type || type === 0) {
    switch (type) {
      case 1:
        return I18n.t('BEE2772' /* Não Iniciada */);
      case 2:
        return I18n.t('BEE205' /* Liberada */);
      case 3:
        return I18n.t('BEE483' /* Reservada */);
      case 4:
        return I18n.t('BEE2040' /* Separada */);
      case 5:
        return I18n.t('BEE2773' /* Requisitada */);
      case 6:
        return I18n.t('BEE2768' /* Iniciada */);
      case 7:
        return I18n.t('BEE2120' /* Finalizada */);
      case 8:
        return I18n.t('BEE2774' /* Terminada */);
      default:
        return type;
    }
  } else if (!type) {
    return [
      { value: 1, label: I18n.t('BEE2772' /* Não Iniciada */) },
      { value: 2, label: I18n.t('BEE205' /* Liberada */) },
      { value: 3, label: I18n.t('BEE483' /* Reservada */) },
      { value: 4, label: I18n.t('BEE2040' /* Separada */) },
      { value: 5, label: I18n.t('BEE2773' /* Requisitada */) },
      { value: 6, label: I18n.t('BEE2768' /* Iniciada */) },
      { value: 7, label: I18n.t('BEE2120' /* Finalizada */) },
      { value: 8, label: I18n.t('BEE2774' /* Terminada */) },
    ];
  }
}

function productionOrderStatus(type) {
  if (type || type === 0) {
    switch (type) {
      case 1:
        return I18n.t('BEE1946' /* Pendente Alocação */);
      case 2:
        return I18n.t('BEE1367' /*  Em Separação */);
      case 3:
        return I18n.t('BEE1368' /* Separado */);
      case 4:
        return I18n.t('BEE2120' /* Finalizada */);
      default:
        return type;
    }
  } else if (!type) {
    return [
      { value: 1, label: I18n.t('BEE1946' /* Pendente Alocação */) },
      { value: 2, label: I18n.t('BEE1367' /* Em Separação */) },
      { value: 3, label: I18n.t('BEE1368' /* Separado */) },
      { value: 4, label: I18n.t('BEE2120' /* Finalizada */) },
    ];
  }
}

export default {
  productionOrderSituation,
  productionOrderStatus,
};
