import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { PanelPage } from '../../components/pages/pages';
import OneClickButton from '../../components/form/button';
import DualList from '../../components/pages/DualList';
import Form from '../../components/form/form';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportAdminGeneralDeposit extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          { I18n.t('BEE12' /* Início */) }
        </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE14' /* Gerais */) },
      { value: I18n.t('BEE18' /* Depósitos */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE82' /* Código */),
        value: 'code',
      }, {
        label: I18n.t('BEE83' /* Nome */),
        value: 'name',
      }, {
        label: I18n.t('BEE135' /* Observação */),
        value: 'note',
      }, {
        label: I18n.t('BEE575' /* Usuário de criação */),
        value: 'createdUser',
      }, {
        label: I18n.t('BEE578' /* Data de criação */),
        value: 'createdAt',
      }, {
        label: I18n.t('BEE576' /* Usuário de alteração */),
        value: 'updatedUser',
      }, {
        label: I18n.t('BEE577' /* Data de alteração */),
        value: 'updatedAt',
      },
    ];

    this.state = {
      selected: [],
      codeFrom: '',
      codeTo: '',
      nameFrom: '',
      nameTo: '',
      resale: '',
      inbound: '',
      inventory: '',
      divergence: '',
      rejected: '',
      damage: '',
      quality: '',
      spare: '',
      allocateBalance: '',
    };
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      codeFrom,
      codeTo,
      nameFrom,
      nameTo,
      resale,
      inbound,
      inventory,
      divergence,
      rejected,
      damage,
      quality,
      spare,
      allocateBalance,
    } = this.state;
    const { location } = this.props;

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['code', { type: 'between', value: [codeFrom, codeTo] }],
      ['name', { type: 'between', value: [nameFrom, nameTo] }],
      ['resale', { type: 'and', value: resale }],
      ['inbound', { type: 'and', value: inbound }],
      ['inventory', { type: 'and', value: inventory }],
      ['divergence', { type: 'and', value: divergence }],
      ['rejected', { type: 'and', value: rejected }],
      ['damage', { type: 'and', value: damage }],
      ['quality', { type: 'and', value: quality }],
      ['spare', { type: 'and', value: spare }],
      ['allocateBalance', { type: 'and', value: allocateBalance }],
    ]);

    this.props.generateReport(
      I18n.t('BEE18' /* Depósitos */).toLowerCase(),
      'xlsx',

      location.pathname,

      headers,

      filters,
    );
  };

  onGeneratePdf = () => {
    const {
      selected,
      codeFrom,
      codeTo,
      nameFrom,
      nameTo,
      resale,
      inbound,
      inventory,
      divergence,
      rejected,
      damage,
      quality,
      spare,
      allocateBalance,
    } = this.state;
    const { location } = this.props;

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    /* Campo dos Filtros no final, inferior à esquerda */
    const filters = returnFiltersByRules([
      ['code', { type: 'between', value: [codeFrom, codeTo] }],
      ['name', { type: 'between', value: [nameFrom, nameTo] }],
      ['resale', { type: 'and', value: resale }],
      ['inbound', { type: 'and', value: inbound }],
      ['inventory', { type: 'and', value: inventory }],
      ['divergence', { type: 'and', value: divergence }],
      ['rejected', { type: 'and', value: rejected }],
      ['damage', { type: 'and', value: damage }],
      ['quality', { type: 'and', value: quality }],
      ['spare', { type: 'and', value: spare }],
      ['allocateBalance', { type: 'and', value: allocateBalance }],
    ]);

    this.props.generateReport(
      I18n.t('BEE18' /* Depósitos */).toLowerCase(),
      'pdf',

      location.pathname,

      headers,

      filters,
    );
  };

  onSelectFilter = (newState) => this.setState({ ...newState });

  render() {
    const {
      selected,
      codeFrom,
      codeTo,
      nameFrom,
      nameTo,
      resale,
      inbound,
      inventory,
      divergence,
      rejected,
      damage,
      quality,
      spare,
      allocateBalance,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        // Título da Empresa
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE18' /* Depósitos */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: codeFrom, to: codeTo },
                    { from: 'codeFrom', to: 'codeTo' },
                    `${I18n.t('BEE82' /* Código */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: nameFrom, to: nameTo },
                    { from: 'nameFrom', to: 'nameTo' },
                    `${I18n.t('BEE83' /* Nome */)}:`,
                    { from: '', to: 'Z'.repeat(100) },
                  ),
                  // Revenda
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE172' /* Sim */),
                      value: '1',
                    },
                    {
                      label: I18n.t('BEE173' /* Não */),
                      value: '0',
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], resale, 'resale', I18n.t('BEE571' /* Revenda */)),
                  // Recebimento
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE172' /* Sim */),
                      value: '1',
                    },
                    {
                      label: I18n.t('BEE173' /* Não */),
                      value: '0',
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], inbound, 'inbound', I18n.t('BEE36' /* Recebimento */)),
                  // Inventário
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE172' /* Sim */),
                      value: '1',
                    },
                    {
                      label: I18n.t('BEE173' /* Não */),
                      value: '0',
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], inventory, 'inventory', I18n.t('BEE572' /* Inventário */)),
                  // Divergência
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE172' /* Sim */),
                      value: '1',
                    },
                    {
                      label: I18n.t('BEE173' /* Não */),
                      value: '0',
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], divergence, 'divergence', I18n.t('BEE176' /* Divergência */)),
                  // Rejeitados
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE172' /* Sim */),
                      value: '1',
                    },
                    {
                      label: I18n.t('BEE173' /* Não */),
                      value: '0',
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], rejected, 'rejected', I18n.t('BEE177' /* Rejeitados */)),
                  // Avaria
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE172' /* Sim */),
                      value: '1',
                    },
                    {
                      label: I18n.t('BEE173' /* Não */),
                      value: '0',
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], damage, 'damage', I18n.t('BEE573' /* Avaria */)),
                  // Qualidade
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE172' /* Sim */),
                      value: '1',
                    },
                    {
                      label: I18n.t('BEE173' /* Não */),
                      value: '0',
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], quality, 'quality', I18n.t('BEE574' /* Qualidade */)),
                  // Sobra
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE172' /* Sim */),
                      value: '1',
                    },
                    {
                      label: I18n.t('BEE173' /* Não */),
                      value: '0',
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], spare, 'spare', I18n.t('BEE789' /* Sobra */)),
                  // Aloca Saldo
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE172' /* Sim */),
                      value: '1',
                    },
                    {
                      label: I18n.t('BEE173' /* Não */),
                      value: '0',
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], allocateBalance, 'allocateBalance', I18n.t('BEE171' /* Aloca Saldo */)),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <>
            <OneClickButton
              type="button"
              style={{ width: 'auto', padding: 10 }}
              className="btn btn-primary p-5 m-5"
              onClick={this.onGenerateSpreadsheet}
            >
              {I18n.t('BEE544' /* Gerar Planilha */)}
            </OneClickButton>
            {
              // <button
              //   type="button"
              //   style={{ width: 'auto', padding: 10 }}
              //   className="btn btn-secondary p-5 m-5"
              //   onClick={this.onGeneratePdf}
              // >
              //   {I18n.t('BEE545' /* Gerar PDF */)}
              // </button>
            }
          </>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportAdminGeneralDeposit);
