import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import DualList from '../../components/pages/DualList';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportInventoryMonitorSheetSerialLog extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE572' /* Inventário */) },
      { value: I18n.t('BEE3615' /* Monitor de Logs - Inventário de Etiquetas */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE572' /* Inventário */),
        value: 'grouperCode',
        table: 'inventory',
      }, {
        label: I18n.t('BEE145' /* Filial */),
        value: 'branchCode',
        table: 'inventory',
      }, {
        label: I18n.t('BEE267' /* Endereço */),
        value: 'addressCode',
        table: 'inventorySheet',
      }, {
        label: I18n.t('BEE378' /* Código do Produto */),
        value: 'productCode',
        table: 'inventorySheet',
      }, {
        label: I18n.t('BEE1043' /* Nome do Produto */),
        value: 'name',
        table: 'product',
      }, {
        label: I18n.t('BEE2764' /* Etiqueta Agrupadora */),
        value: 'code',
        table: 'serialGroup',
      }, {
        label: I18n.t('BEE2760' /* Etiqueta Seriada */),
        value: 'serialLabelCode',
        table: 'serialLabel',
      }, {
        label: I18n.t('BEE200' /* Tipo */),
        value: 'type',
        table: 'InventorySheetSerialLog',
      }, {
        label: I18n.t('BEE2761' /* Mensagem */),
        value: 'message',
        table: 'InventorySheetSerialLog',
      }, {
        label: I18n.t('BEE2288' /* Usuário Contagem */),
        value: 'login',
        table: 'userCreated',
      },
      {
        label: I18n.t('BEE578' /* Data Criação */),
        value: 'createdAt',
        table: 'InventorySheetSerialLog',
      },
    ];

    this.state = {
      selected: [],
      branchCodeFrom: '',
      branchCodeTo: '',
      createdAtFrom: '',
      createdAtTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      serialLabelFrom: '',
      serialLabelTo: '',
      serialGroupFrom: '',
      serialGroupTo: '',
    };
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      branchCodeFrom,
      branchCodeTo,
      createdAtFrom,
      createdAtTo,
      productCodeFrom,
      productCodeTo,
      serialLabelFrom,
      serialLabelTo,
      serialGroupFrom,
      serialGroupTo,
      selected,
    } = this.state;

    const { location } = this.props;
    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['branchCode', {
        type: 'between',
        instance: 'Inventory',
        as: 'inventory',
        value: [branchCodeFrom, branchCodeTo],
      }],
      ['productCode', {
        type: 'between',
        instance: 'InventorySheet',
        as: 'inventorySheet',
        value: [productCodeFrom, productCodeTo],
      }],
      ['createdAt', {
        type: 'between',
        value: [createdAtFrom, createdAtTo],
      }],
      ['code', {
        type: 'between',
        instance: 'SerialGroup',
        as: 'serialGroup',
        value: [serialGroupFrom, serialGroupTo],
      }],
      ['code', {
        type: 'between',
        instance: 'SerialLabel',
        as: 'serialLabel',
        value: [serialLabelFrom, serialLabelTo],
      }],
    ]);

    this.props.generateReport(
      I18n.t('BEE3615' /* Monitor de Logs - Inventário de Etiquetas */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  onSelectFilter = (newState) => this.setState({ ...newState });

  render() {
    const {
      branchCodeFrom,
      branchCodeTo,
      createdAtFrom,
      createdAtTo,
      productCodeFrom,
      productCodeTo,
      serialLabelFrom,
      serialLabelTo,
      serialGroupFrom,
      serialGroupTo,
      selected,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${
          I18n.t('BEE3615' /* Monitor de Logs - Inventário de Etiquetas */)
        }`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            {' '}
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div className="col">
              <div className="pt-4 add_display_flex" style={{ marginTop: 30 }}>
                <Form
                  noPanel
                  leftType
                  setValue={this.setValue}
                  inputs={(formContext) => ([
                    formContext.createInputRange(
                      { from: branchCodeFrom, to: branchCodeTo },
                      { from: 'branchCodeFrom', to: 'branchCodeTo' },
                      `${I18n.t('BEE145' /* Filial */)}:`,
                      { from: '', to: 'Z'.repeat(10) },
                    ),
                    formContext.createDateInputRange(
                      { from: createdAtFrom, to: createdAtTo },
                      { from: 'createdAtFrom', to: 'createdAtTo' },
                      `${I18n.t('BEE2342' /* Data da Contagem */)}:`,
                      { from: '__/__/____', to: '__/__/____' },
                    ),
                    formContext.createInputRange(
                      { from: productCodeFrom, to: productCodeTo },
                      { from: 'productCodeFrom', to: 'productCodeTo' },
                      `${I18n.t('BEE225' /* Produto */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                    ),
                    formContext.createInputRange(
                      { from: serialGroupFrom, to: serialGroupTo },
                      { from: 'serialGroupFrom', to: 'serialGroupTo' },
                      `${I18n.t('BEE2764' /* Etiqueta Agrupadora */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                    ),
                    formContext.createInputRange(
                      { from: serialLabelFrom, to: serialLabelTo },
                      { from: 'serialLabelFrom', to: 'serialLabelTo' },
                      `${I18n.t('BEE2760' /* Etiqueta Seriada */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                    ),
                  ])}
                />
              </div>
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportInventoryMonitorSheetSerialLog);
