import RestService from '../services/rest';

// GET
async function getDepartment(departmentId) {
  return RestService.getAuthenticated(`departments/detail?departmentId=${departmentId}`);
}

async function getDepartments() {
  return RestService.getAuthenticated('departments/list/all');
}

async function getDepartmentsOptions() {
  return RestService.getAuthenticated('departments/list/options');
}

// POST
async function createDepartment(department) {
  return RestService.postAuthenticated('departments/create', department);
}

// PUT
async function updateDepartment(department) {
  return RestService.putAuthenticated('departments/update', department);
}

async function deleteDepartment(departmentId) {
  return RestService.postAuthenticated('departments/delete', { departmentId });
}

export default {
  createDepartment,
  getDepartment,
  getDepartments,
  getDepartmentsOptions,
  updateDepartment,
  deleteDepartment
};
