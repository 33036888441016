import RestService from '../services/rest';

const getReportUserFilters = async (filterData) => {
  return RestService.getAuthenticated('usersFilters/getReportUserFilters', filterData);
};

const saveOrCreateFilter = async (values) => {
  return RestService.postAuthenticated('usersFilters/saveOrCreateFilterDefault', values);
};

const removeFilter = async (values) => {
  return RestService.postAuthenticated('usersFilters/removeFilterDefault', values);
};

export default {
  getReportUserFilters,
  saveOrCreateFilter,
  removeFilter,
};