import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import NumberFormat from 'react-number-format';

import OneClickButton from '../../../components/form/button';
import { Panel, PanelHeader, PanelBody, PanelFooter } from '../../../components/panel/panel';
import addNotification from '../../../components/notification';

import { getBaptism, saveVolumeCheck } from '../../../app/store/actions/outboundOrderVolumes';
import VolumesList from './volumesList';

class VolumeCheckForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      baptism: '',
      outboundOrderNumber: '',
      outboundOrderId: null,
      volumeLabel: '',
      volumesLabels: [],
      packageCode: '',
      quantity: 0,
      grossWeight: 0,
      showForm: false,
    };

  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled, keypressFunction=undefined, id=null) => (
    <div className="form-group p-2">
      <label>{label}</label>
      <div className='input-group'>
        <input
          onKeyDown={ keypressFunction && (e => keypressFunction(e, id, attr)) }
          id={ id && id }
          type={type}
          className="form-control"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, '')}>
            <i className='fa fa-times'></i>
          </button>
        </div>
      </div>
    </div>
  );

  createInputButton = (value, attr, label, placeholder, type = 'text', required, disabled, buttonLabel, buttonFunction) => (
    <div className="form-group p-2">
      <label>{label}</label>
      <div className="input-group">
        <input 
          type="text"
          className="form-control"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, '')}>
            <i className='fa fa-times'></i>
          </button>
          <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
            { buttonLabel }
          </button>
        </div>
      </div>
    </div>
  );

  createNumberFormat = (value, attr, label, decimals, keypressFunction=undefined, id=null, disabled=false ) => (
    <div className="form-group p-2">
      <label>{label}</label>
      <div className='input-group'>
        <NumberFormat
          onKeyDown={ keypressFunction && (e => keypressFunction(e, id, attr)) }
          id={ id && id }
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={decimals}
          value={value || ''}
          onValueChange={(values) => {
            this.setValue(attr, values.floatValue)
          }}
          fixedDecimalScale
          defaultValue={0}
          disabled={disabled}
          allowEmptyFormatting={true}
        ></NumberFormat>
        { !disabled && <div className="input-group-append">
          <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, 0)}>
            <i className='fa fa-times'></i>
          </button>
        </div>}
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  resetForm = () => {
    this.setState({
      baptism: '',
      outboundOrderNumber: '',
      outboundOrderId: null,
      volumeLabel: '',
      volumesLabels: [],
      packageCode: '',
      quantity: 0,
      grossWeight: 0,
      showForm: false,
    });
  }

  getBaptism = async () => {
    const { baptism } = this.state;

    try {
      if (baptism === '') {
        addNotification('danger', I18n.t('BEE757' /* Conferência de Volume */), I18n.t('BEE982' /* É necessário informar o batismo */), 'top-right');
        return
      } else {
        const baptismData = await this.props.getBaptism(baptism);

        const outboundOrderNumber = baptismData.outboundOrderNumber
        const outboundOrderId = baptismData.outboundOrderId

        if (!outboundOrderNumber) {
          addNotification('danger', I18n.t('BEE757' /* Conferência de Volume */), I18n.t('BEE1069', { 0: baptism} /* Batismo %{0} não encontrado */), 'top-right');
          return
        }

        this.setState({
          outboundOrderNumber,
          outboundOrderId,
          showForm: true,
        });

        document.getElementById(0).focus();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE757' /* Conferência de Volume */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          addNotification(
            'danger', I18n.t('BEE757' /* Conferência de Volume */), 'Erro Conferência de Volume', 'top-right'
          );
        }
      } else {
        addNotification(
          'danger', I18n.t('BEE757' /* Conferência de Volume */), 'Erro Conferência de Volume', 'top-right'
        );
      }
    }
  }

  goToElement = (e, id, attr) => {
    if (e.keyCode === 13) {
      if (attr === 'ean') {
        this.scanEan();
      } 
      document.getElementById(id+1).focus();
      document.getElementById(id+1).select();
    }
  }

  keypressAddVolumes = (e) => {
    e.keyCode === 13 && this.addVolumes();
  }

  addVolumes = () => {
    const { quantity, packageCode } = this.state;

    const volumesLabels = [];
    for (let i = 0; i<quantity; i++) {
      const newVolume = {
        packageCode,
        volumeLabel: i+1,
        quantity,
      }
      volumesLabels.push(newVolume)
    }
    this.setState({
      volumesLabels,
      packageCode: '',
      quantity: 0,
    })
  }

  keypressSubmitVolumes = (e) => {
    e.keyCode === 13 && this.submitVolumes();
  }

  submitVolumes = async () => {
    const { volumesLabels, grossWeight, outboundOrderId } = this.state;

    const volumesList = volumesLabels.map(vol => {
      vol.grossWeight = grossWeight
      vol.outboundOrderId = outboundOrderId
      return vol
    })

    await this.props.saveVolumeCheck(volumesList)
  }

  render() {
    let {
      baptism, shipment, outboundOrderNumber, packageCode, quantity, grossWeight,
    } = this.state;

    const {
      volumesLabels, showForm,
    } = this.state;

    let title = I18n.t('BEE757' /* Conferência de Volume */);

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE757' /* Conferência de Volume */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <form onSubmit={e => e.preventDefault()}>
              <Panel>
                <PanelHeader noButton={true} />
                <PanelBody>
                  <div className='row'>
                    <div className='col-md-4'>
                      { showForm 
                        ? this.createInput(baptism, 'baptism', I18n.t('BEE425' /* Batismo */), '', 'text', true, showForm)
                        : this.createInputButton(baptism, 'baptism', I18n.t('BEE425' /* Batismo */), I18n.t('BEE1076' /* Informe o batismo */), 'text', true, false, <i className='fa fa-search'></i>, this.getBaptism)
                      }
                    </div>
                    <div className='col-md-4'>
                      { showForm && this.createInput(
                        shipment, 'shipment', I18n.t('BEE1378' /* Documento */), '', 'text', false, true, this.goToElement, 0
                      )}
                    </div>
                    <div className='col-md-4'>
                      { showForm && this.createInput(
                        outboundOrderNumber, 'outboundOrderNumber', I18n.t('BEE51' /* Pedido */), '', 'text', false, true, this.goToElement, 1
                      )}
                    </div>
                  </div>
                  { showForm && <div className='row'>
                    <div className='col-md-8'>
                      {this.createInput(
                        packageCode, 'packageCode', I18n.t('BEE497' /* Embalagem */), '', 'text', false, false, this.goToElement, 2
                      )}
                    </div>
                    <div className='col-md-4'>
                      {this.createInput(
                        quantity, 'quantity', I18n.t('BEE405' /* Volumes */), '', 'number', false, false, this.keypressAddVolumes, 3
                      )}
                    </div>
                  </div> }
                </PanelBody>
                <PanelFooter>
                  <div className='row'>
                    <div className='col text-right'>
                      <OneClickButton 
                        type="button"
                        className="btn btn-120 btn-white p-5 m-5"
                        onClick={() => this.resetForm()}
                        >{I18n.t('BEE99' /* Cancelar */)}
                      </OneClickButton>
                      {(!showForm &&
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-primary p-5 m-5"
                          onClick={() => this.getBaptism()}
                        >{I18n.t('BEE407' /* Buscar */)}</OneClickButton>
                      )}
                      {(showForm &&
                        <OneClickButton
                          type="button"
                          className="btn btn-120 btn-primary p-5 m-5"
                          onClick={() => this.addVolumes()}
                        >{I18n.t('BEE785' /* Adicionar */)}</OneClickButton>
                      )}
                    </div>
                  </div>
                </PanelFooter>
              </Panel>
              { showForm && (
                <Panel>
                  <PanelBody>
                    <div className="row">
                    <VolumesList 
                      volumes={volumesLabels}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-4 text-left">
                        {this.createNumberFormat(
                          grossWeight, 'grossWeight', I18n.t('BEE564' /* Peso Bruto */), 0, this.keypressAddVolumes, 10, false
                        )}
                      </div>
                    </div>
                  </PanelBody>
                  <PanelFooter>
                    <div className="row">
                      <div className="col text-right">
                      <OneClickButton
                          type="button"
                          className="btn btn-120 btn-primary p-5 m-5"
                          onClick={() => this.submitVolumes()}
                        >{I18n.t('BEE100' /* Confirmar */)}</OneClickButton>
                      </div>
                    </div>
                  </PanelFooter>
                </Panel>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getBaptism: baptismLabel => dispatch(getBaptism(baptismLabel)),
  saveVolumeCheck: volumesList => dispatch(saveVolumeCheck(volumesList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VolumeCheckForm));