import MaterialsFamilyRequests from '../../server/materialsFamily';
import { addLoading, removeLoading } from './loading';

export const setMaterialsFamily = materialsFamily => ({
  type: 'SET_MATERIALS_FAMILY',
  materialsFamily,
});

export const getMaterialsFamilyList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const materialsFamily = await MaterialsFamilyRequests.getMaterialsFamily();
    dispatch(setMaterialsFamily(materialsFamily));
  } finally {
    dispatch(removeLoading());
  }
};

export const createMaterialFamily = materialFamily => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newMaterialFamily = await MaterialsFamilyRequests.createMaterialFamily(materialFamily);
    return newMaterialFamily;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateMaterialFamily = (materialFamily) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await MaterialsFamilyRequests.updateMaterialFamily(materialFamily);
  } finally {
    dispatch(removeLoading());
  }
}

export const getMaterialFamily = (materialFamilyId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await MaterialsFamilyRequests.getMaterialFamily(materialFamilyId);
  } finally {
    dispatch(removeLoading());
  }
}

export const deleteMaterialFamily = (materialFamilyId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await MaterialsFamilyRequests.deleteMaterialFamily(materialFamilyId);
  } finally {
    dispatch(removeLoading());
  }
}

export const getMaterialsFamilyOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await MaterialsFamilyRequests.getMaterialsFamilyOptions();
  } finally {
    dispatch(removeLoading());
  }
};