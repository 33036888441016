import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';

import Table from '../../../components/table/Table';
import FormatHelpers from '../../../helpers/format';

export default class BaptismPartialConference extends React.Component {
  constructor(props) {

    super(props);

    this.tableColumns = [{
      Header: I18n.t('BEE463' /* Linha */),
      accessor: 'lineNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80
    }, {
      Header: I18n.t('BEE225' /* Produto */),
      accessor: 'productCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80
    }, {
      Header: I18n.t('BEE277' /* Descrição */),
      accessor: 'productName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200
    }, {
      Header: I18n.t('BEE425' /* Batismo */),
      accessor: 'baptismLabel',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100
    }, {
      Header: I18n.t('BEE494' /* Sequência */),
      accessor: 'sequence',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80
    }, {
      Header: I18n.t('BEE441' /* Quantidade */),
      accessor: 'quantity',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Cell: (row) => (
        <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
      ),
    },
    ];

    this.state = {
      defaultPageSize: 5,
      pageSizeOptions: [5, 10, 15],
    };
  }

  render() {
    const {
      pageSizeOptions, defaultPageSize
    } = this.state;
    const {
      openModalPartialBaptism, setModalPartialBaptism,
      listInboundOrderProductPartial = [],
    } = this.props;

    return (
      <Modal size='lg' isOpen={openModalPartialBaptism} toggle={setModalPartialBaptism}>
        <ModalHeader toggle={setModalPartialBaptism}>{I18n.t('BEE2312' /* Batismo Parcial */)}</ModalHeader>
        <ModalBody>
          <Table
            filterable
            data={listInboundOrderProductPartial}
            columns={this.tableColumns}
            panelHeaderProps={{
              noButton: true,
            }}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={pageSizeOptions}
            defaultSorted={[{ id: 'lineNumber', desc: false }]}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
          />
        </ModalBody>
      </Modal>
    );
  };
};