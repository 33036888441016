import RestService from '../services/rest';

// GET
async function getBranches() {
  return RestService.getAuthenticated('branches/list/all');
}

async function getBranch(branchId) {
  return RestService.getAuthenticated(`branches/detail?branchId=${branchId}`);
}

async function getBranchSettings(branchId) {
  return RestService.getAuthenticated(`branches/settings/detail?branchId=${branchId}`);
}

async function getBranchesOptions() {
  return RestService.getAuthenticated('branches/list/options');
}

async function getOptionsBranchesAndDeposits() {
  return RestService.getAuthenticated('branches/list/optionsBranches');
}

async function getBranchesSuggestions() {
  return RestService.getAuthenticated('branches/list/suggestions');
}

// POST
async function createBranch(branch) {
  return RestService.postAuthenticated('branches/create', branch);
}

// PUT
async function updateBranch(branch) {
  return RestService.putAuthenticated('branches/update', branch);
}

async function updateBranchSettings(branch) {
  return RestService.putAuthenticated('branches/settings/update', branch);
}

// DELETE
async function deleteBranch(branch) {
  return RestService.postAuthenticated('branches/delete', branch);
}

export default {
  getBranches,
  createBranch,
  updateBranch,
  getBranch,
  getBranchesOptions,
  getOptionsBranchesAndDeposits,
  getBranchesSuggestions,
  deleteBranch,
  updateBranchSettings,
  getBranchSettings,
};
