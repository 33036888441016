import RestService from '../services/rest';

// GET
async function getIndustrialScales() {
  return RestService.getAuthenticated('industrialScales/list/all');
}

async function getIndustrialScale(industrialScaleId) {
  return RestService.getAuthenticated('industrialScales/detail', { industrialScaleId });
}

// POST
async function createIndustrialScale(industrialScale) {
  return RestService.postAuthenticated('industrialScales/create', industrialScale);
}

// PUT
async function updateIndustrialScale(industrialScale) {
  return RestService.putAuthenticated('industrialScales/update', industrialScale);
}

// DELETE
async function deleteIndustrialScale(industrialScaleId) {
  return RestService.postAuthenticated('industrialScales/delete', { industrialScaleId });
}

export default {
  getIndustrialScales,
  createIndustrialScale,
  updateIndustrialScale,
  getIndustrialScale,
  deleteIndustrialScale,
};
