import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';
import {
  getOutboundWavesByDate, getWaveDocuments, setWaveDocuments, updateWaveAndDocuments,
} from '../../../app/store/actions/outboundWaves';
import addNotification from '../../../components/notification';
import UserConfirmationModal from '../../../components/pages/userConfirmationModal';
import { Panel } from '../../../components/panel/panel';
import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';
import OutboundOrdersWaveHelpers from '../../../helpers/outboundOrdersWave';
import OutboundOrdersTable from './outboundOrdersTable';

class OutboundOrderWaveMonitor extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE1882' /* Monitor de Onda */), active: true },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            {rows.row.status < 3 && (
              <UncontrolledButtonDropdown>
                <DropdownToggle color="default" caret className="btn-xs" />
                <DropdownMenu>
                  <DropdownItem onClick={() => this.setState({ openModal: true })}>
                    {I18n.t('BEE81' /* Eliminar */)}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            )}
          </div>
        ),
      }, {
        Header: I18n.t('BEE1454' /* Onda */),
        accessor: 'waveNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE1410' /* Usuário Separação */),
        accessor: 'pickedUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 90,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{OutboundOrdersWaveHelpers.statusOutboundOrdersWave(row.value)}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OutboundOrdersWaveHelpers.statusOutboundOrdersWave().map((element) => (
              <option
                key={element.value}
                value={element.value}
              >
                {element.label}
              </option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
    ];

    this.state = {
      outboundWaves: [],
      selectedWave: {},
      openModal: false,
      defaultPageSize: 5,
      defaultSorted: [{ id: 'status', desc: false }, { id: 'waveNumber', desc: false }],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
    };
  }

  componentDidMount() {
    this.getOutboundWavesByDate();
  }

  getOutboundWavesByDate = async () => {
    const { startDate, endDate } = this.state;

    try {
      const outboundWaves = await this.props.getOutboundWavesByDate(
        moment(startDate).format(),
        moment(endDate).format(),
      );

      if (outboundWaves && outboundWaves.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1882' /* Monitor de Onda */),
          I18n.t('BEE1892' /* Nenhum onda foi localizada */),

          'top-right',
        );
      } else {
        this.setState({ outboundWaves });
      }
    } catch (error) {
      const messageError = error.response && error.response.data
        && error.response.data.error && error.response.data.error.message;

      if (messageError) addNotification('danger', I18n.t('BEE1882' /* Monitor de Onda */), messageError, 'top-right');
      else {
        addNotification(
          'danger',
          I18n.t('BEE1882' /* Monitor de Onda */),
          I18n.t('BEE1892' /* Nenhum onda foi localizada */),
          'top-right',
        );
      }
    }
  };

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });

    this.getOutboundWavesByDate();
  };

  waveDocuments = async () => {
    const { selectedWave } = this.state;

    try {
      await this.props.getWaveDocuments(selectedWave.id);
    } catch (error) {
      const messageError = error.response && error.response.data
        && error.response.data.error && error.response.data.error.message;

      if (messageError) addNotification('danger', I18n.t('BEE1882' /* Monitor de Onda */), messageError, 'top-right');
      else {
        addNotification(
          'danger',
          I18n.t('BEE1882' /* Monitor de Onda */),
          I18n.t('BEE1891' /* Nenhum documento nessa onda */),
          'top-right',
        );
      }
    }
  };

  updateWaveAndDocuments = async () => {
    const { selectedWave } = this.state;

    try {
      const updateWaveAndDocuments = await this.props.updateWaveAndDocuments('', selectedWave.id);
      if (updateWaveAndDocuments && updateWaveAndDocuments.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1882' /* Monitor de Onda */),
          I18n.t('BEE1889' /* Não foi possível eliminar essa onda */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE1882' /* Monitor de Onda */),
          I18n.t('BEE1890' /* Onda eliminada */),
          'top-right',
        );
        this.setState({ selectedWave: {} });
        this.props.setWaveDocuments([]);
        this.getOutboundWavesByDate();
      }
    } catch (error) {
      const messageError = error.response && error.response.data
        && error.response.data.error && error.response.data.error.message;

      if (messageError) addNotification('danger', I18n.t('BEE1882' /* Monitor de Onda */), messageError, 'top-right');
      else {
        addNotification(
          'danger',
          I18n.t('BEE1882' /* Monitor de Onda */),
          I18n.t('BEE1889' /* Não foi possível eliminar essa onda */),
          'top-right',
        );
      }
    }
  };

  userConfirmation = async (confirm) => {
    if (confirm) {
      this.setState({ openModal: false });
      this.updateWaveAndDocuments();
    } else {
      this.setState({ openModal: false });
    }
  };

  render() {
    const {
      defaultPageSize, defaultSorted, outboundWaves, selectedWave,
    } = this.state;

    return (
      <>
        <Table
          breadcrumb={this.breadcrumb}
          headerTitle={I18n.t('BEE1882' /* Monitor de Onda */)}
          wikiHelp={ROUTES.OUTBOUND_WAVE_MONITOR_HELP}
          downloadCSV
          panelHeaderProps={{
            onClickReload: this.getOutboundWavesByDate,
            children: I18n.t('BEE1454' /* Onda */).toUpperCase(),
          }}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          filterable
          expander
          data={outboundWaves}
          columns={this.tableColumns}
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: async () => {
                  await this.setState({ selectedWave: rowInfo.original });
                  await this.waveDocuments();
                },
                style: {
                  background: selectedWave && rowInfo.original.id === selectedWave.id ? 'silver' : 'white',
                  cursor: 'pointer',
                },
              };
            }
            return {};
          }}
        />
        <Panel>
          <OutboundOrdersTable
            getOutboundWavesByDate={() => this.getOutboundWavesByDate()}
            getWaveDocuments={() => this.waveDocuments()}
          />
        </Panel>
        <UserConfirmationModal
          openModal={this.state.openModal}
          confirm={(confirm) => this.userConfirmation(confirm)}
        />
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getOutboundWavesByDate: (
    filterStartDate,
    filterEndDate,
  ) => dispatch(getOutboundWavesByDate(filterStartDate, filterEndDate)),
  getWaveDocuments: (outboundWaveId) => dispatch(getWaveDocuments(outboundWaveId)),
  setWaveDocuments: (waveDocuments) => dispatch(setWaveDocuments(waveDocuments)),
  updateWaveAndDocuments: (
    outboundRangeId,
    outboundWaveId,
  ) => dispatch(updateWaveAndDocuments(outboundRangeId, outboundWaveId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundOrderWaveMonitor));
