import RestService from '../services/rest';

// GET
async function getPrinters() {
  return RestService.getAuthenticated('printers/list/all');
}

async function getPrinter(printerId) {
  return RestService.getAuthenticated(`printers/detail?printerId=${printerId}`);
}

async function getUserPrinterOptions() {
  return RestService.getAuthenticated('printers/userOptions');
}

async function getUsersPrinter(printerId) {
  return RestService.getAuthenticated(`printers/getUsersPrinter?printerId=${printerId}`);
}

async function getAllAgentsOptions() {
  return RestService.getAuthenticated('printers/getAllAgentsOptions');
}

// POST
async function createPrinter(printer) {
  return RestService.postAuthenticated('printers/create', printer);
}

async function deletePrinter(printerId) {
  return RestService.postAuthenticated('printers/delete', { printerId });
}

// PUT
async function updatePrinter(printer) {
  return RestService.putAuthenticated('printers/update', printer);
}

export default {
  getPrinters,
  createPrinter,
  updatePrinter,
  getPrinter,
  deletePrinter,
  getUserPrinterOptions,
  getUsersPrinter,
  getAllAgentsOptions,
};
