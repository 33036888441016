import 'react-table/react-table.css';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from 'lodash';

import Table from '../../../../components/table/Table';
import AdressTransferWaveProductHelpers from '../../../../helpers/adressTransferWaveProduct';
import addNotification from '../../../../components/notification';
import { deleteAddressTransferWaveProduct } from '../../../../app/store/actions/addressTransferWave';
import FormatHelpers from '../../../../helpers/format';

class ProductsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      defaultPageSize: 5,
      selectedProduct: {},
    };

    this.defaultSorted = [{
      id: 'status', desc: false
    }, {
      id: 'productCode', desc: false
    }];

    this.tableColumns = [{
      Header: I18n.t('BEE55' /* Ações */),
      accessor: 'action',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      minWidth: 100,
      Cell: rows => (
        <div style={{ textAlign: 'center' }}>
          <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
          <UncontrolledButtonDropdown>
            <DropdownToggle color="default" caret className="btn-xs"></DropdownToggle>
            <DropdownMenu>
              {rows.original.status === 1 &&
                <DropdownItem onClick={() => this.openModalDelete(rows.original)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              }
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      )
    }, {
      Header: I18n.t('BEE225' /* Produto */),
      accessor: 'productCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE277' /* Descrição */),
      accessor: 'product.fullName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE224' /* Status */),
      accessor: 'status',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
      Cell: rows => (<span>{AdressTransferWaveProductHelpers.statusTransfer(rows.original.status)}</span>),
      filterMethod: (filter, row) => {
        if (parseInt(filter.value) === parseInt(row.status)) return true;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select type={'text'} style={{ width: '100%' }}
            onChange={event => { onChange(event.target.value) }}
          >
            <option value='' key='all'>{I18n.t('BEE793' /* Todos */)}</option>
            {AdressTransferWaveProductHelpers.statusTransfer().map((elem, index) => (
              <option value={elem.value} key={index} >{elem.label}</option>
            ))}
          </select>
        )
      },
    }, {
      Header: I18n.t('BEE428' /* Lote */),
      accessor: 'lotNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
    }, {
      Header: I18n.t('BEE377' /* EAN */),
      accessor: 'ean',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
    }, {
      Header: I18n.t('BEE384' /* Unidade */),
      accessor: 'product.unitMeasure',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE495' /* Endereço Retirada */),
      accessor: 'pickupAddress',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE441' /* Quantidade */),
      accessor: 'quantity',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Cell: (row) => (
        <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
      ),
    }];
  }

  openModalDelete = (selectedProduct) => {
    this.setState({ showModalDelete: true, selectedProduct: { ...selectedProduct } });
  }

  deleteAddressTransferWaveProduct = async () => {
    const { selectedProduct } = this.state;
    this.setState({ showModalDelete: false });
    try {
      const result = await this.props.deleteAddressTransferWaveProduct(selectedProduct.id);

      if (result && result.success === false) {
        addNotification('danger', I18n.t('BEE2294' /* Transferência por Onda */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');
      } else {
        addNotification('success', I18n.t('BEE225' /* Produto */),
          I18n.t('BEE1779', { 0: selectedProduct.productCode } /* Produto %{0} eliminado com sucesso! */), 'top-right'
        );
        this.props.getAddressTransferWaveByBaptism();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details) ?
          `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2294' /* Transferência por Onda */), messageError, 'top-right');

      } else {
        addNotification('danger', I18n.t('BEE2294' /* Transferência por Onda */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');
      }
    }
  }

  render() {
    const { products = [] } = this.props;
    const { showModalDelete, selectedProduct, defaultPageSize } = this.state;

    return (
      <>
        <Table
          filterable
          data={products}
          columns={this.tableColumns}
          expander
          defaultPageSize={defaultPageSize}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {showModalDelete &&
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteAddressTransferWaveProduct()}
            onCancel={() => this.setState({ showModalDelete: false, })}
          >
            {I18n.t('BEE1778', { 0: selectedProduct.productCode } /* O produto %{0} será eliminado! */)}
          </SweetAlert>
        }
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteAddressTransferWaveProduct: (transterOrderOutboundId) => dispatch(deleteAddressTransferWaveProduct(transterOrderOutboundId)),
});

export default connect(null, mapDispatchToProps)(withRouter(ProductsList));