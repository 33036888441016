import RestService from '../services/rest';

// GET
async function getListSheetSerialLogs(filterStartDate, filterEndDate, page, pageSize, sorted, filtered) {
  return RestService.postAuthenticated('inventorySheetSerialLog/list', {
    filterStartDate, filterEndDate, page, pageSize, sorted, filtered,
  });
}

export default {
  getListSheetSerialLogs,
};
