import PickingAreaRequests from '../../server/pickingAreas';
import StorageAddressesRequests from '../../server/storageAddresses';
import { addLoading, removeLoading } from './loading';

export const setPickingAreas = (pickingAreas) => ({
  type: 'SET_PICKING_AREA',
  pickingAreas,
});

export const setAddressPickingAreas = (pickingAreas) => ({
  type: 'SET_ADDRESSES_PICKING_AREA',
  pickingAreas,
});

export const openBulkImportPickingArea = (showBulkImport) => ({
  type: 'OPEN_BULK_IMPORT_PICKING_AREA',
  showBulkImport,
});

export const bulkImportAddressesPickingArea = (jsonCsv) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StorageAddressesRequests.updateBulkImportAddressesPickingArea(jsonCsv);
  } finally {
    dispatch(removeLoading());
  }
};

export const getPickingArea = (pickingAreaID) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PickingAreaRequests.getPickingArea(pickingAreaID);
  } finally {
    dispatch(removeLoading());
  }
};

export const getPickingAreaList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const pickingAreas = await PickingAreaRequests.getPickingAreas();
    dispatch(setPickingAreas(pickingAreas));
  } finally {
    dispatch(removeLoading());
  }
};

export const getPickingAreaOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PickingAreaRequests.getOptionsPickingAreas();
  } finally {
    dispatch(removeLoading());
  }
};

export const getPickingAreaOptionsProducts = (productFilter) => async (dispatch) => {
  try {
    return await PickingAreaRequests.getOptionsPickingAreasProducts(productFilter);
  } finally {
    dispatch(removeLoading());
  }
};

export const getStorageAddressesPickingAreaList = (PickingAreaId = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const storageAddresses = await StorageAddressesRequests.getStorageAddressesPickingArea(PickingAreaId);
    dispatch(setAddressPickingAreas(storageAddresses || []));
    dispatch(removeLoading());
    return storageAddresses || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const createPickingArea = (pickingArea) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newPickingArea = await PickingAreaRequests.createPickingArea(pickingArea);
    return newPickingArea;
  } finally {
    dispatch(removeLoading());
  }
};

export const updatePickingArea = (pickingArea) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const updPickingArea = await PickingAreaRequests.updatePickingArea(pickingArea);
    return updPickingArea;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateStorageAddressPickingArea = (storageAddressId, pickingAreaId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const updPA = await StorageAddressesRequests.updateStorageAddressesPickingArea(storageAddressId, pickingAreaId);
    return updPA;
  } finally {
    dispatch(removeLoading());
  }
};

export const deletePickingArea = (pickingAreaId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await PickingAreaRequests.deletePickingArea(pickingAreaId);
    dispatch(removeLoading());
    return { success: true, message: 'OK' };
  } catch (e) {
    dispatch(removeLoading());
    return { success: false, message: e.response.data.error.message };
  }
};
