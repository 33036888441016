import { I18n } from 'react-redux-i18n';

const resupplyStatus = (status) => {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE470' /* Pendente */);
      case 2:
        return I18n.t('BEE2647' /* Em Ressuprimento */);
      case 3:
        return I18n.t('BEE1766' /* Finalizado */);
      case 4:
        return I18n.t('BEE64' /* Cancelado */);
      case 5:
        return I18n.t('BEE2860' /* Retirado */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE470' /* Pendente */) },
      { value: 2, label: I18n.t('BEE2647' /* Em Ressuprimento */) },
      { value: 5, label: I18n.t('BEE2860' /* Retirado */) },
      { value: 3, label: I18n.t('BEE1766' /* Finalizado */) },
      { value: 4, label: I18n.t('BEE64' /* Cancelado */) },
    ];
  }
};

export default {
  resupplyStatus,
};
