import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import ROUTES from '../../../../config/routes';

import addNotification from '../../../../components/notification';
import Form from '../../../../components/form/form';
import { getBranchesOptions } from '../../../../app/store/actions/branches';
import { getDepositsOptions } from '../../../../app/store/actions/deposits';

import {
  createResupplyDeposits, updateResupplyDeposits,
} from '../../../../app/store/actions/resupplyDeposits';

class ResupplyDepositsForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      resupplyDepositsId: '',
      branchCode: '',
      depositCode: '',
      branchName: '',
      depositName: '',
      depositWithdrawCode: '',
      depositWithdrawName: '',
      listBranch: [],
      listDeposit: [],
      disableFields: false,
    };
  }

  async componentDidMount() {
    const { mode } = this.props;
    const listDeposit = await this.props.getDepositsOptions();
    const listBranch = await this.props.getBranchesOptions();

    const disableFields = mode === 'detail';
    let temp = {};

    if (mode !== 'create') {
      const { resupplyDeposits } = this.props.location.state;
      temp = {
        resupplyDepositsId: resupplyDeposits.id,
        branchCode: resupplyDeposits.branchCode,
        branchName: `${resupplyDeposits.branchCode} - ${resupplyDeposits.branch.name}`,
        depositCode: resupplyDeposits.depositCode,
        depositName: resupplyDeposits.deposit.name,
        depositWithdrawCode: resupplyDeposits.depositCodeWithdraw,
        depositWithdrawName: resupplyDeposits.depositWithdraw.name,
        listDeposit,
        listBranch,
        disableFields,
      };
    } else {
      temp = {
        listDeposit,
        listBranch,
        disableFields,
      };
    }

    await this.setState({ ...temp });
  }

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  createResupplyDeposits = async () => {
    const {
      branchCode, depositCode, depositWithdrawCode,
    } = this.state;

    try {
      const newResupplyDeposits = await this.props.createResupplyDeposits({
        branchCode, depositCode, depositCodeWithdraw: depositWithdrawCode,
      });

      if (newResupplyDeposits) {
        addNotification(
          'success',
          I18n.t('BEE2932' /* Associação de Depósitos */),
          I18n.t('BEE2879' /* Incluída com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.RESUPPLY_DEPOSITS_LIST);
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2932' /* Associação de Depósitos */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE2932' /* Associação de Depósitos */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE2932' /* Associação de Depósitos */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2932' /* Associação de Depósitos */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  updateResupplyDeposits = async () => {
    const {
      resupplyDepositsId, branchCode, depositCode, depositWithdrawCode,
    } = this.state;

    try {
      const updResupplyDeposits = await this.props.updateResupplyDeposits({
        id: resupplyDepositsId, branchCode, depositCode, depositCodeWithdraw: depositWithdrawCode,
      });

      if (updResupplyDeposits?.data && updResupplyDeposits?.data?.success) {
        addNotification(
          'success',
          I18n.t('BEE2932' /* Associação de Depósitos */),
          I18n.t('BEE2880' /* Alterado com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.RESUPPLY_DEPOSITS_LIST);
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2932' /* Associação de Depósitos */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE2932' /* Associação de Depósitos */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE2932' /* Associação de Depósitos */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2932' /* Associação de Depósitos */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      branchCode,
      branchName,
      depositCode,
      depositName,
      depositWithdrawCode,
      depositWithdrawName,
      listBranch,
      listDeposit,
      disableFields,
    } = this.state;
    const { mode } = this.props;

    const title = I18n.t('BEE2932' /* Associação de Depósitos */);

    const modeDescription = {
      create: I18n.t('BEE138' /* Criar */),
      detail: I18n.t('BEE56' /* Detalhes */),
      edit: I18n.t('BEE57' /* Editar */),
      copy: I18n.t('BEE80' /* Copiar */),
    };

    const breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            { I18n.t('BEE12' /* Início */) }
          </>
        ),
      },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE14' /* Gerais */) },
      { value: I18n.t('BEE2932' /* Associação de Depósitos */) },
      { value: modeDescription[mode], active: true },
    ];

    return (
      <Form
        title={title}
        noButton
        linkBack={{ pathname: ROUTES.RESUPPLY_DEPOSITS_LIST, state: { dice: this.props.location.dice } }}
        showCreate={mode === 'create' || mode === 'copy'}
        showCancel
        showUpdate={mode === 'edit'}
        setValue={this.setValue}
        setValueDrop={this.setValueDrop}
        onSubmitCreate={this.createResupplyDeposits}
        onSubmitUpdate={this.updateResupplyDeposits}
        breadcrumb={breadcrumb}
        inputs={(formContext) => ([
          formContext.createSelectDropDown(
            { value: branchCode, label: branchName },
            'branch',
            `${I18n.t('BEE145' /* Filial */)}:`,
            listBranch,
            disableFields,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
          ),
          formContext.createSelectDropDown(
            { value: depositWithdrawCode, label: depositWithdrawName },
            'depositWithdraw',
            `${I18n.t('BEE1795' /* Depósito Retirada */)}:`,
            listDeposit,
            disableFields,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
          ),
          formContext.createSelectDropDown(
            { value: depositCode, label: depositName },
            'deposit',
            `${I18n.t('BEE1796' /* Depósito Entrada */)}:`,
            listDeposit,
            disableFields,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
          ),
        ])}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  createResupplyDeposits: (resupplyDeposits) => dispatch(createResupplyDeposits(resupplyDeposits)),
  updateResupplyDeposits: (resupplyDeposits) => dispatch(updateResupplyDeposits(resupplyDeposits)),
  getDepositsOptions: () => dispatch(getDepositsOptions()),
  getBranchesOptions: () => dispatch(getBranchesOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResupplyDepositsForm));
