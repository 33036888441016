import inventorySheetSerialLogRequests from '../../server/inventorySheetSerialLog';
import { addLoading, removeLoading } from './loading';

export const
  getListSheetSerialLogs = (filterStartDate, filterEndDate, page, pageSize, sorted, filtered) => async (dispatch) => {
    dispatch(addLoading());
    try {
      return await inventorySheetSerialLogRequests
        .getListSheetSerialLogs(filterStartDate, filterEndDate, page, pageSize, sorted, filtered);
    } finally {
      dispatch(removeLoading());
    }
  };

export default {
  getListSheetSerialLogs,
};
