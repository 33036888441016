import RestService from '../services/rest';

// GET
async function getStockGroups() {
  return RestService.getAuthenticated('stockGroup/list/all');
}

async function getStockGroup(stockGroupId) {
  return RestService.getAuthenticated('stockGroup/detail', { stockGroupId });
}

async function getStockGroupOptions() {
  return RestService.getAuthenticated('stockGroup/list/options');
}

// POST
async function createStockGroup(stockGroup) {
  return RestService.postAuthenticated('stockGroup/create', stockGroup);
}

async function deleteStockGroup(stockGroupId) {
  return RestService.postAuthenticated('stockGroup/delete', { stockGroupId });
}

// PUT
async function updateStockGroup(stockGroup) {
  return RestService.putAuthenticated('stockGroup/update', stockGroup);
}

export default {
  getStockGroups,
  createStockGroup,
  updateStockGroup,
  getStockGroup,
  getStockGroupOptions,
  deleteStockGroup,
};
