import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getPrintersList, deletePrinter, getUsersPrinter } from '../../../../app/store/actions/printers';

import PrinterHelpers from '../../../../helpers/printer';

class PrinterList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      printerUpdate: null,
      newModal: false,
      userInformations: [],
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showPrinterDetail(rows.row)}>
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showEditPrinter(rows.row)}>
                  {I18n.t('BEE57' /* Editar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showCopyPrinter(rows.row)}>
                  {I18n.t('BEE80' /* Copiar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showDeletePrinter(rows.row)}>
                  {I18n.t('BEE81' /* Eliminar */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE322' /* Localização */),
        accessor: 'location',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'type',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (rows) => (
          <span>
            {PrinterHelpers.printerType(rows.row.type)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const type = row[filter.id];
          const value = _.lowerCase(PrinterHelpers.printerType(type));

          if (_.includes(value, input)) {
            return true;
          }
        },
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
    }

    await this.getPrinters();
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      printerUpdate: null,
      newModal: false,
    });
  };

  getPrinters = async () => {
    await this.props.getPrintersList();
  };

  deletePrinter = async (printer) => {
    const result = await this.props.deletePrinter(printer.id);

    this.setInitState();
    if (result) {
      addNotification(
        'success',
        I18n.t('BEE323' /* Eliminar Impressora */),
        I18n.t('BEE324', { 0: printer.code } /* Impressora %{0} eliminada com sucesso! */),
        'top-right',
      );
    }
    await this.getPrinters();
  };

  showPrinterDetail = (printer) => {
    this.props.history.push({
      pathname: ROUTES.PRINTER_DETAIL,
      state: {
        printer: printer._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditPrinter = (printer) => {
    this.props.history.push({
      pathname: ROUTES.PRINTER_EDIT,
      state: {
        printer: printer._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyPrinter = (printer) => {
    this.props.history.push({
      pathname: ROUTES.PRINTER_COPY,
      state: {
        printer: printer._original,
      },
    });
  };

  showDeletePrinter = async (printerId) => {
    const { id, code } = printerId._original;
    const userPrinters = await this.props.getUsersPrinter(printerId.code);
    const userInformations = userPrinters && userPrinters.printer.length > 0 ? userPrinters.printer : null;

    if (userInformations) {
      await this.setState({
        showModalDelete: false, printerUpdate: { id, code }, newModal: true, userInformations,
      });
    } else {
      await this.setState({ showModalDelete: true, printerUpdate: { id, code }, newModal: false });
    }
  };

  render() {
    const {
      printerUpdate, showModalDelete, page, newModal, userInformations,
    } = this.state;
    const { printersList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE1396' /* Equipamentos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE21' /* Impressoras */)}</li>
          </ol>
        </div>
        <Table
          wikiHelp={ROUTES.PRINTER_HELP}
          downloadCSV
          headerTitle={I18n.t('BEE21' /* Impressoras */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.PRINTER_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE325' /* Incluir Impressora */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getPrinters,
          }}
          filterable
          data={printersList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun
            && this.props.location.state
            && this.props.location.state.dice
            && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(p) => ((p >= 0) ? this.setState({ page: p }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {showModalDelete && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deletePrinter(printerUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE326', { 0: printerUpdate.code } /* A impressora %{0} será eliminada! */)}
          </SweetAlert>
        )}

        {newModal && (
          <SweetAlert
            danger
            title={I18n.t('BEE2457' /* Não é possível excluir a impressora pois está associada aos usuários: */)}
            onConfirm={() => this.setInitState()}
          >
            {userInformations.map((element) => (
              <p>{element.user.login}</p>
            ))}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  printersList: state.printers && state.printers.printersList,
  getUsersPrinter: state.getUsersPrinter,
});

const mapDispatchToProps = (dispatch) => ({
  getPrintersList: () => dispatch(getPrintersList()),
  deletePrinter: (printerId) => dispatch(deletePrinter(printerId)),
  getUsersPrinter: (printerId) => dispatch(getUsersPrinter(printerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrinterList));
