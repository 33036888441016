import React from 'react';
import Select, { createFilter } from 'react-select';
import { FixedSizeList as List } from 'react-window';

function FilterSelectionList({
  value, onChangeValue, placeholderValue, optionsValue, onClickValue,
}) {
  const selectionStyle = {
    control: (defaultStyles) => ({
      ...defaultStyles,
      minHeight: 36,
      /* maxHeight: 36, */
      width: '300px',
      maxWidth: '300px',
      border: 0,
      boxShadow: 'none',
      color: '#FFF',
      backgroundColor: '#2d353c',
      fontWeight: '600',
      borderRadius: '5px 0px 0px 5px',
      outline: 'none',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: '#ffffff',
    }),
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      paddingTop: 0,
      paddingBottom: 0,
      color: '#ffffff',
    }),
    clearIndicator: (defaultStyles) => ({
      ...defaultStyles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return (
    <>
      <Select
        value={value}
        isMulti
        onChange={(event) => onChangeValue(event || [])}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        isSearchable
        styles={selectionStyle}
        maxMenuHeight={300}
        placeholder={placeholderValue}
        options={optionsValue}
        menuPortalTarget={document.body}
      />
      <div style={{ borderRadius: '0px 5px 5px 0px', backgroundColor: '#2d353c', maxHeight: 36 }}>
        <button
          className="h-100 border-0 bg-transparent"
          style={{ fontSize: '22px' }}
          type="button"
          onClick={onClickValue}
        >
          <i className="fa fa-search text-white" style={{ fontSize: '20px' }} />
        </button>
      </div>
    </>

  );
}

export default FilterSelectionList;
