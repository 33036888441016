import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';

import OneClickButton from '../../../../components/form/button';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createDeposit, updateDeposit, getDeposit,
} from '../../../../app/store/actions/deposits';
import Form from '../../../../components/form/form';

class DepositForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      mode: 'detail',
      codeWms: '',
      nameWms: '',
      codeErp: '',
      nameErp: '',
      resale: false,
      inbound: false,
      inventory: false,
      divergence: false,
      rejected: false,
      damage: false,
      quality: false,
      spare: false,
      intermediary: false,
      fractionalLabel: false,
      factory: false,
      serialLabel: false,
      allocateBalance: false,
      note: '',
      disableCode: true,
      disableCodeErp: true,
      disableName: true,
      disableResale: true,
      disableInbound: true,
      disableInventory: true,
      disableDivergence: true,
      disableSeparationDivergence: true,
      disableRejected: true,
      disableDamage: true,
      disableQuality: true,
      disableSpare: true,
      disableIntermediary: true,
      disableFractionalLabel: true,
      disableFactory: true,
      disableSerialLabel: true,
      disableAllocate: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      separationDivergence: false,
      activeTab: '1',
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getDepositSelected();
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  setMode = () => {
    let mode;
    if (this.props.isCreate) mode = 'create';
    else if (this.props.isCopy) mode = 'copy';
    else if (this.props.isEdit) mode = 'edit';
    else if (this.props.isDetail) mode = 'detail';
    else mode = '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableCodeErp: false,
        disableResale: false,
        disableInbound: false,
        disableInventory: false,
        disableDivergence: false,
        disableSeparationDivergence: false,
        disableRejected: false,
        disableDamage: false,
        disableQuality: false,
        disableSpare: false,
        disableIntermediary: false,
        disableFractionalLabel: false,
        disableFactory: false,
        disableSerialLabel: false,
        disableAllocate: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableCodeErp: false,
        disableResale: false,
        disableInbound: false,
        disableInventory: false,
        disableDivergence: false,
        disableSeparationDivergence: false,
        disableRejected: false,
        disableDamage: false,
        disableQuality: false,
        disableSpare: false,
        disableIntermediary: false,
        disableFractionalLabel: false,
        disableFactory: false,
        disableSerialLabel: false,
        disableAllocate: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <span className="col-form-label col-md-4">{label}</span>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createToggleBox = (item, attr, label, disabled) => (
    <div className="form-group row m-b-15">
      <label htmlFor={label} className="col-form-label col-md-5">{}</label>
      <div className="col-md-0">
        <div className="row">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={this.handleInputChange}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`}>{label}</label>
          </div>
        </div>
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  getDepositSelected = async () => {
    if (this.props.location.state.deposit) {
      const { deposit } = this.props.location.state;

      const depositSelected = await this.props.getDeposit(deposit.id);

      if (depositSelected) {
        this.setState({
          depositId: depositSelected.id,
          codeWms: depositSelected.code,
          nameWms: depositSelected.name,
          codeErp: depositSelected.codeErp,
          nameErp: depositSelected.nameErp,
          note: depositSelected.note,
          resale: depositSelected.resale,
          inbound: depositSelected.inbound,
          inventory: depositSelected.inventory,
          divergence: depositSelected.divergence,
          separationDivergence: depositSelected.separationDivergence,
          rejected: depositSelected.rejected,
          damage: depositSelected.damage,
          quality: depositSelected.quality,
          spare: deposit.spare,
          intermediary: deposit.intermediary,
          fractionalLabel: deposit.fractionalLabel,
          factory: deposit.factory,
          serialLabel: deposit.serialLabel,
          allocateBalance: depositSelected.allocateBalance,
        });
      }
    }
  };

  createDepositSubmit = async () => {
    const {
      codeWms, nameWms, codeErp, nameErp, allocateBalance, note, resale, inbound, inventory,
      divergence, rejected, damage, quality, spare, intermediary, fractionalLabel,
      factory, serialLabel, separationDivergence,
    } = this.state;

    try {
      const newDeposit = await this.props.createDeposit({
        code: codeWms,
        name: nameWms,
        codeErp,
        nameErp,
        resale,
        inbound,
        inventory,
        divergence,
        separationDivergence,
        rejected,
        damage,
        quality,
        spare,
        intermediary,
        fractionalLabel,
        factory,
        serialLabel,
        allocateBalance,
        note,
      });

      if (newDeposit) {
        await addNotification(
          'success',
          I18n.t('BEE182' /* Depósito */),
          I18n.t('BEE183', { 0: codeWms } /* Depósito %{0} incluído com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.DEPOSIT_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE182' /* Depósito */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE182' /* Depósito */),
            I18n.t('BEE184', { 0: codeWms } /* Erro ao incluir o Depósito %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE182' /* Depósito */),
          I18n.t('BEE184', { 0: codeWms } /* Erro ao incluir o Depósito %{0}! */),
          'top-right',
        );
      }
    }
  };

  updateDepositSubmit = async () => {
    const {
      depositId, codeWms, allocateBalance, nameWms, note, resale, inbound, inventory, divergence,
      rejected, damage, quality, spare, intermediary, fractionalLabel, factory, serialLabel,
      separationDivergence, codeErp, nameErp,
    } = this.state;

    try {
      const updDeposit = await this.props.updateDeposit({
        depositId,
        name: nameWms,
        resale,
        inbound,
        inventory,
        divergence,
        separationDivergence,
        rejected,
        damage,
        quality,
        spare,
        intermediary,
        fractionalLabel,
        factory,
        serialLabel,
        allocateBalance,
        note,
        codeErp,
        nameErp,
      });

      if (updDeposit) {
        await addNotification(
          'success',
          I18n.t('BEE185' /* Atualizar Depósito */),
          I18n.t('BEE186', { 0: codeWms } /* Atualização do Depósito %{0} efetuada com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.DEPOSIT_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE185' /* Atualizar Depósito */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE185' /* Atualizar Depósito */),
            I18n.t('BEE187', { 0: codeWms } /* Erro ao atualizar o Depósito %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE185' /* Atualizar Depósito */),
          I18n.t('BEE187', { 0: codeWms } /* Erro ao atualizar o Depósito %{0}! */),
          'top-right',
        );
      }
    }
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      codeWms, nameWms, codeErp, nameErp, allocateBalance, note, resale, inbound, inventory, divergence,
      rejected, damage, quality, spare, intermediary, fractionalLabel, factory, serialLabel,
    } = this.state;

    const {
      mode, disableCode, disableName, disableCodeErp, disableAllocate, disableNote, disableResale, disableInbound,
      disableInventory, disableDivergence, disableRejected, disableDamage, disableQuality, disableSpare,
      showCancel, showUpdate, showCreate, disableIntermediary, disableFractionalLabel, disableFactory,
      disableSerialLabel, separationDivergence, disableSeparationDivergence,
    } = this.state;

    let title = '';
    //  let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE188' /* Novo Depósito */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE189' /* Editar Depósito */);
      //    breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE190' /* Detalhes Depósito */);
      //     breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE18' /* Depósitos */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel className="panel-with-tabs">
                <PanelHeader noButton />
                <PanelBody>
                  <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '1' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('1');
                        }}
                      >
                        <span className="d-sm-none">Tab 1</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE191' /* Geral */)}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '2' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('2');
                        }}
                      >
                        <span className="d-sm-none">Tab 2</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE2562' /* Tipos */)}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '3' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('3');
                        }}
                      >
                        <span className="d-sm-none">Tab 3</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE439' /* Parâmetros */)}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <Form
                        noPanel
                        setValue={this.setValue}
                        inputs={(formContext) => ([
                          formContext.createInput(
                            codeWms,
                            'codeWms',
                            `${I18n.t('BEE4057', { 0: I18n.t('BEE174' /* WMS */) } /* Código (%{0}) */)}:`,
                            I18n.t(
                              'BEE4058',
                              { 0: I18n.t('BEE174' /* WMS */) }, /* Informe o código do depósito no %{0} */
                            ),
                            'text',
                            true,
                            disableCode,
                          ),
                          formContext.createInput(
                            nameWms,
                            'nameWms',
                            `${I18n.t('BEE4059', { 0: I18n.t('BEE174' /* WMS */) } /* Nome (%{0}) */)}:`,
                            I18n.t(
                              'BEE4062',
                              { 0: I18n.t('BEE174' /* WMS */) }, /* Informe o nome do depósito no %{0} */
                            ),
                            'text',
                            true,
                            disableName,
                          ),
                          formContext.createInput(
                            codeErp,
                            'codeErp',
                            `${I18n.t('BEE4057', { 0: I18n.t('BEE4061' /* ERP */) } /* Código (%{0}) */)}:`,
                            I18n.t(
                              'BEE4058',
                              { 0: I18n.t('BEE4061' /* ERP */) }, /* Informe o código do depósito no %{0} */
                            ),
                            'text',
                            true,
                            disableCodeErp,
                          ),
                          formContext.createInput(
                            nameErp,
                            'nameErp',
                            `${I18n.t('BEE4059', { 0: I18n.t('BEE4061' /* ERP */) } /* Nome (%{0}) */)}:`,
                            I18n.t(
                              'BEE4062',
                              { 0: I18n.t('BEE4061' /* ERP */) }, /* Informe o nome do depósito no %{0} */
                            ),
                            'text',
                            true,
                            disableName,
                          ),
                          formContext.createInput(
                            note,
                            'note',
                            `${I18n.t('BEE135' /* Observação */)}:`,
                            I18n.t('BEE136' /* Informe a observação */),
                            'text',
                            false,
                            disableNote,
                          ),
                        ])}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      {this.createToggleBox(
                        allocateBalance,
                        'allocateBalance',
                        I18n.t('BEE171' /* Aloca Saldo */),
                        disableAllocate,
                      )}
                      {this.createToggleBox(
                        inbound,
                        'inbound',
                        I18n.t('BEE36' /* Recebimento */),
                        disableInbound,
                      )}
                      {this.createToggleBox(
                        resale,
                        'resale',
                        I18n.t('BEE571' /* Revenda */),
                        disableResale,
                      )}
                      {this.createToggleBox(
                        inventory,
                        'inventory',
                        I18n.t('BEE572' /* Inventário */),
                        disableInventory,
                      )}
                      {this.createToggleBox(
                        divergence,
                        'divergence',
                        I18n.t('BEE3588' /* Divergência no Recebimento */),
                        disableDivergence,
                      )}
                      {this.createToggleBox(
                        separationDivergence,
                        'separationDivergence',
                        I18n.t('BEE3586' /* Divergência na Separação */),
                        disableSeparationDivergence,
                      )}
                      {this.createToggleBox(
                        damage,
                        'damage',
                        I18n.t('BEE573' /* Avaria */),
                        disableDamage,
                      )}
                      {this.createToggleBox(
                        rejected,
                        'rejected',
                        I18n.t('BEE177' /* Rejeitados */),
                        disableRejected,
                      )}
                      {this.createToggleBox(
                        quality,
                        'quality',
                        I18n.t('BEE574' /* Qualidade */),
                        disableQuality,
                      )}
                      {this.createToggleBox(
                        spare,
                        'spare',
                        I18n.t('BEE789' /* Sobra */),
                        disableSpare,
                      )}
                      {this.createToggleBox(
                        intermediary,
                        'intermediary',
                        I18n.t('BEE2832' /* Intermediário */),
                        disableIntermediary,
                      )}
                      {this.createToggleBox(
                        factory,
                        'factory',
                        I18n.t('BEE2873' /* Fábrica */),
                        disableFactory,
                      )}
                    </TabPane>
                    <TabPane tabId="3">
                      {this.createToggleBox(
                        fractionalLabel,
                        'fractionalLabel',
                        I18n.t('BEE2988' /* Fraciona Etiqueta */),
                        disableFractionalLabel,
                      )}

                      {this.createToggleBox(
                        serialLabel,
                        'serialLabel',
                        I18n.t('BEE2830' /* Etiquetas Seriadas */),
                        disableSerialLabel,
                      )}
                    </TabPane>
                  </TabContent>
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.DEPOSIT_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE137' /* Voltar */)}
                        </OneClickButton>
                      ))}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createDepositSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}

                    </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateDepositSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}

                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getDeposit: (depositId) => dispatch(getDeposit(depositId)),
  createDeposit: (deposit) => dispatch(createDeposit(deposit)),
  updateDeposit: (deposit) => dispatch(updateDeposit(deposit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DepositForm));
