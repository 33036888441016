import RestService from '../services/rest';

async function getDataGrid(tableParams) {
  return RestService.postAuthenticated(
    'outboundMonitor/list', tableParams
  );
}


async function toggleOrderPriority(orderId, docUrgent) {
  return RestService.postAuthenticated(
    'outboundMonitor/toggleOrderPriority', { orderId, docUrgent }
  );
}

async function allocatedProducts(outboundRangeId) {
  return RestService.getAuthenticated('outboundMonitor/allocatedProducts', { outboundRangeId });
}

async function updateOutboundRange(outboundRangeId, fieldsOutboundRange = {}) {
  return RestService.putAuthenticated('outboundMonitor/update', { outboundRangeId, fieldsOutboundRange });
}

async function updateOutboundRangeProduct(outboundRangeProductId, fieldsOutboundRangeProduct = {}) {
  return RestService.putAuthenticated('outboundMonitor/update/outboundRangeProduct', { outboundRangeProductId, fieldsOutboundRangeProduct });
}

export default {
  getDataGrid,
  toggleOrderPriority,
  allocatedProducts,
  updateOutboundRange,
  updateOutboundRangeProduct
}