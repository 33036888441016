import SerialLabelRequests from '../../server/serialLabel';
import { addLoading, removeLoading } from './loading';

export const
  getListSerialLabels = (filterStartDate, filterEndDate, page, pageSize, sorted, filtered) => async (dispatch) => {
    dispatch(addLoading());
    try {
      return await SerialLabelRequests
        .getListSerialLabels(filterStartDate, filterEndDate, page, pageSize, sorted, filtered);
    } finally {
      dispatch(removeLoading());
    }
  };

export const
  createSerialLabels = (
    productionOrderId,
    quantityToPrint,
    printerSelected,
    expirationDate,
    typeLabel,
  ) => async (dispatch) => {
    dispatch(addLoading());
    try {
      return await SerialLabelRequests.createSerialLabels(
        productionOrderId,
        quantityToPrint,
        printerSelected,
        expirationDate,
        typeLabel,
      );
    } finally {
      dispatch(removeLoading());
    }
  };

export const getProductionOrderSerialLabels = (orderCode, page, pageSize, sorted, filtered) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SerialLabelRequests.getProductionOrderSerialLabels(orderCode, page, pageSize, sorted, filtered);
  } finally {
    dispatch(removeLoading());
  }
};

export default {
  getListSerialLabels,
};
