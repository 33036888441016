import { I18n } from 'react-redux-i18n';
import RestService from '../services/rest';

// POST
async function getListAndCountAllResupply(searchParameters) {
  return RestService.postAuthenticated('resupply/listAndCountAll', searchParameters);
}

async function getListPickingAddressToResupply(filter) {
  return RestService.postAuthenticated('resupply/pickingAddressToResupply/list', { filter });
}

async function createResupply(addressPicking, tableFilter) {
  const response = await RestService.postAuthenticated('resupply/create', { addressPicking, tableFilter }, {
    'Content-Type': 'application/json',
    Accept: 'application/xlsx',
  }, { responseType: 'arraybuffer' });

  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  const Now = new Date();
  const filePath = `${I18n.t('BEE2642' /* Ressuprimento */)}-${
    Now.getDate()}-${Now.getMonth()}-${Now.getFullYear()}-${
    Now.getHours()}-${Now.getMinutes()}-${Now.getSeconds()}.xlsx`;
  link.setAttribute('download', filePath);
  document.body.appendChild(link);
  link.click();
}

// PUT
async function updateResupply(resupplyId, updateData) {
  return RestService.putAuthenticated('resupply/update', { resupplyId, updateData });
}

async function updateCancelResupply(resupplyId) {
  return RestService.putAuthenticated('resupply/cancelResupply', { resupplyId });
}

export default {
  getListPickingAddressToResupply,
  getListAndCountAllResupply,
  updateResupply,
  createResupply,
  updateCancelResupply,
};
