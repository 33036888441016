import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';
import moment from 'moment';

import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../components/panel/panel';
import ProductsList from './productsList';

import ROUTES from '../../../config/routes';

import OutboundOrderHelpers from '../../../helpers/outboundOrder';

import {
  getOutboundOrder,
} from '../../../app/store/actions/outboundOrders';

class OutboundOrderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      branchCode: '',
      branchName: '',
      customerCode: '',
      customerName: '',
      orderType: '',
      orderNumber: '',
      urgent: '',
      partialOrder: '',
      carrierCode: '',
      carrierName: '',
      status: '',
      deliveryDate: '',
      deliveryType: '',
      customerOrderCode: '',
      orderDate: '',
      totalValue: '',
      orderValue: '',
      shipToCustomerCode: '',
      shipToCustomerName: '',
      shipToPostalCode: '',
      shipToStreet: '',
      shipToNumber: '',
      shipToComplement: '',
      shipToDistrict: '',
      shipToCity: '',
      shipToState: '',
      shipToCountry: '',
      note: '',
      products: [],
      productionOrder: '',
    };
  }

  async componentDidMount() {
    await this.getOutboundOrderSelected();
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  getOutboundOrderSelected = async () => {
    if (this.props.location.state.outboundOrder) {
      const { outboundOrder } = this.props.location.state;

      const outboundOrderSelected = await this.props.getOutboundOrder(outboundOrder.id);

      if (outboundOrderSelected) {
        this.setState({
          branchCode: outboundOrderSelected.branchCode,
          branchName: outboundOrderSelected.branchName,
          customerCode: outboundOrderSelected.customerCode,
          customerName: outboundOrderSelected.customerName,
          orderType: outboundOrderSelected.orderType,
          orderNumber: outboundOrderSelected.orderNumber,
          urgent: outboundOrderSelected.urgent,
          partialOrder: outboundOrderSelected.partialOrder,
          carrierCode: outboundOrderSelected.carrierCode || '',
          carrierName: outboundOrderSelected.carrierName || '',
          status: outboundOrderSelected.status,
          deliveryDate: outboundOrderSelected.deliveryDate,
          deliveryType: outboundOrderSelected.deliveryType,
          customerOrderCode: outboundOrderSelected.customerOrderCode,
          orderDate: outboundOrderSelected.orderDate,
          totalValue: outboundOrderSelected.totalValue,
          orderValue: outboundOrderSelected.orderValue,
          shipToCustomerCode: outboundOrderSelected.shipToCustomerCode,
          shipToCustomerName: outboundOrderSelected.shipToCustomerName,
          shipToPostalCode: outboundOrderSelected.shipToPostalCode,
          shipToStreet: outboundOrderSelected.shipToStreet,
          shipToNumber: outboundOrderSelected.shipToNumber,
          shipToDistrict: outboundOrderSelected.shipToDistrict,
          shipToCity: outboundOrderSelected.shipToCity,
          shipToState: outboundOrderSelected.shipToState,
          shipToCountry: outboundOrderSelected.shipToCountry,
          note: outboundOrderSelected.note,
          products: outboundOrderSelected.products,
          productionOrder: outboundOrderSelected.productionOrder ? outboundOrderSelected.productionOrder.code : '',
        });
      }
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={label} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group row m-b-15">
      <label htmlFor={label} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  handleRouteToReturn = () => {
    if (this.props.location.state.dashboardRoute) {
      return {
        pathname: ROUTES.OUTBOUND_ORDERS_DASHBOARD,
        state: {
          isRouteReturn: true,
        },
      };
    }

    if (this.props.location.state.dashboardAverageTime) {
      return {
        pathname: ROUTES.DASHBOARD_OUTBOUND_AVERAGE_TIME,
        state: {
          isRouteReturn: true,
        },
      };
    }

    if (this.props.location.state.dashboardOverview) {
      return {
        pathname: ROUTES.OUTBOUND_ORDERS_DASHBOARD_OVERVIEW,
        state: {
          isRouteReturn: true,
        },
      };
    }

    if (this.props.isConsult) {
      return ROUTES.CONSULT_OUTBOUND_ORDER_LIST;
    }

    return ROUTES.OUTBOUND_ORDER_LIST;
  };

  render() {
    const {
      branchCode, branchName, customerCode, customerName, orderType, orderNumber, urgent, partialOrder,
      carrierCode, carrierName, status, deliveryDate, deliveryType, customerOrderCode, orderDate, totalValue,
      shipToCustomerCode, shipToCustomerName, shipToPostalCode, shipToStreet, shipToNumber, productionOrder,
      shipToComplement, shipToDistrict, shipToCity, shipToState, shipToCountry, note, products = [], orderValue,
    } = this.state;
    const { isConsult = false } = this.props;

    const title = I18n.t('BEE1094' /* Detalhes documento de saída */);

    return (
      <div className="slideUpTransition">
        {this.props.location && this.props.location.state && this.props.location.state.breadcrumb ? (
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              {this.props.location.state.breadcrumb.map((it, index) => {
                if (index === 0) {
                  return (
                    <li key={`${it.value}${index}`} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                      <i className={it.iconClassName || 'fas fa-home fa-fw m-t-10 m-r-5'} />
                      {' '}
                      {it.value}
                    </li>
                  );
                }
                return (
                  <li key={`${it.value}${index}`} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                    {it.value}
                  </li>
                );
              })}
            </ol>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              <li className="breadcrumb-item">
                <i className="fas fa-home fa-fw m-t-10 m-r-5" />
                {' '}
                {I18n.t('BEE12' /* Início */)}
              </li>
              <li className="breadcrumb-item">
                {(isConsult) ? I18n.t('BEE33' /* Consultas */) : I18n.t('BEE1400' /* Movimentação */)}
              </li>
              <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
              <li className="breadcrumb-item active">{I18n.t('BEE1087' /* Documentos de saída */)}</li>
            </ol>
          </div>
        )}
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel className="panel-with-tabs">
                <PanelHeader noButton />
                <PanelBody>
                  <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '1' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('1');
                        }}
                      >
                        <span className="d-sm-none">Tab 1</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE443' /* Documento */)}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '2' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('2');
                        }}
                      >
                        <span className="d-sm-none">Tab 2</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE27' /* Produtos */)}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="row">
                        <div className="col-xl-12">
                          {this.createInput(
                            `${branchCode} - ${branchName}`,
                            'branchCode',
                            `${I18n.t('BEE145' /* Filial */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            `${customerCode} - ${customerName}`,
                            'customerCode',
                            'Cliente:',
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            orderType,
                            'orderType',
                            `${I18n.t('BEE200' /* Tipo */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            orderNumber,
                            'orderNumber',
                            `${I18n.t('BEE1027' /* Número do documento */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            `${OutboundOrderHelpers.outboundOrderStatus(status)}`,
                            'status',
                            `${I18n.t('BEE224' /* Status */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            urgent,
                            'urgent',
                            `${I18n.t('BEE613' /* Urgente */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            partialOrder,
                            'partialOrder',
                            `${I18n.t('BEE1095' /* Pedido parcial */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            `${carrierCode} - ${carrierName}`,
                            'carrierCode',
                            `${I18n.t('BEE301' /* Transportadora */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            customerOrderCode,
                            'customerOrderCode',
                            `${I18n.t('BEE1096' /* Pedido cliente */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            `${moment(orderDate).format('DD/MM/YYYY')}`,
                            'orderDate',
                            `${I18n.t('BEE1097' /* Data pedido */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            `${moment(deliveryDate).format('DD/MM/YYYY')}`,
                            'deliveryDate',
                            `${I18n.t('BEE444' /* Data de entrega */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            deliveryType,
                            'deliveryType',
                            `${I18n.t('BEE1098' /* Tipo de entrega */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            `${!isNaN(parseFloat(totalValue)) ? parseFloat(totalValue).toFixed(2) : ''}`,
                            'totalValue',
                            `${I18n.t('BEE1099' /* Total do pedido */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            `${!isNaN(parseFloat(orderValue)) ? parseFloat(orderValue).toFixed(2) : ''}`,
                            'totalValue',
                            `${I18n.t('BEE3921' /* Total da Mercadoria */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            shipToCustomerCode,
                            'shipToCustomerCode',
                            `${I18n.t('BEE1100' /* Cliente de entrega */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            shipToCustomerName,
                            'shipToCustomerName',
                            `${I18n.t('BEE1101' /* Nome entrega */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            shipToPostalCode,
                            'shipToPostalCode',
                            `${I18n.t('BEE1102' /* CEP Entrega */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            shipToStreet,
                            'shipToStreet',
                            `${I18n.t('BEE1103' /* Rua entrega */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            shipToNumber,
                            'shipToNumber',
                            `${I18n.t('BEE1104' /* Número entrega */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            shipToComplement,
                            'shipToComplement',
                            `${I18n.t('BEE1105' /* Complemento entrega */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            shipToDistrict,
                            'shipToDistrict',
                            `${I18n.t('BEE1106' /* Bairro entrega */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            shipToCity,
                            'shipToCity',
                            `${I18n.t('BEE1107' /* Cidade entrega */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            shipToState,
                            'shipToState',
                            `${I18n.t('BEE1108' /* Estado entrega */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createInput(
                            shipToCountry,
                            'shipToCountry',
                            `${I18n.t('BEE1109' /* País entrega */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                          {this.createTextArea(
                            note,
                            'note',
                            `${I18n.t('BEE135' /* Observação */)}:`,
                            '',
                            3,
                            true,
                            true,
                          )}
                          {this.createInput(
                            productionOrder,
                            'productionOrder',
                            `${I18n.t('BEE2770' /* Ordem de Produção */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )}
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="row">
                        <div className="col-xl-12">
                          <ProductsList
                            products={products}
                          />
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </PanelBody>
                <PanelFooter>
                  <Link to={this.handleRouteToReturn}>
                    <button type="submit" className="btn btn-120 btn-white p-5 m-5">
                      {I18n.t('BEE137' /* Voltar */)}
                    </button>
                  </Link>
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getOutboundOrder: (outboundOrderId) => dispatch(getOutboundOrder(outboundOrderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundOrderForm));
