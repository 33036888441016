import DocksRequests from '../../server/dockCarriers';
import { addLoading, removeLoading } from './loading';

export const getCarrierDocks = (carrierCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const docks = await DocksRequests.getCarrierDocks(carrierCode);
    return docks
  } finally {
    dispatch(removeLoading());
  }
};

export const getDockCarriers = (dockCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const docks = await DocksRequests.getDockCarriers(dockCode);
    return docks
  } finally {
    dispatch(removeLoading());
  }
};

export const searchDocks = (searchParams) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const docks = await DocksRequests.searchDocks(searchParams);
    return docks
  } finally {
    dispatch(removeLoading());
  }
};

export const searchCarriers = (searchParams) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const docks = await DocksRequests.searchCarriers(searchParams);
    return docks
  } finally {
    dispatch(removeLoading());
  }
};

export const linkDockCarrier = (carrierCode, dockCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const result = await DocksRequests.linkDockCarrier(carrierCode, dockCode);
    return result
  } finally {
    dispatch(removeLoading());
  }
};

export const unlinkDockCarrier = (dockCarrierId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const result = await DocksRequests.unlinkDockCarrier(dockCarrierId);
    return result
  } finally {
    dispatch(removeLoading());
  }
};