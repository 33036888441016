import RestService from '../services/rest';

// GET
async function getWorkShift(workShiftId) {
  return RestService.getAuthenticated(`workshifts/detail?workShiftId=${workShiftId}`);
}

async function getWorkShifts() {
  return RestService.getAuthenticated('workshifts/list/all');
}

async function getWorkShiftsOptions() {
  return RestService.getAuthenticated('workshifts/list/options');
}

// POST
async function createWorkShift(workShift) {
  return RestService.postAuthenticated('workshifts/create', workShift);
}

// PUT
async function updateWorkShift(workShift) {
  return RestService.putAuthenticated('workshifts/update', workShift);
}

async function deleteWorkShift(workShiftId) {
  return RestService.postAuthenticated('workshifts/delete', { workShiftId });
}

export default {
  createWorkShift,
  getWorkShift,
  getWorkShifts,
  getWorkShiftsOptions,
  updateWorkShift,
  deleteWorkShift,
};
