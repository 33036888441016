import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import moment from 'moment';
import Table from '../../../components/table/Table';
import OneClickButton from '../../../components/form/button';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../components/panel/panel';
import addNotification from '../../../components/notification';

import { generateWaves, getOutboundOrdersList } from '../../../app/store/actions/outboundWaves';

import ROUTES from '../../../config/routes';

class OutboundWaveForm extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.tableColumns = [
      {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branch.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customer.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
      }, {
        Header: I18n.t('BEE85' /* CNPJ */),
        accessor: 'customer.cnpj',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      },
    ];

    this.state = {
      startDate: moment().subtract(7, 'day'),
      endDate: moment(),
      currentRange: `${moment().subtract(7, 'day').format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`,
      pages: 0,
      ordersList: [],
      selectedOrdersList: [],
      totalDataLength: 0,
    };
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled, keypressFunction = undefined, id = null) => (
    <div className="form-group p-2">
      <label>{label}</label>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        { !disabled && (
        <div className="input-group-append">
          <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, '')}>
            <i className="fa fa-times" />
          </button>
        </div>
        )}
      </div>
    </div>
  );

  setValue = (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  handleDateApplyEvent = async (event, picker) => {
    const { startDate } = picker;
    const { endDate } = picker;

    const currentRange = `${startDate.format('DD MMM YYYY')} - ${endDate.format('DD MMM YYYY')}`;

    this.setState({
      startDate,
      endDate,
      currentRange,
    });

    await this.getOrderList();
  };

  getOrderList = async () => {
    try {
      const {
        page, pageSize, sorted, filtered,
      } = this;

      const startDate = moment(this.state.startDate).format();
      const endDate = moment(this.state.endDate).format();

      const tableParams = {
        page,
        pageSize,
        sorted,
        filtered,
        startDate,
        endDate,
      };

      const list = await this.props.getOutboundOrdersList(tableParams);

      const pages = Math.ceil(list.pages / pageSize);
      const ordersList = list ? list.rows : [];

      this.setState({
        pages,
        ordersList,
        totalDataLength: list.totalDataLength,
      });
    } catch (error) {
      console.log(error);
    }
  };

  selectOrder = (order) => {
    const selectedOrdersList = [...this.state.selectedOrdersList];
    const repeatedOrder = selectedOrdersList.find((o) => o.branchCode === order.branchCode && o.orderNumber === order.orderNumber);

    if (repeatedOrder) {
      addNotification('danger', I18n.t('BEE1445' /* Gerar Onda */), I18n.t('BEE1450', { 0: order.orderNumber } /* Documento %{0} já selecionado! */), 'top-right');
      return;
    }

    selectedOrdersList.push(order);
    this.setState({
      selectedOrdersList,
    });
  };

  deselectOrder = (order) => {
    const selectedOrdersList = [...this.state.selectedOrdersList];
    const orderIndex = selectedOrdersList.indexOf(order);
    selectedOrdersList.splice(orderIndex, 1);
    this.setState({
      selectedOrdersList,
    });
  };

  clearSelection = () => {
    this.setState({
      selectedOrdersList: [],
    });
  };

  generateWaves = async () => {
    try {
      const { selectedOrdersList } = this.state;

      const outboundOrderIds = selectedOrdersList.map((o) => o.id);

      if (outboundOrderIds.length < 2) {
        addNotification('danger', I18n.t('BEE1445' /* Gerar Onda */), I18n.t('BEE1444' /* Informe pelo menos um documento! */), 'top-right');
        return;
      }

      const result = await this.props.generateWaves(outboundOrderIds);

      if (result.waveNumber) {
        addNotification('success', I18n.t('BEE1445' /* Gerar Onda */), I18n.t('BEE1451', { 0: result.waveNumber } /* Onda %{0} gerada com sucesso! */), 'top-right');
        this.clearSelection();
      } else if (result.errorOrderId) {
        const errDoc = selectedOrdersList.find((o) => o.id === parseFloat(result.errorOrderId));
        addNotification('danger', I18n.t('BEE1445' /* Gerar Onda */), I18n.t('BEE1453', { 0: errDoc.orderNumber } /* Documento %{0} já incluso em outra onda! */), 'top-right');
      } else {
        addNotification('danger', I18n.t('BEE1445' /* Gerar Onda */), I18n.t('BEE1452' /* Erro ao gerar onda */), 'top-right');
      }
    } catch (error) {
      addNotification('danger', I18n.t('BEE1445' /* Gerar Onda */), error.message, 'top-right');
    }
  };

  render() {
    const {
      startDate, endDate, currentRange, pages, ordersList, selectedOrdersList, totalDataLength,
    } = this.state;

    const title = I18n.t('BEE1445' /* Gerar Onda */);

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1445' /* Gerar Onda */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <form onSubmit={(e) => e.preventDefault()}>
              <Panel className="panel-with-tabs">
                <PanelHeader noButton />
                <PanelBody>
                  <Table
                    panelHeaderProps={{
                      onClickReload: this.getOrderList,
                    }}
                    filterable
                    data={ordersList}
                    totalDataLength={totalDataLength}
                    datePicker={{
                      startDate,
                      endDate,
                      currentRange,
                      handleDateApplyEvent: this.handleDateApplyEvent,
                    }}
                    columns={this.tableColumns}
                    expander
                    defaultPageSize={10}
                    defaultSorted={this.defaultSorted}
                    defaultFilterMethod={(filter, row) => {
                      const input = _.lowerCase(filter.value);
                      const value = _.lowerCase(row[filter.id]);
                      if (_.includes(value, input)) {
                        return true;
                      }
                    }}
                    manual
                    pages={pages}
                    onFetchData={(state, instance) => {
                      this.page = state.page;
                      this.pageSize = state.pageSize;
                      this.sorted = state.sorted;
                      this.filtered = state.filtered;
                      this.getOrderList();
                    }}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            this.selectOrder(rowInfo.original);
                          },
                          style: {
                            cursor: 'pointer',
                          },
                        };
                      }
                      return {};
                    }}
                  />

                  <Table
                    headerTitle={I18n.t('BEE1449' /* Documentos Selecionados */)}
                    filterable
                    data={selectedOrdersList}
                    columns={this.tableColumns}
                    expander
                    defaultPageSize={10}
                    defaultSorted={this.defaultSorted}
                    defaultFilterMethod={(filter, row) => {
                      const input = _.lowerCase(filter.value);
                      const value = _.lowerCase(row[filter.id]);
                      if (_.includes(value, input)) {
                        return true;
                      }
                    }}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            this.deselectOrder(rowInfo.original);
                          },
                          style: {
                            cursor: 'pointer',
                          },
                        };
                      }
                      return {};
                    }}
                  />

                </PanelBody>
                <PanelFooter className="text-right">
                  <Link to={ROUTES.OUTBOUND_PANEL}>
                    <OneClickButton type="button" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>
                  </Link>
                  <OneClickButton type="button" className="btn btn-120 btn-white p-5 m-5" onClick={() => this.clearSelection()}>{I18n.t('BEE492' /* Limpar */)}</OneClickButton>
                  <OneClickButton type="button" className="btn btn-120 btn-primary p-5 m-5" onClick={() => this.generateWaves()}>{I18n.t('BEE602' /* Gerar */)}</OneClickButton>
                </PanelFooter>
              </Panel>
            </form>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getOutboundOrdersList: (tableParams) => dispatch(getOutboundOrdersList(tableParams)),
  generateWaves: (outboundOrderIds) => dispatch(generateWaves(outboundOrderIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundWaveForm));
