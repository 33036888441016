import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import _ from 'lodash';

import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import { setWaveDocuments, updateWaveAndDocuments } from '../../../app/store/actions/outboundWaves';
import OutboundRangeHelpers from '../../../helpers/outboundRange';
import UserConfirmationModal from '../../../components/pages/userConfirmationModal';

class OutboundOrdersTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            {rows.row.status < 3 && (
              <UncontrolledButtonDropdown>
                <DropdownToggle color="default" caret className="btn-xs" />
                <DropdownMenu>
                  <DropdownItem onClick={() => this.setState({ openModal: true, selectedDocument: rows.row._original })}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            )}
          </div>
        ),
      }, {
        Header: I18n.t('BEE1378' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE581' /* Range */),
        accessor: 'rangeCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE1096' /* Pedido Cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE27' /* Produtos */),
        accessor: 'quantityProducts',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{OutboundRangeHelpers.outboundRangeStatus(row.value)}</span>
          </div>
        ),
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OutboundRangeHelpers.outboundRangeStatus().map((element, index) => <option key={index} value={element.value}>{element.label}</option>)}
          </select>
        ),
      }, {
        Header: I18n.t('BEE613' /* Urgente */),
        accessor: 'urgent',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{(row.value) ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}</span>
          </div>
        ),
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option value>{I18n.t('BEE172' /* Sim */)}</option>
            <option value={false}>{I18n.t('BEE173' /* Não */)}</option>
          </select>
        ),
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      },
    ];

    this.state = {
      openModal: false,
      selectedDocument: {},
      defaultPageSize: 5,
      pageSizeOptions: [5, 10],
      defaultSorted: [{ id: 'orderNumber', desc: false }],
    };
  }

  updateWaveAndDocuments = async () => {
    const { selectedDocument } = this.state;
    const { waveDocumentsList = [] } = this.props;
    try {
      const updateWaveAndDocuments = await this.props.updateWaveAndDocuments(selectedDocument.outboundRangeId, selectedDocument.outboundWaveId);
      if (updateWaveAndDocuments && updateWaveAndDocuments.success === false) {
        addNotification('danger', I18n.t('BEE1882' /* Monitor de Onda */), I18n.t('BEE1887' /* Não foi possível eliminar este documento */), 'top-right');
      } else {
        addNotification('success', I18n.t('BEE1882' /* Monitor de Onda */), I18n.t('BEE3234' /* Documento excluído! */), 'top-right');
        if (waveDocumentsList.length === 1) await this.props.setWaveDocuments([]);
        await this.props.getOutboundWavesByDate();
        await this.props.getWaveDocuments();
      }
    } catch (error) {
      const messageError = error.response && error.response.data && error.response.data.error && error.response.data.error.message;

      if (messageError) addNotification('danger', I18n.t('BEE1882' /* Monitor de Onda */), messageError, 'top-right');
      else addNotification('danger', I18n.t('BEE1882' /* Monitor de Onda */), I18n.t('BEE1887' /* Não foi possível eliminar este documento */), 'top-right');
    }
    this.setState({ selectedDocument: {} });
  };

  userConfirmation = async (confirm) => {
    if (confirm) {
      this.setState({ openModal: false });
      this.updateWaveAndDocuments();
    } else {
      this.setState({
        openModal: false,
        selectedDocument: {},
      });
    }
  };

  render() {
    const { defaultPageSize, defaultSorted, pageSizeOptions } = this.state;
    const { waveDocumentsList = [] } = this.props;

    return (
      <>
        <Table
          filterable
          expander
          downloadCSV
          panelHeaderProps={{
            noButton: true,
            children: I18n.t('BEE462' /* Documentos */).toUpperCase(),
          }}
          data={waveDocumentsList}
          columns={this.tableColumns}
          defaultPageSize={defaultPageSize}
          pageSizeOptions={pageSizeOptions}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        <UserConfirmationModal
          openModal={this.state.openModal}
          confirm={(confirm) => this.userConfirmation(confirm)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  waveDocumentsList: state.outboundWaves && state.outboundWaves.waveDocumentsList,
});

const mapDispatchToProps = (dispatch) => ({
  setWaveDocuments: (waveDocuments) => dispatch(setWaveDocuments(waveDocuments)),
  updateWaveAndDocuments: (outboundRangeId, outboundWaveId) => dispatch(updateWaveAndDocuments(outboundRangeId, outboundWaveId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundOrdersTable));
