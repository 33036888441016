import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './CardDashboard.css';

class CardDashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      dataCards = [],
    } = this.props;
    return (
      <div className="w-100">
        {
          !dataCards ? null
            : (
              <div className="containerCard">
                {
                  dataCards.map((item) => (
                    <div key={item.bigCard.title} className="contentCard">
                      <div
                        className="d-flex flex-column align-items-center justify-content-center rounded mt-3"
                        style={{
                          backgroundColor: item.color,
                          height: '8rem',
                        }}
                      >
                        <h1 style={{ fontSize: '2.2rem' }}>{item.bigCard.title}</h1>
                        <div
                          className="d-flex flex-row justify-content-between align-items-center w-100 p-3"
                        >
                          {
                            item.bigCard.data.map((subItem) => (
                              <div
                                key={subItem.title}
                                className="text-align-center"
                              >
                                <p className="w-100">{subItem.title}</p>
                                <p className="w-100">{subItem.value}</p>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      {
                        item.cards.map((subItem, index) => (
                          <div
                            key={`${subItem} - ${index}`}
                            className="d-flex flex-column align-items-center justify-content-center
                            mt-2 text-black bg-white rounded"
                            style={{ height: '5.5rem' }}
                          >
                            <div className="w-100 text-align-center px-1">
                              <h4 style={{ margin: '0.5rem 0 0.2rem 0' }}>{subItem.title}</h4>
                              <div className="d-flex flex-row justify-content-between align-items-center w-100 mt-2">
                                {
                                  subItem.data.map((cardItem) => (
                                    <div
                                      key={cardItem.title}
                                      className="text-align-center px-1"
                                    >
                                      <p className="mb-0">{cardItem.title}</p>
                                      <p className="m-0 py-0">{cardItem.value}</p>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  ))
                }

              </div>
            )
        }
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardDashboard));
