import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import OneClickButton from '../../components/form/button';
import { PanelPage } from '../../components/pages/pages';
import Form from '../../components/form/form';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportMovementExpeditionCheckQtyProductsSeparatedPerDateAndUser extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
        </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: 'KPIs' },
      { value: I18n.t('BEE2000' /* Qtd Produtos Separados por Data e Usuário */), active: true },
    ];

    this.state = {
      branchCodeFrom: '',
      branchCodeTo: '',
      pickDateFrom: '',
      pickDateTo: '',
      checkUserCodeFrom: '',
      checkUserCodeTo: '',
      checkUserNameFrom: '',
      checkUserNameTo: '',
      rangeFrom: '',
      rangeTo: '',
    };
  }

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      branchCodeFrom,
      branchCodeTo,
      pickDateFrom,
      pickDateTo,
      checkUserCodeFrom,
      checkUserCodeTo,
      checkUserNameFrom,
      checkUserNameTo,
      rangeFrom,
      rangeTo,
    } = this.state;

    const { location } = this.props;

    const filters = returnFiltersByRules([
      ['$code', {
        type: 'between', value: [branchCodeFrom, branchCodeTo], instance: 'Branch', as: 'outboundOrder->branch',
      }],
      ['pickedAt', { type: 'between', value: [pickDateFrom, pickDateTo] }],
      ['$login', {
        type: 'between', value: [checkUserCodeFrom, checkUserCodeTo], instance: 'User', as: 'pickedUserObj',
      }],
      ['$name', {
        type: 'between', value: [checkUserNameFrom, checkUserNameTo], instance: 'User', as: 'pickedUserObj',
      }],
      ['$rangeCode', {
        type: 'between', value: [rangeFrom, rangeTo], instance: 'OutboundRange', as: 'outboundRange',
      }],
    ]);

    this.props.generateReport(
      I18n.t('BEE2000' /* Qtd Produtos Separados por Data e Usuário */).toLowerCase(),
      'xlsx',

      location.pathname,

      [[]],

      filters,
    );
  };

  onSelectFilter = (newState) => {
    this.setState({ ...newState });
  };

  render() {
    const {
      branchCodeFrom,
      branchCodeTo,
      pickDateFrom,
      pickDateTo,
      checkUserCodeFrom,
      checkUserCodeTo,
      checkUserNameFrom,
      checkUserNameTo,
      rangeFrom,
      rangeTo,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE2000' /* Qtd Produtos Separados por Data e Usuário */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createDateInputRange(
                    { from: pickDateFrom, to: pickDateTo },
                    { from: 'pickDateFrom', to: 'pickDateTo' },
                    `${I18n.t('BEE1721' /* Data Separação */)}:`,
                    { from: '', to: '__/__/____' },
                  ),
                  formContext.createInputRange(
                    { from: checkUserCodeFrom, to: checkUserCodeTo },
                    { from: 'checkUserCodeFrom', to: 'checkUserCodeTo' },
                    `${I18n.t('BEE1748' /* Código Usuário */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: checkUserNameFrom, to: checkUserNameTo },
                    { from: 'checkUserNameFrom', to: 'checkUserNameTo' },
                    `${I18n.t('BEE1749' /* Nome Usuário */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: rangeFrom, to: rangeTo },
                    { from: 'rangeFrom', to: 'rangeTo' },
                    `${I18n.t('BEE581' /* Range */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportMovementExpeditionCheckQtyProductsSeparatedPerDateAndUser);
