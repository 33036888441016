import ProductionOrderRequests from '../../server/productionOrder';
import { addLoading, removeLoading } from './loading';

export const getListProductionOrders = (
  filterStartDate,
  filterEndDate,
  page,
  pageSize,
  sorted,
  filtered,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const listProductionOrders = await ProductionOrderRequests.getListProductionOrders(
      filterStartDate,
      filterEndDate,
      page,
      pageSize,
      sorted,
      filtered,
    );
    return listProductionOrders || [];
  } finally {
    dispatch(removeLoading());
  }
};

export default {
  getListProductionOrders,
};
