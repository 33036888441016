import RestService from '../services/rest';

// GET
async function getAddressSizes() {
  return RestService.getAuthenticated('storageAddressSize/list/all');
};
async function getAddressSize(addressSizeId) {
  return RestService.getAuthenticated(`storageAddressSize/detail?addressSizeId=${addressSizeId}`);
};

// POST
async function createAddressSize(newAddressSize) {
  return RestService.postAuthenticated('storageAddressSize/create', newAddressSize);
};

async function deleteAddressSize(addressSizeId) {
  return RestService.postAuthenticated('storageAddressSize/delete', { addressSizeId });
};

// PUT
async function updateAddressSize(updAddressSize) {
  return RestService.putAuthenticated('storageAddressSize/update', updAddressSize);
};

export default {
  getAddressSizes,
  createAddressSize,
  updateAddressSize,
  getAddressSize,
  deleteAddressSize,
};
