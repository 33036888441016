import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import addNotification from '../../../../components/notification';

import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { deleteCurve, getAllCurves } from '../../../../app/store/actions/curves';

class CurveList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalDelete: false,
      curveUpdate: null,
    };
    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showEditCurve(rows.row)}>
                  {I18n.t('BEE57' /* Editar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showDeleteCurve(rows.row)}>
                  {I18n.t('BEE81' /* Eliminar */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minMidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1853' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minMidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getCurves();
    } else {
      await this.getCurves();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      curveUpdate: null,
    });
  };

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getCurves = async () => {
    await this.props.getAllCurves();
  };

  showEditCurve = (curves) => {
    this.props.history.push({
      pathname: ROUTES.CURVE_EDIT,
      state: {
        curves: curves._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  deleteCurve = async (curve) => {
    try {
      const result = await this.props.deleteCurve(curve._original.id, curve.code);
      if (result) {
        addNotification(
          'success',
          I18n.t('BEE2476' /* Eliminar Curva */),
          I18n.t('BEE2475', { 0: curve.code } /* Curva %{0} eliminada com sucesso! */),
          'top-right',
        );
      }
      await this.getCurves();
    } catch (err) {
      console.log(err);
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? error.details
          : error.message;

        addNotification(
          'danger',
          I18n.t('BEE2476' /* Eliminar Curva */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2476' /* Eliminar Curva */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
    this.setInitState();
  };

  showDeleteCurve = (curve) => {
    this.setState({ showModalDelete: true, curveUpdate: curve });
  };

  render() {
    const { page, showModalDelete, curveUpdate } = this.state;
    const { curves = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE2463' /* Curvas */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          wikiHelp={ROUTES.CURVES_HELP}
          headerTitle={I18n.t('BEE2463' /* Curvas */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.CURVE_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {I18n.t('BEE2464' /* Incluir Curva */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getCurves,
          }}
          filterable
          data={curves}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          // page={(page) ? page : (!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page}
          page={page ? (this.props.location.state?.dice?.page) : page}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteCurve(curveUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE2474', { 0: curveUpdate.code } /* A curva %{0} será eliminada! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  curves: state.curves && state.curves.curvesList,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCurves: () => dispatch(getAllCurves()),
  deleteCurve: (curveId, code) => dispatch(deleteCurve(curveId, code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CurveList));
