import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getIndirectTasksList, deleteIndirectTask } from '../../../../app/store/actions/indirectTasks';

class IndirectTasksList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      indirectTaskUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showIndirectTaskDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditIndirectTask(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyIndirectTask(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDeleteIndirectTask(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        id: 'name',
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getIndirectTasks();
    } else {
      await this.getIndirectTasks();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      indirectTaskUpdate: null,
    });
  };

  getIndirectTasks = async () => {
    await this.props.getIndirectTasksList();
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deleteIndirectTask = async (indirectTask) => {
    const result = await this.props.deleteIndirectTask(indirectTask._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'success',
        I18n.t('BEE1678' /* Eliminar Tarefa Indireta */),
        I18n.t('BEE1679', { 0: indirectTask.code } /* Tarefa %{0} eliminada com sucesso */),
        'top-right',
      );
    }
    await this.getIndirectTasks();
  };

  showIndirectTaskDetail = (indirectTask) => {
    this.props.history.push({
      pathname: ROUTES.INDIRECT_TASKS_DETAIL,
      state: {
        indirectTask: indirectTask._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditIndirectTask = (indirectTask) => {
    this.props.history.push({
      pathname: ROUTES.INDIRECT_TASKS_EDIT,
      state: {
        indirectTask: indirectTask._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyIndirectTask = (indirectTask) => {
    this.props.history.push({
      pathname: ROUTES.INDIRECT_TASKS_COPY,
      state: {
        indirectTask: indirectTask._original,
      },
    });
  };

  showDeleteIndirectTask = (indirectTask) => {
    this.setState({ showModalDelete: true, indirectTaskUpdate: indirectTask });
  };

  render() {
    const { indirectTaskUpdate, showModalDelete, page } = this.state;
    const { indirectTasksList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE25' /* Usuários */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1668' /* Tarefas Indiretas */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE1668' /* Tarefas Indiretas */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.INDIRECT_TASKS_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE1680' /* Incluir Tarefa Indireta */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getIndirectTasks,
          }}
          filterable
          data={indirectTasksList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteIndirectTask(indirectTaskUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE1681', { 0: indirectTaskUpdate.code } /* A tarefa %{0} será eliminada! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  indirectTasksList: state.indirectTasks && state.indirectTasks.indirectTasksList,
});

const mapDispatchToProps = (dispatch) => ({
  getIndirectTasksList: () => dispatch(getIndirectTasksList()),
  deleteIndirectTask: (indirectTaskId) => dispatch(deleteIndirectTask(indirectTaskId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IndirectTasksList));
