import React from 'react';
import { I18n } from 'react-redux-i18n';
import './bulkImportStyles.scss';
import { CSVLink } from 'react-csv';

class ImportCaption extends React.PureComponent {
  render() {
    const {
      legend = [],
      headers = [],
      data = [],
      filename,
      className,
    } = this.props;

    return (
      <div className={className || 'legendImportCaption'}>
        <div className="scrollViewlegendImportCaption">
          <div className="legendWarning" style={{ textAlign: 'center' }}>
            {I18n.t('BEE2503' /* Os nomes das colunas devem ser respeitados seguindo o modelo! */)}
            <br />
            {I18n.t('BEE2504' /* O arquivo deve estar no formato CSV ultilizando o ; como separador! */)}
          </div>
          {legend.map(
            (element) => (
              <div key={element.nameField}>
                <span className="legendBold">{`"${element.nameField}" -`}</span>
                <span>{` ${element.observationField};`}</span>
              </div>
            ),
          )}
        </div>
        <div className="boxButtonDownload">
          <CSVLink
            data={data}
            headers={headers}
            separator=";"
            className="downloadCsv"
            filename={filename}
          >
            {I18n.t('BEE2497' /* Baixar Modelo CSV */)}
          </CSVLink>
        </div>
      </div>
    );
  }
}

export default ImportCaption;
