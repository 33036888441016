import { I18n } from 'react-redux-i18n';
import ResupplyRules from '../../server/resupplyRules';
import { addLoading, removeLoading } from './loading';

export const setResupplyRules = (rules) => ({
  type: 'SET_RESUPPLY_RULES',
  rules,
});

export const updateRule = (rules) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ResupplyRules.updateRule(rules);
  } finally {
    dispatch(removeLoading());
  }
};

export const getResupplyRules = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const rules = await ResupplyRules.getRules();

    rules.forEach((r) => {
      if (r.columnSide === 'odd') {
        r.columnSide = I18n.t('BEE3399' /* Ímpar */);
      } else if (r.columnSide === 'even') {
        r.columnSide = I18n.t('BEE3398' /* Par */);
      } else {
        r.columnSide = I18n.t('BEE203' /* Ambos */);
      }
    });

    return rules;
  } finally {
    dispatch(removeLoading());
  }
};

export const createRulesResupply = (rules) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ResupplyRules.createRulesResupply(rules);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteRule = (ruleId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ResupplyRules.deleteRule(ruleId);
  } finally {
    dispatch(removeLoading());
  }
};
