import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import Table from '../../../../components/table/Table';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  getStorageAddressesPickingAreaList,
  updateStorageAddressPickingArea,
} from '../../../../app/store/actions/pickingAreas';
import OneClickButton from '../../../../components/form/button';

class PickingAreaLinkAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageTable2: 0,
      firstRun: false,
      AddAddressCheckList: [],
      DelAddressCheckList: [],
    };
    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.addTableColumns = [
      {
        Header: I18n.t('BEE436' /* Selecionar */),
        accessor: 'action',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 95,
        filterable: false,
        sortable: false,
        Cell: (row) => (
          row.original.stockOtherProduct === 0

          && (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              onChange={(e) => this.handleCheckList(e.target.checked, row.original)}
              checked={this.state.AddAddressCheckList.filter((item) => item === row.original.id).length > 0
                || this.state.DelAddressCheckList.filter((item) => item === row.original.id).length > 0}
            />
          </div>
          )
        ),
      },
      {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'pickingArea.productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'pickingArea.depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE204' /* Situation */),
        accessor: 'freeAddresses',
        Cell: (row) => (
          <span style={{ color: row.value === 0 && 'red' }}>
            {row.value === 0 ? I18n.t('BEE2484' /* Ocupado */) : I18n.t('BEE530' /* Disponível */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);

          const id = row[filter.id];
          const isNot = _.lowerCase(I18n.t('BEE2484' /* Ocupado */));
          const isTrue = _.lowerCase(I18n.t('BEE530' /* Disponível */));

          if (isNot.includes(input)) {
            return id === 0;
          } if (isTrue.includes(input)) {
            return id === 1;
          }
        },
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE279' /* Setor */),
        accessor: 'sector',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 65,
      }, {
        Header: I18n.t('BEE121' /* Rua */),
        accessor: 'street',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 65,
      }, {
        Header: I18n.t('BEE281' /* Coluna */),
        accessor: 'column',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 65,
      }, {
        Header: I18n.t('BEE283' /* Nível */),
        accessor: 'level',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 65,
      }, {
        Header: I18n.t('BEE285' /* Gaveta */),
        accessor: 'drawer',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 65,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      },
    ];
    this.removeTableColumns = [
      {
        Header: I18n.t('BEE436' /* Selecionar */),
        accessor: 'action',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 95,
        filterable: false,
        sortable: false,
        Cell: (row) => (

          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              onChange={(e) => this.handleCheckList(e.target.checked, row.original)}
              checked={this.state.AddAddressCheckList.filter((item) => item === row.original.id).length > 0
                || this.state.DelAddressCheckList.filter((item) => item === row.original.id).length > 0}
            />
          </div>

        ),
      },
      {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'pickingArea.productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'pickingArea.depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE204' /* Situation */),
        accessor: 'freeAddresses',
        Cell: (row) => (
          <span style={{ color: row.value === 0 && 'red' }}>
            {row.value === 0 ? I18n.t('BEE2484' /* Ocupado */) : I18n.t('BEE530' /* Disponível */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          const isNot = _.lowerCase(I18n.t('BEE2484' /* Ocupado */));
          const isTrue = _.lowerCase(I18n.t('BEE530' /* Disponível */));

          if (isNot.includes(input)) {
            return id === 0;
          } if (isTrue.includes(isTrue, input)) {
            return id === 1;
          }
        },
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE279' /* Setor */),
        accessor: 'sector',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 65,
      }, {
        Header: I18n.t('BEE121' /* Rua */),
        accessor: 'street',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 65,
      }, {
        Header: I18n.t('BEE281' /* Coluna */),
        accessor: 'column',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 65,
      }, {
        Header: I18n.t('BEE283' /* Nível */),
        accessor: 'level',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 65,
      }, {
        Header: I18n.t('BEE285' /* Gaveta */),
        accessor: 'drawer',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 65,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        pageTable2: this.props.location.state.dice.page,
        firstRun: true,
        AddAddressCheckList: [],
        DelAddressCheckList: [],
      });
      await this.getStorageAddressesPickingArea();
    } else {
      await this.getStorageAddressesPickingArea();
    }
  };

  handleCheckList = async (check, row) => {
    const { storageAddresses } = this.props;
    const isAddCheckList = storageAddresses.filter((item) => item.id === row.id).length > 0;
    let AddressCheckListTemp = isAddCheckList ? this.state.AddAddressCheckList : this.state.DelAddressCheckList;

    if (check) {
      AddressCheckListTemp.push(row.id);
    } else {
      AddressCheckListTemp = AddressCheckListTemp.filter((item) => item !== row.id);
    }

    if (isAddCheckList) {
      this.setState(() => ({
        AddAddressCheckList: AddressCheckListTemp,
      }));
    } else {
      this.setState(() => ({
        DelAddressCheckList: AddressCheckListTemp,
      }));
    }
  };

  getStorageAddressesPickingArea = async () => {
    await this.props.getStorageAddressesPickingAreaList(this.props.location.state.pickingArea.id);
  };

  handleAllCheckList = async (isAdd) => {
    const listAddressCheckListTemp = isAdd
      ? this.reactTableAdd.getResolvedState().sortedData : this.reactTableDel.getResolvedState().sortedData;
    const listAddressCheckList = [];

    listAddressCheckListTemp.filter((item) => item._original.stockOtherProduct === 0 || !isAdd).forEach((element) => {
      listAddressCheckList.push(element._original.id);
    });

    if (isAdd) {
      this.setState({
        AddAddressCheckList: listAddressCheckList,
      });
    } else {
      this.setState({
        DelAddressCheckList: listAddressCheckList,
      });
    }
  };

  handleAllNoCheckList = async (isAdd) => {
    if (isAdd) {
      this.setState({
        AddAddressCheckList: [],
      });
    } else {
      this.setState({
        DelAddressCheckList: [],
      });
    }
  };

  updateStorageAddressPickingArea = async (pickingAreaId) => {
    const isAddCheckList = pickingAreaId !== null;

    try {
      await this.props.updateStorageAddressPickingArea(
        isAddCheckList ? this.state.AddAddressCheckList : this.state.DelAddressCheckList,
        pickingAreaId,
      );

      await this.props.getStorageAddressesPickingAreaList(this.props.location.state.pickingArea.id);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;
        await addNotification(
          'danger',
          I18n.t('BEE596' /* Vincular Endereços */),
          `${error.code} - ${error.details || error.message}`,
          'top-right',
        );
      }
    }

    this.setState({
      page: 0,
      pageTable2: 0,
      AddAddressCheckList: [],
      DelAddressCheckList: [],
    });
  };

  render() {
    const { page = 0, pageTable2 = 0 } = this.state;
    const { storageAddresses = [], storageAddressesInPickingArea = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE2570' /* Áreas de Picking */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE596' /* Vincular Endereços */)}</li>
          </ol>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-md-3 mb-2 w-100">
          <h1 className="page-header mb-0">
            {`${this.props.location.state.pickingArea.productCode} -
            ${this.props.location.state.pickingArea.description}
            (${this.props.location.state.pickingArea.depositCode})` }
          </h1>
          <Link to={{ pathname: ROUTES.PICKING_AREA_LIST, state: { dice: this.props.location.dice } }}>
            <OneClickButton
              type="submit"
              className="btn btn-sm btn-gray btn-rounded ml-1"
            >
              <i className="fa fa-arrow-left mr-1" />
              {I18n.t('BEE137' /* Voltar */)}
            </OneClickButton>
          </Link>
        </div>
        <div className="d-flex align-items-center">
          <div className="mw-100 overflow-auto">
            <Table
              className="mw-100 overflow-auto"
              downloadCSV
              wikiHelp=""
              ref={(r) => {
                this.reactTableAdd = r && r.reactTable;
              }}
              headerTitle={I18n.t('BEE20' /* Endereços */)}
              actionButtons={(
                <div className="ml-auto">
                  <button
                    type="button"
                    onClick={() => { this.handleAllCheckList(true); }}
                    className="btn btn-gray btn-sm pl-4 pr-4 mr-2"
                  >
                    {' '}
                    {I18n.t('BEE1908' /* Marcar Todos */)}
                  </button>
                  <button
                    type="button"
                    onClick={() => { this.handleAllNoCheckList(true); }}
                    className="btn btn-gray btn-sm pl-4 pr-4 mr-2"
                  >
                    {' '}
                    {I18n.t('BEE1909' /* Desmarcar Todos */)}
                  </button>
                  <button
                    type="button"
                    onClick={() => { this.updateStorageAddressPickingArea(this.props.location.state.pickingArea.id); }}
                    className="btn btn-blue btn-sm pl-4 pr-4"
                  >
                    {' '}
                    {I18n.t('BEE785' /* Adicionar */)}
                  </button>
                </div>
            )}
              panelHeaderProps={{
                onClickReload: this.getStorageAddressesPickingArea,
              }}
              filterable
              data={storageAddresses}
              columns={this.addTableColumns}
              expander
              defaultPageSize={10}
              defaultSorted={this.defaultSorted}
              page={(page) || (
                (!this.state.firstRun && this.props.location.state
                && this.props.location.state.dice && this.props.location.state.dice.page)
                  ? this.props.location.state.dice.page : page)}
              onPageChange={(Page) => ((Page >= 0)
                ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          </div>
          <div className="mw-100 overflow-auto ml-4">
            <Table
              downloadCSV
              wikiHelp=""
              headerTitle={I18n.t('BEE2570' /* Áreas de Picking */)}
              ref={(r) => {
                this.reactTableDel = r && r.reactTable;
              }}
              actionButtons={(
                <div className="ml-auto">
                  <button
                    type="button"
                    className="btn btn-gray btn-sm pl-4 pr-4 mr-2"
                    onClick={() => { this.handleAllCheckList(false); }}
                  >
                    {' '}
                    {I18n.t('BEE1908' /* Marcar Todos */)}
                  </button>
                  <button
                    type="button"
                    className="btn btn-gray btn-sm pl-4 pr-4 mr-2"
                    onClick={() => { this.handleAllNoCheckList(false); }}
                  >
                    {' '}
                    {I18n.t('BEE1909' /* Desmarcar Todos */)}
                  </button>
                  <button
                    type="button"
                    onClick={() => { this.updateStorageAddressPickingArea(null); }}
                    className="btn btn-danger btn-sm pl-4 pr-4"
                  >
                    {' '}
                    {I18n.t('BEE558' /* Remover */)}
                  </button>
                </div>
            )}
              panelHeaderProps={{
                onClickReload: this.getStorageAddressesPickingArea,
              }}
              filterable
              data={storageAddressesInPickingArea}
              columns={this.removeTableColumns}
              expander
              defaultPageSize={10}
              defaultSorted={this.defaultSorted}
              page={(pageTable2) || (
                (!this.state.firstRun && this.props.location.state
                && this.props.location.state.dice && this.props.location.state.dice.pageTable2)
                  ? this.props.location.state.dice.pageTable2 : pageTable2)}
              onPageChange={(Page) => ((Page >= 0)
                ? this.setState({ pageTable2: Page }) : this.setState({ pageTable2: 0 }))}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  storageAddressesInPickingArea: state.pickingArea.storageAddressesList
    && state.pickingArea.storageAddressesList.storageAddressesInPickingArea,
  storageAddresses: state.pickingArea.storageAddressesList && state.pickingArea.storageAddressesList.storageAddresses,
});

const mapDispatchToProps = (dispatch) => ({
  getStorageAddressesPickingAreaList: (pickingAreaId) => dispatch(getStorageAddressesPickingAreaList(pickingAreaId)),
  updateStorageAddressPickingArea: (storageAddressId, pickingAreaId) => dispatch(
    updateStorageAddressPickingArea(storageAddressId, pickingAreaId),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PickingAreaLinkAddress));
