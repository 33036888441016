import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import ROUTES from '../../../../config/routes';

import { getBranchesOptions } from '../../../../app/store/actions/branches';
import OneClickButton from '../../../../components/form/button';
import Select from '../../../../components/form/select';
import addNotification from '../../../../components/notification';
import { PanelPage } from '../../../../components/pages/pages';
import WikiHelp from '../../../../components/tooltip/wikiHelp';
import { getPickingAreaOptionsProducts } from '../../../../app/store/actions/pickingAreas';
import {
  createManualRequest,
  getManualRequestOptions,
  getManualRequestOptionsProducts,
} from '../../../../app/store/actions/manualRequest';
import Table from '../../../../components/table/Table';
import { CustomNumberFormat } from '../../../../components/form/input';

import FormatHelpers from '../../../../helpers/format';

class GenerateManualRequest extends React.PureComponent {
  constructor(props) {
    super(props);

    this.pageSize = 10;

    this.state = {
      productionOrder: '',
      quantity: 0,
      productCode: '',
      productName: '',
      branchCode: '',
      branchName: '',
      depositCode: '',
      depositName: '',
      listProduct: [],
      listDeposit: [],
      observation: '',
      orderNumber: '',
      unitMeasure: 'UN',
      productsRequest: [],
    };

    this.defaultSorted = [{
      id: 'lineNumber',
      desc: true,
    }];

    this.tableColumns = [
      {
        Header: I18n.t('BEE463' /* Linha */),
        accessor: 'lineNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      },
      {
        Header: I18n.t('BEE378' /* Código do Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1043' /* Nome do Produto */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1688' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
        Cell: (row) => (
          <span>
            {FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE384' /* Unidade */),
        accessor: 'unitMeasure',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 150,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button
              className="btn btn-sm btn-danger"
              onClick={() => { this.removeProductsRequest(rows.row); }}
              type="button"
            >
              {I18n.t('BEE558' /* Remover */)}
            </button>
          </div>
        ),
      }];
  }

  async componentDidMount() {
    await this.getBranches();
  }

  async componentDidUpdate(prevProps) {
    if ((prevProps.userLogged && prevProps.userLogged.mainBranchData)
    !== (this.props.userLogged && this.props.userLogged.mainBranchData)) {
      await this.getBranches();
    }
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = (attr, value) => {
    this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  setFilterDrop = _.debounce(async (attr, value) => {
    const { products } = await this.props.getManualRequestOptionsProducts(value, false);

    const dataSelected = products?.filter((item) => item.value === value);

    if (dataSelected.length === 1) {
      await this.setState({
        [`${attr}Code`]: dataSelected[0].value ? dataSelected[0].value : null,
        [`${attr}Name`]: dataSelected[0].value ? dataSelected[0].label : '',
        unitMeasure: dataSelected[0].value ? dataSelected[0].unitMeasure : '',
      });
    } else {
      await this.setState({
        [`${attr}Code`]: null,
        [`${attr}Name`]: '',
      });
    }

    this.setState({
      listProduct: products,
    });
  }, 1000);

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  addProductsRequest = () => {
    const {
      productCode, productName, unitMeasure,
      quantity, productsRequest,
    } = this.state;

    if (!productCode) {
      addNotification(
        'danger',
        I18n.t('BEE2842' /* Gerar Requisição Manual */),
        I18n.t('BEE2454' /* Informe o código do produto */),
        'top-right',
      );
      return;
    } if (!quantity && quantity <= 0) {
      addNotification(
        'danger',
        I18n.t('BEE2842' /* Gerar Requisição Manual */),
        I18n.t('BEE2293' /* A quantidade tem que ser maior que 0 */),
        'top-right',
      );
      return;
    }

    const existProduct = productsRequest.filter((item) => item.productCode === productCode);

    if (existProduct.length > 0) {
      addNotification(
        'danger',
        I18n.t('BEE2842' /* Gerar Requisição Manual */),
        I18n.t('BEE3343', { 0: productCode } /* Produto %{0} já foi adicionado! */),
        'top-right',
      );
      return;
    }

    const lineNumber = productsRequest.length
      ? Number(productsRequest[productsRequest.length - 1].lineNumber) + 10
      : 10;

    const productsRequestTemp = [...productsRequest];

    productsRequestTemp.push({
      lineNumber,
      productCode,
      name: productName,
      quantity,
      unitMeasure,
    });

    this.setState({
      productsRequest: productsRequestTemp,
      quantity: 0,
      productCode: '',
      productName: '',
      unitMeasure: 'UN',
    });
  };

  removeProductsRequest = (item) => {
    const { productsRequest } = this.state;
    const removeProductsRequest = productsRequest.filter((filter) => filter.lineNumber !== item.lineNumber);

    this.setState({ productsRequest: removeProductsRequest });
  };

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
    buttonLabel = '',
    buttonFunction = undefined,
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          {buttonLabel && buttonFunction && (
            <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  createSelectDropDown = (value, attr, label, items, disabled, keypressFunction = undefined, id) => (
    <div className="form-group m-b-15 text-left">
      <label htmlFor={label}>{label}</label>
      <div>
        <Select
          id={id && id}
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          value={value.label ? value : ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={this.colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          isDisabled={disabled}
        />
      </div>
    </div>
  );

  createTextArea = (value = '', attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 text-left w-75">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <textarea
          className="form-control"
          style={{ resize: 'none' }}
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  createNumberFormat = (
    value,
    attr,
    label,
    decimals,
    disabled = false,
    keypressFunction = undefined,
    id = null,
    disabledButton = false,
    autoFocus = false,
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <CustomNumberFormat
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          className="form-control"
          decimalScale={(!this.props.fractionalQuantity) ? 0 : decimals}
          value={value || ''}
          onValueChange={(values) => {
            this.setValue(attr, values);
          }}
          fixedDecimalScale
          defaultValue={0}
          disabled={disabled}
          allowEmptyFormatting
          autoFocus={autoFocus}
          thousandSeparator="."
          decimalSeparator=","
        />
        {(!disabled && !disabledButton) && (
          <div className="input-group-append">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.setValue(attr, 0)}
            >
              <i className="fa fa-times" />
            </button>
          </div>
        )}
      </div>
    </div>
  );

  getBranches = async () => {
    try {
      const listBranch = await this.props.getBranchesOptions();
      const branchCodeUser = await this.props.userLogged.mainBranch;
      const selectBranch = listBranch.find((element) => element.value === branchCodeUser);
      const dataOptions = await this.props.getManualRequestOptions();
      this.setState({
        branchCode: selectBranch.value,
        branchName: selectBranch.label,
        listProduct: dataOptions?.products,
        listDeposit: dataOptions?.deposits,
      });
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;
        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE2842' /* Gerar Requisição Manual */),
            `${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1638' /* Ocorreu uma falha de comunicação com o servidor. */),
            'top-right',
          );
        }
      }
    }
  };

  createSelectDropDownFilter = (
    value,
    attr,
    label,
    items,
    disabled,
    onChange,
    id = 'inputList',
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={label}>
        {label}
      </label>
      <div className="input-group">
        <input
          id={id}
          list="dataListFilter"
          className="form-control"
          disabled={disabled}
          value={value}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          onChange={(e) => {
            onChange(e);
            this.setFilterDrop(attr, e.target.value);
          }}
        />
        <datalist
          id="dataListFilter"
        >
          {
            items.map((item) => (
              <option
                key={item.value}
                value={item.value}
              >
                {item.label}
              </option>
            ))
          }
        </datalist>
      </div>
    </div>
  );

  handleInputProductCode = (event) => {
    this.setState({ productCode: event.target.value });
  };

  createManualRequest = async () => {
    const {
      productionOrder, branchCode, productsRequest, depositCode,
      observation, quantity,
    } = this.state;

    try {
      const newManualRequest = {
        productionOrder,
        branchCode,
        depositCode,
        observation,
        productsRequest,
        quantity,
      };

      const result = await this.props.createManualRequest(newManualRequest);

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2842' /* Gerar Requisição Manual */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2842' /* Gerar Requisição Manual */),
          I18n.t('BEE2843' /* Requisição gerada com sucesso! */),
          'top-right',
        );

        this.setState({
          productionOrder: '',
          productCode: '',
          productName: '',
          quantity: 0,
          depositCode: '',
          depositName: '',
          observation: '',
          orderNumber: result.orderNumber,
          productsRequest: [],
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2842' /* Gerar Requisição Manual */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2842' /* Gerar Requisição Manual */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      branchCode,
      branchName,
      depositCode,
      depositName,
      productCode,
      productName,
      observation,
      listProduct,
      selectBranch,
      listDeposit,
      productionOrder,
      quantity,
      orderNumber,
      unitMeasure,
      productsRequest,
    } = this.state;

    return (
      <>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE2771' /* Indústria */)}</li>
            <li className="breadcrumb-item active">
              {I18n.t('BEE2842' /* Gerar Requisição Manual */)}
            </li>
          </ol>
        </div>
        <div className="d-flex align-items-center">
          <h1 className="page-header mb-0">
            {I18n.t('BEE2842' /* Gerar Requisição Manual */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.INDUSTRY_MANUAL_REQUEST_HELP} />
        </div>
        <div onSubmit={(e) => e.preventDefault()}>
          <PanelPage
            noButton
            content={(
              <div className="col">
                <div className="row">
                  <div className="col-md-4">
                    {this.createInput(
                      productionOrder,
                      'productionOrder',
                      `${I18n.t('BEE2770' /* Ordem de Produção */)}:`,
                      I18n.t('BEE2770' /* Ordem de Produção */),
                      'text',
                      false,
                      false,
                    )}
                  </div>
                  <div className="col-md-4">
                    {this.createSelectDropDown(
                      { value: branchCode, label: branchName },
                      'branch',
                      `${I18n.t('BEE145' /* Filial */)}:`,
                      selectBranch,
                      true,
                      this.goToElement,
                      2,
                    )}
                  </div>
                  <div className="col-md-4">
                    {this.createSelectDropDown(
                      { value: depositCode, label: depositName },
                      'deposit',
                      `${I18n.t('BEE182' /* Depósito */)}:`,
                      listDeposit,
                      false,
                      this.goToElement,
                      2,
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    {this.createSelectDropDownFilter(
                      productCode,
                      'product',
                      `${I18n.t('BEE378' /* Código do Produto */)}:`,
                      listProduct,
                      false,
                      this.handleInputProductCode,
                    )}
                  </div>
                  <div className="col-md-4">
                    {this.createInput(
                      productName,
                      'productName',
                      `${I18n.t('BEE758' /* Descrição Produto */)}:`,
                      I18n.t('BEE758' /* Descrição do Produto */),
                      'text',
                      false,
                      true,
                    )}
                  </div>
                  <div className="col-md-4">
                    {this.createNumberFormat(
                      quantity,
                      'quantity',
                      I18n.t('BEE3253', { 0: unitMeasure } /* Quantidade em %{0} */),
                      3,
                      false,
                    )}
                  </div>
                  <div className="col-md-12 w-100 d-flex justify-content-between">
                    {this.createTextArea(
                      observation,
                      'observation',
                      `${I18n.t('BEE135' /* Observação */)}:`,
                      I18n.t('BEE136' /* Informe a observação */),
                      2,
                      false,
                      false,
                    )}
                    {
                      <div>
                        <OneClickButton
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={this.addProductsRequest}
                        >
                          {I18n.t('BEE359' /* Incluir Produto */)}

                        </OneClickButton>
                      </div>
                    }
                  </div>
                </div>
              </div>
            )}
            footerContent={(
              <>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={this.createManualRequest}
                  disabled={!productsRequest.length}
                >
                  {I18n.t('BEE2842' /* Gerar Requisição Manual */)}

                </OneClickButton>
                <Table
                  expander
                  filterable
                  downButtonPosition
                  data={productsRequest}
                  totalDataLength={productsRequest.length}
                  columns={this.tableColumns}
                  pages={Math.ceil(productsRequest.length / this.pageSize)}
                  defaultSorted={this.defaultSorted}
                  defaultPageSize={this.pageSize}
                  defaultFilterMethod={(filter, row) => {
                    const input = _.lowerCase(filter.value);
                    const value = _.lowerCase(row[filter.id]);
                    if (_.includes(value, input)) {
                      return true;
                    }
                  }}
                />

              </>
            )}
          />
        </div>
        {(!!orderNumber
          && (
            <SweetAlert
              success
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              title={<h3>{I18n.t('BEE1021' /* Documento criado com sucesso ! */)}</h3>}
              onConfirm={() => this.setState({ orderNumber: '' })}
            >
              <h2>{`${I18n.t('BEE123' /* Número */)}: ${orderNumber}`}</h2>
            </SweetAlert>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged && state.app.userLogged,
  fractionalQuantity: state.app.permissionsCompany && state.app.permissionsCompany.fractionalQuantity,
});

const mapDispatchToProps = (dispatch) => ({
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getManualRequestOptions: () => dispatch(getManualRequestOptions()),
  getPickingAreaOptionsProducts: (productFilter) => dispatch(getPickingAreaOptionsProducts(productFilter)),
  getManualRequestOptionsProducts: (productFilter, loading) => dispatch(
    getManualRequestOptionsProducts(productFilter, loading),
  ),
  createManualRequest: (newManualRequest) => dispatch(createManualRequest(newManualRequest)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GenerateManualRequest));
