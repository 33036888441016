import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import DateRangePicker from '../../../components/date/DateRangePicker.jsx';

import { PopoverHeader, UncontrolledPopover } from 'reactstrap';

import List from './list';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';

class ReturnPickingOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().subtract(30, 'days'),
      endDate: moment(),
      currentRange: `${moment().subtract(30, 'days').format('DD MMM YYYY')} - ${moment().format('DD MMM YYYY')}`,
    };
  }

  handleDateApplyEvent = async (event) => {
    const startDate = moment(event[0]);
    const endDate = moment(event[1]);
    const currentRange = `${startDate.format('DD MMM YYYY')} - ${endDate.format('DD MMM YYYY')}`;

    await this.setState({
      startDate,
      endDate,
      currentRange,
    });
  };

  render() {
    const { startDate, endDate, currentRange } = this.state;

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1921' /* Monitor de Devolução */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE1921' /* Monitor de Devolução */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.OUTBOUND_DEVOLUTION_MONITOR_HELP} />
        </div>
        <div className="row m-l-1 mb-3">
          <DateRangePicker
            onChange={this.handleDateApplyEvent}
          />
          <UncontrolledPopover trigger="hover" placement="top" target="dateRangePicker">
            <PopoverHeader>
              { I18n.t('BEE3342', { 0:(I18n.t('BEE3344' /* data de criação */)) } /* O intervalo segue o critério pela %{0} */) }
            </PopoverHeader>
          </UncontrolledPopover>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <List
              startDate={startDate}
              endDate={endDate}
              currentRange={currentRange}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReturnPickingOrder));
