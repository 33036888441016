import RestService from '../services/rest';

async function getLotInfo() {
  return RestService.getAuthenticated(`lots/info`)
}

async function getLots( branchCode) {
  return RestService.getAuthenticated(`lots/allBranch`, { branchCode })
}

async function getOptionsLots( branchCode) {
  return RestService.getAuthenticated(`lots/options`, { branchCode })
}

async function generateLots(branchCode, quantity, initial) {
  return RestService.postAuthenticated('lots/create', { branchCode, quantity, initial })
}

export default {
  getLotInfo,
  getLots,
  getOptionsLots,
  generateLots,
}