import WarehousesRequests from '../../server/warehouses';
import { addLoading, removeLoading } from './loading';

export const setWarehouses = warehouses => ({
  type: 'SET_WAREHOUSES',
  warehouses,
});

export const getWarehousesList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const warehouses = await WarehousesRequests.getWarehouses();
    dispatch(setWarehouses(warehouses));
  } finally {
    dispatch(removeLoading());
  }
};

export const createWarehouse = warehouse => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newWarehouse = await WarehousesRequests.createWarehouse(warehouse);
    return newWarehouse;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateWarehouse = (warehouse) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await WarehousesRequests.updateWarehouse(warehouse);
  } finally {
    dispatch(removeLoading());
  }
}

export const getWarehouse = (warehouseId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await WarehousesRequests.getWarehouse(warehouseId);
  } finally {
    dispatch(removeLoading());
  }
}

export const getWarehousesOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await WarehousesRequests.getWarehousesOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteWarehouse = (warehouseId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await WarehousesRequests.deleteWarehouse(warehouseId);
  } finally {
    dispatch(removeLoading());
  }
}