import React from 'react';
import DualListBox from 'react-dual-listbox';
import { I18n } from 'react-redux-i18n';

const createLabel = (label, required) => (
  <span>
    {label}
    {' '}
    {required && <span style={{ color: 'red' }}>*</span>}
  </span>
);

const DualList = props => (
  <div id={props.id || ''} className="text-left">
    <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
      <h1 className="page-header mb-0">
        {props.title || I18n.t('BEE569' /* Personalizar */)}
      </h1>
    </div>
    <hr />
    <DualListBox
      preserveSelectOrder
      showOrderButtons
      showHeaderLabels
      canFilter
      {...props}
      options={props.options || []}
      selected={props.selected || []}
      onChange={props.onChange || (() => false)}
      lang={{
        availableFilterHeader: props.availableFilterHeader || I18n.t('BEE529' /* Filtro disponível */),
        availableHeader: props.availableHeader || I18n.t('BEE542' /* Colunas Disponíveis */),
        moveLeft: props.moveLeft || I18n.t('BEE531' /* Mover para esquerda */),
        moveAllLeft: props.moveAllLeft || I18n.t('BEE532' /* Mover tudo para a esquerda */),
        moveRight: props.moveRight || I18n.t('BEE533' /* Mover para a direita */),
        moveAllRight: props.moveAllRight || I18n.t('BEE534' /* Mover tudo para a direita */),
        moveDown: props.moveDown || I18n.t('BEE535' /* Mover o item para baixo */),
        moveUp: props.moveUp || I18n.t('BEE536' /* Mover o item para cima */),
        noAvailableOptions: props.noAvailableOptions || I18n.t('BEE537' /* Sem opções disponíveis */),
        noSelectedOptions: props.noSelectedOptions || I18n.t('BEE538' /* Sem opções selecionadas */),
        selectedFilterHeader: props.selectedFilterHeader || I18n.t('BEE539' /* Filtro selecionado */),
        selectedHeader: props.selectedHeader || createLabel(I18n.t('BEE543' /* Colunas Selecionadas */), true),
      }}
      filterPlaceholder={props.filterPlaceholder || I18n.t('BEE570' /* Filtrar coluna... */)}
      filterCallback={(option, filterInput) => {
        if (filterInput === '') {
            return true;
        }

        const filterTerm = filterInput.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
        const labelValue = option.label.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

        return (new RegExp(filterTerm).test(labelValue));
      }}
    />
  </div>
);

export default DualList;