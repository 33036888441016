const INITIAL_STATE = {
  pickingAreasList: [],
  storageAddressesList: [],
  showBulkImport: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PICKING_AREA': {
      return { ...state, pickingAreasList: action.pickingAreas };
    }
    case 'SET_ADDRESSES_PICKING_AREA': {
      return { ...state, storageAddressesList: action.pickingAreas };
    }
    case 'OPEN_BULK_IMPORT_PICKING_AREA': {
      return { ...state, showBulkImport: action.showBulkImport };
    }
    default:
      return state;
  }
};
