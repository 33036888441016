import RestService from '../services/rest';

// GET
async function getOrdersType() {
  return RestService.getAuthenticated('typeOrder/list/all');
}

async function getOrdersTypeOptions() {
  return RestService.getAuthenticated('typeOrder/list/options');
}

async function getUsesIndustryProcess() {
  return RestService.getAuthenticated('typeOrder/list/getUsesIndustryProcess');
}

async function getGridOptions(typeOrder) {
  return RestService.getAuthenticated('typeOrder/getGridOptions', { typeOrder });
}

// POST
async function createTypeOrder(typeOrder = '') {
  return RestService.postAuthenticated('typeOrder/create', typeOrder);
}

// PUT
async function updateTypeOrders(updateTypeOrder) {
  return RestService.putAuthenticated('typeOrder/update', updateTypeOrder);
}

// DELETE
async function deleteOrder(typeOrder) {
  return RestService.postAuthenticated('typeOrder/delete', typeOrder);
}

export default {
  getUsesIndustryProcess,
  getOrdersType,
  createTypeOrder,
  updateTypeOrders,
  deleteOrder,
  getGridOptions,
  getOrdersTypeOptions,
};
