/* eslint-disable max-len */
import React from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  Nav, NavItem, NavLink, PopoverHeader, TabContent, TabPane,
  UncontrolledPopover,
} from 'reactstrap';
import getAddressByCep from '../../../../app/services/viaCep';
import { createBranch, getBranch, updateBranch } from '../../../../app/store/actions/branches';
import { getCompaniesOptions } from '../../../../app/store/actions/companies';
import { getDepositsOptions } from '../../../../app/store/actions/deposits';
import { getPackagesList } from '../../../../app/store/actions/packages';
import { getUsersOptions } from '../../../../app/store/actions/users';
import OneClickButton from '../../../../components/form/button';
import Select from '../../../../components/form/select';
import addNotification from '../../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';
import ROUTES from '../../../../config/routes';
import BranchHelpers from '../../../../helpers/branches';
import Form from '../../../../components/form/form';

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    isDisabled, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};
class BranchForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      activeTab: '1',
      mode: 'detail',
      disableCode: true,
      disableName: true,
      disableCompany: true,
      disableEmail: true,
      disablePhone: true,
      disableCNPJ: true,
      disableNote: true,
      disableStateRegistration: true,
      disablePostalCode: true,
      disableComplement: true,
      disableStreet: true,
      disableNumber: true,
      disableDistrict: true,
      disableCity: true,
      disableState: true,
      disableCountry: true,
      disableLimitProductsWave: true,
      disablePrefix: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      disableMandatoryComplementAddress: true,
      restrictSearchSepDoc: false,
      disableRestrictSearchSepDoc: true,
      code: '',
      name: '',
      company: '',
      companyName: '',
      email: '',
      phone: '',
      cnpj: '',
      stateRegistration: '',
      postalCode: '',
      street: '',
      number: '',
      complement: '',
      city: '',
      district: '',
      state: '',
      note: '',
      listCompany: '',
      limitProductsWave: 0,
      prefix: '',
      notConfirmProductWaveTransfer: false,
      dockPrintConsolidation: false,
      ruleSelect: [],
      rulesPriority: [],
      prioritySuggest: [],
      requestWeightDimensions: false,
      mandatoryWeightDimensions: false,
      preConference: false,
      receiveLeftover: false,
      partialInboundCheck: false,
      productAddressBranchCapacity: false,
      disableProductAddressBranchCapacity: true,
      inboundCheckPlate: false,
      separationMode: 1,
      disableRequestProduct: true,
      disableMandatoryProduct: true,
      disablePreConference: true,
      disableReceiveLeftover: true,
      disablePartialInboundCheck: true,
      disableInboundCheckPlate: true,
      disableRule: true,
      packageCode: null,
      packageName: '',
      preReceiptDeposit: null,
      preReceiptName: '',
      inboundDeposit: null,
      inboundName: '',
      resaleDeposit: null,
      resaleName: '',
      inventoryDeposit: null,
      inventoryName: '',
      divergenceDeposit: null,
      divergenceName: '',
      separationDivergenceDeposit: null,
      separationDivergenceName: '',
      damageDeposit: null,
      damageName: '',
      rejectedDeposit: null,
      rejectedName: '',
      qualityDeposit: '',
      qualityName: '',
      spareDeposit: null,
      spareName: '',
      intermediaryDeposit: null,
      intermediaryName: '',
      factoryDeposit: null,
      factoryName: '',
      disableDeposits: true,
      disablePackage: true,
      listDeposit: [],
      listPackages: [],
      withdrawUser: null,
      branchInInventory: false,
      serialControlDeposit: false,
      usesCableCuttingPlan: false,
      usesCarrierSchedule: false,
      dispatch: false,
      consolidation: false,
      dockControl: false,
      blindConference: false,
      automaticSeparationAfterCuttingCables: false,
      automaticCheckAfterPicking: false,
      disableConsolidation: true,
      disableDockControl: true,
      disableBlindConference: true,
      disableAutomaticSeparationAfterCuttingCables: true,
      disableAutomaticCheckAfterPicking: true,
      disableUsesCableCuttingPlan: true,
      disableBranchInInventory: true,
      disableSerialControlDeposit: true,
      mandatoryComplementAddress: true,
      requestResupplyQuantity: false,
      disableRequestResupplyQuantity: true,
      rulesPriorityResupply: [],
      prioritySuggestResupply: [],
      rulesResupply: [],
      restrictionPicking: false,
      disableRestrictionPicking: true,
      limitResupplyUser: 0,
      disableLimitResupplyUser: true,
      disableNotConfirmProductWaveTransfer: true,
      automaticReallocation: false,
      disableAutomaticReallocation: true,
      disabledDockPrintConsolidation: true,
      disableGenerateInventoryForSeparationDiscrepancy: false,
      generateInventoryForSeparationDiscrepancy: true,
      autoFinalizeNoDivergence: false,
      disableAutoFinalizeNoDivergence: true,
    };
  }

  async componentDidMount() {
    await this.setMode();

    const listDeposit = await this.props.getDepositsOptions();
    this.setState({
      listDeposit,
    });

    if (this.state.mode !== 'create') {
      await this.getBranchSelected();
    }

    const rulesPriorityResupply = await this.rulesPriorityResupply();

    const rulesPriority = await this.rulePriority();

    if (rulesPriority) {
      this.setState({
        rulesPriority,
      });
    }

    if (rulesPriorityResupply) {
      this.setState({
        rulesPriorityResupply,
      });
    }

    const listCompany = await this.props.getCompaniesOptions();

    await this.props.getPackagesList();
    const listPackages = (this.props.packagesList).map((element) => ({
      value: element.code,
      label: `${element.code} - ${element.name}`,
    }));

    this.setState({
      listCompany,
      listPackages,
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    if (name === 'usesCableCuttingPlan') {
      if (!value) {
        this.setState({
          disableAutomaticSeparationAfterCuttingCables: true,
          automaticSeparationAfterCuttingCables: false,
        });
      } else {
        this.setState({
          disableAutomaticSeparationAfterCuttingCables: false,
        });
      }
    } else if (name === 'serialControlDeposit') {
      this.setState({
        disableAutomaticReallocation: true,
        automaticReallocation: false,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  getBranchSelected = async () => {
    if (this.props.location.state.branch) {
      const { branch } = this.props.location.state;

      const branchSelected = await this.props.getBranch(branch.id);

      if (branchSelected) {
        this.setState({
          branchId: branchSelected.id,
          restrictSearchSepDoc: branchSelected.restrictSearchSepDoc,
          code: branchSelected.code,
          name: branchSelected.name,
          company: branchSelected.companyCode,
          companyName: branchSelected.companyName,
          email: branchSelected.email,
          phone: branchSelected.phone,
          cnpj: branchSelected.cnpj,
          stateRegistration: branchSelected.stateRegistration,
          note: branchSelected.note,
          postalCode: branchSelected.postalCode,
          ibge: branchSelected.ibge,
          street: branchSelected.street,
          city: branchSelected.city,
          number: branchSelected.number,
          complement: branchSelected.complement,
          district: branchSelected.district,
          state: branchSelected.state,
          country: branchSelected.country,
          limitProductsWave: branchSelected.limitProductsWave,
          requestWeightDimensions: branchSelected.requestWeightDimensions,
          mandatoryWeightDimensions: branchSelected.mandatoryWeightDimensions,
          preConference: branchSelected.preConference,
          receiveLeftover: branchSelected.receiveLeftover,
          productAddressBranchCapacity: branchSelected.productAddressBranchCapacity,
          partialInboundCheck: branchSelected.partialInboundCheck,
          inboundCheckPlate: branchSelected.inboundCheckPlate,
          separationMode: branchSelected.separationMode,
          prioritySuggest: branchSelected.prioritySuggest,
          preReceiptDeposit: branchSelected.preReceiptDeposit,
          inboundDeposit: branchSelected.inboundDeposit,
          resaleDeposit: branchSelected.resaleDeposit,
          inventoryDeposit: branchSelected.inventoryDeposit,
          divergenceDeposit: branchSelected.divergenceDeposit,
          separationDivergenceDeposit: branchSelected.separationDivergenceDeposit,
          damageDeposit: branchSelected.damageDeposit,
          rejectedDeposit: branchSelected.rejectedDeposit,
          qualityDeposit: branchSelected.qualityDeposit,
          spareDeposit: branchSelected.spareDeposit,
          intermediaryDeposit: branchSelected.intermediaryDeposit,
          factoryDeposit: branchSelected.factoryDeposit,
          preReceiptName: branchSelected.preReceiptName,
          inboundName: branchSelected.inboundName,
          resaleName: branchSelected.resaleName,
          inventoryName: branchSelected.inventoryName,
          divergenceName: branchSelected.divergenceName,
          separationDivergenceName: branchSelected.separationDivergenceName,
          damageName: branchSelected.damageName,
          rejectedName: branchSelected.rejectedName,
          qualityName: branchSelected.qualityName,
          spareName: branchSelected.spareName,
          intermediaryName: branchSelected.intermediaryName,
          factoryName: branchSelected.factoryName,
          withdrawUser: branchSelected.withdrawUser,
          branchInInventory: branchSelected.branchInInventory,
          serialControlDeposit: branchSelected.serialControlDeposit,
          usesCableCuttingPlan: branchSelected.usesCableCuttingPlan,
          usesCarrierSchedule: branchSelected.usesCarrierSchedule,
          dispatch: branchSelected.dispatch,
          consolidation: branchSelected.consolidation,
          dockControl: branchSelected.dockControl,
          blindConference: branchSelected.blindConference,
          automaticSeparationAfterCuttingCables: branchSelected.automaticSeparationAfterCuttingCables,
          automaticCheckAfterPicking: branchSelected.automaticCheckAfterPicking,
          packageCode: branchSelected.package,
          packageName: branchSelected.packageName,
          mandatoryComplementAddress: branchSelected.mandatoryComplementAddress,
          requestResupplyQuantity: branchSelected.requestResupplyQuantity,
          prioritySuggestResupply: branchSelected.prioritySuggestResupply,
          restrictionPicking: branchSelected.restrictionPicking,
          limitResupplyUser: branchSelected.limitResupplyUser,
          prefix: branchSelected.prefix,
          notConfirmProductWaveTransfer: branchSelected.notConfirmProductWaveTransfer,
          automaticReallocation: branchSelected.automaticReallocation,
          dockPrintConsolidation: branchSelected.dockPrintConsolidation,
          generateInventoryForSeparationDiscrepancy: branchSelected.generateInventoryForSeparationDiscrepancy,
          autoFinalizeNoDivergence: branchSelected.autoFinalizeNoDivergence,
        });
      }

      const rulesPriorityResupply = await this.rulesPriorityResupply();
      const rulesPriority = await this.rulePriority();

      if (rulesPriority) {
        this.setState({
          rulesPriority,
        });
      }

      if (rulesPriorityResupply) {
        this.setState({
          rulesPriorityResupply,
        });
      }
    }
  };

  setMode = () => {
    let mode;
    if (this.props.isCreate) mode = 'create';
    else if (this.props.isCopy) mode = 'copy';
    else if (this.props.isEdit) mode = 'edit';
    else if (this.props.isDetail) mode = 'detail';
    else mode = '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableMandatoryComplementAddress: false,
        disableRestrictSearchSepDoc: false,
        disableCode: false,
        disableName: false,
        disableCompany: false,
        disableEmail: false,
        disablePhone: false,
        disableCNPJ: false,
        disableNote: false,
        disableStateRegistration: false,
        disablePostalCode: false,
        disableComplement: false,
        disableStreet: false,
        disableNumber: false,
        disableLimitProductsWave: false,
        disableDistrict: false,
        disableCity: false,
        disableState: false,
        disableCountry: false,
        disableRequestProduct: false,
        disableMandatoryProduct: false,
        disablePreConference: false,
        disableReceiveLeftover: false,
        disableProductAddressBranchCapacity: false,
        disablePartialInboundCheck: false,
        disableInboundCheckPlate: false,
        disableRule: false,
        disableDeposits: false,
        disablePackage: false,
        disableUsesCableCuttingPlan: false,
        disableBranchInInventory: false,
        disableSerialControlDeposit: false,
        disableConsolidation: false,
        disableDockControl: false,
        disableBlindConference: false,
        disableAutomaticSeparationAfterCuttingCables: true,
        disableAutomaticCheckAfterPicking: false,
        disablePrefix: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
        disableRequestResupplyQuantity: false,
        disableRestrictionPicking: false,
        disableLimitResupplyUser: false,
        disableNotConfirmProductWaveTransfer: false,
        disableAutomaticReallocation: false,
        disabledDockPrintConsolidation: false,
        disableGenerateInventoryForSeparationDiscrepancy: false,
        disableAutoFinalizeNoDivergence: false,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableMandatoryComplementAddress: false,
        disableCode: true,
        disableRestrictSearchSepDoc: false,
        disableName: false,
        disableCompany: false,
        disableEmail: false,
        disablePhone: false,
        disableCNPJ: false,
        disableNote: false,
        disableStateRegistration: false,
        disablePostalCode: false,
        disableComplement: false,
        disableStreet: false,
        disableNumber: false,
        disableLimitProductsWave: false,
        disableDistrict: false,
        disableCity: false,
        disableState: false,
        disableCountry: false,
        disableRequestProduct: false,
        disableMandatoryProduct: false,
        disablePreConference: false,
        disableReceiveLeftover: false,
        disableProductAddressBranchCapacity: false,
        disablePartialInboundCheck: false,
        disableInboundCheckPlate: false,
        disableRule: false,
        disableDeposits: false,
        disablePackage: false,
        disableUsesCableCuttingPlan: false,
        disableBranchInInventory: false,
        disableSerialControlDeposit: false,
        disableConsolidation: false,
        disableDockControl: false,
        disableBlindConference: false,
        disableAutomaticSeparationAfterCuttingCables: false,
        disableAutomaticCheckAfterPicking: false,
        disablePrefix: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
        disableRequestResupplyQuantity: false,
        disableRestrictionPicking: false,
        disableLimitResupplyUser: false,
        disableNotConfirmProductWaveTransfer: false,
        disableAutomaticReallocation: false,
        disabledDockPrintConsolidation: false,
        disableGenerateInventoryForSeparationDiscrepancy: false,
        disableAutoFinalizeNoDivergence: false,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={label} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createInputMask = (value, attr, label, placeholder, mask, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={label} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <InputMask
          className="form-control"
          placeholder={placeholder}
          type={type}
          mask={mask}
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createSelectDropDown = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={label} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <Select
          value={value || ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
        />
      </div>
    </div>
  );

  createRadioButtons = (radios = [], value, attr, label) => (
    <div className="form-group row m-b-15" style={{ position: 'relative', left: '533px', textAlign: 'center' }}>
      {radios.map((radioItem = {}) => (
        <div
          key={`${attr}${radioItem.value}`}
          className="radio radio-css radio-inline"
        >
          <input
            type="radio"
            id={`${attr}${radioItem.value}`}
            value={radioItem.value}
            checked={`${radioItem.value}` === `${value}`}
            onChange={(e) => this.setValue(attr, e.target.value)}
            disabled={radioItem.disabled}
          />
          <label style={{ marginRight: '10px' }} htmlFor={`${attr}${radioItem.value}`}>{radioItem.label}</label>
        </div>
      ))}
      <div style={{
        position: 'relative', display: 'inline-block', height: '15px', top: '8px', borderLeft: '2px solid #ccc', margin: '0',
      }}
      />
      <label style={{ position: 'relative', bottom: '1px' }} htmlFor={label} className="col-form-label">
        {`${label}`}
      </label>

    </div>
  );

  createToggleBox = (item, attr, label, disabled, popOver = false) => (
    <div
      className="form-group row m-b-15"
      style={{ position: 'relative' }}
    >
      <label htmlFor={label} className="col-form-label col-md-4">{ }</label>
      <div className="col-md-8">
        <div className="row">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={this.handleInputChange}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`}>{label}</label>
          </div>
        </div>
      </div>
      {
        popOver && (
          <div style={{ position: 'absolute', right: '1060px', top: '1px' }}>
            <i style={{ cursor: 'pointer' }} id={attr} className="fas fa-lg fa-fw me-10px fa-info-circle" />
            <UncontrolledPopover trigger="hover" placement="top" target={attr}>
              <PopoverHeader>
                {I18n.t('BEE3664' /* Quando ativada, esta opção limitará as informações exibidas durante a busca de documentos de saída pelo coletor. Assim, ao tentar assumir um documento, o separador visualizará apenas os detalhes essenciais do documento, que incluem: Número do Documento, T */)}
              </PopoverHeader>
            </UncontrolledPopover>
          </div>
        )
      }
    </div>
  );

  setValue = async (attr, value) => {
    if (attr === 'postalCode') {
      const newPostalCode = value;
      const { postalCode } = this.state;

      if (newPostalCode !== postalCode) {
        await this.setState({
          [`${attr}`]: value,
        });

        const re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;

        if (re.test(newPostalCode)) {
          await this.searchViaCep(newPostalCode);
        } else {
          await this.cleanAddress();
        }
      }
      // Filtro para numeros maiores que 0, impedindo valores negativos.
    } else if (attr === 'limitProductsWave' || attr === 'limitResupplyUser') {
      if (parseInt(value, 10) >= 0 || value === '') {
        await this.setState({
          [`${attr}`]: value,
        });
      }
    } else {
      await this.setState({
        [`${attr}`]: value,
      });
    }
  };

  setValueDrop = async (attr, value) => {
    if (attr === 'company') {
      await this.setState({
        [`${attr}`]: value ? value.value : null,
        [`${attr}Name`]: value ? value.label : '',
      });
    } else if (attr === 'withdraw') {
      await this.setState({
        [`${attr}User`]: value ? value.value : null,
        [`${attr}Name`]: value ? value.label : '',
      });
    } else if (attr === 'package') {
      await this.setState({
        [`${attr}Code`]: value ? value.value : null,
        [`${attr}Name`]: value ? value.label : '',
      });
    } else {
      await this.setState({
        [`${attr}Deposit`]: value ? value.value : null,
        [`${attr}Name`]: value ? value.label : '',
      });
    }
  };

  cleanAddress = async () => {
    await this.setState({
      ibge: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
    });
  };

  searchViaCep = async (postalCode) => {
    const keepOnlyNumber = (value) => value.replace(/\D/g, '');
    const address = await getAddressByCep(keepOnlyNumber(postalCode));

    if (address.erro) {
      await this.cleanAddress();
    } else {
      await this.setState({
        street: address.logradouro,
        ibge: address.ibge,
        number: '',
        complement: '',
        district: address.bairro,
        city: address.localidade,
        state: address.uf,
        country: I18n.t('BEE103' /* Brasil */),
      });
    }
  };

  createBranchSubmit = async () => {
    const {
      code, name, company, email, phone, cnpj, stateRegistration, ibge, postalCode, street, number, complement,
      city, district, state, country, note, requestWeightDimensions, mandatoryWeightDimensions,
      partialInboundCheck, preConference, receiveLeftover, inboundCheckPlate, rulesPriority, separationMode,
      preReceiptDeposit, inboundDeposit, resaleDeposit, inventoryDeposit, divergenceDeposit, damageDeposit,
      rejectedDeposit, qualityDeposit, spareDeposit, intermediaryDeposit, withdrawUser, branchInInventory,
      usesCableCuttingPlan, usesCarrierSchedule, dispatch, consolidation, dockControl, blindConference,
      automaticSeparationAfterCuttingCables, automaticCheckAfterPicking, limitProductsWave, packageCode, packageName,
      mandatoryComplementAddress, requestResupplyQuantity, rulesPriorityResupply, restrictionPicking, limitResupplyUser,
      factoryDeposit, serialControlDeposit, prefix, notConfirmProductWaveTransfer, automaticReallocation,
      dockPrintConsolidation, generateInventoryForSeparationDiscrepancy, separationDivergenceDeposit,
      autoFinalizeNoDivergence, restrictSearchSepDoc, productAddressBranchCapacity,
    } = this.state;

    if (automaticCheckAfterPicking && !packageCode) {
      await addNotification(
        'danger',
        I18n.t('BEE2407' /* Insira Embalagem Padrão */),
        I18n.t('BEE2408' /* O CheckBox "Conferência Automática Após Separação Por Documento" está selecionado */),
        'top-right',
      );
      return;
    }

    try {
      const newBranch = await this.props.createBranch({
        code,
        name,
        restrictSearchSepDoc,
        companyCode: company,
        email,
        phone: phone ? phone.replace(/\D/g, '') : '',
        cnpj: cnpj ? cnpj.replace(/\D/g, '') : '',
        stateRegistration,
        ibge,
        postalCode: postalCode ? postalCode.replace(/\D/g, '') : '',
        street,
        number,
        complement,
        city,
        district,
        state,
        country,
        limitProductsWave,
        requestWeightDimensions,
        mandatoryWeightDimensions,
        preConference,
        receiveLeftover,
        productAddressBranchCapacity,
        partialInboundCheck,
        inboundCheckPlate,
        separationMode,
        prioritySuggest: rulesPriority.map((rule) => rule.value).toString(),
        preReceiptDeposit,
        inboundDeposit,
        resaleDeposit,
        inventoryDeposit,
        divergenceDeposit,
        separationDivergenceDeposit,
        damageDeposit,
        rejectedDeposit,
        qualityDeposit,
        spareDeposit,
        intermediaryDeposit,
        factoryDeposit,
        withdrawUser,
        branchInInventory,
        serialControlDeposit,
        usesCableCuttingPlan,
        usesCarrierSchedule,
        note,
        dispatch,
        consolidation,
        dockControl,
        blindConference,
        automaticSeparationAfterCuttingCables,
        automaticCheckAfterPicking,
        packageCode,
        packageName,
        mandatoryComplementAddress,
        requestResupplyQuantity,
        prioritySuggestResupply: rulesPriorityResupply.map((rule) => rule.value).toString(),
        restrictionPicking,
        limitResupplyUser,
        prefix,
        notConfirmProductWaveTransfer,
        automaticReallocation,
        dockPrintConsolidation,
        generateInventoryForSeparationDiscrepancy,
        autoFinalizeNoDivergence,
      });

      if (newBranch && newBranch.success === false) {
        await addNotification(
          'danger',
          I18n.t('BEE145' /* Filial */),
          I18n.t('BEE147', { 0: code } /* Erro ao incluir a Filial %{0}! */),
          'top-right',
        );
      } else {
        await addNotification(
          'success',
          I18n.t('BEE145' /* Filial */),
          I18n.t('BEE146', { 0: code } /* Filial %{0} incluída com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.BRANCH_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE145' /* Filial */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE145' /* Filial */),
            I18n.t('BEE147', { 0: code } /* Erro ao incluir a Filial %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE145' /* Filial */),
          I18n.t('BEE147', { 0: code } /* Erro ao incluir a Filial %{0}! */),
          'top-right',
        );
      }
    }
  };

  updateBranchSubmit = async () => {
    const {
      branchId, code, name, company, email, phone, cnpj, stateRegistration, ibge, postalCode, street, number,
      complement, city, district, state, country, note, requestWeightDimensions, mandatoryWeightDimensions,
      partialInboundCheck, preConference, receiveLeftover, inboundCheckPlate, rulesPriority, separationMode,
      preReceiptDeposit, inboundDeposit, resaleDeposit, inventoryDeposit, divergenceDeposit, damageDeposit,
      rejectedDeposit, qualityDeposit, spareDeposit, intermediaryDeposit, factoryDeposit, withdrawUser,
      branchInInventory, dispatch, consolidation, dockControl, blindConference, automaticSeparationAfterCuttingCables,
      usesCableCuttingPlan, usesCarrierSchedule, automaticCheckAfterPicking, limitProductsWave, packageCode,
      packageName, mandatoryComplementAddress, requestResupplyQuantity, rulesPriorityResupply, restrictionPicking,
      limitResupplyUser, serialControlDeposit, prefix, notConfirmProductWaveTransfer, automaticReallocation,
      dockPrintConsolidation, generateInventoryForSeparationDiscrepancy, separationDivergenceDeposit,
      autoFinalizeNoDivergence, restrictSearchSepDoc, productAddressBranchCapacity,
    } = this.state;

    if (automaticCheckAfterPicking && !packageCode) {
      await addNotification(
        'danger',
        I18n.t('BEE2407' /* Insira Embalagem Padrão */),
        I18n.t('BEE2408' /* O CheckBox "Conferência Automática Após Separação Por Documento" está selecionado */),
        'top-right',
      );
      return;
    }

    try {
      const updBranch = await this.props.updateBranch({
        branchId,
        name,
        restrictSearchSepDoc,
        companyCode: company,
        email,
        phone: phone ? phone.replace(/\D/g, '') : '',
        cnpj: cnpj ? cnpj.replace(/\D/g, '') : '',
        stateRegistration,
        ibge,
        postalCode: postalCode ? postalCode.replace(/\D/g, '') : '',
        street,
        number,
        complement,
        city,
        district,
        state,
        country,
        limitProductsWave,
        requestWeightDimensions,
        mandatoryWeightDimensions,
        preConference,
        receiveLeftover,
        productAddressBranchCapacity,
        partialInboundCheck,
        inboundCheckPlate,
        separationMode,
        prioritySuggest: rulesPriority.map((rule) => rule.value).toString(),
        preReceiptDeposit,
        inboundDeposit,
        resaleDeposit,
        inventoryDeposit,
        divergenceDeposit,
        separationDivergenceDeposit,
        damageDeposit,
        rejectedDeposit,
        qualityDeposit: qualityDeposit || '',
        spareDeposit: spareDeposit || '',
        intermediaryDeposit: intermediaryDeposit || '',
        factoryDeposit: factoryDeposit || '',
        withdrawUser,
        branchInInventory,
        serialControlDeposit,
        usesCableCuttingPlan,
        usesCarrierSchedule,
        note,
        dispatch,
        consolidation,
        dockControl,
        blindConference,
        automaticSeparationAfterCuttingCables,
        automaticCheckAfterPicking,
        packageCode,
        packageName,
        mandatoryComplementAddress,
        requestResupplyQuantity,
        prioritySuggestResupply: rulesPriorityResupply.map((rule) => rule.value).toString(),
        restrictionPicking,
        limitResupplyUser,
        prefix,
        notConfirmProductWaveTransfer,
        automaticReallocation,
        dockPrintConsolidation,
        generateInventoryForSeparationDiscrepancy,
        autoFinalizeNoDivergence,
      });

      if (updBranch) {
        await addNotification(
          'success',
          I18n.t('BEE148' /* Atualizar Filial */),
          I18n.t('BEE149', { 0: code } /* Atualização da Filial %{0} efetuada com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.BRANCH_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE148' /* Atualizar Filial */),
            `${error.code} - ${error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE148' /* Atualizar Filial */),
            I18n.t('BEE150', { 0: code } /* Erro ao atualizar a Filial %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE148' /* Atualizar Filial */),
          I18n.t('BEE150', { 0: code } /* Erro ao atualizar a Filial %{0}! */),
          'top-right',
        );
      }
    }
  };

  rulePriority = async () => {
    const rules = BranchHelpers.listRulesStorage();
    const { prioritySuggest = [] } = this.state;

    let priority = 1;

    if (prioritySuggest && prioritySuggest.length > 0) {
      const priorityArray = prioritySuggest.split(',');

      if (priorityArray && priorityArray.length > 0) {
        return priorityArray.map((suggest) => {
          const rule = rules.find((rl) => parseInt(rl.value, 10) === parseInt(suggest, 10));
          const returnValue = {
            value: suggest,
            label: rule.label,
            priority: priority += 1,
          };
          return returnValue;
        });
      }
    }

    return rules.map((rule) => {
      const returnValue = {
        value: rule.value,
        label: rule.label,
        priority: priority += 1,
      };
      return returnValue;
    });
  };

  rulesPriorityResupply = async () => {
    const rulesResupply = BranchHelpers.listRulesResupply();
    const { prioritySuggestResupply = [] } = this.state;

    let priority = 1;

    if (prioritySuggestResupply && prioritySuggestResupply.length > 0) {
      const priorityArray = prioritySuggestResupply.split(',');

      if (priorityArray && priorityArray.length > 0) {
        return priorityArray.map((suggest) => {
          const rules = rulesResupply.find((rl) => parseInt(rl.value, 10) === parseInt(suggest, 10));
          const returnValue = {
            value: suggest,
            label: rules.label,
            priority: priority += 1,
          };
          return returnValue;
        });
      }
    }

    return rulesResupply.map((rules) => {
      const returnValue = {
        value: rules.value,
        label: rules.label,
        priority: priority += 1,
      };
      return returnValue;
    });
  };

  upPriority = async (requestResupplyQuantity) => {
    if (requestResupplyQuantity) {
      const { rulesResupply, rulesPriorityResupply } = this.state;

      const rSelect = rulesPriorityResupply.find((rules) => parseInt(rules.value, 10) === parseInt(rulesResupply, 10));
      const rUp = rulesPriorityResupply.find(
        (rules) => parseInt(rules.priority, 10) === parseInt(rSelect.priority - 1, 10),
      );

      if (!rUp) return;

      rSelect.priority -= 1;
      rUp.priority += 1;

      const newlist = rulesPriorityResupply.map((rules) => {
        if (rules.value === rSelect.value) rules.priority = rSelect.priority;
        if (rules.value === rUp.value) rules.priority = rUp.priority;

        return rules;
      });

      if (newlist) {
        newlist.sort((a, b) => (a.priority < b.priority ? -1 : 1));
        this.setState({
          rulesPriorityResupply: newlist,
        });
      }
    } else {
      const { ruleSelect, rulesPriority } = this.state;

      const rSelect = rulesPriority.find((rule) => parseInt(rule.value, 10) === parseInt(ruleSelect, 10));

      const rUp = rulesPriority.find((rule) => parseInt(rule.priority, 10) === parseInt(rSelect.priority - 1, 10));

      if (!rUp) return;

      rSelect.priority -= 1;
      rUp.priority += 1;

      const newlist = rulesPriority.map((rule) => {
        if (rule.value === rSelect.value) rule.priority = rSelect.priority;
        if (rule.value === rUp.value) rule.priority = rUp.priority;

        return rule;
      });

      if (newlist) {
        newlist.sort((a, b) => (a.priority < b.priority ? -1 : 1));
        this.setState({
          rulesPriority: newlist,
        });
      }
    }
  };

  downPriority = async (requestResupplyQuantity) => {
    if (requestResupplyQuantity) {
      const { rulesResupply, rulesPriorityResupply } = this.state;

      const rSelect = rulesPriorityResupply.find((rules) => parseInt(rules.value, 10) === parseInt(rulesResupply, 10));
      const rUp = rulesPriorityResupply.find(
        (rules) => parseInt(rules.priority, 10) === parseInt(rSelect.priority + 1, 10),
      );

      if (!rUp) return;

      rSelect.priority += 1;
      rUp.priority -= 1;

      const newlist = rulesPriorityResupply.map((rules) => {
        if (rules.value === rSelect.value) rules.priority = rSelect.priority;
        if (rules.value === rUp.value) rules.priority = rUp.priority;

        return rules;
      });

      if (newlist) {
        newlist.sort((a, b) => (a.priority < b.priority ? -1 : 1));
        this.setState({
          rulesPriorityResupply: newlist,
        });
      }
    } else {
      const { ruleSelect, rulesPriority } = this.state;

      const rSelect = rulesPriority.find(
        (rule) => parseInt(rule.value, 10) === parseInt(ruleSelect, 10),
      );

      const rDown = rulesPriority.find(
        (rule) => parseInt(rule.priority, 10) === parseInt(rSelect.priority + 1, 10),
      );

      if (!rDown) return;

      rSelect.priority += 1;
      rDown.priority -= 1;

      const newlist = rulesPriority.map((rule) => {
        if (rule.value === rSelect.value) rule.priority = rSelect.priority;
        if (rule.value === rDown.value) rule.priority = rDown.priority;

        return rule;
      });

      if (newlist) {
        newlist.sort((a, b) => (a.priority < b.priority ? -1 : 1));
        this.setState({
          rulesPriority: newlist,
        });
      }
    }
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      code, name, company, companyName, email, phone, cnpj, notConfirmProductWaveTransfer,
      stateRegistration, postalCode, street, number, prefix, automaticReallocation,
      dockPrintConsolidation, complement, city, district, state, country, note, autoFinalizeNoDivergence,
      listCompany, limitProductsWave, limitResupplyUser, generateInventoryForSeparationDiscrepancy,
    } = this.state;

    const {
      mode, disableCode, disableName, disableCompany, disableEmail, disableLimitProductsWave,
      disablePhone, disableCNPJ, disableStateRegistration, disablePostalCode,
      disableStreet, disableNumber, disableComplement, disableCity, disableDistrict,
      disableState, disableNote, disableCountry, showCancel, showCreate, showUpdate,
      disableMandatoryComplementAddress, disableRequestResupplyQuantity,
      disableLimitResupplyUser, disablePrefix, disableGenerateInventoryForSeparationDiscrepancy,
    } = this.state;

    const {
      requestWeightDimensions, mandatoryWeightDimensions, partialInboundCheck, inboundCheckPlate,
      ruleSelect, rulesPriority, preConference, disableRequestProduct, disableMandatoryProduct,
      disablePartialInboundCheck, disableInboundCheckPlate, disableRule, disablePreConference,
      receiveLeftover, disableReceiveLeftover, rulesPriorityResupply, rulesResupply, productAddressBranchCapacity,
      disableProductAddressBranchCapacity,
    } = this.state;

    const {
      preReceiptDeposit, inboundDeposit, resaleDeposit, inventoryDeposit, divergenceDeposit,
      damageDeposit, rejectedDeposit, qualityDeposit, spareDeposit, intermediaryDeposit,
      disableDeposits, preReceiptName, inboundName, resaleName, inventoryName, divergenceName,
      damageName, disablePackage, rejectedName, qualityName, spareName, intermediaryName, listDeposit, listPackages,
      factoryDeposit, factoryName, restrictSearchSepDoc,
    } = this.state;

    const {
      usesCableCuttingPlan, consolidation, dockControl, blindConference,
      automaticSeparationAfterCuttingCables, automaticCheckAfterPicking, packageCode, packageName,
    } = this.state;

    const {
      branchInInventory, disableBranchInInventory, disableUsesCableCuttingPlan, disableSerialControlDeposit,
      disableConsolidation, disableDockControl, disableBlindConference, serialControlDeposit,
      disableAutomaticSeparationAfterCuttingCables, disableAutomaticCheckAfterPicking,
      mandatoryComplementAddress, requestResupplyQuantity, restrictionPicking, disableRestrictionPicking,
      disableNotConfirmProductWaveTransfer, disableAutomaticReallocation, disabledDockPrintConsolidation,
      separationDivergenceName, separationDivergenceDeposit, disableAutoFinalizeNoDivergence,
      disableRestrictSearchSepDoc,
    } = this.state;

    let title = '';
    let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE151' /* Nova Filial */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE152' /* Editar Filial */);
      breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE153' /* Detalhes Filial */);
      breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE16' /* Filiais */)}</li>
            <li className="breadcrumb-item active">{breadActive}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <div>
              <Panel className="panel-with-tabs">
                <PanelHeader noButton />
                <PanelBody>
                  <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '1' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('1');
                        }}
                      >
                        <span className="d-sm-none">Tab 1</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE370' /* Dados */)}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '2' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('2');
                        }}
                      >
                        <span className="d-sm-none">Tab 2</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE191' /* Geral */)}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '3' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('3');
                        }}
                      >
                        <span className="d-sm-none">Tab 3</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE36' /* Recebimento */)}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '4' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('4');
                        }}
                      >
                        <span className="d-sm-none">Tab 4</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE43' /* Separação */)}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '5' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('5');
                        }}
                      >
                        <span className="d-sm-none">Tab 5</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE18' /* Depósitos */)}</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '6' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('6');
                        }}
                      >
                        <span className="d-sm-none">Tab 6</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE572' /* Inventário */)}</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '7' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('7');
                        }}
                      >
                        <span className="d-sm-none">Tab 7</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE2642' /* Ressuprimento */)}</span>
                      </NavLink>
                    </NavItem>

                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="row">
                        <div className="col-xl-12">
                          <Form
                            noPanel
                            setValue={this.setValue}
                            setValueDrop={this.setValueDrop}
                            inputs={(formContext) => ([
                              formContext.createInput(
                                code,
                                'code',
                                `${I18n.t('BEE82' /* Código */)}:`,
                                I18n.t('BEE1781' /* Informe a filial */),
                                'text',
                                true,
                                disableCode,
                              ),
                              formContext.createInput(
                                name,
                                'name',
                                `${I18n.t('BEE83' /* Nome */)}:`,
                                I18n.t('BEE3671' /* Informe o nome da filial */),
                                'text',
                                true,
                                disableName,
                              ),
                              disableCompany
                                ? formContext.createInput(
                                  companyName,
                                  'company',
                                  `${I18n.t('BEE112' /* Empresa */)}:`,
                                  '',
                                  'text',
                                  false,
                                  disableCompany,
                                )
                                : formContext.createSelectDropDown(
                                  { value: company, label: companyName },
                                  'company',
                                  `${I18n.t('BEE112' /* Empresa */)}:`,
                                  listCompany,
                                  disableCompany,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  true,
                                ),
                              formContext.createInputMask(
                                cnpj,
                                'cnpj',
                                `${I18n.t('BEE85' /* CNPJ */)}:`,
                                I18n.t('BEE3668' /* Informe o CNPJ */),
                                '99.999.999/9999-99',
                                true,
                                disableCNPJ,
                              ),
                              formContext.createInput(
                                stateRegistration,
                                'stateRegistration',
                                `${I18n.t('BEE117' /* Inscrição Estadual */)}:`,
                                I18n.t('BEE3669' /* Informe a inscrição estadual */),
                                'text',
                                true,
                                disableStateRegistration,
                              ),
                              formContext.createInputMask(
                                postalCode,
                                'postalCode',
                                `${I18n.t('BEE119' /* CEP */)}:`,
                                I18n.t('BEE3670' /* Informe o CEP */),
                                '99999-999',
                                true,
                                disablePostalCode,
                              ),
                              formContext.createInput(
                                street,
                                'street',
                                `${I18n.t('BEE121' /* Rua */)}:`,
                                I18n.t('BEE122' /* Informe a rua */),
                                'text',
                                true,
                                disableStreet,
                              ),
                              formContext.createInput(
                                number,
                                'number',
                                `${I18n.t('BEE123' /* Número */)}:`,
                                I18n.t('BEE124' /* Informe o número */),
                                'text',
                                false,
                                disableNumber,
                              ),
                              formContext.createInput(
                                complement,
                                'complement',
                                `${I18n.t('BEE125' /* Complemento */)}:`,
                                I18n.t('BEE126' /* Informe o complemento */),
                                'text',
                                false,
                                disableComplement,
                              ),
                              formContext.createInput(
                                district,
                                'district',
                                `${I18n.t('BEE127' /* Bairro */)}:`,
                                I18n.t('BEE128' /* Informe o bairro */),
                                'text',
                                true,
                                disableDistrict,
                              ),
                              formContext.createInput(
                                city,
                                'city',
                                `${I18n.t('BEE53' /* Cidade */)}:`,
                                I18n.t('BEE129' /* Informe a cidade */),
                                'text',
                                true,
                                disableCity,
                              ),
                              formContext.createInput(
                                state,
                                'state',
                                `${I18n.t('BEE87' /* Estado */)}:`,
                                I18n.t('BEE130' /* Informe o estado */),
                                'text',
                                true,
                                disableState,
                              ),
                              formContext.createInput(
                                country,
                                'country',
                                `${I18n.t('BEE88' /* País */)}:`,
                                I18n.t('BEE131' /* Informe o país */),
                                'text',
                                true,
                                disableCountry,
                              ),
                              formContext.createInput(
                                email,
                                'email',
                                `${I18n.t('BEE86' /* E-mail */)}:`,
                                I18n.t('BEE898' /* Informe o e-mail */),
                                'text',
                                false,
                                disableEmail,
                              ),
                              formContext.createInputMask(
                                phone,
                                'phone',
                                `${I18n.t('BEE133' /* Telefone */)}:`,
                                I18n.t('BEE134' /* Informe o telefone */),
                                phone && phone.length < 11 ? '(99) 9999-9999' : '(99) 99999-9999',
                                false,
                                disablePhone,
                              ),
                              formContext.createInput(
                                note,
                                'note',
                                `${I18n.t('BEE135' /* Observação */)}:`,
                                I18n.t('BEE136' /* Informe a observação */),
                                'text',
                                false,
                                disableNote,
                              ),
                            ])}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="row">
                        <div className="col-xl-12">
                          {this.createToggleBox(
                            serialControlDeposit,
                            'serialControlDeposit',
                            I18n.t('BEE2955' /* Controle de Etiquetas Seriadas por Depósito */),
                            disableSerialControlDeposit,
                          )}
                          {this.createToggleBox(
                            notConfirmProductWaveTransfer,
                            'notConfirmProductWaveTransfer',
                            I18n.t('BEE3321' /* Não confirma o produto na transferência por onda (entrada) */),
                            disableNotConfirmProductWaveTransfer,
                          )}
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      {this.createToggleBox(
                        inboundCheckPlate,
                        'inboundCheckPlate',
                        I18n.t('BEE399' /* Conferência de Placa */),
                        disableInboundCheckPlate,
                      )}
                      {this.createToggleBox(
                        partialInboundCheck,
                        'partialInboundCheck',
                        I18n.t('BEE783' /* Conferência física parcial */),
                        disablePartialInboundCheck,
                      )}
                      {this.createToggleBox(
                        requestWeightDimensions,
                        'requestWeightDimensions',
                        I18n.t('BEE784' /* Solicitar pesos e dimensões do produto na conferência Física */),
                        disableRequestProduct,
                      )}
                      {this.createToggleBox(
                        mandatoryWeightDimensions,
                        'mandatoryWeightDimensions',
                        I18n.t('BEE786' /* Pesagem e medição obrigatórios */),
                        disableMandatoryProduct,
                      )}
                      {this.createToggleBox(
                        preConference,
                        'preConference',
                        I18n.t('BEE1792' /* Pré-conferência */),
                        disablePreConference,
                      )}
                      {this.createToggleBox(
                        receiveLeftover,
                        'receiveLeftover',
                        I18n.t('BEE1967' /* Permite receber Sobra de Produto */),
                        disableReceiveLeftover,
                      )}
                      {this.createToggleBox(
                        productAddressBranchCapacity,
                        'productAddressBranchCapacity',
                        I18n.t('BEE2658' /* Capacidade Produto x Classificação x Filial */),
                        disableProductAddressBranchCapacity,
                      )}
                      <div className="form-group row m-b-15">
                        <span className="col-form-label col-md-4">
                          {I18n.t('BEE787' /* Regra sugestão endereço */)}
                          :
                        </span>
                        <div className="col-md-4" style={{ paddingLeft: '0px' }}>
                          <select
                            multiple
                            className="form-control"
                            value={ruleSelect || ''}
                            disabled={disableRule}
                            onChange={(e) => this.setValue('ruleSelect', e.target.value)}
                          >
                            {rulesPriority.map((rule) => (
                              <option key={rule.value} value={rule.value}>{rule.label}</option>
                            ))}
                          </select>
                        </div>
                        <div className="rdl-actions" style={{ margin: '10px' }}>
                          <button
                            className="rdl-move rdl-move-up"
                            type="button"
                            disabled={disableRule}
                            onClick={() => ((ruleSelect.length === 0) ? '' : this.upPriority())}
                          >
                            <span className="fa fa-chevron-up" />
                          </button>
                          <button
                            className="rdl-move rdl-move-down"
                            type="button"
                            disabled={disableRule}
                            onClick={() => ((ruleSelect.length === 0) ? '' : this.downPriority())}
                          >
                            <span className="fa fa-chevron-down" />
                          </button>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div className="ml-3">
                        {this.createToggleBox(
                          usesCableCuttingPlan,
                          'usesCableCuttingPlan',
                          I18n.t('BEE780' /* Utiliza plano de corte de cabo */),
                          disableUsesCableCuttingPlan,
                        )}
                        {this.createToggleBox(
                          restrictSearchSepDoc,
                          'restrictSearchSepDoc',
                          I18n.t('BEE3662' /* Restringir informações na busca de Doc de separação */),
                          disableRestrictSearchSepDoc,
                          true,
                        )}
                        {this.createToggleBox(
                          automaticSeparationAfterCuttingCables,
                          'automaticSeparationAfterCuttingCables',
                          I18n.t('BEE1054' /* Separação Automática Após Corte de Cabos */),
                          disableAutomaticSeparationAfterCuttingCables,
                        )}
                        {this.createToggleBox(
                          blindConference,
                          'blindConference',
                          I18n.t('BEE1053' /* Conferência a Cegas */),
                          disableBlindConference,
                        )}
                        {this.createToggleBox(
                          consolidation,
                          'consolidation',
                          I18n.t('BEE1046' /* Consolidação */),
                          disableConsolidation,
                        )}
                        {this.createToggleBox(
                          dockControl,
                          'dockControl',
                          I18n.t('BEE1049' /* Controla Doca */),
                          disableDockControl,
                        )}
                        {this.createToggleBox(
                          dockPrintConsolidation,
                          'dockPrintConsolidation',
                          I18n.t('BEE3371' /* Imprimir etiquetas de consolidação ao fechar a doca */),
                          disabledDockPrintConsolidation,
                        )}
                        {this.createToggleBox(
                          restrictionPicking,
                          'restrictionPicking',
                          I18n.t('BEE2778' /* Utilizar Monitor de Restrição de Separação */),
                          disableRestrictionPicking,
                        )}
                        {this.createToggleBox(
                          automaticCheckAfterPicking,
                          'automaticCheckAfterPicking',
                          I18n.t('BEE2354' /* Conferência Automática Após Separação Por Documento */),
                          disableAutomaticCheckAfterPicking,
                        )}
                        {this.createToggleBox(
                          automaticReallocation,
                          'automaticReallocation',
                          I18n.t('BEE3383' /* Realocação Automática na Divergência da Separação */),
                          disableAutomaticReallocation || serialControlDeposit,
                        )}
                      </div>
                      {this.createInput(
                        limitProductsWave,
                        'limitProductsWave',
                        `${I18n.t('BEE1664' /* Limite de Produto por Onda */)}:`,
                        I18n.t('BEE1667' /* Informe o Limite de Produto por Onda */),
                        'number',
                        false,
                        disableLimitProductsWave,
                      )}
                      {(disablePackage
                        ? this.createInput(
                          packageName,
                          'package',
                          `${I18n.t('BEE2355' /* Embalagem Padrão */)}:`,
                          '',
                          'text',
                          false,
                          disablePackage,
                        )
                        : this.createSelectDropDown(
                          { value: packageCode, label: packageName },
                          'package',
                          `${I18n.t('BEE2355' /* Embalagem Padrão */)}:`,
                          listPackages,
                        )
                      )}
                      {this.createInput(
                        prefix,
                        'prefix',
                        `${I18n.t('BEE2990' /* Prefixo do código do produto: */)}:`,
                        I18n.t('BEE2991' /* Informe o prefixo */),
                        'text',
                        false,
                        disablePrefix,
                      )}
                    </TabPane>
                    <TabPane tabId="5">
                      <div className="row">
                        <div className="col-xl-12">
                          {(disableDeposits
                            ? this.createInput(
                              preReceiptName,
                              'preReceipt',
                              `${I18n.t('BEE36' /* Recebimento */)}:`,
                              '',
                              'text',
                              false,
                              disableDeposits,
                            )
                            : this.createSelectDropDown(
                              { value: preReceiptDeposit, label: preReceiptName },
                              'preReceipt',
                              `${I18n.t('BEE36' /* Recebimento */)}:`,
                              listDeposit.filter((dep) => dep.inbound === 1),
                            )
                          )}
                          {(disableDeposits
                            ? this.createInput(
                              inboundName,
                              'inbound',
                              `${I18n.t('BEE38' /* Armazenamento */)}:`,
                              '',
                              'text',
                              false,
                              disableDeposits,
                            )
                            : this.createSelectDropDown(
                              { value: inboundDeposit, label: inboundName },
                              'inbound',
                              `${I18n.t('BEE38' /* Armazenamento */)}:`,
                              listDeposit.filter((dep) => dep.inbound === 1),
                            )
                          )}
                          {(disableDeposits
                            ? this.createInput(
                              resaleName,
                              'resale',
                              `${I18n.t('BEE571' /* Revenda */)}:`,
                              '',
                              'text',
                              false,
                              disableDeposits,
                            )
                            : this.createSelectDropDown(
                              { value: resaleDeposit, label: resaleName },
                              'resale',
                              `${I18n.t('BEE571' /* Revenda */)}:`,
                              listDeposit.filter((dep) => dep.resale === 1),
                            )
                          )}
                          {(disableDeposits
                            ? this.createInput(
                              inventoryName,
                              'inventory',
                              `${I18n.t('BEE572' /* Inventário */)}:`,
                              '',
                              'text',
                              false,
                              disableDeposits,
                            )
                            : this.createSelectDropDown(
                              { value: inventoryDeposit, label: inventoryName },
                              'inventory',
                              `${I18n.t('BEE572' /* Inventário */)}:`,
                              listDeposit.filter((dep) => dep.inventory === 1),
                            )
                          )}
                          {(disableDeposits
                            ? this.createInput(
                              divergenceName,
                              'divergence',
                              `${I18n.t('BEE3588' /* Divergência no Recebimento */)}:`,
                              '',
                              'text',
                              false,
                              disableDeposits,
                            )
                            : this.createSelectDropDown(
                              { value: divergenceDeposit, label: divergenceName },
                              'divergence',
                              `${I18n.t('BEE3588' /* Divergência no Recebimento */)}:`,
                              listDeposit.filter((dep) => dep.divergence === 1),
                            )
                          )}
                          {(disableDeposits
                            ? this.createInput(
                              separationDivergenceName,
                              'separationDivergence',
                              `${I18n.t('BEE3586' /* Divergência na Separação */)}:`,
                              '',
                              'text',
                              false,
                              disableDeposits,
                            )
                            : this.createSelectDropDown(
                              { value: separationDivergenceDeposit, label: separationDivergenceName },
                              'separationDivergence',
                              `${I18n.t('BEE3586' /* Divergência na Separação */)}:`,
                              listDeposit.filter((dep) => dep.separationDivergence === 1),
                            )
                          )}
                          {(disableDeposits
                            ? this.createInput(
                              damageName,
                              'damage',
                              `${I18n.t('BEE573' /* Avaria */)}:`,
                              '',
                              'text',
                              false,
                              disableDeposits,
                            )
                            : this.createSelectDropDown(
                              { value: damageDeposit, label: damageName },
                              'damage',
                              `${I18n.t('BEE573' /* Avaria */)}:`,
                              listDeposit.filter((dep) => dep.damage === 1),
                            )
                          )}
                          {(disableDeposits
                            ? this.createInput(
                              rejectedName,
                              'rejected',
                              `${I18n.t('BEE177' /* Rejeitados */)}:`,
                              '',
                              'text',
                              false,
                              disableDeposits,
                            )
                            : this.createSelectDropDown(
                              { value: rejectedDeposit, label: rejectedName },
                              'rejected',
                              `${I18n.t('BEE177' /* Rejeitados */)}:`,
                              listDeposit.filter((dep) => dep.rejected === 1),
                            )
                          )}
                          {(disableDeposits
                            ? this.createInput(
                              qualityName,
                              'quality',
                              `${I18n.t('BEE574' /* Qualidade */)}:`,
                              '',
                              'text',
                              false,
                              disableDeposits,
                            )
                            : this.createSelectDropDown(
                              { value: qualityDeposit, label: qualityName },
                              'quality',
                              `${I18n.t('BEE574' /* Qualidade */)}:`,
                              listDeposit.filter((dep) => dep.quality === 1),
                            )
                          )}
                          {(disableDeposits
                            ? this.createInput(
                              spareName,
                              'spare',
                              `${I18n.t('BEE789' /* Sobra */)}:`,
                              '',
                              'text',
                              false,
                              disableDeposits,
                            )
                            : this.createSelectDropDown(
                              { value: spareDeposit, label: spareName },
                              'spare',
                              `${I18n.t('BEE789' /* Sobra */)}:`,
                              listDeposit.filter((dep) => dep.spare === 1),
                            )
                          )}
                          {(disableDeposits
                            ? this.createInput(
                              intermediaryName,
                              'intermediary',
                              `${I18n.t('BEE2832' /* Intermediário */)}:`,
                              '',
                              'text',
                              false,
                              disableDeposits,
                            )
                            : this.createSelectDropDown(
                              { value: intermediaryDeposit, label: intermediaryName },
                              'intermediary',
                              `${I18n.t('BEE2832' /* Intermediário */)}:`,
                              listDeposit.filter((dep) => dep.intermediary === 1),
                            )
                          )}
                          {(disableDeposits
                            ? this.createInput(
                              factoryName,
                              'factory',
                              `${I18n.t('BEE2873' /* Fábrica */)}:`,
                              '',
                              'text',
                              false,
                              disableDeposits,
                            )
                            : this.createSelectDropDown(
                              { value: factoryDeposit, label: factoryName },
                              'factory',
                              `${I18n.t('BEE2873' /* Fábrica */)}:`,
                              listDeposit.filter((dep) => dep.factory === 1),
                            )
                          )}
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="6">
                      {this.createToggleBox(
                        branchInInventory,
                        'branchInInventory',
                        I18n.t('BEE779' /* Filial em Inventário */),
                        disableBranchInInventory,
                      )}
                      {this.createToggleBox(
                        mandatoryComplementAddress,
                        'mandatoryComplementAddress',
                        I18n.t('BEE3191' /* Obrigatório gerar ficha para endereços complementares */),
                        disableMandatoryComplementAddress,
                      )}
                      {this.createToggleBox(
                        generateInventoryForSeparationDiscrepancy,
                        'generateInventoryForSeparationDiscrepancy',
                        I18n.t('BEE3583' /* Gerar inventário para discrepância de separação */),
                        disableGenerateInventoryForSeparationDiscrepancy,
                      )}
                      {this.createToggleBox(
                        autoFinalizeNoDivergence,
                        'autoFinalizeNoDivergence',
                        I18n.t('BEE3627' /* Finaliza automaticamente fichas sem divergências */),
                        disableAutoFinalizeNoDivergence,
                      )}
                    </TabPane>
                    <TabPane tabId="7">
                      <Form
                        noPanel
                        setValue={this.setValue}
                        setValueDrop={this.setValueDrop}
                        inputs={(formContext) => ([
                          formContext.createInput(
                            limitResupplyUser,
                            'limitResupplyUser',
                            `${I18n.t('BEE2838' /* Quantidade máxima Ressuprimentos por usuário */)}:`,
                            I18n.t('BEE2839' /* Informe o máximo de Ressuprimentos por usuário */),
                            number,
                            false,
                            disableLimitResupplyUser,
                            undefined,
                            4,
                            undefined,
                            'limitResupplyUser',
                            20,
                          ),
                          formContext.createCheckBoxes(
                            [
                              {
                                value: requestResupplyQuantity,
                                attr: 'requestResupplyQuantity',
                                disabled: disableRequestResupplyQuantity,
                              },
                            ],
                            `${I18n.t('BEE2643' /* Não sugerir a quantidade no ressuprimento */)}:`,
                            undefined,
                            ['col-md-3', 'col-md-auto'],
                          ),
                        ])}
                      />
                      <div className="form-group row m-b-15">
                        <span className="col-form-label col-md-4 me-5">
                          {I18n.t('BEE2644' /* Fila de Priorização do Ressuprimentos */)}
                          :
                        </span>
                        <div className="col-md-4" style={{ marginLeft: '0.1rem' }}>
                          <select
                            multiple
                            className="form-control"
                            value={rulesResupply || ''}
                            disabled={disableRule}
                            onChange={(e) => this.setValue('rulesResupply', e.target.value)}
                          >
                            {rulesPriorityResupply.map((rule) => (
                              <option key={rule.value} value={rule.value}>{rule.label}</option>
                            ))}
                          </select>
                        </div>
                        <div className="rdl-actions" style={{ margin: '10px' }}>
                          <button
                            className="rdl-move rdl-move-up"
                            type="button"
                            disabled={disableRule}
                            onClick={() => ((rulesResupply.length === 0)
                              ? '' : this.upPriority('requestResupplyQuantity'))}
                          >
                            <span className="fa fa-chevron-up" />
                          </button>
                          <button
                            className="rdl-move rdl-move-down"
                            type="button"
                            disabled={disableRule}
                            onClick={() => ((rulesResupply.length === 0)
                              ? '' : this.downPriority('requestResupplyQuantity'))}
                          >
                            <span className="fa fa-chevron-down" />
                          </button>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.BRANCH_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE137' /* Voltar */)}
                        </OneClickButton>
                      ))}
                  </Link>
                  {(showCreate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.createBranchSubmit()}
                      >
                        {I18n.t('BEE138' /* Criar */)}

                      </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.updateBranchSubmit()}
                      >
                        {I18n.t('BEE139' /* Atualizar */)}

                      </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  packagesList: state.packages && state.packages.packagesList,
});

const mapDispatchToProps = (dispatch) => ({
  getBranch: (branchId) => dispatch(getBranch(branchId)),
  createBranch: (branch) => dispatch(createBranch(branch)),
  updateBranch: (branch) => dispatch(updateBranch(branch)),
  getCompaniesOptions: () => dispatch(getCompaniesOptions()),
  getDepositsOptions: () => dispatch(getDepositsOptions()),
  getUsersOptions: () => dispatch(getUsersOptions()),
  getPackagesList: () => dispatch(getPackagesList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BranchForm));
