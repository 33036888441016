import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

import InboundOrderHelpers from '../../../helpers/inboundOrders';
import FormatHelpers from '../../../helpers/format';

export default class ProductsList extends React.Component {  
  render() {
    const { products, getStorableProduct } = this.props;

    return (
      <div className="table-responsive">
        <table className={'table table-bordered widget-table widget-table-rounded'}>
          <thead>
            <tr className="text-center f-s-12">
              <th width="5%">{I18n.t('BEE463' /* Linha */)}</th>
              <th width="30%">{I18n.t('BEE225' /* Produto */)}</th>
              <th width="10%">{I18n.t('BEE428' /* Lote */)}</th>
              <th width="10%">{I18n.t('BEE434' /* Data Validade */)}</th>
              <th width="10%">{I18n.t('BEE384' /* Unidade */)}</th>
              <th width="10%">{I18n.t('BEE441' /* Quantidade */)}</th>
              <th width="30%">{I18n.t('BEE135' /* Observação */)}</th>
              <th width="5%">{`${I18n.t('BEE427' /* Armazenado */)}?`}</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => {
              const textClass = product.status >= 4
                ? ' text-teal'
                : product.status === 1 ? ' text-red' : '';

              return (
                <tr className="text-center" key={index}>
                  <td>
                    <h5 className={textClass}>{product.lineNumber}</h5>
                    <p>
                    { (product.status === 2 || product.status === 3) && (<button type="button" className="btn btn-primary" onClick={() => getStorableProduct(product)}>
                      <i className="fa fa-search"></i>
                    </button>)}
                    </p>
                  </td>
                  <td className="text-left">
                    <h4 className={"widget-table-title" + textClass}>{product.productCode}</h4>
                    <p className={"widget-table-desc m-b-15" + textClass}>{product.name}</p>
                    <p className={"widget-table-desc m-b-15" + textClass}>{product.fullName}</p>
                    <div className={"clearfix f-s-10" + textClass}>
                      Status: 
                      <b className={textClass}> 
                        {InboundOrderHelpers.inboundOrderProductStatus(product.status)}
                      </b>
                    </div>
                  </td>
                  <td className={textClass}>
                    {product.lotNumber}
                  </td>
                  <td className={textClass}>
                    {product.expirationDate && moment(product.expirationDate).format('L')}
                  </td>
                  <td className={textClass}>
                    {product.unitMeasure}
                  </td>
                  <td className={textClass}>
                    {FormatHelpers.formatNumber(product.quantityPending, 3, false)}
                  </td>
                  <td className={textClass}>
                    {product.note}
                  </td>
                  <td>
                      { product.status >= 4 ? 
                        <i className="fas fa-check-circle fa-2x text-teal"></i> :
                        <i className="fas fa-times-circle fa-2x text-danger"></i> }
                    </td>
                </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}