/* eslint-disable import/no-extraneous-dependencies */
import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

import {
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../components/panel/panel';
import addNotification from '../../../components/notification';

import ROUTES from '../../../config/routes';

import {
  getPermissionTree,
  getMobilePermissionTree,
  updatePermissionTree,
  updateMobilePermissionTree,
} from '../../../app/store/actions/permissions';
import CheckBoxTree from '../../../components/checkboxtree';

class PermissionsManager extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      permissions: [],
      mobilePermissions: [],
      checked: [],
      expanded: [],
      title: '',
      activeTab: '1',
    };
  }

  async componentDidMount() {
    await this.getPermissionTree();
    await this.getMobilePermissionTree();
  }

  mountIcons = (icon, expanded, checked) => {
    expanded.push(icon.value);
    if (icon.checked) {
      checked.push(icon.value);
    }

    if (icon.icon) {
      icon.icon = (<span style={{ color: '#f79c20' }} className={icon.icon} />);
    } else {
      icon.icon = null;
    }

    if (icon.children) {
      for (let index = 0; index < icon.children.length; index += 1) {
        const iconChild = icon.children[index];

        this.mountIcons(iconChild, expanded, checked);
      }
    }
  };

  getPermissionTree = async () => {
    if (this.props.location.state.accessProfile) {
      const { accessProfile } = this.props.location.state;

      const permissions = await this.props.getPermissionTree(accessProfile.code);

      if (permissions && permissions instanceof Array) {
        const expanded = [];
        const checked = [];
        permissions.forEach((it) => {
          this.mountIcons(it, expanded, checked);
        });
        this.setState({
          permissions,
          title: `${I18n.t('BEE26' /* Permissões */)} - ${accessProfile.name}`,
          expanded,
          checked,
        });
      }
    }
  };

  getMobilePermissionTree = async () => {
    if (this.props.location.state.accessProfile) {
      const { accessProfile } = this.props.location.state;

      const mobilePermissions = await this.props.getMobilePermissionTree(accessProfile.code);

      if (mobilePermissions && mobilePermissions instanceof Array) {
        const mobileExpanded = [];
        const mobileChecked = [];
        mobilePermissions.forEach((it) => {
          this.mountIcons(it, mobileExpanded, mobileChecked);
        });
        await this.setState({
          mobilePermissions,
          // title: `${I18n.t('BEE26' /* Permissões */)} - ${accessProfile.name}`,
          mobileExpanded,
          mobileChecked,
        });
      }
    }
  };

  getFatherCode = (permissions, code, father) => {
    for (let index = 0; index < permissions.length; index += 1) {
      const currentPermission = permissions[index];

      if (currentPermission.children) {
        for (let indexB = 0; indexB < currentPermission.children.length; indexB += 1) {
          const currentChild = currentPermission.children[indexB];

          if (currentChild.value === code) {
            return { code: currentPermission.code };
          }

          if (currentChild.children) {
            const valueCode = this.getFatherCode(currentChild.children, code, currentChild.code);

            if (valueCode) {
              return { code: valueCode.code, isSubChildren: true };
            }
          }
        }
      } else if (currentPermission.code === code && father) {
        return { code: father };
      }
    }

    return 0;
  };

  fillChildren = (checkeds, code, isMobile = false) => {
    const permissionList = isMobile ? this.state.mobilePermissions : this.state.permissions;
    const fatherObj = this.getFatherCode(permissionList, code);

    if (fatherObj && fatherObj.code && !checkeds.includes(fatherObj.code)) {
      checkeds.push(fatherObj.code);
    }

    if (fatherObj && fatherObj.isSubChildren) {
      return this.fillChildren(checkeds, fatherObj.code, isMobile);
    }
  };

  fillCodes = (checkeds, isMobile = false) => {
    const checkedList = checkeds;
    const permissionList = isMobile ? this.state.mobilePermissions : this.state.permissions;

    const finalCheckedList = [];

    for (let index = 0; index < checkedList.length; index += 1) {
      const checkedCode = checkedList[index];
      const fatherObj = this.getFatherCode(permissionList, checkedCode);

      if (fatherObj && fatherObj.code && !finalCheckedList.includes(fatherObj.code)) {
        finalCheckedList.push(fatherObj.code);
      }

      if (fatherObj && fatherObj.isSubChildren) {
        this.fillChildren(checkedList, fatherObj.code);
      }

      finalCheckedList.push(checkedCode);
    }

    return finalCheckedList;
  };

  proccessBlockPermissions = (nodeIds, node, isNodeOwner) => {
    const nodeOwner = (isNodeOwner || !node.isChild)
      ? node
      : _.find(node.parent.children, (item) => (node.value === item.value));
    const hasNodeAndParent = nodeOwner && nodeOwner.children && nodeOwner.children.length;

    if (hasNodeAndParent && nodeOwner.hasBlockPermission) {
      if (node.checked && node.checkState === 2) {
        const nodesToRemove = nodeOwner.children.map((value) => value.value);
        nodesToRemove.push(node.value);

        for (let index = 0; index < nodesToRemove.length; index += 1) {
          const indexElement = nodeIds.indexOf(`${nodesToRemove[index]}`);
          if (indexElement !== -1) {
            nodeIds.splice(indexElement, 1);
          }
        }
      } else if (node.checked) {
        const nodesToRemove = nodeOwner.children.reduce((acum, value) => {
          if (!value.isLabelBlock && !this.state.checked.includes(value.value)) acum.push(value.value);
          return acum;
        }, []);

        for (let index = 0; index < nodesToRemove.length; index += 1) {
          const indexElement = nodeIds.indexOf(`${nodesToRemove[index]}`);
          if (indexElement !== -1) {
            nodeIds.splice(indexElement, 1);
          }
        }
      }
    } else if (hasNodeAndParent) {
      for (let index = 0; index < nodeOwner.children.length; index += 1) {
        const element = nodeOwner.children[index];

        if (element.children && element.children.length) {
          nodeIds = this.proccessBlockPermissions(nodeIds, element, true);
        }
      }
    }

    return nodeIds;
  };

  onCheck = async (nodeIds, node, isMobileTab = false) => {
    // Verificacao para nos de bloqueio
    if (node.isParent && [0, 1].includes(node.checkState)) {
      nodeIds = this.proccessBlockPermissions(nodeIds, node);
    }

    if (isMobileTab) {
      await this.setState({ mobileChecked: nodeIds });
    } else {
      await this.setState({ checked: nodeIds });
    }
  };

  onSubmitUpdate = async () => {
    if (this.props.location.state.accessProfile) {
      const { accessProfile } = this.props.location.state;

      try {
        const { checked, mobileChecked } = this.state;

        const webCheckedVerifiedList = await this.fillCodes(checked);
        const mobileCheckedVerifiedList = await this.fillCodes(mobileChecked, true);

        const result = await this.props.updatePermissionTree(accessProfile.code, webCheckedVerifiedList);
        const resultMobile = await this.props.updateMobilePermissionTree(accessProfile.code, mobileCheckedVerifiedList);

        if ((result && result instanceof Array && result.length)
        && resultMobile
        && resultMobile instanceof Array
        && resultMobile.length) {
          await addNotification(
            'success',
            I18n.t('BEE375' /* Atualizar Permissão */),
            I18n.t('BEE374', { 0: accessProfile.code } /* Atualização da Permissão %{0} efetuada com sucesso! */),
            'top-right',
          );
          this.props.history.push(ROUTES.DEVELOP_ACCESS_PROFILE_LIST);
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE375' /* Atualizar Permissão */),
            I18n.t('BEE376', { 0: accessProfile.code } /* Erro ao atualizar a Permissão %{0}! */),
            'top-right',
          );
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;
          if (error.details || error.message) {
            await addNotification(
              'danger',
              I18n.t('BEE375' /* Atualizar Permissão */),
              `${error.code} - ${error.details || error.message}`,
              'top-right',
            );
          } else {
            await addNotification(
              'danger',
              I18n.t('BEE375' /* Atualizar Permissão */),
              I18n.t('BEE376', { 0: accessProfile.code } /* Erro ao atualizar a Permissão %{0}! */),
              'top-right',
            );
          }
        }
      }
    }
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      permissions, checked, expanded, mobilePermissions, mobileChecked, mobileExpanded,
    } = this.state;

    return (
      <div className={this.props.animationType || 'slideUpTransition'}>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {this.state.title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel className="panel-with-tabs">
                <PanelHeader noButton />
                <PanelBody>
                  <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '1' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('1');
                        }}
                      >
                        <span className="d-sm-none">Tab 1</span>
                        <span className="d-sm-block d-none">Web</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '2' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('2');
                        }}
                      >
                        <span className="d-sm-none">Tab 2</span>
                        <span className="d-sm-block d-none">Coletor</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    {/* WEB */}
                    <TabPane tabId="1">
                      <div className="row">
                        <div className="col-xl-12">
                          <CheckBoxTree
                            checkModel="all"
                            nodes={permissions}
                            checked={checked}
                            expanded={expanded}
                            onCheck={this.onCheck}
                            onExpand={(value) => this.setState({ expanded: value })}
                          />
                        </div>
                      </div>
                    </TabPane>
                    {/* MOBILE */}
                    <TabPane tabId="2">
                      <div className="row">
                        <div className="col-xl-12">
                          <CheckBoxTree
                            checkModel="all"
                            nodes={mobilePermissions}
                            checked={mobileChecked}
                            expanded={mobileExpanded}
                            onCheck={(nodeIds, node) => this.onCheck(nodeIds, node, true)}
                            onExpand={(value) => this.setState({ mobileExpanded: value })}
                          />
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </PanelBody>
                <PanelFooter
                  linkBack={ROUTES.DEVELOP_ACCESS_PROFILE_LIST}
                  showCancel
                  showUpdate
                  onSubmitUpdate={this.onSubmitUpdate}
                />
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getPermissionTree: (code) => dispatch(getPermissionTree(code)),
  getMobilePermissionTree: (code) => dispatch(getMobilePermissionTree(code)),
  updatePermissionTree: (
    accessProfileCode,
    permissions,
  ) => dispatch(updatePermissionTree(accessProfileCode, permissions)),
  updateMobilePermissionTree: (
    accessProfileCode,
    permissions,
  ) => dispatch(updateMobilePermissionTree(accessProfileCode, permissions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PermissionsManager));
