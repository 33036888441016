const INITIAL_STATE = {
  rangesList: [],
  rangeAddressList: {},
  showBulkImport: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_RANGES': {
      return { ...state, rangesList: action.ranges };
    }
    case 'SET_RANGE_ADDRESS': {
      return { ...state, rangeAddressList: action.rangeAddress };
    }
    case 'OPEN_BULK_IMPORT_RANGE': {
      return { ...state, showBulkImport: action.showBulkImport };
    }
    default:
      return state;
  }
};
