const INITIAL_STATE = {
  storageAddressSizesList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_STORAGE_ADRESS_SIZE': {
      return { ...state, storageAddressSizesList: action.storageAddressSize };
    }
    default:
      return state;
  }
};
