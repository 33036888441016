import moment from 'moment';
import RestService from '../services/rest';

async function getOutboundOrderListJustDocument(startDate, endDate) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    `industryOutboundOrder/list/monitor?startDate=${sDate}&endDate=${eDate}`,
  );
}

async function getOutboundOrderSerials(outboundOrderId) {
  return RestService.getAuthenticated(
    `industryOutboundOrder/list/serials?outboundOrderId=${outboundOrderId}`,
  );
}

async function updateOutboundRange(outboundRangeId, updateDataOutboundRange) {
  return RestService.putAuthenticated('industryOutboundOrder/updateOutboundRange', {
    outboundRangeId,
    updateDataOutboundRange,
  });
}

async function cancelOutboundOrder(outboundOrderId) {
  return RestService.postAuthenticated('industryOutboundOrder/undoOutboundOrder', {
    outboundOrderId,
  });
}

async function cancelOutboundProduct(outboundOrderId, outboundOrderProductId, serialGroupCode) {
  return RestService.postAuthenticated('industryOutboundOrder/undoPicking', {
    outboundOrderId, outboundOrderProductId, serialGroupCode,
  });
}

async function updateOutboundOrderAndFinally(outboundOrderId, orderType) {
  return RestService.postAuthenticated('industryOutboundOrder/updateAndFinally', {
    outboundOrderId,
    orderType,
  });
}

export default {
  updateOutboundOrderAndFinally,
  updateOutboundRange,
  cancelOutboundOrder,
  cancelOutboundProduct,
  getOutboundOrderListJustDocument,
  getOutboundOrderSerials,
};
