import { I18n } from 'react-redux-i18n';

function inventorySheetSerialLog(type) {
  if (type || type === 0) {
    switch (type) {
      case 1:
        return I18n.t('BEE3612' /* Divergente */);
      case 2:
        return I18n.t('BEE3613' /* Etiqueta não prevista */);
      case 3:
        return I18n.t('BEE3614' /* Etiqueta não lida */);
      default:
        return type;
    }
  } else if (!type) {
    return [
      { value: 1, label: I18n.t('BEE3612' /* Divergente */) },
      { value: 2, label: I18n.t('BEE3613' /* Etiqueta não prevista */) },
      { value: 3, label: I18n.t('BEE3614' /* Etiqueta não lida */) },
    ];
  }
}

export default {
  inventorySheetSerialLog,
};
