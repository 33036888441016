const INITIAL_STATE = {
  inboundOrdersList: [],
  inboundOrdersPanel: [],
  cardsPanel: [],
  chartsPanel: {},
  inboundOrdersTotalByStatus: [],
  inboundOrdersTotal: [],
  averageTimeCards: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_INBOUND_ORDERS': {
      return { ...state, inboundOrdersList: action.inboundOrders };
    }
    case 'SET_INBOUND_ORDERS_PANEL': {
      return { ...state, inboundOrdersPanel: action.inboundOrders };
    }
    case 'SET_INBOUND_CARDS_PANEL': {
      return { ...state, cardsPanel: action.cardsPanel };
    }
    case 'SET_INBOUND_CHARTS_PANEL': {
      return { ...state, chartsPanel: action.chartsPanel };
    }
    case 'SET_INBOUND_ORDERS_DATES': {
      return { ...state, dates: action.dates };
    }
    case 'SET_INBOUND_ORDERS_SELECTED_BRANCHES': {
      return { ...state, selectedBranches: action.selectedBranches };
    }
    case 'SET_INBOUND_ORDERS_USER_BRANCHES': {
      return { ...state, userBranches: action.userBranches };
    }
    case 'SET_INBOUND_ORDERS_TOTAL': {
      return { ...state, inboundOrdersTotal: action.totalInboundOrderProduct };
    }
    case 'SET_INBOUND_ORDERS_TOTAL_BY_STATUS': {
      return { ...state, inboundOrdersTotalByStatus: action.totalByStatusInboundOrdersProducts };
    }
    case 'SET_INBOUND_ORDERS_AVERAGE_TIME_CARDS': {
      return { ...state, averageTimeCards: action.averageTimeCards };
    }
    default:
      return state;
  }
};
