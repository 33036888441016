import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthService from '../../app/services/auth';
import ROUTES from '../../config/routes';

import { getUserLogged } from '../../app/store/actions/app';
import PageNotFound from '../../pages/PageNotFound';
import Permissions from '../../app/services/permissions';

class PrivateRoute extends React.PureComponent {
  renderPageNotFound = (route) =>
    // Array contendo condicoes falsas para renderizar a pagina nao encontrada
    // como exemplo nao possuir a permissao de rota
    [Permissions.checkRoutePermission(route.path)].includes(false);

  render() {
    const { route, routeProps, userLogged } = this.props;
    if (AuthService.isAuthenticated()) {
      if (!userLogged) {
        this.props.getUserLogged();
      }

      if (this.renderPageNotFound(route)) return <PageNotFound />;

      return <route.component {...routeProps} />;
    }
    return (
      <Redirect
        to={{
          pathname: ROUTES.LOGIN,
          state: { from: this.props.location },
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged,
});

const mapActionsToProps = (dispatch) => ({
  getUserLogged: () => dispatch(getUserLogged()),
});

export default connect(mapStateToProps, mapActionsToProps)(PrivateRoute);
