import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';
import ROUTES from '../../../../config/routes';

import { getTransportEquipmentsList, deleteTransportEquipment } from '../../../../app/store/actions/transportEquipment';

class TransportEquipmentList extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
        </>),
      },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE1578' /* Equipamento */) },
      { value: I18n.t('BEE1397' /* Equipamentos de Transportes */), active: true },
    ];
    this.state = {
      showModalDelete: false,
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEdit(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopy(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDelete(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE1700' /* Marca */),
        accessor: 'brand',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE1701' /* Modelo */),
        accessor: 'model',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE1702' /* Número Patrimonial */),
        accessor: 'assetNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE501' /* Número Série */),
        accessor: 'serialNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE499' /* Ativo */),
        accessor: 'active',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        Filter: this.filterColumnSelect,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.updateData();
    } else {
      await this.updateData();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
    });
  };

  updateData = async () => {
    await this.props.getTransportEquipmentsList();
  };

  filterColumnSelect = ({ filter, onChange }) => (
    <select
      type="text"
      style={{ width: '100%' }}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    >
      <option value="">{I18n.t('BEE793' /* Todos */)}</option>
      <option value="true">{I18n.t('BEE172' /* Sim */)}</option>
      <option value="false">{I18n.t('BEE173' /* Não */)}</option>
    </select>
  );

  deleteRecord = async (transportEquipmentId) => {
    try {
      const result = await this.props.deleteTransportEquipment(transportEquipmentId._original.id);
      this.setInitState();
      if (result) {
        addNotification('success', I18n.t('BEE748' /* Eliminar Tipo de Endereço */), I18n.t('BEE749' /* Tipo de endereço eliminado com sucesso! */), 'top-right');
      }
      await this.updateData();
    } catch (error) {
      this.setInitState();
      addNotification('danger', I18n.t('BEE981' /* Erro */), I18n.t('BEE1727' /* não foi possível eliminar */), 'top-right');
    }
  };

  showDelete = (transportEquipmentId) => {
    this.setState({ showModalDelete: true, transportEquipmentId });
  };

  showDetail = (transportEquipment) => {
    this.props.history.push({
      pathname: ROUTES.TRANSPORT_EQUIP_DETAIL,
      state: {
        transportEquipment: transportEquipment._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEdit = (transportEquipment) => {
    this.props.history.push({
      pathname: ROUTES.TRANSPORT_EQUIP_EDIT,
      state: {
        transportEquipment: transportEquipment._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopy = (transportEquipment) => {
    this.props.history.push({
      pathname: ROUTES.TRANSPORT_EQUIP_COPY,
      state: {
        transportEquipment: transportEquipment._original,
      },
    });
  };

  render() {
    const { transportEquipmentId, showModalDelete, page } = this.state;
    const { transportEquipmentsList = [] } = this.props;

    return (
      <div>
        <Table
          downloadCSV
          breadcrumb={this.breadcrumb}
          headerTitle={I18n.t('BEE1397' /* Equipamentos de Transportes */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.TRANSPORT_EQUIP_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {I18n.t('BEE3195' /* Incluir Equipamento */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.updateData,
          }}
          filterable
          data={transportEquipmentsList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />

        {(showModalDelete
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.deleteRecord(transportEquipmentId)}
              onCancel={() => this.setInitState()}
            >
              {I18n.t('BEE1726', { 0: transportEquipmentId.code } /* O equipamentos de transportes %{0}, será eliminado! */)}
            </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  transportEquipmentsList: state.transportEquipment.transportEquipmentsList,
});

const mapDispatchToProps = (dispatch) => ({
  getTransportEquipmentsList: () => dispatch(getTransportEquipmentsList()),
  deleteTransportEquipment: (transportEquipmentId) => dispatch(deleteTransportEquipment(transportEquipmentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TransportEquipmentList));
