/* eslint-disable max-len */
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle,
  Modal, ModalBody, ModalHeader, UncontrolledButtonDropdown,
} from 'reactstrap';

import {
  allocatedProducts, getDataGrid, toggleOrderPriority, updateOutboundRange, updateOutboundRangeProduct,
} from '../../../app/store/actions/outboundMonitor';
import { changeUserPickRange, confirmDivergence } from '../../../app/store/actions/outboundOrders';
import { getListUsersPicking } from '../../../app/store/actions/users';
import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import OutboundRangeHelpers from '../../../helpers/outboundRange';
import OutboundOrderHelpers from '../../../helpers/outboundOrder';

import Form from '../../../components/form/form';
import FormatHelpers from '../../../helpers/format';

const filterColumn = ({ onChange }) => (
  <input
    type="text"
    style={{ width: '100%' }}
    onKeyPress={(event) => {
      if (event.keyCode === 13 || event.which === 13) {
        onChange(event.target.value);
      }
    }}
    onKeyUp={(event) => {
      if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
        onChange(event.target.value);
      }
    }}
  />
);
export class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderList: [],
      productList: [],
      currentRange: null,
      type: null,
      orderSelect: {},
      userAssignmentModal: false,
      userOptions: [],
      allocationData: [],
      allocationModel: false,
      showDivergenceModel: false,
      productSelect: null,
      quantityDivergence: 0,
      showModalNote: false,
      showModalUrgent: false,
      outboundOrderUrgent: null,
      updateNoteOutboundRange: true,
      note: '',
      notePrevious: '',
      pickingType: 0,
      quantityChecked: 0,
      quantityPicked: 0,
      showRemoveUrgent: false,
      showDivergenceZero: false,
    };

    this.defaultSorted = [
      {
        id: 'outboundOrder.orderNumber',
        desc: false,
      },
    ];

    this.defaultSortedProducts = [
      {
        id: 'productCode',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => {
          const { outboundOrder } = rows.original;
          const outboundRange = rows.original;
          return (
            <div style={{ textAlign: 'center' }}>
              <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
              <UncontrolledButtonDropdown>
                <DropdownToggle color="default" caret className="btn-xs" />
                <DropdownMenu>
                  {this.props.activeTab === 'pendingPicking' && !outboundOrder.urgent ? (
                    <DropdownItem onClick={() => this.showUrgentModal(outboundRange.outboundOrderId)}>
                      {I18n.t('BEE613' /* Urgente */)}
                    </DropdownItem>
                  ) : (
                    <DropdownItem onClick={() => this.showModalRemoveUrgent(outboundRange.outboundOrderId)}>
                      {I18n.t('BEE2637' /* Remover Urgência */)}
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={() => this.showAllocationModel()}>
                    {I18n.t('BEE1432' /* Alocações */)}
                  </DropdownItem>
                  {(outboundOrder.pickingType === 0 || outboundOrder.pickingType === 1)
                    && (this.props.activeTab === 'pendingPicking' || this.props.activeTab === 'picking') && (
                      <DropdownItem onClick={() => this.showUserAssignmentModal(1)}>
                        {(rows.row._original.pickedUserObj && rows.row._original.pickedUserObj.name)
                          ? I18n.t('BEE2003', { 0: rows.row._original.pickedUserObj.name } /* Trocar Separador */)
                          : `${I18n.t('BEE2004' /* Atribuir Separador */)} (${I18n.t('BEE1454' /* Onda */)})`}
                      </DropdownItem>
                  )}
                  {(outboundOrder.pickingType === 0 || outboundOrder.pickingType === 2)
                    && (this.props.activeTab === 'pendingPicking' || this.props.activeTab === 'picking') && (
                      <DropdownItem onClick={() => this.showUserAssignmentModal(2)}>
                        {(rows.row._original.pickedUserObj && rows.row._original.pickedUserObj.name)
                          ? I18n.t('BEE2003', { 0: rows.row._original.pickedUserObj.name } /* Trocar Separador */)
                          : `${I18n.t('BEE2004' /* Atribuir Separador */)} (${I18n.t('BEE1378' /* Documento */)})`}
                      </DropdownItem>
                  )}
                  <DropdownItem onClick={() => {
                    this.setState({ updateNoteOutboundRange: true });
                    this.modalNoteOpening(rows.original);
                  }}
                  >
                    {I18n.t('BEE135' /* Observação */)}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE1378' /* Documento */),
        accessor: 'outboundOrder.orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE581' /* Range */),
        accessor: 'rangeCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE1096' /* Pedido cliente */),
        accessor: 'outboundOrder.customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE402' /* Nota Fiscal */),
        accessor: 'outboundOrder.invoiceNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'outboundOrder.orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE613' /* Urgente */),
        accessor: 'outboundOrder.urgent',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: filterColumn,
        Cell: (rows) => (
          <div className={{ textAlign: 'center' }}>
            {rows.original.outboundOrder.urgent ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </div>
        ),
      }, {
        Header: I18n.t('BEE43' /* Separação */),
        accessor: 'outboundOrder.pickingType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div>
            <div style={{ textAlign: 'center' }}>
              <span>{OutboundOrderHelpers.outboundOrderPickingType(row.value)}</span>
            </div>
          </div>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          const status = OutboundOrderHelpers.outboundOrderPickingType(id);

          if (_.includes(_.lowerCase(status), input)) {
            return true;
          }
        },
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE1763' /* Data Documento */),
        accessor: 'outboundOrder.orderDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: filterColumn,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE1057' /* Entrega */),
        accessor: 'outboundOrder.deliveryType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE1092' /* Separador */),
        accessor: 'pickedUserObj.login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE1721' /* Data Separação */),
        accessor: 'pickedAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: filterColumn,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE805' /* Conferente */),
        accessor: 'checkedUserObj.login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE467' /* Data Conferência */),
        accessor: 'checkedAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: filterColumn,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE2089' /* Consolidado */),
        accessor: 'consolidationUserObj.login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE2095' /* Data Consolidação */),
        accessor: 'consolidationAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: filterColumn,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE1374' /* Vendedor */),
        accessor: 'outboundOrder.representative',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'outboundOrder.note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 400,
        Filter: filterColumn,
      },
    ];

    this.productTableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                {rows.original.status === 6
                  && <DropdownItem onClick={() => this.confirmDivergence(rows.original)}>{I18n.t('BEE176' /* Divergência */)}</DropdownItem>}
                <DropdownItem onClick={() => {
                  this.setState({ updateNoteOutboundRange: false });
                  this.modalNoteOpening(rows.original);
                }}
                >
                  {I18n.t('BEE135' /* Observação */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE1897' /* Descrição */),
        accessor: 'product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE384' /* Unidade */),
        accessor: 'product.unitMeasure',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: filterColumn,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE425' /* Batismo */),
        accessor: 'baptismLabel',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE2040' /* Separada */),
        accessor: 'pickedQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: filterColumn,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE1092' /* Separador */),
        accessor: 'pickedUserObj.login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE1721' /* Data Separação */),
        accessor: 'pickedAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: filterColumn,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {' '}
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE464' /* Conferida */),
        accessor: 'checkedQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: filterColumn,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE805' /* Conferente */),
        accessor: 'checkedUserObj.login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: filterColumn,
      }, {
        Header: I18n.t('BEE467' /* Data Conferência */),
        accessor: 'checkedAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: filterColumn,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {' '}
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Cell: (row) => (
          <div>
            <div style={{ textAlign: 'center' }}>
              <span>{OutboundRangeHelpers.outboundRangeProductStatus(row.value)}</span>
            </div>
          </div>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          const status = OutboundRangeHelpers.outboundRangeProductStatus(id);

          if (_.includes(_.lowerCase(status), input)) {
            return true;
          }
        },
        Filter: filterColumn,
      },
    ];

    this.userTableColumnsUserAssignment = [
      {
        Header: I18n.t('BEE623' /* Login */),
        accessor: 'login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      },
    ];

    this.tableColumnsAllocations = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE1378' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE267' /* Endereço */),
        accessor: 'addressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
      }, {
        Header: I18n.t('BEE1096' /* Pedido Cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE444' /* Data de Entrega */),
        accessor: 'deliveryDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {moment(row.value).format('L')}
          </span>
        ),
      }, {
        Header: I18n.t('BEE434' /* Data de Validade */),
        accessor: 'expirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE1688' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getDataGrid();
  }

  componentDidUpdate() {
    const { currentRange, type } = this.state;
    const newCurrentRange = this.props.currentRange;
    const newType = this.props.activeTab;

    if (currentRange !== newCurrentRange || type !== newType) {
      this.getDataGrid();
    }
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  modalNoteOpening = (rowData) => {
    this.setState({
      showModalNote: true,
      note: rowData.note,
      idUpdateNote: rowData.id,
      notePrevious: rowData.note,
    });
  };

  modalNoteClosing = () => {
    this.setState({
      showModalNote: false,
      note: '',
      idUpdateNote: null,
      updateNoteOutboundRange: true,
      notePrevious: '',
    });
  };

  showUrgentModal = (outboundOrderId) => {
    this.setState({
      showModalUrgent: true,
      outboundOrderUrgent: outboundOrderId,
    });
  };

  closeUrgentModal = () => {
    this.setState({
      showModalUrgent: false,
      showRemoveUrgent: false,
      outboundOrderUrgent: null,
    });
  };

  showModalRemoveUrgent = async (outboundOrderId) => {
    this.setState({
      showRemoveUrgent: true,
      outboundOrderUrgent: outboundOrderId,
    });
  };

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 mt-4">
      <label htmlFor={`id-${attr}`}>{label}</label>
      <div className="col">
        <textarea
          id={`id-${attr}`}
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  updateNote = async () => {
    const {
      idUpdateNote, note, updateNoteOutboundRange, notePrevious,
    } = this.state;
    if (notePrevious === note) {
      addNotification(
        'danger',
        I18n.t('BEE1363' /* Monitor de Expedição */),
        I18n.t('BEE2079' /* Não teve alterações se deseja manter essa observação selecione a opção cancelar */),
        'top-right',
      );
    } else {
      this.modalNoteClosing();
      try {
        const result = (updateNoteOutboundRange)
          ? await this.props.updateOutboundRange(idUpdateNote, { note })
          : await this.props.updateOutboundRangeProduct(idUpdateNote, { note });
        if (result && result.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE1363' /* Monitor de Expedição */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */),
            'top-right',
          );
        } else {
          this.getDataGrid();
        }
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && error.message) {
          const messageError = (error.details)
            ? `${error.code} - ${error.details} | ${error.message}`
            : error.message;
          addNotification('danger', I18n.t('BEE1363' /* Monitor de Expedição */), messageError, 'top-right');
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1363' /* Monitor de Expedição */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */),
            'top-right',
          );
        }
      }
    }
  };

  modifyUrgency = async (docUrgent) => {
    const { outboundOrderUrgent } = this.state;

    if (outboundOrderUrgent) {
      try {
        const result = await this.props.toggleOrderPriority(outboundOrderUrgent, docUrgent);

        if (result && result.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE1363' /* Monitor de Expedição */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */),
            'top-right',
          );
        } else {
          addNotification(
            'success',
            I18n.t('BEE1363' /* Monitor de Expedição */),
            (docUrgent)
              ? I18n.t('BEE2635' /* Documento alterado para Urgente com sucesso! */)
              : I18n.t('BEE2636' /* Urgencia removida com sucesso! */),
            'top-right',
          );

          this.getDataGrid();
        }
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && error.message) {
          const messageError = (error.details)
            ? `${error.code} - ${error.details} | ${error.message}`
            : error.message;
          addNotification('danger', I18n.t('BEE1363' /* Monitor de Expedição */), messageError, 'top-right');
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1363' /* Monitor de Expedição */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */),
            'top-right',
          );
        }
      }
    }

    this.closeUrgentModal();
  };

  getDataGrid = async () => {
    const startDate = moment(this.props.startDate).format();
    const endDate = moment(this.props.endDate).format();
    const newCurrentRange = this.props.currentRange;
    const newType = this.props.activeTab;
    const { orderSelect } = this.state;

    const tableParams = {
      startDate,
      endDate,
      type: this.props.activeTab,
    };
    const orderList = await this.props.getDataGrid(tableParams);

    const existOrder = (orderSelect && orderSelect.id) ? orderList.find((o) => o.id === orderSelect.id) : null;

    this.setState({
      orderList,
      currentRange: newCurrentRange,
      type: newType,
      orderSelect: existOrder || null,
      productList: existOrder ? existOrder.products : [],
    });
  };

  setOrderSelected = (order) => {
    this.setState({
      orderSelect: order,
      productList: order.products,
    });
  };

  closeUserAssignmentModal = () => {
    this.setState({
      userAssignmentModal: false,
      userOptions: [],
      pickingType: 0,
    });
  };

  showUserAssignmentModal = async (type) => {
    const { orderSelect } = this.state;
    try {
      const userOptions = await this.props.getListUsersPicking(orderSelect.branchCode, orderSelect.rangeCode);

      if (userOptions && userOptions.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        this.setState({
          userAssignmentModal: true,
          userOptions,
          pickingType: type,
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE1363' /* Monitor de Expedição */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  assignUser = async (user) => {
    const { orderSelect, pickingType } = this.state;

    try {
      const result = await this.props.changeUserPickRange(orderSelect.id, user.id, pickingType);

      if (result && result.success === false && result.detail === 'RANGE_IS_BEING_SEPARATED') {
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          I18n.t('BEE3394' /* Esse range já está sendo separado pelo Documento. */),
          'top-right',
        );
      } else if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          I18n.t('BEE2359' /* Atribuído com sucesso */),
          'top-right',
        );

        this.getDataGrid();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE1363' /* Monitor de Expedição */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
    this.closeUserAssignmentModal();
  };

  showAllocationModel = async () => {
    const { orderSelect } = this.state;
    try {
      const allocationData = await this.props.allocatedProducts(orderSelect.id);
      if (allocationData && allocationData.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        this.setState({
          allocationData,
          allocationModel: true,
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  closeAllocationModel = () => {
    this.setState({
      allocationModel: false,
      allocationData: [],
    });
  };

  createDivergenceModal = () => {
    const {
      showDivergenceModel, productSelect, quantityDivergence, typeDivergence,
    } = this.state;

    const quantityOrder = productSelect ? productSelect.quantity : 0;

    return (
      <Modal size="lg" isOpen={showDivergenceModel} toggle={() => this.closeDivergenceModel()}>
        <ModalHeader toggle={() => this.closeDivergenceModel()}>
          <span className="text-center">
            {I18n.t('BEE176' /* Divergência */).toUpperCase()}
          </span>
        </ModalHeader>
        <ModalBody>
          <Form
            noHeader
            headerProps={{
              className: 'm-b-0',
            }}
            setValue={this.setValue}
            inputs={(formContext) => ([
              formContext.createInput(
                quantityOrder,
                'quantityOrder',
                `${I18n.t('BEE3395' /* Quantidade no Documento */)}:`,
                '',
                'number',
                true,
                true,
              ),
              (typeDivergence === 2
                ? formContext.createInput(
                  quantityDivergence,
                  'quantityDivergence',
                  `${I18n.t('BEE422' /* Quantidade Conferida */)}:`,
                  '',
                  'number',
                  true,
                  false,
                )
                : formContext.createInput(
                  quantityDivergence,
                  'quantityDivergence',
                  `${I18n.t('BEE1134' /* Quantidade Separada */)}:`,
                  '',
                  'number',
                  true,
                  false,
                )
              ),
            ])}
            footerContent={(
              <>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-white p-5 m-5"
                  onClick={this.closeDivergenceModel}
                >
                  {I18n.t('BEE99' /* Cancelar */)}
                </OneClickButton>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={this.confirmDivergenceSubmit}
                >
                  {I18n.t('BEE1435' /* Liberar */)}
                </OneClickButton>
              </>
            )}
          />
        </ModalBody>
      </Modal>
    );
  };

  confirmDivergence = async (outboundProductRange) => {
    const quantityPicked = outboundProductRange ? outboundProductRange.pickedQuantity : 0;
    const quantityChecked = outboundProductRange ? outboundProductRange.checkedQuantity : 0;

    let type = 0;

    if (outboundProductRange && outboundProductRange.checked) {
      type = 2;
    } else if (outboundProductRange && outboundProductRange.picked) {
      type = 1;
    }

    let quantityDivergence = 0;

    if (type === 2) {
      quantityDivergence = quantityChecked;
    } else if (type === 1) {
      quantityDivergence = quantityPicked;
    }

    this.setState({
      showDivergenceModel: true,
      quantityChecked,
      quantityPicked,
      productSelect: outboundProductRange,
      quantityDivergence,
      typeDivergence: type,
    });
  };

  closeDivergenceModel = () => {
    this.setState({
      showDivergenceModel: false,
      productSelect: null,
    });
  };

  confirmDivergenceSubmit = async () => {
    const {
      quantityDivergence, typeDivergence, productSelect, quantityChecked, quantityPicked, showDivergenceZero,
    } = this.state;

    try {
      if (typeDivergence === 0) {
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          I18n.t('BEE3387' /* Problema na liberação da divergência */),
          'top-right',
        );
      } else if (typeDivergence === 1 && parseFloat(quantityDivergence) > parseFloat(productSelect.quantity)) {
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          I18n.t('BEE3388' /* A Quantidade Separada não pode ser maior que a Quantidade Pedido. */),
          'top-right',
        );
      } else if (typeDivergence === 2 && parseFloat(quantityDivergence) > parseFloat(productSelect.quantity)) {
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          I18n.t('BEE3389' /* A Quantidade Conferida não pode ser maior que a Quantidade Pedida. */),
          'top-right',
        );
      } else if (typeDivergence === 2 && parseFloat(quantityDivergence) < parseFloat(quantityChecked)) {
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          I18n.t('BEE3390', { 0: parseFloat(quantityDivergence), 1: parseFloat(quantityChecked) } /* A quantidade informada (%{0}) não pode ser menor que a quantidade conferida (%{1}). */),
          'top-right',
        );
      } else if (typeDivergence === 1 && parseFloat(quantityDivergence) < parseFloat(quantityPicked)) {
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          I18n.t('BEE3391', { 0: parseFloat(quantityDivergence), 1: parseFloat(quantityPicked) } /* A quantidade informada (%{0}) não pode ser menor que a quantidade separada (%{1}). */),
          'top-right',
        );
      } else if (parseFloat(quantityDivergence || 0) <= 0 && parseFloat(quantityPicked || 0) <= 0 && !showDivergenceZero) {
        this.setState({ showDivergenceZero: true });
      } else {
        const result = await this.props.confirmDivergence(productSelect.id, typeDivergence, quantityDivergence);

        if (result && result.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE1363' /* Monitor de Expedição */),
            I18n.t('BEE3392' /* Ocorreu um erro ao liberar a divergência. */),
            'top-right',
          );
        } else {
          this.setState({
            typeDivergence: 0,
            quantityDivergence: 0,
            productSelect: null,
            showDivergenceModel: false,
            showDivergenceZero: false,
          });

          addNotification(
            'success',
            I18n.t('BEE1363' /* Monitor de Expedição */),
            I18n.t('BEE3393' /* Divergência liberada com sucesso! */),
            'top-right',
          );

          this.getDataGrid();
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          `${error.code} - ${error.details || error.message}`,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1363' /* Monitor de Expedição */),
          err.response && err.response.data
          && (`${err.response.data.error.message} - ${err.response.data.error.details}`),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      orderList, productList, orderSelect, userOptions, userAssignmentModal, showDivergenceZero,
      allocationData, allocationModel, showModalNote, note, showModalUrgent, showRemoveUrgent,
    } = this.state;

    return (
      <div>
        <Table
          panelHeaderProps={{
            onClickReload: this.getDataGrid,
            children: I18n.t('BEE462' /* Documentos */).toUpperCase(),
          }}
          filterable
          data={orderList}
          columns={this.tableColumns}
          expander
          defaultPageSize={5}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: () => {
                  this.setOrderSelected(rowInfo.original);
                },
                style: {
                  background: orderSelect && rowInfo.original.id === orderSelect.id ? 'silver' : 'white',
                  cursor: 'pointer',
                  color: rowInfo.original.status === 6 ? 'red' : 'black',
                },
              };
            }
            return {};
          }}
        />

        <Table
          panelHeaderProps={{
            children: I18n.t('BEE27' /* Produtos */).toUpperCase(),
          }}
          filterable
          data={productList}
          columns={this.productTableColumns}
          expander
          defaultPageSize={5}
          defaultSorted={this.defaultSortedProducts}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        <Modal size="lg" isOpen={userAssignmentModal} toggle={() => this.closeUserAssignmentModal()}>
          <ModalHeader toggle={() => this.closeUserAssignmentModal()}>
            {I18n.t('BEE2004' /* Atribuir Separador */).toLocaleUpperCase()}
          </ModalHeader>
          <ModalBody>
            <div className="p-b-15">
              <Table
                panelHeaderProps={{ noButton: true }}
                filterable
                data={userOptions}
                columns={this.userTableColumnsUserAssignment}
                expander
                defaultPageSize={10}
                pageSizeOptions={[5, 10]}
                defaultSorted={[{ id: 'login', desc: false }]}
                defaultFilterMethod={(filter, row) => {
                  const input = _.lowerCase(filter.value);
                  const value = _.lowerCase(row[filter.id]);
                  if (_.includes(value, input)) {
                    return true;
                  }
                }}
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: async () => {
                        await this.assignUser(rowInfo.original);
                      },
                      style: {
                        cursor: 'pointer',
                      },
                    };
                  }
                  return {};
                }}
              />
            </div>
          </ModalBody>
        </Modal>

        <Modal size="lg" isOpen={allocationModel} toggle={() => this.closeAllocationModel()}>
          <ModalHeader
            toggle={() => this.closeAllocationModel()}
            className="text-center"
          >
            {I18n.t('BEE1432' /* Alocações */).toLocaleUpperCase()}
          </ModalHeader>
          <ModalBody>
            <Table
              panelHeaderProps={{ noButton: true }}
              filterable
              data={allocationData}
              columns={this.tableColumnsAllocations}
              expander
              defaultPageSize={10}
              pageSizeOptions={[5, 10]}
              defaultSorted={[{ id: 'productCode', desc: false }]}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          </ModalBody>
        </Modal>

        {this.createDivergenceModal()}
        {(showModalNote
          && (
            <SweetAlert
              showCancel
              confirmBtnText={I18n.t('BEE493' /* Salvar */)}
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="danger"
              title={I18n.t('BEE135' /* Observação */)}
              onConfirm={() => this.updateNote()}
              onCancel={() => this.modalNoteClosing()}
              btnSize="sm"
            >
              {this.createTextArea(
                note,
                'note',
                I18n.t('BEE135' /* Observação */),
                '',
                3,
                true,
                false,
              )}
            </SweetAlert>
          ))}
        {(showModalUrgent
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1849' /* Continuar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.modifyUrgency(true)}
              onCancel={() => this.closeUrgentModal()}
            >
              {I18n.t('BEE2633' /* O Documento de Saída vai ser alterado para Urgente e terá prioridade no processo de Separação */)}
            </SweetAlert>
          )
        )}
        {showRemoveUrgent && (
          <SweetAlert
            info
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE1849' /* Continuar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.modifyUrgency(false)}
            onCancel={() => this.closeUrgentModal()}
          >
            {I18n.t('BEE2634' /* O Documento de Saída terá sua Urgência removida e perderá sua prioridade no processo de Separação */)}
          </SweetAlert>
        )}
        {showDivergenceZero && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE1849' /* Continuar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.confirmDivergenceSubmit()}
            onCancel={() => { this.setState({ showDivergenceZero: false }); }}
          >
            {I18n.t('BEE3396' /* O item não possui separação, o que resultará no cancelamento do item. */)}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  changeUserPickRange: (
    outboundRangeId,
    userPickId,
    pickingType,
  ) => dispatch(changeUserPickRange(outboundRangeId, userPickId, pickingType)),
  getListUsersPicking: (branchCode, rangeCode) => dispatch(getListUsersPicking(branchCode, rangeCode)),
  getDataGrid: (tableParams) => dispatch(getDataGrid(tableParams)),
  allocatedProducts: (outboundRangeId) => dispatch(allocatedProducts(outboundRangeId)),
  confirmDivergence: (productRangeId, type, quantity) => dispatch(confirmDivergence(productRangeId, type, quantity)),
  updateOutboundRange: (
    outboundRangeId,
    fieldsOutboundRange,
  ) => dispatch(updateOutboundRange(outboundRangeId, fieldsOutboundRange)),
  updateOutboundRangeProduct: (
    outboundRangeProductId,
    fieldsOutboundRangeProduct,
  ) => dispatch(updateOutboundRangeProduct(outboundRangeProductId, fieldsOutboundRangeProduct)),
  toggleOrderPriority: (outboundOrderId, docUrgent) => dispatch(toggleOrderPriority(outboundOrderId, docUrgent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(List));
