const INITIAL_STATE = {
  capacitiesList: [],
  showBulkImport: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_CAPACITIES': {
      return { ...state, capacitiesList: action.capacities };
    }
    case 'OPEN_BULK_IMPORT_CAPACITY': {
      return { ...state, showBulkImport: action.showBulkImport };
    }
    default:
      return state;
  }
};
