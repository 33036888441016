import RangesRequests from '../../server/ranges';
import { addLoading, removeLoading } from './loading';

export const setRanges = (ranges) => ({
  type: 'SET_RANGES',
  ranges,
});

export const setRangeAddress = (rangeAddress) => ({
  type: 'SET_RANGE_ADDRESS',
  rangeAddress,
});

export const openBulkImportRange = (showBulkImport) => ({
  type: 'OPEN_BULK_IMPORT_RANGE',
  showBulkImport,
});

export const getRangesList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const ranges = await RangesRequests.getRanges();
    dispatch(setRanges(ranges));
  } finally {
    dispatch(removeLoading());
  }
};

export const createRange = (range) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newRange = await RangesRequests.createRange(range);
    return newRange;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateRange = (range) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await RangesRequests.updateRange(range);
  } finally {
    dispatch(removeLoading());
  }
};

export const getRange = (rangeId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await RangesRequests.getRange(rangeId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getRangesOptions = (branchCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await RangesRequests.getRangesOptions(branchCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteRange = (rangeId, rangeCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await RangesRequests.deleteRange(rangeId, rangeCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const previewLinkAddresses = (filters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await RangesRequests.previewLinkAddresses(filters);
  } finally {
    dispatch(removeLoading());
  }
};

export const persistLinkAddresses = (rangeCode, filters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await RangesRequests.persistLinkAddresses(rangeCode, filters);
  } finally {
    dispatch(removeLoading());
  }
};

export const removeLinkAddress = (storageAddressId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await RangesRequests.removeLinkAddress(storageAddressId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUserList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const users = await RangesRequests.getUserList(tableParams);
    dispatch(removeLoading());
    return users;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const bulkImportUsersRange = (jsonCsv) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await RangesRequests.createBulkImportUsersRange(jsonCsv);
  } finally {
    dispatch(removeLoading());
  }
};

export const createLinkUser = (userId, rangeCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await RangesRequests.createLinkUser(userId, rangeCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteLinkUser = (usersRangesId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await RangesRequests.deleteLinkUser(usersRangesId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getRangeAddress = (tableParams) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const rangeAddress = await RangesRequests.getRangeAddress(tableParams);
    dispatch(setRangeAddress(rangeAddress || {}));
    dispatch(removeLoading());
  } catch (e) {
    dispatch(removeLoading());
  }
};

export const getUserByRange = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await RangesRequests.getUserByRange();
  } finally {
    dispatch(removeLoading());
  }
};
