import { I18n } from 'react-redux-i18n';


function cableCutStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE470' /* Pendente */);
      case 2:
        return I18n.t('BEE1833' /* finalizado */);
      case 3:
        return I18n.t('BEE64' /* Cancelado */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE470' /* Pendente */) },
      { value: 2, label: I18n.t('BEE1833' /* finalizado */) },
      { value: 3, label: I18n.t('BEE64' /* Cancelado */) },
    ]
  }
}

export default {
  cableCutStatus,
};
