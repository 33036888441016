import RestService from '../services/rest';

// GET
async function getStockBalances(tableParams) {
  return RestService.postAuthenticated('stocks/balances', tableParams);
}

async function getAllStockBalances(tableParams) {
  return RestService.postAuthenticated('stocks/allBalances', tableParams);
}

async function getProductsStockBalance(tableParams) {
  return RestService.postAuthenticated('stocks/productsStockBalance', tableParams);
}

async function getStockMovements(tableParams) {
  return RestService.postAuthenticated('stocks/movements', tableParams);
}

async function getProductByEan(ean, orderType = '') {
  return RestService.getAuthenticated('stocks/productByEan', { ean, orderType });
}

async function getProductStockBalanceByAddresAndEan(ean, addressId) {
  return RestService.getAuthenticated(`stocks/getProductStockBalanceByAddresAndEan?ean=${ean}&addressId=${addressId}`);
}

async function getInformation(addressCode, productCode, lotNumber = '') {
  return RestService.getAuthenticated('stocks/getInformation', { addressCode, productCode, lotNumber });
}

// POST
async function doStockTransfer(transfer) {
  return RestService.postAuthenticated('stocks/transfer', transfer);
}

async function setNewQuantityToStock(branchCode, addressCode, ean, lotNumber, expirationDate, quantity, depositCode) {
  return RestService.postAuthenticated('stocks/setNewQuantityToStock', {
    branchCode, addressCode, ean, lotNumber, expirationDate, quantity, depositCode,
  });
}

async function getStockDashboardInfo(selectedBranch, selectedDeposits) {
  return RestService.postAuthenticated('stocks/getStockDashboardInfo', { selectedBranch, selectedDeposits });
}

export default {
  getStockBalances,
  getStockMovements,
  getProductByEan,
  doStockTransfer,
  getAllStockBalances,
  getProductsStockBalance,
  getProductStockBalanceByAddresAndEan,
  getInformation,
  setNewQuantityToStock,
  getStockDashboardInfo,
};
