import AccessProfileServer from '../../server/accessProfile';
import { addLoading, removeLoading } from './loading';

export const setAccessProfiles = accessProfiles => ({
  type: 'SET_DEVELOP_ACCESS_PROFILE_GRID',
  payload: accessProfiles,
});

export const getAllAccessProfiles = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const accessProfiles = await AccessProfileServer.getAllAccessProfiles();
    dispatch(setAccessProfiles(accessProfiles));
  } finally {
    dispatch(removeLoading());
  }
};

export const getAccessProfileOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AccessProfileServer.getAccessProfileOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const createAccessProfile = accessProfile => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newAccessProfile = await AccessProfileServer.createAccessProfile(accessProfile);
    return newAccessProfile;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateAccessProfile = (accessProfile) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AccessProfileServer.updateAccessProfile(accessProfile);
  } finally {
    dispatch(removeLoading());
  }
}

export const getAccessProfile = (code) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AccessProfileServer.getAccessProfile(code);
  } finally {
    dispatch(removeLoading());
  }
}

export const deleteAccessProfile = (code) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AccessProfileServer.deleteAccessProfile(code);
  } finally {
    dispatch(removeLoading());
  }
}