import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import { getWarehousesList, deleteWarehouse } from '../../../../app/store/actions/warehouses';
import Table from '../../../../components/table/Table';

class WarehouseList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      warehouseUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showWarehouseDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditWarehouse(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyWarehouse(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDeleteWarehouse(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getWarehouses();
    } else {
      await this.getWarehouses();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      warehouseUpdate: null,
    });
  };

  getWarehouses = async () => {
    await this.props.getWarehousesList();
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deleteWarehouse = async (warehouse) => {
    const result = await this.props.deleteWarehouse(warehouse._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'success',
        I18n.t('BEE157' /* Eliminar Armazém */),
        I18n.t('BEE158', { 0: warehouse.code } /* Armazém %{0} eliminado com sucesso! */),
        'top-right',
      );
    }
    await this.getWarehouses();
  };

  showWarehouseDetail = (warehouse) => {
    this.props.history.push({
      pathname: ROUTES.WAREHOUSE_DETAIL,
      state: {
        warehouse: warehouse._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditWarehouse = (warehouse) => {
    this.props.history.push({
      pathname: ROUTES.WAREHOUSE_EDIT,
      state: {
        warehouse: warehouse._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyWarehouse = (warehouse) => {
    this.props.history.push({
      pathname: ROUTES.WAREHOUSE_COPY,
      state: {
        warehouse: warehouse._original,
      },
    });
  };

  showDeleteWarehouse = (warehouse) => {
    this.setState({ showModalDelete: true, warehouseUpdate: warehouse });
  };

  render() {
    const { warehouseUpdate, showModalDelete, page } = this.state;
    const { warehousesList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE17' /* Armazéns */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          wikiHelp={ROUTES.WAREHOUSE_HELP}
          headerTitle={I18n.t('BEE17' /* Armazéns */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.WAREHOUSE_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE159' /* Incluir Armazém */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getWarehouses,
          }}
          filterable
          data={warehousesList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {
          (showModalDelete
            && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.deleteWarehouse(warehouseUpdate)}
              onCancel={() => this.setInitState()}
            >
              {I18n.t('BEE160', { 0: warehouseUpdate.code } /* O armazém %{0} será eliminado! */)}
            </SweetAlert>
            )
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  warehousesList: state.warehouses && state.warehouses.warehousesList,
});

const mapDispatchToProps = (dispatch) => ({
  getWarehousesList: () => dispatch(getWarehousesList()),
  deleteWarehouse: (warehouseId) => dispatch(deleteWarehouse(warehouseId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WarehouseList));
