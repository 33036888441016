import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';
import helpersBarCode from '../../../../helpers/barCode';
import { getEansList, deleteEan } from '../../../../app/store/actions/eans';
import { getUserPrinterOptions } from '../../../../app/store/actions/printers';
import { printEanLabel } from '../../../../app/store/actions/labels';

class EanList extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      showModalDelete: false,
      eanUpdate: null,
      pages: 0,
      totalDataLength: 0,
      showPrinterDialog: false,
      printerOptions: [],
      printerSelected: null,
      eanPrint: null,
    };

    this.defaultSorted = [
      {
        id: 'productCode',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 60,
        Cell: (rows) => (
          <div style={{ display: 'flex', justifyContent: 'center' }} className="m-auto">
            <button type="button" className="btn btn-primary btn-xs" onClick={() => this.showPrinterDialog(rows.row)}>
              <i className="fa fa-print" />
            </button>
            <div style={{ textAlign: 'center' }}>
              <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
              <UncontrolledButtonDropdown>
                <DropdownToggle color="default" caret className="btn-xs" />
                <DropdownMenu>
                  <DropdownItem onClick={() => this.showEanDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                  <DropdownItem onClick={() => this.showEditEan(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                  <DropdownItem onClick={() => this.showCopyEan(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                  <DropdownItem onClick={() => this.showDeleteEan(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        ),
      }, {
        Header: I18n.t('BEE378' /* Código do Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE377' /* EAN */),
        accessor: 'ean',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1897' /* Descrição */),
        accessor: 'product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'barCodeType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Cell: (row) => helpersBarCode.barCodeType(row.value),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {helpersBarCode.barCodeType().map((element, index) => (
              <option
                key={index}
                value={element.value}
              >
                {element.label}
              </option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE2591' /* Fator */),
        accessor: 'factor',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE379' /* Principal */),
        accessor: 'main',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}</span>
          </div>
        ),
        Filter: this.filterColumnSelect,
      },
    ];
  }

  async componentDidMount() {
    const { printerSelected } = this.state;
    this.maintainsState();
    const printerReq = await this.props.getUserPrinterOptions();
    const { printerOptions, userPrinter } = printerReq;

    this.setState({
      printerOptions,
      printerSelected: printerSelected === null
        ? userPrinter
        : printerSelected !== userPrinter
          ? printerSelected
          : userPrinter,
    });
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({ page: this.props.location.state.dice.page });
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      eanUpdate: null,
    });
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  filterColumnSelect = ({ filter, onChange }) => (
    <select
      type="text"
      style={{ width: '100%' }}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    >
      <option value="all">{I18n.t('BEE793' /* Todos */)}</option>
      <option value="yes">{I18n.t('BEE172' /* Sim */)}</option>
      <option value="no">{I18n.t('BEE173' /* Não */)}</option>
    </select>
  );

  getEans = async () => {
    const data = await this.props.getEansList({
      page: this.page,
      pageSize: this.pageSize,
      sorted: this.sorted,
      filtered: this.filtered,
    });
    this.setState({ pages: data.pages, firstRun: true, totalDataLength: data.totalDataLength });
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deleteEan = async (delEan) => {
    const result = await this.props.deleteEan(delEan._original.id);

    this.setInitState();
    if (result) {
      addNotification('success', I18n.t('BEE837' /* Eliminar EAN */), I18n.t('BEE838' /* EAN eliminado com sucesso ! */), 'top-right');
    }
    await this.getEans();
  };

  showEanDetail = (ean) => {
    this.props.history.push({
      pathname: ROUTES.EAN_DETAIL,
      state: {
        ean: ean._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditEan = (ean) => {
    this.props.history.push({
      pathname: ROUTES.EAN_EDIT,
      state: {
        ean: ean._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyEan = (ean) => {
    this.props.history.push({
      pathname: ROUTES.EAN_COPY,
      state: {
        ean: ean._original,
      },
    });
  };

  showDeleteEan = (ean) => {
    this.setState({ showModalDelete: true, eanUpdate: ean });
  };

  showPrinterDialog = async (ean) => {
    const { printerOptions } = this.state;

    if (!printerOptions || printerOptions.length === 0) {
      addNotification(
        'danger',
        I18n.t('BEE3227' /* Impressão EAN */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
      return;
    }

    if (printerOptions && printerOptions.length > 1) {
      this.setState({
        showPrinterDialog: true,
        eanPrint: ean,
      });
    } else {
      await this.setState({
        showPrinterDialog: false,
        printerSelected: printerOptions[0].value,
        eanPrint: ean,
      });

      await this.printEanLabel();
    }
  };

  printEanLabel = () => {
    const { eanPrint, printerSelected, printerOptions } = this.state;

    this.props.printEanLabel(eanPrint.ean, printerSelected || printerOptions[0].value);

    addNotification(
      'success',
      I18n.t('BEE3227' /* Impressão EAN */),
      I18n.t('BEE3228' /* Etiquetas enviadas para impressora! */),
      'top-right',
    );

    this.setState({
      showPrinterDialog: false,
    });
  };

  createSelect = (value, attr, label, items) => (
    <div className="form-group p-2">
      <label>{label}</label>
      <select
        className="form-control"
        value={value || ''}
        onChange={(e) => this.setValue(attr, e.target.value)}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );

  render() {
    const {
      eanUpdate, showModalDelete, pages, showPrinterDialog, printerOptions, printerSelected, page, totalDataLength,
    } = this.state;
    const { eansList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE377' /* EANs */)}</li>
          </ol>
        </div>
        <Table
          wikiHelp={ROUTES.EAN_HELP}
          downloadCSV
          headerTitle={I18n.t('BEE377' /* EANs */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.EAN_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE839' /* Incluir EAN */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getEans,
          }}
          filterable
          data={eansList}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          manual
          pages={pages}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          onFetchData={(state, instance) => {
            this.page = (state.page) ? state.page : (!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : state.page;
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;
            this.getEans();
          }}
        />
        {(showModalDelete
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.deleteEan(eanUpdate)}
              onCancel={() => this.setInitState()}
            >
              {I18n.t('BEE840', { 0: eanUpdate.code } /*  O EAN %{0} será eliminada ! */)}
            </SweetAlert>
          )
        )}
        {(showPrinterDialog && (
          <SweetAlert
            confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE1324' /* Selecionar Impressora */)}
            onConfirm={() => this.printEanLabel()}
            onCancel={() => this.setState({
              showPrinterDialog: false,
            })}
          >
            <div className="mt-4">
              {this.createSelect(printerSelected, 'printerSelected', I18n.t('BEE328' /* Impressora */), printerOptions)}
            </div>
          </SweetAlert>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  eansList: state.eans && state.eans.eansList,
});

const mapDispatchToProps = (dispatch) => ({
  getEansList: (tableParams) => dispatch(getEansList(tableParams)),
  deleteEan: (eanId) => dispatch(deleteEan(eanId)),
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  printEanLabel: (eanCode, printer) => dispatch(printEanLabel(eanCode, printer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EanList));
