import RestService from '../services/rest';

function getInboundDocument(accessKey) {
  return RestService.postAuthenticated('volumePlate/getOrder', { accessKey })
}

function savePlateToCheck(id, expectedVolumes, licensePlate) {
  return RestService.postAuthenticated('volumePlate/updateOrder', { id, expectedVolumes, licensePlate })
}

export default {
  getInboundDocument,
  savePlateToCheck,
}