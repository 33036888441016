import { addLoading, removeLoading } from './loading';
import OutboundOrderCheckRequests from '../../server/outboundOrderCheck';


export const getOutboundOrdersList = (startDate, endDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrdersList = await OutboundOrderCheckRequests.getOutboundOrdersList(startDate, endDate);
    return outboundOrdersList;
  } finally {
    dispatch(removeLoading());
  }
};

// Action Creator para Consulta -> Expedição -> Conferência
export const getOutboundOrdersListCheck = (startDate, endDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrderList = await OutboundOrderCheckRequests.getOutboundOrdersListCheck(startDate, endDate);
    return outboundOrderList
  } finally {
    dispatch(removeLoading());
  }
}

export const getOutboundOrder = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrder = await OutboundOrderCheckRequests.getOutboundOrder(id);
    return outboundOrder
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrderByNumber = (orderNumber) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrder = await OutboundOrderCheckRequests.getOutboundOrderByNumber(orderNumber);
    return outboundOrder
  } finally {
    dispatch(removeLoading());
  }
};

export const confirmCheck = (id, quantityCheck, outboundOrderId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const pickResult = await OutboundOrderCheckRequests.postConfirmCheck(id, quantityCheck, outboundOrderId);
    return pickResult
  } finally {
    dispatch(removeLoading());
  }
};