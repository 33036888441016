import { I18n } from 'react-redux-i18n';

export default function validationForm() {
  const validateFormUser = (formData) => {
    const errors = {};

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(formData.email)) {
      errors.email = I18n.t('BEE2892' /* Formato de e-mail inválido. */);
    }

    if (!formData.name || formData.name.trim() === '') {
      errors.name = I18n.t('BEE2893' /* O nome não pode estar vazio. */);
    }

    if (!formData.email || formData.email.trim() === '') {
      errors.email = I18n.t('BEE2894' /* O e-mail não pode estar vazio. */);
    }

    if (!formData.login || formData.login.trim() === '') {
      errors.login = I18n.t('BEE2895' /* O login não pode estar vazio. */);
    }

    if (!formData.password || formData.password.trim() === '') {
      errors.password = I18n.t('BEE2896' /* A senha não pode estar vazia. */);
    }

    if (!formData.confirmPassword || formData.confirmPassword.trim() === '') {
      errors.confirmPassword = I18n.t('BEE2897' /* Confirmação de senha não pode estar vazio. */);
    }

    if (formData.password !== formData.confirmPassword) {
      errors.password = I18n.t('BEE2898' /* As senhas não conferem. */);
      errors.confirmPassword = I18n.t('BEE2898' /* As senhas não conferem. */);
    }

    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{6,}$/;
    if (!passwordRegex.test(formData.password)) {
      errors.password = I18n.t(
        'BEE2899',
        /* A senha deve ter no mínimo 6 caracteres, conter pelo menos uma letra maiúscula e um caractere especial. */
      );
    }
    return errors;
  };

  const validateFormCompany = (formData) => {
    const errors = {};

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(formData.companyEmail)) {
      errors.companyEmail = I18n.t('BEE2900' /* Formato de e-mail da empresa inválido. */);
    }

    if (!formData.code) {
      errors.code = I18n.t('BEE2901' /* Código é obrigatório. */);
    }

    if (!formData.corporateName || formData.corporateName.trim() === '') {
      errors.corporateName = I18n.t('BEE2902' /* Nome corporativo é obrigatório */);
    }

    if (!formData.companyName || formData.companyName.trim() === '') {
      errors.companyName = I18n.t('BEE2903' /* Nome da empresa é obrigatório. */);
    }

    if (!formData.companyEmail || formData.companyEmail.trim() === '') {
      errors.companyEmail = I18n.t('BEE2904' /* E-mail da empresa é obrigatório. */);
    }

    if (!formData.phone) {
      errors.phone = I18n.t('BEE2905' /* Telefone é obrigatório. */);
    }

    if (!formData.cnpj) {
      errors.cnpj = I18n.t('BEE2906' /* CNPJ é obrigatório. */);
    }

    if (!formData.postalCode) {
      errors.postalCode = I18n.t('BEE2907' /* CEP é obrigatório. */);
    }

    if (!formData.street || formData.street.trim() === '') {
      errors.street = I18n.t('BEE2908' /* Rua é obrigatória. */);
    }

    if (!formData.number) {
      errors.number = I18n.t('BEE2909' /* Número é obrigatório. */);
    }

    if (!formData.district || formData.district.trim() === '') {
      errors.district = I18n.t('BEE2910' /* Bairro é obrigatório. */);
    }

    if (!formData.city || formData.city.trim() === '') {
      errors.city = I18n.t('BEE2911' /* Cidade é obrigatória. */);
    }

    if (!formData.state || formData.state.trim() === '') {
      errors.state = I18n.t('BEE2912' /* Estado é obrigatório. */);
    }

    if (!formData.country || formData.country.trim() === '') {
      errors.country = I18n.t('BEE2913' /* País é obrigatório. */);
    }

    return errors;
  };

  const isValidPostalCode = (postalCode) => /^[0-9]{5}-[0-9]{3}$/.test(postalCode);

  const unmaskedPostalCode = (maskedPostalCode) => maskedPostalCode.replace(/\D+/g, '');

  return {
    validateFormUser, validateFormCompany, isValidPostalCode, unmaskedPostalCode,
  };
}
