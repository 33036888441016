import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from '../../components/form/button';
import { returnFiltersByRules } from './utils';
import { generateReport } from '../../app/store/actions/reports';

import { PanelPage } from '../../components/pages/pages';
import FilterDefault from '../../components/filters/FilterDefault';
import Form from '../../components/form/form';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportSalesOrderStatus extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
        </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1963' /* KPIs */) },
      { value: I18n.t('BEE2155' /* Status Pedido de Venda */), active: true },
    ];

    this.state = {
      branchCodeFrom: '',
      branchCodeTo: '',
      orderNumberFrom: '',
      orderNumberTo: '',
      orderEmissionDateTo: '',
      orderEmissionDateFrom: '',
      documentNumberTo: '',
      documentNumberFrom: '',
      customerCodeTo: '',
      customerCodeFrom: '',
      documentSeparationDateTo: '',
      documentSeparationDateFrom: '',
      productCodeTo: '',
      productCodeFrom: '',
      vendorCodeTo: '',
      vendorCodeFrom: '',
      carrierCodeTo: '',
      carrierCodeFrom: '',
      pendingAllocation: false,
      pendingCut: false,
      pendingPicking: false,
      inSeparation: false,
      pendingConference: false,
      pendingDocks: false,
      pendingList: false,
      pendingConsolidation: false,
      finished: false,
      divergence: false,
      canceled: false,
      returned: false,
    };
  }

  onSelectFilter = (newState) => {
    this.setState({ ...newState });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const { location } = this.props;

    const {
      branchCodeFrom, branchCodeTo, orderNumberFrom, orderNumberTo, orderEmissionDateTo,
      orderEmissionDateFrom, documentNumberTo, documentNumberFrom, customerCodeTo, customerCodeFrom,
      documentSeparationDateTo, documentSeparationDateFrom, productCodeTo, productCodeFrom, vendorCodeTo,
      vendorCodeFrom, carrierCodeTo, carrierCodeFrom, pendingAllocation, pendingCut, pendingPicking,
      inSeparation, pendingConference, pendingDocks, pendingList, pendingConsolidation, finished,
      divergence, canceled, returned,
    } = this.state;

    const statusDocument = [];

    if (pendingAllocation) statusDocument.push(1);
    if (pendingCut) statusDocument.push(2);
    if (pendingPicking) statusDocument.push(3);
    if (inSeparation) statusDocument.push(4);
    if (pendingConference) statusDocument.push(5);
    if (pendingDocks) statusDocument.push(6);
    if (pendingList) statusDocument.push(7);
    if (pendingConsolidation) statusDocument.push(8);
    if (finished) statusDocument.push(9);
    if (divergence) statusDocument.push(10);
    if (canceled) statusDocument.push(11);
    if (returned) statusDocument.push(12);

    const filters = returnFiltersByRules([
      [
        '$branchCode',
        {
          type: 'between',
          value: [branchCodeFrom, branchCodeTo],
          instance: 'OutboundOrder',
          as: 'outboundOrder',
        },
      ], [
        '$customerOrderCode',
        {
          type: 'between',
          value: [orderNumberFrom, orderNumberTo],
          instance: 'OutboundOrder',
          as: 'outboundOrder',
        },
      ], [
        '$orderDate',
        {
          type: 'between',
          value: [orderEmissionDateTo, orderEmissionDateFrom],
          instance: 'OutboundOrder',
          as: 'outboundOrder',
        },
      ], [
        '$orderNumber', {
          type: 'between',
          value: [documentNumberTo, documentNumberFrom],
          instance: 'OutboundOrder',
          as: 'outboundOrder',
        },
      ], [
        '$code',
        {
          type: 'between',
          value: [customerCodeTo, customerCodeFrom],
          instance: 'Customer',
          as: 'outboundOrder->customer',
        },
      ], [
        '$pickStartAt',
        {
          type: 'between',
          value: [documentSeparationDateTo, documentSeparationDateFrom],
          instance: 'OutboundOrder',
          as: 'outboundOrder',
        },
      ], [
        'productCode',
        {
          type: 'between',
          value: [productCodeTo, productCodeFrom],
        },
      ], [
        '$representative',
        {
          type: 'between',
          value: [vendorCodeTo, vendorCodeFrom],
          instance: 'OutboundOrder',
          as: 'outboundOrder',
        },
      ], [
        '$carrierCode',
        {
          type: 'between',
          value: [carrierCodeTo, carrierCodeFrom],
          instance: 'Carrier',
          as: 'outboundOrder->carrier',
        },
      ], [
        '$status',
        {
          type: 'in',
          value: statusDocument,
          instance: 'OutboundOrder',
          as: 'outboundOrder',
        },
      ],
    ]);

    this.props.generateReport(
      I18n.t('BEE2155' /* Status Pedido de Venda */).toLowerCase(),
      'xlsx',
      location.pathname,
      [{}],
      filters,
    );
  };

  render() {
    const { location = {} } = this.props;
    const {
      branchCodeFrom, branchCodeTo, orderNumberFrom, orderNumberTo, orderEmissionDateTo, orderEmissionDateFrom,
      documentNumberTo, documentNumberFrom, customerCodeTo, customerCodeFrom, documentSeparationDateTo,
      documentSeparationDateFrom, productCodeTo, productCodeFrom, vendorCodeTo, vendorCodeFrom,
      carrierCodeTo, carrierCodeFrom, pendingAllocation, pendingCut, pendingPicking, inSeparation,
      pendingConference, pendingDocks, pendingList, pendingConsolidation, finished, divergence, canceled, returned,
    } = this.state;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE2155' /* Status Pedido de Venda */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ), formContext.createInputRange(
                    { from: orderNumberFrom, to: orderNumberTo },
                    { from: 'orderNumberFrom', to: 'orderNumberTo' },
                    `${I18n.t('BEE2156' /* Nr. Pedido */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ), formContext.createDateInputRange(
                    { from: orderEmissionDateTo, to: orderEmissionDateFrom },
                    { from: 'orderEmissionDateTo', to: 'orderEmissionDateFrom' },
                    `${I18n.t('BEE2157' /* Data Pedido */)}:`,
                    { from: '', to: '__/__/____' },
                  ), formContext.createInputRange(
                    { from: documentNumberTo, to: documentNumberFrom },
                    { from: 'documentNumberTo', to: 'documentNumberFrom' },
                    `${I18n.t('BEE2158' /* Nr. Nota Fiscal */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ), formContext.createInputRange(
                    { from: customerCodeTo, to: customerCodeFrom },
                    { from: 'customerCodeTo', to: 'customerCodeFrom' },
                    `${I18n.t('BEE50' /* Cliente */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ), formContext.createDateInputRange(
                    { from: documentSeparationDateTo, to: documentSeparationDateFrom },
                    { from: 'documentSeparationDateTo', to: 'documentSeparationDateFrom' },
                    `${I18n.t('BEE1721' /* Data Separação */)}:`,
                    { from: '', to: '__/__/____' },
                  ), formContext.createInputRange(
                    { from: productCodeTo, to: productCodeFrom },
                    { from: 'productCodeTo', to: 'productCodeFrom' },
                    `${I18n.t('BEE225' /* Produto */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ), formContext.createInputRange(
                    { from: vendorCodeTo, to: vendorCodeFrom },
                    { from: 'vendorCodeTo', to: 'vendorCodeFrom' },
                    `${I18n.t('BEE1374' /* Vendedor */)}:`,
                    { from: '', to: 'Z'.repeat(80) },
                  ), formContext.createInputRange(
                    { from: carrierCodeTo, to: carrierCodeFrom },
                    { from: 'carrierCodeTo', to: 'carrierCodeFrom' },
                    `${I18n.t('BEE301' /* Transportadora */)}:`,
                    { from: '', to: 'Z'.repeat(150) },
                  ), formContext.createCheckBoxes([{
                    label: `${I18n.t('BEE1946' /* Pendente Alocação */)}`,
                    value: pendingAllocation,
                    attr: 'pendingAllocation',
                  }, {
                    label: `${I18n.t('BEE1947' /* Pendente Corte de Cabos */)}`,
                    value: pendingCut,
                    attr: 'pendingCut',
                  }, {
                    label: `${I18n.t('BEE1365' /* Pendente Separação */)}`,
                    value: pendingPicking,
                    attr: 'pendingPicking',
                  }, {
                    label: `${I18n.t('BEE1367' /* Em Separação */)}`,
                    value: inSeparation,
                    attr: 'inSeparation',
                  }, {
                    label: `${I18n.t('BEE1948' /* Pendente Conferência */)}`,
                    value: pendingConference,
                    attr: 'pendingConference',
                  }, {
                    label: `${I18n.t('BEE1949' /* Pendente Docas */)}`,
                    value: pendingDocks,
                    attr: 'pendingDocks',
                  }, {
                    label: `${I18n.t('BEE1773' /* Pendente Romaneio */)}`,
                    value: pendingList,
                    attr: 'pendingList',
                  }, {
                    label: `${I18n.t('BEE1769' /* Pendente Consolidação */)}`,
                    value: pendingConsolidation,
                    attr: 'pendingConsolidation',
                  }, {
                    label: `${I18n.t('BEE1766' /* Finalizado */)}`,
                    value: finished,
                    attr: 'finished',
                  }, {
                    label: `${I18n.t('BEE176' /* Divergência */)}`,
                    value: divergence,
                    attr: 'divergence',
                  }, {
                    label: `${I18n.t('BEE64' /* Cancelado */)}`,
                    value: canceled,
                    attr: 'canceled',
                  }, {
                    label: `${I18n.t('BEE2159' /* Resolvido */)}`,
                    value: returned,
                    attr: 'returned',
                  },
                    //  {
                    //   label: `${I18n.t('BEE793' /* Todos */)}`,
                    //   value: R,
                    //   attr: 'R',
                    // }
                  ], `${I18n.t('BEE1759' /* Status do Documento */)}:`),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSalesOrderStatus);
