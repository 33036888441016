import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';
import FormatHelpers from '../../../helpers/format';

class AllStockBalance extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.defaultSorted = [{
      id: 'productCode',
      desc: false,
    }, {
      id: 'branchCode',
      desc: false,
    }];

    this.state = {
      pages: 0,
      totalDataLength: 0,
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 350,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2090' /* Fabricante */),
        accessor: 'manufacturer',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE695' /* Código Complementar */),
        accessor: 'complementCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE696' /* Informação Complementar */),
        accessor: 'complementInfo',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE530' /* Disponível */),
        accessor: 'balance',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
        filterable: false,
      }, {
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
        filterable: false,
      }, {
        Header: I18n.t('BEE482' /* Alocada */),
        accessor: 'allocated',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
        filterable: false,
      },
    ];
  }

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  selectedLine = async (selectedProduct) => {
    this.props.getProductsStockBalance(selectedProduct.productCode, selectedProduct.branchCode);
    this.setState({ selectedProductId: selectedProduct.id });
  };

  getAllStockBalances = async () => {
    const data = await this.props.getAllStockBalances({
      page: this.page,
      pageSize: this.pageSize,
      filtered: this.filtered,
      sorted: this.sorted,
    });
    this.setState({ pages: data.pages, totalDataLength: data.totalDataLength });
  };

  render() {
    const { allStockBalancesList = [] } = this.props;
    const { pages, selectedProductId, totalDataLength } = this.state;
    return (
      <div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE1761' /* Saldo do Estoque - Detalhes */)}
          wikiHelp={ROUTES.DETAILED_STOCK_BALANCE_HELP}
          panelHeaderProps={{
            onClickReload: this.getAllStockBalances,
            children: I18n.t('BEE27' /* Produtos */).toUpperCase(),
          }}
          filterable
          data={allStockBalancesList}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          expander
          defaultSorted={this.defaultSorted}
          defaultPageSize={this.pageSize}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          manual
          pages={pages}
          onFetchData={(state, instance) => {
            this.page = state.page;
            this.pageSize = state.pageSize;
            this.filtered = state.filtered;
            this.sorted = state.sorted;
            this.getAllStockBalances();
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.original) {
              const dateForCalculation = (rowInfo.original.outboundPreExpiration)
                ? moment(rowInfo.original.expirationDate).subtract(parseInt(rowInfo.original.outboundPreExpiration, 10), 'days')
                : rowInfo.original.expirationDate;

              const color = (rowInfo.original.controlExpirationDate && rowInfo.original.expirationDate)
                ? moment().isSameOrAfter(dateForCalculation, 'day')
                  ? '#bf4441'
                  : 'black'
                : 'black';

              return {
                onClick: () => {
                  this.selectedLine(rowInfo.original);
                },
                style: {
                  color,
                  background: selectedProductId && rowInfo.original.id === selectedProductId ? 'silver' : 'white',
                  cursor: 'pointer',
                },
              };
            }
            return {};
          }}
        />

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allStockBalancesList: state.stocks && state.stocks.allStockBalancesList,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllStockBalance));
