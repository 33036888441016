import { I18n } from 'react-redux-i18n';

function serialLabelStatus(type) {
  if (type || type === 0) {
    switch (type) {
      case 1:
        return I18n.t('BEE2768' /* Iniciada */);
      case 2:
        return I18n.t('BEE2188' /* Aprovada */);
      default:
        return type;
    }
  } else if (!type) {
    return [
      { value: 1, label: I18n.t('BEE2768' /* Iniciada */) },
      { value: 2, label: I18n.t('BEE2188' /* Aprovada */) },
    ];
  }
}

function serialLabelSituation(type) {
  if (type || type === 0) {
    switch (type) {
      case 1:
        return I18n.t('BEE2767' /* Não impresso */);
      case 2:
        return I18n.t('BEE2615' /* impresso */);
      default:
        return type;
    }
  } else if (!type) {
    return [
      { value: 1, label: I18n.t('BEE2767' /* Não impresso */) },
      { value: 2, label: I18n.t('BEE2615' /* impresso */) },
    ];
  }
}

export default {
  serialLabelStatus,
  serialLabelSituation,
};
