import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import DualList from '../../components/pages/DualList';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportMovementInboundOrderPreEntry extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
        </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE36' /* Recebimento */) },
      { value: I18n.t('BEE2279' /* Nota Fiscal Pré-Entrada */), active: true },
    ];

    this.options = [{
      label: I18n.t('BEE145' /* Filial */),
      value: 'branchCode',
    }, {
      label: I18n.t('BEE1997' /* Nome Filial */),
      value: 'branchName',
    }, {
      label: I18n.t('BEE2174' /* Número Nota Fiscal */),
      value: 'orderNumber',
    }, {
      label: I18n.t('BEE1729' /* Espécie */),
      value: 'specie',
    }, {
      label: I18n.t('BEE403' /* Série */),
      value: 'serie',
    }, {
      label: I18n.t('BEE1044' /* Código do fornecedor */),
      value: 'supplierCode',
    }, {
      label: I18n.t('BEE352' /* Nome Fantasia */),
      value: 'businessName',
    }, {
      label: I18n.t('BEE84' /* Razão Social */),
      value: 'name',
    }, {
      label: I18n.t('BEE2284' /* Movimento */),
      value: 'orderType',
    }, {
      label: I18n.t('BEE2280' /* Data Emissão */),
      value: 'createdAt',
    }, {
      label: I18n.t('BEE2281' /* Data Recebimento / Validação XML */),
      value: 'deliveryDate',
    }, {
      label: I18n.t('BEE2282' /* Valor Total Nota */),
      value: 'totalNoteValue',
    }, {
      label: I18n.t('BEE1030' /* CFOP */),
      value: 'cfop',
    }];

    this.state = {
      selected: [],
      branchCodeFrom: '',
      branchCodeTo: '',
      orderNumberFrom: '',
      orderNumberTo: '',
      serieFrom: '',
      serieTo: '',
      supplierCodeFrom: '',
      supplierCodeTo: '',
      createdAtFrom: '',
      createdAtTo: '',
      deliveryDateFrom: '',
      deliveryDateTo: '',
    };
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const { location } = this.props;
    const {
      selected,
      branchCodeFrom, branchCodeTo,
      orderNumberFrom, orderNumberTo,
      serieFrom, serieTo,
      supplierCodeFrom, supplierCodeTo,
      createdAtFrom, createdAtTo,
      deliveryDateFrom, deliveryDateTo,
    } = this.state;

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['orderNumber', { type: 'between', value: [orderNumberFrom, orderNumberTo] }],
      ['serie', { type: 'between', value: [serieFrom, serieTo] }],
      ['supplierCode', { type: 'between', value: [supplierCodeFrom, supplierCodeTo] }],
      ['createdAt', { type: 'between', value: [createdAtFrom, createdAtTo] }],
      ['deliveryDate', { type: 'between', value: [deliveryDateFrom, deliveryDateTo] }],
    ]);

    this.props.generateReport(
      I18n.t('BEE2279' /* Nota Fiscal Pré-Entrada */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  onSelectFilter = (newState) => {
    this.setState({ ...newState });
  };

  render() {
    const { location = {} } = this.props;
    const {
      selected,
      branchCodeFrom, branchCodeTo,
      orderNumberFrom, orderNumberTo,
      serieFrom, serieTo,
      supplierCodeFrom, supplierCodeTo,
      createdAtFrom, createdAtTo,
      deliveryDateFrom, deliveryDateTo,
    } = this.state;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE2279' /* Nota Fiscal Pré-Entrada */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: orderNumberFrom, to: orderNumberTo },
                    { from: 'orderNumberFrom', to: 'orderNumberTo' },
                    `${I18n.t('BEE2174' /* Número Nota Fiscal */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: serieFrom, to: serieTo },
                    { from: 'serieFrom', to: 'serieTo' },
                    `${I18n.t('BEE403' /* Série */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: supplierCodeFrom, to: supplierCodeTo },
                    { from: 'supplierCodeFrom', to: 'supplierCodeTo' },
                    `${I18n.t('BEE1044' /* Código do fornecedor */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createDateInputRange(
                    { from: createdAtFrom, to: createdAtTo },
                    { from: 'createdAtFrom', to: 'createdAtTo' },
                    `${I18n.t('BEE2280' /* Data Emissão */)}:`,
                    { from: '__/__/____', to: '__/__/____' },
                  ),
                  formContext.createDateInputRange(
                    { from: deliveryDateFrom, to: deliveryDateTo },
                    { from: 'deliveryDateFrom', to: 'deliveryDateTo' },
                    `${I18n.t('BEE2283' /* Data Recebimento */)}:`,
                    { from: '__/__/____', to: '__/__/____' },
                  ),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (nameDoc, type, route, columns, filters) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMovementInboundOrderPreEntry);
