import CollectorsRequests from '../../server/collectors';
import { addLoading, removeLoading } from './loading';

export const setCollectors = collectors => ({
  type: 'SET_COLLECTORS',
  collectors,
});

export const getCollector = (collectorId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CollectorsRequests.getCollector(collectorId);
  } finally {
    dispatch(removeLoading());
  }
}

export const getCollectorsList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const collectors = await CollectorsRequests.getCollectors();
    dispatch(setCollectors(collectors));
  } finally {
    dispatch(removeLoading());
  }
};

export const createCollector = collector => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newCollector = await CollectorsRequests.createCollector(collector);
    return newCollector;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateCollector = (collector) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CollectorsRequests.updateCollector(collector);
  } finally {
    dispatch(removeLoading());
  }
}

export const deleteCollector = (collectorId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CollectorsRequests.deleteCollector(collectorId);
  } finally {
    dispatch(removeLoading());
  }
}