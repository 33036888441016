import { addLoading, removeLoading } from './loading';
import OutboundOrderVolumesRequests from '../../server/outboundOrderVolumes';

export const setOutboundVolumeList = (outboundVolumeList) => ({
  type: 'SET_OUTBOUND_VOLUME_LIST',
  outboundVolumeList,
});

export const setUserRemovalList = (userRemovalList) => ({
  type: 'SET_USER_REMOVAL_LIST',
  userRemovalList,
});

export const getVolumes = (outboundOrderNumber) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundVolumes = await OutboundOrderVolumesRequests.getVolumes(outboundOrderNumber);
    dispatch(setOutboundVolumeList(outboundVolumes));
  } finally {
    dispatch(removeLoading());
  }
};

export const getBaptism = (baptismLabel) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const result = await OutboundOrderVolumesRequests.getBaptism(baptismLabel);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const saveVolumeCheck = (volumesList) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const result = await OutboundOrderVolumesRequests.saveVolumeCheck(volumesList);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrderCheckProductList = (baptismLabel) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderVolumesRequests.getOutboundOrderCheckProductList(baptismLabel);
  } finally {
    dispatch(removeLoading());
  }
};

export const getProductQuantityVolume = (outboundVolumeId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderVolumesRequests.getProductQuantityVolume(outboundVolumeId);
  } finally {
    dispatch(removeLoading());
  }
};

export const confirmVolumeCheck = (
  outboundRangeProductId,
  quantity,
  packageCode,
  baptismLabel,
  divergenceProduct,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderVolumesRequests.confirmVolumeCheck(
      outboundRangeProductId,
      quantity,
      packageCode,
      baptismLabel,
      divergenceProduct,
    );
  } finally {
    dispatch(removeLoading());
  }
};

export const finishVolume = (outboundVolumeId, quantityVolume, grossWeight, printerCode = '') => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderVolumesRequests.finishVolume(outboundVolumeId, quantityVolume, grossWeight, printerCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const changePackageVolume = (outboundVolumeId, packageCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderVolumesRequests.changePackageVolume(outboundVolumeId, packageCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const restartVolumeCheck = (outboundVolumeId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderVolumesRequests.restartVolumeCheck(outboundVolumeId);
  } finally {
    dispatch(removeLoading());
  }
};
