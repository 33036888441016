import { addLoading, removeLoading } from './loading';
import WizardRequests from '../../server/wizard';
import getAddressByCep from '../../services/viaCep/index';

export const SET_FIRST_ACCESS = 'SET_FIRST_ACCESS';
export const SET_FORM = 'SET_FORM';
export const SET_FORM_ERRORS = 'SET_FORM_ERRORS';

export const setForm = (attr, value) => ({
  type: SET_FORM,
  payload: { attr, value },
});

export const setFirstAccess = (firstAccess) => ({
  type: SET_FIRST_ACCESS,
  payload: firstAccess,
});

export const setFormErrors = (attr, error) => ({
  type: SET_FORM_ERRORS,
  payload: { attr, error },
});

export const createWizard = (user) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newWizard = await WizardRequests.createWizard(user);
    return newWizard;
  } finally {
    dispatch(removeLoading());
  }
};

export const fetchCEP = (CEP) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newWizard = await getAddressByCep(CEP);
    return newWizard;
  } finally {
    dispatch(removeLoading());
  }
};
