import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

import ProductHelpers from '../../../helpers/products';
import Table from '../../../components/table/Table';
import FormatHelpers from '../../../helpers/format';

class StockBalanceComparison extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultPageSize: 10,
    };

    this.defaultSorted = [{
      id: 'productCode',
      desc: false,
    }, {
      id: 'branchCode',
      desc: false,
    }];

    this.tableColumns = [
      {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'productFullName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 500,
      }, {
        Header: `${I18n.t('BEE441' /* Quantidade */)} ERP`,
        accessor: 'quantityEPR',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
        filterable: false,
      }, {
        Header: `${I18n.t('BEE441' /* Quantidade */)} BEE`,
        accessor: 'quantityBee',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
        filterable: false,
      }, {
        Header: I18n.t('BEE2152' /* Diferença */),
        accessor: 'difference',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
        filterable: false,
      }, {
        Header: I18n.t('BEE773' /* Controle Estoque */),
        accessor: 'stockControlType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Cell: (row) => (
          <span>{ProductHelpers.stockControlType(row.value)}</span>
        ),
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {ProductHelpers.stockControlType().map((element, index) => <option key={index} value={element.value}>{element.label}</option>)}
          </select>
        ),
      }, {
        Header: I18n.t('BEE701' /* Controlar Vencimento */),
        accessor: 'controlExpirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 170,
        Cell: (row) => (
          <span>{row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}</span>
        ),
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option key="all" value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option key="true" value>{I18n.t('BEE172' /* Sim */)}</option>
            <option key="false" value={false}>{I18n.t('BEE173' /* Não */)}</option>
          </select>
        ),
      }, {
        Header: I18n.t('BEE2090' /* Fabricante */),
        accessor: 'manufacturer',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
    ];
  }

  selectedLine = async (selectedProduct) => {
    this.props.getProductsStockBalance(selectedProduct.productCode);
  };

  render() {
    const { listStockErp = [], selectedProductCode } = this.props;

    return (
      <div>
        <Table
          panelHeaderProps={{
            onClickReload: this.props.getListInventoryErp,
            children: I18n.t('BEE27' /* Produtos */).toUpperCase(),
          }}
          downloadCSV
          filterable
          data={listStockErp}
          columns={this.tableColumns}
          expander
          defaultSorted={this.defaultSorted}
          defaultPageSize={this.state.defaultPageSize}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.original) {
              const color = (parseFloat(rowInfo.original.quantityEPR) !== parseFloat(rowInfo.original.quantityBee))
                ? '#bf4441'
                : 'black';

              return {
                onClick: () => {
                  this.selectedLine(rowInfo.original);
                },
                style: {
                  color,
                  background: selectedProductCode && rowInfo.original.productCode === selectedProductCode
                    ? 'silver' : 'white',
                  cursor: 'pointer',
                },
              };
            }
            return {};
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  listStockErp: state.inventoriesErp && state.inventoriesErp.listStockErp,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StockBalanceComparison));
