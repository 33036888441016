import InboundOrderAddRequests from '../../server/inboundOrderAdd';
import { addLoading, removeLoading } from './loading';

export const createInboundOrder = (inboundOrder) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrderAddRequests.putInboundOrder(inboundOrder);
  } finally {
    dispatch(removeLoading());
  }
}

export const updateInboundOrder = (inboundOrderId, inboundOrder) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrderAddRequests.putInboundOrderUpdate(inboundOrderId, inboundOrder);
  } finally {
    dispatch(removeLoading());
  }
}

export const getProduct = (productCodeEan) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrderAddRequests.getProduct(productCodeEan);
  } finally {
    dispatch(removeLoading());
  }
}

export const getSuppliers = (supplierQuery) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrderAddRequests.getSuppliers(supplierQuery);
  } finally {
    dispatch(removeLoading());
  }
}

export const getBaseInfo = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrderAddRequests.getBaseInfo();
  } finally {
    dispatch(removeLoading());
  }
}