import _ from 'lodash';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import 'react-table/react-table.css';
import Table from '../../../components/table/Table';

export default class AddressSelectForm extends React.Component {
  constructor(props) {
    super(props);

    this.tableColumns = [
      {
        Header: I18n.t('BEE267' /* Endereço */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
      {
        Header: I18n.t('BEE355' /* Curva */),
        accessor: 'curve',
        style: { alignSelf: 'center', textAlign: 'center' },
      },
      {
        Header: I18n.t('BEE385' /* Classificação */),
        accessor: 'size',
        style: { alignSelf: 'center', textAlign: 'center' },
      },
    ];
  }

  createTable = () => (
    <Table
      panelHeaderProps={{
        noButton: true,
        children: I18n.t('BEE1753' /* Endereços Vazios */),
      }}
      filterable
      data={this.props.emptyAddressesOptions}
      columns={this.tableColumns}
      expander
      defaultPageSize={10}
      defaultSorted={this.defaultSorted}
      defaultFilterMethod={(filter, row) => {
        const input = _.lowerCase(filter.value);
        const value = _.lowerCase(row[filter.id]);
        if (_.includes(value, input)) {
          return true;
        }
      }}
      getTrProps={(state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
          return {
            onClick: async (e) => {
              await this.props.selectSuggestedAddress(rowInfo.original);
            },
            style: {
              cursor: 'pointer',
            },
          };
        }
        return {};
      }}
    />
  );

  render() {
    return (
      <span>
        <div className="row">
          <div className="col">
            { this.createTable() }
          </div>
        </div>
      </span>
    );
  }
}
