import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import SweetAlert from 'react-bootstrap-sweetalert';
import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import { deleteOrder, getOrdersTypeList } from '../../../../app/store/actions/typeOrder';
import ROUTES from '../../../../config/routes';

class TypeOrdersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      orderDelete: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">
              {I18n.t('BEE55' /* Ações */)}
            </button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showOrdersTypeDetail(rows.row)}>
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showEditOrdersType(rows.row)}>
                  {I18n.t('BEE57' /* Editar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showCopyOrdersType(rows.row)}>
                  {I18n.t('BEE80' /* Copiar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showDeleteOrder(rows.row)}>
                  {I18n.t('BEE81' /* Eliminar */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'type',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE1897' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      },
      {
        Header: I18n.t('BEE1796' /* Depósito Entrada */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.getOrdersType();
    this.setInitState();
  }

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      orderDelete: null,
    });
  };

  getOrdersType = async () => {
    try {
      await this.props.getOrdersTypeList();
    } catch (e) {
      addNotification(
        'danger',
        I18n.t('BEE1627' /* Erro */),
        I18n.t('BEE1969' /* Erro ao buscar dados */),
        'top-right',
      );
    }
  };

  showOrdersTypeDetail = (orderType) => {
    this.props.history.push({
      pathname: ROUTES.ORDERS_TYPE_DETAIL,
      state: {
        orderType: orderType._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditOrdersType = (orderType) => {
    this.props.history.push({
      pathname: ROUTES.ORDERS_TYPE_EDIT,
      state: {
        orderType: orderType._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyOrdersType = (orderType) => {
    this.props.history.push({
      pathname: ROUTES.ORDERS_TYPE_COPY,
      state: {
        orderType: orderType._original,
      },
    });
  };

  deleteOrder = async (order) => {
    try {
      await this.props.deleteOrder({ order: order._original });
      await this.setInitState();

      addNotification(
        'success',
        I18n.t('BEE2797' /* Eliminar Tipo de Documento */),
        I18n.t('BEE2798', { 0: order._original.type } /* Tipo de documento %{0} eliminado com sucesso! */),
        'top-right',
      );
      await this.getOrdersType();
    } catch (err) {
      await this.setInitState();
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE2797' /* Eliminar Tipo de Documento */),
            `${error.code} - ${error.message || error.details}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE2797' /* Eliminar Tipo de Documento */),
            I18n.t('BEE2813', { 0: order._original.type } /* Erro ao eliminar o Tipo de Documento %{0} */),
            'top-right',
          );
        }
      }
    }
  };

  showDeleteOrder = (typeOrder) => {
    this.setState({ showModalDelete: true, orderDelete: typeOrder });
  };

  render() {
    const { orderTypeList = [] } = this.props;
    const { orderDelete, showModalDelete } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE2796' /* Tipos de Documentos */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE2796' /* Tipos de Documentos */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.ORDERS_TYPE_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE2799' /* Incluir tipo de Documento */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getOrdersType,
          }}
          filterable
          data={orderTypeList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.deleteOrder(orderDelete)}
              onCancel={() => this.setInitState()}
            >
              {I18n.t('BEE2800', { 0: orderDelete.type } /* O tipo de documento %{0} será eliminado! */)}
            </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orderTypeList: state.ordersTypes && state.ordersTypes.orderTypeList,
});

const mapDispatchToProps = (dispatch) => ({
  getOrdersTypeList: () => dispatch(getOrdersTypeList()),
  deleteOrder: (order) => dispatch(deleteOrder(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TypeOrdersList));
