const INITIAL_STATE = {
  eansList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_EANS': {
      return { ...state, eansList: action.eans };
    }
    default:
      return state;
  }
};
