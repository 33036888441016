import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

import Table from '../../../components/table/Table';
import FormatHelpers from '../../../helpers/format';

export default class ProductTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableProductsColumns = [
      {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'productName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 270,
      }, {
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      },
    ];

    this.state = {
      selectedInboundOrderProductId: null,
    };
  }

  render() {
    const { productsToCutList = [], productToCutSelect } = this.props;
    // const { showUndoCheckModal } = this.state;

    return (
      <span>
        <Table
          panelHeaderProps={{
            noButton: true,
            children: I18n.t('BEE27' /* Produtos */).toUpperCase(),
          }}
          filterable
          data={productsToCutList}
          columns={this.tableProductsColumns}
          expander
          defaultPageSize={99}
          style={{
            height: '960px',
          }}
          className="-striped -highlight"
          showPagination={false}
          // pageSizeOptions={[5, 10]}
          defaultSorted={[{ id: 'lineNumber', desc: false }]}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  this.props.setProductToCut(rowInfo.original);
                },
                style: {
                  background: productToCutSelect && rowInfo.original.productCode === productToCutSelect.productCode
                    ? 'silver'
                    : 'white',
                  cursor: 'pointer',
                },
              };
            }
            return {};
          }}
        />
      </span>
    );
  }
}
