import React from 'react';
import { withRouter } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Panel, PanelBody, PanelHeader } from '../panel/panel';
import { I18nGetLocaleCurrency } from '../../helpers/i18n';

class LineChart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleFormatter = (data) => {
    const { useFormatter = true, formatToCurrency = false } = this.props;

    const localeCurrency = I18nGetLocaleCurrency(navigator.language);

    if (useFormatter) {
      if (formatToCurrency) {
        data.dataLabels = {
          enabled: true,
          formatter(val) {
            const currencyVal = val.toLocaleString(
              localeCurrency.language,
              {
                style: 'currency',
                currency: localeCurrency.currency,
              },
            );

            return `${currencyVal}`;
          },
        };

        data.tooltip = {
          y: {
            formatter(val) {
              const currencyVal = val.toLocaleString(
                localeCurrency.language,
                {
                  style: 'currency',
                  currency: localeCurrency.currency,
                },
              );

              return `${currencyVal}`;
            },
          },
        };
      }
    }
    return data;
  };

  render() {
    const { lineChartCheckData } = this.props;

    return (
      <Panel>
        <PanelHeader onClickCollapse>
          {this.props.title}
        </PanelHeader>
        <PanelBody>
          { lineChartCheckData
            ? (
              <div style={{ height: '450px' }}>
                <Chart
                  options={this.handleFormatter(lineChartCheckData.options)}
                  series={lineChartCheckData.series}
                  type={this.props.typeChart}
                  height="450px"
                />
              </div>
            )
            : (
              <div
                className="d-flex flex-column w-100"
                style={{ height: `${this.props.height ? this.props.height : '450px'}` }}
              >
                <div
                  className="d-flex align-items-center justify-content-center user-select-none"
                  style={{
                    flex: 'auto', color: '#9b8a79', fontSize: '1rem', fontFamily: 'Helvetica',
                  }}
                >
                  {I18n.t('BEE2423' /* Não há dados no filtro informado */)}
                </div>
              </div>
            )}
        </PanelBody>
      </Panel>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(withRouter(LineChart));
