import RestService from '../services/rest';

async function getVolumes(outboundOrderNumber) {
  return RestService.getAuthenticated(
    `outboundVolume/getVolumes?outboundOrderNumber=${outboundOrderNumber}`,
  );
}

async function getBaptism(baptismLabel) {
  return RestService.getAuthenticated(
    `outboundOrderVolume/getBaptism?baptismLabel=${baptismLabel}`,
  );
}

async function saveVolumeCheck(volumesList) {
  return RestService.postAuthenticated('outboundOrderVolume/saveVolumeCheck', { volumesList });
}

async function getOutboundOrderCheckProductList(baptismLabel) {
  return RestService.getAuthenticated('outboundVolume/productList', { baptismLabel });
}

async function getProductQuantityVolume(outboundVolumeId) {
  return RestService.getAuthenticated('outboundVolume/productQuantityVolume', { outboundVolumeId });
}

async function confirmVolumeCheck(outboundRangeProductId, quantity, packageCode, baptismLabel, divergenceProduct) {
  return RestService.postAuthenticated('outboundVolume/confirmVolumeCheck', {
    outboundRangeProductId, quantity, packageCode, baptismLabel, divergenceProduct,
  });
}

async function finishVolume(outboundVolumeId, quantityVolume, grossWeight, printerCode = '') {
  return RestService.postAuthenticated(
    'outboundVolume/finishVolume',
    {
      outboundVolumeId, quantityVolume, grossWeight, printerCode,
    },
  );
}

async function changePackageVolume(outboundVolumeId, packageCode) {
  return RestService.putAuthenticated(
    'outboundVolume/changePackage',
    { outboundVolumeId, packageCode },
  );
}

async function restartVolumeCheck(outboundVolumeId) {
  return RestService.postAuthenticated(
    'outboundVolume/restartVolumeCheck',
    { outboundVolumeId },
  );
}

export default {
  getVolumes,
  getBaptism,
  saveVolumeCheck,
  getOutboundOrderCheckProductList,
  getProductQuantityVolume,
  confirmVolumeCheck,
  finishVolume,
  changePackageVolume,
  restartVolumeCheck,
};
