/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-empty */
import { I18n } from 'react-redux-i18n';

import NotificationsServer from '../../server/notifications';

import addNotification from '../../../components/notification';
import { addLoading, removeLoading } from './loading';

export const setNotifications = (notifications) => ({
  type: 'SET_NOTIFICATIONS',
  notifications,
});

export const getNotifications = (withLoading = false) => async (dispatch) => {
  if (withLoading) dispatch(addLoading());
  try {
    const ret = await NotificationsServer.getNotifications();

    dispatch(setNotifications(ret.notifications || []));
  } catch (e) {}

  if (withLoading) dispatch(removeLoading());
};

export const setNotificationAsRead = (notifId) => async () => {
  try {
    await NotificationsServer.setNotificationAsRead(notifId);
  } catch (e) {}
};

export const deleteNotification = (notifId, history) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const ret = await NotificationsServer.deleteNotification(notifId);

    if (ret) {
      dispatch(getNotifications(true));
      history.goBack();
    } else {
      addNotification(
        'danger',
        I18n.t('BEE5' /* Notificações */),
        I18n.t('BEE2565' /* Falha ao deletar a notificação */),
        'top-right',
      );
    }
  } catch (e) {
    addNotification(
      'danger',
      I18n.t('BEE5' /* Notificações */),
      I18n.t('BEE2565' /* Falha ao deletar a notificação */),
      'top-right',
    );
  }
  dispatch(removeLoading());
};

export const downloadFile = (notifId, id, nameDoc, typeDoc) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await NotificationsServer.downloadFile(notifId, id, nameDoc, typeDoc);
  } catch (e) {
    addNotification(
      'danger',
      I18n.t('BEE5' /* Notificações */),
      `${I18n.t('BEE2564' /* Falha ao baixar o arquivo. */)} ${nameDoc}`,
      'top-right',
    );
  }
  dispatch(removeLoading());
};
