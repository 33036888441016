
import React from 'react';
import { default as Ctb } from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

export default class CheckBoxTreeComponent extends React.PureComponent {
  render = () => <Ctb 
    {...this.props}
    icons={{
      check: <span className="fas fa-check-square" />,
      uncheck: <span className="far fa-square" />,
      halfCheck: <span className="fas fa-plus-square" />,
      expandClose: <span className="fas fa-angle-right fa-lg" />,
      expandOpen: <span className="fas fa-angle-down fa-lg" />,
      expandAll: <span className="fa angle-right" />,
      collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
      parentClose: <span className="rct-icon rct-icon-parent-close" />,
      parentOpen: <span className="rct-icon rct-icon-parent-open" />,
      leaf: <span style={{ color: '#f79c20' }} className="fas fa-align-justify fa-xs" />,
  }}
  />
}