import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';

import { getProduct } from '../../../app/store/actions/inboundOrderAdd';

class AddAddProduct extends React.Component {  
  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
      editing: false,
      product: null,
      unitsMeasure: [],
      productCode: '',
      supplierProductCode: '',
      quantity: 0,
      unitMeasure: '',
      note: ''
    };
  }

  resetForm = () => {
    this.setState({
      showForm: false,
      product: null,
      unitsMeasure: [],
      productCode: '',
      quantity: 0,
      unitMeasure: '',
      note: ''
    })
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled, keyDownFunction=undefined, id) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          id={id}
          onKeyDown={ keyDownFunction && (e => keyDownFunction(e, id)) }
          type={type}
          className="form-control m-b-5"
          value={value}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createNumberFormat = (value, attr, label, decimals, keyDownFunction=undefined, id) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <NumberFormat
          id={id}
          onKeyDown={ keyDownFunction && (e => keyDownFunction(e, id)) }
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={(!this.props.fractionalQuantity) ? 0 : decimals}
          value={value}
          onValueChange={(values) => {
            this.setValue(attr, values.floatValue)
          }}
          fixedDecimalScale
          defaultValue={0}
        ></NumberFormat>
      </div>
    </div>
  );

  createSelect = (value, attr, label, items, keyDownFunction=undefined, id) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <select
          id={id}
          onKeyDown={ keyDownFunction && (e => keyDownFunction(e, id)) }
          className="form-control"
          value={value}
          onChange={e => this.setValue(attr, e.target.value)}
        >
          {items.map(item => (
            <option key={item.id} value={item.code}>{`${item.code} - ${item.name}`}</option>
          ))}
        </select>
      </div>
    </div>
  );

  setValue = (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  }

  goToElement = (e, id) => {
    e.keyCode === 13 && document.getElementById(id+1).focus()
  }

  readBarCode = () => {
    addNotification('danger', I18n.t('BEE516' /* Adicionar Produto */), I18n.t('BEE1035' /* Coletor não implementado ! */), 'top-right')
  }

  keyDownGetProduct = e => {
    e.keyCode === 13 && this.getProduct()
  }

  getProduct = async () => {
    try {
      const productCodeEan = this.state.productCode;

      if (!productCodeEan) {
        addNotification('danger', I18n.t('BEE516' /* Adicionar Produto */), I18n.t('BEE1036' /* É necessário informar o código ou EAN do produto para adicionar */), 'top-right')
        return
      }

      const product = await this.props.getProduct(productCodeEan)
      
      if (!product) {
        addNotification('danger', I18n.t('BEE516' /* Adicionar Produto */), I18n.t('BEE1037', {0:productCodeEan} /* Produto com código ou EAN %{0} não encontrado */), 'top-right')
        return
      }

      this.setState({
        product,
        showForm: true,
        productCode: product.productCode,
        supplierProductCode: '',
        unitMeasure: product.unitMeasure,
        quantity: 0,
        note: '',
      })
      
      document.getElementById('21').focus()
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE516' /* Adicionar Produto */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          addNotification(
            'danger', I18n.t('BEE516' /* Adicionar Produto */), I18n.t('BEE1038' /* Houve um erro ao buscar o produto */), 'top-right'
          );
        }
      } else {
        addNotification(
          'danger', I18n.t('BEE516' /* Adicionar Produto */), I18n.t('BEE1038' /* Houve um erro ao buscar o produto */), 'top-right'
        );
      }
    }
  }

  keyDownAddProduct = e => {
    e.keyCode === 13 && this.addProduct()
  }

  addProduct = () => {
    const { product, quantity, unitMeasure, note, supplierProductCode } = this.state;

    if (quantity <= 0) { addNotification('danger', I18n.t('BEE516' /* Adicionar Produto */), I18n.t('BEE1039' /* É necessário informar a quantidade */), 'top-right'); return }
    if (!supplierProductCode) { addNotification('danger', I18n.t('BEE516' /* Adicionar Produto */),I18n.t('BEE1040' /* É necessário informar o código do produto do fornecedor */), 'top-right'); return }

    const newProduct = {
      product,
      quantity,
      unitMeasure,
      supplierProductCode,
      note,
    }

    this.props.addProduct(newProduct)
    this.props.toggleShowAddProduct()
  }

  salvarAlteracoes = () => {
    const { product, quantity, unitMeasure, note, supplierProductCode, lineNumber } = this.state;

    if (quantity <= 0) { addNotification('danger', I18n.t('BEE516' /* Adicionar Produto */), I18n.t('BEE1039' /* É necessário informar a quantidade */), 'top-right'); return }
    if (!supplierProductCode) { addNotification('danger', I18n.t('BEE516' /* Adicionar Produto */), I18n.t('BEE1040' /* É necessário informar o código do produto do fornecedor */), 'top-right'); return }

    const updProduct = {
      lineNumber,
      product,
      quantity,
      unitMeasure,
      supplierProductCode,
      note,
    }

    this.props.updateProduct(updProduct)
    this.props.toggleShowAddProduct()
  }

  componentDidMount() {
    if (this.props.editingProduct) {
      const { product, supplierProductCode, quantity, 
        unitMeasure, note, lineNumber } = this.props.editingProduct;

      this.setState({
        showForm: true,
        editing: true,
        product,
        productCode: product.productCode,
        lineNumber,
        supplierProductCode,
        quantity,
        unitMeasure,
        note
      })
    }
  }

  render() {
    const { isOpen, toggleShowAddProduct, unitsMeasure, } = this.props;

    const { editing, showForm, product, supplierProductCode, note } = this.state;
    let { productCode, quantity, unitMeasure} = this.state;

    const productName = product ? product.name : ''

    return (
      <Modal size='xl' isOpen={isOpen} toggle={() => toggleShowAddProduct()}>
      <ModalHeader toggle={() => toggleShowAddProduct()}>{I18n.t('BEE516' /* Adicionar Produto */)}</ModalHeader>
      <ModalBody>
        <div className='row'>
          <div className='col'>
            {this.createInput(
              productCode, 'productCode', showForm ? I18n.t('BEE378' /* Código do Produto */) : I18n.t('BEE1041' /* Código ou EAN do produto */), I18n.t('BEE1042' /* Busca por código do produto ou EAN */), 'text', true, showForm, this.keyDownGetProduct, 20
            )}
            {showForm && this.createInput(
              productName, 'product.name', I18n.t('BEE1043' /* Nome do produto */), '', 'text', true, true,
            )}
            {showForm && this.createInput(
              supplierProductCode, 'supplierProductCode', I18n.t('BEE1044' /* Código do fornecedor */), '', 'text', false, false, this.goToElement, 21
            )}      
            {showForm && this.createNumberFormat(
              quantity, 'quantity', `${I18n.t('BEE441' /* Quantidade */)}:`, 3, this.goToElement, 22
            )}      
            {showForm && this.createSelect(
              unitMeasure, 'unitMeasure', `${I18n.t('BEE29' /* Unidades de Medida */)}:`, unitsMeasure, this.goToElement, 23
            )}
            {showForm && this.createInput(
              note, 'note', I18n.t('BEE135' /* Observação */), I18n.t('BEE1047' /* Observação (opcional) */), 'text', false, false, this.keyDownAddProduct, 24
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {!showForm && <OneClickButton className='btn btn-white m-5 p-5' onClick={() => this.readBarCode()}>
          {I18n.t('BEE1048' /* Usar coletor */)}
        </OneClickButton>}
        {!showForm && <OneClickButton className='btn btn-primary m-5 p-5' onClick={() => this.getProduct()}>
          {I18n.t('BEE1050' /* Buscar produto */)}
        </OneClickButton>}
        {showForm && !editing && <OneClickButton className='btn btn-white m-5 p-5' onClick={() => this.resetForm()}>
          {I18n.t('BEE1051' /* Buscar outro produto */)}
        </OneClickButton>}
        {showForm && !editing && <OneClickButton className='btn btn-primary m-5 p-5' onClick={() => this.addProduct()}>
          {I18n.t('BEE785' /* Adicionar */)}
        </OneClickButton>}
        {showForm && editing && <OneClickButton className='btn btn-white m-5 p-5' onClick={() => this.props.toggleShowAddProduct()}>
          {I18n.t('BEE99' /* Cancelar */)}
        </OneClickButton>}
        {showForm && editing && <OneClickButton className='btn btn-primary m-5 p-5' onClick={() => this.salvarAlteracoes()}>
         {I18n.t('BEE1034' /* Salvar alterações */)} 
        </OneClickButton>}
      </ModalFooter>
    </Modal>
    )
  }
}

const mapStateToProps = state => ({
  fractionalQuantity: state.app.permissionsCompany && state.app.permissionsCompany.fractionalQuantity,
});

const mapDispatchToProps = dispatch => ({
  getProduct: productCodeEan => dispatch(getProduct(productCodeEan)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddAddProduct));
  
