import RestService from '../services/rest';

async function getDataGrid(tableParams) {
  return RestService.postAuthenticated(
    'outboundPendingAllocationMonitor/list', tableParams
  );
}

async function allocateProducts(outboundOrderId, outboundProductId) {
  return RestService.postAuthenticated('outboundOrders/allocateProducts', {
    outboundOrderId, outboundProductId
  });
}

export default {
  getDataGrid,
  allocateProducts
}