import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

import OneClickButton from '../../../components/form/button';
import Table from '../../../components/table/Table';

export default class BalanceTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableBalanceColumns = [
      {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 90,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE267' /* Endereço */),
        accessor: 'addressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1431' /* Alocado */),
        accessor: 'allocated',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      }, {
        Header: I18n.t('BEE530' /* Disponível */),
        accessor: 'availableQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      },
    ];
  }

  filterBalance = () => {
    const {
      balancesList, totalSelected, checkedOrders, outboundOrderList,
    } = this.props;

    const outboundOrderIds = [];

    checkedOrders.forEach((value, index) => {
      if (value) outboundOrderIds.push(index);
    });

    const filteredList = [];

    if (outboundOrderIds && outboundOrderIds.length > 0) {
      for (let index = 0; index < outboundOrderIds.length; index++) {
        const id = outboundOrderIds[index];

        const outboundOrder = outboundOrderList.find((order) => order.id === id);

        if (outboundOrder) filteredList.push(outboundOrder);
      }
    }

    const filtered = balancesList.filter((balance) => {
      let total = parseFloat(totalSelected);

      for (const order of filteredList) {
        for (const alloc of order.allocations) {
          if (alloc.stockBalanceId === balance.id) {
            total -= parseFloat(alloc.allocated);
          }
        }
      }

      return parseFloat(balance.availableQuantity) >= parseFloat(total);
    });

    return filtered;
  };

  render() {
    const { balanceSelect } = this.props;

    return (
      <div>
        <Table
          panelProps={{
            className: 'm-b-0',
          }}
          panelHeaderProps={{
            noButton: true,
            children: I18n.t('BEE515' /* Saldo */).toUpperCase(),
          }}
          filterable
          data={this.filterBalance()}
          columns={this.tableBalanceColumns}
          expander
          defaultPageSize={99}
          style={{
            height: '260px', // This will force the table body to overflow and scroll, since there is not enough room
          }}
          className="-striped -highlight"
          showPagination={false}
          defaultSorted={[{ id: 'storageAddress', desc: false }]}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  this.props.setBalanceSelect(rowInfo.original);
                },
                style: {
                  background: balanceSelect && rowInfo.original.id === balanceSelect.id ? 'silver' : 'white',
                  cursor: 'pointer',
                },
              };
            }
            return {};
          }}
        />
        <div className="text-left">
          <OneClickButton
            type="button"
            className="btn btn-100 btn-primary p-5 m-5"
            onClick={() => this.props.showAllocationsModal()}
          >
            {I18n.t('BEE1432' /* Alocações */)}
          </OneClickButton>
          <OneClickButton
            type="button"
            className="btn btn-120 btn-primary p-5 m-5"
            onClick={() => this.props.changeProductAllocation()}
          >
            {I18n.t('BEE1433' /* Alterar Alocações */)}
          </OneClickButton>
          <OneClickButton
            type="button"
            className="btn btn-100 btn-primary p-5 m-5"
            onClick={() => this.props.createCutCablePlan()}
          >
            {I18n.t('BEE1435' /* Liberar */)}
          </OneClickButton>
        </div>
      </div>
    );
  }
}
