import StockGroupRequests from '../../server/stockGroup';
import { addLoading, removeLoading } from './loading';

export const setStockGroup = (stockGroup) => ({
  type: 'SET_STOCK_GROUP',
  stockGroup,
});
export const getStockGroupList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const stockGroup = await StockGroupRequests.getStockGroups();
    dispatch(setStockGroup(stockGroup));
  } finally {
    dispatch(removeLoading());
  }
};

export const createStockGroup = (stockGroup) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newStockGroup = await StockGroupRequests.createStockGroup(stockGroup);
    return newStockGroup;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateStockGroup = (stockGroup) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StockGroupRequests.updateStockGroup(stockGroup);
  } finally {
    dispatch(removeLoading());
  }
};

export const getStockGroup = (stockGroupId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StockGroupRequests.getStockGroup(stockGroupId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getStockGroupOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StockGroupRequests.getStockGroupOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteStockGroup = (stockGroupId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StockGroupRequests.deleteStockGroup(stockGroupId);
  } finally {
    dispatch(removeLoading());
  }
};
