import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';

import CollectorHelpers from '../../../../helpers/collectors';

import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getCollectorsList, deleteCollector } from '../../../../app/store/actions/collectors';

class CollectorList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      collectorUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showCollectorDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditCollector(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyCollector(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDeleteCollector(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE1700' /* Marca */),
        accessor: 'brand',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE1701' /* Modelo */),
        accessor: 'model',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE1702' /* Número Patrimonial */),
        accessor: 'assetNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE501' /* Número Série */),
        accessor: 'serialNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE499' /* Ativo */),
        accessor: 'active',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes(_.lowerCase(CollectorHelpers.collectorStatus(false)), input)) {
            return id === false;
          }
          if (_.includes(_.lowerCase(CollectorHelpers.collectorStatus(true)), input)) {
            return id === true;
          }
        },
        Cell: (row) => (
          <span>
            <i
              className="fa fa-circle f-s-7 fa-fw m-r-10 m-t-5"
              style={{
                color: row.value === false ? '#ff5b57'
                  : '#00acac',
                transition: 'all .3s ease',
              }}
            />
            {' '}
            {CollectorHelpers.collectorStatus(row.value)}
          </span>
        ),
      },
      {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getCollectors();
    } else {
      await this.getCollectors();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      collectorUpdate: null,
    });
  };

  getCollectors = async () => {
    await this.props.getCollectorsList();
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deleteCollector = async (collector) => {
    const result = await this.props.deleteCollector(collector._original.id);

    this.setInitState();
    if (result) {
      addNotification('success', I18n.t('BEE1705' /* Eliminar Coletor */), I18n.t('BEE1706', { 0: collector.code } /* Coletor %{0} eliminado com sucesso! */), 'top-right');
    }
    await this.getCollectors();
  };

  showCollectorDetail = (collector) => {
    this.props.history.push({
      pathname: ROUTES.COLLECTOR_DETAIL,
      state: {
        collector: collector._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditCollector = (collector) => {
    this.props.history.push({
      pathname: ROUTES.COLLECTOR_EDIT,
      state: {
        collector: collector._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyCollector = (collector) => {
    this.props.history.push({
      pathname: ROUTES.COLLECTOR_COPY,
      state: {
        collector: collector._original,
      },
    });
  };

  showDeleteCollector = (collector) => {
    this.setState({ showModalDelete: true, collectorUpdate: collector });
  };

  render() {
    const { collectorUpdate, showModalDelete, page } = this.state;
    const { collectorsList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE1396' /* Equipamentos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE30' /* Coletores */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE30' /* Coletores */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.COLLECTOR_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE1703' /* Incluir Coletor */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getCollectors,
          }}
          filterable
          data={collectorsList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteCollector(collectorUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE1704', { 0: collectorUpdate.code } /*  O Coletor %{0} será eliminado! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  collectorsList: state.collectors && state.collectors.collectorsList,
});

const mapDispatchToProps = (dispatch) => ({
  getCollectorsList: () => dispatch(getCollectorsList()),
  deleteCollector: (collectorId) => dispatch(deleteCollector(collectorId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CollectorList));
