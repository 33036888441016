const INITIAL_STATE = {
  warehousesList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_WAREHOUSES': {
      return { ...state, warehousesList: action.warehouses };
    }
    default:
      return state;
  }
};
