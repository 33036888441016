import { endOfDay, startOfDay, subDays } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle,
  PopoverHeader,
  UncontrolledButtonDropdown,
  UncontrolledPopover,
} from 'reactstrap';

import { getCarriersOptions } from '../../app/store/actions/carriers';
import {
  getOutboundOrdersDashboardTotal, setDashboardOutboundOrdersDates, setSelectedUserBranches,
} from '../../app/store/actions/outboundOrders';
import { getOrdersTypeOptions } from '../../app/store/actions/typeOrder';

import CardDashboard from '../../components/card/CardDashboard';
import DateRangePicker from '../../components/date/DateRangePicker.jsx';
import FilterSelectionList from '../../components/form/filterSelectionList';
import Table from '../../components/table/Table';

import ROUTES from '../../config/routes';
import OutboundOrderHelpers from '../../helpers/outboundOrder';

class ExpeditionOverview extends React.PureComponent {
  static maskValue = (value) => {
    let valueTemp = value;
    let nSizeDecimal = valueTemp.length - valueTemp.indexOf('.') - 1;
    nSizeDecimal = nSizeDecimal < 0 ? 0 : nSizeDecimal;

    if (nSizeDecimal < 2) {
      valueTemp += '0'.repeat(2 - nSizeDecimal);
    }

    valueTemp = valueTemp.replace(/\D/g, '');
    valueTemp = valueTemp.replace(/(\d)(\d{2})$/, '$1,$2');
    valueTemp = valueTemp.replace(/(?=(\d{3})+(\D))\B/g, '.');

    return valueTemp;
  };

  constructor(props) {
    super(props);

    this.breadcrumb = [
      { value: I18n.t('BEE42' /* Dashboard */), iconClassName: 'fa fa-chart-line m-t-10 m-r-5' },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE2546' /* Visão Geral Expedição */), active: true },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.showOutboundOrderDetail(rows)}
                >
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1096' /* Pedido cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE402' /* Nota Fiscal */),
        accessor: 'invoiceNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{OutboundOrderHelpers.outboundOrderStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            id="filterSelect"
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OutboundOrderHelpers.outboundOrderStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE53' /* Cidade */),
        accessor: 'cityName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1057' /* Entrega */),
        accessor: 'deliveryDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('DD/MM/YYYY')}
          </span>
        ),
        Filter: this.filterColumn,
      },
    ];

    this.state = {
      defaultSorted: [{
        id: 'orderNumber',
        desc: false,
      }],
      dataCards: [{
        color: '#C69400',
        bigCard: {
          title: I18n.t('BEE43' /* Separação */),
          data: [],
        },
        cards: [],
      },
      {
        color: '#2E79BC',
        bigCard: {
          title: I18n.t('BEE37' /* Conferência */),
          data: [],
        },
        cards: [],
      },
      {
        color: '#C8590F',
        bigCard: {
          title: I18n.t('BEE176' /* Divergência */),
          data: [],
        },
        cards: [],
      }, {
        color: '#518332',
        bigCard: {
          title: I18n.t('BEE41' /* Expedição */),
          data: [],
        },
        cards: [],
      }, {
        color: '#002163',
        bigCard: {
          title: I18n.t('BEE2541' /* Encerrado */),
          data: [],
        },
        cards: [],
      }],
    };
  }

  async componentDidMount() {
    if (!this.props.location.state || !Object.keys(this.props.location.state).length) {
      const startDate = moment().subtract(30, 'd');
      const endDate = moment();
      const dates = { startDate, endDate };

      await this.props.setDashboardOutboundOrdersDates(dates);

      await this.getOutboundOrders();

      const listOrderTypes = await this.props.getOrdersTypeOptions();
      const listCarriers = await this.props.getCarriersOptions();

      this.setState({
        listOrderTypes,
        listCarriers,
      });
    }

    this.loadDataCards();

    this.props.history.push({
      state: {},
    });
  }

  getOutboundOrders = async () => {
    const {
      startDate, endDate,
    } = this.props.dates;

    const branchesToSearch = [];
    if (this.props.selectedBranches) {
      this.props.selectedBranches.map(({ value }) => branchesToSearch.push(value));
    }

    const orderTypesToSearch = [];
    if (this.state.selectedOrderTypes) {
      this.state.selectedOrderTypes.map(({ value }) => orderTypesToSearch.push(value));
    }

    const carriersToSearch = [];
    if (this.state.selectedCarriers) {
      this.state.selectedCarriers.map(({ value }) => carriersToSearch.push(value));
    }

    await this.props.getOutboundOrdersDashboardTotal(
      startDate,
      endDate,
      branchesToSearch,
      orderTypesToSearch,
      carriersToSearch,
    );

    await this.loadDataCards();
    const existOrder = {};

    this.setState({
      selectedOrder: existOrder || {},
    });
  };

  showOutboundOrderDetail = (outboundOrder) => {
    const newBreadcrumb = this.breadcrumb.map((it) => {
      const newIt = { ...it };
      it.active = false;
      return newIt;
    });
    newBreadcrumb.push({ value: I18n.t('BEE1094' /* Detalhes Documento de Saída */), active: true });

    this.props.history.push({
      pathname: ROUTES.OUTBOUND_ORDER_DETAIL,
      state: {
        outboundOrder: outboundOrder.original,
        breadcrumb: newBreadcrumb,
        dashboardOverview: true,
      },
    });
  };

  handleDateApplyEvent = async (value) => {
    const startDate = value[0];
    const endDate = value[1];

    const dates = { startDate, endDate };

    await this.props.setDashboardOutboundOrdersDates(dates);
  };

  handleBranchesSelected = async (event) => {
    const selectedBranches = [];

    event.map((element) => selectedBranches.push({
      value: element.value,
      label: element.label,
    }));

    await this.props.setSelectedUserBranches(selectedBranches);
  };

  handleOrderTypesSelected = async (event) => {
    const selectedOrderTypes = [];

    event.map((element) => selectedOrderTypes.push({
      value: element.value,
      label: element.label,
    }));

    this.setState({
      selectedOrderTypes: selectedOrderTypes || {},
    });
  };

  handleCarriersSelected = async (event) => {
    const selectedCarriers = [];

    event.map((element) => selectedCarriers.push({
      value: element.value,
      label: element.label,
    }));

    this.setState({
      selectedCarriers: selectedCarriers || {},
    });
  };

  DataCards = (data, status) => {
    const dataTemp = [];

    const dataFilter = data.filter((item) => item.status === status);

    if (dataFilter.length <= 0) {
      dataTemp.push({ title: I18n.t('BEE462' /* Documentos */), value: 0 });
      dataTemp.push({ title: I18n.t('BEE93' /* Linhas */), value: 0 });
      dataTemp.push({
        title: (status === 2
          ? I18n.t('BEE2542' /* Metros */) : I18n.t('BEE2543' /* Unidades */)),
        value: 0,
      });
      dataTemp.push({ title: I18n.t('BEE1115' /* Valor */), value: 'R$ 0' });
    } else {
      dataFilter.map((card) => {
        dataTemp.push({ title: I18n.t('BEE462' /* Documentos */), value: card.TotalOutboundOrders });
        dataTemp.push({ title: I18n.t('BEE93' /* Linhas */), value: card.TotalProducts });
        dataTemp.push({
          title: (status === 2
            ? I18n.t('BEE2542' /* Metros */) : I18n.t('BEE2543' /* Unidades */)),
          value: Number(card.TotalUnityOutboundOrders),
        });
        dataTemp.push({
          title: I18n.t('BEE1115' /* Valor */),
          value: `R$ ${this.maskValue(Number(card.TotalAmountOutboundOrders).toFixed(2))}`,
        });

        return dataTemp;
      });
    }

    return dataTemp;
  };

  DataCardsTotal = (data, status) => {
    let document = 0;
    let line = 0;
    let unity = 0;
    let value = 0;

    if (data) {
      data.filter((item) => status.indexOf(item.status) >= 0).map((card) => {
        document += card.TotalOutboundOrders;
        line += card.TotalProducts;
        unity += parseFloat(card.TotalUnityOutboundOrders);
        value += parseFloat(card.TotalAmountOutboundOrders);

        return '';
      });
    }

    return [
      { title: I18n.t('BEE462' /* Documentos */), value: document },
      { title: I18n.t('BEE93' /* Linhas */), value: line },
      { title: I18n.t('BEE2543' /* Unidades */), value: unity },
      { title: I18n.t('BEE1115' /* Valor */), value: `R$ ${this.maskValue(value.toFixed(2))}` },
    ];
  };

  loadDataCards = () => {
    const dataCards = [{
      color: '#C69400',
      bigCard: {
        title: I18n.t('BEE43' /* Separação */),
        data: this.DataCardsTotal(this.props.outboundOrdersTotalByStatus, [2, 3, 4]),
      },
      cards: [{
        title: I18n.t('BEE1947' /* Pendente Corte de Cabos */),
        data: this.DataCards(this.props.outboundOrdersTotalByStatus, 2),
      },
      {
        title: I18n.t('BEE1365' /* Pendente Separação */),
        data: this.DataCards(this.props.outboundOrdersTotalByStatus, 3),
      },
      {
        title: I18n.t('BEE1367' /* Em Separação */),
        data: this.DataCards(this.props.outboundOrdersTotalByStatus, 4),
      }],
    },
    {
      color: '#2E79BC',
      bigCard: {
        title: I18n.t('BEE37' /* Conferência */),
        data: this.DataCardsTotal(this.props.outboundOrdersTotalByStatus, [5]),
      },
      cards: [{
        title: I18n.t('BEE1948' /* Pendente Conferência */),
        data: this.DataCards(this.props.outboundOrdersTotalByStatus, 5),
      }],
    },
    {
      color: '#C8590F',
      bigCard: {
        title: I18n.t('BEE176' /* Divergência */),
        data: this.DataCardsTotal(this.props.outboundOrdersTotalByStatus, [1, 11]),
      },
      cards: [{
        title: I18n.t('BEE176' /* Divergência */),
        data: this.DataCards(this.props.outboundOrdersTotalByStatus, 11),
      }, {
        title: I18n.t('BEE1946' /* Pendente Alocação */),
        data: this.DataCards(this.props.outboundOrdersTotalByStatus, 1),
      }],
    },
    {
      color: '#518332',
      bigCard: {
        title: I18n.t('BEE41' /* Expedição */),
        data: this.DataCardsTotal(this.props.outboundOrdersTotalByStatus, [6, 7, 8, 9]),
      },
      cards: [{
        title: I18n.t('BEE1949' /* Pendente Docas */),
        data: this.DataCards(this.props.outboundOrdersTotalByStatus, 6),
      }, {
        title: I18n.t('BEE1773' /* Pendente Romaneio */),
        data: this.DataCards(this.props.outboundOrdersTotalByStatus, 7),
      }, {
        title: I18n.t('BEE1769' /* Pendente Consolidação */),
        data: this.DataCards(this.props.outboundOrdersTotalByStatus, 8),
      }, {
        title: I18n.t('BEE2529' /* Pendente Faturamento */),
        data: this.DataCards(this.props.outboundOrdersTotalByStatus, 9),
      }],
    }, {
      color: '#002163',
      bigCard: {
        title: I18n.t('BEE2541' /* Encerrado */),
        data: this.DataCardsTotal(this.props.outboundOrdersTotalByStatus, [10, 12, 13]),
      },
      cards: [{
        title: I18n.t('BEE1833' /* Finalizado */),
        data: this.DataCards(this.props.outboundOrdersTotalByStatus, 10),
      }, {
        title: I18n.t('BEE64' /* Cancelado */),
        data: this.DataCards(this.props.outboundOrdersTotalByStatus, 12),
      }, {
        title: I18n.t('BEE1899' /* Devolvido */),
        data: this.DataCards(this.props.outboundOrdersTotalByStatus, 13),
      }],
    }];

    this.setState({
      dataCards,
    });
  };

  render() {
    const {
      outboundOrdersList = [],
      outboundOrdersTotalProcess = [],
      outboundOrdersTotalBilled = {},
      outboundOrdersTotalNotBilled = {},
    } = this.props;
    const startDate = startOfDay(subDays(new Date(), 30));
    const endDate = endOfDay(new Date());
    const {
      defaultSorted, selectedOrder, listOrderTypes, listCarriers,
    } = this.state;

    return (
      <div>
        {!!this.breadcrumb && this.breadcrumb.length && (
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              {this.breadcrumb.map((it, index) => (
                <li key={`${it.value}${index}`} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                  {
                    it.iconClassName
                      ? (
                        <>
                          <i className="fa fa-chart-line m-t-10 m-r-5" />
                          {' '}
                          {it.value}
                        </>
                      ) : (
                        it.value
                      )
                  }
                </li>
              ))}
            </ol>
          </div>
        )}
        <h2>{I18n.t('BEE2546' /* Visão Geral Expedição */)}</h2>
        <div className="d-sm-flex">
          <div className="d-flex mt-2">
            <DateRangePicker
              onChange={(value) => {
                this.handleDateApplyEvent(value);
              }}
              defaultValue={[startDate, endDate]}
            />
            <UncontrolledPopover trigger="hover" placement="top" target="dateRangePicker">
              <PopoverHeader>
                { I18n.t(
                  'BEE3342',
                  { 0: (I18n.t('BEE3344' /* data de criação */)) }, /* O intervalo segue o critério pela %{0} */
                ) }
              </PopoverHeader>
            </UncontrolledPopover>
          </div>
          {/* Filiais */}
          <div className="d-flex mt-2">
            <FilterSelectionList
              value={this.props.selectedBranches}
              onChangeValue={this.handleBranchesSelected}
              placeholderValue={I18n.t('BEE2424' /* Todas as filiais estão selecionadas */)}
              optionsValue={this.props.userBranches}
              onClickValue={this.getOutboundOrders}
            />
          </div>
          {/* Tipo de Documento */}
          <div className="d-flex mt-2 ml-1">
            <FilterSelectionList
              value={this.props.selectedOrderTypes}
              onChangeValue={this.handleOrderTypesSelected}
              placeholderValue={I18n.t('BEE3355' /* Todos os tipos de documentos estão selecionados. */)}
              optionsValue={listOrderTypes}
              onClickValue={this.getOutboundOrders}
            />
          </div>
          {/* Transportadora */}
          <div className="d-flex mt-2 ml-1">
            <FilterSelectionList
              value={this.props.selectedCarriers}
              onChangeValue={this.handleCarriersSelected}
              placeholderValue={I18n.t('BEE3593' /* Todas as transportadoras estão selecionadas. */)}
              optionsValue={listCarriers}
              onClickValue={this.getOutboundOrders}
            />
          </div>
        </div>
        <div
          className="mt-1 d-flex flex-row justify-content-between text-white"
        >
          <CardDashboard dataCards={this.state.dataCards} />
        </div>
        <div
          className="text-gray-darker my-4 d-flex flex-wrap "
          style={{
            gap: '1rem',
          }}
        >
          <div style={{ flex: '1 0 400px', textAlign: 'center' }}>
            <h1>{I18n.t('BEE2547' /* Totais Gerais em Processamento */)}</h1>
            <div
              className="d-flex flex-row justify-content-around mt-4"
              style={{
                borderLeft: '4px solid var(--gray)',
                padding: '1rem',
              }}
            >
              <div className="w-100 text-align-center px-1">
                <h4>{I18n.t('BEE462' /* Documentos */)}</h4>
                <h1>
                  {outboundOrdersTotalProcess.TotalOutboundOrders ? outboundOrdersTotalProcess.TotalOutboundOrders : 0}
                </h1>
              </div>
              <div className="w-100 text-align-center px-1">
                <h4>{I18n.t('BEE93' /* Linhas */)}</h4>
                <h1>{outboundOrdersTotalProcess.TotalProducts ? outboundOrdersTotalProcess.TotalProducts : 0}</h1>
              </div>
              <div className="w-100 text-align-center px-1">
                <h4>{I18n.t('BEE2543' /* Unidades */)}</h4>
                <h1>
                  {outboundOrdersTotalProcess.TotalUnity ? Number(outboundOrdersTotalProcess.TotalUnity) : 0}
                </h1>
              </div>
            </div>
          </div>
          <div style={{ flex: '1 0 50px', textAlign: 'center' }}>
            <h1>{I18n.t('BEE2548' /* Faturados */)}</h1>
            <div
              className="mt-4"
            >
              <div className="d-flex flex-row justify-content-between">
                <h4>{I18n.t('BEE462' /* Documentos */)}</h4>
                <h4>
                  {outboundOrdersTotalBilled.TotalOutboundOrders ? outboundOrdersTotalBilled.TotalOutboundOrders : 0}
                </h4>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <h4>{I18n.t('BEE93' /* Linhas */)}</h4>
                <h4>{outboundOrdersTotalBilled.TotalProducts ? outboundOrdersTotalBilled.TotalProducts : 0}</h4>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <h4>{I18n.t('BEE2543' /* Unidades */)}</h4>
                <h4>
                  {!outboundOrdersTotalBilled.TotalUnityOutboundOrders
                    ? 0
                    : Number(outboundOrdersTotalBilled.TotalUnityOutboundOrders)}
                </h4>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <h4>{I18n.t('BEE1115' /* Valor */)}</h4>
                <h4>
                  {!outboundOrdersTotalBilled.TotalAmountOutboundOrders
                    ? 'R$ 0'
                    : `R$
                    ${this.maskValue(parseFloat(outboundOrdersTotalBilled.TotalAmountOutboundOrders).toFixed(2))}`}
                </h4>
              </div>
            </div>
          </div>
          <div style={{ flex: '1 0 100px', textAlign: 'center' }}>
            <h1>{I18n.t('BEE2549' /* Não Faturados */)}</h1>
            <div
              className="mt-4"
            >
              <div className="d-flex flex-row justify-content-between">
                <h4>{I18n.t('BEE462' /* Documentos */)}</h4>
                <h4>
                  {outboundOrdersTotalNotBilled.TotalOutboundOrders
                    ? outboundOrdersTotalNotBilled.TotalOutboundOrders : 0}
                </h4>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <h4>{I18n.t('BEE93' /* Linhas */)}</h4>
                <h4>{outboundOrdersTotalNotBilled.TotalProducts ? outboundOrdersTotalNotBilled.TotalProducts : 0}</h4>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <h4>{I18n.t('BEE2543' /* Unidades */)}</h4>
                <h4>
                  {!outboundOrdersTotalNotBilled.TotalUnityOutboundOrders
                    ? 0
                    : Number(outboundOrdersTotalNotBilled.TotalUnityOutboundOrders)}
                </h4>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <h4>{I18n.t('BEE1115' /* Valor */)}</h4>
                <h4>
                  {!outboundOrdersTotalNotBilled.TotalAmountOutboundOrders
                    ? 'R$ 0'
                    : `R$ ${this.maskValue(
                      parseFloat(outboundOrdersTotalNotBilled.TotalAmountOutboundOrders)
                        .toFixed(2),
                    )}`}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="tableData">
          <Table
            downloadCSV
            onClickCollapse
            panelHeaderProps={{
              onClickReload: () => this.getOutboundOrders(),
              pageDescription: I18n.t('BEE793' /* Todos */).toUpperCase(),
            }}
            filterable
            data={outboundOrdersList}
            columns={this.tableColumns}
            expander
            defaultPageSize={10}
            defaultSorted={defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            noDataText={I18n.t('BEE2423' /* Não há dados na data informada */)}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  style: {
                    background: selectedOrder && rowInfo.original.id === selectedOrder.id ? 'silver' : 'white',
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  outboundOrdersList: state.outboundOrders && state.outboundOrders.outboundOrdersList,
  outboundOrdersTotalBilled: state.outboundOrders && state.outboundOrders.outboundOrdersTotalBilled,
  outboundOrdersTotalProcess: state.outboundOrders && state.outboundOrders.outboundOrdersTotalProcess,
  outboundOrdersTotalNotBilled: state.outboundOrders && state.outboundOrders.outboundOrdersTotalNotBilled,
  outboundOrdersTotalByStatus: state.outboundOrders && state.outboundOrders.outboundOrdersTotalByStatus,
  userBranches: state.outboundOrders.userBranches,
  selectedBranches: state.outboundOrders.selectedBranches,
  dates: state.outboundOrders.dates,
});

const mapDispatchToProps = (dispatch) => ({
  setDashboardOutboundOrdersDates: (dates) => dispatch(setDashboardOutboundOrdersDates(dates)),
  setSelectedUserBranches: (selectedBranches) => dispatch(setSelectedUserBranches(selectedBranches)),
  getOutboundOrdersDashboardTotal:
    (
      startDate,
      endDate,
      selectedBranches,
      currentRange,
      listOrderTypes,
      listCarriers,
    ) => dispatch(getOutboundOrdersDashboardTotal(
      startDate,
      endDate,
      selectedBranches,
      currentRange,
      listOrderTypes,
      listCarriers,
    )),
  getOrdersTypeOptions: () => dispatch(getOrdersTypeOptions()),
  getCarriersOptions: () => dispatch(getCarriersOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExpeditionOverview));
