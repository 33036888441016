import ReturnPickingOrderRequests from '../../server/returnPickingOrder';
import { addLoading, removeLoading } from './loading';

export const getDataGrid = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ReturnPickingOrderRequests.getDataGrid(tableParams);
  } finally {
    dispatch(removeLoading());
  }
};

export const getDetailOrder = (filterStartDate, filterEndDate, orderNumber = '') => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ReturnPickingOrderRequests.getDetailOrder(filterStartDate, filterEndDate, orderNumber);
  } finally {
    dispatch(removeLoading());
  }
};

export const createPickingReturnDocument = (
  outboundOrderProductIds,
  outboundOrderId,
  orderNumber,
  branchCode,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ReturnPickingOrderRequests.createPickingReturnDocument(
      outboundOrderProductIds,
      outboundOrderId,
      orderNumber,
      branchCode,
    );
  } finally {
    dispatch(removeLoading());
  }
};
