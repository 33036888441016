import RestService from '../services/rest';

// GET
async function dockVolumeRemovalList(query) {
  return RestService.getAuthenticated('dockVolumeRemoval/listOrders', query);
}

// PUT
async function updateDockVolumeRemoval(dockVolumeRemoval) {
  return RestService.putAuthenticated('dockVolumeRemoval/update', dockVolumeRemoval);
}

export default {
  dockVolumeRemovalList,
  updateDockVolumeRemoval,
};
