import UsersRequests from '../../server/users';
import { addLoading, removeLoading } from './loading';

export const setUsers = (users) => ({
  type: 'SET_USERS',
  users,
});

export const createUser = (user) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newUser = await UsersRequests.createUser(user);
    return newUser;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateUser = (user) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.updateUser(user);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUser = (userId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.getUser(userId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUsersList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const users = await UsersRequests.getUsers();
    dispatch(setUsers(users));
  } finally {
    dispatch(removeLoading());
  }
};

export const getUserPermissions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.getUserPermissions();
  } finally {
    dispatch(removeLoading());
  }
};

export const updateUserStatus = (user) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.updateStatus(user);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateUserPassword = (user, newPass, expired = false) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.updatePassword(user, newPass, expired);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateUserValid = (user, newValid) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.updateValid(user, newValid);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.deleteUser(userId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUsersOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.getUsersOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const createLinkPrinter = (userId, printerCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.createLinkPrinter(userId, printerCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteLinkPrinter = (userPrinterId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.deleteLinkPrinter(userPrinterId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getPrinterList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const users = await UsersRequests.getPrinterList(tableParams);
    dispatch(removeLoading());
    return users;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const getRangeUserList = (params) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.getRangeUserList(params);
  } finally {
    dispatch(removeLoading());
  }
};

export const userConfirmation = (login, password) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.userConfirmation(login, password);
  } finally {
    dispatch(removeLoading());
  }
};

export const getListUsersPicking = (branchCode, rangeCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.getListUsersPicking(branchCode, rangeCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const changePasswordLoggedOut = (login, newPassword) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.changePasswordLoggedOut(login, newPassword);
  } finally {
    dispatch(removeLoading());
  }
};

export const changePassword = (login, userId, password, newPassword) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UsersRequests.changePassword(login, userId, password, newPassword);
  } finally {
    dispatch(removeLoading());
  }
};
