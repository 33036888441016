/* eslint-disable class-methods-use-this */
import React from 'react';
import { I18n } from 'react-redux-i18n';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';

class SpeedometerChart extends React.PureComponent {
  constructor() {
    super();

    this.state = {

    };
  }

  createAndUpdateCards = () => {
    const { speedometerChartData } = this.props;

    const generalStatusCards = [];

    speedometerChartData.data.cards.forEach((card) => {
      generalStatusCards.push({
        title: card.label,
        quantity: card.value,
        backgroundColor: '#2d353c',
      });
    });

    return (
      generalStatusCards.map((element) => (
        element.quantity
          ? (
            <div
              key={element.title}
              className="row rounded align-items-center justify-content-center mt-2"
              style={{ height: '5rem', width: '10rem', backgroundColor: `${element.backgroundColor}` }}
            >
              <div className="d-flex flex-column align-items-center text-white">
                <div className="font-weight-bold" style={{ fontSize: '0.7rem' }}>
                  {element.title}
                </div>
                <div style={{ fontSize: '1.2rem' }}>
                  {element.quantity}
                </div>
              </div>
            </div>
          ) : ''
      ))
    );
  };

  render() {
    const { speedometerChartData } = this.props;
    return (
      <div>
        {speedometerChartData && speedometerChartData.data.totalValue ? (
          <div className="d-flex flex-row">
            <div className="d-flex flex-column col-md-2">
              {this.createAndUpdateCards()}
            </div>
            <div className="col-md-10 ml-5">
              <div>
                <Doughnut
                  height={100}
                  data={speedometerChartData}
                  plugins={[ChartDataLabels]}
                  options={{
                    ...speedometerChartData.options,
                    responsive: true,
                    maintainAspectRadio: false,
                  }}
                />
              </div>
              <div className="mt-4 d-flex justify-content-center">
                <table className="text-black text-center border">
                  <thead>
                    <tr className="border font-weight-bold bg-dark text-white" style={{ height: '35px' }}>
                      <th>{I18n.t('BEE283' /* Nível */)}</th>
                      <th>{I18n.t('BEE2437' /* Faixa */)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border" style={{ height: '25px' }}>
                      <td className="pr-3 pl-3">{I18n.t('BEE2438' /* Crítico */)}</td>
                      <td className="pr-3 pl-3">{I18n.t('BEE2444', { 0: '50%' } /* Até %{0} */)}</td>
                    </tr>
                    <tr className="border" style={{ height: '25px' }}>
                      <td className="pr-3 pl-3">{I18n.t('BEE611' /* Baixo */)}</td>
                      <td className="pr-3 pl-3">
                        {I18n.t('BEE2443', { 0: '50%', 1: '65%' } /* %{0} a %{1} */)}
                      </td>
                    </tr>
                    <tr className="border" style={{ height: '25px' }}>
                      <td className="pr-3 pl-3">{I18n.t('BEE391' /* Médio */)}</td>
                      <td className="pr-3 pl-3">
                        {I18n.t('BEE2443', { 0: '65%', 1: '90%' } /* %{0} a %{1} */)}
                      </td>
                    </tr>
                    <tr className="border" style={{ height: '25px' }}>
                      <td className="pr-3 pl-3">{I18n.t('BEE2445' /* Alto */)}</td>
                      <td className="pr-3 pl-3">
                        {I18n.t('BEE2443', { 0: '90%', 1: '98%' } /* %{0} a %{1} */)}
                      </td>
                    </tr>
                    <tr className="border" style={{ height: '25px' }}>
                      <td className="pr-3 pl-3">{I18n.t('BEE2446' /* Excelente */)}</td>
                      <td className="pr-3 pl-3">
                        {I18n.t('BEE2443', { 0: '98%', 1: '100%' } /* %{0} a %{1} */)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column" style={{ height: '450px' }}>
            <div
              className="d-flex align-items-center justify-content-center user-select-none"
              style={{
                flex: 'auto', color: '#9b8a79', fontSize: '1rem', fontFamily: 'Helvetica',
              }}
            >
              {I18n.t('BEE2423' /* Não há dados na data informada */)}
            </div>
          </div>
        )}

      </div>

    );
  }
}

export default SpeedometerChart;
