import RestService from '../services/rest';

async function productAllocationsList(filterStartDate, filterEndDate) {
  return RestService.getAuthenticated('outboundOrderAllocation/productAllocationsList'
    + `?filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}`);
}

async function productAllocationAndStockBalances(outboundOrderId, productCode, branchCode) {
  return RestService.getAuthenticated('outboundOrderAllocation/productAllocationAndStockBalances', {
    outboundOrderId, productCode, branchCode,
  });
}

async function changeAllocationProduct(outboundProductAllocationId, balances) {
  return RestService.putAuthenticated('outboundOrderAllocation/changeAllocationProduct', {
    outboundProductAllocationId, balances,
  });
}

async function allocationListToChange(filterStartDate, filterEndDate) {
  return RestService.getAuthenticated('outboundOrderAllocation/allocationListToChange'
    + `?filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}`);
}

async function getStockBalancesAvailable(outboundProductAllocationId) {
  return RestService.getAuthenticated('outboundOrderAllocation/listBalancesAvailable', {
    outboundProductAllocationId,
  });
}

export default {
  productAllocationsList,
  productAllocationAndStockBalances,
  changeAllocationProduct,
  allocationListToChange,
  getStockBalancesAvailable,
};
