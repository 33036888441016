import ROUTES from '../../config/routes';
import RestService from '../services/rest';

const getAll = async () => RestService.postAuthenticated(
  'permissions/getAllPermissions',
  { ROUTES },
);
const getPermissionTree = async (code) => RestService.postAuthenticated(
  'permissions/getPermissionTree',
  { ROUTES, code },
);
const getMobilePermissionTree = async (code) => RestService.postAuthenticated(
  'permissions/getMobilePermissionTree',
  { code },
);
const updatePermissionTree = async (accessProfileCode, permissions) => RestService.postAuthenticated(
  'permissions/updatePermissionTree',
  { code: accessProfileCode, permissions },
);
const updateMobilePermissionTree = async (accessProfileCode, permissions) => RestService.postAuthenticated(
  'permissions/updateMobilePermissionTree',
  { code: accessProfileCode, permissions },
);

export default {
  getAll,
  getPermissionTree,
  getMobilePermissionTree,
  updatePermissionTree,
  updateMobilePermissionTree,
};
