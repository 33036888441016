import React from 'react';
import { I18n } from 'react-redux-i18n';

class SearchForm extends React.Component {
  constructor() {
    super();

    this.state = {
      searchField: '',
    };
  }

  searchWiki = async (e) => {
    e.preventDefault();
    const { searchField } = this.state;
    const urlWiki = `http://wiki.szsolucoes.com.br/wiki/doku.php/beestock/beestock?q=${searchField}&do=search`;
    window.open(urlWiki, '_blank');

    this.setState({
      searchField: '',
    });
  };

  setValue = (value) => {
    this.setState({
      searchField: value,
    });
  };

  render() {
    const { searchField } = this.state;

    return (
      <li className="navbar-form">
        <form action="" method="POST" name="search_form" onSubmit={this.searchWiki}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder={I18n.t('BEE3189' /* Pesquisar na Wiki da SZ Soluções */)}
              value={searchField || ''}
              onChange={(e) => this.setValue(e.target.value)}
            />
            <button
              type="button"
              className="btn btn-search"
              onClick={this.searchWiki}
            >
              <i className="fa fa-search" />
            </button>
          </div>
        </form>
      </li>
    );
  }
}

export default SearchForm;
