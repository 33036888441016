/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
// eslint-disable-next-line jsx-a11y/control-has-associated-label
// eslint-disable-next-line jsx-a11y/control-has-associated-label
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { I18n } from 'react-redux-i18n';

import 'react-table/react-table.css';
import { connect } from 'react-redux';
import addNotification from '../../../components/notification';
import FormatHelpers from '../../../helpers/format';

class LotControlForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lots: [],
      lotNumber: '',
      quantity: 0,
      damageCount: 0,
      mfgDate: null,
      expDate: null,
      showExpDateDivergeModal: false,
      expirationDivergenceNote: '',
      deposit: '',
    };
  }

  async componentDidMount() {
    this.setState({ lots: this.props.lots });
    await this.getDeposit();
  }

  getDeposit = async () => {
    const { branchUser, receivesExpired } = this.props;
    const deposit = receivesExpired ? branchUser.inboundDeposit : branchUser.rejectedDeposit;

    this.setState({
      deposit,
    });
  };

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div className="form-group">
      <label>{label}</label>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        {!disabled && (
        <div className="input-group-append">

          <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, '')}>
            <i className="fa fa-times" />
          </button>
        </div>
        )}
      </div>
    </div>
  );

  createNumberFormat = (value, attr, label, decimals, keypressFunction = undefined, id = null) => (
    <div className="form-group">
      <label>{label}</label>
      <div className="input-group">
        <NumberFormat
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id))}
          id={id && id}
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={(!this.props.fractionalQuantity) ? 0 : decimals}
          value={value || ''}
          onValueChange={(values) => {
            this.setValue(attr, values.floatValue);
          }}
          fixedDecimalScale
          defaultValue={0}
          allowEmptyFormatting
        />
        <div className="input-group-append">
          <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, 0)}>
            <i className="fa fa-times" />
          </button>
        </div>
      </div>
    </div>
  );

  createDateInput = (value, attr, label, keyDownFunction = null, id = null, type, disabled) => {
    const customDateStyle = ({ className, children }) => (
      <div>
        <div className="bg-primary rounded p-2">
          <h5 className="text-light text-center pt-2">
            Bee
            <span className="text-dark">Stock</span>
          </h5>
        </div>
        <div className={className}>
          <div>{children}</div>
        </div>
      </div>
    );

    return (
      <div className="form-group">
        <label>{label}</label>
        <div className="input-group">
          <DatePicker
            id={id}
            selected={value}
            className="form-control"
            dateFormat="dd/MM/yyyy"
            value={value}
            onChange={(e) => this.calculateDates(attr, e, type)}
            calendarContainer={customDateStyle}
            disabled={disabled}
            customInput={(
              <InputMask
                className="form-control"
                type="text"
                mask="99/99/9999"
              />
            )}
          />
        </div>
      </div>
    );
  };

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 mt-4">
      <label>{label}</label>
      <div className="col">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  goToElement = (e, id) => e.keyCode === 13 && document.getElementById(id + 1).focus();

  keypressAddLot = (e) => e.keyCode === 13 && this.validateLot();

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  calculateDates = (attr, value, type) => {
    const expControl = this.props.product.product.controlExpirationDate;

    if (expControl) {
      const { lifeDays } = this.props.product.product;
      if (type === 'mfg') {
        if (moment(moment().subtract(1, 'days').format()).isBefore(moment(value).format(), 'day')) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE1981' /* A data de fabricação superior a data atual */),
            'top-right',
          );
        } else {
          this.setState((prevState) => {
            const mfgDate = value;
            const expDate = !prevState.expDate ? moment(mfgDate).add(lifeDays, 'day').toDate() : prevState.expDate;
            const expirationDivergenceNote = (!moment(moment().format()).isBefore(moment(expDate).format(), 'day'))
              ? I18n.t('BEE1976' /* ITEM VENCIDO */)
              : '';
            return {
              mfgDate,
              expDate,
              expirationDivergenceNote,
            };
          });
        }
      }

      if (type === 'exp') {
        this.setState((prevState) => {
          const expDate = value;
          const mfgDate = !prevState.mfgDate ? moment(expDate).subtract(lifeDays, 'day').toDate() : prevState.mfgDate;
          const expirationDivergenceNote = (!moment(moment().format()).isBefore(moment(value).format(), 'day'))
            ? I18n.t('BEE1976' /* ITEM VENCIDO */)
            : '';
          return {
            mfgDate,
            expDate,
            expirationDivergenceNote,
          };
        });
      }
    } else {
      this.setValue(attr, value);
    }
  };

  calculateTotalQuantity = () => {
    let total = 0;
    this.state.lots.forEach((l) => {
      total += l.quantity;
    });
    return total;
  };

  clearForm = () => {
    const { product } = this.props.product;
    this.setState((prevState) => ({
      lotNumber: '',
      quantity: 0,
      damageCount: 0,
      mfgDate: null,
      expDate: (product.stockControlType === 3) ? prevState.expDate : null,
      expirationDivergenceNote: '',
    }));
  };

  validateLot = () => {
    const { lotNumber, quantity, expDate } = this.state;

    const { product } = this.props.product;
    const lotsAlreadyAdd = this.state.lots.findIndex((element) => element.lotNumber === lotNumber);
    const quantityCheck = this.state.lots.reduce((accumulator, element) => accumulator + element.quantity, 0);
    const quantityProduct = (this.props.product && this.props.product.quantity) ? this.props.product.quantity : 0;
    const quantityProductCheck = (
      this.props.product && this.props.product.quantityCheck)
      ? this.props.product.quantityCheck : 0;

    if (quantity <= 0) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1980' /* Quantidade deve ser informada e maior que 0 (zero)! */),
        'top-right',
      );
    } else if (product.stockControlType === 3 && (!lotNumber || lotNumber === '')) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1979' /* Lote é obrigatório para Produtos com o Tipo de Controle de Estoque igual a "Lote" */),
        'top-right',
      );
    } else if (product.stockControlType === 3 && lotsAlreadyAdd >= 0) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1978' /* O lote não pode ser repetido */),
        'top-right',
      );
    } else if ((quantityCheck + quantity) > (quantityProduct - quantityProductCheck)) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1982' /* A quantidade conferida ultrapassou a quantidade de produtos */),
        'top-right',
      );
    } else if (product.controlExpirationDate && !expDate) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        'Data de Validade deve ser informada',
        'top-right',
      );
    } else if (
      product.controlExpirationDate
      && this.state.lots.length
      && !moment(this.state.lots[0].expDate).isSame(expDate)) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1983' /* Data de validade diferente das outras */),
        'top-right',
      );
    } else {
      const expControl = product.controlExpirationDate;
      if (expControl) {
        const { inboundPreExpiration } = product;
        const dateExpired = moment(this.state.expDate);

        if (moment().isAfter(dateExpired.subtract(inboundPreExpiration, 'day'))) {
          this.setState({
            showExpDateDivergeModal: true,
          });
          return;
        }
      }

      this.addLot();
    }
  };

  addLot = () => {
    const {
      lotNumber, quantity, damageCount, mfgDate, expDate, expirationDivergenceNote,
    } = this.state;

    const newLot = {
      lotNumber,
      quantity,
      damageCount,
      mfgDate,
      expDate,
      expirationDivergenceNote,
    };

    this.setState((prevState) => ({
      lots: [...prevState.lots, newLot],
      showExpDateDivergeModal: false,
    }));

    this.clearForm();

    document.getElementById(0).focus();
  };

  removeLot = (lotIndex) => {
    this.setState((prevState) => {
      const newLots = [...prevState.lots];
      newLots.splice(lotIndex, 1);
      return { lots: newLots };
    });
  };

  render() {
    const { product } = this.props;

    const { showExpDateDivergeModal, deposit } = this.state;
    const {
      lots, lotNumber, quantity, mfgDate, expDate, expirationDivergenceNote, damageCount,
    } = this.state;

    const expControl = product.product.controlExpirationDate;
    const { stockControlType } = product.product;

    const remMargin = {
      marginBottom: '1rem',
    };

    return (
      <span>
        <div className="row">
          <div className="col">
            <p>
              {I18n.t('BEE225' /* Produto */)}
              :
              {' '}
              <strong>
                {product.productCode}
                {' '}
                -
                {' '}
                {product.product.name}
              </strong>
            </p>
          </div>
        </div>
        <div className="row align-items-end">
          <div className="col-md-2">
            {
              this.createInput(
                lotNumber,
                'lotNumber',
                I18n.t('BEE428' /* Lote */),
                I18n.t('BEE996' /* Informe o lote */),
                'string',
                true,
                stockControlType !== 3,
                this.goToElement,
                10,
              )
            }
          </div>
          <div className="col-md-2">
            {
              this.createDateInput(
                mfgDate,
                'mfgDate',
                I18n.t('BEE488' /* Data de Fabricação */),
                this.goToElement,
                11,
                'mfg',
                !expControl,
              )
            }
          </div>
          <div className="col-md-2">
            {
              this.createDateInput(
                expDate,
                'expDate',
                I18n.t('BEE434' /* Data de Validade */),
                this.goToElement,
                12,
                'exp',
                !expControl,
              )
            }
          </div>
          <div className="col-md-2">
            {
              this.createNumberFormat(quantity, 'quantity', I18n.t('BEE441' /* Quantidade */), 3, this.goToElement, 13)
            }
          </div>
          <div className="col-md-2">
            {
              this.createNumberFormat(
                damageCount,
                'damageCount',
                I18n.t('BEE1665' /* Danificados */),
                3,
                this.keypressAddLot,
                14,
              )
            }
          </div>
          <div className="col-md-1 text-right" style={remMargin}>
            <button type="button" className="btn btn-white" onClick={() => this.clearForm()}>
              {I18n.t('BEE492' /* Limpar */)}
            </button>
          </div>
          <div className="col-md-1 text-right" style={remMargin}>
            <button type="button" className="btn btn-primary" onClick={() => this.validateLot()}>
              {I18n.t('BEE100' /* Confirmar */)}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col table-responsive">
            <table className="table table-bordered widget-table widget-table-rounded">
              <thead>
                <tr className="text-center f-s-12">
                  <th width="15%">{I18n.t('BEE494' /* Sequência */)}</th>
                  <th width="20%">{I18n.t('BEE428' /* Lote */)}</th>
                  <th width="20%">{I18n.t('BEE488' /* Data de Fabricação */)}</th>
                  <th width="20%">{I18n.t('BEE434' /* Data de Validade */)}</th>
                  <th width="15%">{I18n.t('BEE441' /* Quantidade */)}</th>
                  <th width="15%">{I18n.t('BEE1665' /* Danificados */)}</th>
                  <th width="10%">{I18n.t('BEE81' /* Eliminar */)}</th>
                </tr>
              </thead>
              <tbody>
                {lots.length
                  ? (lots.map((lot, i) => (
                    <tr className="text-center" key={i}>
                      <td>
                        {i + 1}
                      </td>
                      <td className="text-center">
                        {lot.lotNumber}
                      </td>
                      <td className="text-center">
                        {lot.mfgDate ? moment(lot.mfgDate).format('DD/MM/YYYY') : ''}
                      </td>
                      <td className="text-center">
                        {lot.expDate ? moment(lot.expDate).format('DD/MM/YYYY') : ''}
                      </td>
                      <td className="text-right">
                        {FormatHelpers.formatNumber(lot.quantity, 3, false)}
                      </td>
                      <td className="text-right">
                        {FormatHelpers.formatNumber(lot.damageCount, 3, false)}
                      </td>
                      <td className="text-center">
                        <button
                          type="button"
                          className="btn btn-default btn-xs"
                          onClick={() => this.removeLot(i)}
                        >
                          {I18n.t('BEE81' /* Eliminar */)}
                        </button>
                      </td>
                    </tr>
                  )))
                  : (
                    <tr className="text-center">
                      <td colSpan="4">{I18n.t('BEE998' /* Sem lotes para mostrar */)}</td>
                    </tr>
                  )}
                <tr>
                  <td colSpan="3" className="text-right">
                    {`${I18n.t('BEE997' /* Total Informado */)}:`}
                  </td>
                  <td className="text-right">
                    {FormatHelpers.formatNumber(this.calculateTotalQuantity(), 3, false)}
                  </td>

                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col text-right p-2">
            <button type="button" className="btn btn-120 btn-primary" onClick={() => this.props.funcSetLots(lots)}>
              {I18n.t('BEE493' /* Salvar */)}
            </button>
          </div>
        </div>
        {(showExpDateDivergeModal
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99')}
            confirmBtnText={I18n.t('BEE100')}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101')}
            onConfirm={() => {
              if (this.state.expirationDivergenceNote) this.addLot();
              else {
                addNotification(
                  'danger',
                  I18n.t('BEE37' /* Conferência */),
                  I18n.t('BEE1977' /* O campo motivo e obrigatório */),
                  'top-right',
                );
              }
            }}
            onCancel={() => this.setState({ showExpDateDivergeModal: false })}
          >
            <div>
              <span>
                {I18n.t(
                  'BEE3259',
                  { 0: deposit },
                  /* Confirmar a movimentação do saldo do Item Vencido para o depósito %{0}? */
                )}
              </span>
              {this.createTextArea(
                expirationDivergenceNote,
                'expirationDivergenceNote',
                `${I18n.t('BEE724' /* Motivo */)}:`,
                I18n.t('BEE991' /* Informe o motivo da divergência */),
                3,
                true,
                false,
              )}
            </div>
          </SweetAlert>
          )
        )}
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  receivesExpired: state.inboundCheck && state.inboundCheck.receivesExpired,
  branchUser: state.app && state.app.branchUser,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LotControlForm);
