import moment from 'moment';
import RestService from '../services/rest';

async function getOutboundOrdersList(startDate, endDate) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    `outboundOrdersPicking/list?startDate=${sDate}&endDate=${eDate}`,
  );
}

async function getOutboundOrderRangeListPicking(startDate, endDate, orderNumber, openPicking = false) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  const date = startDate && endDate ? `startDate=${sDate}&endDate=${eDate}` : '';
  return RestService.getAuthenticated(
    `outboundOrdersPicking/listPicking?${date}${orderNumber
      ? `&orderNumber=${orderNumber}` : ''}&openPicking=${openPicking}`,
  );
}

async function getOutboundRangePicking(outboundRangeId) {
  return RestService.getAuthenticated(`outboundOrdersPicking/listProductsPicking?outboundRangeId=${outboundRangeId}`);
}

async function getOutboundWavesList(startDate, endDate) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    `outboundOrdersPicking/wave?startDate=${sDate}&endDate=${eDate}`,
  );
}

async function getOutboundOrder(id) {
  return RestService.getAuthenticated(
    `outboundOrdersPicking/order?id=${id}`,
  );
}

async function getOutboundOrderByNumber(orderNumber) {
  return RestService.getAuthenticated(
    `outboundOrdersPicking/orderByNumber?orderNumber=${orderNumber}`,
  );
}

async function getOutboundWaveByNumber(waveNumber) {
  return RestService.getAuthenticated(
    `outboundOrdersPicking/waveByNumber?waveNumber=${waveNumber}`,
  );
}

async function getStorageAddressesOptions() {
  return RestService.getAuthenticated(
    'outboundOrdersPicking/storageAddressesOptions',
  );
}

async function postConfirmPick(
  outboundRangeProductId,
  pickedQuantity,
  baptismLabel,
  storageAddressPicked,
  serialGroupId,
  serialLabelId,
  serialLabelControl = false,
  confirmDivergence = false,
  stage = '',
) {
  return RestService.postAuthenticated('outboundOrdersPicking/confirmPicking', {
    outboundRangeProductId,
    pickedQuantity,
    baptismLabel,
    storageAddressPicked,
    serialGroupId,
    serialLabelId,
    serialLabelControl,
    confirmDivergence,
    stage,
  });
}

async function outboundSerialLabel(serialCode, outboundRangeId) {
  return RestService.getAuthenticated('outboundOrdersPicking/serialLabel', { serialCode, outboundRangeId });
}

export default {
  getOutboundOrdersList,
  getOutboundOrder,
  getOutboundOrderByNumber,
  getStorageAddressesOptions,
  postConfirmPick,
  getOutboundWavesList,
  getOutboundWaveByNumber,
  getOutboundOrderRangeListPicking,
  getOutboundRangePicking,
  outboundSerialLabel,
};
