import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { getAllStockBalances, getProductsStockBalance } from '../../../app/store/actions/stocks';
import AllStockBalance from './allStockBalance';
import SelectedStockBalance from './selectedStockBalance';

class DetailedStockBalanceList extends React.PureComponent {
  render() {
    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE33' /* Consultas */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE32' /* Estoque */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1761' /* Saldo do Estoque - Detalhes */)}</li>
          </ol>
        </div>
        <AllStockBalance
          getAllStockBalances={(tableParams) => this.props.getAllStockBalances(tableParams)}
          getProductsStockBalance={(
            productCode,
            branchCode,
          ) => this.props.getProductsStockBalance({ productCode, branchCode })}
        />
        <SelectedStockBalance />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  getAllStockBalances: (tableParams) => dispatch(getAllStockBalances(tableParams)),
  getProductsStockBalance: (tableParams) => dispatch(getProductsStockBalance(tableParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailedStockBalanceList);
