function generatePassword(sizePassword = 6) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=';
  const specialCharacters = '!@#$%^&*()_+-=';
  let senha = '';

  const randomNumbers = new Uint8Array(sizePassword);
  window.crypto.getRandomValues(randomNumbers);
  // eslint-disable-next-line no-mixed-operators
  senha += String.fromCharCode(randomNumbers[0] % 26 + 65);
  senha += parseInt((randomNumbers[2] % 10), 10);
  // eslint-disable-next-line no-plusplus
  for (let i = 3; i < sizePassword; i++) {
    senha += characters[randomNumbers[i] % characters.length];
  }
  senha += specialCharacters[randomNumbers[1] % (specialCharacters.length)];
  return senha;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  generatePassword,
};
