/* eslint-disable class-methods-use-this */
import React from 'react';
import { I18n } from 'react-redux-i18n';

class CountsInformation extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      isEmpty: true,
    };
  }

  componentDidUpdate() {
    this.handleIsEmpty();
  }

  createInputInfo = (value) => (
    <div className="form-group text-left">
      <input
        type="text"
        readOnly
        className="form-control bg-white"
        value={(value || value === 0) ? value : ''}
      />
    </div>
  );

  handleIsEmpty = async () => {
    const { countChartValues } = this.props;
    countChartValues.map((element) => {
      if (element.qtdTotalSheets > 0) {
        this.setState({
          isEmpty: false,
        });
      }
    });
  };

  render() {
    const { countChartValues } = this.props;
    const { isEmpty } = this.state;
    return (
      <div className="row">
        {isEmpty ? (
          <div className="d-flex flex-column" style={{ height: '365px', width: '100%' }}>
            <div
              className="d-flex align-items-center justify-content-center user-select-none"
              style={{
                flex: 'auto', color: '#9b8a79', fontSize: '1rem', fontFamily: 'Helvetica',
              }}
            >
              {I18n.t('BEE2423' /* Não há dados na data informada */)}
            </div>
          </div>
        )
          : countChartValues.map((element) => (
            <div className={`col-xl-4 ${element.title === 2 && 'border-left border-right'}`} key={element.title}>
              <div className="d-flex flex-column">
                <div className="align-self-center mb-2">
                  <h4>{I18n.t('BEE2108', { 0: element.title } /* %{0}ª Contagem */)}</h4>
                </div>
                <div className="row align-items-center h-100 w-100">
                  <div className="col-md-6">
                    <h6>{I18n.t('BEE2450' /* Data / Hora de Início */)}</h6>
                  </div>
                  <div className="col-md-6">
                    {this.createInputInfo(element.initialTime)}
                  </div>
                </div>
                <div className="row align-items-center h-100 w-100">
                  <div className="col-md-6">
                    <h6>{I18n.t('BEE2432' /* Total de Fichas */)}</h6>
                  </div>
                  <div className="col-md-6">
                    {this.createInputInfo(element.qtdTotalSheets)}
                  </div>
                </div>
                <div className="row align-items-center h-100 w-100">
                  <div className="col-md-6">
                    <h6>{I18n.t('BEE2429' /* Pendentes */)}</h6>
                  </div>
                  <div className="col-md-3">
                    {this.createInputInfo(element.qtdNotStarted)}
                  </div>
                  <div className="col-md-3">
                    {this.createInputInfo(element.percentageNotStarted)}
                  </div>
                </div>
                <div className="row align-items-center h-100 w-100">
                  <div className="col-md-6">
                    <h6>{I18n.t('BEE2116' /* Contagem Finalizada */)}</h6>
                  </div>
                  <div className="col-md-3">
                    {this.createInputInfo(element.qtdCountFinished)}
                  </div>
                  <div className="col-md-3">
                    {this.createInputInfo(element.percentageCountFinished)}
                  </div>
                </div>
                { element.title === 1
                && (
                <>
                  <div className="row align-items-center h-100 w-100">
                    <div className="col-md-6">
                      <h6>{I18n.t('BEE2428' /* Canceladas */)}</h6>
                    </div>
                    <div className="col-md-3">
                      {this.createInputInfo(element.qtdCountCanceled)}
                    </div>
                    <div className="col-md-3">
                      {this.createInputInfo(element.percentageCountCanceled)}
                    </div>
                  </div>
                  <div className="row align-items-center h-100 w-100">
                    <div className="col-md-6">
                      <h6>{I18n.t('BEE2431' /* Reprovadas */)}</h6>
                    </div>
                    <div className="col-md-3">
                      {this.createInputInfo(element.qtdCountReproved)}
                    </div>
                    <div className="col-md-3">
                      {this.createInputInfo(element.percentageCountReproved)}
                    </div>
                  </div>
                </>
                )}
                <div className="row align-items-center h-100 w-100">
                  <div className="col-md-6">
                    <h6>{I18n.t('BEE54' /* Progresso */)}</h6>
                  </div>
                  <div className="col-md-6">
                    <div className="progress rounded-corner form-group">
                      <div
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        className="progress-bar f-s-16 f-w-600"
                        style={{
                          width: `${element.progressBarValue}`,
                          color: parseFloat(element.progressBarValue) < 10 && '#333333',
                          overflow: 'visible',
                          // eslint-disable-next-line no-nested-ternary
                          backgroundColor: parseFloat(element.progressBarValue) > 70 ? '#a4c639'
                            : parseFloat(element.progressBarValue) > 40 ? '#f59c1a'
                              : '#ff5b57',
                          transition: 'all .2s ease-out',
                        }}
                      >
                        {element.progressBarValue !== '0%' && element.progressBarValue}
                      </div>
                    </div>
                  </div>
                </div>
                { element.title !== 3
                        && (
                        <div className="row align-items-center h-100 w-100 border-top ml-auto mr-auto mt-2">
                          <div className="row mt-3 align-items-center">
                            <div className="col-md-6">
                              <h6>{I18n.t('BEE2451' /* Fichas com Divergência */)}</h6>
                            </div>
                            { element.title === 1
                              ? (
                                <>
                                  <div className="col-md-3">
                                    {this.createInputInfo(element.qtdDivergentFirstCount)}
                                  </div>
                                  <div className="col-md-3">
                                    {this.createInputInfo(element.percentageDivergentFirstCount)}
                                  </div>
                                </>
                              )
                              : (
                                <>
                                  <div className="col-md-3">
                                    {this.createInputInfo(element.qtdDivergentSecondCount)}
                                  </div>
                                  <div className="col-md-3">
                                    {this.createInputInfo(element.percentageDivergentSecondCount)}
                                  </div>
                                </>
                              )}
                          </div>
                        </div>
                        )}
              </div>
            </div>
          ))}
      </div>

    );
  }
}

export default CountsInformation;
