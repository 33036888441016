import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SweetAlert from 'react-bootstrap-sweetalert';

import OneClickButton from '../../../components/form/button';
import { startSeparation } from '../../../app/store/actions/outboundWaves';
import addNotification from '../../../components/notification';
import { PanelPage } from '../../../components/pages/pages';

class TableWave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerOrderCode: '',
      orderNumber: '',
      outboundRangeProductNote: '',
      showModalNote: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.addNew && this.props.addNew !== prevProps.addNew) {
      this.modalNoteOpening();
    }
  }

  startSeparation = async () => {
    const { outboundWavesList = [] } = this.props;
    if (outboundWavesList.length) {
      try {
        await this.props.startSeparation(outboundWavesList[0].id);
        await this.props.selectPage();
      } catch (error) {
        const messageError = error.response && error.response.data
        && error.response.data.error && error.response.data.error.message;
        if (messageError) {
          addNotification(
            'danger',
            I18n.t('BEE1446' /* Separação por Onda */),
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1446' /* Separação por Onda */),
            I18n.t('BEE1842' /* Erro ao buscar documento para separação */),
            'top-right',
          );
        }
      }
    } else {
      addNotification(
        'danger',
        I18n.t('BEE1446' /* Separação por Onda */),
        I18n.t('BEE1840' /* Nenhum range foi adicionado */),
        'top-right',
      );
    }
  };

  modalNoteOpening = () => {
    const { outboundWavesList } = this.props;
    const lastAddOutboundWaves = (outboundWavesList.length)
      ? outboundWavesList[outboundWavesList.length - 1]
      : null;
    if (lastAddOutboundWaves && lastAddOutboundWaves.outboundRangeNote) {
      this.setState({
        showModalNote: true,
        outboundRangeProductNote: lastAddOutboundWaves.outboundRangeNote,
        customerOrderCode: lastAddOutboundWaves.shipToCustomerCode,
        orderNumber: lastAddOutboundWaves.document,
      });
    }
    this.props.setState({ addNew: false });
  };

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div className="form-group pt-2 text-left">
      <span>{label}</span>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          onBlur={(e) => this.fieldValidation(e, id, attr)}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        {!disabled && (
        <div className="input-group-append">
          <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, '')}>
            <i className="fa fa-times" />
            {' '}
          </button>
        </div>
        )}
      </div>
    </div>
  );

  render() {
    const {
      outboundRangeProductNote, showModalNote, orderNumber, customerOrderCode,
    } = this.state;
    const { outboundWavesList = [] } = this.props;

    return (
      <>
        <PanelPage
          noButton
          content={(
            <div style={{ overflow: 'auto' }}>
              <table className="table table-striped text-left">
                <thead>
                  <tr>
                    <td>{I18n.t('BEE1454' /* Onda */)}</td>
                    <td>{I18n.t('BEE145' /* Filial */)}</td>
                    <td>{I18n.t('BEE443' /* Documento */)}</td>
                    <td>{I18n.t('BEE50' /* Cliente */)}</td>
                    <td>{I18n.t('BEE1096' /* Pedido Cliente */)}</td>
                    <td>{I18n.t('BEE581' /* Range */)}</td>
                    <td>{I18n.t('BEE301' /* Transportadora */)}</td>
                    <td>{I18n.t('BEE1837' /* Total Produtos */)}</td>
                  </tr>
                </thead>
                <tbody>
                  {outboundWavesList.map((element, index) => (
                    <tr key={`${index}`}>
                      <td>{element.wave}</td>
                      <td>{element.branch}</td>
                      <td>{element.document}</td>
                      <td>{element.customer}</td>
                      <td>{element.shipToCustomerCode}</td>
                      <td>{element.rangeCode}</td>
                      <td>{element.carrier}</td>
                      <td>{element.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
)}
          footerContent={(
            <OneClickButton
              type="button"
              style={{ width: 'auto', padding: 10 }}
              className="btn btn-primary p-5 m-5"
              onClick={() => this.startSeparation()}
            >
              {I18n.t('BEE1844' /* Iniciar Separação */)}
            </OneClickButton>
          )}
        />
        {(showModalNote
          && (
          <SweetAlert
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="primary"
            title={I18n.t('BEE135' /* Observação */)}
            onConfirm={() => {
              this.setState({
                showModalNote: false,
                outboundRangeProductNote: '',
                customerOrderCode: '',
                orderNumber: '',
              });
            }}
            onCancel={() => {
              this.setState({
                showModalNote: false,
                outboundRangeProductNote: '',
                customerOrderCode: '',
                orderNumber: '',
              });
            }}
          >
            <>
              <div className="row">
                <div className="col-6">
                  {this.createInput(
                    orderNumber,
                    'orderNumber',
                    I18n.t('BEE443' /* Documento */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-6">
                  {this.createInput(
                    customerOrderCode,
                    'customerOrderCode',
                    I18n.t('BEE1096' /* Pedido Cliente */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
              </div>
              <div className="text-left pt-2">
                <span>{I18n.t('BEE135' /* Observação */)}</span>
                <textarea
                  className="form-control"
                  rows={4}
                  value={outboundRangeProductNote || ''}
                  disabled
                />
              </div>
            </>
          </SweetAlert>
          ))}
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  startSeparation: (outboundWaveId) => dispatch(startSeparation(outboundWaveId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableWave));
