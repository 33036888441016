const INITIAL_STATE = {
  stockGroupList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_STOCK_GROUP': {
      return { ...state, stockGroupList: action.stockGroup };
    }
    default:
      return state;
  }
};
