import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import NumberFormat from 'react-number-format';

import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import Table from '../../../../components/table/Table';

import addNotification from '../../../../components/notification';
import Form from '../../../../components/form/form';
import { PanelPage } from '../../../../components/pages/pages';
import { getProductionOrderSerialLabels, createSerialLabels } from '../../../../app/store/actions/serialLabel';
import { getUserPrinterOptions } from '../../../../app/store/actions/printers';

import SerialLabelHelpers from '../../../../helpers/serialLabel';

import WikiHelp from '../../../../components/tooltip/wikiHelp';
import ROUTES from '../../../../config/routes';

class GenerateSerialLabel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      firstLoading: true,
      totalDataLength: 0,
      pages: 1,
      orderCode: '',
      branchCode: '',
      depositCode: '',
      lotNumber: '',
      expectedDate: '',
      expirationDate: '',
      product: '',
      quantity: 0,
      quantityToPrint: 0,
      serialLabels: [],
      quantityTotalLabel: 0,
      quantitySerialLabels: 0,
      quantityNoSerialLabels: 0,
      productionOrderId: undefined,
      defaultPageSize: 10,
      showPrinterDialog: false,
      printerSelected: '',
      controlExpirationDate: false,
      typeLabel: 'Vertical',
    };

    this.defaultSorted = [{
      id: 'code',
      desc: true,
    }];

    this.tableColumns = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2764' /* Etiqueta Agrupadora */),
        accessor: 'serialGroup.code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2765' /* Etiqueta */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE378' /* Código do Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1043' /* Nome do Produto */),
        accessor: 'product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1688' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Filter: this.filterColumn,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{parseFloat(row.value)}</span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE434' /* Data de Validade */),
        accessor: 'expirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE578' /* Data Criação */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
        Cell: (row) => (
          <div>
            <span>
              {' '}
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{SerialLabelHelpers.serialLabelStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === row.status.toString()) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%', textAlign: 'center' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {SerialLabelHelpers.serialLabelStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE204' /* Situação */),
        accessor: 'situation',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{SerialLabelHelpers.serialLabelSituation(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === row.status.toString()) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%', textAlign: 'center' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {SerialLabelHelpers.serialLabelSituation().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }];
  }

  async componentDidMount() {
    const printerReq = await this.props.getUserPrinterOptions();
    const { printerOptions, userPrinter } = printerReq;

    this.setState({
      printerOptions,
      printerSelected: userPrinter || (printerOptions.length > 0 ? printerOptions[0].value : ''),
    });

    if (this.props.location.state && this.props.location.state.productionOrderCode) {
      this.setState({ orderCode: this.props.location.state.productionOrderCode, firstLoading: false });

      await this.getProductionOrderSerialLabels();
    }
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
    buttonLabel = '',
    buttonFunction = undefined,
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          {buttonLabel && buttonFunction && (
            <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  createNumberFormat = (
    value,
    attr,
    label,
    decimals,
    disabled = false,
    keypressFunction = undefined,
    id = null,
    disabledButton = false,
    autoFocus = false,
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <NumberFormat
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={(!this.props.fractionalQuantity) ? 0 : decimals}
          value={value || ''}
          onValueChange={(values) => {
            this.setValue(attr, values.floatValue);
          }}
          fixedDecimalScale
          defaultValue={0}
          disabled={disabled}
          allowEmptyFormatting
          autoFocus={autoFocus}
        />
        {(!disabled && !disabledButton) && (
          <div className="input-group-append">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.setValue(attr, 0)}
            >
              <i className="fa fa-times" />
            </button>
          </div>
        )}
      </div>
    </div>
  );

  createTextArea = (value = '', attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group">
      <p className="col-form-label">{label}</p>
      <div>
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  getProductionOrderSerialLabels = async () => {
    const {
      orderCode, firstLoading, productionOrderId,
    } = this.state;

    if (firstLoading && productionOrderId) {
      this.setState({ firstLoading: false });
      return;
    }

    try {
      const {
        productionOrder,
        serialLabels,
      } = await this.props.getProductionOrderSerialLabels(
        orderCode,
        this.page,
        this.pageSize,
        this.sorted,
        this.filtered,
      );

      if (!productionOrder) {
        addNotification(
          'danger',
          I18n.t('BEE2784' /* Gerar Etiquetas Seriadas */),
          I18n.t('BEE2782', { 0: orderCode } /* Ordem de produção %{0} encontrada! */),
          'top-right',
        );
      } else {
        const expirationDate = serialLabels.rows.length > 0
          ? serialLabels.rows[0].expirationDate : productionOrder.expirationDate;
        const pages = Math.ceil(serialLabels.count / this.pageSize);
        const notSerialLabel = serialLabels.count >= productionOrder.quantityTotalLabel
          ? 0 : productionOrder.quantityTotalLabel - serialLabels.count;

        this.setState({
          orderCode: productionOrder.code,
          productionOrderId: productionOrder.id,
          branchCode: productionOrder.branchCode,
          depositCode: productionOrder.depositCode,
          lotNumber: productionOrder.lotNumber,
          quantityTotalLabel: productionOrder.quantityTotalLabel,
          expectedDate: productionOrder.expectedDate ? moment(productionOrder.expectedDate).format('DD/MM/YYYY') : '',
          controlExpirationDate: productionOrder.product.controlExpirationDate,
          product: `${productionOrder.productCode} - ${productionOrder.product.fullName}`,
          quantity: Number(productionOrder.quantity),
          quantitySerialLabels: serialLabels.count,
          quantityNoSerialLabels: notSerialLabel,
          serialLabels: serialLabels.rows,
          totalDataLength: serialLabels.count,
          expirationDate: expirationDate ? new Date(expirationDate) : '',
          pages,
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2784' /* Gerar Etiquetas Seriadas */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2784' /* Gerar Etiquetas Seriadas */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  showPrinterDialog = async () => {
    const {
      printerOptions, quantityToPrint, controlExpirationDate, expirationDate,
    } = this.state;

    if (quantityToPrint === 0 || !quantityToPrint) {
      addNotification(
        'danger',
        I18n.t('BEE2784' /* Gerar Etiquetas Seriadas */),
        I18n.t('BEE989' /* Informe a quantidade de etiquetas */),
        'top-right',
      );
      return;
    }

    if (controlExpirationDate && !expirationDate) {
      addNotification(
        'danger',
        I18n.t('BEE2784' /* Gerar Etiquetas Seriadas */),
        I18n.t('BEE2006' /* Data de validade não informada! */),
        'top-right',
      );
      return;
    }

    if (!printerOptions || printerOptions.length === 0) {
      addNotification(
        'danger',
        I18n.t('BEE44' /* Consolidação */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
    } else if (printerOptions && printerOptions.length > 1) {
      this.setState({
        showPrinterDialog: true,
      });
    } else if (printerOptions && printerOptions.length === 1) {
      await this.setState({
        printerSelected: printerOptions[0].value,
      });
      this.createSerialLabels();
    }
  };

  createDateInput = (value, attr, label, disabled, id) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <DatePicker
          id={id}
          isClearable
          selected={value && (typeof value === 'string' ? new Date(value) : value)}
          className="form-control"
          dateFormat="dd/MM/yyyy"
          value={value || new Date('')}
          onChange={(e) => this.setValue(attr, e)}
          disabled={disabled}
          customInput={(
            <InputMask
              className="form-control"
              mask="99/99/9999"
            />
          )}
        />
      </div>
    </div>
  );

  createRadioButtons = (radios = [], value, attr, label) => (
    <div className="d-flex flex-column justify-content-center align-items-center form-group" key={attr}>
      {!!label && (
        <span>
          {label}
        </span>
      )}
      <div>
        {radios.map((radioItem = {}) => (
          <div
            key={`${attr}${radioItem.value}`}
            className="radio radio-css radio-inline"
          >
            <input
              type="radio"
              id={`${attr}${radioItem.value}`}
              value={radioItem.value}
              checked={`${radioItem.value}` === `${value}`}
              disabled={radioItem.disabled}
              className="form-control"
              onChange={(e) => this.setValue(attr, e.target.value)}
            />
            <label htmlFor={`${attr}${radioItem.value}`}>{radioItem.label}</label>
          </div>
        ))}
      </div>
    </div>
  );

  createSerialLabels = async () => {
    const {
      productionOrderId,
      quantityToPrint,
      orderCode,
      printerSelected,
      expirationDate,
      controlExpirationDate,
      quantityTotalLabel,
      typeLabel,
    } = this.state;

    this.setState({
      showPrinterDialog: false,
    });

    if (controlExpirationDate && !expirationDate) {
      addNotification(
        'danger',
        I18n.t('BEE2784' /* Gerar Etiquetas Seriadas */),
        I18n.t('BEE2006' /* Data de validade não informada! */),
        'top-right',
      );
      return;
    }

    if (quantityToPrint > 0) {
      try {
        await this.props
          .createSerialLabels(productionOrderId, quantityToPrint, printerSelected, expirationDate, typeLabel);
        const {
          productionOrder,
          serialLabels,
        } = await this.props.getProductionOrderSerialLabels(
          orderCode,
          this.page,
          this.pageSize,
          this.sorted,
          this.filtered,
        );

        if (productionOrder) {
          const notSerialLabel = serialLabels.count >= quantityTotalLabel
            ? 0 : quantityTotalLabel - serialLabels.count;

          const pages = Math.ceil(serialLabels.count / this.pageSize);
          this.setState({
            quantity: Number(productionOrder.quantity),
            quantitySerialLabels: serialLabels.count,
            quantityNoSerialLabels: notSerialLabel,
            serialLabels: serialLabels.rows,
            quantityToPrint: 0,
            pages,
          });

          addNotification(
            'success',
            I18n.t('BEE2784' /* Gerar Etiquetas Seriadas */),
            I18n.t('BEE2790' /* Etiquetas criadas e enviadas para a impressora! */),
            'top-right',
          );
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;
          if (error.details || error.message) {
            addNotification(
              'danger',
              I18n.t('BEE2784' /* Gerar Etiquetas Seriadas */),
              `${error.details || error.message}`,
              'top-right',
            );
          } else {
            addNotification(
              'danger',
              I18n.t('BEE1638' /* Ocorreu uma falha de comunicação com o servidor. */),
              'top-right',
            );
          }
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1638' /* Ocorreu uma falha de comunicação com o servidor. */),
            'top-right',
          );
        }
      }
    } else {
      addNotification(
        'danger',
        I18n.t('BEE2784' /* Gerar Etiquetas Seriadas */),
        I18n.t('BEE989' /* Informe a quantidade de etiquetas */),
        'top-right',
      );
    }
  };

  createSelect = (value, attr, label, items) => (
    <div className="form-group p-2">
      <label htmlFor={label}>{label}</label>
      <select
        className="form-control"
        value={value || ''}
        onChange={(e) => this.setValue(attr, e.target.value)}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );

  handleClearVariables = () => {
    this.setState({
      firstLoading: true,
      totalDataLength: 0,
      pages: 1,
      orderCode: '',
      branchCode: '',
      depositCode: '',
      lotNumber: '',
      expectedDate: '',
      expirationDate: '',
      product: '',
      quantity: 0,
      quantityToPrint: 0,
      serialLabels: [],
      quantityTotalLabel: 0,
      quantitySerialLabels: 0,
      quantityNoSerialLabels: 0,
      productionOrderId: undefined,
      defaultPageSize: 10,
      showPrinterDialog: false,
      printerSelected: '',
      controlExpirationDate: false,
    });
  };

  keypressConfirmSearch = (e) => {
    if (e.keyCode === 13) {
      this.getProductionOrderSerialLabels();
    }
  };

  keypressConfirmQuantity = (e) => {
    if (e.keyCode === 13) {
      const { quantityToPrint, controlExpirationDate, expirationDate } = this.state;

      if (quantityToPrint > 0) {
        if (controlExpirationDate && !expirationDate) {
          document.getElementById('inputExpirationDate').focus();
        }
      }
    }
  };

  render() {
    const {
      pages,
      orderCode,
      branchCode,
      depositCode,
      lotNumber,
      expectedDate,
      controlExpirationDate,
      product,
      quantity,
      expirationDate,
      quantityToPrint,
      quantitySerialLabels,
      quantityTotalLabel,
      quantityNoSerialLabels,
      defaultPageSize,
      serialLabels,
      totalDataLength,
      showPrinterDialog,
      printerSelected,
      printerOptions,
      typeLabel,
    } = this.state;

    return (
      <>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE2771' /* Indústria */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE2784' /* Gerar Etiquetas Seriadas */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center">
          <h1 className="page-header mb-0">
            {I18n.t('BEE2784' /* Gerar Etiquetas Seriadas */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.INDUSTRY_GENERATE_LABEL_SERIAL_HELP} />
        </div>
        {quantity === 0
          ? (
            <div onSubmit={(e) => e.preventDefault()}>
              <PanelPage
                noButton
                content={(
                  <div className="col-md-4">
                    <Form
                      noPanel
                      leftType
                      setValue={this.setValue}
                      inputs={(formContext) => ([
                        formContext.createInputButton(
                          orderCode,
                          'orderCode',
                          I18n.t('BEE2770' /* Ordem de Produção */),
                          '',
                          'text',
                          true,
                          this.props.userLogged ? !this.props.userLogged.mainBranchData.serialControlDeposit : false,
                          <i className="fa fa-search" />,
                          this.getProductionOrderSerialLabels,
                          this.keypressConfirmSearch,
                          1,
                        ),
                      ])}
                    />
                  </div>
                )}
                footerContent={(
                  <button
                    type="button"
                    className="btn btn-120 btn-primary p-5 m-5"
                    onClick={this.getProductionOrderSerialLabels}
                  >
                    {I18n.t('BEE407' /* Buscar */)}
                  </button>
                )}
              />
            </div>
          )
          : (
            <PanelPage
              noButton
              content={(
                <>
                  <div className="row">
                    <div className="col-md-3">
                      {this.createInput(
                        orderCode,
                        'orderCode',
                        I18n.t('BEE2770' /* Ordem de Produção */),
                        '',
                        'text',
                        false,
                        true,
                        undefined,
                        null,
                      )}
                    </div>
                    <div className="col-md-2">
                      {this.createInput(
                        branchCode,
                        'branchCode',
                        I18n.t('BEE1994' /* Código Filial */),
                        '',
                        'text',
                        false,
                        true,
                        undefined,
                        null,
                      )}
                    </div>
                    <div className="col-md-1">
                      {this.createInput(
                        depositCode,
                        'depositCode',
                        I18n.t('BEE182' /* Depósito */),
                        undefined,
                        'text',
                        false,
                        true,
                        undefined,
                        undefined,
                        undefined,
                        3,
                      )}
                    </div>
                    <div className="col-md-3">
                      {this.createInput(
                        lotNumber,
                        'lotNumber',
                        I18n.t('BEE428' /* Lote */),
                        undefined,
                        'text',
                        false,
                        true,
                        undefined,
                        undefined,
                        undefined,
                        4,
                      )}
                    </div>
                    <div className="col-md-3">
                      {this.createInput(
                        expectedDate,
                        'expectedDate',
                        I18n.t('BEE2819' /* Data Prevista */),
                        undefined,
                        'text',
                        false,
                        true,
                        undefined,
                        undefined,
                        undefined,
                        4,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-left">
                      {this.createTextArea(
                        product,
                        'product',
                        I18n.t('BEE225' /* Produto */),
                        undefined,
                        2,
                        false,
                        true,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      {this.createNumberFormat(
                        quantity,
                        'quantity',
                        I18n.t('BEE2785' /* Quantidade em UN */),
                        0,
                        true,
                      )}
                    </div>
                    <div className="col-md-3">
                      {this.createNumberFormat(
                        quantityTotalLabel,
                        'quantityTotalLabel',
                        I18n.t('BEE2788' /* Total de Etiquetas */),
                        0,
                        true,
                      )}
                    </div>
                    <div className="col-md-3">
                      {this.createNumberFormat(
                        quantitySerialLabels || '0',
                        'quantitySerialLabels',
                        I18n.t('BEE2786' /* Qtd Etiquetas Impressas */),
                        0,
                        true,
                      )}
                    </div>
                    <div className="col-md-3">
                      {this.createNumberFormat(
                        quantityNoSerialLabels || '0',
                        'quantityNoSerialLabels',
                        I18n.t('BEE2787' /* Qtd Etiquetas não Impressas */),
                        0,
                        true,
                      )}
                    </div>
                  </div>
                </>
              )}
              footerContent={(
                <div className="d-flex row justify-content-between align-items-center p-10">
                  <div className="col-md-3">
                    {this.createNumberFormat(
                      quantityToPrint,
                      'quantityToPrint',
                      I18n.t('BEE2789' /* Quantas etiquetas deseja imprimir? */),
                      0,
                      false,
                      this.keypressConfirmQuantity,
                      undefined,
                      undefined,
                      true,
                    )}
                  </div>
                  {
                    controlExpirationDate && (
                      <div>
                        {this.createDateInput(
                          expirationDate,
                          'expirationDate',
                          I18n.t('BEE2398' /* Data de Validade */),
                          !controlExpirationDate,
                          'inputExpirationDate',
                        )}
                      </div>
                    )
                  }
                  {
                    this.createRadioButtons(
                      [
                        {
                          label: I18n.t('BEE2959' /* Horizontal */),
                          value: 'Horizontal',
                          disabled: false,
                        },
                        {
                          label: I18n.t('BEE2960' /* Vertical */),
                          value: 'Vertical',
                          disabled: false,
                        },
                      ],
                      typeLabel,
                      'typeLabel',
                      `${I18n.t('BEE2961' /* Formato da Etiqueta */)}:`,
                    )
                  }
                  <div>
                    <button
                      type="button"
                      className="btn btn-120 btn-gray p-5 m-5"
                      onClick={this.handleClearVariables}
                    >
                      {I18n.t('BEE137' /* Voltar */)}
                    </button>
                    <button
                      type="button"
                      className="btn btn-blue p-5 m-5"
                      onClick={this.showPrinterDialog}
                    >
                      {I18n.t('BEE2783' /* Gerar etiquetas e imprimir */)}
                    </button>
                  </div>
                </div>
              )}
            />
          )}
        {(showPrinterDialog
          && (
            <SweetAlert
              confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1324' /* Selecionar Impressora */)}
              onConfirm={() => this.createSerialLabels()}
              onCancel={() => this.setState({ showPrinterDialog: false })}
            >
              <div className="mt-4">
                {
                  this.createSelect(
                    printerSelected,
                    'printerSelected',
                    I18n.t('BEE328' /* Impressora */),
                    printerOptions,
                  )
                }
              </div>
            </SweetAlert>
          )
        )}
        {
          (serialLabels.length > 0 || this.filtered.length > 0)
          && (
            <Table
              expander
              filterable
              downloadCSV
              manual
              downButtonPosition
              data={serialLabels}
              columns={this.tableColumns}
              defaultSorted={this.defaultSorted}
              defaultPageSize={defaultPageSize}
              panelHeaderProps={{
                onClickReload: this.getProductionOrderSerialLabels,
              }}
              totalDataLength={totalDataLength}
              pages={pages}
              breadcrumb={this.breadcrumb}
              headerTitle={I18n.t('BEE2760' /* Etiquetas Seriadas */)}
              onFetchData={(state) => {
                this.page = state.page;
                this.pageSize = state.pageSize;
                this.sorted = state.sorted;
                this.filtered = state.filtered;
                this.getProductionOrderSerialLabels();
              }}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          )
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  getProductionOrderSerialLabels: (orderCode, page, pageSize, sorted, filtered) => dispatch(
    getProductionOrderSerialLabels(orderCode, page, pageSize, sorted, filtered),
  ),
  createSerialLabels: (productionOrderId, quantityToPrint, printerSelected, expirationDate, typeLabel) => dispatch(
    createSerialLabels(productionOrderId, quantityToPrint, printerSelected, expirationDate, typeLabel),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GenerateSerialLabel));
