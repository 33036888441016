import AddressTransferWaveRequests from '../../server/addressTransferWave';
import { addLoading, removeLoading } from './loading';

export const setAddressTransferWave = (addressTransferWave) => ({
  type: 'SET_TRANSFER_ORDER_OUT',
  addressTransferWave,
});

export const getAddressTransferWaveList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const addressTransferWave = await AddressTransferWaveRequests.getAddressTransferWaveList();
    dispatch(setAddressTransferWave(addressTransferWave));
  } finally {
    dispatch(removeLoading());
  }
};

export const getAddressTransferWaves = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AddressTransferWaveRequests.getAddressTransferWaves();
  } finally {
    dispatch(removeLoading());
  }
};

export const getAddressTransferWaveByBaptism = (
  branch,
  baptism,
  validateStatusStarted = false,
  isDetail = false,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AddressTransferWaveRequests.getAddressTransferWavesByBaptism(
      branch,
      baptism,
      validateStatusStarted,
      isDetail,
    );
  } finally {
    dispatch(removeLoading());
  }
};

export const createAddressTransferWave = (addressTransferWave) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newAddressTransferWave = await AddressTransferWaveRequests.createAddressTransferWave(addressTransferWave);
    return newAddressTransferWave;
  } finally {
    dispatch(removeLoading());
  }
};

export const createAddressTransferWaveProduct = (addressTransferWaveProduct) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newAddressTransferWaveProduct = await AddressTransferWaveRequests
      .createAddressTransferWaveProduct(addressTransferWaveProduct);
    return newAddressTransferWaveProduct;
  } finally {
    dispatch(removeLoading());
  }
};

export const cancelAddressTransferWave = (addressTransferWave) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AddressTransferWaveRequests.cancelAddressTransferWave(addressTransferWave);
  } finally {
    dispatch(removeLoading());
  }
};

export const transferAddressTransferWaveProduct = (addressTransferWaveProduct) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AddressTransferWaveRequests.transferAddressTransferWaveProduct(addressTransferWaveProduct);
  } finally {
    dispatch(removeLoading());
  }
};

export const getAddressTransferWave = (addressTransferWaveId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AddressTransferWaveRequests.getAddressTransferWave(addressTransferWaveId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteAddressTransferWave = (addressTransferWaveId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AddressTransferWaveRequests.deleteAddressTransferWave(addressTransferWaveId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteAddressTransferWaveProduct = (addressTransferWaveProductId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AddressTransferWaveRequests.deleteAddressTransferWaveProduct(addressTransferWaveProductId);
  } finally {
    dispatch(removeLoading());
  }
};
