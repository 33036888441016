import UsersFiltersServer from '../../server/usersFilters';
import { addLoading, removeLoading } from './loading';


export const getReportUserFilters = (filterData) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const userFilters = await UsersFiltersServer.getReportUserFilters(filterData);
    return userFilters;
  } finally {
    dispatch(removeLoading());
  }
};

export const saveOrCreateFilter = (values, getFilters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const saved = await UsersFiltersServer.saveOrCreateFilter(values);
    if (saved) await getFilters();
    dispatch(removeLoading());
    return saved;
  } catch(err) {
    dispatch(removeLoading());
    throw err;
  }
};

export const removeFilter = (values, getFilters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const removed = await UsersFiltersServer.removeFilter(values);
    if (removed) await getFilters();
    dispatch(removeLoading());
    return removed;
  } catch(err) {
    dispatch(removeLoading());
    throw err;
  }
};
