/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { allocateProducts, getDataGrid } from '../../../app/store/actions/outboundPendingAllocationMonitor';
import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import FormatHelpers from '../../../helpers/format';
import 'react-datepicker/dist/react-datepicker.css';

export class List extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      orderList: [],
      productList: [],
      selectedAllocations: {},
      selectedIds: [],
      startDate: moment().subtract(30, 'days'),
      endDate: moment(),
      showModalPendingAllocation: false,
      date: '',
    };

    this.defaultSorted = [
      {
        id: 'orderNumber',
        desc: false,
      },
    ];

    this.defaultSortedProducts = [
      {
        id: 'lineNumber',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE436' /* Selecionar */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            {(row.original.status !== 200) && (
              <input
                type="checkbox"
                checked={this.state.selectedAllocations[row.original.id] || false}
                onChange={() => this.singleSelection(row.original.id)}
              />
            )}
          </div>
        ),
      }, {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <button
              type="button"
              className="btn btn-default btn-xs"
            >
              {I18n.t('BEE55' /* Ações */)}
            </button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.allocateOrder(row.original.id)}>
                  {I18n.t('BEE3198' /* Alocar Produtos */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1378' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE3351' /* Alocação Prevista (%) */),
        accessor: 'percentageAllocation',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1096' /* Pedido cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE51' /* Pedido */),
        accessor: 'erpOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: 'Data Documento',
        accessor: 'orderDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE444' /* Data de Entrega */),
        accessor: 'deliveryDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
        Filter: this.filterDeliveryDate,
        filterMethod: (filter, row) => {
          const input = filter.value !== null ? _.lowerCase(moment(filter.value).format('L')) : '';
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE1057' /* Entrega */),
        accessor: 'deliveryType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      },
    ];

    this.productTableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <button
              type="button"
              className="btn btn-default btn-xs"
            >
              {I18n.t('BEE55' /* Ações */)}
            </button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                {row.original.status === 1
                  && <DropdownItem onClick={() => this.allocateProduct(row.original.id)}>Alocar Produto</DropdownItem>}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE463' /* Linha */),
        accessor: 'lineNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1897' /* Descrição */),
        accessor: 'product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE384' /* Unidade */),
        accessor: 'product.unitMeasure',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE1431' /* Alocado */),
        accessor: 'allocatedQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      },
      {
        Header: I18n.t('BEE530' /* Disponível */),
        accessor: 'available',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getDataGrid();
  }

  handleDateChange = (date) => {
    this.setState({ date });
  };

  filterDeliveryDate = ({ onChange }) => (
    <DatePicker
      selected={this.state.date}
      onChange={(date) => {
        this.handleDateChange(date);
        onChange(date);
      }}
      dateFormat="dd/MM/yyyy"
      withPortal
      isClearable
    />
  );

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getDataGrid = async () => {
    const startDate = moment(this.state.startDate).format();
    const endDate = moment(this.state.endDate).format();

    const tableParams = {
      startDate,
      endDate,
      status: 1,
    };

    const list = await this.props.getDataGrid(tableParams);
    const orderList = list ? list.rows : [];

    this.setState({
      orderList,
      productList: [],
    });
  };

  setOrderSelected = (order) => {
    this.setState({
      orderSelect: order,
      productList: order.products,
    });
  };

  allocateProduct = async (outboundProductId) => {
    try {
      const result = await this.props.allocateProducts(null, outboundProductId);

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1857' /* Monitor de Pendências de Alocação */),
          'Não foi possível alocar o Produto selecionado',
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE1857' /* Monitor de Pendências de Alocação */),
          'Produto alocado com sucesso!',
          'top-right',
        );

        this.getDataGrid();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification(
          'danger',
          I18n.t('BEE1857' /* Monitor de Pendências de Alocação */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1857' /* Monitor de Pendências de Alocação */),
          'Erro ao tentar alocar o Produto selecionado',
          'top-right',
        );
      }
    }
  };

  allocateOrder = async (outboundOrderId) => {
    try {
      this.setState({
        showModalPendingAllocation: false,
      });

      const result = await this.props.allocateProducts(outboundOrderId, null);

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1857' /* Monitor de Pendências de Alocação */),
          'Não foi possível alocar todo o Documento selecionado',
          'top-right',
        );
      } else {
        this.setState({
          selectedAllocations: {},
          selectedIds: [],
        });

        addNotification(
          result.amountFail > 0 ? 'warning' : 'success',
          I18n.t('BEE1857' /* Monitor de Pendências de Alocação */),
          I18n.t(
            'BEE2934',
            { 0: result.amountTotal, 1: result.amountSuccess, 2: result.amountFail },
            /* De %{0} documentos selecionados, %{1} alocados e %{2} não alocados. */),
          'top-right',
        );

        this.getDataGrid();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification(
          'danger',
          I18n.t('BEE1857' /* Monitor de Pendências de Alocação */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1857' /* Monitor de Pendências de Alocação */),
          'Erro ao tentar alocar o Documento selecionado',
          'top-right',
        );
      }
    }
  };

  singleSelection = (idAllocation) => {
    this.setState((prevState) => {
      const { selectedAllocations } = prevState;
      return {
        selectedAllocations: {
          ...selectedAllocations,
          [idAllocation]: !selectedAllocations[idAllocation],
        },
      };
    });
  };

  multipleSelection = (selectionOption) => {
    const { selectedAllocations } = this.state;
    const data = this.reactTable.getResolvedState().sortedData;
    const updatedSelectedAllocations = {};

    for (const element of data) {
      updatedSelectedAllocations[element._original.id] = selectionOption;
    }

    this.setState({ selectedAllocations: { ...selectedAllocations, ...updatedSelectedAllocations } });
  };

  validChecked = () => {
    const { selectedAllocations } = this.state;
    if (!Object.keys(selectedAllocations).length) {
      addNotification(
        'danger',
        I18n.t('BEE2223' /* Monitor de Integração */),
        I18n.t('BEE2236' /* Nenhuma integração selecionada */),
        'top-right',
      );
    } else {
      const selectedIds = [];
      for (const index in selectedAllocations) {
        if (selectedAllocations[index]) selectedIds.push(index);
      }
      if (selectedIds.length) {
        this.setState({
          selectedIds: [...selectedIds],
          showModalPendingAllocation: true,
        });
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2223' /* Monitor de Integração */),
          I18n.t('BEE2236' /* Nenhuma integração selecionada */),
          'top-right',
        );
      }
    }
  };

  handleDateApplyEvent = async (event) => {
    const startDate = moment(event[0]);
    const endDate = moment(event[1]);

    await this.setState({
      startDate,
      endDate,
    });
    this.getDataGrid();
  };

  render() {
    const {
      orderList, productList, orderSelect, selectedIds,
      showModalPendingAllocation,
    } = this.state;

    return (
      <div>
        <Table
          panelHeaderProps={{
            onClickReload: this.getDataGrid,
            children: I18n.t('BEE462' /* Documentos */).toUpperCase(),
          }}
          filterable
          data={orderList}
          columns={this.tableColumns}
          expander
          defaultPageSize={5}
          defaultSorted={this.defaultSorted}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          actionButtons={(
            <div className="ml-auto -my-5">
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(true)}
              >
                {I18n.t('BEE1908' /* Marcar Todos */)}
              </button>
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(false)}
              >
                {I18n.t('BEE1909' /* Desmarcar Todos */)}
              </button>
              <OneClickButton
                type="button"
                className="btn btn-140 btn-primary p-5 m-5"
                onClick={() => this.validChecked()}
              >
                {I18n.t('BEE2956' /* Alocação automática */)}
              </OneClickButton>
            </div>
          )}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: () => {
                  this.setOrderSelected(rowInfo.original);
                },
                style: {
                  background: orderSelect && rowInfo.original.id === orderSelect.id ? 'silver' : 'white',
                  cursor: 'pointer',
                },
              };
            }
            return {};
          }}
          ref={(r) => {
            this.reactTable = r && r.reactTable;
          }}
        />

        <Table
          panelHeaderProps={{
            children: I18n.t('BEE27' /* Produtos */).toUpperCase(),
          }}
          filterable
          data={productList}
          columns={this.productTableColumns}
          expander
          defaultPageSize={5}
          defaultSorted={this.defaultSortedProducts}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                style: {
                  color: parseFloat(rowInfo.original.allocatedQuantity) === 0 ? 'red' : 'black',
                },
              };
            }
            return {};
          }}
        />
        {(showModalPendingAllocation
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1849' /* Continuar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.allocateOrder(selectedIds)}
              onCancel={() => this.setState({ showModalPendingAllocation: false })}
            >
              {I18n.t('BEE2957', { 0: selectedIds.length } /* Foram selecionado(s) %{0} documento(s) */)}
            </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDataGrid: (tableParams) => dispatch(getDataGrid(tableParams)),
  allocateProducts: (
    outboundOrderId,
    outboundProductId,
  ) => dispatch(allocateProducts(outboundOrderId, outboundProductId)),
});

export default connect(undefined, mapDispatchToProps)(withRouter(List));
