import RestService from '../services/rest';

// GET
async function getCarrierSchedules() {
  return RestService.getAuthenticated('carrierSchedules/list/all');
}

async function getCarrierSchedulesQuery(query) {
  return RestService.postAuthenticated('carrierSchedules/list/query', { query });
}

async function getCarrierSchedule(carrierScheduleId) {
  return RestService.getAuthenticated(`carrierSchedules/detail?carrierScheduleId=${carrierScheduleId}`);
}

// POST
async function createCarrierSchedule(carrierSchedule) {
  return RestService.postAuthenticated('carrierSchedules/create', carrierSchedule);
}

async function deleteCarrierSchedule(carrierScheduleId) {
  return RestService.postAuthenticated('carrierSchedules/delete', { carrierScheduleId });
}

// PUT
async function updateCarrierSchedule(carrierSchedule) {
  return RestService.putAuthenticated('carrierSchedules/update', carrierSchedule);
}

export default {
  getCarrierSchedules,
  getCarrierSchedulesQuery,
  createCarrierSchedule,
  updateCarrierSchedule,
  getCarrierSchedule,
  deleteCarrierSchedule,
};
