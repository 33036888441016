import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import OneClickButton from '../form/button';
import Form from '../form/form';
import { getReportUserFilters, saveOrCreateFilter, removeFilter } from '../../app/store/actions/usersFilters';
import addNotification from '../notification';
import Select from '../form/select';

class FilterDefault extends React.PureComponent {
  constructor() {
    super();

    this.form = new Form();

    this.colourStyles = this.form.colourStyles;
    this.groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };
    this.groupBadgeStyles = {
      backgroundColor: '#EBECF0',
      borderRadius: '2em',
      color: '#172B4D',
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '1',
      minWidth: 1,
      padding: '0.16666666666667em 0.5em',
      textAlign: 'center',
    };

    this.optionsPermission = [{
      label: I18n.t('BEE551' /* Público */),
      value: 'public',
    }, {
      label: I18n.t('BEE552' /* Privado */),
      value: 'private'
    }]; 

    this.state = {
      items: [],
      userFilters: [],
      value: '',
      filterInModal: '',
      filterName: '',
      permission: 'private',
      showModalFilter: false,
      modalType: 'save',
    }
  }

  componentDidMount = async () => {
    await this.getFilters();
  }

  getFilters = async () => {
    const { route, getFilterDefaultValues } = this.props;

    const values = await getFilterDefaultValues(route);

    if (values) {
      const { filterPrivate = [], filterPublic = [], userFilters = [] } = values;
      this.setState({
        items: [{
          label: I18n.t('BEE552' /* Privado */),
          options: filterPrivate.map(it => ({ label: it.filterName, value: it })),
        }, {
          label: I18n.t('BEE551' /* Público */),
          options: filterPublic.map(it => ({ label: it.filterName, value: it })),
        }],
        userFilters: [{
          label: I18n.t('BEE552' /* Privado */),
          options: userFilters.filter(itb => itb.permission === 'private').map(it => ({ label: it.filterName, value: it })),
        }, {
          label: I18n.t('BEE551' /* Público */),
          options: userFilters.filter(itb => itb.permission === 'public').map(it => ({ label: it.filterName, value: it })),
        }],
      });
    }
  }

  onClickSave = () => {
    this.setState({ showModalFilter: true, modalType: 'save' });
  }

  onClickRemove = () => {
    this.setState({ showModalFilter: true, modalType: 'remove' });
  }

  onSave = async () => {
    const {
      stateToSave = {},
      saveFilterDefault,
      route,
    } = this.props;

    const { filterName, permission } = this.state;

    try {
      const saved = await saveFilterDefault({
        filterName,
        permission,
        route,
        filter: stateToSave
      }, this.getFilters);

      if (saved) {
        this.closeModalFilter();
        await addNotification('success', I18n.t('BEE550' /* Filtro padrão */), I18n.t('BEE556' /* Filtro salvo com sucesso! */), 'top-right');
      } else {
        await addNotification('danger', I18n.t('BEE550' /* Filtro padrão */), I18n.t('BEE557' /* Falha ao salvar o filtro! */), 'top-right');
      }
    } catch (e) {
      const isError = e.response && e.response.data && e.response.data.error;
      if (isError && e.response.data.error.code === 902) {
        await addNotification('danger', I18n.t('BEE550' /* Filtro padrão */), e.response.data.error.message, 'top-right');
      } else {
        await addNotification('danger', I18n.t('BEE550' /* Filtro padrão */), I18n.t('BEE557' /* Falha ao salvar o filtro! */), 'top-right');
      }
    }
  }

  onRemove = async () => {
    const {
      removeFilterDefault,
      route,
    } = this.props;

    const { filterInModal } = this.state;

    try {
      const removed = await removeFilterDefault({
        filterName: filterInModal.value.filterNameValue,
        route,
      }, this.getFilters);

      if (removed) {
        this.closeModalFilter();
        await addNotification('success', I18n.t('BEE550' /* Filtro padrão */), I18n.t('BEE559' /* Filtro removido com sucesso! */), 'top-right');
      } else {
        await addNotification('danger', I18n.t('BEE550' /* Filtro padrão */), I18n.t('BEE560' /* Falha ao remover filtro! */), 'top-right');
      }
    } catch (e) {
      await addNotification('danger', I18n.t('BEE550' /* Filtro padrão */), I18n.t('BEE560' /* Falha ao remover filtro! */), 'top-right');
    }
  }

  formatGroupLabel = data => (
    <div style={this.groupStyles}>
      <span>{data.label}</span>
      <span style={this.groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  setValue = (attr, value, label) => {
    this.setState({ 
      [`${attr}`]: value,
    });
  }

  setValueDrop = (attr, dataValue) => {
    this.setState({ 
      [`${attr}`]: dataValue ? { label: dataValue.label, value: dataValue.value } : '',
      ...attr === 'filterInModal' ? { 
        filterName: dataValue ? dataValue.value.filterNameValue : '',
        permission: dataValue ? dataValue.value.permission : this.state.permission
      } : {}
    });

    if (attr === 'value' && dataValue && this.props.onSelectFilter) {
      this.props.onSelectFilter(dataValue.value.filter);
    }
  }

  closeModalFilter = () => this.setState({
    showModalFilter: false,
    filterInModal: '',
    filterName: '',
    permission: 'private',
  })

  renderModalFilter = (showModalFilter, modalType) => (
    <Modal isOpen={showModalFilter} toggle={this.closeModalFilter} size="lg">
      <ModalHeader toggle={this.closeModalFilter}>
        {`${I18n.t('BEE550' /* Filtro padrão */)} - ${modalType === 'remove' ? I18n.t('BEE558' /* Remover */) : I18n.t('BEE493' /* Salvar */)}`}
      </ModalHeader>
      <ModalBody>
        <Form
          noPanel
          setValue={this.setValue}
          inputs={(formContext) => (modalType === 'remove' ? [
            (
              <div key={'filterInModal'} className="form-group row m-b-15" style={{ height: 40 }}>
              <label className="col-form-label col-md-4">
                <span>
                  {I18n.t('BEE550' /* Filtro padrão */)}
                </span>
              </label>
              <div className="col-md-5">
                <Select
                  value={this.state.filterInModal || ''}
                  onChange={dataValue => this.setValueDrop('filterInModal', dataValue)}
                  options={this.state.userFilters}
                  isClearable
                  styles={this.colourStyles}
                  placeholder={I18n.t('BEE144' /* Selecione... */)}
                  formatGroupLabel={this.formatGroupLabel}
                  disabled={this.props.disabled || false}
                />
              </div>
            </div>
            )
          ] : [
            (
            <div key={'filterInModal'} className="form-group row m-b-15" style={{ height: 40 }}>
              <label className="col-form-label col-md-4">
                <span>
                  {I18n.t('BEE550' /* Filtro padrão */)}
                </span>
              </label>
              <div className="col-md-5">
                <Select
                  value={this.state.filterInModal || ''}
                  onChange={dataValue => this.setValueDrop('filterInModal', dataValue)}
                  options={this.state.userFilters}
                  isClearable
                  styles={this.colourStyles}
                  placeholder={I18n.t('BEE144' /* Selecione... */)}
                  formatGroupLabel={this.formatGroupLabel}
                  disabled={this.props.disabled || false}
                />
              </div>
            </div>
            ),
            formContext.createInput(
              this.state.filterName, 'filterName', `${I18n.t('BEE554' /* Nome do Filtro */)}:`, '', 'text',
            ),
            formContext.createSelect(
              this.state.permission, 'permission', `${I18n.t('BEE200' /* Tipo */)}:`, this.optionsPermission,
            ),
          ])}
        />
      </ModalBody>
      <ModalFooter>
        <OneClickButton className="btn btn-white" onClick={this.closeModalFilter}>{I18n.t('BEE553' /* Fechar */)}</OneClickButton>
        <OneClickButton
          className={`btn ${modalType === 'remove' ? 'btn-danger' : 'btn-success'}`}
          onClick={modalType === 'remove' ? this.onRemove : this.onSave}
        >
          {modalType === 'remove' ? I18n.t('BEE558' /* Remover */) : I18n.t('BEE493' /* Salvar */) }
        </OneClickButton>
      </ModalFooter>
    </Modal>
  )

  render() {
    const { value, items, showModalFilter, modalType } = this.state;
    const { disabled = false } = this.props;

    return (
      <div>
        <div className="col-xs-12 mb-md-3 mb-5 mt-1 no-padding-horizontal">
          <div className="col-xs-12 col-md-4 text-align-left no-padding-horizontal text-align-left d-flex flex-row align-items-center">
            <h5>
              {I18n.t('BEE550' /* Filtro padrão */)}
            </h5>
            <div className="d-flex flex-row" style={{ paddingBottom: 5 }}>
              <div style={{ fontSize: '1.5rem' }} onClick={this.onClickSave}>
                <i className="far fa-save filter-icons" />
              </div>
              <div style={{ fontSize: '1.44rem' }} onClick={this.onClickRemove}>
                <i className="far fa-trash-alt filter-icons" />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-5 no-padding-horizontal">
            <Select
              value={value || ''}
              onChange={dataValue => this.setValueDrop('value', dataValue)}
              options={items}
              isClearable
              styles={this.colourStyles}
              placeholder={I18n.t('BEE144' /* Selecione... */)}
              formatGroupLabel={this.formatGroupLabel}
              disabled={disabled}
            />
          </div>
        </div>
        {this.renderModalFilter(showModalFilter, modalType)}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getFilterDefaultValues: (route) => dispatch(getReportUserFilters({ route })),
  saveFilterDefault: (values, getFilters) => dispatch(saveOrCreateFilter(values, getFilters)),
  removeFilterDefault: (values, getFilters) => dispatch(removeFilter(values, getFilters)),
});

export default connect(undefined, mapDispatchToProps)(FilterDefault);