import 'react-table/react-table.css';
import React from 'react';
import Table from '../../../../components/table/Table';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import addNotification from '../../../../components/notification';
import SweetAlert from 'react-bootstrap-sweetalert';

import { deleteLinkPrinter } from '../../../../app/store/actions/users';

class UserPrintersTable extends React.Component { 
  constructor(props) {
    super(props)

    this.state = {
      showModalDelete: false,
      selectedUserPrinter: null,
    }

    this.tableColumns = [
      {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'printer.code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'printer.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
      }, {
        Header: I18n.t('BEE322' /* Localização */),
        accessor: 'printer.location',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
    ];

    this.actionColumn = {
      Header: I18n.t('BEE55' /* Ações */),
      accessor: 'action',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      width: 100,
      Cell: rows => (
        <div style={{ textAlign: 'center' }}>
          <button
            type="button" 
            className="btn btn-default btn-xs"
            onClick={() => this.deletePrinterLink(rows.row._original)}
          >{I18n.t('BEE608' /* Desvincular */)}</button>
        </div>
      )
    }
  }

  openPrinterLinkModal = () => {
    this.props.openPrinterLinkModal();
  }

  deletePrinterLink = (userPrinter) => {
    this.setState({
      showModalDelete: true,
      selectedUserPrinter: userPrinter
    });
  }

  confirmDeletePrinterLink = async () => {
    const { login, name } = this.props.userSelected;
    const { printer, id } = this.state.selectedUserPrinter;

    try {
      const resultado = await this.props.deleteLinkPrinter(id)

      if (resultado === 1) {
          this.closeDeleteModal()
          addNotification(
            'success',
            I18n.t('BEE1' /* Usuário */),
            I18n.t('BEE961', {0:printer.code,1:printer.name,2:login,3:name} /* %{0} - %{1} desvinculado do usuário %{2} - %{3} com sucesso ! */),
            'top-right'
          )
          await this.props.getUserSelected()
        } else {
          throw new Error(I18n.t('BEE964' /* Erro ao efetuar a desvinculação */))
        }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1' /* Usuário */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          addNotification(
            'danger', I18n.t('BEE1' /* Usuário */), I18n.t('BEE965', {0:printer.code} /* Erro ao desvincular a impressora %{0} ! */), 'top-right'
          );
        }
      } else {
        addNotification(
          'danger', I18n.t('BEE1' /* Usuário */), I18n.t('BEE965', {0:printer.code} /* Erro ao desvincular a impressora %{0} ! */), 'top-right'
        );
      }
    }
  }

  closeDeleteModal = () => {
    this.setState({
      showModalDelete: false,
      selectedUserPrinter: null
    })
  }

  reloadTable = async () => {
    await this.props.getUserSelected();
  }

  render() {
    const {
      userPrinters, title, showPrinterLinkBtn, openPrinterLinkModal, userSelected
    } = this.props;

    const { showModalDelete, selectedUserPrinter } = this.state;

    const columns = showPrinterLinkBtn
      ? [ this.actionColumn, ...this.tableColumns]
      : this.tableColumns
      
    return (
      <div className="table-responsive">
        <Table
          headerTitle={title}
          data={userPrinters}
          columns={columns}
          panelHeaderProps={{
            onClickReload: this.reloadTable
          }}
          expander
          filterable
          defaultPageSize={this.props.lines ? this.props.lines : 10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          actionButtons={(
            showPrinterLinkBtn &&
              <div className="ml-auto">
                <button
                  type="button"
                  className="btn btn-240 btn-success btn-rounded pr-3"
                  onClick={() => openPrinterLinkModal()}
                ><i className="fa fa-link mr-1"></i>{I18n.t('BEE963' /* Vincular impressoras */)}</button>
              </div>
          )}
        />
        {(showModalDelete &&
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.confirmDeletePrinterLink()}
            onCancel={() => this.closeDeleteModal()}
          >
            {I18n.t('BEE966', {0:selectedUserPrinter.printer.code,1:userSelected.login} /* A impressora %{0} será desvinculada do usuário %{1} */)}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  deleteLinkPrinter: (userPrinterId) => dispatch(deleteLinkPrinter(userPrinterId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserPrintersTable));