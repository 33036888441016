import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SweetAlert from 'react-bootstrap-sweetalert';
import OneClickButton from '../../../../components/form/button';
import { PanelPage } from '../../../../components/pages/pages';
import Form from '../../../../components/form/form';
import { printEanLabel } from '../../../../app/store/actions/labels';
import { getUserPrinterOptions } from '../../../../app/store/actions/printers';
import addNotification from '../../../../components/notification';
import FormatHelpers from '../../../../helpers/format';
import ROUTES from '../../../../config/routes';

class LabelEanForm extends React.PureComponent {
  constructor() {
    super();
    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE1371' /* Etiquetas */) },
      { value: I18n.t('BEE1372' /* Etiqueta EAN */), active: true },
    ];

    this.state = {
      ean: '',
      amount: 1,
      printerOptions: [],
      showPrinterDialog: false,
      printerSelected: null,
      showPrintConfirmation: false,
    };
  }

  async componentDidMount() {
    this.getUserPrinterOptions();
  }

  getUserPrinterOptions = async () => {
    const Info = await this.props.getUserPrinterOptions();
    const { userPrinter, printerOptions } = Info;
    const { printerSelected } = this.state;

    this.setState({
      printerOptions,
      printerSelected: printerSelected === null
        ? userPrinter
        : printerSelected !== userPrinter
          ? printerSelected
          : userPrinter,
    });
  };

  setValue = async (attr, value) => {
    if (attr === 'amount') {
      await this.setState({
        [`${attr}`]: FormatHelpers.formatNumber(value, 0, false, true),
      });
    } else {
      await this.setState({
        [`${attr}`]: value,
      });
    }
  };

  hidePrinterDialog = () => {
    this.setState({
      showPrinterDialog: false,
      printerSelected: null,
    });
  };

  checkPrinters = () => {
    const {
      printerOptions,
      printerSelected,
    } = this.state;
    if (printerOptions.length === 0 && printerSelected === null) {
      addNotification(
        'danger',
        I18n.t('BEE1425' /* Impressão EAN */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
    } else if (printerOptions && printerOptions.length > 1 /* && printerSelected === '' */) {
      this.setState({
        showPrintConfirmation: false,
        showPrinterDialog: true,
      });
    } else {
      this.printOut();
    }
  };

  printOut = async () => {
    this.setState({
      showPrintConfirmation: false,
      showPrinterDialog: false,
    });

    try {
      const {
        ean,
        amount,
        printerSelected,
        printerOptions,
      } = this.state;
      const selected = (printerSelected === null) ? printerOptions[0].value : printerSelected;

      if (ean.length === 0) {
        addNotification(
          'danger',
          I18n.t('BEE1425' /* Impressão EAN */),
          I18n.t('BEE1556' /* Campo (EAN) deve ser informado. */),
          'top-right',
        );
      } else if (!amount) {
        addNotification(
          'danger',
          I18n.t('BEE1425' /* Impressão EAN */),
          I18n.t('BEE2324', { 0: I18n.t('BEE1375' /* Quantidade de vezes */) } /* Campo (%{0}) deve ser informado */),
          'top-right',
        );
      } else {
        await this.props.printEanLabel(ean, selected, null, amount);
        addNotification(
          'success',
          I18n.t('BEE1425' /* Impressão EAN */),
          I18n.t('BEE1427' /* Etiquetas enviadas para impressora ! */),
          'top-right',
        );
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE1425' /* Impressão EAN */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1425' /* Impressão EAN */),
          I18n.t('BEE1441' /* Erro ao tentar imprimir */),
          'top-right',
        );
      }
    }
  };

  printConfirmation = () => {
    const { amount } = this.state;

    if (!parseInt(amount, 10)) {
      addNotification(
        'danger',
        I18n.t('BEE1372' /* Etiqueta EAN */),
        I18n.t('BEE2293' /* A quantidade tem que ser maior que 0 */),
        'top-right',
      );
    } else if (parseInt(amount, 10) === 1) this.checkPrinters();
    else this.setState({ showPrintConfirmation: true });
  };

  render() {
    const {
      ean,
      amount,
      showPrinterDialog,
      printerSelected,
      printerOptions,
      showPrintConfirmation,
    } = this.state;
    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={I18n.t('BEE1372' /* Etiqueta EAN */)}
        wikiHelp={ROUTES.MOVEMENT_LABEL_EAN_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInput(
                    ean,
                    'ean',
                    `${I18n.t('BEE377' /* EAN */)}:`,
                    'Z'.repeat(20),
                    'text',
                    true,
                    false,
                    '',
                    4,
                  ),
                  formContext.createInput(
                    FormatHelpers.formatNumber(amount, 0, false, true),
                    'amount',
                    `${I18n.t('BEE1375' /* Quantidade de Etiquetas */)}:`,
                    '',
                    'number',
                    true,
                    false,
                    '',
                    4,
                    undefined,
                    null,
                    2,
                  ),
                ])}
              />
            </div>
            {(showPrinterDialog && (
            <SweetAlert
              confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1324' /* Selecionar Impressora */)}
              onConfirm={() => this.printOut()}
              onCancel={() => this.hidePrinterDialog()}
            >
              <div className="mt-4">
                <Form
                  noPanel
                  setValue={this.setValue}
                  inputs={(formContext) => ([
                    formContext.createSelect(
                      printerSelected,
                      'printerSelected',
                      I18n.t('BEE328' /* Impressora */),
                      printerOptions,
                      '',
                      '',
                      12,
                      true,
                    ),
                  ])}
                />
              </div>
            </SweetAlert>
            ))}
            {(showPrintConfirmation && (
            <SweetAlert
              warning
              showCancel
              confirmBtnText={I18n.t('BEE1800' /* Prosseguir */)}
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="danger"
              title={I18n.t('BEE1975' /* Verificação de Quantidade */)}
              onConfirm={() => this.checkPrinters()}
              onCancel={() => this.setState({ showPrintConfirmation: false })}
            >
              <h5>
                {I18n.t('BEE1974', { 0: amount } /* A quantidade informada é %{0}. Deseja prosseguir? */)}
              </h5>
            </SweetAlert>
            )
            )}
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            className="btn btn-120 btn-primary p-5 m-5"
            onClick={() => this.printConfirmation()}
          >
            {I18n.t('BEE1270' /* Imprimir */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  printEanLabel: (eanCode, printer, filters, amount) => dispatch(printEanLabel(eanCode, printer, filters, amount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelEanForm);
