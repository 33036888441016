/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Route, Link } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';

class SidebarNavList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      clicked: -1,
    };
  }

  handleExpand(e, i, match) {
    e.preventDefault();

    this.setState(
      (state) => ({
        active: (this.state.active === i ? -1 : i),
        clicked: 1,
      }),
      () => {
        this.props.onUpdateSize();
      },
    );
  }

  render() {
    const { hasBlockPermission } = this.props.data;
    const hasChildrenValid = this.props.data.children && !hasBlockPermission;
    const icon = this.props.data.icon && !hasBlockPermission && <i className={this.props.data.icon} />;
    const img = this.props.data.img && <div className="icon-img"><img src={this.props.data.img} alt="" /></div>;
    const caret = (hasChildrenValid && !this.props.data.badge) && <b className="caret" />;
    const label = this.props.data.label && <span className="label label-theme m-l-5">{this.props.data.label}</span>;
    const badge = this.props.data.badge && <span className="badge pull-right">{this.props.data.badge}</span>;
    const title = this.props.data.title && (
    <span>
      {this.props.data.title}
      {' '}
      {label}
    </span>
    );
    const highlight = this.props.data.highlight && <i className="fa fa-paper-plane text-theme" />;

    return (
      <PageSettings.Consumer>
        {({ handleSidebarOnMouseOver, handleSidebarOnMouseOut, pageSidebarMinified }) => (
          <Route
            path={this.props.data.path}
            exact={this.props.data.exact}
            children={({ match }) => (
              <li className={(match ? 'active ' : '') + ((this.props.active || (this.props.clicked === -1 && match) || this.props.data.search) ? 'expand ' : 'closed ') + (hasChildrenValid ? 'has-sub ' : '')}>
                {hasChildrenValid ? (
                  <a
                    style={{ cursor: 'pointer' }}
                    onMouseOver={(e) => handleSidebarOnMouseOver(e, this.props.data)}
                    onMouseOut={(e) => handleSidebarOnMouseOut(e, this.props.data)}
                    onClick={this.props.expand}
                  >
                    {caret}
                    {' '}
                    {badge}
                    {' '}
                    {img}
                    {' '}
                    {icon}
                    {' '}
                    {title}
                    {' '}
                    {highlight}
                  </a>
                ) : (
                  <Link to={this.props.data.path || ' '}>
                    {caret}
                    {' '}
                    {img}
                    {' '}
                    {icon}
                    {' '}
                    {badge}
                    {' '}
                    {title}
                    {' '}
                    {highlight}
                  </Link>
                )}
                {hasChildrenValid && (
                <ul className={`sub-menu ${((this.props.active || (this.props.clicked === -1 && match) || this.props.data.search) && !pageSidebarMinified) ? 'd-block ' : 'd-none'}`}>
                  {hasChildrenValid && this.props.data.children.map((submenu, i) => (submenu ? (
                    <SidebarNavList
                      data={submenu}
                      key={i}
                      expand={(e) => this.handleExpand(e, i, match)}
                      active={i === this.state.active}
                      clicked={this.state.clicked}
                      onUpdateSize={this.props.onUpdateSize}
                    />
                  ) : null))}
                </ul>
                )}
              </li>
            )}
          />
        )}
      </PageSettings.Consumer>
    );
  }
}

export default SidebarNavList;
