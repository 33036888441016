import DocksRequests from '../../server/docks';
import { addLoading, removeLoading } from './loading';

export const setDocks = docks => ({
  type: 'SET_DOCKS',
  docks,
});

export const getDocksList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const docks = await DocksRequests.getDocks();
    dispatch(setDocks(docks));
  } finally {
    dispatch(removeLoading());
  }
};

export const createDock = dock => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newDock = await DocksRequests.createDock(dock);
    return newDock;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateDock = (dock) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DocksRequests.updateDock(dock);
  } finally {
    dispatch(removeLoading());
  }
}

export const getDock = (dockId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DocksRequests.getDock(dockId);
  } finally {
    dispatch(removeLoading());
  }
}

export const deleteDock = (dockId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DocksRequests.deleteDock(dockId);
  } finally {
    dispatch(removeLoading());
  }
}