const INITIAL_STATE = {
  carriersList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_CARRIERS': {
      return { ...state, carriersList: action.carriers };
    }
    default:
      return state;
  }
};
