import { I18n } from 'react-redux-i18n';

function statusTransfer(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE470' /* Pendente */);
      case 2:
        return I18n.t('BEE2262' /* Iniciado */);
      case 3:
        return I18n.t('BEE1765' /* Concluído */);
      case 4:
        return I18n.t('BEE64' /* Cancelado */);
      default:
        return '';
    }
  }
  return [
    { value: 1, label: I18n.t('BEE470' /* Pendente */) },
    { value: 2, label: I18n.t('BEE2262' /* Iniciado */) },
    { value: 3, label: I18n.t('BEE1765' /* Concluído */) },
    { value: 4, label: I18n.t('BEE64' /* Cancelado */) },
  ]
}

export default {
  statusTransfer,
}