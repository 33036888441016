import RestService from '../services/rest';

// GET
async function getListIntegrationQueue(filterStartDate, filterEndDate, isBeeStock) {
  return RestService.getAuthenticated(
    'integration/integrationQueue/list'
    + `?filterStartDate=${filterStartDate}`
    + `&filterEndDate=${filterEndDate}`
    + `&isBeeStock=${isBeeStock}`,
  );
}

// PUT
async function resyncQueue(queueIds = [], isBeeStock) {
  return RestService.putAuthenticated('integration/resync', { queueIds, isBeeStock });
}

export default {
  getListIntegrationQueue,
  resyncQueue,
};
