import RestService from '../services/rest';

// GET
async function getSerialGroupDetail(code, depositCode) {
  return RestService.getAuthenticated('serialGroups/detail', { code, depositCode });
}

async function getSerialGroupOrSerialLabel(code, depositCode) {
  return RestService.getAuthenticated('serialGroups/serialGroupOrSerialLabel', { code, depositCode });
}

async function getListSerialGroups(filterStartDate, filterEndDate, page, pageSize, sorted, filtered) {
  return RestService.postAuthenticated('serialGroups/list', {
    filterStartDate, filterEndDate, page, pageSize, sorted, filtered,
  });
}

async function getSerialGroup(code, type = '', inboundOrderId, uz = '') {
  return RestService.getAuthenticated('serialGroups/getSerialGroupDocument', {
    code, type, inboundOrderId, uz,
  });
}

async function getListSerialGroupsToPrint(productionOrderCode, page, pageSize, sorted, filtered) {
  return RestService.postAuthenticated('serialGroups/listToPrint', {
    productionOrderCode, page, pageSize, sorted, filtered,
  });
}

export default {
  getSerialGroupDetail,
  getSerialGroupOrSerialLabel,
  getListSerialGroups,
  getSerialGroup,
  getListSerialGroupsToPrint,
};
