import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { getDetailOrder } from '../../../app/store/actions/returnPickingOrder';
import Form from '../../../components/form/form';
import { PanelPage } from '../../../components/pages/pages';
import addNotification from '../../../components/notification';
import OrderSearchModa from './orderSearchModa';
import PickingReturn from './pickingReturn';
import ROUTES from '../../../config/routes';

class PickingReturnForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderNumber: '',
      showOrderSearch: false,
      ShowPickingReturn: false,
      orderSelected: {},
    };
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  keypressConfirmPicking = (e) => {
    e.keyCode === 13 && this.getDetailOrder();
  };

  toggleOrderSearchModal = async () => {
    this.setState({ showOrderSearch: !this.state.showOrderSearch });
  };

  detailOrderSelected = async (orderSelected) => {
    this.setState({
      orderSelected,
      ShowPickingReturn: true,
      showOrderSearch: false,
    });
  };

  getDetailOrder = async (search = false) => {
    const { orderNumber, orderSelected } = this.state;
    if (orderNumber || search) {
      try {
        const detailOrder = await this.props.getDetailOrder('', '', (search) ? orderSelected.orderNumber : orderNumber);
        if (detailOrder && detailOrder.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE908' /* Devolução de Picking */),
            I18n.t('BEE1902' /* Não foi localizar o documento */),
            'top-right',
          );
        } else if (detailOrder.length) {
          this.detailOrderSelected(detailOrder[0]);
        } else {
          this.resetForm();
        }
      } catch (error) {
        const messageError = error.response && error.response.data
        && error.response.data.error && error.response.data.error.message;

        if (messageError) {
          addNotification(
            'danger',
            I18n.t('BEE908' /* Devolução de Picking */),
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE908' /* Devolução de Picking */),
            I18n.t('BEE1902' /* Não foi localizar o documento */),
            'top-right',
          );
        }
      }
    } else {
      addNotification(
        'danger',
        I18n.t('BEE908' /* Devolução de Picking */),
        I18n.t('BEE1903' /* O campo documento e obrigatório */),
        'top-right',
      );
      document.getElementById(1).focus();
      document.getElementById(1).select();
    }
  };

  resetForm() {
    this.setState({
      orderNumber: '',
      showOrderSearch: false,
      ShowPickingReturn: false,
      orderSelected: {},
    });
  }

  render() {
    const {
      orderNumber, showOrderSearch, ShowPickingReturn, orderSelected,
    } = this.state;

    return (
      <>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE908' /* Devolução de Picking */)}</li>
          </ol>
        </div>

        {!ShowPickingReturn && (
          <PanelPage
            noButton
            title={I18n.t('BEE908' /* Devolução de Picking */)}
            wikiHelp={ROUTES.OUTBOUND_PICKING_RETURN_HELP}
            content={(
              <div className="row">
                <div className="col-md-4" onSubmit={(e) => e.preventDefault()}>
                  <Form
                    noPanel
                    leftType
                    setValue={this.setValue}
                    inputs={(formContext) => ([
                      formContext.createInputButton(
                        orderNumber,
                        'orderNumber',
                        I18n.t('BEE1904' /* Documento de Saída */),
                        '',
                        'text',
                        false,
                        false,
                        <i className="fa fa-list-alt" />,
                        this.toggleOrderSearchModal,
                        this.keypressConfirmPicking,
                        1,
                      ),
                    ])}
                  />
                </div>
              </div>
            )}
            footerContent={(
              <button
                type="submit"
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={() => this.getDetailOrder()}
              >
                {I18n.t('BEE407' /* Buscar */)}
              </button>
            )}
          />
        )}

        {ShowPickingReturn && (
          <PickingReturn
            resetForm={() => this.resetForm()}
            orderSelected={orderSelected}
            getDetailOrder={(returnOrder) => this.getDetailOrder(returnOrder)}
          />
        )}

        {showOrderSearch && (
          <OrderSearchModa
            detailOrderSelected={(search) => this.detailOrderSelected(search)}
            toggleOrderSearchModal={() => this.toggleOrderSearchModal()}
            showOrderSearch={showOrderSearch}
          />
        )}

      </>
    );
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  getDetailOrder: (
    filterStartDate,
    filterEndDate,
    orderNumber,
  ) => dispatch(getDetailOrder(filterStartDate, filterEndDate, orderNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PickingReturnForm));
