import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import SweetAlert from 'react-bootstrap-sweetalert';
import Table from '../../../../components/table/Table';
import ROUTES from '../../../../config/routes';
import { deleteResupplyDeposits, getAllResupplyDeposits } from '../../../../app/store/actions/resupplyDeposits';
import addNotification from '../../../../components/notification';

class ResupplyDepositsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resupplyDeposits: [],
      showModalDelete: false,
      resupplyDepositsUpdate: null,
      resupplyDepositsSelected: {},
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button
              type="button"
              className="btn btn-default btn-xs"
            >
              {I18n.t('BEE55' /* Ações */)}
            </button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showDetailResupplyDeposits(rows.row)}>
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>

                <>
                  <DropdownItem onClick={() => this.showEditResupplyDeposits(rows.row)}>
                    {I18n.t('BEE57' /* Editar */)}
                  </DropdownItem>
                  <DropdownItem onClick={() => this.showCopyResupplyDeposits(rows.row)}>
                    {I18n.t('BEE80' /* Copiar */)}
                  </DropdownItem>
                  <DropdownItem onClick={() => this.showDeleteResupplyDeposits(rows.row._original)}>
                    {I18n.t('BEE81' /* Eliminar */)}
                  </DropdownItem>
                </>

              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1795' /* Depósito Retirada */),
        accessor: 'depositCodeWithdraw',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1796' /* Depósito Entrada */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.getAllResupplyDeposits();
  }

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      resupplyDepositsUpdate: null,
      resupplyDepositsSelected: {},
    });
  };

  getAllResupplyDeposits = async () => {
    try {
      const resupplyDeposits = await this.props.getAllResupplyDeposits();
      this.setState({ resupplyDeposits });
    } catch (e) {
      addNotification(
        'danger',
        I18n.t('BEE1627' /* Erro */),
        I18n.t('BEE1969' /* Erro ao buscar dados */),
        'top-right',
      );
    }
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      value={(filter && filter.value) || ''}
      onChange={(e) => onChange(e.target.value)}
      autoComplete="nope"
    />
  );

  showDetailResupplyDeposits = (row) => {
    this.props.history.push({
      pathname: ROUTES.RESUPPLY_DEPOSITS_DETAIL,
      state: {
        resupplyDeposits: row._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditResupplyDeposits = (row) => {
    this.props.history.push({
      pathname: ROUTES.RESUPPLY_DEPOSITS_EDIT,
      state: {
        resupplyDeposits: row._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyResupplyDeposits = (row) => {
    this.props.history.push({
      pathname: ROUTES.RESUPPLY_DEPOSITS_COPY,
      state: {
        resupplyDeposits: row._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showDeleteResupplyDeposits = (resupplyDeposits) => {
    this.setState({
      showModalDelete: true,
      resupplyDepositsUpdate: resupplyDeposits.id,
      resupplyDepositsSelected: resupplyDeposits,
    });
  };

  deleteResupplyDeposits = async (resupplyDepositsId) => {
    const result = await this.props.deleteResupplyDeposits(resupplyDepositsId);

    this.setInitState();
    if (result) {
      addNotification(
        'danger',
        I18n.t('BEE2932' /* Associação de Depósitos */),
        I18n.t('BEE2671' /* Regra eliminada com sucesso! */),
        'top-right',
      );
    }
    await this.getAllResupplyDeposits();
  };

  render() {
    const {
      page, showModalDelete, resupplyDepositsUpdate, resupplyDepositsSelected,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE2932' /* Associação de Depósitos */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          wikiHelp={ROUTES.RESUPPLY_DEPOSITS_HELP}
          headerTitle={I18n.t('BEE2932' /* Associação de Depósitos */)}
          actionButtons={(
            <div className="ml-auto">
              <Link
                to={ROUTES.RESUPPLY_DEPOSITS_CREATE}
                className="btn btn-success btn-sm btn-rounded pl-2 pr-3"
              >
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE2882' /* Incluir */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getAllResupplyDeposits,
          }}
          filterable
          data={this.state.resupplyDeposits}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun
            && this.props.location.state && this.props.location.state.dice
            && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page1) => ((page1 >= 0) ? this.setState({ page: page1 }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.deleteResupplyDeposits(resupplyDepositsUpdate)}
              onCancel={() => this.setInitState()}
            >
              {I18n.t(
                'BEE2878',
                {
                  0: resupplyDepositsSelected.depositCode,
                  1: resupplyDepositsSelected.depositCodeWithdraw,
                }, /* A regra do depósito origem %{0} e destino %{1} será eliminado! */
              )}
            </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllResupplyDeposits: () => dispatch(getAllResupplyDeposits()),
  deleteResupplyDeposits: (resupplyDepositsId) => dispatch(deleteResupplyDeposits(resupplyDepositsId)),
});

export default connect(undefined, mapDispatchToProps)(withRouter(ResupplyDepositsList));
