import React from 'react';
import { CustomProvider, DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import ptBR from 'rsuite/locales/pt_BR';
import { I18n } from 'react-redux-i18n';
import {
  endOfDay, startOfDay, startOfMonth, subDays,
} from 'date-fns';
import '../../scss/plugins/_rsuite-daterangepicker.scss';

export default function (props) {
  return (
    <CustomProvider locale={ptBR}>
      <DateRangePicker
        id="dateRangePicker"
        placeholder={I18n.t('BEE2440' /* Nenhuma data selecionada */)}
        format="dd/MM/yyyy"
        character=" - "
        defaultValue={[startOfDay(subDays(new Date(), 30)), endOfDay(new Date())]}
        appearance="subtle"
        className="bg-dark text-white"
        cleanable={false}
        ranges={[
          {
            label: I18n.t('BEE2441' /* Este mês */),
            value: [startOfMonth(new Date()), endOfDay(new Date())],
          },
          {
            label: I18n.t('BEE2442', { 0: 30 } /* Últimos %{0} dias */),
            value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
          },
          {
            label: I18n.t('BEE2442', { 0: 90 } /* Últimos %{0} dias */),
            value: [startOfDay(subDays(new Date(), 90)), endOfDay(new Date())],
          },
        ]}
        {...props}
      />
    </CustomProvider>
  );
}
