/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { PageSettings } from '../../config/page-settings';
import SidebarNavList from './sidebar-nav-list';

class SidebarNav extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      clicked: -1,
      menus: this.props.menus,
    };

    this.handleSidebarSearch = this.handleSidebarSearch.bind(this);
  }

  handleExpand(e, i, match) {
    e.preventDefault();

    if (this.state.clicked === -1 && match) {
      this.setState(
        (state) => ({
          active: -1,
          clicked: 1,
        }),
        () => {
          this.props.onUpdateSize();
        },
      );
    } else {
      this.setState(
        (state) => ({
          active: this.state.active === i ? -1 : i,
          clicked: 1,
        }),
        () => {
          this.props.onUpdateSize();
        },
      );
    }
  }

  handleSidebarSearch(e) {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();

    this.setState((state) => {
      let newMenus = [];
      let subMenu1 = [];
      let subMenu2 = [];
      let subMenu3 = [];

      if (searchValue !== '') {
        const { menus } = this.props;
        for (let item1 = 0; item1 < menus.length; item1 += 1) {
          if (menus[item1]) {
            if (menus[item1].title.toLowerCase().search(searchValue) > -1) {
              newMenus.push(menus[item1]);
            } else {
              subMenu1 = [];
              if (menus[item1].children) {
                for (let item2 = 0; item2 < menus[item1].children.length; item2 += 1) {
                  if (menus[item1].children[item2]) {
                    if (menus[item1].children[item2].title.toLowerCase().search(searchValue) > -1) {
                      subMenu1.push(menus[item1].children[item2]);
                    } else {
                      subMenu2 = [];
                      if (menus[item1].children[item2].children) {
                        for (let item3 = 0; item3 < menus[item1].children[item2].children.length; item3 += 1) {
                          if (menus[item1].children[item2].children[item3]) {
                            if (menus[item1].children[item2].children[item3].title.toLowerCase().search(searchValue)
                            > -1) {
                              subMenu2.push(menus[item1].children[item2].children[item3]);
                            } else {
                              subMenu3 = [];
                              if (menus[item1].children[item2].children[item3].children) {
                                for (let item4 = 0; item4
                                  < menus[item1].children[item2].children[item3].children.length; item4 += 1) {
                                  if (menus[item1].children[item2].children[item3].children[item4]) {
                                    if (menus[item1].children[item2]
                                      .children[item3].children[item4].title.toLowerCase().search(searchValue) > -1) {
                                      subMenu3.push(menus[item1].children[item2].children[item3].children[item4]);
                                    }
                                  }
                                }
                              }
                              if (subMenu3.length > 0) {
                                subMenu2.push({
                                  ...menus[item1].children[item2].children[item3],
                                  children: subMenu3,
                                });
                              }
                            }
                          }
                        }
                      }
                      if (subMenu2.length > 0) {
                        subMenu1.push({
                          ...menus[item1].children[item2],
                          children: subMenu2,
                        });
                      }
                    }
                  }
                }
              }
              if (subMenu1.length > 0) {
                newMenus.push({
                  ...menus[item1],
                  children: subMenu1,
                });
              }
            }
          }
        }
      } else {
        newMenus = this.props.menus;
      }
      return {
        menus: newMenus,
      };
    });
  }

  render() {
    return (
      <ul className="nav">
        {this.context.pageSidebarSearch && (
          <li className="nav-search">
            <input
              type="text"
              className="form-control"
              placeholder={I18n.t('BEE2776' /* Filtro de Menu */)}
              onKeyUp={this.handleSidebarSearch}
            />
          </li>
        )}
        <li className="nav-header" />
        {this.state.menus.map((menu, i) => (
          <Route
            path={menu.path}
            exact={menu.exact}
            key={menu.path}
            children={({ match }) => (
              <SidebarNavList
                data={menu}
                key={i}
                expand={(e) => this.handleExpand(e, i, match)}
                active={i === this.state.active}
                clicked={this.state.clicked}
                onUpdateSize={this.props.onUpdateSize}
              />
            )}
          />
        ))}
      </ul>
    );
  }
}

const mapStateToProps = (state) => ({
  menus: state.app.menus,
});

export default connect(mapStateToProps, {})(SidebarNav);
