const INITIAL_STATE = {
  outboundVolumeList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_OUTBOUND_VOLUME_LIST': {
      return { ...state, outboundVolumeList: action.outboundVolumeList };
    }
    case 'SET_USER_REMOVAL_LIST': {
      return { ...state, userRemovalList: action.userRemovalList };
    }
    default:
      return state;
  }
};
