import AddressTypesRequests from '../../server/storageAddressTypes';
import { addLoading, removeLoading } from './loading';

export const setStorageAddressTypes = (storageAddressTypes) => ({
  type: 'SET_STORAGE_ADRESS_TYPES',
  storageAddressTypes,
});

export const getStorageAddressTypesList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const addressTypes = await AddressTypesRequests.getAddressTypes();
    dispatch(setStorageAddressTypes(addressTypes));
  } finally {
    dispatch(removeLoading());
  }
};

export const getStorageAddressTypesOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const addressTypes = await AddressTypesRequests.getAddressTypes();
    const addressTypesOptions = addressTypes.length === undefined ? null : addressTypes.map((at) => ({
      label: `${at.name}`,
      value: at.id,
    }));
    return addressTypesOptions;
  } finally {
    dispatch(removeLoading());
  }
};

export const createStorageAddressType = (newAddressType) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newAddrType = await AddressTypesRequests.createAddressType(newAddressType);
    return newAddrType;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateStorageAddressType = (updAddressType) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AddressTypesRequests.updateAddressType(updAddressType);
  } finally {
    dispatch(removeLoading());
  }
};

export const getStorageAddressType = (addressTypeId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AddressTypesRequests.getAddressType(addressTypeId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteStorageAddressType = (addressTypeId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AddressTypesRequests.deleteAddressType(addressTypeId);
  } finally {
    dispatch(removeLoading());
  }
};
