import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import OneClickButton from '../../components/form/button';
import { PanelPage } from '../../components/pages/pages';
import DualList from '../../components/pages/DualList';
import Form from '../../components/form/form';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';
import addNotification from '../../components/notification';

class ReportMovementExpeditionOutboundLot extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          { I18n.t('BEE12' /* Início */)}
        </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE3501' /* Documento de Saída - Rastreio Lote */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE443' /* Documento */),
        value: 'orderNumber',
      }, {
        label: I18n.t('BEE145' /* Filial */),
        value: 'branchCode',
      }, {
        label: I18n.t('BEE3502' /* Pedido do cliente */),
        value: 'customerOrderCode',
      }, {
        label: I18n.t('BEE378' /* Código do Produto */),
        value: 'productCode',
      }, {
        label: I18n.t('BEE428' /* Lote */),
        value: 'lotNumber',
      }, {
        label: I18n.t('BEE434' /* Data Validade */),
        value: 'expirationDate',
      }, {
        label: I18n.t('BEE50' /* Cliente */),
        value: 'customerCode',
      }, {
        label: I18n.t('BEE3503' /* Data de Integração do Documento */),
        value: 'documentCreatedAt',
      }, {
        label: I18n.t('BEE1759' /* Status do Documento */),
        value: 'documentStatus',
      }, {
        label: I18n.t('BEE441' /* Quantidade */),
        value: 'quantity',
      },
    ];

    this.state = {
      orderNumberFrom: '',
      orderNumberTo: '',
      branchCodeFrom: '',
      branchCodeTo: '',
      customerOrderCodeFrom: '',
      customerOrderCodeTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      lotNumberFrom: '',
      lotNumberTo: '',
      expirationDateFrom: '',
      expirationDateTo: '',
      customerCodeFrom: '',
      customerCodeTo: '',
      createdAtTo: '',
      createdAtFrom: '',
      pendingAllocation: true,
      pendingCableCut: true,
      pendingPick: true,
      onPick: true,
      pendingCheck: true,
      pendingDock: true,
      pendingWaybill: true,
      pendingConsolidation: true,
      waitingBilling: true,
      finished: true,
      divergence: true,
      canceled: true,
      refund: true,
      checkAllCheckBoxes: true,
    };
  }

  checkOrUncheckAllBoxes = (value) => {
    this.setState({
      pendingAllocation: value,
      pendingCableCut: value,
      pendingPick: value,
      onPick: value,
      pendingCheck: value,
      pendingDock: value,
      pendingWaybill: value,
      pendingConsolidation: value,
      waitingBilling: value,
      finished: value,
      divergence: value,
      canceled: value,
      refund: value,
      checkAllCheckBoxes: value,
    });
  };

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });

    if (attr === 'checkAllCheckBoxes') {
      this.checkOrUncheckAllBoxes(value);
    }
  };

  onGenerateSpreadsheet = () => {
    const {
      orderNumberFrom,
      orderNumberTo,
      branchCodeFrom,
      branchCodeTo,
      customerOrderCodeFrom,
      customerOrderCodeTo,
      productCodeFrom,
      productCodeTo,
      lotNumberFrom,
      lotNumberTo,
      expirationDateFrom,
      expirationDateTo,
      customerCodeFrom,
      customerCodeTo,
      createdAtTo,
      createdAtFrom,
      pendingAllocation,
      pendingCableCut,
      pendingPick,
      onPick,
      pendingCheck,
      pendingDock,
      pendingWaybill,
      pendingConsolidation,
      waitingBilling,
      finished,
      divergence,
      canceled,
      refund,
    } = this.state;

    const statusDocument = [];
    const { location } = this.props;
    const headers = this.options;

    if (pendingAllocation) statusDocument.push('1');
    if (pendingCableCut) statusDocument.push('2');
    if (pendingPick) statusDocument.push('3');
    if (onPick) statusDocument.push('4');
    if (pendingCheck) statusDocument.push('5');
    if (pendingDock) statusDocument.push('6');
    if (pendingWaybill) statusDocument.push('7');
    if (pendingConsolidation) statusDocument.push('8');
    if (waitingBilling) statusDocument.push('9');
    if (finished) statusDocument.push('10');
    if (divergence) statusDocument.push('11');
    if (canceled) statusDocument.push('12');
    if (refund) statusDocument.push('13');

    const filters = returnFiltersByRules([
      ['orderNumber', { type: 'between', value: [orderNumberFrom, orderNumberTo] }],
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['customerOrderCode', { type: 'between', value: [customerOrderCodeFrom, customerOrderCodeTo] }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['lotNumber', { type: 'between', value: [lotNumberFrom, lotNumberTo] }],
      ['expirationDate', { type: 'between', value: [expirationDateFrom, expirationDateTo] }],
      ['customerCode', { type: 'between', value: [customerCodeFrom, customerCodeTo] }],
      ['createdAt', { type: 'between', value: [createdAtFrom, createdAtTo] }],
      ['status', { type: 'in', value: statusDocument }],
    ]);
    if (!filters.status) {
      addNotification(
        'danger',
        I18n.t('BEE1759' /* Status do Documento */),
        I18n.t('BEE1198' /* Status deve ser informado! */),
        'top-right',
      );
      return;
    }

    if (Object.keys(filters).length > 1) {
      this.props.generateReport(
        I18n.t('BEE3501' /* Documento de Saída - Rastreio Lote */).toLowerCase(),
        'xlsx',
        location.pathname,
        headers,
        filters,
      );
      return;
    }

    addNotification(
      'danger',
      I18n.t('BEE2386' /* Verifique os Campos */),
      I18n.t('BEE2387' /* Nenhum campo foi preenchido */),
      'top-right',
    );
  };

  onSelectFilter = (newState) => {
    this.setState({ ...newState });
  };

  render() {
    const { location } = this.props;
    const {
      orderNumberFrom,
      orderNumberTo,
      branchCodeFrom,
      branchCodeTo,
      customerOrderCodeFrom,
      customerOrderCodeTo,
      productCodeFrom,
      productCodeTo,
      lotNumberFrom,
      lotNumberTo,
      expirationDateFrom,
      expirationDateTo,
      customerCodeFrom,
      customerCodeTo,
      createdAtFrom,
      createdAtTo,
      pendingAllocation,
      pendingCableCut,
      pendingPick,
      onPick,
      pendingCheck,
      pendingDock,
      pendingWaybill,
      pendingConsolidation,
      waitingBilling,
      finished,
      divergence,
      canceled,
      refund,
      checkAllCheckBoxes,
    } = this.state;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE3501' /* Documento de Saída - Rastreio Lote */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ), formContext.createInputRange(
                    { from: orderNumberFrom, to: orderNumberTo },
                    { from: 'orderNumberFrom', to: 'orderNumberTo' },
                    `${I18n.t('BEE443' /* Documento */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: customerOrderCodeFrom, to: customerOrderCodeTo },
                    { from: 'customerOrderCodeFrom', to: 'customerOrderCodeTo' },
                    `${I18n.t('BEE3502' /* Pedido do cliente */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE27' /* Produtos */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: lotNumberFrom, to: lotNumberTo },
                    { from: 'lotNumberFrom', to: 'lotNumberTo' },
                    `${I18n.t('BEE428' /* Lote */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createDateInputRange(
                    { from: expirationDateFrom, to: expirationDateTo },
                    { from: 'expirationDateFrom', to: 'expirationDateTo' },
                    `${I18n.t('BEE434' /* Data Validade */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: customerCodeFrom, to: customerCodeTo },
                    { from: 'customerCodeFrom', to: 'customerCodeTo' },
                    `${I18n.t('BEE50' /* Cliente */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createDateInputRange(
                    { from: createdAtFrom, to: createdAtTo },
                    { from: 'createdAtFrom', to: 'createdAtTo' },
                    `${I18n.t('BEE3503' /* Data de Integração do Documento */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createCheckBoxes([
                    {
                      attr: 'checkAllCheckBoxes',
                      value: checkAllCheckBoxes,
                      label: I18n.t('BEE2385' /* Marcar ou Desmarcar todos */),
                    },
                    {
                      attr: 'pendingAllocation',
                      value: pendingAllocation,
                      label: I18n.t('BEE1946' /* Pendente Alocação */),
                    },
                    {
                      attr: 'pendingCableCut',
                      value: pendingCableCut,
                      label: I18n.t('BEE1947' /* Pendente Corte de Cabos */),
                    },
                    { attr: 'pendingPick', value: pendingPick, label: I18n.t('BEE1365' /* Pendente Separação */) },
                    { attr: 'onPick', value: onPick, label: I18n.t('BEE1367' /* Em Separação */) },
                    { attr: 'pendingCheck', value: pendingCheck, label: I18n.t('BEE1948' /* Pendente Conferência */) },
                    { attr: 'pendingDock', value: pendingDock, label: I18n.t('BEE1949' /* Pendente Docas */) },
                    { attr: 'pendingWaybill', value: pendingWaybill, label: I18n.t('BEE1773' /* Pendente Romaneio */) },
                    {
                      attr: 'pendingConsolidation',
                      value: pendingConsolidation,
                      label: I18n.t('BEE1769' /* Pendente Consolidação */),
                    },
                    {
                      attr: 'waitingBilling',
                      value: waitingBilling,
                      label: I18n.t('BEE2529' /* Pendente Faturamento */),
                    },
                    { attr: 'finished', value: finished, label: I18n.t('BEE1833' /* Finalizado */) },
                    { attr: 'divergence', value: divergence, label: I18n.t('BEE176' /* Divergência */) },
                    { attr: 'canceled', value: canceled, label: I18n.t('BEE64' /* Cancelado */) },
                    { attr: 'refund', value: refund, label: I18n.t('BEE1899' /* Devolvido */) },
                  ], `${I18n.t('BEE1759' /* Status do Documento */)}:`),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
              )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
              )}
      />
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMovementExpeditionOutboundLot);
