import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import addNotification from '../../components/notification';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportMovementInboundOrdersItemsList extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE2388' /* Itens de Documentos de Entrada */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE145' /* Filial */),
        value: 'inboundOrder.branchCode',
      }, {
        label: I18n.t('BEE342' /* Fornecedor */),
        value: 'inboundOrder.supplierCode',
      }, {
        label: I18n.t('BEE2390' /* CNPJ do Fornecedor */),
        value: 'inboundOrder.supplier.cnpj',
      }, {
        label: I18n.t('BEE301' /* Transportadora */),
        value: 'inboundOrder.carrierCode',
      }, {
        label: I18n.t('BEE443' /* Documento */),
        value: 'inboundOrder.orderNumber',
      }, {
        label: I18n.t('BEE1028' /* Série do documento */),
        value: 'inboundOrder.serie',
      }, {
        label: I18n.t('BEE1030' /* CFOP */),
        value: 'inboundOrder.cfop',
      }, {
        label: I18n.t('BEE200' /* Tipo */),
        value: 'inboundOrder.orderType',
      }, {
        label: I18n.t('BEE967' /* Chave de Acesso */),
        value: 'inboundOrder.accessKey',
      }, {
        label: I18n.t('BEE1759' /* Status do Documento */),
        value: 'inboundOrder.status',
      }, {
        label: I18n.t('BEE2409' /* Data / Hora Integração */),
        value: 'inboundOrder.createdAt',
      }, {
        label: I18n.t('BEE400' /* Placa do Veículo */),
        value: 'inboundOrder.licensePlate',
      }, {
        label: I18n.t('BEE463' /* Linha */),
        value: 'lineNumber',
      }, {
        label: I18n.t('BEE378' /* Código do Produto */),
        value: 'productCode',
      }, {
        label: I18n.t('BEE758' /* Descrição do Produto */),
        value: 'product.name',
      }, {
        label: I18n.t('BEE1756' /* Unidade de Medida */),
        value: 'product.unitMeasure',
      }, {
        label: I18n.t('BEE425' /* Batismo */),
        value: 'baptismLabel',
      }, {
        label: I18n.t('BEE428' /* Lote */),
        value: 'productLots.lotNumber',
      }, {
        label: I18n.t('BEE2398' /* Data de Validade */),
        value: 'productLots.expDate',
      }, {
        label: I18n.t('BEE441' /* Quantidade */),
        value: 'quantity',
      }, {
        label: I18n.t('BEE445' /* Conferido */),
        value: 'quantityCheck',
      }, {
        label: I18n.t('BEE470' /* Pendente */),
        value: 'quantityPending',
      }, {
        label: I18n.t('BEE427' /* Armazenado */),
        value: 'quantityStorage',
      }, {
        label: I18n.t('BEE2391' /* Danificado */),
        value: 'quantityDamage',
      }, {
        label: I18n.t('BEE176' /* Divergência */),
        value: 'quantityDivergence',
      }, {
        label: I18n.t('BEE2393' /* Status do Produto */),
        value: 'status',
      }, {
        label: I18n.t('BEE1422' /* Usuário Conferência */),
        value: 'checkProductUser',
      }, {
        label: I18n.t('BEE2395' /* Data / Hora Conferência */),
        value: 'checkProductAt',
      }, {
        label: I18n.t('BEE468' /* Usuário Armazenagem */),
        value: 'storageProductUser',
      }, {
        label: I18n.t('BEE2399' /* Data / Hora Armazenagem */),
        value: 'storageProductAt',
      },
    ];

    this.state = {
      branchCodeFrom: '',
      branchCodeTo: '',
      supplierCodeFrom: '',
      supplierCodeTo: '',
      orderNumberFrom: '',
      orderNumberTo: '',
      carrierCodeFrom: '',
      carrierCodeTo: '',
      integrationDateFrom: '',
      integrationDateTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      pendingSign: true,
      signChecked: true,
      pendingCheck: true,
      startedCheck: true,
      finishedCheck: true,
      storage: true,
      stored: true,
      canceled: true,
      checkAllCheckBoxes: true,
    };
  }

  checkOrUncheckAllBoxes = (value) => {
    this.setState({
      pendingSign: value,
      signChecked: value,
      pendingCheck: value,
      startedCheck: value,
      finishedCheck: value,
      storage: value,
      stored: value,
      canceled: value,
      checkAllCheckBoxes: value,
    });
  };

  handleSelectAllCheckBox = () => {
    if (this.state.pendingSign
      && this.state.signChecked
      && this.state.pendingCheck
      && this.state.startedCheck
      && this.state.finishedCheck
      && this.state.storage
      && this.state.stored
      && this.state.canceled) {
      this.setState({
        checkAllCheckBoxes: true,
      });
    } else {
      this.setState({
        checkAllCheckBoxes: false,
      });
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });

    if (attr === 'checkAllCheckBoxes') {
      this.checkOrUncheckAllBoxes(value);
    }

    this.handleSelectAllCheckBox();
  };

  onGenerateSpreadsheet = () => {
    const {
      orderNumberFrom,
      orderNumberTo,
      branchCodeFrom,
      branchCodeTo,
      carrierCodeFrom,
      carrierCodeTo,
      integrationDateFrom,
      integrationDateTo,
      productCodeFrom,
      productCodeTo,
      supplierCodeFrom,
      supplierCodeTo,
      pendingSign,
      signChecked,
      pendingCheck,
      startedCheck,
      finishedCheck,
      storage,
      stored,
      canceled,
    } = this.state;

    const statusDocument = [];
    const { location } = this.props;
    const headers = this.options;

    if (pendingSign) statusDocument.push('1');
    if (signChecked) statusDocument.push('2');
    if (pendingCheck) statusDocument.push('3');
    if (startedCheck) statusDocument.push('4');
    if (finishedCheck) statusDocument.push('5');
    if (storage) statusDocument.push('6');
    if (stored) statusDocument.push('7');
    if (canceled) statusDocument.push('9');

    const filters = returnFiltersByRules([
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['orderNumber', { type: 'between', value: [orderNumberFrom, orderNumberTo] }],
      ['carrierCode', { type: 'between', value: [carrierCodeFrom, carrierCodeTo] }],
      ['createdAt', { type: 'between', value: [integrationDateFrom, integrationDateTo] }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['supplierCode', { type: 'between', value: [supplierCodeFrom, supplierCodeTo] }],
      ['status', { type: 'in', value: statusDocument }],
    ]);

    if (!filters.status) {
      addNotification(
        'danger',
        I18n.t('BEE1759' /* Status do Documento */),
        I18n.t('BEE1198' /* Status deve ser informado! */),
        'top-right',
      );
      return;
    }

    if (Object.keys(filters).length > 1) {
      this.props.generateReport(
        I18n.t('BEE2388' /* Itens de Documentos de Entrada */).toLowerCase(),
        'xlsx',
        location.pathname,
        headers,
        filters,
      );
      return;
    }

    addNotification(
      'danger',
      I18n.t('BEE2386' /* Verifique os Campos */),
      I18n.t('BEE2387' /* Nenhum campo foi preenchido */),
      'top-right',
    );
  };

  onSelectFilter = (newState) => {
    this.setState({ ...newState });
  };

  render() {
    const {
      orderNumberFrom,
      orderNumberTo,
      branchCodeFrom,
      branchCodeTo,
      carrierCodeFrom,
      carrierCodeTo,
      integrationDateFrom,
      integrationDateTo,
      productCodeFrom,
      productCodeTo,
      supplierCodeFrom,
      supplierCodeTo,
      pendingSign,
      signChecked,
      pendingCheck,
      startedCheck,
      finishedCheck,
      storage,
      stored,
      canceled,
      checkAllCheckBoxes,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={I18n.t('BEE2389' /* Relatório - Itens de Documentos de Entrada */)}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ), formContext.createInputRange(
                    { from: orderNumberFrom, to: orderNumberTo },
                    { from: 'orderNumberFrom', to: 'orderNumberTo' },
                    `${I18n.t('BEE443' /* Documento */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: supplierCodeFrom, to: supplierCodeTo },
                    { from: 'supplierCodeFrom', to: 'supplierCodeTo' },
                    `${I18n.t('BEE1044' /* Código do fornecedor */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE225' /* Produto */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: carrierCodeFrom, to: carrierCodeTo },
                    { from: 'carrierCodeFrom', to: 'carrierCodeTo' },
                    `${I18n.t('BEE2272' /* Código da Transportadora */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createDateInputRange(
                    { from: integrationDateFrom, to: integrationDateTo },
                    { from: 'integrationDateFrom', to: 'integrationDateTo' },
                    `${I18n.t('BEE2224' /* Data Integração */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createCheckBoxes([
                    {
                      attr: 'checkAllCheckBoxes',
                      value: checkAllCheckBoxes,
                      label: I18n.t('BEE2385' /* Marcar ou Desmarcar todos */),
                    },
                    {
                      attr: 'pendingSign',
                      value: pendingSign,
                      label: I18n.t('BEE1783' /* Pendente de Placa */),
                    },
                    {
                      attr: 'signChecked',
                      value: signChecked,
                      label: I18n.t('BEE471' /* Conferência Placa Finalizada */),
                    },
                    {
                      attr: 'startedCheck',
                      value: startedCheck,
                      label: I18n.t('BEE473' /* Conferência Física Iniciada */),
                    },
                    {
                      attr: 'pendingCheck',
                      value: pendingCheck,
                      label: I18n.t('BEE472' /* Conferência Física Pendente */),
                    },
                    {
                      attr: 'finishedCheck',
                      value: finishedCheck,
                      label: I18n.t('BEE474' /* Conferência Física Finalizada */),
                    },
                    {
                      attr: 'storage',
                      value: storage,
                      label: I18n.t('BEE475' /* Em armazenagem */),
                    },
                    {
                      attr: 'stored',
                      value: stored,
                      label: I18n.t('BEE427' /* Armazenado */),
                    },
                    {
                      attr: 'canceled',
                      value: canceled,
                      label: I18n.t('BEE64' /* Cancelado */),
                    },
                  ], `${I18n.t('BEE1759' /* Status do Documento */)}:`),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
                )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
                )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport:
  (nameDoc, type, route, columns, filters) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMovementInboundOrdersItemsList);
