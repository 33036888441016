import ProductBranchesRequests from '../../server/productBranches';
import { addLoading, removeLoading } from './loading';

export const setProductBranches = (productBranchesList) => ({
  type: 'SET_PRODUCT_BRANCHES',
  productBranchesList,
});

export const getProductBranchesList = (tableParams) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const productBranches = await ProductBranchesRequests.getProductBranches(tableParams);
    dispatch(setProductBranches(productBranches ? productBranches.rows : []));
    dispatch(removeLoading());
    return productBranches || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const createProductBranch = (productBranch) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newProductBranch = await ProductBranchesRequests.createProductBranch(productBranch);
    return newProductBranch;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateProductBranch = (productBranch) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ProductBranchesRequests.updateProductBranch(productBranch);
  } finally {
    dispatch(removeLoading());
  }
};

export const getProductBranch = (productBranchId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ProductBranchesRequests.getProductBranch(productBranchId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteProductBranch = (productBranchId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ProductBranchesRequests.deleteProductBranch(productBranchId);
  } finally {
    dispatch(removeLoading());
  }
};
