import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { Badge } from 'reactstrap';
import Table from '../../../components/table/Table';

import ChangeVolumeRemovalUserModal from '../../../components/pages/changeVolumeRemovalUserModal';

import { getUsersOptions } from '../../../app/store/actions/users';

class TableDockVolumeRemoval extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDockVolumeRemoval: {},
      defaultPageSize: 5,
      pageSizeOptions: [5, 10, 15, 20, 25],
      defaultSorted: [{ id: 'id', desc: false }],
      showModalChangeUserRemoval: false,
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'outboundOrder.orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: ({ value }) => {
          let badgeColor;
          let badgeText;

          switch (value) {
            case 1:
              badgeColor = 'primary';
              badgeText = I18n.t('BEE470' /* Pendente */);
              break;
            case 2:
              badgeColor = 'warning';
              badgeText = I18n.t('BEE3810' /* Em Andamento */);
              break;
            case 3:
              badgeColor = 'success';
              badgeText = I18n.t('BEE1765' /* Concluído */);
              break;
            default:
              badgeColor = 'secondary';
              badgeText = I18n.t('BEE3811' /* Desconhecido */);
              break;
          }

          return <Badge color={badgeColor}>{badgeText}</Badge>;
        },
      }, {
        Header: I18n.t('BEE199' /* Doca */),
        accessor: 'dockControl.dockCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'dockControl.carrier.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE3764' /* Total de Volumes */),
        accessor: 'orderVolumeQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE3778' /* Volumes Removidos */),
        accessor: 'withdrawlVolumeQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE2261' /* Data de início */),
        accessor: 'withdrawlStartedAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: ({ value }) => (value ? moment(value).format('L LTS') : ''),
      }, {
        Header: I18n.t('BEE3867' /* Data de Finalização */),
        accessor: 'withdrawlFinisheddAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: ({ value }) => (value ? moment(value).format('L LTS') : ''),
      }, {
        Header: I18n.t('BEE2702' /* Responsável */),
        accessor: 'userRemoval.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 150,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button
              type="button"
              onClick={() => {
                this.onClickChangeUser();
              }}
              className="btn btn-primary"
            >
              {I18n.t('BEE2700' /* Alterar Responsável */)}
            </button>
          </div>
        ),
      }];
  }

  async componentDidMount() {
    await this.props.listDockVolumeRemoval();
  }

  async onClickChangeUser() {
    const usersList = await this.props.getUsersOptions();

    this.setState(
      {
        showModalChangeUserRemoval: true,
        usersList,
      },
    );
  }

  changeVolumeRemovalUser = async (confirm) => {
    if (confirm) {
      this.componentDidMount();
    }

    this.setState({
      showModalChangeUserRemoval: false,
    });
  };

  selectLine = (selectedDockVolumeRemoval) => {
    this.setState({ selectedDockVolumeRemoval });
    this.props.listOutboundVolume(selectedDockVolumeRemoval.outboundOrder.orderNumber);
  };

  render() {
    const {
      defaultPageSize, defaultSorted, pageSizeOptions, selectedDockVolumeRemoval,
    } = this.state;

    const {
      dockVolumeRemovalList = [],
    } = this.props;

    return (
      <>
        <Table
          panelHeaderProps={{
            noButton: false,
            children: I18n.t('BEE3812' /* Missões de Remoção de Volume */).toLocaleUpperCase(),
            onClickReload: this.props.listDockVolumeRemoval,
          }}
          filterable
          data={dockVolumeRemovalList}
          columns={this.tableColumns}
          expander
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          pageSizeOptions={pageSizeOptions}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: () => (this.selectLine(rowInfo.original)),
                style: {
                  background:
                  selectedDockVolumeRemoval.outboundOrder
                  && rowInfo.original.outboundOrder.orderNumber
                  === selectedDockVolumeRemoval.outboundOrder.orderNumber
                    ? 'silver'
                    : 'white',
                  cursor: 'pointer',
                },
              };
            } return {};
          }}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        <ChangeVolumeRemovalUserModal
          openModal={this.state.showModalChangeUserRemoval}
          confirm={(confirm) => this.changeVolumeRemovalUser(confirm)}
          usersList={this.state.usersList}
          row={this.state.selectedDockVolumeRemoval}
        />
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getUsersOptions: () => dispatch(getUsersOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableDockVolumeRemoval));
