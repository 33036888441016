import InventoriesErpRequests from '../../server/inventoriesErp';
import { addLoading, removeLoading } from './loading';

export const setInventoriesErp = (inventoriesErp) => ({
  type: 'SET_INVENTORY_ERP',
  inventoriesErp,
});

export const setListInventoriesErp = (listInventoriesErp) => ({
  type: 'SET_LIST_INVENTORY_ERP',
  listInventoriesErp,
});

export const getInventoriesErpList = (tableParams) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const inventoriesErp = await InventoriesErpRequests.getAllInventoriesErp(tableParams);
    dispatch(setInventoriesErp(inventoriesErp ? inventoriesErp.rows : []));
    dispatch(removeLoading());
    return inventoriesErp || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const createInventoryErp = (inventoryErp) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newInventoryErp = await InventoriesErpRequests.createInventoryErp(inventoryErp);
    return newInventoryErp;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateInventoryErp = (updInventoryErp) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoriesErpRequests.updateInventoryErp(updInventoryErp);
  } finally {
    dispatch(removeLoading());
  }
};

export const getInventoryErp = (inventoryErpId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoriesErpRequests.getInventoryErp(inventoryErpId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteInventoryErp = (inventoryErpId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoriesErpRequests.deleteInventoryErp(inventoryErpId);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateErpStockTable = (branchCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoriesErpRequests.updateErpStockTable(branchCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const getListInventoryErp = (branchCode, onlyDifference = false) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const inventoriesErp = await InventoriesErpRequests.getListInventoryErp(branchCode, onlyDifference);
    dispatch(setListInventoriesErp(inventoriesErp || []));
  } finally {
    dispatch(removeLoading());
  }
};
