import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import OneClickButton from '../../components/form/button';
import { PanelPage } from '../../components/pages/pages';
import DualList from '../../components/pages/DualList';
import Form from '../../components/form/form';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportAdminGeneralDock extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          { I18n.t('BEE12' /* Início */)}
        </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE14' /* Gerais */) },
      { value: I18n.t('BEE19' /* Docas */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE82' /* Código */),
        value: 'code',
      }, {
        label: I18n.t('BEE83' /* Nome */),
        value: 'name',
      }, {
        label: I18n.t('BEE145' /* Filial */),
        value: 'branchCode',
      }, {
        label: I18n.t('BEE156' /* Armazém */),
        value: 'warehouseCode',
      }, {
        label: I18n.t('BEE200' /* Tipo */),
        value: 'dockType',
      }, {
        label: I18n.t('BEE204' /* Situação */),
        value: 'status',
      }, {
        label: I18n.t('BEE135' /* Observação */),
        value: 'note',
      }, {
        label: I18n.t('BEE575' /* Usuário de criação */),
        value: 'createdUser',
      }, {
        label: I18n.t('BEE578' /* Data de criação */),
        value: 'createdAt',
      }, {
        label: I18n.t('BEE576' /* Usuário de alteração */),
        value: 'updatedUser',
      }, {
        label: I18n.t('BEE577' /* Data de alteração */),
        value: 'updatedAt',
      },
    ];

    this.state = {
      selected: [],
      codeFrom: '',
      codeTo: '',
      nameFrom: '',
      nameTo: '',
      branchCodeFrom: '',
      branchCodeTo: '',
      warehouseCodeFrom: '',
      warehouseCodeTo: '',
      input: '',
      output: '',
      both: '',
      released: '',
      busy: '',
      blocked: '',
    };
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      codeFrom,
      codeTo,
      nameFrom,
      nameTo,
      branchCodeFrom,
      branchCodeTo,
      warehouseCodeFrom,
      warehouseCodeTo,
      input,
      output,
      both,
      released,
      busy,
      blocked,
    } = this.state;
    const { location } = this.props;
    const dockType = [];
    const status = [];

    if (input) dockType.push(1);
    if (output) dockType.push(2);
    if (both) dockType.push(3);

    if (released) status.push(1);
    if (busy) status.push(2);
    if (blocked) status.push(3);

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['code', { type: 'between', value: [codeFrom, codeTo] }],
      ['name', { type: 'between', value: [nameFrom, nameTo] }],
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['warehouseCode', { type: 'between', value: [warehouseCodeFrom, warehouseCodeTo] }],
      ['dockType', { type: 'in', value: dockType }],
      ['status', { type: 'in', value: status }],
    ]);
    this.props.generateReport(I18n.t('BEE19' /* Docas */).toLowerCase(), 'xlsx', location.pathname, headers, filters);
  };

  onSelectFilter = (newState) => this.setState({ ...newState });

  render() {
    const {
      selected,
      codeFrom,
      codeTo,
      nameFrom,
      nameTo,
      branchCodeFrom,
      branchCodeTo,
      warehouseCodeFrom,
      warehouseCodeTo,
      input,
      output,
      both,
      released,
      busy,
      blocked,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE19' /* Docas */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: codeFrom, to: codeTo },
                    { from: 'codeFrom', to: 'codeTo' },
                    `${I18n.t('BEE82' /* Código */)}:`,
                    { from: '', to: 'Z'.repeat(100) },
                  ),
                  formContext.createInputRange(
                    { from: nameFrom, to: nameTo },
                    { from: 'nameFrom', to: 'nameTo' },
                    `${I18n.t('BEE83' /* Nome */)}:`,
                    { from: '', to: 'Z'.repeat(100) },
                  ),
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(100) },
                  ),
                  formContext.createInputRange(
                    { from: warehouseCodeFrom, to: warehouseCodeTo },
                    { from: 'warehouseCodeFrom', to: 'warehouseCodeTo' },
                    `${I18n.t('BEE156' /* Armazém */)}:`,
                    { from: '', to: 'Z'.repeat(100) },
                  ),
                  formContext.createCheckBoxes([
                    {
                      label: I18n.t('BEE201' /* Entrada */),
                      value: input,
                      attr: 'input',
                    }, {
                      label: I18n.t('BEE202' /* Saída */),
                      value: output,
                      attr: 'output',
                    }, {
                      label: I18n.t('BEE212' /* Ambas */),
                      value: both,
                      attr: 'both',
                    },
                  ], `${I18n.t('BEE200' /* Tipo */)}:`),
                  formContext.createCheckBoxes([
                    {
                      label: I18n.t('BEE205' /* Liberada */),
                      value: released,
                      attr: 'released',
                    }, {
                      label: I18n.t('BEE206' /* Ocupada */),
                      value: busy,
                      attr: 'busy',
                    }, {
                      label: I18n.t('BEE213' /* Bloqueada */),
                      value: blocked,
                      attr: 'blocked',
                    },
                  ], `${I18n.t('BEE204' /* Situação */)}:`),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <>
            <OneClickButton
              type="button"
              style={{ width: 'auto', padding: 10 }}
              className="btn btn-primary p-5 m-5"
              onClick={this.onGenerateSpreadsheet}
            >
              {I18n.t('BEE544' /* Gerar Planilha */)}
            </OneClickButton>
            {/*  <button
              type="button"
              style={{ width: 'auto', padding: 10 }}
              className="btn btn-secondary p-5 m-5"
              onClick={this.onGeneratePdf}
            >
              {I18n.t('BEE545'  Gerar PDF )}
            </button> */}
          </>
        )}
      />
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportAdminGeneralDock);
