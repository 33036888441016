/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from '../../../../components/form/button';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createIndustrialScale, updateIndustrialScale, getIndustrialScale,
} from '../../../../app/store/actions/industrialScales';

import Form from '../../../../components/form/form';

class IndustrialScaleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      identification: '',
      weighingRoom: '',
      portNumber: null,
      sequence: null,
      baudRate: null,
      dataBits: null,
      stopBits: null,
      parity: 'Even',
      parityList: [{ value: 'Even', label: 'Even' }],
      flowControl: 'Xoff',
      flowControlList: [{ value: 'Xoff', label: 'Xoff' }, { value: 'Xon', label: 'Xon' }],
      separator: '',
      packetSize: null,
      maxWeight: null,
      divisionFactor: null,
      readTimeout: null,
      writeTimeout: null,
      startPosRead: null,
      numCharacters: null,
      disableIdentification: true,
      disableWeighingRoom: true,
      disableSequence: true,
      disablePortNumber: true,
      disableBaudRate: true,
      disableDataBits: true,
      disableStopBits: true,
      disableParity: true,
      disableFlowControl: true,
      disableSeparator: true,
      disablePacketSize: true,
      disableMaxWeight: true,
      disableDivisionFactor: true,
      disableReadTimeout: true,
      disableWriteTimeout: true,
      disableStartPosRead: true,
      disableNumCharacters: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getIndustrialScaleSelected();
    }
  }

  getIndustrialScaleSelected = async () => {
    if (this.props.location.state.industrialScale) {
      const { industrialScale } = this.props.location.state;

      const industrialScaleSelected = await this.props.getIndustrialScale(industrialScale.id);

      if (industrialScaleSelected) {
        const availableParities = ['Even'];

        const parityOptions = availableParities.map((parity) => ({
          value: parity,
          label: parity,
        }));

        const updatedParityList = parityOptions;

        this.setState({
          industrialScaleId: industrialScale.id,
          identification: industrialScaleSelected.identification,
          weighingRoom: industrialScaleSelected.weighingRoom,
          portNumber: industrialScaleSelected.portNumber,
          baudRate: industrialScaleSelected.baudRate,
          dataBits: industrialScaleSelected.dataBits,
          stopBits: industrialScaleSelected.stopBits,
          parity: industrialScaleSelected.parity,
          sequence: industrialScaleSelected.sequence,
          flowControl: industrialScaleSelected.flowControl,
          separator: industrialScaleSelected.separator,
          packetSize: industrialScaleSelected.packetSize,
          maxWeight: industrialScaleSelected.maxWeight,
          divisionFactor: industrialScaleSelected.divisionFactor,
          readTimeout: industrialScaleSelected.readTimeout,
          writeTimeout: industrialScaleSelected.writeTimeout,
          startPosRead: industrialScaleSelected.startPosRead,
          numCharacters: industrialScaleSelected.numCharacters,
          parityList: updatedParityList,
        });
      }
    }
  };

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableIdentification: false,
        disableWeighingRoom: false,
        disableSequence: false,
        disablePortNumber: false,
        disableBaudRate: false,
        disableDataBits: false,
        disableStopBits: false,
        disableParity: false,
        disableFlowControl: false,
        disableSeparator: false,
        disablePacketSize: false,
        disableMaxWeight: false,
        disableDivisionFactor: false,
        disableReadTimeout: false,
        disableWriteTimeout: false,
        disableStartPosRead: false,
        disableNumCharacters: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableIdentification: true,
        disableWeighingRoom: false,
        disableSequence: false,
        disablePortNumber: false,
        disableBaudRate: false,
        disableDataBits: false,
        disableStopBits: false,
        disableParity: false,
        disableFlowControl: false,
        disableSeparator: false,
        disablePacketSize: false,
        disableMaxWeight: false,
        disableDivisionFactor: false,
        disableReadTimeout: false,
        disableWriteTimeout: false,
        disableStartPosRead: false,
        disableNumCharacters: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  createIndustrialScaleSubmit = async () => {
    const {
      identification,
      weighingRoom,
      sequence,
      portNumber,
      baudRate,
      dataBits,
      stopBits,
      parity,
      flowControl,
      separator,
      packetSize,
      maxWeight,
      divisionFactor,
      readTimeout,
      writeTimeout,
      startPosRead,
      numCharacters,
    } = this.state;

    try {
      const newIndustrialScale = await this.props.createIndustrialScale({
        identification,
        weighingRoom,
        sequence,
        portNumber,
        baudRate,
        dataBits,
        stopBits,
        parity,
        flowControl,
        separator,
        packetSize,
        maxWeight,
        divisionFactor,
        readTimeout,
        writeTimeout,
        startPosRead,
        numCharacters,
      });

      if (newIndustrialScale) {
        await addNotification(
          'success',
          I18n.t('BEE3855' /* Balança Industrial */),
          I18n.t('BEE3856', { 0: identification } /* Balança Industrial %{0} incluída com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.INDUSTRIAL_SCALE_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE3855' /* Balança */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE3855' /* Balança Industrial */),
            I18n.t('BEE3857', { 0: identification } /* Erro ao incluir a balança industrial %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3855' /* Balança Industrial */),
          I18n.t(
            'BEE3857',
            { 0: identification }, /* Erro ao incluir a balança industrial %{0}! */
          ),
          'top-right',
        );
      }
    }
  };

  updateIndustrialScaleSubmit = async () => {
    const {
      industrialScaleId,
      weighingRoom,
      sequence,
      portNumber,
      baudRate,
      dataBits,
      stopBits,
      parity,
      flowControl,
      separator,
      packetSize,
      maxWeight,
      divisionFactor,
      readTimeout,
      writeTimeout,
      startPosRead,
      numCharacters,
      identification,
    } = this.state;

    try {
      const updIndustrialScale = await this.props.updateIndustrialScale({
        industrialScaleId,
        weighingRoom,
        sequence,
        portNumber,
        baudRate,
        dataBits,
        stopBits,
        parity,
        flowControl,
        separator,
        packetSize,
        maxWeight,
        divisionFactor,
        readTimeout,
        writeTimeout,
        startPosRead,
        numCharacters,
      });

      if (updIndustrialScale) {
        await addNotification(
          'success',
          I18n.t('BEE3858' /* Atualizar Balança Industrial */),
          I18n.t('BEE3859', { 0: identification } /* Atualização da Balança Industrial %{0} efetuada com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.INDUSTRIAL_SCALE_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE3858' /* Atualizar Balança */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE3858' /* Atualizar Balança Industrial */),
            I18n.t('BEE3860', { 0: identification } /* Erro ao atualizar a balança industrial %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3858' /* Atualizar Balança Industrial */),
          I18n.t('BEE3860', { 0: identification } /* Erro ao atualizar a balança industrial %{0}! */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      identification,
      weighingRoom,
      sequence,
      portNumber,
      baudRate,
      dataBits,
      stopBits,
      parity,
      parityList,
      flowControlList,
      flowControl,
      separator,
      packetSize,
      maxWeight,
      divisionFactor,
      readTimeout,
      writeTimeout,
      startPosRead,
      numCharacters,
    } = this.state;

    const {
      mode,
      disableIdentification,
      disableWeighingRoom,
      disableSequence,
      disablePortNumber,
      disableBaudRate,
      disableDataBits,
      disableStopBits,
      disableParity,
      disableFlowControl,
      disableSeparator,
      disablePacketSize,
      disableMaxWeight,
      disableDivisionFactor,
      disableReadTimeout,
      disableWriteTimeout,
      disableStartPosRead,
      disableNumCharacters, showCancel, showCreate, showUpdate,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE3862' /* Nova Balança Industrial */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE3861' /* Editar Balança Industrial */);
      //   breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE3863' /* Detalhes Balança Industrial */);
      //   breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE1396' /* Equipamentos */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE3846' /* Impressoras */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE3862' /* Nova Balança Industrial */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  <Form
                    noPanel
                    setValue={this.setValue}
                    // setValueDrop={this.setValueDrop}
                    inputs={(formContext) => ([
                      formContext.createInput(
                        identification,
                        'identification',
                        `${I18n.t('BEE3847' /* Identificação */)}:`,
                        I18n.t('BEE3864' /* Informe a idenficação */),
                        'text',
                        true,
                        disableIdentification,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        30,
                      ),
                      formContext.createInput(
                        weighingRoom,
                        'weighingRoom',
                        `${I18n.t('BEE3854' /* Sala de Pesagem */)}:`,
                        I18n.t('BEE3868' /* Informe a sala de pesagem */),
                        'text',
                        true,
                        disableWeighingRoom,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        150,
                      ),
                      formContext.createNumberFormat(
                        sequence,
                        'sequence',
                        `${I18n.t('BEE494' /* Sequencia */)}:`,
                        0,
                        disableSequence,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        false,
                        true,
                        false,
                        false,
                        true,
                      ),
                      formContext.createNumberFormat(
                        portNumber,
                        'portNumber',
                        `${I18n.t('BEE3848' /* Número da porta */)}:`,
                        0,
                        disablePortNumber,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        false,
                        true,
                        false,
                        false,
                        true,
                      ),
                      formContext.createNumberFormat(
                        baudRate,
                        'baudRate',
                        `${I18n.t('BEE3870' /* Taxa de baud */)}:`,
                        0,
                        disableBaudRate,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        false,
                        true,
                        false,
                        false,
                        true,
                      ),
                      formContext.createNumberFormat(
                        dataBits,
                        'dataBits',
                        `${I18n.t('BEE3872' /* Bits de dados */)}:`,
                        0,
                        disableDataBits,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        false,
                        true,
                        false,
                        false,
                        true,
                      ),
                      formContext.createNumberFormat(
                        stopBits,
                        'stopBits',
                        `${I18n.t('BEE3874' /* Bits de parada */)}:`,
                        0,
                        disableStopBits,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        false,
                        true,
                        false,
                        false,
                        true,
                      ),
                      formContext.createSelect(
                        parity,
                        'parity',
                        `${I18n.t('BEE3876' /* Paridade */)}:`,
                        parityList,
                        disableParity,
                        undefined,
                        undefined,
                        undefined,
                        true,
                      ),
                      formContext.createSelect(
                        flowControl,
                        'flowControl',
                        `${I18n.t('BEE3849' /* Controle de fluxo */)}:`,
                        flowControlList,
                        disableFlowControl,
                        undefined,
                        undefined,
                        undefined,
                        true,
                      ),
                      formContext.createInput(
                        separator,
                        'separator',
                        `${I18n.t('BEE1092' /* Separador */)}:`,
                        I18n.t('BEE3879' /* Informe o separador. */),
                        'text',
                        true,
                        disableSeparator,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        30,
                      ),
                      formContext.createNumberFormat(
                        packetSize,
                        'packetSize',
                        `${I18n.t('BEE3880' /* Tamanho do pacote */)}:`,
                        0,
                        disablePacketSize,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        false,
                        true,
                        false,
                        false,
                        true,
                      ),
                      formContext.createNumberFormat(
                        maxWeight,
                        'maxWeight',
                        `${I18n.t('BEE3882' /* Peso máximo */)}:`,
                        0,
                        disableMaxWeight,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        false,
                        true,
                        false,
                        false,
                        true,
                      ),
                      formContext.createInputMask(
                        divisionFactor,
                        'divisionFactor',
                        `${I18n.t('BEE3884' /* Fator de divisão */)}:`,
                        I18n.t('BEE3885' /* Informe o fator de divisão */),
                        '99.99999999999',
                        true,
                        disableDivisionFactor,
                      ),
                      formContext.createNumberFormat(
                        readTimeout,
                        'readTimeout',
                        `${I18n.t('BEE3886' /* Tempo de leitura */)}:`,
                        0,
                        disableReadTimeout,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        false,
                        true,
                        false,
                        false,
                        true,
                      ),
                      formContext.createNumberFormat(
                        writeTimeout,
                        'writeTimeout',
                        `${I18n.t('BEE3888' /* Tempo de escrita */)}:`,
                        0,
                        disableWriteTimeout,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        false,
                        true,
                        false,
                        false,
                        true,
                      ),
                      formContext.createNumberFormat(
                        startPosRead,
                        'startPosRead',
                        `${I18n.t('BEE3890' /* Posição inicial de leitura */)}:`,
                        0,
                        disableStartPosRead,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        false,
                        true,
                        false,
                        false,
                        true,
                      ),
                      formContext.createNumberFormat(
                        numCharacters,
                        'numCharacters',
                        `${I18n.t('BEE3892' /* Número de caracteres */)}:`,
                        0,
                        disableNumCharacters,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        false,
                        true,
                        false,
                        false,
                        true,
                      ),
                    ])}
                  />
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.INDUSTRIAL_SCALE_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                          { I18n.t('BEE137' /* Voltar */)}
                        </OneClickButton>
                      ))}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createIndustrialScaleSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}
                    </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateIndustrialScaleSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}
                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getIndustrialScale: (industrialScaleId) => dispatch(getIndustrialScale(industrialScaleId)),
  createIndustrialScale: (industrialScale) => dispatch(createIndustrialScale(industrialScale)),
  updateIndustrialScale: (industrialScale) => dispatch(updateIndustrialScale(industrialScale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IndustrialScaleForm));
