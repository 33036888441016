import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import List from './list';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';

class OutboundPendingAllocationMonitor extends PureComponent {
  render() {
    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1857' /* Monitor de Pendências de Alocação */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE1857' /* Monitor de Pendências de Alocação */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.PENDING_ALLOCATION_MONITOR_HELP} />
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <List />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundPendingAllocationMonitor));
