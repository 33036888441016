import RestService from '../services/rest';

// GET
async function getSchedules() {
  return RestService.getAuthenticated('schedules/list/all');
}

async function getSchedule(scheduleId) {
  return RestService.getAuthenticated(`schedules/detail?scheduleId=${scheduleId}`);
}

// POST
async function createSchedule(schedule) {
  return RestService.postAuthenticated('schedules/create', schedule);
}

async function deleteSchedule(scheduleId) {
  return RestService.postAuthenticated('schedules/delete', { scheduleId });
}

// PUT
async function updateSchedule(schedule) {
  return RestService.putAuthenticated('schedules/update', schedule);
}

export default {
  getSchedules,
  createSchedule,
  updateSchedule,
  getSchedule,
  deleteSchedule,
};
