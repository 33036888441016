import CustomerProductsRequests from '../../server/customerProducts';
import { addLoading, removeLoading } from './loading';

export const setCustomerProducts = (customerProducts) => ({
  type: 'SET_CUSTOMER_PRODUCTS',
  customerProducts,
});

export const getCustomerProductsList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const customerProducts = await CustomerProductsRequests.getCustomerProducts(tableParams);
    dispatch(setCustomerProducts(customerProducts ? customerProducts.rows : []));
    dispatch(removeLoading());
    return customerProducts || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const createCustomerProduct = (newCustomerProduct) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newProductCustomerProduct = await CustomerProductsRequests.createCustomerProduct(newCustomerProduct);
    return newProductCustomerProduct;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateCustomerProduct = (updCustomerProduct) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CustomerProductsRequests.updateCustomerProduct(updCustomerProduct);
  } finally {
    dispatch(removeLoading());
  }
};

export const getCustomerProduct = (customerProductId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CustomerProductsRequests.getCustomerProduct(customerProductId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteCustomerProduct = (customerProductId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CustomerProductsRequests.deleteCustomerProduct(customerProductId);
  } finally {
    dispatch(removeLoading());
  }
};
