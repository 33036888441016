import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';
import Permissions from '../../../app/services/permissions';

import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';

import OutboundOrderHelpers from '../../../helpers/outboundOrder';

import addNotification from '../../../components/notification';
import UserConfirmationModal from '../../../components/pages/userConfirmationModal';
import {
  cancelOutboundOrder, cancelOutboundProduct, getOutboundOrderListJustDocument,
  getOutboundOrderSerials,
  updateOutboundOrderAndFinally,
} from '../../../app/store/actions/outboundOrdersIndustry';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import FormatHelpers from '../../../helpers/format';
import ModalOutboundOrder from './modalOutboundOrder';

class OutboundMonitorIndustry extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedDocumentType: null,
      openModalOutboundOrder: false,
      isOutboundOrder: false,
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
      typeDoc: '',
      showCancelModal: false,
      showCompleteModal: false,
      showCancelProductModal: false,
      selectedOutboundOrder: {},
      selectedOutboundProduct: null,
      showUserModal: false,
      defaultSortedProducts: [{
        id: 'lineNumber',
        desc: false,
      }],
      defaultSorted: [{
        id: 'pickStartAt',
        desc: true,
      }],
    };
    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => {
          const statusOutboundOrder = rows.original.status;
          return (
            <div style={{ textAlign: 'center' }}>
              <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
              <UncontrolledButtonDropdown>
                <DropdownToggle color="default" caret className="btn-xs" />
                {!Permissions.checkPermission(ROUTES.P_CANCEL_OUTBOUND_INDUSTRY_ORDER) && (
                  <DropdownMenu>
                    {![10, 11, 12, 13].includes(statusOutboundOrder) && (
                      <DropdownItem onClick={() => this.showCancelOrder(rows.original)}>
                        {I18n.t('BEE99' /* Cancelar */)}
                      </DropdownItem>
                    )}
                    {![9, 10, 11, 12, 13].includes(statusOutboundOrder) && (
                      <DropdownItem onClick={() => this.showCompleteOrder(rows.original)}>
                        {I18n.t('BEE2942' /* Finalizar o Documento */)}
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                )}
              </UncontrolledButtonDropdown>
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2997' /* Tipo de Documento */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE402' /* Nota Fiscal */),
        accessor: 'invoiceNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{OutboundOrderHelpers.outboundOrderStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OutboundOrderHelpers.outboundOrderStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE2826' /* Data / Hora Abertura */),
        accessor: 'pickStartAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);

          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY HH mm ss');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYYHHmmss');

          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2074' /* Usuário Abertura */),
        accessor: 'pickStartUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
        Cell: (rows) => (
          <div className={{ textAlign: 'center' }}>
            <span>
              {rows.original.pickStartUser ? rows.original.pickStartUser : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE2825' /* Data / Hora Fechamento */),
        accessor: 'pickEndAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);

          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY HH mm ss');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYYHHmmss');

          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2075' /* Usuário Fechamento */),
        accessor: 'pickEndUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
        Cell: (rows) => (
          <div className={{ textAlign: 'center' }}>
            {rows.original.pickEndUser ? rows.original.pickEndUser : ''}
          </div>
        ),
      },
    ];

    this.productTableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              {!Permissions.checkPermission(ROUTES.P_CANCEL_OUTBOUND_INDUSTRY_ORDER) && (
                <DropdownMenu>
                  {[4, 5, 6].includes(rows.original.status) && (
                    <DropdownItem onClick={() => this.showCancelProduct(rows.original)}>
                      Cancelar Produto
                    </DropdownItem>
                  )}
                </DropdownMenu>
              )}
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE2764' /* Etiqueta Agrupadora */),
        accessor: 'serialGroup',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1897' /* Descrição */),
        accessor: 'productName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{OutboundOrderHelpers.outboundOrderProductStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OutboundOrderHelpers.outboundOrderProductStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE2245' /* Validade */),
        accessor: 'expirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('DD/MM/YYYY') : ''}
          </span>
        ),
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE579' /* Peso */),
        accessor: 'grossWeight',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      },
    ];
  }

  componentDidMount() {
    this.getOutboundOrders();
  }

  handleSelectOutboundOrder = (selectedType) => {
    this.setState({ selectedDocumentType: selectedType });
  };

  showUserModalProps = () => {
    this.setState({ showUserModal: true, typeDoc: 'complete', isOutboundOrder: true });
  };

  updateOutboundOrderAndFinally = async () => {
    try {
      const { selectedDocumentType, selectedOutboundOrder } = this.state;

      if (!selectedDocumentType || !selectedDocumentType.type) {
        addNotification(
          'warning',
          I18n.t('BEE2997' /* Tipo de Documento */),
          I18n.t('BEE3002' /* Por favor, selecione um tipo de documento. */),
          'top-right',
        );
        return;
      }

      const { orderType } = selectedOutboundOrder;

      if (orderType) {
        const outboundOrderId = selectedOutboundOrder.id;
        const result = await this.props.updateOutboundOrderAndFinally(outboundOrderId, orderType);

        if (result.success !== false) {
          this.setState({ openModalOutboundOrder: false });
          addNotification(
            'success',
            I18n.t('BEE2997' /* Tipo de Documento */),
            I18n.t('BEE2918' /* Cadastro realizado com sucesso. */),
            'top-right',
          );
          await this.getOutboundOrders();
        }
      }
    } catch (error) {
      addNotification(
        'danger',
        I18n.t('BEE2997' /* Tipo de Documento */),
        I18n.t('BEE2916' /* Erro no cadastro */),
        'top-right',
      );
    }
  };

  getOutboundOrderProduct = async () => {
    const { selectedOutboundOrder } = this.state;

    try {
      await this.props.getOutboundOrderSerials(selectedOutboundOrder.id);
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification('danger', I18n.t('BEE2829' /* Documento de Saída - Indústria */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2829' /* Documento de Saída - Indústria */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  getOutboundOrders = async () => {
    const { startDate, endDate, selectedOutboundOrder } = this.state;

    await this.props.getOutboundOrderListJustDocument(startDate, endDate);

    const orderList = this.props.outboundOrdersList;

    const existOrder = (orderList && orderList.length && Object.keys(selectedOutboundOrder).length)
      ? (orderList.find((element) => (element.id === selectedOutboundOrder.id)))
      : {};

    await this.setState({
      selectedOutboundOrder: existOrder || {},
    });

    if (existOrder && Object.keys(existOrder).length) {
      await this.getOutboundOrderProduct();
    }
  };

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (
          event.target.value === ''
          && (event.keyCode === 8 || event.which === 8)
        ) {
          onChange(event.target.value);
        }
      }}
    />
  );

  showCancelOrder = () => {
    this.setState({
      showCancelModal: true,
      typeDoc: 'order',
    });
  };

  showCompleteOrder = () => {
    this.setState({
      showCompleteModal: true,
      typeDoc: 'complete',
    });
  };

  showCancelProduct = (outboundProduct) => {
    this.setState({
      showCancelProductModal: true,
      typeDoc: 'product',
      selectedOutboundProduct: outboundProduct,
    });
  };

  cancelOrder = async () => {
    try {
      const { selectedOutboundOrder } = this.state;

      const result = await this.props.cancelOutboundOrder(selectedOutboundOrder.id);
      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2829' /* Documento de Saída - Indústria */),
          I18n.t('BEE2948' /* Erro ao cancelar Documento de Saída */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2829' /* Documento de Saída - Indústria */),
          I18n.t('BEE2949' /* Documento cancelado com sucesso! */),
          'top-right',
        );
        this.getOutboundOrders();
      }
    } catch (error) {
      const messageError = error.response
        && error.response.data && error.response.data.error && error.response.data.error.message;

      if (messageError) {
        addNotification(
          'danger',
          I18n.t('BEE2829' /* Documento de Saída - Indústria */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2829' /* Documento de Saída - Indústria */),
          I18n.t('BEE2950' /* Não foi possível cancelar o Documento de Saída. */),
          'top-right',
        );
      }
    }
  };

  cancelProduct = async () => {
    try {
      const { selectedOutboundProduct, selectedOutboundOrder } = this.state;

      const result = await this.props.cancelOutboundProduct(
        selectedOutboundOrder.id,
        selectedOutboundProduct.id,
        selectedOutboundProduct.serialGroup,
      );

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2829' /* Documento de Saída - Indústria */),
          I18n.t('BEE2952' /* Erro ao cancelar Produto */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2829' /* Documento de Saída - Indústria */),
          I18n.t('BEE2953' /* Produto cancelado com sucesso! */),
          'top-right',
        );

        this.getOutboundOrders();
      }
    } catch (error) {
      const messageError = error.response
        && error.response.data && error.response.data.error && error.response.data.error.message;

      if (messageError) {
        addNotification(
          'danger',
          I18n.t('BEE2829' /* Documento de Saída - Indústria */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2829' /* Documento de Saída - Indústria */),
          I18n.t('BEE2954' /* Não foi possível cancelar o Produto */),
          'top-right',
        );
      }
    }
  };

  handleDateApplyEvent = async (event) => {
    const startDate = event[0];
    const endDate = event[1];

    await this.setState({
      startDate,
      endDate,
    });

    this.getOutboundOrders();
  };

  userConfirmation = async (confirm) => {
    const { typeDoc, isOutboundOrder } = this.state;

    if (isOutboundOrder) {
      this.setState({ showUserModal: false });
      if (confirm && typeDoc === 'complete') await this.updateOutboundOrderAndFinally();
    }

    this.setState({ showUserModal: false });
    if (confirm && typeDoc === 'order') this.cancelOrder();
    if (confirm && typeDoc === 'product') this.cancelProduct();
  };

  selectedProducts = async (selectedOutboundOrder) => {
    if (!this.state.selectedOutboundOrder || (selectedOutboundOrder && this.state.selectedOutboundOrder
      && this.state.selectedOutboundOrder.id !== selectedOutboundOrder.id)) {
      await this.setState({ selectedOutboundOrder: { ...selectedOutboundOrder } });
      await this.getOutboundOrderProduct();
    }
  };

  render() {
    const { outboundOrdersList = [], outboundOrderProductsList = [] } = this.props;

    const {
      showCancelModal, showCancelProductModal, selectedOutboundOrder, defaultSorted,
      defaultSortedProducts, showCompleteModal,
    } = this.state;

    return (
      <>
        <div>
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              <li className="breadcrumb-item">
                <i className="fas fa-home fa-fw m-t-10 m-r-5" />
                {' '}
                {I18n.t('BEE12' /* Início */)}
              </li>
              <li className="breadcrumb-item">
                {I18n.t('BEE1400' /* Movimentação */)}
              </li>
              <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
              <li className="breadcrumb-item active">{I18n.t('BEE2829' /* Documento de Saída - Indústria */)}</li>
            </ol>
          </div>
          <div className="d-flex align-items-center mb-md-3 mb-2">
            <h1 className="page-header mb-0">
              {I18n.t('BEE2829' /* Documento de Saída - Indústria */)}
            </h1>
            <WikiHelp wikiPath={ROUTES.OUTBOUND_INDUSTRY_ORDER_LIST_HELP} />
          </div>
          <Table
            downloadCSV
            panelHeaderProps={{
              onClickReload: this.getOutboundOrders,
            }}
            datePicker={{
              handleDateApplyEvent: this.handleDateApplyEvent,
            }}
            filterable
            data={outboundOrdersList}
            columns={this.tableColumns}
            expander
            defaultPageSize={5}
            defaultSorted={defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: () => {
                    this.selectedProducts(rowInfo.original);
                  },
                  style: {
                    background: selectedOutboundOrder && rowInfo.original.id === selectedOutboundOrder.id
                      ? 'silver'
                      : 'white',
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
          />
        </div>
        <Table
          downloadCSV
          panelHeaderProps={{
            children: I18n.t('BEE27' /* Produtos */).toUpperCase(),
          }}
          filterable
          data={outboundOrderProductsList}
          columns={this.productTableColumns}
          expander
          defaultPageSize={10}
          pageSizeOptions={[5, 10, 15]}
          defaultSorted={defaultSortedProducts}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        <UserConfirmationModal
          openModal={this.state.showUserModal}
          confirm={(confirm) => this.userConfirmation(confirm)}
        />
        {(showCompleteModal
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1849' /* continuar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE2942' /* Finalizar o Documento */)}
              onConfirm={() => this.setState({
                showCompleteModal: false,
                typeDoc: 'complete',
                openModalOutboundOrder: true,
              })}
              onCancel={() => this.setState({ showCompleteModal: false, typeDoc: '' })}
            >
              {I18n.t('BEE2947' /* Documento de saída será finalizado! Deseja continuar? */)}
            </SweetAlert>
          )
        )}
        {(showCancelModal
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1849' /* continuar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE2943' /* Cancelar Documento */)}
              onConfirm={() => this.setState({ showUserModal: true, showCancelModal: false, typeDoc: 'order' })}
              onCancel={() => this.setState({ showCancelModal: false, typeDoc: '' })}
            >
              {I18n.t('BEE2946' /* Documento de saída será cancelado! Deseja continuar? */)}
            </SweetAlert>
          )
        )}
        {(showCancelProductModal
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1849' /* continuar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE2944' /* Cancelar Produto */)}
              onConfirm={() => this.setState({
                showUserModal: true,
                showCancelProductModal: false,
                typeDoc: 'product',
              })}
              onCancel={() => this.setState({ showCancelProductModal: false, typeDoc: 'product' })}
            >
              {I18n.t('BEE2945' /* Produto será cancelado! Deseja continuar? */)}
            </SweetAlert>
          )
        )}

        <ModalOutboundOrder
          openModalOutboundOrder={this.state.openModalOutboundOrder}
          closeModalOutboundOrder={() => this.setState({
            openModalOutboundOrder: false,
          })}
          orderType={this.state.selectedOutboundOrder.orderType}
          orderId={this.state.selectedOutboundOrder.id}
          selectedOutboundOrderConcussion={this.showUserModalProps}
          onSelectOutboundOrder={this.handleSelectOutboundOrder}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  outboundOrdersList: state.outboundOrdersIndustry && state.outboundOrdersIndustry.outboundList,
  outboundOrderProductsList: state.outboundOrdersIndustry && state.outboundOrdersIndustry.outboundProductsList,
  userLogged: state.app.userLogged && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getOutboundOrderListJustDocument: (
    startDate,
    endDate,
  ) => dispatch(getOutboundOrderListJustDocument(startDate, endDate)),
  getOutboundOrderSerials: (outboundOrderId) => dispatch(getOutboundOrderSerials(outboundOrderId)),
  cancelOutboundOrder: (outboundOrderId) => dispatch(cancelOutboundOrder(outboundOrderId)),
  cancelOutboundProduct: (
    outboundOrderId,
    outboundOrderProductId,
    outboundProductSerialGroup,
  ) => dispatch(cancelOutboundProduct(outboundOrderId, outboundOrderProductId, outboundProductSerialGroup)),
  updateOutboundOrderAndFinally: (outboundOrderId, orderType) => dispatch(
    updateOutboundOrderAndFinally(outboundOrderId, orderType),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundMonitorIndustry));
