import React from 'react';
import { Link } from 'react-router-dom';
import DropdownNotification from './dropdown/notification';
import DropdownLanguage from './dropdown/language';
import DropdownProfile from './dropdown/profile';
import SearchForm from './search/form';
import DropdownMegaMenu from './dropdown/mega';

import { PageSettings } from '../../config/page-settings';
import serverData from '../../data/server';

const environments = {
  '10.4.0.47:8080': 'AMBIENTE DE HOMOLOGAÇÃO',
  '10.4.0.47:8082': 'AMBIENTE DE DESENVOLVIMENTO',
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMegaMenu = this.toggleMegaMenu.bind(this);
    this.state = {
      collapseMegaMenu: false,
      beehost: '',
    };
  }

  componentDidMount() {
    this.renderPosts();
  }

  renderPosts = async () => {
    try {
      this.setState({
        beehost: await serverData.getDisplayName(),
      });
    } catch (err) {}
  };

  toggleMegaMenu = () => {
    this.setState((prevState) => ({ collapseMegaMenu: !prevState.collapseMegaMenu }));
  };

  render() {
    const server = environments[window.location.host] || '';
    const { beehost } = this.state;
    return (
      <PageSettings.Consumer>
        {({
          toggleMobileSidebar,
          toggleRightSidebar,
          toggleMobileRightSidebar,
          toggleMobileTopMenu,
          pageHeaderLanguageBar,
          pageHeaderMegaMenu,
          pageTwoSidebar,
          pageTopMenu,
          pageSidebar,
        }) => (
          <div id="header" className="header navbar-default bg-gradient-orange">
            <div className="navbar-header">
              {pageTwoSidebar && (
              <button
                type="button"
                className="navbar-toggle pull-left"
                onClick={toggleMobileRightSidebar}
                data-testid="page-two-sidebar-toggle-mobile"
              >
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              )}
              <div className="navbar-brand">
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <b className="logo-font text-white f-s-20">Bee</b>
                  <b className="logo-font text-black f-s-20">Stock</b>
                  <img
                    src="/assets/img/fav-icon.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top ml-1"
                    alt=""
                  />
                </Link>
                <b style={{ color: '#ff0000' }} className="logo-font f-s-20 ml-5">{beehost}</b>
              </div>
              {pageHeaderMegaMenu && (
              <button
                type="button"
                className="navbar-toggle pt-0 pb-0 mr-0"
                onClick={this.toggleMegaMenu}
                data-testid="page-header-mega-menu-button"
              >
                <span className="fa-stack fa-lg text-inverse">
                  <i className="far fa-square fa-stack-2x" />
                  <i className="fa fa-cog fa-stack-1x" />
                </span>
              </button>
              )}
              {pageTopMenu && pageSidebar && (
              <button
                type="button"
                className="navbar-toggle pt-0 pb-0 mr-0 collapsed"
                onClick={toggleMobileTopMenu}
                data-testid="page-top-menu-sidebar-btn"
              >
                <span className="fa-stack fa-lg text-inverse">
                  <i className="far fa-square fa-stack-2x" />
                  <i className="fa fa-cog fa-stack-1x" />
                </span>
              </button>
              )}
              {!pageSidebar && pageTopMenu && (
              <button
                type="button"
                className="navbar-toggle"
                onClick={toggleMobileTopMenu}
                data-testid="page-top-menu-sidebar-btn"
              >
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              )}
              {pageSidebar && (
              <button type="button" className="navbar-toggle" onClick={toggleMobileSidebar}>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              )}
            </div>

            <h1 className="text-red bg-black-darker text-center m-auto">{server}</h1>

            {pageHeaderMegaMenu && (
            <DropdownMegaMenu collapse={this.state.collapseMegaMenu} />
            )}

            <ul className="navbar-nav navbar-right">
              <SearchForm />
              <DropdownNotification />

              {pageHeaderLanguageBar && (
              <DropdownLanguage />
              )}

              <DropdownProfile />

              {pageTwoSidebar && (
              <li className="divider d-none d-md-block" />
              )}

              {pageTwoSidebar && (
              <li className="d-none d-md-block">
                <Link to="/" onClick={toggleRightSidebar} className="f-s-14" data-testid="page-two-sidebar-link">
                  <i className="fa fa-th" />
                </Link>
              </li>
              )}
            </ul>
          </div>
        )}
      </PageSettings.Consumer>
    );
  }
}

export default Header;
