import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import {
  checkVolumes,
  createDockControl,
  finalizeCheckVolumes,
  getOpenConference,
  getOutboundOrderDockControl,
  getOutboundOrdersChecked,
} from '../../../app/store/actions/dockControl';
import { getUserPrinterOptions } from '../../../app/store/actions/printers';
import OneClickButton from '../../../components/form/button';
import Form from '../../../components/form/form';
import addNotification from '../../../components/notification';
import { PanelPage } from '../../../components/pages/pages';
import DocumentsTable from './documentsTable';
import ROUTES from '../../../config/routes';
import { printConsolidationsLabel } from '../../../app/store/actions/labels';

class OutboundDocks extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE19' /* Docas */), active: true },
    ];

    this.state = {
      dockCode: '',
      carrierCode: '',
      carrierBusinessName: '',
      outboundVolumeId: '',
      dockControlId: '',
      dockCodeDisabled: false,
      carrierCodeDisabled: true,
      outboundVolumeIdDisabled: true,
      showWarningDialog: false,
      showPrinterDialog: false,
      showPrintConsolidationDialog: false,
      outboundOrder: [],
      printerOptions: [],
      printerSelected: null,
      totalVolumes: 0,
    };
  }

  async componentDidMount() {
    const { printerSelected } = this.state;

    const printerReq = await this.props.getUserPrinterOptions();
    const { printerOptions, userPrinter } = printerReq;

    this.setState({
      printerOptions,
      printerSelected: (printerSelected !== null && printerSelected !== userPrinter)
        ? printerSelected
        : userPrinter,
    });
  }

  setValue = async (attr, value, reset) => {
    this.setState({
      [`${attr}`]: value,
    });
    if ((attr === 'dockCode' || attr === 'carrierCode') && reset) {
      this.restoreState();
    }
  };

  keypressConfirmPicking = (e, id, attr) => {
    if (e.keyCode === 13) {
      const keyPressFunction = {
        dockCode: this.getOpenConference,
        outboundVolumeId: this.checkVolumes,
      };
      keyPressFunction[`${attr}`]();
    }
  };

  restoreState = () => {
    this.setState({
      dockCode: '',
      carrierCode: '',
      carrierBusinessName: '',
      outboundVolumeId: '',
      dockControlId: '',
      dockCodeDisabled: false,
      carrierCodeDisabled: true,
      outboundVolumeIdDisabled: true,
      showWarningDialog: false,
      showPrinterDialog: false,
      showPrintConsolidationDialog: false,
      totalVolumes: 0,
      outboundOrder: [],
    });
  };

  getOpenConference = async () => {
    const { dockCode } = this.state;

    if (!dockCode) {
      addNotification(
        'danger',
        I18n.t('BEE19' /* Docas */),
        I18n.t('BEE1926' /* Informe a Doca */),
        'top-right',
      );
      document.getElementById(1).focus();
      document.getElementById(1).select();
    } else {
      try {
        const dockControlOpen = await this.props.getOpenConference(dockCode);

        if (dockControlOpen && dockControlOpen.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE19' /* Docas */),
            I18n.t('BEE1958' /* Ocorreu um erro ao localizar os dados tente mais tarde */),
            'top-right',
          );
        } else if (!dockControlOpen) {
          this.setState({
            dockCodeDisabled: true,
            carrierCodeDisabled: true,
            outboundVolumeIdDisabled: false,
          });
          document.getElementById(2).focus();
          document.getElementById(2).select();
        } else {
          this.setState({
            carrierCode: dockControlOpen.carrierCode,
            carrierBusinessName: dockControlOpen.carrierBusinessName,
            dockCodeDisabled: true,
            carrierCodeDisabled: true,
            dockControlId: dockControlOpen.dockControlId,
            outboundVolumeIdDisabled: false,
          });
          document.getElementById(2).focus();
          document.getElementById(2).select();
          await this.getOutboundOrdersChecked();
        }
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && error.message) {
          const messageError = (error.details)
            ? `${error.code} - ${error.details} | ${error.message}`
            : error.message;

          addNotification(
            'danger',
            I18n.t('BEE19' /* Docas */),
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE19' /* Docas */),
            I18n.t('BEE1958' /* Ocorreu um erro ao localizar os dados tente mais tarde */),
            'top-right',
          );
        }
      }
    }
  };

  getOutboundOrdersChecked = async () => {
    const { dockControlId } = this.state;

    try {
      const outboundOrder = await this.props.getOutboundOrdersChecked(dockControlId);

      if (outboundOrder && outboundOrder.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE19' /* Docas */),
          I18n.t('BEE1959' /* Não foi possível localizar o documento conferido */),
          'top-right',
        );
      } else {
        this.setState({ outboundOrder });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification(
          'danger',
          I18n.t('BEE19' /* Docas */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE19' /* Docas */),
          I18n.t('BEE1959' /* Não foi possível localizar o documento conferido */),
          'top-right',
        );
      }
    }
  };

  checkVolumes = async () => {
    const { carrierCode, outboundVolumeId, dockCode } = this.state;

    if (!outboundVolumeId) {
      addNotification(
        'danger',
        I18n.t('BEE19' /* Docas */),
        I18n.t('BEE1924' /* Informe a etiqueta volume */),
        'top-right',
      );
      document.getElementById(2).focus();
      document.getElementById(2).select();
    } else if (!this.state.dockControlId) {
      try {
        const createDock = await this.props.createDockControl(dockCode, outboundVolumeId, carrierCode);
        if (createDock && createDock.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE19' /* Docas */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */),
            'top-right',
          );
        } else {
          this.setState({
            dockCodeDisabled: true,
            carrierCodeDisabled: true,
            dockControlId: createDock.dockControlId,
            outboundVolumeIdDisabled: false,
            carrierCode: createDock.carrierCode,
            carrierBusinessName: createDock.carrierBusinessName,
          });
        }
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && error.message) {
          const messageError = (error.details)
            ? `${error.code} - ${error.details} | ${error.message}`
            : error.message;

          addNotification(
            'danger',
            I18n.t('BEE19' /* Docas */),
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE19' /* Docas */),
            I18n.t('BEE1961' /* Não foi possível iniciar o processo */),
            'top-right',
          );
        }
      }
    }
    if (outboundVolumeId && this.state.dockControlId) {
      try {
        const checkVolume = await this.props.checkVolumes(
          this.state.dockControlId,
          outboundVolumeId,

          this.state.carrierCode,

          dockCode,
        );

        if (checkVolume && checkVolume.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE19' /* Docas */),
            I18n.t('BEE1937' /* erro ao localizar o volume */),
            'top-right',
          );
        } else {
          addNotification(
            'success',
            I18n.t('BEE19' /* Docas */),
            I18n.t('BEE1941' /* Volume Conferido */),
            'top-right',
          );
          this.setState({ outboundVolumeId: '' });
          await this.getOutboundOrdersChecked();
        }
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && error.message) {
          const messageError = (error.details)
            ? `${error.code} - ${error.details} | ${error.message}`
            : error.message;

          addNotification('danger', I18n.t('BEE19' /* Docas */), messageError, 'top-right');
        } else {
          addNotification(
            'danger',
            I18n.t('BEE19' /* Docas */),
            I18n.t('BEE1937' /* erro ao localizar o volume */),
            'top-right',
          );
        }
      }
    }
  };

  onSelectFilterModal = () => {
    const { printerSelected, outboundOrder } = this.state;

    this.setState({ showPrinterDialog: false });

    const outboundOrderId = [];

    outboundOrder.forEach((element) => {
      outboundOrderId.push(element.outboundOrderId);
    });

    this.props.printConsolidationsLabel(printerSelected, outboundOrderId, false);

    addNotification(
      'success',
      I18n.t('BEE3375' /* Etiqueta de Consolidação */),
      I18n.t('BEE3228' /* Etiquetas enviadas para impressora! */),
      'top-right',
    );

    this.restoreState();
  };

  onDoPrintConsolidation = async () => {
    const { printerOptions } = this.state;

    if (!printerOptions || printerOptions.length === 0) {
      this.restoreState();
      addNotification(
        'danger',
        I18n.t('BEE19' /* Docas */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
      return;
    }

    if (printerOptions && printerOptions.length > 1) {
      this.setState({
        showPrintConsolidationDialog: false,
        showPrinterDialog: true,
      });
    } else {
      await this.setState({
        showPrintConsolidationDialog: false,
        showPrinterDialog: false,
        printerSelected: printerOptions[0].value,
      });

      await this.onSelectFilterModal();
    }
  };

  finalizeCheckVolumes = async () => {
    const { userLogged } = this.props;
    const { dockControlId, outboundOrder } = this.state;
    try {
      const result = await this.props.finalizeCheckVolumes(dockControlId);

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE19' /* Docas */),
          I18n.t('BEE1852' /* Não foi possível finalizar esta ação */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE19' /* Docas */),
          I18n.t('BEE1766' /* Finalizado */),
          'top-right',
        );

        if (result.waybillNumber) {
          addNotification(
            'success',
            I18n.t('BEE19' /* Docas */),
            I18n.t('BEE3372', { 0: result.waybillNumber } /* Romaneio %{0} gerado com sucesso! */),
            'top-right',
          );
        }

        const dockPrintConsolidation = userLogged.mainBranchData
          ? userLogged.mainBranchData.dockPrintConsolidation
          : false;

        if (dockPrintConsolidation) {
          const totalVolumes = outboundOrder.reduce((total, item) => total + parseFloat(item.totalAmount), 0);

          this.setState({ showPrintConsolidationDialog: true, totalVolumes });
        } else {
          this.restoreState();
        }
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification('danger', I18n.t('BEE19' /* Docas */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE19' /* Docas */),
          I18n.t('BEE1852' /* Não foi possível finalizar esta ação */),
          'top-right',
        );
      }
    }
    this.setState({ showWarningDialog: false });
  };

  createSelect = (value, attr, label, items) => (
    <div className="form-group p-2">
      <p>{label}</p>
      <select
        className="form-control"
        value={value || ''}
        onChange={(e) => this.setValue(attr, e.target.value)}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );

  render() {
    const {
      outboundVolumeId, carrierBusinessName, dockCode, dockCodeDisabled, showPrinterDialog, totalVolumes,
      carrierCodeDisabled, showWarningDialog, outboundOrder, outboundVolumeIdDisabled, dockControlId,
      showPrintConsolidationDialog, printerSelected, printerOptions,
    } = this.state;

    return (
      <div onSubmit={(e) => e.preventDefault()}>
        <PanelPage
          breadcrumb={this.breadcrumb}
          title={I18n.t('BEE19' /* Docas */)}
          wikiHelp={ROUTES.OUTBOUND_DOCKS_HELP}
          noButton
          content={(
            <div className="col-md-4">
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputButton(
                    dockCode,
                    'dockCode',
                    I18n.t('BEE199' /* Doca */),
                    '',
                    'text',
                    false,
                    dockCodeDisabled,
                    undefined,
                    undefined,
                    this.keypressConfirmPicking,
                    1,
                  ),
                  formContext.createInputButton(
                    outboundVolumeId,
                    'outboundVolumeId',
                    I18n.t('BEE1922' /* Etiqueta Volume */),
                    '',
                    'text',
                    false,
                    outboundVolumeIdDisabled,
                    undefined,
                    undefined,
                    this.keypressConfirmPicking,
                    2,
                  ),
                  formContext.createInputButton(
                    carrierBusinessName,
                    'carrierBusinessName',
                    I18n.t('BEE301' /* Transportadora */),
                    '',
                    'text',
                    false,
                    carrierCodeDisabled,
                    undefined,
                    undefined,
                    undefined,
                    3,
                  ),
                ])}
              />
            </div>
          )}
          footerContent={(
            <>
              {!dockCodeDisabled && (
                <button
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={() => this.getOpenConference()}
                >
                  {I18n.t('BEE407' /* Buscar */)}
                </button>
              )}
              {!outboundVolumeIdDisabled && !dockControlId && (
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={() => this.checkVolumes()}
                >
                  {I18n.t('BEE1931' /* iniciar */)}
                </OneClickButton>
              )}
              {!outboundVolumeIdDisabled && dockControlId && (
                <>
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-primary p-5 m-5"
                    onClick={() => this.checkVolumes()}
                  >
                    {I18n.t('BEE1481' /* Conferir */)}
                  </OneClickButton>
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-secondary p-5 m-5"
                    onClick={() => {
                      if (outboundOrder.length) {
                        this.setState({ showWarningDialog: true });
                      } else {
                        addNotification(
                          'danger',
                          I18n.t('BEE19' /* Docas */),
                          I18n.t('BEE1952' /* Nenhum volume conferido */),
                          'top-right',
                        );
                      }
                    }}
                  >
                    {I18n.t('BEE1567' /* Finalizar */)}
                  </OneClickButton>
                </>
              )}
            </>
          )}
        />

        <DocumentsTable
          outboundOrder={this.state.outboundOrder}
        />
        {(showWarningDialog
          && (
            <SweetAlert
              warning
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.finalizeCheckVolumes()}
              onCancel={() => this.setState({ showWarningDialog: false })}
            >
              {I18n.t('BEE3374' /* O Controle de Doca será finalizado e o Romaneio será gerado! */)}
            </SweetAlert>
          )
        )}
        {(showPrinterDialog
          && (
            <SweetAlert
              confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1324' /* Selecionar Impressora */)}
              onConfirm={() => this.onSelectFilterModal()}
              onCancel={() => this.setState({
                showPrinterDialog: false,
              })}
            >
              <div className="mt-4">
                {this.createSelect(
                  printerSelected,
                  'printerSelected',
                  I18n.t('BEE328' /* Impressora */),
                  printerOptions,
                )}
              </div>
            </SweetAlert>
          )
        )}
        {(showPrintConsolidationDialog
          && (
            <SweetAlert
              warning
              showCancel
              cancelBtnText={I18n.t('BEE173' /* Não */)}
              confirmBtnText={I18n.t('BEE172' /* Sim */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE3375' /* Etiqueta de Consolidação */)}
              onConfirm={() => this.onDoPrintConsolidation()}
              onCancel={() => { this.restoreState(); this.setState({ showPrintConsolidationDialog: false }); }}
            >
              {
                I18n.t(
                  'BEE3373',
                  { 0: totalVolumes || 0 },
                  /* Deseja imprimir as %{0} etiquetas de consolidação relacionadas aos documentos da doca? */
                )
              }
            </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getOpenConference: (dockCode) => dispatch(getOpenConference(dockCode)),
  createDockControl: (
    dockCode,
    outboundVolumeId,
    carrierCode,
  ) => dispatch(createDockControl(dockCode, outboundVolumeId, carrierCode)),
  getOutboundOrdersChecked: (dockControlId) => dispatch(getOutboundOrdersChecked(dockControlId)),
  checkVolumes: (
    dockControlId,
    outboundVolumeId,
    carrierCode,
    dockCode,
  ) => dispatch(checkVolumes(dockControlId, outboundVolumeId, carrierCode, dockCode)),
  finalizeCheckVolumes: (dockControlId) => dispatch(finalizeCheckVolumes(dockControlId)),
  getOutboundOrderDockControl: (outboundVolumeId) => dispatch(getOutboundOrderDockControl(outboundVolumeId)),
  printConsolidationsLabel: (printerCode, outboundOrderId, showLoading) => dispatch(
    printConsolidationsLabel(printerCode, outboundOrderId, showLoading),
  ),
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundDocks));
