/* eslint-disable no-unused-vars */
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { getBranchesOptions } from '../../../../app/store/actions/branches';
import { printAddressLabel, getLabelsByTrigger } from '../../../../app/store/actions/labels';
import { getUserPrinterOptions } from '../../../../app/store/actions/printers';
import OneClickButton from '../../../../components/form/button';
import Form from '../../../../components/form/form';
import addNotification from '../../../../components/notification';
import { PanelPage } from '../../../../components/pages/pages';
import ROUTES from '../../../../config/routes';
import { returnFiltersByRules } from '../../../reports/utils';

class LabelAddressForm extends React.PureComponent {
  constructor() {
    super();
    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE1371' /* Etiquetas */) },
      { value: I18n.t('BEE1405' /* Etiqueta Endereço */), active: true },
    ];

    this.state = {
      branchCode: '',
      branchName: '',
      listBranch: [],
      addressFrom: '',
      addressTo: '',
      sectorFrom: '',
      sectorTo: '',
      streetFrom: '',
      streetTo: '',
      columnFrom: '',
      columnTo: '',
      columnSide: '',
      printOrder: '',
      levelFrom: '',
      levelTo: '',
      drawerFrom: '',
      drawerTo: '',
      printerOptions: [],
      showPrinterDialog: false,
      printerSelected: null,
      printLimitNotice: false,
      listLabels: [],
      labelCode: '',
    };
  }

  async componentDidMount() {
    this.getUserPrinterOptions();
    const listBranch = await this.props.getBranchesOptions();
    this.setState({
      listBranch,
      printLimitNotice: true,
    });

    const columnSideList = [
      { label: I18n.t('BEE203' /* Ambos */), value: 'both' },
      { label: I18n.t('BEE3398' /* Par */), value: 'even' },
      { label: I18n.t('BEE3399' /* Ímpar */), value: 'odd' },
    ];

    const printOrderList = [
      { label: I18n.t('BEE3461' /* Ascendente */), value: 'asc' },
      { label: I18n.t('BEE3462' /* Descendente */), value: 'desc' },
    ];

    const listLabels = await this.props.getLabelsByTrigger('address');

    this.setState({
      columnSideList,
      columnSide: 'both',
      columnSideLabel: I18n.t('BEE203' /* Ambos */),
      printOrderList,
      printOrder: 'asc',
      printOrderLabel: I18n.t('BEE3461' /* Ascendente */),
      listLabels: listLabels || [],
      labelCode: (listLabels && listLabels.length) ? listLabels[0].code : '',
    });
  }

  getUserPrinterOptions = async () => {
    const Info = await this.props.getUserPrinterOptions();
    const { userPrinter, printerOptions } = Info;
    const { printerSelected } = this.state;

    this.setState({
      printerOptions,
      printerSelected: (printerSelected === null
        ? userPrinter
        : printerSelected !== userPrinter
          && printerSelected)
           || userPrinter,
    });
  };

  setValue = (attr, value) => {
    this.setState({
      [`${attr}`]: value.toUpperCase(),
    });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });

    if (attr === 'printOrder') {
      const { printOrderCode } = this.state;

      let printOrderLabel = '';

      const printOrderList = [
        { label: I18n.t('BEE3461' /* Ascendente */), value: 'asc' },
        { label: I18n.t('BEE3462' /* Descendente */), value: 'desc' },
      ];

      if (printOrderCode === 'asc') {
        printOrderLabel = I18n.t('BEE3461' /* Ascendente */);
      } else if (printOrderCode === 'desc') {
        printOrderLabel = I18n.t('BEE3462' /* Descendente */);
      }

      await this.setState({
        printOrderList,
        printOrder: printOrderCode,
        printOrderLabel,
      });
    }

    if (attr === 'columnSide') {
      const { columnSideCode } = this.state;

      let columnSideLabel = '';

      const columnSideList = [
        { label: I18n.t('BEE203' /* Ambos */), value: 'both' },
        { label: I18n.t('BEE3398' /* Par */), value: 'even' },
        { label: I18n.t('BEE3399' /* Ímpar */), value: 'odd' },
      ];

      if (columnSideCode === 'both') {
        columnSideLabel = I18n.t('BEE203' /* Ambos */);
      } else if (columnSideCode === 'even') {
        columnSideLabel = I18n.t('BEE3398' /* Par */);
      } else if (columnSideCode === 'odd') {
        columnSideLabel = I18n.t('BEE3399' /* Ímpar */);
      }

      await this.setState({
        columnSideList,
        columnSide: columnSideCode,
        columnSideLabel,
      });
    }
  };

  hidePrinterDialog = () => {
    this.setState({
      showPrinterDialog: false,
      printerSelected: null,
    });
  };

  checkPrinters = () => {
    const {
      printerOptions,
      printerSelected,
    } = this.state;

    if (printerOptions.length === 0 && printerSelected === null) {
      addNotification(
        'danger',
        I18n.t('BEE1780' /* Impressão Endereço */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
    } else if (printerOptions && printerOptions.length > 1) {
      this.setState({
        showPrinterDialog: true,
      });
    } else {
      this.printOut();
    }
  };

  printOut = async () => {
    this.setState({
      showPrinterDialog: false,
    });
    try {
      const {
        branchCode, addressFrom, addressTo, sectorFrom, sectorTo, labelCode,
        streetFrom, streetTo, columnFrom, columnTo, columnSide, levelFrom, levelTo,
        drawerFrom, drawerTo, printerOptions, printerSelected, printOrder,
      } = this.state;

      if (branchCode === '') {
        addNotification(
          'danger',
          I18n.t('BEE1405' /* Etiqueta Endereço */),
          I18n.t('BEE1781' /* Informe a filial */),
          'top-right',
        );
      } else {
        let filters = '';
        const selected = (printerSelected === null) ? printerOptions[0].value : printerSelected;

        filters = returnFiltersByRules([
          ['branchCode', { type: 'in', value: [branchCode] }],
          ['code', { type: 'between', value: [addressFrom.toLocaleUpperCase(), addressTo.toLocaleUpperCase()] }],
          ['sector', { type: 'between', value: [sectorFrom.toLocaleUpperCase(), sectorTo.toLocaleUpperCase()] }],
          ['street', { type: 'between', value: [streetFrom.toLocaleUpperCase(), streetTo.toLocaleUpperCase()] }],
          ['column', { type: 'between', value: [columnFrom.toLocaleUpperCase(), columnTo.toLocaleUpperCase()] }],
          ['level', { type: 'between', value: [levelFrom.toLocaleUpperCase(), levelTo.toLocaleUpperCase()] }],
          ['drawer', { type: 'between', value: [drawerFrom.toLocaleUpperCase(), drawerTo.toLocaleUpperCase()] }],
        ]);

        const result = await this.props.printAddressLabel(
          branchCode,
          selected,
          filters,
          columnSide,
          printOrder,
          labelCode,
        );

        if (result && result.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE1780' /* Impressão Endereço */),
            I18n.t('BEE1441' /* Erro ao tentar imprimir */),
            'top-right',
          );
        } else {
          addNotification(
            'success',
            I18n.t('BEE1780' /* Impressão Endereço */),
            I18n.t('BEE1427' /* Etiquetas enviadas para impressora ! */),
            'top-right',
          );
        }
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification('danger', I18n.t('BEE1780' /* Impressão Endereço */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1780' /* Impressão Endereço */),
          I18n.t('BEE1441' /* Erro ao tentar imprimir */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      branchCode, branchName, listBranch = [], addressFrom, addressTo, sectorFrom, sectorTo,
      streetFrom, streetTo, columnFrom, columnTo, columnSide, columnSideLabel, columnSideList,
      levelFrom, levelTo, drawerFrom, drawerTo, printerOptions, showPrinterDialog, printerSelected,
      printLimitNotice, printOrder, printOrderLabel, printOrderList, labelCode, listLabels,
    } = this.state;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        noButton
        title={I18n.t('BEE1405' /* Etiqueta Endereço */)}
        wikiHelp={ROUTES.MOVEMENT_LABEL_ADDRESS_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                setValueDrop={this.setValueDrop}
                inputs={(formContext) => ([
                  formContext.createSelectDropDown(
                    { value: branchCode, label: branchName },
                    'branch',
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    listBranch,
                    false,
                    undefined,
                    ['col-md-2', 'col-md-4'],
                    undefined,
                    undefined,
                    undefined,
                    true,
                  ),
                  formContext.createSelect(
                    labelCode,
                    'labelCode',
                    `${I18n.t('BEE4108' /* Modelo de Etiqueta */)}:`,
                    listLabels.map((item) => (
                      { value: item.code, label: item.code + (item.description ? ` - ${item.description}` : '') })),
                    false,
                    undefined,
                    4,
                  ),
                  formContext.createInputRange(
                    { from: addressFrom.toLocaleUpperCase(), to: addressTo.toLocaleUpperCase() },
                    { from: 'addressFrom', to: 'addressTo' },
                    `${I18n.t('BEE267' /* Endereço */)}:`,
                    { from: 'z'.repeat(15), to: 'z'.repeat(15) },
                    { from: 'text', to: 'text' },
                  ),
                  formContext.createInputRange(
                    { from: sectorFrom.toLocaleUpperCase(), to: sectorTo.toLocaleUpperCase() },
                    { from: 'sectorFrom', to: 'sectorTo' },
                    `${I18n.t('BEE279' /* Setor */)}:`,
                    { from: '000000000000001', to: '9'.repeat(15) },
                  ),
                  formContext.createInputRange(
                    { from: streetFrom.toLocaleUpperCase(), to: streetTo.toLocaleUpperCase() },
                    { from: 'streetFrom', to: 'streetTo' },
                    `${I18n.t('BEE121' /* Rua */)}:`,
                    { from: '000000000000001', to: '9'.repeat(15) },
                  ),
                  formContext.createInputRange(
                    { from: columnFrom.toLocaleUpperCase(), to: columnTo.toLocaleUpperCase() },
                    { from: 'columnFrom', to: 'columnTo' },
                    `${I18n.t('BEE281' /* Coluna */)}:`,
                    { from: '000000000000001', to: '9'.repeat(15) },
                  ),
                  formContext.createSelectDropDown(
                    { value: columnSide, label: columnSideLabel },
                    'columnSide',
                    `${I18n.t('BEE3397' /* Gerar Lados (Coluna) */)}:`,
                    columnSideList,
                    false,
                    undefined,
                    ['col-md-2', 'col-md-4'],
                  ),
                  formContext.createSelectDropDown(
                    { value: printOrder, label: printOrderLabel },
                    'printOrder',
                    `${I18n.t('BEE3463' /* Ordem de Impressão */)}:`,
                    printOrderList,
                    false,
                    undefined,
                    ['col-md-2', 'col-md-4'],
                  ),
                  formContext.createInputRange(
                    { from: levelFrom.toLocaleUpperCase(), to: levelTo.toLocaleUpperCase() },
                    { from: 'levelFrom', to: 'levelTo' },
                    `${I18n.t('BEE283' /* Nível */)}:`,
                    { from: '000000000000001', to: '9'.repeat(15) },
                  ),
                  formContext.createInputRange(
                    { from: drawerFrom.toLocaleUpperCase(), to: drawerTo.toLocaleUpperCase() },
                    { from: 'drawerFrom', to: 'drawerTo' },
                    `${I18n.t('BEE285' /* Gaveta */)}:`,
                    { from: '000000000000001', to: '9'.repeat(15) },
                  ),
                ])}
              />
            </div>
            {(showPrinterDialog && (
              <SweetAlert
                confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="default"
                title={I18n.t('BEE1324' /* Selecionar Impressora */)}
                onConfirm={() => this.printOut()}
                onCancel={() => this.hidePrinterDialog()}
              >
                <div className="mt-4">
                  <Form
                    noPanel
                    setValue={this.setValue}
                    inputs={(formContext) => ([
                      formContext.createSelect(
                        printerSelected,
                        'printerSelected',
                        I18n.t('BEE328' /* Impressora */),
                        printerOptions,
                        '',
                        '',
                        12,
                        true,
                      ),
                    ])}
                  />
                </div>
              </SweetAlert>
            ))}
            {(printLimitNotice && (
              <SweetAlert
                warning
                confirmBtnText={I18n.t('BEE3210' /* Entendi */)}
                confirmBtnBsStyle="primary"
                onConfirm={() => { this.setState({ printLimitNotice: false }); }}
                onCancel={() => { this.setState({ printLimitNotice: false }); }}
              >
                <h4>
                  {
                  I18n.t('BEE2322')
                    /* Só pode ser impresso 300 etiquetas por vez, passando do limite nenhuma etiqueta será impressa */
                  }
                </h4>
              </SweetAlert>
            ))}
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            className="btn btn-120 btn-primary p-5 m-5"
            onClick={() => this.checkPrinters()}
          >
            {I18n.t('BEE1270' /* Imprimir */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  printAddressLabel: (
    branchCode,
    printerCode,
    filters,
    columnSide,
    printOrder,
    labelCode,
  ) => dispatch(printAddressLabel(branchCode, printerCode, filters, columnSide, printOrder, labelCode)),
  getLabelsByTrigger: (trigger) => dispatch(getLabelsByTrigger(trigger)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelAddressForm);
