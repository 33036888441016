import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import { getAddressTransferWaveByBaptism } from '../../../../app/store/actions/addressTransferWave';
import addNotification from '../../../../components/notification';
import { PanelPage } from '../../../../components/pages/pages';
import ROUTES from '../../../../config/routes';
import ProductsList from './productsList';

class WaveTransferMonitorForm extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE32' /* Estoque */) },
      { value: I18n.t('BEE2294' /* Transferência por Onda */) },
    ];

    this.state = {
      branchCode: null,
      branchName: '',
      documentNumber: null,
      baptism: null,
      products: [],
      title: '',
      mode: '',
      depositCode: '',
    };
  }

  async componentDidMount() {
    await this.setInitialDataSearch();
    await this.getAddressTransferWaveByBaptism();
  }

  getAddressTransferWaveByBaptism = async () => {
    const { baptism, branchCode, mode } = this.state;

    try {
      const documentData = await this.props.getAddressTransferWaveByBaptism(branchCode, baptism, this.props.isDetail);
      if (Object.keys(documentData).length) {
        this.setState({
          products: [...documentData.products],
        });
        const result = documentData.products.find((elem) => elem.status === 1 || elem.status === 2);
        if (mode !== 'detail' && !result) {
          addNotification(
            'success',
            I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
            I18n.t('BEE1953' /* Documento finalizado */),
            'top-right',
          );
          this.props.history.push(ROUTES.STOCK_WAVE_TRANSFER_MONITOR);
        }
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2294' /* Transferência por Onda */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2294' /* Transferência por Onda */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  setValue = (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div className="form-group p-2 text-left">
      <span>{label}</span>
      <input
        onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
        id={id && id}
        type={type}
        className="form-control"
        value={value || ''}
        onChange={(e) => this.setValue(attr, e.target.value)}
        placeholder={disabled ? '' : placeholder}
        required={required}
        disabled={disabled}
      />
    </div>
  );

  setInitialDataSearch = async () => {
    const mode = (this.props.isEdit ? 'edit'
      : this.props.isDetail && 'detail') || 'transfer';

    const title = ((mode === 'edit') ? I18n.t('BEE57' /* Editar */)
      : (mode === 'detail') && I18n.t('BEE56' /* Detalhes */)) || I18n.t('BEE1506' /* Transferir */);

    await this.setState({
      baptism: this.props.location && this.props.location.state.baptism,
      branchCode: this.props.location && this.props.location.state.branchCode,
      documentNumber: this.props.location && this.props.location.state.documentNumber,
      depositCode: this.props.location && this.props.location.state.depositCode,
      branchName: this.props.location
      && `${this.props.location.state.branchCode} - ${this.props.location.state.branchName}`,
      mode,
      title,
    });

    this.breadcrumb.push({
      value: title, active: true,
    });
  };

  render() {
    const {
      branchName, documentNumber, depositCode, baptism,
    } = this.state;

    return (
      <>
        <PanelPage
          breadcrumb={this.breadcrumb}
          title={this.state.title}
          noButton
          content={(
            <div className="col">
              <div className="row">
                <div className="col-md-3">
                  {this.createInput(
                    branchName,
                    'branchName',
                    I18n.t('BEE145' /* Filial */),
                    '',
                    'text',
                    true,
                    true,
                  )}
                </div>
                <div className="col-md-3">
                  {this.createInput(
                    baptism,
                    'baptism',
                    I18n.t('BEE425' /* Batismo */),
                    '',
                    'text',
                    true,
                    true,
                  )}
                </div>
                <div className="col-md-3">
                  {this.createInput(
                    documentNumber,
                    'documentNumber',
                    I18n.t('BEE1378' /* Document */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-3">
                  {this.createInput(
                    depositCode,
                    'depositCode',
                    I18n.t('BEE1795' /* Depósito Retirada */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
              </div>
            </div>
          )}
          footerContent={(
            <button
              type="button"
              className="btn btn-120 btn-white p-5 m-5"
              onClick={() => this.props.history.push(ROUTES.STOCK_WAVE_TRANSFER_MONITOR)}
            >
              {I18n.t('BEE553' /* Fechar */)}
            </button>
          )}
        />
        {!!this.state.mode
          && (
          <ProductsList
            products={this.state.products}
            title={this.state.title}
            mode={this.state.mode}
            getAddressTransferWaveByBaptism={this.getAddressTransferWaveByBaptism}
            documentData={{
              branchCode: this.state.branchCode,
              baptism: this.state.baptism,
              depositCodeOut: this.state.depositCode,
            }}
          />
          )}
      </>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getAddressTransferWaveByBaptism: (
    branch,
    baptism,
    isDetail,
  ) => dispatch(getAddressTransferWaveByBaptism(branch, baptism, false, isDetail)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WaveTransferMonitorForm));
