import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

import OneClickButton from '../../../components/form/button';
import Form from '../../../components/form/form';
import addNotification from '../../../components/notification';

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inventoryType: '1',
      branchInInventory: false,
      complementAddress: true,
      transactionDate: new Date(),
      grouperCode: '',
      mandatoryComplementAddress: true,
    };
  }

  async componentDidUpdate(prevProps) {
    const params = this.props.tableFilter;

    if (params && params !== prevProps.tableFilter) {
      const branchCode = params.branchCode.value;
      const depositCode = params.depositCode.value;
      const { listDeposit = [] } = this.props;

      const deposit = listDeposit.find((item) => item.value === params.depositCode.value);
      let type = '';
      let inventoryType = '1';

      if (deposit && deposit.serialLabel) {
        type = 'S';
        inventoryType = '3';
      } else if (this.state.inventoryType === '1') {
        type = 'R';
        inventoryType = '1';
      } else {
        type = 'G';
        inventoryType = '2';
      }

      const { transactionDate } = this.state;

      const grouperAux = await this.props.getNewGrouperCode(branchCode, type, transactionDate, depositCode);

      this.setState({
        grouperCode: grouperAux.grouperCode,
        mandatoryComplementAddress: grouperAux.mandatoryComplementAddress,
        inventoryType,
        complementAddress: inventoryType !== '3',
      });
    }
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });

    if (attr === 'inventoryType') {
      if (value === '1') {
        this.setState({
          branchInInventory: false,
        });
      } else if (value === '2') {
        this.setState({
          branchInInventory: true,
        });
      }
    }

    if (attr === 'inventoryType' || attr === 'transactionDate') {
      const params = this.props.tableFilter;

      if (params) {
        const branchCode = params.branchCode.value;
        const depositCode = params.depositCode.value;

        let type;

        if (attr === 'inventoryType') {
          type = value === '1' ? 'R' : 'G';
        } else {
          type = this.state.inventoryType === '1' ? 'R' : 'G';
        }

        const dateAux = attr === 'transactionDate'
          ? value
          : this.state.transactionDate;

        const grouperAux = await this.props.getNewGrouperCode(branchCode, type, dateAux, depositCode);

        this.setState({
          grouperCode: grouperAux.grouperCode,
          mandatoryComplementAddress: grouperAux.mandatoryComplementAddress,
        });
      }
    }
  };

  submitConfirm = () => {
    const {
      grouperCode, inventoryType, branchInInventory, transactionDate, complementAddress,
    } = this.state;

    if (!grouperCode || grouperCode === '') {
      addNotification(
        'danger',
        I18n.t('BEE1516' /* Agrupador */),
        I18n.t('BEE3594' /* O agrupador deve ser informado. */),
        'top-right',
      );
    } else {
      this.props.submitConfirm({
        grouperCode, inventoryType, branchInInventory, transactionDate, complementAddress,
      });
    }
  };

  render() {
    const {
      inventoryType,
      branchInInventory,
      transactionDate,
      grouperCode,
      complementAddress,
      mandatoryComplementAddress,
    } = this.state;
    const { showConfirmModal = false } = this.props;

    return (
      <Modal size="md" isOpen={showConfirmModal} toggle={this.props.closeConfirm} className="adjustmentPadding">
        <ModalHeader toggle={this.props.closeConfirm}>
          {I18n.t('BEE3596' /* Parâmetros de Inventário */)}
        </ModalHeader>
        <ModalBody>
          <div style={{ marginTop: 20 }}>
            <Form
              noPanel
              isModal
              headerProps={{
                className: 'm-b-0',
              }}
              setValue={this.setValue}
              setValueDrop={this.setValueDrop}
              inputs={(formContext) => ([
                formContext.createInput(
                  grouperCode,
                  'grouperCode',
                  `${I18n.t('BEE1516' /* Agrupador */)}:`,
                  I18n.t('BEE2111' /* Informe o agrupador */),
                  'text',
                  false,
                  false,
                  undefined,
                  undefined,
                  undefined,
                  null,
                  20,
                ),
                formContext.createRadioButtons(

                  inventoryType && inventoryType === '3' ? [{
                    label: I18n.t('BEE2760' /* Etiqueta Seriada */),
                    value: 3,
                    disabled: true,
                  }] : [{
                    label: I18n.t('BEE2085' /* Rotativo */),
                    value: 1,
                    disabled: inventoryType && inventoryType === '3',
                  }, {
                    label: I18n.t('BEE191' /* Geral */),
                    value: 2,
                    disabled: inventoryType && inventoryType === '3',
                  }],

                  inventoryType,
                  'inventoryType',
                  `${I18n.t('BEE2046' /* Tipo Inventário */)}:`,
                  undefined,
                  undefined,
                  'm-l-10',
                  ['col-md-3'],
                ),
                formContext.createCheckBoxes([
                  {
                    value: branchInInventory,
                    attr: 'branchInInventory',
                    disabled: true,
                    label: I18n.t('BEE779' /* Filial em Inventário */),
                  },
                ], ' ', undefined, ['col-md-3', 'col-md-6']),
                inventoryType && inventoryType !== '3' && formContext.createCheckBoxes([
                  {
                    value: complementAddress,
                    attr: 'complementAddress',
                    label: I18n.t('BEE3595' /* Gerar Ficha para Endereços Complementares */),
                    disabled: mandatoryComplementAddress,
                  },
                ], ' ', undefined, ['col-md-3', 'col-md-8']),
                formContext.createDateInput(
                  transactionDate,
                  'transactionDate',
                  `${I18n.t('BEE2088' /* Data Transação */)}:`,
                  false,
                  undefined,
                  ['col-md-3', 'col-md-4'],
                ),
              ])}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-120 btn-white p-5 m-5"
            onClick={this.props.closeConfirm}
          >
            {I18n.t('BEE99' /* Cancelar */)}
          </button>
          <OneClickButton
            type="button"
            className="btn btn-120 btn-primary p-5 m-5"
            onClick={this.submitConfirm}
          >
            {I18n.t('BEE100' /* Confirmar */)}
          </OneClickButton>
        </ModalFooter>
      </Modal>

    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmModal));
