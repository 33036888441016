import { I18n } from 'react-redux-i18n';

function separationMode(mode) {
  switch (mode) {
    case 1:
      return I18n.t('BEE1008' /* Por onda */);
    case 2:
      return I18n.t('BEE1009' /* Por pedido */);
    default:
      return mode;
  }
}

function listSeparationMode() {
  return [
    { value: 1, label: I18n.t('BEE1008' /* Por onda */) },
    { value: 2, label: I18n.t('BEE1009' /* Por pedido */) },
  ];
}

function listRulesStorage() {
  return [{
    value: 1, label: I18n.t('BEE1010' /* Endereço com saldo do produto */),
  }, {
    value: 2, label: I18n.t('BEE1011' /* Ultimo endereço vazio do produto */),
  }, {
    value: 3, label: I18n.t('BEE1012' /* Endereço padrão do produto */),
  }, {
    value: 4, label: I18n.t('BEE2361' /* Endereço Vazio da Curva do Produto */),
  }];
}

function listRulesResupply() {
  return [{
    value: 1, label: I18n.t('BEE2641' /* FEFO - Data de validade */),
  }, {
    value: 2, label: I18n.t('BEE2640' /* FIFO - Data / Hora de entrada */),
  }, {
    value: 3, label: I18n.t('BEE2639' /* Crescente por endereço de retirada */),
  }];
}

export default {
  separationMode,
  listSeparationMode,
  listRulesStorage,
  listRulesResupply,
};
