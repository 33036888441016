import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import CableCutsTable from './cableCutsTable';
import CutProductTable from './cutProductTable';
import Form from '../../../components/form/form';
import { returnFiltersByRules } from '../../reports/utils';

import { cutsMonitoList, setChangeCutProductList } from '../../../app/store/actions/cutsMonito';
import { getUserPrinterOptions } from '../../../app/store/actions/printers';
import { getRangeUserList } from '../../../app/store/actions/users';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';

class CableCutMonitList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilterModal: false,
      printerOptions: [],
      printerSelected: null,
      userOptions: [],
      userSelected: null,
      pages: 0,
      tableParams: {},
      status: 1,
      filterAction: false,
      totalDataLength: 0,
    };
  }

  async componentDidMount() {
    const printerReq = await this.props.getUserPrinterOptions();
    const { printerOptions, userPrinter } = printerReq;
    this.setState({
      printerOptions,
      printerSelected: userPrinter || '',
    });
  }

  getRangeUserList = async (params) => {
    const listUser = await this.props.getRangeUserList(params);
    this.setState({ userOptions: listUser });
  };

  showFilters = () => {
    this.setState({
      showFilterModal: true,
      codeFrom: '',
      codeTo: '',
      createdFrom: '',
      createdTo: '',
      status: 1,
      orderFrom: '',
      orderTo: '',
      documentFrom: '',
      documentTo: '',
      clientFrom: '',
      clientTo: '',
    });
  };

  closeFilters = () => {
    this.setState({
      showFilterModal: false,
    });
  };

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  mountFilter = async () => {
    const {
      codeFrom, codeTo, createdFrom, createdTo, status, orderFrom,
      orderTo, documentFrom, documentTo, clientFrom, clientTo, tableParams,
    } = this.state;

    this.setState({ showFilterModal: false });

    const filters = returnFiltersByRules([
      ['code', { type: 'between', value: [codeFrom, codeTo] }],
      ['createdAt', { type: 'between', value: [createdFrom, createdTo] }],
      ['status', { type: 'and', value: status }],
    ]);
    const filtersProduct = returnFiltersByRules([
      ['lotNumber', { type: 'between', value: [orderFrom, orderTo] }],
      ['$orderNumber', {
        type: 'between', value: [documentFrom, documentTo], instance: 'OutboundOrder', as: 'outboundOrder',
      }],
      ['$name', {
        type: 'between', value: [clientFrom, clientTo], instance: 'Customer', as: 'outboundOrder->customer',
      }],
    ]);

    tableParams.generalFilters = filters || {};
    if (filtersProduct) tableParams.filtersProduct = filtersProduct;
    const data = await this.props.cutsMonitoList(tableParams);
    this.setState({ pages: data.pages, filterAction: true, totalDataLength: data.totalDataLength });
  };

  createFilterModal = () => {
    const {
      showFilterModal, codeFrom, codeTo, createdFrom, createdTo, status, orderFrom,
      orderTo, documentFrom, documentTo, clientFrom, clientTo,
    } = this.state;

    return (
      <Modal size="lg" isOpen={showFilterModal} toggle={() => this.closeFilters()}>
        <ModalHeader toggle={() => this.closeFilters()}>{I18n.t('BEE546' /* Filtros */).toUpperCase()}</ModalHeader>
        <ModalBody>
          <Form
            noHeader
            leftType
            headerProps={{
              className: 'm-b-0',
            }}
            setValue={this.setValue}
            inputs={(formContext) => ([
              formContext.createInputRange(
                { from: codeFrom, to: codeTo },
                { from: 'codeFrom', to: 'codeTo' },
                `${I18n.t('BEE1581' /* Corte */)}:`,
                { from: '', to: 'Z'.repeat(20) },
              ),
              formContext.createDateInputRange(
                { from: createdFrom, to: createdTo },
                { from: 'createdFrom', to: 'createdTo' },
                `${I18n.t('BEE578' /* Data de Criação */)}:`,
                { from: '__/__/____', to: '__/__/____' },
              ),
              formContext.createInputRange(
                { from: orderFrom, to: orderTo },
                { from: 'orderFrom', to: 'orderTo' },
                `${I18n.t('BEE51' /* Pedido */)}:`,
                { from: '', to: 'Z'.repeat(20) },
              ),
              formContext.createInputRange(
                { from: clientFrom, to: clientTo },
                { from: 'clientFrom', to: 'clientTo' },
                `${I18n.t('BEE50' /* Cliente */)}:`,
                { from: '', to: 'Z'.repeat(20) },
              ),
              formContext.createInputRange(
                { from: documentFrom, to: documentTo },
                { from: 'documentFrom', to: 'documentTo' },
                `${I18n.t('BEE1378' /* Documento */)}:`,
                { from: '', to: 'Z'.repeat(20) },
              ),
              formContext.createRadioButtons([
                {
                  label: I18n.t('BEE470' /* Pendente */),
                  value: '1',
                }, {
                  label: I18n.t('BEE1833' /* finalizado */),
                  value: '2',
                }, {
                  label: I18n.t('BEE521' /* Cancelados */),
                  value: '3',
                }, {
                  label: I18n.t('BEE793' /* Todos */),
                  value: '',
                },
              ], status, 'status', `${I18n.t('BEE204' /* Situação */)}:`, 'radio-inline', '', 'col-md-8'),
            ])}
            footerContent={(
              <>
                <button type="button" className="btn btn-120 btn-white p-5 m-5" onClick={this.closeFilters}>
                  {I18n.t('BEE99' /* Cancelar */)}
                </button>
                <button type="button" className="btn btn-120 btn-primary p-5 m-5" onClick={this.mountFilter}>
                  {I18n.t('BEE407' /* Buscar */)}
                </button>
              </>
            )}
          />
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { totalDataLength } = this.state;
    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1419' /* Monitor de Cortes */)}</li>
          </ol>
        </div>
        <div>
          <div className="d-flex align-items-center mb-md-3 mb-2">
            <h1 className="page-header mb-0">
              {I18n.t('BEE1419' /* Monitor de Cortes */)}
            </h1>
            <WikiHelp wikiPath={ROUTES.OUTBOUND_CABLE_CUT_MONIT_HELP} />
          </div>
          <div className="d-sm-flex align-items-center mb-3">
            <button
              type="button"
              className="btn btn-inverse mr-2 text-truncate"
              onClick={() => this.showFilters()}
            >
              <i className="fa fa-filter fa-fw text-white-transparent-5 ml-n1" />
              {I18n.t('BEE546' /* Filtros */)}
            </button>
          </div>
        </div>
        <div>
          <div>
            <CableCutsTable
              pages={this.state.pages || 0}
              totalDataLength={totalDataLength}
              filterAction={this.state.filterAction}
              setState={(value) => this.setState(value)}
              cutsMonitoList={() => this.mountFilter()}
              getRangeUserList={(params) => this.getRangeUserList(params)}
              printerOptions={this.state.printerOptions}
              printerSelected={this.state.printerSelected}
              userOptions={this.state.userOptions}
              userSelected={this.state.userSelected}
              setValue={(attr, value) => this.setValue(attr, value)}
            />
            <br />
            <CutProductTable
              cutsMonitoList={() => this.mountFilter()}
              printerOptions={this.state.printerOptions}
              printerSelected={this.state.printerSelected}
              setValue={(attr, value) => this.setValue(attr, value)}
            />
          </div>
          {this.createFilterModal()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  cutsMonitoList: (tableParams) => dispatch(cutsMonitoList(tableParams)),
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  setChangeCutProductList: (cableCutproductList) => dispatch(setChangeCutProductList(cableCutproductList)),
  getRangeUserList: (params) => dispatch(getRangeUserList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CableCutMonitList));
