const INITIAL_STATE = {
  schedulesList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_SCHEDULES': {
      return { ...state, schedulesList: action.schedules };
    }
    default:
      return state;
  }
};
