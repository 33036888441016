import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import OneClickButton from '../../components/form/button';
import { PanelPage } from '../../components/pages/pages';
import Form from '../../components/form/form';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportMovementExpeditionCheckQtyProductsPerCheckerInbound extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
        </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: 'KPIs' },
      { value: I18n.t('BEE1886' /* Conferência Recebimento Qtd Produtos por Conferente */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE145' /* Filial */),
        value: '_inboundOrderBranch',
      },
      {
        label: I18n.t('BEE1997' /* Nome Filial */),
        value: 'branchName',
      },
      {
        label: I18n.t('BEE467' /* Data Conferência */),
        value: 'checkProductAt',
      },
      // {
      //   label: I18n.t('BEE623' /* Login */),
      //   value: 'checkProductUser'
      // },
      {
        label: I18n.t('BEE1749' /* Nome Usuário */),
        value: '_nameUser',
      },
      {
        label: I18n.t('BEE422' /* Quantidade Conferida */),
        value: 'quantityCheck',
      },
    ];

    this.state = {
      branchCodeFrom: '',
      branchCodeTo: '',
      checkDateFrom: '',
      checkDateTo: '',
      checkProductUserFrom: '',
      checkProductUserTo: '',
      checkProductUsernameFrom: '',
      checkProductUsernameTo: '',
    };
  }

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      branchCodeFrom,
      branchCodeTo,
      checkDateFrom,
      checkDateTo,
      checkProductUserFrom,
      checkProductUserTo,
      checkProductUsernameFrom,
      checkProductUsernameTo,
    } = this.state;

    const { location } = this.props;

    const filters = returnFiltersByRules([
      ['$code', {
        type: 'between', value: [branchCodeFrom, branchCodeTo], instance: 'Branch', as: 'inboundOrder->branch',
      }],
      ['checkProductAt', { type: 'between', value: [checkDateFrom, checkDateTo] }],
      ['$login', {
        type: 'between', value: [checkProductUserFrom, checkProductUserTo], instance: 'User', as: 'checkUser',
      }],
      ['$name', {
        type: 'between', value: [checkProductUsernameFrom, checkProductUsernameTo], instance: 'User', as: 'checkUser',
      }],
    ]);

    this.props.generateReport(
      I18n.t('BEE1886' /* Conferência Recebimento Qtd Produtos por Conferente */).toLowerCase(),
      'xlsx',
      location.pathname,
      this.options,
      filters,
    );
  };

  onSelectFilter = (newState) => {
    console.log(newState);
    this.setState({ ...newState });
  };

  render() {
    const {
      branchCodeFrom,
      branchCodeTo,
      checkDateFrom,
      checkDateTo,
      checkProductUserFrom,
      checkProductUserTo,
      checkProductUsernameFrom,
      checkProductUsernameTo,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE1886' /* Conferência Recebimento Qtd Produtos por Conferente */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createDateInputRange(
                    { from: checkDateFrom, to: checkDateTo },
                    { from: 'checkDateFrom', to: 'checkDateTo' },
                    `${I18n.t('BEE467' /* Data Conferência */)}:`,
                    { from: '', to: '__/__/____' },
                  ),
                  formContext.createInputRange(
                    { from: checkProductUserFrom, to: checkProductUserTo },
                    { from: 'checkProductUserFrom', to: 'checkProductUserTo' },
                    `${I18n.t('BEE1748' /* Código Usuário */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: checkProductUsernameFrom, to: checkProductUsernameTo },
                    { from: 'checkProductUsernameFrom', to: 'checkProductUsernameTo' },
                    `${I18n.t('BEE1749' /* Nome Usuário */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMovementExpeditionCheckQtyProductsPerCheckerInbound);
