import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import DualList from '../../components/pages/DualList';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportMovementExpeditionCheckProductsRange extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          { I18n.t('BEE12' /* Início */)}
        </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE32' /* Estoque */) },
      { value: I18n.t('BEE1683' /* Conferência Qtd Produtos e Range */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE145' /* Filial */),
        value: 'outboundOrder.branchCode',
      }, {
        label: I18n.t('BEE342' /* Fornecedor */),
        value: 'outboundOrder.customerCode',
      }, {
        label: I18n.t('BEE1027' /* Número do documento */),
        value: 'outboundOrder.orderNumber',
      }, {
        label: I18n.t('BEE200' /* Tipo */),
        value: 'outboundOrder.orderType',
      }, {
        label: I18n.t('BEE613' /* Urgente */),
        value: 'outboundOrder.urgent',
      }, {
        label: I18n.t('BEE1095' /* Pedido parcial */),
        value: 'outboundOrder.partialOrder',
      }, {
        label: I18n.t('BEE301' /* Transportadora */),
        value: 'outboundOrder.carrierCode',
      }, {
        label: I18n.t('BEE224' /* Status */),
        value: 'outboundOrder.status',
      }, {
        label: I18n.t('BEE444' /* Data de Entrega */),
        value: 'outboundOrder.deliveryDate',
      }, {
        label: I18n.t('BEE1098' /* Tipo de Entrega */),
        value: 'outboundOrder.deliveryType',
      }, {
        label: I18n.t('BEE1096' /* Pedido cliente */),
        value: 'outboundOrder.customerOrderCode',
      }, {
        label: I18n.t('BEE1686' /* Data Ordem */),
        value: 'outboundOrder.orderDate',
      }, {
        label: I18n.t('BEE1687' /* Valor Total */),
        value: 'outboundOrder.totalValue',
      }, {
        label: I18n.t('BEE3922' /* Valor da Mercadoria */),
        value: 'outboundOrder.orderValue',
      }, {
        label: I18n.t('BEE1100' /* Cliente de entrega */),
        value: 'outboundOrder.shipToCustomerCode',
      }, {
        label: I18n.t('BEE1101' /* Nome entrega */),
        value: 'outboundOrder.shipToCustomerName',
      }, {
        label: I18n.t('BEE1102' /* CEP Entrega */),
        value: 'outboundOrder.shipToPostalCode',
      }, {
        label: I18n.t('BEE1715' /* Código IBGE de Envio */),
        value: 'outboundOrder.shipToIbgeCode',
      }, {
        label: I18n.t('BEE1103' /* Rua entrega */),
        value: 'outboundOrder.shipToStreet',
      }, {
        label: I18n.t('BEE1104' /* Número entrega */),
        value: 'outboundOrder.shipToNumber',
      }, {
        label: I18n.t('BEE1105' /* Complemento entrega */),
        value: 'outboundOrder.shipToComplement',
      }, {
        label: I18n.t('BEE1106' /* Bairro entrega */),
        value: 'outboundOrder.shipToDistrict',
      }, {
        label: I18n.t('BEE1107' /* Cidade entrega */),
        value: 'outboundOrder.shipToCity',
      }, {
        label: I18n.t('BEE1108' /* Estado entrega */),
        value: 'outboundOrder.shipToState',
      }, {
        label: I18n.t('BEE1109' /* País entrega */),
        value: 'outboundOrder.shipToCountry',
      }, {
        label: I18n.t('BEE1395' /* Início Separação */),
        value: 'outboundOrder.pickStartAt',
      }, {
        label: I18n.t('BEE1722' /* Usuário Inicio Separação */),
        value: 'outboundOrder.pickStartUser',
      }, {
        label: I18n.t('BEE1430' /* Fim Separação */),
        value: 'outboundOrder.pickEndAt',
      }, {
        label: I18n.t('BEE1716' /* Usuário Final Separação */),
        value: 'outboundOrder.pickEndUser',
      }, {
        label: I18n.t('BEE1421' /* Início Conferência */),
        value: 'outboundOrder.checkStartAt',
      }, {
        label: I18n.t('BEE1422' /* Usuário Conferência */),
        value: 'outboundOrder.checkStartUser',
      }, {
        label: I18n.t('BEE451' /* Data Fim Conferência */),
        value: 'outboundOrder.checkEndAt',
      }, {
        label: I18n.t('BEE450' /* Usuário Fim Conferência */),
        value: 'outboundOrder.checkEndUser',
      }, {
        label: I18n.t('BEE135' /* Observação */),
        value: 'outboundOrder.note',
      },
      // Colunas de outboundOrderProducts
      {
        label: I18n.t('BEE1717' /* ID Documento Saída */),
        value: 'products.outboundOrderId',
      },
      {
        label: I18n.t('BEE1689' /* Numero Linha */),
        value: 'products.lineNumber',
      },
      {
        label: I18n.t('BEE378' /* Código do Produto */),
        value: 'products.productCode',
      },
      {
        label: I18n.t('BEE382' /* Código do Produto do Cliente */),
        value: 'products.customerProductCode',
      },
      {
        label: I18n.t('BEE1688' /* Quantidade */),
        value: 'products.quantity',
      },
      {
        label: I18n.t('BEE1718' /* Lance Minimo */),
        value: 'products.minimumQuantity',
      },
      {
        label: I18n.t('BEE1579' /* Lance Obrigatório */),
        value: 'products.mandatoryQuantity',
      },
      {
        label: I18n.t('BEE1115' /* Valor */),
        value: 'products.value',
      },
      {
        label: I18n.t('BEE3922' /* Valor da Mercadoria */),
        value: 'products.orderValue',
      },
      {
        label: I18n.t('BEE224' /* Status */),
        value: 'products.status',
      },
      {
        label: I18n.t('BEE1719' /* Etiqueta Batismo */),
        value: 'products.baptismLabel',
      },
      {
        label: I18n.t('BEE1720' /* Endereço Separação */),
        value: 'products.storageAddressPicked',
      },
      {
        label: I18n.t('BEE1134' /* Quantidade Separada */),
        value: 'products.pickedQuantity',
      },
      {
        label: I18n.t('BEE1721' /* Data Separação */),
        value: 'products.pickedAt',
      },
      {
        label: I18n.t('BEE1410' /* Usuário Separação */),
        value: 'products.pickedUser',
      },
      {
        label: I18n.t('BEE1368' /* Separado */),
        value: 'products.picked',
      },
      {
        label: I18n.t('BEE422' /* Quantidade Conferida */),
        value: 'products.checkedQuantity',
      },
      {
        label: I18n.t('BEE467' /* Data Conferência */),
        value: 'products.checkedAt',
      },
      {
        label: I18n.t('BEE466' /* Usuário Conferência */),
        value: 'products.checkedUser',
      },
      {
        label: I18n.t('BEE445' /* Conferido */),
        value: 'products.checked',
      },
      {
        label: I18n.t('BEE135' /* Observação */),
        value: 'products.note',
      },
      {
        label: I18n.t('BEE575' /* Usuário de criação */),
        value: 'products.createdUser',
      },
      {
        label: I18n.t('BEE578' /* Data de criação */),
        value: 'products.createdAt',
      },
      {
        label: I18n.t('BEE576' /* Usuário de alteração */),
        value: 'products.updatedUser',
      },
      {
        label: I18n.t('BEE577' /* Data de alteração */),
        value: 'products.updatedAt',
      },
    ];

    this.state = {
      selected: [],
      branchCodeFrom: '',
      branchCodeTo: '',
      orderNumberFrom: '',
      orderNumberTo: '',
      deliveryDateFrom: '',
      deliveryDateTo: '',
      conferenceUserFrom: '',
      conferenceUserTo: '',
      productCodeFrom: '',
      productCodeTo: '',
    };
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      orderNumberFrom,
      orderNumberTo,
      deliveryDateFrom,
      deliveryDateTo,
      conferenceUserFrom,
      conferenceUserTo,
      productCodeFrom,
      productCodeTo,
    } = this.state;

    const { location } = this.props;

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['orderNumber', { type: 'between', value: [orderNumberFrom, orderNumberTo] }],
      ['deliveryDate', { type: 'between', value: [deliveryDateFrom, deliveryDateTo] }],
      ['login', {
        type: 'between', value: [conferenceUserFrom, conferenceUserTo], instance: 'User', as: 'checkedUserObj',
      }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
    ]);
    this.props.generateReport(
      I18n.t('BEE1683' /* Conferência Qtd Produtos e Range */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  onSelectFilter = (newState) => {
    console.log(newState);
    this.setState({ ...newState });
  };

  render() {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      orderNumberFrom,
      orderNumberTo,
      deliveryDateFrom,
      deliveryDateTo,
      conferenceUserFrom,
      conferenceUserTo,
      productCodeFrom,
      productCodeTo,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE1683' /* Conferência Qtd Produtos e Range */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: orderNumberFrom, to: orderNumberTo },
                    { from: 'orderNumberFrom', to: 'orderNumberTo' },
                    `${I18n.t('BEE1027' /* Número do documento */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createDateInputRange(
                    { from: deliveryDateFrom, to: deliveryDateTo },
                    { from: 'deliveryDateFrom', to: 'deliveryDateTo' },
                    `${I18n.t('BEE444' /* Data de Entrega */)}:`,
                    { from: '', to: '__/__/____' },
                  ),
                  formContext.createInputRange(
                    { from: conferenceUserFrom, to: conferenceUserTo },
                    { from: 'conferenceUserFrom', to: 'conferenceUserTo' },
                    `${I18n.t('BEE466' /* Usuário Conferência */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE378' /* Código do Produto */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
                )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
                )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (nameDoc, type, route, columns, filters) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMovementExpeditionCheckProductsRange);
