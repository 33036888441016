const INITIAL_STATE = {
  storageAddressesList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_STORAGE_ADDRESSES': {
      return { ...state, storageAddressesList: action.storageAddresses };
    }
    default:
      return state;
  }
};
