import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';

import { Panel, PanelBody } from '../../../components/panel/panel';
import DateRangePicker from '../../../components/date/DateRangePicker.jsx';

import { PopoverHeader, UncontrolledPopover } from 'reactstrap';

import List from './list';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';

class OutboundMonitor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'pendingCut',
      startDate: moment().subtract(30, 'days'),
      endDate: moment(),
      currentRange: `${moment().subtract(30, 'days').format('DD MMM YYYY')} - ${moment().format('DD MMM YYYY')}`,
      usesCableCuttingPlan: false,
    };

    this.styleNavLink = {
      flexGrow: 1,
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'center',
    };

    this.styleSpan = {
      height: '36px',
    };
  }

  async componentDidMount() {
    await this.getUsesCableCuttingplan();
  }

  async componentDidUpdate(prevProps) {
    if ((prevProps.user && prevProps.user.mainBranchData) !== (this.props.user && this.props.user.mainBranchData)) {
      await this.getUsesCableCuttingplan();
    }
  }

  getUsesCableCuttingplan = async () => {
    const { user } = this.props;

    if (user && user.mainBranchData) {
      const { mainBranchData } = user;
      const { usesCableCuttingPlan } = mainBranchData;

      this.setState({
        usesCableCuttingPlan,
        activeTab: usesCableCuttingPlan ? 'pendingCut' : 'pendingPicking',
      });
    }
  };

  toggleFilter = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  handleDateApplyEvent = async (event) => {
    const startDate = moment(event[0]);
    const endDate = moment(event[1]);
    const currentRange = `${startDate.format('DD MMM YYYY')} - ${endDate.format('DD MMM YYYY')}`;

    await this.setState({
      startDate,
      endDate,
      currentRange,
    });
  };

  render() {
    const {
      activeTab, startDate, endDate, currentRange, usesCableCuttingPlan,
    } = this.state;

    const {
      styleNavLink, styleSpan,
    } = this;

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1363' /* Monitor de Expedição */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE1363' /* Monitor de Expedição */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.OUTBOUND_MONITOR_HELP} />
        </div>
        <div className="row m-l-1 mb-3">
          <DateRangePicker
            onChange={this.handleDateApplyEvent}
          >
            <button type="button" className="btn btn-inverse mr-2 text-truncate">
              <i className="fa fa-calendar fa-fw text-white-transparent-5 ml-n1" />
              <span>{currentRange}</span>
              <b className="caret" />
            </button>
          </DateRangePicker>
          <UncontrolledPopover trigger="hover" placement="top" target="dateRangePicker">
            <PopoverHeader>
              { I18n.t('BEE3342', { 0:(I18n.t('BEE3344' /* data de criação */)) } /* O intervalo segue o critério pela %{0} */) }
            </PopoverHeader>
          </UncontrolledPopover>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <Panel>
              <PanelBody>
                <Nav tabs fill style={{ backgroundColor: 'lightgray' }}>
                  {usesCableCuttingPlan
                    && (
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === 'pendingCut' ? 'active' : ''}
                        onClick={() => {
                          this.toggleFilter('pendingCut');
                        }}
                        style={styleNavLink}
                      >
                        <span className="d-sm-none">pendingCut</span>
                        <span
                          className="d-sm-block d-none"
                          style={styleSpan}
                        >
                          {I18n.t('BEE1420' /* Corte de Cabos */)}
                        </span>
                      </NavLink>
                    </NavItem>
                    )}
                  <NavItem style={{ display: 'flex' }}>
                    <NavLink
                      className={this.state.activeTab === 'pendingPicking' ? 'active' : ''}
                      onClick={() => {
                        this.toggleFilter('pendingPicking');
                      }}
                      style={styleNavLink}
                    >
                      <span className="d-sm-none">pendingPicking</span>
                      <span
                        className="d-sm-block d-none"
                        style={styleSpan}
                      >
                        {I18n.t('BEE1365' /* Pendente Separação */)}

                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ display: 'flex' }}>
                    <NavLink
                      className={this.state.activeTab === 'picking' ? 'active' : ''}
                      onClick={() => {
                        this.toggleFilter('picking');
                      }}
                      style={styleNavLink}
                    >
                      <span className="d-sm-none">picking</span>
                      <span
                        style={styleSpan}
                        className="d-sm-block d-none"
                      >
                        {I18n.t('BEE1367' /* Em Separação */)}

                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ display: 'flex' }}>
                    <NavLink
                      className={this.state.activeTab === 'picked' ? 'active' : ''}
                      onClick={() => {
                        this.toggleFilter('picked');
                      }}
                      style={styleNavLink}
                    >
                      <span className="d-sm-none">picked</span>
                      <span
                        style={styleSpan}
                        className="d-sm-block d-none"
                      >
                        {I18n.t('BEE1368' /* Separado */)}

                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ display: 'flex' }}>
                    <NavLink
                      className={this.state.activeTab === 'check' ? 'active' : ''}
                      onClick={() => {
                        this.toggleFilter('check');
                      }}
                      style={styleNavLink}
                    >
                      <span className="d-sm-none">check</span>
                      <span
                        style={styleSpan}
                        className="d-sm-block d-none"
                      >
                        {I18n.t('BEE37' /* Conferência */)}

                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ display: 'flex' }}>
                    <NavLink
                      className={this.state.activeTab === 'withdrawStore' ? 'active' : ''}
                      onClick={() => {
                        this.toggleFilter('withdrawStore');
                      }}
                      style={styleNavLink}
                    >
                      <span className="d-sm-none">withdrawStore</span>
                      <span
                        style={styleSpan}
                        className="d-sm-block d-none"
                      >
                        {I18n.t('BEE2963' /* Pendente de Faturamento */)}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ display: 'flex' }}>
                    <NavLink
                      className={this.state.activeTab === 'dockControl' ? 'active' : ''}
                      onClick={() => {
                        this.toggleFilter('dockControl');
                      }}
                      style={styleNavLink}
                    >
                      <span className="d-sm-none">dockControl</span>
                      <span
                        style={styleSpan}
                        className="d-sm-block d-none"
                      >
                        {I18n.t('BEE199' /* Doca */)}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ display: 'flex' }}>
                    <NavLink
                      className={this.state.activeTab === 'packingList' ? 'active' : ''}
                      onClick={() => {
                        this.toggleFilter('packingList');
                      }}
                      style={styleNavLink}
                    >
                      <span className="d-sm-none">packingList</span>
                      <span
                        style={styleSpan}
                        className="d-sm-block d-none"
                      >
                        {I18n.t('BEE1369' /* Embarque em Romaneio */)}

                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ display: 'flex' }}>
                    <NavLink
                      className={this.state.activeTab === 'consolidation' ? 'active' : ''}
                      onClick={() => {
                        this.toggleFilter('consolidation');
                      }}
                      style={styleNavLink}
                    >
                      <span className="d-sm-none">consolidation</span>
                      <span
                        style={styleSpan}
                        className="d-sm-block d-none"
                      >
                        {I18n.t('BEE1046' /* Consolidação */)}

                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ display: 'flex' }}>
                    <NavLink
                      className={this.state.activeTab === 'divergence' ? 'active' : ''}
                      onClick={() => {
                        this.toggleFilter('divergence');
                      }}
                      style={styleNavLink}
                    >
                      <span className="d-sm-none">divergence</span>
                      <span
                        style={styleSpan}
                        className="d-sm-block d-none"
                      >
                        {I18n.t('BEE176' /* Divergência */)}

                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent style={{ padding: '0px', paddingBlock: '15px' }} activeTab={activeTab}>
                  <TabPane tabId="pendingCut">
                    {activeTab === 'pendingCut'
                      ? (
                        <List
                          startDate={startDate}
                          endDate={endDate}
                          currentRange={currentRange}
                          activeTab={activeTab}
                        />
                      )
                      : null}
                  </TabPane>
                  <TabPane tabId="pendingPicking">
                    {activeTab === 'pendingPicking'
                      ? (
                        <List
                          startDate={startDate}
                          endDate={endDate}
                          currentRange={currentRange}
                          activeTab={activeTab}
                        />
                      )
                      : null}
                  </TabPane>
                  <TabPane tabId="picking">
                    {activeTab === 'picking'
                      ? (
                        <List
                          startDate={startDate}
                          endDate={endDate}
                          currentRange={currentRange}
                          activeTab={activeTab}
                        />
                      )
                      : null}
                  </TabPane>
                  <TabPane tabId="divergence">
                    {activeTab === 'divergence'
                      ? (
                        <List
                          startDate={startDate}
                          endDate={endDate}
                          currentRange={currentRange}
                          activeTab={activeTab}
                        />
                      )
                      : null}
                  </TabPane>
                  <TabPane tabId="withdrawStore">
                    {activeTab === 'withdrawStore'
                      ? (
                        <List
                          startDate={startDate}
                          endDate={endDate}
                          currentRange={currentRange}
                          activeTab={activeTab}
                        />
                      )
                      : null}
                  </TabPane>
                  <TabPane tabId="picked">
                    {activeTab === 'picked'
                      ? (
                        <List
                          startDate={startDate}
                          endDate={endDate}
                          currentRange={currentRange}
                          activeTab={activeTab}
                        />
                      )
                      : null}
                  </TabPane>
                  <TabPane tabId="check">
                    {activeTab === 'check'
                      ? (
                        <List
                          startDate={startDate}
                          endDate={endDate}
                          currentRange={currentRange}
                          activeTab={activeTab}
                        />
                      )
                      : null}
                  </TabPane>
                  <TabPane tabId="dockControl">
                    {activeTab === 'dockControl'
                      ? (
                        <List
                          startDate={startDate}
                          endDate={endDate}
                          currentRange={currentRange}
                          activeTab={activeTab}
                        />
                      )
                      : null}
                  </TabPane>
                  <TabPane tabId="consolidation">
                    {activeTab === 'consolidation'
                      ? (
                        <List
                          startDate={startDate}
                          endDate={endDate}
                          currentRange={currentRange}
                          activeTab={activeTab}
                        />
                      )
                      : null}
                  </TabPane>
                  <TabPane tabId="packingList">
                    {activeTab === 'packingList'
                      ? (
                        <List
                          startDate={startDate}
                          endDate={endDate}
                          currentRange={currentRange}
                          activeTab={activeTab}
                        />
                      )
                      : null}
                  </TabPane>
                </TabContent>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.app.userLogged,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundMonitor));
