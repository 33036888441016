/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import InboundStorageRequests from '../../server/inboundStorage';
import SerialGroupRequests from '../../server/serialGroup';
import { addLoading, removeLoading } from './loading';

export const setInboundStorageProducts = (inboundStorageProducts) => ({
  type: 'SET_INBOUND_STORAGE_PRODUCTS',
  inboundStorageProducts,
});

export const getInboundStorageProducts = (baptismLabel) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const inboundStorageProducts = await InboundStorageRequests.getInboundStorageProducts(baptismLabel);
    dispatch(setInboundStorageProducts(inboundStorageProducts));
    return inboundStorageProducts;
  } finally {
    dispatch(removeLoading());
  }
};

export const getInboundStorageProductByEan = (ean, sequence, baptismLabel) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundStorageRequests.getInboundStorageProductByEan(ean, sequence, baptismLabel);
  } finally {
    dispatch(removeLoading());
  }
};

export const putConfirmStorage = (
  baptism,
  inboundOrderProductId,
  addressSelected,
  quantity,
  lotNumber,
  sequence,
  productLotId,
  productPartialId,
  divergenceNote,
  divergenceStorage,
  expirationDate,
  serialGroupId,
  depositCode,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundStorageRequests.confirmStorage(
      baptism,
      inboundOrderProductId,
      addressSelected,
      quantity,
      lotNumber,
      sequence,
      productLotId,
      productPartialId,
      divergenceNote,
      divergenceStorage,
      expirationDate,
      serialGroupId,
      depositCode,
    );
  } finally {
    dispatch(removeLoading());
  }
};

export const putConfirmStorageGroup = (
  baptism,
  inboundOrderProductIds,
  addressSelected,
  quantity,
  lotNumber,
  sequence,
  productLotIds,
  productPartialIds,
  divergenceNote,
  divergenceStorage,
  expirationDate,
  serialGroupId,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const results = [];
    for (let i = 0; i < inboundOrderProductIds.length; i++) {
      const result = await InboundStorageRequests.confirmStorage(
        baptism,
        inboundOrderProductIds[i],
        addressSelected,
        quantity,
        lotNumber,
        sequence,
        productLotIds[i],
        productPartialIds[i],
        divergenceNote,
        divergenceStorage,
        expirationDate,
        serialGroupId,
      );
      results.push(result);
    }
    const failedRequest = results.find((r) => r && r.success === false);

    return failedRequest || results[0];
  } finally {
    dispatch(removeLoading());
  }
};

export const getSerialGroup = (barCode, inboundOrderId, uz) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SerialGroupRequests.getSerialGroup(barCode, 'store', inboundOrderId, uz);
  } finally {
    dispatch(removeLoading());
  }
};

export const syncStorage = (inboundOrderProductId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return InboundStorageRequests.syncStorage(inboundOrderProductId);
  } finally {
    dispatch(removeLoading());
  }
};
