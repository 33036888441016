import React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import JSONPretty from 'react-json-pretty';

export default class modalIntegrationInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
    };
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    const {
      openInformationModal, dataSend, responseData, closeModal,
    } = this.props;

    return (
      <Modal size="lg" isOpen={openInformationModal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>{I18n.t('BEE2234' /* Dados da Integração */)}</ModalHeader>
        <ModalBody>
          <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
            <NavItem>
              <NavLink
                className={this.state.activeTab === '1' ? 'active' : ''}
                onClick={() => this.toggleTab('1')}
              >
                <span className="d-sm-block d-none">
                  {I18n.t('BEE2232' /* Enviado */)}
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab === '2' ? 'active' : ''}
                onClick={() => this.toggleTab('2')}
              >
                <span className="d-sm-block d-none">
                  {I18n.t('BEE2233' /* Retorno */)}
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <h5>
                <JSONPretty id="json-pretty" data={dataSend} />
              </h5>
            </TabPane>
            <TabPane tabId="2">
              <h5>
                <JSONPretty id="json-pretty" data={responseData} />
              </h5>
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-120 btn-secondary p-5 m-5"
            onClick={closeModal}
          >
            {I18n.t('BEE553' /* Fechar */)}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}
