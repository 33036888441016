import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import _ from 'lodash';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { getDetailOrder } from '../../../app/store/actions/returnPickingOrder';
import Table from '../../../components/table/Table';
import { Panel } from '../../../components/panel/panel';
import addNotification from '../../../components/notification';
import OutboundOrderHelpers from '../../../helpers/outboundOrder';

class OrderSearchModa extends React.Component {
  constructor(props) {
    super(props);

    this.tableColumns = [
      {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE381' /* Código do Cliente */),
        accessor: 'customerCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE1089' /* Nome do Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE85' /* CNPJ */),
        accessor: 'customerCNPJ',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{OutboundOrderHelpers.outboundOrderStatus(row.value)}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OutboundOrderHelpers.outboundOrderStatus().map((element, index) => (
              <option key={index} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE1721' /* Data Separação */),
        accessor: 'pickStartAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{moment(row.value).format('L LTS')}</span>
          </div>
        ),
      },
    ];

    this.state = {
      outboundOrderList: [],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
    };
  }

  componentDidMount() {
    this.getOrderList();
  }

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });
    this.getOrderList();
  };

  getOrderList = async () => {
    const { startDate, endDate } = this.state;
    try {
      const orderByDate = await this.props.getDetailOrder(
        moment(startDate).format(),
        moment(endDate).format(),
      );

      if (orderByDate && orderByDate.success === false) {
        // addNotification('danger', I18n.t('BEE1882' /* Monitor de Onda */),
        // I18n.t('BEE1901' /* Nenhum registro encontrado dentro do range de datas */), 'top-right');

      } else {
        this.setState({ outboundOrderList: orderByDate });
      }
    } catch (error) {
      const messageError = error.response && error.response.data
      && error.response.data.error && error.response.data.error.message;

      if (messageError) {
        addNotification(
          'danger',
          I18n.t('BEE908' /* Devolução de Picking */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE908' /* Devolução de Picking */),
          I18n.t('BEE1902' /* Não foi localizar os documentos */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      outboundOrderList,
    } = this.state;
    const { showOrderSearch = false } = this.props;

    return (
      <Panel>
        <Modal size="xl" isOpen={showOrderSearch} toggle={() => this.props.toggleOrderSearchModal()}>
          <ModalHeader toggle={() => this.props.toggleOrderSearchModal()}>
            {I18n.t('BEE1917' /* Buscar Documentos para Devolução de Picking */)}
          </ModalHeader>
          <ModalBody>
            <Table
              panelHeaderProps={{ noButton: true }}
              datePicker={{
                handleDateApplyEvent: this.handleDateApplyEvent,
              }}
              filterable
              data={outboundOrderList}
              columns={this.tableColumns}
              expander
              defaultPageSize={5}
              defaultSorted={this.defaultSorted}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
              getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: async () => {
                      await this.props.detailOrderSelected(rowInfo.original);
                    },
                    style: { cursor: 'pointer' },
                  };
                }
                return {};
              }}
            />
          </ModalBody>
        </Modal>
      </Panel>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getDetailOrder: (filterStartDate, filterEndDate) => dispatch(getDetailOrder(filterStartDate, filterEndDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderSearchModa));
