import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import DualList from '../../components/pages/DualList';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportMovementExpeditionVolumeDocumentList extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
        </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE2270' /* Volumes por Documento */), active: true },
    ];

    this.options = [{
      label: I18n.t('BEE145' /* Filial */),
      value: 'branchCode',
    }, {
      label: I18n.t('BEE51' /* Pedido */),
      value: 'erpOrderCode',
    }, {
      label: I18n.t('BEE443' /* Documento */),
      value: 'orderNumber',
    }, {
      label: I18n.t('BEE2273' /* Tipo do Documento */),
      value: 'orderType',
    }, {
      label: I18n.t('BEE2197' /* Sequência do Volume */),
      value: 'sequence',
    }, {
      label: I18n.t('BEE2271' /* Código da Embalagem */),
      value: 'packageCode',
    }, {
      label: I18n.t('BEE2272' /* Código da Transportadora */),
      value: 'carrierCode',
    }, {
      label: I18n.t('BEE1391' /* Nome Transportadora */),
      value: 'carrierName',
    }, {
      label: I18n.t('BEE2038' /* Usuário Movimentação */),
      value: 'createdUser',
    }, {
      label: I18n.t('BEE2039' /* Data Movimentação */),
      value: 'createdAt',
    }, {
      label: I18n.t('BEE907' /* Romaneio */),
      value: 'waybills',
    }, {
      label: I18n.t('BEE2275' /* Código Doca */),
      value: 'dockCode',
    }, {
      label: I18n.t('BEE576' /* Usuário de Alteração */),
      value: 'updatedUser',
    }, {
      label: I18n.t('BEE577' /* Data de Alteração */),
      value: 'updatedAt',
    }, {
      label: I18n.t('BEE2276' /* Status do Volume */),
      value: 'status',
    }];

    this.state = {
      selected: [],
      branchCodeFrom: '',
      branchCodeTo: '',
      erpOrderCodeFrom: '',
      erpOrderCodeTo: '',
      orderNumberFrom: '',
      orderNumberTo: '',
      carrierCodeFrom: '',
      carrierCodeTo: '',
    };
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const { location } = this.props;
    const {
      selected,
      branchCodeFrom, branchCodeTo,
      erpOrderCodeFrom, erpOrderCodeTo,
      orderNumberFrom, orderNumberTo,
      carrierCodeFrom, carrierCodeTo,
    } = this.state;

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['$erpOrderCode', {
        type: 'between', value: [erpOrderCodeFrom, erpOrderCodeTo], instance: 'OutboundOrder', as: 'outboundOrder',
      }],
      ['$orderNumber', {
        type: 'between', value: [orderNumberFrom, orderNumberTo], instance: 'OutboundOrder', as: 'outboundOrder',
      }],
      ['$code', {
        type: 'between', value: [carrierCodeFrom, carrierCodeTo], instance: 'Carrier', as: 'outboundOrder->carrier',
      }],
    ]);

    this.props.generateReport(
      I18n.t('BEE2270' /* Volumes por Documento */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  onSelectFilter = (newState) => this.setState({ ...newState });

  render() {
    const { location = {} } = this.props;
    const {
      selected,
      branchCodeFrom, branchCodeTo,
      erpOrderCodeFrom, erpOrderCodeTo,
      orderNumberFrom, orderNumberTo,
      carrierCodeFrom, carrierCodeTo,
    } = this.state;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE2270' /* Volumes por Documento */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ), formContext.createInputRange(
                    { from: erpOrderCodeFrom, to: erpOrderCodeTo },
                    { from: 'erpOrderCodeFrom', to: 'erpOrderCodeTo' },
                    `${I18n.t('BEE51' /* Pedido */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ), formContext.createInputRange(
                    { from: orderNumberFrom, to: orderNumberTo },
                    { from: 'orderNumberFrom', to: 'orderNumberTo' },
                    `${I18n.t('BEE443' /* Documento */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: carrierCodeFrom, to: carrierCodeTo },
                    { from: 'carrierCodeFrom', to: 'carrierCodeTo' },
                    `${I18n.t('BEE301' /* Transportadora */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMovementExpeditionVolumeDocumentList);
