import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from 'lodash';

import Table from '../../../components/table/Table';


class OutboundOrdersTable extends React.Component {
  constructor(props) {

    super(props);

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: rows => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs"></DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => this.setState({ openModal: true, selectedDocument: rows.row._original })}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        )
      }, {
        Header: I18n.t('BEE1378' /* Documento */),
        accessor: 'outboundOrder.orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150
      }, {
        Header: I18n.t('BEE1096' /* Pedido Cliente */),
        accessor: 'outboundOrder.customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'outboundOrder.customerBusinessName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: 'Nota Fiscal',
        accessor: 'outboundOrder.invoiceNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: 'Entrega',
        accessor: 'outboundOrder.deliveryType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }
    ];

    this.state = {
      openModal: false,
      selectedDocument: {},
      defaultPageSize: 5,
      pageSizeOptions: [5, 10],
      defaultSorted: [{ id: 'orderNumber', desc: false }],
    };

  };

  render() {
    const { defaultPageSize, defaultSorted, pageSizeOptions } = this.state;
    const { waybillOrdersList = [] } = this.props;

    return (
      <>
        <Table
          filterable
          expander
          downloadCSV
          panelHeaderProps={{
            noButton: true,
            children: I18n.t('BEE462' /* Documentos */).toUpperCase(),
          }}
          data={waybillOrdersList}
          columns={this.tableColumns}
          defaultPageSize={defaultPageSize}
          pageSizeOptions={pageSizeOptions}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
      </>
    );
  };

};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundOrdersTable));