import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import Table from '../../../components/table/Table';
import InventoryHelpers from '../../../helpers/inventory';

export default class InventorySheetTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultPageSize: 5,
      defaultSorted: [{ id: 'sheetNumber', desc: false }],
    };

    this.tableColumns = [{
      Header: I18n.t('BEE2106' /* Ficha */),
      accessor: 'sheetNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
    }, {
      Header: I18n.t('BEE267' /* Endereço */),
      accessor: 'addressCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 140,
    }, {
      Header: I18n.t('BEE225' /* Produto */),
      accessor: 'productCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE204' /* Situação */),
      accessor: 'status',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{InventoryHelpers.inventorySheetStatus(row.value)}</span>
        </div>
      ),
      filterMethod: (filter, row) => {
        if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
          return true;
        }
      },
      Filter: ({ onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={(event) => { onChange(event.target.value); }}
        >
          <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
          {InventoryHelpers.inventorySheetStatus().map((elem, index) => (
            <option value={elem.value} key={index}>{elem.label}</option>
          ))}
        </select>
      ),
    }, {
      Header: I18n.t('BEE182' /* Depósito */),
      accessor: 'depositName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
    }, {
      Header: I18n.t('BEE428' /* Lote */),
      accessor: 'lotNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE434' /* Data de Validade */),
      accessor: 'expirationDate',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 140,
      filterMethod: (filter, row) => {
        const input = _.lowerCase(filter.value);
        const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
        const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
        if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
          return true;
        }
      },
    }, {
      Header: I18n.t('BEE2108', { 0: '1' } /* %{0} Contagem */),
      columns: [{
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'firstCountQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
      }, {
        Header: I18n.t('BEE515' /* Saldo */),
        accessor: 'firstCountBalance',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
      }, {
        Header: I18n.t('BEE2152' /* Diferença */),
        accessor: 'firstCountDifference',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
      }, {
        Header: I18n.t('BEE1' /* Usuário */),
        accessor: 'firstCountUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE3209' /* Data */),
        accessor: 'firstCountAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }],
    }, {
      Header: I18n.t('BEE2108', { 0: '2' } /* %{0} Contagem */),
      columns: [{
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'secondCountQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
      }, {
        Header: I18n.t('BEE515' /* Saldo */),
        accessor: 'secondCountBalance',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
      }, {
        Header: I18n.t('BEE2152' /* Diferença */),
        accessor: 'secondCountDifference',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
      }, {
        Header: I18n.t('BEE1' /* Usuário */),
        accessor: 'secondCountUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE3209' /* Data */),
        accessor: 'secondCountAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }],
    }, {
      Header: I18n.t('BEE2108', { 0: '3' } /* %{0} Contagem */),
      columns: [{
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'thirdCountQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
      }, {
        Header: I18n.t('BEE515' /* Saldo */),
        accessor: 'thirdCountBalance',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
      }, {
        Header: I18n.t('BEE2152' /* Diferença */),
        accessor: 'thirdCountDifference',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
      }, {
        Header: I18n.t('BEE1' /* Usuário */),
        accessor: 'thirdCountUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE3209' /* Data */),
        accessor: 'thirdCountAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }],
    }, {
      Header: I18n.t('BEE1854' /* Setor */),
      accessor: 'sector',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE121' /* Rua */),
      accessor: 'street',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE281' /* Coluna */),
      accessor: 'column',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE283' /* Nível */),
      accessor: 'level',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE285' /* Gaveta */),
      accessor: 'drawer',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }];
  }

  handleClickLine = (row) => {
    this.props.selectLineSheet(row);
  };

  render() {
    const {
      defaultPageSize, defaultSorted,
    } = this.state;

    const { listSheets, selectedInventorySheet } = this.props;

    return (
      <Table
        panelHeaderProps={{
          children: I18n.t('BEE2184' /* Fichas */).toUpperCase(),
        }}
        downloadCSV
        filterable
        data={listSheets}
        columns={this.tableColumns}
        expander
        defaultPageSize={defaultPageSize}
        defaultSorted={defaultSorted}
        defaultFilterMethod={(filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row[filter.id]);
          if (_.includes(value, input)) {
            return true;
          }
        }}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: () => { this.handleClickLine(rowInfo.original); },
              style: {
                background: selectedInventorySheet
                  && rowInfo.original.inventorySheetId === selectedInventorySheet.inventorySheetId ? 'silver' : 'white',
                cursor: 'pointer',
              },
            };
          } return {};
        }}
      />
    );
  }
}
