import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { PanelPage } from '../../../components/pages/pages';
import Form from '../../../components/form/form';
import addNotification from '../../../components/notification';
import { getOutboundOrderCheckProductList } from '../../../app/store/actions/outboundOrderVolumes';
import OutboundConference from './outboundConference';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';

class OutboundOrderCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baptismLabel: '',
      userPermission: false,
      blockBaptismField: false,
      fetchData: true,
      outboundOrderCheckProductList: [],
      outboundVolumeOpen: null,
      mainPackage: null,
    };
  }

  setValue = async (attr, value) => {
    const { blockBaptismField, userPermission } = this.state;

    this.setState({
      [`${attr}`]: value,
      blockBaptismField: (attr === 'baptismLabel' && value === '' && userPermission)
        ? false
        : blockBaptismField,
    });
  };

  resetForm = async () => {
    this.setState({
      baptismLabel: '',
      userPermission: false,
      blockBaptismField: false,
      fetchData: true,
      outboundOrderCheckProductList: [],
      outboundVolumeOpen: null,
      mainPackage: null,
    });
  };

  keydownSendConfirmCheck = (e) => {
    if (e.keyCode === 13) this.searchConferenceProducts();
  };

  searchConferenceProducts = async () => {
    const { baptismLabel } = this.state;

    if (baptismLabel === '') {
      document.getElementById(1).focus();
      document.getElementById(1).select();
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1555' /* Campo (Batismo) deve ser informado. */),
        'top-right',
      );
    } else {
      try {
        const result = await this.props.getOutboundOrderCheckProductList(baptismLabel);

        if (result && result.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE1870' /* Erro ao localizar o batismo */),
            'top-right',
          );
        } else {
          this.setState({
            blockBaptismField: true,
            outboundOrderCheckProductList: result.listOutboundRangeProducts,
            outboundVolumeOpen: result.outboundVolumeOpen,
            mainPackage: result.mainPackage,
            fetchData: false,
          });
        }
      } catch (error) {
        const messageError = error.response && error.response.data
        && error.response.data.error && error.response.data.error.message;

        if (messageError) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE1870' /* Erro ao localizar o batismo */),
            'top-right',
          );
        }
      }
    }
  };

  render() {
    const {
      baptismLabel, blockBaptismField, fetchData, outboundOrderCheckProductList, outboundVolumeOpen,
      mainPackage,
    } = this.state;

    return (
      <>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE37' /* Conferência */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center">
          <h1 className="page-header mb-0">
            {I18n.t('BEE37' /* Conferência */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.OUTBOUND_CHECK_HELP} />
        </div>
        {fetchData && (
          <div onSubmit={(e) => e.preventDefault()}>
            <PanelPage
              noButton
              content={(
                <div className="col-md-4">
                  <Form
                    noPanel
                    leftType
                    setValue={this.setValue}
                    inputs={(formContext) => ([
                      formContext.createInputButton(
                        baptismLabel,
                        'baptismLabel',
                        I18n.t('BEE425' /* Batismo */),
                        '',
                        'text',
                        false,
                        blockBaptismField,
                        <i className="fa fa-search" />,
                        this.searchConferenceProducts,
                        this.keydownSendConfirmCheck,
                        1,
                      ),
                    ])}
                  />
                </div>
              )}
              footerContent={(
                <button
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={this.searchConferenceProducts}
                >
                  {I18n.t('BEE407' /* Buscar */)}
                </button>
              )}
            />
          </div>
        )}
        {!fetchData && (
          <OutboundConference
            searchConferenceProducts={() => this.searchConferenceProducts()}
            outboundOrderCheckProductList={outboundOrderCheckProductList}
            outboundVolumeOpen={outboundVolumeOpen}
            mainPackage={mainPackage}
            resetForm={() => this.resetForm()}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = () => ({

});

const mapDispatchToProps = (dispatch) => ({
  getOutboundOrderCheckProductList: (baptismLabel) => dispatch(getOutboundOrderCheckProductList(baptismLabel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundOrderCheck));
