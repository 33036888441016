import RestService from '../services/rest';

// GET
async function getListSerialLabels(filterStartDate, filterEndDate, page, pageSize, sorted, filtered) {
  return RestService.postAuthenticated('serialLabels/list', {
    filterStartDate, filterEndDate, page, pageSize, sorted, filtered,
  });
}

// POST
async function createSerialLabels(productionOrderId, quantityToPrint, printerSelected, expirationDate, typeLabel) {
  return RestService.postAuthenticated('serialLabels/create', {
    productionOrderId, quantityToPrint, printerSelected, expirationDate, typeLabel,
  });
}

// GET
async function getProductionOrderSerialLabels(orderCode, page, pageSize, sorted, filtered) {
  return RestService.postAuthenticated('serialLabels/listToGenerate', {
    orderCode, page, pageSize, sorted, filtered,
  });
}

export default {
  getListSerialLabels,
  getProductionOrderSerialLabels,
  createSerialLabels,
};
