import SerialGroupRequests from '../../server/serialGroup';
import { addLoading, removeLoading } from './loading';

export const getListSerialGroups = (
  filterStartDate,
  filterEndDate,
  page,
  pageSize,
  sorted,
  filtered,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SerialGroupRequests
      .getListSerialGroups(filterStartDate, filterEndDate, page, pageSize, sorted, filtered);
  } finally {
    dispatch(removeLoading());
  }
};

export const getSerialGroupDetail = (code, depositCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SerialGroupRequests.getSerialGroupDetail(code, depositCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const getSerialGroupOrSerialLabel = (code, depositCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SerialGroupRequests.getSerialGroupOrSerialLabel(code, depositCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const listSerialGroupsToPrint = (productionOrderCode, page, pageSize, sorted, filtered) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SerialGroupRequests
      .getListSerialGroupsToPrint(productionOrderCode, page, pageSize, sorted, filtered);
  } finally {
    dispatch(removeLoading());
  }
};

export default {
  getListSerialGroups,
  getSerialGroupDetail,
  getSerialGroupOrSerialLabel,
};
