import RestService from '../services/rest';

// GET

async function getDepositsOptionsList() {
  return RestService.getAuthenticated('deposits/list/depositsOptions');
}

async function getDeposits() {
  return RestService.getAuthenticated('deposits/list/all');
}

async function getDeposit(depositId) {
  return RestService.getAuthenticated(`deposits/detail?depositId=${depositId}`);
}

async function getDepositsOptions(filterDeposits = 0) {
  return RestService.getAuthenticated('deposits/list/options', { filterDeposits });
}

// POST
async function createDeposit(deposit) {
  return RestService.postAuthenticated('deposits/create', deposit);
}

async function deleteDeposit(depositId) {
  return RestService.postAuthenticated('deposits/delete', { depositId });
}

// PUT
async function updateDeposit(deposit) {
  return RestService.putAuthenticated('deposits/update', deposit);
}

export default {
  getDeposits,
  createDeposit,
  updateDeposit,
  getDeposit,
  deleteDeposit,
  getDepositsOptions,
  getDepositsOptionsList,
};
