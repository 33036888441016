import { I18n } from 'react-redux-i18n';

function printerType(type) {
  switch (type) {
    case 1:
      return I18n.t('BEE1943' /* Térmica */);
    case 2:
      return I18n.t('BEE1944' /* Laser */);
    case 3:
      return I18n.t('BEE1945' /* Jato de Tinta */);
    case 4:
      return I18n.t('BEE394' /* Outro */);
    default:
      return type;
  }
}

function listPrinterType() {
  return [
    { value: 1, label: I18n.t('BEE1943' /* Térmica */) },
    { value: 2, label: I18n.t('BEE1944' /* Laser */) },
    { value: 3, label: I18n.t('BEE1945' /* Jato de Tinta */) },
    { value: 3, label: I18n.t('BEE394' /* Outro */) },
  ];
}

export default {
  printerType,
  listPrinterType
}