import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

import { getVolumes, setOutboundVolumeList } from '../../../app/store/actions/outboundOrderVolumes';

import { dockVolumeRemovalList } from '../../../app/store/actions/dockVolumeRemoval';
import addNotification from '../../../components/notification';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';
import TableDockVolumeRemoval from './tableDockVolumeRemoval';
import TableOutboundVolume from './tableOutboundVolume';

class DockVolumeRemovalMonitor extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE3804' /* Monitor de Missões de Remoção de Volumes da Doca */), active: true },
    ];

    this.state = {
      dockVolumeRemovalList: [],
    };
  }

  componentDidMount() {
    this.props.setOutboundVolumeList([]);
  }

  listDockVolumeRemoval = async () => {
    try {
      const dockVolumeRemovalListData = await this.props.dockVolumeRemovalList({ status: 'all' });

      if (dockVolumeRemovalListData && !dockVolumeRemovalListData.length) {
        addNotification(
          'danger',
          I18n.t('BEE3804' /* Monitor de Missões de Remoção de Volumes da Doca */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        this.setState({ dockVolumeRemovalList: dockVolumeRemovalListData });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification(
          'danger',
          I18n.t('BEE3804' /* Monitor de Missões de Remoção de Volumes da Doca */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE3804' /* Monitor de Missões de Remoção de Volumes da Doca */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  listOutboundVolume = async (outboundOrderNumber) => {
    try {
      await this.props.getVolumes(outboundOrderNumber);
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification(
          'danger',
          I18n.t('BEE3804' /* Monitor de Missões de Remoção de Volumes da Doca */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE3804' /* Monitor de Missões de Remoção de Volumes da Doca */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    return (
      <>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            {this.breadcrumb.map((it, index) => (
              <li key={index} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                {it.value}
              </li>
            ))}
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE3804' /* Monitor de Missões de Remoção de Volumes da Doca */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.OUTBOUND_DOCK_CONTROL_MONITOR_HELP} />
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <TableDockVolumeRemoval
              listOutboundVolume={(outboundOrderNumber) => this.listOutboundVolume(outboundOrderNumber)}
              dockVolumeRemovalList={this.state.dockVolumeRemovalList}
              listDockVolumeRemoval={() => this.listDockVolumeRemoval()}
              setState={(data) => this.setState(data)}
            />
            <TableOutboundVolume
              listOutboundVolume={(outboundOrderNumber) => this.listOutboundVolume(outboundOrderNumber)}
              listDockVolumeRemoval={() => this.listDockVolumeRemoval()}
              setOutboundVolumeList={() => this.props.setOutboundVolumeList([])}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getUserList: () => dispatch(getUserList()),
  dockVolumeRemovalList: (query) => dispatch(dockVolumeRemovalList(query)),
  getVolumes: (outboundOrderId) => dispatch(getVolumes(outboundOrderId)),
  setOutboundVolumeList: (outboundVolumes) => dispatch(setOutboundVolumeList(outboundVolumes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DockVolumeRemovalMonitor));
