import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from '../../../../components/form/button';
import { Panel, PanelHeader, PanelBody, PanelFooter } from '../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createMaterialFamily, getMaterialFamily, updateMaterialFamily,
} from '../../../../app/store/actions/materialsFamily';
import { getUnitsMeasureOptions } from '../../../../app/store/actions/unitsMeasure';
import Select from '../../../../components/form/select';
import Form from '../../../../components/form/form';

class MaterialFamilyForm extends React.Component {
  constructor(props) {

    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      activeTab: '1',
      mode: 'detail',
      code: '',
      name: '',
      unitMeasure: null,
      unitMeasureName: '',
      cuttingPlan: false,
      note: '',
      disableCode: true,
      disableName: true,
      disableUnitMeasureCode: true,
      disableCuttingPlan: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      listUnitMeasure: '',
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getMaterialFamilySelected();
    }

    const listUnitMeasure = await this.props.getUnitsMeasureOptions();

    this.setState({
      listUnitMeasure,
    });
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  getMaterialFamilySelected = async () => {
    if (this.props.location.state.materialFamily) {
      const { materialFamily } = this.props.location.state;

      const materialFamilySelected = await this.props.getMaterialFamily(materialFamily.id);

      if (materialFamilySelected) {
        this.setState({
          materialFamilySelected,
          materialFamilyId: materialFamilySelected.id,
          code: materialFamilySelected.code,
          name: materialFamilySelected.name,
          cuttingPlan: materialFamilySelected.cuttingPlan,
          note: materialFamilySelected.note,
          unitMeasure: materialFamilySelected.unitMeasureCode,
          unitMeasureName: materialFamilySelected.unitMeasureName,
        });
      }
    }
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableUnitMeasureCode: false,
        disableCuttingPlan: false,
        disableNote: false,
        showCreate: true,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableUnitMeasureCode: false,
        disableCuttingPlan: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createSelectDropDown = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <Select
          value={value || ''}
          onChange={e => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
        />
      </div>
    </div>
  );

  createToggleBox = (item, attr, label, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <div className="row" style={{ marginTop: '8px', marginLeft: '0px' }}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={this.handleInputChange}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`}></label>
          </div>
        </div>
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.type === 'radio' ? parseInt(target.value) : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  createMaterialFamilySubmit = async () => {
    const {
      code, name, unitMeasure, cuttingPlan, note,
    } = this.state;

    try {
      const newMaterialFamily = await this.props.createMaterialFamily({
        code,
        name,
        unitMeasureCode: unitMeasure,
        cuttingPlan,
        note,
      });

      if (newMaterialFamily) {
        if (newMaterialFamily.success && newMaterialFamily.success === false) {
          await addNotification(
            'danger', I18n.t('BEE1120' /* Nova Família Material */), I18n.t('BEE1123', { 0: code } /* Erro ao incluir a Família Material %{0}! */), 'top-right'
          );
        } else {
          await addNotification(
            'success', I18n.t('BEE1120' /* Nova Família Material */), I18n.t('BEE1125', { 0: code } /* Nova Família Material %{0} incluído com sucesso! */), 'top-right'
          );

          this.props.history.push(ROUTES.MATERIAL_FAMILY_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE1120' /* Nova Família Material */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE1120' /* Nova Família Material */), I18n.t('BEE1123', { 0: code } /* Erro ao incluir a Família Material %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE1120' /* Nova Família Material */), I18n.t('BEE1123', { 0: code } /* Erro ao incluir a Família Material %{0}! */), 'top-right'
        );
      }
    }
  };

  updateMaterialFamilySubmit = async () => {
    const {
      materialFamilyId, code, name, unitMeasure, cuttingPlan, note,
    } = this.state;

    try {
      const updMaterialFamily = await this.props.updateMaterialFamily({
        materialFamilyId,
        code,
        name,
        unitMeasureCode: unitMeasure,
        cuttingPlan,
        note,
      });

      if (updMaterialFamily) {
        if (updMaterialFamily.success && updMaterialFamily.success === false) {
          await addNotification(
            'danger', I18n.t('BEE1126' /* Atualizar Família Material */), I18n.t('BEE1127', { 0: code } /* Erro ao atualizar a Família Material %{0}! */), 'top-right'
          );
        } else {
          await addNotification('success', I18n.t('BEE1126' /* Atualizar Família Material */), I18n.t('BEE1128', { 0: code } /* Atualização da Família Material %{0} efetuada com sucesso! */), 'top-right');

          this.props.history.push(ROUTES.MATERIAL_FAMILY_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE1126' /* Atualizar Família Material */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE1126' /* Atualizar Família Material */), I18n.t('BEE1127', { 0: code } /* Erro ao atualizar a Família Material %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE1126' /* Atualizar Família Material */), I18n.t('BEE1127', { 0: code } /* Erro ao atualizar a Família Material %{0}! */), 'top-right'
        );
      }
    }
  };

  render() {
    const {
      code, name, note,
      disableCode, disableName, disableNote,
    } = this.state;

    const {
      unitMeasure, unitMeasureName, listUnitMeasure, cuttingPlan,
      disableUnitMeasureCode, disableCuttingPlan, 
    } = this.state;

    const {
      mode, showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE1120' /* Nova Família Material */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE1130' /* Editar Família Material */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE1132' /* Detalhes Família Material */);
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE1080' /* Família de Materiais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1120' /* Nova Família Material */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel className="panel-with-tabs">
                <PanelHeader noButton={true} />
                <PanelBody>
                  <Form
                    noPanel
                    setValue={this.setValue}
                    setValueDrop={this.setValueDrop}
                    inputs={(formContext) => ([
                      formContext.createInput(
                        code,
                        'code',
                        `${I18n.t('BEE82' /* Código */)}:`,
                        I18n.t('BEE3710' /* Informe o código da Família Material */),
                        'text',
                        true,
                        disableCode,
                      ),
                      formContext.createInput(
                        name,
                        'name',
                        `${I18n.t('BEE83' /* Nome */)}:`,
                        I18n.t('BEE1136' /* Nome da Família Material */),
                        'text',
                        true,
                        disableName,
                      ),
                      disableUnitMeasureCode
                        ? formContext.createInput(
                          unitMeasureName,
                          'unitMeasure',
                          `${I18n.t('BEE1756' /* Unidade de Medida */)}:`,
                          '',
                          'text',
                          false,
                          disableUnitMeasureCode,
                        )
                        : formContext.createSelectDropDown(
                          { value: unitMeasure, label: unitMeasureName },
                          'unitMeasure',
                          `${I18n.t('BEE1756' /* Unidade de Medida */)}:`,
                          listUnitMeasure,
                          disableUnitMeasureCode,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          true,
                        ),
                      this.createToggleBox(
                        cuttingPlan,
                        'cuttingPlan',
                        `${I18n.t('BEE1082' /* Gerar Plano Corte */)}`,
                        disableCuttingPlan,
                      ),
                      formContext.createInput(
                        note,
                        'note',
                        `${I18n.t('BEE135' /* Observação */)}:`,
                        I18n.t('BEE136' /* Informe a observação */),
                        'text',
                        false,
                        disableNote,
                      ),
                    ])}
                  />
                </PanelBody>
                <PanelFooter>
                  <Link to={{pathname: ROUTES.MATERIAL_FAMILY_LIST, state: {dice: this.props.location.dice}}}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createMaterialFamilySubmit()}
                    >{I18n.t('BEE138' /* Criar */)}</OneClickButton>
                  )}
                  {(showUpdate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateMaterialFamilySubmit()}
                    >{I18n.t('BEE139' /* Atualizar */)}</OneClickButton>
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    }
  }),
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getMaterialFamily: materialFamilyId => dispatch(getMaterialFamily(materialFamilyId)),
  createMaterialFamily: materialFamily => dispatch(createMaterialFamily(materialFamily)),
  updateMaterialFamily: materialFamily => dispatch(updateMaterialFamily(materialFamily)),
  getUnitsMeasureOptions: () => dispatch(getUnitsMeasureOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MaterialFamilyForm));