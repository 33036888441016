const INITIAL_STATE = {
  prioritiesList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PRIORITIES': {
      return { ...state, prioritiesList: action.priorities };
    }
    default:
      return state;
  }
};
