import RestService from '../services/rest';

// GET
async function getAllInventoriesErp(tableParams) {
  return RestService.postAuthenticated(`inventoriesErp/list/all`, tableParams);
}

async function getListInventoryErp(branchCode, onlyDifference = false) {
  return RestService.getAuthenticated(`inventoriesErp/list`, { branchCode, onlyDifference });
}

async function getInventoryErp(inventoryErpId) {
  return RestService.getAuthenticated(`inventoriesErp/detail?inventoryErpId=${inventoryErpId}`);
}

// POST
async function createInventoryErp(newInventoryErp) {
  return RestService.postAuthenticated('inventoriesErp/create', newInventoryErp);
}

async function deleteInventoryErp(inventoryErpId) {
  return RestService.postAuthenticated('inventoriesErp/delete', { inventoryErpId });
}

// PUT
async function updateInventoryErp(updInventoryErp) {
  return RestService.putAuthenticated('inventoriesErp/update', updInventoryErp);
}

async function updateErpStockTable(branchCode) {
  return RestService.putAuthenticated('inventoriesErp/getStockErp',{ branchCode});
}

export default {
  getAllInventoriesErp,
  createInventoryErp,
  updateInventoryErp,
  getInventoryErp,
  deleteInventoryErp,
  updateErpStockTable,
  getListInventoryErp,
};
