import RestService from '../services/rest';

async function getPickingArea(pickingAreaId) {
  return RestService.getAuthenticated('pickingAreas/detail', { pickingAreaId });
}

async function getPickingAreas() {
  return RestService.getAuthenticated('pickingAreas/list/all');
}

async function getOptionsPickingAreas() {
  return RestService.getAuthenticated('pickingAreas/list/options');
}

async function getOptionsPickingAreasProducts(productFilter) {
  return RestService.getAuthenticated('pickingAreas/list/options/products', {}, {}, { params: { productFilter } });
}

async function createPickingArea(pickingArea) {
  return RestService.postAuthenticated('pickingAreas/create', pickingArea);
}

async function updatePickingArea(pickingArea) {
  return RestService.putAuthenticated('pickingAreas/update', pickingArea);
}

async function deletePickingArea(pickingAreaId) {
  return RestService.deleteAuthenticated('pickingAreas/delete', { pickingAreaId });
}

export default {
  getPickingArea,
  getPickingAreas,
  getOptionsPickingAreas,
  getOptionsPickingAreasProducts,
  createPickingArea,
  updatePickingArea,
  deletePickingArea,
};
