import RestService from '../services/rest';

// GET
async function getTransportEquipments() {
  return RestService.getAuthenticated('transportEquipment/list/all');
};
async function getTransportEquipment(transportEquipmentId) {
  return RestService.getAuthenticated(`transportEquipment/detail?transportEquipmentId=${transportEquipmentId}`);
};

// POST
async function createTransportEquipment(newTransportEquipment) {
  return RestService.postAuthenticated('transportEquipment/create', newTransportEquipment);
};

async function deleteTransportEquipment(transportEquipmentId) {
  return RestService.postAuthenticated('transportEquipment/delete', { transportEquipmentId });
};

// PUT
async function updateTransportEquipment(updTransportEquipment) {
  return RestService.putAuthenticated('transportEquipment/update', updTransportEquipment);
};

export default {
  getTransportEquipments,
  getTransportEquipment,
  createTransportEquipment,
  deleteTransportEquipment,
  updateTransportEquipment,
};
