import StorageAddressesRequests from '../../server/storageAddresses';
import { addLoading, removeLoading } from './loading';

export const setStorageAddresses = (storageAddresses) => ({
  type: 'SET_STORAGE_ADDRESSES',
  storageAddresses,
});

export const getStorageAddressesList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const storageAddresses = await StorageAddressesRequests.getStorageAddresses(tableParams);
    dispatch(setStorageAddresses(storageAddresses ? storageAddresses.rows : []));
    dispatch(removeLoading());
    return storageAddresses || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const createStorageAddress = (storageAddress) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newStorageAddress = await StorageAddressesRequests.createStorageAddress(storageAddress);
    return newStorageAddress;
  } finally {
    dispatch(removeLoading());
  }
};

export const generateStorageAddresses = (storageAddresses) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newStorageAddresses = await StorageAddressesRequests.generateStorageAddresses(storageAddresses);
    return newStorageAddresses;
  } finally {
    dispatch(removeLoading());
  }
};

export const saveListGeneratedAddresses = (addressesList) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const totalStoredAddresses = await StorageAddressesRequests.saveListGeneratedAddresses(addressesList);
    return totalStoredAddresses;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateStorageAddress = (storageAddress) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StorageAddressesRequests.updateStorageAddress(storageAddress);
  } finally {
    dispatch(removeLoading());
  }
};

export const getStorageAddress = (storageAddressId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StorageAddressesRequests.getStorageAddress(storageAddressId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteStorageAddress = (storageAddressId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StorageAddressesRequests.deleteStorageAddress(storageAddressId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getStorageAddressesOptions = (branchCode = '', depositCode = '') => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StorageAddressesRequests.getStorageAddressesOptions(branchCode, depositCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const exportAddressList = (columns, rows, nameDoc, typeDoc) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await StorageAddressesRequests.exportAddressList(columns, rows, nameDoc, typeDoc);
  } finally {
    dispatch(removeLoading());
  }
};

export const blockStorageAddress = (addressId, blocked, note) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StorageAddressesRequests.blockStorageAddress(addressId, blocked, note);
  } finally {
    dispatch(removeLoading());
  }
};

export const previewBlockList = (filters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StorageAddressesRequests.previewBlockList(filters);
  } finally {
    dispatch(removeLoading());
  }
};

export const persistBlockList = (blocked, filters, note) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StorageAddressesRequests.persistBlockList(blocked, filters, note);
  } finally {
    dispatch(removeLoading());
  }
};

export const bulkImportStorageAddressSequence = (jsonCsv) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StorageAddressesRequests.bulkImportUpdateSequence(jsonCsv);
  } finally {
    dispatch(removeLoading());
  }
};
