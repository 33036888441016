import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import SweetAlert from 'react-bootstrap-sweetalert';
import { updateDockVolumeRemoval } from '../../app/store/actions/dockVolumeRemoval';

import addNotification from '../notification';

import Select from '../form/select';

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    isDisabled, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};

class changeVolumeRemovalUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userValue: null,
      userLabel: null,
    };
  }

  componentDidUpdate() {
    const { row, usersList } = this.props;
    const { userValue, userLabel } = this.state;

    if (row.userRemoval && usersList && !userValue) {
      const user = usersList.find((user) => user.value === row.userRemoval.id);

      if (user && (userValue !== user.value || userLabel !== user.label)) {
        this.setState({
          userValue: user.value,
          userLabel: user.label,
        });
      }
    }
  }

  showConfirm = async () => {
    const { userValue } = this.state;
    const { row } = this.props;

    if (userValue === '') {
      addNotification(
        'danger',
        I18n.t('BEE3911' /* Alterar usuário */),
        I18n.t('BEE3910' /* O usuário deve ser informado. */),
        'top-right',
      );
    } else {
      const result = await this.props.updateDockVolumeRemoval(
        {
          id: row.id,
          removalUser: userValue,
        },
      );
      if (result && result.success) {
        this.props.confirm(true);
        addNotification(
          'success',
          I18n.t('BEE3911' /* Alterar usuário */),
          I18n.t('BEE1834' /* Usuário alterado com sucesso */),
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE920' /* Senha usuário */),
          I18n.t('BEE1077' /* Usuário ou senha incorretos ! */),
          'top-right',
        );
      }

      this.setState({
        userValue: null,
        userLabel: null,
      });
    }
  };

  showCancel = () => {
    this.props.confirm(false);
    this.setState({
      userValue: null,
      userLabel: null,
    });
  };

  setValueDrop = async (attr, value) => {
    if (attr === 'user') {
      await this.setState({
        [`${attr}Value`]: value ? value.value : null,
        [`${attr}Label`]: value ? value.label : '',
      });
    }
  };

  createSelectDropDown = (value, attr, label, items) => (

    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={label} className="col-form-label col-md-3">{label}</label>
      <div className="col-md-9">
        <Select
          value={value || ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
        />
      </div>
    </div>
  );

  render() {
    const { openModal = false, usersList } = this.props;
    const { userValue, userLabel } = this.state;

    return (
      openModal && (
        <SweetAlert
          showCancel
          confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
          cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title={I18n.t('BEE3909' /* Alteração do Usuário Responsável */)}
          onConfirm={() => this.showConfirm()}
          onCancel={() => this.showCancel()}
        >
          <div>
            <br />
            <br />
            {this.createSelectDropDown(
              { value: userValue, label: userLabel },
              'user',
              `${I18n.t('BEE1' /* Usuário */)}:`,
              usersList,
            )}
          </div>
        </SweetAlert>
      )
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  updateDockVolumeRemoval: (dockVolumeRemoval) => dispatch(updateDockVolumeRemoval(dockVolumeRemoval)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(changeVolumeRemovalUserModal));
