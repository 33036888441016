const INITIAL_STATE = {
  cutsMonitoList: [],
  productList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_CUTS_MONITO_LIST': {
      return { ...state, cutsMonitoList: action.cutsMonitoList };
    }
    case 'SET_PRODUCT_LIST': {
      return { ...state, productList: action.productList };
    }
    default:
      return state;
  }
};
