import RestService from '../services/rest';

// GET
async function getCustomerProducts(tableParams) {
  return RestService.postAuthenticated('customerProducts/list/all', tableParams);
}

async function getCustomerProduct(customerProductId) {
  return RestService.getAuthenticated(`customerProducts/detail?customerProductId=${customerProductId}`);
}

// POST
async function createCustomerProduct(newCustomerProduct) {
  return RestService.postAuthenticated('customerProducts/create', newCustomerProduct);
}

async function deleteCustomerProduct(customerProductId) {
  return RestService.postAuthenticated('customerProducts/delete', { customerProductId });
}

// PUT
async function updateCustomerProduct(updCustomerProduct) {
  return RestService.putAuthenticated('customerProducts/update', updCustomerProduct);
}

export default {
  getCustomerProducts,
  createCustomerProduct,
  updateCustomerProduct,
  getCustomerProduct,
  deleteCustomerProduct
};
