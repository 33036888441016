import { I18n } from 'react-redux-i18n';

function waybillStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE470' /* Pendente */);
      case 2:
        return I18n.t('BEE2376' /* Integrado */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE470' /* Pendente */) },
      { value: 2, label: I18n.t('BEE2376' /* Integrado */) },
    ];
  }
}

function waybillPrintedStatus(printed) {
  if (printed || printed === 0) {
    switch (printed) {
      case 0:
        return I18n.t('BEE470' /* Pendente */);
      case 1:
        return I18n.t('BEE2615' /* Impresso */);
      default:
        return printed;
    }
  } else if (!printed) {
    return [
      { value: 0, label: I18n.t('BEE470' /* Pendente */) },
      { value: 1, label: I18n.t('BEE2615' /* Impresso */) },
    ];
  }
}

export default {
  waybillStatus,
  waybillPrintedStatus,
};
