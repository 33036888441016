import moment from 'moment'
import RestService from '../services/rest';

async function getOutboundOrdersList(startDate, endDate) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    `outboundOrderCheck/list?startDate=${sDate}&endDate=${eDate}`
  );
}

// Para Consulta -> Expedição -> Conferência
async function getOutboundOrdersListCheck(startDate, endDate) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    `outboundOrderCheck/listCheck?startDate=${sDate}&endDate=${eDate}`,
  );
}

async function getOutboundOrder(id) {
  return RestService.getAuthenticated(
    `outboundOrderCheck/order?id=${id}`
  );
}

async function getOutboundOrderByNumber(orderNumber) {
  return RestService.getAuthenticated(
    `outboundOrderCheck/orderByNumber?orderNumber=${orderNumber}`
  );
}

async function postConfirmCheck(outboundOrderProductId, checkedQuantity, outboundOrderId) {
  return RestService.postAuthenticated(
    `outboundOrderCheck/check`, { outboundOrderProductId, checkedQuantity, outboundOrderId }
  );
}

export default {
  getOutboundOrdersList,
  getOutboundOrdersListCheck,
  getOutboundOrder,
  getOutboundOrderByNumber,
  postConfirmCheck
}