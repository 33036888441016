import EansRequests from '../../server/eans';
import { addLoading, removeLoading } from './loading';

export const setEans = (eans) => ({
  type: 'SET_EANS',
  eans,
});

export const getEansList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const eans = await EansRequests.getEans(tableParams);
    dispatch(setEans(eans ? eans.rows : []));
    dispatch(removeLoading());
    return eans || 0;
  } catch (e) {
    dispatch(removeLoading());
    return (e);
  }
};

export const createEan = (newEan) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newProductEan = await EansRequests.createEan(newEan);
    return newProductEan;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateEan = (updEan) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await EansRequests.updateEan(updEan);
  } finally {
    dispatch(removeLoading());
  }
};

export const getEan = (eanId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await EansRequests.getEan(eanId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getValidateEan = (ean) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await EansRequests.getValidateEan(ean);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteEan = (eanId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await EansRequests.deleteEan(eanId);
  } finally {
    dispatch(removeLoading());
  }
};
