import OutboundOrderAllocationsRequests from '../../server/outboundOrderAllocations';
import { addLoading, removeLoading } from './loading';

export const productAllocationsList = (filterStartDate, filterEndDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderAllocationsRequests.productAllocationsList(filterStartDate, filterEndDate);
  } finally {
    dispatch(removeLoading());
  }
};

export const productAllocationAndStockBalances = (outboundOrderId, productCode, branchCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderAllocationsRequests.productAllocationAndStockBalances(
      outboundOrderId,
      productCode,
      branchCode,
    );
  } finally {
    dispatch(removeLoading());
  }
};

export const changeAllocationProduct = (outboundProductAllocationId, balances) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderAllocationsRequests.changeAllocationProduct(outboundProductAllocationId, balances);
  } finally {
    dispatch(removeLoading());
  }
};

export const allocationListToChange = (filterStartDate, filterEndDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderAllocationsRequests.allocationListToChange(filterStartDate, filterEndDate);
  } finally {
    dispatch(removeLoading());
  }
};

export const getStockBalancesAvailable = (outboundProductAllocationId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderAllocationsRequests.getStockBalancesAvailable(outboundProductAllocationId);
  } finally {
    dispatch(removeLoading());
  }
};
