import BaptismRequests from '../../server/baptism';
import { addLoading, removeLoading } from './loading';

export const getBaptismInfo = (type) => async (dispatch) => {
  dispatch(addLoading())
  try {
    const info = await BaptismRequests.getBaptismInfo(type)
    return info
  } finally {
    dispatch(removeLoading())
  }
}

export const generateBaptism = (type, branchCode, quantity) => async (dispatch) => {
  dispatch(addLoading())
  try {
    const result = await BaptismRequests.generateBaptism(type, branchCode, quantity)
    return result
  } finally {
    dispatch(removeLoading())
  }
}