const INITIAL_STATE = {
  outboundOrdersList: [],
  outboundOrdersListJustDocument: [],
  outboundOrderDetailsList: [],
  chartsPanel: {},
  outboundOrderProductsList: [],
  outboundOrdersTotalProcess: {},
  outboundOrdersTotalBilled: {},
  outboundOrdersTotalNotBilled: {},
  outboundOrdersTotalByStatus: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_OUTBOUND_ORDERS': {
      return { ...state, outboundOrdersList: action.outboundOrders };
    }
    case 'SET_AVERAGE_TIME_CARDS': {
      return { ...state, averageTimeCards: action.averageTimeCards };
    }
    case 'SET_OUTBOUND_ORDERS_DETAILS': {
      return { ...state, outboundOrderDetailsList: action.outboundOrdersDetails };
    }
    case 'SET_OUTBOUND_ORDERS_CHARTS': {
      return { ...state, chartsPanel: action.chartsPanel };
    }
    case 'SET_OUTBOUND_AVERAGE_TIME_CHARTS': {
      return { ...state, charts: action.charts };
    }
    case 'SET_OUTBOUND_ORDERS_USER_BRANCHES': {
      return { ...state, userBranches: action.userBranches };
    }
    case 'SET_OUTBOUND_ORDERS_DATES': {
      return { ...state, dates: action.dates };
    }
    case 'SET_OUTBOUND_ORDERS_CURRENT_RANGE': {
      return { ...state, currentRange: action.currentRange };
    }
    case 'SET_OUTBOUND_ORDERS_SELECTED_BRANCHES': {
      return { ...state, selectedBranches: action.selectedBranches };
    }
    case 'SET_OUTBOUND_ORDERS_JUST_DOCUMENT': {
      return { ...state, outboundOrdersListJustDocument: [...action.outboundOrders] };
    }
    case 'SET_OUTBOUND_ORDERS_PRODUCTS': {
      return { ...state, outboundOrderProductsList: [...action.outboundOrderProducts] };
    }
    case 'SET_OUTBOUND_ORDERS_TOTAL_PROCESS': {
      return { ...state, outboundOrdersTotalProcess: action.totalOutboundOrderProcess };
    }
    case 'SET_OUTBOUND_ORDERS_TOTAL_BILLED': {
      return { ...state, outboundOrdersTotalBilled: action.totalOutboundOrderBilled };
    }
    case 'SET_OUTBOUND_ORDERS_TOTAL_NOT_BILLED': {
      return { ...state, outboundOrdersTotalNotBilled: action.totalOutboundOrderNotBilled };
    }
    case 'SET_OUTBOUND_ORDERS_TOTAL_BY_STATUS': {
      return { ...state, outboundOrdersTotalByStatus: [...action.totalByStatusOutboundOrdersProducts] };
    }
    default:
      return state;
  }
};
