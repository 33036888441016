import { I18n } from 'react-redux-i18n';

function movementType(type) {
  if (type || type === 0) {
    switch (type) {
      case 1:
        return I18n.t('BEE201' /* Entrada */);
      case 2:
        return I18n.t('BEE202' /* Saída */);
      default:
        return type;
    }
  } else if (!type) {
    return [
      { value: 1, label: I18n.t('BEE201' /* Entrada */) },
      { value: 2, label: I18n.t('BEE202' /* Saída */) },
    ]
  }
};

export default {
  movementType,
}