import AverageCostsRequests from '../../server/averageCost';
import { addLoading, removeLoading } from './loading';

export const setAllAverageCosts = (allAverageCosts) => ({
  type: 'SET_ALL_AVERAGE_COSTS',
  allAverageCosts,
});

export const getAllAverageCosts = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const allAverageCosts = await AverageCostsRequests.getAllAverageCosts(tableParams);
    dispatch(setAllAverageCosts(allAverageCosts ? allAverageCosts.data : []));
    dispatch(removeLoading());
    return allAverageCosts ? allAverageCosts.pages : 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};
