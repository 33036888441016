import PrioritiesRequests from '../../server/priorities';
import { addLoading, removeLoading } from './loading';

export const setPriorities = priorities => ({
  type: 'SET_PRIORITIES',
  priorities,
});

export const getPrioritiesList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const priorities = await PrioritiesRequests.getPriorities();
    dispatch(setPriorities(priorities));
  } finally {
    dispatch(removeLoading());
  }
};

export const createPriority = priority => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newPriority = await PrioritiesRequests.createPriority(priority);
    return newPriority;
  } finally {
    dispatch(removeLoading());
  }
};

export const updatePriority = (priority) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PrioritiesRequests.updatePriority(priority);
  } finally {
    dispatch(removeLoading());
  }
}

export const getPriority = (priorityId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PrioritiesRequests.getPriority(priorityId);
  } finally {
    dispatch(removeLoading());
  }
}

export const getPrioritiesOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PrioritiesRequests.getPrioritiesOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const deletePriority = (priorityId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PrioritiesRequests.deletePriority(priorityId);
  } finally {
    dispatch(removeLoading());
  }
}