import VolumePlateRequests from '../../server/volumePlate';
import { addLoading, removeLoading } from './loading';

export const getInboundDocument = (accessKey) => async (dispatch) => {
  dispatch(addLoading())
  try {
    const info = await VolumePlateRequests.getInboundDocument(accessKey)
    return info
  } finally {
    dispatch(removeLoading())
  }
}

export const savePlateToCheck = (id, expectedVolumes, licensePlate) => async (dispatch) => {
  dispatch(addLoading())
  try {
    const result = await VolumePlateRequests.savePlateToCheck(id, expectedVolumes, licensePlate)
    return result
  } finally {
    dispatch(removeLoading())
  }
}