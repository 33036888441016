import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import { createPickingReturnDocument } from '../../../app/store/actions/returnPickingOrder';
import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import { PanelPage } from '../../../components/pages/pages';
import { Panel, PanelBody } from '../../../components/panel/panel';
import ProductsList from './productsList';

class PickingReturn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      outboundOrderId: '',
      branchCode: '',
      branchName: '',
      returnOrder: '',
      pickingOrder: '',
      returnDate: '',
      customerName: '',
      orderType: '',
      products: [],
      selectedCheckbox: {},
      outboundOrderProductIds: [],
    };
  }

  async componentDidMount() {
    await this.detailOrder();
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  detailOrder = async () => {
    const { orderSelected } = this.props;
    this.setState({
      outboundOrderId: orderSelected.outboundOrderId,
      branchCode: orderSelected.branchCode,
      branchName: `${orderSelected.branchCode} - ${orderSelected.branchName}`,
      returnOrder: `DEV${orderSelected.orderNumber}`,
      pickingOrder: orderSelected.orderNumber,
      returnDate: moment(orderSelected.pickStartAt).format('DD/MM/YYYY'),
      customerName: `${orderSelected.customerCode}-${orderSelected.customerName}`,
      orderType: orderSelected.orderType,
      products: orderSelected.products,
    });
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={attr}>{label}</label>
      <div className="input-group">
        <input
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  pickingReturn = async () => {
    const {
      outboundOrderProductIds, outboundOrderId, returnOrder, branchCode, products,
    } = this.state;
    const returnedProducts = products.reduce((accumulator, element) => {
      if (element.productStatus < 10) return accumulator + 1;
      return accumulator;
    }, 0);
    this.setState({ outboundOrderProductIds: [], selectedCheckbox: {} });

    if (outboundOrderProductIds.length) {
      try {
        const pickingReturn = await this.props.createPickingReturnDocument(
          outboundOrderProductIds,
          outboundOrderId,
          returnOrder,
          branchCode,
        );

        if (pickingReturn && pickingReturn.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE908' /* Devolução de Picking */),
            I18n.t('BEE1907' /* Não foi possível finalizar a devolução */),
            'top-right',
          );
        } else {
          addNotification(
            'success',
            I18n.t('BEE908' /* Devolução de Picking */),
            I18n.t('BEE1906' /* Devolução efetuada */),
            'top-right',
          );
          if (returnedProducts === outboundOrderProductIds.length) {
            this.props.resetForm();
          } else {
            await this.props.getDetailOrder(true);
            await this.detailOrder();
          }
        }
      } catch (error) {
        const messageError = error.response && error.response.data
          && error.response.data.error && error.response.data.error.message;

        if (messageError) {
          addNotification(
            'danger',
            I18n.t('BEE908' /* Devolução de Picking */),
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE908' /* Devolução de Picking */),
            I18n.t('BEE1852' /* Não foi possível finalizar esta ação */),
            'top-right',
          );
        }
      }
    } else {
      addNotification(
        'danger',
        I18n.t('BEE908' /* Devolução de Picking */),
        I18n.t('BEE1072' /* Nenhum produto selecionado ! */),
        'top-right',
      );
    }
  };

  render() {
    const {
      branchName, returnOrder, pickingOrder, returnDate, customerName, orderType, products,
    } = this.state;

    return (
      <>
        <PanelPage
          noButton
          title={I18n.t('BEE908' /* Devolução de Picking */)}
          content={(
            <div className="slideUpTransition">
              <div className="row">
                <div className="col-xl-12">
                  <div>
                    <div className="row">
                      <div className="col-md-6">
                        {this.createInput(
                          branchName,
                          'branchName',
                          I18n.t('BEE145' /* Filial */),
                          '',
                          'text',
                          true,
                          true,
                        )}
                      </div>
                      <div className="col-md-3">
                        {this.createInput(
                          returnOrder,
                          'returnOrder',
                          I18n.t('BEE1905' /* Documento de Devolução */),
                          '',
                          'text',
                          false,
                          true,
                        )}
                      </div>
                      <div className="col-md-3">
                        {this.createInput(
                          returnDate,
                          'returnDate',
                          I18n.t('BEE1906' /* Data Transação */),
                          '',
                          'text',
                          false,
                          true,
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {this.createInput(
                          customerName,
                          'customerName',
                          I18n.t('BEE50' /* Cliente */),
                          '',
                          'text',
                          true,
                          true,
                        )}
                      </div>
                      <div className="col-md-4">
                        {this.createInput(
                          pickingOrder,
                          'pickingOrder',
                          I18n.t('BEE1904' /* Documento de Saída */),
                          '',
                          'text',
                          false,
                          true,
                        )}
                      </div>
                      <div className="col-md-2">
                        {this.createInput(orderType, 'orderType', I18n.t('BEE200' /* Tipo */), '', 'text', false, true)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          footerContent={(
            <>
              <OneClickButton
                type="button"
                className="btn btn-120 btn-white p-5 m-5"
                onClick={() => this.props.resetForm()}
              >
                {I18n.t('BEE99' /* Cancelar */)}
              </OneClickButton>
              <OneClickButton
                type="button"
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={async () => this.pickingReturn()}
              >
                {I18n.t('BEE100' /* Confirmar */)}
              </OneClickButton>
            </>
          )}

        />

        <Panel>
          <PanelBody>
            <ProductsList
              selectedCheckbox={this.state.selectedCheckbox}
              setSelectedCheckbox={(selectedCheckbox) => this.setState({ selectedCheckbox: { ...selectedCheckbox } })}
              outboundOrderProductIds={this.state.outboundOrderProductIds}
              setOutboundOrderProductIds={
                (outboundOrderProductIds) => this.setState({ outboundOrderProductIds: [...outboundOrderProductIds] })
              }
              products={products}
            />
          </PanelBody>
        </Panel>
      </>
    );
  }
}

const mapStateToProps = () => ({

});

const mapDispatchToProps = (dispatch) => ({
  createPickingReturnDocument: (
    outboundOrderProductIds,
    outboundOrderId,
    orderNumber,
    branchCode,
  ) => dispatch(createPickingReturnDocument(outboundOrderProductIds, outboundOrderId, orderNumber, branchCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PickingReturn));
