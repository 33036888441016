import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

import { getOutboundOrderDetails, setOutboundOrderDetails } from '../../../app/store/actions/outboundOrders';
import { dockControlList } from '../../../app/store/actions/dockControl';
import addNotification from '../../../components/notification';
import TableDockControl from './tableDockControl';
import TableOutboundOrder from './tableOutboundOrder';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';

class DockControlMonitor extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE2072' /* Monitor de Controle de Docas */), active: true },
    ];

    this.state = {
      dockControlList: [],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
    };
  }

  componentDidMount() {
    this.props.setOutboundOrderDetails([]);
  }

  listDockControl = async () => {
    const { startDate, endDate } = this.state;
    try {
      const dockControlListData = await this.props.dockControlList(
        moment(startDate).format(),
        moment(endDate).format(),
      );
      if (dockControlListData && dockControlListData.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2072' /* Monitor de Controle de Docas */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        this.setState({ dockControlList: dockControlListData });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2072' /* Monitor de Controle de Docas */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2072' /* Monitor de Controle de Docas */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  outboundOrderDetails = async (dockControlId) => {
    try {
      await this.props.getOutboundOrderDetails(dockControlId);
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2072' /* Monitor de Controle de Docas */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2072' /* Monitor de Controle de Docas */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    return (
      <>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            {this.breadcrumb.map((it, index) => (
              <li key={index} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                {it.value}
              </li>
            ))}
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE2072' /* Monitor de Controle de Docas */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.OUTBOUND_DOCK_CONTROL_MONITOR_HELP} />
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <TableDockControl
              outboundOrderDetails={(dockControlId) => this.outboundOrderDetails(dockControlId)}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              dockControlList={this.state.dockControlList}
              listDockControl={() => this.listDockControl()}
              setState={(data) => this.setState(data)}
            />
            <TableOutboundOrder
              outboundOrderDetails={(dockControlId) => this.outboundOrderDetails(dockControlId)}
              listDockControl={() => this.listDockControl()}
              setOutboundOrderDetails={() => this.props.setOutboundOrderDetails([])}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getOutboundOrderDetails: (dockControlId) => dispatch(getOutboundOrderDetails(dockControlId)),
  setOutboundOrderDetails: (outboundOrders) => dispatch(setOutboundOrderDetails(outboundOrders)),
  dockControlList: (filterStartDate, filterEndDate) => dispatch(dockControlList(filterStartDate, filterEndDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DockControlMonitor));
