import SuppliersRequests from '../../server/suppliers';
import { addLoading, removeLoading } from './loading';

export const setSuppliers = (suppliers) => ({
  type: 'SET_SUPPLIERS',
  suppliers,
});

export const getSuppliersList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const suppliers = await SuppliersRequests.getSuppliers(tableParams);
    dispatch(setSuppliers(suppliers ? suppliers.rows : []));
    dispatch(removeLoading());
    return suppliers || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const getSupplierOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const suppliers = await SuppliersRequests.getSuppliersOptions();

    dispatch(removeLoading());
    return suppliers || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const createSupplier = (supplier) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newSupplier = await SuppliersRequests.createSupplier(supplier);
    return newSupplier;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateSupplier = (supplier) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SuppliersRequests.updateSupplier(supplier);
  } finally {
    dispatch(removeLoading());
  }
};

export const getSupplier = (supplierId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SuppliersRequests.getSupplier(supplierId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteSupplier = (supplierId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SuppliersRequests.deleteSupplier(supplierId);
  } finally {
    dispatch(removeLoading());
  }
};
