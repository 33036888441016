import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import OneClickButton from '../../../components/form/button';
import Table from '../../../components/table/Table';
import addNotification from '../../../components/notification';
import cableCutHelpers from '../../../helpers/cableCut';
import { printCableCutLabel, deleteCableCut, getCableCutProductList, finishCableCut } from '../../../app/store/actions/cutsMonito';
import FormatHelpers from '../../../helpers/format';

class CutProductTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableOrderColumns = [
      {
        Header: I18n.t('BEE1581' /* Corte */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80
      }, {
        Header: I18n.t('BEE626' /* Prioridades */),
        accessor: 'priorityCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE1378' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200
      }, {
        Header: I18n.t('BEE1688' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250
      }, {
        Header: I18n.t('BEE1808' /* Pedido Cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE463' /* Linha */),
        accessor: 'lineNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120
      }, {
        Header: I18n.t('BEE1829' /* Minimo */),
        accessor: 'minimumQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE1114' /* Obrigatório */),
        accessor: 'mandatoryQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE204' /* Situação */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => {
          return (
            <div style={{ textAlign: "center" }}>
              <span>{cableCutHelpers.cableCutStatus(row.value)}</span>
            </div>
          );
        },
        filterMethod: (filter, row) => {
          if (parseInt(filter.value) === parseInt(row.status)) {
            return true;
          }
        },
        Filter: ({ filter, onChange }) => {
          return (
            <select type={'text'} style={{ width: '100%' }}
              onChange={event => { onChange(event.target.value) }}
            >
              <option value='' key='all'>{I18n.t('BEE793' /* Todos */)}</option>
              {cableCutHelpers.cableCutStatus().map((elem, index) => (
                <option value={elem.value} key={index} >{elem.label}</option>
              ))}
            </select>
          )
        },
      }, {
        Header: I18n.t('BEE578' /* Data de Criação */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120
      }, {
        Header: I18n.t('BEE575' /* Usuário de Criação */),
        accessor: 'createdUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130
      }, {
        Header: I18n.t('BEE1850' /* Data de Corte */),
        accessor: 'finishCableCutAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120
      }, {
        Header: I18n.t('BEE1851' /* Usuário Corte */),
        accessor: 'finishCableCutUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120
      }, {
        Header: I18n.t('BEE34' /* Transferência */),
        accessor: 'transferOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120
      }
    ];
    this.state = {
      defaultSorted: [],
      defaultPageSize: 10,
      pageSizeOptions: [5, 10],
      selectedCableCutproduct: {},
      showPrinterDialog: false,
      showNoticeExecution: false,
      showTerminationAlert: false,
      baptismLabel: '',
      showBaptismLabelDialog: false,
      finishFunction: false,
    }
  };

  resetSelectedCableCutproduct = async () => {
    const { selectedCableCutproduct } = this.state;
    if (Object.keys(selectedCableCutproduct).length && !this.props.productList.find(elem => elem.id === selectedCableCutproduct.id)) {
      this.setState({ selectedCableCutproduct: { ...{} } })
    }
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  }

  createSelect = (value, attr, label, items) => (
    <div className="form-group p-2">
      <label>{label}</label>
      <select
        className="form-control"
        value={value || ''}
        onChange={e => this.props.setValue(attr, e.target.value)}
      >
        {items.map(item => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group p-2">
      <label className="col-form-label col-md-4">{label}</label>
      <div>
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  selecionado = (selectedCableCutproduct) => {
    this.setState({ selectedCableCutproduct })
  };

  showPrinterDialog = async () => {
    await this.resetSelectedCableCutproduct();
    const { selectedCableCutproduct, finishFunction } = this.state;
    const { printerOptions } = this.props;

    if (selectedCableCutproduct && selectedCableCutproduct.id) {

      if (!printerOptions || printerOptions.length === 0) {
        addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1415' /* Usuário não possui impressora relacionada */), 'top-right');
        this.setState({ finishFunction: false, baptismLabel: '' })
      } else if (printerOptions && printerOptions.length > 1) {
        this.setState({ showPrinterDialog: true });

      } else if (printerOptions && printerOptions.length === 1) {
        await this.setState({ printerSelected: printerOptions[0].value });
        (finishFunction)
          ? this.finishCutProduct()
          : this.printLabel()
      }
    } else {
      addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1828' /* nenhum corte selecionado */), 'top-right');
      this.setState({ finishFunction: false, baptismLabel: '' })
    }
  };

  printLabel = async () => {
    const { selectedCableCutproduct } = this.state;
    const { printerSelected, printerOptions } = this.props;
    this.setState({ showPrinterDialog: false });
    try {
      const result = await this.props.printCableCutLabel(printerSelected || printerOptions[0].value, 'byDocument', selectedCableCutproduct.id);
      if (result && result.success === false) {
        addNotification('danger',
          I18n.t('BEE1419' /* Monitor de Cortes */),
          I18n.t('BEE1643' /* Falha na impressão! */),
          'top-right');
      } else {
        addNotification('success',
          I18n.t('BEE1419' /* Monitor de Cortes */),
          I18n.t('BEE1427' /* Etiquetas enviadas para impressora ! */),
          'top-right');
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details) ?
          `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.tI18n.t('BEE1419' /* Monitor de Cortes */), messageError, 'top-right');

      } else {
        addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */),
          I18n.t('BEE1643' /* Falha na impressão! */), 'top-right');
      }
    }
  };

  validationDeleteCableCut = async () => {
    await this.resetSelectedCableCutproduct();
    const { selectedCableCutproduct } = this.state;

    if (selectedCableCutproduct && selectedCableCutproduct.id) {
      this.setState({ showNoticeExecution: true })

    } else {
      addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1828' /* nenhum corte selecionado */), 'top-right');
    }
  };

  deleteCableCut = async () => {
    this.setState({ showNoticeExecution: false })
    try {
      const { selectedCableCutproduct } = this.state;

      const result = await this.props.deleteCableCut(selectedCableCutproduct.cableCutPlanId, selectedCableCutproduct.id)

      if (result && result.success === false) {
        addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1727' /* não foi possível eliminar */), 'top-right');

      } else {
        await this.props.getCableCutProductList(selectedCableCutproduct.cableCutPlanId)
        addNotification('success', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1830' /* corte eliminado */), 'top-right');
        this.setState({ selectedCableCutproduct: {} })
        await this.props.cutsMonitoList()
      }
    } catch (error) {
      const messageError = error.response && error.response.data && error.response.data.error && error.response.data.error.message;

      if (messageError) addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), messageError, 'top-right');
      else addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1727' /* não foi possível eliminar */), 'top-right');
    }
  };

  finishCutProduct = async () => {
    const { printerSelected, printerOptions } = this.props;
    const { selectedCableCutproduct, baptismLabel } = this.state;
    this.setState({ showTerminationAlert: false, showPrinterDialog: false, finishFunction: false, baptismLabel: '' })

    try {

      const result = await this.props.finishCableCut(selectedCableCutproduct.cableCutPlanId, selectedCableCutproduct.id, baptismLabel, printerSelected || printerOptions[0].value)

      if (result && result.success === false) {
        addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1831' /* não foi possível finalizar o corte */), 'top-right')

      } else {
        await this.props.getCableCutProductList(selectedCableCutproduct.cableCutPlanId)
        addNotification('success', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1803' /* Corte Finalizado */), 'top-right');
        await this.props.cutsMonitoList()

      }
    } catch (error) {
      const messageError = error.response && error.response.data && error.response.data.error && error.response.data.error.message;

      if (messageError) addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), messageError, 'top-right');
      else addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1831' /* não foi possível finalizar o corte */), 'top-right');
    }
  };

  validationFinishCutProduct = async () => {
    const { selectedCableCutproduct } = this.state;

    if (selectedCableCutproduct && selectedCableCutproduct.id) {
      if (selectedCableCutproduct.automaticSeparationAfterCuttingCables) {
        this.setState({ showBaptismLabelDialog: true })
      } else {
        this.setState({ showTerminationAlert: true })
      }
    } else {
      addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1828' /* nenhum corte selecionado */), 'top-right');
    }
  };

  validationMethodOfCompletion = async () => {
    await this.resetSelectedCableCutproduct();
    const { selectedCableCutproduct } = this.state;

    if (Object.keys(selectedCableCutproduct).length) {
      (selectedCableCutproduct.status === 1)
        ? this.validationFinishCutProduct()
        : addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1803' /* Corte Finalizado */), 'top-right')
    } else {
      addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1828' /* nenhum corte selecionado */), 'top-right');
    }
  }

  render() {
    const { productList = [], printerSelected, printerOptions } = this.props;
    const { defaultSorted, defaultPageSize, pageSizeOptions,
      showPrinterDialog, selectedCableCutproduct, showNoticeExecution, showTerminationAlert, baptismLabel,
      showBaptismLabelDialog, finishFunction,
    } = this.state;

    return (
      <>
        <Table
          panelProps={{
            className: "m-b-0",
          }}
          panelHeaderProps={{
            noButton: true,
            children: I18n.t('BEE1802' /* Produto do Corte */).toUpperCase(),
          }}
          filterable
          expander
          data={productList}
          columns={this.tableOrderColumns}
          defaultPageSize={defaultPageSize}
          pageSizeOptions={pageSizeOptions}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  this.selecionado(rowInfo.original);
                },
                style: {
                  background: selectedCableCutproduct && rowInfo.original.id === selectedCableCutproduct.id ? 'silver' : 'white',
                  cursor: 'pointer',
                }
              }
            } else {
              return {}
            }
          }}
        />
        <div className='text-left'>
          <OneClickButton
            type="button"
            className="btn btn-140 btn-primary p-5 m-5"
            onClick={() => this.showPrinterDialog()}
          >
            {I18n.t('BEE1819' /* imprimir etiquetas */)}
          </OneClickButton>
          <OneClickButton
            type="button"
            className="btn btn-140 btn-primary p-5 m-5"
            onClick={() => this.validationDeleteCableCut()}
          >
            {I18n.t('BEE1820' /* Eliminar Corte */)}
          </OneClickButton>
          <OneClickButton
            type="button"
            className="btn btn-140 btn-primary p-5 m-5"
            onClick={this.validationMethodOfCompletion}
          >
            {I18n.t('BEE1821' /* Finalizar Corte */)}
          </OneClickButton>
        </div>
        {(showPrinterDialog &&
          <SweetAlert
            confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE1324' /* Selecionar Impressora */)}
            onConfirm={() => (finishFunction) ? this.finishCutProduct() : this.printLabel() }
            onCancel={() => this.setState({ showPrinterDialog: false })}
          >
            <div className='mt-4'>
              {this.createSelect(
                printerSelected, 'printerSelected', I18n.t('BEE328' /* Impressora */), printerOptions
              )}
            </div>
          </SweetAlert>
        )}
        {(showNoticeExecution &&
          <SweetAlert
            info
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE1849' /* continuar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE1820' /* Eliminar Corte */)}
            onConfirm={() => this.deleteCableCut()}
            onCancel={() => this.setState({ showNoticeExecution: false, baptismLabel: '', finishFunction: false })}
          >
            {I18n.t('BEE1859' /* Esta ação irá excluir o produto do corte! não sera possível desfazer esta ação! Deseja continuar? */)}
          </SweetAlert>
        )}
        {(showTerminationAlert &&
          <SweetAlert
            info
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE1849' /* continuar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE1567' /* Finalizar */)}
            onConfirm={() => (!selectedCableCutproduct.printedCableCutLabel)
              ? (this.setState({ showTerminationAlert: false, finishFunction: true }),
                this.showPrinterDialog()
              ) : this.finishCutProduct()
            }
            onCancel={() => this.setState({ showTerminationAlert: false, baptismLabel: '', finishFunction: false })}
          >
            {I18n.t('BEE1860' /* Esta ação irá finalizar produto do corte! Deseja continuar? */)}
          </SweetAlert>
        )}
        {(showBaptismLabelDialog &&
          <SweetAlert
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE1849' /* continuar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE1076' /* Informe o Batismo */)}
            onConfirm={() => 
              (!baptismLabel)
              ? addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1876' /* Batismo não informada */), 'top-right')
              : this.setState({ showBaptismLabelDialog: false, showTerminationAlert: true })
            }
            onCancel={() => this.setState({ showBaptismLabelDialog: false, baptismLabel: '' })}
          >
            <div className='mt-4'>
              {this.createInput(
                baptismLabel, 'baptismLabel', I18n.t('BEE425' /* Batismo */), '', 'test', false, false
              )}
            </div>
          </SweetAlert>
        )}
      </>
    );
  }
};

const mapStateToProps = state => ({
  productList: state.cutsMonito && state.cutsMonito.productList,
});

const mapDispatchToProps = dispatch => ({
  printCableCutLabel: (printerCode, type, code) => dispatch(printCableCutLabel(printerCode, type, code)),
  deleteCableCut: (cableCutPlanId, cableCutProductId) => dispatch(deleteCableCut(cableCutPlanId, cableCutProductId)),
  getCableCutProductList: (cableCutPlanId) => dispatch(getCableCutProductList(cableCutPlanId)),
  finishCableCut: (cableCutPlanId, cableCutProductId, baptismLabel, printerCode) => dispatch(finishCableCut(cableCutPlanId, cableCutProductId, baptismLabel, printerCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CutProductTable));