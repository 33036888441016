import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { printLotWithStockBalance } from '../../../../app/store/actions/labels';
import { getUserPrinterOptions } from '../../../../app/store/actions/printers';
import OneClickButton from '../../../../components/form/button';
import Form from '../../../../components/form/form';
import addNotification from '../../../../components/notification';
import { PanelPage } from '../../../../components/pages/pages';
import { returnFiltersByRules } from '../../../reports/utils';

class LabelLotWithStockBalance extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE1371' /* Etiquetas */) },
      { value: I18n.t('BEE2315' /* Etiqueta Lote com Saldo de Estoque */), active: true },
    ];

    this.state = {
      printerSelected: null,
      addressFrom: '',
      addressTo: '',
      branchName: '',
      columnSide: '',
      printOrder: '',
    };
  }

  async componentDidMount() {
    await this.getUserPrinterOptions();
    if (this.props.userLogged && this.props.userLogged.mainBranch) {
      const { mainBranch = '', mainBranchName = '' } = this.props.userLogged;
      this.setState({ branchName: `${mainBranch} - ${mainBranchName}` });
    }

    const columnSideList = [
      { label: I18n.t('BEE203' /* Ambos */), value: 'both' },
      { label: I18n.t('BEE3398' /* Par */), value: 'even' },
      { label: I18n.t('BEE3399' /* Ímpar */), value: 'odd' },
    ];

    const printOrderList = [
      { label: I18n.t('BEE3461' /* Ascendente */), value: 'asc' },
      { label: I18n.t('BEE3462' /* Descendente */), value: 'desc' },
    ];

    this.setState({
      columnSideList,
      columnSide: 'both',
      columnSideLabel: I18n.t('BEE203' /* Ambos */),
      printOrderList,
      printOrder: 'asc',
      printOrderLabel: I18n.t('BEE3461' /* Ascendente */),
    });
  }

  getUserPrinterOptions = async () => {
    const Info = await this.props.getUserPrinterOptions();
    const { userPrinter, printerOptions } = Info;
    const { printerSelected } = this.state;

    this.setState({
      printerOptions,
      printerSelected: printerSelected === null
        ? userPrinter
        : printerSelected !== userPrinter
          ? printerSelected
          : userPrinter,
    });
  };

  setValue = (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });

    if (attr === 'printOrder') {
      const { printOrderCode } = this.state;

      let printOrderLabel = '';

      const printOrderList = [
        { label: I18n.t('BEE3461' /* Ascendente */), value: 'asc' },
        { label: I18n.t('BEE3462' /* Descendente */), value: 'desc' },
      ];

      if (printOrderCode === 'asc') {
        printOrderLabel = I18n.t('BEE3461' /* Ascendente */);
      } else if (printOrderCode === 'desc') {
        printOrderLabel = I18n.t('BEE3462' /* Descendente */);
      }

      await this.setState({
        printOrderList,
        printOrder: printOrderCode,
        printOrderLabel,
      });
    }

    if (attr === 'columnSide') {
      const { columnSideCode } = this.state;

      let columnSideLabel = '';

      const columnSideList = [
        { label: I18n.t('BEE203' /* Ambos */), value: 'both' },
        { label: I18n.t('BEE3398' /* Par */), value: 'even' },
        { label: I18n.t('BEE3399' /* Ímpar */), value: 'odd' },
      ];

      if (columnSideCode === 'both') {
        columnSideLabel = I18n.t('BEE203' /* Ambos */);
      } else if (columnSideCode === 'even') {
        columnSideLabel = I18n.t('BEE3398' /* Par */);
      } else if (columnSideCode === 'odd') {
        columnSideLabel = I18n.t('BEE3399' /* Ímpar */);
      }

      await this.setState({
        columnSideList,
        columnSide: columnSideCode,
        columnSideLabel,
      });
    }
  };

  hidePrinterDialog = () => {
    this.setState({
      showPrinterDialog: false,
      printerSelected: null,
    });
  };

  checkPrinters = () => {
    const {
      printerOptions, printerSelected,
    } = this.state;

    if (printerOptions.length === 0 && printerSelected === null) {
      addNotification(
        'danger',
        I18n.t('BEE2315' /* Etiqueta Lote com Saldo de Estoque */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
    } else if (printerOptions && printerOptions.length > 1) {
      this.setState({ showPrinterDialog: true });
    } else {
      this.printOut();
    }
  };

  printOut = async () => {
    this.setState({ showPrinterDialog: false });
    try {
      const {
        addressFrom, addressTo,
        printerOptions, printerSelected,
        printOrder, columnSide,
      } = this.state;

      const selected = (printerSelected === null) ? printerOptions[0].value : printerSelected;
      const filters = returnFiltersByRules([
        ['addressCode', { type: 'between', value: [addressFrom.toLocaleUpperCase(), addressTo.toLocaleUpperCase()] }],
      ]);

      const result = await this.props.printLotWithStockBalance(selected, filters, printOrder, columnSide);
      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2315' /* Etiqueta Lote com Saldo de Estoque */),
          I18n.t('BEE1441' /* Erro ao tentar imprimir */),
          'top-right',
        );
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification(
          'danger',
          I18n.t('BEE2315' /* Etiqueta Lote com Saldo de Estoque */),
          messageError,

          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2315' /* Etiqueta Lote com Saldo de Estoque */),
          I18n.t('BEE1441' /* Erro ao tentar imprimir */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      addressFrom, addressTo, printerOptions,
      showPrinterDialog, printerSelected, branchName,
      columnSide, columnSideLabel, columnSideList,
      printOrder, printOrderLabel, printOrderList,
    } = this.state;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        noButton
        title={I18n.t('BEE2315' /* Etiqueta Lote com Saldo de Estoque */)}
        content={(
          <>
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                setValueDrop={this.setValueDrop}
                inputs={(formContext) => ([
                  formContext.createInput(
                    branchName,
                    'branchName',
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    '',
                    'text',
                    false,
                    true,
                    undefined,
                    4,
                  ),
                  formContext.createInputRange(
                    { from: addressFrom.toLocaleUpperCase(), to: addressTo.toLocaleUpperCase() },
                    { from: 'addressFrom', to: 'addressTo' },
                    `${I18n.t('BEE267' /* Endereço */)}:`,
                    { from: 'z'.repeat(20), to: 'z'.repeat(20) },
                    { from: 'text', to: 'text' },
                  ),
                  formContext.createSelectDropDown(
                    { value: columnSide, label: columnSideLabel },
                    'columnSide',
                    `${I18n.t('BEE3397' /* Gerar Lados (Coluna) */)}:`,
                    columnSideList,
                    false,
                  ),
                  formContext.createSelectDropDown(
                    { value: printOrder, label: printOrderLabel },
                    'printOrder',
                    `${I18n.t('BEE3463' /* Ordem de Impressão */)}:`,
                    printOrderList,
                    false,
                  ),
                ])}
              />
            </div>
            {(showPrinterDialog && (
            <SweetAlert
              confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1324' /* Selecionar Impressora */)}
              onConfirm={() => this.printOut()}
              onCancel={() => this.hidePrinterDialog()}
            >
              <div className="mt-4">
                <Form
                  noPanel
                  setValue={this.setValue}
                  inputs={(formContext) => ([
                    formContext.createSelect(
                      printerSelected,
                      'printerSelected',
                      I18n.t('BEE328' /* Impressora */),
                      printerOptions,
                      '',
                      '',
                      12,
                      true,
                    ),
                  ])}
                />
              </div>
            </SweetAlert>
            ))}
          </>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            className="btn btn-120 btn-primary p-5 m-5"
            onClick={() => this.checkPrinters()}
          >
            {I18n.t('BEE1270' /* Imprimir */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  printLotWithStockBalance: (printerCode, filters, printOrder, columnSide) => dispatch(printLotWithStockBalance(printerCode, filters, printOrder, columnSide)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelLotWithStockBalance);
