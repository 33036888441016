import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from '../../../components/form/button';
import Select from '../../../components/form/select';
import addNotification from '../../../components/notification';
import { getBranchesOptions } from '../../../app/store/actions/branches';
import { updateErpStockTable, getListInventoryErp } from '../../../app/store/actions/inventoriesErp';
import { getProductsStockBalance, setProductsStockBalance } from '../../../app/store/actions/stocks';
import StockBalanceErp from './StockBalanceErp';
import SelectedStockBalance from './selectedStockBalance';
import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';

class StockBalanceComparison extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      branchCode: null,
      branchName: '',
      listBranch: [],
      selectedProductCode: null,
      onlyDifference: false,
    };
  }

  async componentDidMount() {
    this.props.setProductsStockBalance([]);
    await this.getBranchesOptions();
    await this.getListInventoryErp();
  }

  async handleInputChange(event) {
    const { onlyDifference } = this.state;
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    await this.setState({
      [name]: value,
    });

    if (name === 'onlyDifference' && (onlyDifference !== value.value)) {
      await this.getListInventoryErp();
    }
  }

  setValueDrop = async (attr, value) => {
    const { branchCode } = this.state;
    await this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });

    if (attr === 'branch' && (value && branchCode !== value.value)) {
      await this.getListInventoryErp();
    }
  };

  createSelectDropDown = (value, attr, label, items, disabled, id) => (
    <div className="form-group row" style={{ marginBottom: 'auto' }}>
      <label className="col-form-label">{label}</label>
      <div className="col-md-5" style={{ marginRight: 'auto' }}>
        <Select
          value={value.label ? value : ''}
          id={id}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={I18n.t('BEE436' /* Selecionar */)}
          isDisabled={disabled}
        />
      </div>
    </div>
  );

  createToggleBox = (item, attr, label, disabled) => (
    <div className="form-group row m-b-15">
      <label className="col-form-label" />
      <div className="col-md-0">
        <div className="row">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={this.handleInputChange}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`}>{label}</label>
          </div>
        </div>
      </div>
    </div>
  );

  getBranchesOptions = async () => {
    try {
      const listBranch = await this.props.getBranchesOptions();
      if (listBranch.length) {
        const branchUserMain = await (this.props.userLogged && this.props.userLogged.mainBranch)
          ? listBranch.find((element) => element.value === this.props.userLogged.mainBranch)
          : null;
        await this.setState({
          listBranch: [...listBranch],
          branchCode: (branchUserMain && branchUserMain.value) ? branchUserMain.value : null,
          branchName: (branchUserMain && branchUserMain.label) ? branchUserMain.label : '',
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2328' /* Comparativo Saldo Stock */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2328' /* Comparativo Saldo Stock */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  getProductsStockBalance = async (productCode) => {
    if (this.state.selectedProductCode !== productCode) {
      this.setState({ selectedProductCode: productCode });
    }
    try {
      await this.props.getProductsStockBalance({ branchCode: this.state.branchCode, productCode });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification('danger', I18n.t('BEE2328' /* Comparativo Saldo Stock */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2328' /* Comparativo Saldo Stock */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  getListInventoryErp = async () => {
    const { branchCode, onlyDifference, selectedProductCode } = this.state;
    try {
      await this.props.getListInventoryErp(branchCode, onlyDifference);
      if (selectedProductCode && this.props.listStockErp.find((elem) => elem.productCode === selectedProductCode)) {
        await this.getProductsStockBalance(selectedProductCode);
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification('danger', I18n.t('BEE2328' /* Comparativo Saldo Stock */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2328' /* Comparativo Saldo Stock */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  updateErpStockTable = async (branchCode) => {
    try {
      const result = await this.props.updateErpStockTable(branchCode);
      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2328' /* Comparativo Saldo Stock */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        await this.getListInventoryErp();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification('danger', I18n.t('BEE2328' /* Comparativo Saldo Stock */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2328' /* Comparativo Saldo Stock */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  reprocessErpStock = async () => {
    const { branchCode } = this.state;

    if (!branchCode) {
      addNotification(
        'danger',
        I18n.t('BEE2328' /* Comparativo Saldo Stock */),
        I18n.t('BEE2028' /* Filial não localizado */),
        'top-right',
      );
    } else {
      await this.updateErpStockTable(branchCode);
    }
  };

  render() {
    const {
      branchCode, branchName, listBranch, onlyDifference,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE33' /* Consultas */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE32' /* Estoque */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE2328' /* Comparativo Saldo Stock */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center">
          <h1 className="page-header mb-0">
            {I18n.t('BEE2328' /* Comparativo Saldo Stock */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.CONSULT_STOCK_BALANCE_COMPARISON_HELP} />
        </div>
        <div className="mt-2 mb-3 row margin-Stock-balance-comparison">
          <div className="select-input-Stock-balance-comparison">
            {this.createSelectDropDown(
              { value: branchCode, label: branchName },
              'branch',
              `${I18n.t('BEE145' /* Filial */)}: `,
              listBranch,
              false,
              1,
            )}
          </div>
          <div className="ml-auto margin-top-stock-balance-comparison">
            <OneClickButton
              className="btn btn-primary btn-sm btn-rounded"
              onClick={this.reprocessErpStock}
            >
              <i className="fa fa-redo mr-2" />
              {I18n.t('BEE2329' /* Reprocessar Estoque ERP */)}
            </OneClickButton>
          </div>
        </div>
        {this.createToggleBox(
          onlyDifference,
          'onlyDifference',
          I18n.t('BEE3212' /* Somente Produtos com Diferença */),
          false,
        )}

        <StockBalanceErp
          // updateVariable={this.updateVariable}
          selectedProductCode={this.state.selectedProductCode}
          getListInventoryErp={this.getListInventoryErp}
          getProductsStockBalance={this.getProductsStockBalance}
        />

        <SelectedStockBalance />

      </div>
    );
  }
}

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    data, isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged && state.app.userLogged,
  listStockErp: state.inventoriesErp && state.inventoriesErp.listStockErp,
});

const mapDispatchToProps = (dispatch) => ({
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  updateErpStockTable: (branchCode) => dispatch(updateErpStockTable(branchCode)),
  getListInventoryErp: (branchCode, onlyDifference) => dispatch(getListInventoryErp(branchCode, onlyDifference)),
  getProductsStockBalance: (tableParams) => dispatch(getProductsStockBalance(tableParams)),
  setProductsStockBalance: (productsStockBalance) => dispatch(setProductsStockBalance(productsStockBalance)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockBalanceComparison);
