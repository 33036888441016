import { endOfDay, startOfDay, subDays } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle,
  PopoverHeader,
  UncontrolledButtonDropdown,
  UncontrolledPopover,
} from 'reactstrap';

import {
  getInboundOrdersDashboardTotal,
  setDashboardInboundOrdersDates, setSelectedUserBranches,
} from '../../app/store/actions/inboundOrders';
import { getSupplierOptions } from '../../app/store/actions/suppliers';
import { getOrdersTypeOptions } from '../../app/store/actions/typeOrder';

import CardDashboard from '../../components/card/CardDashboard';
import DateRangePicker from '../../components/date/DateRangePicker.jsx';
import FilterSelectionList from '../../components/form/filterSelectionList';
import Table from '../../components/table/Table';

import ROUTES from '../../config/routes';
import InboundOrderHelpers from '../../helpers/inboundOrders';

class ReceiptOverview extends React.PureComponent {
  static ShortenValue = (num) => {
    num = num.toString().replace(/[^0-9.]/g, '');
    if (num < 1000) {
      return num;
    }

    const si = [
      { value: 1E3, symbol: 'K' },
      { value: 1E6, symbol: 'M' },
      { value: 1E9, symbol: 'G' },
      { value: 1E12, symbol: 'T' },
      { value: 1E15, symbol: 'P' },
      { value: 1E18, symbol: 'E' },
    ];
    let i;
    // for negative value is work
    for (i = si.length - 1; i > 0; i -= 1) {
      if (Math.abs(num) >= si[i].value) {
        break;
      }
    }
    return num + si[i].symbol;
  };

  constructor(props) {
    super(props);

    this.breadcrumb = [
      { value: I18n.t('BEE42' /* Dashboard */), iconClassName: 'fa fa-chart-line m-t-10 m-r-5' },
      { value: I18n.t('BEE36' /* Recebimento */) },
      { value: I18n.t('BEE2539' /* Visão Geral Recebimento */), active: true },
    ];

    function inboundOrderProgress(status, products = []) {
      let progress = 0;
      let pending = [];

      switch (status) {
        case 7:
          progress = 100;
          break;
        case 6:
          pending = products.filter((prod) => prod.status < 4);
          progress = parseFloat((pending.length / products.length) * 100);
          break;
        case 5:
          progress = 100;
          break;
        case 4:
          pending = products.filter((prod) => prod.status === 2);
          progress = parseFloat((pending.length / products.length) * 100);
          break;
        default:
          progress = 0;
          break;
      }

      return progress;
    }

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.showInboundOrderDetail(rows.row)}
                >
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE342' /* Fornecedor */),
        accessor: 'supplier.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{InboundOrderHelpers.inboundOrderStatus(row.value)}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {InboundOrderHelpers.inboundOrderStatus().map((element, index) => (
              (element.value !== 2 && element.value !== 8) && (
                <option
                  key={index}
                  value={element.value}
                >
                  {element.label}
                </option>
              )
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE444' /* Data de Entrega */),
        accessor: 'deliveryDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('L')}
          </span>
        ),
      }, {
        Header: I18n.t('BEE405' /* Volumes */),
        accessor: 'expectedVolumes',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 85,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{parseInt(row.value || 0, 10)}</span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE27' /* Produtos */),
        accessor: 'products.length',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 90,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{row.value || 0}</span>
          </div>
        ),
      },
      {
        Header: I18n.t('BEE54' /* Progresso */),
        minWidth: 100,
        filterMethod: (filter, row) => String(
          inboundOrderProgress(row.status, row._original.products),
        ).includes(filter.value),
        Cell: (row) => {
          const progress = inboundOrderProgress(row.row.status, row.original.products);

          return (
            <div className="progress rounded-corner">
              <div
                className="progress-bar f-s-10 f-w-600"
                style={{
                  width: `${progress}%`,
                  // eslint-disable-next-line no-nested-ternary
                  backgroundColor: ((progress > 70) ? '#00acac' : ((progress > 40) ? '#f59c1a' : '#ff5b57')),
                  transition: 'all .2s ease-out',
                  minWidth: '30px',
                }}
              >
                {progress}
                %

              </div>
            </div>
          );
        },
      },
    ];

    this.state = {
      defaultSorted: [{
        id: 'orderNumber',
        desc: false,
      }],
      dataCards: [{
        color: '#C69400',
        bigCard: {
          title: I18n.t('BEE913' /* Placa */),
          data: [],
        },
        cards: [],
      },
      {
        color: '#2E79BC',
        bigCard: {
          title: I18n.t('BEE37' /* Conferência */),
          data: [],
        },
        cards: [],
      }, {
        color: '#518332',
        bigCard: {
          title: I18n.t('BEE2540' /* Armazenagem */),
          data: [],
        },
        cards: [],
      }, {
        color: '#002163',
        bigCard: {
          title: I18n.t('BEE2541' /* Encerrado */),
          data: [],
        },
        cards: [],
      }],
    };
  }

  async componentDidMount() {
    if (!this.props.location.state || !Object.keys(this.props.location.state).length) {
      const startDate = moment().subtract(30, 'd');
      const endDate = moment();
      const dates = { startDate, endDate };

      await this.props.setDashboardInboundOrdersDates(dates);

      await this.getInboundOrders();

      const listOrderTypes = await this.props.getOrdersTypeOptions();
      const listSuppliers = await this.props.getSupplierOptions();

      this.setState({
        listOrderTypes,
        listSuppliers,
      });
    }

    this.loadDataCards();

    this.props.history.push({
      state: {},
    });
  }

  getInboundOrders = async () => {
    const {
      startDate, endDate,
    } = this.props.dates;

    const branchesToSearch = [];
    if (this.props.selectedBranches) {
      this.props.selectedBranches.map(({ value }) => branchesToSearch.push(value));
    }

    const orderTypesToSearch = [];
    if (this.state.selectedOrderTypes) {
      this.state.selectedOrderTypes.map(({ value }) => orderTypesToSearch.push(value));
    }

    const suppliersToSearch = [];
    if (this.state.selectedSuppliers) {
      this.state.selectedSuppliers.map(({ value }) => suppliersToSearch.push(value));
    }

    await this.props.getInboundOrdersDashboardTotal(
      startDate,
      endDate,
      branchesToSearch,
      orderTypesToSearch,
      suppliersToSearch,
    );

    await this.loadDataCards();
    const existOrder = {};

    this.setState({
      selectedOrder: existOrder || {},
    });
  };

  showInboundOrderDetail = (inboundOrder) => {
    this.props.history.push({
      pathname: ROUTES.INBOUND_ORDER_DETAIL,
      state: {
        inboundOrder: inboundOrder._original,
        dashboardRoute: 'receiptOverview',
      },
      dice: {
        startDate: moment(this.state.startDate).format('MM/DD/YYYY'),
        endDate: moment(this.state.endDate).format('MM/DD/YYYY'),
        currentRange: this.state.currentRange,
        page: this.state.page,
      },
    });
  };

  handleDateApplyEvent = async (value) => {
    const startDate = value[0];
    const endDate = value[1];

    const dates = { startDate, endDate };

    await this.props.setDashboardInboundOrdersDates(dates);
  };

  handleBranchesSelected = async (event) => {
    const selectedBranches = [];

    event.map((element) => selectedBranches.push({
      value: element.value,
      label: element.label,
    }));

    await this.props.setSelectedUserBranches(selectedBranches);
  };

  handleOrderTypesSelected = async (event) => {
    const selectedOrderTypes = [];

    event.map((element) => selectedOrderTypes.push({
      value: element.value,
      label: element.label,
    }));

    this.setState({
      selectedOrderTypes: selectedOrderTypes || {},
    });
  };

  handleSuppliersSelected = async (event) => {
    const selectedSuppliers = [];

    event.forEach((element) => {
      selectedSuppliers.push({
        value: element.value,
        label: element.label,
      });
    });

    this.setState({
      selectedSuppliers: selectedSuppliers || {},
    });
  };

  DataCards = (data, status) => {
    const dataTemp = [];

    const dataFilter = data.filter((item) => item.status === status);

    if (dataFilter.length <= 0) {
      dataTemp.push({ title: I18n.t('BEE462' /* Documentos */), value: 0 });
      dataTemp.push({ title: I18n.t('BEE93' /* Linhas */), value: 0 });
      dataTemp.push({ title: I18n.t('BEE2542' /* Metros */), value: 0 });
      dataTemp.push({ title: I18n.t('BEE2543' /* Unidades */), value: 0 });
    } else {
      dataFilter.map((card) => {
        dataTemp.push({ title: I18n.t('BEE462' /* Documentos */), value: card.TotalInboundOrders });
        dataTemp.push({ title: I18n.t('BEE93' /* Linhas */), value: card.TotalProducts });
        dataTemp.push({
          title: I18n.t('BEE2542' /* Metros */),
          value: this.ShortenValue(Number(card.TotalQuantProdCableInboundOrders)),
        });
        dataTemp.push({
          title: I18n.t('BEE2543' /* Unidades */),
          value: this.ShortenValue(Number(card.TotalQuantProdNoCableInboundOrders)),
        });

        return dataTemp;
      });
    }

    return dataTemp;
  };

  DataCardsTotal = (data, status) => {
    let document = 0;
    let line = 0;
    let quantity = 0;
    let quantityOthers = 0;

    if (data) {
      data.filter((item) => status.indexOf(item.status) >= 0).map((card) => {
        document += card.TotalInboundOrders;
        line += card.TotalProducts;
        quantity += parseFloat(card.TotalQuantProdCableInboundOrders);
        quantityOthers += parseFloat(card.TotalQuantProdNoCableInboundOrders);

        return '';
      });
    }

    return [
      { title: I18n.t('BEE462' /* Documentos */), value: document },
      { title: I18n.t('BEE93' /* Linhas */), value: line },
      { title: I18n.t('BEE2542' /* Metros */), value: this.ShortenValue(quantity) },
      { title: I18n.t('BEE2543' /* Unidades */), value: this.ShortenValue(quantityOthers) },
    ];
  };

  loadDataCards = () => {
    const dataCards = [{
      color: '#C69400',
      bigCard: {
        title: I18n.t('BEE913' /* Placa */),
        data: this.DataCardsTotal(this.props.inboundOrdersTotalByStatus, [1]),
      },
      cards: [{
        title: I18n.t('BEE1783' /* Pendente de Placa */),
        data: this.DataCards(this.props.inboundOrdersTotalByStatus, 1),
      }],
    },
    {
      color: '#2E79BC',
      bigCard: {
        title: I18n.t('BEE37' /* Conferência */),
        data: this.DataCardsTotal(this.props.inboundOrdersTotalByStatus, [3, 4, 5]),
      },
      cards: [{
        title: I18n.t('BEE472' /* Conferência Física Pendente */),
        data: this.DataCards(this.props.inboundOrdersTotalByStatus, 3),
      }, {
        title: I18n.t('BEE473' /* Conferência Física Iniciada */),
        data: this.DataCards(this.props.inboundOrdersTotalByStatus, 4),
      }, {
        title: I18n.t('BEE474' /* Conferência Física Finalizada */),
        data: this.DataCards(this.props.inboundOrdersTotalByStatus, 5),
      }],
    }, {
      color: '#518332',
      bigCard: {
        title: I18n.t('BEE2540' /* Armazenagem */),
        data: this.DataCardsTotal(this.props.inboundOrdersTotalByStatus, [6]),
      },
      cards: [{
        title: I18n.t('BEE475' /* Em armazenagem */),
        data: this.DataCards(this.props.inboundOrdersTotalByStatus, 6),
      }],
    }, {
      color: '#002163',
      bigCard: {
        title: I18n.t('BEE2541' /* Encerrado */),
        data: this.DataCardsTotal(this.props.inboundOrdersTotalByStatus, [7, 9]),
      },
      cards: [{
        title: I18n.t('BEE427' /* Armazenado */),
        data: this.DataCards(this.props.inboundOrdersTotalByStatus, 7),
      }, {
        title: I18n.t('BEE64' /* Cancelado */),
        data: this.DataCards(this.props.inboundOrdersTotalByStatus, 9),
      }],
    }];

    this.setState({
      dataCards,
    });
  };

  render() {
    const { inboundOrdersList = [], inboundOrdersTotal = [] } = this.props;
    const startDate = startOfDay(subDays(new Date(), 30));
    const endDate = endOfDay(new Date());
    const {
      defaultSorted, selectedOrder, listOrderTypes, listSuppliers,
    } = this.state;

    return (
      <div>
        {!!this.breadcrumb && this.breadcrumb.length && (
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              {this.breadcrumb.map((it, index) => (
                <li key={`${it.value}${index}`} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                  {
                    it.iconClassName
                      ? (
                        <>
                          <i className="fa fa-chart-line m-t-10 m-r-5" />
                          {' '}
                          {it.value}
                        </>
                      ) : (
                        it.value
                      )
                  }
                </li>
              ))}
            </ol>
          </div>
        )}
        <h2>{I18n.t('BEE2539' /* Visão Geral Recebimento */)}</h2>
        <div className="d-sm-flex">
          <div className="d-flex mt-2">
            <DateRangePicker
              onChange={(value) => {
                this.handleDateApplyEvent(value);
              }}
              defaultValue={[startDate, endDate]}
            />
            <UncontrolledPopover trigger="hover" placement="top" target="dateRangePicker">
              <PopoverHeader>
                { I18n.t('BEE3342', {
                  0: (I18n.t('BEE3344' /* data de criação */)),
                } /* O intervalo segue o critério pela %{0} */) }
              </PopoverHeader>
            </UncontrolledPopover>
          </div>
          {/* Filiais */}
          <div className="d-flex mt-2">
            <FilterSelectionList
              value={this.props.selectedBranches}
              onChangeValue={this.handleBranchesSelected}
              placeholderValue={I18n.t('BEE2424' /* Todas as filiais estão selecionadas */)}
              optionsValue={this.props.userBranches}
              onClickValue={this.getInboundOrders}
            />
          </div>
          {/* Tipo de Documento */}
          <div className="d-flex mt-2 ml-1">
            <FilterSelectionList
              value={this.props.selectedOrderTypes}
              onChangeValue={this.handleOrderTypesSelected}
              placeholderValue={I18n.t('BEE3355' /* Todos os tipos de documentos estão selecionados. */)}
              optionsValue={listOrderTypes}
              onClickValue={this.getInboundOrders}
            />
          </div>
          {/* Fornecedores */}
          <div className="d-flex mt-2 ml-1">
            <FilterSelectionList
              value={this.props.selectedSuppliers}
              onChangeValue={this.handleSuppliersSelected}
              placeholderValue={I18n.t('BEE3601' /* Todos os fornecedores foram selecionados. */)}
              optionsValue={listSuppliers}
              onClickValue={this.getInboundOrders}
            />
          </div>
        </div>
        <div
          className="mt-1 d-flex flex-row justify-content-between text-white"
        >
          <CardDashboard dataCards={this.state.dataCards} />
        </div>
        <div className="text-gray-darker my-4">
          <h1>{I18n.t('BEE2544' /* Totais Gerais em Aberto */)}</h1>
          <div
            className="d-flex flex-row justify-content-around mt-4"
            style={{
              borderLeft: '4px solid var(--gray)',
            }}
          >
            <div className="w-100 text-align-center px-1">
              <h4>{I18n.t('BEE462' /* Documentos */)}</h4>
              <h1>
                {inboundOrdersTotal.TotalInboundOrders
                  ? this.ShortenValue(inboundOrdersTotal.TotalInboundOrders)
                  : 0}
              </h1>
            </div>
            <div className="w-100 text-align-center px-1">
              <h4>{I18n.t('BEE93' /* Linhas */)}</h4>
              <h1>{inboundOrdersTotal.TotalProducts ? this.ShortenValue(inboundOrdersTotal.TotalProducts) : 0}</h1>
            </div>
            <div className="w-100 text-align-center px-1">
              <h4>{I18n.t('BEE2545' /* Metros de Cabos */)}</h4>
              <h1>
                {inboundOrdersTotal.TotalQuantProdCableInboundOrders
                  ? this.ShortenValue(Number(inboundOrdersTotal.TotalQuantProdCableInboundOrders))
                  : 0}

              </h1>
            </div>
            <div className="w-100 text-align-center px-1">
              <h4>{I18n.t('BEE2543' /* Unidades */)}</h4>
              <h1>
                {inboundOrdersTotal.TotalQuantProdNoCableInboundOrders
                  ? this.ShortenValue(Number(inboundOrdersTotal.TotalQuantProdNoCableInboundOrders)) : 0}

              </h1>
            </div>
          </div>
        </div>
        <div className="tableData">
          <Table
            downloadCSV
            onClickCollapse
            panelHeaderProps={{
              onClickReload: () => this.getInboundOrders(),
              pageDescription: I18n.t('BEE793' /* Todos */).toUpperCase(),
            }}
            filterable
            data={inboundOrdersList}
            columns={this.tableColumns}
            expander
            defaultPageSize={10}
            defaultSorted={defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            noDataText={I18n.t('BEE2423' /* Não há dados na data informada */)}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  style: {
                    background: selectedOrder && rowInfo.original.id === selectedOrder.id ? 'silver' : 'white',
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  inboundOrdersList: state.inboundOrders && state.inboundOrders.inboundOrdersList,
  inboundOrdersTotal: state.inboundOrders && state.inboundOrders.inboundOrdersTotal,
  inboundOrdersTotalByStatus: state.inboundOrders && state.inboundOrders.inboundOrdersTotalByStatus,
  userBranches: state.inboundOrders.userBranches,
  selectedBranches: state.inboundOrders.selectedBranches,
  dates: state.inboundOrders.dates,
});

const mapDispatchToProps = (dispatch) => ({
  setDashboardInboundOrdersDates: (dates) => dispatch(setDashboardInboundOrdersDates(dates)),
  setSelectedUserBranches: (selectedBranches) => dispatch(setSelectedUserBranches(selectedBranches)),
  getInboundOrdersDashboardTotal:
    (
      startDate,
      endDate,
      selectedBranches,
      listOrderTypes,
      listSuppliers,
    ) => dispatch(getInboundOrdersDashboardTotal(
      startDate,
      endDate,
      selectedBranches,
      listOrderTypes,
      listSuppliers,
    )),
  getOrdersTypeOptions: () => dispatch(getOrdersTypeOptions()),
  getSupplierOptions: () => dispatch(getSupplierOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReceiptOverview));
