import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getSchedulesList, deleteSchedule } from '../../../../app/store/actions/schedules';

class ScheduleList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      scheduleUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showScheduleDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditSchedule(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopySchedule(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDeleteSchedule(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>
            {`${row.value} - ${row.original.branch.name}`}
          </span>
        ),
      }, {
        Header: I18n.t('BEE662' /* Segunda-feira */),
        accessor: 'monday',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE663' /* Terça-feira */),
        accessor: 'tuesday',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE664' /* Quarta-feira */),
        accessor: 'wednesday',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE665' /* Quinta-feira */),
        accessor: 'thursday',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE666' /* Sexta-feira */),
        accessor: 'friday',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE667' /* Sábado */),
        accessor: 'saturday',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE668' /* Domingo */),
        accessor: 'sunday',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE669' /* CIF */),
        accessor: 'cif',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE670' /* FOB */),
        accessor: 'fob',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE671' /* Venda */),
        accessor: 'sale',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE34' /* Transferência */),
        accessor: 'transfer',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      },
    ];
  }

  componentDidMount() {
    this.getSchedules();
  }

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      scheduleUpdate: null,
    });
  };

  getSchedules = async () => {
    await this.props.getSchedulesList();
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deleteSchedule = async (schedule) => {
    const result = await this.props.deleteSchedule(schedule._original.id);

    this.setInitState();
    if (result) {
      addNotification('success', I18n.t('BEE687' /* Eliminar Agendamento */), I18n.t('BEE688', { 0: schedule.code } /* Agendamento %{0} eliminado com sucesso! */), 'top-right');
    }
    await this.getSchedules();
  };

  showScheduleDetail = (schedule) => {
    this.props.history.push({
      pathname: ROUTES.SCHEDULE_DETAIL,
      state: {
        schedule: schedule._original,
      },
    });
  };

  showEditSchedule = (schedule) => {
    this.props.history.push({
      pathname: ROUTES.SCHEDULE_EDIT,
      state: {
        schedule: schedule._original,
      },
    });
  };

  showCopySchedule = (schedule) => {
    this.props.history.push({
      pathname: ROUTES.SCHEDULE_COPY,
      state: {
        schedule: schedule._original,
      },
    });
  };

  showDeleteSchedule = (schedule) => {
    this.setState({ showModalDelete: true, scheduleUpdate: schedule });
  };

  render() {
    const { scheduleUpdate, showModalDelete } = this.state;
    const { schedulesList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE658' /* Agendamentos */)}</li>
          </ol>
        </div>
        <Table
          headerTitle={I18n.t('BEE658' /* Agendamentos */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.SCHEDULE_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE689' /* Incluir Agendamento */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getSchedules,
          }}
          filterable
          data={schedulesList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteSchedule(scheduleUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE690', { 0: scheduleUpdate.code } /* Agendamento %{0} será eliminado! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  schedulesList: state.schedules && state.schedules.schedulesList,
});

const mapDispatchToProps = (dispatch) => ({
  getSchedulesList: () => dispatch(getSchedulesList()),
  deleteSchedule: (scheduleId) => dispatch(deleteSchedule(scheduleId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScheduleList));
