import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { getBaptismLabelProducts } from '../../../../../../app/store/actions/outboundOrders';
import OneClickButton from '../../../../../../components/form/button';
import Form from '../../../../../../components/form/form';
import addNotification from '../../../../../../components/notification';
import { PanelPage } from '../../../../../../components/pages/pages';
import Table from '../../../../../../components/table/Table';
import OnboundOrderHelpers from '../../../../../../helpers/outboundRange';
import ROUTES from '../../../../../../config/routes';

class BaptismLabelConsultOutbound extends React.PureComponent {
  constructor() {
    super();
    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE33' /* Consultas */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE1719' /* Etiqueta Batismo */), active: true },
    ];

    this.state = {
      baptismLabel: '',
      disableLabel: false,
      productsList: [],
      defaultPageSize: 5,
      pageSizeOptions: [5, 10],
      defaultSorted: [{ id: '', desc: false }],
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE1972' /* Número Documento */),
        accessor: 'outboundOrder.orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        Cell: (rows) => (
          <span>{OnboundOrderHelpers.outboundRangeProductStatus(rows.value)}</span>
        ),
      }, {
        Header: I18n.t('BEE758' /* Descrição do Produto */),
        accessor: 'product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      },
    ];
  }

  setValue = async (attr, value, verifica) => {
    if (attr === 'amount') {
      if (parseInt(value, 10) >= 1) {
        this.setState({
          [`${attr}`]: value,
        });
      }
    } else {
      this.setState({
        [`${attr}`]: value,
      });
    }
    if (attr === 'baptismLabel' && verifica) {
      this.setState({
        disableLabel: false,
      });
    }
  };

  keypressConfirmPicking = (e, id, attr) => {
    if (e.keyCode === 13) {
      if (attr === 'baptismLabel') this.getBaptismLabelProducts();
    }
  };

  getBaptismLabelProducts = async () => {
    const { baptismLabel } = this.state;

    if (!baptismLabel) {
      addNotification(
        'danger',
        I18n.t('BEE1971' /* Consulta Etiqueta Batismo */),
        `${I18n.t('BEE1076' /* Informe o Batismo */)}!`,
        'top-right',
      );
    } else {
      const baptismProductsList = await this.props.getProducts(baptismLabel);

      if (baptismProductsList === null || !baptismProductsList.length) {
        addNotification(
          'danger',
          I18n.t('BEE1971' /* Consulta Etiqueta Batismo */),
          I18n.t('BEE1475' /* Etiqueta Batismo inválida! */),
          'top-right',
        );
      }

      if (baptismProductsList !== null && baptismProductsList.length) {
        addNotification(
          'success',
          I18n.t('BEE1971' /* Consulta Etiqueta Batismo */),
          I18n.t('BEE1973', { 0: baptismProductsList.length } /* %{0} produto(s) encontrado(s) */),
          'top-right',
        );
        this.setState({
          productsList: baptismProductsList,
          baptismLabel,
          disableLabel: true,
        });
      }
    }
  };

  render() {
    const {
      baptismLabel, defaultPageSize, defaultSorted, pageSizeOptions, productsList, disableLabel,
    } = this.state;
    return (
      <>
        <PanelPage
          breadcrumb={this.breadcrumb}
          title={I18n.t('BEE1719' /* Etiqueta Batismo */)}
          wikiHelp={ROUTES.CONSULT_BAPTISM_LABEL_OUTBOUND_HELP}
          content={(
            <div className="col-md-4" onSubmit={(e) => e.preventDefault()}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputButton(
                    baptismLabel,
                    'baptismLabel',
                    `${I18n.t('BEE1719' /* Etiqueta Batismo */)}:`,
                    I18n.t('BEE1076' /* Informe o Batismo */),
                    'text',
                    false,
                    disableLabel,
                    undefined,
                    undefined,
                    this.keypressConfirmPicking,
                    1,
                  ),
                ])}
              />
            </div>
          )}
          footerContent={(
            <OneClickButton
              type="button"
              className="btn btn-120 btn-primary p-5 m-5"
              onClick={() => this.getBaptismLabelProducts()}
            >
              {I18n.t('BEE407' /* Buscar */)}
            </OneClickButton>
          )}
        />
        <Table
          panelHeaderProps={{ noButton: true, children: I18n.t('BEE27' /* Produtos */).toUpperCase() }}
          filterable
          expander
          columns={this.tableColumns}
          data={productsList}
          pageSizeOptions={pageSizeOptions}
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
  getProducts: (baptismLabel) => dispatch(getBaptismLabelProducts(baptismLabel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BaptismLabelConsultOutbound);
