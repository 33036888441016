import RestService from '../services/rest';

// GET
async function getOpenConference(dockCode) {
  return RestService.getAuthenticated('dockControl/openConference', { dockCode });
}

async function dockControlList(filterStartDate, filterEndDate) {
  return RestService.getAuthenticated(
    `dockControl/list?filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}`,
  );
}

async function getOutboundOrdersChecked(dockControlId) {
  return RestService.getAuthenticated('dockControl/outboundOrdersChecked', { dockControlId });
}

async function getOutboundOrderDockControl(outboundVolumeId) {
  return RestService.postAuthenticated('dockControl/outboundOrderDockControl', { outboundVolumeId });
}

// POST
async function createDockControl(dockCode, outboundVolumeId, carrierCode) {
  return RestService.postAuthenticated('dockControl/createDockControl', { dockCode, outboundVolumeId, carrierCode });
}

// PUT
async function finalizeCheckVolumes(dockControlId) {
  return RestService.putAuthenticated('dockControl/finalizeCheckVolumes', { dockControlId });
}

async function checkVolumes(dockControlId, outboundVolumeId, carrierCode, dockCode) {
  return RestService.putAuthenticated('dockControl/checkVolumes', {
    dockControlId, outboundVolumeId, carrierCode, dockCode,
  });
}

async function outboundOrderWithdrawal(outboundOrderId, hasPermission) {
  return RestService.putAuthenticated('dockControl/outboundOrderWithdrawal', { outboundOrderId, hasPermission });
}

export default {
  getOpenConference,
  finalizeCheckVolumes,
  createDockControl,
  getOutboundOrdersChecked,
  checkVolumes,
  dockControlList,
  outboundOrderWithdrawal,
  getOutboundOrderDockControl,
};
