import ResupplyRequests from '../../server/resupply';
import { addLoading, removeLoading } from './loading';

export const setResupply = (rule) => ({
  type: 'SET_RESUPPLY',
  rule,
});

export const createResupply = (addressPicking, tableFilter) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ResupplyRequests.createResupply(addressPicking, tableFilter);
  } finally {
    dispatch(removeLoading());
  }
};

export const getListPickingAddressToResupply = (filter) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ResupplyRequests.getListPickingAddressToResupply(filter);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateResupply = (resupplyId, updateData) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const result = await ResupplyRequests.updateResupply(resupplyId, updateData);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateCancelResupply = (resupplyId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const result = await ResupplyRequests.updateCancelResupply(resupplyId);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const printResupply = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ResupplyRequests.printResupply();
  } finally {
    dispatch(removeLoading());
  }
};

export const getListAndCountAllResupply = (searchParameters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const result = await ResupplyRequests.getListAndCountAllResupply(searchParameters);
    return result;
  } catch (e) {
    return {
      rows: [],
      pages: 0,
      totalDataLength: 0,
    };
  } finally {
    dispatch(removeLoading());
  }
};
