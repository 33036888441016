import RestService from '../services/rest';

async function getDetailOrder(startDate, endDate, orderNumber = '') {
  return RestService.getAuthenticated(
    `returnPickingOrder/DetailOrder?filterStartDate=${startDate}&filterEndDate=${endDate}&orderNumber=${orderNumber}`,
  );
}

async function getDataGrid(tableParams) {
  return RestService.postAuthenticated('returnPickingOrder/list', tableParams);
}

async function createPickingReturnDocument(outboundOrderProductIds, outboundOrderId, orderNumber, branchCode) {
  return RestService.putAuthenticated('returnPickingOrder/createDocument', {
    outboundOrderProductIds, outboundOrderId, orderNumber, branchCode,
  });
}

export default {
  getDataGrid,
  getDetailOrder,
  createPickingReturnDocument,
};
