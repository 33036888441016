const INITIAL_STATE = {
  packagesList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PACKAGES': {
      return { ...state, packagesList: action.packages };
    }
    default:
      return state;
  }
};
