const INITIAL_STATE = {
  inventoriesErpList: [],
  listStockErp: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_INVENTORY_ERP': {
      return { ...state, inventoriesErpList: action.inventoriesErp };
    }
    case 'SET_LIST_INVENTORY_ERP': {
      return { ...state, listStockErp: [...action.listInventoriesErp] };
    }
    default:
      return state;
  }
};
