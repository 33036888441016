/* eslint-disable no-unused-vars */
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import { PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { getStockBalances } from '../../../app/store/actions/stocks';
import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';
import FormatHelpers from '../../../helpers/format';

class StockBalanceList extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      pages: 0,
      totalDataLength: 0,
    };

    this.defaultSorted = [{
      id: 'productCode',
      desc: false,
    }, {
      id: 'branchCode',
      desc: false,
    }];

    this.tableColumns = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 400,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2998' /* UM */),
        accessor: 'unitMeasure',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE530' /* Disponível */),
        accessor: 'balance',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
        filterable: false,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE482' /* Alocada */),
        accessor: 'allocated',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
        filterable: false,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE483' /* Reservada */),
        accessor: 'reserved',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>
            {(row.value !== 0 || row.original.waveTransferDocs)
                && (
                  <UncontrolledPopover trigger="hover" placement="top-start" target={`reserved${row.original.id}`}>
                    <PopoverHeader>
                      {row.original.waveTransferDocs
                        ? I18n.t('BEE3377', { 0: row.original.waveTransferDocs } /* Transferências Pendentes: %{0}. */)
                        : I18n.t('BEE3378' /* Não foi encontrado nenhum documento pendente de transferência. */)}
                    </PopoverHeader>
                  </UncontrolledPopover>
                )}
            <span id={`reserved${row.original.id}`}>
              {FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}
            </span>
          </span>
        ),
        filterable: false,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE504' /* Total */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
        filterable: false,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE432' /* Endereço */),
        accessor: 'addressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2090' /* Fabricante */),
        accessor: 'manufacturer',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE434' /* Data de Validade */),
        accessor: 'expirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE695' /* Código Complementar */),
        accessor: 'complementCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2038' /* Usuário Movimentação */),
        accessor: 'lastMovingUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 170,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2039' /* Data Movimentação */),
        accessor: 'lastMovingDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 170,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE696' /* Informação Complementar */),
        accessor: 'complementInfo',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE485' /* Usuário Última Entrada */),
        accessor: 'lastEntranceName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 170,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE484' /* Data Última Entrada */),
        accessor: 'lastEntranceAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 170,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE486' /* Usuário Última Contagem */),
        accessor: 'lastCountName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 190,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE487' /* Data Última Contagem */),
        accessor: 'lastCountAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 170,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE488' /* Data de Fabricação */),
        accessor: 'manufacturingDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
        Filter: this.filterColumn,
      },
    ];
  }

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getStockBalances = async () => {
    const data = await this.props.getStockBalances({
      page: this.page,
      pageSize: this.pageSize,
      sorted: this.sorted,
      filtered: this.filtered,
    });
    this.setState({ pages: data.pages, totalDataLength: data.totalDataLength });
  };

  render() {
    const { stockBalancesList = [] } = this.props;
    const { pages, totalDataLength } = this.state;
    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE33' /* Consultas */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE32' /* Estoque */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE489' /* Saldo Estoque */)}</li>
          </ol>
        </div>
        <Table
          wikiHelp={ROUTES.STOCK_BALANCE_HELP}
          downloadCSV
          headerTitle={I18n.t('BEE489' /* Saldo Estoque */)}
          panelHeaderProps={{
            onClickReload: this.getStockBalances,
          }}
          filterable
          data={stockBalancesList}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          manual
          pages={pages}
          onFetchData={(state, instance) => {
            this.page = state.page;
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;
            this.getStockBalances();
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.original
              && rowInfo.original.controlExpirationDate && rowInfo.original.expirationDate) {
              const dateForCalculation = (rowInfo.original.outboundPreExpiration)
                ? moment(rowInfo.original.expirationDate)
                  .subtract(parseInt(rowInfo.original.outboundPreExpiration, 10), 'days')
                : rowInfo.original.expirationDate;

              return {
                style: {
                  color: moment().isSameOrAfter(dateForCalculation, 'day') ? '#bf4441' : 'black',
                },
              };
            }
            return {};
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stockBalancesList: state.stocks && state.stocks.balancesList,
});

const mapDispatchToProps = (dispatch) => ({
  getStockBalances: (tableParams) => dispatch(getStockBalances(tableParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StockBalanceList));
