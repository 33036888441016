import RestService from '../services/rest';

// GET
async function getCollector(collectorId) {
  return RestService.getAuthenticated(`collectors/detail?collectorId=${collectorId}`);
}

async function getCollectors() {
  return RestService.getAuthenticated('collectors/list/all');
}

// POST
async function createCollector(collector) {
  return RestService.postAuthenticated('collectors/create', collector);
}

async function deleteCollector(collectorId) {
  return RestService.postAuthenticated('collectors/delete', { collectorId });
}

// PUT
async function updateCollector(collector) {
  return RestService.putAuthenticated('collectors/update', collector);
}

export default {
  getCollectors,
  createCollector,
  updateCollector,
  getCollector,
  deleteCollector,
};
