import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from '../../components/form/button';
import { PanelPage } from '../../components/pages/pages';
import Form from '../../components/form/form';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportProductCurveDivergences extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
                </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1963' /* KPIs */) },
      { value: I18n.t('BEE1962' /* Divergências Curva dos Produtos */), active: true },
    ];

    this.state = {
      branchCodeFrom: '',
      branchCodeTo: '',
      warehouseCodeFrom: '',
      warehouseCodeTo: '',
      depositCodeFrom: '',
      depositCodeTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      addressCodeFrom: '',
      addressCodeTo: '',
    };
  }

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      branchCodeFrom, branchCodeTo, warehouseCodeFrom, warehouseCodeTo,
      depositCodeFrom, depositCodeTo, productCodeFrom, productCodeTo,
      fullNameFrom, fullNameTo, addressCodeFrom, addressCodeTo,
    } = this.state;

    const { location } = this.props;

    const filters = returnFiltersByRules([
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['warehouseCode', { type: 'between', value: [warehouseCodeFrom, warehouseCodeTo] }],
      ['depositCode', { type: 'between', value: [depositCodeFrom, depositCodeTo] }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['$fullName', {
        type: 'between', value: [fullNameFrom, fullNameTo], instance: 'Product', as: 'product',
      }],
      ['addressCode', {
        type: 'between',
        value: [addressCodeFrom.toLocaleUpperCase(), addressCodeTo.toLocaleUpperCase()],
      }],
    ]);

    this.props.generateReport(
      I18n.t('BEE1962' /* Divergências Curva dos Produtos */).toLowerCase(),
      'xlsx',
      location.pathname,
      [{}],
      filters,
    );
  };

  onSelectFilter = (newState) => {
    this.setState({ ...newState });
  };

  render() {
    const {
      branchCodeFrom, branchCodeTo, warehouseCodeFrom, warehouseCodeTo,
      depositCodeFrom, depositCodeTo, productCodeFrom, productCodeTo,
      fullNameFrom, fullNameTo, addressCodeFrom, addressCodeTo,
    } = this.state;
    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE1962' /* Divergências Curva dos Produtos */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: warehouseCodeFrom, to: warehouseCodeTo },
                    { from: 'warehouseCodeFrom', to: 'warehouseCodeTo' },
                    `${I18n.t('BEE156' /* Armazém */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: depositCodeFrom, to: depositCodeTo },
                    { from: 'depositCodeFrom', to: 'depositCodeTo' },
                    `${I18n.t('BEE182' /* Depósito */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE225' /* Produto */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: fullNameFrom, to: fullNameTo },
                    { from: 'fullNameFrom', to: 'fullNameTo' },
                    `${I18n.t('BEE277' /* Descrição */)}:`,
                    { from: '', to: 'Z'.repeat(100) },
                  ),
                  formContext.createInputRange(
                    { from: addressCodeFrom.toLocaleUpperCase(), to: addressCodeTo.toLocaleUpperCase() },
                    { from: 'addressCodeFrom', to: 'addressCodeTo' },
                    `${I18n.t('BEE432' /* Endereço */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportProductCurveDivergences);
