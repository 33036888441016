/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';
import OneClickButton from '../../../../components/form/button';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createRange, updateRange, getRange, previewLinkAddresses, persistLinkAddresses,
  removeLinkAddress, getRangeAddress,
} from '../../../../app/store/actions/ranges';
import { getBranchesOptions } from '../../../../app/store/actions/branches';

import AddressLinkModal from './addressLinkModal';
import UserLinkModal from './userLinkModal';
import StorageAddressTable from './storageAddressTable';
import UsersRangesTable from './usersRangesTable';
import Form from '../../../../components/form/form';

class RangeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      code: '',
      branch: null,
      branchName: '',
      name: '',
      note: '',
      disableCode: true,
      disableBranch: true,
      disableName: true,
      disableNote: true,
      disableAllocateTotalBalance: true,
      disableAllocateClosedPackage: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      showAddressLinkBtn: false,
      showUserLinkBtn: false,
      showAddressLinkModal: false,
      showUserLinkModal: false,
      showUnlinkDialog: false,
      previewAddressList: [],
      selectedAddress: null,
      selectedUser: null,
      linkModalIsUnlink: false,
      activeTab: 'main',
      allocateTotalBalance: false,
      allocateClosedPackage: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create' && this.state.mode !== 'copy') {
      await this.getRangeSelected();
    }
    if (this.state.mode === 'copy') {
      await this.getSelectedCopy();
    }

    const listBranch = await this.props.getBranchesOptions();

    this.setState({
      listBranch,
    });
  }

  getRangeSelected = async () => {
    if (this.props.location.state.range) {
      const { range } = this.props.location.state;
      const rangeSelected = await this.props.getRange(range.id);
      if (rangeSelected) {
        this.setState({
          rangeSelected,
          rangeId: range.id,
          code: rangeSelected.code,
          name: rangeSelected.name,
          branch: rangeSelected.branchCode,
          branchName: rangeSelected.branchName,
          note: rangeSelected.note,
          allocateTotalBalance: rangeSelected.allocateTotalBalance,
          allocateClosedPackage: rangeSelected.allocateClosedPackage,
        });
      }
    }
  };

  getSelectedCopy = async () => {
    if (this.props.location.state.range) {
      const { range } = this.props.location.state;
      this.setState({
        rangeId: range.id,
        code: range.code,
        name: range.name,
        branch: range.branchCode,
        branchName: range.branchName,
        note: range.note,
        allocateTotalBalance: range.allocateTotalBalance,
        allocateClosedPackage: range.allocateClosedPackage,
      });
    }
  };

  setMode = () => {
    let mode;

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableBranch: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
        showAddressLinkBtn: false,
        showUserLinkBtn: false,
        disableAllocateTotalBalance: false,
        disableAllocateClosedPackage: false,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableBranch: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
        showAddressLinkBtn: true,
        showUserLinkBtn: true,
        disableAllocateTotalBalance: false,
        disableAllocateClosedPackage: false,
      });
    }
  };

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  createRangeSubmit = async () => {
    const {
      code, name, branch, note, allocateTotalBalance, allocateClosedPackage,
    } = this.state;

    if (code.length > 10) {
      addNotification(
        'danger',
        I18n.t('BEE581' /* Range */),
        I18n.t('BEE2321' /* O código do range só pode conter 10 caracteres */),
        'top-right',
      );
    } else {
      try {
        const newRange = await this.props.createRange({
          code,
          name,
          branchCode: branch,
          note,
          allocateTotalBalance,
          allocateClosedPackage,
        });

        if ((newRange && newRange.success === false) || !newRange) {
          addNotification(
            'danger',
            I18n.t('BEE581' /* Range */),
            I18n.t('BEE587', { 0: code } /* Erro ao incluir a Range ${0}! */),
            'top-right',
          );
        } else {
          addNotification(
            'success',
            I18n.t('BEE581' /* Range */),
            I18n.t('BEE586', { 0: code } /* Range ${0} criada com sucesso! */),
            'top-right',
          );
          this.props.history.push(ROUTES.RANGE_LIST);
        }
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && (error.message || error.details)) {
          addNotification(
            'danger',
            I18n.t('BEE581' /* Range */),
            (!error.message ? error.details : error.message),
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE581' /* Range */),
            I18n.t('BEE587', { 0: code } /* Erro ao incluir a Range ${0}! */),
            'top-right',
          );
        }
      }
    }
  };

  updateRangeSubmit = async () => {
    const {
      rangeId, code, name, branch, note, allocateTotalBalance, allocateClosedPackage,
    } = this.state;

    try {
      const updRange = await this.props.updateRange({
        rangeId,
        code,
        name,
        branchCode: branch,
        note,
        allocateTotalBalance,
        allocateClosedPackage,
      });

      if (updRange) {
        addNotification(
          'success',
          I18n.t('BEE588' /* Atualizar Range */),
          I18n.t('BEE589', { 0: code } /* Range ${0} atualizada com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.RANGE_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE588' /* Atualizar Range */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE588' /* Atualizar Range */),
            I18n.t('BEE590', { 0: code } /* Erro atualizando a Range ${0}! */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE588' /* Atualizar Range */),
          I18n.t('BEE590', { 0: code } /* Erro atualizando a Range ${0}! */),
          'top-right',
        );
      }
    }
  };

  openAddressLinkModal = () => {
    this.setState({
      showAddressLinkModal: true,
      linkModalIsUnlink: false,
    });
  };

  openAddressUnlinkModal = () => {
    this.setState({
      showAddressLinkModal: true,
      linkModalIsUnlink: true,
    });
  };

  closeAddressLinkModal = () => {
    this.setState({
      showAddressLinkModal: false,
      previewAddressList: [],
      linkModalIsUnlink: false,
    });
  };

  clearPreviewList = () => {
    this.setState({
      previewAddressList: [],
    });
  };

  openUserLinkModal = () => {
    this.setState({
      showUserLinkModal: true,
    });
  };

  closeUserLinkModal = async () => {
    this.setState({
      showUserLinkModal: false,
    });
    await this.getRangeSelected();
  };

  buildFilters = (filterParams) => {
    const {
      fromSector, fromStreet, fromColumn, fromLevel, fromDrawer,
      toSector, toStreet, toColumn, toLevel, toDrawer,
    } = filterParams;

    const { linkModalIsUnlink } = this.state;

    const sector = fromSector || toSector !== 'Z'.repeat(15)
      ? { type: 'between', value: [fromSector, toSector] }
      : null;

    const street = fromStreet || toStreet !== 'Z'.repeat(15)
      ? { type: 'between', value: [fromStreet, toStreet] }
      : null;

    const column = fromColumn || toColumn !== 'Z'.repeat(15)
      ? { type: 'between', value: [fromColumn, toColumn] }
      : null;

    const level = fromLevel || toLevel !== 'Z'.repeat(15)
      ? { type: 'between', value: [fromLevel, toLevel] }
      : null;

    const drawer = fromDrawer || toDrawer !== 'Z'.repeat(15)
      ? { type: 'between', value: [fromDrawer, toDrawer] }
      : null;

    const branchCode = { type: 'eq', value: this.props.location.state.range.branchCode };

    const rangeCode = linkModalIsUnlink
      ? { type: 'eq', value: this.props.location.state.range.code }
      : { type: 'is', value: null };

    return {
      ...(sector && { sector }),
      ...(street && { street }),
      ...(column && { column }),
      ...(level && { level }),
      ...(drawer && { drawer }),
      ...({ branchCode }),
      ...({ rangeCode }),
    };
  };

  previewAddressLink = async (filterParams) => {
    if (this.props.location.state.range) {
      const { range } = this.props.location.state;

      const filters = this.buildFilters(filterParams);

      filters.side = filterParams.columnSide;

      try {
        const addressesList = await this.props.previewLinkAddresses(filters);

        if (addressesList.length) {
          this.setState({
            previewAddressList: addressesList,
          });
          if (addressesList.length > 200) {
            addNotification(
              'danger',
              I18n.t('BEE588' /* Atualizar Range */),
              I18n.t('BEE718', { 0: addressesList.length }),
              /*
              Seus filtros geraram %{0} resultados! Apenas os 200 primeiros serão gravados se a operação for confirmada.
              */
              'top-right',
            );
          }
        } else {
          addNotification(
            'danger',
            I18n.t('BEE588' /* Atualizar Range */),
            I18n.t('BEE604' /* Nenhum endereço localizado com os filtros informados! */),
            'top-right',
          );
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;

          if (error.details || error.message) {
            addNotification(
              'danger',
              I18n.t('BEE588' /* Atualizar Range */),
              `${error.code} - ${error.details || error.message}`,
              'top-right',
            );
          } else {
            addNotification(
              'danger',
              I18n.t('BEE588' /* Atualizar Range */),
              I18n.t('BEE590', { 0: range.name } /* Erro atualizando a Range ${0}! */),
              'top-right',
            );
          }
        } else {
          addNotification(
            'danger',
            I18n.t('BEE588' /* Atualizar Range */),
            I18n.t('BEE590', { 0: range.name } /* Erro atualizando a Range ${0}! */),
            'top-right',
          );
        }
      }
    }
  };

  persistAddressLink = async (filterParams) => {
    if (this.props.location.state.range) {
      const unlink = this.state.linkModalIsUnlink;
      const { range } = this.props.location.state;

      const rangeCode = unlink ? null : range.code;

      const filters = this.buildFilters(filterParams);

      try {
        const updRange = await this.props.persistLinkAddresses(rangeCode, filters);
        if (updRange) {
          await this.updateRangeAddress();
          this.setState({
            showAddressLinkModal: false,
            previewAddressList: [],
            linkModalIsUnlink: false,
          });
          addNotification(
            'success',
            I18n.t('BEE588' /* Atualizar Range */),
            I18n.t('BEE589', { 0: range.name } /* Range ${0} atualizada com sucesso! */),
            'top-right',
          );
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;

          if (error.details || error.message) {
            addNotification(
              'danger',
              I18n.t('BEE588' /* Atualizar Range */),
              `${error.code} - ${error.details || error.message}`,
              'top-right',
            );
          } else {
            addNotification(
              'danger',
              I18n.t('BEE588' /* Atualizar Range */),
              I18n.t('BEE590', { 0: range.name } /* Erro atualizando a Range ${0}! */),
              'top-right',
            );
          }
        } else {
          addNotification(
            'danger',
            I18n.t('BEE588' /* Atualizar Range */),
            I18n.t('BEE590', { 0: range.name } /* Erro atualizando a Range ${0}! */),
            'top-right',
          );
        }
      }
    }
  };

  selectAddress = (address) => {
    this.setState({
      selectedAddress: address,
      showUnlinkDialog: true,
    });
  };

  deselectAddress = () => {
    this.setState({
      selectedAddress: null,
      showUnlinkDialog: false,
    });
  };

  removeLinkAddress = async (storageAddressId) => {
    this.deselectAddress();

    if (this.props.location.state.range) {
      const { range } = this.props.location.state;
      try {
        const updRange = await this.props.removeLinkAddress(storageAddressId);
        if (updRange) {
          await this.updateRangeAddress();
          addNotification(
            'success',
            I18n.t('BEE588' /* Atualizar Range */),
            I18n.t('BEE589', { 0: range.name } /* Range ${0} atualizada com sucesso! */),
            'top-right',
          );
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;

          if (error.details || error.message) {
            addNotification(
              'danger',
              I18n.t('BEE588' /* Atualizar Range */),
              `${error.code} - ${error.details || error.message}`,
              'top-right',
            );
          } else {
            addNotification(
              'danger',
              I18n.t('BEE588' /* Atualizar Range */),
              I18n.t('BEE590', { 0: range.name } /* Erro atualizando a Range ${0}! */),
              'top-right',
            );
          }
        } else {
          addNotification(
            'danger',
            I18n.t('BEE588' /* Atualizar Range */),
            I18n.t('BEE590', { 0: range.name } /* Erro atualizando a Range ${0}! */),
            'top-right',
          );
        }
      }
    }
  };

  updateRangeAddress = async (tableParams = '') => {
    if (tableParams) {
      await this.props.getRangeAddress(tableParams);
      this.setState({
        tableParams,
      });
    } else if (this.state.tableParams) {
      await this.props.getRangeAddress(this.state.tableParams);
    }
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      code, name, branch, branchName, note, listBranch, rangeSelected,
      linkModalIsUnlink, showUnlinkDialog, selectedAddress, selectedUser,
      showUnlinkUserDialog, allocateTotalBalance, allocateClosedPackage,
    } = this.state;

    const {
      mode, disableCode, disableName, disableBranch,
      disableNote, showCreate, showUpdate, showCancel,
      showAddressLinkBtn, showAddressLinkModal, previewAddressList,
      showUserLinkBtn, showUserLinkModal, disableAllocateTotalBalance, disableAllocateClosedPackage,
    } = this.state;

    // const storageAddresses = rangeSelected ? rangeSelected.storageAddress : []
    const usersRanges = rangeSelected ? rangeSelected.usersRanges : [];

    let title = '';
    //  let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE591' /* Nova Range */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE592' /* Editar Range */);
      //    breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE593' /* Detalhar Range */);
      //   breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE581' /* Ranges */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form onSubmit={(e) => e.preventDefault()}>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  <div className="row">
                    <div className="col mb-3">
                      <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === 'main' ? 'active' : ''}
                            onClick={() => {
                              this.toggleTab('main');
                            }}
                          >
                            {I18n.t('BEE191' /* Geral */)}
                          </NavLink>
                        </NavItem>
                        {(this.state.mode !== 'create' && this.state.mode !== 'copy') ? (
                          <>
                            <NavItem>
                              <NavLink
                                className={this.state.activeTab === 'address' ? 'active' : ''}
                                onClick={() => {
                                  this.toggleTab('address');
                                }}
                              >
                                {I18n.t('BEE20' /* Endereços */)}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={this.state.activeTab === 'user' ? 'active' : ''}
                                onClick={() => {
                                  this.toggleTab('user');
                                }}
                              >
                                {I18n.t('BEE25' /* Usuários */)}
                              </NavLink>
                            </NavItem>
                          </>
                        )
                          : ''}
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === 'allocation' ? 'active' : ''}
                            onClick={() => {
                              this.toggleTab('allocation');
                            }}
                          >
                            {I18n.t('BEE3914' /* Regras de Alocação */)}
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="main">
                          <Form
                            noPanel
                            setValue={this.setValue}
                            setValueDrop={this.setValueDrop}
                            inputs={(formContext) => ([
                              formContext.createInput(
                                code,
                                'code',
                                `${I18n.t('BEE82' /* Código */)}:`,
                                I18n.t('BEE3692' /* Informe o armazém */),
                                'text',
                                true,
                                disableCode,
                              ),
                              formContext.createInput(
                                name,
                                'name',
                                `${I18n.t('BEE83' /* Nome */)}:`,
                                I18n.t('BEE3693' /* Informe o nome do armazém */),
                                'text',
                                true,
                                disableName,
                              ),
                              disableBranch
                                ? formContext.createInput(
                                  branchName,
                                  'branch',
                                  `${I18n.t('BEE145' /* Filial */)}:`,
                                  '',
                                  'text',
                                  true,
                                  disableBranch,
                                )
                                : formContext.createSelectDropDown(
                                  { value: branch, label: branchName },
                                  'branch',
                                  `${I18n.t('BEE145' /* Filial */)}:`,
                                  listBranch,
                                  disableBranch,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  true,
                                ),
                              formContext.createInput(
                                note,
                                'note',
                                `${I18n.t('BEE135' /* Observação */)}:`,
                                I18n.t('BEE136' /* Informe a observação */),
                                'text',
                                false,
                                disableNote,
                              ),
                            ])}
                          />
                        </TabPane>
                        {(this.state.mode !== 'create' && this.state.mode !== 'copy')
                          ? (
                            <>
                              <TabPane tabId="address">
                                {
                                  (this.state.code) ? (
                                    <StorageAddressTable
                                      title={I18n.t('BEE603' /* Endereços Vinculados */)}
                                      rangeCode={code}
                                      getRangeAddress={(tableParams) => this.updateRangeAddress(tableParams)}
                                      rangeAddressList={this.props.rangeAddressList}
                                      pages={this.props.pages}
                                      executeFunction
                                      showAddressLinkBtn={showAddressLinkBtn}
                                      openAddressLinkModal={this.openAddressLinkModal}
                                      openAddressUnlinkModal={this.openAddressUnlinkModal}
                                      selectAddress={this.selectAddress}
                                    />
                                  ) : ''
                                }
                              </TabPane>
                              <TabPane tabId="user">
                                <UsersRangesTable
                                  title={I18n.t('BEE619' /* Usuários Vinculados */)}
                                  usersRanges={usersRanges}
                                  showUserLinkBtn={showUserLinkBtn}
                                  openUserLinkModal={this.openUserLinkModal}
                                  rangeSelected={rangeSelected}
                                  getRangeSelected={this.getRangeSelected}
                                />
                              </TabPane>
                            </>
                          )
                          : ''}

                        <TabPane tabId="allocation">
                          <div className="d-flex justify-content-center">
                            <Form
                              noPanel
                              setValue={this.setValue}
                              inputs={(formContext) => ([
                                formContext.createCheckBoxLabel(
                                  allocateTotalBalance,
                                  'allocateTotalBalance',
                                  I18n.t('BEE3912' /* Alocação do Saldo Total do Endereço */),
                                  disableAllocateTotalBalance || allocateClosedPackage,
                                ),
                                formContext.createCheckBoxLabel(
                                  allocateClosedPackage,
                                  'allocateClosedPackage',
                                  I18n.t('BEE3913' /* Alocação Somente em Embalagem Fechada */),
                                  disableAllocateClosedPackage || allocateTotalBalance,
                                ),
                              ])}
                            />
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.RANGE_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                      ) : (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE137' /* Voltar */)}
                        </OneClickButton>
                      ))}
                  </Link>
                  {(showCreate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.createRangeSubmit()}
                      >
                        {I18n.t('BEE138' /* Criar */)}
                      </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.updateRangeSubmit()}
                      >
                        {I18n.t('BEE139' /* Atualizar */)}
                      </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>

            <AddressLinkModal
              showAddressLinkModal={showAddressLinkModal}
              closeAddressLinkModal={this.closeAddressLinkModal}
              clearPreviewList={this.clearPreviewList}
              previewAddressLink={this.previewAddressLink}
              persistAddressLink={this.persistAddressLink}
              previewAddressList={previewAddressList.length ? previewAddressList : null}
              isUnlink={linkModalIsUnlink}
            />

            <UserLinkModal
              showUserLinkModal={showUserLinkModal}
              closeUserLinkModal={this.closeUserLinkModal}
              rangeSelected={rangeSelected}
            />

          </div>
        </div>

        {
          (showUnlinkDialog
            && (
              <SweetAlert
                danger
                showCancel
                cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
                confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={I18n.t('BEE101' /* Você tem certeza? */)}
                onConfirm={() => this.removeLinkAddress(selectedAddress.id)}
                onCancel={() => this.deselectAddress()}
              >
                {I18n.t('BEE614', { 0: selectedAddress.code } /* O endereço %{0} será desvinculado da Range! */)}
              </SweetAlert>
            )
          )
        }

        {
          (showUnlinkUserDialog
            && (
              <SweetAlert
                danger
                showCancel
                cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
                confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={I18n.t('BEE101' /* Você tem certeza? */)}
                onConfirm={() => this.removeLinkUser(selectedUser.id)}
                onCancel={() => this.deselectUser()}
              >
                {I18n.t('BEE621', { 0: selectedUser.name } /* O usuário %{0} será desvinculado! */)}
              </SweetAlert>
            )
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rangeAddressList: state.ranges && state.ranges.rangeAddressList && state.ranges.rangeAddressList.rows,
  pages: state.ranges && state.ranges.rangeAddressList && state.ranges.rangeAddressList.pages,
});

const mapDispatchToProps = (dispatch) => ({
  getRange: (rangeId) => dispatch(getRange(rangeId)),
  createRange: (range) => dispatch(createRange(range)),
  updateRange: (range) => dispatch(updateRange(range)),
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  previewLinkAddresses: (filters) => dispatch(previewLinkAddresses(filters)),
  persistLinkAddresses: (rangeCode, filters) => dispatch(persistLinkAddresses(rangeCode, filters)),
  removeLinkAddress: (storageAddressId) => dispatch(removeLinkAddress(storageAddressId)),
  getRangeAddress: (tableParams) => dispatch(getRangeAddress(tableParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RangeForm));
