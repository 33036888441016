import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import addNotification from '../../components/notification';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportMovementExpeditionOutboundOrdersItemsList extends React.PureComponent {
  constructor() {
    super();

    this.options = [
      {
        label: I18n.t('BEE145' /* Filial */),
        value: 'branchCode',
      }, {
        label: I18n.t('BEE2409' /* Data / Hora Integração */),
        value: 'documentCreatedAt',
      }, {
        label: I18n.t('BEE443' /* Documento */),
        value: 'orderNumber',
      }, {
        label: I18n.t('BEE1096' /* Pedido Cliente */),
        value: 'customerOrderCode',
      }, {
        label: I18n.t('BEE402' /* Nota Fiscal */),
        value: 'invoiceNumber',
      }, {
        label: I18n.t('BEE1759' /* Status do Documento */),
        value: 'documentStatus',
      }, {
        label: I18n.t('BEE381' /* Código do Cliente */),
        value: 'customerCode',
      }, {
        label: I18n.t('BEE84' /* Razão Social */),
        value: 'shipToCustomerName',
      }, {
        label: I18n.t('BEE463' /* Linha */),
        value: 'lineNumber',
      }, {
        label: I18n.t('BEE378' /* Código do Produto */),
        value: 'productCode',
      }, {
        label: I18n.t('BEE758' /* Descrição do Produto */),
        value: 'productName',
      }, {
        label: I18n.t('BEE1756' /* Unidade de Medida */),
        value: 'unitMeasure',
      }, {
        label: I18n.t('BEE1579' /* Lance Obrigatório */),
        value: 'mandatoryQuantity',
      }, {
        label: I18n.t('BEE441' /* Quantidade */),
        value: 'quantity',
      }, {
        label: I18n.t('BEE1431' /* Alocado */),
        value: 'allocatedQuantity',
      }, {
        label: I18n.t('BEE1115' /* Valor */),
        value: 'value',
        numFmt: '"R$"#,##0.00;[Red]\\-"R$"#,##0.00',
      }, {
        label: I18n.t('BEE3922' /* Valor da Mercadoria */),
        value: 'orderValue',
        numFmt: '"R$"#,##0.00;[Red]\\-"R$"#,##0.00',
      }, {
        label: I18n.t('BEE1368' /* Separado */),
        value: 'pickedQuantity',
      }, {
        label: I18n.t('BEE445' /* Conferido */),
        value: 'checkedQuantity',
      }, {
        label: I18n.t('BEE2393' /* Status do Produto */),
        value: 'status',
      }, {
        label: I18n.t('BEE3645' /* Data Alocação */),
        value: 'formattedAllocationAt',
      }, {
        label: I18n.t('BEE3646' /* Usuário Alocação */),
        value: 'allocationUser',
      }, {
        label: I18n.t('BEE3647' /* Data/Hora - Atribuição */),
        value: 'attributionAt',
      }, {
        label: I18n.t('BEE3648' /* Usuário Atribuiu */),
        value: 'attributionUser',
      }, {
        label: I18n.t('BEE2410' /* Usuário Corte de Cabos */),
        value: 'finishCableCutUser',
      }, {
        label: I18n.t('BEE2397' /* Data / Hora Corte de Cabos */),
        value: 'finishCableCutAt',
      }, {
        label: I18n.t('BEE1410' /* Usuário Separação */),
        value: 'pickedUser',
      }, {
        label: I18n.t('BEE2394' /* Data / Hora Separação */),
        value: 'formattedPickedAt',
      }, {
        label: I18n.t('BEE3652' /* Tempo Pend. Separação */),
        value: 'pendingSeparationTime',
      }, {
        label: I18n.t('BEE1422' /* Usuário Conferência */),
        value: 'checkedUser',
      }, {
        label: I18n.t('BEE2395' /* Data / Hora Conferência */),
        value: 'formattedCheckedAt',
      }, {
        label: I18n.t('BEE2091' /* Usuário Consolidação */),
        value: 'consolidationUser',
      }, {
        label: I18n.t('BEE2396' /* Data / Hora Consolidação */),
        value: 'consolidationAt',
      },
      {
        label: I18n.t('BEE3649' /* Horas Totais */),
        value: 'totalHours',
      },
    ];

    this.state = {
      branchCodeFrom: '',
      branchCodeTo: '',
      createdAtDateFrom: '',
      createdAtDateTo: '',
      orderNumberFrom: '',
      orderNumberTo: '',
      customerCodeFrom: '',
      customerCodeTo: '',
      customerOrderCodeFrom: '',
      customerOrderCodeTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      pendingAllocation: true,
      pendingCableCut: true,
      pendingPick: true,
      onPick: true,
      pendingCheck: true,
      pendingDock: true,
      pendingWaybill: true,
      pendingConsolidation: true,
      waitingBilling: true,
      finished: true,
      divergence: true,
      canceled: true,
      refund: true,
      checkAllCheckBoxes: true,
    };
  }

  checkOrUncheckAllBoxes = (value) => {
    this.setState({
      pendingAllocation: value,
      pendingCableCut: value,
      pendingPick: value,
      onPick: value,
      pendingCheck: value,
      pendingDock: value,
      pendingWaybill: value,
      pendingConsolidation: value,
      waitingBilling: value,
      finished: value,
      divergence: value,
      canceled: value,
      refund: value,
      checkAllCheckBoxes: value,
    });
  };

  handleSelectAllCheckBox = () => {
    if (this.state.pendingAllocation
    && this.state.pendingCableCut
    && this.state.pendingPick
    && this.state.onPick
    && this.state.pendingCheck
    && this.state.pendingDock
    && this.state.pendingWaybill
    && this.state.pendingConsolidation
    && this.state.waitingBilling
    && this.state.finished
    && this.state.divergence
    && this.state.canceled
    && this.state.refund) {
      this.setState({
        checkAllCheckBoxes: true,
      });
    } else {
      this.setState({
        checkAllCheckBoxes: false,
      });
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });

    if (attr === 'checkAllCheckBoxes') {
      this.checkOrUncheckAllBoxes(value);
    }

    this.handleSelectAllCheckBox();
  };

  onGenerateSpreadsheet = () => {
    const {
      orderNumberFrom,
      orderNumberTo,
      branchCodeFrom,
      branchCodeTo,
      customerCodeFrom,
      customerCodeTo,
      customerOrderCodeFrom,
      customerOrderCodeTo,
      productCodeFrom,
      productCodeTo,
      createdAtDateFrom,
      createdAtDateTo,
      pendingAllocation,
      pendingCableCut,
      pendingPick,
      onPick,
      pendingCheck,
      pendingDock,
      pendingWaybill,
      pendingConsolidation,
      waitingBilling,
      finished,
      divergence,
      canceled,
      refund,
    } = this.state;

    const statusDocument = [];
    const { location } = this.props;
    const headers = this.options;

    if (pendingAllocation) statusDocument.push('1');
    if (pendingCableCut) statusDocument.push('2');
    if (pendingPick) statusDocument.push('3');
    if (onPick) statusDocument.push('4');
    if (pendingCheck) statusDocument.push('5');
    if (pendingDock) statusDocument.push('6');
    if (pendingWaybill) statusDocument.push('7');
    if (pendingConsolidation) statusDocument.push('8');
    if (waitingBilling) statusDocument.push('9');
    if (finished) statusDocument.push('10');
    if (divergence) statusDocument.push('11');
    if (canceled) statusDocument.push('12');
    if (refund) statusDocument.push('13');

    const filters = returnFiltersByRules([
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['orderNumber', { type: 'between', value: [orderNumberFrom, orderNumberTo] }],
      ['customerCode', { type: 'between', value: [customerCodeFrom, customerCodeTo] }],
      ['customerOrderCode', { type: 'between', value: [customerOrderCodeFrom, customerOrderCodeTo] }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['createdAt', { type: 'between', value: [createdAtDateFrom, createdAtDateTo] }],
      ['status', { type: 'in', value: statusDocument }],
    ]);

    if (!filters.status) {
      addNotification(
        'danger',
        I18n.t('BEE1759' /* Status do Documento */),
        I18n.t('BEE1198' /* Status deve ser informado! */),
        'top-right',
      );
      return;
    }

    if (Object.keys(filters).length > 1) {
      this.props.generateReport(
        I18n.t('BEE2378' /* Itens de Documentos de Saída */).toLowerCase(),
        'xlsx',
        location.pathname,
        headers,
        filters,
      );
      return;
    }

    addNotification(
      'danger',
      I18n.t('BEE2386' /* Verifique os Campos */),
      I18n.t('BEE2387' /* Nenhum campo foi preenchido */),
      'top-right',
    );
  };

  onSelectFilter = (newState) => {
    this.setState({ ...newState });
  };

  render() {
    const {
      orderNumberFrom,
      orderNumberTo,
      branchCodeFrom,
      branchCodeTo,
      customerCodeFrom,
      customerCodeTo,
      customerOrderCodeFrom,
      customerOrderCodeTo,
      productCodeFrom,
      productCodeTo,
      createdAtDateFrom,
      createdAtDateTo,
      pendingAllocation,
      pendingCableCut,
      pendingPick,
      onPick,
      pendingCheck,
      pendingDock,
      pendingWaybill,
      pendingConsolidation,
      waitingBilling,
      finished,
      divergence,
      canceled,
      refund,
      checkAllCheckBoxes,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={I18n.t('BEE2379' /* Relatório - Itens de Documentos de Saída */)}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ), formContext.createInputRange(
                    { from: orderNumberFrom, to: orderNumberTo },
                    { from: 'orderNumberFrom', to: 'orderNumberTo' },
                    `${I18n.t('BEE443' /* Documento */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createDateInputRange(
                    { from: createdAtDateFrom, to: createdAtDateTo },
                    { from: 'createdAtDateFrom', to: 'createdAtDateTo' },
                    `${I18n.t('BEE2380' /* Data do Documento */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: customerCodeFrom, to: customerCodeTo },
                    { from: 'customerCodeFrom', to: 'customerCodeTo' },
                    `${I18n.t('BEE381' /* Código do Cliente */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: customerOrderCodeFrom, to: customerOrderCodeTo },
                    { from: 'customerOrderCodeFrom', to: 'customerOrderCodeTo' },
                    `${I18n.t('BEE1808' /* Pedido Cliente */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE225' /* Produto */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createCheckBoxes([
                    {
                      attr: 'checkAllCheckBoxes',
                      value: checkAllCheckBoxes,
                      label: I18n.t('BEE2385' /* Marcar ou Desmarcar todos */),
                    },
                    {
                      attr: 'pendingAllocation',
                      value: pendingAllocation,
                      label: I18n.t('BEE1946' /* Pendente Alocação */),
                    },
                    {
                      attr: 'pendingCableCut',
                      value: pendingCableCut,
                      label: I18n.t('BEE1947' /* Pendente Corte de Cabos */),
                    },
                    { attr: 'pendingPick', value: pendingPick, label: I18n.t('BEE1365' /* Pendente Separação */) },
                    { attr: 'onPick', value: onPick, label: I18n.t('BEE1367' /* Em Separação */) },
                    { attr: 'pendingCheck', value: pendingCheck, label: I18n.t('BEE1948' /* Pendente Conferência */) },
                    { attr: 'pendingDock', value: pendingDock, label: I18n.t('BEE1949' /* Pendente Docas */) },
                    { attr: 'pendingWaybill', value: pendingWaybill, label: I18n.t('BEE1773' /* Pendente Romaneio */) },
                    {
                      attr: 'pendingConsolidation',
                      value: pendingConsolidation,
                      label: I18n.t('BEE1769' /* Pendente Consolidação */),
                    },
                    {
                      attr: 'waitingBilling',
                      value: waitingBilling,
                      label: I18n.t('BEE2529' /* Pendente Faturamento */),
                    },
                    { attr: 'finished', value: finished, label: I18n.t('BEE1833' /* Finalizado */) },
                    { attr: 'divergence', value: divergence, label: I18n.t('BEE176' /* Divergência */) },
                    { attr: 'canceled', value: canceled, label: I18n.t('BEE64' /* Cancelado */) },
                    { attr: 'refund', value: refund, label: I18n.t('BEE1899' /* Devolvido */) },
                  ], `${I18n.t('BEE1759' /* Status do Documento */)}:`),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
      )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
      )}
      />
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport:
    (nameDoc, type, route, columns, filters) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMovementExpeditionOutboundOrdersItemsList);
