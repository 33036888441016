import OutboundPendingAllocationMonitorRequests from '../../server/outboundPendingAllocationMonitor';
import { addLoading, removeLoading } from './loading';

export const getDataGrid = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const pendingCut = await OutboundPendingAllocationMonitorRequests.getDataGrid(tableParams);
    return pendingCut;
  } finally {
    dispatch(removeLoading());
  }
};

export const allocateProducts = (outboundOrderId, outboundProductId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const result = await OutboundPendingAllocationMonitorRequests.allocateProducts(outboundOrderId, outboundProductId);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};
