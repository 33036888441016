import InboundOrdersRequests from '../../server/inboundOrders';
import { addLoading, removeLoading } from './loading';

export const setInboundOrders = (inboundOrders) => ({
  type: 'SET_INBOUND_ORDERS',
  inboundOrders,
});

export const setInboundOrdersPanel = (inboundOrders) => ({
  type: 'SET_INBOUND_ORDERS_PANEL',
  inboundOrders,
});

export const setCardsPanel = (cardsPanel) => ({
  type: 'SET_INBOUND_CARDS_PANEL',
  cardsPanel,
});

export const setChartsPanel = (chartsPanel) => ({
  type: 'SET_INBOUND_CHARTS_PANEL',
  chartsPanel,
});

export const setDates = (dates) => ({
  type: 'SET_INBOUND_ORDERS_DATES',
  dates,
});

export const setTotal = (totalInboundOrderProduct) => ({
  type: 'SET_INBOUND_ORDERS_TOTAL',
  totalInboundOrderProduct,
});

export const setTotalByStatus = (totalByStatusInboundOrdersProducts) => ({
  type: 'SET_INBOUND_ORDERS_TOTAL_BY_STATUS',
  totalByStatusInboundOrdersProducts,
});

export const setAverageTimeCards = (averageTimeCards) => ({
  type: 'SET_INBOUND_ORDERS_AVERAGE_TIME_CARDS',
  averageTimeCards,
});

export const getInboundOrdersList = (startDate, endDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const inboundOrders = await InboundOrdersRequests.getAllInboundOrders(startDate, endDate);
    dispatch(setInboundOrders(inboundOrders));
  } finally {
    dispatch(removeLoading());
  }
};

export const getInboundOrder = (inboundOrderId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.getInboundOrder(inboundOrderId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getInboundOrdersCheckPlate = (licensePlate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.getInboundOrdersCheckPlate(licensePlate);
  } finally {
    dispatch(removeLoading());
  }
};

export const confirmCheckPlateOrder = (inboundOrderId, volumesChecked) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.confirmCheckPlate(inboundOrderId, volumesChecked);
  } finally {
    dispatch(removeLoading());
  }
};

export const getInboundOrdersPanel = (startDate, endDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const result = await InboundOrdersRequests.getInboundOrdersPanel(startDate, endDate);
    dispatch(setInboundOrdersPanel(result.orders));
    dispatch(setCardsPanel(result.cards));
    dispatch(setChartsPanel(result.chartInfo));
  } finally {
    dispatch(removeLoading());
  }
};

export const getListInboundOrdersOnly = (startDate, endDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const listInboundOrders = await InboundOrdersRequests.getListInboundOrdersOnly(startDate, endDate);
    dispatch(setInboundOrders(listInboundOrders));
  } finally {
    dispatch(removeLoading());
  }
};

export const getListInboundOrderProducts = (inboundOrderId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.getListInboundOrderProducts(inboundOrderId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getListInboundOrderProductLots = (inboundOrderProductId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.getListInboundOrderProductLots(inboundOrderProductId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getListInboundOrderProductPartial = (inboundOrderProductId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.getListInboundOrderProductPartial(inboundOrderProductId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getBaptismLabelProducts = (baptismLabel) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.getBaptismLabelProducts(baptismLabel);
  } finally {
    dispatch(removeLoading());
  }
};

export const undoInboundOrderCheck = (inboundOrderId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.undoInboundOrderCheck(inboundOrderId);
  } finally {
    dispatch(removeLoading());
  }
};

export const undoInboundOrderProductCheck = (inboundOrderProductId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.undoInboundOrderProductCheck(inboundOrderProductId);
  } finally {
    dispatch(removeLoading());
  }
};

export const assignCheckUser = (inboundOrderId, userId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.assignCheckUser(inboundOrderId, userId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getBranchUsers = (branchCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.getBranchUsers(branchCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateNote = (inboundOrderProductId, note) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.updateNote(inboundOrderProductId, note);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateOrderNote = (inboundOrderId, note) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.updateOrderNote(inboundOrderId, note);
  } finally {
    dispatch(removeLoading());
  }
};

export const cancelOrder = (inboundOrderId, cancelNote) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const result = await InboundOrdersRequests.cancelOrder(inboundOrderId, cancelNote);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const listingByDateInboundOrderProducts = (filterStartDate, filterEndDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundOrdersRequests.listingByDateInboundOrderProducts(filterStartDate, filterEndDate);
  } finally {
    dispatch(removeLoading());
  }
};

export const setDashboardInboundOrdersDates = (dates) => async (dispatch) => {
  dispatch(setDates(dates));
};

export const setSelectedBranches = (selectedBranches) => ({
  type: 'SET_INBOUND_ORDERS_SELECTED_BRANCHES',
  selectedBranches,
});

export const setUserBranches = (userBranches) => ({
  type: 'SET_INBOUND_ORDERS_USER_BRANCHES',
  userBranches,
});

export const getInboundOrdersDashboardInfo = (
  startDate,
  endDate,
  getCharts,
  selectedBranches,
  currentRange,
  orderTypesToSearch,
  suppliersToSearch,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const inboundOrdersDashboardInfo = await InboundOrdersRequests.getInboundOrdersDashboardInfo(
      startDate,
      endDate,
      getCharts,
      selectedBranches,
      currentRange,
      orderTypesToSearch,
      suppliersToSearch,
    );
    dispatch(setInboundOrders(inboundOrdersDashboardInfo.inboundOrders));
    dispatch(setUserBranches(inboundOrdersDashboardInfo.userBranches));
    dispatch(setChartsPanel(inboundOrdersDashboardInfo.charts));
  } finally {
    dispatch(removeLoading());
  }
};

export const getInboundOrdersAverageTimes = (
  startDate,
  endDate,
  selectedBranches,
  orderTypesToSearch,
  suppliersToSearch,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const inboundOrdersDashboard = await InboundOrdersRequests.getInboundOrdersAverageTimes(
      startDate,
      endDate,
      selectedBranches,
      orderTypesToSearch,
      suppliersToSearch,
    );
    dispatch(setInboundOrders(inboundOrdersDashboard.inboundOrders));
    dispatch(setAverageTimeCards(inboundOrdersDashboard.averageTimeCards));
    dispatch(setUserBranches(inboundOrdersDashboard.userBranches));
    dispatch(setChartsPanel(inboundOrdersDashboard.charts));
  } finally {
    dispatch(removeLoading());
  }
};

export const getInboundOrdersDashboardTotal = (
  startDate,
  endDate,
  selectedBranches,
  orderTypesToSearch,
  suppliersToSearch,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const inboundOrdersDashboardTotal = await InboundOrdersRequests.getInboundOrdersDashboardTotal(
      startDate,
      endDate,
      selectedBranches,
      orderTypesToSearch,
      suppliersToSearch,
    );

    dispatch(setInboundOrders(inboundOrdersDashboardTotal.inboundOrders));
    dispatch(setUserBranches(inboundOrdersDashboardTotal.userBranches));
    dispatch(setTotal(inboundOrdersDashboardTotal.totalInboundOrderProduct));
    dispatch(setTotalByStatus(inboundOrdersDashboardTotal.totalByStatusInboundOrdersProducts));
  } finally {
    dispatch(removeLoading());
  }
};

export const setSelectedUserBranches = (selectedBranches) => async (dispatch) => {
  dispatch(setSelectedBranches(selectedBranches));
};
