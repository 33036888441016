import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';

import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import { PanelPage } from '../../../components/pages/pages';

import ROUTES from '../../../config/routes';

import { getAllCurves } from '../../../app/store/actions/curves';
import { getBranchOptions } from '../../../app/store/actions/customerBranch';
import { getDepositsOptionsList } from '../../../app/store/actions/deposits';
import { getMaterialsFamilyOptions } from '../../../app/store/actions/materialsFamily';
import { createRulesResupply, updateRule } from '../../../app/store/actions/resupplyRules';
import { getUserPermissions } from '../../../app/store/actions/users';
import Form from '../../../components/form/form';

class ResupplyRulesForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      storageAddressCodeFrom: '',
      storageAddressCodeTo: '',
      sectorFrom: '',
      sectorTo: '',
      streetFrom: '',
      streetTo: '',
      levelFrom: '',
      levelTo: '',
      columnSide: '',
      columnFrom: '',
      columnTo: '',
      drawerFrom: '',
      drawerTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      resupplyBy: 1,
      criteriaResupply: 1,
      storageRangeCodeFrom: '',
      storageRangeCodeTo: '',
      selectedCurves: [],
      curvesList: [],
      branchCode: '',
      branchName: '',
      listBranch: [],
      depositName: '',
      depositCode: '',
      listDeposit: [],
      listMaterialFamily: '',
      materialFamilyCode: '',
      materialFamilyName: '',
      rulesCode: '',
      mode: 'detail',
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      disableCode: true,
      disableSector: true,
      disableLevel: true,
      disableStreet: true,
      disableColumn: true,
      disableDrawer: true,
      disableProduct: true,
      disableResupplyBy: true,
      disableCritriaResupplyBy: true,
      disableStorageRangeCode: true,
      disableSelectedCurves: true,
      disableBranch: true,
      disableDeposit: true,
      disableMaterialFamily: true,
      disableRulesCode: true,

    };
    this.initialState = this.state;
  }

  async componentDidMount() {
    await this.initialDidMount();
    if (this.state.mode !== 'create') {
      await this.getRulesSelected();
    }

    const columnSideList = [
      { label: I18n.t('BEE203' /* Ambos */), value: 'both' },
      { label: I18n.t('BEE3398' /* Par */), value: 'even' },
      { label: I18n.t('BEE3399' /* Ímpar */), value: 'odd' },
    ];

    this.setState({
      columnSideList,
      columnSide: 'both',
      columnSideLabel: I18n.t('BEE203' /* Ambos */),
    });
  }

  initialDidMount = async () => {
    await this.getCurves();
    await this.branchOptions();
    await this.depositsOptionsList();
    await this.setMode();
    await this.getFamily();
    this.setBranch(this.props.userLogged.mainBranch);
  };

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
        disableCode: false,
        disableSector: false,
        disableLevel: false,
        disableStreet: false,
        disableColumn: false,
        disableDrawer: false,
        disableProduct: false,
        disableResupplyBy: false,
        disableCritriaResupplyBy: false,
        disableStorageRangeCode: false,
        disableSelectedCurves: false,
        disableBranch: false,
        disableDeposit: false,
        disableMaterialFamily: false,
        disableRulesCode: false,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        showUpdate: true,
        showCancel: true,
        disableCode: false,
        disableSector: false,
        disableLevel: false,
        disableStreet: false,
        disableColumn: false,
        disableDrawer: false,
        disableProduct: false,
        disableResupplyBy: false,
        disableCritriaResupplyBy: false,
        disableStorageRangeCode: false,
        disableSelectedCurves: true,
        disableBranch: true,
        disableDeposit: true,
        disableMaterialFamily: true,
        disableRulesCode: true,
      });
    }
  };

  getCurves = async () => {
    try {
      await this.props.getAllCurves();

      const curvesList = this.props.curvesList.map((element) => ({
        value: element.code,
        label: `${element.code} - ${element.name}`,
      }));

      this.setState({
        curvesList: [...curvesList || []],
      });
    } catch (e) {
      addNotification(
        'danger',
        I18n.t('BEE1627' /* Erro */),
        I18n.t('BEE1969' /* Erro ao buscar dados */),
        'top-right',
      );
    }
  };

  getFamily = async () => {
    try {
      const listMaterialFamily = await this.props.getMaterialsFamilyOptions();

      this.setState({
        listMaterialFamily,
      });
    } catch (e) {
      addNotification(
        'danger',
        I18n.t('BEE1627' /* Erro */),
        I18n.t('BEE1969' /* Erro ao buscar dados */),
        'top-right',
      );
    }
  };

  setFamily = async (familyCode) => {
    const { listMaterialFamily } = this.state;
    const selectFamily = listMaterialFamily.find((element) => element.value === familyCode);

    if (selectFamily) {
      this.setState({
        materialFamilyCode: selectFamily.value,
        materialFamilyName: selectFamily.label,
      });
    }
  };

  setCurves = async (curvesCode) => {
    const getCurves = this.props.curvesList;
    const curvesCodeSelectedUser = curvesCode;
    let selectedCurves = [];

    if (curvesCodeSelectedUser.length >= 1) {
      const curvesArray = curvesCode.split(/[\s,]+/);
      selectedCurves = curvesArray.map((code) => {
        const selectCurves = getCurves.find((element) => element.code === code.trim());
        if (selectCurves) {
          return {
            value: selectCurves.code,
            label: `${selectCurves.code} - ${selectCurves.name}`,
          };
        }
        return null;
      });
    }

    await this.setState({
      selectedCurves,
    });
  };

  setBranch = async (branchCode) => {
    const { listBranch } = this.state;
    const selectBranch = listBranch.find((element) => element.value === branchCode);

    if (selectBranch) {
      this.setState({
        branchCode: selectBranch.value,
        branchName: selectBranch.label,
      });
    }
  };

  branchOptions = async () => {
    try {
      const listBranch = await this.props.getBranchOptions();

      this.setState({
        listBranch: [...listBranch || []],
      });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2665' /* Regras Ressuprimento Automático */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2665' /* Regras Ressuprimento Automático */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  setDeposit = async (depositCode) => {
    const { listDeposit } = this.state;
    const selectDeposit = listDeposit.find((element) => element.value === depositCode);

    if (selectDeposit) {
      this.setState({
        depositCode: selectDeposit.value,
        depositName: selectDeposit.label,
      });
    }
  };

  depositsOptionsList = async () => {
    try {
      const listDeposit = await this.props.getDepositsOptionsList();

      await this.setState({
        listDeposit: [...listDeposit || []],
      });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2665' /* Regras Ressuprimento Automático */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2665' /* Regras Ressuprimento Automático */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: (attr === 'resupplyBy' || attr === 'criteriaResupply') ? parseInt(value, 10) : value,
    });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });

    if (attr === 'columnSide') {
      const { columnSideCode } = this.state;
      let columnSideLabel = '';

      const columnSideList = [
        { label: I18n.t('BEE203' /* Ambos */), value: 'both' },
        { label: I18n.t('BEE3398' /* Par */), value: 'even' },
        { label: I18n.t('BEE3399' /* Ímpar */), value: 'odd' },
      ];

      if (columnSideCode === 'both') {
        columnSideLabel = I18n.t('BEE203' /* Ambos */);
      } else if (columnSideCode === 'even') {
        columnSideLabel = I18n.t('BEE3398' /* Par */);
      } else if (columnSideCode === 'odd') {
        columnSideLabel = I18n.t('BEE3399' /* Ímpar */);
      }

      await this.setState({
        columnSideList,
        columnSide: columnSideCode,
        columnSideLabel,
      });
    }
  };

  generateRulesResupply = async (rules) => {
    try {
      const newRulesResupply = await this.props.createRulesResupply(rules);
      if (newRulesResupply && newRulesResupply.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2665' /* Regras Ressuprimento Automático */),
          I18n.t('BEE2674' /* Erro ao gerar nova Regra */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2665' /* Regras Ressuprimento Automático */),
          I18n.t('BEE2675' /* Regra gerada com sucesso! */),
          'top-right',
        );
        this.props.history.push(ROUTES.MOVEMENT_RESUPPLY_RULES);
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification('danger', I18n.t('BEE2665' /* Regras Ressuprimento Automático */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2665' /* Regras Ressuprimento Automático */),
          I18n.t('BEE2676' /* Não foi possível gerar a nova regra! */),
          'top-right',
        );
      }
    }
  };

  listToGenerateResupply = async () => {
    const curvesList = this.props.curvesList.map((element) => element.code);

    const {
      storageAddressCodeFrom, storageAddressCodeTo, sectorFrom, sectorTo, streetFrom, streetTo, levelFrom, levelTo,
      columnFrom, columnTo, columnSide, drawerFrom, drawerTo, productCodeFrom, productCodeTo,
      branchCode, depositCode, selectedCurves, resupplyBy, materialFamilyCode, criteriaResupply,
      storageRangeCodeFrom, storageRangeCodeTo, rulesCode,
    } = this.state;

    const filterCurves = (!!selectedCurves && selectedCurves.length !== 0)
      ? selectedCurves.map((element) => element.value).join(',') : curvesList.toString();

    const rules = {
      storageAddressCodeFrom,
      storageAddressCodeTo,
      storageRangeCodeFrom,
      storageRangeCodeTo,
      sectorFrom,
      sectorTo,
      streetFrom,
      streetTo,
      levelFrom,
      levelTo,
      columnFrom,
      columnTo,
      columnSide,
      drawerFrom,
      drawerTo,
      rulesCode,
      branchCode,
      depositCode,
      productCodeFrom,
      productCodeTo,
      materialFamily: materialFamilyCode,
      curves: filterCurves,
      resupplyBy,
      resupplyCriteria: criteriaResupply,
    };
    if (!depositCode) {
      addNotification(
        'danger',
        I18n.t('BEE2386' /* Verifique os Filtros */),
        I18n.t('BEE3694' /* Informe o depósito */),
        'top-right',
      );
      return;
    }
    if (rulesCode === '') {
      addNotification(
        'danger',
        I18n.t('BEE2386' /* Verifique os Filtros */),
        I18n.t('BEE3752' /* Informe o código da Regra ! */),
        'top-right',
      );
      return;
    }
    if (!branchCode) {
      addNotification(
        'danger',
        I18n.t('BEE2386' /* Verifique os Filtros */),
        I18n.t('BEE1781' /* Informe a filial */),
        'top-right',
      );
      return;
    }

    await this.generateRulesResupply(rules);
  };

  getRulesSelected = async () => {
    if (this.props.location.state.rules) {
      const { rules } = this.props.location.state;

      await this.setDeposit(rules.depositCode);
      await this.setFamily(rules.materialFamily);
      await this.setBranch(rules.branchCode);
      await this.setCurves(rules.curves);

      this.setState({
        id: rules.id,
        criteriaResupply: rules.resupplyCriteria,
        resupplyBy: rules.resupplyBy,
        rulesCode: rules.rulesCode,
        sectorFrom: rules.sectorFrom,
        sectorTo: rules.sectorTo,
        storageAddressCodeFrom: rules.storageAddressCodeFrom,
        storageAddressCodeTo: rules.storageAddressCodeTo,
        storageRangeCodeFrom: rules.storageRangeCodeFrom,
        storageRangeCodeTo: rules.storageRangeCodeTo,
        streetFrom: rules.streetFrom,
        streetTo: rules.streetTo,
        columnSide: rules.columnSide,
        columnFrom: rules.columnFrom,
        columnTo: rules.columnTo,
        levelFrom: rules.levelFrom,
        levelTo: rules.levelTo,
        drawerFrom: rules.drawerFrom,
        drawerTo: rules.drawerTo,
        productCodeFrom: rules.productCodeFrom,
        productCodeTo: rules.productCodeTo,
      });
    }
  };

  updateRuleSubmit = async () => {
    if (this.state.resupplyBy === 1) {
      this.setState({
        productCodeFrom: '',
        productCodeTo: '',
      });
    }

    const {
      storageAddressCodeFrom, storageAddressCodeTo, sectorFrom, sectorTo, streetFrom, streetTo, levelFrom, levelTo,
      columnFrom, columnTo, columnSide, drawerFrom, drawerTo, productCodeFrom, productCodeTo,
      branchCode, depositCode, selectedCurves, resupplyBy, materialFamilyCode, criteriaResupply,
      storageRangeCodeFrom, storageRangeCodeTo, rulesCode, id,
    } = this.state;

    try {
      const updRule = await this.props.updateRule({
        id,
        storageAddressCodeFrom,
        storageAddressCodeTo,
        sectorFrom,
        sectorTo,
        streetFrom,
        streetTo,
        levelFrom,
        levelTo,
        columnSide,
        columnFrom,
        columnTo,
        drawerFrom,
        drawerTo,
        productCodeFrom,
        productCodeTo,
        branchCode,
        depositCode,
        selectedCurves,
        resupplyBy,
        materialFamilyCode,
        criteriaResupply,
        storageRangeCodeFrom,
        storageRangeCodeTo,
        rulesCode,
      });

      if (updRule) {
        if (updRule.success && updRule.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE2678' /* Atualizar Regra */),
            I18n.t('BEE2677', { 0: rulesCode } /* Erro ao atualizar a regra %{0} ! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE2678' /* Atualizar Regra */),
            I18n.t('BEE2679', { 0: rulesCode } /* Atualização da regra %{0} efetuada com sucesso! */),
            'top-right',
          );

          this.props.history.push(ROUTES.MOVEMENT_RESUPPLY_RULES);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE2678' /* Atualizar Regra */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE2678' /* Atualizar Regra */),
            I18n.t('BEE2679', { 0: rulesCode } /* Atualização da regra %{0} efetuada com sucesso! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE2678' /* Atualizar Regra */),
          I18n.t('BEE2679', { 0: rulesCode } /* Atualização da regra %{0} efetuada com sucesso! */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      storageAddressCodeFrom, storageAddressCodeTo, sectorFrom, sectorTo, streetFrom, streetTo, levelFrom, levelTo,
      columnFrom, columnTo, columnSide, columnSideLabel, columnSideList, drawerFrom, drawerTo, productCodeFrom,
      productCodeTo, resupplyBy, criteriaResupply, curvesList, branchCode, branchName, listBranch, depositName,
      depositCode, listDeposit, listMaterialFamily, materialFamilyCode, materialFamilyName,
      storageRangeCodeFrom, storageRangeCodeTo, rulesCode, mode, showCreate, showCancel, showUpdate,
      disableCode, disableSector, disableLevel, disableStreet, disableColumn,
      disableDrawer, disableProduct, disableResupplyBy, disableCritriaResupplyBy, disableStorageRangeCode,
      disableSelectedCurves, disableBranch, disableDeposit, disableMaterialFamily,
      disableRulesCode, selectedCurves,
    } = this.state;

    let title = '';

    if (mode === 'create') {
      title = I18n.t('BEE2682' /* Nova Regra */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE2680' /* Editar Regra */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE2681' /* Detalhes da Regra */);
    } else if (mode === 'copy') {
      title = I18n.t('BEE2689' /* Copiar Regra */);
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE2642' /* Ressuprimento */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE2665' /* Regras Ressuprimento Automático */)}</li>
            {mode === 'create' && (
              <li className="breadcrumb-item active">{I18n.t('BEE2682' /* Nova Regra */)}</li>
            )}
            {mode === 'copy' && (
              <li className="breadcrumb-item active">{I18n.t('BEE2689' /* Copiar Regra */)}</li>
            )}
            {mode === 'detail' && (
              <li className="breadcrumb-item active">{I18n.t('BEE2681' /* Detalhes da Regra */)}</li>
            )}
            {mode === 'edit' && (
              <li className="breadcrumb-item active">{I18n.t('BEE2680' /* Editar Regra */)}</li>
            )}
          </ol>
        </div>
        <PanelPage
          noButton
          breadcrumb={this.breadcrumb}
          title={title}
          content={(
            <div className="row">
              <div className="col-md-6 mt-3" style={{ borderRight: 'solid 1px #d5dbe0' }}>
                <Form
                  noPanel
                  setValue={this.setValue}
                  setValueDrop={this.setValueDrop}
                  inputs={(formContext) => ([
                    formContext.createInput(
                      rulesCode,
                      'rulesCode',
                      `${I18n.t('BEE2685' /* Código da Regra */)}:`,
                      I18n.t('BEE2685' /* Código da Regra */),
                      'text',
                      true,
                      disableRulesCode,
                      undefined,
                      7,
                    ),
                    formContext.createSelectDropDown(
                      { value: branchCode, label: branchName },
                      'branch',
                      `${I18n.t('BEE145' /* Filial */)}:`,
                      listBranch,
                      disableBranch,
                      undefined,
                      ['col-md-6', 'col-md-7'],
                      undefined,
                      undefined,
                      undefined,
                      true,
                    ),
                    formContext.createSelectDropDown(
                      { value: depositCode, label: depositName },
                      'deposit',
                      `${I18n.t('BEE182' /* Depósito */)}:`,
                      listDeposit,
                      disableDeposit,
                      undefined,
                      ['col-md-6', 'col-md-7'],
                      undefined,
                      undefined,
                      undefined,
                      true,
                    ),
                    formContext.createSelectDropDown(
                      { value: materialFamilyCode, label: materialFamilyName },
                      'materialFamily',
                      `${I18n.t('BEE2668' /* Família Material */)}:`,
                      listMaterialFamily,
                      disableMaterialFamily,
                      undefined,
                      ['col-md-6', 'col-md-7'],
                      undefined,
                      undefined,
                      undefined,
                      false,
                    ),
                    formContext.createSelectDropDownMulti(
                      selectedCurves,
                      `${I18n.t('BEE355' /* Curva */)}:`,
                      'selectedCurves',
                      curvesList,
                      `${I18n.t('BEE2478' /* Todas as curvas estão selecionadas */)}`,
                      ['col-md-4', 'col-md-7'],
                      disableSelectedCurves,
                    ),
                  ])}
                />
                <hr />
                <Form
                  noPanel
                  setValue={this.setValue}
                  setValueDrop={this.setValueDrop}
                  inputs={(formContext) => ([
                    formContext.createRadioButtons(
                      [{
                        label: I18n.t('BEE2660' /* Utilizar como base a % Ponto de Ressuprimento de Cada Produto */),
                        value: 1,
                        disabled: disableCritriaResupplyBy,
                      }, {
                        label: I18n.t(
                          'BEE2661', /* Completar o Picking em sua Capacidade Máxima
                          (Desconsiderar Ponto de Ressuprimento) */
                        ),
                        value: 2,
                        disabled: disableCritriaResupplyBy,
                      },
                      ],
                      criteriaResupply,
                      'criteriaResupply',
                      `${I18n.t('BEE2669' /* Critério do Ressuprimento */)}:`,
                      ['ms-0'],
                      undefined,
                      ['col-md-7'],
                    ),
                  ])}
                />
              </div>
              <div className="col-md-6">
                <Form
                  noPanel
                  leftType
                  setValue={this.setValue}
                  setValueDrop={this.setValueDrop}
                  inputs={(formContext) => ([
                    formContext.createRadioButtons(
                      [{
                        label: I18n.t('BEE267' /* Endereço */),
                        value: 1,
                        disabled: disableResupplyBy,
                      }, {
                        label: I18n.t('BEE225' /* Produto */),
                        value: 2,
                        disabled: disableResupplyBy,
                      },
                      ],
                      resupplyBy,
                      'resupplyBy',
                      `${I18n.t('BEE2683' /* Ressuprir por */)}:`,
                      undefined,
                      undefined,
                      'col-md-8',
                      ['col-md-2'],
                    ),
                    formContext.createInputRange(
                      { from: storageAddressCodeFrom, to: storageAddressCodeTo },
                      { from: 'storageAddressCodeFrom', to: 'storageAddressCodeTo' },
                      `${I18n.t('BEE267' /* Endereço */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                      undefined,
                      undefined,
                      { from: disableCode, to: disableCode },
                    ),
                    formContext.createInputRange(
                      { from: sectorFrom, to: sectorTo },
                      { from: 'sectorFrom', to: 'sectorTo' },
                      `${I18n.t('BEE279' /* Setor */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                      undefined,
                      undefined,
                      { from: disableSector, to: disableSector },
                    ),
                    formContext.createInputRange(
                      { from: storageRangeCodeFrom, to: storageRangeCodeTo },
                      { from: 'storageRangeCodeFrom', to: 'storageRangeCodeTo' },
                      `${I18n.t('BEE581' /* Range */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                      undefined,
                      undefined,
                      { from: disableStorageRangeCode, to: disableStorageRangeCode },
                    ),
                    formContext.createInputRange(
                      { from: streetFrom, to: streetTo },
                      { from: 'streetFrom', to: 'streetTo' },
                      `${I18n.t('BEE121' /* Rua */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                      undefined,
                      undefined,
                      { from: disableStreet, to: disableStreet },
                    ),
                    formContext.createInputRange(
                      { from: columnFrom, to: columnTo },
                      { from: 'columnFrom', to: 'columnTo' },
                      `${I18n.t('BEE281' /* Coluna */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                      undefined,
                      undefined,
                      { from: disableColumn, to: disableColumn },
                    ),
                    formContext.createSelectDropDown(
                      { value: columnSide, label: columnSideLabel },
                      'columnSide',
                      `${I18n.t('BEE3397' /* Gerar Lados (Coluna) */)}:`,
                      columnSideList,
                      false,
                      undefined,
                      ['col-md-2', 'col-md-4'],
                    ),
                    formContext.createInputRange(
                      { from: levelFrom, to: levelTo },
                      { from: 'levelFrom', to: 'levelTo' },
                      `${I18n.t('BEE283' /* Nível */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                      undefined,
                      undefined,
                      { from: disableLevel, to: disableLevel },
                    ),
                    formContext.createInputRange(
                      { from: drawerFrom, to: drawerTo },
                      { from: 'drawerFrom', to: 'drawerTo' },
                      `${I18n.t('BEE285' /* Gaveta */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                      undefined,
                      undefined,
                      { from: disableDrawer, to: disableDrawer },
                    ),
                    (resupplyBy === '2' || resupplyBy === 2) && (
                      formContext.createInputRange(
                        { from: productCodeFrom, to: productCodeTo },
                        { from: 'productCodeFrom', to: 'productCodeTo' },
                        `${I18n.t('BEE225' /* Produto */)}:`,
                        { from: '', to: 'Z'.repeat(20) },
                        undefined,
                        undefined,
                        { from: disableProduct, to: disableProduct },
                      )
                    ),
                  ])}
                />
              </div>
            </div>
          )}
          footerContent={(
            <>
              {(showCancel
                ? (
                  <Link to={ROUTES.MOVEMENT_RESUPPLY_RULES}>
                    <OneClickButton
                      type="submit"
                      className="btn btn-120 btn-white p-5 m-5"
                    >
                      {I18n.t('BEE99' /* Cancelar */)}
                    </OneClickButton>
                  </Link>
                )
                : (
                  <Link to={ROUTES.MOVEMENT_RESUPPLY_RULES}>
                    <OneClickButton
                      type="submit"
                      className="btn btn-120 btn-white p-5 m-5"
                    >
                      {I18n.t('BEE137' /* Voltar */)}
                    </OneClickButton>
                  </Link>
                )
              )}
              {(showCreate
                && (
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-primary p-5 m-5"
                    onClick={this.listToGenerateResupply}
                  >
                    {I18n.t('BEE138' /* Criar */)}
                  </OneClickButton>
                )
              )}
              {(showUpdate
                && (
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-primary p-5 m-5"
                    onClick={() => this.updateRuleSubmit()}
                  >
                    {I18n.t('BEE139' /* Atualizar */)}

                  </OneClickButton>
                )
              )}
            </>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  curvesList: state.curves && state.curves.curvesList,
  userLogged: state.app.userLogged && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCurves: () => dispatch(getAllCurves()),
  getBranchOptions: () => dispatch(getBranchOptions()),
  getDepositsOptionsList: () => dispatch(getDepositsOptionsList()),
  getMaterialsFamilyOptions: () => dispatch(getMaterialsFamilyOptions()),
  getUserPermissions: () => dispatch(getUserPermissions()),
  createRulesResupply: (rules) => dispatch(createRulesResupply(rules)),
  updateRule: (rulesId) => dispatch(updateRule(rulesId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResupplyRulesForm));
