import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import moment from 'moment';

import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import { getAddressTransferWaves, cancelAddressTransferWave } from '../../../../app/store/actions/addressTransferWave';
import AdressTransferWaveHelper from '../../../../helpers/adressTransferWave';

import Table from '../../../../components/table/Table';

class WaveTransferMonitorList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalCancel: false,
      selectedDocument: {},
      transterOrderInboundsList: [],
    };

    this.defaultSorted = [{
      id: 'status',
      desc: false,
    }, {
      id: 'document',
      desc: true,
    },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                {rows.original.status < 3 && (
                  <>
                    <DropdownItem onClick={() => this.showControlPage('transfer', rows.original)}>
                      {I18n.t('BEE1506' /* Transferir */)}
                    </DropdownItem>
                    <DropdownItem onClick={() => this.showControlPage('edit', rows.original)}>
                      {I18n.t('BEE57' /* Editar */)}

                    </DropdownItem>
                  </>
                )}
                {rows.original.status === 1
                  && (
                  <DropdownItem onClick={() => this.showCancel(rows.original)}>
                    {I18n.t('BEE99' /* Cancelar */)}
                  </DropdownItem>
                  )}
                <DropdownItem onClick={() => this.showControlPage('detail', rows.original)}>
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branch.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        filterMethod: (filter, row) => {
          const match = `${row._original.branch.code} - ${row._original.branch.name}`;
          return match.toLowerCase().includes(filter.value.toLowerCase());
        },

        Cell: (rows) => (
          <span>
            {`${rows.row._original.branch.code} - ${rows.row._original.branch.name}`}
          </span>
        ),
      }, {
        Header: I18n.t('BEE1378' /* Documento */),
        accessor: 'document',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        Cell: (rows) => (<span>{AdressTransferWaveHelper.statusTransfer(rows.row._original.status)}</span>),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value, 10) === parseInt(row.status, 10)) return true;
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {AdressTransferWaveHelper.statusTransfer().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE425' /* Batismo */),
        accessor: 'baptismLabel',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE1763' /* Data Documento */),
        accessor: 'documentDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 60,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY hh mm ss');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYYhhmmss');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
      },
    ];
  }

  componentDidMount() {
    this.getAddressTransferWaves();
  }

  getAddressTransferWaves = async () => {
    try {
      const result = await this.props.getAddressTransferWaves();
      if (result.length) this.setState({ transterOrderInboundsList: [...result] });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2295' /* Monitor de Transferência por Onda */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  cancelAddressTransferWave = async () => {
    const { selectedDocument } = this.state;
    this.setState({
      showModalCancel: false,
      selectedDocument: {},
    });

    try {
      const result = await this.props.cancelAddressTransferWave({
        addressTransferWaveId: selectedDocument.id,
      });
      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
          I18n.t('BEE2296' /* Documento cancelado */),
          'top-right',
        );
        await this.getAddressTransferWaves();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2295' /* Monitor de Transferência por Onda */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  showCancel = (selected) => {
    this.setState({
      showModalCancel: true,
      selectedDocument: selected,
    });
  };

  showControlPage(typePage, selected) {
    let page = '';
    if (typePage === 'edit') {
      page = 'STOCK_WAVE_TRANSFER_MONITOR_FORM_EDIT';
    } else if (typePage === 'detail') {
      page = 'STOCK_WAVE_TRANSFER_MONITOR_FORM_DETAIL';
    } else {
      page = 'STOCK_WAVE_TRANSFER_MONITOR_FORM';
    }

    this.props.history.push({
      pathname: ROUTES[page],
      state: {
        baptism: selected.baptismLabel,
        branchCode: selected.branchCode,
        branchName: selected.branch.name,
        documentNumber: selected.document,
        depositCode: selected.depositCode,
      },
    });
  }

  render() {
    const { selectedDocument, showModalCancel, transterOrderInboundsList } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE32' /* Estoque */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE2295' /* Monitor de Transferência por Onda */)}</li>
          </ol>
        </div>
        <Table
          headerTitle={I18n.t('BEE2295' /* Monitor de Transferência por Onda */)}
          wikiHelp={ROUTES.STOCK_WAVE_TRANSFER_MONITOR_HELP}
          actionButtons={(
            <div className="ml-auto" />
          )}
          panelHeaderProps={{
            onClickReload: this.getAddressTransferWaves,
          }}
          filterable
          data={transterOrderInboundsList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {showModalCancel
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={this.cancelAddressTransferWave}
            onCancel={() => this.setState({
              showModalCancel: false,
            })}
          >
            {I18n.t('BEE1777', { 0: selectedDocument.document } /* O documento %{0} será cancelado! */)}
          </SweetAlert>
          )}
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getAddressTransferWaves: () => dispatch(getAddressTransferWaves()),
  cancelAddressTransferWave: (addressTransferWave) => dispatch(cancelAddressTransferWave(addressTransferWave)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WaveTransferMonitorList));
