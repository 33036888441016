import { I18n } from 'react-redux-i18n';

function inboundOrderStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE1783' /* Pendente de Placa */);
      case 2:
        return I18n.t('BEE471' /* Conferência Placa Finalizada */);
      case 3:
        return I18n.t('BEE472' /* Conferência Física Pendente */);
      case 4:
        return I18n.t('BEE473' /* Conferência Física Iniciada */);
      case 5:
        return I18n.t('BEE474' /* Conferência Física Finalizada */);
      case 6:
        return I18n.t('BEE475' /* Em armazenagem */);
      case 7:
        return I18n.t('BEE427' /* Armazenado */);
      case 8:
        return I18n.t('BEE207' /* Bloqueado */);
      case 9:
        return I18n.t('BEE64' /* Cancelado */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE1783' /* Pendente de Placa */) },
      { value: 2, label: I18n.t('BEE471' /* Conferência Placa Finalizada */) },
      { value: 3, label: I18n.t('BEE472' /* Conferência Física Pendente */) },
      { value: 4, label: I18n.t('BEE473' /* Conferência Física Iniciada */) },
      { value: 5, label: I18n.t('BEE474' /* Conferência Física Finalizada */) },
      { value: 6, label: I18n.t('BEE475' /* Em armazenagem */) },
      { value: 7, label: I18n.t('BEE427' /* Armazenado */) },
      { value: 8, label: I18n.t('BEE207' /* Bloqueado */) },
      { value: 9, label: I18n.t('BEE64' /* Cancelado */) },
    ];
  }
}

function inboundOrderProductStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE470' /* Pendente */);
      case 2:
        return I18n.t('BEE445' /* Conferido */);
      case 3:
        return I18n.t('BEE548' /* Armazenado Parcial */);
      case 4:
        return I18n.t('BEE427' /* Armazenado */);
      case 5:
        return I18n.t('BEE207' /* Bloqueado */);
      case 6:
        return I18n.t('BEE64' /* Cancelado */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE470' /* Pendente */) },
      { value: 2, label: I18n.t('BEE445' /* Conferido */) },
      { value: 3, label: I18n.t('BEE548' /* Armazenado Parcial */) },
      { value: 4, label: I18n.t('BEE427' /* Armazenado */) },
      { value: 5, label: I18n.t('BEE207' /* Bloqueado */) },
      { value: 6, label: I18n.t('BEE64' /* Cancelado */) },
    ];
  }
}

export default {
  inboundOrderStatus,
  inboundOrderProductStatus,
};
