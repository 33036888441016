import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';

import Table from '../../../components/table/Table';
import addNotification from '../../../components/notification';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../components/panel/panel';

import {
  getOutboundOrderRangeListPicking,
  getOutboundRangePicking,
} from '../../../app/store/actions/outboundOrdersPicking';

import PickingWave from '../pickingWave/pickingWave';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';

class OutboundOrderPicking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      startDate: moment().subtract(30, 'day'),
      endDate: moment(),
      currentRange: `${moment().subtract(30, 'day').format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`,
      outboundOrdersList: [],
      orderNumber: null,
      page: 0,
      productsList: [],
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'outboundOrder.orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: 'Tipo',
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE1096' /* Pedido Cliente */),
        accessor: 'outboundOrder.customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE581' /* Range */),
        accessor: 'rangeCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1837' /* Total Produtos */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      },
      {
        Header: I18n.t('BEE1811' /* Sequência */),
        accessor: 'sequence',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
    ];
  }

  async componentDidMount() {
    await this.openSeparationValidation();
  }

  openSeparationValidation = async () => {
    try {
      const result = await this.props.getOutboundOrderRangeListPicking(undefined, undefined, undefined, true);
      if (result && Object.keys(result).length) {
        await this.getOutboundOrderRangeSelected(result);
      } else {
        this.resetForm();
      }
    } catch (error) {
      this.resetForm();
    }
  };

  createTable = (startDate, endDate, currentRange, outboundOrdersList) => (
    <Table
      panelHeaderProps={{ noButton: true }}
      datePicker={{
        startDate,
        endDate,
        currentRange,
        handleDateApplyEvent: this.handleDateApplyEvent,
      }}
      filterable
      data={outboundOrdersList}
      columns={this.tableColumns}
      expander
      defaultPageSize={10}
      defaultSorted={this.defaultSorted}
      defaultFilterMethod={(filter, row) => {
        const input = _.lowerCase(filter.value);
        const value = _.lowerCase(row[filter.id]);
        if (_.includes(value, input)) {
          return true;
        }
      }}
      getTrProps={(state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
          return {
            onClick: async () => {
              await this.getOutboundOrderRangeSelected(rowInfo.original);
            },
            style: {
              cursor: 'pointer',
              color: (rowInfo.original.outboundOrder.typeOrders
                ? rowInfo.original.outboundOrder.typeOrders.awaitBilling
                : ['CL', 'LJ'].includes(rowInfo.original.outboundOrder.orderType))
                ? 'red'
                : 'black',
            },
          };
        }
        return {};
      }}
    />
  );

  createInputButton = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
    buttonLabel,
    buttonFunction,
  ) => (
    <div className="form-group p-2">
      <span>{label}</span>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, '')}>
            <i className="fa fa-times" />
          </button>
          <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );

  handleDateApplyEvent = async (event) => {
    const startDate = event[0];
    const endDate = event[1];

    const currentRange = `${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format('DD MMM YYYY')}`;

    await this.setState({
      startDate,
      endDate,
      currentRange,
    });

    const outboundOrdersList = await this.refreshOutboundOrdersList();

    this.setState({
      outboundOrdersList,
    });
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  getOutboundOrderRangesByOrderNumber = async (e) => {
    if (e.keyCode === 13) {
      try {
        const { orderNumber } = this.state;

        const outboundOrderRanges = await this.props.getOutboundOrderRangeListPicking(null, null, orderNumber);

        if (outboundOrderRanges.length > 1) {
          this.showModalFilterOrders(outboundOrderRanges);
        } else if (outboundOrderRanges.length === 1) {
          this.getOutboundOrderRangeSelected(outboundOrderRanges[0]);
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1447' /* Separação por Documento */),
            I18n.t('BEE1079', { 0: this.state.orderNumber } /* Não foi possível encontrar o embarque %{0} */),
            'top-right',
          );
        }
      } catch (error) {
        addNotification(
          'danger',
          I18n.t('BEE1447' /* Separação por Documento */),
          I18n.t('BEE1083', { 0: error.message } /* Houve um erro ao buscar o embarque: %{0} */),
          'top-right',
        );
      }
    }
  };

  getOutboundOrderRangeSelected = async (outboundRange) => {
    try {
      const outboundRangeProducts = await this.props.getOutboundRangePicking(outboundRange.id);

      this.setState({
        showModal: false,
        page: 2,
        productsList: outboundRangeProducts,
      });
    } catch (error) {
      // error
    }
  };

  showModalAllOrders = async () => {
    if (!this.state.showModal) {
      const outboundOrdersList = await this.refreshOutboundOrdersList();

      this.setState({
        showModal: true,
        outboundOrdersList,
      });
    } else {
      this.setState({
        showModal: false,
      });
    }
  };

  refreshOutboundOrdersList = async () => {
    const { startDate, endDate, orderNumber } = this.state;

    return this.props.getOutboundOrderRangeListPicking(startDate, endDate, orderNumber);
  };

  showModalFilterOrders = async (OrderRangeList) => {
    if (!this.state.showModal) {
      this.setState({
        showModal: true,
        outboundOrdersList: OrderRangeList,
      });
    } else {
      this.setState({
        showModal: false,
      });
    }
  };

  resetForm() {
    this.setState({
      showModal: false,
      startDate: moment().subtract(30, 'day'),
      endDate: moment(),
      currentRange: `${moment().subtract(30, 'day').format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`,
      outboundOrdersList: [],
      orderNumber: null,
      page: 1,
      productsList: [],
    });
  }

  render() {
    const {
      orderNumber, startDate, endDate, currentRange, outboundOrdersList, page, productsList,
    } = this.state;

    const title = I18n.t('BEE1447' /* Separação por Documento */);

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1447' /* Separação por Documento */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
          <WikiHelp wikiPath={ROUTES.OUTBOUND_PICKING_HELP} />
        </div>
        {page === 1 && (
          <div className="row">
            <div className="col-xl-12">
              <form onSubmit={(e) => e.preventDefault()}>
                <Panel className="panel-with-tabs">
                  <PanelHeader noButton />
                  <PanelBody>
                    <div className="row">
                      <div className="col-md-4">
                        {this.createInputButton(
                          orderNumber,
                          'orderNumber',
                          I18n.t('BEE2333' /* Documento Saída */),
                          '',
                          'text',
                          false,
                          false,
                          this.getOutboundOrderRangesByOrderNumber,
                          0,
                          <i className="fa fa-search" />,
                          this.showModalAllOrders,
                        )}
                      </div>
                    </div>
                  </PanelBody>
                  <PanelFooter />
                </Panel>
              </form>

            </div>
          </div>
        )}
        {page === 2 && (
          <PickingWave
            selectPage={this.openSeparationValidation}
            productsList={productsList}
            pickingDocument
            resetForm={() => this.resetForm()}
          />
        )}

        <Modal size="xl" isOpen={this.state.showModal} toggle={() => this.showModalAllOrders()}>
          <ModalHeader toggle={() => this.showModalAllOrders()}>{I18n.t('BEE1440' /* Buscar Documento */)}</ModalHeader>
          <ModalBody>
            <div className="p-b-15">
              {this.createTable(startDate, endDate, currentRange, outboundOrdersList)}
            </div>
          </ModalBody>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getOutboundOrderRangeListPicking: (
    startDate,
    endDate,
    orderNumber,
    openPicking,
  ) => dispatch(getOutboundOrderRangeListPicking(startDate, endDate, orderNumber, openPicking)),
  getOutboundRangePicking: (outboundRangeId) => dispatch(getOutboundRangePicking(outboundRangeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundOrderPicking));
