import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';

import { endOfDay, startOfDay, subDays } from 'date-fns';
import Table from '../../../components/table/Table';
import OutboundOrderHelpers from '../../../helpers/outboundOrder';
import addNotification from '../../../components/notification';
import { getOutboundOrderByDate } from '../../../app/store/actions/outboundOrders';
import { getUserPrinterOptions } from '../../../app/store/actions/printers';
import { printConsolidationLabel } from '../../../app/store/actions/labels';

class OutboundConsolidation extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE44' /* Consolidação */), active: true },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button
              type="button"
              className="btn btn-primary btn-xs"
              onClick={() => {
                this.setState({ selected: rows.row._original });
                this.showPrinterDialog();
              }}
            >
              <span className="d-flex align-items-center text-start">
                <i className="fa fa-print" />
                <strong className="ml-1">
                  {I18n.t('BEE1270' /* Imprimir */)}
                </strong>
              </span>
            </button>
          </div>
        ),
      }, {
        Header: I18n.t('BEE1378' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1808' /* Pedido Cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE52' /* Tipo Frete */),
        accessor: 'deliveryType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE1107' /* Cidade Entrega */),
        accessor: 'shipToCity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
      }, {
        Header: I18n.t('BEE1108' /* Estado Entrega */),
        accessor: 'shipToState',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
      }, {
        Header: I18n.t('BEE2091' /* Usuário Consolidação */),
        accessor: 'consolidationUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE2095' /* Data Consolidação */),
        accessor: 'consolidationAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE2102' /* Volumes Pendentes */),
        accessor: 'pendingVolumes',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE2103' /* Volumes Consolidados */),
        accessor: 'consolidatedVolumes',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE204' /* Situação */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{OutboundOrderHelpers.outboundOrderStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OutboundOrderHelpers.outboundOrderStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      },
    ];

    this.state = {
      selected: {},
      showPrinterDialog: false,
      outboundOrdersList: [],
      defaultPageSize: 10,
      defaultSorted: [{ id: 'orderNumber', desc: false }],
      startDate: moment().subtract(7, 'd'),
      endDate: moment(),
    };
  }

  async componentDidMount() {
    await this.outboundOrdersConsolidation();

    const printerReq = await this.props.getUserPrinterOptions();
    const { printerOptions, userPrinter } = printerReq;

    this.setState({
      printerOptions,
      printerSelected: userPrinter || '',
    });
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  outboundOrdersConsolidation = async () => {
    const { startDate, endDate } = this.state;

    try {
      const outboundOrdersList = await this.props.getOutboundOrderByDate(
        moment(startDate).format(),
        moment(endDate).format(),
      );
      if (outboundOrdersList && outboundOrdersList.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE44' /* Consolidação */),
          I18n.t('BEE1918' /* Não foi possível localizar os documentos */),
          'top-right',
        );
      } else {
        this.setState({ outboundOrdersList });
      }
    } catch (error) {
      const messageError = error.response && error.response.data
      && error.response.data.error && error.response.data.error.message;

      if (messageError) {
        addNotification(
          'danger',
          I18n.t('BEE44' /* Consolidação */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE44' /* Consolidação */),
          I18n.t('BEE1918' /* Não foi possível localizar os documentos */),
          'top-right',
        );
      }
    }
  };

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });
    await this.outboundOrdersConsolidation();
  };

  createSelect = (value, attr, label, items) => (
    <div className="form-group p-2">
      <label htmlFor={label}>{label}</label>
      <select
        className="form-control"
        value={value || ''}
        onChange={(e) => this.setValue(attr, e.target.value)}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );

  showPrinterDialog = async () => {
    const { printerOptions } = this.state;

    if (!printerOptions || printerOptions.length === 0) {
      addNotification(
        'danger',
        I18n.t('BEE44' /* Consolidação */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
    } else if (printerOptions && printerOptions.length > 1) {
      this.setState({
        showPrinterDialog: true,
      });
    } else if (printerOptions && printerOptions.length === 1) {
      await this.setState({
        printerSelected: printerOptions[0].value,
      });
      this.printLabel();
    }
  };

  printLabel = async () => {
    const { printerSelected, printerOptions, selected } = this.state;

    try {
      const outboundVolumeLabe = await this.props.printConsolidationLabel(printerSelected
        || printerOptions[0].value, selected.outboundOrderId, selected.customerCode);
      if (outboundVolumeLabe && outboundVolumeLabe.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE44' /* Consolidação */),
          I18n.t('BEE1441' /* Erro ao tentar imprimir */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE44' /* Consolidação */),
          I18n.t('BEE1427' /* Etiquetas enviadas para impressora ! */),
          'top-right',
        );
      }
    } catch (error) {
      const messageError = error.response && error.response.data
      && error.response.data.error && error.response.data.error.message;

      if (messageError) {
        addNotification(
          'danger',
          I18n.t('BEE44' /* Consolidação */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE44' /* Consolidação */),
          I18n.t('BEE1441' /* Erro ao tentar imprimir */),
          'top-right',
        );
      }
    }
    this.setState({ showPrinterDialog: false });
  };

  render() {
    const {
      defaultPageSize, defaultSorted, outboundOrdersList, showPrinterDialog, printerSelected, printerOptions,
    } = this.state;

    return (
      <>
        <Table
          breadcrumb={this.breadcrumb}
          headerTitle={I18n.t('BEE44' /* Consolidação */)}
          downloadCSV
          panelHeaderProps={{
            noButton: false,
            onClickReload: this.outboundOrdersConsolidation,
            // children: I18n.t('BEE44' /* Consolidação */).toUpperCase(),
          }}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
            defaultValue: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())],
          }}
          filterable
          expander
          data={outboundOrdersList}
          columns={this.tableColumns}
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showPrinterDialog
          && (
          <SweetAlert
            confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE1324' /* Selecionar Impressora */)}
            onConfirm={() => this.printLabel()}
            onCancel={() => this.setState({ showPrinterDialog: false })}
          >
            <div className="mt-4">
              {this.createSelect(printerSelected, 'printerSelected', I18n.t('BEE328' /* Impressora */), printerOptions)}
            </div>
          </SweetAlert>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  getOutboundOrderByDate: (filterStartDate, filterEndDate) => dispatch(getOutboundOrderByDate(
    filterStartDate,
    filterEndDate,
  )),
  printConsolidationLabel: (printerCode, outboundOrderId, customerCode) => dispatch(printConsolidationLabel(
    printerCode,
    outboundOrderId,
    customerCode,
  )),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundConsolidation));
