import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';
import ROUTES from '../../../../config/routes';
import { getAllCurves } from '../../../../app/store/actions/curves';

import { getStorageAddressSizesList, deleteStorageAddressSize } from '../../../../app/store/actions/storageAdressSizes';

class StorageAddressSizeList extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
        </>),
      },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE14' /* Gerais */) },
      { value: I18n.t('BEE1491' /* Tamanho de Endereço */), active: true },
    ];
    this.state = {
      showModalDelete: false,
      curve: '',
      curves: [],
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [];
  }

  async componentDidMount() {
    this.maintainsState();

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showAddressSizeDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showAdressSizeEdit(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showAddressSizeCopy(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showAddressSizeDelete(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE386' /* Altura */),
        accessor: 'height',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE387' /* Largura */),
        accessor: 'width',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE388' /* Comprimento */),
        accessor: 'length',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE727' /* Capacidade (Volume) */),
        accessor: 'capacityVolume',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE726' /* Capacidade (Peso) */),
        accessor: 'capacityWeight',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE355' /* Curva */),
        accessor: 'curve',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Filter: this.filterColumnSelect,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      },
    ];

    await this.getCurves();
  }

  getCurves = async () => {
    await this.props.getAllCurves();
    const curves = [];
    this.props.curves.map((element) => (curves.push({
      value: element.code,
      label: element.code,
    })));
    this.setState({
      curves: [...curves],
    });
  };

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getStorageAddressSizes();
    } else {
      await this.getStorageAddressSizes();
    }
  };

  filterColumnSelect = ({ onChange }) => {
    const { curves } = this.state;
    return (
      <select
        type="text"
        style={{ width: '100%' }}
        onChange={(event) => onChange(event.target.value)}
      >
        <option value="">{I18n.t('BEE793' /* Todos */)}</option>
        {curves.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    );
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
    });
  };

  getStorageAddressSizes = async () => {
    await this.props.getStorageAddressSizesList();
  };

  deleteStorageAddressSize = async (addressSize) => {
    const result = await this.props.deleteStorageAddressSize(addressSize._original.id);

    this.setInitState();
    if (result) {
      addNotification('success', I18n.t('BEE748' /* Eliminar Tipo de Endereço */), I18n.t('BEE749' /* Tipo de endereço eliminado com sucesso! */), 'top-right');
    }
    await this.getStorageAddressSizes();
  };

  showAddressSizeDelete = (addressSize) => {
    this.setState({ showModalDelete: true, addressSizeUpdate: addressSize });
  };

  showAddressSizeDetail = (addressSize) => {
    this.props.history.push({
      pathname: ROUTES.ADDRESS_SISE_DETAIL,
      state: {
        addressSize: addressSize._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showAdressSizeEdit = (addressSize) => {
    this.props.history.push({
      pathname: ROUTES.ADDRESS_SIZE_EDIT,
      state: {
        addressSize: addressSize._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showAddressSizeCopy = (addressSize) => {
    this.props.history.push({
      pathname: ROUTES.ADDRESS_SIZE_COPY,
      state: {
        addressSize: addressSize._original,
      },
    });
  };

  render() {
    const { addressSizeUpdate, showModalDelete, page } = this.state;
    const { addressSizesList = [] } = this.props;

    return (
      <div>
        <Table
          downloadCSV
          breadcrumb={this.breadcrumb}
          headerTitle={I18n.t('BEE1491' /* Tamanho de Endereço */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.ADDRESS_SIZE_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE1494' /* Incluir Tamanho de Endereço */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getStorageAddressSizes,
          }}
          filterable
          data={addressSizesList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteStorageAddressSize(addressSizeUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE1543', { 0: addressSizeUpdate.name } /* O tamanho de endereço %{0} será eliminado! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  addressSizesList: state.storageAddressSizes.storageAddressSizesList,
  curves: state.curves?.curvesList,
});

const mapDispatchToProps = (dispatch) => ({
  getStorageAddressSizesList: () => dispatch(getStorageAddressSizesList()),
  deleteStorageAddressSize: (addressSizeId) => dispatch(deleteStorageAddressSize(addressSizeId)),
  getAllCurves: () => dispatch(getAllCurves()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StorageAddressSizeList));
