import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from './../../../../components/form/button';
import { Panel, PanelHeader, PanelBody, PanelFooter } from './../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createWarehouse, updateWarehouse, getWarehouse
} from '../../../../app/store/actions/warehouses';
import { getBranchesOptions } from '../../../../app/store/actions/branches';
import Select from '../../../../components/form/select';
import Form from '../../../../components/form/form';

class WarehouseForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      code: '',
      branch: '',
      branchName: '',
      name: '',
      note: '',
      disableCode: true,
      disableBranch: true,
      disableName: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getWarehouseSelected();
    }

    const listBranch = await this.props.getBranchesOptions();

    this.setState({
      listBranch,
    });
  }

  getWarehouseSelected = async () => {
    if (this.props.location.state.warehouse) {
      const { warehouse } = this.props.location.state;

      const warehouseSelected = await this.props.getWarehouse(warehouse.id);
      if (warehouseSelected) {
        this.setState({
          warehouseSelected,
          warehouseId: warehouse.id,
          code: warehouseSelected.code,
          name: warehouseSelected.name,
          branch: warehouseSelected.branchCode,
          branchName: warehouseSelected.branchName,
          note: warehouseSelected.note,
        });
      }
    }
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableBranch: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableBranch: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true
      });
    }
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createSelectDropDown = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <Select
          value={value || ''}
          onChange={e => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  }

  createWarehouseSubmit = async () => {
    const {
      code, name, branch, note,
    } = this.state;

    try {
      const newWarehouse = await this.props.createWarehouse({
        code,
        name,
        branchCode: branch,
        note
      });

      if (newWarehouse) {
        await addNotification(
          'success', I18n.t('BEE156' /* Armazém */), I18n.t('BEE161', { 0: code } /* Armazém %{0} incluído com sucesso! */), 'top-right'
        );

        this.props.history.push(ROUTES.WAREHOUSE_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE156' /* Armazém */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE156' /* Armazém */), I18n.t('BEE162', { 0: code } /* Erro ao incluir o Armazém %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE156' /* Armazém */), I18n.t('BEE162', { 0: code } /* Erro ao incluir o Armazém %{0}! */), 'top-right'
        );
      }
    }
  };

  updateWarehouseSubmit = async () => {
    const {
      warehouseId, code, name, branch, note
    } = this.state;

    try {
      const updWarehouse = await this.props.updateWarehouse({
        warehouseId,
        code,
        name,
        branchCode: branch,
        note
      });

      if (updWarehouse) {
        await addNotification('success', I18n.t('BEE163' /* Atualizar Armazém */), I18n.t('BEE164', { 0: code } /* Atualização do Armazém %{0} efetuada com sucesso! */), 'top-right');

        this.props.history.push(ROUTES.WAREHOUSE_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE163' /* Atualizar Armazém */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE163' /* Atualizar Armazém */), I18n.t('BEE165', { 0: code } /* Erro ao atualizar o Armazém %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE163' /* Atualizar Armazém */), I18n.t('BEE165', { 0: code } /* Erro ao atualizar o Armazém %{0}! */), 'top-right'
        );
      }
    }
  };

  render() {
    const {
      code, name, branch, branchName, note, listBranch
    } = this.state;

    const {
      mode, disableCode, disableName, disableBranch,
      disableNote, showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';
  //  let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE166' /* Novo Armazém */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE167' /* Editar Armazém */);
  //    breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE168' /* Detalhes Armazém */);
 //     breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE17' /* Armazéns */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton={true} />
                <PanelBody>
                  <Form
                    noPanel
                    setValue={this.setValue}
                    setValueDrop={this.setValueDrop}
                    inputs={(formContext) => ([
                      formContext.createInput(
                        code,
                        'code',
                        `${I18n.t('BEE82' /* Código */)}:`,
                        I18n.t('BEE3692' /* Informe o código do armazém */),
                        'text',
                        true,
                        disableCode,
                      ),
                      formContext.createInput(
                        name,
                        'name',
                        `${I18n.t('BEE83' /* Nome */)}:`,
                        I18n.t('BEE3693' /* Informe o nome do armazém */),
                        'text',
                        true,
                        disableName,
                      ),
                      disableBranch
                        ? formContext.createInput(
                          branchName,
                          'branch',
                          `${I18n.t('BEE145' /* Filial */)}:`,
                          '',
                          'text',
                          false,
                          disableBranch,
                        )
                        : formContext.createSelectDropDown(
                          { value: branch, label: branchName },
                          'branch',
                          `${I18n.t('BEE145' /* Filial */)}:`,
                          listBranch,
                          disableBranch,
                        ),
                      formContext.createInput(
                        note,
                        'note',
                        `${I18n.t('BEE135' /* Observação */)}:`,
                        I18n.t('BEE136' /* Informe a observação */),
                        'text',
                        false,
                        disableNote,
                      ),
                    ])}
                  />
                </PanelBody>
                <PanelFooter>
                  <Link to={{pathname: ROUTES.WAREHOUSE_LIST, state: {dice: this.props.location.dice}}}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createWarehouseSubmit()}
                    >{I18n.t('BEE138' /* Criar */)}</OneClickButton>
                  )}
                  {(showUpdate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateWarehouseSubmit()}
                    >{I18n.t('BEE139' /* Atualizar */)}</OneClickButton>
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    }
  }),
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getWarehouse: warehouseId => dispatch(getWarehouse(warehouseId)),
  createWarehouse: warehouse => dispatch(createWarehouse(warehouse)),
  updateWarehouse: warehouse => dispatch(updateWarehouse(warehouse)),
  getBranchesOptions: () => dispatch(getBranchesOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WarehouseForm));