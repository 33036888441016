const INITIAL_STATE = {
  workShiftsList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_WORK_SHIFTS_LIST':
      return { ...state, workShiftsList: action.workShifts };
    default:
      return state;
  }
};
