import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import Table from '../../../components/table/Table';
import InventoryHelpers from '../../../helpers/inventory';

export default class InventorySheetGroupTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultPageSize: 5,
      defaultSorted: [{ id: 'sheetNumber', desc: false }],
    };

    this.tableColumns = [{
      Header: I18n.t('BEE2978' /* Agrupadora */),
      accessor: 'serialGroup.code',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE2999' /* Quantidade Original */),
      accessor: 'quantity',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Cell: (row) => (
        <span>
          {row.value ? parseFloat(row.value) : ''}
        </span>
      ),
    }, {
      Header: I18n.t('BEE1514' /* Contagem */),
      columns: [
        {
          Header: I18n.t('BEE224' /* Status */),
          accessor: 'firstCountStatus',
          style: { alignSelf: 'center', textAlign: 'center' },
          minWidth: 100,
          Cell: (row) => (
            <div style={{ textAlign: 'center' }}>
              <span>{InventoryHelpers.inventorySheetGroupCountStatus(row.value)}</span>
            </div>
          ),
          filterMethod: (filter, row) => {
            if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
              return true;
            }
          },
          Filter: ({ onChange }) => (
            <select
              type="text"
              style={{ width: '100%' }}
              onChange={(event) => { onChange(event.target.value); }}
            >
              <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
              {InventoryHelpers.inventorySheetGroupCountStatus().map((elem, index) => (
                <option value={elem.value} key={index}>{elem.label}</option>
              ))}
            </select>
          ),
        }, {
          Header: I18n.t('BEE441' /* Quantidade */),
          accessor: 'firstCountQuantity',
          style: { alignSelf: 'center', textAlign: 'center' },
          minWidth: 110,
          Cell: (row) => (
            <span>
              {row.value ? parseFloat(row.value) : ''}
            </span>
          ),
        }, {
          Header: I18n.t('BEE1' /* Usuário */),
          accessor: 'firstCounter.login',
          style: { alignSelf: 'center', textAlign: 'center' },
          minWidth: 120,
        }, {
          Header: I18n.t('BEE3209' /* Data */),
          accessor: 'firstCountAt',
          style: { alignSelf: 'center', textAlign: 'center' },
          minWidth: 140,
          filterMethod: (filter, row) => {
            const input = _.lowerCase(filter.value);
            const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
            const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
            if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
              return true;
            }
          },
          Cell: (row) => (
            <span>
              {' '}
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          ),
        }],
    }];
  }

  render() {
    const {
      defaultPageSize, defaultSorted,
    } = this.state;

    return (
      <Table
        panelHeaderProps={{
          children: I18n.t('BEE1515' /* Etiqueta Agrupadora */).toUpperCase(),
        }}
        downloadCSV
        filterable
        data={this.props.listSheets || []}
        columns={this.tableColumns}
        expander
        defaultPageSize={defaultPageSize}
        defaultSorted={defaultSorted}
        defaultFilterMethod={(filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row[filter.id]);
          if (_.includes(value, input)) {
            return true;
          }
        }}
      />
    );
  }
}
