import BranchesRequests from '../../server/branches';
import { addLoading, removeLoading } from './loading';

export const setBranches = (branches) => ({
  type: 'SET_BRANCHES',
  branches,
});

export const getBranchesList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const branches = await BranchesRequests.getBranches();
    dispatch(setBranches(branches));
  } finally {
    dispatch(removeLoading());
  }
};

export const createBranch = (branch) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newBranch = await BranchesRequests.createBranch(branch);
    return newBranch;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateBranch = (branch) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await BranchesRequests.updateBranch(branch);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateBranchSettings = (branchSettings) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await BranchesRequests.updateBranchSettings(branchSettings);
  } finally {
    dispatch(removeLoading());
  }
};

export const getBranch = (branchId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await BranchesRequests.getBranch(branchId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getBranchSettings = (branchId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await BranchesRequests.getBranchSettings(branchId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getBranchesOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await BranchesRequests.getBranchesOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const getOptionsBranchesAndDeposits = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await BranchesRequests.getOptionsBranchesAndDeposits();
  } finally {
    dispatch(removeLoading());
  }
};

export const getBranchesSuggestions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await BranchesRequests.getBranchesSuggestions();
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteBranch = (branchId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await BranchesRequests.deleteBranch(branchId);
  } finally {
    dispatch(removeLoading());
  }
};
