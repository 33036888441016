import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import OneClickButton from '../../components/form/button';
import { PanelPage } from '../../components/pages/pages';
import DualList from '../../components/pages/DualList';
import Form from '../../components/form/form';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportRetail extends React.PureComponent {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
                </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1963' /* KPIs */) },
      { value: I18n.t('BEE2239' /* Retalhos */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE145' /* Filial */),
        value: 'branchCode',
      }, {
        label: I18n.t('BEE1999' /* Código Produto */),
        value: 'productCode',
      }, {
        label: I18n.t('BEE1897' /* Descrição */),
        value: 'productName',
      }, {
        label: I18n.t('BEE2240' /* Unidade de Venda */),
        value: 'productUnitMeasure',
      }, {
        label: I18n.t('BEE2241' /* Quantidade Estoque */),
        value: 'stockQuantity',
      }, {
        label: I18n.t('BEE2242' /* Quantidade Retalho Atual */),
        value: 'retailQuantity',
      }, {
        label: I18n.t('BEE2243' /* Custo Contábil */),
        value: 'accountingCost',
      }, {
        label: I18n.t('BEE579' /* Peso */),
        value: 'productWeight',
      }, {
        label: I18n.t('BEE2244' /* Sequência Retalho */),
        value: 'retailSequence',
      }, {
        label: I18n.t('BEE84' /* Razão Social */),
        value: 'companyName',
      }, {
        label: I18n.t('BEE352' /* Nome Fantasia */),
        value: 'businessName',
      },
    ];

    this.state = {
      selected: [],
      branchCodeFrom: '',
      branchCodeTo: '',
      orderEmissionDateFrom: '',
      orderEmissionDateTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      supplierCodeFrom: '',
      supplierCodeTo: '',
      businessNameFrom: '',
      businessNameTo: '',
    };
  }

  onSelectFilter = (newState) => this.setState({ ...newState });

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      branchCodeFrom, branchCodeTo,
      orderEmissionDateFrom, orderEmissionDateTo,
      productCodeFrom, productCodeTo,
      supplierCodeFrom, supplierCodeTo,
      businessNameFrom, businessNameTo,
    } = this.state;

    const { location } = this.props;

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['$updatedAt', {
        type: 'between',
        value: [orderEmissionDateFrom, orderEmissionDateTo],
        instance: 'StockMovement',
        as: 'movements',
      }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['$supplierCode', {
        type: 'between', value: [supplierCodeFrom, supplierCodeTo], instance: 'StockMovement', as: 'movements',
      }],
      ['$businessName', {
        type: 'between', value: [businessNameFrom, businessNameTo], instance: 'Supplier', as: 'movements->supplier',
      }],
    ]);

    this.props.generateReport(
      I18n.t('BEE2239' /* Retalhos */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  render() {
    const {
      selected,
      branchCodeFrom, branchCodeTo,
      orderEmissionDateTo, orderEmissionDateFrom,
      productCodeFrom, productCodeTo,
      supplierCodeFrom, supplierCodeTo,
      businessNameFrom, businessNameTo,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE2239' /* Retalhos */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE409' /* Código da Filial */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ), formContext.createDateInputRange(
                    { from: orderEmissionDateFrom, to: orderEmissionDateTo },
                    { from: 'orderEmissionDateFrom', to: 'orderEmissionDateTo' },
                    `${I18n.t('BEE1763' /* Data Documento */)}:`,
                    { from: '', to: '__/__/____' },
                  ), formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE378' /* Código do Produto */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ), formContext.createInputRange(
                    { from: supplierCodeFrom, to: supplierCodeTo },
                    { from: 'supplierCodeFrom', to: 'supplierCodeTo' },
                    `${I18n.t('BEE1044' /* Código do fornecedor */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ), formContext.createInputRange(
                    { from: businessNameFrom, to: businessNameTo },
                    { from: 'businessNameFrom', to: 'businessNameTo' },
                    `${I18n.t('BEE352' /* Nome Fantasia */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportRetail);
