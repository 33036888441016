import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { returnFiltersByRules } from './utils';
import { generateReport } from '../../app/store/actions/reports';

import OneClickButton from '../../components/form/button';
import { PanelPage } from '../../components/pages/pages';
import FilterDefault from '../../components/filters/FilterDefault';
import Form from '../../components/form/form';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportProductsSeparationDate extends React.PureComponent {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
        </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1963' /* KPIs */) },
      { value: I18n.t('BEE2289' /* Separação de Produtos por Data */), active: true },
    ];

    this.state = {
      branchCodeFrom: '',
      branchCodeTo: '',
      sectorTo: '',
      sectorFrom: '',
      productCodeTo: '',
      productCodeFrom: '',
      userPickedTo: '',
      userPickedFrom: '',
      datePickedRangeTo: '',
      datePickedRangeFrom: '',
    };
  }

  onSelectFilter = (newState) => {
    this.setState({ ...newState });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const { location } = this.props;
    const {
      branchCodeFrom, branchCodeTo,
      sectorFrom, sectorTo,
      productCodeFrom, productCodeTo,
      userPickedFrom, userPickedTo,
      datePickedRangeFrom, datePickedRangeTo,
    } = this.state;

    const filters = returnFiltersByRules([
      ['$branchCode', {
        type: 'between', value: [branchCodeFrom, branchCodeTo], instance: 'OutboundRange', as: 'outboundRange',
      }],
      ['$rangeCode', {
        type: 'between', value: [sectorFrom, sectorTo], instance: 'OutboundRange', as: 'outboundRange',
      }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['$login', {
        type: 'between', value: [userPickedFrom, userPickedTo], instance: 'User', as: 'pickedUserObj',
      }],
      ['pickedAt', { type: 'between', value: [datePickedRangeFrom, datePickedRangeTo] }],
    ]);

    this.props.generateReport(I18n.t('BEE2289' /* Separação de Produtos por Data */).toLowerCase(), 'xlsx', location.pathname, [{}], filters);
  };

  render() {
    const { location = {} } = this.props;
    const {
      branchCodeFrom, branchCodeTo,
      sectorFrom, sectorTo,
      productCodeFrom, productCodeTo,
      userPickedFrom, userPickedTo,
      datePickedRangeFrom, datePickedRangeTo,
    } = this.state;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE2289' /* Separação de Produtos por Data */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ), formContext.createInputRange(
                    { from: sectorFrom, to: sectorTo },
                    { from: 'sectorFrom', to: 'sectorTo' },
                    `${I18n.t('BEE279' /* Setor */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ), formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE378' /* Código do Produto */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ), formContext.createInputRange(
                    { from: userPickedFrom, to: userPickedTo },
                    { from: 'userPickedFrom', to: 'userPickedTo' },
                    `${I18n.t('BEE1410' /* Usuário Separação */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ), formContext.createDateInputRange(
                    { from: datePickedRangeFrom, to: datePickedRangeTo },
                    { from: 'datePickedRangeFrom', to: 'datePickedRangeTo' },
                    `${I18n.t('BEE1721' /* Data Separação */)}:`,
                    { from: '', to: '__/__/____' },
                  ),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportProductsSeparationDate);
