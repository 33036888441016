import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import OneClickButton from '../../components/form/button';
import { PanelPage } from '../../components/pages/pages';
import DualList from '../../components/pages/DualList';
import Form from '../../components/form/form';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportStockStockValuedBalance extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE32' /* Estoque */) },
      { value: I18n.t('BEE4112' /* Saldo Valorizado */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE145' /* Filial */),
        value: 'branchCode',
      }, {
        label: I18n.t('BEE182' /* Depósito */),
        value: 'depositCode',
      }, {
        label: I18n.t('BEE225' /* Produto */),
        value: 'productCode',
      }, {
        label: I18n.t('BEE277' /* Descrição */),
        value: 'name',
      }, {
        label: I18n.t('BEE432' /* Endereço */),
        value: 'addressCode',
      }, {
        label: I18n.t('BEE428' /* Lote */),
        value: 'lotNumber',
      }, {
        label: I18n.t('BEE355' /* Curva */),
        value: 'curve',
      }, {
        label: I18n.t('BEE441' /* Quantidade */),
        value: 'quantity',
      }, {
        label: I18n.t('BEE1115' /* Valor */),
        value: 'balance',
      }, {
        label: I18n.t('BEE2668' /* Família Material */),
        value: 'materialFamily',
      }, {
        label: I18n.t('BEE1756' /* Unidade de Medida */),
        value: 'unitMeasure',
      }, {
        label: I18n.t('BEE2398' /* Data de Validade */),
        value: 'expirationDate',
      },
    ];

    this.state = {
      selected: [],
      branchCodeFrom: '',
      branchCodeTo: '',
      depositCodeFrom: '',
      depositCodeTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      addressCodeFrom: '',
      addressCodeTo: '',
      lotNumberFrom: '',
      lotNumberTo: '',
      nameFrom: '',
      nameTo: '',
      curveFrom: '',
      curveTo: '',
      quantityFrom: '',
      quantityTo: '',
      materialFamilyFrom: '',
      materialFamilyTo: '',
      unitMeasureFrom: '',
      unitMeasureTo: '',
      expirationDateFrom: '',
      expirationDateTo: '',
    };
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      depositCodeFrom,
      depositCodeTo,
      productCodeFrom,
      productCodeTo,
      addressCodeFrom,
      addressCodeTo,
      lotNumberFrom,
      lotNumberTo,
      nameFrom,
      nameTo,
      curveFrom,
      curveTo,
      quantityFrom,
      quantityTo,
      materialFamilyFrom,
      materialFamilyTo,
      unitMeasureFrom,
      unitMeasureTo,
      expirationDateFrom,
      expirationDateTo,
    } = this.state;

    const { location } = this.props;

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['$branchCode', {
        type: 'and',
        value: branchCodeTo.length,
        functionOnColumn: { name: 'length', type: 'lte' },
        instance: 'StockBalance',
      }],
      ['depositCode', { type: 'between', value: [depositCodeFrom, depositCodeTo] }],
      ['$depositCode', {
        type: 'and',
        value: depositCodeTo.length,
        functionOnColumn: { name: 'length', type: 'lte' },
        instance: 'StockBalance',
      }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['$productCode', {
        type: 'and',
        value: productCodeTo.length,
        functionOnColumn: { name: 'length', type: 'lte' },
        instance: 'StockBalance',
      }],
      ['$name', {
        type: 'between',
        value: [nameFrom, nameTo],
        instance: 'Product',
        as: 'product',
      }],
      ['addressCode', {
        type: 'between',
        value: [addressCodeFrom.toLocaleUpperCase(), addressCodeTo.toLocaleUpperCase()],
      }],
      ['lotNumber', { type: 'between', value: [lotNumberFrom, lotNumberTo] }],
      ['$curve', {
        type: 'between',
        value: [curveFrom, curveTo],
        instance: 'StorageAddress',
        as: 'address',
      }],
      ['quantity', { type: 'between', value: [quantityFrom, quantityTo] }],
      ['$materialFamily', {
        type: 'between',
        value: [materialFamilyFrom, materialFamilyTo],
        instance: 'Product',
        as: 'product',
      }],
      ['$unitMeasure', {
        type: 'between',
        value: [unitMeasureFrom, unitMeasureTo],
        instance: 'Product',
        as: 'product',
      }],
      ['expirationDate', { type: 'between', value: [expirationDateFrom, expirationDateTo] }],
    ]);

    this.props.generateReport(
      I18n.t('BEE4112' /* Saldo Valorizado */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  onSelectFilter = (newState) => this.setState({ ...newState });

  render() {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      depositCodeFrom,
      depositCodeTo,
      productCodeFrom,
      productCodeTo,
      addressCodeFrom,
      addressCodeTo,
      lotNumberFrom,
      lotNumberTo,
      nameFrom,
      nameTo,
      curveFrom,
      curveTo,
      quantityFrom,
      quantityTo,
      materialFamilyFrom,
      materialFamilyTo,
      unitMeasureFrom,
      unitMeasureTo,
      expirationDateFrom,
      expirationDateTo,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE4112' /* Saldo Valorizado */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: depositCodeFrom, to: depositCodeTo },
                    { from: 'depositCodeFrom', to: 'depositCodeTo' },
                    `${I18n.t('BEE182' /* Depósito */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE225' /* Produto */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: nameFrom, to: nameTo },
                    { from: 'nameFrom', to: 'nameTo' },
                    `${I18n.t('BEE277' /* Descrição */)}:`,
                    { from: '', to: 'Z'.repeat(100) },
                  ),
                  formContext.createInputRange(
                    { from: addressCodeFrom.toLocaleUpperCase(), to: addressCodeTo.toLocaleUpperCase() },
                    { from: 'addressCodeFrom', to: 'addressCodeTo' },
                    `${I18n.t('BEE432' /* Endereço */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: lotNumberFrom, to: lotNumberTo },
                    { from: 'lotNumberFrom', to: 'lotNumberTo' },
                    `${I18n.t('BEE428' /* Lote */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: curveFrom, to: curveTo },
                    { from: 'curveFrom', to: 'curveTo' },
                    `${I18n.t('BEE355' /* Curva */)}:`,
                    { from: '', to: 'Z'.repeat(1) },
                  ),
                  formContext.createInputRange(
                    { from: parseInt(quantityFrom, 10), to: parseInt(quantityTo, 10) },
                    { from: 'quantityFrom', to: 'quantityTo' },
                    `${I18n.t('BEE441' /* Quantidade */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: materialFamilyFrom, to: materialFamilyTo },
                    { from: 'materialFamilyFrom', to: 'materialFamilyTo' },
                    `${I18n.t('BEE2668' /* Família Material */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: unitMeasureFrom.toLocaleUpperCase(), to: unitMeasureTo.toLocaleUpperCase() },
                    { from: 'unitMeasureFrom', to: 'unitMeasureTo' },
                    `${I18n.t('BEE1756' /* Unidade de Medida */)}:`,
                    { from: '', to: 'Z'.repeat(5) },
                  ),
                  formContext.createDateInputRange(
                    { from: expirationDateFrom, to: expirationDateTo },
                    { from: 'expirationDateFrom', to: 'expirationDateTo' },
                    `${I18n.t('BEE2398' /* Data de Validade */)}:`,
                    { from: '__/__/____', to: '__/__/____' },
                  ),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportStockStockValuedBalance);
