import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../components/panel/panel';

import {
  createLabel, updateLabel, getLabel, simulationLabel,
} from '../../../app/store/actions/labels';

import labelEditorHelpers from '../../../helpers/labelEditor';
import ROUTES from '../../../config/routes';
import addNotification from '../../../components/notification';
import OneClickButton from '../../../components/form/button';

class LabelEditor extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mode: '',
      code: '',
      labelId: undefined,
      widthLabel: 0,
      heightLabel: 0,
      description: '',
      label: '',
      version: 1,
      trigger: '',
      note: '',
      optionsTrigger: [' ', ...labelEditorHelpers.optionsTrigger()],
      labelImageSimulation: '',
      openSimulation: true,
    };
  }

  async componentDidMount() {
    await this.setMode();

    if (this.state.mode !== 'create') {
      await this.getSelectedLabel();
    }
  }

  getSelectedLabel = async () => {
    if (this.props.location.state.label) {
      const { mode } = this.state;
      const { label } = this.props.location.state;

      if (label) {
        const {
          id, code, description, version, trigger,
          note, labelImageSimulation, labelWidthSimulation,
          labelHeightSimulation,
        } = label;
        this.setState({
          labelId: id,
          code: mode === 'copy' ? '' : code,
          description,
          label: label.label,
          version: mode === 'copy' ? 1 : version,
          trigger,
          note,
          labelImageSimulation,
          widthLabel: labelWidthSimulation,
          heightLabel: labelHeightSimulation,
        });
      }
    }
  };

  setMode = async () => {
    let mode = '';
    if (this.props.isCreate) mode = 'create';
    else if (this.props.isCopy) mode = 'copy';
    else if (this.props.isNewVersion) mode = 'newVersion';
    else if (this.props.isEdit) mode = 'edit';
    else if (this.props.isDetail) mode = 'detail';

    await this.setState({
      mode,
    });
  };

  setValue = (attr, value) => {
    if ((attr === 'widthLabel' || attr === 'heightLabel') && value > 20) {
      value = 20;
    }

    this.setState({
      [attr]: value,
      ...(attr === 'label' ? { labelImageSimulation: '' } : {}),
    });
  };

  onSubmitCreate = async () => {
    const {
      code,
      version,
      description,
      label,
      trigger,
      note,
      labelImageSimulation,
      widthLabel,
      heightLabel,
      mode,
    } = this.state;

    try {
      const createdLabel = await this.props.createLabel({
        code,
        version,
        description,
        label,
        trigger,
        note,
        labelImageSimulation,
        labelWidthSimulation: widthLabel,
        labelHeightSimulation: heightLabel,
        isCopy: mode === 'copy',
      });

      if (createdLabel) {
        await addNotification(
          'success',
          I18n.t('BEE3992' /* Incluir etiqueta */),
          I18n.t('BEE3991', { 0: code } /* Etiqueta %{0} incluída com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.DEVELOP_LABEL_EDITOR_LIST);
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3992' /* Incluir etiqueta */),
          I18n.t('BEE3993', { 0: code } /* Erro ao incluir a etiqueta %{0}! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE3992' /* Incluir etiqueta */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE3992' /* Incluir etiqueta */),
            I18n.t('BEE3993', { 0: code } /* Erro ao incluir a etiqueta %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3992' /* Incluir etiqueta */),
          I18n.t('BEE3993', { 0: code } /* Erro ao incluir a etiqueta %{0}! */),
          'top-right',
        );
      }
    }
  };

  onSubmitUpdate = async () => {
    const {
      code,
      version,
      description,
      label,
      trigger,
      note,
      labelId,
      labelImageSimulation,
      widthLabel,
      heightLabel,
    } = this.state;

    try {
      const updLabel = await this.props.updateLabel({
        labelId,
        version,
        description,
        label,
        trigger,
        note,
        labelImageSimulation,
        labelWidthSimulation: widthLabel,
        labelHeightSimulation: heightLabel,
      });

      if (updLabel && updLabel instanceof Array && updLabel.length) {
        await addNotification(
          'success',
          I18n.t('BEE3994' /* Alterar etiqueta */),
          I18n.t('BEE3995', { 0: code } /* Atualização da etiqueta %{0} efetuada com sucesso! */),
          'top-right',
        );
        this.props.history.push(ROUTES.DEVELOP_LABEL_EDITOR_LIST);
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3994' /* Alterar etiqueta */),
          I18n.t('BEE3996', { 0: code } /* Erro ao atualizar a etiqueta %{0}! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE3994' /* Alterar etiqueta */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE3994' /* Alterar etiqueta */),
            I18n.t('BEE3996', { 0: code } /* Erro ao atualizar a etiqueta %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3994' /* Alterar etiqueta */),
          I18n.t('BEE3996', { 0: code } /* Erro ao atualizar a etiqueta %{0}! */),
          'top-right',
        );
      }
    }
  };

  onSimulation = async () => {
    const {
      widthLabel,
      heightLabel,
      trigger,
      label,
    } = this.state;

    try {
      const simulation = await this.props.simulationLabel({
        widthLabel,
        heightLabel,
        trigger,
        zpl: label,
      });

      if (simulation) {
        await this.setState({ labelImageSimulation: simulation });
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3975' /* Simulação de Etiqueta */),
          I18n.t('BEE4042' /* Erro na tentativa de simulação da etiqueta */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE3975' /* Simulação de Etiqueta */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE3975' /* Simulação de Etiqueta */),
            I18n.t('BEE4042' /* Erro na tentativa de simulação da etiqueta */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3975' /* Simulação de Etiqueta */),
          I18n.t('BEE4042' /* Erro na tentativa de simulação da etiqueta */),
          'top-right',
        );
      }
    }
  };

  createInputRange = (
    values = {},
    attr = {},
    label,
    placeholder = {},
    type = { from: 'text', to: 'text' },
    required = false,
    disabled = false,
    tooltip,
    inputCode = {},
    isFilter = false,
    onChangeEnterPress = false,
    className = [],
  ) => (
    <div
      key={attr.from || attr.to}
      className={(className.length > 0 && className[0]) || isFilter ? 'row' : 'form-group row m-b-15'}
      style={{ height: 'auto', ...(isFilter ? { justifyContent: 'space-around' } : {}) }}
    >
      {!isFilter && (
        <span className={className.length >= 1 ? className[1] : 'col-form-label col-md-3'}>
          <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
            {label}
            {' '}
            {(required && (!disabled && this.state.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
            {!!tooltip && (
              <i className="fas fa-info-circle" style={tooltip.style && tooltip.style} />
            )}
          </span>
        </span>
      )}
      {(inputCode.checks)
        ? (
          <div className="col-md-1">
            <div style={{ width: '42px' }}>
              <input
                type={inputCode.type}
                className={isFilter ? 'form-control' : 'form-control m-b-5'}
                value={inputCode.value || ''}
                onChange={(e) => this.setValue(inputCode.attr, e.target.value)}
                placeholder={inputCode.placeholder ? inputCode.placeholder : ''}
                required={required}
                disabled={disabled}
              />
            </div>
          </div>
        )
        : ''}
      <div className={isFilter ? 'col-md-5' : 'col-md-3'}>
        <input
          type={type.from}
          className="form-control m-b-5"
          {
          ...isFilter
            ? {} : {
              value: values.from || '',
              onChange: (e) => this.setValue(attr.from, e.target.value),
            }
          }
          onKeyPress={(event) => {
            if (event.keyCode === 13 || event.which === 13) {
              if (onChangeEnterPress) {
                onChangeEnterPress([event.target.value, values.to]);
              }
            }
          }}
          placeholder={disabled ? '' : placeholder.from}
          required={required}
          disabled={disabled}
        />
      </div>
      <div className="col-md-1 d-flex justify-content-center align-items-center pb-1">
        <div style={{ fontSize: '0.8rem', marginRight: isFilter ? 6 : 16 }}>
          <i className="fas fa-chevron-left" />
        </div>
        <div style={{ fontSize: '0.8rem', marginLeft: isFilter ? 6 : 16 }}>
          <i className="fas fa-chevron-right" />
        </div>
      </div>
      <div className={isFilter ? 'col-md-5' : 'col-md-3'}>
        <input
          type={type.to}
          className={isFilter ? 'form-control' : 'form-control m-b-5'}
          {
          ...isFilter
            ? {} : {
              value: values.to || '',
              onChange: (e) => this.setValue(attr.to, e.target.value),
            }
          }
          onKeyPress={(event) => {
            if (event.keyCode === 13 || event.which === 13) {
              if (onChangeEnterPress) {
                onChangeEnterPress([values.from, event.target.value]);
              }
            }
          }}
          placeholder={disabled ? '' : placeholder.to}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    tooltip,
    keypressFunction = undefined,
    id = null,
    maxLength = 524288,
    className = [],
  ) => (

    <div
      key={attr}
      style={{ height: 'auto' }}
      className={className.length > 0 ? className[0] : 'form-group row m-b-15 m'}
    >
      {label
        && (
          <label
            data-required-indicator={required}
            className="col-form-label col-md-2"
            htmlFor={attr}
          >
            <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
              {label}
              {' '}
              {(required && (!disabled && this.state.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
              {!!tooltip && (
                <>
                  {' '}
                  <i className="fas fa-info-circle" />
                </>
              )}
            </span>
          </label>
        )}
      <div className="col-md-9">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
        />
      </div>
    </div>
  );

  buttonFooter = () => {
    const { mode, labelImageSimulation } = this.state;

    return (
      <div>
        <Link to={{ pathname: ROUTES.DEVELOP_LABEL_EDITOR_LIST, state: { dice: this.props.location.dice } }}>
          <OneClickButton type="button" style={{ width: 'auto', padding: 10 }} className="btn btn-white p-5 m-5">
            {
              mode === 'detail' ? I18n.t('BEE137' /* Voltar */) : I18n.t('BEE99' /* Cancelar */)
            }
          </OneClickButton>
        </Link>
        {(mode === 'create' || mode === 'copy' || mode === 'newVersion') && (
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onSubmitCreate}
          >
            {I18n.t('BEE138' /* Criar */)}
          </OneClickButton>
        )}
        {mode === 'edit' && (
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onSubmitUpdate}
            disabled={!labelImageSimulation}
          >
            {I18n.t('BEE139' /* Atualizar */)}
          </OneClickButton>
        )}

      </div>
    );
  };

  createTextArea = (value, attr, label, placeholder, rows, required, disabled, tooltip, className = [], id) => (
    <div key={attr} className={className.length > 0 ? className[0] : 'form-group row m-b-15'}>
      <span className={className.length > 1
        ? className[1]
        : `col-form-label ${this.props.leftType ? 'col-md-2' : 'col-md-4'}`}
      >
        <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
          {label}
          {' '}
          {(required && (!disabled && this.state.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
          {!!tooltip && (
            <>
              {' '}
              <i className="fas fa-info-circle" />
            </>
          )}
        </span>
      </span>
      <div className={className.length > 2 ? className[2] : 'col-md-5'}>
        <textarea
          id={id}
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
      {this.props.leftType && <div className="col-md-4" />}
    </div>
  );

  createSelect = (
    value,
    attr,
    label,
    items,
    disabled,
    tooltip,
    required = false,
    placeholder = '',
  ) => (
    <div key={attr} className="form-group row m-b-15">
      <span className="col-form-label col-md-2">
        <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
          {label}
          {(required && (!disabled && this.state.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
          {!!tooltip && (
            <>
              {' '}
              <i className="fas fa-info-circle" />
            </>
          )}
        </span>
      </span>
      <div className="col-md-9">
        <select
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          disabled={disabled}
          required={required}
          placeholder={disabled ? '' : placeholder}
        >
          {items.map((item) => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
      {this.props.leftType && <div className="col-md-4" />}
    </div>
  );

  handleOpenCloseSimulation = async () => {
    const { openSimulation } = this.state;
    await this.setState({ openSimulation: !openSimulation });
  };

  render() {
    const {
      mode, widthLabel, heightLabel, code, description, openSimulation,
      label, version, trigger, optionsTrigger, note, labelImageSimulation,
    } = this.state;
    let title = '';

    if (mode === 'create') {
      title = I18n.t('BEE2882' /* Incluir */);
    } else if (mode === 'copy') {
      title = I18n.t('BEE80' /* Copiar */);
    } else if (mode === 'newVersion') {
      title = I18n.t('BEE4043' /* Nova versão */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE3974' /* Alterar */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE56' /* Detalhes */);
    }

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE48' /* Desenvolvedor */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE3945' /* Editor de Etiquetas */)}</li>
            <li className="breadcrumb-item active">{title}</li>
          </ol>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader pageDescription={`${title} - ${I18n.t('BEE3945' /* Editor de Etiquetas */)}`} />
                <PanelBody>
                  <div className="d-flex flex-row">
                    {/* LEFT */}
                    <div
                      className="w-100 text-center position-relative"
                      style={{ borderRight: '1px solid black' }}
                    >
                      <div
                        className="position-absolute"
                        style={{
                          width: '12px', fontSize: '1rem', top: '50%', right: 0, backgroundColor: 'write',
                        }}
                      >
                        <OneClickButton
                          className="btn btn-white"
                          onClick={this.handleOpenCloseSimulation}
                          style={{ marginLeft: '-5px' }}
                        >
                          <i className={openSimulation ? 'fa fa-arrow-right' : 'fa fa-arrow-left'} />

                        </OneClickButton>
                      </div>
                      <h6>
                        {I18n.t('BEE439' /* Parâmetros */)}
                      </h6>
                      <div className="column text-center">
                        {
                          this.createInput(
                            code,
                            'code',
                            `${I18n.t('BEE82' /* Código */)}:`,
                            '',
                            'text',
                            true,
                            mode !== 'create' && mode !== 'copy',
                          )
                        }
                        {
                          this.createInput(
                            version,
                            'version',
                            `${I18n.t('BEE3976' /* Versão */)}:`,
                            '',
                            'text',
                            true,
                            true,
                          )
                        }
                        {
                          this.createInput(
                            description,
                            'description',
                            `${I18n.t('BEE277' /* Descrição */)}:`,
                            '',
                            'text',
                            false,
                            mode === 'detail',
                          )
                        }
                        {
                          this.createTextArea(
                            label,
                            'label',
                            `${I18n.t('BEE2765' /* Etiqueta */)}:`,
                            '',
                            10,
                            true,
                            mode === 'detail',
                            undefined,
                            ['form-group row m-b-15', 'col-form-label col-md-2', 'col-md-9'],
                            3,
                          )
                        }
                        {this.createSelect(
                          trigger,
                          'trigger',
                          `${I18n.t('BEE3986' /* Gatilho */)}:`,
                          optionsTrigger,
                          mode === 'detail',
                          undefined,
                          true,
                          undefined,
                          true,
                        )}
                        {
                          this.createTextArea(
                            note,
                            'note',
                            `${I18n.t('BEE135' /* Observação */)}:`,
                            '',
                            2,
                            false,
                            mode === 'detail',
                            undefined,
                            ['form-group row m-b-15', 'col-form-label col-md-2', 'col-md-9'],
                            3,
                          )
                        }
                      </div>
                    </div>
                    {/* END LEFT */}
                    {/* RIGHT */}
                    <div className={openSimulation ? 'w-100 text-center ml-2' : 'd-none'}>
                      <h6>
                        {I18n.t('BEE3975' /* Simulação de Etiqueta */)}
                      </h6>
                      {this.createInputRange(
                        { from: widthLabel, to: heightLabel },
                        { from: 'widthLabel', to: 'heightLabel' },
                        `${I18n.t('BEE3973' /* Tamanho (cm) */)}:`,
                        { from: '0cm', to: '0cm' },
                        { from: 'number', to: 'number' },
                        true,
                        mode === 'detail',
                      )}
                      <div className="mw-100 mh-100 overflow-scroll d-flex align-items-center justify-content-center">
                        <div
                          className="border p-3"
                          style={{ width: `${widthLabel}cm`, height: `${heightLabel}cm` }}
                        >
                          {labelImageSimulation
                        && <img className="mw-100 mh-100" alt="simulation" src={labelImageSimulation} />}
                        </div>
                      </div>
                      <OneClickButton
                        className="btn btn-white btn-120 p-5 m-5"
                        onClick={this.onSimulation}
                        disabled={!(Number(widthLabel) > 0 && Number(heightLabel) > 0)}
                      >
                        {I18n.t('BEE600' /* Simular */)}
                      </OneClickButton>
                    </div>
                    {/* END RIGHT */}
                  </div>
                </PanelBody>
                <PanelFooter>
                  {
                    this.buttonFooter()
                  }
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getLabel: (labelId) => dispatch(getLabel(labelId)),
  createLabel: (label) => dispatch(createLabel(label)),
  updateLabel: (label) => dispatch(updateLabel(label)),
  simulationLabel: (label) => dispatch(simulationLabel(label)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LabelEditor));
