import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import DualList from '../../components/pages/DualList';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportStockExpiredProducts extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE32' /* Estoque */) },
      { value: I18n.t('BEE2362' /* Produto Vencido */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE145' /* Filial */),
        value: 'branchCode',
      }, {
        label: I18n.t('BEE156' /* Armazém */),
        value: 'warehouseCode',
      }, {
        label: I18n.t('BEE182' /* Depósito */),
        value: 'depositCode',
      }, {
        label: I18n.t('BEE225' /* Produto */),
        value: 'productCode',
      }, {
        label: I18n.t('BEE277' /* Descrição */),
        value: 'name',
      }, {
        label: I18n.t('BEE432' /* Endereço */),
        value: 'addressCode',
      }, {
        label: I18n.t('BEE428' /* Lote */),
        value: 'lotNumber',
      }, {
        label: I18n.t('BEE515' /* Saldo */),
        value: 'balance',
      }, {
        label: I18n.t('BEE441' /* Quantidade */),
        value: 'quantity',
      }, {
        label: I18n.t('BEE482' /* Alocada */),
        value: 'allocated',
      }, {
        label: I18n.t('BEE485' /* Usuário Última Entrada */),
        value: 'lastEntranceUser',
      }, {
        label: I18n.t('BEE484' /* Data Última Entrada */),
        value: 'lastEntranceAt',
      }, {
        label: I18n.t('BEE486' /* Usuário Última Contagem */),
        value: 'lastCountUser',
      }, {
        label: I18n.t('BEE487' /* Data Última Contagem */),
        value: 'lastCountAt',
      }, {
        label: I18n.t('BEE434' /* Data de Validade */),
        value: 'expirationDate',
      }, {
        label: I18n.t('BEE488' /* Data de Fabricação */),
        value: 'manufacturingDate',
      }, {
        label: I18n.t('BEE135' /* Observação */),
        value: 'note',
      }, {
        label: I18n.t('BEE575' /* Usuário de criação */),
        value: 'createdUser',
      }, {
        label: I18n.t('BEE578' /* Data de criação */),
        value: 'createdAt',
      }, {
        label: I18n.t('BEE576' /* Usuário de alteração */),
        value: 'updatedUser',
      }, {
        label: I18n.t('BEE577' /* Data de alteração */),
        value: 'updatedAt',
      },
    ];

    this.state = {
      selected: [],
      branchCodeFrom: '',
      branchCodeTo: '',
      warehouseCodeFrom: '',
      warehouseCodeTo: '',
      depositCodeFrom: '',
      depositCodeTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      addressCodeFrom: '',
      addressCodeTo: '',
      lotNumberFrom: '',
      lotNumberTo: '',
      nameFrom: '',
      nameTo: '',
      isCloseToExpiration: '',
    };
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const { location } = this.props;
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      warehouseCodeFrom,
      warehouseCodeTo,
      depositCodeFrom,
      depositCodeTo,
      productCodeFrom,
      productCodeTo,
      addressCodeFrom,
      addressCodeTo,
      lotNumberFrom,
      lotNumberTo,
      nameFrom,
      nameTo,
      isCloseToExpiration,
    } = this.state;

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['branchCode', {
        type: 'between', value: [branchCodeFrom, branchCodeTo],
      }],
      ['warehouseCode', {
        type: 'between', value: [warehouseCodeFrom, warehouseCodeTo],
      }],
      ['depositCode', {
        type: 'between', value: [depositCodeFrom, depositCodeTo],
      }],
      ['productCode', {
        type: 'between', value: [productCodeFrom, productCodeTo],
      }],
      ['$name', {
        type: 'between', value: [nameFrom, nameTo], instance: 'Product', as: 'product',
      }],
      ['addressCode', {
        type: 'between', value: [addressCodeFrom, addressCodeTo],
      }],
      ['lotNumber', {
        type: 'between', value: [lotNumberFrom, lotNumberTo],
      }],
      ['isCloseToExpiration', {
        type: 'and', value: isCloseToExpiration,
      }],
    ]);

    this.props.generateReport(
      I18n.t('BEE2362' /* Produto Vencido */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  onSelectFilter = (newState) => this.setState({ ...newState });

  render() {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      warehouseCodeFrom,
      warehouseCodeTo,
      depositCodeFrom,
      depositCodeTo,
      productCodeFrom,
      productCodeTo,
      addressCodeFrom,
      addressCodeTo,
      lotNumberFrom,
      lotNumberTo,
      nameFrom,
      nameTo,
      isCloseToExpiration,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE2362' /* Produto Vencido */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: warehouseCodeFrom, to: warehouseCodeTo },
                    { from: 'warehouseCodeFrom', to: 'warehouseCodeTo' },
                    `${I18n.t('BEE156' /* Armazém */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: depositCodeFrom, to: depositCodeTo },
                    { from: 'depositCodeFrom', to: 'depositCodeTo' },
                    `${I18n.t('BEE182' /* Depósito */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE225' /* Produto */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: nameFrom, to: nameTo },
                    { from: 'nameFrom', to: 'nameTo' },
                    `${I18n.t('BEE277' /* Descrição */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: addressCodeFrom.toLocaleUpperCase(), to: addressCodeTo.toLocaleUpperCase() },
                    { from: 'addressCodeFrom', to: 'addressCodeTo' },
                    `${I18n.t('BEE432' /* Endereço */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: lotNumberFrom, to: lotNumberTo },
                    { from: 'lotNumberFrom', to: 'lotNumberTo' },
                    `${I18n.t('BEE428' /* Lote */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createRadioButtons([{
                    label: I18n.t('BEE173' /* Não */),
                    value: '',
                  }, {
                    label: I18n.t('BEE172' /* Sim */),
                    value: '1',
                  },
                  ], isCloseToExpiration, 'isCloseToExpiration', `${I18n.t('BEE2366' /* Próximos ao vencimento */)}:`),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  generateReport: (nameDoc, type, route, columns, filters) => dispatch(
    generateReport(nameDoc, type, route, columns, filters),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportStockExpiredProducts);
