import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import InputMask from 'react-input-mask';

import { Panel, PanelHeader, PanelBody, PanelFooter } from '../../../../components/panel/panel';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createUserFunction, updateUserFunction, getUserFunction, deleteUserFunction
} from '../../../../app/store/actions/userFunctions';
import Form from '../../../../components/form/form';

class UserFunctionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      note: '',
      disableCode: true,
      disableName: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getUserFunctionSelected();
    }
  }

  getUserFunctionSelected = async () => {
    if (this.props.location.state.userFunction) {
      const { userFunction } = this.props.location.state;

      const userFunctionSelected = await this.props.getUserFunction(userFunction.id);

      if (userFunctionSelected) {
        this.setState({
          userFunctionSelected,
          userFunctionId: userFunctionSelected.id,
          code: userFunctionSelected.code,
          name: userFunctionSelected.name,
          note: userFunctionSelected.note,
        });
      }
    }
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createInputMask = (value, attr, label, placeholder, mask, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <InputMask
          className="form-control"
          placeholder={placeholder}
          type={type}
          mask={mask}
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          required={required}
          disabled={disabled}
        ></InputMask>
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  createUserFunctionSubmit = async () => {
    const {code, name, note } = this.state;

    try {
      const newUserFunction = await this.props.createUserFunction({
        code,
        name,
        note,
      });

      if (newUserFunction) {
        if (newUserFunction.success && newUserFunction.success === false) {
          await addNotification(
            'danger', I18n.t('BEE934' /* Nova Função */), I18n.t('BEE936', { 0: code } /* Erro ao incluir a Função %{0}! */), 'top-right'
          );
        } else {
          await addNotification(
            'success', I18n.t('BEE934' /* Nova Função */), I18n.t('BEE935', { 0: code } /* Nova Função %{0} incluída com sucesso! */), 'top-right'
          );

          this.props.history.push(ROUTES.USER_FUNCTION_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE934' /* Nova Função */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE934' /* Nova Função */), I18n.t('BEE936', { 0: code } /* Erro ao incluir a Função %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE934' /* Nova Função */), I18n.t('BEE936', { 0: code } /* Erro ao incluir a Função %{0}! */), 'top-right'
        );
      }
    }
  };

  updateUserFunctionSubmit = async () => {
    const {
      name, userFunctionId, note, 
    } = this.state;

    try {
      const updUserFunction = await this.props.updateUserFunction({
        userFunctionId,
        name,
        note,
      });

      if (updUserFunction) {
        if (updUserFunction.success && updUserFunction.success === false) {
          await addNotification(
            'danger', I18n.t('BEE938' /* Atualizar Função */), I18n.t('BEE940', { 0: name } /* Erro ao atualizar a Função %{0}! */), 'top-right'
          );
        } else {
          await addNotification('success', I18n.t('BEE938' /* Atualizar Função */), I18n.t('BEE939', { 0: name } /* Atualização da Função %{0} efetuada com sucesso! */), 'top-right');

          this.props.history.push(ROUTES.USER_FUNCTION_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE938' /* Atualizar Função */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE938' /* Atualizar Função */), I18n.t('BEE940', { 0: name } /* Erro ao atualizar a Função %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE938' /* Atualizar Função */), I18n.t('BEE940', { 0: name } /* Erro ao atualizar a Função %{0}! */), 'top-right'
        );
      }
      await addNotification('danger', I18n.t('BEE938' /* Atualizar Função */), I18n.t('BEE940', { 0: name } /* Erro ao atualizar a Função %{0}! */), 'top-right');
    }
  };

  render() {
    const { code, name, note } = this.state;

    const {
      mode, disableCode, disableName, disableNote, showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';
  //  let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE934' /* Nova Função */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE941' /* Editar Função */);
   //   breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE942' /* Detalhes Função */);
   //   breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE25' /* Usuários */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE881' /* Funções do Usuário */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton={true} />
                <PanelBody>
                  <Form
                    noPanel
                    setValue={this.setValue}
                    inputs={(formContext) => ([
                      formContext.createInput(
                        code,
                        'code',
                        `${I18n.t('BEE82' /* Código */)}:`,
                        I18n.t('BEE3684' /* Informe o código da Função */),
                        'text',
                        true,
                        disableCode,
                      ),
                      formContext.createInput(
                        name,
                        'name',
                        `${I18n.t('BEE83' /* Nome */)}:`,
                        I18n.t('BEE3736' /* Informe o nome da Função */),
                        'text',
                        true,
                        disableName,
                      ),
                      formContext.createInput(
                        note,
                        'note',
                        `${I18n.t('BEE135' /* Observação */)}:`,
                        I18n.t('BEE136' /* Informe a observação */),
                        'text',
                        false,
                        disableNote,
                      ),
                    ])}
                  />
                </PanelBody>
                <PanelFooter>
                  <Link to={{pathname: ROUTES.USER_FUNCTION_LIST, state: {dice: this.props.location.dice}}}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createUserFunctionSubmit()}
                    >{I18n.t('BEE138' /* Criar */)}</OneClickButton>
                  )}
                  {(showUpdate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateUserFunctionSubmit()}
                    >{I18n.t('BEE139' /* Atualizar */)}</OneClickButton>
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getUserFunction: userFunctionId => dispatch(getUserFunction(userFunctionId)),
  createUserFunction: userFunction => dispatch(createUserFunction(userFunction)),
  updateUserFunction: userFunction => dispatch(updateUserFunction(userFunction)),
  deleteUserFunction: userFunctionId => dispatch(deleteUserFunction(userFunctionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserFunctionForm));
