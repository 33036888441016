import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from './../../../../components/form/button';
import { Panel, PanelHeader, PanelBody, PanelFooter } from './../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createCollector, updateCollector, getCollector
} from '../../../../app/store/actions/collectors';
import Form from '../../../../components/form/form';

class CollectorForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      brand: '',
      model: '',
      assetNumber: '',
      serialNumber: '',
      active: true,
      note: '',
      disableCode: true,
      disableName: true,
      disableBrand: true,
      disableModel: true,
      disableAssetNumber: true,
      disableSerialNumber: true,
      disableActive: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getCollectorSelected();
    }
  }

  getCollectorSelected = async () => {
    if (this.props.location.state.collector) {
      const { collector } = this.props.location.state;
      const collectorSelected = await collector;

      if (collectorSelected) {
        this.setState({
          collectorSelected,
          collectorId: collector.id,
          code: collectorSelected.code,
          name: collectorSelected.name,
          brand: collectorSelected.brand,
          model: collectorSelected.model,
          assetNumber: collectorSelected.assetNumber,
          serialNumber: collectorSelected.serialNumber,
          active: collectorSelected.active,
          note: collectorSelected.note,
        });
      }
    }
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableBrand: false,
        disableModel: false,
        disableAssetNumber: false,
        disableSerialNumber: false,
        disableActive: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableBrand: false,
        disableModel: false,
        disableAssetNumber: false,
        disableSerialNumber: false,
        disableActive: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true
      });
    }
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createToggleBox = (item, attr, label, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <div className="row" style={{ marginTop: '8px', marginLeft: '0px' }}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={this.handleInputChange.bind(this)}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`}></label>
          </div>
        </div>
      </div>
    </div>
  );

  createTextArea = (value = '', attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={e => this.setValue(attr, e.target.value)}
        ></textarea>
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.type === 'radio' ? parseInt(target.value) : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  createCollectorSubmit = async () => {
    const {
      code, name, brand, model, assetNumber, serialNumber, active, note
    } = this.state;

    try {
      const newCollector = await this.props.createCollector({
        code,
        name,
        brand,
        model,
        assetNumber,
        serialNumber,
        active,
        note,
      });

      if (newCollector) {
        await addNotification(
          'success', I18n.t('BEE1697' /* Coletor */), I18n.t('BEE1707', { 0: name } /* Coletor %{0} incluído com sucesso! */), 'top-right'
        );

        this.props.history.push(ROUTES.COLLECTOR_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          const msg = error.details === 'EXIST_COLLECTOR_CODE' ? I18n.t('BEE1696', { 0: code } /* Coletor %{0} já cadastrado! */) : error.details;
          await addNotification(
            'danger',
            I18n.t('BEE1697' /* Coletor */),
            `${error.code} - ${msg || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE1697' /* Coletor */), I18n.t('BEE1708', { 0: code } /* Erro ao incluir o Coletor %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE1697' /* Coletor */), I18n.t('BEE1708', { 0: code } /* Erro ao incluir o Coletor %{0}! */), 'top-right'
        );
      }
    }
  };

  updateCollectorSubmit = async () => {
    const {
      collectorId, code, name, brand, model, assetNumber, serialNumber, active, note,
    } = this.state;

    try {
      const updCollector = await this.props.updateCollector({
        collectorId,
        code,
        name,
        brand,
        model,
        assetNumber,
        serialNumber,
        active,
        note,
      });

      if (updCollector) {
        await addNotification('success', I18n.t('BEE1709' /* Atualizar Coletor */), I18n.t('BEE1710', { 0: code } /* Atualização do Coletor %{0} efetuada com sucesso! */), 'top-right');

        this.props.history.push(ROUTES.COLLECTOR_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE1709' /* Atualizar Coletor */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE1709' /* Atualizar Coletor */), I18n.t('BEE1711', { 0: code } /* Erro ao atualizar o Coletor %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE1709' /* Atualizar Coletor */), I18n.t('BEE1711', { 0: code } /* Erro ao atualizar o Coletor %{0}! */), 'top-right'
        );
      }
    }
  };

  render() {
    const {
      code, name, brand, model, assetNumber, serialNumber, active, note,
    } = this.state;

    const {
      mode, disableCode, disableName, disableBrand, disableModel, disableAssetNumber,
      disableSerialNumber, disableActive, disableNote, showCancel, showCreate, showUpdate
    } = this.state;

    let title = '';
    // let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE1712' /* Novo Coletor */);
    } else if (mode === 'edit') {
      title = `${I18n.t('BEE57' /* Editar */)} ${I18n.t('BEE1697' /* Coletor */)}`;
      //   breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = `${I18n.t('BEE56' /* Detalhes */)} ${I18n.t('BEE1697' /* Coletor */)}`;
      //   breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE1396' /* Equipamentos */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE21' /* Impressoras */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1712' /* Novo Coletor */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton={true} />
                <PanelBody>
                  <Form
                    noPanel
                    setValue={this.setValue}
                    inputs={(formContext) => ([
                      formContext.createInput(
                        code,
                        'code',
                        `${I18n.t('BEE82' /* Código */)}:`,
                        I18n.t('BEE3725' /* Informe o código do coletor */),
                        'text',
                        true,
                        disableCode,
                      ),
                      formContext.createInput(
                        name,
                        'name',
                        `${I18n.t('BEE83' /* Nome */)}:`,
                        I18n.t('BEE3726' /* Informe o nome do coletor */),
                        'text',
                        true,
                        disableName,
                      ),
                      formContext.createInput(
                        brand,
                        'brand',
                        `${I18n.t('BEE1700' /* Marca */)}:`,
                        '',
                        'text',
                        false,
                        disableBrand,
                      ),
                      formContext.createInput(
                        model,
                        'model',
                        `${I18n.t('BEE1701' /* Modelo */)}:`,
                        '',
                        'text',
                        false,
                        disableModel,
                      ),
                      formContext.createInput(
                        assetNumber,
                        'assetNumber',
                        `${I18n.t('BEE1702' /* Número Patrimonial */)}:`,
                        '',
                        'text',
                        false,
                        disableAssetNumber,
                      ),
                      formContext.createInput(
                        serialNumber,
                        'serialNumber',
                        `${I18n.t('BEE501' /* Número Série */)}:`,
                        '',
                        'text',
                        false,
                        disableSerialNumber,
                      ),
                      this.createToggleBox(
                        active,
                        'active',
                        `${I18n.t('BEE499' /* Ativo */)}:`,
                        disableActive,
                      ),
                      formContext.createTextArea(
                        note,
                        'note',
                        `${I18n.t('BEE135' /* Observação */)}:`,
                        '',
                        4,
                        false,
                        disableNote,
                      ),
                    ])}
                  />
                </PanelBody>
                <PanelFooter>
                  <Link to={{pathname: ROUTES.COLLECTOR_LIST, state: {dice: this.props.location.dice}}}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createCollectorSubmit()}
                    >{I18n.t('BEE138' /* Criar */)}</OneClickButton>
                  )}
                  {(showUpdate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateCollectorSubmit()}
                    >{I18n.t('BEE139' /* Atualizar */)}</OneClickButton>
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getCollector: collectorId => dispatch(getCollector(collectorId)),
  createCollector: collector => dispatch(createCollector(collector)),
  updateCollector: collector => dispatch(updateCollector(collector)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CollectorForm));