import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import DualList from '../../components/pages/DualList';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';
import { getAllCurves } from '../../app/store/actions/curves';

class ReportResupplyPicking extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE32' /* Estoque */) },
      { value: I18n.t('BEE2646' /* Ressuprimento de Picking */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE2648' /* ID do Ressuprimento */),
        value: 'id',
      }, {
        label: I18n.t('BEE145' /* Filial */),
        value: 'branchCode',
      }, {
        label: I18n.t('BEE225' /* Produto */),
        value: 'productCode',
      }, {
        label: I18n.t('BEE2649' /* Quantidade Sugerida */),
        value: 'suggestedQuantity',
      }, {
        label: I18n.t('BEE2650' /* Quantidade Ressuprida */),
        value: 'quantityResupplied',
      }, {
        label: I18n.t('BEE495' /* Endereço Retirada */),
        value: 'pickupAddressCode',
      }, {
        label: I18n.t('BEE2651' /* Endereço Destino */),
        value: 'storageAddressCode',
      }, {
        label: I18n.t('BEE204' /* Situação */),
        value: 'status',
      }, {
        label: I18n.t('BEE2652' /* Saldo no picking */),
        value: 'balanceOnResupply',
      }, {
        label: I18n.t('BEE2653' /* Ponto de Ressuprimento */),
        value: 'resupplyPoint',
      },
      {
        label: I18n.t('BEE2347' /* Curva do Produto */),
        value: 'curves',
      },
      {
        label: I18n.t('BEE2654' /* % Ocupação do Picking */),
        value: 'urgent',
      },
      {
        label: I18n.t('BEE2655' /* Usuário de Criação */),
        value: 'resupplyUser',
      },
      {
        label: I18n.t('BEE2656' /* Data Ressuprimento */),
        value: 'createdAt',
      },
    ];

    this.state = {
      selected: [],
      branchCodeFrom: '',
      branchCodeTo: '',
      idResupplyFrom: '',
      idResupplyTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      addressCodeFrom: '',
      addressCodeTo: '',
      storageAddressCodeFrom: '',
      storageAddressCodeTo: '',
      pending: true,
      inResupply: true,
      finished: true,
      canceled: true,
      checkAllCheckBoxes: true,
      selectedCurves: [],
      curvesCodes: [],
    };
    this.initialState = this.state;
  }

  componentDidMount() {
    this.getCurves();
  }

  getCurves = async () => {
    await this.props.getAllCurves();
    const curvesCodes = [];
    this.props.curves.map((element) => (curvesCodes.push({
      value: element.code,
      label: `${element.code} - ${element.name}`,
    })));
    this.setState({
      curvesCodes,
    });
  };

  checkOrUncheckAllBoxes = (value) => {
    this.setState({
      pending: value,
      inResupply: value,
      finished: value,
      canceled: value,
      checkAllCheckBoxes: value,
    });
  };

  handleSelectAllCheckBox = () => {
    if (this.state.pending
      && this.state.inResupply
      && this.state.finished
      && this.state.canceled) {
      this.setState({
        checkAllCheckBoxes: true,
      });
    } else {
      this.setState({
        checkAllCheckBoxes: false,
      });
    }
  };

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value) => {
    if (attr === 'curvesCodes') {
      const selectedCurves = [];
      if (value) {
        value.map((element) => (
          selectedCurves.push({ value: element.value, label: element.label })
        ));
      }
      this.setState({
        selectedCurves,
      });
    } else {
      await this.setState({
        [`${attr}`]: value,
      });
    }

    if (attr === 'checkAllCheckBoxes') {
      this.checkOrUncheckAllBoxes(value);
      this.handleSelectAllCheckBox();
    }
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      idResupplyFrom,
      idResupplyTo,
      productCodeFrom,
      productCodeTo,
      addressCodeFrom,
      addressCodeTo,
      storageAddressCodeFrom,
      storageAddressCodeTo,
      pending,
      inResupply,
      finished,
      canceled,
      selectedCurves,
    } = this.state;

    const { location } = this.props;
    const statusDocument = [];

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    if (pending) statusDocument.push('1');
    if (inResupply) statusDocument.push('2');
    if (finished) statusDocument.push('3');
    if (canceled) statusDocument.push('4');

    const filterCurves = [];
    selectedCurves.map((element) => (
      filterCurves.push(element.value)
    ));

    const filters = returnFiltersByRules([
      ['id', { type: 'between', value: [parseInt(idResupplyFrom || 0, 10), parseInt(idResupplyTo || 9999999999, 10)] }],
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['pickupAddressCode', { type: 'between', value: [addressCodeFrom, addressCodeTo] }],
      ['storageAddressCode', { type: 'between', value: [storageAddressCodeFrom, storageAddressCodeTo] }],
      ['status', { type: 'in', value: statusDocument }],
      ['curves', { type: 'in', value: filterCurves }],
    ]);

    this.props.generateReport(
      I18n.t('BEE2642' /* Ressuprimento */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  onSelectFilter = (newState) => this.setState({ ...newState });

  render() {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      idResupplyFrom,
      idResupplyTo,
      productCodeFrom,
      productCodeTo,
      addressCodeFrom,
      addressCodeTo,
      storageAddressCodeFrom,
      storageAddressCodeTo,
      pending,
      inResupply,
      finished,
      canceled,
      checkAllCheckBoxes,
      curvesCodes,
      selectedCurves,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE2646' /* Ressuprimento de Picking */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            {' '}
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div className="col">
              <div className="pt-4 add_display_flex" style={{ marginTop: 30 }}>
                <Form
                  noPanel
                  leftType
                  setValue={this.setValue}
                  inputs={(formContext) => ([
                    formContext.createInputRange(
                      { from: idResupplyFrom, to: idResupplyTo },
                      { from: 'idResupplyFrom', to: 'idResupplyTo' },
                      `${I18n.t('BEE2648' /* ID do Ressuprimento */)}:`,
                      { from: '', to: '9'.repeat(10) },
                      { from: 'number', to: 'number' },
                    ),
                    formContext.createInputRange(
                      { from: branchCodeFrom, to: branchCodeTo },
                      { from: 'branchCodeFrom', to: 'branchCodeTo' },
                      `${I18n.t('BEE145' /* Filial */)}:`,
                      { from: '', to: 'Z'.repeat(10) },
                    ),
                    formContext.createInputRange(
                      { from: productCodeFrom, to: productCodeTo },
                      { from: 'productCodeFrom', to: 'productCodeTo' },
                      `${I18n.t('BEE225' /* Produto */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                    ),
                    formContext.createInputRange(
                      { from: addressCodeFrom.toLocaleUpperCase(), to: addressCodeTo.toLocaleUpperCase() },
                      { from: 'addressCodeFrom', to: 'addressCodeTo' },
                      `${I18n.t('BEE495' /* Endereço Retirada */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                    ),
                    formContext.createInputRange(
                      { from: storageAddressCodeFrom.toLocaleUpperCase(), to: storageAddressCodeTo.toLocaleUpperCase() },
                      { from: 'storageAddressCodeFrom', to: 'storageAddressCodeTo' },
                      `${I18n.t('BEE2651' /* Endereço Destino */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                    ), formContext.createSelectDropDownMulti(
                      selectedCurves,
                      `${I18n.t('BEE355' /* Curva */)}:`,
                      'curvesCodes',
                      curvesCodes,
                      `${I18n.t('BEE2478' /* Todas as curvas estão selecionadas */)}`,
                    ),
                    formContext.createCheckBoxes([
                      {
                        attr: 'checkAllCheckBoxes',
                        value: checkAllCheckBoxes,
                        label: I18n.t('BEE2385' /* Marcar ou Desmarcar todos */),
                      },
                      {
                        attr: 'pending',
                        value: pending,
                        label: I18n.t('BEE470' /* Pendente */),
                      },
                      {
                        attr: 'inResupply',
                        value: inResupply,
                        label: I18n.t('BEE2647' /* Em Ressuprimento */),
                      },
                      { attr: 'finished', value: finished, label: I18n.t('BEE1766' /* Finalizado */) },
                      { attr: 'canceled', value: canceled, label: I18n.t('BEE64' /* Cancelado */) },
                    ], `${I18n.t('BEE1759' /* Status do Documento */)}:`),
                  ])}
                />
              </div>
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  curves: state.curves && state.curves.curvesList,
});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
  getAllCurves: () => dispatch(getAllCurves()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportResupplyPicking);
