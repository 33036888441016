import {
  SET_FIRST_ACCESS, SET_FORM_ERRORS, SET_FORM,
} from '../actions/wizard';

const initialState = {
  firstAccess: false,
  errors: {},
  code: '',
  corporateName: '',
  companyName: '',
  companyEmail: '',
  phone: '',
  cnpj: '',
  stateRegistration: '',
  postalCode: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  city: '',
  state: '',
  country: 'Brasil',
  email: '',
  password: '',
  confirmPassword: '',
  login: '',
  name: '',
  ibge: '',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FIRST_ACCESS:
      return { ...state, firstAccess: action.payload };
    case SET_FORM:
      return { ...state, [action.payload.attr]: action.payload.value };
    case SET_FORM_ERRORS:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.attr]: action.payload.error,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
