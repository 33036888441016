/* eslint-disable react/no-unused-class-component-methods */
import React, { useRef } from 'react';

import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { I18n } from 'react-redux-i18n';
import ReactTooltip from 'react-tooltip';

import SelectMulti from 'react-select';
import {
  Panel,
  PanelBody, PanelFooter,
  PanelHeader,
} from '../panel/panel';
import Select from './select';

export default class Form extends React.PureComponent {
  constructor() {
    super();

    this.colourStyles = {
      control: (styles, { isDisabled }) => ({
        ...styles,
        backgroundColor: isDisabled ? '#e9ecef' : 'white',
      }),
      option: (styles, {
        isDisabled, isSelected,
      }) => ({
        ...styles,
        backgroundColor: isDisabled ? 'grey' : 'white',
        color: isDisabled ? 'grey' : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        textAlign: 'left',

        ':hover': {
          ...styles[':hover'],
          backgroundColor: isSelected ? 'grey' : '#eb900a',
          color: 'white',
        },
      }),
    };
  }

  setValue = async (attr, value, label) => {
    this.props.setValue(attr, value, label);
  };

  setValueDrop = async (attr, value) => {
    this.props.setValueDrop(attr, value);
  };

  setFilterDrop = async (attr, value) => {
    this.props.setFilterDrop(attr, value);
  };

  customDateStyle = ({ className, children }) => (
    <div>
      <div className="bg-primary rounded p-2">
        <h5 className="text-light text-center pt-2">
          Bee
          <span className="text-dark">Stock</span>
        </h5>
      </div>
      <div className={className}>
        <div>{children}</div>
      </div>
    </div>
  );

  customDateRangeStyle = ({ className, children }) => (
    <div>
      <div className="bg-primary rounded p-2">
        <h5 className="text-light text-center pt-2">
          Bee
          <span className="text-dark">Stock</span>
        </h5>
      </div>
      <div className={className}>
        <div>{children}</div>
      </div>
    </div>
  );

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    tooltip,
    inputSize = 5,
    keypressFunction = undefined,
    id = null,
    maxLength = 524288,
    className = [],
  ) => (
    // eslint-disable-next-line max-len
    <div key={attr} style={{ height: 'auto' }} className={className.length > 0 ? className[0] : 'form-group row m-b-15 m'}>
      {label
        && (
          <label
            data-required-indicator={required}
            className={
            (className.length > 1 && className[1])
            || `col-form-label ${this.props.leftType ? 'col-md-2' : 'col-md-4'}`
          }
            htmlFor={attr}
          >
            <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
              {label}
              {' '}
              {(required && (!disabled && this.props.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
              {!!tooltip && (
                <>
                  {' '}
                  {' '}
                  <i className="fas fa-info-circle" />
                </>
              )}
            </span>
          </label>
        )}
      {!!tooltip && !!tooltip.content && (
        <ReactTooltip
          id={tooltip.id}
          type={tooltip.type || 'dark'}
          effect={tooltip.effect || 'solid'}
          place={tooltip.place || 'top'}
        >
          <span>{tooltip.content}</span>
        </ReactTooltip>
      )}
      <div className={className.length > 2 ? className[2] : `col-md-${inputSize}`}>
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
        />
      </div>
    </div>
  );

  createInputPrefix = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    tooltip,
    inputSize = 5,
    keypressFunction = undefined,
    id = null,
    maxLength = 524288,
  ) => (
    <div key={attr} className="form-group row m-b-15 m" style={{ height: 'auto' }}>
      {label
        && (
          <span className={`col-form-label ${this.props.leftType ? 'col-md-2' : 'col-md-4'}`}>
            <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
              {label}
              {!!tooltip && (
                <>
                  {' '}
                  {' '}
                  <i className="fas fa-info-circle" />
                </>
              )}
            </span>
          </span>
        )}
      {!!tooltip && !!tooltip.content && (
        <ReactTooltip
          id={tooltip.id}
          type={tooltip.type || 'dark'}
          effect={tooltip.effect || 'solid'}
          place={tooltip.place || 'top'}
        >
          <span>{tooltip.content}</span>
        </ReactTooltip>
      )}
      <div className={`col-md-${inputSize}`}>
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
        />
      </div>
      {this.props.leftType && <div className="col-md-4" />}
    </div>
  );

  createInputButton = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    buttonLabel,
    buttonFunction,
    keypressFunction = undefined,
    id = null,
    className = [],
  ) => (
    <div key={attr} className={className.length > 0 ? className[0] : 'form-group p-2'}>
      <span className={className.length > 1 ? className[1] : ''}>
        {label}
        {' '}
        {(required && (!disabled && this.props.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
      </span>
      <div className={className.length > 2 ? className[2] : 'input-group mt-1'}>
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              this.setValue(attr, '', true);
            }}
          >
            {' '}
            {' '}
            <i className="fa fa-times" />
          </button>
          {buttonFunction && buttonLabel && (
            <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  createInputRange = (
    values = {},
    attr = {},
    label,
    placeholder = {},
    type = { from: 'text', to: 'text' },
    required = {},
    disabled = {},
    tooltip,
    inputCode = {},
    isFilter = false,
    onChangeEnterPress = false,
    className = [],
  ) => {
    // useRef utilizado somente em componente funcional, se utilizado em render class ocasionara erro
    const toRef = isFilter ? useRef(null) : null;
    const fromRef = isFilter ? useRef(null) : null;
    return (
      <div
        key={attr.from || attr.to}
        className={(className.length > 0 && className[0]) || isFilter ? 'row' : 'form-group row m-b-15'}
        style={{ height: 'auto', ...(isFilter ? { justifyContent: 'space-around' } : {}) }}
      >
        {!isFilter && (
          <span className={className.length >= 1 ? className[1] : 'col-form-label col-md-2'}>
            <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
              {label}
              {' '}
              {(required && (!disabled && this.props.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
              {!!tooltip && (
                <i className="fas fa-info-circle" style={tooltip.style && tooltip.style} />
              )}
            </span>
          </span>
        )}
        {!!tooltip && !!tooltip.content && (
          <ReactTooltip
            id={tooltip.id}
            type={tooltip.type || 'dark'}
            effect={tooltip.effect || 'solid'}
            place={tooltip.place || 'top'}
          >
            <span>{tooltip.content}</span>
          </ReactTooltip>
        )}
        {(inputCode.checks)
          ? (
            <div className="col-md-1">
              <div style={{ width: '42px' }}>
                <input
                  type={inputCode.type}
                  className={isFilter ? 'form-control' : 'form-control m-b-5'}
                  value={inputCode.value || ''}
                  onChange={(e) => this.setValue(inputCode.attr, e.target.value)}
                  placeholder={inputCode.placeholder ? inputCode.placeholder : ''}
                  required={required.from}
                  disabled={disabled.from}
                />
              </div>
            </div>
          )
          : ''}
        <div className={isFilter ? 'col-md-5' : 'col-md-4'}>
          <input
            ref={fromRef}
            type={type.from}
            className="form-control m-b-5"
            {
            ...isFilter
              ? {} : {
                value: values.from || '',
                onChange: (e) => this.setValue(attr.from, e.target.value),
              }
            }
            onKeyPress={(event) => {
              if (event.keyCode === 13 || event.which === 13) {
                if (onChangeEnterPress) {
                  onChangeEnterPress([event.target.value, toRef.current ? toRef.current.value : values.to]);
                }
              }
            }}
            placeholder={disabled.from ? '' : placeholder.from}
            required={required.from}
            disabled={disabled.from}
          />
        </div>
        <div className="col-md-1 d-flex justify-content-center align-items-center pb-1">
          <div style={{ fontSize: '0.8rem', marginRight: isFilter ? 6 : 16 }}>
            <i className="fas fa-chevron-left" />
          </div>
          <div style={{ fontSize: '0.8rem', marginLeft: isFilter ? 6 : 16 }}>
            <i className="fas fa-chevron-right" />
          </div>
        </div>
        <div className={isFilter ? 'col-md-5' : 'col-md-4'}>
          <input
            ref={toRef}
            type={type.to}
            className={isFilter ? 'form-control' : 'form-control m-b-5'}
            {
            ...isFilter
              ? {} : {
                value: values.to || '',
                onChange: (e) => this.setValue(attr.to, e.target.value),
              }
            }
            onKeyPress={(event) => {
              if (event.keyCode === 13 || event.which === 13) {
                if (onChangeEnterPress) {
                  onChangeEnterPress([fromRef.current ? fromRef.current.value : values.from, event.target.value]);
                }
              }
            }}
            placeholder={disabled.to ? '' : placeholder.to}
            required={required.to}
            disabled={disabled.to}
          />
        </div>
      </div>
    );
  };

  createDateInputRange = (
    values = {},
    attr = {},
    label,
    placeholder = {},
    required = {},
    disabled = {},
    tooltip,
  ) => (
    <div key={attr.from || attr.to} className="form-group row m-b-15" style={{ height: 'auto' }}>
      <span className="col-form-label col-md-2">
        <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
          {label}
          {!!tooltip && (
            <>
              {' '}
              {' '}
              <i className="fas fa-info-circle" />
            </>
          )}
        </span>
      </span>
      {!!tooltip && !!tooltip.content && (
        <ReactTooltip
          id={tooltip.id}
          type={tooltip.type || 'dark'}
          effect={tooltip.effect || 'solid'}
          place={tooltip.place || 'top'}
        >
          <span>{tooltip.content}</span>
        </ReactTooltip>
      )}
      <div className="col-md-4">
        <DatePicker
          isClearable
          selected={(values.from && (typeof values.from === 'string' ? new Date(values.from) : values.from)) || null}
          className="form-control"
          wrapperClassName="date-range"
          dateFormat="dd/MM/yyyy"
          onChange={(e) => this.setValue(attr.from, e)}
          calendarContainer={this.customDateRangeStyle}
          placeholderText={placeholder.from || '__/__/____'}
          disabled={disabled.from}
          required={required.from}
          customInput={(
            <InputMask
              className="form-control"
              mask="99/99/9999"
            />
          )}
        />
      </div>
      <div className="col-md-1 d-flex justify-content-center align-items-center pb-1">
        <div style={{ fontSize: '0.8rem', marginRight: 16 }}><i className="fas fa-chevron-left" /></div>
        <div style={{ fontSize: '0.8rem', marginLeft: 16 }}><i className="fas fa-chevron-right" /></div>
      </div>
      <div className="col-md-4">
        <DatePicker
          isClearable
          selected={(values.to && (typeof values.to === 'string' ? new Date(values.to) : values.to)) || null}
          className="form-control"
          wrapperClassName="date-range"
          dateFormat="dd/MM/yyyy"
          onChange={(e) => this.setValue(attr.to, e)}
          calendarContainer={this.customDateStyle}
          placeholderText={placeholder.to || '31/12/9999'}
          disabled={disabled.to}
          required={required.to}
          customInput={(
            <InputMask
              className="form-control"
              mask="99/99/9999"
            />
          )}
        />
      </div>
    </div>
  );

  createSelect = (
    value,
    attr,
    label,
    items,
    disabled,
    tooltip,
    inputSize = 5,
    centerTitle = false,
    required,
    placeholder = '',
    id = null,
  ) => (
    <div key={attr} className={`form-group row m-b-15 ${centerTitle ? 'justify-content-center' : ''}`}>
      <span className={`col-form-label ${(centerTitle && '') || this.props.leftType ? 'col-md-2' : 'col-md-4'}`}>
        <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
          {label}
          {' '}
          {(required && (!disabled && this.props.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
          {!!tooltip && (
          <>
            {' '}
            {' '}
            <i className="fas fa-info-circle" />
          </>
          )}
        </span>
      </span>
      {!!tooltip && !!tooltip.content && (
      <ReactTooltip
        id={tooltip.id}
        type={tooltip.type || 'dark'}
        effect={tooltip.effect || 'solid'}
        place={tooltip.place || 'top'}
      >
        <span>{tooltip.content}</span>
      </ReactTooltip>
      )}
      <div className={`col-md-${inputSize}`}>
        <select
          id={id}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          disabled={disabled}
          required={required}
          placeholder={disabled ? '' : placeholder}
        >
          {items.map((item) => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
      {this.props.leftType && <div className="col-md-4" />}
    </div>
  );

  createDateInput = (value, attr, label, disabled, tooltip, className = ['col-md-2', 'col-md-5']) => (
    <div key={attr} className="form-group row m-b-15" style={{ height: 'auto' }}>
      <span className={`col-form-label ${this.props.leftType ? className[0] : 'col-md-4'}`}>
        <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
          {label}
          {!!tooltip && (
            <>
              {' '}
              {' '}
              <i className="fas fa-info-circle" />
            </>
          )}
        </span>
      </span>
      {!!tooltip && !!tooltip.content && (
        <ReactTooltip
          id={tooltip.id}
          type={tooltip.type || 'dark'}
          effect={tooltip.effect || 'solid'}
          place={tooltip.place || 'top'}
        >
          <span>{tooltip.content}</span>
        </ReactTooltip>
      )}
      <div className={className[1]}>
        <DatePicker
          isClearable
          selected={(value && (typeof value === 'string' ? new Date(value) : value)) || null}
          className="form-control"
          dateFormat="dd/MM/yyyy"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e)}
          calendarContainer={this.customDateStyle}
          disabled={disabled}
          customInput={(
            <InputMask
              className="form-control"
              mask="99/99/9999"
            />
          )}
        />
      </div>
      {this.props.leftType && <div className="col-md-4" />}
    </div>
  );

  createSelectDropDown = (
    value,
    attr,
    label,
    items,
    disabled,
    tooltip,
    className = ['col-md-2', 'col-md-5'],
    keypressFunction,
    id = null,
    isClearable,
    required,
  ) => (
    <div key={attr} className="form-group row m-b-15" style={{ height: 'auto' }}>
      <span className={`col-form-label ${this.props.leftType ? className[0] : 'col-md-4'}`}>
        <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
          {label}
          {' '}
          {(required && (!disabled && this.props.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
          {!!tooltip && (
            <>
              {' '}
              {' '}
              <i className="fas fa-info-circle" />
            </>
          )}
        </span>
      </span>
      {!!tooltip && !!tooltip.content && (
        <ReactTooltip
          id={tooltip.id}
          type={tooltip.type || 'dark'}
          effect={tooltip.effect || 'solid'}
          place={tooltip.place || 'top'}
        >
          <span>{tooltip.content}</span>
        </ReactTooltip>
      )}
      <div className={className[1]}>
        <Select
          id={id && id}
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          value={value.label ? value : ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable={isClearable !== undefined ? isClearable : true}
          styles={this.colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          isDisabled={disabled}
          required={required}
        />
      </div>
      {this.props.leftType && <div className="col-md-4" />}
    </div>
  );

  createSelectDropDownFilter = (
    value,
    attr,
    label,
    items,
    disabled,
    className = ['col-md-2', 'col-md-5'],
    required,
  ) => (
    <div key={attr} className="form-group row m-b-15" style={{ height: 'auto' }}>
      <p className={`col-form-label ${this.props.leftType ? className[0] : 'col-md-4'}`}>
        <span>
          {label}
          {' '}
          {(required && (!disabled && this.props.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
        </span>
      </p>
      <div className={`${className[1]} position-relative`}>
        <input
          list="dataListFilter"
          className="form-control"
          disabled={disabled}
          defaultValue={value.code ? value.code : ''}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          onChange={(e) => this.setFilterDrop(attr, e.target.value)}
        />
        <datalist
          id="dataListFilter"
        >
          {
            items.map((item) => (
              <option
                key={item.value}
                value={item.value}
              >
                {item.label}
              </option>
            ))
          }
        </datalist>
      </div>
    </div>
  );

  createSelectDropDownMulti = (
    selectItems,
    label,
    attr,
    items,
    placeholder,
    className = ['col-md-2', 'col-md-4'],
    disabled,
    zIndex = 999,
    keypressFunction,
    required,
  ) => (
    <div key={attr} className="form-group row m-b-15">
      <span className={`col-form-label, ${className[0]}`}>
        {label}
        {' '}
        {(required && (!disabled && this.props.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
      </span>
      <div className={`${className[1]}`} style={{ zIndex }}>
        <SelectMulti
          value={selectItems}
          isMulti
          onChange={(value) => this.setValue(attr, value)}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          noOptionsMessage={() => I18n.t('BEE561' /* Sem opções */)}
          isSearchable
          styles={this.colourStyles}
          maxMenuHeight={300}
          placeholder={placeholder || I18n.t('BEE144' /* Selecione... */)}
          options={items}
          isDisabled={disabled}
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, attr))}
        />
      </div>
    </div>
  );

  createInputMask = (value, attr, label, placeholder, mask, required, disabled, maskChar = null, tooltip) => (
    <div key={attr} className="form-group row m-b-15" style={{ height: 'auto' }}>
      <span className={`col-form-label ${this.props.leftType ? 'col-md-2' : 'col-md-4'}`}>
        <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
          {label}
          {' '}
          {(required && (!disabled && this.props.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
          {!!tooltip && (
            <>
              {' '}
              {' '}
              <i className="fas fa-info-circle" />
            </>
          )}
        </span>
      </span>
      {!!tooltip && !!tooltip.content && (
        <ReactTooltip
          id={tooltip.id}
          type={tooltip.type || 'dark'}
          effect={tooltip.effect || 'solid'}
          place={tooltip.place || 'top'}
        >
          <span>{tooltip.content}</span>
        </ReactTooltip>
      )}
      <div className="col-md-5">
        <InputMask
          className="form-control"
          placeholder={disabled ? '' : placeholder}
          // type="text"
          mask={mask}
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          required={required}
          disabled={disabled}
          {...((maskChar === null && { maskChar: null }) || (maskChar ? { maskChar } : {}))}
        />
      </div>
      {this.props.leftType && <div className="col-md-4" />}
    </div>
  );

  createCheckBoxes = (checks = [], labelParam, tooltip, className = ['col-md-2', 'col-md-5']) => (
    <div
      key={checks[0] ? `main${checks[0].attr}` : labelParam}
      className="form-group row m-b-15"
      style={{ height: 'auto' }}
    >
      {!!labelParam && (
        <span className={`col-form-label ${this.props.leftType ? [className[0]] : 'col-md-4'}`}>
          <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
            {labelParam}
            {!!tooltip && (
              <>
                {' '}
                {' '}
                <i className="fas fa-info-circle" />
              </>
            )}
          </span>
        </span>
      )}
      {!!tooltip && !!tooltip.content && (
        <ReactTooltip
          id={tooltip.id}
          type={tooltip.type || 'dark'}
          effect={tooltip.effect || 'solid'}
          place={tooltip.place || 'top'}
        >
          <span>{tooltip.content}</span>
        </ReactTooltip>
      )}
      <div className={className[1]} style={{ paddingTop: 6 }}>
        {checks.map(({
          value, attr, label, disabled,
        }) => (
          <div
            key={`child${attr}`}
            className={className.length > 2 ? className[2] : 'custom-control custom-checkbox'}
            style={{ marginBottom: 10 }}
          >
            <input
              type="checkbox"
              id={attr}
              className="custom-control-input"
              disabled={disabled}
              checked={value}
              onChange={(e) => this.setValue(attr, e.target.checked)}
            />
            <label className="custom-control-label" htmlFor={attr}>{label}</label>
          </div>
        ))}
      </div>
      {this.props.leftType && <div className="col-md-4" />}
    </div>
  );

  createCheckBoxLabel = (item, attr, label, disabled) => (
    <div key={`child${attr}`} className="form-group row m-b-15">
      <span className="col-form-label col-md-4" />
      <div className="col-md-0">
        <div className="row">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={(e) => this.setValue(attr, e.target.checked)}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`}>{label}</label>
          </div>
        </div>
      </div>
    </div>
  );

  createRadioButtons = (
    radios = [],
    value,
    attr,
    label,
    radioMode = 'radio-inline',
    tooltip,
    divClass = 'col-md-6',
    className = ['col-md-2'],
  ) => (
    <div key={attr} className="form-group row m-b-15" style={{ height: 'auto' }}>
      {!!label && (
        <span className={`col-form-label ${this.props.leftType ? className[0] : 'col-md-4'}`}>
          <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
            {label}
            {!!tooltip && (
              <>
                {' '}
                {' '}
                <i className="fas fa-info-circle" />
              </>
            )}
          </span>
        </span>
      )}
      {!!tooltip && !!tooltip.content && (
        <ReactTooltip
          id={tooltip.id}
          type={tooltip.type || 'dark'}
          effect={tooltip.effect || 'solid'}
          place={tooltip.place || 'top'}
        >
          <span>{tooltip.content}</span>
        </ReactTooltip>
      )}
      <div className={divClass}>
        {radios.map((radioItem = {}) => (
          <div
            key={`${attr}${radioItem.value}`}
            className={`radio radio-css ${radioMode}`}
            style={{ textAlign: 'left' }}
          >
            <input
              type="radio"
              id={`${attr}${radioItem.value}`}
              value={radioItem.value}
              checked={`${radioItem.value}` === `${value}`}
              disabled={radioItem.disabled}
              onChange={(e) => this.setValue(attr, e.target.value)}
            />
            <label htmlFor={`${attr}${radioItem.value}`}>{radioItem.label}</label>
          </div>
        ))}
      </div>
      {this.props.leftType && <div className="col-md-4" />}
    </div>
  );

  createTextArea = (
    value,
    attr,
    label,
    placeholder,
    rows,
    required,
    disabled,
    tooltip,
    className = [],
    id,
    keypressFunction = () => {},
  ) => (
    <div key={attr} className={className.length > 0 ? className[0] : 'form-group row m-b-15'}>
      <span className={className.length > 1
        ? className[1]
        : `col-form-label ${this.props.leftType ? 'col-md-2' : 'col-md-4'}`}
      >
        <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
          {label}
          {' '}
          {(required && (!disabled && this.props.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
          {!!tooltip && (
          <>
            {' '}
            {' '}
            <i className="fas fa-info-circle" />
          </>
          )}
        </span>
      </span>
      <div className={className.length > 2 ? className[2] : 'col-md-5'}>
        <textarea
          id={id}
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
          onKeyDown={keypressFunction && ((el) => keypressFunction(el, id, attr))}
        />
      </div>
      {this.props.leftType && <div className="col-md-4" />}
    </div>
  );

  createNumberFormat = (
    value,
    attr,
    label,
    decimals,
    disabled,
    prefix,
    tooltip,
    keypressFunction = undefined,
    id = null,
    isFormatted = true,
    inputSize = false,
    allowNegative = true,
    floatValue = true,
    required = false,
  ) => (
    <div key={attr} className="form-group row m-b-15">
      <span className={`col-form-label ${this.props.leftType ? 'col-md-2' : 'col-md-4'}`}>
        <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
          {label}
          {(required && (!disabled && this.props.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
          {!!tooltip && (
            <>
              {' '}
              {' '}
              <i className="fas fa-info-circle" />
            </>
          )}
        </span>
      </span>
      <div className="col-md-5">
        <NumberFormat
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          className={inputSize ? 'form-control m-b-5' : 'form-control'}
          thousandSeparator={isFormatted ? '.' : undefined}
          decimalSeparator={isFormatted ? ',' : undefined}
          decimalScale={decimals}
          value={(value === 0 || value) ? value : ''}
          onValueChange={(values) => {
            this.setValue(attr, (floatValue ? values.floatValue : values.value));
          }}
          allowNegative={!!allowNegative}
          fixedDecimalScale
          defaultValue={0}
          disabled={disabled}
          allowEmptyFormatting
          prefix={prefix}
        />
      </div>
      {this.props.leftType && <div className="col-md-4" />}
    </div>
  );

  createToggleBox = (item, attr, label, disabled) => (
    <div className="form-group row m-b-15">
      <label htmlFor={label} className="col-form-label col-md-4">{ }</label>
      <div className="col-md-0">
        <div className="row">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={this.handleInputChange}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`}>{label}</label>
          </div>
        </div>
      </div>
    </div>
  );

  createRadioSet = (value, attr, label, items, disabled) => (
    <div className="form-group row m-b-15">
      <span className="col-md-4 col-form-label">
        {label}
      </span>
      <div className="col-md-5">
        {items.map((item, index) => (
          <div className="radio radio-css" key={index} style={{ textAlign: 'left' }}>
            <input
              type="radio"
              id={`cssRadio${attr}${item.value}`}
              value={item.value}
              name={attr}
              checked={item.value === value}
              disabled={disabled}
              onChange={this.handleInputChange}
            />
            <label htmlFor={`cssRadio${attr}${item.value}`}>{item.label}</label>
          </div>
        ))}
      </div>
    </div>
  );

  createInputFile = (
    value,
    attr,
    label,
    placeholder,
    type = 'file',
    required,
    disabled,
    tooltip,
    inputSize = 5,
    keypressFunction = undefined,
    id = null,
    maxLength = 524288,
    className = [],
    accept,
  ) => (
    // eslint-disable-next-line max-len
    <div key={attr} style={{ height: 'auto' }} className={className.length > 0 ? className[0] : 'form-group row m-b-15 m'}>
      {label
        && (
          <label
            data-required-indicator={required}
            className={
            (className.length > 1 && className[1])
            || `col-form-label ${this.props.leftType ? 'col-md-2' : 'col-md-4'}`
          }
            htmlFor={attr}
          >
            <span {...tooltip ? { 'data-tip': true, 'data-for': tooltip.id } : {}}>
              {label}
              {' '}
              {(required && (!disabled && this.props.mode !== 'detail')) && <span style={{ color: 'red' }}>*</span>}
              {!!tooltip && (
                <>
                  {' '}
                  {' '}
                  <i className="fas fa-info-circle" />
                </>
              )}
            </span>
          </label>
        )}
      {!!tooltip && !!tooltip.content && (
        <ReactTooltip
          id={tooltip.id}
          type={tooltip.type || 'dark'}
          effect={tooltip.effect || 'solid'}
          place={tooltip.place || 'top'}
        >
          <span>{tooltip.content}</span>
        </ReactTooltip>
      )}
      <div className={className.length > 2 ? className[2] : `col-md-${inputSize}`}>
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
          accept={accept}
        />
      </div>
    </div>
  );

  customComponent = (component) => component;

  render() {
    if (this.props.noPanel) {
      return (
        <div className="row">
          <div
            className={`col-xl-12 ${(this.props.leftType && 'text-left') || (this.props.isModal ? '' : 'text-right')}`}
          >
            <form>
              {!!this.props.inputs && this.props.inputs(this)}
            </form>
          </div>
        </div>
      );
    }

    return (
      <div className={this.props.animationType || 'slideUpTransition'}>
        {!!this.props.breadcrumb && this.props.breadcrumb.length && (
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              {this.props.breadcrumb.map((it) => (
                <li key={it.value} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                  {it.value}
                </li>
              ))}
            </ol>
          </div>
        )}
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {this.props.title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel {...this.props.headerProps}>
                {!this.props.noHeader && <PanelHeader {...this.props} />}
                <PanelBody>
                  {!!this.props.inputs && this.props.inputs(this)}
                </PanelBody>
                {!this.props.noFooter && <PanelFooter {...this.props} />}
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
