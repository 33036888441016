import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

import Table from '../../../../components/table/Table';
import ROUTES from '../../../../config/routes';
import { getCustomerBranchesList, customerBranchDelete } from '../../../../app/store/actions/customerBranch';
import addNotification from '../../../../components/notification';

class CustomerBranchList extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
                </>),
      },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE14' /* Gerais */) },
      { value: I18n.t('BEE2020' /* Cliente x Filial */), active: true },
    ];

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];
    this.defaultSorted = [{ id: 'branchCode', desc: false }];

    this.state = {
      customerBranchId: '',
      showModalDelete: false,
      productBranchUpdate: null,
      defaultPageSize: 10,
      pages: 0,
      totalDataLength: 0,
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEdit(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopy(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.setState({ customerBranchId: rows.original.id, showModalDelete: true })}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE84' /* Razão Social */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 400,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2022' /* Restrição */),
        accessor: 'restriction',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (rows) => (rows.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)),
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option key="all" value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option key={1} value={1}>{I18n.t('BEE172' /* Sim */)}</option>
            <option key={0} value={0}>{I18n.t('BEE173' /* Não */)}</option>
          </select>
        ),
      },
    ];
  }

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  customerBranchesList = async () => {
    const {
      page, pageSize, filtered, sorted,
    } = this;
    try {
      const customerBranchData = await this.props.getCustomerBranchesList({
        page, pageSize, filtered, sorted,
      });

      if (customerBranchData && customerBranchData.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2020' /* Cliente x Filial */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        this.setState({
          pages: customerBranchData.pages,
          totalDataLength: customerBranchData.totalDataLength,
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2020' /* Cliente x Filial */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  showDetail = (customerBranch) => {
    this.props.history.push({
      pathname: ROUTES.CUSTOMER_BRANCH_DETAIL,
      state: {
        customerBranch: customerBranch._original,
      },
    });
  };

  showEdit = (customerBranch) => {
    this.props.history.push({
      pathname: ROUTES.CUSTOMER_BRANCH_EDIT,
      state: {
        customerBranch: customerBranch._original,
      },
    });
  };

  showCopy = (customerBranch) => {
    this.props.history.push({
      pathname: ROUTES.CUSTOMER_BRANCH_COPY,
      state: {
        customerBranch: customerBranch._original,
      },
    });
  };

  showDelete = async () => {
    const { customerBranchId } = this.state;
    this.setState({ showModalDelete: false, customerBranchId: '' });
    try {
      const result = await this.props.customerBranchDelete(customerBranchId);

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2020' /* Cliente x Filial */),
          I18n.t('BEE2031' /* Erro ao eliminar o registro */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2020' /* Cliente x Filial */),
          I18n.t('BEE2032' /* Registro eliminado com sucesso */),
          'top-right',
        );
        this.customerBranchesList();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2020' /* Cliente x Filial */),
          I18n.t('BEE2031' /* Erro ao eliminar o registro */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      defaultPageSize, pages, showModalDelete, totalDataLength,
    } = this.state;
    const { customerBranchesList } = this.props;

    return (
      <>
        <Table
          wikiHelp={ROUTES.CUSTOMER_BRANCH_HELP}
          breadcrumb={this.breadcrumb}
          downloadCSV
          headerTitle={I18n.t('BEE2020' /* Cliente x Filial */)}
          panelHeaderProps={{
            onClickReload: this.customerBranchesList,
          }}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.CUSTOMER_BRANCH_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE2023' /* Incluir Cliente x Filial */)}
              </Link>
            </div>
          )}
          filterable
          data={customerBranchesList}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          expander
          defaultPageSize={defaultPageSize}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          manual
          pages={pages}
          onFetchData={(state, instance) => {
            this.page = state.page;
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;
            this.customerBranchesList();
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.showDelete()}
            onCancel={() => this.setState({ showModalDelete: false, customerBranchId: '' })}
          >
            {I18n.t('BEE2033' /* Esta ação ira excluir o registro Cliente x Filial */)}
          </SweetAlert>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerBranchesList: state.customerBranches && state.customerBranches.customerBranchesList,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerBranchesList: (tableParams) => dispatch(getCustomerBranchesList(tableParams)),
  customerBranchDelete: (customerBranchId) => dispatch(customerBranchDelete(customerBranchId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerBranchList));
