/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { I18n } from 'react-redux-i18n';

import addNotification from '../../../components/notification';
import { showReportBackgroundModal } from '../../../components/pages/reportsBackgroundModal';
import { scrollToElement, focusElementById } from '../../../components/utils';
import ReportsServer from '../../server/reports';
import { addLoading, removeLoading } from './loading';

export const generateReport = (nameDoc, typeDoc, route, columns, filters, noModal = false) => async (dispatch) => {
  if (!columns.length) {
    addNotification('danger', I18n.t('BEE528' /* Relatório */), I18n.t('BEE549' /* Selecione uma coluna ou mais para a geração do relatório */), 'top-right');
    scrollToElement('#dualselect');
    focusElementById('dualselect-available');
    return;
  }

  const reportType = (noModal) ? 'wait' : await showReportBackgroundModal(); // 'bg', 'wait', 'cancel'

  if (reportType === 'cancel') return;

  dispatch(addLoading());
  try {
    await ReportsServer.generateReport(nameDoc, typeDoc, route, columns, filters, reportType);
  } catch (e) {
    if (reportType !== 'bg') {
      const decodedString = String.fromCharCode.apply(null, new Uint8Array(e.response.data));
      const response = JSON.parse(decodedString);
      addNotification('danger', I18n.t('BEE528' /* Relatório */), response?.error?.message || I18n.t('BEE547', { 0: e.response ? e.response.status : 500 } /* Erro ao gerar relatório. Código: %{0} */), 'top-right');
    } else {
      addNotification('danger', I18n.t('BEE528' /* Relatório */), I18n.t('BEE547', { 0: e.response ? e.response.status : 500 } /* Erro ao gerar relatório. Código: %{0} */), 'top-right');
    }
  }
  dispatch(removeLoading());
};
