import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getNotifications } from '../../../app/store/actions/notifications';
import ROUTES from '../../../config/routes';

class DropdownNotification extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.intervalRefreshNotification = null;
    this.state = {
      dropdownOpen: false,
    };
  }

  componentDidMount() {
    clearInterval(this.intervalRefreshNotification);

    this.props.getNotifications();

    this.intervalRefreshNotification = setInterval(this.props.getNotifications, (30 * 1000));
  }

  componentWillUnmount() {
    clearInterval(this.intervalRefreshNotification);
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  render() {
    return (
      <li className="dropdown cursorPointer">
        <Link className="f-s-14" to={ROUTES.NOTIFICATION} role="alert">
          <i className="fa fa-bell" />
          {this.props.notificationsCount && <span className="label">{this.props.notificationsCount}</span>}
        </Link>
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  notificationsCount: (state.notifications.list || []).reduce((acum, notif) => {
    if (!notif.readed) acum += 1;
    return acum;
  }, 0) || '',
});

const mapDispatchToProps = (dispatch) => ({
  getNotifications: () => dispatch(getNotifications()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DropdownNotification);
