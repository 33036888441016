import RestService from '../services/rest';

// GET
async function getAddressTransferWaveList() {
  return RestService.getAuthenticated('addressTransferWave/list/all');
}

async function getAddressTransferWaves() {
  return RestService.getAuthenticated('addressTransferWave/list');
}

async function getAddressTransferWavesByBaptism(branch, baptism, validateStatusStarted = false, isDetail) {
  return RestService.getAuthenticated('addressTransferWave/byBaptism', {
    branch, baptism, validateStatusStarted, isDetail,
  });
}

async function getAddressTransferWave(addressTransferWaveId) {
  return RestService.getAuthenticated('addressTransferWave/detail', { addressTransferWaveId });
}

// POST
async function createAddressTransferWave(addressTransferWave) {
  return RestService.postAuthenticated('addressTransferWave/create', addressTransferWave);
}

async function transferAddressTransferWaveProduct(addressTransferWaveProduct) {
  return RestService.postAuthenticated('addressTransferWave/transfer', addressTransferWaveProduct);
}

async function createAddressTransferWaveProduct(addressTransferWaveProduct) {
  return RestService.postAuthenticated('addressTransferWave/createProduct', addressTransferWaveProduct);
}

async function deleteAddressTransferWave(addressTransferWaveId) {
  return RestService.postAuthenticated('addressTransferWave/delete', { addressTransferWaveId });
}

async function deleteAddressTransferWaveProduct(addressTransferWaveProductId) {
  return RestService.postAuthenticated('addressTransferWave/deleteProduct', { addressTransferWaveProductId });
}

// PUT
async function cancelAddressTransferWave(addressTransferWave) {
  return RestService.putAuthenticated('addressTransferWave/cancel', addressTransferWave);
}

export default {
  getAddressTransferWaveList,
  createAddressTransferWave,
  createAddressTransferWaveProduct,
  cancelAddressTransferWave,
  getAddressTransferWave,
  deleteAddressTransferWave,
  deleteAddressTransferWaveProduct,
  getAddressTransferWavesByBaptism,
  transferAddressTransferWaveProduct,
  getAddressTransferWaves,
};
