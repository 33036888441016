import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import DualList from '../../components/pages/DualList';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

import Select from 'react-select';

class ReportStockMovementAdress extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
                </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE32' /* Estoque */) },
      { value: I18n.t('BEE754' /* Movimento Estoque x Endereço */), active: true },
    ];

    this.options = [
      // {
      //     label: I18n.t('BEE489' /* Saldo Estoque */),
      //     value: 'stockBalanceId',
      // },
      {
        label: I18n.t('BEE524' /* Operação */),
        value: 'operation',
      }, {
        label: I18n.t('BEE200' /* Tipo */),
        value: 'type',
      }, {
        label: I18n.t('BEE409' /* Código da Filial */),
        value: 'branchCode',
      }, {
        label: I18n.t('BEE430' /* Código do Armazém */),
        value: 'warehouseCode',
      }, {
        label: I18n.t('BEE431' /* Código do Depósito */),
        value: 'depositCode',
      }, {
        label: I18n.t('BEE432' /* Endereço */),
        value: 'addressCode',
      }, {
        label: I18n.t('BEE225' /* Produto */),
        value: 'productCode',
      }, {
        label: I18n.t('BEE428' /* Lote */),
        value: 'lotNumber',
      }, {
        label: I18n.t('BEE2398' /* Data de Validade */),
        table: 'stockBalance',
        value: 'expirationDate'
      }, {
        label: I18n.t('BEE441' /* Quantidade */),
        value: 'quantity',
      }, {
        label: I18n.t('BEE29' /* Unidades de Medida */),
        value: 'unitMeasure',
      }, {
        label: I18n.t('BEE523' /* Data Movimento */),
        value: 'movementDate',
      }, {
        label: I18n.t('BEE522' /* Usuário Movimento */),
        value: 'movementUser',
      }, {
        label: I18n.t('BEE403' /* Série */),
        value: 'serie',
      }, {
        label: I18n.t('BEE760' /* Número da Ordem */),
        value: 'orderNumber',
      }, {
        label: I18n.t('BEE342' /* Fornecedor */),
        value: 'supplierCode',
      }, {
        label: I18n.t('BEE50' /* Cliente */),
        value: 'customerCode',
      }, {
        label: I18n.t('BEE135' /* Observação */),
        value: 'note',
      }, {
        label: I18n.t('BEE2978' /* Agrupadora */),
        value: 'serialGroupCode',
      }, {
        label: I18n.t('BEE575' /* Usuário de criação */),
        value: 'createdUser',
      }, {
        label: I18n.t('BEE578' /* Data de criação */),
        value: 'createdAt',
      }, {
        label: I18n.t('BEE576' /* Usuário de alteração */),
        value: 'updatedUser',
      }, {
        label: I18n.t('BEE577' /* Data de alteração */),
        value: 'updatedAt',
      },
    ];

    this.state = {
      selected: [],
      type: '',
      branchCodeFrom: '',
      branchCodeTo: '',
      warehouseCodeFrom: '',
      warehouseCodeTo: '',
      depositCodeFrom: '',
      depositCodeTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      noteFrom: '',
      noteTo: '',
      addressCodeFrom: '',
      addressCodeTo: '',
      lotNumberFrom: '',
      lotNumberTo: '',
      serialGroupFrom: '',
      serialGroupTo: '',
      movementDateFrom: '',
      movementDateTo: '',
      selectedDocTypes: [],
      docTypesCodes: [],
    };
  }

  componentDidMount() {
    this.getDocTypes();
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      type,
      branchCodeFrom,
      branchCodeTo,
      warehouseCodeFrom,
      warehouseCodeTo,
      depositCodeFrom,
      depositCodeTo,
      productCodeFrom,
      productCodeTo,
      noteFrom,
      noteTo,
      addressCodeFrom,
      addressCodeTo,
      lotNumberFrom,
      lotNumberTo,
      serialGroupFrom,
      serialGroupTo,
      movementDateFrom,
      movementDateTo,
      selectedDocTypes,
      docTypesCodes,
    } = this.state;
    const { location } = this.props;
    const operation = [];

    selectedDocTypes != '' ? selectedDocTypes.map((selected) => (operation.push(selected.value))) : docTypesCodes.map((selected) => (operation.push(selected.value)));

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['type', { type: 'and', value: type }],
      ['operation', { type: 'in', value: operation }],
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['warehouseCode', { type: 'between', value: [warehouseCodeFrom, warehouseCodeTo] }],
      ['depositCode', { type: 'between', value: [depositCodeFrom, depositCodeTo] }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['note', { type: 'between', value: [noteFrom, noteTo] }],
      ['addressCode', {
        type: 'between',
        value: [addressCodeFrom.toLocaleUpperCase(), addressCodeTo.toLocaleUpperCase()],
      }],
      ['lotNumber', { type: 'between', value: [lotNumberFrom, lotNumberTo] }],
      ['$code', {
        type: 'between',
        value: [serialGroupFrom, serialGroupTo],
        instance: 'SerialGroup',
        as: 'serialGroup',
      }],
      ['movementDate', { type: 'between', value: [movementDateFrom, movementDateTo] }],
    ]);

    this.props.generateReport(I18n.t('BEE3354' /* Movimento Estoque */).toLowerCase(), 'xlsx', location.pathname, headers, filters);
  };

  onSelectFilter = (newState) => this.setState({ ...newState });

  handleDocTypesSelected = async (event) => {
    const selectedDocTypes = [];
    event.map((element) => (
      selectedDocTypes.push({ value: element.value, label: element.label })
    ));

    this.setState({
      selectedDocTypes,
    });
  };

  getDocTypes = async () => {
    const docTypesCodes = [{value: 'IMP', label: `IMP - ${I18n.t('BEE3380' /* Importação */)}`},
                           {value: 'IND', label: `IND - ${I18n.t('BEE2771' /* Indústria */)}`},
                           {value: 'LCQ', label: `LCQ - ${I18n.t('BEE3381' /* Liberação do Controle de Qualidade */)}`},
                           {value: 'TRA', label: `TRA - ${I18n.t('BEE34' /* Transferência */)}`},
                           {value: 'EXP', label: `EXP - ${I18n.t('BEE41' /* Expedição */)}`},
                           {value: 'INV', label: `INV - ${I18n.t('BEE572' /* Inventário */)}`},
                           {value: 'DEV', label: `DEV - ${I18n.t('BEE39' /* Devolução */)}`},
                           {value: 'REC', label: `REC - ${I18n.t('BEE3382' /* Conferência, Armazenamento, processo de recebimento */)}`}];

    this.setState({
      docTypesCodes,
    });
  };

  render() {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      type,
      warehouseCodeFrom,
      warehouseCodeTo,
      depositCodeFrom,
      depositCodeTo,
      productCodeFrom,
      productCodeTo,
      noteFrom,
      noteTo,
      addressCodeFrom,
      addressCodeTo,
      lotNumberFrom,
      lotNumberTo,
      serialGroupFrom,
      serialGroupTo,
      movementDateFrom,
      movementDateTo,
      docTypesCodes,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE754' /* Movimento Estoque x Endereço */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: warehouseCodeFrom, to: warehouseCodeTo },
                    { from: 'warehouseCodeFrom', to: 'warehouseCodeTo' },
                    `${I18n.t('BEE156' /* Armazém */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: depositCodeFrom, to: depositCodeTo },
                    { from: 'depositCodeFrom', to: 'depositCodeTo' },
                    `${I18n.t('BEE182' /* Depósito */)}:`,
                    { from: '', to: 'Z'.repeat(100) },
                  ),
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE225' /* Produto */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: noteFrom, to: noteTo },
                    { from: 'noteFrom', to: 'noteTo' },
                    `${I18n.t('BEE758' /* Descrição do Produto */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: addressCodeFrom.toLocaleUpperCase(), to: addressCodeTo.toLocaleUpperCase() },
                    { from: 'addressCodeFrom', to: 'addressCodeTo' },
                    `${I18n.t('BEE432' /* Endereço */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: lotNumberFrom, to: lotNumberTo },
                    { from: 'lotNumberFrom', to: 'lotNumberTo' },
                    `${I18n.t('BEE428' /* Lote */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: serialGroupFrom, to: serialGroupTo },
                    { from: 'serialGroupFrom', to: 'serialGroupTo' },
                    `${I18n.t('BEE2978' /* Agrupadora */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: movementDateFrom, to: movementDateTo },
                    { from: 'movementDateFrom', to: 'movementDateTo' },
                    `${I18n.t('BEE523' /* Data Movimento */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE201' /* Entrada */),
                      value: '1',
                    },
                    {
                      label: I18n.t('BEE202' /* Saída */),
                      value: '2',
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], type, 'type', I18n.t('BEE200' /* Tipo */)),

                  <div className="row" style={{ justifyContent: 'space-between' }}>
                    <label htmlFor="label" style={{ marginTop: 'auto', marginBottom: 'auto', paddingLeft: '10px' }}>
                      {`${I18n.t('BEE524' /* Operação */)}:`}
                    </label>
                    <div className="col-10" style={{ marginLeft: 'auto', marginRight: '2.5vw' }}>
                      <Select
                        value={this.state.selectedDocTypes}
                        isMulti
                        onChange={(event) => this.handleDocTypesSelected(event || [])}
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        isSearchable
                        styles={{
                          control: (defaultStyles) => ({
                            ...defaultStyles,
                            minHeight: '38px',
                            border: '1px solid #ccc',
                            boxShadow: 'none',
                            color: '#ccc',
                            backgroundColor: '#fff',
                            borderRadius: '5px',
                            fontFamily: 'Open Sans", sans-serif',
                            fontWeigth: '400',
                          }),
                          placeholder: (defaultStyles) => ({
                            ...defaultStyles,
                            color: '#333',
                            fontSize: '12px',
                          }),
                          dropdownIndicator: (defaultStyles) => ({
                            ...defaultStyles,
                            paddingTop: 0,
                            paddingBottom: 0,
                            color: '#ccc',
                          }),
                          clearIndicator: (defaultStyles) => ({
                            ...defaultStyles,
                            paddingTop: 0,
                            paddingBottom: 0,
                          }),
                        }}
                        maxMenuHeight={300}
                        placeholder={I18n.t('BEE3379' /* Todas as operações estão selecionadas. */)}
                        options={docTypesCodes}
                      />
                    </div>
                  </div>
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  docTypes: state.ordersTypes && state.ordersTypes.orderTypeList,
});

const mapDispatchToProps = (dispatch) => ({
  generateReport: (nameDoc, type, route, columns, filters) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportStockMovementAdress);
