import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { PageSettings } from '../config/page-settings';
import AuthService from '../app/services/auth';

class PageNotFound extends React.PureComponent {
  static contextType = PageSettings;

  componentDidMount() {
    if (!AuthService.isAuthenticated()) {
      this.context.handleSetPageSidebar(false);
      this.context.handleSetPageHeader(false);
      this.context.handleSetBodyWhiteBg(true);
    }
  }

  componentWillUnmount() {
    if (!AuthService.isAuthenticated()) {
      this.context.handleSetPageSidebar(true);
      this.context.handleSetPageHeader(true);
      this.context.handleSetBodyWhiteBg(false);
    }
  }

  render() {
    return (
      <div className="error" style={{ height: 'auto' }}>
        <div>
          <div className="error-code m-b-10">404</div>
          <div className="error-content" style={{ flex: 1 }}>
            <div className="error-message">{I18n.t('BEE526' /* Página não encontrada... */)}</div>
            <div className="error-desc">
              {I18n.t('BEE527' /* A página procurada não existe ou o conteúdo não está disponível por falta de permissão */)} <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PageNotFound);