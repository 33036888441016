
import TransportEquipmentRequests from '../../server/transportEquipment';
import { addLoading, removeLoading } from './loading';

export const setTransportEquipments = transportEquipment => ({
  type: 'SET_TRANSPORT_EQUIPMENTS',
  transportEquipment,
});

export const getTransportEquipmentsList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const transportEquipment = await TransportEquipmentRequests.getTransportEquipments();
    dispatch(setTransportEquipments(transportEquipment));
  } finally {
    dispatch(removeLoading());
  }
};

export const createTransportEquipment = newTransportEquipment => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await TransportEquipmentRequests.createTransportEquipment(newTransportEquipment);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateTransportEquipment = (updTransportEquipment) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await TransportEquipmentRequests.updateTransportEquipment(updTransportEquipment);
  } finally {
    dispatch(removeLoading());
  }
};

export const getTransportEquipment = (transportEquipmentId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await TransportEquipmentRequests.getTransportEquipment(transportEquipmentId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteTransportEquipment = (transportEquipmentId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await TransportEquipmentRequests.deleteTransportEquipment(transportEquipmentId);
  } finally {
    dispatch(removeLoading());
  }
};
