import RestService from '../services/rest';

// GET
async function getAllCurves() {
  return RestService.getAuthenticated('curves/list/all');
}

async function getCurve(curveId) {
  return RestService.getAuthenticated('curves/list/one', { curveId });
}

// POST
async function createCurve(curve) {
  return RestService.postAuthenticated('curves/create', curve);
}

// PUT
async function updateCurve(curve) {
  return RestService.putAuthenticated('curves/update', curve);
}

// DELETE
async function deleteCurve(curveId, code) {
  return RestService.deleteAuthenticated('curves/delete', { curveId, code });
}

export default {
  getAllCurves,
  getCurve,
  createCurve,
  updateCurve,
  deleteCurve,
};
