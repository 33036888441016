import RestService from '../services/rest';

// GET
async function getUnitsMeasure() {
  return RestService.getAuthenticated('units/list/all');
}

async function getUnitMeasure(unitMeasureId) {
  return RestService.getAuthenticated(`units/detail?unitMeasureId=${unitMeasureId}`);
}

async function getUnitsMeasureOptions() {
  return RestService.getAuthenticated('units/list/options');
}

// POST
async function createUnitMeasure(unitMeasure) {
  return RestService.postAuthenticated('units/create', unitMeasure);
}

async function deleteUnitMeasure(unitMeasureId) {
  return RestService.postAuthenticated('units/delete', { unitMeasureId });
}

// PUT
async function updateUnitMeasure(unitMeasure) {
  return RestService.putAuthenticated('units/update', unitMeasure);
}

export default {
  getUnitsMeasure,
  createUnitMeasure,
  updateUnitMeasure,
  getUnitMeasure,
  getUnitsMeasureOptions,
  deleteUnitMeasure
};
