/* eslint-disable no-restricted-syntax */
import React from 'react';
import ReactDOM from 'react-dom';
import ReactNotification from 'react-notifications-component';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import Loading from './components/loading/loading';

import initialization from './app/initialization';
import { store } from './config/configureStore';

import 'react-notifications-component/dist/theme.css';

// css
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap-social/bootstrap-social.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './index.css';
import './scss/fonts/logo.scss';
import './scss/styles.scss';
import './scss/transitions/slide.scss';
import server from './data/server';

const URLS = [];

for (const key in server.serverUrls) {
  if (Object.prototype.hasOwnProperty.call(server.serverUrls, key)) {
    const element = server.serverUrls[key];
    URLS.push(element);
  }
}

// ========================================

const MainAppRef = React.createRef();
initialization(store, MainAppRef);

class MainApp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mountedApp: false,
    };
  }

  mountApp = () => this.setState({ mountedApp: true });

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ReactNotification />
          <Loading />
          {this.state.mountedApp && <App />}
        </BrowserRouter>
      </Provider>
    );
  }
}

ReactDOM.render(<MainApp ref={MainAppRef} />, document.getElementById('root'));
