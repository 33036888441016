import { I18n } from 'react-redux-i18n';

function outboundRangeStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE1365' /* Pendente Separação */);
      case 2:
        return I18n.t('BEE1367' /* Em Separação */);
      case 3:
        return I18n.t('BEE1948' /* Pendente Conferência */);
      case 4:
        return I18n.t('BEE1833' /* Finalizado */);
      case 5:
        return I18n.t('BEE176' /* Divergência */);
      case 6:
        return I18n.t('BEE1899' /* Devolvido */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE1365' /* Pendente Separação */) },
      { value: 2, label: I18n.t('BEE1367' /* Em Separação */) },
      { value: 3, label: I18n.t('BEE1948' /* Pendente Conferência */) },
      { value: 4, label: I18n.t('BEE1833' /* Finalizado */) },
      { value: 5, label: I18n.t('BEE176' /* Divergência */) },
      { value: 6, label: I18n.t('BEE1899' /* Devolvido */) },
    ];
  }
}

function outboundRangeProductStatus(status) {
  switch (status) {
    case 1:
      return I18n.t('BEE1947' /* Pendente Corte de Cabos */);
    case 2:
      return I18n.t('BEE1365' /* Pendente Separação */);
    case 3:
      return I18n.t('BEE1367' /* Em Separação */);
    case 4:
      return I18n.t('BEE1948' /* Pendente Conferência */);
    case 5:
      return I18n.t('BEE1833' /* Finalizado */);
    case 6:
      return I18n.t('BEE176' /* Divergência */);
    case 7:
      return I18n.t('BEE64' /* Cancelado */);
    case 8:
      return I18n.t('BEE1899' /* Devolvido */);
    default:
      return status;
  }
}

function outboundRangeSeparationType(status) {
  switch (status) {
    case 1:
      return I18n.t('BEE1446' /* Separação por Onda */);
    case 2:
      return I18n.t('BEE1447' /* Separação por Documento */);
    default:
      return status;
  }
}

export default {
  outboundRangeStatus,
  outboundRangeProductStatus,
  outboundRangeSeparationType,
};
