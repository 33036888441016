import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../components/panel/panel';
import ProductList from './productsList';

import ROUTES from '../../../config/routes';

import {
  getInboundOrder,
} from '../../../app/store/actions/inboundOrders';

import InboundOrderHelpers from '../../../helpers/inboundOrders';

class InboundOrderForm extends React.Component {
  constructor(props) {
    super(props);

    // Atributo para definir que o botão "Voltar" na tela de Detalhes irá voltar ao componente de Consulta
    this.isBackConsult = this.props.backConsult;

    this.state = {
      activeTab: '1',
      mode: 'detail',
      branchCode: '',
      branchName: '',
      supplierCode: '',
      supplierName: '',
      orderType: '',
      orderNumber: '',
      accessKey: '',
      carrierCode: '',
      carrierName: '',
      licensePlate: '',
      cfop: '',
      postalCode: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
      deliveryDate: '',
      expectedVolumes: '',
      note: '',
      status: '',
      products: [],
    };
  }

  async componentDidMount() {
    await this.getInboundOrderSelected();
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  getInboundOrderSelected = async () => {
    if (this.props.location.state.inboundOrder) {
      const { inboundOrder } = this.props.location.state;

      const inboundOrderSelected = await this.props.getInboundOrder(inboundOrder.id);

      if (inboundOrderSelected) {
        this.setState({
          inboundOrderSelected,
          inboundOrderId: inboundOrderSelected.id,
          branchCode: inboundOrderSelected.branchCode,
          branchName: inboundOrderSelected.branchName,
          supplierCode: inboundOrderSelected.supplierCode,
          supplierName: inboundOrderSelected.supplierName,
          orderType: inboundOrderSelected.orderType,
          orderNumber: inboundOrderSelected.orderNumber,
          accessKey: inboundOrderSelected.accessKey,
          carrierCode: inboundOrderSelected.carrierCode,
          carrierName: inboundOrderSelected.carrierName,
          licensePlate: inboundOrderSelected.licensePlate,
          cfop: inboundOrderSelected.cfop,
          postalCode: inboundOrderSelected.postalCode,
          street: inboundOrderSelected.street,
          number: inboundOrderSelected.number,
          complement: inboundOrderSelected.complement,
          district: inboundOrderSelected.district,
          city: inboundOrderSelected.city,
          state: inboundOrderSelected.state,
          country: inboundOrderSelected.country,
          deliveryDate: inboundOrderSelected.deliveryDate,
          expectedVolumes: inboundOrderSelected.expectedVolumes,
          note: inboundOrderSelected.note,
          status: inboundOrderSelected.status,
          products: inboundOrderSelected.products,
        });
      }
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createNumberFormat = (value, attr, label, decimals, disabled = false, keyDownFunction = undefined, id = null) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <NumberFormat
          onKeyDown={keyDownFunction && ((e) => keyDownFunction(e, id, attr))}
          id={id && id}
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={decimals}
          value={value || ''}
          onValueChange={(values) => {
            this.setValue(attr, values.floatValue);
          }}
          fixedDecimalScale
          defaultValue={0}
          allowEmptyFormatting
          disabled={disabled}
        />
      </div>
    </div>
  );

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  handleRouteToReturn = () => {
    if (this.props.location.state.dashboardRoute === 'inboundOrder') {
      return {
        pathname: ROUTES.INBOUND_ORDERS_DASHBOARD,
        state: {
          isRouteReturn: true,
        },
      };
    }
    if (this.props.location.state.dashboardRoute === 'receiptOverview') {
      return {
        pathname: ROUTES.INBOUND_ORDERS_DASHBOARD_OVERVIEW,
        state: {
          isRouteReturn: true,
        },
      };
    }

    if (this.props.location.state.dashboardRoute === 'averageTimes') {
      return {
        pathname: ROUTES.INBOUND_ORDERS_DASHBOARD_AVERAGE_TIME,
        state: {
          isRouteReturn: true,
        },
      };
    }

    if (this.isBackConsult) {
      return ROUTES.CONSULT_INBOUND_LIST;
    }

    return ROUTES.INBOUND_ORDER_LIST;
  };

  render() {
    const {
      branchName, supplierName, orderType, orderNumber, accessKey,
      carrierName, licensePlate, cfop, deliveryDate, expectedVolumes, note, status, products = [],
    } = this.state;

    const title = 'Detalhes Documento de Entrada';

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE36' /* Recebimento */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE438' /* Documentos de Entrada */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel className="panel-with-tabs">
                <PanelHeader noButton />
                <PanelBody>
                  <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '1' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('1');
                        }}
                      >
                        <span className="d-sm-none">Tab 1</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE443' /* Documento */)}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '2' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('2');
                        }}
                      >
                        <span className="d-sm-none">Tab 2</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE27' /* Produtos */)}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="row">
                        <div className="col-xl-12">
                          {this.createInput(branchName, 'branchCode', `${I18n.t('BEE145' /* Filial */)}:`, '', 'text', true, true)}
                          {this.createInput(supplierName, 'supplierCode', `${I18n.t('BEE342' /* Fornecedor */)}:`, '', 'text', true, true)}
                          {this.createInput(orderType, 'orderType', `${I18n.t('BEE200' /* Tipo */)}:`, '', 'text', true, true)}
                          {this.createInput(orderNumber, 'orderNumber', `${I18n.t('BEE1027' /* Número do documento */)}:`, '', 'text', true, true)}
                          {this.createInput(accessKey, 'accessKey', `${I18n.t('BEE967' /* Chave de acesso */)}:`, '', 'text', true, true)}
                          {this.createInput(carrierName, 'carrierCode', `${I18n.t('BEE301' /* Transportadora */)}:`, '', 'text', true, true)}
                          {this.createInput(licensePlate, 'licensePlate', `${I18n.t('BEE913' /* Placa */)}:`, '', 'text', true, true)}
                          {this.createInput(cfop, 'cfop', `${I18n.t('BEE1030' /* CFOP */)}:`, '', 'text', true, true)}
                          {this.createInput(`${moment(deliveryDate).format('DD/MM/YYYY')}`, 'deliveryDate', `${I18n.t('BEE444' /* Data de Entrega */)}:`, '', 'text', true, true)}
                          {this.createNumberFormat(expectedVolumes, 'expectedVolumes', `${I18n.t('BEE1029' /* Volumes Esperados */)}:`, 0, true)}
                          {this.createInput(InboundOrderHelpers.inboundOrderStatus(status), 'status', `${I18n.t('BEE224' /* Status */)}:`, '', 'text', true, true)}
                          {this.createTextArea(note, 'note', `${I18n.t('BEE135' /* Observação */)}:`, I18n.t('BEE764' /* Informe a observação do produto */), 3, true, true)}
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="row">
                        <div className="col-xl-12">
                          <ProductList
                            products={products}
                          />
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </PanelBody>
                <PanelFooter>
                  {
                    <Link to={this.handleRouteToReturn}>
                      <button type="submit" className="btn btn-120 btn-white p-5 m-5">
                        {I18n.t('BEE137' /* Voltar */)}
                      </button>
                    </Link>
                  }
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getInboundOrder: (inboundOrderId) => dispatch(getInboundOrder(inboundOrderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InboundOrderForm));
