import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';
import { getWaybillsByDate, syncWaybillErp } from '../../../app/store/actions/waybills';
import addNotification from '../../../components/notification';
import { Panel } from '../../../components/panel/panel';
import Table from '../../../components/table/Table';
import WaybillsHelpers from '../../../helpers/waybills';
import OutboundOrdersTable from './outboundOrdersTable';
import ROUTES from '../../../config/routes';

import { generateReport } from '../../../app/store/actions/reports';

class OutboundOrderWaveMonitor extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE1369' /* Romaneios */), active: true },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">
              {I18n.t('BEE55' /* Ações */)}
            </button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                {rows.row.status < 2 && (
                  <DropdownItem onClick={() => this.syncErp(rows.original)}>
                    {I18n.t('BEE3205' /* Integrar ERP */)}
                  </DropdownItem>
                )}
                <DropdownItem onClick={() => this.onGenerateWaybillPDF(rows.original)}>
                  {I18n.t('BEE3204' /* Imprimir romaneio */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE907' /* Romaneio */),
        accessor: 'waybillNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierBusinessName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE199' /* Doca */),
        accessor: 'dockControl.dockCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{WaybillsHelpers.waybillStatus(row.value)}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {WaybillsHelpers.waybillStatus().map((element) => (
              <option
                key={element.value}
                value={element.value}
              >
                {element.label}
              </option>
            ))}
          </select>
        ),
      },
      {
        Header: I18n.t('BEE2615' /* Impresso */),
        accessor: 'printed',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{WaybillsHelpers.waybillPrintedStatus(row.original.printed)}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {WaybillsHelpers.waybillPrintedStatus().map((el) => (
              <option
                key={el.value}
                value={el.value}
              >
                {el.label}
              </option>
            ))}
          </select>
        ),
      },
    ];

    this.state = {
      waybillsList: [],
      selectedWaybill: {},
      defaultPageSize: 10,
      defaultSorted: [{ id: 'waybillNumber', desc: false }],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
    };
  }

  componentDidMount() {
    this.getWaybillsByDate();
  }

  getWaybillsByDate = async () => {
    const { startDate, endDate } = this.state;

    try {
      const waybillsList = await this.props.getWaybillsByDate(
        moment(startDate).format(),
        moment(endDate).format(),
      );

      if (waybillsList && waybillsList.success === false) {
        addNotification('danger', I18n.t('BEE1369' /* Romaneios */), I18n.t('BEE3238' /* Nenhum Romaneio foi localizado */), 'top-right');
      } else {
        this.setState({ waybillsList });
      }
    } catch (error) {
      const messageError = error.response && error.response.data
        && error.response.data.error && error.response.data.error.message;

      if (messageError) addNotification('danger', I18n.t('BEE1369' /* Romaneios */), messageError, 'top-right');
      else addNotification('danger', I18n.t('BEE1369' /* Romaneios */), I18n.t('BEE3238' /* Nenhum Romaneio foi localizado */), 'top-right');
    }
  };

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });

    this.getWaybillsByDate();
  };

  waybillOrders = async (selectedWaybill) => {
    this.setState({
      selectedWaybill,
      waybillOrders: selectedWaybill.waybillDocuments,
    });
  };

  syncErp = async (waybill) => {
    try {
      const result = await this.props.syncWaybillErp(waybill.id);

      if (result && !result.length) {
        addNotification('danger', I18n.t('BEE1369' /* Romaneios */), I18n.t('BEE3236' /* Erro de Integração ERP */), 'top-right');
      } else {
        addNotification('success', I18n.t('BEE1369' /* Romaneios */), I18n.t('BEE3235' /* Integração realizada com sucesso! */), 'top-right');
        await this.getWaybillsByDate();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1369' /* Romaneios */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1369' /* Romaneios */),
            'Erro Integração ERP',
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1369' /* Romaneios */),
          'Erro Integração ERP',
          'top-right',
        );
      }
    }
  };

  onGenerateWaybillPDF = async (waybill) => {
    const { location } = this.props;
    const idWaybillPDF = waybill.id;

    await this.props.generateReport(
      I18n.t('BEE907' /* Romaneio */),
      'pdf',
      location.pathname,
      [{}],
      { id: idWaybillPDF },
    );
    await this.getWaybillsByDate();
  };

  render() {
    const {
      defaultPageSize,
      defaultSorted,
      waybillsList,
      selectedWaybill,
      waybillOrders,
    } = this.state;

    return (
      <>
        <Table
          breadcrumb={this.breadcrumb}
          headerTitle={I18n.t('BEE1369' /* Romaneios */)}
          wikiHelp={ROUTES.WAYBILL_MONITOR_HELP}
          downloadCSV
          panelHeaderProps={{
            onClickReload: this.getWaybillsByDate,
            children: I18n.t('BEE907' /* Romaneio */).toUpperCase(),
          }}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          filterable
          expander
          data={waybillsList}
          columns={this.tableColumns}
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: async () => {
                  await this.waybillOrders(rowInfo.original);
                },
                style: {
                  background:
                    selectedWaybill
                      && rowInfo.original.id === selectedWaybill.id
                      ? 'silver'
                      : 'white',
                  cursor: 'pointer',
                },
              };
            }
            return {};
          }}
        />
        <Panel>
          <OutboundOrdersTable
            getWaybillsByDate={() => this.getWaybillsByDate()}
            waybillOrdersList={waybillOrders}
          />
        </Panel>
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getWaybillsByDate: (filterStartDate, filterEndDate) => dispatch(getWaybillsByDate(filterStartDate, filterEndDate)),
  syncWaybillErp: (waybillId) => dispatch(syncWaybillErp(waybillId)),
  generateReport: (nameDoc, type, route, columns, filters) => dispatch(generateReport(nameDoc, type, route, columns, filters, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundOrderWaveMonitor));
