import React from 'react';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import OneClickButton from '../../../../components/form/button';
import Table from '../../../../components/table/Table';

export default class StorageAddressTable extends React.Component {
  constructor(props) {

    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.state = {
      pages: 0,
      totalDataLength: 0,
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE279' /* Setor */),
        accessor: 'sector',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE121' /* Rua */),
        accessor: 'street',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE281' /* Coluna */),
        accessor: 'column',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE283' /* Nível */),
        accessor: 'level',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE285' /* Gaveta */),
        accessor: 'drawer',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        Filter: this.filterColumn,
      },
    ];

    this.actionColumn = {
      Header: I18n.t('BEE55' /* Ações */),
      accessor: 'action',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      width: 100,
      Cell: (rows) => (
        <div style={{ textAlign: 'center' }}>
          <button
            type="button"
            className="btn btn-default btn-xs"
            onClick={() => this.props.selectAddress(rows.row._original)}
          >
            {I18n.t('BEE608' /* Desvincular */)}

          </button>
        </div>
      ),
    };
  }

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getRangeAddress = async () => {
    const data = await this.props.getRangeAddress({
      rangeCode: this.props.rangeCode,
      page: this.page,
      pageSize: this.pageSize,
      filtered: this.filtered,
      sorted: this.sorted,
    });
    if (data) {
      this.setState({
        pages: data.pages, totalDataLength: data.totalDataLength,
      });
    }
  };

  render() {
    const {
      storageAddresses, title, showAddressLinkBtn,
      openAddressLinkModal, openAddressUnlinkModal, rangeAddressList = [],
    } = this.props;

    const { pages = 0, totalDataLength = 0 } = this.state;

    const columns = showAddressLinkBtn
      ? [this.actionColumn, ...this.tableColumns]
      : this.tableColumns;

    return (
      <div className="table-responsive">
        {this.props.executeFunction === true
          ? (
            <Table
              headerTitle={title}
              data={rangeAddressList}
              totalDataLength={totalDataLength}
              columns={columns}
              expander
              filterable
              panelHeaderProps={{ noButton: true }}
              defaultPageSize={this.pageSize}
              defaultSorted={this.defaultSorted}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
              manual
              pages={pages}
              onFetchData={(state, instance) => {
                this.page = state.page;
                this.pageSize = state.pageSize;
                this.filtered = state.filtered;
                this.sorted = state.sorted;
                this.getRangeAddress();
              }}
              actionButtons={(
              showAddressLinkBtn
              && (
              <div className="ml-auto">
                <OneClickButton
                  type="button"
                  className="btn btn-240 btn-danger btn-rounded pr-3 mr-3"
                  onClick={() => openAddressUnlinkModal()}
                >
                  <i className="fa fa-unlink mr-1" />
                  {I18n.t('BEE607' /* Desnvincular Endereços */)}

                </OneClickButton>
                <OneClickButton
                  type="button"
                  className="btn btn-240 btn-success btn-rounded pr-3"
                  onClick={() => openAddressLinkModal()}
                >
                  <i className="fa fa-link mr-1" />
                  {I18n.t('BEE595' /* Gerar vínculo com endereços */)}

                </OneClickButton>
              </div>
              )
            )}
            />
          )
          : (
            <Table
              headerTitle={title}
              data={storageAddresses}
              columns={columns}
              expander
            // filterable
              panelHeaderProps={{ noButton: true }}
              defaultPageSize={this.props.lines ? this.props.lines : 10}
              defaultSorted={this.defaultSorted}
            // defaultFilterMethod={(filter, row) => {
            //   const input = _.lowerCase(filter.value);
            //   const value = _.lowerCase(row[filter.id]);
            //   if (_.includes(value, input)) {
            //     return true;
            //   }
            // }}
              actionButtons={(
              showAddressLinkBtn
              && (
              <div className="ml-auto">
                <OneClickButton
                  type="button"
                  className="btn btn-240 btn-danger btn-rounded pr-3 mr-3"
                  onClick={() => openAddressUnlinkModal()}
                >
                  <i className="fa fa-unlink mr-1" />
                  {I18n.t('BEE607' /* Desnvincular Endereços */)}
                </OneClickButton>
                <OneClickButton
                  type="button"
                  className="btn btn-240 btn-success btn-rounded pr-3"
                  onClick={() => openAddressLinkModal()}
                >
                  <i className="fa fa-link mr-1" />
                  {I18n.t('BEE595' /* Gerar vínculo com endereços */)}
                </OneClickButton>
              </div>
              )
            )}
            />
          )}
      </div>
    );
  }
}
