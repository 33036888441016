const INITIAL_STATE = {
  balancesList: [],
  movementsList: [],
  allStockBalancesList: [],
  productsStockBalanceList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_STOCK_BALANCES': {
      return { ...state, balancesList: action.stockBalances };
    }
    case 'SET_STOCK_MOVEMENTS': {
      return { ...state, movementsList: action.stockMovements };
    }
    case 'SET_ALL_STOCK_BALANCES': {
      return { ...state, allStockBalancesList: action.allStockBalances };
    }
    case 'SET_PRODUCTS_STOCK_BALANCE': {
      return { ...state, productsStockBalanceList: action.productsStockBalance };
    }
    default:
      return state;
  }
};
