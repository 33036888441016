const INITIAL_STATE = {
  indirectTasksList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_INDIRECT_TASKS_LIST':
      return { ...state, indirectTasksList: action.indirectTasks };
    default:
      return state;
  }
};
