import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import {
  getCarrierDocks, linkDockCarrier, searchDocks, unlinkDockCarrier,
} from '../../../../app/store/actions/dockCarriers';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';
import ROUTES from '../../../../config/routes';

class CarrierLinkDocksForm extends React.Component {
  constructor(props) {
    super(props);

    this.tableDocksListColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => {
          const dockCode = rows.row._original.code;
          const isLinked = this.state.dockCarrierList.find((dc) => dc.dockCode === dockCode);

          if (isLinked) {
            return (
              <div style={{ textAlign: 'center' }}>
                <button
                  className="btn btn-default btn-xs"
                  onClick={() => this.unlinkDockCarrier(rows.row._original.code)}
                >
                  {I18n.t('BEE608' /* Desvincular */)}
                </button>
              </div>
            );
          }
          return (
            <div style={{ textAlign: 'center' }}>
              <button
                className="btn btn-default btn-xs"
                onClick={() => this.linkDockCarrier(rows.row._original.code)}
              >
                {I18n.t('BEE601' /* Vincular */)}
              </button>
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'dockType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('entrada', input)) {
            return id === 1;
          } if (_.includes('saida', input)) {
            return id === 2;
          } if (_.includes('ambos', input)) {
            return id === 3;
          }
        },
        Cell: (row) => (
          <span>
            {row.value === 1 ? I18n.t('BEE201' /* Entrada */) : row.value === 2 ? I18n.t('BEE202' /* Saída */) : I18n.t('BEE203' /* Ambos */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE204' /* Situação */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('liberada', input)) {
            return id === 1;
          } if (_.includes('ocupada', input)) {
            return id === 2;
          } if (_.includes('bloqueada', input)) {
            return id === 3;
          }
        },
        Cell: (row) => (
          <span>
            {row.value === 1 ? I18n.t('BEE205' /* Liberada */) : row.value === 2 ? I18n.t('BEE206' /* Ocupada */) : I18n.t('BEE207' /* Bloqueado */)}
          </span>
        ),
      },
    ];

    this.tableCarrierDocksColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs" onClick={() => { this.showUnlinkModal(rows.row._original); }}>{I18n.t('BEE608' /* Desvincular */)}</button>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'dockCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'dock.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'dock.dockType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('entrada', input)) {
            return id === 1;
          } if (_.includes('saida', input)) {
            return id === 2;
          } if (_.includes('ambos', input)) {
            return id === 3;
          }
        },
        Cell: (row) => (
          <span>
            {row.value === 1 ? I18n.t('BEE201' /* Entrada */) : row.value === 2 ? I18n.t('BEE202' /* Saída */) : I18n.t('BEE203' /* Ambos */)}
          </span>
        ),
      },
      {
        Header: I18n.t('BEE204' /* Situação */),
        accessor: 'dockStatus',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('liberada', input)) {
            return id === 1;
          } if (_.includes('ocupada', input)) {
            return id === 2;
          } if (_.includes('bloqueada', input)) {
            return id === 3;
          }
        },
        Cell: (row) => (
          <span>
            {row.value === 1 ? I18n.t('BEE205' /* Liberada */) : row.value === 2 ? I18n.t('BEE206' /* Ocupada */) : I18n.t('BEE207' /* Bloqueado */)}
          </span>
        ),
      },
    ];

    this.state = {
      carrierCode: null,
      carrierLabel: '',
      showAddModal: false,
      dockCarrierList: [],
      docksList: [],
      dockCarrierSelected: null,
      showUnlinkModal: false,
    };
  }

  async componentDidMount() {
    await this.getBaseInfo();
  }

  async getBaseInfo() {
    if (this.props.location.state.carrier) {
      const { carrier } = this.props.location.state;
      const carrierLabel = `${carrier.code} - ${carrier.name}`;

      const baseInfo = await this.props.getCarrierDocks(carrier.code);

      this.setState({
        carrierLabel,
        carrier,
        dockCarrierList: baseInfo.docks,
      });
    }
  }

  searchDocks = async () => {
    try {
      const docksList = await this.props.searchDocks();

      if (docksList.length) {
        this.setState({
          showAddModal: true,
          docksList,
        });
      } else {
        addNotification('danger', I18n.t('BEE1081' /* Vincular Docas */), I18n.t('BEE1159' /* Nenhum item encontrado! */), 'top-right');
      }
    } catch (error) {
      addNotification('danger', I18n.t('BEE1081' /* Vincular Docas */), I18n.t('BEE1156' /* Erro ao vincular doca! */), 'top-right');
    }
  };

  linkDockCarrier = async (dockCode) => {
    const carrierCode = this.state.carrier.code;
    const { carrierLabel } = this.state;

    try {
      await this.props.linkDockCarrier(carrierCode, dockCode);
      await this.getBaseInfo();

      addNotification('success', I18n.t('BEE1081' /* Vincular Docas */), I18n.t('BEE1155', { 0: dockCode, 1: carrierLabel } /* Doca %{0} vinculada com sucesso a transportadora %{1}! */), 'top-right');
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1081' /* Vincular Docas */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification('danger', I18n.t('BEE1081' /* Vincular Docas */), I18n.t('BEE1156' /* Erro ao vincular doca! */), 'top-right');
        }
      } else {
        addNotification('danger', I18n.t('BEE1081' /* Vincular Docas */), I18n.t('BEE1156' /* Erro ao vincular doca! */), 'top-right');
      }
    }
  };

  linkAll = async () => {
    const { docksList, dockCarrierList } = this.state;
    const unlinkedDocks = docksList.filter((d) => !dockCarrierList.find((dc) => dc.dockCode === d.code));

    for (const dock of unlinkedDocks) {
      await this.linkDockCarrier(dock.code);
    }
  };

  unlinkDockCarrier = async (dockCode) => {
    const carrierCode = this.state.carrier.code;
    const { carrierLabel, dockCarrierList } = this.state;

    const dockCarrierSelected = dockCarrierList.find(
      (dc) => dc.carrierCode === carrierCode && dc.dockCode === dockCode,
    );

    try {
      await this.props.unlinkDockCarrier(dockCarrierSelected.id);
      await this.getBaseInfo();

      addNotification('success', I18n.t('BEE1081' /* Vincular Docas */), I18n.t('BEE1157', { 0: dockCode, 1: carrierLabel } /* Doca %{0} desnviculada da transportadora %{1} */), 'top-right');
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1081' /* Vincular Docas */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification('danger', I18n.t('BEE1081' /* Vincular Docas */), I18n.t('BEE1156' /* Erro ao vincular doca! */), 'top-right');
        }
      } else {
        addNotification('danger', I18n.t('BEE1081' /* Vincular Docas */), I18n.t('BEE1156' /* Erro ao vincular doca! */), 'top-right');
      }
    }
  };

  closeAddModal = () => {
    this.setState({
      showAddModal: false,
      docksList: [],
    });
  };

  showUnlinkModal = (dockCarrierSelected) => {
    this.setState({
      dockCarrierSelected,
      showUnlinkModal: true,
    });
  };

  closeUnlinkModal = () => {
    this.setState({
      dockCarrierSelected: null,
      showUnlinkModal: false,
    });
  };

  confirmUnlinkModal = async () => {
    const { dockCarrierSelected } = this.state;
    this.closeUnlinkModal();
    await this.unlinkDockCarrier(dockCarrierSelected.dockCode);
  };

  render() {
    const {
      carrierLabel, showAddModal, dockCarrierList, docksList,
      showUnlinkModal, dockCarrierSelected,
    } = this.state;

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE24' /* Transportadoras */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1081' /* Vincular Docas */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE1081' /* Vincular Docas */)}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form onSubmit={(e) => e.preventDefault()}>
              <Table
                headerTitle={carrierLabel}
                actionButtons={(
                  <>
                    <div className="ml-auto btn btn-success btn-sm btn-rounded pl-2 pr-3" onClick={this.searchDocks}>
                      <i className="fa fa-link mr-1" />
                      {' '}
                      {I18n.t('BEE1081' /* Vincular Docas */)}
                    </div>
                    <Link to={{ pathname: ROUTES.CARRIER_LIST, state: { dice: this.props.location.dice } }}>
                      <button type="submit" className="btn btn-sm btn-danger btn-rounded ml-3">{I18n.t('BEE137' /* Voltar */)}</button>
                    </Link>
                  </>
                )}
                panelHeaderProps={{
                  onClickReload: () => this.getBaseInfo(),
                }}
                filterable
                data={dockCarrierList}
                columns={this.tableCarrierDocksColumns}
                expander
                defaultPageSize={10}
                defaultSorted={this.defaultSorted}
                defaultFilterMethod={(filter, row) => {
                  const input = _.lowerCase(filter.value);
                  const value = _.lowerCase(row[filter.id]);
                  if (_.includes(value, input)) {
                    return true;
                  }
                }}
              />
            </form>
          </div>
        </div>
        <Modal size="xl" isOpen={showAddModal} toggle={() => this.closeAddModal()}>
          <ModalHeader toggle={() => this.closeAddModal()}>{I18n.t('BEE1140' /* Buscar Docas */)}</ModalHeader>

          <ModalBody>
            <Table
              headerTitle={carrierLabel}
              filterable
              data={docksList}
              columns={this.tableDocksListColumns}
              expander
              defaultPageSize={5}
              defaultSorted={this.defaultSorted}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          </ModalBody>
          <ModalFooter>
            <OneClickButton
              type="button"
              className="btn btn-120 btn-white p-5 m-5"
              onClick={() => this.closeAddModal()}
            >
              {I18n.t('BEE553' /* Fechar */)}

            </OneClickButton>
            <OneClickButton
              type="button"
              className="btn btn-120 btn-primary p-5 m-5"
              onClick={() => this.linkAll()}
            >
              {I18n.t('BEE1158' /* Vincular Todas */)}

            </OneClickButton>
          </ModalFooter>
        </Modal>
        {(showUnlinkModal
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.confirmUnlinkModal()}
            onCancel={() => this.closeUnlinkModal()}
          >
            {I18n.t('BEE1162', { 0: dockCarrierSelected.dockCode, 1: carrierLabel } /* A doca %{0} será desvinculada da transportadora %{1}! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getCarrierDocks: (carrierCode) => dispatch(getCarrierDocks(carrierCode)),
  searchDocks: () => dispatch(searchDocks()),
  linkDockCarrier: (carrierCode, dockCode) => dispatch(linkDockCarrier(carrierCode, dockCode)),
  unlinkDockCarrier: (carrierCode, dockCode) => dispatch(unlinkDockCarrier(carrierCode, dockCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CarrierLinkDocksForm));
