import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AuthService from '../../app/services/auth';
import ROUTES from '../../config/routes';

import { getUserLogged } from '../../app/store/actions/app';

class PublicRoute extends React.PureComponent {
  render() {
    const { route, routeProps } = this.props;
    const isAuthenticated = AuthService.isAuthenticated();

    if (isAuthenticated) this.props.getUserLogged();

    if (this.props.firstAccess && route.path !== ROUTES.WIZARD_SETUP) {
      return (
        <Redirect
          to={{
            pathname: ROUTES.WIZARD_SETUP,
            state: { from: this.props.location },
          }}
        />
      );
    }

    if (!this.props.firstAccess && !isAuthenticated && route.path !== ROUTES.LOGIN) {
      return (
        <Redirect
          to={{
            pathname: ROUTES.LOGIN,
            state: { from: this.props.location },
          }}
        />
      );
    }

    if (
      (route.path === ROUTES.LOGIN || (route.path === ROUTES.WIZARD_SETUP && !this.props.firstAccess))
      && isAuthenticated
    ) {
      return (
        <Redirect
          to={{
            pathname: ROUTES.HOME,
            state: { from: this.props.location },
          }}
        />
      );
    }

    if (route.path === ROUTES.LOGIN && isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: ROUTES.HOME,
            state: { from: this.props.location },
          }}
        />
      );
    }
    return <route.component {...routeProps} />;
  }
}

const mapStateToProps = (state) => ({
  firstAccess: state.wizard.firstAccess,
});

const mapActionsToProps = (dispatch) => ({
  getUserLogged: () => dispatch(getUserLogged()),
});

export default connect(mapStateToProps, mapActionsToProps)(PublicRoute);
