import WorkShiftsRequests from '../../server/workShifts';
import { addLoading, removeLoading } from './loading';

export const setWorkShifts = workShifts => ({
  type: 'SET_WORK_SHIFTS_LIST',
  workShifts,
});

export const createWorkShift = workShift => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newWorkShift = await WorkShiftsRequests.createWorkShift(workShift);
    return newWorkShift;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateWorkShift = (workShift) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await WorkShiftsRequests.updateWorkShift(workShift);
  } finally {
    dispatch(removeLoading());
  }
}

export const getWorkShift = (workShiftId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await WorkShiftsRequests.getWorkShift(workShiftId);
  } finally {
    dispatch(removeLoading());
  }
}

export const getWorkShiftsList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const workShifts = await WorkShiftsRequests.getWorkShifts();
    dispatch(setWorkShifts(workShifts));
  } finally {
    dispatch(removeLoading());
  }
};

export const getWorkShiftsOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await WorkShiftsRequests.getWorkShiftsOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteWorkShift = (workShiftId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await WorkShiftsRequests.deleteWorkShift(workShiftId);
  } finally {
    dispatch(removeLoading());
  }
};