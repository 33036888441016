/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

import { changingCountingUser } from '../../../app/store/actions/inventory';
import addNotification from '../../../components/notification';
import OneClickButton from '../../../components/form/button';
import Table from '../../../components/table/Table';
import InventoryHelpers from '../../../helpers/inventory';

class CountSheets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultSorted: [{ id: 'sheetNumber', desc: false }],
    };

    this.tableColumns = [{
      Header: I18n.t('BEE2106' /* Ficha */),
      accessor: 'sheetNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
    }, {
      Header: I18n.t('BEE267' /* Endereço */),
      accessor: 'addressCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 140,
    }, {
      Header: I18n.t('BEE3453' /* Lado */),
      accessor: 'columnSide',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 140,
    }, {
      Header: I18n.t('BEE225' /* Produto */),
      accessor: 'productCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE428' /* Lote */),
      accessor: 'lotNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
    }, {
      Header: I18n.t('BEE2139' /* Situação Contagem */),
      accessor: 'statusCount',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{InventoryHelpers.inventoryCountQuantityStatus(row.value)}</span>
        </div>
      ),
      filterMethod: (filter, row) => {
        if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
          return true;
        }
      },
      Filter: ({ onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={(event) => { onChange(event.target.value); }}
        >
          <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
          {InventoryHelpers.inventoryCountQuantityStatus().map((elem, index) => (
            <option value={elem.value} key={index}>{elem.label}</option>
          ))}
        </select>
      ),
    }, {
      Header: I18n.t('BEE1516' /* Agrupador */),
      accessor: 'grouperCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE2150' /* Situação Ficha */),
      accessor: 'status',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{InventoryHelpers.inventorySheetStatus(row.value)}</span>
        </div>
      ),
      filterMethod: (filter, row) => {
        if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
          return true;
        }
      },
      Filter: ({ onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={(event) => { onChange(event.target.value); }}
        >
          <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
          {InventoryHelpers.inventorySheetStatus().map((elem) => (
            <option value={elem.value} key={elem.value}>{elem.label}</option>
          ))}
        </select>
      ),
    }];

    this.tableColumnsSheet = [{
      Header: I18n.t('BEE436' /* Selecionar */),
      accessor: 'action',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      filterable: false,
      sortable: false,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <input
            type="checkbox"
            checked={this.props.checkedAdd[row.original.inventorySheetId] || false}
            onChange={() => this.singleSelection('checkedAdd', row.original)}
          />
        </div>
      ),
    },
    this.tableColumns[0],
    this.tableColumns[1],
    this.tableColumns[2],
    this.tableColumns[5],
    {
      Header: I18n.t('BEE1516' /* Agrupador */),
      accessor: 'grouperCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    },
    {
      Header: I18n.t('BEE2108', { 0: 1 } /* %{0}ª Contagem */),
      accessor: 'firstCountUser',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE2108', { 0: 2 } /* %{0}ª Contagem */),
      accessor: 'secondCountUser',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE2108', { 0: 3 } /* %{0}ª Contagem */),
      accessor: 'thirdCountUser',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE1854' /* Setor */),
      accessor: 'sector',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE121' /* Rua */),
      accessor: 'street',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE281' /* Coluna */),
      accessor: 'column',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE283' /* Nível */),
      accessor: 'level',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE285' /* Gaveta */),
      accessor: 'drawer',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    },
    this.tableColumns[3],
    ];

    this.tableColumnsSheetUser = [{
      Header: I18n.t('BEE436' /* Selecionar */),
      accessor: 'action',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      filterable: false,
      sortable: false,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          {row.original.statusCount === 1 && (
            <input
              type="checkbox"
              checked={this.props.checkedRemove[row.original.inventorySheetId] || false}
              onChange={() => this.singleSelection('checkedRemove', row.original)}
            />
          )}
        </div>
      ),
    },
    ...this.tableColumns,
    {
      Header: I18n.t('BEE2108', { 0: 1 } /* %{0}ª Contagem */),
      accessor: 'firstCountUser',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE2108', { 0: 2 } /* %{0}ª Contagem */),
      accessor: 'secondCountUser',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE2108', { 0: 3 } /* %{0}ª Contagem */),
      accessor: 'thirdCountUser',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }];
  }

  singleSelection = (variableName, selection) => {
    const checked = this.props[variableName];
    checked[selection.inventorySheetId] = !checked[selection.inventorySheetId];
    this.props.setState({ [variableName]: { ...checked } });
  };

  multipleSelection = (variableName, reactTable, selectionOption) => {
    const checked = this.props[variableName];
    const data = this[reactTable].getResolvedState().sortedData;
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index += 1) {
        const element = data[index]._original;
        if (variableName === 'checkedRemove' && element.statusCount === 1) {
          checked[element.inventorySheetId] = selectionOption;
        } else if (variableName === 'checkedAdd') {
          checked[element.inventorySheetId] = selectionOption;
        }
      }
      this.props.setState({ [variableName]: { ...checked } });
    }
  };

  changingCountingUser = async (variableName, checkedInventorySheetIds, inventoryCount, userId) => {
    if (userId) {
      this.props.setState({ [variableName]: {} });
      try {
        const result = await this.props.changingCountingUser(
          checkedInventorySheetIds,
          inventoryCount,
          userId,
          (variableName === 'checkedAdd'),
        );

        if (result && result.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE2138' /* Usuário de Contagem */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */),
            'top-right',
          );
        } else {
          if (result && result.error) {
            addNotification(
              'danger',
              I18n.t('BEE2138' /* Usuário de Contagem */),
              I18n.t('BEE2147'), /* Usuário esta responsável por outra contagem
              e algumas fichas não foram adicionadas */
              'top-right',
            );
          }
          addNotification(
            'success',
            I18n.t('BEE2138' /* Usuário de Contagem */),
            I18n.t('BEE2145' /* As fichas foram alteradas com sucesso */),
            'top-right',
          );
          await this.props.inventorySheetCounting();
        }
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && error.message) {
          const messageError = (error.details)
            ? `${error.code} - ${error.details} | ${error.message}`
            : error.message;
          addNotification('danger', I18n.t('BEE2138' /* Usuário de Contagem */), messageError, 'top-right');
        } else {
          addNotification(
            'danger',
            I18n.t('BEE2138' /* Usuário de Contagem */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */),
            'top-right',
          );
        }
      }
    } else {
      addNotification(
        'danger',
        I18n.t('BEE2138' /* Usuário de Contagem */),
        I18n.t('BEE2144' /* O usuário não foi selecionado */),
        'top-right',
      );
    }
  };

  validChecked = (variableName) => {
    const { inventoryCount, userId } = this.props;
    const checked = this.props[variableName];

    if (!Object.keys(checked).length) {
      addNotification(
        'danger',
        I18n.t('BEE2138' /* Usuário de Contagem */),
        I18n.t('BEE2142' /* Nenhum ficha selecionada */),
        'top-right',
      );
    } else {
      const checkedInventorySheetIds = [];
      Object.keys(checked).forEach((index) => {
        if (checked[index]) {
          checkedInventorySheetIds.push(index);
        }
      });
      if (checkedInventorySheetIds.length) {
        this.changingCountingUser(variableName, checkedInventorySheetIds, inventoryCount, userId);
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2138' /* Usuário de Contagem */),
          I18n.t('BEE2142' /* Nenhum ficha selecionada */),
          'top-right',
        );
      }
    }
  };

  render() {
    const { defaultSorted } = this.state;

    const { listInventorySheet = [], listInventorySheetUser = [] } = this.props;

    return (
      <div className="row">
        <div className="col-md-6">
          <Table
            downloadCSV
            expander
            filterable
            data={listInventorySheet}
            defaultSorted={defaultSorted}
            columns={this.tableColumnsSheet}
            showPagination={false}
            defaultPageSize={99}
            style={{
              height: '500px',
            }}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            ref={(r) => {
              this.reactTableSheet = r && r.reactTable;
            }}
            panelHeaderProps={{
              children: I18n.t('BEE2184' /* Fichas */).toUpperCase(),
              noButton: true,
            }}
            actionButtons={(
              <div className="ml-auto">
                <button
                  type="button"
                  className="btn btn-120 btn-secondary p-5 m-5"
                  onClick={() => this.multipleSelection('checkedAdd', 'reactTableSheet', true)}
                >
                  {I18n.t('BEE1908' /* Marcar Todos */)}
                </button>
                <button
                  type="button"
                  className="btn btn-120 btn-secondary p-5 m-5"
                  onClick={() => this.multipleSelection('checkedAdd', 'reactTableSheet', false)}
                >
                  {I18n.t('BEE1909' /* Desmarcar Todos */)}
                </button>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={() => this.validChecked('checkedAdd')}
                >
                  {I18n.t('BEE785' /* Adicionar */)}
                </OneClickButton>
              </div>
            )}
          />
        </div>
        <div className="col-md-6">
          <Table
            downloadCSV
            expander
            filterable
            data={listInventorySheetUser}
            defaultSorted={defaultSorted}
            columns={this.tableColumnsSheetUser}
            showPagination={false}
            defaultPageSize={99}
            style={{
              height: '500px',
            }}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            ref={(r) => {
              this.reactTableSheetUser = r && r.reactTable;
            }}
            panelHeaderProps={{
              children: I18n.t('BEE2137' /* Fichas do Usuário */).toUpperCase(),
              noButton: true,
            }}
            actionButtons={(
              <div className="ml-auto">
                <button
                  type="button"
                  className="btn btn-120 btn-secondary p-5 m-5"
                  onClick={() => this.multipleSelection('checkedRemove', 'reactTableSheetUser', true)}
                >
                  {I18n.t('BEE1908' /* Marcar Todos */)}
                </button>
                <button
                  type="button"
                  className="btn btn-120 btn-secondary p-5 m-5"
                  onClick={() => this.multipleSelection('checkedRemove', 'reactTableSheetUser', false)}
                >
                  {I18n.t('BEE1909' /* Desmarcar Todos */)}
                </button>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-danger p-5 m-5"
                  onClick={() => this.validChecked('checkedRemove')}
                >
                  {I18n.t('BEE558' /* Remover */)}
                </OneClickButton>
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  changingCountingUser: (checkedInventorySheetIds, inventoryCount, userId, typeAction) => dispatch(
    changingCountingUser(checkedInventorySheetIds, inventoryCount, userId, typeAction),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CountSheets));
