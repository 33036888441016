/* eslint-disable no-undef */
export const I18nLanguages = (language = '') => {
  const languageLower = language.toLowerCase();
  if (languageLower.startsWith('pt')) return 'ptBr';
  if (languageLower.startsWith('en')) return 'enUs';
  if (languageLower.startsWith('es')) return 'esCh';

  return 'enUs';
};

export const I18nMomentLocale = (language = '') => {
  const languageLower = language.toLowerCase();
  if (languageLower.startsWith('pt')) return 'pt-br';
  if (languageLower.startsWith('en')) return 'en';
  if (languageLower.startsWith('es')) return 'es';

  return 'en';
};

export const I18nConstrueLocale = (language = '') => {
  const languageLower = language.toLowerCase();
  if (languageLower.startsWith('pt')) return 'pt_BR';
  if (languageLower.startsWith('en')) return 'en';
  if (languageLower.startsWith('es')) return 'es';

  return 'en';
};

export const I18nFormat = () => {
  let a = this;
  for (let index = 0; index < arguments.length; index += 1) {
    const element = arguments[index];
    a = a.replace(new RegExp(`\\{${k}\\}`, 'g'), element);
  }
  return a;
};

export const I18nGetDateLocale = (language = '') => {
  const languageLower = language.toLowerCase();
  if (languageLower.startsWith('pt')) return 'ptBr';
  if (languageLower.startsWith('en')) return 'enUs';
  if (languageLower.startsWith('es')) return 'es';

  return 'enUs';
};

export default {
  I18nLanguages,
  I18nFormat,
  I18nGetDateLocale,
  I18nMomentLocale,
  I18nConstrueLocale,
};
