import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import { Badge } from 'reactstrap';

import Table from '../../../components/table/Table';
import OutboundOrderHelpers from '../../../helpers/outboundOrder';

class TableOutboundVolume extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultPageSize: 5,
      pageSizeOptions: [5, 10, 15],
      defaultSorted: [{ id: 'id', desc: false }],
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE1922' /* Etiqueta Volume */),
        accessor: 'id',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE1811' /* Sequência */),
        accessor: 'sequence',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE497' /* Embalagem */),
        accessor: 'packageCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE564' /* Peso Bruto */),
        accessor: 'grossWeight',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE1812' /* Quantidade de produtos */),
        accessor: 'products',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{row.value.length}</span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: ({ value }) => {
          let badgeColor;
          let badgeText;

          switch (value) {
            case 1:
              badgeColor = 'primary';
              badgeText = I18n.t('BEE470' /* Pendente */);
              break;
            case 2:
              badgeColor = 'danger';
              badgeText = I18n.t('BEE2860' /* Retirado */);
              break;
            case 3:
              badgeColor = 'warning';
              badgeText = I18n.t('BEE445' /* Conferido */);
              break;
            default:
              badgeColor = 'success';
              badgeText = I18n.t('BEE3866' /* Na Doca */);
              break;
          }

          return <Badge color={badgeColor}>{badgeText}</Badge>;
        },
        filterMethod: (filter, row) => {
          if (parseInt(filter.value) === parseInt(row.status)) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {OutboundOrderHelpers.outboundOrderStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }];
  }

  render() {
    const {
      defaultPageSize, defaultSorted, pageSizeOptions,
    } = this.state;
    const { outboundVolumeList = [] } = this.props;

    return (
      <Table
        panelHeaderProps={{
          noButton: true,
          children: I18n.t('BEE462' /* Documentos */).toLocaleUpperCase(),
        }}
        filterable
        data={outboundVolumeList}
        columns={this.tableColumns}
        expander
        defaultPageSize={defaultPageSize}
        defaultSorted={defaultSorted}
        pageSizeOptions={pageSizeOptions}
        defaultFilterMethod={(filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row[filter.id]);
          if (_.includes(value, input)) {
            return true;
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  outboundVolumeList: state.outboundOrderVolumes && state.outboundOrderVolumes.outboundVolumeList,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableOutboundVolume));
