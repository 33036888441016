import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import InputMask from 'react-input-mask';

import { Panel, PanelHeader, PanelBody, PanelFooter } from '../../../../components/panel/panel';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createWorkShift, updateWorkShift, getWorkShift, deleteWorkShift
} from '../../../../app/store/actions/workShifts';
import Form from '../../../../components/form/form';

class WorkShiftForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      note: '',
      disableCode: true,
      disableName: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getWorkShiftSelected();
    }
  }

  getWorkShiftSelected = async () => {
    if (this.props.location.state.workShift) {
      const { workShift } = this.props.location.state;

      const workShiftSelected = await this.props.getWorkShift(workShift.id);

      if (workShiftSelected) {
        this.setState({
          workShiftSelected,
          workShiftId: workShiftSelected.id,
          code: workShiftSelected.code,
          name: workShiftSelected.name,
          note: workShiftSelected.note,
        });
      }
    }
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createInputMask = (value, attr, label, placeholder, mask, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <InputMask
          className="form-control"
          placeholder={placeholder}
          type={type}
          mask={mask}
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          required={required}
          disabled={disabled}
        ></InputMask>
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  createWorkShiftSubmit = async () => {
    const {code, name, note } = this.state;

    try {
      const newWorkShift = await this.props.createWorkShift({
        code,
        name,
        note,
      });

      if (newWorkShift) {
        if (newWorkShift.success && newWorkShift.success === false) {
          await addNotification(
            'danger', I18n.t('BEE945' /* Novo Turno de Trabalho */), I18n.t('BEE946', { 0: code } /* Erro ao incluir o Turno de Trabalho %{0}! */), 'top-right'
          );
        } else {
          await addNotification(
            'success', I18n.t('BEE945' /* Novo Turno de Trabalho */), I18n.t('BEE947', { 0: code } /* Novo Turno de Trabalho %{0} incluído com sucesso! */), 'top-right'
          );

          this.props.history.push(ROUTES.WORK_SHIFT_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE945' /* Novo Turno de Trabalho */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE945' /* Novo Turno de Trabalho */), I18n.t('BEE946', { 0: code } /* Erro ao incluir o Turno de Trabalho %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE945' /* Novo Turno de Trabalho */), I18n.t('BEE946', { 0: code } /* Erro ao incluir o Turno de Trabalho %{0}! */), 'top-right'
        );
      }
    }
  };

  updateWorkShiftSubmit = async () => {
    const {
      name, workShiftId, note, 
    } = this.state;

    try {
      const updWorkShift = await this.props.updateWorkShift({
        workShiftId,
        name,
        note,
      });

      if (updWorkShift) {
        if (updWorkShift.success && updWorkShift.success === false) {
          await addNotification(
            'danger', I18n.t('BEE948' /* Atualizar Turno de Trabalho */), I18n.t('BEE950', { 0: name } /* Erro ao atualizar o Turno de Trabalho %{0}! */), 'top-right'
          );
        } else {
          await addNotification('success', I18n.t('BEE948' /* Atualizar Turno de Trabalho */), I18n.t('BEE949', { 0: name } /* Atualização do Turno de Trabalho %{0} efetuado com sucesso! */), 'top-right');

          this.props.history.push(ROUTES.WORK_SHIFT_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE948' /* Atualizar Turno de Trabalho */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE948' /* Atualizar Turno de Trabalho */), I18n.t('BEE950', { 0: name } /* Erro ao atualizar o Turno de Trabalho %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE948' /* Atualizar Turno de Trabalho */), I18n.t('BEE950', { 0: name } /* Erro ao atualizar o Turno de Trabalho %{0}! */), 'top-right'
        );
      }
      await addNotification('danger', I18n.t('BEE948' /* Atualizar Turno de Trabalho */), I18n.t('BEE950', { 0: name } /* Erro ao atualizar o Turno de Trabalho %{0}! */), 'top-right');
    }
  };

  render() {
    const { code, name, note } = this.state;

    const {
      mode, disableCode, disableName, disableNote, showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';
 //   let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE945' /* Novo Turno de Trabalho */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE951' /* Editar Turno de Trabalho */);
  //    breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE952' /* Detalhes Turno de Trabalho */);
    //  breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE25' /* Usuários */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE869' /* Turnos de Trabalho */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton={true} />
                <PanelBody>
                  <Form
                    noPanel
                    setValue={this.setValue}
                    inputs={(formContext) => ([
                      formContext.createInput(
                        code,
                        'code',
                        `${I18n.t('BEE82' /* Código */)}:`,
                        I18n.t('BEE3685' /* Informe o código do turno de trabalho */),
                        'text',
                        true,
                        disableCode,
                      ),
                      formContext.createInput(
                        name,
                        'name',
                        `${I18n.t('BEE83' /* Nome */)}:`,
                        I18n.t('BEE3737' /* Informe o nome do turno de trabalho */),
                        'text',
                        true,
                        disableName,
                      ),
                      formContext.createInput(
                        note,
                        'note',
                        `${I18n.t('BEE135' /* Observação */)}:`,
                        I18n.t('BEE136' /* Informe a observação */),
                        'text',
                        false,
                        disableNote,
                      ),
                    ])}
                  />
                </PanelBody>
                <PanelFooter>
                  <Link to={{pathname: ROUTES.WORK_SHIFT_LIST, state: {dice: this.props.location.dice}}}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createWorkShiftSubmit()}
                    >{I18n.t('BEE138' /* Criar */)}</OneClickButton>
                  )}
                  {(showUpdate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateWorkShiftSubmit()}
                    >{I18n.t('BEE139' /* Atualizar */)}</OneClickButton>
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getWorkShift: workShiftId => dispatch(getWorkShift(workShiftId)),
  createWorkShift: workShift => dispatch(createWorkShift(workShift)),
  updateWorkShift: workShift => dispatch(updateWorkShift(workShift)),
  deleteWorkShift: workShiftId => dispatch(deleteWorkShift(workShiftId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WorkShiftForm));
