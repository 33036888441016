export const setJson = (jsonCsv) => ({
  type: 'SET_JSON_CSV',
  jsonCsv,
});

export const setFile = (fileCsv) => ({
  type: 'SET_FILE_CSV',
  fileCsv,
});

export const setJsonContent = (json) => async (dispatch) => {
  dispatch(setJson(json));
};
