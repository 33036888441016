import IndustrialScalesRequests from '../../server/industrialScales';
import { addLoading, removeLoading } from './loading';

export const getIndustrialScalesList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IndustrialScalesRequests.getIndustrialScales();
  } finally {
    dispatch(removeLoading());
  }
};

export const createIndustrialScale = (industrialScale) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newIndustrialScale = await IndustrialScalesRequests.createIndustrialScale(industrialScale);
    return newIndustrialScale;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateIndustrialScale = (industrialScale) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IndustrialScalesRequests.updateIndustrialScale(industrialScale);
  } finally {
    dispatch(removeLoading());
  }
};

export const getIndustrialScale = (industrialScaleId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IndustrialScalesRequests.getIndustrialScale(industrialScaleId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteIndustrialScale = (industrialScaleId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IndustrialScalesRequests.deleteIndustrialScale(industrialScaleId);
  } finally {
    dispatch(removeLoading());
  }
};
