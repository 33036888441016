const INITIAL_STATE = {
  curvesList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_CURVES': {
      return { ...state, curvesList: action.curves };
    }
    default:
      return state;
  }
};
