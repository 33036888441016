import RestService from '../services/rest';


async function getCustomerOptions() {
  return RestService.getAuthenticated('customerBranch/customerOptions');
};

async function getBranchOptions() {
  return RestService.getAuthenticated('customerBranch/branchOptions');
};

async function customerBranchCreate(parametersForCreation) {
  return RestService.postAuthenticated('customerBranch/create', parametersForCreation);
};

async function getCustomerBranchesList(tableParams) {
  return RestService.postAuthenticated('customerBranch/all', tableParams);
}

async function customerBranchDelete(customerBranchId) {
  return RestService.deleteAuthenticated('customerBranch/delete', { customerBranchId });
}

async function customerBranchUpdate(customerBranchId, customerCode, branchCode, restriction, note) {
  return RestService.putAuthenticated('customerBranch/update', customerBranchId, customerCode, branchCode, restriction, note);
}

export default {
  getCustomerOptions,
  getBranchOptions,
  customerBranchCreate,
  getCustomerBranchesList,
  customerBranchUpdate,
  customerBranchDelete,
};