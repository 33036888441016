import React, { PureComponent } from 'react';
import { PageSettings } from '../../config/page-settings';
import PanelWizard from './components/panel/PanelWizard';

class Wizard extends PureComponent {
  static contextType = PageSettings;

  componentDidMount() {
    this.context.handleSetPageSidebar(false);
    this.context.handleSetPageHeader(false);
  }

  componentWillUnmount() {
    this.context.handleSetPageSidebar(true);
    this.context.handleSetPageHeader(true);
  }

  render() {
    return (
      <div>
        <PanelWizard />
      </div>
    );
  }
}

export default Wizard;
