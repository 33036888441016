export const I18nLanguages = (language = '') => {
  const languageLower = language.toLowerCase();
  if (languageLower.startsWith('pt')) return 'ptBr';
  if (languageLower.startsWith('en')) return 'enUs';
  if (languageLower.startsWith('es')) return 'esCh';

  return 'enUs';
};

export const I18nGetLocaleDateString = (language = '') => {
  const formats = {
    'af-za': 'yyyy/MM/dd',
    'am-et': 'd/M/yyyy',
    'ar-ae': 'dd/MM/yyyy',
    'ar-bh': 'dd/MM/yyyy',
    'ar-dz': 'dd-MM-yyyy',
    'ar-eg': 'dd/MM/yyyy',
    'ar-iq': 'dd/MM/yyyy',
    'ar-jo': 'dd/MM/yyyy',
    'ar-kw': 'dd/MM/yyyy',
    'ar-lb': 'dd/MM/yyyy',
    'ar-ly': 'dd/MM/yyyy',
    'ar-ma': 'dd-MM-yyyy',
    'ar-om': 'dd/MM/yyyy',
    'ar-qa': 'dd/MM/yyyy',
    'ar-sa': 'dd/MM/yy',
    'ar-sy': 'dd/MM/yyyy',
    'ar-tn': 'dd-MM-yyyy',
    'ar-ye': 'dd/MM/yyyy',
    'arn-cl': 'dd-MM-yyyy',
    'as-in': 'dd-MM-yyyy',
    'az-cyrl-az': 'dd.MM.yyyy',
    'az-latn-az': 'dd.MM.yyyy',
    'ba-ru': 'dd.MM.yy',
    'be-by': 'dd.MM.yyyy',
    'bg-bg': 'dd.M.yyyy',
    'bn-bd': 'dd-MM-yy',
    'bn-in': 'dd-MM-yy',
    'bo-cn': 'yyyy/M/d',
    'br-fr': 'dd/MM/yyyy',
    'bs-cyrl-ba': 'd.M.yyyy',
    'bs-latn-ba': 'd.M.yyyy',
    'ca-es': 'dd/MM/yyyy',
    'co-fr': 'dd/MM/yyyy',
    'cs-cz': 'd.M.yyyy',
    'cy-gb': 'dd/MM/yyyy',
    'da-dk': 'dd-MM-yyyy',
    'de-at': 'dd.MM.yyyy',
    'de-ch': 'dd.MM.yyyy',
    'de-de': 'dd.MM.yyyy',
    'de-li': 'dd.MM.yyyy',
    'de-lu': 'dd.MM.yyyy',
    'dsb-de': 'd. M. yyyy',
    'dv-mv': 'dd/MM/yy',
    'el-gr': 'd/M/yyyy',
    'en-029': 'MM/dd/yyyy',
    'en-au': 'd/MM/yyyy',
    'en-bz': 'dd/MM/yyyy',
    'en-ca': 'dd/MM/yyyy',
    'en-gb': 'dd/MM/yyyy',
    'en-ie': 'dd/MM/yyyy',
    'en-in': 'dd-MM-yyyy',
    'en-jm': 'dd/MM/yyyy',
    'en-my': 'd/M/yyyy',
    'en-nz': 'd/MM/yyyy',
    'en-ph': 'M/d/yyyy',
    'en-sg': 'd/M/yyyy',
    'en-tt': 'dd/MM/yyyy',
    'en-us': 'M/d/yyyy',
    'en-za': 'yyyy/MM/dd',
    'en-zw': 'M/d/yyyy',
    'es-ar': 'dd/MM/yyyy',
    'es-bo': 'dd/MM/yyyy',
    'es-cl': 'dd-MM-yyyy',
    'es-co': 'dd/MM/yyyy',
    'es-cr': 'dd/MM/yyyy',
    'es-do': 'dd/MM/yyyy',
    'es-ec': 'dd/MM/yyyy',
    'es-es': 'dd/MM/yyyy',
    'es-gt': 'dd/MM/yyyy',
    'es-hn': 'dd/MM/yyyy',
    'es-mx': 'dd/MM/yyyy',
    'es-ni': 'dd/MM/yyyy',
    'es-pa': 'MM/dd/yyyy',
    'es-pe': 'dd/MM/yyyy',
    'es-pr': 'dd/MM/yyyy',
    'es-py': 'dd/MM/yyyy',
    'es-sv': 'dd/MM/yyyy',
    'es-us': 'M/d/yyyy',
    'es-uy': 'dd/MM/yyyy',
    'es-ve': 'dd/MM/yyyy',
    'et-ee': 'd.MM.yyyy',
    'eu-es': 'yyyy/MM/dd',
    'fa-ir': 'MM/dd/yyyy',
    'fi-fi': 'd.M.yyyy',
    'fil-ph': 'M/d/yyyy',
    'fo-fo': 'dd-MM-yyyy',
    'fr-be': 'd/MM/yyyy',
    'fr-ca': 'yyyy-MM-dd',
    'fr-ch': 'dd.MM.yyyy',
    'fr-fr': 'dd/MM/yyyy',
    'fr-lu': 'dd/MM/yyyy',
    'fr-mc': 'dd/MM/yyyy',
    'fy-nl': 'd-M-yyyy',
    'ga-ie': 'dd/MM/yyyy',
    'gd-gb': 'dd/MM/yyyy',
    'gl-es': 'dd/MM/yy',
    'gsw-fr': 'dd/MM/yyyy',
    'gu-in': 'dd-MM-yy',
    'ha-latn-ng': 'd/M/yyyy',
    'he-il': 'dd/MM/yyyy',
    'hi-in': 'dd-MM-yyyy',
    'hr-ba': 'd.M.yyyy.',
    'hr-hr': 'd.M.yyyy',
    'hsb-de': 'd. M. yyyy',
    'hu-hu': 'yyyy. MM. dd.',
    'hy-am': 'dd.MM.yyyy',
    'id-id': 'dd/MM/yyyy',
    'ig-ng': 'd/M/yyyy',
    'ii-cn': 'yyyy/M/d',
    'is-is': 'd.M.yyyy',
    'it-ch': 'dd.MM.yyyy',
    'it-it': 'dd/MM/yyyy',
    'iu-cans-ca': 'd/M/yyyy',
    'iu-latn-ca': 'd/MM/yyyy',
    'ja-jp': 'yyyy/MM/dd',
    'ka-ge': 'dd.MM.yyyy',
    'kk-kz': 'dd.MM.yyyy',
    'kl-gl': 'dd-MM-yyyy',
    'km-kh': 'yyyy-MM-dd',
    'kn-in': 'dd-MM-yy',
    'ko-kr': 'yyyy-MM-dd',
    'kok-in': 'dd-MM-yyyy',
    'ky-kg': 'dd.MM.yy',
    'lb-lu': 'dd/MM/yyyy',
    'lo-la': 'dd/MM/yyyy',
    'lt-lt': 'yyyy.MM.dd',
    'lv-lv': 'yyyy.MM.dd.',
    'mi-nz': 'dd/MM/yyyy',
    'mk-mk': 'dd.MM.yyyy',
    'ml-in': 'dd-MM-yy',
    'mn-mn': 'yy.MM.dd',
    'mn-mong-cn': 'yyyy/M/d',
    'moh-ca': 'M/d/yyyy',
    'mr-in': 'dd-MM-yyyy',
    'ms-bn': 'dd/MM/yyyy',
    'ms-my': 'dd/MM/yyyy',
    'mt-mt': 'dd/MM/yyyy',
    'nb-no': 'dd.MM.yyyy',
    'ne-np': 'M/d/yyyy',
    'nl-be': 'd/MM/yyyy',
    'nl-nl': 'd-M-yyyy',
    'nn-no': 'dd.MM.yyyy',
    'nso-za': 'yyyy/MM/dd',
    'oc-fr': 'dd/MM/yyyy',
    'or-in': 'dd-MM-yy',
    'pa-in': 'dd-MM-yy',
    'pl-pl': 'yyyy-MM-dd',
    'prs-af': 'dd/MM/yy',
    'ps-af': 'dd/MM/yy',
    'pt-br': 'dd/MM/yyyy',
    'pt-pt': 'dd-MM-yyyy',
    'qut-gt': 'dd/MM/yyyy',
    'quz-bo': 'dd/MM/yyyy',
    'quz-ec': 'dd/MM/yyyy',
    'quz-pe': 'dd/MM/yyyy',
    'rm-ch': 'dd/MM/yyyy',
    'ro-ro': 'dd.MM.yyyy',
    'ru-ru': 'dd.MM.yyyy',
    'rw-rw': 'M/d/yyyy',
    'sa-in': 'dd-MM-yyyy',
    'sah-ru': 'MM.dd.yyyy',
    'se-fi': 'd.M.yyyy',
    'se-no': 'dd.MM.yyyy',
    'se-se': 'yyyy-MM-dd',
    'si-lk': 'yyyy-MM-dd',
    'sk-sk': 'd. M. yyyy',
    'sl-si': 'd.M.yyyy',
    'sma-no': 'dd.MM.yyyy',
    'sma-se': 'yyyy-MM-dd',
    'smj-no': 'dd.MM.yyyy',
    'smj-se': 'yyyy-MM-dd',
    'smn-fi': 'd.M.yyyy',
    'sms-fi': 'd.M.yyyy',
    'sq-al': 'yyyy-MM-dd',
    'sr-cyrl-ba': 'd.M.yyyy',
    'sr-cyrl-cs': 'd.M.yyyy',
    'sr-cyrl-me': 'd.M.yyyy',
    'sr-cyrl-rs': 'd.M.yyyy',
    'sr-latn-ba': 'd.M.yyyy',
    'sr-latn-cs': 'd.M.yyyy',
    'sr-latn-me': 'd.M.yyyy',
    'sr-latn-rs': 'd.M.yyyy',
    'sv-fi': 'd.M.yyyy',
    'sv-se': 'yyyy-MM-dd',
    'sw-ke': 'M/d/yyyy',
    'syr-sy': 'dd/MM/yyyy',
    'ta-in': 'dd-MM-yyyy',
    'te-in': 'dd-MM-yy',
    'tg-cyrl-tj': 'dd.MM.yy',
    'th-th': 'd/M/yyyy',
    'tk-tm': 'dd.MM.yy',
    'tn-za': 'yyyy/MM/dd',
    'tr-tr': 'dd.MM.yyyy',
    'tt-ru': 'dd.MM.yyyy',
    'tzm-latn-dz': 'dd-MM-yyyy',
    'ug-cn': 'yyyy-M-d',
    'uk-ua': 'dd.MM.yyyy',
    'ur-pk': 'dd/MM/yyyy',
    'uz-cyrl-uz': 'dd.MM.yyyy',
    'uz-latn-uz': 'dd/MM yyyy',
    'vi-vn': 'dd/MM/yyyy',
    'wo-sn': 'dd/MM/yyyy',
    'xh-za': 'yyyy/MM/dd',
    'yo-ng': 'd/M/yyyy',
    'zh-cn': 'yyyy/M/d',
    'zh-hk': 'd/M/yyyy',
    'zh-mo': 'd/M/yyyy',
    'zh-sg': 'd/M/yyyy',
    'zh-tw': 'yyyy/M/d',
    'zu-za': 'yyyy/MM/dd',
  };

  const formatsDefault = {
    afza: 'yyyy/MM/dd',
    amet: 'd/M/yyyy',
    arae: 'dd/MM/yyyy',
    arbh: 'dd/MM/yyyy',
    ardz: 'dd-MM-yyyy',
    areg: 'dd/MM/yyyy',
    ariq: 'dd/MM/yyyy',
    arjo: 'dd/MM/yyyy',
    arkw: 'dd/MM/yyyy',
    arlb: 'dd/MM/yyyy',
    arly: 'dd/MM/yyyy',
    arma: 'dd-MM-yyyy',
    arom: 'dd/MM/yyyy',
    arqa: 'dd/MM/yyyy',
    arsa: 'dd/MM/yy',
    arsy: 'dd/MM/yyyy',
    artn: 'dd-MM-yyyy',
    arye: 'dd/MM/yyyy',
    arncl: 'dd-MM-yyyy',
    asin: 'dd-MM-yyyy',
    azcyrlaz: 'dd.MM.yyyy',
    azlatnaz: 'dd.MM.yyyy',
    baru: 'dd.MM.yy',
    beby: 'dd.MM.yyyy',
    bgbg: 'dd.M.yyyy',
    bnbd: 'dd-MM-yy',
    bnin: 'dd-MM-yy',
    bocn: 'yyyy/M/d',
    brfr: 'dd/MM/yyyy',
    bscyrlba: 'd.M.yyyy',
    bslatnba: 'd.M.yyyy',
    caes: 'dd/MM/yyyy',
    cofr: 'dd/MM/yyyy',
    cscz: 'd.M.yyyy',
    cygb: 'dd/MM/yyyy',
    dadk: 'dd-MM-yyyy',
    deat: 'dd.MM.yyyy',
    dech: 'dd.MM.yyyy',
    dede: 'dd.MM.yyyy',
    deli: 'dd.MM.yyyy',
    delu: 'dd.MM.yyyy',
    dsbde: 'd. M. yyyy',
    dvmv: 'dd/MM/yy',
    elgr: 'd/M/yyyy',
    en029: 'MM/dd/yyyy',
    enau: 'd/MM/yyyy',
    enbz: 'dd/MM/yyyy',
    enca: 'dd/MM/yyyy',
    engb: 'dd/MM/yyyy',
    enie: 'dd/MM/yyyy',
    enin: 'dd-MM-yyyy',
    enjm: 'dd/MM/yyyy',
    enmy: 'd/M/yyyy',
    ennz: 'd/MM/yyyy',
    enph: 'M/d/yyyy',
    ensg: 'd/M/yyyy',
    entt: 'dd/MM/yyyy',
    enus: 'M/d/yyyy',
    enza: 'yyyy/MM/dd',
    enzw: 'M/d/yyyy',
    esar: 'dd/MM/yyyy',
    esbo: 'dd/MM/yyyy',
    escl: 'dd-MM-yyyy',
    esco: 'dd/MM/yyyy',
    escr: 'dd/MM/yyyy',
    esdo: 'dd/MM/yyyy',
    esec: 'dd/MM/yyyy',
    eses: 'dd/MM/yyyy',
    esgt: 'dd/MM/yyyy',
    eshn: 'dd/MM/yyyy',
    esmx: 'dd/MM/yyyy',
    esni: 'dd/MM/yyyy',
    espa: 'MM/dd/yyyy',
    espe: 'dd/MM/yyyy',
    espr: 'dd/MM/yyyy',
    espy: 'dd/MM/yyyy',
    essv: 'dd/MM/yyyy',
    esus: 'M/d/yyyy',
    esuy: 'dd/MM/yyyy',
    esve: 'dd/MM/yyyy',
    etee: 'd.MM.yyyy',
    eues: 'yyyy/MM/dd',
    fair: 'MM/dd/yyyy',
    fifi: 'd.M.yyyy',
    filph: 'M/d/yyyy',
    fofo: 'dd-MM-yyyy',
    frbe: 'd/MM/yyyy',
    frca: 'yyyy-MM-dd',
    frch: 'dd.MM.yyyy',
    frfr: 'dd/MM/yyyy',
    frlu: 'dd/MM/yyyy',
    frmc: 'dd/MM/yyyy',
    fynl: 'd-M-yyyy',
    gaie: 'dd/MM/yyyy',
    gdgb: 'dd/MM/yyyy',
    gles: 'dd/MM/yy',
    gswfr: 'dd/MM/yyyy',
    guin: 'dd-MM-yy',
    halatnng: 'd/M/yyyy',
    heil: 'dd/MM/yyyy',
    hiin: 'dd-MM-yyyy',
    hrba: 'd.M.yyyy.',
    hrhr: 'd.M.yyyy',
    hsbde: 'd. M. yyyy',
    huhu: 'yyyy. MM. dd.',
    hyam: 'dd.MM.yyyy',
    idid: 'dd/MM/yyyy',
    igng: 'd/M/yyyy',
    iicn: 'yyyy/M/d',
    isis: 'd.M.yyyy',
    itch: 'dd.MM.yyyy',
    itit: 'dd/MM/yyyy',
    iucansca: 'd/M/yyyy',
    iulatnca: 'd/MM/yyyy',
    jajp: 'yyyy/MM/dd',
    kage: 'dd.MM.yyyy',
    kkkz: 'dd.MM.yyyy',
    klgl: 'dd-MM-yyyy',
    kmkh: 'yyyy-MM-dd',
    knin: 'dd-MM-yy',
    kokr: 'yyyy-MM-dd',
    kokin: 'dd-MM-yyyy',
    kykg: 'dd.MM.yy',
    lblu: 'dd/MM/yyyy',
    lola: 'dd/MM/yyyy',
    ltlt: 'yyyy.MM.dd',
    lvlv: 'yyyy.MM.dd.',
    minz: 'dd/MM/yyyy',
    mkmk: 'dd.MM.yyyy',
    mlin: 'dd-MM-yy',
    mnmn: 'yy.MM.dd',
    mnmongcn: 'yyyy/M/d',
    mohca: 'M/d/yyyy',
    mrin: 'dd-MM-yyyy',
    msbn: 'dd/MM/yyyy',
    msmy: 'dd/MM/yyyy',
    mtmt: 'dd/MM/yyyy',
    nbno: 'dd.MM.yyyy',
    nenp: 'M/d/yyyy',
    nlbe: 'd/MM/yyyy',
    nlnl: 'd-M-yyyy',
    nnno: 'dd.MM.yyyy',
    nsoza: 'yyyy/MM/dd',
    ocfr: 'dd/MM/yyyy',
    orin: 'dd-MM-yy',
    pain: 'dd-MM-yy',
    plpl: 'yyyy-MM-dd',
    prsaf: 'dd/MM/yy',
    psaf: 'dd/MM/yy',
    ptbr: 'dd/MM/yyyy',
    ptpt: 'dd-MM-yyyy',
    qutgt: 'dd/MM/yyyy',
    quzbo: 'dd/MM/yyyy',
    quzec: 'dd/MM/yyyy',
    quzpe: 'dd/MM/yyyy',
    rmch: 'dd/MM/yyyy',
    roro: 'dd.MM.yyyy',
    ruru: 'dd.MM.yyyy',
    rwrw: 'M/d/yyyy',
    sain: 'dd-MM-yyyy',
    sahru: 'MM.dd.yyyy',
    sefi: 'd.M.yyyy',
    seno: 'dd.MM.yyyy',
    sese: 'yyyy-MM-dd',
    silk: 'yyyy-MM-dd',
    sksk: 'd. M. yyyy',
    slsi: 'd.M.yyyy',
    smano: 'dd.MM.yyyy',
    smase: 'yyyy-MM-dd',
    smjno: 'dd.MM.yyyy',
    smjse: 'yyyy-MM-dd',
    smnfi: 'd.M.yyyy',
    smsfi: 'd.M.yyyy',
    sqal: 'yyyy-MM-dd',
    srcyrlba: 'd.M.yyyy',
    srcyrlcs: 'd.M.yyyy',
    srcyrlme: 'd.M.yyyy',
    srcyrlrs: 'd.M.yyyy',
    srlatnba: 'd.M.yyyy',
    srlatncs: 'd.M.yyyy',
    srlatnme: 'd.M.yyyy',
    srlatnrs: 'd.M.yyyy',
    svfi: 'd.M.yyyy',
    svse: 'yyyy-MM-dd',
    swke: 'M/d/yyyy',
    syrsy: 'dd/MM/yyyy',
    tain: 'dd-MM-yyyy',
    tein: 'dd-MM-yy',
    tgcyrltj: 'dd.MM.yy',
    thth: 'd/M/yyyy',
    tktm: 'dd.MM.yy',
    tnza: 'yyyy/MM/dd',
    trtr: 'dd.MM.yyyy',
    ttru: 'dd.MM.yyyy',
    tzmlatndz: 'dd-MM-yyyy',
    ugcn: 'yyyy-M-d',
    ukua: 'dd.MM.yyyy',
    urpk: 'dd/MM/yyyy',
    uzcyrluz: 'dd.MM.yyyy',
    uzlatnuz: 'dd/MM yyyy',
    vivn: 'dd/MM/yyyy',
    wosn: 'dd/MM/yyyy',
    xhza: 'yyyy/MM/dd',
    yong: 'd/M/yyyy',
    zhcn: 'yyyy/M/d',
    zhhk: 'd/M/yyyy',
    zhmo: 'd/M/yyyy',
    zhsg: 'd/M/yyyy',
    zhtw: 'yyyy/M/d',
    zuza: 'yyyy/MM/dd',
  };

  return (
    formats[language.toLowerCase()]
    || formatsDefault[language.toLowerCase()]
    || 'dd/MM/yyyy'
  );
};

export const I18nGetLocaleCurrency = (language = '') => {
  const formats = {
    'pt-br': {
      precision: 2,
      separator: ',',
      delimiter: '.',
      unit: 'R$',
      suffixUnit: '',
      currency: 'BRL',
      language: 'pt-BR',
    },
    'es-ch': {
      precision: 0,
      separator: '.',
      delimiter: ',',
      unit: '$',
      suffixUnit: '',
      currency: 'CLP',
      language: 'en-CL',
    },
    'es-us': {
      precision: 2,
      separator: '.',
      delimiter: ',',
      unit: '$',
      suffixUnit: '',
      currency: 'USD',
      language: 'en-US',
    },
  };

  const formatsDefault = {
    ptbr: {
      precision: 2,
      separator: ',',
      delimiter: '.',
      unit: 'R$',
      suffixUnit: '',
      currency: 'BRL',
      language: 'pt-BR',
    },
    esch: {
      precision: 0,
      separator: '.',
      delimiter: ',',
      unit: '$',
      suffixUnit: '',
      currency: 'CLP',
      language: 'en-CL',
    },
    esus: {
      precision: 2,
      separator: '.',
      delimiter: ',',
      unit: '$',
      suffixUnit: '',
      currency: 'USD',
      language: 'en-US',
    },
  };

  return (
    formats[language.toLowerCase()]
    || formatsDefault[language.toLowerCase()]
    || formats['pt-br']
  );
};

export default {
  I18nLanguages,
  I18nGetLocaleDateString,
  I18nGetLocaleCurrency,
};
