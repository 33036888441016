import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  NavItem,
  NavLink,
  Nav,
} from 'reactstrap';
import Select from 'react-select';

import moment from 'moment-timezone';
import _ from 'lodash';
import Table from '../../../components/table/Table';
import Form from '../../../components/form/form';
import addNotification from '../../../components/notification';
import { getBranchOptions } from '../../../app/store/actions/customerBranch';
import { getDepositsOptionsList } from '../../../app/store/actions/deposits';
import { getUserPermissions } from '../../../app/store/actions/users';
import { getAllCurves } from '../../../app/store/actions/curves';
import { getMaterialsFamilyOptions } from '../../../app/store/actions/materialsFamily';
import { getStorageAddressSizesList } from '../../../app/store/actions/storageAdressSizes';
import { getStorageAddressTypesOptions } from '../../../app/store/actions/storageAdressTypes';

import { returnFiltersByRules } from '../../reports/utils';
import DragAndDrop from '../../../components/bulkImport/DragAndDrop';
import ImportCaption from '../../../components/bulkImport/ImportCaption';
import { setJsonContent } from '../../../app/store/actions/bulkImport';

class FilteringModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codeFrom: '',
      codeTo: '',
      sectorFrom: '',
      sectorTo: '',
      streetFrom: '',
      streetTo: '',
      levelFrom: '',
      levelTo: '',
      columnFrom: '',
      columnTo: '',
      drawerFrom: '',
      drawerTo: '',
      productFrom: '',
      productTo: '',
      countsFrom: 0,
      countsTo: 0,
      branch: '',
      branchName: '',
      listBranch: [],
      depositName: '',
      deposit: '',
      listDeposit: [],
      selectedCurves: [],
      curvesCodes: [],
      movingDateTo: '',
      movingDateFrom: '',
      movingType: 0,
      topAddress: 0,
      unitaryValueFrom: 0,
      unitaryValueTo: 0,
      unitaryValueFromFormatted: 0,
      unitaryValueToFormatted: 0,
      materialsFamily: [],
      selectedMaterialsFamily: [],
      storageAddressSizes: [],
      selectedStorageAddressSizes: [],
      storageAddressTypes: [],
      selectedStorageAddressTypes: [],
      onlyAddressesType: 1,
      typeFilter: 1,
      typeFilterSheet: '1',
      page: 0,
    };

    this.csvPreviewTable = [
      {
        Header: I18n.t('BEE1755' /* Código Endereço */),
        accessor: 'storageAddressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        sortable: false,
      }, {
        Header: I18n.t('BEE378' /* Código do Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        sortable: false,
      }];
  }

  componentDidMount() {
    const columnSideList = [
      { label: I18n.t('BEE203' /* Ambos */), value: 'both' },
      { label: I18n.t('BEE3398' /* Par */), value: 'even' },
      { label: I18n.t('BEE3399' /* Ímpar */), value: 'odd' },
    ];

    this.setState({
      columnSideList,
      columnSide: 'both',
      columnSideLabel: I18n.t('BEE203' /* Ambos */),
    });

    this.getCurves();
    this.getMaterialsFamilyOptions();
    this.getStorageAddressSizesList();
    this.getStorageAddressTypesOptions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.showFilterModal && this.props.showFilterModal !== prevProps.showFilterModal) {
      this.branchOptions();
      this.depositsOptionsList();
    }
  }

  setValue = async (attr, value) => {
    if (attr === 'unitaryValueFrom' || attr === 'unitaryValueTo') {
      const valueNumber = parseFloat(value.replace(/[^\d,.-]/g, '').replaceAll(',', '').replaceAll('.', ''));
      await this.setState({
        [`${attr}`]: valueNumber,
        [`${attr}Formatted`]: valueNumber
          ? Number(valueNumber).toLocaleString('pt-BR', {
            style: 'currency', currency: 'BRL', minimumFractionDigits: 0, maximumFractionDigits: 0,
          })
          : '',
      });
    } else if (attr === 'onlyAddressesType' && value === '2') {
      this.setState({
        typeFilterSheet: '1',
        selectedMaterialsFamily: [],
        selectedCurves: [],
        productFrom: '',
        productTo: '',
        unitaryValueFrom: 0,
        unitaryValueTo: 0,
        unitaryValueFromFormatted: 0,
        unitaryValueToFormatted: 0,
        onlyAddressesType: value,
      });
    } else {
      await this.setState({
        [`${attr}`]: value,
      });
    }
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });

    if (attr === 'columnSide') {
      const { columnSide } = this.state;

      let columnSideLabel = '';

      const columnSideList = [
        { label: I18n.t('BEE203' /* Ambos */), value: 'both' },
        { label: I18n.t('BEE3398' /* Par */), value: 'even' },
        { label: I18n.t('BEE3399' /* Ímpar */), value: 'odd' },
      ];

      if (columnSide === 'both') {
        columnSideLabel = I18n.t('BEE203' /* Ambos */);
      } else if (columnSide === 'even') {
        columnSideLabel = I18n.t('BEE3398' /* Par */);
      } else if (columnSide === 'odd') {
        columnSideLabel = I18n.t('BEE3399' /* Ímpar */);
      }

      await this.setState({
        columnSideList,
        columnSide,
        columnSideLabel,
      });
    }
  };

  getCurves = async () => {
    await this.props.getAllCurves();
    const curvesCodes = [];
    this.props.curves.map((element) => (curvesCodes.push({
      value: element.code,
      label: `${element.code} - ${element.name}`,
    })));
    this.setState({
      curvesCodes,
    });
  };

  getMaterialsFamilyOptions = async () => {
    const materialsFamily = await this.props.getMaterialsFamilyOptions();
    this.setState({
      materialsFamily,
    });
  };

  getStorageAddressTypesOptions = async () => {
    const storageAddressTypes = await this.props.getStorageAddressTypesOptions();
    this.setState({
      storageAddressTypes,
    });
  };

  getStorageAddressSizesList = async () => {
    await this.props.getStorageAddressSizesList();
    const storageAddressSizes = [];

    this.props.addressSizesList.forEach((element) => {
      storageAddressSizes.push({ value: element.id, label: `${element.code} - ${element.name}` });
    });

    this.setState({
      storageAddressSizes,
    });
  };

  branchOptions = async () => {
    try {
      const listBranch = await this.props.getBranchOptions();
      const userPermission = await this.props.getUserPermissions();

      if (!this.state.branch && userPermission && userPermission.mainBranch) {
        const mainBranch = listBranch.find((branch) => branch.value === userPermission.mainBranch);

        if (mainBranch) {
          this.setState({
            branch: mainBranch.value,
            branchName: mainBranch.label,
          });
        }
      }

      this.setState({
        listBranch: [...listBranch || []],
      });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE1408' /* Gerar Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1408' /* Gerar Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  depositsOptionsList = async () => {
    try {
      const listDeposit = await this.props.getDepositsOptionsList();
      const defaultDeposit = listDeposit.find((element) => element.value === 'RV1');
      await this.setState({
        listDeposit: [...listDeposit || []],
        ...(defaultDeposit && { deposit: defaultDeposit.value }),
        ...(defaultDeposit && { depositName: defaultDeposit.label }),
      });

      this.props.setValueList('listDeposit', [...listDeposit || []]);
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE1408' /* Gerar Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1408' /* Gerar Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  listToGenerateInventory = () => {
    const {
      codeFrom, codeTo, sectorFrom, sectorTo, streetFrom, streetTo, levelFrom, levelTo,
      columnFrom, columnTo, columnSide, drawerFrom, drawerTo, productFrom, productTo, countsFrom, countsTo,
      considerEmptyAddress, topAddress, branch, deposit, selectedCurves, movingDateTo, movingDateFrom, movingType,
      unitaryValueFrom, unitaryValueTo, selectedMaterialsFamily, selectedStorageAddressSizes,
      selectedStorageAddressTypes, onlyAddressesType, typeFilter, typeFilterSheet,
    } = this.state;

    const { jsonCsv = [] } = this.props;
    const filterSheet = [];

    const filterCurves = [];

    if (!deposit) {
      addNotification(
        'danger',
        I18n.t('BEE2386' /* Verifique os Filtros */),
        I18n.t('BEE1330' /* Depósito deve ser informado ! */),
        'top-right',
      );
      return;
    }

    if ((!movingDateFrom && movingDateTo) || (movingDateFrom && !movingDateTo)) {
      addNotification(
        'danger',
        I18n.t('BEE2386' /* Verifique os Filtros */),
        I18n.t('BEE3512' /* Data de movimentação não informada! */),
        'top-right',
      );
      return;
    }

    if (movingDateFrom && movingDateTo && !['1', '2', '3'].includes(movingType)) {
      addNotification(
        'danger',
        I18n.t('BEE2386' /* Verifique os Filtros */),
        I18n.t('BEE3532' /* Tipo de Movimentação não informada! */),
        'top-right',
      );
      return;
    }

    selectedCurves.map((element) => (
      filterCurves.push(element.value)
    ));

    // Filtro Planilha
    if (typeFilter === 2) {
      if (jsonCsv.length <= 0) {
        addNotification(
          'danger',
          I18n.t('BEE2386' /* Verifique os Filtros */),
          I18n.t('BEE3537' /* É necessário realizar a importação do CSV! */),
          'top-right',
        );
        return;
      }

      if (typeFilterSheet === '1') {
        // Endereço
        jsonCsv.forEach((item) => {
          if (item.storageAddressCode && item.storageAddressCode.trim()) {
            filterSheet.push(item.storageAddressCode.trim());
          }
        });
      } else if (typeFilterSheet === '2') {
        // Produto
        jsonCsv.forEach((item) => {
          if (item.productCode && item.productCode.trim()) filterSheet.push(item.productCode.trim());
        });
      } else if (typeFilterSheet === '3') {
        // Ambos
        jsonCsv.forEach((item) => {
          if (item.storageAddressCode
            && item.productCode
            && item.productCode.trim()
            && item.storageAddressCode.trim()) {
            filterSheet.push(`${item.storageAddressCode.trim()} / ${item.productCode.trim()}`);
          }
        });
      }
    }

    const filters = returnFiltersByRules([
      ['addressCode', { type: 'between', value: [codeFrom.toLocaleUpperCase(), codeTo.toLocaleUpperCase()] }],
      ['productCode', { type: 'between', value: [productFrom, productTo] }],
      ['sector', { type: 'between', value: [sectorFrom.toLocaleUpperCase(), sectorTo.toLocaleUpperCase()] }],
      ['street', { type: 'between', value: [streetFrom.toLocaleUpperCase(), streetTo.toLocaleUpperCase()] }],
      ['column', { type: 'between', value: [columnFrom.toLocaleUpperCase(), columnTo.toLocaleUpperCase()] }],
      ['level', { type: 'between', value: [levelFrom.toLocaleUpperCase(), levelTo.toLocaleUpperCase()] }],
      ['drawer', { type: 'between', value: [drawerFrom.toLocaleUpperCase(), drawerTo.toLocaleUpperCase()] }],
      ['counts', { type: 'between', value: [parseInt(countsFrom || 0, 10), parseInt(countsTo || 999, 10)] }],
      ['curve', { type: 'in', value: filterCurves }],
      ['branchCode', { type: 'and', value: branch }],
      ['depositCode', { type: 'and', value: deposit }],
      ['considerEmptyAddress', { type: 'and', value: considerEmptyAddress }],
      ['columnSide', columnSide],
      ['onlyAddressesType', onlyAddressesType],
      ['topAddress', { type: 'and', value: topAddress }],
      ['movingDate', {
        type: 'between',
        movingType,
        value: [
          movingDateFrom ? moment(movingDateFrom).startOf('day').format() : '',
          movingDateTo ? moment(movingDateTo).endOf('day').format() : '',
        ],
      },
      ],
      ['unitaryValue', { type: 'between', value: [unitaryValueFrom, unitaryValueTo] }],
      ['materialsFamily', { type: 'in', value: selectedMaterialsFamily }],
      ['storageAddressSizes', { type: 'in', value: selectedStorageAddressSizes }],
      ['storageAddressTypes', { type: 'in', value: selectedStorageAddressTypes }],
      ['filterSheet', { type: 'in', value: filterSheet, typeFilterSheet }],
    ]);

    this.props.getListToGenerateInventory(filters);
  };

  handleMultiSelected = async (event, attr) => {
    const selected = [];
    event.map((element) => (
      selected.push({ value: element.value, label: element.label })
    ));

    await this.setState({
      [`${attr}`]: selected,
    });
  };

  clearImportSheet = async () => {
    await this.props.setJsonContent(null);
  };

  render() {
    const {
      codeFrom, codeTo, sectorFrom, sectorTo, streetFrom, streetTo, levelFrom, levelTo,
      columnFrom, columnTo, drawerFrom, drawerTo, productFrom, productTo, countsFrom, countsTo,
      branch, branchName, listBranch, depositName, deposit, curvesCodes,
      listDeposit, columnSide, columnSideLabel, columnSideList,
      selectedStorageAddressSizes, storageAddressSizes, onlyAddressesType,
      movingDateFrom, movingDateTo, movingType, topAddress, storageAddressTypes,
      unitaryValueFromFormatted, unitaryValueToFormatted, materialsFamily, selectedMaterialsFamily, selectedCurves,
      selectedStorageAddressTypes, typeFilter, typeFilterSheet, page,
    } = this.state;
    const { showFilterModal = false, jsonCsv = [] } = this.props;

    return (
      <Modal size="xl" isOpen={showFilterModal} toggle={this.props.closeFilters} className="adjustmentPadding">
        <ModalHeader toggle={this.props.closeFilters}>{I18n.t('BEE546' /* Filtros */).toUpperCase()}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col" style={{ borderRight: 'solid 1px #d5dbe0' }}>
              <div className="pt-4 add_display_flex">
                <Form
                  noPanel
                  leftType
                  setValue={this.setValue}
                  setValueDrop={this.setValueDrop}
                  inputs={(formContext) => ([
                    formContext.createSelectDropDown(
                      { value: branch, label: branchName },
                      'branch',
                      `${I18n.t('BEE145' /* Filial */)}:`,
                      listBranch,
                      false,
                      undefined,
                      ['col-md-3', 'col-md-8'],
                      undefined,
                      undefined,
                      undefined,
                      true,
                    ),
                    formContext.createSelectDropDown(
                      { value: deposit, label: depositName },
                      'deposit',
                      `${I18n.t('BEE182' /* Depósito */)}:`,
                      listDeposit,
                      false,
                      undefined,
                      ['col-md-3', 'col-md-8'],
                      undefined,
                      undefined,
                      false,
                      true,
                    ),
                    formContext.customComponent((
                      <div className="form-group row m-b-15" style={{ height: 'auto' }}>
                        <span className="col-form-label col-md-3">
                          {`${I18n.t('BEE1080' /* Família de Materiais */)}:`}
                        </span>
                        <div className="col-md-8">
                          <Select
                            value={selectedMaterialsFamily}
                            isMulti
                            onChange={(event) => this.handleMultiSelected(event || [], 'selectedMaterialsFamily')}
                            closeMenuOnSelect={false}
                            blurInputOnSelect={false}
                            isSearchable
                            styles={{
                              control: (defaultStyles) => ({
                                ...defaultStyles,
                                minHeight: '38px',
                                border: '1px solid #ccc',
                                boxShadow: 'none',
                                color: '#ccc',
                                backgroundColor: '#fff',
                                borderRadius: '5px',
                                fontFamily: 'Open Sans", sans-serif',
                                fontWeight: '400',
                              }),
                              placeholder: (defaultStyles) => ({
                                ...defaultStyles,
                                color: '#333',
                                fontSize: '12px',
                              }),
                              dropdownIndicator: (defaultStyles) => ({
                                ...defaultStyles,
                                paddingTop: 0,
                                paddingBottom: 0,
                                color: '#ccc',
                              }),
                              clearIndicator: (defaultStyles) => ({
                                ...defaultStyles,
                                paddingTop: 0,
                                paddingBottom: 0,
                              }),
                            }}
                            maxMenuHeight={300}
                            placeholder={I18n.t('BEE3518' /* Todas as famílias foram selecionadas */)}
                            options={materialsFamily}
                            isDisabled={onlyAddressesType === '2'}
                          />
                        </div>
                      </div>
                    )),
                    formContext.customComponent((
                      <div className="form-group row m-b-15" style={{ height: 'auto' }}>
                        <span className="col-form-label col-md-3">
                          {`${I18n.t('BEE3523' /* Curvas de Produtos */)}:`}
                        </span>
                        <div className="col-md-8">
                          <Select
                            value={selectedCurves}
                            isMulti
                            onChange={(event) => this.handleMultiSelected(event || [], 'selectedCurves')}
                            closeMenuOnSelect={false}
                            blurInputOnSelect={false}
                            isSearchable
                            styles={{
                              control: (defaultStyles) => ({
                                ...defaultStyles,
                                minHeight: '38px',
                                border: '1px solid #ccc',
                                boxShadow: 'none',
                                color: '#ccc',
                                backgroundColor: '#fff',
                                borderRadius: '5px',
                                fontFamily: 'Open Sans", sans-serif',
                                fontWeight: '400',
                              }),
                              placeholder: (defaultStyles) => ({
                                ...defaultStyles,
                                color: '#333',
                                fontSize: '12px',
                              }),
                              dropdownIndicator: (defaultStyles) => ({
                                ...defaultStyles,
                                paddingTop: 0,
                                paddingBottom: 0,
                                color: '#ccc',
                              }),
                              clearIndicator: (defaultStyles) => ({
                                ...defaultStyles,
                                paddingTop: 0,
                                paddingBottom: 0,
                              }),
                            }}
                            maxMenuHeight={300}
                            placeholder={I18n.t('BEE2478' /* Todas as curvas estão selecionadas */)}
                            options={curvesCodes}
                            isDisabled={onlyAddressesType === '2'}
                          />
                        </div>
                      </div>
                    )),
                    formContext.customComponent((
                      <div className="form-group row m-b-15" style={{ height: 'auto' }}>
                        <span className="col-md-3">
                          {`${I18n.t('BEE3522' /* Classificações de Endereço */)}:`}
                        </span>
                        <div className="col-md-8">
                          <Select
                            value={selectedStorageAddressSizes}
                            isMulti
                            onChange={(event) => this.handleMultiSelected(event || [], 'selectedStorageAddressSizes')}
                            closeMenuOnSelect={false}
                            blurInputOnSelect={false}
                            isSearchable
                            styles={{
                              control: (defaultStyles) => ({
                                ...defaultStyles,
                                minHeight: '38px',
                                border: '1px solid #ccc',
                                boxShadow: 'none',
                                color: '#ccc',
                                backgroundColor: '#fff',
                                borderRadius: '5px',
                                fontFamily: 'Open Sans", sans-serif',
                                fontWeight: '400',
                              }),
                              placeholder: (defaultStyles) => ({
                                ...defaultStyles,
                                color: '#333',
                                fontSize: '12px',
                              }),
                              dropdownIndicator: (defaultStyles) => ({
                                ...defaultStyles,
                                paddingTop: 0,
                                paddingBottom: 0,
                                color: '#ccc',
                              }),
                              clearIndicator: (defaultStyles) => ({
                                ...defaultStyles,
                                paddingTop: 0,
                                paddingBottom: 0,
                              }),
                            }}
                            maxMenuHeight={300}
                            placeholder={I18n.t('BEE3519' /* Todas as classificações foram selecionadas. */)}
                            options={storageAddressSizes}
                          />
                        </div>
                      </div>
                    )),
                    formContext.customComponent((
                      <div className="form-group row m-b-15" style={{ height: 'auto' }}>
                        <span className="col-form-label col-md-3">
                          {`${I18n.t('BEE732' /* Tipos de Endereço */)}:`}
                        </span>
                        <div className="col-md-8">
                          <Select
                            value={selectedStorageAddressTypes}
                            isMulti
                            onChange={(event) => this.handleMultiSelected(event || [], 'selectedStorageAddressTypes')}
                            closeMenuOnSelect={false}
                            blurInputOnSelect={false}
                            isSearchable
                            styles={{
                              control: (defaultStyles) => ({
                                ...defaultStyles,
                                minHeight: '38px',
                                border: '1px solid #ccc',
                                boxShadow: 'none',
                                color: '#ccc',
                                backgroundColor: '#fff',
                                borderRadius: '5px',
                                fontFamily: 'Open Sans", sans-serif',
                                fontWeight: '400',
                              }),
                              placeholder: (defaultStyles) => ({
                                ...defaultStyles,
                                color: '#333',
                                fontSize: '12px',
                              }),
                              dropdownIndicator: (defaultStyles) => ({
                                ...defaultStyles,
                                paddingTop: 0,
                                paddingBottom: 0,
                                color: '#ccc',
                              }),
                              clearIndicator: (defaultStyles) => ({
                                ...defaultStyles,
                                paddingTop: 0,
                                paddingBottom: 0,
                              }),
                            }}
                            maxMenuHeight={300}
                            placeholder={I18n.t('BEE3520' /* Todos os tipos de endereços estão selecionados. */)}
                            options={storageAddressTypes}
                          />
                        </div>
                      </div>
                    )),
                    formContext.createInput(
                      topAddress,
                      'topAddress',
                      `${I18n.t('BEE3516' /* Top Endereços Mais Separados */)}:`,
                      '',
                      'number',
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      ['form-group row m-b-15', 'col-md-3', 'col-md-8'],
                    ),
                    formContext.createInputRange(
                      { from: movingDateFrom, to: movingDateTo },
                      { from: 'movingDateFrom', to: 'movingDateTo' },
                      `${I18n.t('BEE2039' /* Data Movimentação */)}:`,
                      { from: '__/__/_____', to: '__/__/____' },
                      { from: 'date', to: 'date' },
                      undefined,
                      false,
                      {
                        id: 'movingDateId',
                        type: 'info',
                        style: {
                          color: 'blue', position: 'absolute', top: 0, right: 0,
                        },
                        place: 'top',
                        content: I18n.t('BEE3514'),
                        /* Período de datas em que houve movimentações de qualquer produto no endereço. */

                      },
                      undefined,
                      false,
                      undefined,
                      ['form-group row m-b-15', 'col-form-label col-md-3 position-relative text-nowrap'],
                      'movingDateId',
                    ),
                    movingDateFrom && movingDateTo
                    && formContext.createRadioButtons(
                      [{
                        label: I18n.t('BEE201' /* Entrada */),
                        value: 1,
                      }, {
                        label: I18n.t('BEE202' /* Saída */),
                        value: 2,
                      }, {
                        label: I18n.t('BEE203' /* Ambos */),
                        value: 3,
                      },
                      ],
                      movingType,
                      'movingType',
                      `${I18n.t('BEE3531' /* Tipo Movimentação */)}:`,
                      undefined,
                      undefined,
                      'col-md-6 mt-3',
                      ['col-md-3 mt-3'],
                    ),
                    formContext.createRadioButtons(
                      [{
                        label: I18n.t('BEE3525' /* Com Saldo */),
                        value: 1,
                      }, {
                        label: I18n.t('BEE3526' /* Sem Saldo */),
                        value: 2,
                      }, {
                        label: I18n.t('BEE3527' /* Com e Sem Saldo */),
                        value: 3,
                      },
                      {
                        label: I18n.t('BEE3513' /* Com Pallet Completo */),
                        value: 4,
                      },
                      {
                        label: I18n.t('BEE3529' /* Abaixo do Saldo Mínimo */),
                        value: 5,
                      },
                      {
                        label: I18n.t('BEE3528' /* Abaixo do Ponto de Ressuprimento */),
                        value: 6,
                      },
                      ],
                      onlyAddressesType,
                      'onlyAddressesType',
                      `${I18n.t('BEE3530' /* Somente Endereços */)}:`,
                      'form-check',
                      undefined,
                      'd-flex flex-column justify-content-center',
                      ['col-md-4'],
                    ),

                  ])}
                />
              </div>
            </div>
            <div className="col" style={{ borderLeft: 'solid 1px #d5dbe0' }}>
              <div className="pt-4">
                <Nav tabs justified fill style={{ backgroundColor: 'lightgray', marginBottom: 15 }}>
                  <NavItem>
                    <NavLink
                      className={typeFilter === 1 ? 'active' : ''}
                      onClick={() => {
                        this.setState({ typeFilter: 1 });
                      }}
                    >
                      <span className="d-sm-none">Tab 1</span>
                      <span className="d-sm-block d-none">{I18n.t('BEE581' /* Range */)}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={typeFilter === 2 ? 'active' : ''}
                      onClick={() => {
                        this.setState({ typeFilter: 2 });
                      }}
                    >
                      <span className="d-sm-none">Tab 2</span>
                      <span className="d-sm-block d-none">{I18n.t('BEE3533' /* Planilha */)}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                {typeFilter === 1
                  ? (
                    <Form
                      noPanel
                      leftType
                      setValue={this.setValue}
                      setValueDrop={this.setValueDrop}
                      inputs={(formContext) => ([
                        formContext.createInputRange(
                          { from: codeFrom.toLocaleUpperCase(), to: codeTo.toLocaleUpperCase() },
                          { from: 'codeFrom', to: 'codeTo' },
                          `${I18n.t('BEE267' /* Endereço */)}:`,
                          { from: '', to: 'Z'.repeat(20) },
                        ),
                        formContext.createInputRange(
                          { from: sectorFrom.toLocaleUpperCase(), to: sectorTo.toLocaleUpperCase() },
                          { from: 'sectorFrom', to: 'sectorTo' },
                          `${I18n.t('BEE279' /* Setor */)}:`,
                          { from: '', to: 'Z'.repeat(20) },
                        ),
                        formContext.createInputRange(
                          { from: streetFrom.toLocaleUpperCase(), to: streetTo.toLocaleUpperCase() },
                          { from: 'streetFrom', to: 'streetTo' },
                          `${I18n.t('BEE121' /* Rua */)}:`,
                          { from: '', to: 'Z'.repeat(20) },
                        ),
                        formContext.createInputRange(
                          { from: columnFrom.toLocaleUpperCase(), to: columnTo.toLocaleUpperCase() },
                          { from: 'columnFrom', to: 'columnTo' },
                          `${I18n.t('BEE281' /* Coluna */)}:`,
                          { from: '', to: 'Z'.repeat(20) },
                        ),
                        formContext.createSelectDropDown(
                          { value: columnSide, label: columnSideLabel },
                          'columnSide',
                          `${I18n.t('BEE3397' /* Gerar Lados (Coluna) */)}:`,
                          columnSideList,
                          false,
                          undefined,
                          ['col-md-2', 'col-md-9'],
                          undefined,
                          undefined,
                          false,
                        ),
                        formContext.createInputRange(
                          { from: levelFrom.toLocaleUpperCase(), to: levelTo.toLocaleUpperCase() },
                          { from: 'levelFrom', to: 'levelTo' },
                          `${I18n.t('BEE283' /* Nível */)}:`,
                          { from: '', to: 'Z'.repeat(20) },
                        ),
                        formContext.createInputRange(
                          { from: drawerFrom.toLocaleUpperCase(), to: drawerTo.toLocaleUpperCase() },
                          { from: 'drawerFrom', to: 'drawerTo' },
                          `${I18n.t('BEE285' /* Gaveta */)}:`,
                          { from: '', to: 'Z'.repeat(20) },
                        ),
                        formContext.createInputRange(
                          { from: countsFrom, to: countsTo },
                          { from: 'countsFrom', to: 'countsTo' },
                          `${I18n.t('BEE3208' /* Contagens */)}:`,
                          { from: '0', to: '9'.repeat(3) },
                        ),
                        formContext.createInputRange(
                          { from: productFrom, to: productTo },
                          { from: 'productFrom', to: 'productTo' },
                          `${I18n.t('BEE225' /* Produto */)}:`,
                          { from: '', to: 'Z'.repeat(20) },
                          undefined,
                          undefined,
                          { from: onlyAddressesType === '2', to: onlyAddressesType === '2' },
                        ),
                        formContext.createInputRange(
                          { from: unitaryValueFromFormatted, to: unitaryValueToFormatted },
                          { from: 'unitaryValueFrom', to: 'unitaryValueTo' },
                          `${I18n.t('BEE2172' /* Valor Unitário */)}:`,
                          { from: 'R$ 0', to: 'R$ 9.999.999.999' },
                          undefined,
                          undefined,
                          { from: onlyAddressesType === '2', to: onlyAddressesType === '2' },
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          ['form-group row m-b-15', 'col-md-2'],
                        ),
                      ])}
                    />
                  )
                  : (
                    <div>
                      <Form
                        noPanel
                        leftType
                        setValue={this.setValue}
                        inputs={(formContext) => ([
                          formContext.createRadioButtons(
                            [{
                              label: I18n.t('BEE20' /* Endereços */),
                              value: 1,
                            }, {
                              label: I18n.t('BEE27' /* Produtos */),
                              value: 2,
                              disabled: onlyAddressesType === '2',
                            }, {
                              label: I18n.t('BEE203' /* Ambos */),
                              value: 3,
                              disabled: onlyAddressesType === '2',
                            },
                            ],
                            typeFilterSheet,
                            'typeFilterSheet',
                            `${I18n.t('BEE3535' /* Filtrar por */)}:`,
                            undefined,
                            undefined,
                            'col-md-6',
                            ['col-md-3'],
                          ),
                        ]
                        )}
                      />
                      {
                        jsonCsv.length === 0
                          ? (
                            <div>
                              <ImportCaption
                                className="legendImportCaptionLower"
                                headers={[
                                  { label: 'productCode', key: 'productCode' },
                                  { label: 'storageAddressCode', key: 'storageAddressCode' },
                                ]}
                                data={[
                                  {
                                    productCode: '10',
                                    storageAddressCode: 'ENDERECO01',
                                  },
                                  {
                                    productCode: '1000',
                                    storageAddressCode: 'ENDERECO02',
                                  },
                                ]}
                                filename="GerarFichaDeInventario.csv"
                                legend={[
                                  {
                                    nameField: 'productCode',
                                    observationField: I18n.t('BEE1999' /* Código Produto */),
                                  }, {
                                    nameField: 'storageAddressCode',
                                    observationField: I18n.t('BEE1755' /* Código Endereço */),
                                  },
                                ]}
                              />
                              <DragAndDrop buttonClear className="dragAndDropImportCaptionLower mt-1" />
                            </div>
                          )
                          : (
                            <div>
                              <button
                                type="button"
                                className="btn btn-120 btn-primary p-5 mb-3"
                                onClick={this.clearImportSheet}
                              >
                                <div className="d-flex align-items-center justify-content-around center">
                                  <i className="fa fa-arrow-left" />
                                  {I18n.t('BEE137' /* Voltar */)}
                                </div>
                              </button>
                              <Table
                                downloadCSV
                                filterable
                                data={jsonCsv}
                                columns={this.csvPreviewTable}
                                expander
                                defaultPageSize={5}
                                defaultSorted={this.defaultSorted}
                                page={(page) || ((!this.state.firstRun
                                  && this.props.location.state && this.props.location.state.dice
                                  && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
                                onPageChange={(Page) => ((Page >= 0)
                                  ? this.setState({ page: Page })
                                  : this.setState({ page: 0 }))}
                                defaultFilterMethod={(filter, row) => {
                                  const input = _.lowerCase(filter.value);
                                  const value = _.lowerCase(row[filter.id]);
                                  if (_.includes(value, input)) {
                                    return true;
                                  }
                                }}
                              />
                            </div>
                          )
                      }

                    </div>
                  )}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-120 btn-white p-5 m-5" onClick={this.props.closeFilters}>
            {I18n.t('BEE99' /* Cancelar */)}
          </button>
          <button type="button" className="btn btn-120 btn-primary p-5 m-5" onClick={this.listToGenerateInventory}>
            {I18n.t('BEE407' /* Buscar */)}
          </button>
        </ModalFooter>
      </Modal>

    );
  }
}

const mapStateToProps = (state) => ({
  curves: state.curves && state.curves.curvesList,
  addressSizesList: state.storageAddressSizes.storageAddressSizesList,
  jsonCsv: state.bulkImport.jsonCsv || [],
});

const mapDispatchToProps = (dispatch) => ({
  getBranchOptions: () => dispatch(getBranchOptions()),
  getDepositsOptionsList: () => dispatch(getDepositsOptionsList()),
  getUserPermissions: () => dispatch(getUserPermissions()),
  getAllCurves: () => dispatch(getAllCurves()),
  getMaterialsFamilyOptions: () => dispatch(getMaterialsFamilyOptions()),
  getStorageAddressSizesList: () => dispatch(getStorageAddressSizesList()),
  getStorageAddressTypesOptions: () => dispatch(getStorageAddressTypesOptions()),
  setJsonContent: (jsonCsv) => dispatch(setJsonContent(jsonCsv)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FilteringModal));
