import 'react-table/react-table.css';
import React from 'react';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

import Table from '../../../components/table/Table';

import InboundOrderHelpers from '../../../helpers/inboundOrders';
import FormatHelpers from '../../../helpers/format';

export default class LotTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableLotsColumns = [
      {
        Header: I18n.t('BEE425' /* Batismo */),
        accessor: 'baptismLabel',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'productName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 400,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE2978' /* Agrupadora */),
        accessor: 'serialGroupCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      },
      {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{InboundOrderHelpers.inboundOrderProductStatus(row.value)}</span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE3196' /* Armazenada */),
        accessor: 'quantityStorage',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE465' /* Danificada */),
        accessor: 'quantityDamage',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE434' /* Data de Validade */),
        accessor: 'expDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE468' /* Usuário Armazenagem */),
        accessor: 'storageUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE469' /* Data Armazenagem */),
        accessor: 'storageProductAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      },
    ];
  }

  render() {
    const { productLotsPanel = [] } = this.props;

    return (
      <Table
        panelHeaderProps={{
          noButton: true,
          children: I18n.t('BEE442' /* Lotes */).toUpperCase(),
        }}
        filterable
        data={productLotsPanel}
        columns={this.tableLotsColumns}
        expander
        showPagination={false}
        pageSize={productLotsPanel.length > 0 ? productLotsPanel.length : 1}
        defaultSorted={[{ id: 'baptismLabel', desc: false }]}
        defaultFilterMethod={(filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row[filter.id]);
          if (_.includes(value, input)) {
            return true;
          }
        }}
      />
    );
  }
}
