import { I18n } from 'react-redux-i18n';

function userType(type) {
  switch (type) {
    case 1:
      return I18n.t('BEE510' /* Master */);
    case 2:
      return I18n.t('BEE511' /* Administrador */);
    case 3:
      return I18n.t('BEE512' /* Operacional */);
    case 4:
      return I18n.t('BEE513' /* Integração */);
    default:
      return type;
  }
}

function listUserType() {
  return [
    { value: 1, label: I18n.t('BEE510' /* Master */) },
    { value: 2, label: I18n.t('BEE511' /* Administrador */) },
    { value: 3, label: I18n.t('BEE512' /* Operacional */) },
    { value: 4, label: I18n.t('BEE513' /* Integração */) }
  ];
}

function userStatus(status) {
  switch (status) {
    case false:
      return I18n.t('BEE2492' /* Desativado */);
    case true:
      return I18n.t('BEE499' /* Ativo */);
    default:
      return status;
  }
}

function listUserStatus() {
  return [
    { value: false, label: I18n.t('BEE2492' /* Desativado */) },
    { value: true, label: I18n.t('BEE499' /* Ativo */) },
  ];
}

export default {
  userType,
  listUserType,
  userStatus,
  listUserStatus,
};
