import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import {
  getListAndCountAllResupply,
  updateResupply,
  updateCancelResupply,
} from '../../../app/store/actions/resupply';
import { getListUsersPicking } from '../../../app/store/actions/users';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';
import resupplyHelpers from '../../../helpers/resupply';
import FormatHelpers from '../../../helpers/format';

class ResupplyMonitorList extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE2642' /* Ressuprimento */) },
      { value: I18n.t('BEE2645' /* Monitor de Ressuprimento */), active: true },
    ];
    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      data: [],
      pages: 0,
      totalDataLength: 0,
      defaultPageSize: 10,
      defaultSorted: [{ id: 'id', desc: false }],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
      showModalUrgent: false,
      showCancelModal: false,
      resupplySelected: null,
      userAssignmentModal: false,
      userOptions: [],
      responsibleUserId: null,
    };

    this.userTableColumnsUserAssignment = [
      {
        Header: I18n.t('BEE623' /* Login */),
        accessor: 'login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      },
    ];

    this.tableColumns = [{
      Header: I18n.t('BEE55' /* Ações */),
      accessor: 'action',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      width: 100,
      Cell: (rows) => (
        <div style={{ textAlign: 'center' }}>
          <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
          {[1, 2].includes(rows.original.status) && (
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                {[1].includes(rows.original.status) && (
                  (!rows.original.urgent) ? (
                    <DropdownItem onClick={() => this.setShowModal(rows.original, 1)}>
                      {I18n.t('BEE613' /* Urgente */)}
                    </DropdownItem>
                  ) : (
                    <DropdownItem onClick={() => this.setShowModal(rows.original, 1)}>
                      {I18n.t('BEE2637' /* Remover Urgência */)}
                    </DropdownItem>
                  )
                )}
                {(!rows.original.resupplyUser) ? (
                  <DropdownItem onClick={() => this.showUserAssignmentModal(rows.original)}>
                    {I18n.t('BEE2701' /* Definir Responsável */)}
                  </DropdownItem>
                ) : (
                  <DropdownItem onClick={() => this.showUserAssignmentModal(rows.original)}>
                    {I18n.t('BEE2700' /* Alterar Responsável */)}
                  </DropdownItem>
                )}
                <DropdownItem onClick={() => this.setShowModal(rows.original, 2)}>
                  {I18n.t('BEE99' /* Cancelar */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          )}
        </div>
      ),
    }, {
      Header: I18n.t('BEE2769' /* Id */),
      accessor: 'id',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
      Filter: this.filterColumn,
    }, {
      Header: I18n.t('BEE1795' /* Depósito Retirada */),
      accessor: 'depositCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 140,
      Filter: this.filterColumn,
    }, {
      Header: I18n.t('BEE200' /* Tipo */),
      accessor: 'resupplyWaveId',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 106,
      Filter: ({ onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={(event) => { onChange(event.target.value); }}
        >
          <option value="">{I18n.t('BEE793' /* Todos */)}</option>
          <option value="Kanban">{I18n.t('BEE2875' /* Kanban */)}</option>
          <option value="Normal">{I18n.t('BEE733' /* Normal */)}</option>
        </select>
      ),
      Cell: (row) => ((row.value) ? I18n.t('BEE2875' /* Kanban */) : I18n.t('BEE733' /* Normal */)),
    }, {
      Header: I18n.t('BEE1454' /* Onda */),
      accessor: 'resupplyWave.waveNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
      Filter: this.filterColumn,
    }, {
      Header: I18n.t('BEE495' /* Endereço Retirada */),
      accessor: 'pickupAddressCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
      Filter: this.filterColumn,
    }, {
      Header: I18n.t('BEE1910' /* Código do Range */),
      accessor: 'pickupRangeCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 140,
      Filter: this.filterColumn,
    }, {
      Header: I18n.t('BEE225' /* Produto */),
      accessor: 'productCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Filter: this.filterColumn,
    }, {
      Header: I18n.t('BEE2649' /* Quantidade Sugerida */),
      accessor: 'suggestedQuantity',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
      Cell: (row) => (FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)),
      Filter: this.filterColumn,
    }, {
      Header: I18n.t('BEE2656' /* Data Ressuprimento */),
      accessor: 'createdAt',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 155,
      Filter: this.filterColumn,
      Cell: (row) => ((row.value) ? moment(row.value).format('L LTS') : ''),
    }, {
      Header: I18n.t('BEE613' /* Urgente */),
      accessor: 'urgent',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Cell: (row) => ((row.value) ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)),
      Filter: ({ onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={(event) => { onChange(event.target.value); }}
        >
          <option value="">{I18n.t('BEE793' /* Todos */)}</option>
          <option value={1}>{I18n.t('BEE172' /* Sim */)}</option>
          <option value={0}>{I18n.t('BEE173' /* Não */)}</option>
        </select>
      ),
    }, {
      Header: I18n.t('BEE204' /* Situação */),
      accessor: 'status',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 130,
      Cell: (row) => (resupplyHelpers.resupplyStatus(row.value)),
      Filter: ({ onChange }) => (
        <select
          type="text"
          id="filterSelect"
          style={{ width: '100%' }}
          onChange={(event) => { onChange(event.target.value); }}
        >
          <option value="">{I18n.t('BEE793' /* Todos */)}</option>
          {resupplyHelpers.resupplyStatus().map((elem, index) => (
            <option value={elem.value} key={index}>{elem.label}</option>
          ))}
        </select>
      ),
    }, {
      Header: I18n.t('BEE2702' /* Responsável */),
      accessor: 'userResupply.login',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 130,
      Filter: this.filterColumn,
    }, {
      Header: I18n.t('BEE2703' /* Data Início */),
      accessor: 'startDate',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 140,
      Filter: this.filterColumn,
      Cell: (row) => ((row.value) ? moment(row.value).format('L LTS') : ''),
    }, {
      Header: I18n.t('BEE2704' /* Data Fim */),
      accessor: 'finalDate',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 140,
      Filter: this.filterColumn,
      Cell: (row) => ((row.value) ? moment(row.value).format('L LTS') : ''),
    }, {
      Header: I18n.t('BEE2883' /* Depósito Destino */),
      accessor: 'storageDepositCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 140,
      Filter: this.filterColumn,
    }, {
      Header: I18n.t('BEE2651' /* Endereço Destino */),
      accessor: 'storageAddressCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
      Filter: this.filterColumn,
    }, {
      Header: I18n.t('BEE2650' /* Quantidade Ressuprida */),
      accessor: 'quantityResupplied',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 180,
      Cell: (row) => (FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)),
      Filter: this.filterColumn,
    }, {
      Header: I18n.t('BEE355' /* Curva */),
      accessor: 'curve',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
      Filter: this.filterColumn,
    }, {
      Header: I18n.t('BEE1994' /* Código Filial */),
      accessor: 'branchCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 110,
      Filter: this.filterColumn,
    }, {
      Header: I18n.t('BEE758' /* Descrição do Produto */),
      accessor: 'product.fullName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 180,
      Filter: this.filterColumn,
    },
    ];
  }

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getListAndCountAllResupply = async () => {
    const { startDate, endDate } = this.state;

    const data = await this.props.getListAndCountAllResupply({
      page: this.page,
      pageSize: this.pageSize,
      sorted: this.sorted,
      filtered: this.filtered,
      filterStartDate: moment(startDate).format(),
      filterEndDate: moment(endDate).format(),
    });

    const dataUpdate = {};

    if (data && data.success === false) {
      dataUpdate.data = [];
      dataUpdate.pages = 0;
      dataUpdate.totalDataLength = 0;
    } else {
      dataUpdate.data = data.rows;
      dataUpdate.pages = data.pages;
      dataUpdate.totalDataLength = data.totalDataLength;
    }

    this.setState({ ...dataUpdate });
  };

  handleDateApplyEvent = async (event) => {
    const startDate = event[0];
    const endDate = event[1];
    await this.setState({ startDate, endDate });

    this.getListAndCountAllResupply();
  };

  setShowModal = (resupply, typeModal) => {
    const { showModalUrgent, showCancelModal } = this.state;
    const state = (typeModal === 1) ? {
      showModalUrgent: !showModalUrgent,
    } : {
      showCancelModal: !showCancelModal,
    };

    this.setState({
      ...state,
      resupplySelected: resupply,
    });
  };

  updateResupply = async (typeFunction) => {
    const { resupplySelected, responsibleUserId } = this.state;
    let message = I18n.t('BEE2756' /* Alterado com sucesso! */);
    const updateData = {};
    try {
      if (typeFunction === 1) {
        updateData.urgent = !resupplySelected.urgent;
      } else if (typeFunction === 3) {
        updateData.status = 1;
        updateData.resupplyUser = responsibleUserId;
        message = I18n.t('BEE2359' /* Atribuído com sucesso */);
      }
      if (typeFunction === 2) {
        await this.props.updateCancelResupply(resupplySelected.id);
      } else {
        await this.props.updateResupply(resupplySelected.id, updateData);
      }
      addNotification(
        'success',
        I18n.t('BEE2645' /* Monitor de Ressuprimento */),
        message,
        'top-right',
      );
      this.getListAndCountAllResupply();
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2645' /* Monitor de Ressuprimento */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2645' /* Monitor de Ressuprimento */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }

    if (typeFunction !== 3) this.setShowModal(null, typeFunction);
    else this.closeUserAssignmentModal();
  };

  showUserAssignmentModal = async (resupply) => {
    try {
      const userOptions = await this.props.getListUsersPicking(resupply.branchCode, resupply.pickupRangeCode);
      if (userOptions && userOptions.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2645' /* Monitor de Ressuprimento */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        this.setState({
          userAssignmentModal: true,
          resupplySelected: resupply,
          userOptions,
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2645' /* Monitor de Ressuprimento */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2645' /* Monitor de Ressuprimento */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  closeUserAssignmentModal = () => {
    this.setState({
      userOptions: [...[]],
      userAssignmentModal: false,
    });
  };

  render() {
    const {
      defaultPageSize, defaultSorted, totalDataLength, pages, data, showCancelModal,
      showModalUrgent, resupplySelected, userAssignmentModal, userOptions,
    } = this.state;
    return (
      <>
        <Table
          expander
          downloadCSV
          breadcrumb={this.breadcrumb}
          wikiHelp={ROUTES.RESUPPLY_MONITOR_HELP}
          headerTitle={I18n.t('BEE2645' /* Monitor de Ressuprimento */)}
          downButtonPosition
          actionButtons={(
            <div className="ml-auto">
              <Link
                to={{
                  pathname: ROUTES.MOVEMENT_RESUPPLY_GENERATE,
                  state: {
                    showFilterModal: true,
                  },
                }}
                className="btn btn-success btn-sm btn-rounded pl-2 pr-3"
              >
                <i className="fa fa-plus mr-1" />
                {` ${I18n.t('BEE2705' /* Incluir Ressuprimento */)}`}
              </Link>
            </div>
          )}
          filterable
          data={data}
          columns={this.tableColumns}
          defaultSorted={defaultSorted}
          defaultPageSize={defaultPageSize}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          panelHeaderProps={{
            onClickReload: this.getListAndCountAllResupply,
          }}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          totalDataLength={totalDataLength}
          manual
          pages={pages}
          onFetchData={(state) => {
            this.page = state.page;
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;
            this.getListAndCountAllResupply();
          }}
        />

        <Modal size="lg" isOpen={userAssignmentModal} toggle={() => this.closeUserAssignmentModal()}>
          <ModalHeader toggle={() => this.closeUserAssignmentModal()}>
            {I18n.t('BEE2755' /* Atribuir Responsável */).toLocaleUpperCase()}
          </ModalHeader>
          <ModalBody>
            <div className="p-b-15">
              <Table
                expander
                filterable
                data={userOptions}
                defaultPageSize={10}
                pageSizeOptions={[5, 10]}
                panelHeaderProps={{ noButton: true }}
                defaultSorted={[{ id: 'login', desc: false }]}
                columns={this.userTableColumnsUserAssignment}
                defaultFilterMethod={(filter, row) => {
                  const input = _.lowerCase(filter.value);
                  const value = _.lowerCase(row[filter.id]);
                  if (_.includes(value, input)) {
                    return true;
                  }
                }}
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: async () => {
                        await this.setState({ responsibleUserId: rowInfo.original.id });
                        await this.updateResupply(3);
                      },
                      style: {
                        cursor: 'pointer',
                      },
                    };
                  }
                  return {};
                }}
              />
            </div>
          </ModalBody>
        </Modal>

        {(showModalUrgent
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1849' /* Continuar */)}
              cancelBtnBsStyle="default"
              confirmBtnBsStyle="primary"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.updateResupply(1)}
              onCancel={() => this.setShowModal(null, 1)}
            >
              <span>
                {resupplySelected.urgent
                  ? I18n.t(
                    'BEE2754', /* O Ressuprimento terá sua Urgência removida e perderá sua prioridade no processo */
                  )
                  : I18n.t('BEE2753' /* O Ressuprimento vai ser alterado para Urgente e terá prioridade no processo */)}
              </span>
            </SweetAlert>
          )
        )}

        {(showCancelModal
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1849' /* Continuar */)}
              cancelBtnBsStyle="default"
              confirmBtnBsStyle="primary"
              title={I18n.t('BEE99' /* Cancelar */)}
              onConfirm={() => this.updateResupply(2)}
              onCancel={() => this.setShowModal(null, 2)}
            >
              {I18n.t('BEE2746' /* Deseja cancelar este Ressuprimento? */)}
            </SweetAlert>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getListAndCountAllResupply: (searchParameters) => dispatch(getListAndCountAllResupply(searchParameters)),
  updateResupply: (resupplyId, updateData) => dispatch(updateResupply(resupplyId, updateData)),
  updateCancelResupply: (resupplyId) => dispatch(updateCancelResupply(resupplyId)),
  getListUsersPicking: (branchCode, rangeCode) => dispatch(getListUsersPicking(branchCode, rangeCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResupplyMonitorList));
