/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../panel/panel';
import WikiHelp from '../tooltip/wikiHelp';

export class PanelPage extends React.PureComponent {
  render() {
    return (
      <div className={this.props.animationType || 'slideUpTransition'}>
        {!!this.props.breadcrumb && this.props.breadcrumb.length && (
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              {this.props.breadcrumb.map((it, index) => (
                <li key={`${it.value}${index}`} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                  {it.value}
                </li>
              ))}
            </ol>
          </div>
        )}
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {this.props.title}
          </h1>
          {this.props.wikiHelp && (<WikiHelp wikiPath={this.props.wikiHelp} />)}
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <Panel>
              <PanelHeader {...this.props} />
              <PanelBody>
                {this.props.content}
              </PanelBody>
              {!this.props.noFooter && <PanelFooter {...this.props} />}
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}
