import { I18n } from 'react-redux-i18n';

function resupplyCriteria(criteria) {
  if (criteria || criteria === 0) {
    switch (criteria) {
      case 1:
        return I18n.t(
          'BEE2660', /* Utilizar como base a % Ponto de Ressuprimento de Cada Produto */
        );
      case 2:
        return I18n.t(
          'BEE2661', /* Completar o Picking em sua Capacidade Máxima (Desconsiderar Ponto de Ressuprimento) */
        );
      default:
        return criteria;
    }
  } else if (!criteria) {
    return [
      {
        value: 1,
        label: I18n.t(
          'BEE2660', /* Utilizar como base a % Ponto de Ressuprimento de Cada Produto */
        ),
      },
      {
        value: 2,
        label: I18n.t(
          'BEE2661', /* Completar o Picking em sua Capacidade Máxima (Desconsiderar Ponto de Ressuprimento) */
        ),
      },
    ];
  }
  return criteria;
}

export default {
  resupplyCriteria,
};
