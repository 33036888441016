/* eslint-disable no-restricted-syntax */
import { endOfDay, startOfDay, subDays } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
  PopoverHeader, UncontrolledPopover,
} from 'reactstrap';
import { getBranchesOptions } from '../../../app/store/actions/branches';
import BarChart from '../../../components/charts/BarChart';
import {
  getAverageTimeDashboardInfo, setSelectedUserBranches, setDashboardOutboundOrdersDates,
} from '../../../app/store/actions/outboundOrders';
import DateRangePicker from '../../../components/date/DateRangePicker.jsx';
import Table from '../../../components/table/Table';
import OutboundOrderHelpers from '../../../helpers/outboundOrder';
import Select from '../../../components/form/select';
import routes from '../../../config/routes';
import OneClickButton from '../../../components/form/button';
import FilterSelectionList from '../../../components/form/filterSelectionList';

import { getOrdersTypeOptions } from '../../../app/store/actions/typeOrder';
import { getCarriersOptions } from '../../../app/store/actions/carriers';

class OutboundOrderDashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      defaultSorted: [{
        id: 'orderNumber',
        desc: false,
      }],
      typeDashboard: 'Document',
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.showOutboundOrderDetail(rows)}
                >
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1096' /* Pedido cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE402' /* Nota Fiscal */),
        accessor: 'invoiceNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{OutboundOrderHelpers.outboundOrderStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            id="filterSelect"
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OutboundOrderHelpers.outboundOrderStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE53' /* Cidade */),
        accessor: 'cityName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1057' /* Entrega */),
        accessor: 'deliveryDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('L')}
          </span>
        ),
        Filter: this.filterColumn,
      },
    ];

    this.breadcrumb = [
      { value: I18n.t('BEE42' /* Dashboard */), iconClassName: 'fa fa-chart-line m-t-10 m-r-5' },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE2517' /* Análise Tempos Médios */), active: true },
    ];
  }

  async componentDidMount() {
    if (!this.props.location.state || !Object.keys(this.props.location.state).length) {
      const startDate = moment().subtract(30, 'd').format();
      const endDate = moment().format();
      const dates = { startDate, endDate };

      await this.props.setDashboardOutboundOrdersDates(dates);
      await this.getOutboundAverageTimeDashboard();

      const listOrderTypes = await this.props.getOrdersTypeOptions();
      const listCarriers = await this.props.getCarriersOptions();

      this.setState({
        listOrderTypes,
        listCarriers,
      });
    }

    this.props.history.push({
      state: {},
    });
  }

  showOutboundOrderDetail = (outboundOrder) => {
    const newBreadcrumb = this.breadcrumb.map((it) => {
      const newIt = { ...it };
      it.active = false;
      return newIt;
    });
    newBreadcrumb.push({ value: I18n.t('BEE1094' /* Detalhes Documento de Saída */), active: true });

    this.props.history.push({
      pathname: routes.OUTBOUND_ORDER_DETAIL,
      state: {
        outboundOrder: outboundOrder.original,
        breadcrumb: newBreadcrumb,
        dashboardAverageTime: true,
      },
    });
  };

  getOutboundAverageTimeDashboard = async () => {
    const {
      startDate, endDate,
    } = this.props.dates;

    const branchesToSearch = [];
    if (this.props.selectedBranches) {
      for (const { value } of this.props.selectedBranches) {
        branchesToSearch.push(value);
      }
    }

    const orderTypesToSearch = [];
    if (this.state.selectedOrderTypes) {
      for (const { value } of this.state.selectedOrderTypes) {
        orderTypesToSearch.push(value);
      }
    }

    const carriersToSearch = [];
    if (this.state.selectedCarriers) {
      for (const { value } of this.state.selectedCarriers) {
        carriersToSearch.push(value);
      }
    }

    await this.props.getAverageTimeDashboardInfo(
      startDate,
      endDate,
      branchesToSearch,
      orderTypesToSearch,
      carriersToSearch,
    );

    const existOrder = {};

    this.setState({
      selectedOrder: existOrder || {},
    });
  };

  handleDateApplyEvent = async (value) => {
    const startDate = moment(value[0]).format();
    const endDate = moment(value[1]).format();
    const dates = { startDate, endDate };

    await this.props.setDashboardOutboundOrdersDates(dates);
  };

  handleBranchesSelected = async (event) => {
    const selectedBranches = [];

    for (const element of event) {
      selectedBranches.push({
        value: element.value,
        label: element.label,
      });
    }

    await this.props.setSelectedUserBranches(selectedBranches);
  };

  handleOrderTypesSelected = async (event) => {
    const selectedOrderTypes = [];

    for (const element of event) {
      selectedOrderTypes.push({
        value: element.value,
        label: element.label,
      });
    }

    this.setState({
      selectedOrderTypes: selectedOrderTypes || {},
    });
  };

  handleCarriersSelected = async (event) => {
    const selectedCarriers = [];

    for (const element of event) {
      selectedCarriers.push({
        value: element.value,
        label: element.label,
      });
    }

    this.setState({
      selectedCarriers: selectedCarriers || {},
    });
  };

  handleTypeDashboard = async (type) => {
    if (this.state.typeDashboard !== type) {
      this.setState({
        typeDashboard: type,
      });
    }
  };

  render() {
    const {
      defaultSorted, selectedOrder, typeDashboard, listOrderTypes, listCarriers,
    } = this.state;
    const { averageTimeCards = [], charts = [], outboundOrdersList = [] } = this.props;
    const startDate = (this.props.dates && this.props.dates.startDate) || startOfDay(subDays(new Date(), 30));
    const endDate = (this.props.dates && this.props.dates.endDate) || endOfDay(new Date());

    return (
      <div>
        {!!this.breadcrumb && this.breadcrumb.length && (
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              {this.breadcrumb.map((it, index) => (
                <li key={`${it.value}${index}`} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                  {
                    it.iconClassName
                      ? (
                        <>
                          <i className="fa fa-chart-line m-t-10 m-r-5" />
                          {' '}
                          {it.value}
                        </>
                      ) : (
                        it.value
                      )
                  }
                </li>
              ))}
            </ol>
          </div>
        )}
        <div className="d-flex align-items-center">
          <h1 className="page-header mb-0">
            {I18n.t('BEE59' /* Dashboard Expedição */)}
          </h1>
        </div>
        <div className="d-flex mt-2">
          <DateRangePicker
            onChange={(value) => {
              this.handleDateApplyEvent(value);
            }}
            defaultValue={[new Date(startDate), new Date(endDate)]}
          />
          <UncontrolledPopover trigger="hover" placement="top" target="dateRangePicker">
            <PopoverHeader>
              { I18n.t('BEE3342', { 0: (I18n.t('BEE3344' /* data de criação */)) } /* O intervalo segue o critério pela %{0} */) }
            </PopoverHeader>
          </UncontrolledPopover>
          {/* Filiais */}
          <div className="d-flex">
            <FilterSelectionList
              value={this.props.selectedBranches}
              onChangeValue={this.handleBranchesSelected}
              placeholderValue={I18n.t('BEE2424' /* Todas as filiais estão selecionadas */)}
              optionsValue={this.props.userBranches}
              onClickValue={this.getOutboundAverageTimeDashboard}
            />
          </div>

          {/* Tipo de Documento */}
          <div className="d-flex ml-1">
            <FilterSelectionList
              value={this.props.selectedOrderTypes}
              onChangeValue={this.handleOrderTypesSelected}
              placeholderValue={I18n.t('BEE3355' /* Todos os tipos de documentos estão selecionados. */)}
              optionsValue={listOrderTypes}
              onClickValue={this.getOutboundAverageTimeDashboard}
            />
          </div>
          {/* Transportadora */}
          <div className="d-flex ml-1">
            <FilterSelectionList
              value={this.props.selectedCarriers}
              onChangeValue={this.handleCarriersSelected}
              placeholderValue={I18n.t('BEE3593' /* Todas as transportadoras estão selecionadas. */)}
              optionsValue={listCarriers}
              onClickValue={this.getOutboundAverageTimeDashboard}
            />
          </div>

        </div>

        <div className="mt-4">
          <h1 className="page-header mb-0">
            {I18n.t('BEE2517' /* Análise Tempos Médios */)}
          </h1>
          <div>
            <OneClickButton
              type="button"
              className={
                  `btn btn-120 ${typeDashboard === 'Document' ? 'btn-primary' : 'btn-white'} btn-rounded p-5 m-5`
                }
              onClick={() => this.handleTypeDashboard('Document')}
            >
              {I18n.t('BEE2559' /* Por Documento */)}

            </OneClickButton>

            <OneClickButton
              type="button"
              className={`btn btn-120 ${typeDashboard === 'Line' ? 'btn-primary' : 'btn-white'} btn-rounded p-5 m-5`}
              onClick={() => this.handleTypeDashboard('Line')}
            >
              {I18n.t('BEE2560' /* Por Linha */)}

            </OneClickButton>
          </div>
        </div>
        <div className="d-flex flex-row mt-2 mb-4 w-100" style={{ height: '300px', fontSize: '1.5rem' }}>
          <div
            className="d-flex flex-column h-100 w-25 rounded bg-dark text-white align-items-center
            justify-content-center text-center"
            style={{ flex: 'none' }}
          >
            <div>
              <p style={{ fontSize: '2.5rem' }}>
                <strong>
                  {averageTimeCards[typeDashboard] ? averageTimeCards[typeDashboard].fromStartToFinishAverageTime : 0}
                </strong>
              </p>
            </div>
            <div>
              <p>{I18n.t('BEE2525' /* Tempo Médio Total */)}</p>
              <p>
                (
                {I18n.t('BEE2526' /* Integração até Expedição */)}
                )
              </p>
            </div>
          </div>
          <div className="d-flex flex-column h-100 ml-3 text-white" style={{ flex: '1' }}>
            <div className="bg-dark h-100 d-flex align-items-center text-left rounded mb-1">
              <p className="my-0 p-0 ml-2">
                <strong>
                  {averageTimeCards[typeDashboard]
                    ? averageTimeCards[typeDashboard].beforeSeparationAverageTime : 0}
                </strong>
                {` - ${I18n.t('BEE2527' /* Até o início da Separação */)}`}
              </p>
            </div>
            <div className="bg-dark h-100 d-flex align-items-center text-left rounded mt-1 mb-1">
              <p className="my-0 p-0 ml-2">
                <strong>
                  {averageTimeCards[typeDashboard]
                    ? averageTimeCards[typeDashboard].inSeparationAverageTime : 0}

                </strong>
                {` - ${I18n.t('BEE1367' /* Em Separação */)}`}
              </p>
            </div>
            <div className="bg-dark h-100 d-flex align-items-center text-left rounded mt-1 mb-1">
              <p className="my-0 p-0 ml-2">
                <strong>
                  {averageTimeCards[typeDashboard]
                    ? averageTimeCards[typeDashboard].inConferenceAverageTime : 0}

                </strong>
                {` - ${I18n.t('BEE1768' /* Em Conferência */)}`}
              </p>
            </div>
            <div className="bg-dark h-100 d-flex align-items-center text-left rounded mt-1 mb-1">
              <p className="my-0 p-0 ml-2">
                <strong>
                  {averageTimeCards[typeDashboard]
                    ? averageTimeCards[typeDashboard].waitingBillingAverageTime : 0}
                </strong>
                {` - ${I18n.t('BEE2529' /* Aguardando Faturamento */)}`}
              </p>
            </div>
            <div className="bg-dark h-100 d-flex align-items-center text-left rounded mt-1">
              <p className="my-0 p-0 ml-2">
                <strong>
                  {averageTimeCards[typeDashboard]
                    ? averageTimeCards[typeDashboard].inExpeditionAverageTime : 0}
                </strong>
                {` - ${I18n.t('BEE2528' /* Em Expedição */)}`}
              </p>
            </div>
          </div>
        </div>
        <div>
          <BarChart
            barChartValues={charts[typeDashboard] && charts[typeDashboard].averageTimeBarChartInfo}
            height={200}
            useFormatter
            decimalNumber={2}
            title={I18n.t('BEE2531' /* Tempo gasto por etapa */)}
            onClickReload={this.getOutboundAverageTimeDashboard}
          />
        </div>
        <div>
          <Table
            downloadCSV
            onClickCollapse
            panelHeaderProps={{
              onClickReload: () => this.getOutboundAverageTimeDashboard(),
              pageDescription: I18n.t('BEE793' /* Todos */),
            }}
            filterable
            data={outboundOrdersList}
            columns={this.tableColumns}
            expander
            defaultPageSize={10}
            defaultSorted={defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            noDataText={I18n.t('BEE2423' /* Não há dados na data informada */)}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  style: {
                    background: selectedOrder && rowInfo.original.id === selectedOrder.id ? 'silver' : 'white',
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  outboundOrdersList: state.outboundOrders && state.outboundOrders.outboundOrdersList,
  averageTimeCards: state.outboundOrders && state.outboundOrders.averageTimeCards,
  charts: state.outboundOrders.charts,
  userBranches: state.outboundOrders.userBranches,
  dates: state.outboundOrders.dates,
  selectedBranches: state.outboundOrders.selectedBranches,
  lineChartCheckData: state.outboundOrders && state.outboundOrders.chartsPanel.lineChartCheck,
  doughnutChartData: state.outboundOrders && state.outboundOrders.chartsPanel.lineProductStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getAverageTimeDashboardInfo:
    (
      startDate,
      endDate,
      selectedBranches,
      selectedOrderTypes,
      selectedCarriers,
    ) => dispatch(getAverageTimeDashboardInfo(
      startDate,
      endDate,
      selectedBranches,
      selectedOrderTypes,
      selectedCarriers,
    )),
  setSelectedUserBranches: (selectedBranches) => dispatch(setSelectedUserBranches(selectedBranches)),
  getOrdersTypeOptions: () => dispatch(getOrdersTypeOptions()),
  getCarriersOptions: () => dispatch(getCarriersOptions()),
  setDashboardOutboundOrdersDates: (dates) => dispatch(setDashboardOutboundOrdersDates(dates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundOrderDashboard));
