/* eslint-disable no-undef */
/* eslint-disable no-empty */
/* eslint-disable no-plusplus */

import $ from 'jquery';

export const appendScript = (scriptToAppend) => {
  const script = document.createElement('script');
  script.src = scriptToAppend;
  script.async = false;
  document.body.appendChild(script);
};

export const removeScript = (scriptToremove) => {
  try {
    const allsuspects = document.getElementsByTagName('script');
    for (let i = allsuspects.length; i >= 0; i--) {
      if (allsuspects[i] && allsuspects[i].getAttribute('src') !== null
        && allsuspects[i].getAttribute('src').indexOf(`${scriptToremove}`) !== -1) {
        allsuspects[i].parentNode.removeChild(allsuspects[i]);
      }
    }
  } catch (e) {}
};

export const scrollToTopAnimated = (timer = 900) => {
  try {
    const funStop = () => {
      $('html, body').stop();
      $(window).off('mousewheel', funStop);
    };

    $(window).on('mousewheel', funStop);

    $('html, body').animate({
      scrollTop: 0,
    }, timer, () => {
      $(window).off('mousewheel', funStop);
    });
  } catch (e) { console.log(e) }
};

export const scrollToElement = (el = 'html, body', timer = 900) => {
  try {
    const funStop = () => {
      $('html, body').stop();
      $(window).off('mousewheel', funStop);
    };

    $(window).on('mousewheel', funStop);

    $('html, body').animate({
      scrollTop: $(el).offset().top - 60,
    }, timer, () => {
      $(window).off('mousewheel', funStop);
    });
  } catch (e) { console.log(e) }
}

export const focusElementById = (el = '') => {
  try {
    document.getElementById(el).focus();
  } catch (e) {}
}

export default {};
