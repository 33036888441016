import RestService from '../services/rest';

async function getOutboundOrdersList(tableParams) {
  return RestService.postAuthenticated('outboundWaves/outboundOrdersList', tableParams);
}

async function generateWaves(outboundOrderIds) {
  return RestService.postAuthenticated('outboundWaves/generate', { outboundOrderIds });
}

async function getOutboundWavesByDate(filterStartDate, filterEndDate) {
  return RestService.getAuthenticated(`outboundWaves/wavesByDate?filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}`);
}

async function getWaveDocuments(outboundWaveId) {
  return RestService.getAuthenticated('outboundWaves/waveDocuments', { outboundWaveId });
}

async function getOpenWaveList() {
  return RestService.getAuthenticated('outboundWaves/openWave');
}

async function addOrderToWave(rangeCode) {
  return RestService.putAuthenticated('outboundWaves/addOrder', { rangeCode });
}

async function startSeparation(outboundWaveId) {
  return RestService.putAuthenticated('outboundWaves/startSeparation', { outboundWaveId });
}

async function updateWaveAndDocuments(outboundRangeId, outboundWaveId) {
  return RestService.putAuthenticated('outboundWaves/updateWaveAndDocuments', { outboundRangeId, outboundWaveId });
}

async function getOutboundWavesProductsList(outboundWaveId) {
  return RestService.getAuthenticated(`outboundWaves/productsList?outboundWaveId=${outboundWaveId}`);
}

async function outboundWavesProductEan(ean, productCode) {
  return RestService.getAuthenticated('outboundWaves/ProductEan', { ean, productCode });
}

export default {
  getOutboundOrdersList,
  generateWaves,
  getOpenWaveList,
  addOrderToWave,
  startSeparation,
  getOutboundWavesProductsList,
  outboundWavesProductEan,
  getOutboundWavesByDate,
  getWaveDocuments,
  updateWaveAndDocuments,
};
