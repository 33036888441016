import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { I18n } from 'react-redux-i18n';

import routes from './../../config/page-route.jsx';
import { PageSettings } from './../../config/page-settings.js';
import PrivateRoute from './PrivateRoute.js';
import PublicRoute from './PublicRoute.js';
import AnimatedTransition from './AnimatedTransition.js';

export const history = createBrowserHistory();

history.listen((location) => {});

function setTitle(path, routeArray) {
  var pageTitle;
  for (var i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      pageTitle = `BeeStock - ${I18n.t(routeArray[i].title)}`;
    }
  }
  document.title = (pageTitle) ? pageTitle : 'BeeStock';
}

class Content extends React.PureComponent {
  componentDidMount() {
    setTitle(this.props.history.location.pathname, routes);
  }
  componentWillMount() {
    this.props.history.listen(() => {
      setTitle(this.props.history.location.pathname, routes);
    });
  }

  renderRouteBasedAuth = (route, routeProps) => {
    if (route.privated === false) return <PublicRoute route={route} routeProps={routeProps} />
    return <PrivateRoute route={route} routeProps={routeProps} />
  }

  render() {
    return (
      <PageSettings.Consumer>
        {({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
          <div className={'content ' + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass}>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact || true}
                  history={history}
                  render={(routeProps) => (
                    <AnimatedTransition route={route} routeProps={routeProps}>
                      {this.renderRouteBasedAuth(route, routeProps)}
                    </AnimatedTransition>
                  )}
                />
              ))}
            </Switch>
          </div>
        )
        }
      </PageSettings.Consumer>
    )
  }
}

export default withRouter(Content);
