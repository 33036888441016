/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import { I18n } from 'react-redux-i18n';

import SweetAlert from 'react-bootstrap-sweetalert';
import OneClickButton from '../form/button';

let singletonModal = null;

export const showReportBackgroundModal = async () => {
  if (singletonModal) {
    return new Promise((resolve, reject) => {
      try {
        const callback = (btnType) => resolve(btnType);
        singletonModal.showModal(callback);
      } catch (e) {
        reject(e);
      }
    });
  }
};

class ReportsBackgroundModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { openModal: false, callback: null };
  }

  componentDidMount() {
    singletonModal = this;
  }

  showModal(callback = () => false) {
    this.setState({ openModal: true, callback });
  }

  closeModal() {
    this.setState({ openModal: false, callback: null });
  }

  onActionChoosed(actionBtn, callback) {
    this.closeModal();
    if (callback) callback(actionBtn);
  }

  render() {
    const { openModal = false, callback } = this.state;

    return (
      openModal && (
        <SweetAlert
          showCancel
          confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
          cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={I18n.t('BEE528' /* Relatório */)}
          onConfirm={() => this.onActionChoosed('cancel', callback)}
          onCancel={() => this.onActionChoosed('cancel', callback)}
          customButtons={(
            <>
              <OneClickButton
                onClick={() => this.onActionChoosed('bg', callback)}
                type="button"
                style={{ width: 'auto', padding: 10 }}
                className="btn btn-danger p-5 m-5"
              >
                {I18n.t('BEE2566' /* Segundo plano */)}
              </OneClickButton>
              <OneClickButton
                onClick={() => this.onActionChoosed('wait', callback)}
                type="button"
                style={{ width: 'auto', padding: 10 }}
                className="btn btn-primary p-5 m-5"
              >
                {I18n.t('BEE2567' /* Aguardar */)}
              </OneClickButton>
              <OneClickButton
                onClick={() => this.onActionChoosed('cancel', callback)}
                type="button"
                style={{ width: 'auto', padding: 10 }}
                className="btn btn-secondary p-5 m-5"
              >
                {I18n.t('BEE99' /* Cancelar */)}
              </OneClickButton>
            </>
          )}
        >
          <div>
            <span>
              {I18n.t('BEE2600' /* Ao selecionar o botão */)}
              {' '}
              <b>{`(${I18n.t('BEE2566' /* Segundo plano */)})`}</b>
              {' '}
              {I18n.t(
                'BEE2601', /* o relatório entrará na fila de processamento e ao final do processamento será emitido uma notificação com o link para download. */
              )}
            </span>
          </div>
        </SweetAlert>
      )
    );
  }
}

export default ReportsBackgroundModal;
