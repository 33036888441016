import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getPrioritiesList, deletePriority } from '../../../../app/store/actions/priorities';

class PriorityList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      priorityUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showPriorityDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditPriority(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyPriority(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDeletePriority(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE628' /* Faturamento Posterior */),
        accessor: 'afterBilling',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE629' /* Considerar Data */),
        accessor: 'considerDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE630' /* Faturamento Loja */),
        accessor: 'storeBilling',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE631' /* Inativa */),
        accessor: 'disabled',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE632' /* Ignora Plano de Corte */),
        accessor: 'ignoreCutPlan',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE633' /* Retira */),
        accessor: 'withdraw',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE649' /* Fura fila */),
        accessor: 'sticksRow',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>
            {row.value === true ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getPriorities();
    } else {
      await this.getPriorities();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      priorityUpdate: null,
    });
  };

  getPriorities = async () => {
    await this.props.getPrioritiesList();
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deletePriority = async (priority) => {
    const result = await this.props.deletePriority(priority._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'success',
        I18n.t('BEE655' /* Eliminar Prioriddade */),
        I18n.t('BEE656', { 0: priority.code } /* Prioridade %{0} eliminada com sucesso! */),
        'top-right',
      );
    }
    await this.getPriorities();
  };

  showPriorityDetail = (priority) => {
    this.props.history.push({
      pathname: ROUTES.PRIORITY_DETAIL,
      state: {
        priority: priority._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditPriority = (priority) => {
    this.props.history.push({
      pathname: ROUTES.PRIORITY_EDIT,
      state: {
        priority: priority._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyPriority = (priority) => {
    this.props.history.push({
      pathname: ROUTES.PRIORITY_COPY,
      state: {
        priority: priority._original,
      },
    });
  };

  showDeletePriority = (priority) => {
    this.setState({ showModalDelete: true, priorityUpdate: priority });
  };

  render() {
    const { priorityUpdate, showModalDelete, page } = this.state;
    const { prioritiesList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE626' /* Prioridades */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE626' /* Prioridades */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.PRIORITY_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE634' /* Incluir Prioridade */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getPriorities,
          }}
          filterable
          data={prioritiesList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deletePriority(priorityUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE635', { 0: priorityUpdate.code } /* A prioridade %{0} será eliminada! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  prioritiesList: state.priorities && state.priorities.prioritiesList,
});

const mapDispatchToProps = (dispatch) => ({
  getPrioritiesList: () => dispatch(getPrioritiesList()),
  deletePriority: (priorityId) => dispatch(deletePriority(priorityId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PriorityList));
