import DockControlRequests from '../../server/dockControl';
import { addLoading, removeLoading } from './loading';

export const getOpenConference = (dockCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DockControlRequests.getOpenConference(dockCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrdersChecked = (dockControlId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DockControlRequests.getOutboundOrdersChecked(dockControlId);
  } finally {
    dispatch(removeLoading());
  }
};

export const createDockControl = (dockCode, outboundVolumeId, carrierCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DockControlRequests.createDockControl(dockCode, outboundVolumeId, carrierCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const finalizeCheckVolumes = (dockControlId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DockControlRequests.finalizeCheckVolumes(dockControlId);
  } finally {
    dispatch(removeLoading());
  }
};

export const checkVolumes = (dockControlId, outboundVolumeId, carrierCode, dockCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DockControlRequests.checkVolumes(dockControlId, outboundVolumeId, carrierCode, dockCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const dockControlList = (filterStartDate, filterEndDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DockControlRequests.dockControlList(filterStartDate, filterEndDate);
  } finally {
    dispatch(removeLoading());
  }
};

export const outboundOrderWithdrawal = (outboundOrderId, hasPermission) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DockControlRequests.outboundOrderWithdrawal(outboundOrderId, hasPermission);
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrderDockControl = (outboundVolumeId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DockControlRequests.getOutboundOrderDockControl(outboundVolumeId);
  } finally {
    dispatch(removeLoading());
  }
};
