import RestService from '../services/rest';

// GET
async function getMaterialsFamily() {
  return RestService.getAuthenticated('materialsFamily/list/all');
}

async function getMaterialFamily(materialFamilyId) {
  return RestService.getAuthenticated(`materialsFamily/detail?materialFamilyId=${materialFamilyId}`);
}

async function getMaterialsFamilyOptions() {
  return RestService.getAuthenticated('materialsFamily/list/options');
}

// POST
async function createMaterialFamily(materialFamily) {
  return RestService.postAuthenticated('materialsFamily/create', materialFamily);
}

async function deleteMaterialFamily(materialFamilyId) {
  return RestService.postAuthenticated('materialsFamily/delete', { materialFamilyId });
}

// PUT
async function updateMaterialFamily(materialFamily) {
  return RestService.putAuthenticated('materialsFamily/update', materialFamily);
}

export default {
  getMaterialsFamily,
  createMaterialFamily,
  updateMaterialFamily,
  getMaterialFamily,
  deleteMaterialFamily,
  getMaterialsFamilyOptions
};
