import 'react-table/react-table.css';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import Table from '../../../../components/table/Table';
import AdressTransferWaveProductHelpers from '../../../../helpers/adressTransferWaveProduct';
import FormatHelpers from '../../../../helpers/format';
import {
  deleteAddressTransferWaveProduct,
  transferAddressTransferWaveProduct,
} from '../../../../app/store/actions/addressTransferWave';
import addNotification from '../../../../components/notification';
import { getDepositsOptions } from '../../../../app/store/actions/deposits';
import { getStorageAddressesOptions } from '../../../../app/store/actions/storageAddresses';
import Select from '../../../../components/form/select';

class ProductsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      showModalTransfer: false,
      selectedProduct: {},
      defaultPageSize: 10,
      depositCode: null,
      depositName: '',
      storageAddressId: null,
      storageAddressCode: null,
      storageAddressName: '',
      listDeposit: [],
      listStorageAddress: [],
      storageAddressDisabled: true,
    };

    this.initialState = this.state;

    this.defaultSorted = [{
      id: 'productCode', desc: false,
    }];

    this.tableColumns = [{
      Header: I18n.t('BEE225' /* Produto */),
      accessor: 'productCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE277' /* Descrição */),
      accessor: 'product.fullName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE224' /* Status */),
      accessor: 'status',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 90,
      Cell: (rows) => (<span>{AdressTransferWaveProductHelpers.statusTransfer(rows.original.status)}</span>),
      filterMethod: (filter, row) => {
        if (parseInt(filter.value, 10) === parseInt(row.status, 10)) return true;
      },
      Filter: ({ onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={(event) => { onChange(event.target.value); }}
        >
          <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
          {AdressTransferWaveProductHelpers.statusTransfer().map((elem, index) => (
            <option value={elem.value} key={index}>{elem.label}</option>
          ))}
        </select>
      ),
    }, {
      Header: I18n.t('BEE428' /* Lote */),
      accessor: 'lotNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 90,
    }, {
      Header: I18n.t('BEE377' /* EAN */),
      accessor: 'ean',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE384' /* Unidade */),
      accessor: 'product.unitMeasure',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 70,
    }, {
      Header: I18n.t('BEE495' /* Endereço Retirada */),
      accessor: 'pickupAddress',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 110,
    }, {
      Header: I18n.t('BEE496' /* Endereço Armazenamento */),
      accessor: 'storageAddress',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 130,
    },
    {
      Header: I18n.t('BEE441' /* Quantidade */),
      accessor: 'quantity',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 90,
      Cell: (row) => (
        <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
      ),
    }];

    this.colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, {
        isDisabled, isSelected,
      }) => ({
        ...styles,
        backgroundColor: isDisabled ? 'grey' : 'white',
        color: isDisabled ? 'grey' : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        textAlign: 'left',

        ':hover': {
          ...styles[':hover'],
          backgroundColor: isSelected ? 'grey' : '#eb900a',
          color: 'white',
        },
      }),
    };
  }

  async componentDidMount() {
    if (this.props.mode !== 'detail') {
      this.tableColumns = [{
        Header: this.props.title,
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 80,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            {rows.original.status <= 2
              && (
              <button
                type="button"
                className="btn btn-primary btn-xs"
                onClick={() => (this.props.mode === 'edit'
                  ? this.openModalDelete(rows.original)
                  : this.openModalTransfer(rows.original))}
              >
                <span className="d-flex align-items-center text-start">
                  <strong>
                    {this.props.mode === 'edit'
                      ? I18n.t('BEE81' /* Eliminar */)
                      : this.props.title}
                  </strong>
                </span>
              </button>
              )}
          </div>
        ),
      },
      ...this.tableColumns,
      ];
      await this.getDepositsOptions();
    }
  }

  getStorageAddressesOptions = async () => {
    try {
      const listStorageAddress = await this.props.getStorageAddressesOptions(
        this.props.userLogged.mainBranch,
        this.state.depositCode,
      );
      if (listStorageAddress) {
        this.setState(
          { listStorageAddress: [...listStorageAddress], storageAddressDisabled: false },
        );
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2295' /* Monitor de Transferência por Onda */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  getDepositsOptions = async () => {
    try {
      const listDeposit = await this.props.getDepositsOptions();
      if (listDeposit && listDeposit.length) this.setState({ listDeposit: [...listDeposit] });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2295' /* Monitor de Transferência por Onda */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  confirmTransfer = async () => {
    const { selectedProduct, depositCode, storageAddressCode } = this.state;
    const { branchCode, depositCodeOut } = this.props.documentData;

    if (!storageAddressCode) {
      addNotification(
        'danger',
        I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
        I18n.t('BEE1224' /* O endereço informado não é válido */),
        'top-right',
      );
    } else if (!depositCode) {
      addNotification(
        'danger',
        I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
        I18n.t('BEE1330' /* Depósito deve ser informado ! */),
        'top-right',
      );
    } else {
      this.setState({ showModalTransfer: false });

      const dataTransfer = {
        addressTransferWaveProductsId: selectedProduct.id,
        depositCode,
        storageAddress: storageAddressCode,
        depositCodeOut,
        branchCode,
        productCode: selectedProduct.productCode,
        expirationDate: selectedProduct.expirationDate,
        pickupAddress: selectedProduct.pickupAddress,
        quantity: selectedProduct.quantity,
        lotNumber: selectedProduct.lotNumber,
      };

      try {
        const result = await this.props.transferAddressTransferWaveProduct(dataTransfer);

        if (result && result.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */),
            'top-right',
          );
        } else {
          addNotification(
            'success',
            I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
            I18n.t('BEE2297' /* Produto armazenado com sucesso! */),
            'top-right',
          );
          this.setState({ ...this.initialState });
          this.props.getAddressTransferWaveByBaptism();
          this.getDepositsOptions();
        }
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && error.message) {
          const messageError = (error.details)
            ? `${error.code} - ${error.details} | ${error.message}`
            : error.message;
          addNotification(
            'danger',
            I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */),
            'top-right',
          );
        }
      }
    }
  };

  openModalTransfer = (selectedProduct) => {
    this.setState({ showModalTransfer: true, selectedProduct: { ...selectedProduct } });
  };

  deleteAddressTransferWaveProduct = async () => {
    const { selectedProduct } = this.state;
    this.setState({ showModalDelete: false, selectedProduct: {} });
    try {
      const result = await this.props.deleteAddressTransferWaveProduct(selectedProduct.id);

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE225' /* Produto */),
          I18n.t('BEE1779', { 0: selectedProduct.productCode } /* Produto %{0} eliminado com sucesso! */),
          'top-right',
        );
        this.setState({ ...this.initialState });
        this.props.getAddressTransferWaveByBaptism();
        this.getStorageAddressesOptions();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2295' /* Monitor de Transferência por Onda */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  openModalDelete = (selectedProduct) => {
    this.setState({ showModalDelete: true, selectedProduct: { ...selectedProduct } });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
    if (attr === 'storageAddress') {
      this.setState({
        [`${attr}Id`]: value ? value.value : null,
        [`${attr}Code`]: value ? value.code : null,
      });
    } else if (attr === 'deposit') {
      if (value) {
        await this.setState({
          listStorageAddress: [...[]],
          storageAddressDisabled: true,
          storageAddressCode: null,
          storageAddressName: '',
        });
        await this.getStorageAddressesOptions();
      } else {
        await this.setState({
          listStorageAddress: [...[]],
          storageAddressDisabled: true,
          storageAddressCode: null,
          storageAddressName: '',
        });
      }
    }
  };

  createSelectDropDown = (value, attr, label, items, disabled, keypressFunction = undefined, id) => (
    <div className="form-group m-b-15 text-left">
      <span>{label}</span>
      <div>
        <Select
          id={id && id}
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          value={value.label ? value : ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={this.colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          isDisabled={disabled}
        />
      </div>
    </div>
  );

  render() {
    const { products = [] } = this.props;
    const {
      showModalDelete, selectedProduct, defaultPageSize, depositCode, listDeposit, storageAddressDisabled,
      depositName, storageAddressId, storageAddressName, listStorageAddress, showModalTransfer,
    } = this.state;

    return (
      <>
        <Table
          filterable
          data={products}
          columns={this.tableColumns}
          expander
          defaultPageSize={defaultPageSize}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          panelHeaderProps={{
            onClickReload: this.props.getAddressTransferWaveByBaptism,
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.original && rowInfo.original.status) {
              return {
                style: {
                  color: (rowInfo.original.status === 3
                    ? '#00acac'
                    : rowInfo.original.status === 4 && '#FF4500') || 'black',
                },
              };
            }
            return {};
          }}
        />
        {showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteAddressTransferWaveProduct()}
            onCancel={() => this.setState({ showModalDelete: false, selectedProduct: {} })}
          >
            {I18n.t('BEE1778', { 0: selectedProduct.productCode } /* O produto %{0} será eliminado! */)}
          </SweetAlert>
          )}
        {showModalTransfer
          && (
          <SweetAlert
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE1506' /* Transferir */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="danger"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.confirmTransfer()}
            onCancel={() => this.setState({ showModalTransfer: false, selectedProduct: {} })}
          >
            <div className="mt-4" />
            {this.createSelectDropDown(
              { value: depositCode, label: depositName },
              'deposit',

              I18n.t('BEE1797' /* Depósito Armazenamento */),
              listDeposit,

              false,
            )}
            <div className="mb-3" />
            {this.createSelectDropDown(
              { value: storageAddressId, label: storageAddressName },
              'storageAddress',

              I18n.t('BEE496' /* Endereço Armazenamento */),
              listStorageAddress,

              storageAddressDisabled,
            )}
            <div className="mb-5" />
          </SweetAlert>
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  deleteAddressTransferWaveProduct: (transterOrderOutboundId) => dispatch(
    deleteAddressTransferWaveProduct(transterOrderOutboundId),
  ),
  transferAddressTransferWaveProduct: (addressTransferWaveProduct) => dispatch(
    transferAddressTransferWaveProduct(addressTransferWaveProduct),
  ),
  getStorageAddressesOptions: (branchCode, depositCode) => dispatch(
    getStorageAddressesOptions(branchCode, depositCode),
  ),
  getDepositsOptions: () => dispatch(getDepositsOptions(1)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductsList));
