import { addLoading, removeLoading } from './loading';
import OutboundCableCutRequests from '../../server/outboundCableCut';

export const setProductsToCableCutPlan = productsToCut => ({
  type: 'SET_PRODUCTS_TO_CUT',
  productsToCut,
});

export const getProductsToCableCutPlan = (filters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const productsToCut = await OutboundCableCutRequests.getProductsToCableCutPlan(filters);
    dispatch(setProductsToCableCutPlan(productsToCut));
  } finally {
    dispatch(removeLoading());
  }
};

export const createCutCablePlan = (outboundProductIds) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundCableCutRequests.createCutCablePlan(outboundProductIds);
  } finally {
    dispatch(removeLoading());
  }
}

export const changeAllocationProduct = (outboundOrderProductIds, stockBalanceId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundCableCutRequests.changeAllocationProduct(outboundOrderProductIds, stockBalanceId);
  } finally {
    dispatch(removeLoading());
  }
}