const INITIAL_STATE = {
  suppliersList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_SUPPLIERS': {
      return { ...state, suppliersList: action.suppliers };
    }
    default:
      return state;
  }
};
