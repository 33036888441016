import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { getPackagesList } from '../../../app/store/actions/packages';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import Format from '../../../helpers/format';

class ModalTypePackaging extends React.Component {
  constructor(props) {
    super(props);
    this.tableColumns = [
      {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: `${I18n.t('BEE579' /* Peso */)} (${I18n.t('BEE2327' /* G */)})`,
        accessor: 'weight',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{Format.formatNumber(row.value, 0, false)}</span>
          </div>
        ),
      }, {
        Header: `${I18n.t('BEE386' /* Altura */)} (${I18n.t('BEE2325' /* cm */)})`,
        accessor: 'height',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{Format.formatNumber(row.value, 0, false)}</span>
          </div>
        ),
      }, {
        Header: `${I18n.t('BEE387' /* Largura */)} (${I18n.t('BEE2325' /* cm */)})`,
        accessor: 'width',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{Format.formatNumber(row.value, 0, false)}</span>
          </div>
        ),
      }, {
        Header: `${I18n.t('BEE388' /* Comprimento */)} (${I18n.t('BEE2325' /* cm */)})`,
        accessor: 'length',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{Format.formatNumber(row.value, 0, false)}</span>
          </div>
        ),
      },
    ];
    this.state = {
      defaultPageSize: 5,
      pageSizeOptions: [5, 10],
      defaultSorted: [{ id: 'code', desc: false }],
      selectPackaging: {},
    };
  }

  async componentDidMount() {
    try {
      const result = await this.props.getPackagesList();

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE497' /* Embalagem */),
          I18n.t('BEE1868' /* Embalagens não localizadas */),
          'top-right',
        );
      }
    } catch (error) {
      const messageError = error.response
        && error.response.data
        && error.response.data.error
        && error.response.data.error.message;

      if (messageError) {
        addNotification(
          'danger',
          I18n.t('BEE497' /* Embalagem */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE497' /* Embalagem */),
          I18n.t('BEE1868' /* Embalagens não localizadas */),
          'top-right',
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.openModalPackaging && prevProps.openModalPackaging !== this.props.openModalPackaging) {
      setTimeout(() => {
        document.getElementById('button_select').focus();
      }, 500);
    }
  }

  selectPackaging = (selectPackaging) => {
    this.setState({ selectPackaging });
    document.getElementById('button_select').focus();
  };

  selectedPackagingConcussion = async () => {
    const { selectPackaging = {} } = this.state;
    const { isFinishing = false } = this.props;

    if (Object.values(selectPackaging).length) {
      await this.props.selectPackaging(selectPackaging);
    } else if (this.props.packageCode) {
      await this.props.selectPackaging({ code: this.props.packageCode });
    } else {
      addNotification(
        'danger',
        I18n.t('BEE497' /* Embalagem */),
        I18n.t('BEE1863' /* É necessário selecionar uma embalagem */),
        'top-right',
      );
    }

    if (isFinishing) {
      this.props.checkDataShowFinishScreen();
    }
  };

  render() {
    const {
      defaultPageSize, defaultSorted, pageSizeOptions, selectPackaging,
    } = this.state;

    const {
      openModalPackaging, packagesList = [], packageCode, mainPackageCode, isFinishing,
    } = this.props;

    return (
      <Modal size="lg" isOpen={openModalPackaging} toggle={() => this.props.closeModalPackaging()}>
        {isFinishing
          ? <ModalHeader><span className="text-center">Confirmar Embalagem</span></ModalHeader>
          : <ModalHeader><span className="text-center">{I18n.t('BEE497' /* Embalagem */)}</span></ModalHeader>}
        <ModalBody>
          <Table
            panelHeaderProps={{ noButton: true }}
            filterable
            expander
            data={packagesList}
            columns={this.tableColumns}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={pageSizeOptions}
            defaultSorted={defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                const code = selectPackaging.code || packageCode;
                return {
                  onClick: () => {
                    this.selectPackaging(rowInfo.original);
                  },
                  style: {
                    background: rowInfo.original.code === code ? 'silver' : 'white',
                    color: rowInfo.original.code === mainPackageCode ? 'red' : 'black',
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
            defaultSortMethod={(a, b) => {
              if (b === mainPackageCode && a === packageCode) return 1;
              if (a === mainPackageCode || a === packageCode) return -1;
              return 1;
            }}
          />
          <div className="text-right">
            <button
              type="button"
              className="btn btn-120 btn-danger p-5 m-5"
              onClick={() => this.props.closeModalPackaging()}
            >
              {I18n.t('BEE99' /* Cancelar */)}
            </button>
            <button
              id="button_select"
              type="button"
              className="btn btn-120 btn-primary p-5 m-5"
              onClick={() => this.selectedPackagingConcussion()}
            >
              {I18n.t('BEE436' /* Selecionar */)}
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  packagesList: state.packages && state.packages.packagesList,
});

const mapDispatchToProps = (dispatch) => ({
  getPackagesList: () => dispatch(getPackagesList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalTypePackaging));
