import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';

import SelectMulti from 'react-select';
import OneClickButton from '../../../components/form/button';
import { PanelPage } from '../../../components/pages/pages';
import Table from '../../../components/table/Table';
import addNotification from '../../../components/notification';
import UserConfirmationModal from '../../../components/pages/userConfirmationModal';

import InventoryHelpers from '../../../helpers/inventory';
import FormatHelpers from '../../../helpers/format';

import { finalizeInventorySheets, disapprovedInventorySheets } from '../../../app/store/actions/inventory';

class InventorySheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultPageSize: 10,
      defaultSorted: [{ id: 'sheetNumber', desc: false }],
      checked: {},
      showUserModal: false,
      showMessageDisapproved: false,
      showMessageNumberOfSheet: false,
      disapprovedNote: '',
      checkedInventorySheetIds: [],
      sheetDifferenceSelected: 0,
      leftover: 0,
      foulsValue: 0,
    };
    this.tableColumns = [{
      Header: I18n.t('BEE436' /* Selecionar */),
      accessor: 'action',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      filterable: false,
      sortable: false,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          {(row.original.status === 4) && (
            <input
              type="checkbox"
              checked={this.state.checked[row.original.inventorySheetId] || false}
              onChange={() => this.singleSelection(row.original)}
            />
          )}
        </div>
      ),
    }, {
      Header: I18n.t('BEE2106' /* Ficha */),
      accessor: 'sheetNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
    }, {
      Header: I18n.t('BEE267' /* Endereço */),
      accessor: 'addressCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 140,
    }, {
      Header: I18n.t('BEE225' /* Produto */),
      accessor: 'productCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE2170' /* Descrição Produto */),
      accessor: 'productName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
    }, {
      Header: I18n.t('BEE1516' /* Agrupador */),
      accessor: 'grouperCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE515' /* Saldo */),
      accessor: 'balanceLastCount',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
    }, {
      Header: I18n.t('BEE1514' /* Contagem */),
      accessor: 'amountLastCount',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
    }, {
      Header: I18n.t('BEE2152' /* Diferença */),
      accessor: 'sheetDifference',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
    }, {
      Header: I18n.t('BEE2199' /* Diferença Valor */),
      accessor: 'sheetDifferenceValue',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 130,
      Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 2, true),
    }, {
      Header: I18n.t('BEE428' /* Lote */),
      accessor: 'lotNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE1854' /* Setor */),
      accessor: 'sector',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE121' /* Rua */),
      accessor: 'street',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE281' /* Coluna */),
      accessor: 'column',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE283' /* Nível */),
      accessor: 'level',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE285' /* Gaveta */),
      accessor: 'drawer',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE204' /* Situação */),
      accessor: 'status',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 180,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{InventoryHelpers.inventorySheetStatus(row.value)}</span>
        </div>
      ),
      filterMethod: (filter, row) => {
        if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
          return true;
        }
      },
      Filter: ({ onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={(event) => { onChange(event.target.value); }}
        >
          <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
          {InventoryHelpers.inventorySheetStatus().map((elem, index) => (
            <option value={elem.value} key={index}>{elem.label}</option>
          ))}
        </select>
      ),
    }];

    this.colourStyles = {
      multiValueRemove: (base) => ({ ...base, display: 'none' }),
      control: (styles) => ({ ...styles, backgroundColor: '#e9ecef' }),
      option: (styles) => ({
        ...styles,
        backgroundColor: '#ccc',
        color: 'black',
        cursor: 'not-allowed',
        textAlign: 'left',
      }),
    };
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 mt-4">
      <span>{label}</span>
      <div className="col">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div key={attr} className="form-group m-b-15 text-left">
      <div>
        <span>
          {label}
        </span>
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control m-b-5"
          value={(value === 0 || value) ? value : ''}
          onChange={(e) => this.props.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createSelectDropDownMulti = (value, attr, label, items, disabled, keypressFunction = undefined) => (
    <div className="form-group m-b-15 text-left">
      <span>{label}</span>
      <div>
        <SelectMulti
          value={value}
          isMulti
          onChange={(val) => this.setValue(attr, val)}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          noOptionsMessage={() => I18n.t('BEE561' /* Sem opções */)}
          isSearchable
          styles={this.colourStyles}
          maxMenuHeight={300}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          options={items}
          isDisabled={disabled}
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, attr))}
        />
      </div>
    </div>
  );

  singleSelection = (selection) => {
    const { checked } = this.state;
    let { sheetDifferenceSelected, leftover, foulsValue } = this.state;
    checked[selection.inventorySheetId] = !checked[selection.inventorySheetId];

    if (checked[selection.inventorySheetId]) {
      if (parseFloat(selection.sheetDifference) >= 0) {
        leftover += parseFloat(selection.sheetDifferenceValue);
      } else {
        foulsValue += parseFloat(selection.sheetDifferenceValue) * -1;
      }
    } else if (parseFloat(selection.sheetDifferenceValue) >= 0) {
      leftover -= parseFloat(selection.sheetDifferenceValue);
    } else {
      foulsValue -= parseFloat(selection.sheetDifferenceValue) * -1;
    }

    sheetDifferenceSelected = leftover - foulsValue;
    this.setState({
      checked: { ...checked }, sheetDifferenceSelected, leftover, foulsValue,
    });
  };

  multipleSelection = (selectionOption) => {
    const { checked } = this.state;
    let { sheetDifferenceSelected, leftover, foulsValue } = this.state;
    const data = this.reactTable.getResolvedState().sortedData;
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index += 1) {
        const element = data[index]._original;
        if (element.status === 4 && checked[element.inventorySheetId] !== selectionOption) {
          checked[element.inventorySheetId] = selectionOption;
          if (element.status === 4 && checked[element.inventorySheetId]) {
            if (parseFloat(element.sheetDifferenceValue) >= 0) {
              leftover += parseFloat(element.sheetDifferenceValue);
            } else {
              foulsValue += parseFloat(element.sheetDifferenceValue) * -1;
            }
          } else if (element.status === 4) {
            if (parseFloat(element.sheetDifferenceValue) >= 0) {
              leftover -= parseFloat(element.sheetDifferenceValue);
            } else {
              foulsValue -= parseFloat(element.sheetDifferenceValue) * -1;
            }
          }
        }
      }

      sheetDifferenceSelected = leftover - foulsValue;

      this.setState({
        checked: { ...checked }, sheetDifferenceSelected, leftover, foulsValue,
      });
    }
  };

  finalizeSheets = () => {
    const { checked } = this.state;

    const { sheets } = this.props.state;

    if (!Object.keys(checked).length) {
      addNotification(
        'danger',
        I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
        I18n.t('BEE2142' /* Nenhum ficha selecionada */),
        'top-right',
      );
    } else {
      const checkedInventorySheetIds = [];

      Object.keys(checked).forEach((index) => {
        if (checked[index]) {
          checkedInventorySheetIds.push(index);
        }
      });

      if (checkedInventorySheetIds.length) {
        let errorValidation = false;
        checkedInventorySheetIds.forEach((id) => {
          const sheet = sheets.find((sh) => parseInt(sh.inventorySheetId, 10) === parseInt(id, 10));

          const sheetsProduct = sheets.filter((sh) => !!sh.productCode
            && sh.productCode === sheet.productCode
            && sh.grouperCode === sheet.grouperCode
            && sh.status <= 4);

          sheetsProduct.forEach((productSheet) => {
            if (!checkedInventorySheetIds.includes(productSheet.inventorySheetId.toString())) {
              addNotification(
                'danger',
                I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
                I18n.t('BEE3237', { 0: productSheet.productCode }),
                /* Todas as fichas do Produto %{0} devem ser selecionadas. */
                'top-right',
              );
              errorValidation = true;
            }
          });
        });

        if (errorValidation) return;

        this.setState({
          checkedInventorySheetIds: [...checkedInventorySheetIds],
          showMessageNumberOfSheet: true,
        });
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
          I18n.t('BEE2142' /* Nenhum ficha selecionada */),
          'top-right',
        );
      }
    }
  };

  reproveSheets = () => {
    const { checked } = this.state;

    if (!Object.keys(checked).length) {
      addNotification(
        'danger',
        I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
        I18n.t('BEE2142' /* Nenhum ficha selecionada */),
        'top-right',
      );
    } else {
      const checkedInventorySheetIds = [];

      Object.keys(checked).forEach((index) => {
        if (checked[index]) {
          checkedInventorySheetIds.push(index);
        }
      });

      if (checkedInventorySheetIds.length) {
        this.setState({
          checkedInventorySheetIds: [...checkedInventorySheetIds],
          showUserModal: true,
        });
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
          I18n.t('BEE2142' /* Nenhum ficha selecionada */),
          'top-right',
        );
      }
    }
  };

  userConfirmation = async (confirm) => {
    this.setState({ showUserModal: false });
    if (confirm) this.setState({ showMessageDisapproved: true });
  };

  finalizeSheetsSubmit = async () => {
    const { checkedInventorySheetIds } = this.state;
    const { inventoryId } = this.props.state;

    try {
      const result = await this.props.finalizeInventorySheets(inventoryId, checkedInventorySheetIds);

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
          I18n.t('BEE2345' /* As fichas foram atualizadas com sucesso */),
          'top-right',
        );

        this.setState({
          showMessageNumberOfSheet: false,
          checkedInventorySheetIds: [],
          checked: {},
          sheetDifferenceSelected: 0,
          leftover: 0,
          foulsValue: 0,
        });
        this.props.getInventorySheetsList(false);
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2151' /* Atualizar Ficha de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  setDisapprovedSheets = async () => {
    const { checkedInventorySheetIds, disapprovedNote } = this.state;
    const { inventoryId } = this.props.state;

    this.setState({
      showMessageDisapproved: false,
      checkedInventorySheetIds: [],
      checked: {},
      disapprovedNote: '',
    });

    try {
      const result = await this.props.disapprovedInventorySheets(
        inventoryId,
        checkedInventorySheetIds,
        disapprovedNote,
      );

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
          I18n.t('BEE2204' /* As fichas foram reprovadas com sucesso */),
          'top-right',
        );

        this.setState({
          sheetDifferenceSelected: 0,
          leftover: 0,
          foulsValue: 0,
        });

        this.props.getInventorySheetsList();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2151' /* Atualizar Ficha de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      defaultPageSize, defaultSorted, showMessageDisapproved, disapprovedNote, checkedInventorySheetIds,
      showMessageNumberOfSheet, sheetDifferenceSelected, foulsValue, leftover,
    } = this.state;

    const {
      branchName, depositName, grouperCode, status,
      sheets, pendingSheet, sheetMissingCount,
    } = this.props.state;

    return (
      <>
        <PanelPage
          noButton
          pageDescription={I18n.t('BEE572' /* Inventário */).toUpperCase()}
          content={(
            <div className="row">
              <div className="col-md-4">
                {this.createInput(
                  branchName,
                  'branchName',
                  I18n.t('BEE145' /* Filial */),
                  '',
                  'text',
                  false,
                  true,
                )}
                {this.createSelectDropDownMulti(
                  grouperCode,
                  'grouperCode',
                  grouperCode && grouperCode.length > 1
                    ? I18n.t('BEE3628' /* Agrupadores */)
                    : I18n.t('BEE1516' /* Agrupador */),
                  grouperCode,
                  true,
                )}
              </div>
              <div className="col-md-4">
                {this.createInput(
                  depositName,
                  'depositName',
                  I18n.t('BEE182' /* Depósito */),
                  '',
                  'text',
                  false,
                  true,
                )}
                <div className="row">
                  <div className="col-md-6">
                    {this.createInput(
                      FormatHelpers.formatNumber(leftover, 2, true),
                      'leftover',
                      I18n.t('BEE3631' /* Sobras */),
                      '',
                      'text',
                      false,
                      true,
                    )}
                  </div>
                  <div className="col-md-6">
                    {this.createInput(
                      FormatHelpers.formatNumber(foulsValue, 2, true),
                      'foulsValue',
                      I18n.t('BEE3632' /* Faltas */),
                      '',
                      'text',
                      false,
                      true,
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                {this.createInput(
                  sheetMissingCount,
                  'sheetMissingCount',
                  I18n.t('BEE2154' /* Fichas Sem Contagem */),
                  '',
                  'text',
                  false,
                  true,
                )}
                {this.createInput(
                  FormatHelpers.formatNumber(sheetDifferenceSelected, 2, true),
                  'sheetDifferenceSelected',
                  I18n.t('BEE3630' /* Diferença NET */),
                  '',
                  'text',
                  false,
                  true,
                )}
              </div>
              <div className="col-md-2">
                {this.createInput(
                  pendingSheet,
                  'pendingSheet',
                  I18n.t('BEE2153' /* Fichas Pendentes */),
                  '',
                  'text',
                  false,
                  true,
                )}
                {this.createInput(
                  status,
                  'status',
                  I18n.t('BEE204' /* Situação */),
                  '',
                  'text',
                  false,
                  true,
                )}
              </div>
            </div>
          )}
          footerContent={(
            <button
              type="button"
              className="btn btn-100 btn-default p-5 m-5"
              onClick={() => this.props.restoreHomePage()}
            >
              {I18n.t('BEE137' /* Voltar */)}
            </button>
          )}
        />
        <Table
          filterable
          downloadCSV
          data={sheets}
          columns={this.tableColumns}
          expander
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          panelHeaderProps={{
            children: I18n.t('BEE2184' /* Fichas */).toUpperCase(),
          }}
          ref={(r) => {
            this.reactTable = r && r.reactTable;
          }}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          actionButtons={(
            <div className="ml-auto">
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(true)}
              >
                {I18n.t('BEE1908' /* Marcar Todos */)}
              </button>
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(false)}
              >
                {I18n.t('BEE1909' /* Desmarcar Todos */)}
              </button>
              <OneClickButton
                type="button"
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={() => this.finalizeSheets()}
              >
                {I18n.t('BEE139' /* Atualizar */)}
              </OneClickButton>
              <OneClickButton
                type="button"
                className=" btn btn-120 btn-danger p-5 m-5"
                onClick={() => this.reproveSheets()}
              >
                {I18n.t('BEE2202' /* Reprovar Fichas */)}
              </OneClickButton>
            </div>
          )}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                style: {
                  color: rowInfo.original.sheetDifferenceValue !== 0 ? '#bf4441' : 'black',
                },
              };
            }
            return {};
          }}
        />
        {(showMessageNumberOfSheet
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1800' /* Prosseguir */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={this.finalizeSheetsSubmit}
              onCancel={() => this.setState({ showMessageNumberOfSheet: false })}
            >
              <h5>
                {
                  I18n.t('BEE2346', { 0: checkedInventorySheetIds.length })
                  /* Foram selecionadas %{0} fichas! Deseja prosseguir com a atualização? */
                }
              </h5>
            </SweetAlert>
          ))}
        {(showMessageDisapproved
          && (
            <SweetAlert
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => ((this.state.disapprovedNote)
                ? this.setDisapprovedSheets()
                : addNotification(
                  'danger',
                  I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
                  I18n.t('BEE1977' /* O campo motivo e obrigatório */),
                  'top-right',
                ))}
              onCancel={() => this.setState({ showMessageDisapproved: false, disapprovedNote: '' })}
            >
              <h5>
                {
                  I18n.t(
                    'BEE2203',
                    { 0: checkedInventorySheetIds.length },
                  ) /* Foram selecionadas %{0} fichas! Para prosseguir informe o motivo da reprovação */
                }
              </h5>
              {this.createTextArea(
                disapprovedNote,
                'disapprovedNote',
                I18n.t('BEE724' /* Motivo */),
                '',
                3,
                true,
                false,
              )}
            </SweetAlert>
          ))}
        <UserConfirmationModal
          openModal={this.state.showUserModal}
          confirm={(confirm) => this.userConfirmation(confirm)}
        />
      </>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  finalizeInventorySheets: (inventoryId, inventorySheetIds) => dispatch(
    finalizeInventorySheets(inventoryId, inventorySheetIds),
  ),
  disapprovedInventorySheets: (inventoryId, inventorySheetIds, disapprovedNote) => dispatch(
    disapprovedInventorySheets(inventoryId, inventorySheetIds, disapprovedNote),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventorySheet));
