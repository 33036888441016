/* eslint-disable class-methods-use-this */
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { I18n } from 'react-redux-i18n';

import { I18nGetLocaleCurrency } from '../../helpers/i18n';

class DonutChart extends React.PureComponent {
  constructor() {
    super();

    this.state = {

    };
  }

  createAndUpdateCards = () => {
    const { donutChartValues } = this.props;

    let generalStatusCards = [];

    if (donutChartValues.cards) {
      donutChartValues.cards.forEach((card) => {
        generalStatusCards.push({
          title: card.label,
          quantity: card.value,
          backgroundColor: card.backgroundColor ? card.backgroundColor : '#2d353c',
        });
      });
    } else {
      generalStatusCards = [
        {
          title: I18n.t('BEE2432' /* Total de Fichas */),
          quantity: donutChartValues && donutChartValues.legendInfo ? donutChartValues.legendInfo.qtdTotal : false,
          backgroundColor: '#2d353c',
        },
        {
          title: I18n.t('BEE2430' /* Finalizadas */),
          quantity: donutChartValues && donutChartValues.legendInfo ? donutChartValues.legendInfo.qtdFinished : false,
          backgroundColor: '#013c8e',
        },
        {
          title: I18n.t('BEE2429' /* Pendentes */),
          quantity: donutChartValues && donutChartValues.legendInfo ? donutChartValues.legendInfo.qtdPending : false,
          backgroundColor: '#0155cb',
        },
        {
          title: I18n.t('BEE2428' /* Canceladas */),
          quantity: donutChartValues && donutChartValues.legendInfo ? donutChartValues.legendInfo.qtdCanceled : false,
          backgroundColor: '#0b70fe',
        },
        {
          title: I18n.t('BEE2431' /* Reprovadas */),
          quantity: donutChartValues && donutChartValues.legendInfo ? donutChartValues.legendInfo.qtdReproved : false,
          backgroundColor: '#4894fe',
        },
      ];
    }

    return (
      generalStatusCards.map((element) => (
        element.quantity
          ? (
            <div
              key={element.title}
              className="row d-flex flex-column rounded align-items-center
          justify-content-center mt-2"
              style={{ height: '5rem', width: '10rem', backgroundColor: `${element.backgroundColor}` }}
            >
              <div className="d-flex flex-column align-items-center text-white">
                <div className="font-weight-bold" style={{ fontSize: '0.7rem' }}>
                  {element.title}
                </div>
                <div style={{ fontSize: '1.2rem' }}>
                  {element.quantity}
                </div>
              </div>
            </div>
          ) : ''
      ))
    );
  };

  handleFormatter = (data) => {
    data.tooltip = {
      y: {
        formatter: (val) => {
          // Transformar o valor em uma string formatada
          const localeCurrency = I18nGetLocaleCurrency(navigator.language);

          const currencyVal = val.toLocaleString(localeCurrency.language, {
            style: 'currency',
            currency: localeCurrency.currency,
            minimumFractionDigits: localeCurrency.precision,
            maximumFractionDigits: localeCurrency.precision,
          });

          return currencyVal;
        },
      },
    };
    data.dataLabels = {
      enabled: true,
      style: {
        fontSize: '1rem',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
      },
    };
    data.plotOptions.pie.donut.labels = {
      show: true,
      value: {
        show: true,
        fontSize: '1rem',
        fontWeight: 'bold',
        color: '#013c8e',
        formatter: (val) => {
          const localeCurrency = I18nGetLocaleCurrency(navigator.language);

          const numVal = parseFloat(val);

          if (isNaN(numVal)) {
            return '0.00';
          }

          const currencyVal = numVal.toLocaleString(localeCurrency.language, {
            style: 'currency',
            currency: localeCurrency.currency,
            minimumFractionDigits: localeCurrency.precision,
            maximumFractionDigits: localeCurrency.precision,
          });

          return currencyVal;
        },
      },
      total: {
        show: !!(data.labels[0] === I18n.t('BEE2430' /* Finalizadas */)),
        label: I18n.t('BEE2430' /* Finalizadas */),
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#013c8e',
        formatter: (val) => {
          if (val.config.labels[0] === I18n.t('BEE2430' /* Finalizadas */)) {
            return `${(val.globals.seriesPercent[0][0]).toFixed(2)}%`;
          }
          return 0;
        },
      },
    };
    return data;
  };

  render() {
    const { donutChartValues } = this.props;
    return (
      <div>
        {(donutChartValues && donutChartValues.series.length)
          ? (
            <div className="row d-flex" style={{ height: '450px' }}>
              <div className="d-flex flex-column align-items-center" style={{ flex: 'auto' }}>
                {this.createAndUpdateCards()}
              </div>
              <div style={{ flex: 'auto' }}>
                { donutChartValues
                              && (
                                <ReactApexChart
                                  series={donutChartValues.series}
                                  options={this.handleFormatter(donutChartValues.options)}
                                  type="donut"
                                />
                              )}
              </div>
            </div>
          )
          : (
            <div className="d-flex flex-column" style={{ height: '450px' }}>
              <div
                className="d-flex align-items-center justify-content-center user-select-none"
                style={{
                  flex: 'auto', color: '#9b8a79', fontSize: '1rem', fontFamily: 'Helvetica',
                }}
              >
                {I18n.t('BEE2423' /* Não há dados na data informada */)}
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default DonutChart;
