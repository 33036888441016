import React from 'react';
import NumberFormat from 'react-number-format';

export class CustomNumberFormat extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      formattedValue: '',
    };
  }

  render() {
    return (
      <NumberFormat
        {...this.props}
        className={this.props.className || 'form-control'}
        value={this.props.value && (this.state.formattedValue || this.props.value || '')}
        onValueChange={(values) => {
          const isNullOrUndefined = values.value === null || values.value === undefined || values.value === '.000';

          if (isNullOrUndefined) {
            if (this.state.formattedValue !== '') {
              this.setState({ formattedValue: '' });
            }
          } else if (this.state.formattedValue !== values.formattedValue) {
            this.setState({ formattedValue: values.formattedValue || '' });
          }

          this.props.onValueChange(isNullOrUndefined ? '' : values.value);
        }}
      />
    );
  }
}

export default {
  CustomNumberFormat,
};
