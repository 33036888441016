import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  Nav, NavItem, NavLink,
  TabContent, TabPane,
} from 'reactstrap';

import getAddressByCep from '../../../../app/services/viaCep';
import {
  createCustomer,
  getCustomer,
  updateCustomer,
} from '../../../../app/store/actions/customers';

import OneClickButton from '../../../../components/form/button';
import Form from '../../../../components/form/form';
import addNotification from '../../../../components/notification';
import {
  Panel,
  PanelBody, PanelFooter,
  PanelHeader,
} from '../../../../components/panel/panel';

import ROUTES from '../../../../config/routes';

class CustomerForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      activeTab: '1',
      mode: 'detail',
      code: '',
      name: '',
      businessName: '',
      email: '',
      phone: '',
      cnpj: '',
      stateRegistration: '',
      postalCode: '',
      ibge: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
      note: '',
      singleVolume: false,
      disableSingleVolume: true,
      specialLabel: false,
      disableSpecialLabel: true,
      disableCode: true,
      disableName: true,
      disableBusinessName: true,
      disableEmail: true,
      disablePhone: true,
      disableCNPJ: true,
      disableStateRegistration: true,
      disablePostalCode: true,
      disableStreet: true,
      disableNumber: true,
      disableComplement: true,
      disableDistrict: true,
      disableCity: true,
      disableState: true,
      disableCountry: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getCustomerSelected();
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  setMode = () => {
    let mode = '';
    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableSingleVolume: false,
        disableSpecialLabel: false,
        disableCode: false,
        disableName: false,
        disableBusinessName: false,
        disableEmail: false,
        disablePhone: false,
        disableCNPJ: false,
        disableStateRegistration: false,
        disablePostalCode: false,
        disableStreet: false,
        disableNumber: false,
        disableComplement: false,
        disableDistrict: false,
        disableCity: false,
        disableState: false,
        disableCountry: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableSingleVolume: false,
        disableSpecialLabel: false,
        disableCode: true,
        disableName: true,
        disableBusinessName: true,
        disableEmail: true,
        disablePhone: true,
        disableCNPJ: true,
        disableStateRegistration: true,
        disablePostalCode: true,
        disableStreet: true,
        disableNumber: true,
        disableComplement: true,
        disableDistrict: true,
        disableCity: true,
        disableState: true,
        disableCountry: true,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  getCustomerSelected = async () => {
    if (this.props.location.state.customer) {
      const { customer } = this.props.location.state;
      const customerSelected = await this.props.getCustomer(customer.id);

      if (customerSelected) {
        this.setState({
          // customerSelected,
          customerId: customerSelected.id,
          code: customerSelected.code,
          name: customerSelected.name,
          businessName: customerSelected.businessName,
          email: customerSelected.email,
          phone: customerSelected.phone,
          cnpj: customerSelected.cnpj,
          stateRegistration: customerSelected.stateRegistration,
          postalCode: customerSelected.postalCode,
          ibge: customerSelected.ibge,
          street: customerSelected.street,
          number: customerSelected.number,
          complement: customerSelected.complement,
          district: customerSelected.district,
          city: customerSelected.city,
          state: customerSelected.state,
          country: customerSelected.country,
          note: customerSelected.note,
          singleVolume: customerSelected.singleVolume,
          specialLabel: customerSelected.specialLabel,
        });
      }
    }
  };

  createToggleBox = (item, attr, label, disabled) => (
    <div className="form-group row m-b-15">
      <label htmlFor={`customCheck${attr}`} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-0">
        <div className="row">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={this.handleInputChange}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`}>{label}</label>
          </div>
        </div>
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    if (attr === 'postalCode') {
      const newPostalCode = value;
      const { postalCode } = this.state;

      if (newPostalCode !== postalCode) {
        await this.setState({
          [`${attr}`]: value,
        });

        const re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;

        if (re.test(newPostalCode)) {
          await this.searchViaCep(newPostalCode);
        } else {
          await this.cleanAddress();
        }
      }
    } else {
      await this.setState({
        [`${attr}`]: value,
      });
    }
  };

  cleanAddress = async () => {
    await this.setState({
      ibge: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
    });
  };

  searchViaCep = async (postalCode) => {
    const keepOnlyNumber = (value) => value.replace(/\D/g, '');
    const address = await getAddressByCep(keepOnlyNumber(postalCode));

    if (address.erro) {
      await this.cleanAddress();
    } else {
      await this.setState({
        street: address.logradouro,
        ibge: address.ibge,
        number: '',
        complement: '',
        district: address.bairro,
        city: address.localidade,
        state: address.uf,
        country: I18n.t('BEE103' /* Brasil */),
      });
    }
  };

  createCustomerSubmit = async () => {
    const {
      code, name, businessName, email, phone, cnpj, stateRegistration,
      postalCode, ibge, street, number, complement, district,
      city, state, country, note, singleVolume, specialLabel,
    } = this.state;

    try {
      const newCustomer = await this.props.createCustomer({
        code,
        name,
        businessName,
        email,
        phone: phone ? phone.replace(/\D/g, '') : '',
        cnpj: cnpj ? cnpj.replace(/\D/g, '') : '',
        stateRegistration,
        postalCode: postalCode ? postalCode.replace(/\D/g, '') : '',
        ibge,
        street,
        number,
        complement,
        district,
        city,
        state,
        country,
        note,
        singleVolume,
        specialLabel,
      });

      if (newCustomer) {
        await addNotification(
          'success',
          I18n.t('BEE50' /* Cliente */),
          I18n.t(
            'BEE315',
            { 0: code }, /* Cliente %{0} incluído com sucesso! */
          ),

          'top-right',
        );

        this.props.history.push(ROUTES.CUSTOMER_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE50' /* Cliente */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE50' /* Cliente */),
            I18n.t('BEE316', { 0: code } /* Erro ao incluir a Cliente %{0}! */),

            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE50' /* Cliente */),
          I18n.t('BEE316', { 0: code } /* Erro ao incluir a Cliente %{0}! */),
          'top-right',
        );
      }
    }
  };

  updateCustomerSubmit = async () => {
    const {
      customerId, code, name, businessName, email, phone, cnpj, stateRegistration,
      postalCode, ibge, street, number, complement, district,
      city, state, country, note, singleVolume, specialLabel,
    } = this.state;

    try {
      const updCustomer = await this.props.updateCustomer({
        customerId,
        code,
        name,
        businessName,
        email,
        phone: phone ? phone.replace(/\D/g, '') : '',
        cnpj: cnpj ? cnpj.replace(/\D/g, '') : '',
        stateRegistration,
        postalCode: postalCode ? postalCode.replace(/\D/g, '') : '',
        ibge,
        street,
        number,
        complement,
        district,
        city,
        state,
        country,
        note,
        singleVolume,
        specialLabel,
      });

      if (updCustomer) {
        await addNotification(
          'success',
          I18n.t('BEE304' /* Atualizar Cliente */),
          I18n.t('BEE305', { 0: code } /* Atualização do Cliente %{0} efetuada com sucesso! */),

          'top-right',
        );

        this.props.history.push(ROUTES.CUSTOMER_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE304' /* Atualizar Cliente */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE304' /* Atualizar Cliente */),
            I18n.t('BEE317', { 0: code } /* Erro ao atualizar o Cliente %{0}! */),

            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE304' /* Atualizar Cliente */),
          I18n.t('BEE317', { 0: code } /* Erro ao atualizar o Cliente %{0}! */),

          'top-right',
        );
      }
    }
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      code, name, businessName, email, phone, cnpj, stateRegistration,
      postalCode, street, number, complement, district,
      city, state, country, note, singleVolume, specialLabel,
    } = this.state;

    const {
      mode, disableCode, disableName, disableBusinessName, disableEmail, disablePhone, disableCNPJ,
      disableStateRegistration, disablePostalCode, disableStreet, disableNumber,
      disableComplement, disableDistrict, disableCity, disableState, disableCountry, disableSingleVolume,
      disableSpecialLabel, disableNote, showCancel, showCreate, showUpdate,
    } = this.state;

    let title = '';
    //   let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE318' /* Novo Cliente */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE319' /* Editar Cliente */);
      //    breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE320' /* Detalhes Cliente */);
      //    breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE22' /* Clientes */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE318' /* Novo Cliente */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel className="panel-with-tabs">
                <PanelHeader noButton />
                <PanelBody>
                  <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '1' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('1');
                        }}
                      >
                        <span className="d-sm-none">Tab 1</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE370' /* Dados */)}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '2' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('2');
                        }}
                      >
                        <span className="d-sm-none">Tab 2</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE191' /* Geral */)}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="row">
                        <div className="col-xl-12">
                          <Form
                            noPanel
                            setValue={this.setValue}
                            inputs={(formContext) => ([
                              formContext.createInput(
                                code,
                                'code',
                                `${I18n.t('BEE82' /* Código */)}:`,
                                I18n.t('BEE3698' /* Informe o cliente */),
                                'text',
                                true,
                                disableCode,
                              ),
                              formContext.createInput(
                                name,
                                'name',
                                `${I18n.t('BEE84' /* Razão Social */)}:`,
                                I18n.t('BEE3666' /* Informe a razão social */),
                                'text',
                                true,
                                disableName,
                              ),
                              formContext.createInput(
                                businessName,
                                'businessName',
                                `${I18n.t('BEE352' /* Nome Fantasia */)}:`,
                                I18n.t('BEE353' /* Informe o nome fantasia */),
                                'text',
                                false,
                                disableBusinessName,
                              ),
                              formContext.createInputMask(
                                cnpj,
                                'cnpj',
                                `${I18n.t('BEE85' /* CNPJ */)}:`,
                                I18n.t('BEE3668' /* Informe o CNPJ */),
                                '99.999.999/9999-99',
                                true,
                                disableCNPJ,
                              ),
                              formContext.createInput(
                                stateRegistration,
                                'stateRegistration',
                                `${I18n.t('BEE117' /* Inscrição Estadual */)}:`,
                                I18n.t('BEE3669' /* Informe a inscrição estadual */),
                                'text',
                                true,
                                disableStateRegistration,
                              ),
                              formContext.createInputMask(
                                postalCode,
                                'postalCode',
                                `${I18n.t('BEE119' /* CEP */)}:`,
                                I18n.t('BEE3670' /* Informe o CEP */),
                                '99999-999',
                                true,
                                disablePostalCode,
                              ),
                              formContext.createInput(
                                street,
                                'street',
                                `${I18n.t('BEE121' /* Rua */)}:`,
                                I18n.t('BEE122' /* Informe a rua */),
                                'text',
                                false,
                                disableStreet,
                              ),
                              formContext.createInput(
                                number,
                                'number',
                                `${I18n.t('BEE123' /* Número */)}:`,
                                I18n.t('BEE124' /* Informe o número */),
                                'text',
                                false,
                                disableNumber,
                              ),
                              formContext.createInput(
                                complement,
                                'complement',
                                `${I18n.t('BEE125' /* Complemento */)}:`,
                                I18n.t('BEE126' /* Informe o complemento */),
                                'text',
                                false,
                                disableComplement,
                              ),
                              formContext.createInput(
                                district,
                                'district',
                                `${I18n.t('BEE127' /* Bairro */)}:`,
                                I18n.t('BEE128' /* Informe o bairro */),
                                'text',
                                false,
                                disableDistrict,
                              ),
                              formContext.createInput(
                                city,
                                'city',
                                `${I18n.t('BEE53' /* Cidade */)}:`,
                                I18n.t('BEE129' /* Informe a cidade */),
                                'text',
                                false,
                                disableCity,
                              ),
                              formContext.createInput(
                                state,
                                'state',
                                `${I18n.t('BEE87' /* Estado */)}:`,
                                I18n.t('BEE130' /* Informe o estado */),
                                'text',
                                false,
                                disableState,
                              ),
                              formContext.createInput(
                                country,
                                'country',
                                `${I18n.t('BEE88' /* País */)}:`,
                                I18n.t('BEE131' /* Informe o país */),
                                'text',
                                false,
                                disableCountry,
                              ),
                              formContext.createInput(
                                email,
                                'email',
                                `${I18n.t('BEE86' /* E-mail */)}:`,
                                I18n.t('BEE898' /* Informe o e-mail */),
                                'text',
                                false,
                                disableEmail,
                              ),
                              formContext.createInputMask(
                                phone,
                                'phone',
                                `${I18n.t('BEE133' /* Telefone */)}:`,
                                I18n.t('BEE134' /* Informe o telefone */),
                                phone && phone.length < 11 ? '(99) 9999-9999' : '(99) 99999-9999',
                                false,
                                disablePhone,
                              ),
                              formContext.createInput(
                                note,
                                'note',
                                `${I18n.t('BEE135' /* Observação */)}:`,
                                I18n.t('BEE136' /* Informe a observação */),
                                'text',
                                false,
                                disableNote,
                              ),
                            ])}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="row">
                        <div className="col-xl-12">
                          {this.createToggleBox(
                            singleVolume,
                            'singleVolume',
                            I18n.t('BEE1682' /* Obriga Volume Único */),
                            disableSingleVolume,
                          )}
                          {this.createToggleBox(
                            specialLabel,
                            'specialLabel',
                            I18n.t('BEE1916' /* Etiqueta Especial */),
                            disableSpecialLabel,
                          )}
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.CUSTOMER_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                          {I18n.t('BEE137' /* Voltar */)}
                        </OneClickButton>
                      ))}
                  </Link>
                  {(showCreate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.createCustomerSubmit()}
                      >
                        {I18n.t('BEE138' /* Criar */)}

                      </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.updateCustomerSubmit()}
                      >
                        {I18n.t('BEE139' /* Atualizar */)}

                      </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getCustomer: (customerId) => dispatch(getCustomer(customerId)),
  createCustomer: (customer) => dispatch(createCustomer(customer)),
  updateCustomer: (customer) => dispatch(updateCustomer(customer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerForm));
