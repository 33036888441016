import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Inputs from './input';
import { setFormErrors, setForm } from '../../../../app/store/actions/wizard';

class FormUserWizard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordConfirmed: false,
    };
    this.handleChecked = this.handleChecked.bind(this);
  }

  handleChecked(e) {
    this.setState({ isPasswordConfirmed: e.target.checked });
  }

  render() {
    const {
      email, password, login, name, confirmPassword, errors,
    } = this.props;
    const { isPasswordConfirmed } = this.state;
    const { createInput } = Inputs();

    return (
      <form className="w-50 mx-auto needs-validation" noValidate>
        <div className="row justify-content-center">
          <div className="col">
            {createInput(
              name,
              'name',
              `${I18n.t('BEE1853' /* Nome */)}`,
              I18n.t('BEE1853' /* Nome */),
              'text',
              true,
              false,
              (attr, value) => {
                this.props.setForm(attr, value);

                if (errors && errors[attr]) {
                  const newErrors = { ...errors };
                  delete newErrors[attr];
                  this.props.setFormErrors(newErrors);
                }
              },
              errors && errors.name,
            )}
            {createInput(
              email,
              'email',
              `${I18n.t('BEE897' /* Email */)}`,
              I18n.t('BEE898' /* Email */),
              'email',
              true,
              false,
              (attr, value) => {
                this.props.setForm(attr, value);

                if (errors && errors[attr]) {
                  const newErrors = { ...errors };
                  delete newErrors[attr];
                  this.props.setFormErrors(newErrors);
                }
              },
              errors && errors.email,
            )}
            {createInput(
              login,
              'login',
              `${I18n.t('BEE623' /* Login */)}`,
              I18n.t('BEE623' /* Login */),
              'text',
              true,
              false,
              (attr, value) => {
                this.props.setForm(attr, value);

                if (errors && errors[attr]) {
                  this.props.setFormErrors(attr, null);
                }
              },
              errors && errors.login,
            )}
            {isPasswordConfirmed ? (
              <>
                {createInput(
                  password,
                  'password',
                  `${I18n.t('BEE2' /* Senha */)}`,
                  I18n.t('BEE2' /* Senha */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);

                    if (errors && errors[attr]) {
                      this.props.setFormErrors(attr, null);
                    }
                  },
                  errors && errors.password,
                )}
                {createInput(
                  confirmPassword,
                  'confirmPassword',
                  `${I18n.t('BEE891' /* Confirme a Senha */)}`,
                  I18n.t('BEE891' /* Confirme a Senha */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);

                    if (errors && errors[attr]) {
                      this.props.setFormErrors(attr, null);
                    }
                  },
                  errors && errors.confirmPassword,
                )}
              </>
            ) : (
              <>
                {createInput(
                  password,
                  'password',
                  `${I18n.t('BEE2' /* Senha */)}`,
                  I18n.t('BEE2' /* Senha */),
                  'password',
                  true,
                  false,
                  this.props.setForm,
                  errors && errors.password,
                )}
                {createInput(
                  confirmPassword,
                  'confirmPassword',
                  `${I18n.t('BEE891' /* Confirme a Senha */)}`,
                  I18n.t('BEE891' /* Confirme a Senha */),
                  'password',
                  true,
                  false,
                  this.props.setForm,
                  errors && errors.confirmPassword,
                )}
              </>
            )}
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="confirmPasswordCheckbox"
                checked={isPasswordConfirmed}
                onChange={this.handleChecked}
              />
              <label className="form-check-label" style={{ marginTop: '3px' }} htmlFor="confirmPasswordCheckbox">
                {I18n.t('BEE2886' /* Ver senha */)}
              </label>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setForm: (attr, value) => dispatch(setForm(attr, value)),
  setFormErrors: (attr, error) => dispatch(setFormErrors(attr, error)),
});

const mapStateToProps = (state) => ({
  email: state.wizard.email,
  password: state.wizard.password,
  login: state.wizard.login,
  name: state.wizard.name,
  confirmPassword: state.wizard.confirmPassword,
  errors: state.wizard.errors,
});

export default connect(mapStateToProps, mapDispatchToProps)(FormUserWizard);
