import PrintersRequests from '../../server/printers';
import { addLoading, removeLoading } from './loading';

export const setPrinters = (printers) => ({
  type: 'SET_PRINTERS',
  printers,
});

export const getPrintersList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const printers = await PrintersRequests.getPrinters();
    dispatch(setPrinters(printers));
  } finally {
    dispatch(removeLoading());
  }
};

export const getUserPrinterOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const printersOptions = await PrintersRequests.getUserPrinterOptions();
    return printersOptions;
  } finally {
    dispatch(removeLoading());
  }
};

export const createPrinter = (printer) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newPrinter = await PrintersRequests.createPrinter(printer);
    return newPrinter;
  } finally {
    dispatch(removeLoading());
  }
};

export const updatePrinter = (printer) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PrintersRequests.updatePrinter(printer);
  } finally {
    dispatch(removeLoading());
  }
};

export const getPrinter = (printerId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PrintersRequests.getPrinter(printerId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deletePrinter = (printerId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PrintersRequests.deletePrinter(printerId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUsersPrinter = (printerId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PrintersRequests.getUsersPrinter(printerId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getAllAgentsOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const agents = await PrintersRequests.getAllAgentsOptions();

    return agents;
  } finally {
    dispatch(removeLoading());
  }
};
