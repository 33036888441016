import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Inputs from './input';
import { setForm, setFormErrors, fetchCEP } from '../../../../app/store/actions/wizard';
import validationForm from '../../validator/validateForm';

class FormCompanyWizard extends PureComponent {
  fetchPostalCode = async () => {
    const { isValidPostalCode, unmaskedPostalCode } = validationForm();
    const { postalCode } = this.props;

    if (postalCode && isValidPostalCode(postalCode) && postalCode !== '') {
      const unmaskedCode = unmaskedPostalCode(postalCode);
      const res = await this.props.fetchCEP(unmaskedCode);

      this.props.setForm('district', res.bairro);
      this.props.setForm('postalCode', res.cep);
      this.props.setForm('street', res.logradouro);
      this.props.setForm('city', res.localidade);
      this.props.setForm('state', res.uf);
      this.props.setForm('ibge', res.ibge);
    }
  };

  render() {
    const {
      code,
      corporateName,
      companyName,
      companyEmail,
      cnpj,
      stateRegistration,
      phone,
      postalCode,
      street,
      number,
      complement,
      district,
      city,
      state,
      errors,
      country,
    } = this.props;

    const {
      createInput, createInputCompany, createInputCompanyMask, createInputMask,
    } = Inputs();

    return (
      <form>
        <div className="row">
          <div className="col-xl-12">
            <h3 className="mb-4">{I18n.t('BEE2888' /* Dados da empresa */)}</h3>
            <div style={{ width: '545px' }}>
              {createInput(
                code,
                'code',
                `${I18n.t('BEE82' /* Código */)}`,
                I18n.t('BEE82' /* Código */),
                'text',
                true,
                false,
                (attr, value) => {
                  this.props.setForm(attr, value);

                  if (errors && errors[attr]) {
                    const newErrors = { ...errors };
                    delete newErrors[attr];
                    this.props.setFormErrors(newErrors);
                  }
                },
                errors && errors.code,
              )}
            </div>
            <div className="row">
              <div className="col-md-6">
                {createInputCompany(
                  corporateName,
                  'corporateName',
                  `${I18n.t('BEE84' /* Razão Social */)}`,
                  I18n.t('BEE84' /* Razão Social */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  'mb-3',
                  '',
                  errors && errors.corporateName,
                )}
              </div>
              <div className="col-md-6">
                {createInputCompany(
                  companyName,
                  'companyName',
                  `${I18n.t('BEE83' /* Nome */)}`,
                  I18n.t('BEE83' /* Nome */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  'mb-3',
                  '',
                  errors && errors.companyName,
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                {createInputMask(
                  cnpj,
                  'cnpj',
                  `${I18n.t('BEE85' /* CNPJ */)}`,
                  I18n.t('BEE85' /* CNPJ */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  errors && errors.cnpj,
                  '99.999.999/9999-99',
                )}
              </div>
              <div className="col-md-6">
                {createInput(
                  stateRegistration,
                  'stateRegistration',
                  `${I18n.t('BEE117' /* Inscrição Estadual */)}`,
                  I18n.t('BEE117' /* Inscrição Estadual */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  errors && errors.stateRegistration,
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                {createInput(
                  companyEmail,
                  'companyEmail',
                  `${I18n.t('BEE897' /* Email */)}`,
                  I18n.t('BEE898' /* Informe o email */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  errors && errors.companyEmail,
                )}
              </div>
              <div className="col-md-6">
                {createInputMask(
                  phone,
                  'phone',
                  `${I18n.t('BEE133' /* Telefone */)}`,
                  I18n.t('BEE133' /* Telefone */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  errors && errors.phone,
                  phone && phone.length < 11 ? '(99) 9999-9999' : '(99) 99999-9999',
                )}
              </div>
            </div>
            <hr />
            <h3 className="mb-4">{I18n.t('BEE267' /* Endereço */)}</h3>
            <div className="row">
              <div className="col-md-6">
                {createInputCompanyMask(
                  postalCode,
                  'postalCode',
                  `${I18n.t('BEE119' /* CEP */)}`,
                  I18n.t('BEE119' /* CEP */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  'mb-3',
                  '',
                  errors && errors.postalCode,
                  this.fetchPostalCode,
                  '99999-999',
                )}
              </div>
              <div className="col-md-6">
                {createInputCompany(
                  country,
                  'country',
                  `${I18n.t('BEE88' /* País */)}`,
                  I18n.t('BEE88' /* País */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  'mb-3',
                  '',
                  errors && errors.country,
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                {createInputCompany(
                  street,
                  'street',
                  `${I18n.t('BEE121' /* Rua */)}`,
                  I18n.t('BEE121' /* Rua */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  'mb-3',
                  '',
                  errors && errors.street,
                )}
              </div>
              <div className="col-md-6">
                {createInputCompany(
                  number,
                  'number',
                  `${I18n.t('BEE123' /* Número */)}`,
                  I18n.t('BEE123' /* Número */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  'mb-3',
                  '',
                  errors && errors.number,
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                {createInputCompany(
                  complement,
                  'complement',
                  `${I18n.t('BEE125' /* Complemento */)}`,
                  I18n.t('BEE125' /* Complemento */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  'mb-3',
                  '',
                  errors && errors.complement,
                )}
              </div>
              <div className="col-md-6">
                {createInputCompany(
                  district,
                  'district',
                  `${I18n.t('BEE127' /* Bairro */)}`,
                  I18n.t('BEE127' /* Bairro */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  'mb-3',
                  '',
                  errors && errors.district,
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                {createInputCompany(
                  city,
                  'city',
                  `${I18n.t('BEE53' /* Cidade */)}`,
                  I18n.t('BEE53' /* Cidade */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  'mb-3',
                  '',
                  errors && errors.city,
                )}
              </div>
              <div className="col-md-6">
                {createInputCompany(
                  state,
                  'state',
                  `${I18n.t('BEE87' /* Estado */)}`,
                  I18n.t('BEE87' /* Estado */),
                  'text',
                  true,
                  false,
                  (attr, value) => {
                    this.props.setForm(attr, value);
                    if (errors && errors[attr]) {
                      const newErrors = { ...errors };
                      delete newErrors[attr];
                      this.props.setFormErrors(newErrors);
                    }
                  },
                  'mb-3',
                  '',
                  errors && errors.state,
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setForm: (attr, value) => dispatch(setForm(attr, value)),
  setFormErrors: (attr, error) => dispatch(setFormErrors(attr, error)),
  fetchCEP: (state) => dispatch(fetchCEP(state)),
});

const mapStateToProps = (state) => ({
  code: state.wizard.code,
  corporateName: state.wizard.corporateName,
  companyName: state.wizard.companyName,
  companyEmail: state.wizard.companyEmail,
  phone: state.wizard.phone,
  cnpj: state.wizard.cnpj,
  stateRegistration: state.wizard.stateRegistration,
  postalCode: state.wizard.postalCode,
  street: state.wizard.street,
  number: state.wizard.number,
  complement: state.wizard.complement,
  district: state.wizard.district,
  city: state.wizard.city,
  state: state.wizard.state,
  country: state.wizard.country,
  errors: state.wizard.errors,
});

export default connect(mapStateToProps, mapDispatchToProps)(FormCompanyWizard);
