const INITIAL_STATE = {
  transportEquipmentsList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_TRANSPORT_EQUIPMENTS': {
      return { ...state, transportEquipmentsList: action.transportEquipment };
    }
    default:
      return state;
  }
};
