import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';

import { getBranchesOptions } from '../../../../app/store/actions/branches';
import { getRangesOptions } from '../../../../app/store/actions/ranges';
import {
  createStorageAddress, exportAddressList, generateStorageAddresses,
  getStorageAddress, saveListGeneratedAddresses, updateStorageAddress,
} from '../../../../app/store/actions/storageAddresses';
import { getStorageAddressSizesList } from '../../../../app/store/actions/storageAdressSizes';
import { getStorageAddressTypesOptions } from '../../../../app/store/actions/storageAdressTypes';
import Select from '../../../../components/form/select';
import addNotification from '../../../../components/notification';
import ROUTES from '../../../../config/routes';
import OneClickButton from '../../../../components/form/button';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';
import { getAllCurves } from '../../../../app/store/actions/curves';
import Form from '../../../../components/form/form';

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};
class StorageAddressFormMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      branch: null,
      branchName: '',
      type: '',
      typeName: '',
      range: null,
      rangeName: '',
      sector: '',
      street: '',
      level: '',
      column: '',
      drawer: '',
      size: '',
      sizeName: '',
      height: 0,
      width: 0,
      length: 0,
      capacityVolume: 0,
      capacityWeight: 0,
      shareAddress: false,
      shareAddressOfProductsWithDifferentLots: false,
      note: '',
      blockedName: '',
      disableCode: true,
      disableName: true,
      disableBranchCode: true,
      disableType: true,
      disableRange: true,
      disableSector: true,
      disableStreet: true,
      disableLevel: true,
      disableColumn: true,
      disableDrawer: true,
      disableBlocked: true,
      disableSize: true,
      disableHeight: true,
      disableWidth: true,
      disableLength: true,
      disableCapacityVolume: true,
      disableCapacityWeight: true,
      disableNote: true,
      disableShareAddress: true,
      disableShareAddressOfProductsWithDifferentLots: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      addressSizesOptions: [],
      check: '',
      curves: [],
      curve: '',
      inboundSequence: null,
      outboundSequence: null,
    };
  }

  async componentDidMount() {
    await this.setMode();
    const listBranch = await this.props.getBranchesOptions();
    await this.props.getStorageAddressSizesList();
    const listType = await this.props.getStorageAddressTypesOptions();
    await this.listOptions();

    this.getCurves();

    if (this.state.mode !== 'create') {
      await this.getAddressSelected();
    }
    const { branch } = this.state;
    const listRange = await this.props.getRangesOptions(branch);

    this.setState({
      listBranch,
      listType,
      listRange,
    });
  }

  handleInputChange(event) {
    const {
      name, type, checked, value,
    } = event.target;

    if (type === 'checkbox') this.setState({ [name]: checked });
    else if (type === 'radio') this.setState({ [name]: parseInt(value, 10) });
    else this.setState({ [name]: value });
  }

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });

    if (attr === 'size' && value) {
      this.props.addressSizesList.forEach((element) => {
        if (element.id === value.value) {
          this.setState({
            height: element.height,
            width: element.width,
            length: element.length,
            capacityVolume: element.capacityVolume,
            capacityWeight: element.capacityWeight,
            curve: element.curve,
          });
        }
      });
    } else if (attr === 'size') {
      this.setState({
        height: '',
        width: '',
        length: '',
        capacityVolume: '',
        capacityWeight: '',
        curve: '',
      });
    } else if (attr === 'branch') {
      const { branch } = this.state;
      const listRange = await this.props.getRangesOptions(branch);
      this.setState({
        listRange,
        range: null,
        rangeName: '',
      });
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });

    if (
      this.state.code === this.state.check
      && (attr === 'sector' || attr === 'street' || attr === 'level' || attr === 'column' || attr === 'drawer')
    ) {
      await this.setState((prevState) => ({
        code: prevState.sector + prevState.street + prevState.column + prevState.level + prevState.drawer,
        check: prevState.sector + prevState.street + prevState.column + prevState.level + prevState.drawer,
      }));
    }
  };

  createToggleBox = (item, attr, label, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <span className="col-form-label col-md-4">{label}</span>
      <div className="col-md-5">
        <div className="row" style={{ marginTop: '8px', marginLeft: '0px' }}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={this.handleInputChange.bind(this)}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`}>{}</label>
          </div>
        </div>
      </div>
    </div>
  );

  createSelectDropDown = (value, attr, label, items, required = false) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div id={attr} className="col-md-5">
        <Select
          value={value.label ? value : ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          required={required}
          styles={colourStyles}
        />
      </div>
    </div>
  );

  createSelect = (value, attr, label, items, required = false) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div id={attr} className="col-md-5">
        <select
          className="form-control"
          value={value || ''}
          required={required}
          onChange={(event) => this.setValue(attr, event.target.value)}
        >
          {items.map((item) => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    </div>
  );

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div id={attr} className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(event) => this.setValue(attr, event.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  setMode = () => {
    let mode;
    if (this.props.isCreate) mode = 'create';
    else if (this.props.isCreateGenerate) mode = 'createGenerate';
    else if (this.props.isCopy) mode = 'copy';
    else if (this.props.isEdit) mode = 'edit';
    else if (this.props.isDetail) mode = 'detail';
    else mode = '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableBranchCode: false,
        disableType: false,
        disableRange: false,
        disableSector: false,
        disableStreet: false,
        disableLevel: false,
        disableColumn: false,
        disableDrawer: false,
        disableBlocked: false,
        disableSize: false,
        disableHeight: false,
        disableWidth: false,
        disableLength: false,
        disableCapacityVolume: false,
        disableCapacityWeight: false,
        disableNote: false,
        disableShareAddress: false,
        disableShareAddressOfProductsWithDifferentLots: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableBranchCode: true,
        disableName: false,
        disableType: false,
        disableRange: false,
        disableSector: false,
        disableStreet: false,
        disableLevel: false,
        disableColumn: false,
        disableDrawer: false,
        disableBlocked: false,
        disableSize: false,
        disableHeight: false,
        disableWidth: false,
        disableLength: false,
        disableCapacityWeight: false,
        disableCapacityVolume: false,
        disableNote: false,
        disableShareAddress: false,
        disableShareAddressOfProductsWithDifferentLots: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  getCurves = async () => {
    await this.props.getAllCurves();
    const curves = [];
    this.props.curves.map((element) => (curves.push({
      value: element.code,
      label: element.code,
    })));
    this.setState({
      curves: [...curves],
    });
  };

  listOptions = async () => {
    if (this.props.addressSizesList) {
      this.props.addressSizesList.forEach((element) => {
        this.state.addressSizesOptions.push({ value: element.id, label: `${element.code} - ${element.name}` });
      });
    }
  };

  getAddressSelected = async () => {
    if (this.props.location.state.address) {
      const { address } = this.props.location.state;
      const addressSelected = await this.props.getStorageAddress(address.id);
      const sizeName = (this.props.addressSizesList.length)
        ? this.props.addressSizesList.find((element) => element.id === addressSelected.size)
        : '';

      if (addressSelected) {
        this.setState({
          storageAddressId: addressSelected.id,
          code: addressSelected.code,
          name: addressSelected.name,
          branch: addressSelected.branchCode,
          branchName: addressSelected.branchName,
          type: addressSelected.type,
          typeName: addressSelected && addressSelected.addressType
            ? `${addressSelected.addressType.id} - ${addressSelected.addressType.name}`
            : '',
          range: addressSelected.rangeCode,
          rangeName: addressSelected && addressSelected.range
            ? `${addressSelected.rangeCode} - ${addressSelected.range.name}`
            : '',
          sector: addressSelected.sector,
          street: addressSelected.street,
          level: addressSelected.level,
          column: addressSelected.column,
          drawer: addressSelected.drawer,
          blocked: addressSelected.blocked,
          blockedName: (addressSelected.blocked) ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */),
          size: addressSelected.size,
          sizeName: (sizeName) ? `${sizeName.code} - ${sizeName.name}` : '',
          height: addressSelected.height,
          width: addressSelected.width,
          length: addressSelected.length,
          capacityVolume: addressSelected.capacityVolume,
          capacityWeight: addressSelected.capacityWeight,
          shareAddress: addressSelected.shareAddress,
          note: addressSelected.note,
          curve: addressSelected.curve,
          shareAddressOfProductsWithDifferentLots: addressSelected.shareAddressOfProductsWithDifferentLots,
          inboundSequence: addressSelected.inboundSequence,
          outboundSequence: addressSelected.outboundSequence,
        });
      }
    }
  };

  createAddressSubmit = async () => {
    const {
      code, name, branch, type, sector, street, range,
      level, column, drawer, blocked, size, height, inboundSequence, outboundSequence,
      width, length, capacityVolume, capacityWeight, note,
      shareAddress, shareAddressOfProductsWithDifferentLots, curves, curve,
    } = this.state;

    const selectedCurve = curve || curves[0].value;

    try {
      const newAddress = await this.props.createStorageAddress({
        code: code.toLocaleUpperCase(),
        name,
        branchCode: branch,
        type,
        rangeCode: range,
        sector: sector.toLocaleUpperCase(),
        street: street.toLocaleUpperCase(),
        level: level.toLocaleUpperCase(),
        column: column.toLocaleUpperCase(),
        drawer: drawer.toLocaleUpperCase(),
        blocked,
        size,
        height,
        width,
        length,
        capacityVolume,
        capacityWeight,
        shareAddress,
        shareAddressOfProductsWithDifferentLots,
        note,
        curve: selectedCurve,
        inboundSequence,
        outboundSequence,
      });

      if (newAddress) {
        await addNotification(
          'success',
          I18n.t('BEE267' /* Endereço */),
          I18n.t('BEE268', { 0: code } /* Endereço %{0} incluído com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.ADDRESS_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE267' /* Endereço */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE267' /* Endereço */),
            I18n.t('BEE269', { 0: code } /* Erro ao incluir o Endereço %{code}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE267' /* Endereço */),
          I18n.t('BEE269', { 0: code } /* Erro ao incluir o Endereço %{code}! */),
          'top-right',
        );
      }
    }
  };

  updateAddressSubmit = async () => {
    const {
      storageAddressId, code, name, branch, type, sector, street, range,
      level, column, drawer, blocked, size, height, width,
      length, capacityVolume, capacityWeight, note, shareAddress,
      shareAddressOfProductsWithDifferentLots, curve, inboundSequence, outboundSequence,
    } = this.state;

    try {
      const updAddress = await this.props.updateStorageAddress({
        storageAddressId,
        name,
        branchCode: branch,
        type,
        rangeCode: range,
        sector: sector.toLocaleUpperCase(),
        street: street.toLocaleUpperCase(),
        level: level.toLocaleUpperCase(),
        column: column.toLocaleUpperCase(),
        drawer: drawer.toLocaleUpperCase(),
        blocked,
        size,
        height,
        width,
        length,
        capacityVolume,
        capacityWeight,
        shareAddress,
        shareAddressOfProductsWithDifferentLots,
        note,
        curve,
        inboundSequence,
        outboundSequence,
      });

      if (updAddress) {
        await addNotification(
          'success',
          I18n.t('BEE270' /* Atualizar Endereço */),
          I18n.t('BEE271', { 0: code } /* Atualização do Endereço %{0} efetuada com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.ADDRESS_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE270' /* Atualizar Endereço */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE270' /* Atualizar Endereço */),
            I18n.t('BEE272', { 0: code } /* Erro ao atualizar o Endereço %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE270' /* Atualizar Endereço */),
          I18n.t('BEE272', { 0: code } /* Erro ao atualizar o Endereço %{0}! */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      code, name, branch, branchName, type, typeName, range, rangeName,
      sector, street, level, column, drawer, inboundSequence, outboundSequence,
      height, length, width, size, sizeName, note,
      capacityVolume, capacityWeight, listType, listRange,
      listBranch, shareAddress, blockedName, curves, curve,
    } = this.state;

    const {
      mode, disableCode, disableName, disableBranchCode, disableType, disableRange,
      disableSector, disableStreet, disableLevel, disableColumn, disableDrawer,
      disableBlocked, disableSize, disableLength, disableHeight, disableWidth,
      disableCapacityVolume, disableCapacityWeight, disableNote, showCancel,
      showCreate, showUpdate, disableShareAddress, addressSizesOptions,
      shareAddressOfProductsWithDifferentLots, disableShareAddressOfProductsWithDifferentLots,
    } = this.state;

    let title = '';
    if (mode === 'create' || mode === 'copy') title = I18n.t('BEE273' /* Novo Endereço */);
    else if (mode === 'edit') title = I18n.t('BEE274' /* Editar Endereço */);
    else if (mode === 'detail') title = I18n.t('BEE275' /* Detalhes Endereço */);

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {` ${I18n.t('BEE12' /* Início */)}`}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE20' /* Endereços */)}</li>
            <li className="breadcrumb-item active">{title}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  <Form
                    noPanel
                    setValue={this.setValue}
                    setValueDrop={this.setValueDrop}
                    inputs={(formContext) => ([
                      disableBranchCode
                        ? formContext.createInput(
                          branchName,
                          'branch',
                          `${I18n.t('BEE145' /* Filial */)}:`,
                          '',
                          'text',
                          false,
                          disableBranchCode,
                        )
                        : formContext.createSelectDropDown(
                          { value: branch, label: branchName },
                          'branch',
                          `${I18n.t('BEE145' /* Filial */)}:`,
                          listBranch,
                          disableBranchCode,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          true,
                        ),
                      formContext.createInput(
                        code.toLocaleUpperCase(),
                        'code',
                        `${I18n.t('BEE82' /* Código */)}:`,
                        I18n.t('BEE3724' /* Informe o endereço */),
                        'text',
                        true,
                        disableCode,
                      ),
                      formContext.createInput(
                        name,
                        'name',
                        `${I18n.t('BEE277' /* Descrição */)}:`,
                        I18n.t('BEE278' /* Informe a descrição do endereço */),
                        'text',
                        true,
                        disableName,
                      ),
                      disableType
                        ? formContext.createInput(
                          typeName,
                          'type',
                          I18n.t('BEE200' /* Tipo */),
                          '',
                          'text',
                          false,
                          disableType,
                        )
                        : formContext.createSelectDropDown(
                          { value: type, label: typeName },
                          'type',
                          `${I18n.t('BEE200' /* Tipo */)}:`,
                          listType,
                          disableType,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          true,
                        ),
                      disableRange
                        ? formContext.createInput(
                          rangeName,
                          'type',
                          I18n.t('BEE581' /* Range */),
                          '',
                          'text',
                          false,
                          disableRange,
                        )
                        : formContext.createSelectDropDown(
                          { value: range, label: rangeName },
                          'range',
                          `${I18n.t('BEE581' /* Range */)}:`,
                          listRange,
                          disableRange,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          true,
                        ),
                      formContext.createInput(
                        sector.toLocaleUpperCase(),
                        'sector',
                        I18n.t('BEE279' /* Setor */),
                        I18n.t('BEE280' /* Informe o setor */),
                        'text',
                        false,
                        disableSector,
                      ),
                      formContext.createInput(
                        street.toLocaleUpperCase(),
                        'street',
                        I18n.t('BEE121' /* Rua */),
                        I18n.t('BEE122' /* Informe a rua */),
                        'text',
                        false,
                        disableStreet,
                      ),
                      formContext.createInput(
                        column.toLocaleUpperCase(),
                        'column',
                        `${I18n.t('BEE281' /* Coluna */)}:`,
                        I18n.t('BEE282' /* Informe a coluna */),
                        'text',
                        false,
                        disableColumn,
                      ),
                      formContext.createInput(
                        level.toLocaleUpperCase(),
                        'level',
                        `${I18n.t('BEE283' /* Nível */)}:`,
                        I18n.t('BEE284' /* Informe o nível */),
                        'text',
                        false,
                        disableLevel,
                      ),
                      formContext.createInput(
                        drawer.toLocaleUpperCase(),
                        'drawer',
                        `${I18n.t('BEE285' /* Gaveta */)}:`,
                        I18n.t('BEE286' /* Informe a gaveta */),
                        'text',
                        false,
                        disableDrawer,
                      ),
                      disableBlocked && formContext.createInput(
                        blockedName,
                        'blocked',
                        `${I18n.t('BEE207' /* Bloqueado */)}?:`,
                        '',
                        'text',
                        false,
                        disableBlocked,
                      ),
                      this.createToggleBox(
                        shareAddress,
                        'shareAddress',
                        `${I18n.t('BEE697' /* Compartilha Endereço */)}:`,
                        disableShareAddress,
                      ),
                      this.createToggleBox(
                        shareAddressOfProductsWithDifferentLots,
                        'shareAddressOfProductsWithDifferentLots',
                        `${I18n.t('BEE2371' /* Compartilha Lote */)}:`,
                        disableShareAddressOfProductsWithDifferentLots,
                      ),
                      disableSize
                        ? formContext.createInput(
                          sizeName,
                          'sizeName',
                          `${I18n.t('BEE385' /* Classificação */)}:`,
                          '',
                          'text',
                          false,
                          disableSize,
                        )
                        : formContext.createSelectDropDown(
                          { value: size, label: sizeName },
                          'size',
                          `${I18n.t('BEE385' /* Classificação */)}:`,
                          addressSizesOptions,
                          disableSize,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          true,
                        ),
                      formContext.createInput(
                        height,
                        'height',
                        `${I18n.t('BEE386' /* Altura */)}:`,
                        I18n.t('BEE395' /* Informe a altura */),
                        'text',
                        false,
                        disableHeight,
                      ),
                      formContext.createInput(
                        length,
                        'length',
                        `${I18n.t('BEE388' /* Comprimento */)}:`,
                        I18n.t('BEE396' /* Informe o comprimento */),
                        'text',
                        false,
                        disableLength,
                      ),
                      formContext.createInput(
                        width,
                        'width',
                        `${I18n.t('BEE387' /* Largura */)}:`,
                        I18n.t('BEE397' /* Informe a largura */),
                        'text',
                        false,
                        disableWidth,
                      ),
                      disableSize
                        ? formContext.createInput(
                          curve,
                          'curve',
                          `${I18n.t('BEE355' /* Curva */)}:`,
                          '',
                          'text',
                          false,
                          disableSize,
                        )
                        : formContext.createSelect(
                          curve,
                          'curve',
                          `${I18n.t('BEE355' /* Curva */)}:`,
                          curves,
                        ),
                      formContext.createInput(
                        capacityVolume,
                        'capacityVolume',
                        `${I18n.t('BEE727' /* Capacidade (Volume) */)}:`,
                        I18n.t('BEE398' /* Informe a capacidade */),
                        'text',
                        false,
                        disableCapacityVolume,
                      ),
                      formContext.createInput(
                        capacityWeight,
                        'capacityWeight',
                        `${I18n.t('BEE726' /* Capacidade (Peso) */)}:`,
                        I18n.t('BEE398' /* Informe a capacidade */),
                        'text',
                        false,
                        disableCapacityWeight,
                      ),
                      formContext.createInput(
                        note,
                        'note',
                        `${I18n.t('BEE135' /* Observação */)}:`,
                        I18n.t('BEE136' /* Informe a observação */),
                        'text',
                        false,
                        disableNote,
                      ),
                      formContext.createInput(
                        inboundSequence,
                        'inboundSequence',
                        `${I18n.t('BEE3577' /* Sequência de Entrada */)}:`,
                        I18n.t('BEE3577' /* Sequência de Entrada */),
                        'number',
                      ),
                      formContext.createInput(
                        outboundSequence,
                        'outboundSequence',
                        `${I18n.t('BEE3576' /* Sequência de Saída */)}:`,
                        I18n.t('BEE3576' /* Sequência de Saída */),
                        'number',
                      ),
                    ])}
                  />
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.ADDRESS_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE137' /* Voltar */)}
                        </OneClickButton>
                      )
                    )}
                  </Link>
                  {(showCreate && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createAddressSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}
                    </OneClickButton>
                  ))}
                  {(showUpdate && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateAddressSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}
                    </OneClickButton>
                  ))}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  addressSizesList: state.storageAddressSizes.storageAddressSizesList,
  curves: state.curves?.curvesList,
});

const mapDispatchToProps = (dispatch) => ({
  getStorageAddress: (storageAddressId) => dispatch(getStorageAddress(storageAddressId)),
  createStorageAddress: (address) => dispatch(createStorageAddress(address)),
  generateStorageAddresses: (addresses) => dispatch(generateStorageAddresses(addresses)),
  saveListGeneratedAddresses: (addressesList) => dispatch(saveListGeneratedAddresses(addressesList)),
  updateStorageAddress: (address) => dispatch(updateStorageAddress(address)),
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getStorageAddressSizesList: () => dispatch(getStorageAddressSizesList()),
  getStorageAddressTypesOptions: () => dispatch(getStorageAddressTypesOptions()),
  getRangesOptions: (branchCode) => dispatch(getRangesOptions(branchCode)),
  exportAddressList: (columns, rows, nameDoc, typeDoc) => dispatch(exportAddressList(columns, rows, nameDoc, typeDoc)),
  getAllCurves: () => dispatch(getAllCurves()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StorageAddressFormMain));
