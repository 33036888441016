import RestService from '../services/rest';

async function getWaybillsByDate(startDate, endDate) {
  return RestService.getAuthenticated(`waybill/listByDate?filterStartDate=${startDate}&filterEndDate=${endDate}`);
};

async function syncWaybillErp(waybillId) {
  return RestService.postAuthenticated('waybill/syncErp', { waybillId });
};

export default {
  getWaybillsByDate,
  syncWaybillErp
}