import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import addNotification from '../../../../../components/notification';
import Form from '../../../../../components/form/form';

import ROUTES from '../../../../../config/routes';

import {
  updateBranchSettings, getBranchSettings,
} from '../../../../../app/store/actions/branches';

class BranchForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      disableCode: true,
      disableName: true,
      disableCompany: true,
      showUpdate: false,
      showCancel: false,
      code: '',
      name: '',
      company: '',
      urlErp: '',
      userUrlErp: '',
      passUrlErp: '',
      integrationType: '',
      branchCodeErp: '',
    };

    this.optionsIntegrationType = [
      { label: 'TotvsV2', value: 'TotvsV2' },
      { label: 'TotvsV1', value: 'TotvsV1' },
    ];
  }

  async componentDidMount() {
    const mode = await this.setMode();

    if (mode !== 'create') {
      await this.getBranchSettingsSelected();
    }
  }

  getBranchSettingsSelected = async () => {
    if (this.props.location.state.branch) {
      const { branch } = this.props.location.state;

      const branchSettingsSelected = await this.props.getBranchSettings(branch.id);

      if (branchSettingsSelected) {
        this.setState({
          code: branchSettingsSelected.code,
          name: branchSettingsSelected.name,
          company: branchSettingsSelected.company,
          urlErp: branchSettingsSelected.urlErp,
          userUrlErp: branchSettingsSelected.userUrlErp,
          passUrlErp: branchSettingsSelected.passUrlErp,
          integrationType: branchSettingsSelected.integrationType,
          branchCodeErp: branchSettingsSelected.branchCodeErp,
        });
      }
    }
  };

  // Forcando edit, mas para futuras implementacoes ou permissoes, o metodo ja estaram disponivel para alteracao
  setMode = () => {
    const mode = 'edit';

    if (mode === 'edit') {
      this.setState({
        disableCode: true,
        disableName: true,
        disableCompany: true,
        showUpdate: true,
        showCancel: true,
        code: '',
        name: '',
        company: '',
        urlErp: '',
        userUrlErp: '',
        passUrlErp: '',
        integrationType: '',
        branchCodeErp: '',
      });
    }

    return mode;
  };

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  updateBranchSettingsSubmit = async () => {
    const {
      code, urlErp, userUrlErp, passUrlErp, integrationType, branchCodeErp,
    } = this.state;

    try {
      const updBranch = await this.props.updateBranchSettings({
        branchCode: code,
        urlErp,
        userUrlErp,
        passUrlErp,
        integrationType,
        branchCodeErp,
      });

      if (updBranch) {
        addNotification(
          'success',
          I18n.t('BEE148' /* Atualizar Filial */),
          I18n.t('BEE149', { 0: code } /* Atualização da Filial %{0} efetuada com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.BRANCH_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE148' /* Atualizar Filial */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE148' /* Atualizar Filial */),
            I18n.t('BEE150', { 0: code } /* Erro ao atualizar a Filial %{0}! */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE148' /* Atualizar Filial */),
          I18n.t('BEE150', { 0: code } /* Erro ao atualizar a Filial %{0}! */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      code, name, company,
    } = this.state;

    const {
      disableCode,
      disableName,
      disableCompany,
      showUpdate,
      showCancel,
      urlErp,
      userUrlErp,
      passUrlErp,
      integrationType,
      branchCodeErp,
    } = this.state;

    const title = I18n.t('BEE439' /* Parâmetros */);

    const breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            { I18n.t('BEE12' /* Início */) }
          </>
        ),
      },
      { value: I18n.t('BEE13' /* Administração */) },
      { value: I18n.t('BEE14' /* Gerais */) },
      { value: I18n.t('BEE16' /* Filiais */) },
      { value: `${code} - ${name}`, active: true },
    ];

    return (
      <Form
        title={title}
        noButton
        linkBack={{ pathname: ROUTES.BRANCH_LIST, state: { dice: this.props.location.dice } }}
        showCancel={showCancel}
        showUpdate={showUpdate}
        setValue={this.setValue}
        onSubmitUpdate={this.updateBranchSettingsSubmit}
        breadcrumb={breadcrumb}
        inputs={(formContext) => ([
          formContext.createInput(
            code,
            'code',
            `${I18n.t('BEE82' /* Código */)}:`,
            I18n.t('BEE1781' /* Informe a filial */),
            'text',
            true,
            disableCode,
          ),
          formContext.createInput(
            name,
            'name',
            `${I18n.t('BEE83' /* Nome */)}:`,
            I18n.t('BEE3671' /* Informe o nome da filial */),
            'text',
            true,
            disableName,
          ),
          formContext.createInput(
            company,
            'company',
            `${I18n.t('BEE112' /* Empresa */)}:`,
            '',
            'text',
            false,
            disableCompany,
          ),
          formContext.createInput(
            urlErp,
            'urlErp',
            `${I18n.t('BEE2534' /* URL de conexão ERP */)}:`,
            '',
            'text',
            false,
          ),
          formContext.createInput(
            userUrlErp,
            'userUrlErp',
            `${I18n.t('BEE2535' /* Usuário ERP */)}:`,
            '',
            'text',
            false,
          ),
          formContext.createInput(
            passUrlErp,
            'passUrlErp',
            `${I18n.t('BEE2536' /* Senha ERP */)}:`,
            '',
            'password',
            false,
          ),
          formContext.createSelect(
            integrationType,
            'integrationType',
            `${I18n.t('BEE3004' /* Tipo de Integração */)}:`,
            this.optionsIntegrationType,
            false,
          ),
          formContext.createInput(
            branchCodeErp,
            'branchCodeErp',
            `${I18n.t('BEE3587' /* Código da Filial no ERP */)}:`,
            '',
            'text',
            false,
          ),
        ])}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getBranchSettings: (branchId) => dispatch(getBranchSettings(branchId)),
  updateBranchSettings: (branch) => dispatch(updateBranchSettings(branch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BranchForm));
