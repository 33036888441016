import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import SweetAlert from 'react-bootstrap-sweetalert';
import Table from '../../../../components/table/Table';

import addNotification from '../../../../components/notification';
import Form from '../../../../components/form/form';
import { PanelPage } from '../../../../components/pages/pages';
import { listSerialGroupsToPrint } from '../../../../app/store/actions/serialGroup';
import { getUserPrinterOptions } from '../../../../app/store/actions/printers';
import { printSerialGroup, reprintSerialGroup } from '../../../../app/store/actions/labels';

import SerialLabelHelpers from '../../../../helpers/serialLabel';

import WikiHelp from '../../../../components/tooltip/wikiHelp';
import ROUTES from '../../../../config/routes';
import OneClickButton from '../../../../components/form/button';
import Permissions from '../../../../app/services/permissions';

class PrintSerialGroup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      firstLoading: true,
      totalDataLength: 0,
      pages: 1,
      productionOrderCode: '',
      branchCode: '',
      depositCode: '',
      lotNumber: '',
      expectedDate: '',
      product: '',
      quantity: 0,
      serialGroups: [],
      productionOrderId: undefined,
      defaultPageSize: 10,
      showPrinterDialog: false,
      printerSelected: '',
      serialGroupSelected: {},
    };

    this.defaultSorted = [{
      id: 'createdAt',
      desc: true,
    }];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 120,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            {
              (rows.original.situation === 1 || (rows.original.situation === 2
                && !Permissions.checkPermission(ROUTES.P_INDUSTRY_REPRINT_SERIAL_GROUP)))
              && (
                <OneClickButton
                  type="button"
                  className="btn btn-primary btn-xs"
                  onClick={() => this.showPrinterDialog(rows.original)}
                >
                  <span className="d-flex align-items-center text-start">
                    <i className="fa fa-print" />
                    <strong className="ml-1">
                      {rows.original.situation === 1 && (
                        I18n.t('BEE1270') // Imprimir
                      )}
                      {rows.original.situation === 2
                      && !Permissions.checkPermission(ROUTES.P_INDUSTRY_REPRINT_SERIAL_GROUP) && (
                        I18n.t('BEE1269') // Reimprimir
                      )}
                    </strong>
                  </span>
                </OneClickButton>
              )
            }
          </div>
        ),
      },
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2764' /* Etiqueta Agrupadora */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1688' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Filter: this.filterColumn,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{parseFloat(row.value)}</span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE434' /* Data de Validade */),
        accessor: 'expirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
        Cell: (row) => (
          <span>
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE578' /* Data Criação */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
        Cell: (row) => (
          <div>
            <span>
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{SerialLabelHelpers.serialLabelStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === row.status.toString()) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%', textAlign: 'center' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {SerialLabelHelpers.serialLabelStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE204' /* Situação */),
        accessor: 'situation',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{SerialLabelHelpers.serialLabelSituation(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === row.status.toString()) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%', textAlign: 'center' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {SerialLabelHelpers.serialLabelSituation().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }];
  }

  async componentDidMount() {
    const printerReq = await this.props.getUserPrinterOptions();
    const { printerOptions, userPrinter } = printerReq;

    this.setState({
      printerOptions,
      printerSelected: userPrinter || printerOptions ? printerOptions[0].value : '',
      serialGroupSelected: {},
    });

    if (this.props.location.state && this.props.location.state.productionOrderCode) {
      this.setState({ productionOrderCode: this.props.location.state.productionOrderCode, firstLoading: false });

      await this.listSerialGroupsToPrint();
    }
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
    buttonLabel = '',
    buttonFunction = undefined,
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          {buttonLabel && buttonFunction && (
            <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  createTextArea = (value = '', attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group">
      <p className="col-form-label">{label}</p>
      <div>
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  listSerialGroupsToPrint = async () => {
    const {
      productionOrderCode, firstLoading, productionOrderId,
    } = this.state;

    if (firstLoading && productionOrderId) {
      this.setState({ firstLoading: false });
      return;
    }

    try {
      const {
        productionOrder,
        serialGroups,
      } = await this.props.listSerialGroupsToPrint(
        productionOrderCode,
        this.page,
        this.pageSize,
        this.sorted,
        this.filtered,
      );

      if (!productionOrder) {
        addNotification(
          'danger',
          I18n.t('BEE3255' /* Impressão Etiquetas Agrupadoras */),
          I18n.t('BEE2782', { 0: productionOrderCode } /* Ordem de produção %{0} encontrada! */),
          'top-right',
        );
      } else {
        const pages = Math.ceil(serialGroups.count / this.pageSize);

        this.setState({
          productionOrderCode: productionOrder.code,
          productionOrderId: productionOrder.id,
          branchCode: productionOrder.branchCode,
          depositCode: productionOrder.depositCode,
          lotNumber: productionOrder.lotNumber,
          expectedDate: productionOrder.expectedDate ? moment(productionOrder.expectedDate).format('DD/MM/YYYY') : '',
          product: `${productionOrder.productCode} - ${productionOrder.product.fullName}`,
          quantity: Number(productionOrder.quantity),
          serialGroups: serialGroups.rows,
          totalDataLength: serialGroups.count,
          pages,
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification(
          'danger',
          I18n.t('BEE3255' /* Impressão Etiquetas Agrupadoras */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE3255' /* Impressão Etiquetas Agrupadoras */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  showPrinterDialog = async (serialGroup) => {
    const { printerOptions } = this.state;

    if (!printerOptions || printerOptions.length === 0) {
      addNotification(
        'danger',
        I18n.t('BEE44' /* Consolidação */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
    } else if (printerOptions && printerOptions.length > 1) {
      this.setState({
        serialGroupSelected: serialGroup,
        showPrinterDialog: true,
      });
    } else if (printerOptions && printerOptions.length === 1) {
      await this.setState({
        serialGroupSelected: serialGroup,
        printerSelected: printerOptions[0].value,
      });
      this.printSerialGroup();
    }
  };

  printSerialGroup = async () => {
    const { productionOrderCode, printerSelected, serialGroupSelected } = this.state;
    this.setState({
      showPrinterDialog: false,
    });
    try {
      if (serialGroupSelected.situation === 1) {
        await this.props.printSerialGroup(printerSelected, serialGroupSelected.id);
      } else if (serialGroupSelected.situation === 2) {
        await this.props.reprintSerialGroup(printerSelected, serialGroupSelected.id);
      }

      const {
        productionOrder,
        serialGroups,
      } = await this.props.listSerialGroupsToPrint(
        productionOrderCode,
        this.page,
        this.pageSize,
        this.sorted,
        this.filtered,
      );

      if (productionOrder) {
        const pages = Math.ceil(serialGroups.count / this.pageSize);
        this.setState({
          quantity: Number(productionOrder.quantity),
          serialGroups: serialGroups.rows,
          pages,
          serialGroupSelected: {},
        });

        addNotification(
          'success',
          I18n.t('BEE3255' /* Impressão Etiquetas Agrupadoras */),
          I18n.t('BEE2790' /* Etiquetas criadas e enviadas para a impressora! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;
        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE3255' /* Impressão Etiquetas Agrupadoras */),
            `${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE3255' /* Impressão Etiquetas Agrupadoras */),
            I18n.t('BEE1638' /* Ocorreu uma falha de comunicação com o servidor. */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE3255' /* Impressão Etiquetas Agrupadoras */),
          I18n.t('BEE1638' /* Ocorreu uma falha de comunicação com o servidor. */),
          'top-right',
        );
      }
    }
  };

  createSelect = (value, attr, label, items) => (
    <div className="form-group p-2">
      <label htmlFor={label}>{label}</label>
      <select
        className="form-control"
        value={value || ''}
        onChange={(e) => this.setValue(attr, e.target.value)}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );

  handleClearVariables = () => {
    this.setState({
      firstLoading: true,
      totalDataLength: 0,
      pages: 1,
      productionOrderCode: '',
      branchCode: '',
      depositCode: '',
      lotNumber: '',
      expectedDate: '',
      product: '',
      quantity: 0,
      serialGroups: [],
      productionOrderId: undefined,
      defaultPageSize: 10,
      showPrinterDialog: false,
      printerSelected: '',
    });
  };

  keypressConfirmSearch = (e) => {
    if (e.keyCode === 13) {
      this.listSerialGroupsToPrint();
    }
  };

  render() {
    const {
      pages,
      productionOrderCode,
      branchCode,
      depositCode,
      lotNumber,
      expectedDate,
      product,
      quantity,
      defaultPageSize,
      serialGroups,
      totalDataLength,
      showPrinterDialog,
      printerSelected,
      printerOptions,
    } = this.state;

    return (
      <>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE2771' /* Indústria */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE3255' /* Impressão Etiquetas Agrupadoras */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center">
          <h1 className="page-header mb-0">
            {I18n.t('BEE3255' /* Impressão Etiquetas Agrupadoras */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.INDUSTRY_PRINT_SERIAL_GROUP_HELP} />
        </div>
        {quantity === 0
          ? (
            <div onSubmit={(e) => e.preventDefault()}>
              <PanelPage
                noButton
                content={(
                  <div className="col-md-4">
                    <Form
                      noPanel
                      leftType
                      setValue={this.setValue}
                      inputs={(formContext) => ([
                        formContext.createInputButton(
                          productionOrderCode,
                          'productionOrderCode',
                          I18n.t('BEE2770' /* Ordem de Produção */),
                          '',
                          'text',
                          true,
                          this.props.userLogged ? !this.props.userLogged.mainBranchData.serialControlDeposit : false,
                          <i className="fa fa-search" />,
                          this.listSerialGroupsToPrint,
                          this.keypressConfirmSearch,
                          1,
                        ),
                      ])}
                    />
                  </div>
                )}
                footerContent={(
                  <button
                    type="button"
                    className="btn btn-120 btn-primary p-5 m-5"
                    onClick={this.listSerialGroupsToPrint}
                  >
                    {I18n.t('BEE407' /* Buscar */)}
                  </button>
                )}
              />
            </div>
          )
          : (
            <PanelPage
              noButton
              content={(
                <>
                  <div className="row">
                    <div className="col-md-3">
                      {this.createInput(
                        productionOrderCode,
                        'productionOrderCode',
                        I18n.t('BEE2770' /* Ordem de Produção */),
                        '',
                        'text',
                        false,
                        true,
                        undefined,
                        null,
                      )}
                    </div>
                    <div className="col-md-2">
                      {this.createInput(
                        branchCode,
                        'branchCode',
                        I18n.t('BEE1994' /* Código Filial */),
                        '',
                        'text',
                        false,
                        true,
                        undefined,
                        null,
                      )}
                    </div>
                    <div className="col-md-1">
                      {this.createInput(
                        depositCode,
                        'depositCode',
                        I18n.t('BEE182' /* Depósito */),
                        undefined,
                        'text',
                        false,
                        true,
                        undefined,
                        undefined,
                        undefined,
                        3,
                      )}
                    </div>
                    <div className="col-md-3">
                      {this.createInput(
                        lotNumber,
                        'lotNumber',
                        I18n.t('BEE428' /* Lote */),
                        undefined,
                        'text',
                        false,
                        true,
                        undefined,
                        undefined,
                        undefined,
                        4,
                      )}
                    </div>
                    <div className="col-md-3">
                      {this.createInput(
                        expectedDate,
                        'expectedDate',
                        I18n.t('BEE2819' /* Data Prevista */),
                        undefined,
                        'text',
                        false,
                        true,
                        undefined,
                        undefined,
                        undefined,
                        4,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-left">
                      {this.createTextArea(
                        product,
                        'product',
                        I18n.t('BEE225' /* Produto */),
                        undefined,
                        2,
                        false,
                        true,
                      )}
                    </div>
                  </div>
                </>
              )}
              footerContent={(
                <div className="d-flex row justify-content-between align-items-center p-10">
                  <button
                    type="button"
                    className="btn btn-120 btn-gray p-5 m-5"
                    onClick={this.handleClearVariables}
                  >
                    {I18n.t('BEE137' /* Voltar */)}
                  </button>
                </div>
              )}
            />
          )}
        {(showPrinterDialog
          && (
            <SweetAlert
              confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1324' /* Selecionar Impressora */)}
              onConfirm={() => this.printSerialGroup()}
              onCancel={() => this.setState({ showPrinterDialog: false })}
            >
              <div className="mt-4">
                {
                  this.createSelect(
                    printerSelected,
                    'printerSelected',
                    I18n.t('BEE328' /* Impressora */),
                    printerOptions,
                  )
                }
              </div>
            </SweetAlert>
          )
        )}
        {
          (serialGroups.length > 0 || this.filtered.length > 0)
          && (
            <Table
              expander
              filterable
              downloadCSV
              manual
              downButtonPosition
              data={serialGroups}
              columns={this.tableColumns}
              defaultSorted={this.defaultSorted}
              defaultPageSize={defaultPageSize}
              panelHeaderProps={{
                onClickReload: this.listSerialGroupsToPrint,
              }}
              totalDataLength={totalDataLength}
              pages={pages}
              breadcrumb={this.breadcrumb}
              headerTitle={I18n.t('BEE2764' /* Etiqueta Agrupadora */)}
              onFetchData={(state) => {
                this.page = state.page;
                this.pageSize = state.pageSize;
                this.sorted = state.sorted;
                this.filtered = state.filtered;
                this.listSerialGroupsToPrint();
              }}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          )
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  listSerialGroupsToPrint: (productionOrderCode, page, pageSize, sorted, filtered) => dispatch(
    listSerialGroupsToPrint(productionOrderCode, page, pageSize, sorted, filtered),
  ),
  printSerialGroup: (printerCode, serialGroupId) => dispatch(printSerialGroup(printerCode, serialGroupId)),
  reprintSerialGroup: (printerCode, serialGroupId) => dispatch(reprintSerialGroup(printerCode, serialGroupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrintSerialGroup));
