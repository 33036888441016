const INITIAL_STATE = {
  outboundList: [],
  outboundProductsList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_OUTBOUND_INDUSTRY_ORDER': {
      return { ...state, outboundList: action.outboundOrdersMonitorIndustryList };
    }
    case 'SET_OUTBOUND_INDUSTRY_ORDERS_PRODUCTS': {
      return { ...state, outboundProductsList: action.outboundOrderProducts };
    }
    default:
      return state;
  }
};
