import { updateBeetranslates } from '../../server/beetranslate';
import { addLoading, removeLoading } from './loading';

const updateBeetranslate = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    await updateBeetranslates();
  } finally {
    dispatch(removeLoading());
  }
};

export default updateBeetranslate;
