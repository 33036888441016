import { addLoading, removeLoading } from './loading';
import approvalLevelRequests from '../../server/approvalLevel';


export const getApprovalLevelList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await approvalLevelRequests.getApprovalLevelList();
  } finally {
    dispatch(removeLoading());
  }
};

export const getApprovalLevelDetail = (approvalLevelId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await approvalLevelRequests.getApprovalLevelDetail(approvalLevelId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getApprovalLevelUser = (approvalLevelId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await approvalLevelRequests.getApprovalLevelUser(approvalLevelId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteApprovalLevel = (approvalLevelId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await approvalLevelRequests.deleteApprovalLevel(approvalLevelId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteApprovalLevelUser = (approvalLevelUserId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await approvalLevelRequests.deleteApprovalLevelUser(approvalLevelUserId);
  } finally {
    dispatch(removeLoading());
  }
};

export const createApprovalLevel = (parametersForCreation) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await approvalLevelRequests.createApprovalLevel(parametersForCreation);
  } finally {
    dispatch(removeLoading());
  }
};

export const createApprovalLevelUser = (parametersForCreation) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await approvalLevelRequests.createApprovalLevelUser(parametersForCreation);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateApprovalLevel = (approvalLevelId, branchCode, inventoryType, note) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await approvalLevelRequests.updateApprovalLevel(approvalLevelId, branchCode, inventoryType, note);
  } finally {
    dispatch(removeLoading());
  }
};