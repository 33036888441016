import RestService from '../services/rest';

// GET
async function getEans(tableParams) {
  return RestService.postAuthenticated('productEans/list/all', tableParams);
}

async function getEan(productEanId) {
  return RestService.getAuthenticated(`productEans/detail?productEanId=${productEanId}`);
}

async function getValidateEan(ean) {
  return RestService.getAuthenticated('productEans/validate', { ean });
}

// POST
async function createEan(newProductEan) {
  return RestService.postAuthenticated('productEans/create', newProductEan);
}

async function deleteEan(productEanId) {
  return RestService.postAuthenticated('productEans/delete', { productEanId });
}

// PUT
async function updateEan(updProductEan) {
  return RestService.putAuthenticated('productEans/update', updProductEan);
}

export default {
  getEans,
  createEan,
  updateEan,
  getEan,
  deleteEan,
  getValidateEan,
};
