import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import _ from 'lodash';

import SweetAlert from 'react-bootstrap-sweetalert';
import Table from '../../../components/table/Table';

import ROUTES from '../../../config/routes';

import {
  getLabels, getLabel, getLabelActive, updateActiveLabel,
} from '../../../app/store/actions/labels';
import addNotification from '../../../components/notification';
import labelEditorHelpers from '../../../helpers/labelEditor';

class LabelEditorList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      labels: [],
      showModalActive: false,
      labelActive: {},
      label: {},
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showAction(row.row, 'detail')}>
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showAction(row.row, 'newVersion')}>
                  {I18n.t('BEE4043' /* Nova versão */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showAction(row.row, 'copy')}>
                  {I18n.t('BEE80' /* Copiar */)}
                </DropdownItem>
                {
                  row.row && !row.row.isMainLabel
                  && (
                    <DropdownItem onClick={() => this.showAction(row.row, 'edit')}>
                      {I18n.t('BEE57' /* Editar */)}
                    </DropdownItem>
                  )
                }
                <DropdownItem onClick={() => this.handleModalActiveLabel(row.row._original)}>
                  {
                    row.row.active
                      ? I18n.t('BEE2491' /* Desativar */)
                      : I18n.t('BEE914' /* Ativar */)
                  }
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 60,
      }, {
        Header: I18n.t('BEE3976' /* Versão */),
        accessor: 'version',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 60,
      }, {
        Header: I18n.t('BEE1897' /* Descrição */),
        accessor: 'description',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE3986' /* Gatilho */),
        accessor: 'trigger',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (labelEditorHelpers.optionsTrigger(row.value)),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option key="all" value="">{I18n.t('BEE793' /* Todos */)}</option>
            {
              (labelEditorHelpers.optionsTrigger() || []).map((trigger) => (
                <option key={trigger.value} value={trigger.value}>{trigger.label}</option>
              ))
            }
          </select>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === row.trigger) {
            return true;
          }
        },
      }, {
        Header: I18n.t('BEE4003' /* Etiqueta Padrão BeeStock */),
        accessor: 'isMainLabel',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option key="all" value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option key={1} value={1}>{I18n.t('BEE172' /* Sim */)}</option>
            <option key={0} value={0}>{I18n.t('BEE173' /* Não */)}</option>
          </select>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === 'all') {
            return true;
          }
          if (filter.value === '1') {
            return row.active;
          }
          return !row.active;
        },
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'active',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>
            <span>
              <i
                className="fa fa-circle f-s-7 fa-fw m-r-10 m-t-5"
                style={{
                  transition: 'all .3s ease',
                  color: row.value ? 'green' : 'red',
                }}
              />
              {row.value ? I18n.t('BEE499' /* Ativo */) : I18n.t('BEE2492' /* Desativado */)}
            </span>
          </span>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === 'all') {
            return true;
          }
          if (filter.value === '1') {
            return row.active;
          }
          return !row.active;
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option key="all" value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option key={1} value={1}>{I18n.t('BEE499' /* Ativo */)}</option>
            <option key={0} value={0}>{I18n.t('BEE2492' /* Desativado */)}</option>
          </select>
        ),
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
    }

    await this.getAllLabels();
  };

  getAllLabels = async () => {
    const labels = await this.props.getLabels() || [];

    await this.setState({
      labels, label: {}, showModalActive: false, labelActive: {},
    });
  };

  showAction = async (row, action) => {
    try {
      const actions = {
        edit: ROUTES.DEVELOP_LABEL_EDITOR_EDIT,
        detail: ROUTES.DEVELOP_LABEL_EDITOR_DETAIL,
        newVersion: ROUTES.DEVELOP_LABEL_EDITOR_NEW_VERSION,
        copy: ROUTES.DEVELOP_LABEL_EDITOR_COPY,
      };
      const label = await this.props.getLabel(row._original.id, action === 'newVersion');

      if (label) {
        this.props.history.push({
          pathname: actions[action],
          state: {
            label,
          },
          dice: {
            page: this.state.page,
          },
        });
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3945' /* Editor de Etiquetas */),
          I18n.t('BEE2981' /* Erro na consulta */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE3945' /* Editor de Etiquetas */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE3945' /* Editor de Etiquetas */),
            I18n.t('BEE2981' /* Erro na consulta */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3945' /* Editor de Etiquetas */),
          I18n.t('BEE2981' /* Erro na consulta */),
          'top-right',
        );
      }
      await addNotification(
        'danger',
        I18n.t('BEE3945' /* Editor de Etiquetas */),
        I18n.t('BEE2981' /* Erro na consulta */),
        'top-right',
      );
    }
  };

  activeLabel = async () => {
    const { label } = this.state;
    try {
      const labelActive = await this.props.updateActiveLabel({
        labelId: label.id,
        active: !label.active,
      });

      if (labelActive) {
        await addNotification(
          'success',
          I18n.t('BEE3987' /* Ativar ou Desativar Etiqueta */),
          !label.active
            ? I18n.t('BEE3990', { 0: label.code } /* Etiqueta %{0} ativada com sucesso */)
            : I18n.t('BEE3989', { 0: label.code } /* Etiqueta %{0} desativada com sucesso */),
          'top-right',
        );
        this.getAllLabels();
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3987' /* Ativar ou Desativar Etiqueta */),
          I18n.t('BEE3988', { 0: label.code } /* Erro ao ativar ou desativar a etiqueta %{0}! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE3987' /* Ativar ou Desativar Etiqueta */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE3987' /* Ativar ou Desativar Etiqueta */),
            I18n.t('BEE3988', { 0: label.code } /* Erro ao ativar ou desativar a etiqueta %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3987' /* Ativar ou Desativar Etiqueta */),
          I18n.t('BEE3988', { 0: label.code } /* Erro ao ativar ou desativar a etiqueta %{0}! */),
          'top-right',
        );
      }
    }
  };

  existLabelActiveTrigger = async (label) => {
    try {
      const labelActive = await this.props.getLabelActive(label.trigger);

      if (labelActive) {
        return labelActive;
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE3987' /* Ativar ou Desativar Etiqueta */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE3987' /* Ativar ou Desativar Etiqueta */),
            I18n.t('BEE3988', { 0: label.code } /* Erro ao ativar ou desativar a etiqueta %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3987' /* Ativar ou Desativar Etiqueta */),
          I18n.t('BEE3988', { 0: label.code } /* Erro ao ativar ou desativar a etiqueta %{0}! */),
          'top-right',
        );
      }
    }
  };

  handleModalActiveLabel = async (row) => {
    const { showModalActive } = this.state;

    if (showModalActive) {
      this.setState({ showModalActive: false, label: {}, labelActive: {} });
    } else {
      let labelActive = {};
      if (!row.active) {
        labelActive = await this.existLabelActiveTrigger(row) || {};
        labelActive.isMulti = labelEditorHelpers.optionsTrigger()
          .filter((item) => item.value === row.trigger && item.isMulti === true).length > 0;
      }

      this.setState({ showModalActive: true, label: row, labelActive });
    }
  };

  render() {
    const {
      labels = [], page, label = {}, showModalActive, labelActive,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE48' /* Desenvolvedor */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE3945' /* Editor de Etiquetas */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE3945' /* Editor de Etiquetas */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.DEVELOP_LABEL_EDITOR_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {I18n.t('BEE2882' /* Incluir */)}
              </Link>
            </div>
          )}
          wikiHelp={ROUTES.DEVELOP_LABEL_EDITOR_HELP}
          panelHeaderProps={{
            onClickReload: this.getAllLabels,
          }}
          filterable
          data={labels}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || (
            (!this.state.firstRun && this.props.location.state
              && this.props.location.state.dice && this.props.location.state.dice.page)
              ? this.props.location.state.dice.page : page)}
          onPageChange={(Page) => ((Page >= 0) ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalActive
          && (
            <SweetAlert
              warning={!labelActive.code || labelActive.isMulti}
              danger={!!labelActive.code && !labelActive.isMulti}
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={this.activeLabel}
              onCancel={this.handleModalActiveLabel}
            >
              { labelActive.code && !label.active && !labelActive.isMulti

                ? I18n.t('BEE4054', {
                  0: label.code, 1: label.version, 2: labelActive.code, 3: labelActive.version,
                })/* Para ativar o modelo %{0} da versão %{1},
                será necessário desativar o modelo %{2} da versão %{3}. */

                : I18n.t('BEE4045', {
                  0: label.code,
                  1: label.active
                    ? I18n.t('BEE2492' /* Desativado */)
                    : I18n.t('BEE4046' /* Ativado */),
                } /* A etiqueta %{0} será %{1}! */)}
            </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getLabels: () => dispatch(getLabels()),
  getLabel: (labelId, isNewVersion) => dispatch(getLabel(labelId, isNewVersion)),
  updateActiveLabel: (label) => dispatch(updateActiveLabel(label)),
  getLabelActive: (trigger) => dispatch(getLabelActive(trigger)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LabelEditorList));
