/* eslint-disable max-len */
import moment from 'moment-timezone';
import RestService from '../services/rest';

const getNotifications = async () => RestService.getAuthenticated('notifications/getNotifications', {}, {}, {});

const deleteNotification = async (notifId) => RestService.postAuthenticated('notifications/deleteNotification', { notifId });

const setNotificationAsRead = async (notifId) => RestService.postAuthenticated('notifications/setNotificationAsRead', { notifId });

const downloadFile = async (notifId, id, nameDoc = '', typeDoc = 'pdf') => {
  let mimeType = 'application/pdf';

  if (typeDoc === 'xlsx') {
    mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  } else if (typeDoc === 'xls') {
    mimeType = 'application/vnd.ms-excel';
  } else if (typeDoc === 'csv') {
    mimeType = 'text/csv';
  } else if (typeDoc === 'doc') {
    mimeType = 'application/msword';
  } else if (typeDoc === 'docx') {
    mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  } else if (typeDoc === 'jpg' || typeDoc === 'jpeg') {
    mimeType = 'image/jpeg';
  } else if (typeDoc === 'png') {
    mimeType = 'image/png';
  } else if (typeDoc === 'webp') {
    mimeType = 'image/webp';
  } else if (typeDoc === 'txt') {
    mimeType = 'text/plain';
  }

  const response = await RestService.postAuthenticated('notifications/downloadFile', {
    notifId, id,
  }, {
    'Content-Type': 'application/json',
    Accept: mimeType,
  }, { responseType: 'arraybuffer' });
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  const filePath = `${`${nameDoc.normalize('NFD').replace(/[\u0300-\u036f]/g, '')}_${moment().toISOString()}`.replace(/[\s+\-.]/g, '_')}.${typeDoc}`;
  link.setAttribute('download', filePath);
  document.body.appendChild(link);
  link.click();
};

export default {
  getNotifications,
  deleteNotification,
  setNotificationAsRead,
  downloadFile,
};
