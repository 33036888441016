import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import { logout } from '../../../app/store/actions/app';
import ChangePassword from '../../password/toChange';
import ShowAboutSystem from '../../about/aboutSystem';

class DropdownProfile extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      showChangePassword: false,
      showAboutSystem: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  onClickLogout = async () => {
    try {
      await this.props.logout();
      document.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { userLogged } = this.props;

    return (
      <>
        <Dropdown
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
          className="dropdown navbar-user cursorPointer"
          tag="li"
        >
          <DropdownToggle tag="a">
            <div className="image image-icon bg-black text-grey-darker">
              <i className="fa fa-user" />
            </div>
            <span className="d-none d-md-inline">
              {(userLogged && userLogged.login) || ''}
            </span>
            <b className="caret" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
            <DropdownItem onClick={() => this.setState({ showChangePassword: true })}>
              {I18n.t('BEE915' /* Alterar senha */)}
            </DropdownItem>
            <DropdownItem onClick={() => this.setState({ showAboutSystem: true })}>
              {I18n.t('BEE2617' /* Sobre */)}
            </DropdownItem>
            <DropdownItem onClick={this.onClickLogout}>{I18n.t('BEE11' /* Sair */)}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <ChangePassword
          isOpen={this.state.showChangePassword}
          expiredPassword={false}
          toClose={() => this.setState({ showChangePassword: false })}
        />
        <ShowAboutSystem
          isOpen={this.state.showAboutSystem}
          toClose={() => this.setState({ showAboutSystem: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged,
});

const mapActionsToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapActionsToProps)(DropdownProfile);
