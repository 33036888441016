import RestService from '../services/rest';

// GET
async function getDocks() {
  return RestService.getAuthenticated('docks/list/all');
}

async function getDock(dockId) {
  return RestService.getAuthenticated(`docks/detail?dockId=${dockId}`);
}

// POST
async function createDock(dock) {
  return RestService.postAuthenticated('docks/create', dock);
}

async function deleteDock(dockId) {
  return RestService.postAuthenticated('docks/delete', { dockId });
}

// PUT
async function updateDock(dock) {
  return RestService.putAuthenticated('docks/update', dock);
}

export default {
  getDocks,
  createDock,
  updateDock,
  getDock,
  deleteDock
};
