import ProductsRequests from '../../server/products';
import { addLoading, removeLoading } from './loading';

export const setProducts = (products) => ({
  type: 'SET_PRODUCTS',
  products,
});

export const getProductsList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const products = await ProductsRequests.getProducts(tableParams);
    dispatch(setProducts(products ? products.rows : []));
    dispatch(removeLoading());
    return products || 0;
  } catch (e) {}

  dispatch(removeLoading());
  return 0;
};

export const createProduct = (product) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newProduct = await ProductsRequests.createProduct(product);
    return newProduct;
  } finally {
    dispatch(removeLoading());
  }
};

export const getProductByCode = (productCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ProductsRequests.getProductByCode(productCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateProduct = (product) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ProductsRequests.updateProduct(product);
  } finally {
    dispatch(removeLoading());
  }
};

export const getProduct = (productId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ProductsRequests.getProduct(productId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteProduct = (productId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ProductsRequests.deleteProduct(productId);
  } finally {
    dispatch(removeLoading());
  }
};
