import { I18n } from 'react-redux-i18n';


function dockControlStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE2077' /* Aberto */);
      case 2:
        return I18n.t('BEE2078' /* Fechado */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE2077' /* Aberto */) },
      { value: 2, label: I18n.t('BEE2078' /* Fechado */) },
    ]
  }
}

export default {
  dockControlStatus,
};