import RestService from '../services/rest';

// GET
async function getRules() {
  return RestService.getAuthenticated('resupplyRules/list/allRules');
}

// POST
async function createRulesResupply(rules) {
  return RestService.postAuthenticated('resupplyRules/create', { rules });
}

// PUT
async function updateRule(rules) {
  return RestService.putAuthenticated('resupplyRules/update', { rules });
}

async function deleteRule(ruleId) {
  return RestService.postAuthenticated('resupplyRules/delete', { ruleId });
}

export default {
  getRules,
  createRulesResupply,
  updateRule,
  deleteRule,
};
