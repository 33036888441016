import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import Table from '../../../components/table/Table';
import dockControlHelpers from '../../../helpers/dockControl';

class TableDockControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDockControl: {},
      defaultPageSize: 5,
      pageSizeOptions: [5, 10, 15, 20, 25],
      defaultSorted: [{ id: 'id', desc: false }],
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE199' /* Doca */),
        accessor: 'dockCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: 'Controle',
        accessor: 'dockControlId',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{dockControlHelpers.dockControlStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {dockControlHelpers.dockControlStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE2074' /* Usuário de Abertura */),
        accessor: 'openingUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
      }, {
        Header: I18n.t('BEE2075' /* Usuário de Fechamento */),
        accessor: 'closingUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
      }, {
        Header: I18n.t('BEE578' /* Data de Criação */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('L')}
          </span>
        ),

      }];
  }

  async componentDidMount() {
    await this.props.listDockControl();
  }

  selectLine = (selectedDockControl) => {
    this.setState({ selectedDockControl });
    this.props.outboundOrderDetails(selectedDockControl.dockControlId);
  };

  handleDateApplyEvent = async (event) => {
    await this.props.setState({
      startDate: event[0],
      endDate: event[1],
    });
    await this.props.listDockControl();
  };

  render() {
    const {
      defaultPageSize, defaultSorted, pageSizeOptions, selectedDockControl,
    } = this.state;

    const {
      dockControlList = [],
    } = this.props;

    return (
      <Table
        panelHeaderProps={{
          noButton: false,
          children: I18n.t('BEE2073' /* controles */).toLocaleUpperCase(),
          onClickReload: this.props.listDockControl,
        }}
        datePicker={{
          handleDateApplyEvent: this.handleDateApplyEvent,
        }}
        filterable
        data={dockControlList}
        columns={this.tableColumns}
        expander
        defaultPageSize={defaultPageSize}
        defaultSorted={defaultSorted}
        pageSizeOptions={pageSizeOptions}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: () => (this.selectLine(rowInfo.original)),
              style: {
                background: selectedDockControl && rowInfo.original.dockControlId === selectedDockControl.dockControlId
                  ? 'silver'
                  : 'white',
                cursor: 'pointer',
              },
            };
          } return {};
        }}
        defaultFilterMethod={(filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row[filter.id]);
          if (_.includes(value, input)) {
            return true;
          }
        }}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableDockControl));
