import Axios from 'axios';

async function getAddressByCep(cep) {
  const urlViaCep = 'https://viacep.com.br';

  try {
    const url = `${urlViaCep}/ws/${cep}/json`;

    const response = await Axios.get(url);
    if (response && response.data) return response.data;
    return response;
  } catch (err) {
    const workplaceErr = err.response && err.response.data && err.response.data;
    console.log(workplaceErr || err);
  }
}

export default getAddressByCep;
