import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';
import { getAllCurves } from '../../../../app/store/actions/curves';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import {
  blockStorageAddress,
  deleteStorageAddress,
  getStorageAddressesList,
  bulkImportStorageAddressSequence,
} from '../../../../app/store/actions/storageAddresses';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';

import ImportCaption from '../../../../components/bulkImport/ImportCaption';
import DragAndDrop from '../../../../components/bulkImport/DragAndDrop';
import OneClickButton from '../../../../components/form/button';
import { setJsonContent } from '../../../../app/store/actions/bulkImport';

class StorageAddressList extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      showModalDelete: false,
      addressUpdate: null,
      pages: 0,
      showBlockModel: false,
      addressSelected: null,
      note: '',
      curves: [],
      showModalSequence: false,
      totalDataLength: 0,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showAddressDetail(rows.row)}>
                  {I18n.t('BEE56' /* Detalhes */)}

                </DropdownItem>
                <DropdownItem onClick={() => this.showEditAddress(rows.row)}>
                  {I18n.t('BEE57' /* Editar */)}

                </DropdownItem>
                <DropdownItem onClick={() => this.showCopyAddress(rows.row)}>
                  {I18n.t('BEE80' /* Copiar */)}

                </DropdownItem>
                <DropdownItem onClick={() => this.showDeleteAddress(rows.row)}>
                  {I18n.t('BEE81' /* Eliminar */)}

                </DropdownItem>
                {rows.row._original.blocked
                  ? (
                    <DropdownItem onClick={() => this.showBlockModel(rows.row)}>
                      {I18n.t('BEE720' /* Desbloquear */)}
                    </DropdownItem>
                  )
                  : (
                    <DropdownItem onClick={() => this.showBlockModel(rows.row)}>
                      {I18n.t('BEE719' /* Bloquear */)}
                    </DropdownItem>
                  )}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE581' /* Range */),
        accessor: 'rangeCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE279' /* Setor */),
        accessor: 'sector',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE121' /* Rua */),
        accessor: 'street',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE281' /* Coluna */),
        accessor: 'column',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE283' /* Nível */),
        accessor: 'level',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE285' /* Gaveta */),
        accessor: 'drawer',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE207' /* Bloqueado */),
        accessor: 'blocked',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE385' /* Tamanho */),
        accessor: 'size',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE386' /* Altura */),
        accessor: 'height',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE387' /* Largura */),
        accessor: 'width',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE388' /* Comprimento */),
        accessor: 'length',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t(I18n.t('BEE355' /* Curva */)),
        accessor: 'curve',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumnSelect,
      }, {
        Header: I18n.t('BEE727' /* Capacidade (Volume) */),
        accessor: 'capacityVolume',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE726' /* Capacidade (Peso) */),
        accessor: 'capacityWeight',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE3577' /* Sequência de Entrada */),
        accessor: 'inboundSequence',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE3576' /* Sequência de Saída */),
        accessor: 'outboundSequence',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      },
    ];

    this.csvPreviewTable = [
      {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'type',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        sortable: false,
        Cell: (row) => (
          <span>
            {this.renderStatusType(row.original)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        sortable: false,
      }, {
        Header: I18n.t('BEE1755' /* Código Endereço */),
        accessor: 'storageAddressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortable: false,
      }, {
        Header: I18n.t('BEE494' /* Sequência */),
        accessor: 'sequence',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        sortable: false,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'error.error',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortable: false,
        Cell: (row) => (
          <span>
            {this.renderStatusValue(row.original)}
          </span>
        ),
      },
      {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'error.message',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        sortable: false,
      },
    ];

    this.renderStatusValue = (row) => {
      if (row?.error?.error === '') {
        return (
          <span style={{ color: 'green', fontWeight: '700' }}>
            {I18n.t('BEE2523' /* Processado com sucesso */)}
          </span>
        );
      }
      if (row?.error?.error) {
        return <span style={{ color: 'red', fontWeight: '700' }}>{I18n.t('BEE2520' /* Processado com erro */)}</span>;
      }
      if (!row.error) {
        return (
          <span style={{ color: '#F17B1E', fontWeight: '700' }}>
            {I18n.t('BEE2524' /* Aguardando processamento */)}
          </span>
        );
      }
    };

    this.renderStatusType = (row) => {
      const { type } = row;
      let typeDescription = '';

      if (type === 'A') {
        typeDescription = I18n.t('BEE203' /* Ambos */);
      } else if (type === 'E') {
        typeDescription = I18n.t('BEE201' /* Entrada */);
      } else if (type === 'S') {
        typeDescription = I18n.t('BEE202' /* Saída */);
      } else {
        typeDescription = I18n.t('BEE3430' /* Código inválido */);
      }

      return (
        <span>
          {`${type} - ${typeDescription}`}
        </span>
      );
    };
  }

  async componentDidMount() {
    this.maintainsState();
    await this.getCurves();
  }

  getCurves = async () => {
    await this.props.getAllCurves();
    const curves = [];
    this.props.curves.map((element) => (curves.push({
      value: element.code,
      label: element.code,
    })));
    await this.setState({
      curves: [...curves],
    });
  };

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 mt-4">
      <label htmlFor={label}>{label}</label>
      <div className="col">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      showBlockModel: false,
      addressUpdate: null,
      addressSelected: null,
    });
  };

  filterColumnSelect = ({ onChange }) => {
    const { curves } = this.state;
    return (
      <select
        type="text"
        style={{ width: '100%' }}
        onChange={(event) => onChange(event.target.value)}
      >
        <option value="">{I18n.t('BEE793' /* Todos */)}</option>
        {curves.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    );
  };

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getAddresses = async () => {
    const data = await this.props.getStorageAddressesList({
      page: this.page,
      pageSize: this.pageSize,
      sorted: this.sorted,
      filtered: this.filtered,
    });

    this.setState({ pages: data.pages, totalDataLength: data.totalDataLength });
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deleteAddress = async (address) => {
    this.setInitState();
    try {
      const result = await this.props.deleteAddress(address._original.id);
      if (result) {
        addNotification(
          'success',
          I18n.t('BEE287' /* Eliminar Endereço */),
          I18n.t('BEE288', { 0: address.code } /* Endereço %{0} eliminado com sucesso! */),
          'top-right',
        );
      }
      await this.getAddresses();
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification(
          'danger',
          I18n.t('BEE287' /* Eliminar Endereço */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE287' /* Eliminar Endereço */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  showAddressDetail = (address) => {
    this.props.history.push({
      pathname: ROUTES.ADDRESS_DETAIL,
      state: {
        address: address._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditAddress = (address) => {
    this.props.history.push({
      pathname: ROUTES.ADDRESS_EDIT,
      state: {
        address: address._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyAddress = (address) => {
    this.props.history.push({
      pathname: ROUTES.ADDRESS_COPY,
      state: {
        address: address._original,
      },
    });
  };

  showDeleteAddress = (address) => {
    this.setState({ showModalDelete: true, addressUpdate: address });
  };

  showBlockModel = (address) => {
    this.setState({
      showBlockModel: true,
      addressSelected: address._original,
    });
  };

  hideBlockModal = () => {
    this.setState({
      showBlockModel: false,
      addressSelected: null,
    });
  };

  cancelBulkImport = () => {
    this.setState({ showModalSequence: false });
    this.props.setJsonContent(null);
  };

  validProcessFile = async () => {
    if (this.props.jsonCsv === null) {
      addNotification(
        'danger',
        I18n.t('BEE2509' /* Importação em massa */),
        I18n.t('BEE2511' /* Nenhum arquivo selecionado */),
        'top-right',
      );
    } else await this.processFile();
  };

  processFile = async () => {
    const { jsonCsv } = this.props;
    const addressesSequenceErrors = await this.props.generateBulkImport(jsonCsv);
    await this.props.setJsonContent(addressesSequenceErrors);
  };

  blockAddress = async () => {
    const { id, code, blocked } = this.state.addressSelected;
    const { note } = this.state;

    try {
      if (note.length < 3) {
        if (blocked) {
          addNotification(
            'danger',
            I18n.t('BEE721' /* Bloquear Endereço */),
            I18n.t('BEE725' /* Informe o motivo do desbloqueio: */),
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE721' /* Bloquear Endereço */),
            I18n.t('BEE723' /* Informe o motivo do bloqueio: */),
            'top-right',
          );
        }
        return;
      }

      const result = await this.props.blockAddress(id, !blocked, note);

      this.setInitState();

      if (result && result.length > 0) {
        if (!blocked) {
          addNotification(
            'success',
            I18n.t('BEE721' /* Bloquear Endereço */),
            I18n.t('BEE722', { 0: code } /* Endereço %{0} bloqueado com sucesso! */),
            'top-right',
          );
        } else {
          addNotification(
            'success',
            I18n.t('BEE3339' /* Desbloquear Endereço */),
            I18n.t('BEE3340', { 0: code } /* Endereço %{0} desbloqueado com sucesso! */),
            'top-right',
          );
        }
      }
      this.setState({
        note: '',
      });
      await this.getAddresses();
    } catch (err) {
      const { error } = err.response.data;
      if (error.details || error.message) {
        addNotification(
          'danger',
          I18n.t('BEE721' /* Bloquear Endereço */),
          `${error.code} - ${error.details || error.message}`,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE721' /* Bloquear Endereço */),
          I18n.t('BEE272', { 0: code } /* Erro ao atualizar o Endereço %{0}! */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      addressUpdate, showModalDelete, pages, showBlockModel, note, page, totalDataLength,
      showModalSequence,
    } = this.state;
    const { addressesList = [], jsonCsv = [] } = this.props;

    const blocked = this.state.addressSelected ? this.state.addressSelected.blocked : false;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE20' /* Endereços */)}</li>
          </ol>
        </div>
        {showModalSequence ? (
          <div>
            <h1 className="page-header mb-0">
              {I18n.t('BEE3578' /* Sequenciamento */)}
            </h1>
            <div className="slideUpTransition">
              <div className="d-flex align-items-center mb-md-3 mb-2" />
              <div className="row">
                <div className="col-xl-12">
                  <Panel>
                    <PanelHeader noButton />
                    <PanelBody>
                      <div className="row">
                        <div
                          style={{
                            justifyContent: 'space-evenly',
                            display: 'flex',
                            flex: 1,
                          }}
                        >
                          <ImportCaption
                            headers={[
                              { label: 'type', key: 'type' },
                              { label: 'branchCode', key: 'branchCode' },
                              { label: 'storageAddressCode', key: 'storageAddressCode' },
                              { label: 'sequence', key: 'sequence' },
                            ]}
                            data={[
                              {
                                type: 'A',
                                branchCode: '04012',
                                storageAddressCode: 'S08R10M01A01G08',
                                sequence: 1,
                              },
                              {
                                type: 'S',
                                branchCode: '04012',
                                storageAddressCode: 'S04R04M01A01G02',
                                sequence: 1,
                              },
                              {
                                type: 'E',
                                branchCode: '04012',
                                storageAddressCode: 'S04R04M01A01G03',
                                sequence: 2,
                              },
                              {
                                type: 'A',
                                branchCode: '04012',
                                storageAddressCode: 'S04R04M01A01G04',
                                sequence: 2,
                              },
                            ]}
                            filename="SequenceStorageAddress.csv"
                            legend={[
                              {
                                nameField: 'type',
                                observationField: I18n.t('BEE3579')
                                /* Tipo de sequenciamento que deve ser atualizado,
                                podendo ser E - Entrada, S - Saída ou A - Ambos. */,
                              }, {
                                nameField: 'branchCode',
                                observationField: I18n.t('BEE1994' /* Código Filial */),
                              }, {
                                nameField: 'storageAddressCode',
                                observationField: I18n.t('BEE1755' /* Código Endereço */),
                              }, {
                                nameField: 'sequence',
                                observationField: I18n.t('BEE494' /* Sequência */),
                              },
                            ]}
                          />
                          <DragAndDrop />
                        </div>
                      </div>
                    </PanelBody>
                    <PanelFooter className="text-right">
                      <OneClickButton
                        type="submit"
                        className="btn btn-white m-5"
                        style={{ paddingRight: '25px', paddingLeft: '25px' }}
                        onClick={this.cancelBulkImport}
                      >
                        {I18n.t('BEE99' /* Cancelar */)}
                      </OneClickButton>
                      <OneClickButton
                        type="submit"
                        style={{ paddingRight: '25px', paddingLeft: '25px' }}
                        className="btn btn-primary m-5"
                        onClick={() => this.validProcessFile()}
                      >
                        {I18n.t('BEE2496' /* Processar Arquivo */)}
                      </OneClickButton>
                    </PanelFooter>
                  </Panel>
                </div>
              </div>
            </div>
            <div>
              <Table
                downloadCSV
                filterable
                data={jsonCsv}
                columns={this.csvPreviewTable}
                expander
                defaultPageSize={10}
                defaultSorted={this.defaultSorted}
                page={(page) || ((!this.state.firstRun
                  && this.props.location.state && this.props.location.state.dice
                  && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
                onPageChange={(Page) => ((Page >= 0) ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
                defaultFilterMethod={(filter, row) => {
                  const input = _.lowerCase(filter.value);
                  const value = _.lowerCase(row[filter.id]);
                  if (_.includes(value, input)) {
                    return true;
                  }
                }}
              />
            </div>
          </div>
        )
          : (
            <Table
              downloadCSV
              wikiHelp={ROUTES.ADDRESS_HELP}
              headerTitle={I18n.t('BEE20' /* Endereços */)}
              actionButtons={(
                <div className="ml-auto">
                  <button
                    type="button"
                    onClick={() => this.setState({ showModalSequence: true })}
                    className="btn btn-success btn-sm btn-rounded pl-2 pr-3 mr-3"
                  >
                    <i className="fa fa-sort mr-1" />
                    {I18n.t('BEE3578' /* Sequenciamento */)}
                  </button>
                  <Link to={ROUTES.ADDRESS_BULK_BLOCK} className="btn btn-success btn-sm btn-rounded pl-2 pr-3 mr-3">
                    <i className="fa fa-lock mr-1" />
                    {I18n.t('BEE728' /* Bloqueio Múltiplo */)}
                  </Link>
                  <Link to={ROUTES.GENERATE_ADDRESSES} className="btn btn-success btn-sm btn-rounded pl-2 pr-3 mr-3">
                    <i className="fa fa-plus mr-1" />
                    {I18n.t('BEE597' /* Gerar Endereços */)}
                  </Link>
                  <Link to={ROUTES.ADDRESS_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                    <i className="fa fa-plus mr-1" />
                    {I18n.t('BEE290' /* Incluir Endereço */)}
                  </Link>
                </div>
              )}
              panelHeaderProps={{
                onClickReload: this.getAddresses,
              }}
              filterable
              data={addressesList}
              totalDataLength={totalDataLength}
              columns={this.tableColumns}
              expander
              defaultPageSize={10}
              defaultSorted={this.defaultSorted}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
              manual
              pages={pages}
              page={(page) || ((!this.state.firstRun && this.props.location.state
                && this.props.location.state.dice && this.props.location.state.dice.page)
                ? this.props.location.state.dice.page
                : page)}
              onPageChange={(Page) => ((Page >= 0) ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
              onFetchData={(state) => {
                this.page = (state.page && state.page)
                  || ((!this.state.firstRun && this.props.location.state
                    && this.props.location.state.dice && this.props.location.state.dice.page)
                    ? this.props.location.state.dice.page
                    : state.page);
                this.pageSize = state.pageSize;
                this.sorted = state.sorted;
                this.filtered = state.filtered;
                this.getAddresses();
              }}
            />
          )}

        {(showModalDelete
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.deleteAddress(addressUpdate)}
              onCancel={() => this.setInitState()}
            >
              {I18n.t('BEE289', { 0: addressUpdate.code } /* O Endereço %{0} será eliminado! */)}
            </SweetAlert>
          )
        )}

        {(showBlockModel && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99')}
            confirmBtnText={I18n.t('BEE100')}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101')}
            onConfirm={() => this.blockAddress()}
            onCancel={() => this.hideBlockModal()}
          >
            <div>
              <span>
                {blocked
                  ? I18n.t('BEE725' /* Informe o motivo do desbloqueio: */)
                  : I18n.t('BEE723' /* Informe o motivo do bloqueio: */)}
              </span>
              {this.createTextArea(
                note,
                'note',
                I18n.t('BEE724' /* Motivo */),
                blocked
                  ? I18n.t('BEE725' /* Informe o motivo do desbloqueio: */)
                  : I18n.t('BEE723' /* Informe o motivo do bloqueio: */),
                3,
                true,
                false,
              )}
            </div>
          </SweetAlert>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  addressesList: state.storageAddresses && state.storageAddresses.storageAddressesList,
  curves: state.curves?.curvesList,
  jsonCsv: state.bulkImport.jsonCsv || [],
  fileCsv: state.bulkImport.fileCsv,
});

const mapDispatchToProps = (dispatch) => ({
  getStorageAddressesList: (tableParams) => dispatch(getStorageAddressesList(tableParams)),
  deleteAddress: (addressId) => dispatch(deleteStorageAddress(addressId)),
  blockAddress: (addressId, blocked, note) => dispatch(blockStorageAddress(addressId, blocked, note)),
  getAllCurves: () => dispatch(getAllCurves()),
  setJsonContent: (jsonCsv) => dispatch(setJsonContent(jsonCsv)),
  generateBulkImport: (jsonCsv) => dispatch(bulkImportStorageAddressSequence(jsonCsv)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StorageAddressList));
