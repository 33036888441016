import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import OneClickButton from '../../../../components/form/button';
import Form from '../../../../components/form/form';

import StorageAddressTable from './storageAddressTable';

export default class AddressLinkModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fromStreet: '',
      toStreet: 'Z'.repeat(15),
      fromSector: '',
      toSector: 'Z'.repeat(15),
      fromLevel: '',
      toLevel: 'Z'.repeat(15),
      fromDrawer: '',
      toDrawer: 'Z'.repeat(15),
      fromColumn: '',
      toColumn: 'Z'.repeat(15),
    };
  }

  async componentDidMount() {
    const columnSideList = [
      { label: I18n.t('BEE203' /* Ambos */), value: 'both' },
      { label: I18n.t('BEE3398' /* Par */), value: 'even' },
      { label: I18n.t('BEE3399' /* Ímpar */), value: 'odd' },
    ];

    this.setState({
      columnSideList,
      columnSide: 'both',
      columnSideLabel: I18n.t('BEE203' /* Ambos */),
    });
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  resetFilters = () => {
    this.setState({
      fromStreet: '',
      toStreet: 'Z'.repeat(15),
      fromSector: '',
      toSector: 'Z'.repeat(15),
      fromLevel: '',
      toLevel: 'Z'.repeat(15),
      fromDrawer: '',
      toDrawer: 'Z'.repeat(15),
      fromColumn: '',
      toColumn: 'Z'.repeat(15),
    });
  };

  previewAddressLink = async () => {
    const {
      fromSector, fromStreet, fromColumn, fromLevel, fromDrawer, columnSide,
      toSector, toStreet, toColumn, toLevel, toDrawer,
    } = this.state;

    await this.props.previewAddressLink({
      columnSide,
      fromSector: fromSector.toLocaleUpperCase(),
      toSector: toSector.toLocaleUpperCase(),
      fromStreet: fromStreet.toLocaleUpperCase(),
      toStreet: toStreet.toLocaleUpperCase(),
      fromColumn: fromColumn.toLocaleUpperCase(),
      toColumn: toColumn.toLocaleUpperCase(),
      fromLevel: fromLevel.toLocaleUpperCase(),
      toLevel: toLevel.toLocaleUpperCase(),
      fromDrawer: fromDrawer.toLocaleUpperCase(),
      toDrawer: toDrawer.toLocaleUpperCase(),
    });
  };

  persistAddressLink = async () => {
    const {
      fromSector, fromStreet, fromColumn, fromLevel, fromDrawer, columnSide,
      toSector, toStreet, toColumn, toLevel, toDrawer,
    } = this.state;

    await this.props.persistAddressLink({
      columnSide,
      fromSector: fromSector.toLocaleUpperCase(),
      toSector: toSector.toLocaleUpperCase(),
      fromStreet: fromStreet.toLocaleUpperCase(),
      toStreet: toStreet.toLocaleUpperCase(),
      fromColumn: fromColumn.toLocaleUpperCase(),
      toColumn: toColumn.toLocaleUpperCase(),
      fromLevel: fromLevel.toLocaleUpperCase(),
      toLevel: toLevel.toLocaleUpperCase(),
      fromDrawer: fromDrawer.toLocaleUpperCase(),
      toDrawer: toDrawer.toLocaleUpperCase(),
    });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });

    if (attr === 'columnSide') {
      const { columnSide } = this.state;

      let columnSideLabel = '';

      const columnSideList = [
        { label: I18n.t('BEE203' /* Ambos */), value: 'both' },
        { label: I18n.t('BEE3398' /* Par */), value: 'even' },
        { label: I18n.t('BEE3399' /* Ímpar */), value: 'odd' },
      ];

      if (columnSide === 'both') {
        columnSideLabel = I18n.t('BEE203' /* Ambos */);
      } else if (columnSide === 'even') {
        columnSideLabel = I18n.t('BEE3398' /* Par */);
      } else if (columnSide === 'odd') {
        columnSideLabel = I18n.t('BEE3399' /* Ímpar */);
      }

      await this.setState({
        columnSideList,
        columnSide,
        columnSideLabel,
      });
    }
  };

  render() {
    const {
      fromSector, fromStreet, fromColumn, fromLevel, fromDrawer, columnSide, columnSideLabel, columnSideList,
      toSector, toStreet, toColumn, toLevel, toDrawer,
    } = this.state;

    const { showAddressLinkModal, previewAddressList, isUnlink } = this.props;

    const title = isUnlink
      ? I18n.t('BEE607' /* Desnvincular Endereços */)
      : I18n.t('BEE595' /* Gerar vínculo com endereços */);

    return (
      <Modal size="lg" isOpen={showAddressLinkModal} toggle={() => this.props.closeAddressLinkModal()}>
        <ModalHeader toggle={() => this.props.closeAddressLinkModal()}>{title}</ModalHeader>
        {!previewAddressList && (
        <ModalBody>
          <div className="d-flex align-items-center mb-1 mt-1">
            <p>
              {I18n.t('BEE598' /* Critérios de vinculação */)}
            </p>
          </div>
          <div style={{ marginTop: 30 }}>
            <Form
              noPanel
              leftType
              setValue={this.setValue}
              setValueDrop={this.setValueDrop}
              inputs={(formContext) => ([
                formContext.createInputRange(
                  { from: fromSector.toLocaleUpperCase(), to: toSector.toLocaleUpperCase() },
                  { from: 'fromSector', to: 'toSector' },
                  `${I18n.t('BEE279' /* Setor */)}:`,
                  { from: '', to: 'Z'.repeat(15) },
                ),
                formContext.createInputRange(
                  { from: fromStreet.toLocaleUpperCase(), to: toStreet.toLocaleUpperCase() },
                  { from: 'fromStreet', to: 'toStreet' },
                  `${I18n.t('BEE121' /* Rua */)}:`,
                  { from: '', to: 'Z'.repeat(15) },
                ),
                formContext.createInputRange(
                  { from: fromColumn.toLocaleUpperCase(), to: toColumn.toLocaleUpperCase() },
                  { from: 'fromColumn', to: 'toColumn' },
                  `${I18n.t('BEE281' /* Coluna */)}:`,
                  { from: '', to: 'Z'.repeat(15) },
                ),
                formContext.createSelectDropDown(
                  { value: columnSide, label: columnSideLabel },
                  'columnSide',
                  `${I18n.t('BEE3397' /* Gerar Lados (Coluna) */)}:`,
                  columnSideList,
                  false,
                ),
                formContext.createInputRange(
                  { from: fromLevel.toLocaleUpperCase(), to: toLevel.toLocaleUpperCase() },
                  { from: 'fromLevel', to: 'toLevel' },
                  `${I18n.t('BEE283' /* Nível */)}:`,
                  { from: '', to: 'Z'.repeat(15) },
                ),
                formContext.createInputRange(
                  { from: fromDrawer.toLocaleUpperCase(), to: toDrawer.toLocaleUpperCase() },
                  { from: 'fromDrawer', to: 'toDrawer' },
                  `${I18n.t('BEE285' /* Gaveta */)}:`,
                  { from: '', to: 'Z'.repeat(15) },
                ),
              ])}
            />
          </div>
        </ModalBody>
        )}
        {previewAddressList && (
        <ModalBody>
          <div className="d-flex align-items-center mb-1 mt-1">
            <StorageAddressTable
              title={I18n.t('BEE20' /* Endereços */)}
              storageAddresses={this.props.previewAddressList}
              lines={5}
            />
          </div>
        </ModalBody>
        )}
        <ModalFooter>
          <div className="row">
            <div className="col text-right">
              {!previewAddressList && (
              <OneClickButton
                className="btn btn-white btn-120 p-5 m-5"
                onClick={() => this.props.closeAddressLinkModal()}
              >
                {I18n.t('BEE99' /* Cancelar */)}
              </OneClickButton>
              )}
              {previewAddressList && (
              <OneClickButton
                className="btn btn-white btn-120 p-5 m-5"
                onClick={() => this.props.clearPreviewList()}
              >
                {I18n.t('BEE137' /* Voltar */)}
              </OneClickButton>
              )}
              {!previewAddressList && (
              <OneClickButton
                className="btn btn-white btn-120 p-5 m-5"
                onClick={() => this.resetFilters()}
              >
                {I18n.t('BEE605' /* Limpar Filtros */)}
              </OneClickButton>
              )}
              {!previewAddressList && (
              <OneClickButton
                className="btn btn-primary btn-120 p-5 m-5"
                onClick={() => this.previewAddressLink()}
              >
                {I18n.t('BEE600' /* Simular Vínculos */)}
              </OneClickButton>
              )}
              {previewAddressList && (
              <OneClickButton
                className={isUnlink ? 'btn btn-danger btn-120 p-5 m-5' : 'btn btn-primary btn-120 p-5 m-5'}
                onClick={() => this.persistAddressLink()}
              >
                {isUnlink ? I18n.t('BEE608' /* Desvincular */) : I18n.t('BEE601' /* Vincular */)}
              </OneClickButton>
              )}
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
