import OutboundOrderIndustryRequests from '../../server/outboundOrdersIndustry';
import { addLoading, removeLoading } from './loading';

export const setOutboundOrdersJustDocument = (outboundOrdersMonitorIndustryList) => ({
  type: 'SET_OUTBOUND_INDUSTRY_ORDER',
  outboundOrdersMonitorIndustryList,
});

export const setOutboundOrdersSerials = (outboundOrderProducts) => ({
  type: 'SET_OUTBOUND_INDUSTRY_ORDERS_PRODUCTS',
  outboundOrderProducts,
});

export const updateOutboundOrderAndFinally = (outboundOrderId, orderType) => async (dispactch) => {
  dispactch(addLoading());
  try {
    const result = await OutboundOrderIndustryRequests.updateOutboundOrderAndFinally(
      outboundOrderId,
      orderType,
    );
    return result;
  } finally {
    dispactch(removeLoading());
  }
};

export const updateOutboundOrder = (outboundOrderId, updateDataOutboundOrder) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderIndustryRequests.updateOutboundOrder(outboundOrderId, updateDataOutboundOrder);
  } finally {
    dispatch(removeLoading());
  }
};

export const cancelOutboundOrder = (outboundOrderId) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const result = await OutboundOrderIndustryRequests.cancelOutboundOrder(outboundOrderId);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const cancelOutboundProduct = (
  outboundOrderId,
  outboundOrderProductId,
  outboundProductSerialGroup,
) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const result = await OutboundOrderIndustryRequests.cancelOutboundProduct(
      outboundOrderId,
      outboundOrderProductId,
      outboundProductSerialGroup,
    );
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrderListJustDocument = (startDate, endDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrders = await OutboundOrderIndustryRequests.getOutboundOrderListJustDocument(startDate, endDate);
    dispatch(setOutboundOrdersJustDocument(outboundOrders));
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrderSerials = (outboundOrderId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrders = await OutboundOrderIndustryRequests.getOutboundOrderSerials(outboundOrderId);
    dispatch(setOutboundOrdersSerials(outboundOrders));
  } finally {
    dispatch(removeLoading());
  }
};
