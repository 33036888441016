import React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import OneClickButton from '../../../components/form/button';
import Form from '../../../components/form/form';
import addNotification from '../../../components/notification';
import { getStorageAddressesOptions } from '../../../app/store/actions/storageAddresses';
import { inventorySheetCreation } from '../../../app/store/actions/inventory';

class InclusionModal extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      listAddress: [],
      addressName: '',
      addressId: null,
      addressCode: null,
      productCode: null,
      sheetType: '1',
      inventoryId: null,
      inventoryName: '',
    };
    this.initialState = this.state;
  }

  componentDidUpdate(prevProps) {
    if (this.props.showInclusionModal && this.props.showInclusionModal !== prevProps.showInclusionModal) {
      this.storageAddressesOptions();
    }
  }

  setValue = (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = (attr, value) => {
    this.setState({
      [`${attr}Id`]: value ? value.value : null,
      [`${attr}Code`]: value ? value.code : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  closeInclusionModal = () => {
    this.setState({ ...this.initialState });
    this.props.closeInclusionModal();
  };

  inventorySheetCreation = async () => {
    const {
      sheetType, addressCode, productCode = '', inventoryId,
    } = this.state;

    try {
      const result = await this.props.inventorySheetCreation(
        sheetType,
        inventoryId,
        addressCode,
        productCode,
      );
      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE2134' /* Foi adicionado com sucesso */),
          'top-right',
        );
        this.closeInclusionModal();
        this.props.inventorySheetsList();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = `${error.code} - ${error.details || error.message}`;
        addNotification('danger', I18n.t('BEE2105' /* Manutenção de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  checkFields = () => {
    const {
      addressId, productCode, sheetType, inventoryId,
    } = this.state;
    if (!addressId) {
      addNotification(
        'danger',
        I18n.t('BEE2105' /* Manutenção de Inventário */),
        I18n.t('BEE2128' /* Endereço não informado */),
        'top-right',
      );
    } else if (sheetType === '2' && !productCode) {
      addNotification(
        'danger',
        I18n.t('BEE2105' /* Manutenção de Inventário */),
        I18n.t('BEE2129' /* Produto não informado */),
        'top-right',
      );
    } else if (!inventoryId) {
      addNotification(
        'danger',
        I18n.t('BEE2105' /* Manutenção de Inventário */),
        I18n.t('BEE3633' /* Agrupador não informado */),
        'top-right',
      );
    } else {
      this.inventorySheetCreation();
    }
  };

  goToElement = (e, id, attr) => {
    const { sheetType } = this.state;
    if (e.keyCode === 13) {
      if (attr === 'productCode') {
        this.checkFields();
      } else if (sheetType === '1' && attr === 'address') {
        this.checkFields();
      } else {
        document.getElementById(id + 1).focus();
        document.getElementById(id + 1).select();
      }
    }
  };

  storageAddressesOptions = async () => {
    const { inventory } = this.props;
    try {
      const listAddress = await this.props.getStorageAddressesOptions(inventory.branchCode);
      if (listAddress.length) this.setState({ listAddress: [...listAddress] });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2105' /* Manutenção de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      productCode, sheetType, listAddress, addressId, addressName, inventoryName, inventoryId,
    } = this.state;
    const { showInclusionModal, inventory } = this.props;

    return (
      <Modal isOpen={showInclusionModal} toggle={this.closeInclusionModal} size="lg">
        <ModalHeader toggle={this.closeInclusionModal}>
          {I18n.t('BEE2110' /* Incluir Produto / Endereço */)}
        </ModalHeader>
        <ModalBody>
          <div onSubmit={(e) => e.preventDefault()}>
            <Form
              noPanel
              leftType
              setValue={this.setValue}
              setValueDrop={this.setValueDrop}
              inputs={(formContext) => ([
                formContext.createSelectDropDown(
                  { value: inventoryId, label: inventoryName },
                  'inventory',
                  I18n.t('BEE1516' /* Agrupador */),
                  inventory.inventoryList || [],
                  false,
                  undefined,
                  undefined,
                  this.goToElement,
                  1,
                ),
                formContext.createRadioButtons(
                  [{
                    label: I18n.t('BEE267' /* Endereço */),
                    value: 1,
                  }, {
                    label: I18n.t('BEE225' /* Produto */),
                    value: 2,
                  }],
                  sheetType,
                  'sheetType',
                  `${I18n.t('BEE2127' /* Tipo da Ficha */)}:`,
                ),
                formContext.createSelectDropDown(
                  { value: addressId, label: addressName },
                  'address',
                  `${I18n.t('BEE267' /* Endereço */)}:`,
                  listAddress,
                  false,
                  undefined,
                  undefined,
                  this.goToElement,
                  2,
                ),
                sheetType === '2' && formContext.createInput(
                  productCode,
                  'productCode',
                  `${I18n.t('BEE225' /* Produto */)}:`,
                  I18n.t('BEE3750' /* Informe o Produto */),
                  'text',
                  false,
                  false,
                  undefined,
                  undefined,
                  this.goToElement,
                  3,
                ),
              ])}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-default"
            onClick={this.closeInclusionModal}
          >
            {I18n.t('BEE99' /* Cancelar */)}
          </button>
          <OneClickButton
            className="btn btn-primary"
            onClick={this.checkFields}
          >
            {I18n.t('BEE785' /* Adicionar */)}
          </OneClickButton>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getStorageAddressesOptions: (branchCode) => dispatch(getStorageAddressesOptions(branchCode)),
  inventorySheetCreation: (sheetType, inventoryId, addressCode, productCode) => dispatch(
    inventorySheetCreation(sheetType, inventoryId, addressCode, productCode),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InclusionModal));
