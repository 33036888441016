import RestService from '../services/rest';

// GET
async function getProducts(tableParams) {
  return RestService.postAuthenticated('products/list/all', tableParams);
}

async function getProduct(productId) {
  return RestService.getAuthenticated(`products/detail?productId=${productId}`);
}

async function getProductByCode(productCode) {
  return RestService.getAuthenticated('products/detail/getProductByCode', {
    productCode,
  });
}

// POST
async function createProduct(product) {
  return RestService.postAuthenticated('products/create', product);
}

async function deleteProduct(productId) {
  return RestService.postAuthenticated('products/delete', { productId });
}

// PUT
async function updateProduct(product) {
  return RestService.putAuthenticated('products/update', product);
}

export default {
  getProducts,
  createProduct,
  updateProduct,
  getProduct,
  deleteProduct,
  getProductByCode,
};
