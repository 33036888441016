import RestService from '../services/rest';

const getAllAccessProfiles = async () => {
  return RestService.getAuthenticated('accessProfile/getGrid');
};
const deleteAccessProfile = async (code) => {
  return RestService.postAuthenticated('accessProfile/delete', { code });
};
const updateAccessProfile = async (accessProfile = {}) => {
  return RestService.postAuthenticated('accessProfile/update', { ...accessProfile });
};
const createAccessProfile = async (accessProfile = {}) => {
  return RestService.postAuthenticated('accessProfile/create', { ...accessProfile });
};
const getAccessProfile = async (code) => {
  return RestService.getAuthenticated('accessProfile/get', { code });
};
const getAccessProfileOptions = async () => {
  return RestService.getAuthenticated('accessProfile/list/options');
}

export default {
  getAllAccessProfiles,
  deleteAccessProfile,
  updateAccessProfile,
  createAccessProfile,
  getAccessProfile,
  getAccessProfileOptions,
};