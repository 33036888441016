import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import addNotification from '../../components/notification';
import DualList from '../../components/pages/DualList';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportMovementInboundOrderList extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            { I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE36' /* Recebimento */) },
      { value: I18n.t('BEE438' /* Documentos de Entrada */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE443' /* Documento */),
        value: 'orderNumber',
      }, {
        label: I18n.t('BEE145' /* Filial */),
        value: 'branchCode',
      }, {
        label: I18n.t('BEE342' /* Fornecedor */),
        value: 'supplierCode',
      }, {
        label: I18n.t('BEE200' /* Tipo */),
        value: 'orderType',
      }, {
        label: I18n.t('BEE301' /* Transportadora */),
        value: 'carrierCode',
      }, {
        label: I18n.t('BEE405' /* Volumes */),
        value: 'expectedVolumes',
      }, {
        label: I18n.t('BEE444' /* Data de Entrega */),
        value: 'deliveryDate',
      }, {
        label: I18n.t('BEE224' /* Status */),
        value: 'status',
      }, {
        label: I18n.t('BEE1812' /* Quantidade de produtos */),
        value: 'qtdProdutos',
      }, {
        label: I18n.t('BEE403' /* Série */),
        value: 'serie',
      }, {
        label: I18n.t('BEE1729' /* Espécie */),
        value: 'specie',
      }, {
        label: I18n.t('BEE967' /* Chave de Acesso */),
        value: 'accessKey',
      }, {
        label: I18n.t('BEE400' /* Placa do Veículo */),
        value: 'licensePlate',
      }, {
        label: I18n.t('BEE1030' /* CFOP */),
        value: 'cfop',
      }, {
        label: I18n.t('BEE1730' /* CEP */),
        value: 'postalCode',
      }, {
        label: I18n.t('BEE568' /* IBGE */),
        value: 'ibge',
      }, {
        label: I18n.t('BEE121' /* Rua */),
        value: 'street',
      }, {
        label: I18n.t('BEE123' /* Número */),
        value: 'number',
      }, {
        label: I18n.t('BEE125' /* Complemento */),
        value: 'complement',
      }, {
        label: I18n.t('BEE567' /* Distrito */),
        value: 'district',
      }, {
        label: I18n.t('BEE53' /* Cidade */),
        value: 'city',
      }, {
        label: I18n.t('BEE87' /* Estado */),
        value: 'state',
      }, {
        label: I18n.t('BEE88' /* País */),
        value: 'country',
      }, {
        label: I18n.t('BEE135' /* Observação */),
        value: 'note',
      }, {
        label: I18n.t('BEE1734' /* Placa Verificada */),
        value: 'checkedPlate',
      }, {
        label: I18n.t('BEE1735' /* Pedido Verificado  */),
        value: 'checkedOrder',
      }, {
        label: I18n.t('BEE427' /* Armazenado  */),
        value: 'stored',
      },
      // {
      //     label: I18n.t('BEE207' /* Bloqueado  */),
      //     value: 'blocked',
      // },
      {
        label: I18n.t('BEE64' /* Cancelado  */),
        value: 'canceled',
      }, {
        label: I18n.t('BEE176' /* Divergência  */),
        value: 'divergence',
      }, {
        label: I18n.t('BEE575' /* Usuário de criação */),
        value: 'createdUser',
      }, {
        label: I18n.t('BEE578' /* Data de criação */),
        value: 'createdAt',
      }, {
        label: I18n.t('BEE576' /* Usuário de alteração */),
        value: 'updatedUser',
      }, {
        label: I18n.t('BEE577' /* Data de alteração */),
        value: 'updatedAt',
      },
    ];

    this.state = {
      selected: [],
      branchCodeFrom: '',
      branchCodeTo: '',
      deliveryDateFrom: '',
      deliveryDateTo: '',
      orderNumberFrom: '',
      orderNumberTo: '',
      supplierCodeFrom: '',
      supplierCodeTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      carrierCodeFrom: '',
      carrierCodeTo: '',
      pending: true,
      // endPlateConference: true,
      pendingPhysicalConference: true,
      physicalConferenceStarted: true,
      physicalConferenceFinalized: true,
      inStorage: true,
      storage: true,
      // blocked: true,
      canceled: true,
      checkAllCheckBoxes: true,
    };
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  checkOrUncheckAllBoxes = async (value) => {
    await this.setState({
      pending: value,
      pendingPhysicalConference: value,
      physicalConferenceStarted: value,
      physicalConferenceFinalized: value,
      inStorage: value,
      storage: value,
      canceled: value,
      checkAllCheckBoxes: value,
    });
  };

  handleSelectAllCheckBox = async () => {
    if (this.state.pending
          && this.state.pendingPhysicalConference
          && this.state.physicalConferenceStarted
          && this.state.physicalConferenceFinalized
          && this.state.inStorage
          && this.state.storage
          && this.state.canceled) {
      await this.setState({
        checkAllCheckBoxes: true,
      });
    } else {
      await this.setState({
        checkAllCheckBoxes: false,
      });
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });

    if (attr === 'checkAllCheckBoxes') {
      this.checkOrUncheckAllBoxes(value);
    }

    this.handleSelectAllCheckBox();
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      orderNumberFrom,
      orderNumberTo,
      branchCodeFrom,
      branchCodeTo,
      supplierCodeFrom,
      supplierCodeTo,
      productCodeFrom,
      productCodeTo,
      carrierCodeFrom,
      carrierCodeTo,
      deliveryDateFrom,
      deliveryDateTo,
      pending,
      // endPlateConference,
      pendingPhysicalConference,
      physicalConferenceStarted,
      physicalConferenceFinalized,
      inStorage,
      storage,
      // blocked,
      canceled,
    } = this.state;
    const { location } = this.props;
    const statusDocument = [];

    if (pending) statusDocument.push(1);
    // if(endPlateConference) statusDocument.push('ENDPLATECONFERENCE');
    if (pendingPhysicalConference) statusDocument.push(3);
    if (physicalConferenceStarted) statusDocument.push(4);
    if (physicalConferenceFinalized) statusDocument.push(5);
    if (inStorage) statusDocument.push(6);
    if (storage) statusDocument.push(7);
    // if(blocked) statusDocument.push('BLOCKED');
    if (canceled) statusDocument.push(9);

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['orderNumber', { type: 'between', value: [orderNumberFrom, orderNumberTo] }],
      ['supplierCode', { type: 'between', value: [supplierCodeFrom, supplierCodeTo] }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['carrierCode', { type: 'between', value: [carrierCodeFrom, carrierCodeTo] }],
      ['deliveryDate', { type: 'between', value: [deliveryDateFrom, deliveryDateTo] }],
      ['status', { type: 'in', value: statusDocument }],
    ]);

    if (!filters.status) {
      addNotification(
        'danger',
        I18n.t('BEE1759' /* Status do Documento */),
        I18n.t('BEE1198' /* Status deve ser informado! */),
        'top-right',
      );
      return;
    }

    this.props.generateReport(
      I18n.t('BEE438' /* Documentos de Entrada */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  onSelectFilter = (newState) => {
    this.setState({ ...newState });
  };

  render() {
    const {
      selected,
      orderNumberFrom,
      orderNumberTo,
      branchCodeFrom,
      branchCodeTo,
      supplierCodeFrom,
      supplierCodeTo,
      productCodeFrom,
      productCodeTo,
      carrierCodeFrom,
      carrierCodeTo,
      deliveryDateFrom,
      deliveryDateTo,
      pending,
      // endPlateConference,
      pendingPhysicalConference,
      physicalConferenceStarted,
      physicalConferenceFinalized,
      inStorage,
      storage,
      // blocked,
      canceled,
      checkAllCheckBoxes,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE438' /* Documentos de Entrada */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: orderNumberFrom, to: orderNumberTo },
                    { from: 'orderNumberFrom', to: 'orderNumberTo' },
                    `${I18n.t('BEE443' /* Documento */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: supplierCodeFrom, to: supplierCodeTo },
                    { from: 'supplierCodeFrom', to: 'supplierCodeTo' },
                    `${I18n.t('BEE342' /* Fornecedor */)}:`,
                    { from: '', to: 'Z'.repeat(100) },
                  ),
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE225' /* Produto */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: carrierCodeFrom, to: carrierCodeTo },
                    { from: 'carrierCodeFrom', to: 'carrierCodeTo' },
                    `${I18n.t('BEE301' /* Transportadora */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createDateInputRange(
                    { from: deliveryDateFrom, to: deliveryDateTo },
                    { from: 'deliveryDateFrom', to: 'deliveryDateTo' },
                    `${I18n.t('BEE444' /* Data de Entrega */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createCheckBoxes([
                    {
                      attr: 'checkAllCheckBoxes',
                      value: checkAllCheckBoxes,
                      label: I18n.t('BEE2385' /* Marcar ou Desmarcar todos */),
                    },
                    { attr: 'pending', value: pending, label: I18n.t('BEE1783' /* Pendente de Placa */) },
                    // { attr: 'endPlateConference', value: endPlateConference,
                    // label: I18n.t('BEE471' /* Conferência Placa Finalizada */) },
                    {
                      attr: 'pendingPhysicalConference',
                      value: pendingPhysicalConference,
                      label: I18n.t('BEE472' /* Conferência Física Pendente */),
                    },
                    {
                      attr: 'physicalConferenceStarted',
                      value: physicalConferenceStarted,
                      label: I18n.t('BEE473' /* Conferência Física Iniciada */),
                    },
                    {
                      attr: 'physicalConferenceFinalized',
                      value: physicalConferenceFinalized,
                      label: I18n.t('BEE474' /* Conferência Física Finalizada */),
                    },
                    { attr: 'inStorage', value: inStorage, label: I18n.t('BEE475' /* Em armazenagem */) },
                    { attr: 'storage', value: storage, label: I18n.t('BEE427' /* Armazenado */) },
                    // { attr: 'blocked', value: blocked, label: I18n.t('BEE207' /* Bloqueado */) },
                    { attr: 'canceled', value: canceled, label: I18n.t('BEE64' /* Cancelado */) },
                  ], `${I18n.t('BEE1759' /* Status do Documento */)}:`),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
                )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
                )}
      />
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (nameDoc, type, route, columns, filters) => dispatch(generateReport(
    nameDoc,
    type,
    route,
    columns,
    filters,
  )),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMovementInboundOrderList);
