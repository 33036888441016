import RestService from '../services/rest';

// GET
async function getCustomers(tableParams) {
  return RestService.postAuthenticated('customers/list/all', tableParams);
}

async function getCustomer(customerId) {
  return RestService.getAuthenticated(`customers/detail?customerId=${customerId}`);
}

async function getCustomersOptions() {
  return RestService.getAuthenticated('customers/list/options');
}

// POST
async function createCustomer(customer) {
  return RestService.postAuthenticated('customers/create', customer);
}

async function deleteCustomer(customerId) {
  return RestService.postAuthenticated('customers/delete', { customerId });
}

// PUT
async function updateCustomer(customer) {
  return RestService.putAuthenticated('customers/update', customer);
}

export default {
  getCustomers,
  createCustomer,
  updateCustomer,
  getCustomer,
  deleteCustomer,
  getCustomersOptions,
};
