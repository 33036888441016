import AgentServer from '../../server/agent';
import { addLoading, removeLoading } from './loading';

export const setAgents = (agents) => ({
  type: 'SET_DEVELOP_AGENT_GRID',
  payload: agents,
});

export const getAllAgents = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const agents = await AgentServer.getAllAgents();
    dispatch(setAgents(agents));
  } finally {
    dispatch(removeLoading());
  }
};

export const createAgent = (agent) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newAgent = await AgentServer.createAgent(agent);

    return newAgent;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateAgent = (agent) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AgentServer.updateAgent(agent);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateTokenAgent = (agent) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AgentServer.updateTokenAgent(agent);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateActiveAgent = (agent) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AgentServer.updateActiveAgent(agent);
  } finally {
    dispatch(removeLoading());
  }
};

export const getAgent = (code) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AgentServer.getAgent(code);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteAgent = (code) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AgentServer.deleteAgent(code);
  } finally {
    dispatch(removeLoading());
  }
};
