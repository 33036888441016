import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import { getInboundOrdersList } from '../../../app/store/actions/inboundOrders';
import Table from '../../../components/table/Table';
import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';
import InboundOrderHelpers from '../../../helpers/inboundOrders';

class InboundOrderList extends React.Component {
  constructor(props) {
    super(props);

    // Atributo para definir que o modo de exibição vai ser para Consulta
    this.isConsultMode = this.props.isConsult;

    this.state = {
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
    };

    this.defaultSorted = [
      {
        id: 'orderNumber',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => {
          // const showEdit = rows.row.status !== 1 ? false : true;
          const consultMode = this.isConsultMode; // Modo Consulta ligado (true), Modo Consulta desligado (false)

          return (
            <div style={{ textAlign: 'center' }}>
              <button type="button" className="btn btn-default btn-xs">
                {I18n.t('BEE55' /* Ações */)}
              </button>
              {consultMode ? (
                <UncontrolledButtonDropdown>
                  <DropdownToggle
                    color="default"
                    caret
                    className="btn-xs"
                  />
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => this.showInboundOrderDetail(rows.row)}
                    >
                      {I18n.t('BEE56' /* Detalhes */)}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              ) : (
                <UncontrolledButtonDropdown>
                  <DropdownToggle
                    color="default"
                    caret
                    className="btn-xs"
                  />
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => this.showInboundOrderDetail(rows.row)}
                    >
                      {I18n.t('BEE56' /* Detalhes */)}
                    </DropdownItem>
                    {/* {showEdit && (
                      <DropdownItem
                        onClick={() => this.editInboundOrder(rows.row)}
                      >
                        {I18n.t("BEE57")}
                      </DropdownItem>
                    )} */}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              )}
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE342' /* Fornecedor */),
        accessor: 'supplierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{InboundOrderHelpers.inboundOrderStatus(row.value)}</span>
          </div>
        ),
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option value={1}>{InboundOrderHelpers.inboundOrderStatus(1)}</option>
            {/* <option value={2}  >{InboundOrderHelpers.inboundOrderStatus(2)}</option> */}
            <option value={3}>{InboundOrderHelpers.inboundOrderStatus(3)}</option>
            <option value={4}>{InboundOrderHelpers.inboundOrderStatus(4)}</option>
            <option value={5}>{InboundOrderHelpers.inboundOrderStatus(5)}</option>
            <option value={6}>{InboundOrderHelpers.inboundOrderStatus(6)}</option>
            <option value={7}>{InboundOrderHelpers.inboundOrderStatus(7)}</option>
            {/* <option value={8}  >{InboundOrderHelpers.inboundOrderStatus(8)}</option> */}
            <option value={9}>{InboundOrderHelpers.inboundOrderStatus(9)}</option>
          </select>
        ),
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE405' /* Volumes */),
        accessor: 'expectedVolumes',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{parseInt(row.value || 0, 10)}</span>
          </div>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2224' /* Data Integração */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (
            _.includes(dateFirstOption, input)
            || _.includes(dateSecondOption, input)
          ) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('L LTS')}
          </span>
        ),
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      await this.setState({
        currentRange: this.props.location.state.dice.currentRange,
        startDate: this.props.location.state.dice.startDate,
        endDate: this.props.location.state.dice.endDate,
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getInboundOrders();
    } else {
      await this.getInboundOrders();
    }
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (
          event.target.value === ''
            && (event.keyCode === 8 || event.which === 8)
        ) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getInboundOrders = async () => {
    const { startDate, endDate } = this.state;

    await this.props.getInboundOrdersList(startDate, endDate);
  };

  showInboundOrderDetail = (inboundOrder) => {
    this.props.history.push({
      pathname: this.isConsultMode ? ROUTES.CONSULT_INBOUND_DETAILS : ROUTES.INBOUND_ORDER_DETAIL,
      state: {
        inboundOrder: inboundOrder._original,
      },
      dice: {
        startDate: moment(this.state.startDate).format('MM/DD/YYYY'),
        endDate: moment(this.state.endDate).format('MM/DD/YYYY'),
        currentRange: this.state.currentRange,
        page: this.state.page,
      },
    });
  };

  editInboundOrder = (inboundOrder) => {
    this.props.history.push({
      pathname: ROUTES.INBOUND_ORDER_EDIT,
      state: {
        inboundOrder: inboundOrder._original,
      },
      dice: {
        startDate: moment(this.state.startDate).format('MM/DD/YYYY'),
        endDate: moment(this.state.endDate).format('MM/DD/YYYY'),
        currentRange: this.state.currentRange,
        page: this.state.page,
      },
    });
  };

  handleDateApplyEvent = async (event) => {
    const startDate = moment(event[0]).format('MM/DD/YYYY');
    const endDate = moment(event[1]).format('MM/DD/YYYY');

    await this.setState({
      startDate,
      endDate,
    });

    this.getInboundOrders();
  };

  createWidgetStats = () => {
    const { inboundOrdersList = [] } = this.props;

    const stats = [
      {
        title: I18n.t('BEE517' /* Conferência Placa */).toUpperCase(),
        quantity: inboundOrdersList.filter(
          (order) => order.status === 1 || order.status === 2,
        ).length,
        color: 'dark',
      },
      {
        title: I18n.t('BEE472' /* Conferência Física Pendente */).toUpperCase(),
        quantity: inboundOrdersList.filter((order) => order.status === 3).length,
        color: 'orange',
      },
      {
        title: I18n.t('BEE473' /* Conferência Física Iniciada */).toUpperCase(),
        quantity: inboundOrdersList.filter((order) => order.status === 4 || order.status === 5).length,
        color: 'yellow',
      },
      {
        title: I18n.t('BEE38' /* Armazenamento */).toUpperCase(),
        quantity: inboundOrdersList.filter((order) => order.status === 6)
          .length,
        color: 'teal',
      },
      {
        title: I18n.t('BEE519' /* Finalizados */).toUpperCase(),
        quantity: inboundOrdersList.filter((order) => order.status === 7)
          .length,
        color: 'green',
      },
      // {
      //   title: I18n.t("BEE520" /* Bloqueados */).toUpperCase(),
      //   quantity: inboundOrdersList.filter((order) => order.status === 8)
      //     .length,
      //   color: "yellow",
      // },
      {
        title: I18n.t('BEE521' /* Cancelados */).toUpperCase(),
        quantity: inboundOrdersList.filter((order) => order.status === 9)
          .length,
        color: 'red',
      },
    ];

    return (
      <div
        className="row row-space-10 m-b-15"
        style={{ justifyContent: 'space-between' }}
      >
        {stats.map((stat, index) => (
          <div className="col-lg-2" key={index}>
            <div
              style={{ maxHeight: '100px' }}
              className={`widget widget-stats bg-${stat.color} m-b-10 p-b-0`}
            >
              <div className="stats-content text-center">
                <div className="stats-title f-w-800" style={{ color: '#fff', fontSize: '14px' }}>{stat.title}</div>
                <div className="stats-number f-s-30 mb-5">{stat.quantity}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { inboundOrdersList = [] } = this.props;

    const {
      page,
    } = this.state;
    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">
              {this.isConsultMode ? I18n.t('BEE33' /* Consultas */) : I18n.t('BEE1400' /* Movimentação */)}
            </li>
            <li className="breadcrumb-item">
              {I18n.t('BEE36' /* Recebimento */)}
            </li>
            <li className="breadcrumb-item active">
              {I18n.t('BEE438' /* Documentos de Entrada */)}
            </li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE438' /* Documentos de Entrada */)}
          </h1>
          <WikiHelp wikiPath={this.isConsultMode
            ? ROUTES.CONSULT_INBOUND_HELP : ROUTES.INBOUND_ORDER_LIST_HELP}
          />
        </div>
        {this.createWidgetStats()}
        <Table
          downloadCSV
          panelHeaderProps={{
            onClickReload: this.getInboundOrders,
          }}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          filterable
          data={inboundOrdersList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  inboundOrdersList: state.inboundOrders && state.inboundOrders.inboundOrdersList,
});

const mapDispatchToProps = (dispatch) => ({
  getInboundOrdersList: (startDate, endDate) => dispatch(getInboundOrdersList(startDate, endDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InboundOrderList));
