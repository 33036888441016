import 'react-table/react-table.css';
import React from 'react';
import Table from '../../../../components/table/Table';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';
import SweetAlert from 'react-bootstrap-sweetalert';

import { deleteLinkUser } from '../../../../app/store/actions/indirectTasks';

class UsersIndirectTasksTable extends React.Component { 
  constructor(props) {
    super(props)

    this.state = {
      showModalDelete: false,
      selectedUsersIndirectTask: null,
    }

    this.tableColumns = [
      {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'user.code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'user.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
      }, {
        Header: I18n.t('BEE279' /* Setor */),
        accessor: 'user.departmentUser.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      },
    ];

    this.actionColumn = {
      Header: I18n.t('BEE55' /* Ações */),
      accessor: 'action',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      width: 100,
      Cell: rows => (
        <div style={{ textAlign: 'center' }}>
          <button
            type="button" 
            className="btn btn-default btn-xs"
            onClick={() => this.deleteUserLink(rows.row._original)}
          >{I18n.t('BEE608' /* Desvincular */)}</button>
        </div>
      )
    }
  }

  openUserLinkModal = () => {
    this.props.openUserLinkModal()
  }

  deleteUserLink = (usersIndirectTask) => {
    this.setState({
      showModalDelete: true,
      selectedUsersIndirectTask: usersIndirectTask
    })
  }

  confirmDeleteUserLink = async () => {
    const { code, name } = this.props.indirectTaskSelected
    const { user, id } = this.state.selectedUsersIndirectTask

    try {
      const resultado = await this.props.deleteLinkUser(id)

      if (resultado === 1) {
          this.closeDeleteModal()
          addNotification(
            'success',
            I18n.t('BEE1668' /* Tarefas Indiretas */),
            I18n.t('BEE1693', { 0:user.code,1:user.name,2:code,3:name } /* %{0} - %{1} desvinculado da Range %{2} - %{3} com sucesso! */),
            'top-right'
          )
          await this.props.getIndirectTaskSelected()
        } else {
          throw new Error(I18n.t('BEE808' /* Vínculo com a Range não encontrado */))
        }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1668' /* Tarefas Indiretas */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          addNotification(
            'danger', I18n.t('BEE1668' /* Tarefas Indiretas */), I18n.t('BEE1692', { 0: code } /* Erro ao incluir a tarefa %{0} */), 'top-right'
          );
        }
      } else {
        addNotification(
          'danger', I18n.t('BEE1668' /* Tarefas Indiretas */), I18n.t('BEE1692', { 0: code } /* Erro ao incluir a tarefa %{0} */), 'top-right'
        );
      }
    }
  }

  closeDeleteModal = () => {
    this.setState({
      showModalDelete: false,
      selectedUsersIndirectTask: null
    })
  }

  reloadTable = async () => {
    await this.props.getIndirectTaskSelected()
  }

  render() {
    const { usersIndirectTasks, title, showUserLinkBtn,
      openUserLinkModal, indirectTaskSelected } = this.props;

    const { showModalDelete, selectedUsersIndirectTask } = this.state;

    const columns = showUserLinkBtn
      ? [ this.actionColumn, ...this.tableColumns]
      : this.tableColumns

    return (
      <div className="table-responsive">
        <Table
          headerTitle={title}
          data={usersIndirectTasks}
          columns={columns}
          panelHeaderProps={{
            onClickReload: this.reloadTable
          }}
          expander
          filterable
          defaultPageSize={this.props.lines ? this.props.lines : 10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          actionButtons={(
            showUserLinkBtn &&
              <div className="ml-auto">
                <OneClickButton
                  type="button"
                  className="btn btn-240 btn-success btn-rounded pr-3"
                  onClick={() => openUserLinkModal()}
                ><i className="fa fa-link mr-1"></i>{I18n.t('BEE620' /* Vincular Usuários */)}</OneClickButton>
              </div>
          )}
        />
        {(showModalDelete &&
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.confirmDeleteUserLink()}
            onCancel={() => this.closeDeleteModal()}
          >
            {I18n.t('BEE1694', { 0: selectedUsersIndirectTask.user.name, 1: indirectTaskSelected.name } /* O usuário %{0} será descinvulado da Range %{1} */)}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  deleteLinkUser: (usersIndirectTasksId) => dispatch(deleteLinkUser(usersIndirectTasksId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersIndirectTasksTable));