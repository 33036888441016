import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';

export default class VolumesList extends React.Component {  
  render() {
    const { volumes } = this.props;

    return (
      <div className="table-responsive">
        <table className={'table table-bordered widget-table widget-table-rounded'}>
          <thead>
            <tr className="text-center f-s-12">
              <th width="20%">{I18n.t('BEE494' /* Sequência */)}</th>
              <th width="50%">{I18n.t('BEE497' /* Embalagem */)}</th>
              <th width="30%">{I18n.t('BEE562' /* Volume */)}</th>
            </tr>
          </thead>
          <tbody>
            {volumes.map((volume, i) => {

              return (
                <tr className="text-center" key={`${i}`}>
                  <td>
                    <p>{ i+1 }</p>
                  </td>
                  <td className="text-left">
                    <p className={"widget-table-desc m-b-15"}>{ volume.packageCode }</p>
                  </td>
                  <td>
                    <p>{volume.volumeLabel}</p>
                  </td>
                </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}