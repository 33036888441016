import { I18n } from 'react-redux-i18n';

function statusOutboundOrdersWave(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE1365' /* Pendente Separação */);
      case 2:
        return I18n.t('BEE1367' /* Em Separação */);
      case 3:
        return I18n.t('BEE1765' /* Concluído */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE1365' /* Pendente Separação */) },
      { value: 2, label: I18n.t('BEE1367' /* Em Separação */) },
      { value: 3, label: I18n.t('BEE1765' /* Concluído */) },
    ];
  }
}

export default {
  statusOutboundOrdersWave,
};
