import I18nServer from '../../server/i18n';
import { addLoading, removeLoading } from './loading';

export const setI18ns = (i18ns) => ({
  type: 'SET_DEVELOP_I18N_GRID',
  payload: i18ns,
});

export const getAllI18ns = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const i18ns = await I18nServer.getAllI18ns();
    dispatch(setI18ns(i18ns));
  } finally {
    dispatch(removeLoading());
  }
};

export const getTranslate = (text, from, to) => async () => {
  try {
    const textTranslated = await I18nServer.getTranslate(text, from, to);
    return typeof textTranslated === 'string' ? textTranslated : text;
  } finally {}
};

export const createI18n = (i18n) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newI18n = await I18nServer.createI18n(i18n);

    return newI18n;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateI18n = (i18n) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await I18nServer.updateI18n(i18n);
  } finally {
    dispatch(removeLoading());
  }
};

export const getI18n = (key) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await I18nServer.getI18n(key);
  } finally {
    dispatch(removeLoading());
  }
};

export const getI18nNewKey = () => async (dispatch) => {
  try {
    return await I18nServer.getI18nNewKey();
  } finally {}
};

export const deleteI18n = (key) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await I18nServer.deleteI18n(key);
  } finally {
    dispatch(removeLoading());
  }
};
