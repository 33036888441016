import IndirectTasksRequests from '../../server/indirectTasks';
import { addLoading, removeLoading } from './loading';

export const setIndirectTasks = indirectTasks => ({
  type: 'SET_INDIRECT_TASKS_LIST',
  indirectTasks,
});

export const createIndirectTask = indirectTask => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newIndirectTask = await IndirectTasksRequests.createIndirectTask(indirectTask);
    return newIndirectTask;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateIndirectTask = (indirectTask) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IndirectTasksRequests.updateIndirectTask(indirectTask);
  } finally {
    dispatch(removeLoading());
  }
}

export const getIndirectTask = (indirectTaskId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IndirectTasksRequests.getIndirectTask(indirectTaskId);
  } finally {
    dispatch(removeLoading());
  }
}

export const getIndirectTasksList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const indirectTasks = await IndirectTasksRequests.getIndirectTasks();
    dispatch(setIndirectTasks(indirectTasks));
  } finally {
    dispatch(removeLoading());
  }
};

export const getIndirectTasksOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IndirectTasksRequests.getIndirectTasksOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteIndirectTask = (indirectTaskId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IndirectTasksRequests.deleteIndirectTask(indirectTaskId);
  } finally {
    dispatch(removeLoading());
  }
}

export const getUserList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const users = await IndirectTasksRequests.getUserList(tableParams);
    dispatch(removeLoading());
    return users
  } catch(e) {
    dispatch(removeLoading());
    return 0
  }
}

export const createLinkUser = (userId, indirectTaskCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IndirectTasksRequests.createLinkUser(userId, indirectTaskCode);
  } finally {
    dispatch(removeLoading());
  }
}

export const deleteLinkUser = (userIndirectTaskId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IndirectTasksRequests.deleteLinkUser(userIndirectTaskId);
  } finally {
    dispatch(removeLoading());
  }
}
