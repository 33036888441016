import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from '../../components/form/button';
import { PanelPage } from '../../components/pages/pages';
import Form from '../../components/form/form';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportQtyProductsStoredPerUser extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
        </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1963' /* KPIs */) },
      { value: I18n.t('BEE1986' /* Qtd de Produtos Armazenados por Usuário */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE145' /* Filial */),
        value: 'inboundOrderProductId',
      },
      {
        label: I18n.t('BEE1997' /* Nome Filial */),
        value: 'branchName',
      },
      {
        label: I18n.t('BEE469' /* Data Armazenagem */),
        value: 'storageProductAt',
      },
      // {
      //   label: I18n.t('BEE1748' /* Código Usuário */),
      //   value: 'storageProductUser'
      // },
      {
        label: I18n.t('BEE1749' /* Nome Usuário */),
        value: '_storageProductUser',
      },
      {
        label: I18n.t('BEE581' /* Range */),
        value: '_range',
      },
      {
        label: I18n.t('BEE1812' /* Quantidade de produtos */),
        value: 'quantityStorage',
      },
    ];

    this.state = {
      branchCodeFrom: '',
      branchCodeTo: '',
      storageProductAtFrom: '',
      storageProductAtTo: '',
      storageProductUserLoginFrom: '',
      storageProductUserLoginTo: '',
      storageProductUserNameFrom: '',
      storageProductUserNameTo: '',
      rangeFrom: '',
      rangeTo: '',
    };
  }

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      branchCodeFrom, branchCodeTo, storageProductAtFrom, storageProductAtTo,
      storageProductUserLoginFrom, storageProductUserLoginTo,
      storageProductUserNameFrom, storageProductUserNameTo, rangeFrom, rangeTo,
    } = this.state;

    const { location } = this.props;

    const filters = returnFiltersByRules([
      ['$branchCode', {
        type: 'between',
        value: [branchCodeFrom, branchCodeTo],
        instance: 'InboundOrder',
        as: 'inboundOrderProduct->inboundOrder',
      }],
      ['storageProductAt', { type: 'between', value: [storageProductAtFrom, storageProductAtTo] }],
      ['$login', {
        type: 'between',
        value: [storageProductUserLoginFrom, storageProductUserLoginTo],
        instance: 'User',
        as: 'userProductStorage',
      }],
      ['$name', {
        type: 'between',
        value: [storageProductUserNameFrom, storageProductUserNameTo],
        instance: 'User',
        as: 'userProductStorage',
      }],
      ['$rangeCode', {
        type: 'between', value: [rangeFrom, rangeTo], instance: 'StorageAddress', as: 'storage',
      }],
    ]);

    this.props.generateReport(
      I18n.t('BEE1986' /* Qtd de Produtos Armazenados por Usuário */).toLowerCase(),
      'xlsx',
      location.pathname,
      this.options,
      filters,
    );
  };

  onSelectFilter = (newState) => {
    this.setState({ ...newState });
  };

  render() {
    const {
      branchCodeFrom, branchCodeTo, storageProductAtFrom, storageProductAtTo, storageProductUserLoginFrom,
      storageProductUserLoginTo, storageProductUserNameFrom, storageProductUserNameTo, rangeFrom, rangeTo,
    } = this.state;
    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE1986' /* Qtd de Produtos Armazenados por Usuário */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createDateInputRange(
                    { from: storageProductAtFrom, to: storageProductAtTo },
                    { from: 'storageProductAtFrom', to: 'storageProductAtTo' },
                    `${I18n.t('BEE469' /* Data Armazenagem */)}:`,
                    { from: '', to: '__/__/____' },
                  ),
                  formContext.createInputRange(
                    { from: storageProductUserLoginFrom, to: storageProductUserLoginTo },
                    { from: 'storageProductUserLoginFrom', to: 'storageProductUserLoginTo' },
                    `${I18n.t('BEE1748' /* Código Usuário */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: storageProductUserNameFrom, to: storageProductUserNameTo },
                    { from: 'storageProductUserNameFrom', to: 'storageProductUserNameTo' },
                    `${I18n.t('BEE1749' /* Nome Usuário */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: rangeFrom, to: rangeTo },
                    { from: 'rangeFrom', to: 'rangeTo' },
                    `${I18n.t('BEE581' /* Range */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportQtyProductsStoredPerUser);
