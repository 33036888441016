/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import { getOutboundOrderByDate, restrictionRelease } from '../../../app/store/actions/outboundOrders';
import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';
import OutboundOrderHelpers from '../../../helpers/outboundOrder';

class RestrictionMonitorList extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE2068' /* Monitor de Restrição */), active: true },
    ];

    this.state = {
      checked: {},
      outboundOrdersList: [],
      checkedOutboundOrderIds: [],
      showModalRestrictionRelease: false,
      defaultPageSize: 10,
      defaultSorted: [{ id: 'orderNumber', desc: false }],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE436' /* Selecionar */),
        accessor: 'selected',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={this.state.checked[row.original.outboundOrderId] || false}
              onChange={() => this.singleSelection(row.original)}
            />
          </div>
        ),
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE1107' /* Cidade Entrega */),
        accessor: 'shipToCity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE444' /* Data de Entrega */),
        accessor: 'deliveryDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE578' /* Data de Criação */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('L')}
          </span>
        ),

      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{OutboundOrderHelpers.outboundOrderStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {OutboundOrderHelpers.outboundOrderStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      },
    ];
  }

  componentDidMount() {
    this.outboundOrdersList();
  }

  singleSelection = (selection) => {
    const { checked } = this.state;
    checked[selection.outboundOrderId] = !checked[selection.outboundOrderId];
    this.setState({ checked: { ...checked } });
  };

  multipleSelection = (selectionOption) => {
    const { checked } = this.state;
    const data = this.reactTable.getResolvedState().sortedData;
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index += 1) {
        const element = data[index]._original;
        checked[element.outboundOrderId] = selectionOption;
      }
      this.setState({ checked: { ...checked } });
    }
  };

  validChecked = () => {
    const { checked } = this.state;
    if (!Object.keys(checked).length) {
      addNotification(
        'danger',
        I18n.t('BEE2068' /* Monitor de Restrição */),
        I18n.t('BEE2069' /* Nenhum documento selecionado */),
        'top-right',
      );
    } else {
      const checkedOutboundOrderIds = [];
      for (const index in checked) {
        if (checked[index]) checkedOutboundOrderIds.push(index);
      }
      if (checkedOutboundOrderIds.length) {
        this.setState({
          checkedOutboundOrderIds: [...checkedOutboundOrderIds],
          showModalRestrictionRelease: true,
        });
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2068' /* Monitor de Restrição */),
          I18n.t('BEE2069' /* Nenhum documento selecionado */),
          'top-right',
        );
      }
    }
  };

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });

    this.outboundOrdersList();
  };

  restrictionRelease = async () => {
    const { checkedOutboundOrderIds } = this.state;
    this.setState({ checkedOutboundOrderIds: [], showModalRestrictionRelease: false, checked: {} });
    try {
      const result = await this.props.restrictionRelease(checkedOutboundOrderIds);
      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2068' /* Monitor de Restrição */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2068' /* Monitor de Restrição */),
          I18n.t('BEE2071', { 0: checkedOutboundOrderIds.length } /* %{0} documentos liberados */),
          'top-right',
        );
        this.outboundOrdersList();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2068' /* Monitor de Restrição */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2068' /* Monitor de Restrição */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  outboundOrdersList = async () => {
    const { startDate, endDate } = this.state;

    try {
      const outboundOrdersList = await this.props.getOutboundOrderByDate(
        moment(startDate).format(),
        moment(endDate).format(),
        2,
      );
      if (outboundOrdersList && outboundOrdersList.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2068' /* Monitor de Restrição */),
          I18n.t('BEE1918' /* Não foi possível localizar os documentos */),
          'top-right',
        );
      } else {
        this.setState({ outboundOrdersList });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2068' /* Monitor de Restrição */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2068' /* Monitor de Restrição */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      showModalRestrictionRelease, defaultPageSize, defaultSorted, outboundOrdersList, checkedOutboundOrderIds,
    } = this.state;

    return (
      <>
        <Table
          ref={(r) => {
            this.reactTable = r && r.reactTable;
          }}
          breadcrumb={this.breadcrumb}
          headerTitle={I18n.t('BEE2068' /* Monitor de Restrição */)}
          wikiHelp={ROUTES.OUTBOUND_RESTRICTION_MONITOR_HELP}
          downButtonPosition
          panelHeaderProps={{
            onClickReload: this.outboundOrdersList,
          }}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          actionButtons={(
            <div className="ml-auto">
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(true)}
              >
                {I18n.t('BEE1908' /* Marcar Todos */)}
              </button>
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(false)}
              >
                {I18n.t('BEE1909' /* Desmarcar Todos */)}
              </button>
              <OneClickButton
                type="button"
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={() => this.validChecked()}
              >
                {I18n.t('BEE1435' /* Liberar */)}
              </OneClickButton>
            </div>
          )}
          filterable
          data={outboundOrdersList}
          columns={this.tableColumns}
          expander
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalRestrictionRelease
          && (
          <SweetAlert
            info
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE1849' /* Continuar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.restrictionRelease()}
            onCancel={() => this.setState({ showModalRestrictionRelease: false, checkedOutboundOrderIds: [] })}
          >
            {I18n.t(
              'BEE2070',
              { 0: checkedOutboundOrderIds.length }, /* Sera liberado %{0} documentos, deseja continuar? */
            )}
          </SweetAlert>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getOutboundOrderByDate: (
    filterStartDate,
    filterEndDate,
    typeOfSearch,
  ) => dispatch(getOutboundOrderByDate(filterStartDate, filterEndDate, typeOfSearch)),
  restrictionRelease: (checkedOutboundOrderIds) => dispatch(restrictionRelease(checkedOutboundOrderIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RestrictionMonitorList));
