import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getCustomerProductsList, deleteCustomerProduct } from '../../../../app/store/actions/customerProducts';

class CustomerProductsList extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      showModalDelete: false,
      customerProductUpdate: null,
      pages: 0,
      totalDataLength: 0,
    };

    this.defaultSorted = [
      {
        id: 'productCode',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showCustomerProductDetail(rows.row)}>
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showEditCustomerProduct(rows.row)}>
                  {I18n.t('BEE57' /* Editar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showCopyCustomerProduct(rows.row)}>
                  {I18n.t('BEE80' /* Copiar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showDeleteCustomerProduct(rows.row)}>
                  {I18n.t('BEE81' /* Eliminar */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2112' /* Produto Cliente */),
        accessor: 'productCustomerCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE3194' /* Descrição do Cliente */),
        accessor: 'productCustomerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 400,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE384' /* Unidade */),
        accessor: 'unitMeasure',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({ page: this.props.location.state.dice.page });
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      customerProductUpdate: null,
    });
  };

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getCustomerProducts = async () => {
    const data = await this.props.getCustomerProductsList({
      page: this.page,
      pageSize: this.pageSize,
      sorted: this.sorted,
      filtered: this.filtered,
    });
    this.setState({ pages: data.pages, firstRun: true, totalDataLength: data.totalDataLength });
  };

  deleteCustomerProduct = async (customerProduct) => {
    try {
      const result = await this.props.deleteCustomerProduct(customerProduct._original.id);
      if (!result || result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE824' /* Eliminar produto por cliente */),
          I18n.t('BEE2031' /* Erro ao eliminar o registro */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE824' /* Eliminar produto por cliente */),
          I18n.t('BEE825' /* Produto por cliente eliminado com sucesso! */),
          'top-right',
        );
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification(
          'danger',
          I18n.t('BEE824' /* Eliminar produto por cliente */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE824' /* Eliminar produto por cliente */),
          I18n.t('BEE2031' /* Erro ao eliminar o registro */),
          'top-right',
        );
      }
    }

    this.setInitState();

    await this.getCustomerProducts();
  };

  showCustomerProductDetail = (customerProduct) => {
    this.props.history.push({
      pathname: ROUTES.CUSTOMER_PRODUCT_DETAIL,
      state: {
        customerProduct: customerProduct._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditCustomerProduct = (customerProduct) => {
    this.props.history.push({
      pathname: ROUTES.CUSTOMER_PRODUCT_EDIT,
      state: {
        customerProduct: customerProduct._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyCustomerProduct = (customerProduct) => {
    this.props.history.push({
      pathname: ROUTES.CUSTOMER_PRODUCT_COPY,
      state: {
        customerProduct: customerProduct._original,
      },
    });
  };

  showDeleteCustomerProduct = (customerProdcut) => {
    this.setState({ showModalDelete: true, customerProductUpdate: customerProdcut });
  };

  render() {
    const { customerProductsList = [] } = this.props;
    const {
      customerProductUpdate, showModalDelete, pages, page, totalDataLength,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE380' /* Produtos por Cliente */)}</li>
          </ol>
        </div>
        <Table
          wikiHelp={ROUTES.CUSTOMER_PRODUCT_HELP}
          downloadCSV
          headerTitle={I18n.t('BEE380' /* Produtos por Cliente */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.CUSTOMER_PRODUCT_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE359' /* Incluir Produto */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getCustomerProducts,
          }}
          filterable
          data={customerProductsList}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          manual
          pages={pages}
          page={(page) || ((!this.state.firstRun
            && this.props.location.state
            && this.props.location.state.dice
            && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(Page) => ((Page >= 0) ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
          onFetchData={(state) => {
            this.page = (state.page) || (!this.state.firstRun
              && this.props.location.state
              && this.props.location.state.dice
              && this.props.location.state.dice.page)
              ? this.props.location.state.dice.page : state.page;
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;
            this.getCustomerProducts();
          }}
        />
        {(showModalDelete
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.deleteCustomerProduct(customerProductUpdate)}
              onCancel={() => this.setInitState()}
            >
              {
                I18n.t(
                  'BEE866',
                  { 0: customerProductUpdate.productCode }, /*  O produto por cliente %{0} será eliminado ! */
                )
              }
            </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customerProductsList: state.customerProducts && state.customerProducts.customerProductsList,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerProductsList: (tableParams) => dispatch(getCustomerProductsList(tableParams)),
  deleteCustomerProduct: (customerProductId) => dispatch(deleteCustomerProduct(customerProductId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerProductsList));
