import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';

import Table from '../../../../components/table/Table';

import {
  createLinkPrinter, deleteLinkPrinter, getPrinterList,
} from '../../../../app/store/actions/users';

class PrinterLinkModal extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            { this.hasLink(rows.row._original)
              ? (
                <button
                  type="button"
                  className="btn btn-default btn-xs"
                  onClick={() => this.removePrinterLink(rows.row._original)}
                >
                  {I18n.t('BEE608' /* Desvincular */)}
                </button>
              )
              : (
                <button
                  type="button"
                  className="btn btn-default btn-xs"
                  onClick={() => this.createPrinterLink(rows.row._original)}
                >
                  {I18n.t('BEE601' /* Vincular */)}
                </button>
              )}
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE322' /* Localização */),
        accessor: 'location',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      },
    ];

    this.state = {
      pages: 0,
      totalDataLength: 0,
      printersList: [],
    };

    this.defaultSorted = [
      {
        id: 'name',
        desc: false,
      },
    ];
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  tableTitle = () => {
    const { userSelected } = this.props;

    if (userSelected) {
      return `${I18n.t('BEE1' /* Usuário */)}: ${userSelected.code} - ${userSelected.name}`;
    }
    return I18n.t('BEE958' /* Erro ao buscar o usuário */);
  };

  hasLink = (printer) => {
    if (this.props.userSelected) {
      const userId = this.props.userSelected.id;
      if (printer.usersPrinter.length) {
        return printer.usersPrinter.find((r) => r.userId === userId);
      }
      return false;
    }
    return false;
  };

  getPrinterList = async () => {
    const {
      page, pageSize, filtered, sorted,
    } = this;

    const printersQuery = await this.props.getPrinterList({
      page, pageSize, filtered, sorted,
    });

    this.setState({
      printersList: printersQuery.rows,
      pages: printersQuery.pages,
      totalDataLength: printersQuery.totalDataLength,
    });
  };

  createPrinterLink = async (printer) => {
    const printerCode = printer.code;
    const { id, name, login } = this.props.userSelected;

    try {
      const resultado = await this.props.createLinkPrinter(id, printerCode);

      if (resultado.id) {
        addNotification(
          'success',
          I18n.t('BEE1' /* Usuário */),
          I18n.t('BEE959', {
            0: printer.code, 1: printer.name, 2: login, 3: name,
          } /* %{0} - %{1} vinculada ao usuário %{2} - %{3} com sucesso ! */),
          'top-right',
        );
        await this.getPrinterList();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1' /* Usuário */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification('danger', I18n.t('BEE1' /* Usuário */), I18n.t('BEE960', { 0: login } /* Erro ao incluir ao usuário %{0} ! */), 'top-right');
        }
      } else {
        addNotification('danger', I18n.t('BEE1' /* Usuário */), I18n.t('BEE960', { 0: login } /* Erro ao incluir ao usuário %{0} ! */), 'top-right');
      }
    }
  };

  removePrinterLink = async (printer) => {
    const { name, login } = this.props.userSelected;

    try {
      const userPrinters = this.hasLink(printer);
      if (userPrinters) {
        const resultado = await this.props.deleteLinkPrinter(userPrinters.id);

        if (resultado === 1) {
          addNotification(
            'success',
            I18n.t('BEE1' /* Usuário */),
            I18n.t('BEE961', {
              0: printer.code, 1: printer.name, 2: login, 3: name,
            } /* %{0} - %{1} desvinculado do usuário %{2} - %{3} com sucesso ! */),
            'top-right',
          );
          await this.getPrinterList();
        }
      } else {
        throw new Error(I18n.t('BEE962' /* Vínculo com o usuário não encontrado */));
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1' /* Usuário */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification('danger', I18n.t('BEE1' /* Usuário */), I18n.t('BEE960', { 0: login } /* Erro ao incluir ao usuário %{0} ! */), 'top-right');
        }
      } else {
        addNotification('danger', I18n.t('BEE1' /* Usuário */), I18n.t('BEE960', { 0: login } /* Erro ao incluir ao usuário %{0} ! */), 'top-right');
      }
    }
  };

  render() {
    const { showPrinterLinkModal } = this.props;

    const { pages, printersList, totalDataLength } = this.state;

    const title = I18n.t('BEE963' /* Vincular impressoras */);

    return (
      <Modal size="lg" isOpen={showPrinterLinkModal} toggle={() => this.props.closePrinterLinkModal()}>
        <ModalHeader toggle={() => this.props.closePrinterLinkModal()}>{title}</ModalHeader>
        <ModalBody>
          <Table
            headerTitle={this.tableTitle()}
            filterable
            data={printersList}
            totalDataLength={totalDataLength}
            columns={this.tableColumns}
            expander
            defaultPageSize={5}
            defaultSorted={this.defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            manual
            pages={pages}
            onFetchData={(state, instance) => {
              this.page = state.page;
              this.pageSize = state.pageSize;
              this.sorted = state.sorted;
              this.filtered = state.filtered;
              this.getPrinterList();
            }}
          />
        </ModalBody>
        <ModalFooter>
          <div className="row">
            <div className="col text-right">
              <OneClickButton
                className="btn btn-white btn-120 p-5 m-5"
                onClick={() => this.props.closePrinterLinkModal()}
              >
                {I18n.t('BEE137' /* Voltar */)}
              </OneClickButton>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getPrinterList: (tableParams) => dispatch(getPrinterList(tableParams)),
  createLinkPrinter: (userId, printerCode) => dispatch(createLinkPrinter(userId, printerCode)),
  deleteLinkPrinter: (userPrinterId) => dispatch(deleteLinkPrinter(userPrinterId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrinterLinkModal));
