import RestService from '../services/rest';

// GET
async function getCarrierDocks(carrierCode) {
  return RestService.getAuthenticated(`dockCarriers/carrierBase?carrierCode=${carrierCode}`);
}

async function getDockCarriers(dockCode) {
  return RestService.getAuthenticated(`dockCarriers/dockBase?dockCode=${dockCode}`);
}

// POST
async function searchDocks(searchParams) {
  return RestService.postAuthenticated('dockCarriers/searchDocks', searchParams);
}

async function searchCarriers(searchParams) {
  return RestService.postAuthenticated('dockCarriers/searchCarriers', searchParams);
}

async function linkDockCarrier(carrierCode, dockCode) {
  return RestService.postAuthenticated('dockCarriers/link', { carrierCode, dockCode })
}

async function unlinkDockCarrier(dockCarrierId) {
  return RestService.postAuthenticated('dockCarriers/unlink', {dockCarrierId})
}

export default {
  getCarrierDocks,
  getDockCarriers,
  searchDocks,
  searchCarriers,
  linkDockCarrier,
  unlinkDockCarrier,
};
