import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';

import OneClickButton from '../../../components/form/button';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../components/panel/panel';
import AddProductList from './addProductsList';
import { getInboundOrder } from '../../../app/store/actions/inboundOrders';
import {
  getBaseInfo, getSuppliers, createInboundOrder, updateInboundOrder,
} from '../../../app/store/actions/inboundOrderAdd';
import ROUTES from '../../../config/routes';
import addNotification from '../../../components/notification';
import Select from '../../../components/form/select';

class InboundOrderAdd extends React.Component {
  constructor(props) {
    super(props);

    const typeOptions = [
      { value: 'NFE', label: I18n.t('BEE999' /* NF-e */) },
    ];

    this.state = {
      activeTab: '1',
      inboundOrderId: null,
      branch: '',
      branches: [],
      supplier: null,
      supplierQuery: '',
      suppliers: [],
      suppliersOptions: [],
      orderType: 'NFE',
      orderNumber: '',
      serie: '1',
      accessKey: '',
      carrier: null,
      carriers: [],
      licensePlate: '',
      cfop: '',
      postalCode: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
      deliveryDate: '',
      expectedVolumes: 0,
      note: '',
      status: '',
      products: [],
      unitsMeasure: [],
      typeOptions,
      editing: false,
      showModalDelete: false,
    };
  }

  getEditInboundOrder = async (inboundOrderId) => {
    try {
      const inboundOrder = await this.props.getInboundOrder(inboundOrderId);

      const supplier = {
        code: inboundOrder.supplierCode,
        name: inboundOrder.supplierName,
      };
      const suppliersOptions = [supplier];

      const branch = {
        label: `${inboundOrder.branchCode} - ${inboundOrder.branchName}`,
        value: inboundOrder.branchCode,
      };

      const carrier = {
        code: inboundOrder.carrierCode,
        name: inboundOrder.carrierName,
      };

      const products = inboundOrder.products.map((p) => ({
        product: p.product,
        lineNumber: p.lineNumber,
        quantity: parseFloat(p.quantity),
        unitMeasure: p.unitMeasure,
        supplierProductCode: p.supplierProductCode,
        note: p.note,
      }));

      this.setState({
        editing: true,
        branchCode: inboundOrder.branchCode,
        inboundOrderId: inboundOrder.id,
        orderNumber: inboundOrder.orderNumber,
        orderType: inboundOrder.orderType,
        serie: inboundOrder.serie,
        accessKey: inboundOrder.accessKey,
        licensePlate: inboundOrder.licensePlate,
        cfop: inboundOrder.cfop,
        expectedVolumes: inboundOrder.expectedVolumes.replace('.', ','),
        deliveryDate: new Date(inboundOrder.deliveryDate),
        status: inboundOrder.status,
        branch,
        suppliersOptions,
        suppliers: suppliersOptions,
        supplier,
        carrier,
        products,
      });
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1000' /* Editar documento de entrada */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification('danger', I18n.t('BEE1000' /* Editar documento de entrada */), 'Erro ao Editar documento de entrada', 'top-right');
        }
      } else {
        addNotification('danger', I18n.t('BEE1000' /* Editar documento de entrada */), 'Erro ao Editar documento de entrada', 'top-right');
      }
    }
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled, keyDownFunction = undefined, id = null) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          id={id}
          onKeyDown={keyDownFunction && ((e) => keyDownFunction(e, id))}
          type={type}
          className="form-control m-b-5"
          value={value}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createTextArea = (value, attr, label, placeholder, rows, required, disabled, keyDownFunction = undefined, id = null) => (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <textarea
          id={id}
          onKeyDown={keyDownFunction && ((e) => keyDownFunction(e, id))}
          className="form-control"
          rows={rows}
          value={value}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  createSelectDropDown = (value, attr, label, items, id = null) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <Select
          id={id}
          value={value}
          onChange={(e) => this.setValue(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
        />
      </div>
    </div>
  );

  createSelect = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <select
          className="form-control"
          value={value}
          onChange={(e) => this.setValue(attr, e.target.value)}
        >
          {items.map((item) => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    </div>
  );

  createNumberFormat = (value, attr, label, decimals, keyDownFunction = undefined, id = null) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <NumberFormat
          onKeyDown={keyDownFunction && ((e) => keyDownFunction(e, id, attr))}
          id={id && id}
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={decimals}
          value={value || ''}
          onValueChange={(values) => {
            this.setValue(attr, values.floatValue);
          }}
          fixedDecimalScale
          defaultValue={0}
          allowEmptyFormatting
        />
      </div>
    </div>
  );

  createDateInput = (value, label, keyDownFunction = null, id = null) => {
    const customDateStyle = ({ className, children }) => (
      <div>
        <div className="bg-primary rounded p-2">
          <h5 className="text-light text-center pt-2">
            Bee
            <span className="text-dark">Stock</span>
          </h5>
        </div>
        <div className={className}>
          <div>{children}</div>
        </div>
      </div>
    );

    return (
      <div className="form-group row m-b-15" style={{ height: 40 }}>
        <label className="col-form-label col-md-4">{label}</label>
        <div className="col-md-0">
          <DatePicker
            id={id}
            selected={value}
            className="form-control"
            dateFormat="dd/MM/yyyy"
            value={value}
            onChange={(e) => this.setValue('deliveryDate', e)}
            calendarContainer={customDateStyle}
            customInput={(
              <InputMask
                className="form-control"
                type="text"
                mask="99/99/9999"
              />
            )}
          />
        </div>
      </div>
    );
  };

  setValue = (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  resetForm = () => {
    this.setState({
      activeTab: '1',
      mode: 'detail',
      branch: '',
      supplier: null,
      supplierQuery: '',
      suppliers: [],
      suppliersOptions: [],
      orderType: 'NFE',
      orderNumber: '',
      serie: '1',
      accessKey: '',
      carrier: '',
      licensePlate: '',
      cfop: '',
      postalCode: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
      deliveryDate: '',
      expectedVolumes: 0,
      note: '',
      status: '',
      products: [],
    });
  };

  goToElement = (e, id) => {
    e.keyCode === 13 && document.getElementById(id + 1).focus();
  };

  keyPressGetSuppliersList = (e) => {
    e.keyCode === 13 && this.getSuppliersList();
  };

  getSuppliersList = async () => {
    try {
      const { supplierQuery } = this.state;

      const suppliers = await this.props.getSuppliers(supplierQuery);

      if (suppliers.error) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), `${I18n.t('BEE981' /* Erro */)}: ${suppliers.error}`, 'top-right');
        return;
      }

      if (!suppliers.length) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1002' /* Nenhum fornecedor encontrado */), 'top-right');
        return;
      }

      const suppliersOptions = suppliers.map((s) => ({
        id: s.id,
        code: s.code,
        label: `${s.name} - ${s.cnpj}`,
      }));

      const supplier = suppliersOptions.length === 1 ? suppliersOptions[0] : null;

      this.setState({
        suppliers,
        suppliersOptions,
        supplier,
      });
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1001' /* Adicionar documento de entrada */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1003' /* Houve um erro ao buscar o fornecedor */), 'top-right');
        }
      } else {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1003' /* Houve um erro ao buscar o fornecedor */), 'top-right');
      }
    }
  };

  keyPressGoToProducts = (e) => {
    e.keyCode === 13 && this.toggleTab('2');
  };

  addProduct = (product) => {
    const productList = [...this.state.products];
    productList.push(product);
    this.setState({
      products: productList,
    });
  };

  updateProduct = (updProduct) => {
    const {
      note, quantity, supplierProductCode, unitMeasure,
    } = updProduct;
    const toUpdProduct = this.state.products[updProduct.lineNumber];

    toUpdProduct.note = note;
    toUpdProduct.quantity = quantity;
    toUpdProduct.supplierProductCode = supplierProductCode;
    toUpdProduct.unitMeasure = unitMeasure;
  };

  removeProduct = (productIndex) => {
    const productList = [...this.state.products];
    productList.splice(productIndex, 1);
    this.setState({
      products: productList,
    });
  };

  createInboundOrder = async () => {
    try {
      const {
        products, supplier, branch, orderType, orderNumber, serie, accessKey,
        carrier, licensePlate, cfop, deliveryDate, expectedVolumes, note,
      } = this.state;

      const supplierCode = supplier ? supplier.code : null;
      const branchCode = branch ? branch.value : null;
      const carrierCode = carrier ? carrier.value : null;

      const inboundOrder = {
        branchCode,
        supplierCode,
        orderNumber,
        orderType,
        serie,
        accessKey,
        carrierCode,
        licensePlate,
        expectedVolumes,
        status: 1,
        cfop,
        deliveryDate,
        note,
        products,
      };

      if (!orderNumber) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1004' /* É necessário informar o número do documento */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!branchCode) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1005' /* É necessário informar a filial */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!carrierCode) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1006' /* É necessário informar a transportadora */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!accessKey) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1013' /* É necessário informar a chave de acesso */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!cfop) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1014' /* É necessário informar a CFOP */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!serie) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1015' /* É necessário informar a série do documento */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (expectedVolumes <= 0) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1016' /* É necessário informar a quantidade de volumes */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!deliveryDate) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1017' /* É necessário informar a data da entrega */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!/^[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}$/.test(licensePlate)) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE969', { 0: licensePlate } /* A placa informada %{0} não é válida ! */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!supplierCode) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1018' /* É necessário informar o fornecedor */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!products.length) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1019' /* É necessário informar os produtos */), 'top-right');
        this.toggleTab('2');
        return;
      }

      const result = await this.props.createInboundOrder(inboundOrder);

      if (result.error) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1020' /* Erro ao criar documento de entrada */), 'top-right');
      } else {
        addNotification('success', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1021' /* Documento criado com sucesso ! */), 'top-right');
        this.props.history.push(ROUTES.INBOUND_ORDER_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1001' /* Adicionar documento de entrada */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1022' /* Houve um erro ao criar o documento de entrada */), 'top-right');
        }
      } else {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1022' /* Houve um erro ao criar o documento de entrada */), 'top-right');
      }
    }
  };

  updateInboundOrder = async () => {
    try {
      const currentOrder = this.props.location.state.inboundOrder;
      const {
        products, branch, orderType, orderNumber, serie, accessKey,
        licensePlate, cfop, deliveryDate, expectedVolumes, note,
      } = this.state;

      const inboundOrderId = currentOrder.id;
      const { supplierCode } = currentOrder;
      const branchCode = branch ? branch.value : null;
      const { carrierCode } = currentOrder;

      const inboundOrder = {
        branchCode,
        supplierCode,
        orderNumber,
        orderType,
        serie,
        accessKey,
        carrierCode,
        licensePlate,
        expectedVolumes,
        status: 1,
        cfop,
        deliveryDate,
        note,
        products,
      };

      if (!orderNumber) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1004' /* É necessário informar o número do documento */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!branchCode) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1005' /* É necessário informar a filial */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!carrierCode) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1006' /* É necessário informar a transportadora */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!accessKey) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1013' /* É necessário informar a chave de acesso */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!cfop) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1014' /* É necessário informar a CFOP */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!serie) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1015' /* É necessário informar a série do documento */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (expectedVolumes <= 0) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1016' /* É necessário informar a quantidade de volumes */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!deliveryDate) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1017' /* É necessário informar a data da entrega */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!/^[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}$/.test(licensePlate)) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE969', { 0: licensePlate } /* A placa informada %{0} não é válida ! */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!supplierCode) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1018' /* É necessário informar o fornecedor */), 'top-right');
        this.toggleTab('1');
        return;
      }

      if (!products.length) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1019' /* É necessário informar os produtos */), 'top-right');
        this.toggleTab('2');
        return;
      }

      const result = await this.props.updateInboundOrder(inboundOrderId, inboundOrder);

      if (result.error) {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1023' /* Erro ao atualizar documento de entrada */), 'top-right');
      } else {
        addNotification('success', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1024' /* Documento atualizado com sucesso ! */), 'top-right');
        this.props.history.push(ROUTES.INBOUND_ORDER_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1001' /* Adicionar documento de entrada */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1023' /* Erro ao atualizar documento de entrada */), 'top-right');
        }
      } else {
        addNotification('danger', I18n.t('BEE1001' /* Adicionar documento de entrada */), I18n.t('BEE1023' /* Erro ao atualizar documento de entrada */), 'top-right');
      }
    }
  };

  async componentWillMount() {
    const { unitsMeasure, branches, carriers } = await this.props.getBaseInfo();

    this.setState({
      unitsMeasure,
      branches,
      carriers,
    });

    if (this.props.location.state && this.props.location.state.inboundOrder) {
      await this.getEditInboundOrder(this.props.location.state.inboundOrder.id);
    }
  }

  render() {
    const {
      branch, supplier, orderType, carrier, orderNumber, accessKey, serie,
      licensePlate, cfop, deliveryDate, expectedVolumes, note, supplierQuery,
    } = this.state;

    const {
      editing, branches, products, suppliersOptions, typeOptions, carriers,
    } = this.state;

    const supplierSelected = this.state.supplier;
    const suppliersAvailable = !!suppliersOptions.length;

    const title = I18n.t('BEE1001' /* Adicionar documento de entrada */);

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE36' /* Recebimento */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1001' /* Adicionar documento de entrada */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form onSubmit={(e) => e.preventDefault()}>
              <Panel className="panel-with-tabs">
                <PanelHeader noButton />
                <PanelBody>

                  {supplier && (
                  <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '1' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('1');
                        }}
                      >
                        <span className="d-sm-none">Tab 1</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE443' /* Documento */)}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '2' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('2');
                        }}
                      >
                        <span className="d-sm-none">Tab 2</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE27' /* Produtos */)}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  )}

                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="row">
                        <div className="col-xl-12">
                          { editing
                            ? this.createInput(supplier.name, 'supplier.name', I18n.t('BEE342' /* Fornecedor */), '', 'text', true, true)
                            : suppliersAvailable
                              ? this.createSelectDropDown(supplier, 'supplier', `${I18n.t('BEE342' /* Fornecedor */)}:`, suppliersOptions, 1)
                              : this.createInput(supplierQuery, 'supplierQuery', `${I18n.t('BEE1025' /* Código, CNPJ ou Nome do Fornecedor */)}:`, I18n.t('BEE1026' /* Informe o código ou nome do fornecedor */), 'text', true, false, this.keyPressGetSuppliersList, 0)}
                          {supplierSelected && this.createSelectDropDown(branch, 'branch', `${I18n.t('BEE145' /* Filial */)}:`, branches, 2)}
                          {supplierSelected && this.createSelect(orderType, 'orderType', `${I18n.t('BEE200' /* Tipo */)}:`, typeOptions, this.goToElement, 3)}
                          {supplierSelected && this.createInput(orderNumber, 'orderNumber', `${I18n.t('BEE1027' /* Número do Documento */)}:`, '', 'text', true, false, this.goToElement, 4)}
                          {supplierSelected && this.createInput(serie, 'serie', `${I18n.t('BEE1028' /* Série do documento */)}:`, '', 'text', true, false, this.goToElement, 5)}
                          {supplierSelected && this.createInput(accessKey, 'accessKey', `${I18n.t('BEE967' /* Chave de acesso */)}:`, '', 'text', true, false, this.goToElement, 6)}
                          {editing
                            ? this.createInput(carrier.name, 'carrier.name', I18n.t('BEE301' /* Transportadora */), '', 'text', true, true)
                            : supplierSelected && this.createSelectDropDown(carrier, 'carrier', `${I18n.t('BEE301' /* Transportadora */)}:`, carriers, 7)}
                          {supplierSelected && this.createInput(licensePlate, 'licensePlate', `${I18n.t('BEE913' /* Placa */)}:`, '', 'text', true, false, this.goToElement, 8)}
                          {supplierSelected && this.createInput(cfop, 'cfop', `${I18n.t('BEE1030' /* CFOP */)}:`, '', 'text', true, false, this.goToElement, 9)}
                          {supplierSelected && this.createDateInput(deliveryDate, `${I18n.t('BEE444' /* Data de Entrega */)}:`, this.goToElement, 10)}
                          {supplierSelected && this.createNumberFormat(expectedVolumes, 'expectedVolumes', `${I18n.t('BEE1029' /* Volumes Esperados */)}:`, 0, this.goToElement, 11)}
                          {supplierSelected && this.createTextArea(note, 'note', `${I18n.t('BEE135' /* Observação */)}:`, I18n.t('BEE764' /* Informe a observação do produto */), 3, true, false, this.keyPressGoToProducts, 12)}
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="row">
                        <div className="col-xl-12">
                          <AddProductList
                            products={products}
                            addProduct={this.addProduct}
                            updateProduct={this.updateProduct}
                            removeProduct={this.removeProduct}
                            editing={editing}
                            unitsMeasure={this.state.unitsMeasure}
                          />
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </PanelBody>
                <PanelFooter>
                  <Link to={ROUTES.INBOUND_ORDER_LIST}>
                    <OneClickButton type="button" style={{ width: 60 }} className="btn btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>
                  </Link>
                  { !suppliersAvailable && <OneClickButton type="button" style={{ width: 180 }} className="btn btn-primary p-5 m-5" onClick={() => this.getSuppliersList()}>{I18n.t('BEE1031' /* Buscar fornecedor */)}</OneClickButton>}
                  { (suppliersAvailable && !editing && !supplier) && <OneClickButton type="button" style={{ width: 180 }} className="btn btn-white p-5 m-5" onClick={() => this.resetForm()}>{I18n.t('BEE1032' /* Refazer busca */)}</OneClickButton>}
                  { (suppliersAvailable && !editing && supplier) && <OneClickButton type="button" style={{ width: 180 }} className="btn btn-primary p-5 m-5" onClick={() => this.createInboundOrder()}>{I18n.t('BEE1033' /* Criar documento */)}</OneClickButton>}
                  { editing && <OneClickButton type="button" style={{ width: 180 }} className="btn btn-primary p-5 m-5" onClick={() => this.updateInboundOrder()}>{I18n.t('BEE1034' /* Salvar alterações */)}</OneClickButton>}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    data, isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getSuppliers: (supplierQuery) => dispatch(getSuppliers(supplierQuery)),
  getBaseInfo: () => dispatch(getBaseInfo()),
  createInboundOrder: (inboundOrder) => dispatch(createInboundOrder(inboundOrder)),
  getInboundOrder: (inboundOrderId) => dispatch(getInboundOrder(inboundOrderId)),
  updateInboundOrder: (inboundOrderId, inboundOrder) => dispatch(updateInboundOrder(inboundOrderId, inboundOrder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InboundOrderAdd));
