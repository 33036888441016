import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

import Table from '../../../components/table/Table';

export default class OrderTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableOrderColumns = [
      {
        Header: I18n.t('BEE436' /* Selecionar */),
        accessor: 'selected',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={this.props.checked[row.original.id] || false}
              onChange={() => this.handleSingleCheckboxChange(row.original.id, row.original.quantity)}
            />
          </div>
        ),
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1808' /* Pedido Cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE463' /* Linha */),
        accessor: 'lineNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      }, {
        Header: I18n.t('BEE482' /* Alocada */),
        accessor: 'allocatedQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      }, {
        Header: I18n.t('BEE1112' /* Mínimo */),
        accessor: 'minimumQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      }, {
        Header: I18n.t('BEE1114' /* Obrigatório */),
        accessor: 'mandatoryQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      },
    ];
  }

  handleSingleCheckboxChange = (outboundOrderId, quantity) => {
    const checkedCopy = this.props.checked;
    let { totalSelected } = this.props;

    checkedCopy[outboundOrderId] = !this.props.checked[outboundOrderId];
    checkedCopy[outboundOrderId] ? totalSelected += parseFloat(quantity) : totalSelected -= parseFloat(quantity);

    this.props.setValue('checkedOrders', checkedCopy);
    this.props.setValue('totalSelected', totalSelected);
  };

  checkAll = () => {
    const data = this.reactTable.getResolvedState().sortedData;

    const checked = [];
    let totalSelected = 0;

    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        checked[element._original.id] = true;

        totalSelected += parseFloat(element._original.quantity);
      }

      this.props.setValue('checkedOrders', checked);
      this.props.setValue('totalSelected', totalSelected);
    }
  };

  deselectAll = () => {
    this.props.setValue('checkedOrders', []);
    this.props.setValue('totalSelected', 0);
  };

  render() {
    const { outboundOrderList = [], outboundOrderProductSelect, totalSelected = 0 } = this.props;

    return (
      <div>
        <Table
          ref={(r) => {
            this.reactTable = r && r.reactTable;
          }}
          panelProps={{
            className: 'm-b-0',
          }}
          panelHeaderProps={{
            noButton: true,
            children: I18n.t('BEE462' /* Documentos */).toUpperCase(),
          }}
          filterable
          data={outboundOrderList}
          columns={this.tableOrderColumns}
          expander
          // defaultPageSize={5}
          showPagination={false}
          defaultPageSize={99}
          style={{
            height: '260px',
          }}
          className="-striped -highlight"
          // pageSizeOptions={[5, 10]}
          defaultSorted={[{ id: 'orderNumber', desc: false }]}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  this.props.setOutboundOrderProductSelect(rowInfo.original);
                },
                style: {
                  background: outboundOrderProductSelect && rowInfo.original.id === outboundOrderProductSelect.id ? 'silver' : 'white',
                  cursor: 'pointer',
                },
              };
            }
            return {};
          }}
        />
        <div className="row m-b-5">
          <div className="col-md-7">
            <button
              type="button"
              className="btn btn-120 btn-primary p-5 m-5"
              onClick={() => this.checkAll()}
            >
              {I18n.t('BEE1908' /* Marcar Todos */)}
            </button>
            <button
              type="button"
              className="btn btn-120 btn-primary p-5 m-5"
              onClick={() => this.deselectAll()}
            >
              {I18n.t('BEE1909' /* Desmarcar Todos */)}
            </button>
          </div>
          <div className="col-md-5">
            <div className="row">
              <label className="col-sm-3 col-form-label f-s-16 f-w-700 m-l-5">
                {I18n.t('BEE504' /* Total */)}
                :
                {' '}
              </label>
              <div className="col-sm-7">
                <input className="form-control text-center f-s-14" type="text" value={totalSelected} readOnly />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
