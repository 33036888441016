import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import 'react-table/react-table.css';
import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import { deleteLinkUser } from '../../../../app/store/actions/ranges';

class UsersRangesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      selectedUsersRange: null,
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE623' /* Login */),
        accessor: 'user.login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'user.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 75,
      }, {
        Header: I18n.t('BEE903' /* Filial Principal */),
        accessor: 'user.mainBranch',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      },
    ];

    this.actionColumn = {
      Header: I18n.t('BEE55' /* Ações */),
      accessor: 'action',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      width: 100,
      Cell: (rows) => (
        <div style={{ textAlign: 'center' }}>
          <button
            type="button"
            className="btn btn-default btn-xs"
            onClick={() => this.deleteUserLink(rows.row._original)}
          >
            {I18n.t('BEE608' /* Desvincular */)}

          </button>
        </div>
      ),
    };
  }

  deleteUserLink = (usersRange) => {
    this.setState({
      showModalDelete: true,
      selectedUsersRange: usersRange,
    });
  };

  confirmDeleteUserLink = async () => {
    const { code, name } = this.props.rangeSelected;
    const { user, id } = this.state.selectedUsersRange;

    try {
      const resultado = await this.props.deleteLinkUser(id);

      if (resultado === 1) {
        this.closeDeleteModal();
        addNotification(
          'success',
          I18n.t('BEE581' /* Range */),
          I18n.t('BEE809', {
            0: user.code, 1: user.name, 2: code, 3: name,
          } /* %{0} - %{1} desvinculado da Range %{2} - %{3} com sucesso! */),
          'top-right',
        );
        await this.props.getRangeSelected();
      } else {
        throw new Error(I18n.t('BEE808' /* Vínculo com a Range não encontrado */));
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE581' /* Range */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE581' /* Range */),
            I18n.t('BEE587', { 0: code } /* Erro ao incluir a Range ${0}! */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE581' /* Range */),
          I18n.t('BEE587', { 0: code } /* Erro ao incluir a Range ${0}! */),
          'top-right',
        );
      }
    }
  };

  closeDeleteModal = () => {
    this.setState({
      showModalDelete: false,
      selectedUsersRange: null,
    });
  };

  reloadTable = async () => {
    await this.props.getRangeSelected();
  };

  render() {
    const {
      usersRanges, title, showUserLinkBtn,
      openUserLinkModal, rangeSelected,
    } = this.props;

    const { showModalDelete, selectedUsersRange } = this.state;

    const columns = showUserLinkBtn
      ? [this.actionColumn, ...this.tableColumns]
      : this.tableColumns;
    return (
      <div className="table-responsive">
        <Table
          headerTitle={title}
          data={usersRanges}
          columns={columns}
          panelHeaderProps={{
            onClickReload: this.reloadTable,
          }}
          expander
          filterable
          defaultPageSize={this.props.lines ? this.props.lines : 10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          actionButtons={(
            showUserLinkBtn
            && (
              <div className="ml-auto">
                <button
                  type="button"
                  className="btn btn-240 btn-success btn-rounded pr-3"
                  onClick={() => openUserLinkModal()}
                >
                  <i className="fa fa-link mr-1" />
                  {I18n.t('BEE620' /* Vincular Usuários */)}
                </button>
              </div>
            )
          )}
        />
        {(showModalDelete
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.confirmDeleteUserLink()}
              onCancel={() => this.closeDeleteModal()}
            >
              {
                I18n.t('BEE625', { 0: selectedUsersRange.user.name, 1: rangeSelected.name })
                /* O usuário %{0} será descinvulado da Range %{1} */
              }
            </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showBulkImport: state.ranges.showBulkImport,
});

const mapDispatchToProps = (dispatch) => ({
  deleteLinkUser: (usersRangesId) => dispatch(deleteLinkUser(usersRangesId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersRangesTable));
