import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle,
  UncontrolledButtonDropdown, PopoverHeader, UncontrolledPopover,
} from 'reactstrap';

import addNotification from '../../../../components/notification';
import { generatePassword } from '../../../../components/password/generator';
import Table from '../../../../components/table/Table';
import ROUTES from '../../../../config/routes';
import UserHelpers from '../../../../helpers/users';

import {
  getUsersList, updateUserPassword, updateUserStatus,
} from '../../../../app/store/actions/users';

class UserList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlertActivate: false,
      showAlertInactivate: false,
      showAlertResetPasswordWarning: false,
      showAlertResetPassword: false,
      password: '',
      userUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'login',
        desc: false,
      },
    ];
    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 100,
        Cell: (rows) => {
          const { status } = rows.row;
          return (
            <div style={{ textAlign: 'center' }}>
              <button
                type="button"
                className="btn btn-default btn-xs"
              >
                {I18n.t('BEE55' /* Ações */)}
              </button>
              <UncontrolledButtonDropdown>
                <DropdownToggle color="default" caret className="btn-xs" />
                <DropdownMenu>
                  {((this.props.userLogged && this.props.userLogged.type <= 2)
                    || (this.props.userLogged && this.props.userLogged.type > 2
                      && this.props.userLogged.id === rows.original.id)) && (
                      <DropdownItem onClick={() => this.showUserDetail(rows.row)}>
                        {I18n.t('BEE56' /* Detalhes */)}
                      </DropdownItem>
                  )}
                  {(this.props.userLogged
                  && (this.props.userLogged.type <= 2 && this.validation(rows.original.type))) && (
                    <>
                      <DropdownItem onClick={() => this.showEditUser(rows.row)}>
                        {I18n.t('BEE57' /* Editar */)}
                      </DropdownItem>
                      <DropdownItem onClick={() => this.showCopyUser(rows.row)}>
                        {I18n.t('BEE80' /* Copiar */)}
                      </DropdownItem>
                      <DropdownItem divider />
                      {status ? (
                        this.props.userLogged.id !== rows.original.id && (
                          <DropdownItem onClick={() => this.showAlert(rows.row, 'inactivate')}>
                            {I18n.t('BEE2491' /* Desativar */)}
                          </DropdownItem>
                        )
                      ) : (
                        <DropdownItem onClick={() => this.showAlert(rows.row, 'activate')}>
                          {I18n.t('BEE914' /* Ativar */)}
                        </DropdownItem>
                      )}
                      <DropdownItem onClick={() => this.showAlert(rows.original, 'resetPasswordWarning')}>
                        {I18n.t('BEE2550' /* Resetar senha */)}
                      </DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE623' /* Login */),
        accessor: 'login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'type',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          const type = UserHelpers.userType(id);

          if (_.includes(_.lowerCase(type), input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {UserHelpers.userType(row.value)}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'mainBranch',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE247' /* Perfil de Acesso */),
        accessor: 'accessProfile',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE328' /* Impressora */),
        accessor: 'printer',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE897' /* Email */),
        accessor: 'email',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 230,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE896' /* Validade Senha */),
        accessor: 'passValid',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('DD/MM/YYYY')}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes(_.lowerCase(UserHelpers.userStatus(false)), input)) {
            return id === false;
          }
          if (_.includes(_.lowerCase(UserHelpers.userStatus(true)), input)) {
            return id === true;
          }
        },
        Cell: (row) => (
          <span>
            <i
              className="fa fa-circle f-s-7 fa-fw m-r-10 m-t-5"
              style={{
                color: row.value === false ? '#ff5b57'
                  : '#00acac',
                transition: 'all .3s ease',
              }}
            />
            {' '}
            {UserHelpers.userStatus(row.value)}
          </span>
        ),
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  validation = (type) => {
    if (type !== 4 && type !== 1) {
      return true;
    }
    if (this.props.userLogged.type === 1) {
      return true;
    }
    return false;
  };

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getUsers();
    } else {
      await this.getUsers();
    }
  };

  setInitState = () => {
    this.setState({
      showAlertActivate: false,
      showAlertInactivate: false,
      showAlertResetPasswordWarning: false,
      showAlertResetPassword: false,
      userUpdate: null,
    });
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      value={(filter && filter.value) || ''}
      onChange={(e) => onChange(e.target.value)}
      autoComplete="nope"
    />
  );

  getUsers = async () => {
    await this.props.getUsersList();
  };

  updatePassword = async (user) => {
    try {
      const password = generatePassword();
      const result = await this.props.updateUserPassword(user, password);
      this.setInitState();
      if (result) {
        this.showAlert(user, 'resetPassword', { password });
      }
    } catch (err) {
      this.setInitState();
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification(
          'danger',
          I18n.t('BEE920' /* Senha usuário */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE920' /* Senha usuário */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  updateStatus = async (user, type) => {
    const result = await this.props.updateUserStatus(user._original);

    if (type === 'inactivate') {
      this.setInitState();
      if (result) {
        addNotification(
          'danger',
          I18n.t('BEE924' /* Status usuário */),
          I18n.t('BEE2494', { 0: user.login } /* Usuário %{0} desativado com sucesso! */),
          'top-right',
        );
      }
    } else if (type === 'activate') {
      this.setInitState();
      if (result) {
        addNotification(
          'success',
          I18n.t('BEE924' /* Status usuário */),
          I18n.t('BEE925', { 0: user.login } /* Usuário %{0} ativado com sucesso! */),
          'top-right',
        );
      }
    }
    await this.getUsers();
  };

  showUserDetail = (user) => {
    this.props.history.push({
      pathname: ROUTES.USER_DETAIL,
      state: {
        user: user._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditUser = (user) => {
    this.props.history.push({
      pathname: ROUTES.USER_EDIT,
      state: {
        user: user._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyUser = (user) => {
    this.props.history.push({
      pathname: ROUTES.USER_COPY,
      state: {
        user: user._original,
      },
    });
  };

  showAlert = (user, type, opts = {}) => {
    if (type === 'inactivate') {
      this.setState({ showAlertInactivate: true, userUpdate: user, ...opts });
    } else if (type === 'activate') {
      this.setState({ showAlertActivate: true, userUpdate: user, ...opts });
    } else if (type === 'resetPasswordWarning') {
      this.setState({ showAlertResetPasswordWarning: true, userUpdate: user, ...opts });
    } else if (type === 'resetPassword') {
      this.setState({ showAlertResetPassword: true, userUpdate: user, ...opts });
    }
  };

  copyPassword = () => {
    // Navigator clipboard api precisa estar em um ambiente seguro (https)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(this.state.password);
    } else {
      // Se for http, será necessário realizar uma adaptação tecnica
      const textArea = document.createElement('textarea');
      textArea.value = this.state.password;

      // Move a textarea para um lugar não visivel
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  };

  displayPassword = () => (
    <div className="form-group p-2 mt-4">
      <label>{I18n.t('BEE2' /* Senha */)}</label>
      <div className="form-control">
        <div className="row">
          <div
            style={{ textAlign: 'initial' }}
            className="col-md-9"
          >
            {this.state.password}
          </div>
          <button
            id="ssssss"
            type="button"
            style={{ marginLeft: 'auto', backgroundColor: '#fff' }}
            onClick={this.copyPassword}
          >
            <i
              className="fas fa-copy fa-2x"
              style={{ lineHeight: '0.59em' }}
            />
            <UncontrolledPopover trigger="hover" placement="top" target="ssssss">
              <PopoverHeader>
                {I18n.t('BEE80' /* Copiar */)}
              </PopoverHeader>
            </UncontrolledPopover>
          </button>
        </div>
      </div>
    </div>
  );

  render() {
    const { usersList = [] } = this.props;

    const {
      userUpdate, showAlertActivate, showAlertInactivate,
      showAlertResetPasswordWarning, showAlertResetPassword,
      page,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE25' /* Usuários */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE25' /* Usuários */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          wikiHelp={ROUTES.USER_HELP}
          headerTitle={I18n.t('BEE25' /* Usuários */)}
          actionButtons={(
            (this.props.userLogged && this.props.userLogged.type) <= 2 && (
              <div className="ml-auto">
                <Link to={ROUTES.USER_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                  <i className="fa fa-plus mr-1" />
                  {' '}
                  {I18n.t('BEE354' /* Incluir Usuário */)}
                </Link>
              </div>
            )
          )}
          panelHeaderProps={{
            onClickReload: this.getUsers,
          }}
          filterable
          data={usersList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun
            && this.props.location.state && this.props.location.state.dice
            && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page1) => ((page1 >= 0) ? this.setState({ page: page1 }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />

        {showAlertResetPasswordWarning && (
          <SweetAlert
            warning
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="warning"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.updatePassword(userUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t(
              'BEE2552',
              { 0: userUpdate.login }, /* A senha do usuário %{0} sera redefinida, esta ação não pode ser desfeita */
            )}
          </SweetAlert>
        )}

        {showAlertResetPassword && (
          <SweetAlert
            success
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="primary"
            title={I18n.t('BEE2553' /* Senha alterada com sucesso */)}
            onConfirm={() => this.setInitState()}
          >
            {I18n.t('BEE2554', { 0: userUpdate.login } /* Senha do usuário %{0} foi alterada com sucesso */)}
            {this.displayPassword()}
          </SweetAlert>
        )}

        {showAlertActivate && (
          <SweetAlert
            success
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.updateStatus(userUpdate, 'activate')}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE955', { 0: userUpdate.login } /*   O usuário %{0} será ativado ! */)}
          </SweetAlert>
        )}

        {showAlertInactivate && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.updateStatus(userUpdate, 'inactivate')}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE2493', { 0: userUpdate.login } /* O usuário %{0} será desativado! */)}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUsersList: () => dispatch(getUsersList()),
  updateUserStatus: (user) => dispatch(updateUserStatus(user)),
  updateUserPassword: (user, newPass) => dispatch(updateUserPassword(user, newPass, true)),
});

const mapStateToProps = (state) => ({
  usersList: state.users && state.users.usersList,
  userLogged: state.app && state.app.userLogged,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserList));
