import { I18n } from 'react-redux-i18n';

function status(statusParam) {
  if (statusParam || statusParam === 0) {
    switch (statusParam) {
      case 1:
        return I18n.t('BEE733' /* Normal */);
      case 2:
        return I18n.t('BEE734' /* Excelente */);
      case 3:
        return I18n.t('BEE735' /* Reserva */);
      case 4:
        return I18n.t('BEE736' /* Picking */);
      case 5:
        return I18n.t('BEE177' /* Rejeitados */);
      case 6:
        return I18n.t('BEE573' /* Avaria */);
      case 7:
        return I18n.t('BEE2238' /* Falta */);
      case 8:
        return I18n.t('BEE3495' /* Stage Pré-Armazenagem */);
      case 9:
        return I18n.t('BEE3589' /* Stage de Separação */);
      default:
        return statusParam;
    }
  } else if (!statusParam) {
    return [
      { value: 1, label: I18n.t('BEE733' /* Normal */) },
      { value: 2, label: I18n.t('BEE734' /* Excelente */) },
      { value: 3, label: I18n.t('BEE735' /* Reserva */) },
      { value: 4, label: I18n.t('BEE736' /* Picking */) },
      { value: 5, label: I18n.t('BEE177' /* Rejeitados */) },
      { value: 6, label: I18n.t('BEE573' /* Avaria */) },
      { value: 7, label: I18n.t('BEE2238' /* Falta */) },
      { value: 8, label: I18n.t('BEE3495' /* Stage Pré-Armazenagem */) },
      { value: 9, label: I18n.t('BEE3589' /* Stage de Separação */) },
    ];
  }
}

export default {
  status,
};
