import RestService from '../services/rest';

// GET
async function getSuppliers(tableParams) {
  return RestService.postAuthenticated('suppliers/list/all', tableParams);
}

async function getSupplier(supplierId) {
  return RestService.getAuthenticated(`suppliers/detail?supplierId=${supplierId}`);
}

async function getSuppliersOptions() {
  return RestService.getAuthenticated('suppliers/list/options');
}

// POST
async function createSupplier(supplier) {
  return RestService.postAuthenticated('suppliers/create', supplier);
}

async function deleteSupplier(supplierId) {
  return RestService.postAuthenticated('suppliers/delete', { supplierId });
}

// PUT
async function updateSupplier(supplier) {
  return RestService.putAuthenticated('suppliers/update', supplier);
}

export default {
  getSuppliers,
  createSupplier,
  updateSupplier,
  getSupplier,
  deleteSupplier,
  getSuppliersOptions,
};
