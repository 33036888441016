import RestService from '../services/rest';

async function getManualRequestOptions() {
  return RestService.getAuthenticated('manualRequest/list/options');
}

async function getManualRequestOptionsProducts(productFilter) {
  return RestService.getAuthenticated('manualRequest/list/options/products', {}, {}, { params: { productFilter } });
}

async function createManualRequest(newManualRequest) {
  return RestService.postAuthenticated('manualRequest/create', newManualRequest);
}

export default {
  createManualRequest,
  getManualRequestOptions,
  getManualRequestOptionsProducts,
};
