import RestService from '../services/rest';

// GET
async function getCapacities() {
  return RestService.getAuthenticated('capacities/list/all', {}, {}, {});
}

async function getCapacity(capacityId) {
  return RestService.getAuthenticated(
    'capacities/detail',
    {},
    {},
    { params: { capacityId } },
  );
}

async function getCapacityOptions() {
  return RestService.getAuthenticated('capacities/list/options');
}

async function getCapacityOptionsProducts(productFilter) {
  return RestService.getAuthenticated('capacities/list/options/products', {}, {}, { params: { productFilter } });
}

async function createCapacity(capacity) {
  return RestService.postAuthenticated('capacities/create', capacity);
}

async function updateCapacity(capacity) {
  return RestService.putAuthenticated('capacities/update', capacity);
}

async function deleteCapacity(capacityId) {
  return RestService.postAuthenticated('capacities/delete', { capacityId });
}

async function bulkImportCapacity(jsonCsv) {
  return RestService.postAuthenticated('capacities/bulkImportCapacity', { jsonCsv });
}

export default {
  getCapacities,
  getCapacity,
  getCapacityOptions,
  getCapacityOptionsProducts,
  createCapacity,
  updateCapacity,
  deleteCapacity,
  bulkImportCapacity,
};
