import SchedulesRequests from '../../server/schedules';
import { addLoading, removeLoading } from './loading';

export const setSchedules = schedules => ({
  type: 'SET_SCHEDULES',
  schedules,
});

export const getSchedulesList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const schedules = await SchedulesRequests.getSchedules();
    dispatch(setSchedules(schedules));
  } finally {
    dispatch(removeLoading());
  }
};

export const createSchedule = schedule => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newSchedule = await SchedulesRequests.createSchedule(schedule);
    return newSchedule;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateSchedule = (schedule) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SchedulesRequests.updateSchedule(schedule);
  } finally {
    dispatch(removeLoading());
  }
}

export const getSchedule = (scheduleId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SchedulesRequests.getSchedule(scheduleId);
  } finally {
    dispatch(removeLoading());
  }
}

export const getSchedulesOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SchedulesRequests.getSchedulesOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteSchedule = (scheduleId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SchedulesRequests.deleteSchedule(scheduleId);
  } finally {
    dispatch(removeLoading());
  }
}