import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { PanelPage } from '../../components/pages/pages';
import OneClickButton from '../../components/form/button';
import DualList from '../../components/pages/DualList';
import Form from '../../components/form/form';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportAdminProductEan extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          { I18n.t('BEE12' /* Início */)}
        </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE27' /* Produtos */) },
      { value: I18n.t('BEE377' /* EAN */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE378' /* Código do Produto */),
        value: 'productCode',
      }, {
        label: I18n.t('BEE377' /* EAN */),
        value: 'ean',
      }, {
        label: I18n.t('BEE200' /* Tipo */),
        value: 'type',
      }, {
        label: I18n.t('BEE379' /* Principal */),
        value: 'main',
      }, {
        label: I18n.t('BEE135' /* Observação */),
        value: 'note',
      }, {
        label: I18n.t('BEE575' /* Usuário de criação */),
        value: 'createdUser',
      }, {
        label: I18n.t('BEE578' /* Data de criação */),
        value: 'createdAt',
      }, {
        label: I18n.t('BEE576' /* Usuário de alteração */),
        value: 'updatedUser',
      }, {
        label: I18n.t('BEE577' /* Data de alteração */),
        value: 'updatedAt',
      },
    ];

    this.state = {
      selected: [],
      productCodeFrom: '',
      productCodeTo: '',
      eanFrom: '',
      eanTo: '',
      typeFrom: '',
      typeTo: '',
    };
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      productCodeFrom,
      productCodeTo,
      eanFrom,
      eanTo,
      typeFrom,
      typeTo,
    } = this.state;
    const { location } = this.props;

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['$productCode', {
        type: 'and',
        value: productCodeTo.length,
        functionOnColumn: { name: 'length', type: 'lte' },
        instance: 'ProductEan',
      }],
      ['ean', { type: 'between', value: [eanFrom, eanTo] }],
      ['$ean', {
        type: 'and', value: eanTo.length, functionOnColumn: { name: 'length', type: 'lte' }, instance: 'ProductEan',
      }],
      ['type', { type: 'between', value: [typeFrom, typeTo] }],
    ]);

    this.props.generateReport(I18n.t('BEE377' /* EAN */).toLowerCase(), 'xlsx', location.pathname, headers, filters);
  };

  /*  onGeneratePdf = () => {
    const {
      selected,
      productCodeFrom,
      productCodeTo,
      eanFrom,
      eanTo,
      typeFrom,
      typeTo,
    } = this.state;
    const { location } = this.props;

    const headers = selected.map(it => ({ ...(this.options.find(itb => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['$productCode', { type: 'and', value: productCodeTo.length, functionOnColumn: { name: 'length', type: 'lte' }, instance: 'ProductEan' }],
      ['ean', { type: 'between', value: [eanFrom, eanTo] }],
      ['$ean', { type: 'and', value: eanTo.length, functionOnColumn: { name: 'length', type: 'lte' }, instance: 'ProductEan' }],
      ['type', { type: 'between', value: [typeFrom, typeTo] }],
    ]);

    this.props.generateReport(I18n.t('BEE377'  EAN ).toLowerCase(), 'pdf', location.pathname, headers, filters);
  } */

  onSelectFilter = (newState) => this.setState({ ...newState });

  render() {
    const {
      selected,
      productCodeFrom,
      productCodeTo,
      eanFrom,
      eanTo,
      typeFrom,
      typeTo,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE377' /* EAN */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE378' /* Código do Produto */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: eanFrom, to: eanTo },
                    { from: 'eanFrom', to: 'eanTo' },
                    `${I18n.t('BEE377' /* EAN */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: typeFrom, to: typeTo },
                    { from: 'typeFrom', to: 'typeTo' },
                    `${I18n.t('BEE200' /* Tipo */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <>
            <OneClickButton
              type="button"
              style={{ width: 'auto', padding: 10 }}
              className="btn btn-primary p-5 m-5"
              onClick={this.onGenerateSpreadsheet}
            >
              {I18n.t('BEE544' /* Gerar Planilha */)}
            </OneClickButton>
            {/*  <button
              type="button"
              style={{ width: 'auto', padding: 10 }}
              className="btn btn-secondary p-5 m-5"
              onClick={this.onGeneratePdf}
            >
              {I18n.t('BEE545'  Gerar PDF )}
            </button> */}
          </>
        )}
      />
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (nameDoc, type, route, columns, filters) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportAdminProductEan);
