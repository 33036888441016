import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import Table from '../../../components/table/Table';
import InventorySheetLabelLogHelpers from '../../../helpers/inventorySheetSerialLog';
import { getListSheetSerialLogs } from '../../../app/store/actions/inventorySheetSerialLog';
import addNotification from '../../../components/notification';
import ROUTES from '../../../config/routes';

class InventoryMonitorSerialLogList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      pages: 1,
      defaultPageSize: 10,
      totalDataLength: 0,
      dataList: [],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
    };

    this.defaultSorted = [{
      id: 'inventory.grouperCode',
      desc: true,
    }];

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE572' /* Inventário */) },
      { value: I18n.t('BEE3615' /* Monitor de Logs - Inventário de Etiquetas */), active: true },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE572' /* Inventário */),
        accessor: 'inventory.grouperCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'inventory.branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE267' /* Endereço */),
        accessor: 'inventorySheet.addressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE378' /* Código do Produto */),
        accessor: 'inventorySheet.productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1043' /* Nome do Produto */),
        accessor: 'inventorySheet.product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2764' /* Etiqueta Agrupadora */),
        accessor: 'serialGroup.code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE2760' /* Etiqueta Seriada */),
        accessor: 'serialLabel.code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'type',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{InventorySheetLabelLogHelpers.inventorySheetSerialLog(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === row.status.toString()) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%', textAlign: 'center' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {InventorySheetLabelLogHelpers.inventorySheetSerialLog().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      },
      {
        Header: I18n.t('BEE2761' /* Mensagem */),
        accessor: 'message',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE2288' /* Usuário Contagem */),
        accessor: 'userCreated.login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE578' /* Data Criação */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
        Cell: (row) => (
          <div>
            <span>
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }];
  }

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onChange={(event) => {
        if (event.target.value === '') {
          onChange(event.target.value);
        }
      }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getList = async () => {
    const { startDate, endDate } = this.state;

    try {
      const dataList = await this.props.getListSheetSerialLogs(
        moment(startDate).format(),
        moment(endDate).format(),
        this.page,
        this.pageSize,
        this.sorted,
        this.filtered,
      );

      if (dataList.rows) {
        const pages = Math.ceil(dataList.count / this.pageSize);
        this.setState({
          dataList: [...dataList.rows],
          totalDataLength: dataList.count,
          pages,
        });
      } else this.setState({ dataList: [] });
    } catch (err) {
      this.setState({ dataList: [] });
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification(
          'danger',
          I18n.t('BEE3615' /* Monitor de Logs - Inventário de Etiquetas */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE3615' /* Monitor de Logs - Inventário de Etiquetas */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });
    this.getList();
  };

  render() {
    const {
      defaultPageSize, dataList, totalDataLength, pages, bolTableGroup,
    } = this.state;

    return (
      <Table
        expander
        filterable
        downloadCSV
        manual
        downButtonPosition
        data={dataList}
        columns={bolTableGroup ? this.tableColumns2 : this.tableColumns}
        defaultSorted={this.defaultSorted}
        defaultPageSize={defaultPageSize}
        panelHeaderProps={{
          onClickReload: this.getList,
        }}
        totalDataLength={totalDataLength}
        pages={pages}
        wikiHelp={ROUTES.MOVEMENT_INVENTORY_MONITOR_HELP}
        datePicker={{
          handleDateApplyEvent: this.handleDateApplyEvent,
        }}
        breadcrumb={this.breadcrumb}
        headerTitle={I18n.t('BEE3615' /* Monitor de Logs - Inventário de Etiquetas */)}
        onFetchData={(state) => {
          this.page = state.page;
          this.pageSize = state.pageSize;
          this.sorted = state.sorted;
          this.filtered = state.filtered;
          this.getList();
        }}
        defaultFilterMethod={(filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row[filter.id]);
          if (_.includes(value, input)) {
            return true;
          }
        }}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getListSheetSerialLogs: (filterStartDate, filterEndDate, page, pageSize, sorted, filtered) => dispatch(
    getListSheetSerialLogs(
      filterStartDate,
      filterEndDate,
      page,
      pageSize,
      sorted,
      filtered,
    ),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventoryMonitorSerialLogList));
