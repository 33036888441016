import RestService from '../services/rest';

// GET
async function getIndirectTask(indirectTaskId) {
  return RestService.getAuthenticated(`indirectTasks/detail?indirectTaskId=${indirectTaskId}`);
}

async function getIndirectTasks() {
  return RestService.getAuthenticated('indirectTasks/list/all');
}

async function getIndirectTasksOptions() {
  return RestService.getAuthenticated('indirectTasks/list/options');
}

// POST
async function createIndirectTask(indirectTask) {
  return RestService.postAuthenticated('indirectTasks/create', indirectTask);
}

// PUT
async function updateIndirectTask(indirectTask) {
  return RestService.putAuthenticated('indirectTasks/update', indirectTask);
}

async function deleteIndirectTask(indirectTaskId) {
  return RestService.postAuthenticated('indirectTasks/delete', { indirectTaskId });
}

async function getUserList(tableParams) {
  return RestService.postAuthenticated('indirectTasks/linkUser/list', tableParams);
}

async function createLinkUser(userId, indirectTaskCode) {
  return RestService.postAuthenticated('indirectTasks/linkUser/create', { userId, indirectTaskCode });
}

async function deleteLinkUser(userIndirectTaskId) {
  return RestService.postAuthenticated('indirectTasks/linkUser/delete', { userIndirectTaskId });
}

export default {
  createIndirectTask,
  getIndirectTask,
  getIndirectTasks,
  getIndirectTasksOptions,
  updateIndirectTask,
  deleteIndirectTask,
  getUserList,
  createLinkUser, 
  deleteLinkUser
};
