import UnitsMeasureRequests from '../../server/unitsMeasure';
import { addLoading, removeLoading } from './loading';

export const setUnitsMeasure = (unitsMeasure) => ({
  type: 'SET_UNITS_MEASURE',
  unitsMeasure,
});

export const getUnitsMeasureList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const unitsMeasure = await UnitsMeasureRequests.getUnitsMeasure();
    dispatch(setUnitsMeasure(unitsMeasure));
  } finally {
    dispatch(removeLoading());
  }
};

export const createUnitMeasure = (unitMeasure) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newUnitMeasure = await UnitsMeasureRequests.createUnitMeasure(unitMeasure);
    return newUnitMeasure;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateUnitMeasure = (unitMeasure) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UnitsMeasureRequests.updateUnitMeasure(unitMeasure);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUnitMeasure = (unitMeasureId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UnitsMeasureRequests.getUnitMeasure(unitMeasureId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUnitsMeasureOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UnitsMeasureRequests.getUnitsMeasureOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteUnitMeasure = (unitMeasureId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UnitsMeasureRequests.deleteUnitMeasure(unitMeasureId);
  } finally {
    dispatch(removeLoading());
  }
};
