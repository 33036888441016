import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { getBranchesOptions } from '../../../app/store/actions/branches';
import { getInventoryOptions, getInventorySheetCounting } from '../../../app/store/actions/inventory';
import OneClickButton from '../../../components/form/button';
import Form from '../../../components/form/form';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';
import UsersHelp from '../../../helpers/users';
import CountSheets from './countSheets';

class CountUserList extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE572' /* Inventário */) },
      { value: I18n.t('BEE2138' /* Usuário de Contagem */), active: true },
    ];

    this.state = {
      listUser: [],
      listInventorySheetUser: [],
      listInventorySheet: [],
      branchCode: null,
      branchName: '',
      grouperDisabled: true,
      grouperCode: [],
      selectedUser: {},
      showFilterModal: false,
      inventoryCount: 1,
      defaultPageSize: 1,
      pageSizeOptions: [1, 5, 10, 15, 20],
      defaultSorted: [{ id: 'userId', desc: false }],
      listBranch: [],
      listInventory: [],
      checkedAdd: {},
      checkedRemove: {},
    };

    this.tableColumns = [{
      Header: I18n.t('BEE83' /* Nome */),
      accessor: 'userName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
    }, {
      Header: I18n.t('BEE623' /* Login */),
      accessor: 'userLogin',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE200' /* Tipo */),
      accessor: 'userType',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{UsersHelp.userType(row.value)}</span>
        </div>
      ),
      filterMethod: (filter, row) => {
        if (parseInt(filter.value, 10) === parseInt(row.userType, 10)) {
          return true;
        }
      },
      Filter: ({ onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={
            (event) => { onChange(event.target.value); }
          }
        >
          <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
          {UsersHelp.listUserType().map((elem, index) => (
            <option value={elem.value} key={index}>{elem.label}</option>
          ))}
        </select>
      ),
    }];
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
    if (attr === 'branch') {
      await this.setState({
        grouperDisabled: !((value && value.value)),
        grouperCode: [],
        inventoryCount: 1,
      });
      if (value && value.value) this.getInventoryOptions();
    }
  };

  keypressFunctionConfirm = (e) => {
    if (e.keyCode === 13) { this.inventorySheetCounting(); }
  };

  clearData = () => {
    this.setState({
      inventoryCount: 1,
      listUser: [],
      listInventorySheet: [],
      listInventorySheetUser: [],
      branchCode: null,
      branchName: '',
      grouperDisabled: true,
      grouperCode: [],
      listBranch: [],
      listInventory: [],
    });
  };

  selectLine = (selectedUser) => {
    this.setState({
      selectedUser,
      listInventorySheetUser: selectedUser.inventorySheetUser,
    });
  };

  inventorySheetCounting = async () => {
    const {
      inventoryCount, grouperCode, branchCode,
    } = this.state;

    this.setState({
      showFilterModal: false,
      checkedAdd: {},
      checkedRemove: {},
    });

    try {
      const grouperCodes = grouperCode && grouperCode.length && grouperCode.map((group) => group.value);

      const sheetCounting = await this.props.getInventorySheetCounting(inventoryCount, grouperCodes, branchCode);

      if (sheetCounting && sheetCounting.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2138' /* Usuário de Contagem */),
          I18n.t('BEE2140' /* Não foi possível localizar o inventario */),
          'top-right',
        );
        this.clearData();
      } else {
        this.setState({
          listUser: sheetCounting.users,
          listInventorySheet: sheetCounting.inventorySheets,
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2138' /* Usuário de Contagem */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2138' /* Usuário de Contagem */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
      this.clearData();
    }
    await this.checkSelectedUser();
  };

  checkSelectedUser = async () => {
    const { listUser, selectedUser } = this.state;

    if (!listUser.length) {
      this.setState({
        listUser: [],
        selectedUser: {},
      });
    } else if (selectedUser && selectedUser.userId) {
      const checkUser = listUser.find((element) => (element.userId === selectedUser.userId));
      if (!checkUser) {
        this.setState({
          listUser: [],
          selectedUser: {},
        });
      } else {
        this.setState({
          listInventorySheetUser: [...checkUser.inventorySheetUser],
          selectedUser: checkUser,
        });
      }
    }
  };

  getBranchesOptions = async () => {
    try {
      const listBranch = await this.props.getBranchesOptions();

      if (listBranch.length) {
        const branchUserMain = (this.props.userLogged && this.props.userLogged.mainBranch)
          ? listBranch.find((element) => element.value === this.props.userLogged.mainBranch)
          : null;

        const { branchCode, branchName, grouperCode } = this.state;

        const allStates = branchCode && branchName && grouperCode.length;
        const currentBranchCode = allStates ? branchCode : (branchUserMain && (branchUserMain.value)) || null;
        const currentBranchName = allStates ? branchName : (branchUserMain && (branchUserMain.label)) || '';
        const currentGrouperCode = allStates ? grouperCode : null;

        await this.setState({
          listBranch: [...listBranch],
          branchCode: currentBranchCode,
          branchName: currentBranchName,
          grouperDisabled: false,
          grouperCode: currentGrouperCode,
        });

        if (currentBranchCode) {
          await this.getInventoryOptions();
        }
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2138' /* Usuário de Contagem */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2138' /* Usuário de Contagem */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  getInventoryOptions = async () => {
    const { branchCode } = this.state;
    this.setState({ listInventory: [] });
    try {
      const listInventory = await this.props.getInventoryOptions(branchCode);
      if (listInventory.length) this.setState({ listInventory });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2138' /* Usuário de Contagem */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2138' /* Usuário de Contagem */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  filteringModal = () => (
    <Modal size="lg" isOpen={this.state.showFilterModal} toggle={() => this.setState({ showFilterModal: false })}>
      <ModalHeader
        toggle={() => this.setState({ showFilterModal: false })}
      >
        {I18n.t('BEE546' /* Filtros */)}
      </ModalHeader>
      <ModalBody onSubmit={(e) => e.preventDefault()}>
        <Form
          noPanel
          leftType
          setValue={this.setValue}
          setValueDrop={this.setValueDrop}
          inputs={(formContext) => ([
            formContext.createSelectDropDown(
              {
                value: this.state.branchCode,
                label: this.state.branchName,
              },
              'branch',
              `${I18n.t('BEE145' /* Filial */)}:`,
              this.state.listBranch,
              false,
              undefined,
              ['col-md-2', 'col-md-6'],
            ),
            formContext.createSelectDropDownMulti(
              this.state.grouperCode,
              `${I18n.t('BEE1516' /* Agrupador */)}:`,
              'grouperCode',
              this.state.listInventory,
              undefined,
              ['col-md-2', 'col-md-6'],
              this.state.grouperDisabled,
              this.keypressFunctionConfirm,
            ),
            formContext.createRadioButtons([{
              label: I18n.t('BEE2108', { 0: 1 } /* %{0} Contagem */),
              value: 1,
            }, {
              label: I18n.t('BEE2108', { 0: 2 } /* %{0} Contagem */),
              value: 2,
            }, {
              label: I18n.t('BEE2108', { 0: 3 } /* %{0} Contagem */),
              value: 3,
            },
            ], this.state.inventoryCount, 'inventoryCount', `${I18n.t('BEE1514' /* Contagem */)}:`),
          ])}
        />
      </ModalBody>
      <ModalFooter>
        <div className="row">
          <div className="col text-right">
            <button
              type="button"
              className="btn btn-white btn-120 p-5 m-5"
              onClick={() => {
                this.clearData();
                this.setState({ showFilterModal: false });
              }}
            >
              {I18n.t('BEE99' /* Cancelar */)}
            </button>
            <OneClickButton
              className="btn btn-primary btn-120 p-5 m-5"
              onClick={this.inventorySheetCounting}
            >
              {I18n.t('BEE407' /* Buscar */)}
            </OneClickButton>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );

  render() {
    const {
      defaultPageSize, defaultSorted, pageSizeOptions, selectedUser,
      grouperCode, inventoryCount, listUser, branchCode,
    } = this.state;
    let grouperText = '';

    if (grouperCode && grouperCode.length) {
      grouperCode.forEach((grouper) => {
        grouperText += (grouperText ? ', ' : '') + grouper.label;
      });
    }

    return (
      <>
        <Table
          expander
          filterable
          data={listUser}
          columns={this.tableColumns}
          breadcrumb={this.breadcrumb}
          headerTitle={I18n.t('BEE2138' /* Usuário de Contagem */)}
          wikiHelp={ROUTES.MOVEMENT_INVENTORY_COUNT_USER_HELP}
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          pageSizeOptions={pageSizeOptions}
          panelHeaderProps={{
            children: I18n.t('BEE1' /* Usuário */).toUpperCase(),
            noButton: true,
          }}
          buttonFilter={{
            actionButton: () => {
              this.setState({ showFilterModal: true });
              this.getBranchesOptions();
            },
          }}
          downButtonPosition
          actionButtons={(
            <h5 className="panel-title">
              <strong className="ml-4">
                {
                  `${grouperCode && grouperCode.length > 1
                    ? I18n.t('BEE3628' /* Agrupadores */)
                    : I18n.t('BEE1516' /* Agrupador */)}:`
                }
              </strong>
              &nbsp;
              {grouperText}
              <strong className="ml-4">
                {`${I18n.t('BEE1514' /* Contagem */)}:`}
              </strong>
              &nbsp;
              {`${inventoryCount}ª`}
              <strong className="ml-4">
                {`${I18n.t('BEE145' /* Filial */)}:`}
              </strong>
              &nbsp;
              {branchCode}
            </h5>
          )}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: () => (this.selectLine(rowInfo.original)),
                style: {
                  background: selectedUser && rowInfo.original.userId === selectedUser.userId ? 'silver' : 'white',
                  cursor: 'pointer',
                },
              };
            } return {};
          }}
        />
        <CountSheets
          listInventorySheet={this.state.listInventorySheet}
          listInventorySheetUser={this.state.listInventorySheetUser}
          inventorySheetCounting={this.inventorySheetCounting}
          inventoryCount={this.state.inventoryCount}
          userId={this.state.selectedUser && this.state.selectedUser.userId}
          checkedAdd={this.state.checkedAdd}
          checkedRemove={this.state.checkedRemove}
          setState={(data) => this.setState(data)}
        />
        {this.filteringModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getInventoryOptions: (branchCode) => dispatch(getInventoryOptions(branchCode, 5)),
  getInventorySheetCounting: (inventoryCount, grouperCode, branchCode) => dispatch(
    getInventorySheetCounting(inventoryCount, grouperCode, branchCode),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CountUserList));
