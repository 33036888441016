const INITIAL_STATE = {
  customerProductsList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_CUSTOMER_PRODUCTS': {
      return { ...state, customerProductsList: action.customerProducts };
    }
    default:
      return state;
  }
};
