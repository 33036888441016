import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';

import {
  Modal,
  ModalBody, ModalFooter,
  ModalHeader,
} from 'reactstrap';
import OneClickButton from '../../../../components/form/button';
import Form from '../../../../components/form/form';
import {
  Panel,
  PanelBody, PanelFooter,
  PanelHeader,
} from '../../../../components/panel/panel';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getBranchesOptions } from '../../../../app/store/actions/branches';
import { persistBlockList, previewBlockList } from '../../../../app/store/actions/storageAddresses';

class MassBlockList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fromStreet: '',
      toStreet: '',
      fromSector: '',
      toSector: '',
      fromLevel: '',
      toLevel: '',
      fromDrawer: '',
      toDrawer: '',
      fromColumn: '',
      toColumn: '',
      previewList: [],
      listBranch: [],
      branchName: '',
      branch: null,
      showModalConfirm: false,
      showModalPreview: false,
      willBlock: 'true',
      listOperations: [
        { value: true, label: I18n.t('BEE775' /* Bloquear vários endereços */) },
        { value: false, label: I18n.t('BEE776' /* Desbloquear vários endereços */) },
      ],
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE279' /* Setor */),
        accessor: 'sector',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE121' /* Rua */),
        accessor: 'street',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE281' /* Coluna */),
        accessor: 'column',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE283' /* Nível */),
        accessor: 'level',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE285' /* Gaveta */),
        accessor: 'drawer',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE207' /* Bloqueado */),
        accessor: 'blocked',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE385' /* Tamanho */),
        accessor: 'size',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE386' /* Altura */),
        accessor: 'height',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE387' /* Largura */),
        accessor: 'width',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE388' /* Comprimento */),
        accessor: 'length',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE727' /* Capacidade (Volume) */),
        accessor: 'capacityVolume',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE726' /* Capacidade (Peso) */),
        accessor: 'capacityWeight',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      },
    ];
  }

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 mt-4">
      <label>{label}</label>
      <div className="col">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  buildFilters = (filterParams) => {
    const {
      fromSector, fromStreet, fromColumn, fromLevel, fromDrawer,
      toSector, toStreet, toColumn, toLevel, toDrawer, willBlock, branch,
    } = filterParams;

    const sector = fromSector || toSector !== 'Z'.repeat(15)
      ? { type: 'between', value: [fromSector.toLocaleUpperCase(), toSector.toLocaleUpperCase()] }
      : null;

    const street = fromStreet || toStreet !== 'Z'.repeat(15)
      ? { type: 'between', value: [fromStreet.toLocaleUpperCase(), toStreet.toLocaleUpperCase()] }
      : null;

    const column = fromColumn || toColumn !== 'Z'.repeat(15)
      ? { type: 'between', value: [fromColumn.toLocaleUpperCase(), toColumn.toLocaleUpperCase()] }
      : null;

    const level = fromLevel || toLevel !== 'Z'.repeat(15)
      ? { type: 'between', value: [fromLevel.toLocaleUpperCase(), toLevel.toLocaleUpperCase()] }
      : null;

    const drawer = fromDrawer || toDrawer !== 'Z'.repeat(15)
      ? { type: 'between', value: [fromDrawer.toLocaleUpperCase(), toDrawer.toLocaleUpperCase()] }
      : null;

    const branchCode = { type: 'eq', value: branch };

    const blocked = { type: 'eq', value: willBlock !== 'true' };

    return {
      ...(sector && { sector }),
      ...(street && { street }),
      ...(column && { column }),
      ...(level && { level }),
      ...(drawer && { drawer }),
      ...({ branchCode }),
      ...({ blocked }),
    };
  };

  getPreviewList = async () => {
    const {
      fromColumn, toColumn, fromDrawer, toDrawer, fromLevel, toLevel,
      fromStreet, toStreet, fromSector, toSector, branch, willBlock,
    } = this.state;

    const tableParams = {
      fromSector,
      fromStreet,
      fromColumn,
      fromLevel,
      fromDrawer,
      toSector,
      toStreet,
      toColumn,
      toLevel,
      toDrawer,
      branch,
      willBlock,
    };

    const filters = this.buildFilters(tableParams);

    try {
      const previewList = await this.props.previewBlockList(filters);

      if (previewList.length) {
        this.setState({
          previewList,
          showModalPreview: true,
        });
        if (previewList.length > 200) {
          addNotification(
            'danger',
            I18n.t('BEE270' /* Atualizar Endereço */),
            I18n.t('BEE718', { 0: previewList.length },
              /* Seus filtros geraram %{0} resultados!
              Apenas os 200 primeiros serão gravados se a operação for confirmada. */
            ),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE270' /* Atualizar Endereço */),
          I18n.t('BEE604' /* Nenhum endereço localizado com os filtros informados! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE270' /* Atualizar Endereço */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE270' /* Atualizar Endereço */),
            I18n.t('BEE590', { 0: '' } /* Erro atualizando a Range ${0}! */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE270' /* Atualizar Endereço */),
          I18n.t('BEE590', { 0: '' } /* Erro atualizando a Range ${0}! */),
          'top-right',
        );
      }
    }
  };

  persistBlockList = async () => {
    const {
      fromColumn, toColumn, fromDrawer, toDrawer, fromLevel, toLevel,
      fromStreet, toStreet, fromSector, toSector, branch, willBlock, note,
    } = this.state;

    const tableParams = {
      fromSector: fromSector.toLocaleUpperCase(),
      fromStreet: fromStreet.toLocaleUpperCase(),
      fromColumn: fromColumn.toLocaleUpperCase(),
      fromLevel: fromLevel.toLocaleUpperCase(),
      fromDrawer: fromDrawer.toLocaleUpperCase(),
      toSector: toSector.toLocaleUpperCase(),
      toStreet: toStreet.toLocaleUpperCase(),
      toColumn: toColumn.toLocaleUpperCase(),
      toLevel: toLevel.toLocaleUpperCase(),
      toDrawer: toDrawer.toLocaleUpperCase(),
      branch,
      willBlock,
    };

    const filters = this.buildFilters(tableParams);
    const blocked = willBlock === 'true';

    try {
      const persistList = await this.props.persistBlockList(blocked, filters, note);

      if (persistList) {
        addNotification(
          'success',
          I18n.t('BEE270' /* Atualizar Endereço */),
          I18n.t('BEE730' /* Endereços atualizados com sucesso! */),
          'top-right',
        );
        this.resetFilters();
      } else {
        addNotification(
          'danger',
          I18n.t('BEE270' /* Atualizar Endereço */),
          I18n.t('BEE604' /* Nenhum endereço localizado com os filtros informados! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE270' /* Atualizar Endereço */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE270' /* Atualizar Endereço */),
            I18n.t('BEE590', { 0: '' } /* Erro atualizando a Range ${0}! */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE270' /* Atualizar Endereço */),
          I18n.t('BEE590', { 0: '' } /* Erro atualizando a Range ${0}! */),
          'top-right',
        );
      }
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  resetFilters = () => {
    this.setState({
      fromStreet: '',
      toStreet: '',
      fromSector: '',
      toSector: '',
      fromLevel: '',
      toLevel: '',
      fromDrawer: '',
      toDrawer: '',
      fromColumn: '',
      toColumn: '',
      previewList: [],
      showModalPreview: false,
      showModalConfirm: false,
      note: '',
    });
  };

  componentDidMount() {
    this.getBranches();
  }

  getBranches = async () => {
    const listBranch = await this.props.getBranchesOptions();
    const branchCodeUser = await this.props.userLogged.mainBranch;
    const selectBranch = listBranch.find((element) => element.value === branchCodeUser);

    if (selectBranch) {
      this.setState({
        branch: selectBranch.value,
        branchName: selectBranch.label,
      });
    }
    this.setState({
      listBranch,
    });
  };

  render() {
    const {
      previewList, showModalConfirm, willBlock, note,
      fromColumn, toColumn, fromDrawer, toDrawer, fromLevel, toLevel,
      fromSector, toSector, fromStreet, toStreet, branch, listBranch, branchName,
      listOperations, showModalPreview,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE20' /* Endereços */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE728' /* Bloqueio Múltiplo */)}</li>
          </ol>
        </div>

        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE728' /* Bloqueio Múltiplo */)}
          </h1>
        </div>

        <div className="row">
          <div className="col-xl-12 text-right">
            <Panel>
              <PanelHeader noButton />
              <PanelBody>
                <Form
                  noPanel
                  leftType
                  setValue={this.setValue}
                  setValueDrop={this.setValueDrop}
                  inputs={(formContext) => ([
                    formContext.createSelect(
                      willBlock,
                      'willBlock',
                      `${I18n.t('BEE524' /* Operação */)}:`,
                      listOperations || [],
                    ),
                    formContext.createSelectDropDown(
                      { value: branch, label: branchName },
                      'branch',
                      `${I18n.t('BEE145' /* Filial */)}:`,
                      listBranch,
                    ),
                    formContext.createInputRange(
                      { from: fromSector.toLocaleUpperCase(), to: toSector.toLocaleUpperCase() },
                      { from: 'fromSector', to: 'toSector' },
                      `${I18n.t('BEE279' /* Setor */)}:`,
                      { from: '', to: 'Z'.repeat(15) },
                    ),
                    formContext.createInputRange(
                      { from: fromStreet.toLocaleUpperCase(), to: toStreet.toLocaleUpperCase() },
                      { from: 'fromStreet', to: 'toStreet' },
                      `${I18n.t('BEE121' /* Rua */)}:`,
                      { from: '', to: 'Z'.repeat(15) },
                    ),
                    formContext.createInputRange(
                      { from: fromColumn.toLocaleUpperCase(), to: toColumn.toLocaleUpperCase() },
                      { from: 'fromColumn', to: 'toColumn' },
                      `${I18n.t('BEE281' /* Coluna */)}:`,
                      { from: '', to: 'Z'.repeat(15) },
                    ),
                    formContext.createInputRange(
                      { from: fromLevel.toLocaleUpperCase(), to: toLevel.toLocaleUpperCase() },
                      { from: 'fromLevel', to: 'toLevel' },
                      `${I18n.t('BEE283' /* Nível */)}:`,
                      { from: '', to: 'Z'.repeat(15) },
                    ),
                    formContext.createInputRange(
                      { from: fromDrawer.toLocaleUpperCase(), to: toDrawer.toLocaleUpperCase() },
                      { from: 'fromDrawer', to: 'toDrawer' },
                      `${I18n.t('BEE285' /* Gaveta */)}:`,
                      { from: '', to: 'Z'.repeat(15) },
                    ),
                  ])}
                />
              </PanelBody>
              <PanelFooter>
                <Link to={ROUTES.ADDRESS_LIST}>
                  <OneClickButton
                    type="submit"
                    className="btn btn-120 btn-white p-5 m-5"
                  >
                    {I18n.t('BEE99' /* Cancelar */)}
                  </OneClickButton>
                </Link>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-white p-5 m-5"
                  onClick={() => this.resetFilters()}
                >
                  {I18n.t('BEE605' /* Limpar Filtros */)}

                </OneClickButton>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={() => this.getPreviewList()}
                >
                  {I18n.t('BEE729' /* Simular Operação */)}

                </OneClickButton>
              </PanelFooter>
            </Panel>
          </div>
        </div>

        <Modal size="lg" isOpen={showModalPreview} toggle={() => this.resetFilters()}>
          <ModalHeader toggle={() => this.resetFilters()}>{I18n.t('BEE728' /* Bloqueio Múltiplo */)}</ModalHeader>
          <ModalBody>
            <Table
              filterable
              data={previewList}
              columns={this.tableColumns}
              defaultPageSize={5}
              defaultSorted={this.defaultSorted}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          </ModalBody>
          <ModalFooter>
            <div className="row">
              <div className="col text-right">
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-white p-5 m-5"
                  onClick={() => this.resetFilters()}
                >
                  {I18n.t('BEE605' /* Limpar Filtros */)}

                </OneClickButton>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-white p-5 m-5"
                  onClick={() => this.setState({ showModalPreview: false })}
                >
                  {I18n.t('BEE137' /* Voltar */)}

                </OneClickButton>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={() => this.setState({ showModalConfirm: true })}
                >
                  {I18n.t('BEE100' /* Confirmar */)}

                </OneClickButton>
              </div>
            </div>
          </ModalFooter>
        </Modal>

        {(showModalConfirm && (
        <SweetAlert
          danger
          showCancel
          cancelBtnText={I18n.t('BEE99')}
          confirmBtnText={I18n.t('BEE100')}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title={I18n.t('BEE101')}
          onConfirm={() => this.persistBlockList()}
          onCancel={() => this.setState({ showModalConfirm: false })}
        >
          <div>
            <span>
              {willBlock
                ? I18n.t('BEE723' /* Informe o motivo do bloqueio: */)
                : I18n.t('BEE725' /* Informe o motivo do desbloqueio: */)}

            </span>
            {this.createTextArea(
              note,
              'note',
              I18n.t('BEE724' /* Motivo */),
              willBlock
                ? I18n.t('BEE723' /* Informe o motivo do bloqueio: */)
                : I18n.t('BEE725' /* Informe o motivo do desbloqueio: */),
              3,
              true,
              false,
            )}
          </div>
        </SweetAlert>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  previewBlockList: (filters) => dispatch(previewBlockList(filters)),
  persistBlockList: (blocked, filters, note) => dispatch(persistBlockList(blocked, filters, note)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MassBlockList));
