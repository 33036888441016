import RestService from '../services/rest';

// GET
async function getWarehouses() {
  return RestService.getAuthenticated('warehouses/list/all');
}

async function getWarehouse(warehouseId) {
  return RestService.getAuthenticated(`warehouses/detail?warehouseId=${warehouseId}`);
}

async function getWarehousesOptions() {
  return RestService.getAuthenticated('warehouses/list/options');
}

// POST
async function createWarehouse(warehouse) {
  return RestService.postAuthenticated('warehouses/create', warehouse);
}

async function deleteWarehouse(warehouseId) {
  return RestService.postAuthenticated('warehouses/delete', { warehouseId });
}

// PUT
async function updateWarehouse(warehouse) {
  return RestService.putAuthenticated('warehouses/update', warehouse);
}

export default {
  getWarehouses,
  createWarehouse,
  updateWarehouse,
  getWarehouse,
  deleteWarehouse,
  getWarehousesOptions,
};
