import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  Modal,
  ModalHeader,
  ModalBody,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import Table from '../../../../components/table/Table';
import productionOrderHelpers from '../../../../helpers/productionOrder';
import ROUTES from '../../../../config/routes';
import { getListProductionOrders } from '../../../../app/store/actions/productionOrders';
import addNotification from '../../../../components/notification';

class ProductionOrder extends React.PureComponent {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      ProductionOrderCodeSelected: '',
      defaultPageSize: 10,
      totalDataLength: 0,
      pages: 0,
      listProductionOrderReserves: [],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
      openModalReserve: false,
      listProductionOrders: [],
    };

    this.defaultSorted = [{
      id: 'createdAt',
      desc: true,
    }];

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE2771' /* Indústria */) },
      { value: I18n.t('BEE2770' /* Ordem de Produção */), active: true },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            {
              <UncontrolledButtonDropdown>
                <DropdownToggle color="default" caret className="btn-xs" />
                <DropdownMenu>
                  {([1, 2, 3].includes(rows.original.status) && (
                    this.props.userLogged ? this.props.userLogged.mainBranchData.serialControlDeposit : false)
                  )
                    && (
                      <Link
                        to={{
                          pathname: ROUTES.INDUSTRY_GENERATE_LABEL_SERIAL,
                          state: {
                            productionOrderCode: rows.original.code,
                          },
                        }}
                        className="dropdown-item"
                      >
                        {I18n.t('BEE2777' /* Gerar etiquetas */)}
                      </Link>
                    )}
                  {
                    rows.original.productionOrderReserve.length > 0
                    && (
                      <button
                        type="button"
                        onClick={() => this.showModalReserve(rows.original)}
                        className="dropdown-item"
                      >
                        {I18n.t('BEE2889' /* Reserva da Ordem */)}
                      </button>
                    )
                  }
                  {
                    this.props.userLogged.mainBranchData.serialControlDeposit && (
                      <Link
                        to={{
                          pathname: ROUTES.INDUSTRY_PRINT_SERIAL_GROUP,
                          state: {
                            productionOrderCode: rows.original.code,
                          },
                        }}
                        className="dropdown-item"
                      >
                        {I18n.t('BEE3254' /* Imprimir Etiquetas Agrupadoras */)}
                      </Link>
                    )
                  }
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            }
          </div>
        ),
      },
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2770' /* Ordem de Produção */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE378' /* Código do Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1043' /* Nome do Produto */),
        accessor: 'product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1688' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Filter: this.filterColumn,
        Cell: (row) => (
          <span>
            {row.value ? Number(row.value) : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE384' /* Unidade */),
        accessor: 'product.unitMeasure',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{productionOrderHelpers.productionOrderStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === row.status.toString()) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%', textAlign: 'center' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {productionOrderHelpers.productionOrderStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE434' /* Data de Validade */),
        accessor: 'expirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE502' /* Referência */),
        accessor: 'reference',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE578' /* Data Criação */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }];

    this.tableProductColumns = [
      {
        Header: I18n.t('BEE463' /* Linha */),
        accessor: 'lineNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
      {
        Header: I18n.t('BEE378' /* Código do Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1043' /* Nome do Produto */),
        accessor: 'product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE1688' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Cell: (row) => (
          <span>
            {row.value ? Number(row.value) : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE384' /* Unidade */),
        accessor: 'product.unitMeasure',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2218' /* Quantidade Reservada */),
        accessor: 'reserved',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Cell: (row) => (
          <span>
            {row.value ? Number(row.value) : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }];
  }

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  showModalReserve = async (row) => {
    const orderReserveList = row.productionOrderReserve.sort((a, b) => a.lineNumber - b.lineNumber);
    this.setState({
      openModalReserve: true,
      listProductionOrderReserves: orderReserveList,
      ProductionOrderCodeSelected: row.code,
    });
  };

  getListProductionOrders = async () => {
    const { startDate, endDate } = this.state;
    try {
      const listProductionOrders = await this.props.getListProductionOrders(
        moment(startDate).format(),
        moment(endDate).format(),
        this.page,
        this.pageSize,
        this.sorted,
        this.filtered,
      );

      this.setState({
        pages: listProductionOrders.pages,
        totalDataLength: listProductionOrders.totalDataLength,
        listProductionOrders: listProductionOrders ? listProductionOrders.rows : [],
      });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;
      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2770' /* Ordem de Produção */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2770' /* Ordem de Produção */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  closeModalReserve = () => {
    this.setState({
      openModalReserve: false,
    });
  };

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });
    this.getListProductionOrders();
  };

  render() {
    const {
      defaultPageSize,
      listProductionOrderReserves,
      openModalReserve,
      ProductionOrderCodeSelected,
      pages,
      totalDataLength,
      listProductionOrders = [],
    } = this.state;

    return (
      <>
        <Table
          expander
          filterable
          downloadCSV
          downButtonPosition
          data={listProductionOrders}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          pages={pages}
          defaultSorted={this.defaultSorted}
          defaultPageSize={this.pageSize}
          breadcrumb={this.breadcrumb}
          headerTitle={I18n.t('BEE2770' /* Ordem de Produção */)}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          manual
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          panelHeaderProps={{
            onClickReload: this.getListProductionOrders,
          }}
          onFetchData={(state) => {
            this.page = state.page;
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;
            this.getListProductionOrders();
          }}
        />

        <Modal size="xl" isOpen={openModalReserve} toggle={this.closeModalReserve} className="adjustmentPadding">
          <ModalHeader toggle={this.closeModalReserve}>
            {`${I18n.t('BEE2889' /* Reserva da Ordem */).toUpperCase()} - ${ProductionOrderCodeSelected}`}
          </ModalHeader>
          <ModalBody>
            <Table
              expander
              filterable
              downloadCSV
              downButtonPosition
              data={listProductionOrderReserves}
              columns={this.tableProductColumns}
              defaultPageSize={defaultPageSize}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged,
  listProductionOrders: state.productionOrders && state.productionOrders.listProductionOrders,
});

const mapDispatchToProps = (dispatch) => ({
  getListProductionOrders: (filterStartDate, filterEndDate, page, pageSize, sorted, filtered) => dispatch(
    getListProductionOrders(filterStartDate, filterEndDate, page, pageSize, sorted, filtered),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductionOrder));
