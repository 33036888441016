import React from 'react';
import { I18n } from 'react-redux-i18n';
import 'react-table/react-table.css';
import FormatHelpers from '../../../helpers/format';

export default class ProductsList extends React.PureComponent {
  render() {
    const { outboundOrderCheckProductList } = this.props;
    const { isBlindConference } = outboundOrderCheckProductList[0];

    return (
      <div className="table-responsive">
        <table className="table table-bordered widget-table widget-table-rounded">
          <thead>
            <tr className="text-center f-s-12">
              <th width="5%">{I18n.t('BEE463' /* Linha */)}</th>
              <th width="35%">{I18n.t('BEE225' /* Produto */)}</th>
              {!isBlindConference && <th width="10%">{I18n.t('BEE441' /* Quantidade */)}</th>}
              <th width="10%">{I18n.t('BEE428' /* Lote */)}</th>
              <th width="15%">{I18n.t('BEE495' /* Endereço Retirada */)}</th>
              <th width="5%">{`${I18n.t('BEE445' /* Conferido */)} ?`}</th>
            </tr>
          </thead>
          <tbody>
            {outboundOrderCheckProductList.map((product, index) => {
              const textClass = product.status >= 5 ? ' text-teal' : '';
              return (
                <tr className="text-center" key={index}>
                  <td>
                    <h5 className={textClass}>{product.lineNumber}</h5>
                    <p>
                      {product.status === 4 && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.props.getSelectedProduct(product)}
                      >
                        <i className="fa fa-search" />
                      </button>
                      )}
                    </p>
                  </td>
                  <td className="text-left">
                    <h4 className={`widget-table-title${textClass}`}>{product.productCode}</h4>
                    <p className={`widget-table-desc m-b-15${textClass}`}>{product.productname}</p>
                    <p className={`widget-table-desc m-b-15${textClass}`}>{product.productFullName}</p>
                    <div className={`clearfix f-s-10${textClass}`}>
                      {`${I18n.t('BEE224' /* Status */)}:`}
                      <b className={textClass}>
                        {' '}
                        {product.status === 4 ? I18n.t('BEE470' /* Pendente */) : I18n.t('BEE445' /* Conferido */)}
                      </b>
                    </div>
                  </td>
                  {!isBlindConference && (
                  <td className={textClass}>
                    {FormatHelpers.formatNumber(product.pendingQuantity, 3, false)}
                  </td>
                  )}
                  <td className={textClass}>
                    {product.lotNumber}
                  </td>
                  <td className={textClass}>
                    {product.storageAddress}
                  </td>
                  <td>
                    {product.status >= 5
                      ? <i className="fas fa-check-circle fa-2x text-teal" />
                      : <i className="fas fa-times-circle fa-2x text-danger" />}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
