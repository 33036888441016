import OutboundWavesRequests from '../../server/outboundWaves';
import { addLoading, removeLoading } from './loading';

export const setWaveDocuments = (waveDocuments) => ({
  type: 'SET_WAVE_DOCUMENTS',
  waveDocuments,
});

export const getOutboundOrdersList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const ordersList = await OutboundWavesRequests.getOutboundOrdersList(tableParams);
    dispatch(removeLoading());
    return ordersList;
  } catch (e) {
    dispatch(removeLoading());
    return {
      pages: 0,
      rows: [],
    };
  }
};

export const generateWaves = (outboundOrderIds) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const wave = await OutboundWavesRequests.generateWaves(outboundOrderIds);
    dispatch(removeLoading());
    return wave;
  } catch (e) {
    dispatch(removeLoading());
    return null;
  }
};

export const getOpenWaveList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundWavesRequests.getOpenWaveList();
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundWavesByDate = (filterStartDate, filterEndDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundWavesRequests.getOutboundWavesByDate(filterStartDate, filterEndDate);
  } finally {
    dispatch(removeLoading());
  }
};

export const getWaveDocuments = (outboundWaveId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const waveDocuments = await OutboundWavesRequests.getWaveDocuments(outboundWaveId);
    dispatch(setWaveDocuments(waveDocuments));
  } finally {
    dispatch(removeLoading());
  }
};

export const addOrderToWave = (rangeCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const result = await OutboundWavesRequests.addOrderToWave(rangeCode);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const startSeparation = (outboundWaveId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundWavesRequests.startSeparation(outboundWaveId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundWavesProductsList = (outboundWaveId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundWavesRequests.getOutboundWavesProductsList(outboundWaveId);
  } finally {
    dispatch(removeLoading());
  }
};

export const outboundWavesProductEan = (ean, productCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundWavesRequests.outboundWavesProductEan(ean, productCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateWaveAndDocuments = (outboundRangeId, outboundWaveId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundWavesRequests.updateWaveAndDocuments(outboundRangeId, outboundWaveId);
  } finally {
    dispatch(removeLoading());
  }
};
