import RestService from '../services/rest';

// GET
async function getPackages() {
  return RestService.getAuthenticated('packages/list/all');
}

async function getPackage(packageId) {
  return RestService.getAuthenticated(`packages/detail?packageId=${packageId}`);
}

async function getPackagesOptionsWithProductEans(productCode) {
  return RestService.getAuthenticated('packages/getPackagesOptionsWithProductEans', { productCode });
}

// POST
async function createPackage(newPackage) {
  return RestService.postAuthenticated('packages/create', newPackage);
}

async function deletePackage(packageId) {
  return RestService.postAuthenticated('packages/delete', { packageId });
}

// PUT
async function updatePackage(updPackage) {
  return RestService.putAuthenticated('packages/update', updPackage);
}

export default {
  getPackages,
  createPackage,
  updatePackage,
  getPackage,
  deletePackage,
  getPackagesOptionsWithProductEans,
};
