import AddressSizeRequests from '../../server/storageAddressSizes';
import { addLoading, removeLoading } from './loading';

export const setStorageAddressSizes = storageAddressSize => ({
  type: 'SET_STORAGE_ADRESS_SIZE',
  storageAddressSize,
});

export const getStorageAddressSizesList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const addressSize = await AddressSizeRequests.getAddressSizes();
    dispatch(setStorageAddressSizes(addressSize));
  } finally {
    dispatch(removeLoading());
  }
};

export const createStorageAddressSize = newAddressSize => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newAddrSize = await AddressSizeRequests.createAddressSize(newAddressSize);
    return newAddrSize;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateStorageAddressSize = (updAddressSize) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AddressSizeRequests.updateAddressSize(updAddressSize);
  } finally {
    dispatch(removeLoading());
  }
};

export const getStorageAddressSize = (addressSizeId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AddressSizeRequests.getAddressSize(addressSizeId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteStorageAddressSize = (addressSizeId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await AddressSizeRequests.deleteAddressSize(addressSizeId);
  } finally {
    dispatch(removeLoading());
  }
};
