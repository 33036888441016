import DepositsRequests from '../../server/deposits';
import { addLoading, removeLoading } from './loading';

export const setDeposits = (deposits) => ({
  type: 'SET_DEPOSITS',
  deposits,
});

export const getDepositsList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const deposits = await DepositsRequests.getDeposits();
    dispatch(setDeposits(deposits));
  } finally {
    dispatch(removeLoading());
  }
};

export const createDeposit = (deposit) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newDeposit = await DepositsRequests.createDeposit(deposit);
    return newDeposit;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateDeposit = (deposit) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DepositsRequests.updateDeposit(deposit);
  } finally {
    dispatch(removeLoading());
  }
};

export const getDeposit = (depositId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DepositsRequests.getDeposit(depositId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteDeposit = (depositId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DepositsRequests.deleteDeposit(depositId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getDepositsOptions = (filterDeposits = 0) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DepositsRequests.getDepositsOptions(filterDeposits);
  } finally {
    dispatch(removeLoading());
  }
};

export const getDepositsOptionsList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DepositsRequests.getDepositsOptionsList();
  } finally {
    dispatch(removeLoading());
  }
};
