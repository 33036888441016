import { I18n } from 'react-redux-i18n';

function productStatus(status) {
  switch (status) {
    case 0:
      return I18n.t('BEE498' /* Obsoleto */);
    case 1:
      return I18n.t('BEE499' /* Ativo */);
    default:
      return status;
  }
}

function listProductStatus() {
  return [
    { value: 1, label: I18n.t('BEE499' /* Ativo */) },
    { value: 0, label: I18n.t('BEE498' /* Obsoleto */) },
  ];
}

function stockControlType(type) {
  if (type || type === 0) {
    switch (type) {
      case 1:
        return I18n.t('BEE500' /* Serial */);
      case 2:
        return I18n.t('BEE501' /* Número Série */);
      case 3:
        return I18n.t('BEE428' /* Lote */);
      case 4:
        return I18n.t('BEE502' /* Referência */);
      default:
        return type;
    }
  } else if (!type) {
    return [
      { value: 1, label: I18n.t('BEE500' /* Serial */) },
      { value: 2, label: I18n.t('BEE501' /* Número Série */) },
      { value: 3, label: I18n.t('BEE428' /* Lote */) },
      { value: 4, label: I18n.t('BEE502' /* Referência */) },
    ]
  }
}

function listStockControlType() {
  return [
    { value: 1, label: I18n.t('BEE500' /* Serial */) },
    { value: 2, label: I18n.t('BEE501' /* Número Série */) },
    { value: 3, label: I18n.t('BEE428' /* Lote */) },
    { value: 4, label: I18n.t('BEE502' /* Referência */) },
  ];
}

function productControlType(type) {
  if (type || type === 0) {
    switch (type) {
      case 1:
        return I18n.t('BEE503' /* Físico */);
      case 2:
        return I18n.t('BEE504' /* Total */);
      case 3:
        return I18n.t('BEE505' /* Consignado */);
      case 4:
        return I18n.t('BEE506' /* Débito Direto */);
      case 5:
        return I18n.t('BEE507' /* Específico */);
      default:
        return type;
    }
  } else if (!type) {
    return [
      { value: 1, label: I18n.t('BEE503' /* Físico */) },
      { value: 2, label: I18n.t('BEE504' /* Total */) },
      { value: 3, label: I18n.t('BEE505' /* Consignado */) },
      { value: 4, label: I18n.t('BEE506' /* Débito Direto */) },
      { value: 5, label: I18n.t('BEE507' /* Específico */) },
    ]
  }
}

function listProductControlType() {
  return [
    { value: 1, label: I18n.t('BEE503' /* Físico */) },
    { value: 2, label: I18n.t('BEE504' /* Total */) },
    { value: 3, label: I18n.t('BEE505' /* Consignado */) },
    { value: 4, label: I18n.t('BEE506' /* Débito Direto */) },
    { value: 5, label: I18n.t('BEE507' /* Específico */) },
  ];
}

function productOrigin(origin) {
  switch (origin) {
    case 1:
      return I18n.t('BEE508' /* Mercado Interno */);
    case 2:
      return I18n.t('BEE509' /* Mercado Externo */);
    default:
      return origin;
  }
}

function listProductOrigin() {
  return [
    { value: 1, label: I18n.t('BEE508' /* Mercado Interno */) },
    { value: 2, label: I18n.t('BEE509' /* Mercado Externo */) }
  ]
}

export default {
  productStatus,
  listProductStatus,
  productControlType,
  listProductControlType,
  stockControlType,
  listStockControlType,
  productOrigin,
  listProductOrigin,
}