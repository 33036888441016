/* eslint-disable no-undef */
/* eslint-disable max-len */
import moment from 'moment-timezone';
import { I18n } from 'react-redux-i18n';
import RestService from '../services/rest';
import ROUTES from '../../config/routes';
import addNotification from '../../components/notification';

const generateReport = async (nameDoc = '', typeDoc = 'pdf', route, columns, filters, reportType) => {
  if (reportType === 'bg') {
    const success = await RestService.postAuthenticated('reports/generateReport', {
      typeDoc, route, ROUTES, columns, filters, reportType, nameDoc,
    });

    if (success) {
      addNotification('success', I18n.t('BEE528' /* Relatório */), I18n.t('BEE2568' /* Relatório em processamento. Ao término uma notificação será emitida com o link para download */), 'top-right');
    } else {
      addNotification('danger', I18n.t('BEE528' /* Relatório */), I18n.t('BEE547', { 0: e.response ? e.response.status : 500 } /* Erro ao gerar relatório. Código: %{0} */), 'top-right');
    }

    return;
  }

  const response = await RestService.postAuthenticated('reports/generateReport', {
    typeDoc, route, ROUTES, columns, filters, reportType,
  }, {
    'Content-Type': 'application/json',
    Accept: `application/${typeDoc}`,
  }, { responseType: 'arraybuffer' });

  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  const filePath = `${`${nameDoc.normalize('NFD').replace(/[\u0300-\u036f]/g, '')}_${moment().toISOString()}`.replace(/[\s+\-.]/g, '_')}.${typeDoc}`;
  link.setAttribute('download', filePath);
  document.body.appendChild(link);
  link.click();
};

export default {
  generateReport,
};
