import RestService from '../services/rest';

const getAllAgents = async () => RestService.getAuthenticated('agent/getGrid');
const deleteAgent = async (code) => RestService.postAuthenticated('agent/destroy', { code });
const updateAgent = async (agent = {}) => RestService.postAuthenticated('agent/update', { ...agent });
const updateTokenAgent = async (agent = {}) => RestService.postAuthenticated('agent/updateToken', { ...agent });
const updateActiveAgent = async (agent = {}) => RestService.postAuthenticated('agent/updateActive', { ...agent });
const createAgent = async (agent = {}) => RestService.postAuthenticated('agent/create', { ...agent });
const getAgent = async (code) => RestService.getAuthenticated(
  'agent/get',
  { code, protocol: window.location.protocol },
);

export default {
  getAgent,
  getAllAgents,
  deleteAgent,
  updateAgent,
  updateTokenAgent,
  updateActiveAgent,
  createAgent,
};
