import RestService from '../services/rest';

// GET
async function getPriorities() {
  return RestService.getAuthenticated('priorities/list/all');
}

async function getPriority(priorityId) {
  return RestService.getAuthenticated(`priorities/detail?priorityId=${priorityId}`);
}

// POST
async function createPriority(priority) {
  return RestService.postAuthenticated('priorities/create', priority);
}

async function deletePriority(priorityId) {
  return RestService.postAuthenticated('priorities/delete', { priorityId });
}

// PUT
async function updatePriority(priority) {
  return RestService.putAuthenticated('priorities/update', priority);
}

export default {
  getPriorities,
  createPriority,
  updatePriority,
  getPriority,
  deletePriority,
};
