import React, { PureComponent } from 'react';
import { I18n } from 'react-redux-i18n';

class WizardStep extends PureComponent {
  render() {
    const {
      onClick, className, style, className2,
    } = this.props;

    return (
      <div className="nav-wizards-container">
        <nav className="nav nav-wizards-1 mb-2">
          <div className="nav-item col">
            <div
              role="button"
              tabIndex={0}
              style={{ cursor: 'pointer' }}
              onClick={onClick}
              onKeyDown={onClick}
              className={className}
            >
              <div className="nav-no">1</div>
              <div className="nav-text">{`${I18n.t('BEE2887' /* Etapa */)} 1`}</div>
            </div>
          </div>
          <div className="nav-item col" style={style}>
            <div className={className2}>
              <div className="nav-no">2</div>
              <div className="nav-text">{`${I18n.t('BEE2887' /* Etapa */)} 2`}</div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default WizardStep;
