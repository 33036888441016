import { I18n } from 'react-redux-i18n';

function modeTransport(mode) {
  switch (mode) {
    case 1:
      return I18n.t('BEE292' /* Rodoviário */);
    case 2:
      return I18n.t('BEE293' /* Aeroviário */);
    case 3:
      return I18n.t('BEE294' /* Marítimo */);
    case 4:
      return I18n.t('BEE295' /* Ferroviário */);
    case 5:
      return I18n.t('BEE296' /* Outros */);
    default:
      return mode;
  }
}

export default {
  modeTransport,
}