import Construe from 'cronstrue/i18n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import ROUTES from '../../../../config/routes';

import { executeJob, getJobsList, updateJob } from '../../../../app/store/actions/jobs';
import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

class JobsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.defaultSorted = [
      {
        id: 'name',
        desc: false,
      },
    ];

    this.draggableColumns = {
      mode: 'reorder',
      draggable: ['name', 'description', 'enabled'],
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showEditJob(rows.row && rows.row._original)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => this.enableOrDisable(rows.row && rows.row._original)}>
                  {rows.row.enabled ? I18n.t('BEE58' /* Inativar */) : I18n.t('BEE914' /* Ativar */)}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => this.executeJob(rows.row && rows.row._original)}>{I18n.t('BEE2211' /* Executar Job */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE1853' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE1897' /* Descrição */),
        accessor: 'description',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE2212' /* Agendamento */),
        accessor: 'description',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterable: false,
        sortable: false,
        Cell: (rows) => <span>{this.getScheduleText(rows.row && rows.row._original)}</span>,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'enabled',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <span>
            {
            row.value
              ? <span style={{ color: 'green', fontWeight: '700' }}>{I18n.t('BEE499' /* Ativo */)}</span>
              : <span style={{ color: 'red', fontWeight: '600' }}>{I18n.t('BEE1137' /* Inativo */)}</span>
          }

          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getJobs();
    } else {
      await this.getJobs();
    }
  };

  setInitState = () => {
    this.setState({});
  };

  getJobs = async () => {
    await this.props.getJobsList();
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  showEditJob = (job) => {
    this.props.history.push({
      pathname: ROUTES.JOBS_EDIT,
      state: {
        job,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  getScheduleText = (job) => {
    if (typeof job.cron === 'string') {
      return Construe.toString(job.cron, { locale: this.props.language.replace('-', '_') });
    }

    return '';
  };

  enableOrDisable = async (job) => {
    try {
      await this.props.updateJob({
        ...job,
        name: job.name,
        enabled: !job.enabled,
      });
      await this.getJobs();
      await addNotification('success', I18n.t('BEE2210' /* Jobs */), I18n.t('BEE2228' /* Job atualizado com sucesso! */), 'top-right');
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE2210' /* Jobs */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification('danger', I18n.t('BEE2210' /* Jobs */), I18n.t('BEE2226' /* Falha ao atualizar Job! */), 'top-right');
        }
      } else {
        await addNotification('danger', I18n.t('BEE2210' /* Jobs */), I18n.t('BEE2226' /* Falha ao atualizar Job! */), 'top-right');
      }
    }
  };

  executeJob = async (job) => {
    try {
      await this.props.executeJob(job.name);
      await addNotification('success', I18n.t('BEE2210' /* Jobs */), I18n.t('BEE2229' /* Job executado com sucesso! */), 'top-right');
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE2210' /* Jobs */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification('danger', I18n.t('BEE2210' /* Jobs */), I18n.t('BEE2230' /* Falha ao executar Job! */), 'top-right');
        }
      } else {
        await addNotification('danger', I18n.t('BEE2210' /* Jobs */), I18n.t('BEE2230' /* Falha ao executar Job! */), 'top-right');
      }
    }
  };

  render() {
    const { page } = this.state;
    const { jobsList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE2210' /* Jobs */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE2210' /* Jobs */)}
          wikiHelp={ROUTES.JOBS_HELP}
          panelHeaderProps={{
            onClickReload: this.getJobs,
          }}
          filterable
          data={jobsList}
          columns={this.tableColumns}
          draggableColumns={this.draggableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jobsList: state.jobs && state.jobs.jobsList,
  language: state.app.locale,
});

const mapDispatchToProps = (dispatch) => ({
  getJobsList: () => dispatch(getJobsList()),
  executeJob: (name) => dispatch(executeJob(name)),
  updateJob: (job) => dispatch(updateJob(job)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JobsList));
