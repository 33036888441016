import 'react-table/react-table.css';
import React from 'react';
import Table from '../../../../components/table/Table';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

export default class StorageAddressTable extends React.Component {
  constructor(props) {
    super(props)

    const tableColumns = [
      {
        Header: I18n.t('BEE82' /* Código */),
        columns: [
          {
            Header: I18n.t('BEE82' /* Código */),
            accessor: 'code',
            style: { alignSelf: 'center', textAlign: 'center' },
            minWidth: 75,
          }, {
            Header: I18n.t('BEE83' /* Nome */),
            accessor: 'name',
            style: { alignSelf: 'center', textAlign: 'center' },
            minWidth: 75,
          },
        ],
        isVisible: true

      }, {
        Header: I18n.t('BEE20' /* Endereços */),
        columns: [
          {
            Header: I18n.t('BEE279' /* Setor */),
            accessor: 'sector',
            style: { alignSelf: 'center', textAlign: 'center' },
            minWidth: 50,
          }, {
            Header: I18n.t('BEE121' /* Rua */),
            accessor: 'street',
            style: { alignSelf: 'center', textAlign: 'center' },
            minWidth: 50,
          }, {
            Header: I18n.t('BEE281' /* Coluna */),
            accessor: 'column',
            style: { alignSelf: 'center', textAlign: 'center' },
            minWidth: 50,
          }, {
            Header: I18n.t('BEE283' /* Nível */),
            accessor: 'level',
            style: { alignSelf: 'center', textAlign: 'center' },
            minWidth: 50,
          }, {
            Header: I18n.t('BEE285' /* Gaveta */),
            accessor: 'drawer',
            style: { alignSelf: 'center', textAlign: 'center' },
            minWidth: 50,
          }
        ],
        isVisible: true
      },
    ];

    const actionColumn = {
      Header: I18n.t('BEE55' /* Ações */),
      accessor: 'action',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      minWidth: 50,
      isVisible: true,
      Cell: rows => (
        <div style={{ textAlign: 'center' }}>
          <button
            className="btn btn-default btn-xs"
            onClick={() => this.createSelectAddressTableModal(rows.row._original, this.props.selectAddressTable)}
          // onClick={() => this.props.selectAddressTable(rows.row._original)}
          >{I18n.t('BEE138' /* Criar */)}</button>
        </div>
      )
    }

    this.state = {
      pages: 0,
      tableColumns,
      actionColumn,
      storageAddresses: this.props.storageAddresses,
    }
  }

  createSelectAddressTableModal = async (address, selectAddressTable) => {
    const { storageAddresses } = this.state;
    await selectAddressTable(address, storageAddresses);
    this.setState({ storageAddresses });
  }

  validColumnModalTableExpandeds = (expand, columns) => {
    columns.forEach((column) => {
      if (column.hide) {
        column.isVisible = expand;
      }
    });

    columns.splice(0, 1);
    this.setState({ tableColumns: columns });
  }

  render() {
    const {
      title,
      showAddressLinkBtn,
      validColumnModalTable,
      exportAddressList
    } = this.props,
      {
        storageAddresses,
        tableColumns,
        actionColumn
      } = this.state;

    const columns = showAddressLinkBtn
      ? [actionColumn, ...tableColumns]
      : tableColumns;

    return (
      <div className="table-responsive">
        <Table
          headerTitle={title}
          data={storageAddresses}
          columns={validColumnModalTable(columns)}
          expander
          filterable
          defaultPageSize={this.props.lines ? this.props.lines : 10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          actionButtons={(
            showAddressLinkBtn &&
            <div className="ml-auto">
              <button
                type="button"
                className="btn btn-240 btn-info btn-rounded pr-3 mr-3"
                onClick={() => exportAddressList(columns, storageAddresses)}
              ><i className="fa fa-download mr-1"></i>{I18n.t('BEE691' /* Exportar */)} {I18n.t('BEE683' /* Lista de Endereços */)}</button>
            </div>
          )}
          panelHeaderProps={{
            onClickExpand: (expand) => this.validColumnModalTableExpandeds(expand, columns)
          }}
        />
      </div>
    );
  }
}