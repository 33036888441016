import React from 'react';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

import Table from '../../../components/table/Table';


export default class DocumentsTable extends React.Component {
  constructor(props) {

    super(props);

    this.tableColumns = [
      {
        Header: I18n.t('BEE1378' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => {
          const textClass = (row.row.addedQuantity === row.row.totalAmount) ? 'text-teal' : ''
          return (
            <div className={textClass}>
              <span>{row.value}</span>
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => {
          const textClass = (row.row.addedQuantity === row.row.totalAmount) ? 'text-teal' : ''
          return (
            <div className={textClass} >
              <span>{row.value}</span>
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE1808' /* Pedido Cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => {
          const textClass = (row.row.addedQuantity === row.row.totalAmount) ? 'text-teal' : ''
          return (
            <div className={textClass} style={{ textAlign: "center" }}>
              <span>{row.value}</span>
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE402' /* Nota Fiscal */),
        accessor: 'invoiceNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => {
          const textClass = (row.row.addedQuantity === row.row.totalAmount) ? 'text-teal' : ''
          return (
            <div className={textClass} >
              <span>{row.value}</span>
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE1936' /* Total de Volumes */),
        accessor: 'totalAmount',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => {
          const textClass = (row.row.addedQuantity === row.row.totalAmount) ? 'text-teal' : ''
          return (
            <div className={textClass} >
              <span>{row.value}</span>
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE1935' /* Volumes Conferidos */),
        accessor: 'addedQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => {
          const textClass = (row.row.addedQuantity === row.row.totalAmount) ? 'text-teal' : ''
          return (
            <div className={textClass} >
              <span>{row.value}</span>
            </div>
          );
        },
      },
    ];

    this.state = {
      defaultPageSize: 5,
      pageSizeOptions: [5, 10],
      defaultSorted: [{ id: 'orderNumber', desc: false }],
    };

  };

  render() {
    const { defaultPageSize, defaultSorted, pageSizeOptions } = this.state;
    const { outboundOrder } = this.props;

    return (
      <>
        <Table
          downloadCSV
          panelHeaderProps={{
            noButton: true,
            children: I18n.t('BEE462' /* Documentos */).toUpperCase(),
          }}
          filterable
          expander
          data={outboundOrder}
          columns={this.tableColumns}
          pageSizeOptions={pageSizeOptions}
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
      </>
    );
  };

};