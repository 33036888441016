import CurvesRequests from '../../server/curves';
import { addLoading, removeLoading } from './loading';

export const setCurves = (curves) => ({
  type: 'SET_CURVES',
  curves,
});

export const getAllCurves = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const curves = await CurvesRequests.getAllCurves();
    dispatch(setCurves(curves));
  } finally {
    dispatch(removeLoading());
  }
};

export const getCurve = (curveId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CurvesRequests.getCurve(curveId);
  } finally {
    dispatch(removeLoading());
  }
};

export const createCurve = (curve) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CurvesRequests.createCurve(curve);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateCurve = (curve) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CurvesRequests.updateCurve(curve);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteCurve = (curveId, code) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CurvesRequests.deleteCurve(curveId, code);
  } finally {
    dispatch(removeLoading());
  }
};
