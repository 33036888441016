import WaybillsRequests from '../../server/waybills';
import { addLoading, removeLoading } from './loading';

export const getWaybillsByDate = (StartDate, EndDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await WaybillsRequests.getWaybillsByDate(StartDate, EndDate);
  } finally {
    dispatch(removeLoading());
  }
};

export const syncWaybillErp = (waybillId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await WaybillsRequests.syncWaybillErp(waybillId);
  } finally {
    dispatch(removeLoading());
  }
};
