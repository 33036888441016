import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';
import { getDataGrid } from '../../../app/store/actions/returnPickingOrder';
import Table from '../../../components/table/Table';
import FormatHelpers from '../../../helpers/format';
import ReturnPicking from '../../../helpers/returnPicking';

export class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderList: [],
      productList: [],
      currentRange: null,
      type: null,
    };

    this.defaultSorted = [
      {
        id: 'outboundOrderId',
        desc: false,
      },
    ];

    this.defaultSortedProducts = [
      {
        id: 'productCode',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu />
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE2333' /* Documento Saída */),
        accessor: 'outboundOrder.orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 170,
      }, {
        Header: I18n.t('BEE1905' /* Documento de Devolução */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 170,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{ReturnPicking.returnPickingOrderStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value) === parseInt(row.status)) {
            return true;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {ReturnPicking.returnPickingOrderStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE3206' /* Usuário de Devolução */),
        accessor: 'userReturnPicking.login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE452' /* Usuário Início Armazenagem */),
        accessor: 'userStartStorage.login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 210,
      }, {
        Header: I18n.t('BEE453' /* Data Início Armazenagem */),
        accessor: 'startStorageAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE454' /* Usuário Fim Armazenagem */),
        accessor: 'userFinishStorage.login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 210,
      }, {
        Header: I18n.t('BEE455' /* Data Fim Armazenagem */),
        accessor: 'finishStorageAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {

        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      },
    ];

    this.productTableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu />
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1897' /* Descrição */),
        accessor: 'product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
      }, {
        Header: I18n.t('BEE384' /* Unidade */),
        accessor: 'product.unitMeasure',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: `${I18n.t('BEE581' /* Range */)}`,
        accessor: 'outboundRangeProduct.outboundRange.rangeCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1688' /* Quantidade */),
        accessor: 'pickedQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE1720' /* Endereço Separação */),
        accessor: 'addressPicked',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
      }, {
        Header: I18n.t('BEE469' /* Data Armazenagem */),
        accessor: 'storageAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getDataGrid();
  }

  componentDidUpdate() {
    const { currentRange } = this.state;
    const newCurrentRange = this.props.currentRange;

    if (currentRange !== newCurrentRange) {
      this.getDataGrid();
    }
  }

  getDataGrid = async () => {
    const startDate = moment(this.props.startDate).format();
    const endDate = moment(this.props.endDate).format();
    const newCurrentRange = this.props.currentRange;

    const tableParams = {
      startDate,
      endDate,
    };

    const orderList = await this.props.getDataGrid(tableParams);
    this.setState({ orderList, currentRange: newCurrentRange });
  };

  setOrderSelected = (order) => {
    this.setState({
      orderSelect: order,
      productList: order.products,
    });
  };

  render() {
    const { orderList, productList, orderSelect } = this.state;

    return (
      <div>
        <Table
          panelHeaderProps={{
            onClickReload: this.getDataGrid,
            children: I18n.t('BEE462' /* Documentos */).toUpperCase(),
          }}
          filterable
          data={orderList}
          columns={this.tableColumns}
          expander
          defaultPageSize={5}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  this.setOrderSelected(rowInfo.original);
                },
                style: {
                  background: orderSelect && rowInfo.original.id === orderSelect.id ? 'silver' : 'white',
                  cursor: 'pointer',
                },
              };
            }
            return {};
          }}
        />

        <Table
          panelHeaderProps={{
            children: I18n.t('BEE27' /* Produtos */).toUpperCase(),
          }}
          filterable
          data={productList}
          columns={this.productTableColumns}
          expander
          defaultPageSize={5}
          defaultSorted={this.defaultSortedProducts}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getDataGrid: (tableParams) => dispatch(getDataGrid(tableParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(List));
