import InventoryRequests from '../../server/inventory';
import { addLoading, removeLoading } from './loading';

export const getListGenerateInventory = (tableFilter) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.getListGenerateInventory(tableFilter);
  } finally {
    dispatch(removeLoading());
  }
};

export const getInventorySheetsList = (grouperCode, branchCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.getInventorySheetsList(grouperCode, branchCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const reopenInventory = (inventorySheetId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.reopenInventory(inventorySheetId);
  } finally {
    dispatch(removeLoading());
  }
};

export const inventorySheetCreation = (sheetType, inventoryId, addressCode, productCode = '') => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.inventorySheetCreation(sheetType, inventoryId, addressCode, productCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const getInventoryList = (filterStartDate, filterEndDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.getInventoryList(filterStartDate, filterEndDate);
  } finally {
    dispatch(removeLoading());
  }
};

export const createInventory = (params, sheetList) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.createInventory(params, sheetList);
  } finally {
    dispatch(removeLoading());
  }
};

export const printInventory = (grouperCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.printInventory(grouperCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const getInventorySheetCounting = (inventoryCount, grouperCode, branchCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.getInventorySheetCounting(inventoryCount, grouperCode, branchCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const changingCountingUser = (
  checkedInventorySheetIds,
  inventoryCount,
  userId,
  typeAction,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.changingCountingUser(checkedInventorySheetIds, inventoryCount, userId, typeAction);
  } finally {
    dispatch(removeLoading());
  }
};

export const approveInventorySheets = (inventoryId, inventorySheetIds) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.approveInventorySheets(inventoryId, inventorySheetIds);
  } finally {
    dispatch(removeLoading());
  }
};

export const cancelInventorySheets = (inventoryId, inventorySheetIds, cancelNote = '') => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.cancelInventorySheets(inventoryId, inventorySheetIds, cancelNote);
  } finally {
    dispatch(removeLoading());
  }
};

export const setUpdateStatusInventory = (inventoryId, cancelNote) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.setUpdateStatusInventory(inventoryId, cancelNote);
  } finally {
    dispatch(removeLoading());
  }
};

export const getAllInventorySheets = (inventoryId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.getAllInventorySheets(inventoryId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getInventoryOptions = (branchCode, statusFilter) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.getInventoryOptions(branchCode, statusFilter);
  } finally {
    dispatch(removeLoading());
  }
};

export const finalizeInventorySheets = (inventoryId, inventorySheetIds) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.finalizeInventorySheets(inventoryId, inventorySheetIds);
  } finally {
    dispatch(removeLoading());
  }
};

export const disapprovedInventorySheets = (inventoryId, inventorySheetIds, disapprovedNote) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.disapprovedInventorySheets(inventoryId, inventorySheetIds, disapprovedNote);
  } finally {
    dispatch(removeLoading());
  }
};

export const getNewGrouperCode = (branchCode, type, date, depositCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.getNewGrouperCode(branchCode, type, date, depositCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const getAllGroupersByBranch = (branchCode, statusFilter, startDate, endDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.getAllGroupersByBranch(branchCode, statusFilter, startDate, endDate);
  } finally {
    dispatch(removeLoading());
  }
};

export const getDepositsByBranch = (branchCode, startDate, endDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.getDepositsByBranch(branchCode, startDate, endDate);
  } finally {
    dispatch(removeLoading());
  }
};

export const getInventoryDashboardSheetsList = (
  branchCode,
  groupersCodes,
  depositsCodes,
  startDate,
  endDate,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InventoryRequests.getInventoryDashboardSheetsList(
      branchCode,
      groupersCodes,
      depositsCodes,
      startDate,
      endDate,
    );
  } finally {
    dispatch(removeLoading());
  }
};
