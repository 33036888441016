import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import Table from '../../../components/table/Table';

import { getInventoriesErpList } from '../../../app/store/actions/inventoriesErp';

import Form from '../../../components/form/form';
import ROUTES from '../../../config/routes';
import FormatHelpers from '../../../helpers/format';

const formComp = new Form();

class InventoryErpList extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
      pages: 0,
      totalDataLength: 0,
    };

    this.defaultSorted = [
      {
        id: 'productCode',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 400,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 350,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
        Filter: this.filterDateRangeColumn,
      }, {
        Header: I18n.t('BEE384' /* Unidade */),
        accessor: 'unitMeasure',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE432' /* Endereço */),
        accessor: 'storageAddress',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE433' /* Data FIFO */),
        accessor: 'fifoDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('DD/MM/YYYY')}
          </span>
        ),
      }, {
        Header: I18n.t('BEE434' /* Data de Validade */),
        accessor: 'expirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('DD/MM/YYYY')}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
        Filter: this.filterColumn,
      },
    ];
  }

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  filterDateRangeColumn = ({ filter, onChange }) => formComp.createInputRange(
    {
      from: filter ? filter.value[0] : '',
      to: filter ? filter.value[1] : '',
    }, // values = { from: '', to: '' }
    undefined, // attr = { from: '', to: '' }
    undefined, // label,
    undefined, // placeholder = {},
    { from: 'number', to: 'number' }, // type = { from: 'text', to: 'text' },
    undefined, // required = {},
    undefined, // disabled = {},
    undefined, // tooltip,
    undefined, // inputCode = {},
    true,
    onChange,
  );

  getInventoriesErp = async () => {
    const { startDate, endDate } = this.state;
    const data = await this.props.getInventoriesErpList({
      page: this.page,
      pageSize: this.pageSize,
      sorted: this.sorted,
      filtered: this.filtered,
      startDate: moment(startDate).format(),
      endDate: moment(endDate).format(),
    });
    this.setState({ pages: data.pages, totalDataLength: data.totalDataLength });
  };

  handleDateApplyEvent = async (event) => {
    const startDate = event[0];
    const endDate = event[1];

    await this.setState({
      startDate,
      endDate,
    });

    this.getInventoriesErp();
  };

  render() {
    const { inventoriesErpList = [] } = this.props;

    const { pages, totalDataLength } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE33' /* Consultas */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE32' /* Estoque */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE429' /* Estoque ERP */)}</li>
          </ol>
        </div>
        <Table
          headerTitle={I18n.t('BEE429' /* Estoque ERP */)}
          wikiHelp={ROUTES.STOCK_INVENTORY_ERP_HELP}
          panelHeaderProps={{
            onClickReload: this.getInventoriesErp,
          }}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          filterable
          data={inventoriesErpList}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          manual
          pages={pages}
          onFetchData={(state) => {
            this.page = state.page;
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;
            this.getInventoriesErp();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  inventoriesErpList: state.inventoriesErp && state.inventoriesErp.inventoriesErpList,
});

const mapDispatchToProps = (dispatch) => ({
  getInventoriesErpList: (tableParams) => dispatch(getInventoriesErpList(tableParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventoryErpList));
