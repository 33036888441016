import RestService from '../services/rest';

// GET
async function getProductBranches(tableParams) {
  return RestService.postAuthenticated('productBranches/list/all', tableParams);
}

async function getProductBranch(productBranchId) {
  return RestService.getAuthenticated(`productBranches/detail?productBranchId=${productBranchId}`);
}

// POST
async function createProductBranch(productBranch) {
  return RestService.postAuthenticated('productBranches/create', productBranch);
}

async function deleteProductBranch(productBranchId) {
  return RestService.postAuthenticated('productBranches/delete', { productBranchId });
}

// PUT
async function updateProductBranch(productBranch) {
  return RestService.putAuthenticated('productBranches/update', productBranch);
}

export default {
  getProductBranches,
  createProductBranch,
  updateProductBranch,
  getProductBranch,
  deleteProductBranch
};
