const INITIAL_STATE = {
  printersList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PRINTERS': {
      return { ...state, printersList: action.printers };
    }
    default:
      return state;
  }
};
