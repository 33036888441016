import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getSuppliersList, deleteSupplier } from '../../../../app/store/actions/suppliers';

class SupplierList extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      showModalDelete: false,
      supplierUpdate: null,
      totalDataLength: 0,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
        pages: 0,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showSupplierDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditSupplier(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopySupplier(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                {!this.props.formIntegrations && (
                  <DropdownItem onClick={() => this.showDeleteSupplier(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE84' /* Razão Social */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 400,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE352' /* Nome Fantasia */),
        accessor: 'businessName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE85' /* CNPJ */),
        accessor: 'cnpj',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE86' /* E-mail */),
        accessor: 'email',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE133' /* Telefone */),
        accessor: 'phone',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE119' /* CEP */),
        accessor: 'postalCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE53' /* Cidade */),
        accessor: 'city',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE87' /* Estado */),
        accessor: 'state',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
    }
    // else {
    //   await this.getSuppliers();
    // }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      supplierUpdate: null,
    });
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getSuppliers = async () => {
    const data = await this.props.getSuppliersList({
      page: this.page,
      pageSize: this.pageSize,
      sorted: this.sorted,
      filtered: this.filtered,
    });
    this.setState({ pages: data.pages, totalDataLength: data.totalDataLength });
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deleteSupplier = async (supplier) => {
    const result = await this.props.deleteSupplier(supplier._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'success',
        I18n.t('BEE338' /* Eliminar Fornecedor */),
        I18n.t('BEE3310', { 0: supplier.code } /* Fornecedor %{0} eliminado com sucesso. */),
        'top-right',
      );
    }
    await this.getSuppliers();
  };

  showSupplierDetail = (supplier) => {
    this.props.history.push({
      pathname: ROUTES.SUPPLIER_DETAIL,
      state: {
        supplier: supplier._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditSupplier = (supplier) => {
    this.props.history.push({
      pathname: ROUTES.SUPPLIER_EDIT,
      state: {
        supplier: supplier._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopySupplier = (supplier) => {
    this.props.history.push({
      pathname: ROUTES.SUPPLIER_COPY,
      state: {
        supplier: supplier._original,
      },
    });
  };

  showDeleteSupplier = (supplier) => {
    this.setState({ showModalDelete: true, supplierUpdate: supplier });
  };

  render() {
    const {
      supplierUpdate, showModalDelete, pages, page, totalDataLength,
    } = this.state;
    const { suppliersList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE23' /* Fornecedores */)}</li>
          </ol>
        </div>
        <Table
          wikiHelp={ROUTES.SUPPLIER_HELP}
          downloadCSV
          headerTitle={I18n.t('BEE23' /* Fornecedores */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.SUPPLIER_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE340' /* Incluir Fornecedor */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getSuppliers,
          }}
          filterable
          data={suppliersList}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          manual
          pages={pages}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          onFetchData={(state, instance) => {
            this.page = (state.page) ? state.page : (!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : state.page;
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;
            this.getSuppliers();
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteSupplier(supplierUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE341', { 0: supplierUpdate.code } /* O fornecedor %{0} será eliminada! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  suppliersList: state.suppliers && state.suppliers.suppliersList,
  formIntegrations: state.app.permissionsCompany && state.app.permissionsCompany.formIntegrations,
});

const mapDispatchToProps = (dispatch) => ({
  getSuppliersList: (tableParams) => dispatch(getSuppliersList(tableParams)),
  deleteSupplier: (supplierId) => dispatch(deleteSupplier(supplierId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SupplierList));
