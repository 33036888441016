import ResupplyDeposits from '../../server/resupplyDeposits';
import { addLoading, removeLoading } from './loading';

export const updateResupplyDeposits = (resupplyDeposits) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ResupplyDeposits.updateResupplyDeposits(resupplyDeposits);
  } finally {
    dispatch(removeLoading());
  }
};

export const getAllResupplyDeposits = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const resupplyDeposits = await ResupplyDeposits.getAllResupplyDeposits();
    return resupplyDeposits;
  } finally {
    dispatch(removeLoading());
  }
};

export const createResupplyDeposits = (resupplyDeposits) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const result = await ResupplyDeposits.createResupplyDeposits(resupplyDeposits);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteResupplyDeposits = (resupplyDepositsId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ResupplyDeposits.deleteResupplyDeposits(resupplyDepositsId);
  } finally {
    dispatch(removeLoading());
  }
};
