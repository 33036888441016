import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import addNotification from '../../components/notification';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportInventoryCount extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE572' /* Inventário */) },
      { value: I18n.t('BEE2206' /* Contagem de Inventário */), active: true },
    ];

    this.state = {
      branchCodeFrom: '',
      branchCodeTo: '',
      dateInventoryFrom: '',
      dateInventoryTo: '',
      grouperCodeFrom: '',
      grouperCodeTo: '',
      checkAllCheckBoxes: true,
      pending: true,
      inCount: true,
      countFinished: true,
      approved: true,
      updated: true,
    };
  }

  checkOrUncheckAllBoxes = (value) => {
    this.setState({
      pending: value,
      inCount: value,
      countFinished: value,
      approved: value,
      updated: value,
      checkAllCheckBoxes: value,
    });
  };

  handleSelectAllCheckBox = () => {
    if (this.state.pending
      && this.state.inCount
      && this.state.countFinished
      && this.state.approved
      && this.state.updated) {
      this.setState({
        checkAllCheckBoxes: true,
      });
    } else {
      this.setState({
        checkAllCheckBoxes: false,
      });
    }
  };

  setValue = async (attr, value, label) => {
    await this.setState({
      [`${attr}`]: value,
    });

    if (attr === 'checkAllCheckBoxes') {
      this.checkOrUncheckAllBoxes(value);
    }

    this.handleSelectAllCheckBox();
  };

  onGenerateSpreadsheet = () => {
    const {
      branchCodeFrom, branchCodeTo, dateInventoryFrom, dateInventoryTo,
      grouperCodeFrom, grouperCodeTo, itemCountedInventoryFrom, itemCountedInventoryTo,
      pending, inCount, countFinished, approved, updated,
    } = this.state;
    const { location } = this.props;

    const status = [];
    if (pending) status.push(1);
    if (inCount) status.push(2);
    if (countFinished) status.push(3);
    if (approved) status.push(4);
    if (updated) status.push(5);

    const filters = returnFiltersByRules([
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['$inventoryAt', {
        type: 'between', value: [dateInventoryFrom, dateInventoryTo], instance: 'Inventory', as: 'inventory',
      }],
      ['$grouperCode', {
        type: 'between', value: [grouperCodeFrom, grouperCodeTo], instance: 'Inventory', as: 'inventory',
      }],
      ['$numberCountsYear', {
        type: 'between',
        value: [itemCountedInventoryFrom, itemCountedInventoryTo],
        instance: 'ProductBranch',
        as: 'productBranch',
      }],
      ['status', {
        type: 'in', value: status,
      }],
    ]);

    if (!status.length) {
      addNotification(
        'danger',
        I18n.t('BEE2149' /* Situação do Inventário */),
        I18n.t('BEE2406' /* A situação do inventário deve ser selecionada! */),
        'top-right',
      );
    } else {
      this.props.generateReport(
        I18n.t('BEE2206' /* Contagem de Inventário */).toLowerCase(),
        'xlsx',
        location.pathname,
        [{}],
        filters,
      );
    }
  };

  onSelectFilter = (newState) => this.setState({ ...newState });

  render() {
    const {
      branchCodeFrom, branchCodeTo, dateInventoryFrom, dateInventoryTo,
      grouperCodeFrom, grouperCodeTo, pending, checkAllCheckBoxes, inCount, approved,
      itemCountedInventoryFrom, itemCountedInventoryTo, countFinished, updated,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE2206' /* Contagem de Inventário */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createDateInputRange(
                    { from: dateInventoryFrom, to: dateInventoryTo },
                    { from: 'dateInventoryFrom', to: 'dateInventoryTo' },
                    `${I18n.t('BEE2113' /* Data Inventário */)}:`,
                    { from: '__/__/____', to: '__/__/____' },
                  ),
                  formContext.createInputRange(
                    { from: grouperCodeFrom, to: grouperCodeTo },
                    { from: 'grouperCodeFrom', to: 'grouperCodeTo' },
                    `${I18n.t('BEE1516' /* Agrupador */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: itemCountedInventoryFrom, to: itemCountedInventoryTo },
                    { from: 'itemCountedInventoryFrom', to: 'itemCountedInventoryTo' },
                    `${I18n.t('BEE2348' /* Ciclo de Contagem */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createCheckBoxes([{
                    label: I18n.t('BEE2385' /* Selecionar Todos */),
                    value: checkAllCheckBoxes,
                    attr: 'checkAllCheckBoxes',
                  }, {
                    label: I18n.t('BEE470' /* Pendente */),
                    value: pending,
                    attr: 'pending',
                  }, {
                    label: I18n.t('BEE2115' /* Em Contagem */),
                    value: inCount,
                    attr: 'inCount',
                  }, {
                    label: I18n.t('BEE2116' /* Contagem Finalizada */),
                    value: countFinished,
                    attr: 'countFinished',
                  }, {
                    label: I18n.t('BEE2119' /* Aprovado */),
                    value: approved,
                    attr: 'approved',
                  }, {
                    label: I18n.t('BEE2118' /* Atualizado */),
                    value: updated,
                    attr: 'updated',
                  }], `${I18n.t('BEE2149' /* Situação Inventário */)}:`),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportInventoryCount);
