import RestService from '../services/rest';

async function getCableCutProductList(cableCutPlanId) {
  return RestService.postAuthenticated('cableCuts/productList', {cableCutPlanId});
};

async function cutsMonitoList(tableParams) {
  return RestService.postAuthenticated('cableCuts/list', tableParams);
};

async function printCableCutLabel(printerCode, type, code) {
  return RestService.postAuthenticated('labels/print/cableCut', {printerCode, type, code});
};
 
async function finishCableCut(cableCutPlanId, cableCutProductId, baptismLabel, printerCode = '') {
  return RestService.postAuthenticated('cableCuts/finishCableCut', {cableCutPlanId, cableCutProductId, baptismLabel, printerCode});
};

async function userChangeCutResponsible(cableCutPlanId, responsibleCutUserId ) {
  return RestService.postAuthenticated('cableCuts/userChangeCutResponsible', {cableCutPlanId, responsibleCutUserId });
}; 

async function deleteCableCut(cableCutPlanId, cableCutProductId) {
  return RestService.deleteAuthenticated('cableCuts/deleteCableCut', {cableCutPlanId, cableCutProductId});
};

export default {
  cutsMonitoList,
  getCableCutProductList,
  printCableCutLabel,
  finishCableCut,
  deleteCableCut,
  userChangeCutResponsible,
}