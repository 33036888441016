/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

import OneClickButton from '../../components/form/button';
import addNotification from '../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../components/panel/panel';

import { confirmCheckPlateOrder, getInboundOrdersCheckPlate } from '../../app/store/actions/inboundOrders';

import WikiHelp from '../../components/tooltip/wikiHelp';
import ROUTES from '../../config/routes';

class PlateCheckForm extends React.Component {
  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);

    this.state = {
      plate: '',
      orderNumber: null,
      serie: null,
      supplierName: null,
      cfop: null,
      expectedVolumes: null,
      volumesChecked: null,
      showForm: false,
      invoiceModal: false,
      invoiceList: null,
      invoiceSelected: null,
      showDivergenceModal: false,
    };

    this.defaultSorted = [
      {
        id: 'orderNumber',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE967' /* Chave de acesso */),
        accessor: 'accessKey',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 320,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE342' /* Fornecedor */),
        accessor: 'supplierBusinessName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE403' /* Série */),
        accessor: 'serie',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 70,
      }, {
        Header: I18n.t('BEE123' /* Número */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      },
    ];
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4 text-right">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value.toUpperCase())}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createNumberFormat = (value, attr, label, decimals) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4 text-right">{label}</label>
      <div className="col-md-5">
        <NumberFormat
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={decimals}
          value={value || ''}
          onValueChange={(values) => {
            this.setValue(attr, values.floatValue);
          }}
          fixedDecimalScale
          defaultValue={0}
          allowEmptyFormatting
        />
      </div>
    </div>
  );

  createInputButton = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    buttonLabel,
    buttonFunction,
  ) => (
    <div className="form-group p-2">
      <label className="col-form-label col-md-4 text-left">{label}</label>
      <div className="input-group col-md-4">
        <input
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value.toUpperCase())}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, '')}>
            <i className="fa fa-times" />
          </button>
          <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
            { buttonLabel }
          </button>
        </div>
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  resetForm = () => {
    this.setState({
      plate: '',
      orderNumber: '',
      serie: '',
      supplierName: '',
      cfop: '',
      volumesChecked: '',
      expectedVolumes: '',
      invoiceSelected: null,
      invoiceList: null,
      showForm: false,
      showDivergenceModal: false,
    });
  };

  createModal = () => {
    const { invoiceList } = this.state;
    return (
      <Modal size="xl" isOpen={this.state.invoiceModal} fade={false} toggle={() => this.toggleModal('invoiceModal')}>
        <ModalHeader toggle={() => this.toggleModal('invoiceModal')}>
          {I18n.t('BEE968' /* Notas Fiscais */)}
        </ModalHeader>
        <ModalBody>
          <Panel>
            <PanelHeader noButton />
            <ReactTable
              showPagination={false}
              filterable
              data={invoiceList}
              columns={this.tableColumns}
              expander
              defaultPageSize={invoiceList ? invoiceList.length : 1}
              defaultSorted={this.defaultSorted}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
              className="-highlight"
              loadingText={I18n.t('BEE97' /* Carregando... */)}
              noDataText={I18n.t('BEE98' /* Nenhuma linha encontrada */)}
              getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: () => {
                      this.setInvoiceSelected(rowInfo.original);
                    },
                    style: {
                      cursor: 'pointer',
                    },
                  };
                }
                return {};
              }}
            />
          </Panel>
        </ModalBody>
        <ModalFooter>
          <OneClickButton className="btn btn-white" onClick={() => this.toggleModal('invoiceModal')}>
            {I18n.t('BEE99' /* Cancelar */)}
          </OneClickButton>
        </ModalFooter>
      </Modal>
    );
  };

  plateSearch = async () => {
    const {
      plate,
    } = this.state;

    const validPlate = /^[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}$/.test(plate);

    try {
      if (validPlate) {
        const orders = await this.props.getInboundOrdersList(plate);

        if (orders) {
          this.setState({
            invoiceList: orders,
          });
          this.toggleModal('invoiceModal');
        }
      } else {
        addNotification('danger', I18n.t('BEE399' /* Conferência de placa */), I18n.t(
          'BEE969',
          { 0: plate }, /* A placa informada %{0} não é válida ! */
        ), 'top-right');
        this.setValue('showForm', false);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE399' /* Conferência de placa */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE399' /* Conferência de placa */),
          'Erro Conferência de placa',
          'top-right',
        );
      }
      this.setValue('showForm', false);
    }
  };

  setInvoiceSelected = async (invoiceSelected) => {
    this.setState({
      invoiceSelected,
      orderNumber: invoiceSelected.orderNumber,
      serie: invoiceSelected.serie,
      supplierName: invoiceSelected.supplierName,
      cfop: invoiceSelected.cfop,
      expectedVolumes: invoiceSelected.expectedVolumes,
      volumesChecked: 0,
      showForm: true,
      invoiceModal: false,
    });
  };

  confirmDivergence = () => {
    this.setState({
      showDivergenceModal: false,
    });
    this.volumesSubmit();
  };

  volumesCheck = () => {
    const {
      expectedVolumes, volumesChecked,
    } = this.state;

    if (volumesChecked !== '' && parseInt(volumesChecked, 10) > 0) {
      if (parseInt(expectedVolumes, 10) !== parseInt(volumesChecked, 10)) {
        this.setState({
          showDivergenceModal: true,
        });
        return;
      }
    } else {
      addNotification(
        'danger',
        I18n.t('BEE399' /* Conferência de placa */),
        'Quantidade de Volumes deve ser maior que 0',
        'top-right',
      );
      return;
    }

    this.volumesSubmit();
  };

  volumesSubmit = async () => {
    const {
      invoiceSelected, volumesChecked,
    } = this.state;

    try {
      const confirmCheckReturn = await this.props.confirmCheckPlateOrder(invoiceSelected.id, volumesChecked);
      if (confirmCheckReturn.success === false) {
        throw confirmCheckReturn.error;
      }
      addNotification(
        'success',
        I18n.t('BEE399' /* Conferência de placa */),
        I18n.t('BEE970' /* O número de volumes foi confirmado com sucesso */),
        'top-right',
      );
      this.resetForm();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE399' /* Conferência de placa */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE399' /* Conferência de placa */),
            I18n.t('BEE985' /* Erro ao conferir produto */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE399' /* Conferência de placa */),
          I18n.t('BEE985' /* Erro ao conferir produto */),
          'top-right',
        );
      }
    }
  };

  toggleModal(name) {
    switch (name) {
      case 'invoiceModal':
        this.setState({ invoiceModal: !this.state.invoiceModal });
        break;
      default:
        break;
    }
  }

  render() {
    const {
      plate, orderNumber, serie, supplierName, cfop, showDivergenceModal, volumesChecked,
    } = this.state;

    const {
      showForm,
    } = this.state;

    const title = I18n.t('BEE399' /* Conferência de Placa */);

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE35' /* Portaria */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE399' /* Conferência de Placa */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
          <WikiHelp wikiPath={ROUTES.ENTRANCE_PLATE_CHECK_HELP} />
        </div>
        <div className="row">
          <div className="col-xl-12">
            <form onSubmit={(e) => e.preventDefault()}>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  {showForm
                    ? this.createInput(
                      plate,
                      'plate',
                      `${I18n.t('BEE400' /* Placa do Veículo */)}:`,
                      I18n.t('BEE401' /* Informe a placa do veículo */),
                      'text',
                      true,
                      showForm,
                    )
                    : this.createInputButton(
                      plate,
                      'plate',
                      I18n.t('BEE400' /* Placa do Veículo */),
                      I18n.t('BEE401' /* Informe a placa do veículo */),
                      'text',
                      true,
                      false,
                      <i className="fa fa-search" />,
                      this.plateSearch,
                    )}
                  {showForm && this.createInput(
                    orderNumber,
                    'orderNumber',
                    `${I18n.t('BEE402' /* Nota Fiscal */)}:`,
                    I18n.t('BEE973' /* Erro ao buscar a nota fiscal */),
                    'text',
                    false,
                    true,
                  )}
                  {showForm && this.createInput(
                    serie,
                    'serie',
                    `${I18n.t('BEE403' /* Série */)}:`,
                    I18n.t('BEE974' /* Erro ao buscar a série da NF */),
                    'text',
                    false,
                    true,
                  )}
                  {showForm && this.createInput(
                    cfop,
                    'cfop',
                    `${I18n.t('BEE404' /* Código da Operação */)}:`,
                    I18n.t('BEE975' /* Erro ao buscar o código da operação */),
                    'text',
                    false,
                    true,
                  )}
                  {showForm && this.createInput(
                    `${supplierName}`,
                    'supplierName',
                    `${I18n.t('BEE342' /* Fornecedor */)}:`,
                    I18n.t('BEE976' /* Erro ao buscar o nome do fornecedor */),
                    'text',
                    false,
                    true,
                  )}
                  {showForm && this.createNumberFormat(
                    volumesChecked,
                    'volumesChecked',
                    `${I18n.t('BEE405' /* Volumes */)}:`,
                    0,
                  )}
                </PanelBody>
                <PanelFooter className="text-right">
                  {(!showForm
                    && (
                    <>
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-white p-5 m-5"
                        onClick={() => this.resetForm()}
                      >
                        {I18n.t('BEE492' /* Limpar */)}
                      </OneClickButton>
                      <OneClickButton
                        type="submit"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.plateSearch()}
                      >
                        {I18n.t('BEE407' /* Buscar */)}

                      </OneClickButton>
                    </>
                    )
                  )}
                  {(showForm
                    && (
                    <>
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-white p-5 m-5"
                        onClick={() => this.resetForm()}
                      >
                        {I18n.t('BEE99' /* Cancelar */)}
                      </OneClickButton>
                      <OneClickButton
                        type="submit"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.volumesCheck()}
                      >
                        {I18n.t('BEE100' /* Confirmar */)}

                      </OneClickButton>
                    </>
                    )
                  )}
                </PanelFooter>
                {this.createModal()}
              </Panel>
            </form>
            {(showDivergenceModal
              && (
              <SweetAlert
                danger
                showCancel
                cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
                confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={I18n.t('BEE101' /* Você tem certeza? */)}
                onConfirm={() => this.confirmDivergence()}
                onCancel={() => this.setState({ showDivergenceModal: false })}
              >
                <div>
                  {`Quantidade de volumes divergente! Você confirma a quantidade de ${volumesChecked} volumes?`}
                </div>
              </SweetAlert>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getInboundOrdersList: (licensePlate) => dispatch(getInboundOrdersCheckPlate(licensePlate)),
  confirmCheckPlateOrder: (inboundOrderId, volumesChecked) => dispatch(confirmCheckPlateOrder(
    inboundOrderId,
    volumesChecked,
  )),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlateCheckForm));
