/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ROUTES from '../../config/routes';

class NotificationLeftSide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onClickNotificationType = (notifType) => () => {
    this.props.history.push({
      pathname: ROUTES.NOTIFICATION,
      state: {
        notifType,
      },
    });
  };

  renderByType = (notificationsByType, notifType = '') => Object.keys(notificationsByType).map((notifKey) => (
    <li key={notifKey} className={notifKey === notifType ? 'active' : ''}>
      <a onClick={this.onClickNotificationType(notifKey)} className="cursorPointer">
        <i className={`${notificationsByType[notifKey].icon} fa-fw m-r-5`} />
        {' '}
        {notificationsByType[notifKey].name}
        {' '}
        <span className="badge pull-right">{notificationsByType[notifKey].total}</span>
      </a>
    </li>
  ));

  render() {
    const { notifType } = this.props;
    return (
      <div className="vertical-box-column width-200 hidden-xs">
        <div className="vertical-box">
          <div className="wrapper text-center">
            <div className="d-flex align-items-center justify-content-center">
              <button type="button" className="btn btn-inverse btn-sm mr-auto d-block d-lg-none">
                <i className="fa fa-cog" />
              </button>
              <Link to={ROUTES.NOTIFICATION} className="btn btn-inverse p-l-40 p-r-40 btn-sm">
                {I18n.t('BEE5' /* Notificações */)}
              </Link>
            </div>
          </div>
          <div className={`vertical-box-row collapse d-lg-table-row ${this.state.isMobileEmailNavOn ? 'show' : ''}`}>
            <div className="vertical-box-cell">
              <div className="vertical-box-inner-cell">
                <PerfectScrollbar className="height-full" options={{ suppressScrollX: true }}>
                  <div className="wrapper p-0">
                    <div className="nav-title"><b>{I18n.t('BEE2562' /* Tipos */).toUpperCase()}</b></div>
                    <ul className="nav nav-inbox">
                      {this.renderByType(this.props.notificationsByType, notifType)}
                    </ul>
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notificationsByType: (state.notifications.list || []).reduce((acum, notif) => {
    acum[notif.type] = {
      name: notif.name,
      icon: notif.icon,
      total: notif.readed ? '' : ((acum[notif.type] && acum[notif.type].total) || 0) + 1,
    };
    return acum;
  }, {}),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationLeftSide));
