import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';

import OneClickButton from '../../../../components/form/button';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createCurve, getCurve, updateCurve,
} from '../../../../app/store/actions/curves';
import Form from '../../../../components/form/form';

class CurveForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: '',
      code: '',
      name: '',
      note: '',
      disableCode: true,
      disableName: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getCurvesSelected();
    }
  }

  getCurvesSelected = async () => {
    if (this.props.location.state.curves) {
      const curve = this.props.location.state.curves;
      const curveSelected = await this.props.getCurve(curve);
      if (curveSelected) {
        this.setState({
          curveId: curve.id,
          code: curve.code,
          name: curve.name,
          note: curve.note,
        });
      }
    }
  };

  updateCurveSubmit = async () => {
    const {
      curveId, code, name, note,
    } = this.state;

    try {
      const updtCurve = await this.props.updateCurve({
        curveId,
        code,
        name,
        note,
      });

      if (updtCurve) {
        await addNotification(
          'success',
          I18n.t('BEE2472' /* Atualizar Curva */),
          I18n.t('BEE2473', { 0: code } /* Atualização da Curva %{0} efetuada com sucesso! */),
          'top-right',
        );
        this.props.history.push(ROUTES.CURVES_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE2472' /* Atualizar Curva */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE2472' /* Atualizar Curva */),
            I18n.t('BEE2471', { 0: code } /* Erro ao atualizar a Curva %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE2472' /* Atualizar Curva */),
          I18n.t('BEE2471', { 0: code } /* Erro ao atualizar a Curva %{0}! */),
          'top-right',
        );
      }
    }
  };

  createCurveSubmit = async () => {
    const {
      code, name, note,
    } = this.state;

    try {
      const newCurve = await this.props.createCurve({
        code,
        name,
        note,
      });

      if (newCurve) {
        await addNotification(
          'success',
          I18n.t('BEE355' /* Curva */),
          I18n.t('BEE2466', { 0: code } /* Nova Curva %{0} criada com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.CURVES_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE355' /* Curva */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE355' /* Curva */),
            I18n.t('BEE262' /* O código informado já existe! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE355' /* Curva */),
          I18n.t('BEE2471', { 0: code } /* Erro ao atualizar a Curva %{0}! */),
          'top-right',
        );
      }
    }
  };

  setMode = () => {
    let mode = '';
    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isEdit) {
      mode = 'edit';
    }

    if (mode === 'create') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled, maxLength) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label
        htmlFor={label}
        className="col-form-label col-md-4"
      >
        {label}
      </label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  render() {
    const {
      code, name, note, showCreate,
      showUpdate, mode, disableCode,
      disableName, disableNote,
    } = this.state;

    let title = '';

    if (mode === 'edit') {
      title = I18n.t('BEE2469' /* Editar Curva */);
    } else {
      title = I18n.t('BEE2464' /* Incluir Curva */);
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE2463' /* Curvas */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader />
                <PanelBody>
                  <Form
                    noPanel
                    setValue={this.setValue}
                    inputs={(formContext) => ([
                      formContext.createInput(
                        code,
                        'code',
                        `${I18n.t('BEE82' /* Código */)}:`,
                        I18n.t('BEE863' /* Informe a curva */),
                        'text',
                        true,
                        disableCode,
                      ),
                      formContext.createInput(
                        name,
                        'name',
                        `${I18n.t('BEE83' /* Nome */)}:`,
                        I18n.t('BEE3697' /* Informe o nome da curva */),
                        'text',
                        true,
                        disableName,
                      ),
                      formContext.createInput(
                        note,
                        'note',
                        `${I18n.t('BEE135' /* Observação */)}:`,
                        I18n.t('BEE136' /* Informe a observação */),
                        'text',
                        false,
                        disableNote,
                      ),
                    ])}
                  />
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.CURVES_LIST, state: { dice: this.props.location.dice } }}>
                    <OneClickButton
                      type="submit"
                      className="btn btn-120 btn-white p-5 m-5"
                    >
                      {I18n.t('BEE99' /* Cancelar */)}
                    </OneClickButton>
                  </Link>
                  {(showCreate && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createCurveSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}
                    </OneClickButton>
                  ))}
                  {(showUpdate && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateCurveSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}
                    </OneClickButton>
                  ))}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (/* state */) => ({
});

const mapDispatchToProps = (dispatch) => ({
  createCurve: (curve) => dispatch(createCurve(curve)),
  getCurve: (curveId) => dispatch(getCurve(curveId)),
  updateCurve: (curve) => dispatch(updateCurve(curve)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CurveForm));
