import RestService from '../services/rest';

// GET
async function getUserFunctions() {
  return RestService.getAuthenticated('userfunctions/list/all');
}

async function getUserFunctionsOptions() {
  return RestService.getAuthenticated('userfunctions/list/options');
}

async function getUserFunction(userFunctionId) {
  return RestService.getAuthenticated(`userfunctions/detail?userFunctionId=${userFunctionId}`);
}

// POST
async function createUserFunction(userFunction) {
  return RestService.postAuthenticated('userfunctions/create', userFunction);
}

// PUT
async function updateUserFunction(userFunction) {
  return RestService.putAuthenticated('userfunctions/update', userFunction);
}

async function deleteUserFunction(userFunctionId) {
  return RestService.postAuthenticated('userFunctions/delete', { userFunctionId });
}

export default {
  createUserFunction,
  getUserFunction,
  getUserFunctions,
  getUserFunctionsOptions,
  updateUserFunction,
  deleteUserFunction,
};
