import CapacityRequests from '../../server/capacities';
import { addLoading, removeLoading } from './loading';

export const setCapacities = (capacities) => ({
  type: 'SET_CAPACITIES',
  capacities,
});

export const openBulkImportCapacity = (showBulkImport) => ({
  type: 'OPEN_BULK_IMPORT_CAPACITY',
  showBulkImport,
});

export const getCapacitiesList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const capacities = await CapacityRequests.getCapacities();
    if (capacities.length > 0) {
      dispatch(setCapacities(capacities));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const getCapacity = (capacityId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CapacityRequests.getCapacity(capacityId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getCapacityOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CapacityRequests.getCapacityOptions();
  } catch {
    dispatch(removeLoading());
  } finally {
    dispatch(removeLoading());
  }
};

export const getCapacityOptionsProducts = (productFilter) => async (dispatch) => {
  try {
    return await CapacityRequests.getCapacityOptionsProducts(productFilter);
  } finally {
    dispatch(removeLoading());
  }
};

export const createCapacity = (capacity) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newCapacity = await CapacityRequests.createCapacity(capacity);
    return newCapacity;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateCapacity = (capacity) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CapacityRequests.updateCapacity(capacity);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteCapacity = (capacityId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CapacityRequests.deleteCapacity(capacityId);
  } finally {
    dispatch(removeLoading());
  }
};

export const bulkImportCapacity = (jsonCsv) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CapacityRequests.bulkImportCapacity(jsonCsv);
  } finally {
    dispatch(removeLoading());
  }
};
