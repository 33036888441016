import 'react-table/react-table.css';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import AddAddProduct from './addAddProduct';
import SweetAlert from 'react-bootstrap-sweetalert';

import FormatHelpers from '../../../helpers/format';

class AddProductsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddProduct: false,
      showDeleteModal: false,
      deleteIndex: null,
      editingProduct: null,
    };
  }  

  toggleShowAddProduct = () => {
    this.setState({
      showAddProduct: !this.state.showAddProduct,
      editingProduct: null,
    })
  }

  editProduct = (product) => {
    this.setState({
      showAddProduct: true,
      editingProduct: product,
    })
  }

  toggleDeleteModal = (index=null) => {
    if (typeof index === 'number') {
      this.setState({
        showDeleteModal: true,
        deleteIndex: index
      })
    } else {
      this.setState({
        showDeleteModal: false,
        deleteIndex: null
      })
    }
  }

  confirmDelete = () => {
    this.props.removeProduct(this.state.deleteIndex)
    this.setState({
      showDeleteModal: false,
      deleteIndex: null
    })
  }

  render() {
    const { showAddProduct, showDeleteModal } = this.state;
    const { products, editing } = this.props;
    return (
      <div>
        <div className="text-right mb-2">
          {(!editing && <div className="btn btn-success btn-sm btn-rounded pl-2 pr-3" onClick={() => this.toggleShowAddProduct()}>
            <i className="fa fa-plus mr-1"></i> {I18n.t('BEE516' /* Adicionar Produto */)}
          </div>)}
        </div>
        <div className="table table-bordered">
          <table className={'table widget-table widget-table-rounded'}>
            <thead>
              <tr className="text-center f-s-14">
                <th width="5%">{I18n.t('BEE463' /* Linha */)}</th>
                <th width="35%">{I18n.t('BEE225' /* Produto */)}</th>
                <th width="10%">{I18n.t('BEE441' /* Quantidade */)}</th>
                <th width="10%">{I18n.t('BEE384' /* Unidade */)}</th>
                <th width="30%">{I18n.t('BEE135' /* Observação */)}</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, line) => (
                <tr className="text-center" key={line}>
                  <td>
                    <h5>{ editing
                            ? product.lineNumber + 1             
                            : line+1}</h5>
                    <button type="button" className="btn btn-primary mb-2" onClick={() => this.editProduct(product)}>
                      <i className="fa fa-edit"></i>
                    </button>
                    { !editing && (<button type="button" className="btn btn-danger" onClick={() => this.toggleDeleteModal(line)}>
                      <i className="fa fa-trash-alt"></i>
                    </button>)}
                  </td>
                  <td className="text-left">
                    <h4 className="widget-table-title">{product.product.productCode}</h4>
                    <p className="widget-table-desc m-b-15">{product.product.name}</p>
                    <p className="widget-table-desc m-b-15">{product.product.fullName}</p>
                  </td>
                  <td>
                    {FormatHelpers.formatNumber(product.quantity, 3, false)}
                  </td>
                  <td>
                    { this.props.unitsMeasure.find(u => u.code = product.unitMeasure).code }
                  </td>
                  <td>
                    {product.note}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        { showAddProduct && (<AddAddProduct 
          isOpen={showAddProduct} 
          toggleShowAddProduct={this.toggleShowAddProduct}
          addProduct={this.props.addProduct}
          unitsMeasure={this.props.unitsMeasure}
          editingProduct={this.state.editingProduct}
          updateProduct={this.props.updateProduct}
        />) }

        { showDeleteModal && (<SweetAlert
          danger
          showCancel
          cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
          confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title={I18n.t('BEE101' /* Você tem certeza? */)}
          onConfirm={() => this.confirmDelete()}
          onCancel={() => this.toggleDeleteModal()}
        >
          {I18n.t('BEE1052' /* O produto será eliminado ! */)}
        </SweetAlert>)}
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddProductsList));