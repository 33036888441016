import LabelsRequests from '../../server/labels';
import { addLoading, removeLoading } from './loading';

export const printEanLabel = (eanCode, printer, filters, amount) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printEanLabel(eanCode, printer, filters, amount);
  } finally {
    dispatch(removeLoading());
  }
};

export const printLotLabels = (lots, printer) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printLotLabels(lots, printer);
  } finally {
    dispatch(removeLoading());
  }
};

export const printPreConferenceLabel = (preConferenceLabelId, printerCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printPreConferenceLabel(preConferenceLabelId, printerCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const printConsolidationLabel = (printerCode, outboundOrderId, customerCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printConsolidationLabel(printerCode, outboundOrderId, customerCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const
  printBaptismLabel = (printerCode, baptismStart, baptismEnd, branchCode, typeBaptism) => async (dispatch) => {
    dispatch(addLoading());
    try {
      return await LabelsRequests.printBaptismLabel(printerCode, baptismStart, baptismEnd, branchCode, typeBaptism);
    } finally {
      dispatch(removeLoading());
    }
  };

export const printConferenceVolumeLabel = (printerCode, checkedVolumeIds) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printConferenceVolumeLabel(printerCode, checkedVolumeIds);
  } finally {
    dispatch(removeLoading());
  }
};

export const printKardexCableCutLabel = (printerCode, productCode, addressCode, lotCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printKardexCableCutLabel(printerCode, productCode, addressCode, lotCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const printAddressLabel = (
  branchCode,
  printerCode,
  filters,
  columnSide,
  printOrder,
  labelCode,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printAddressLabel(branchCode, printerCode, filters, columnSide, printOrder, labelCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const printLotWithStockBalance = (printerCode, filters, printOrder, columnSide) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printLotWithStockBalance(printerCode, filters, printOrder, columnSide);
  } finally {
    dispatch(removeLoading());
  }
};

export const printSerialGroup = (printerCode, serialGroupId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printSerialGroup(printerCode, serialGroupId);
  } finally {
    dispatch(removeLoading());
  }
};

export const reprintSerialGroup = (printerCode, serialGroupId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.reprintSerialGroup(printerCode, serialGroupId);
  } finally {
    dispatch(removeLoading());
  }
};

export const printConsolidationsLabel = (printerCode, outboundOrderId, showLoading = true) => async (dispatch) => {
  if (showLoading) dispatch(addLoading());
  try {
    return await LabelsRequests.printConsolidationsLabel(printerCode, outboundOrderId);
  } finally {
    if (showLoading) dispatch(removeLoading());
  }
};
export const getLabels = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.getLabels();
  } finally {
    dispatch(removeLoading());
  }
};

export const getLabelsByTrigger = (trigger) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.getLabelsByTrigger(trigger);
  } finally {
    dispatch(removeLoading());
  }
};

export const getLabel = (labelId, isNewVersion) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.getLabel(labelId, isNewVersion);
  } finally {
    dispatch(removeLoading());
  }
};

export const getLabelActive = (trigger) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.getLabelActive(trigger);
  } finally {
    dispatch(removeLoading());
  }
};

export const createLabel = (label) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.createLabel(label);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateLabel = (label) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.updateLabel(label);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateActiveLabel = (label) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.updateActiveLabel(label);
  } finally {
    dispatch(removeLoading());
  }
};

export const simulationLabel = (label) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.simulationLabel(label);
  } finally {
    dispatch(removeLoading());
  }
};
