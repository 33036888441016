import PackagesRequests from '../../server/packages';
import { addLoading, removeLoading } from './loading';

export const setPackages = (packages) => ({
  type: 'SET_PACKAGES',
  packages,
});

export const getPackagesList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const packages = await PackagesRequests.getPackages();
    dispatch(setPackages(packages));
  } finally {
    dispatch(removeLoading());
  }
};

export const createPackage = (newPackage) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newPck = await PackagesRequests.createPackage(newPackage);
    return newPck;
  } finally {
    dispatch(removeLoading());
  }
};

export const updatePackage = (updPackage) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PackagesRequests.updatePackage(updPackage);
  } finally {
    dispatch(removeLoading());
  }
};

export const getPackage = (packageId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PackagesRequests.getPackage(packageId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getPackagesOptionsWithProductEans = (productCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PackagesRequests.getPackagesOptionsWithProductEans(productCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const deletePackage = (packageId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PackagesRequests.deletePackage(packageId);
  } finally {
    dispatch(removeLoading());
  }
};
