import RestService from '../services/rest';

// GET
async function getListProductionOrders(filterStartDate, filterEndDate, page, pageSize, sorted, filtered) {
  return RestService.postAuthenticated('productionOrders/list', {
    filterStartDate, filterEndDate, page, pageSize, sorted, filtered,
  });
}

export default {
  getListProductionOrders,
};
