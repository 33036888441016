import RestService from '../services/rest';

// GET
async function getUser(userId) {
  return RestService.getAuthenticated(`users/detail?userId=${userId}`);
}

async function getUsers() {
  return RestService.getAuthenticated('users/list/all');
}

async function getUsersOptions() {
  return RestService.getAuthenticated('users/list/options');
}
async function getRangeUserList(params) {
  return RestService.getAuthenticated('users/list/optionsRange', params);
}

async function getUserPermissions() {
  return RestService.getAuthenticated('users/userPermissions');
}

async function getListUsersPicking(branchCode, rangeCode) {
  return RestService.getAuthenticated('users/listUsersPicking', { branchCode, rangeCode });
}

// POST
async function createUser(user) {
  return RestService.postAuthenticated('users/create', user);
}

async function deleteUser(userId) {
  return RestService.postAuthenticated('users/delete', { userId });
}

async function getPrinterList(tableParams) {
  return RestService.postAuthenticated('users/linkPrinter/list', tableParams);
}

async function createLinkPrinter(userId, printerCode) {
  return RestService.postAuthenticated('users/linkPrinter/create', { userId, printerCode });
}

async function deleteLinkPrinter(userPrinterId) {
  return RestService.postAuthenticated('users/linkPrinter/delete', { userPrinterId });
}

async function userConfirmation(login, password) {
  return RestService.postAuthenticated('users/confirmation', { login, password });
}

// PUT
async function updateUser(user) {
  return RestService.putAuthenticated('users/update', user);
}

async function updateStatus(user) {
  return RestService.putAuthenticated('users/update/status', user);
}

async function updatePassword(userUpd, newPass, expired = false) {
  const user = {
    id: userUpd.id,
    newPass,
    expired,
  };
  return RestService.putAuthenticated('users/update/password', user);
}

async function updateValid(userUpd, newValid) {
  const user = {
    id: userUpd.id,
    newValid,
  };
  return RestService.putAuthenticated('users/update/valid', user);
}

async function changePasswordLoggedOut(login, newPassword) {
  return RestService.putRest('users/changePasswordLoggedOut', { login, newPassword });
}

async function changePassword(login, userId, password, newPassword) {
  return RestService.putAuthenticated('users/changePassword', {
    login, userId, password, newPassword,
  });
}

export default {
  createUser,
  getUser,
  getUsers,
  getUsersOptions,
  updateUser,
  updatePassword,
  updateStatus,
  updateValid,
  deleteUser,
  createLinkPrinter,
  deleteLinkPrinter,
  getPrinterList,
  userConfirmation,
  getUserPermissions,
  getRangeUserList,
  getListUsersPicking,
  changePasswordLoggedOut,
  changePassword,
};
