import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import _ from 'lodash';

import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';

import ROUTES from '../../../config/routes';

import { getAllI18ns, deleteI18n } from '../../../app/store/actions/i18n';
import updateBeetranslate from '../../../app/store/actions/beetranslate';

class DevelopI18nList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
    };

    this.defaultSorted = [
      {
        id: 'key',
        desc: true,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showEdit(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem
                  onClick={() => this.copyCode(rows.row)}
                  updateBeetranslate
                >
                  {I18n.t('BEE226' /* Copiar Código */)}

                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE227' /* Chave */),
        accessor: 'key',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortMethod: (a, b) => (parseInt(a.replace('BEE', ''), 10) > parseInt(b.replace('BEE', ''), 10) ? 1 : -1),
      }, {
        Header: I18n.t('BEE228' /* Português */),
        accessor: 'ptBr',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE229' /* Espanhol */),
        accessor: 'esCh',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE230' /* Inglês */),
        accessor: 'enUs',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getAllI18n();
    } else {
      await this.getAllI18n();
    }
  };

  getAllI18n = async () => {
    await this.props.getI18nList();
  };

  showEdit = (row) => {
    this.props.history.push({
      pathname: ROUTES.DEVELOP_I18N_EDIT,
      state: {
        i18n: row._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  copyCode = (row) => {
    const el = document.createElement('textarea');
    const value = row.ptBr.includes('%{0}')
      ? `I18n.t('${row.key}', {  } /* ${row.ptBr} */)` : `I18n.t('${row.key}' /* ${row.ptBr} */)`;
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    addNotification('success', I18n.t('BEE231' /* Copiado para o clipboard */), value, 'top-right');
  };

  updateTranslates = async () => {
    try {
      await this.props.updateBeetranslate();
      await this.getAllI18n();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE236' /* Atualizar Idioma */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE2931' /* Atualização de idiomas */),
            I18n.t('BEE2930' /* Erro ao atualizar os idiomas */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE2931' /* Atualização de idiomas */),
          I18n.t('BEE2930' /* Erro ao atualizar os idiomas */),
          'top-right',
        );
      }
    }
  };

  render() {
    const { i18ns = [] } = this.props;
    const { page } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE48' /* Desenvolvedor */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE49' /* Idiomas */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE49' /* Idiomas */)}
          wikiHelp={ROUTES.DEVELOP_I18N_HELP}
          actionButtons={(
            <div className="ml-auto">
              <button
                onClick={this.updateTranslates}
                type="button"
                className="btn btn-primary btn-sm btn-rounded pl-2 pr-3"
              >
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE236' /* Atualizar Idioma */)}
              </button>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getAllI18n,
          }}
          filterable
          data={i18ns}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || (
            (!this.state.firstRun && this.props.location.state
              && this.props.location.state.dice && this.props.location.state.dice.page)
              ? this.props.location.state.dice.page : page)}
          onPageChange={(Page) => ((Page >= 0) ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  i18ns: state.i18nGrid && state.i18nGrid.i18ns,
});

const mapDispatchToProps = (dispatch) => ({
  getI18nList: () => dispatch(getAllI18ns()),
  deleteI18n: (key) => dispatch(deleteI18n(key)),
  updateBeetranslate: (data = {}) => dispatch(updateBeetranslate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DevelopI18nList));
