import React from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';
import SidebarNav from './sidebar-nav.jsx';
import SidebarProfile from './sidebar-profile.jsx';

class Sidebar extends React.Component {
  static contextType = PageSettings;

  render() {
    return (
      <PageSettings.Consumer>
        {({ toggleSidebarMinify, toggleMobileSidebar, pageSidebarTransparent }) => (
          <>
            <div id="sidebar" className={`sidebar ${pageSidebarTransparent ? 'sidebar-transparent' : ''}`}>
              <ScrollBar
                ref={(ref) => { this._scrollBarRef = ref; }}
                className="height-full"
                options={{ suppressScrollX: true }}
              >
                {this.context.pageSidebarProfile && (
                <SidebarProfile />
                )}
                <SidebarNav onUpdateSize={() => { this._scrollBarRef.updateScroll(); }} />
                <Link to="/" className="sidebar-minify-btn" onClick={toggleSidebarMinify}>
                  <i className="fa fa-angle-double-left" />
                </Link>
              </ScrollBar>
            </div>
            <div className="sidebar-bg" />
            <div className="sidebar-mobile-dismiss" onClick={toggleMobileSidebar} />
          </>
        )}
      </PageSettings.Consumer>
    );
  }
}

export default Sidebar;
