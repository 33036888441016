import LotsRequests from '../../server/lots';
import { addLoading, removeLoading } from './loading';

export const getLotInfo = () => async (dispatch) => {
  dispatch(addLoading())
  try {
    const info = await LotsRequests.getLotInfo()
    return info
  } finally {
    dispatch(removeLoading())
  }
}

export const getLots = ( branchCode) => async (dispatch) => {
  dispatch(addLoading())
  try {
    const result = await LotsRequests.getLots( branchCode)
    return result
  } finally {
    dispatch(removeLoading())
  }
}

export const getOptionsLots = ( branchCode) => async (dispatch) => {
  dispatch(addLoading())
  try {
    const result = await LotsRequests.getOptionsLots( branchCode)
    return result
  } finally {
    dispatch(removeLoading())
  }
}

export const generateLots = ( branchCode, quantity, initial) => async (dispatch) => {
  dispatch(addLoading())
  try {
    const result = await LotsRequests.generateLots( branchCode, quantity, initial)
    return result
  } finally {
    dispatch(removeLoading())
  }
}