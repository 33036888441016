import RestService from '../services/rest';

// GET
async function getApprovalLevelList() {
  return RestService.getAuthenticated('approvalLevel/list/all');
}

async function getApprovalLevelDetail(approvalLevelId) {
  return RestService.getAuthenticated('approvalLevel/list/detail', { approvalLevelId });
}

async function getApprovalLevelUser(approvalLevelId) {
  return RestService.getAuthenticated('approvalLevel/list/linkUsers', { approvalLevelId });
}

// DELETE
async function deleteApprovalLevel(approvalLevelId) {
  return RestService.deleteAuthenticated('approvalLevel/delete', { approvalLevelId });
}

async function deleteApprovalLevelUser(approvalLevelUserId) {
  return RestService.deleteAuthenticated('approvalLevel/delete/approvalLevelUser', { approvalLevelUserId });
}

// POST
async function createApprovalLevel(parametersForCreation) {
  return RestService.postAuthenticated('approvalLevel/create', parametersForCreation);
}

async function createApprovalLevelUser(parametersForCreation) {
  return RestService.postAuthenticated('approvalLevel/create/approvalLevelUser', parametersForCreation);
}

//PUT
async function updateApprovalLevel(approvalLevelId, branchCode, inventoryType, note) {
  return RestService.putAuthenticated('approvalLevel/update', { approvalLevelId, branchCode, inventoryType, note });
}

export default {
  getApprovalLevelList,
  deleteApprovalLevel,
  createApprovalLevel,
  getApprovalLevelDetail,
  getApprovalLevelUser,
  createApprovalLevelUser,
  deleteApprovalLevelUser,
  updateApprovalLevel,
};