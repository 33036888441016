import RestService from '../services/rest';

// GET
async function getAddressTypes() {
  return RestService.getAuthenticated('storageAddressTypes/list/all');
}

async function getAddressType(addressTypeId) {
  return RestService.getAuthenticated(`storageAddressTypes/detail?addressTypeId=${addressTypeId}`);
}

// POST
async function createAddressType(newAddressType) {
  return RestService.postAuthenticated('storageAddressTypes/create', newAddressType);
}

async function deleteAddressType(addressTypeId) {
  return RestService.postAuthenticated('storageAddressTypes/delete', { addressTypeId });
}

// PUT
async function updateAddressType(updAddressType) {
  return RestService.putAuthenticated('storageAddressTypes/update', updAddressType);
}

export default {
  getAddressTypes,
  createAddressType,
  updateAddressType,
  getAddressType,
  deleteAddressType
};
