import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import {
  getDockCarriers, linkDockCarrier, searchCarriers, unlinkDockCarrier,
} from '../../../../app/store/actions/dockCarriers';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';
import ROUTES from '../../../../config/routes';

class DockLinkCarrierForm extends React.Component {
  constructor(props) {
    super(props);

    this.tableCarrierListColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => {
          const carrierCode = rows.row._original.code;
          const isLinked = this.state.dockCarrierList.find((dc) => dc.carrierCode === carrierCode);

          if (isLinked) {
            return (
              <div style={{ textAlign: 'center' }}>
                <button
                  type="button"
                  className="btn btn-default btn-xs"
                  onClick={() => this.unlinkDockCarrier(rows.row._original.code)}
                >
                  {I18n.t('BEE608' /* Desvincular */)}
                </button>
              </div>
            );
          }
          return (
            <div style={{ textAlign: 'center' }}>
              <button
                type="button"
                className="btn btn-default btn-xs"
                onClick={() => this.linkDockCarrier(rows.row._original.code)}
              >
                {I18n.t('BEE601' /* Vincular */)}
              </button>
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE85' /* CNPJ */),
        accessor: 'cnpj',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
    ];

    this.tableCarrierDocksColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button
              type="button"
              className="btn btn-default btn-xs"
              onClick={() => { this.showUnlinkModal(rows.row._original); }}
            >
              {I18n.t('BEE608' /* Desvincular */)}
            </button>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'carrierCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE85' /* CNPJ */),
        accessor: 'carrierCnpj',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      },
    ];

    this.state = {
      dockLabel: '',
      showAddModal: false,
      dockCarrierList: [],
      carriersList: [],
      dockCarrierSelected: null,
      showUnlinkModal: false,
    };
  }

  async componentDidMount() {
    await this.getBaseInfo();
  }

  async getBaseInfo() {
    if (this.props.location.state.dock) {
      const { dock } = this.props.location.state;
      const dockLabel = `${dock.code} - ${dock.name}`;

      const baseInfo = await this.props.getDockCarriers(dock.code);

      this.setState({
        dockLabel,
        dock,
        dockCarrierList: baseInfo.docks,
      });
    }
  }

  searchCarriers = async () => {
    try {
      const carriersList = await this.props.searchCarriers();

      if (carriersList.length) {
        this.setState({
          carriersList,
          showAddModal: true,
        });
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1163' /* Vincular Transportadora */),

          I18n.t('BEE1159' /* Nenhum item encontrado! */),

          'top-right',
        );
      }
    } catch (error) {
      addNotification(
        'danger',
        I18n.t('BEE1163' /* Vincular Transportadora */),

        I18n.t('BEE1176' /* Erro ao vincular transportadora! */),

        'top-right',
      );
    }
  };

  linkDockCarrier = async (carrierCode) => {
    const dockCode = this.state.dock.code;

    try {
      await this.props.linkDockCarrier(carrierCode, dockCode);
      await this.getBaseInfo();

      addNotification(
        'success',
        I18n.t('BEE1163' /* Vincular Transportadora */),

        I18n.t('BEE1155', { 0: dockCode, 1: carrierCode } /* Doca %{0} vinculada com sucesso a transportadora %{1}! */),

        'top-right',
      );
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1163' /* Vincular Transportadora */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1163' /* Vincular Transportadora */),

            I18n.t('BEE1176' /* Erro ao vincular transportadora! */),

            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1163' /* Vincular Transportadora */),

          I18n.t('BEE1176' /* Erro ao vincular transportadora! */),

          'top-right',
        );
      }
    }
  };

  linkAll = async () => {
    const { dockCarrierList } = this.state;
    const carriersList = this.reactTable.getResolvedState().sortedData;

    const unlinkedCarriers = carriersList.filter((c) => !dockCarrierList.find((dc) => dc.carrierCode === c.code));
    for (const carrier of unlinkedCarriers) {
      await this.linkDockCarrier(carrier.code);
    }
  };

  unlinkDockCarrier = async (carrierCode) => {
    const dockCode = this.state.dock.code;
    const { dockCarrierList } = this.state;

    const dockCarrierSelected = dockCarrierList.find(
      (dc) => dc.dockCode === dockCode && dc.carrierCode === carrierCode,
    );

    try {
      await this.props.unlinkDockCarrier(dockCarrierSelected.id);
      await this.getBaseInfo();

      addNotification(
        'success',
        I18n.t('BEE1163' /* Vincular Transportadora */),

        I18n.t('BEE1157', { 0: dockCode, 1: carrierCode } /* Doca %{0} desnviculada da transportadora %{1} */),

        'top-right',
      );
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1163' /* Vincular Transportadora */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1163' /* Vincular Transportadora */),

            I18n.t('BEE1176' /* Erro ao vincular transportadora! */),

            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1163' /* Vincular Transportadora */),

          I18n.t('BEE1176' /* Erro ao vincular transportadora! */),

          'top-right',
        );
      }
    }
  };

  closeAddModal = () => {
    this.setState({
      showAddModal: false,
      carriersList: [],
    });
  };

  showUnlinkModal = (dockCarrierSelected) => {
    this.setState({
      dockCarrierSelected,
      showUnlinkModal: true,
    });
  };

  closeUnlinkModal = () => {
    this.setState({
      dockCarrierSelected: null,
      showUnlinkModal: false,
    });
  };

  confirmUnlinkModal = async () => {
    const { dockCarrierSelected } = this.state;
    this.closeUnlinkModal();
    await this.unlinkDockCarrier(dockCarrierSelected.carrierCode);
  };

  render() {
    const {
      dockLabel, showAddModal, dockCarrierList, carriersList,
      showUnlinkModal, dockCarrierSelected,
    } = this.state;

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE19' /* Docas */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE2377' /* Vincular Transportadoras */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE2377' /* Vincular Transportadoras */)}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form onSubmit={(e) => e.preventDefault()}>
              <Table
                headerTitle={dockLabel}
                actionButtons={(
                  <>
                    <div className="ml-auto btn btn-success btn-sm btn-rounded pl-2 pr-3" onClick={this.searchCarriers}>
                      <i className="fa fa-link mr-1" />
                      {' '}
                      {I18n.t('BEE2377' /* Vincular Transportadoras */)}
                    </div>
                    <Link to={{ pathname: ROUTES.DOCKS_LIST, state: { dice: this.props.location.dice } }}>
                      <OneClickButton
                        type="submit"
                        className="btn btn-sm btn-danger btn-rounded ml-3"
                      >
                        {I18n.t('BEE137' /* Voltar */)}
                      </OneClickButton>
                    </Link>
                  </>
                )}
                panelHeaderProps={{
                  onClickReload: () => this.getBaseInfo(),
                }}
                filterable
                data={dockCarrierList}
                columns={this.tableCarrierDocksColumns}
                expander
                defaultPageSize={10}
                defaultSorted={this.defaultSorted}
                defaultFilterMethod={(filter, row) => {
                  const input = _.lowerCase(filter.value);
                  const value = _.lowerCase(row[filter.id]);
                  if (_.includes(value, input)) {
                    return true;
                  }
                }}
              />
            </form>
          </div>
        </div>
        <Modal size="xl" isOpen={showAddModal} toggle={() => this.closeAddModal()}>
          <ModalHeader toggle={() => this.closeAddModal()}>{I18n.t('BEE1178' /* Buscar Transportadora */)}</ModalHeader>
          <ModalBody>
            <Table
              filterable
              ref={(r) => {
                this.reactTable = r && r.reactTable;
              }}
              data={carriersList}
              columns={this.tableCarrierListColumns}
              expander
              defaultPageSize={5}
              defaultSorted={this.defaultSorted}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          </ModalBody>
          <ModalFooter>
            <OneClickButton
              type="button"
              className="btn btn-120 btn-white p-5 m-5"
              onClick={() => this.closeAddModal()}
            >
              {I18n.t('BEE553' /* Fechar */)}
            </OneClickButton>
            <OneClickButton
              type="button"
              className="btn btn-120 btn-primary p-5 m-5"
              onClick={() => this.linkAll()}
            >
              {I18n.t('BEE1158' /* Vincular Todas */)}
            </OneClickButton>
          </ModalFooter>
        </Modal>
        {(showUnlinkModal
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.confirmUnlinkModal()}
              onCancel={() => this.closeUnlinkModal()}
            >
              {I18n.t(
                'BEE1162',
                { 0: dockCarrierSelected.dockCode, 1: dockCarrierSelected.carrierCode },
              /* A doca %{0} será desvinculada da transportadora %{1}! */)}
            </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getDockCarriers: (carrierCode) => dispatch(getDockCarriers(carrierCode)),
  searchCarriers: () => dispatch(searchCarriers()),
  linkDockCarrier: (carrierCode, dockCode) => dispatch(linkDockCarrier(carrierCode, dockCode)),
  unlinkDockCarrier: (carrierCode, dockCode) => dispatch(unlinkDockCarrier(carrierCode, dockCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DockLinkCarrierForm));
