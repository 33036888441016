import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { generateReport } from '../../app/store/actions/reports';
import { getStorageAddressTypesOptions } from '../../app/store/actions/storageAdressTypes';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import Select from '../../components/form/select';
import DualList from '../../components/pages/DualList';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportStockAllocationAddress extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
                </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE32' /* Estoque */) },
      { value: I18n.t('BEE2030' /* Alocação de Endereço */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE145' /* Filial */),
        value: 'branchCode',
      }, {
        label: I18n.t('BEE581' /* Range */),
        value: 'range',
      }, {
        label: I18n.t('BEE267' /* Endereço */),
        value: 'addressCode',
      }, {
        label: I18n.t('BEE1854' /* Setor */),
        value: 'sector',
      }, {
        label: I18n.t('BEE121' /* Rua */),
        value: 'street',
      }, {
        label: I18n.t('BEE281' /* Coluna */),
        value: 'column',
      }, {
        label: I18n.t('BEE283' /* Nível */),
        value: 'level',
      }, {
        label: I18n.t('BEE285' /* Gaveta */),
        value: 'drawer',
      }, {
        label: I18n.t('BEE355' /* Curva */),
        value: 'curve',
      }, {
        label: `${I18n.t('BEE200' /* Tipo */)} ${I18n.t('BEE267' /* Endereço */)}`,
        value: 'type',
      }, {
        label: I18n.t('BEE225' /* Produto */),
        value: 'productCode',
      }, {
        label: I18n.t('BEE2008' /* Quantidade Alocada */),
        value: 'quantity',
      }, {
        label: I18n.t('BEE428' /* Lote */),
        value: 'lotNumber',
      }, {
        label: I18n.t('BEE2035' /* Data Vencimento */),
        value: 'expirationDate',
      }, {
        label: I18n.t('BEE443' /* Documento */),
        value: 'orderNumber',
      },
    ];

    this.state = {
      selected: [],
      branchCodeFrom: '',
      branchCodeTo: '',
      rangeFrom: '',
      rangeTo: '',
      sectorFrom: '',
      sectorTo: '',
      streetFrom: '',
      streetTo: '',
      columnFrom: '',
      columnTo: '',
      levelFrom: '',
      levelTo: '',
      drawerFrom: '',
      drawerTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      type: '',
      curve: [],
      F: '',
      M: '',
      R: '',
    };
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = async () => {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      rangeFrom,
      rangeTo,
      sectorFrom,
      sectorTo,
      streetFrom,
      streetTo,
      columnFrom,
      columnTo,
      levelFrom,
      levelTo,
      drawerFrom,
      drawerTo,
      productCodeFrom,
      productCodeTo,
      F,
      M,
      R,
    } = this.state;

    const { location } = this.props;

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const curve = [];
    if (F) curve.push('F');
    if (M) curve.push('M');
    if (R) curve.push('R');

    const whereStockAllocation = await returnFiltersByRules([
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
    ]);

    const whereAddress = await returnFiltersByRules([
      ['rangeCode', { type: 'between', value: [rangeFrom, rangeTo] }],
      ['sector', { type: 'between', value: [sectorFrom.toLocaleUpperCase(), sectorTo.toLocaleUpperCase()] }],
      ['street', { type: 'between', value: [streetFrom.toLocaleUpperCase(), streetTo.toLocaleUpperCase()] }],
      ['column', { type: 'between', value: [columnFrom.toLocaleUpperCase(), columnTo.toLocaleUpperCase()] }],
      ['level', { type: 'between', value: [levelFrom.toLocaleUpperCase(), levelTo.toLocaleUpperCase()] }],
      ['drawer', { type: 'between', value: [drawerFrom.toLocaleUpperCase(), drawerTo.toLocaleUpperCase()] }],
      ['curve', { type: 'in', value: curve }],
    ]);

    const filters = { whereStockAllocation, whereAddress };

    this.props.generateReport(
      I18n.t('BEE2030' /* Alocação de Endereço */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  onSelectFilter = (newState) => this.setState({ ...newState });

  render() {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      rangeFrom,
      rangeTo,
      sectorFrom,
      sectorTo,
      streetFrom,
      streetTo,
      columnFrom,
      columnTo,
      levelFrom,
      levelTo,
      drawerFrom,
      drawerTo,
      productCodeFrom,
      productCodeTo,
      F,
      M,
      R,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE2030' /* Alocação de Endereço */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: rangeFrom, to: rangeTo },
                    { from: 'rangeFrom', to: 'rangeTo' },
                    `${I18n.t('BEE581' /* Range */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: sectorFrom.toLocaleUpperCase(), to: sectorTo.toLocaleUpperCase() },
                    { from: 'sectorFrom', to: 'sectorTo' },
                    `${I18n.t('BEE1854' /* Setor */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: streetFrom.toLocaleUpperCase(), to: streetTo.toLocaleUpperCase() },
                    { from: 'streetFrom', to: 'streetTo' },
                    `${I18n.t('BEE121' /* Rua */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: columnFrom.toLocaleUpperCase(), to: columnTo.toLocaleUpperCase() },
                    { from: 'columnFrom', to: 'columnTo' },
                    `${I18n.t('BEE281' /* Coluna */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: levelFrom.toLocaleUpperCase(), to: levelTo.toLocaleUpperCase() },
                    { from: 'levelFrom', to: 'levelTo' },
                    `${I18n.t('BEE283' /* Nível */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: drawerFrom.toLocaleUpperCase(), to: drawerTo.toLocaleUpperCase() },
                    { from: 'drawerFrom', to: 'drawerTo' },
                    `${I18n.t('BEE285' /* Gaveta */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE27' /* Produtos */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createCheckBoxes([{
                    label: 'F',
                    value: F,
                    attr: 'F',
                  }, {
                    label: 'M',
                    value: M,
                    attr: 'M',
                  }, {
                    label: 'R',
                    value: R,
                    attr: 'R',
                  }], `${I18n.t('BEE355' /* Curva */)}:`),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    data, isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',
    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
  getStorageAddressTypesOptions: () => dispatch(getStorageAddressTypesOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportStockAllocationAddress);
