import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import OneClickButton from '../../../../components/form/button';
import { PanelPage } from '../../../../components/pages/pages';
import Form from '../../../../components/form/form';
import addNotification from '../../../../components/notification';
import ROUTES from '../../../../config/routes';
import {
  createTransportEquipment, getTransportEquipment, updateTransportEquipment,
} from '../../../../app/store/actions/transportEquipment';

class TransportEquipmentForm extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            { I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE1578' /* Equipamento */) },
      { value: I18n.t('BEE1397' /* Equipamentos de Transportes */) },
      { value: '', active: true },
    ];
    this.state = {
      mode: 'detail',
      code: '',
      brand: '',
      model: '',
      assetNumber: '',
      serialNumber: '',
      active: '',
      note: '',
      disableCode: true,
      disableBrand: true,
      disableModel: true,
      disableAssetNumber: true,
      disableSerialNumber: true,
      disableActive: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      optionsActive: [
        { value: 0, label: I18n.t('BEE173' /* Não */) },
        { value: 1, label: I18n.t('BEE172' /* Sim */) },
      ],
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.dataSearch();
    }
  }

  dataSearch = async () => {
    if (this.props.location.state.transportEquipment) {
      const { transportEquipment } = this.props.location.state;
      const transportEquipmentSelected = await this.props.getTransportEquipment(transportEquipment.id);
      if (transportEquipmentSelected) {
        this.setState({
          transportEquipmentId: transportEquipmentSelected.id,
          code: transportEquipmentSelected.code,
          brand: transportEquipmentSelected.brand,
          model: transportEquipmentSelected.model,
          assetNumber: transportEquipmentSelected.assetNumber,
          serialNumber: transportEquipmentSelected.serialNumber,
          active: transportEquipmentSelected.active,
          note: transportEquipmentSelected.note,
        });
      }
    }
  };

  setMode = () => {
    let mode = '';
    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableBrand: false,
        disableModel: false,
        disableAssetNumber: false,
        disableSerialNumber: false,
        disableActive: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableBrand: false,
        disableModel: false,
        disableAssetNumber: false,
        disableSerialNumber: false,
        disableActive: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  create = async () => {
    const {
      code, brand, model, assetNumber, serialNumber, active, note, optionsActive,
    } = this.state;
    const tempActive = (active === '') ? optionsActive[0].value : active;
    try {
      const newAddressSize = await this.props.createTransportEquipment({
        code,
        brand,
        model,
        assetNumber,
        serialNumber,
        active: tempActive,
        note,
      });

      if (newAddressSize) {
        await addNotification(
          'success',
          I18n.t('BEE1743' /* Novo equipamento de transporte */),
          I18n.t('BEE1744', { 0: code } /* Novo equipamento de transporte %{0} registrado com sucesso! */),

          'top-right',
        );

        this.props.history.push(ROUTES.TRANSPORT_EQUIP_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
        await addNotification(
          'danger',
          I18n.t('BEE1743' /* Novo equipamento de transporte */),
          err.response.data.error.message,
          'top-right',
        );
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE1743' /* Novo equipamento de transporte */),
          I18n.t('BEE1745', { 0: code } /* Erro ao registrar o equipamento de transporte %{0} */),

          'top-right',
        );
      }
    }
  };

  update = async () => {
    const {
      transportEquipmentId, code, brand, model, assetNumber, serialNumber, active, note,
    } = this.state;

    try {
      const updTransportEquipment = await this.props.updateTransportEquipment({
        transportEquipmentId,
        code,
        brand,
        model,
        assetNumber,
        serialNumber,
        active,
        note,
      });

      if (updTransportEquipment) {
        await addNotification(
          'success',
          I18n.t('BEE1736' /* Atualizar Equipamento de Transporte */),
          I18n.t('BEE1738', { 0: code } /* Equipamento de transporte %{0} atualizado com sucesso! */),

          'top-right',
        );

        this.props.history.push(ROUTES.TRANSPORT_EQUIP_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
        await addNotification(
          'danger',
          I18n.t('BEE1736' /* Atualizar Equipamento de Transporte */),
          err.response.data.error.message,
          'top-right',
        );
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE1736' /* Atualizar Equipamento de Transporte */),

          I18n.t('BEE1737', { 0: code } /* Erro ao atualizar  equipamento de transporte %{0} */),

          'top-right',
        );
      }
    }
  };

  render() {
    const {
      code, brand, model, assetNumber, serialNumber, active, note, optionsActive,
    } = this.state;

    const {
      disableCode, disableBrand, disableModel, disableAssetNumber, disableSerialNumber, disableActive, disableNote,
      mode, showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';
    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE1731' /* Novo Equipamento de Transporte */);
      this.breadcrumb[4].value = '';
    } else if (mode === 'edit') {
      title = I18n.t('BEE1732' /* Editar Equipamento de Transporte */);
      this.breadcrumb[4].value = `${code} - ${note}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE1733' /* Detalhes do Equipamento de Transporte */);
      this.breadcrumb[4].value = `${code} - ${note}`;
    }

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={title}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInput(
                    code,
                    'code',
                    `${I18n.t('BEE82' /* Código */)}:`,
                    I18n.t('BEE3686' /* Informe o código */),
                    'text',
                    true,
                    disableCode,
                  ),
                  formContext.createInput(
                    note,
                    'note',
                    `${I18n.t('BEE277' /* Descrição */)}:`,
                    I18n.t('BEE3477' /* Informe a descrição */),
                    'text',
                    true,
                    disableNote,
                  ),
                  formContext.createInput(
                    brand,
                    'brand',
                    `${I18n.t('BEE1700' /* Marca */)}:`,
                    I18n.t('BEE3727' /* Informe a marca */),
                    'text',
                    true,
                    disableBrand,
                  ),
                  formContext.createInput(
                    model,
                    'model',
                    `${I18n.t('BEE1701' /* Modelo */)}:`,
                    I18n.t('BEE3728' /* Informe o modelo */),
                    'text',
                    true,
                    disableModel,
                  ),
                  formContext.createInput(
                    assetNumber,
                    'assetNumber',
                    `${I18n.t('BEE1702' /* Número Patrimonial */)}:`,
                    '',
                    'text',
                    false,
                    disableAssetNumber,
                  ),
                  formContext.createInput(
                    serialNumber,
                    'serialNumber',
                    `${I18n.t('BEE501' /* Número Série */)}:`,
                    '',
                    'text',
                    false,
                    disableSerialNumber,
                  ),
                  formContext.createSelect(
                    active,
                    'active',
                    `${I18n.t('BEE499' /* Ativo */)}:`,
                    optionsActive,
                    disableActive,
                    undefined,
                    undefined,
                    undefined,
                    true,
                  ),
                ])}
              />
            </div>
          </div>
        )}
        footerContent={(
          <>
            <Link to={{ pathname: ROUTES.TRANSPORT_EQUIP_LIST, state: { dice: this.props.location.dice } }}>
              {(showCancel
                ? (
                  <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                    {I18n.t('BEE99' /* Cancelar */)}
                  </OneClickButton>
                )
                : (
                  <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                    {I18n.t('BEE137' /* Voltar */)}
                  </OneClickButton>
                ))}
            </Link>
            {(showCreate
              && (
              <OneClickButton
                type="button"
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={() => this.create()}
              >
                {I18n.t('BEE138' /* Criar */)}
              </OneClickButton>
              )
            )}
            {(showUpdate
              && (
              <OneClickButton
                type="button"
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={() => this.update()}
              >
                {I18n.t('BEE139' /* Atualizar */)}
              </OneClickButton>
              )
            )}
          </>
        )}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  updateTransportEquipment: (transportEquipment) => dispatch(updateTransportEquipment(transportEquipment)),
  createTransportEquipment: (transportEquipment) => dispatch(createTransportEquipment(transportEquipment)),
  getTransportEquipment: (transportEquipmentId) => dispatch(getTransportEquipment(transportEquipmentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TransportEquipmentForm));
