const INITIAL_STATE = {
  departmentsList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_DEPARTMENTS_LIST':
      return { ...state, departmentsList: action.departments };
    default:
      return state;
  }
};
