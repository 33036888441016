import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { PanelPage } from '../../components/pages/pages';
import OneClickButton from '../../components/form/button';
import DualList from '../../components/pages/DualList';
import Form from '../../components/form/form';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportAdminGeneralPriorities extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          { I18n.t('BEE12' /* Início */)}
                </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE14' /* Gerais */) },
      { value: I18n.t('BEE626' /* Prioridades */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE82' /* Código */),
        value: 'code',
      }, {
        label: I18n.t('BEE83' /* Nome */),
        value: 'name',
      }, {
        label: I18n.t('BEE628' /* Faturamento Posterior */),
        value: 'afterBilling',
      }, {
        label: I18n.t('BEE629' /* Considerar Data */),
        value: 'considerDate',
      }, {
        label: I18n.t('BEE630' /* Faturamento Loja */),
        value: 'storeBilling',
      }, {
        label: I18n.t('BEE631' /* Inativa */),
        value: 'disabled',
      }, {
        label: I18n.t('BEE632' /* Ignora Plano de Corte */),
        value: 'ignoreCutPlan',
      }, {
        label: I18n.t('BEE633' /* Retira */),
        value: 'withdraw',
      }, {
        label: I18n.t('BEE649' /* Fura fila */),
        value: 'sticksRow',
      }, {
        label: I18n.t('BEE135' /* Observação */),
        value: 'note',
      }, {
        label: I18n.t('BEE575' /* Usuário de criação */),
        value: 'createdUser',
      }, {
        label: I18n.t('BEE578' /* Data de criação */),
        value: 'createdAt',
      }, {
        label: I18n.t('BEE576' /* Usuário de alteração */),
        value: 'updatedUser',
      }, {
        label: I18n.t('BEE577' /* Data de alteração */),
        value: 'updatedAt',
      },
    ];

    this.state = {
      selected: [],
      codeFrom: '',
      codeTo: '',
      nameFrom: '',
      nameTo: '',
      afterBilling: '',
      considerDate: '',
      storeBilling: '',
      disabled: '',
      ignoreCutPlan: '',
      withdraw: '',
      sticksRow: '',
    };
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      codeFrom,
      codeTo,
      nameFrom,
      nameTo,
      afterBilling,
      considerDate,
      storeBilling,
      disabled,
      ignoreCutPlan,
      withdraw,
      sticksRow,
    } = this.state;
    const { location } = this.props;

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['afterBilling', { type: 'and', value: afterBilling }],
      ['considerDate', { type: 'and', value: considerDate }],
      ['storeBilling', { type: 'and', value: storeBilling }],
      ['disabled', { type: 'and', value: disabled }],
      ['ignoreCutPlan', { type: 'and', value: ignoreCutPlan }],
      ['withdraw', { type: 'and', value: withdraw }],
      ['sticksRow', { type: 'and', value: sticksRow }],
      ['name', { type: 'between', value: [nameFrom, nameTo] }],
      ['code', { type: 'between', value: [codeFrom, codeTo] }],
    ]);

    this.props.generateReport(
      I18n.t('BEE626' /* Prioridades */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  onSelectFilter = (newState) => this.setState({ ...newState });

  render() {
    const {
      selected,
      codeFrom,
      codeTo,
      nameFrom,
      nameTo,
      afterBilling, // faturamento posterior
      considerDate, // considerar data
      storeBilling, // faturamento loja
      disabled, // inativa
      ignoreCutPlan, // ignorar plano de corte
      withdraw, // withdraw
      sticksRow, // fura fila
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE626' /* Prioridades */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: codeFrom, to: codeTo },
                    { from: 'codeFrom', to: 'codeTo' },
                    `${I18n.t('BEE82' /* Código */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: nameFrom, to: nameTo },
                    { from: 'nameFrom', to: 'nameTo' },
                    `${I18n.t('BEE83' /* Nome */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  // Faturamento Posterior
                  formContext.createCheckBoxes([
                    {
                      value: afterBilling,
                      attr: 'afterBilling',
                    },
                  ], `${I18n.t('BEE628' /* Faturamento Posterior */)}:`),

                  // Considerar Data
                  formContext.createCheckBoxes([
                    {
                      value: considerDate,
                      attr: 'considerDate',
                    },
                  ], `${I18n.t('BEE629' /* Considerar Data */)}:`),

                  // Faturamento Loja
                  formContext.createCheckBoxes([
                    {
                      value: storeBilling,
                      attr: 'storeBilling',
                    },
                  ], `${I18n.t('BEE630' /* Faturamento Loja */)}:`),

                  // Inativa
                  formContext.createCheckBoxes([
                    {
                      value: disabled,
                      attr: 'disabled',
                    },
                  ], `${I18n.t('BEE631' /* Inativa */)}:`),

                  // Ignora Plano de Corte
                  formContext.createCheckBoxes([
                    {
                      value: ignoreCutPlan,
                      attr: 'ignoreCutPlan',
                    },
                  ], `${I18n.t('BEE632' /* Ignora Plano de Corte */)}:`),

                  // Retira
                  formContext.createCheckBoxes([
                    {
                      value: withdraw,
                      attr: 'withdraw',
                    },
                  ], `${I18n.t('BEE633' /* Retira */)}:`),

                  // Fura fila
                  formContext.createCheckBoxes([
                    {
                      value: sticksRow,
                      attr: 'sticksRow',
                    },
                  ], `${I18n.t('BEE649' /* Fura fila */)}:`),

                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
                )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
                )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportAdminGeneralPriorities);
