import React from 'react';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { getOptionsBranchesAndDeposits } from '../../../app/store/actions/branches';
import { getValidateEan } from '../../../app/store/actions/eans';
import { setNewQuantityToStock } from '../../../app/store/actions/stocks';
import { getStorageAddressesOptions } from '../../../app/store/actions/storageAddresses';
import OneClickButton from '../../../components/form/button';
import Select from '../../../components/form/select';
import addNotification from '../../../components/notification';
import { PanelPage } from '../../../components/pages/pages';
import ROUTES from '../../../config/routes';

class PhysicalInventory extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE572' /* Inventário */) },
      { value: I18n.t('BEE2265' /* Inventario Físico */), active: true },
    ];

    this.state = {
      branchCode: null,
      branchName: '',
      listBranch: [],
      disableBranch: false,
      addressId: null,
      addressCode: null,
      addressName: '',
      listAddress: [],
      disableAddress: true,
      depositCode: null,
      depositName: '',
      listDeposit: [],
      disableDeposit: true,
      ean: '',
      disabledEan: true,
      lotNumber: null,
      disabledLotNumber: true,
      expirationDate: null,
      disabledExpirationDate: true,
      quantity: null,
      disabledQuantity: true,
      bolPhysicalInventory: false,
    };

    this.initialState = this.state;

    this.colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, {
        isDisabled, isSelected,
      }) => ({
        ...styles,
        backgroundColor: isDisabled ? 'grey' : 'white',
        color: isDisabled ? 'grey' : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        textAlign: 'left',

        ':hover': {
          ...styles[':hover'],
          backgroundColor: isSelected ? 'grey' : '#eb900a',
          color: 'white',
        },
      }),
    };
  }

  componentDidMount() {
    document.getElementById(1).focus();
    this.getBranchesOptions();
  }

  goToElement = (e, id, attr) => {
    if (e.keyCode === 13) {
      if (attr === 'ean') {
        this.getValidateEan();
      } else if (attr === 'lotNumber' && this.state.disabledExpirationDate) {
        document.getElementById(id + 2).focus();
        document.getElementById(id + 2).select();
      } else if (attr !== 'quantity') {
        document.getElementById(id + 1).focus();
        if (id + 1 > 2) document.getElementById(id + 1).select();
      }
    }
  };

  setValue = async (attr, value, reset = false) => {
    this.setState({
      [`${attr}`]: value,
    });
    if (attr === 'ean' && reset && this.state.ean) {
      this.setState({
        disabledEan: false,
        lotNumber: null,
        disabledLotNumber: true,
        expirationDate: null,
        disabledExpirationDate: true,
        quantity: null,
        disabledQuantity: true,
      });
    }
  };

  createNumberFormat = (value, attr, label, disabled = false, decimals, keypressFunction = undefined, id = null) => (
    <div className="form-group text-left">
      <label htmlFor={id && id}>{label}</label>
      <NumberFormat
        onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
        id={id && id}
        className="form-control"
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={(!this.props.fractionalQuantity) ? 0 : decimals}
        onValueChange={(values) => {
          this.setValue(attr, Math.abs(values.floatValue));
        }}
        value={(value || value === 0) ? value : ''}
        fixedDecimalScale
        allowNegative={false}
        disabled={disabled}
        allowEmptyFormatting
        isAllowed={(valueToValidate) => (Math.sign(valueToValidate.floatValue) !== -1)}
      />
    </div>
  );

  customDateStyle = ({ className, children }) => (
    <div>
      <div className="bg-primary rounded p-2">
        <h5 className="text-light text-center pt-2">
          Bee
          <span className="text-dark">Stock</span>
        </h5>
      </div>
      <div className={className}>
        <div>{children}</div>
      </div>
    </div>
  );

  createDateInput = (value, attr, label, disabled, keypressFunction = undefined, id = null) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={id && id}>{label}</label>
      <div className="add_display_flex">
        <DatePicker
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          isClearable
          selected={value !== null && typeof value === 'string' ? new Date(value) : value}
          className="form-control"
          dateFormat="dd/MM/yyyy"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e)}
          calendarContainer={this.customDateStyle}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={id && id}>{label}</label>
      <input
        onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
        id={id && id}
        type={type}
        className="form-control"
        value={value || ''}
        onChange={(e) => this.setValue(attr, e.target.value)}
        placeholder={disabled ? '' : placeholder}
        required={required}
        disabled={disabled}
      />
    </div>
  );

  createInputButton = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
    buttonLabel,
    buttonFunction,
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={id && id}>{label}</label>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.setValue(attr, '', true)}
            aria-label="Clear"
          >
            <i className="fa fa-times" />
          </button>
          {buttonLabel
            && (
              <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
                {buttonLabel}
              </button>
            )}
        </div>
      </div>
    </div>
  );

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
    if (attr === 'deposit') {
      this.setState({
        disableAddress: false,
      });
    }
    if (attr === 'branch') {
      if (!value) {
        this.setState((prevState) => ({
          ...prevState.initialState,
          listBranch: [...prevState.listBranch],
        }));
      } else {
        const branch = this.state.listBranch.find((element) => element.value === value.value);

        this.setState((prevState) => ({
          depositCode: '',
          depositName: '',
          addressCode: '',
          addressName: '',
          disableAddress: false,
          ...(!prevState.bolPhysicalInventory
            ? { listDeposit: [{ value: branch.deposit.value, label: branch.deposit.label }] }
            : {}),
        }));
        this.getStorageAddressesOptions();
      }
    } else if (attr === 'address') {
      if (!value) {
        this.setState({
          [`${attr}Code`]: value ? value.code : null,
          [`${attr}Id`]: value ? value.value : null,
          [`${attr}Name`]: value ? value.label : '',
          disabledEan: true,
          ean: '',
        });
      } else {
        this.setState({
          [`${attr}Code`]: value ? value.code : null,
          [`${attr}Id`]: value ? value.value : null,
          [`${attr}Name`]: value ? value.label : '',
          disabledEan: false,
          ean: '',
          lotNumber: null,
          disabledLotNumber: true,
          expirationDate: null,
          disabledExpirationDate: true,
          quantity: null,
          disabledQuantity: true,
        });
      }
    }
  };

  createSelectDropDown = (value, attr, label, items, disabled, keypressFunction = undefined, id) => (
    <div className="form-group m-b-15 text-left">
      <label htmlFor={id && id}>{label}</label>
      <div>
        <Select
          id={id && id}
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          value={value.label ? value : ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={this.colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          isDisabled={disabled}
        />
      </div>
    </div>
  );

  getValidateEan = async () => {
    const { ean } = this.state;
    try {
      const result = await this.props.getValidateEan(ean);
      if (result && result.ean) {
        this.setState({
          disabledEan: true,
          disabledLotNumber: result.stockControlType !== 3,
          disabledExpirationDate: !(result.controlExpirationDate),
          disabledQuantity: false,
        });
        let id;
        if (result.stockControlType === 3) {
          id = 4;
        } else if (result.controlExpirationDate) {
          id = 5;
        } else {
          id = 6;
        }

        document.getElementById(id).focus();
        document.getElementById(id).select();
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2265' /* Inventario Físico */),
          I18n.t('BEE1470' /* EAN Não Localizado! */),
          'top-right',
        );
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2265' /* Inventario Físico */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2265' /* Inventario Físico */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  getStorageAddressesOptions = async () => {
    const { branchCode } = this.state;
    try {
      const listAddress = await this.props.getStorageAddressesOptions(branchCode);
      if (listAddress.length) this.setState({ listAddress: [...listAddress] });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2265' /* Inventario Físico */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2265' /* Inventario Físico */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  getBranchesOptions = async () => {
    try {
      const data = await this.props.getBranchesOptions();
      if (data.branches.length) {
        const branchUserMain = (this.props.userLogged && this.props.userLogged.mainBranch)
          ? data.branches.find((element) => element.value === this.props.userLogged.mainBranch)
          : null;

        this.setState({
          listBranch: [...data.branches],
          branchCode: (branchUserMain && branchUserMain.value) ? branchUserMain.value : null,
          branchName: (branchUserMain && branchUserMain.label) ? branchUserMain.label : '',
          listDeposit: data.deposits.length > 0
            ? [...data.deposits]
            : [{ value: branchUserMain.deposit.value, label: branchUserMain.deposit.label }],
          disableDeposit: false,
          bolPhysicalInventory: data.deposits.length > 0,
        });
        await this.getStorageAddressesOptions();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2265' /* Inventario Físico */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2265' /* Inventario Físico */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  setNewQuantityToStock = async () => {
    const {
      ean, branchCode, addressCode, lotNumber, quantity, expirationDate, depositCode,
    } = this.state;
    try {
      const result = await this.props.setNewQuantityToStock(
        branchCode,
        addressCode,
        ean,
        lotNumber,
        expirationDate,
        quantity,
        depositCode,
      );
      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2265' /* Inventario Físico */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2265' /* Inventario Físico */),
          I18n.t('BEE2269' /* ajuste efetuado */),
          'top-right',
        );

        this.setState({
          ean: '',
          disabledEan: true,
          lotNumber: null,
          disabledLotNumber: true,
          expirationDate: null,
          disabledExpirationDate: true,
          quantity: null,
          disabledQuantity: true,
          addressId: null,
          addressCode: null,
          addressName: '',
        });
        document.getElementById(1).focus();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2265' /* Inventario Físico */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2265' /* Inventario Físico */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  balanceAdjustment = () => {
    const { ean, disabledEan } = this.state;

    if (ean && !disabledEan) {
      this.getValidateEan();
    } else {
      this.setNewQuantityToStock();
    }
  };

  render() {
    const {
      branchCode, branchName, listBranch, disableBranch, ean, disabledEan, lotNumber, disabledLotNumber, expirationDate,
      disabledExpirationDate, quantity, disabledQuantity, addressId, addressName, listAddress, disableAddress,
      listDeposit, depositCode, depositName, disableDeposit,
    } = this.state;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={I18n.t('BEE2265' /* Inventario Físico */)}
        wikiHelp={ROUTES.PHYSICAL_INVENTORY_HELP}
        content={(
          <>
            <div className="row">
              <div className="col-md-4 mr-2">
                {this.createSelectDropDown(
                  { value: branchCode, label: branchName },
                  'branch',
                  I18n.t('BEE145' /* Filial */),
                  listBranch,
                  disableBranch,
                  this.goToElement,
                  1,
                )}
              </div>
              <div className="col-md-3 ml-5">
                {this.createSelectDropDown(
                  { value: depositCode, label: depositName },
                  'deposit',
                  I18n.t('BEE182' /* Depósito */),
                  listDeposit,
                  disableDeposit,
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mr-2">
                {this.createSelectDropDown(
                  { value: addressId, label: addressName },
                  'address',
                  I18n.t('BEE432' /* Endereço */),
                  listAddress,
                  disableAddress,
                  this.goToElement,
                  2,
                )}
              </div>
              <div className="col-md-3 ml-5">
                {this.createInputButton(
                  ean,
                  'ean',
                  I18n.t('BEE377' /* EAN */),
                  '',
                  'text',
                  false,
                  disabledEan,
                  this.goToElement,
                  3,
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                {this.createInput(
                  lotNumber,
                  'lotNumber',
                  I18n.t('BEE428' /* Lote */),
                  '',
                  'text',
                  false,
                  disabledLotNumber,
                  this.goToElement,
                  4,
                )}
              </div>
              <div className="col-md-2 mr-2 ">
                {this.createDateInput(
                  expirationDate,
                  'expirationDate',
                  I18n.t('BEE434' /* Data de Validade */),
                  disabledExpirationDate,
                  this.goToElement,
                  5,
                )}
              </div>
              <div className="col-md-3 ml-5">
                {this.createNumberFormat(
                  quantity,
                  'quantity',
                  I18n.t('BEE441' /* Quantidade */),
                  disabledQuantity,
                  3,
                  this.goToElement,
                  6,
                )}
              </div>
            </div>
          </>
        )}
        footerContent={(
          <>
            <button
              type="button"
              className="btn btn-120 btn-white p-5 m-5"
              onClick={() => this.setState({
                ...this.initialState,
                listBranch: [...listBranch],
              })}
            >
              {I18n.t('BEE492' /* Limpar */)}
            </button>
            <OneClickButton
              type="button"
              className="btn btn-120 btn-primary p-5 m-5"
              onClick={this.balanceAdjustment}
            >
              {I18n.t('BEE100' /* Confirmar */)}
            </OneClickButton>
          </>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged && state.app.userLogged,
  fractionalQuantity: state.app.permissionsCompany && state.app.permissionsCompany.fractionalQuantity,
});

const mapDispatchToProps = (dispatch) => ({
  getBranchesOptions: () => dispatch(getOptionsBranchesAndDeposits()),
  getStorageAddressesOptions: (branchCode) => dispatch(getStorageAddressesOptions(branchCode)),
  getValidateEan: (ean) => dispatch(getValidateEan(ean)),
  setNewQuantityToStock: (
    branchCode,
    addressCode,
    ean,
    lotNumber,
    expirationDate,
    quantity,
    depositCode,
  ) => dispatch(setNewQuantityToStock(
    branchCode,
    addressCode,
    ean,
    lotNumber,
    expirationDate,
    quantity,
    depositCode,
  )),

});

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalInventory);
