import RestService from '../services/rest';

async function getBaptismInfo(type) {
  return RestService.getAuthenticated(`baptism/info?type=${type}`)
}

async function generateBaptism(type, branchCode, quantity) {
  return RestService.postAuthenticated('baptism/create', { type, branchCode, quantity })
}

export default {
  getBaptismInfo,
  generateBaptism,
}