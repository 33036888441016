import RestService from '../services/rest';

// GET
async function getProduct(productCodeEan) {
  return RestService.getAuthenticated(
    `inboundOrderAdd/product?productCodeEan=${productCodeEan}`
  );
}

async function getSuppliers(supplierQuery) {
  return RestService.getAuthenticated(
    `inboundOrderAdd/suppliers?supplierQuery=${supplierQuery}`
  );
}

async function getBaseInfo() {
  return RestService.getAuthenticated('inboundOrderAdd/baseInfo');
}

// PUT
async function putInboundOrder(inboundOrder) {
  return RestService.putAuthenticated('inboundOrderAdd/inboundOrder', inboundOrder);
}

async function putInboundOrderUpdate(inboundOrderId, inboundOrder) {
  return RestService.putAuthenticated('inboundOrderAdd/updateInboundOrder', {inboundOrderId, inboundOrder});
}

export default {
  getProduct,
  getSuppliers,
  getBaseInfo,
  putInboundOrder,
  putInboundOrderUpdate,
};
