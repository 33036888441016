/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PageSettings } from '../../config/page-settings';
import ROUTES from '../../config/routes';
import NotificationLeftSide from './notification-lefside';
import {
  setNotificationAsRead, getNotifications, deleteNotification, downloadFile,
} from '../../app/store/actions/notifications';

class NotificationDetail extends React.Component {
  static contextType = PageSettings;

  async componentDidMount() {
    this.context.handleSetPageContentFullHeight(true);
    this.context.handleSetPageContentFullWidth(true);

    const { item = {} } = this.props.location.state;

    await this.props.setNotificationAsRead(item.id);
    this.props.getNotifications(item.id);
  }

  componentWillUnmount() {
    this.context.handleSetPageContentFullHeight(false);
    this.context.handleSetPageContentFullWidth(false);
  }

  downloadFile = (e, attach = {}) => {
    e.preventDefault();
    this.props.downloadFile(attach.notifId, attach.id, attach.name, attach.type);
  };

  renderAttachments = (attachments = []) => {
    if (attachments.length) {
      const attachmentsMapped = attachments.map((attach) => {
        if (['jpg', 'jpeg', 'png', 'webp'].includes(attach.type)) {
          return (
            <li key={attach.name} className="fa-camera">
              <div className="document-file">
                <Link to="#" onClick={(e) => this.downloadFile(e, attach)}>
                  <i className="fa fa-camera" />
                </Link>
              </div>
              <div className="document-name">
                <Link to="#" onClick={(e) => this.downloadFile(e, attach)}>{attach.name}</Link>
              </div>
            </li>
          );
        }

        return (
          <li key={attach.name} className="fa-file">
            <div className="document-file">
              <Link to="#" onClick={(e) => this.downloadFile(e, attach)}>
                <i className="fa fa-file" />
              </Link>
            </div>
            <div className="document-name">
              <Link to="#" onClick={(e) => this.downloadFile(e, attach)}>{attach.name}</Link>
            </div>
          </li>
        );
      });
      return (
        <ul className="attached-document clearfix">
          {attachmentsMapped}
        </ul>
      );
    }

    return null;
  };

  render() {
    const { state = {} } = this.props.location;
    const { item = {}, notifType = null } = state;

    return (
      <div className="vertical-box with-grid inbox bg-light">
        <NotificationLeftSide notifType={notifType} />
        <div className="vertical-box-column">
          <div className="vertical-box">
            <div className="wrapper clearfix">
              <div className="pull-left">
                <div className="btn-group m-r-5">
                  <button
                    type="button"
                    className="btn btn-white btn-sm"
                    onClick={() => this.props.deleteNotification(item.id, this.props.history)}
                  >
                    <i className="fa fa-trash m-r-3 m-r-xs-0" />
                    {' '}
                    <span className="hidden-xs">{I18n.t('BEE2563' /* Deletar */)}</span>
                  </button>
                </div>
              </div>
              <div className="pull-right">
                <div className="btn-group m-l-5">
                  <Link to={ROUTES.NOTIFICATION} className="btn btn-white btn-sm"><i className="fa fa-times" /></Link>
                </div>
              </div>
            </div>
            <div className="vertical-box-row bg-white">
              <div className="vertical-box-cell">
                <div className="vertical-box-inner-cell">
                  <PerfectScrollbar className="height-full" options={{ suppressScrollX: true }}>
                    <div className="wrapper">
                      <h3 className="m-t-0 m-b-15 f-w-500">{item.content.title}</h3>
                      <p className="f-s-12 text-inverse p-t-10">
                        {item.content.message}
                      </p>
                      <br />
                      {this.renderAttachments(item.content.attachments)}
                    </div>
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: (state.notifications.list || []),
});

const mapDispatchToProps = (dispatch) => ({
  setNotificationAsRead: (notifId) => dispatch(setNotificationAsRead(notifId)),
  deleteNotification: (notifId, history) => dispatch(deleteNotification(notifId, history)),
  getNotifications: () => dispatch(getNotifications()),
  downloadFile: (notifId, id, nameDoc, typeDoc) => dispatch(downloadFile(notifId, id, nameDoc, typeDoc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationDetail));
