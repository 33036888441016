import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';

import Table from '../../../../components/table/Table';

import {
  createLinkUser, deleteLinkUser, getUserList,
} from '../../../../app/store/actions/ranges';

class UserLinkModal extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            { this.hasLink(rows.row._original)
              ? (
                <button
                  type="button"
                  className="btn btn-default btn-xs"
                  onClick={() => this.removeUserLink(rows.row._original)}
                >
                  {I18n.t('BEE608' /* Desvincular */)}
                </button>
              )
              : (
                <button
                  type="button"
                  className="btn btn-default btn-xs"
                  onClick={() => this.createUserLink(rows.row._original)}
                >
                  {I18n.t('BEE601' /* Vincular */)}
                </button>
              )}
          </div>
        ),
      }, {
        Header: I18n.t('BEE624' /* Vinculado? */),
        accessor: 'linked',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            { this.hasLink(rows.row._original)
              ? I18n.t('BEE172' /* Sim */)
              : I18n.t('BEE173' /* Não */)}
          </div>
        ),
      }, {
        Header: I18n.t('BEE623' /* Login */),
        accessor: 'login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE903' /* Filial Principal */),
        accessor: 'mainBranch',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      },
    ];

    this.state = {
      pages: 0,
      usersList: [],
      totalDataLength: 0,
    };

    this.defaultSorted = [
      {
        id: 'name',
        desc: false,
      },
    ];
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  tableTitle = () => {
    const { rangeSelected } = this.props;

    if (rangeSelected) {
      return `${I18n.t('BEE581' /* Range */)}: ${rangeSelected.code} - ${rangeSelected.name}`;
    }
    return I18n.t('BEE806' /* Erro ao buscar a Range */);
  };

  hasLink = (user) => {
    if (this.props.rangeSelected) {
      const rangeCode = this.props.rangeSelected.code;
      if (user.usersRanges.length) {
        return user.usersRanges.find((r) => r.rangeCode === rangeCode);
      }
      return false;
    }
    return false;
  };

  getUserList = async () => {
    const {
      page, pageSize, filtered, sorted,
    } = this;

    const usersQuery = await this.props.getUserList({
      page, pageSize, filtered, sorted,
    });

    this.setState({
      usersList: usersQuery.rows,
      pages: usersQuery.pages,
      totalDataLength: usersQuery.totalDataLength,
    });
  };

  createUserLink = async (user) => {
    const userId = user.id;
    const { code, name } = this.props.rangeSelected;

    try {
      const resultado = await this.props.createLinkUser(userId, code);

      if (resultado.id) {
        addNotification(
          'success',
          I18n.t('BEE581' /* Range */),
          I18n.t('BEE807', {
            0: user.code, 1: user.name, 2: code, 3: name,
          } /* %{0} - %{1} vinculado à Range %{2} - %{3} com sucesso! */),
          'top-right',
        );
        await this.getUserList();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE581' /* Range */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification('danger', I18n.t('BEE581' /* Range */), I18n.t('BEE587', { 0: code } /* Erro ao incluir a Range ${0}! */), 'top-right');
        }
      } else {
        addNotification('danger', I18n.t('BEE581' /* Range */), I18n.t('BEE587', { 0: code } /* Erro ao incluir a Range ${0}! */), 'top-right');
      }
    }
  };

  removeUserLink = async (user) => {
    const { code, name } = this.props.rangeSelected;

    try {
      const usersRanges = this.hasLink(user);
      if (usersRanges) {
        const resultado = await this.props.deleteLinkUser(usersRanges.id);

        if (resultado === 1) {
          addNotification(
            'success',
            I18n.t('BEE581' /* Range */),
            I18n.t('BEE809', {
              0: user.code, 1: user.name, 2: code, 3: name,
            } /* %{0} - %{1} desvinculado da Range %{2} - %{3} com sucesso! */),
            'top-right',
          );
          await this.getUserList();
        }
      } else {
        throw new Error(I18n.t('BEE808' /* Vínculo com a Range não encontrado */));
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE581' /* Range */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification('danger', I18n.t('BEE581' /* Range */), I18n.t('BEE587', { 0: code } /* Erro ao incluir a Range ${0}! */), 'top-right');
        }
      } else {
        addNotification('danger', I18n.t('BEE581' /* Range */), I18n.t('BEE587', { 0: code } /* Erro ao incluir a Range ${0}! */), 'top-right');
      }
    }
  };

  render() {
    const { showUserLinkModal } = this.props;

    const { pages, usersList, totalDataLength } = this.state;

    const title = I18n.t('BEE620' /* Vincular Usuários */);

    return (
      <Modal size="lg" isOpen={showUserLinkModal} toggle={() => this.props.closeUserLinkModal()}>
        <ModalHeader toggle={() => this.props.closeUserLinkModal()}>{title}</ModalHeader>
        <ModalBody>
          <Table
            headerTitle={this.tableTitle()}
            filterable
            data={usersList}
            totalDataLength={totalDataLength}
            columns={this.tableColumns}
            expander
            defaultPageSize={5}
            defaultSorted={this.defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            manual
            pages={pages}
            onFetchData={(state, instance) => {
              this.page = state.page;
              this.pageSize = state.pageSize;
              this.sorted = state.sorted;
              this.filtered = state.filtered;
              this.getUserList();
            }}
          />
        </ModalBody>
        <ModalFooter>
          <div className="row">
            <div className="col text-right">
              <OneClickButton
                className="btn btn-white btn-120 p-5 m-5"
                onClick={() => this.props.closeUserLinkModal()}
              >
                {I18n.t('BEE137' /* Voltar */)}
              </OneClickButton>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getUserList: (tableParams) => dispatch(getUserList(tableParams)),
  createLinkUser: (userId, rangeCode) => dispatch(createLinkUser(userId, rangeCode)),
  deleteLinkUser: (usersRangesId) => dispatch(deleteLinkUser(usersRangesId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserLinkModal));
