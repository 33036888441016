import { I18n } from 'react-redux-i18n';

function collectorStatus(status) {
  switch (status) {
    case false:
      return I18n.t('BEE207' /* Bloqueado */);
    case true:
      return I18n.t('BEE172' /* Sim */);
    default:
      return status;
  }
}

export default {
  collectorStatus,
}