/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';
import ROUTES from '../../../../config/routes';

import FilteringModal from './filteringModal';

import {
  createResupply,
  getListPickingAddressToResupply,
} from '../../../../app/store/actions/resupply';

class GenerateResupplyList extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value:
          (
            <>
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </>),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE2642' /* Ressuprimento */) },
      { value: I18n.t('BEE2666' /* Gerar Ressuprimento */), active: true },
    ];

    this.state = {
      checked: {},
      listAddressPicking: [],
      tableFilter: [],
      showConfirm: false,
      defaultPageSize: 10,
      defaultSorted: [{ id: 'productCode', desc: false }],
      showFilterModal: false,
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE436' /* Selecionar */),
        accessor: 'action',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={this.state.checked[row.original.id] || false}
              onChange={() => this.singleSelection(row.original)}
            />
          </div>
        ),
      }, {
        Header: I18n.t('BEE2715' /* Endereço de Picking */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'pickingArea.productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'pickingArea.product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
      }, {
        Header: I18n.t('BEE2654' /* % Ocupação do Picking */),
        accessor: 'occupationPercentage',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'balances[0].lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE434' /* Data de Validade */),
        accessor: 'balances[0].expirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE2716' /* Dt. Último Ressup. */),
        accessor: 'balances[0].lastResupplyAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      },
      {
        Header: I18n.t('BEE2717' /* Média Ressup. / Dia */),
        accessor: 'balances[0].avgDayResupply',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
      {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'pickingArea.depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
    ];
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.showFilterModal) {
      this.setState({ showFilterModal: this.props.location.state.showFilterModal });
    }
  }

  singleSelection = (selection) => {
    const { checked } = this.state;
    checked[selection.id] = !checked[selection.id];
    this.setState({ checked: { ...checked } });
  };

  multipleSelection = (selectionOption) => {
    const { checked } = this.state;
    const data = this.reactTable.getResolvedState().sortedData;
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index += 1) {
        const element = data[index]._original;
        checked[element.id] = selectionOption;
      }
      this.setState({ checked: { ...checked } });
    }
  };

  validChecked = () => {
    const { checked } = this.state;

    if (!Object.keys(checked).length) {
      addNotification(
        'danger',
        I18n.t('BEE2666' /* Gerar Ressuprimento */),
        I18n.t('BEE2728' /* Nenhum endereço selecionado */),
        'top-right',
      );
    } else {
      const checkedSheetIds = [];
      for (const index in checked) {
        if (checked[index]) checkedSheetIds.push(index);
      }
      if (checkedSheetIds.length) {
        this.setState({
          checkedSheetIds: [...checkedSheetIds],
          showConfirm: true,
        });
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2666' /* Gerar Ressuprimento */),
          I18n.t('BEE2728' /* Nenhum endereço selecionado */),
          'top-right',
        );
      }
    }
  };

  getListToGenerateResupply = async (tableFilter) => {
    try {
      const listAddressPicking = await this.props.getListPickingAddressToResupply(tableFilter);

      this.setState({
        listAddressPicking: [...listAddressPicking || []],
        checked: {},
        tableFilter,
      });
      this.closeFilters();
    } catch (err) {
      this.setState({ listAddressPicking: [] });
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2666' /* Gerar Ressuprimento */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2666' /* Gerar Ressuprimento */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  generateResupply = async () => {
    const { listAddressPicking, tableFilter, checkedSheetIds } = this.state;
    const addressPicking = listAddressPicking.filter((item) => checkedSheetIds.indexOf(String(item.id)) >= 0);

    try {
      this.setState({ showConfirm: false });

      await this.props.createResupply(addressPicking, tableFilter);

      this.setState({
        listAddressPicking: [],
        checked: {},
        checkedSheetIds: [],
      });
    } catch (err) {
      this.setState({ listAddressPicking: [] });

      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2666' /* Gerar Ressuprimento */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2666' /* Gerar Ressuprimento */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  closeFilters = () => {
    this.setState({
      showFilterModal: false,
    });
  };

  render() {
    const {
      showConfirm, defaultPageSize, defaultSorted, listAddressPicking, checkedSheetIds,
    } = this.state;

    return (
      <>
        <Table
          wikiHelp={ROUTES.MOVEMENT_RESUPPLY_GENERATE_HELP}
          ref={(r) => {
            this.reactTable = r && r.reactTable;
          }}
          downloadCSV
          buttonFilter={{
            actionButton: () => { this.setState({ showFilterModal: true }); },
          }}
          breadcrumb={this.breadcrumb}
          headerTitle={I18n.t('BEE2666' /* Gerar Ressuprimento */)}
          downButtonPosition
          actionButtons={(
            <div className="ml-auto">
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(true)}
              >
                {I18n.t('BEE1908' /* Marcar Todos */)}
              </button>
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(false)}
              >
                {I18n.t('BEE1909' /* Desmarcar Todos */)}
              </button>
              <OneClickButton
                type="button"
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={() => this.validChecked()}
              >
                {I18n.t('BEE602' /* Gerar */)}
              </OneClickButton>
            </div>
          )}
          filterable
          data={listAddressPicking}
          columns={this.tableColumns}
          expander
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                style: {
                  color: rowInfo.original.allocated > 0 ? '#bf4441' : 'black',
                },
              };
            }
            return {};
          }}
        />
        <FilteringModal
          showFilterModal={this.state.showFilterModal}
          closeFilters={this.closeFilters}
          getListToGenerateResupply={(tableFilter) => this.getListToGenerateResupply(tableFilter)}
        />
        {(showConfirm
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1849' /* Continuar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.generateResupply()}
              onCancel={() => this.setState({ showConfirm: false, checkedSheetIds: [] })}
            >
              {
                checkedSheetIds.length > 1
                  ? I18n.t(
                    'BEE2739',
                    { 0: checkedSheetIds.length }, /* Serão geradas %{0} ressuprimento, deseja continuar? */
                  )
                  : I18n.t(
                    'BEE2740',
                    { 0: checkedSheetIds.length }, /* Será gerado %{0} ressuprimento, deseja continuar? */
                  )
              }
            </SweetAlert>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getListPickingAddressToResupply: (tableFilter) => dispatch(getListPickingAddressToResupply(tableFilter)),
  createResupply: (params, tableFilter, sheetList) => dispatch(createResupply(params, tableFilter, sheetList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GenerateResupplyList));
