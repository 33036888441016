import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createStockGroup, getStockGroup, updateStockGroup,
} from '../../../../app/store/actions/stockGroup';
import Form from '../../../../components/form/form';

class StockGroupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      note: '',
      disableCode: true,
      disableName: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getStockGroupSelected();
    }
  }

  getStockGroupSelected = async () => {
    if (this.props.location.state.stockGroup) {
      const { stockGroup } = this.props.location.state;

      const stockGroupSelected = await this.props.getStockGroup(stockGroup.id);

      if (stockGroupSelected) {
        this.setState({
          stockGroupSelected,
          stockGroupId: stockGroupSelected.id,
          code: stockGroupSelected.code,
          name: stockGroupSelected.name,
          note: stockGroupSelected.note,
        });
      }
    }
  };

  setMode = () => {
    let mode = '';
    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  createStockGroupSubmit = async () => {
    const {
      code, name, note,
    } = this.state;

    try {
      const newStockGroup = await this.props.createStockGroup({
        code,
        name,
        note,
      });

      if (newStockGroup) {
        await addNotification(
          'success',
          I18n.t('BEE4010', { 0: I18n.t('BEE4009' /* Grupo de Estoque */) } /* Novo %{0} */),
          I18n.t('BEE4024', {
            0: I18n.t('BEE4009' /* Grupo de Estoque */),
            1: code,
          } /* Novo %{0} %{1} incluído com sucesso! */),
          'top-right',
        );
        this.props.history.push(ROUTES.STOCK_GROUP_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE4010', { 0: I18n.t('BEE4009' /* Grupo de Estoque */) } /* Novo %{0} */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE4010', { 0: I18n.t('BEE4009' /* Grupo de Estoque */) } /* Novo %{0} */),
            I18n.t('BEE4013', { 0: code } /* Erro ao incluir grupo de estoque %{0} */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'success',
          I18n.t('BEE4010', { 0: I18n.t('BEE4009' /* Grupo de Estoque */) } /* Novo %{0} */),
          I18n.t('BEE4013', { 0: code } /* Erro ao incluir grupo de estoque %{0} */),
          'top-right',
        );
      }
    }
  };

  updateStockGroupSubmit = async () => {
    const {
      stockGroupId, code, name, note,
    } = this.state;

    try {
      const updStockGroup = await this.props.updateStockGroup({
        stockGroupId,
        name,
        note,
      });

      if (updStockGroup) {
        await addNotification(
          'success',
          I18n.t('BEE4016', { 0: I18n.t('BEE4009' /* Grupo de Estoque */) } /* Atualizar %{0} */),
          I18n.t('BEE4015', { 0: code } /* Atualização do grupo de estoque %{0} efetuada com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.STOCK_GROUP_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE4016', { 0: I18n.t('BEE4009' /* Grupo de Estoque */) } /* Atualizar %{0} */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE4016', { 0: I18n.t('BEE4009' /* Grupo de Estoque */) } /* Atualizar %{0} */),
            I18n.t('BEE4014', { 0: code } /* Erro ao atualizar grupo de estoque %{0} */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE4016', { 0: I18n.t('BEE4009' /* Grupo de Estoque */) } /* Atualizar %{0} */),
          I18n.t('BEE4014', { 0: code } /* Erro ao atualizar grupo de estoque %{0} */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      code, name, note,
    } = this.state;

    const {
      mode, disableCode, disableName, disableNote, showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE4010', { 0: I18n.t('BEE4009' /* Grupo de Estoque */) } /* Novo %{0} */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE4011', { 0: I18n.t('BEE4009' /* Grupo de Estoque */) } /* Editar %{0} */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE4012', { 0: I18n.t('BEE4009' /* Grupo de Estoque */) } /* Detalhes %{0} */);
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE4007' /* Grupos de Estoque */)}</li>
            <li className="breadcrumb-item active">
              {I18n.t('BEE4010', { 0: I18n.t('BEE4009' /* Grupo de Estoque */) } /* Novo %{0} */)}
            </li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  <Form
                    noPanel
                    setValue={this.setValue}
                    setValueDrop={this.setValueDrop}
                    inputs={(formContext) => ([
                      formContext.createInput(
                        code,
                        'code',
                        `${I18n.t('BEE82' /* Código */)}:`,
                        I18n.t('BEE4017' /* Informe o código do grupo de estoque */),
                        'text',
                        true,
                        disableCode,
                      ),
                      formContext.createInput(
                        name,
                        'name',
                        `${I18n.t('BEE83' /* Nome */)}:`,
                        I18n.t('BEE4018' /* Informe o nome do grupo de estoque */),
                        'text',
                        true,
                        disableName,
                      ),
                      formContext.createInput(
                        note,
                        'note',
                        `${I18n.t('BEE135' /* Observação */)}:`,
                        I18n.t('BEE136' /* Informe a observação */),
                        'text',
                        false,
                        disableNote,
                      ),
                    ])}
                  />
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.STOCK_GROUP_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE137' /* Voltar */)}
                        </OneClickButton>
                      ))}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createStockGroupSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}

                    </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateStockGroupSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}

                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getStockGroup: (stockGroupId) => dispatch(getStockGroup(stockGroupId)),
  createStockGroup: (stockGroup) => dispatch(createStockGroup(stockGroup)),
  updateStockGroup: (stockGroup) => dispatch(updateStockGroup(stockGroup)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StockGroupForm));
