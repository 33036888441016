import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getStockGroupList, deleteStockGroup } from '../../../../app/store/actions/stockGroup';

class StockGroupList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      stockGroupUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showStockGroupDetail(rows.row)}>
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showEditStockGroup(rows.row)}>
                  {I18n.t('BEE57' /* Editar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showCopyStockGroup(rows.row)}>
                  {I18n.t('BEE80' /* Copiar */)}
                </DropdownItem>
                {!this.props.formIntegrations && (
                  <DropdownItem onClick={() => this.showDeleteStockGroup(rows.row)}>
                    {I18n.t('BEE81' /* Eliminar */)}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getStockGroups();
    } else {
      await this.getStockGroups();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      stockGroupUpdate: null,
    });
  };

  getStockGroups = async () => {
    await this.props.getStockGroupList();
  };

  deleteStockGroup = async (stockGroup) => {
    const result = await this.props.deleteStockGroup(stockGroup._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'success',
        I18n.t('BEE4019', { 0: I18n.t('BEE4009' /* Grupo de Estoque */) } /* Eliminar %{0} */),
        I18n.t(
          'BEE4020',
          {
            0: I18n.t('BEE4009' /* Grupo de Estoque */),
            1: stockGroup.code,
          }, /* %{0} %{1} eliminado com sucesso! */
        ),
        'top-right',
      );
    }
    await this.getStockGroups();
  };

  showStockGroupDetail = (stockGroup) => {
    this.props.history.push({
      pathname: ROUTES.STOCK_GROUP_DETAIL,
      state: {
        stockGroup: stockGroup._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditStockGroup = (stockGroup) => {
    this.props.history.push({
      pathname: ROUTES.STOCK_GROUP_EDIT,
      state: {
        stockGroup: stockGroup._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyStockGroup = (stockGroup) => {
    this.props.history.push({
      pathname: ROUTES.STOCK_GROUP_COPY,
      state: {
        stockGroup: stockGroup._original,
      },
    });
  };

  showDeleteStockGroup = (stockGroup) => {
    this.setState({ showModalDelete: true, stockGroupUpdate: stockGroup });
  };

  render() {
    const { stockGroupUpdate, showModalDelete, page } = this.state;
    const { stockGroupList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE4007' /* Grupos de Estoque */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          wikiHelp={ROUTES.STOCK_GROUP_HELP}
          headerTitle={I18n.t('BEE4007' /* Grupos de Estoque */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.STOCK_GROUP_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE4008' /* Incluir Grupo de Estoque */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getStockGroups,
          }}
          filterable
          data={stockGroupList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page)
                || ((!this.state.firstRun
                  && this.props.location.state
                  && this.props.location.state.dice
                  && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteStockGroup(stockGroupUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE880', { 0: stockGroupUpdate.code } /*    A impressora %{0} será eliminada ! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stockGroupList: state.stockGroup && state.stockGroup.stockGroupList,
  formIntegrations: state.app.permissionsCompany && state.app.permissionsCompany.formIntegrations,
});

const mapDispatchToProps = (dispatch) => ({
  getStockGroupList: () => dispatch(getStockGroupList()),
  deleteStockGroup: (stockGroupId) => dispatch(deleteStockGroup(stockGroupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StockGroupList));
