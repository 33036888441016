import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import SelectMulti from 'react-select';
import { getBranchesOptions } from '../../../app/store/actions/branches';
import { getInventoryOptions, getInventorySheetsList } from '../../../app/store/actions/inventory';
import Select from '../../../components/form/select';
import addNotification from '../../../components/notification';
import { PanelPage } from '../../../components/pages/pages';
import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';
import InclusionModal from './inclusionModal';
import InventoryMaintenance from './inventoryMaintenance';

class InventoryMaintenanceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchPage: true,
      grouperCode: [],
      grouperDisabled: true,
      listInventory: [],
      listBranch: [],
      branchCode: null,
      branchName: '',
      inventory: {},
      showInclusionModal: false,
      updateData: false,
    };
    this.initialState = this.state;

    this.colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, {
        isDisabled, isSelected,
      }) => ({
        ...styles,
        backgroundColor: isDisabled ? 'grey' : 'white',
        color: isDisabled ? 'grey' : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        textAlign: 'left',

        ':hover': {
          ...styles[':hover'],
          backgroundColor: isSelected ? 'grey' : '#eb900a',
          color: 'white',
        },
      }),
    };
  }

  componentDidMount() {
    document.getElementById(1).focus();
    this.getBranchesOptions();
  }

  setValueDrop = async (attr, value) => {
    this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
    if (attr === 'branch') {
      await this.setState({
        grouperDisabled: !((value && value.value)),
        grouperCode: [],
      });
      if (value && value.value) this.getInventoryOptions();
    }
  };

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  goToElement = (e, id, attr) => {
    if (e.keyCode === 13) {
      if (attr === 'grouperCode') {
        this.checkFields();
      } else {
        document.getElementById(id + 1).focus();
      }
    }
  };

  createSelectDropDown = (value, attr, label, items, disabled, keypressFunction = undefined, id) => (
    <div className="form-group m-b-15 text-left">
      <span>{label}</span>
      <div>
        <Select
          id={id && id}
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          value={value.label ? value : ''}
          onChange={(e) => {
            this.setValueDrop(attr, e);
          }}
          options={items}
          isClearable
          styles={this.colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          isDisabled={disabled}
        />
      </div>
    </div>
  );

  createSelectDropDownMulti = (value, attr, label, items, disabled, keypressFunction = undefined) => (
    <div className="form-group m-b-15 text-left">
      <span>{label}</span>
      <div>
        <SelectMulti
          value={value}
          isMulti
          onChange={(val) => this.setValue(attr, val)}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          noOptionsMessage={() => I18n.t('BEE561' /* Sem opções */)}
          isSearchable
          styles={this.colourStyles}
          maxMenuHeight={300}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          options={items}
          isDisabled={disabled}
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, attr))}
        />
      </div>
    </div>
  );

  inventorySheetsList = async () => {
    const { grouperCode, branchCode } = this.state;
    try {
      const grouperCodes = grouperCode.map((group) => group.value);
      const inventory = await this.props.getInventorySheetsList(grouperCodes, branchCode);

      if (inventory && inventory.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
        this.setState({ ...this.initialState });
        this.getBranchesOptions();
      } else if (inventory) {
        this.setState({
          inventory,
          updateData: true,
          searchPage: false,
        });
      }
    } catch (err) {
      this.setState({ ...this.initialState });
      this.getBranchesOptions();

      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2105' /* Manutenção de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  checkFields = () => {
    const { grouperCode, branchCode } = this.state;
    if (!branchCode) {
      addNotification(
        'danger',
        I18n.t('BEE2105' /* Manutenção de Inventário */),
        I18n.t('BEE1781' /* Informe a filial */),
        'top-right',
      );
    } else if (!grouperCode || grouperCode.length === 0) {
      addNotification(
        'danger',
        I18n.t('BEE2105' /* Manutenção de Inventário */),
        I18n.t('BEE2111' /* Informe o agrupador */),
        'top-right',
      );
    } else {
      this.inventorySheetsList();
    }
  };

  getBranchesOptions = async () => {
    this.setState({ listBranch: [] });
    try {
      const listBranch = await this.props.getBranchesOptions();
      if (listBranch.length) this.setState({ listBranch });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2105' /* Manutenção de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  getInventoryOptions = async () => {
    const { branchCode } = this.state;
    this.setState({ listInventory: [] });
    try {
      const listInventory = await this.props.getInventoryOptions(branchCode);
      if (listInventory.length) this.setState({ listInventory });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2105' /* Manutenção de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      searchPage, grouperCode, grouperDisabled, listBranch = [],
      branchCode, branchName, listInventory,
    } = this.state;

    return (
      <>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE572' /* Inventário */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE2105' /* Manutenção de Inventário */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center">
          <h1 className="page-header mb-0">
            {I18n.t('BEE2105' /* Manutenção de Inventário */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.MOVEMENT_INVENTORY_MAINTENANCE_HELP} />
          {!searchPage && (
            <div className="ml-auto">
              <button
                className="btn btn-success btn-sm btn-rounded pl-2 pr-3"
                type="button"
                onClick={() => { this.setState({ showInclusionModal: true }); }}
              >
                <i className="fa fa-plus mr-1" />
                {I18n.t('BEE2110' /* Incluir Produto / Endereço */)}
              </button>
            </div>
          )}
        </div>
        {searchPage && (
          <PanelPage
            noButton
            content={(
              <div className="col-md-5">
                {this.createSelectDropDown(
                  { value: branchCode, label: branchName },
                  'branch',
                  I18n.t('BEE145' /* Filial */),
                  listBranch,
                  false,
                  this.goToElement,
                  1,
                )}
                {this.createSelectDropDownMulti(
                  grouperCode,
                  'grouperCode',
                  I18n.t('BEE1516' /* Agrupador */),
                  listInventory,
                  grouperDisabled,
                  this.goToElement,
                  2,
                  true,
                )}
              </div>
            )}
            footerContent={(
              <button
                type="button"
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={this.checkFields}
              >
                {I18n.t('BEE407' /* Buscar */)}
              </button>
            )}
          />
        )}
        {!searchPage && (
          <InventoryMaintenance
            restoreHomePage={() => {
              this.setState({ ...this.initialState });
              this.getBranchesOptions();
            }}
            inventory={this.state.inventory}
            inventorySheetsList={() => this.inventorySheetsList()}
            updateData={this.state.updateData}
            setState={(data) => this.setState(data)}
          />
        )}
        <InclusionModal
          inventory={this.state.inventory}
          showInclusionModal={this.state.showInclusionModal}
          closeInclusionModal={() => { this.setState({ showInclusionModal: false }); }}
          inventorySheetsList={() => this.inventorySheetsList()}
        />
      </>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getInventorySheetsList: (grouperCode, branchCode) => dispatch(getInventorySheetsList(grouperCode, branchCode)),
  getInventoryOptions: (branchCode) => dispatch(getInventoryOptions(branchCode, 4)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventoryMaintenanceList));
