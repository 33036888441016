import 'react-table/react-table.css';
import React from 'react';
import Table from '../../../../components/table/Table';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import addNotification from '../../../../components/notification';
import OneClickButton from '../../../../components/form/button';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from '../../../../components/form/select';

import { getCarriersList } from '../../../../app/store/actions/carriers';
import { getCustomersList } from '../../../../app/store/actions/customers';
import { createCarrierSchedule, deleteCarrierSchedule } from '../../../../app/store/actions/carrierSchedules';


class CarrierScheduleTable extends React.Component { 
  constructor(props) {
    super(props)

    this.typingTimer = null;
    this.typingInterval = 200;

    this.state = {
      carrierScheduleSelected: null,
      showModalDelete: false,
      showModalCreate: false,
      selectedUsersRange: null,
      customerSelected: null,
      customerSelectedInput: '',
      carrierSelected: null,
      carrierSelectedInput: '',
      note: '',
      customerOptions: [],
      carrierOptions: []
    }

    this.tableColumns = [
      {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: rows => (
          <span>
            {`${rows.row.carrierCode} - ${rows.row._original.carrier.name}`}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(`${row.carrierCode} - ${row._original.carrier.name}`);
          if (_.includes(value, input)) {
            return true;
          }
        }
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: rows => (
          <span>
            {`${rows.row.customerCode} - ${rows.row._original.customer.name}`}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(`${row.customerCode} - ${row._original.customer.name}`);
          if (_.includes(value, input)) {
            return true;
          }
        }
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
    ];

    this.actionColumn = {
      Header: I18n.t('BEE55' /* Ações */),
      accessor: 'action',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      width: 100,
      Cell: rows => (
        <div style={{ textAlign: 'center' }}>
          <button
            type="button" 
            className="btn btn-default btn-xs"
            onClick={() => this.openModalDelete(rows.row._original)}
          >{I18n.t('BEE608' /* Desvincular */)}</button>
        </div>
      )
    }
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  )

  createSelectDropDown = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <Select
          value={value.value ? value : ''}
          onChange={e => this.setValueDrop(attr, e)}
          onKeyDown={e => this.handleSelectKeyDown(attr, e)}
          onInputChange={inputValue => this.setState({ [`${attr}Input`]: inputValue })}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={I18n.t('BEE693' /* Procure por nome ou CNPJ... */)}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  }

  handleSelectKeyDown = (attr, e) => {
    if (e.keyCode === 13) {
      this.handleSearchTimer(attr) 
    } else {
      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(() => this.handleSearchTimer(attr), this.typingInterval)
    }
  }

  handleSearchTimer = async (attr) => {
    clearTimeout(this.typingTimer)
    const queryTerm = this.state[`${attr}Input`]

    const numbersOnly = /^\d+$/.test(queryTerm)

    const filtered = [
      {
        id: numbersOnly ? 'cnpj' : 'name',
        value: queryTerm
      }
    ]

    const sorted = {
      id: 'name',
      desc: false
    }

    const tableParams = {
      sorted,
      filtered,
      page: 0,
      pageSize: 50,
    }

    if (attr === 'customerSelected') {
      await this.props.getCustomersList(tableParams);
      const customerOptions = this.props.customerOptions.map(c => {
        return {
          value: c.code,
          label: `${c.name} - ${I18n.t('BEE85' /* CNPJ */)}: ${c.cnpj}`
        }
      })
      this.setState({ customerOptions })
    } else {
      await this.props.getCarriersList(tableParams);
      const carrierOptions = this.props.carrierOptions.map(c => {
        return {
          value: c.code,
          label: `${c.name} - ${I18n.t('BEE85' /* CNPJ */)}: ${c.cnpj}`
        }
      })

      this.setState({ carrierOptions })
    }
  }

  openModalDelete = (carrierScheduleSelected) => {
    this.setState({ 
      showModalDelete: true,
      carrierScheduleSelected,
    })
  }

  closeModalDelete = () => {
    this.setState({ showModalDelete: false })
  }

  openModalCreate = () => {
    this.setState({ showModalCreate: true })
  }

  closeModalCreate = () => {
    this.setState({ showModalCreate: false })
  }

  updateCarrierSchedules = async () => {
    await this.props.updateCarrierSchedules()
  }

  createCarrierSchedule = async () => {
    const { carrierSelected, customerSelected, note } = this.state;
    const { scheduleSelected } = this.props;

    try {
      if (!carrierSelected || !customerSelected ) {
        addNotification('danger', I18n.t('BEE658' /* Agendamentos */), I18n.t('BEE704' /* Preencha todos os campos! */), 'top-right')
        return
      }
  
      const carrierSchedule = {
        customerCode: customerSelected,
        carrierCode: carrierSelected,
        scheduleCode: scheduleSelected.code,
        note,
      }
  
      const newCarrierSchedule = await this.props.createCarrierSchedule(carrierSchedule)
      
      if (newCarrierSchedule) {
        await this.getRangeSelected()
        this.setState({
          carrierSelected: null,
          customerSelected: null,
          note: '',
        })
        this.closeModalCreate()
        addNotification('success', I18n.t('BEE658' /* Agendamentos */), I18n.t('BEE714' /* Vínculo criado com sucesso! */), 'top-right');
        this.updateCarrierSchedules()
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE658' /* Agendamentos */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          addNotification(
            'danger', I18n.t('BEE658' /* Agendamentos */), I18n.t('BEE715' /* Erro ao criar o vínculo! */), 'top-right'
          );
        }
      } else {
        addNotification(
          'danger', I18n.t('BEE658' /* Agendamentos */), I18n.t('BEE715' /* Erro ao criar o vínculo! */), 'top-right'
        );
      }
    }
  }

  deleteCarrierSchedule = async () => {
    const { carrierScheduleSelected } = this.state;

    try {
      const deleted = await this.props.deleteCarrierSchedule(carrierScheduleSelected.id)
      
      if (deleted) {
        this.setState({
          carrierScheduleSelected: null,
          showModalDelete: false,
        })
        addNotification('success', I18n.t('BEE658' /* Agendamentos */), I18n.t('BEE716' /* Vínculo excluído com sucesso! */), 'top-right');
        this.updateCarrierSchedules()
      }
  
      await this.updateCarrierSchedules()
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE658' /* Agendamentos */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          addNotification(
            'danger', I18n.t('BEE658' /* Agendamentos */), I18n.t('BEE717' /* Erro ao excluír o vínculo! */), 'top-right'
          );
        }
      } else {
        addNotification(
          'danger', I18n.t('BEE658' /* Agendamentos */), I18n.t('BEE717' /* Erro ao excluír o vínculo! */), 'top-right'
        );
      }
    }
  }

  render() {
    const { canEdit, carrierSchedules } = this.props;

    const { 
      showModalDelete, showModalCreate, customerSelectedName, carrierOptions, note,
      customerOptions, customerSelected, carrierSelected, carrierSelectedName,
     } = this.state;

    const columns = canEdit
      ? [ this.actionColumn, ...this.tableColumns]
      : this.tableColumns

    return (
      <div className="table-responsive">
        <Table
          headerTitle={I18n.t('BEE24' /* Transportadoras */)}
          data={carrierSchedules}
          columns={columns}
          panelHeaderProps={{
            onClickReload: this.updateCarrierSchedules
          }}
          expander
          filterable
          defaultPageSize={this.props.lines ? this.props.lines : 10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          actionButtons={(
            canEdit &&
              <div className="ml-auto">
                <button
                  type="button"
                  className="btn btn-240 btn-success btn-rounded pr-3"
                  onClick={() => { this.openModalCreate() }}
                ><i className="fa fa-link mr-1"></i>{I18n.t('BEE620' /* Vincular Usuários */)}</button>
              </div>
          )}
        />
        {(showModalDelete &&
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteCarrierSchedule()}
            onCancel={() => this.closeModalDelete()}
          >
            {I18n.t('BEE625', { 0: '', 1: '' } /* O usuário %{0} será descinvulado da Range %{1} */)}
          </SweetAlert>
        )}
        <Modal size='lg' isOpen={showModalCreate} toggle={() => this.closeModalCreate()}>
          <ModalHeader toggle={() => this.closeModalCreate()}>{I18n.t('BEE601' /* Vincular */)}</ModalHeader>
          <ModalBody>
            {this.createSelectDropDown(
              {value: carrierSelected, label: carrierSelectedName}, 'carrierSelected', I18n.t('BEE301' /* Transportadora */), carrierOptions)
            }
            {this.createSelectDropDown(
              {value: customerSelected, label: customerSelectedName}, 'customerSelected', I18n.t('BEE50' /* Cliente */), customerOptions)
            }
            {this.createInput(
              note, 'note', I18n.t('BEE135' /* Observação */), I18n.t('BEE135' /* Observação */), 'text', false, false
            )}
          </ModalBody>
          <ModalFooter>
            <div className="row">
              <div className="col text-right">
                <OneClickButton 
                  className="btn btn-white btn-120 p-5 m-5"
                  onClick={() => this.closeModalCreate()}
                >
                  {I18n.t('BEE137' /* Voltar */)}
                </OneClickButton>
                <OneClickButton 
                  className="btn btn-primary btn-120 p-5 m-5"
                  onClick={() => this.createCarrierSchedule()}
                >
                  {I18n.t('BEE100' /* Confirmar */)}
                </OneClickButton>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', zIndex: 99999 }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    }
  }),
};

const mapStateToProps = state => ({
  carrierOptions: state.carriers && state.carriers.carriersList,
  customerOptions: state.customers && state.customers.customersList
});

const mapDispatchToProps = dispatch => ({
  getCarriersList: (tableParams) => dispatch(getCarriersList(tableParams)),
  getCustomersList: (tableParams) => dispatch(getCustomersList(tableParams)),
  createCarrierSchedule: (carrierSchedule) => dispatch(createCarrierSchedule(carrierSchedule)),
  deleteCarrierSchedule: (carrierScheduleId) => dispatch(deleteCarrierSchedule(carrierScheduleId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CarrierScheduleTable));