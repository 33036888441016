const INITIAL_STATE = {
  warehousesList: []
};

export default (state = INITIAL_STATE, action) => {  
  switch (action.type) {    
    case 'SET_TRANSFER_ORDER_OUT': {
      return { ...state, addressTransferWave: action.addressTransferWave };
    }
    default:
      return state;
  }
};
