import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import _ from 'lodash';

import Table from '../../../components/table/Table';

import ROUTES from '../../../config/routes';

import {
  getAllAgents, deleteAgent, updateActiveAgent, updateTokenAgent,
} from '../../../app/store/actions/agent';
import addNotification from '../../../components/notification';

const STATUS = {
  connected: 'BEE3466' /* Conectado */,
  disconnected: 'BEE3467' /* Desconectado */,
};

const STATUS_VALUE = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
};

class DevelopAgentList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      showModalDelete: false,
      deleteAgentObj: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: true,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showDetails(row.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEdit(row.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.tokenAgent(row.row)}>
                  {I18n.t('BEE3494' /* Gerar um novo token */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.activeAgent(row.row)}>
                  {
                  row.row.active
                    ? I18n.t('BEE2491' /* Desativar */)
                    : I18n.t('BEE914' /* Ativar */)
                }
                </DropdownItem>
                <DropdownItem style={{ color: 'red' }} onClick={() => this.showDeleteModal(row.row)}>
                  {I18n.t('BEE81' /* Eliminar */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1897' /* Descrição */),
        accessor: 'description',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => {
          if (row.value === STATUS_VALUE.CONNECTED) {
            return <span style={{ color: 'green', fontWeight: '700' }}>{I18n.t(STATUS[row.value])}</span>;
          } if (row.value === STATUS_VALUE.DISCONNECTED) {
            return <span style={{ color: 'red', fontWeight: '600' }}>{I18n.t(STATUS[row.value])}</span>;
          }
          return row.value;
        },
        Filter: ({ onChange }) => {
          const labelConnected = I18n.t(STATUS.connected);
          const labelDiconnected = I18n.t(STATUS.disconnected);

          return (
            <select
              type="text"
              style={{ width: '100%' }}
              onChange={(event) => { onChange(event.target.value); }}
            >
              <option key="all" value="">{I18n.t('BEE793' /* Todos */)}</option>
              <option key={STATUS_VALUE.CONNECTED} value={STATUS_VALUE.CONNECTED}>{labelConnected}</option>
              <option key={STATUS_VALUE.DISCONNECTED} value={STATUS_VALUE.DISCONNECTED}>
                {labelDiconnected}
              </option>
            </select>
          );
        },
        filterMethod: (filter, row) => filter.value === row[filter.id],
      }, {
        Header: I18n.t('BEE499' /* Ativo */),
        accessor: 'active',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option key="all" value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option key={1} value={1}>{I18n.t('BEE172' /* Sim */)}</option>
            <option key={0} value={0}>{I18n.t('BEE173' /* Não */)}</option>
          </select>
        ),
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
    }

    await this.getAllAgents();
  };

  getAllAgents = async () => {
    await this.props.getAgentList();
  };

  showDetails = (row) => {
    this.props.history.push({
      pathname: ROUTES.DEVELOP_AGENT_DETAIL,
      state: {
        agent: row._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showDeleteModal = (agent) => {
    this.setState({ showModalDelete: true, deleteAgentObj: agent });
  };

  showEdit = (row) => {
    this.props.history.push({
      pathname: ROUTES.DEVELOP_AGENT_EDIT,
      state: {
        agent: row._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  activeAgent = async (agent) => {
    try {
      const newAgentActive = !agent.active;
      const agentActive = await this.props.updateActiveAgent({ code: agent.code, active: newAgentActive });

      if (agentActive) {
        await addNotification(
          'success',
          I18n.t('BEE3491' /* Ativar ou Desativar Agente */),
          newAgentActive
            ? I18n.t('BEE3488', { 0: agent.code } /* Agente %{0} ativado com sucesso */)
            : I18n.t('BEE3489', { 0: agent.code } /* Agente %{0} desativado com sucesso */),
          'top-right',
        );
        this.getAllAgents();
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3491' /* Ativar ou Desativar Agente */),
          I18n.t('BEE3490', { 0: agent.code } /* Erro ao ativar ou desativar o agente %{0}! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE3491' /* Ativar ou Desativar Agente */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE3491' /* Ativar ou Desativar Agente */),
            I18n.t('BEE3490', { 0: agent.code } /* Erro ao ativar ou desativar o agente %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3491' /* Ativar ou Desativar Agente */),
          I18n.t('BEE3490', { 0: agent.code } /* Erro ao ativar ou desativar o agente %{0}! */),
          'top-right',
        );
      }
      await addNotification(
        'danger',
        I18n.t('BEE3491' /* Ativar ou Desativar Agente */),
        I18n.t('BEE3490', { 0: agent.code } /* Erro ao ativar ou desativar o agente %{0}! */),
        'top-right',
      );
    }
  };

  tokenAgent = async (agent) => {
    try {
      const agentActive = await this.props.updateTokenAgent({ code: agent.code });

      if (agentActive) {
        await addNotification(
          'success',
          I18n.t('BEE3494' /* Gerar um novo token */),
          I18n.t('BEE3492', { 0: agent.code } /* Novo token gerado para o agente %{0} */),
          'top-right',
        );

        await this.getAllAgents();
        this.showDetails(agent);
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3494' /* Gerar um novo token */),
          I18n.t('BEE3493', { 0: agent.code } /* Erro ao gerar token para o agente %{0} */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE3494' /* Gerar um novo token */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE3494' /* Gerar um novo token */),
            I18n.t('BEE3493', { 0: agent.code } /* Erro ao gerar token para o agente %{0} */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3494' /* Gerar um novo token */),
          I18n.t('BEE3493', { 0: agent.code } /* Erro ao gerar token para o agente %{0} */),
          'top-right',
        );
      }
      await addNotification(
        'danger',
        I18n.t('BEE3494' /* Gerar um novo token */),
        I18n.t('BEE3493', { 0: agent.code } /* Erro ao gerar token para o agente %{0} */),
        'top-right',
      );
    }
  };

  removeAgent = async (agent) => {
    try {
      this.setState({ showModalDelete: false, deleteAgentObj: null });

      const agentDeleted = await this.props.deleteAgent(agent.code);

      if (agentDeleted) {
        await addNotification(
          'success',
          I18n.t('BEE3485' /* Eliminar Agente */),
          I18n.t('BEE3486', { 0: agent.code } /* Agente %{0} eliminado com sucesso */),
          'top-right',
        );
        this.getAllAgents();
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3485' /* Eliminar Agente */),
          I18n.t('BEE3487', { 0: agent.code } /* Erro ao eliminar o agente %{0}! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE3485' /* Eliminar Agente */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE3485' /* Eliminar Agente */),
            I18n.t('BEE3487', { 0: agent.code } /* Erro ao eliminar o agente %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3485' /* Eliminar Agente */),
          I18n.t('BEE3487', { 0: agent.code } /* Erro ao eliminar o agente %{0}! */),
          'top-right',
        );
      }
      await addNotification(
        'danger',
        I18n.t('BEE3485' /* Eliminar Agente */),
        I18n.t('BEE3487', { 0: agent.code } /* Erro ao eliminar o agente %{0}! */),
        'top-right',
      );
    }
  };

  render() {
    const { agents = [] } = this.props;
    const { page, showModalDelete, deleteAgentObj } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE48' /* Desenvolvedor */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE3465' /* Agentes */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE3465' /* Agentes */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.DEVELOP_AGENT_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE3468' /* Incluir Agente */)}
              </Link>
            </div>
          )}
          wikiHelp={ROUTES.DEVELOP_AGENT_HELP}
          panelHeaderProps={{
            onClickReload: this.getAllAgents,
          }}
          filterable
          data={agents}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || (
            (!this.state.firstRun && this.props.location.state
              && this.props.location.state.dice && this.props.location.state.dice.page)
              ? this.props.location.state.dice.page : page)}
          onPageChange={(Page) => ((Page >= 0) ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.removeAgent(deleteAgentObj)}
            onCancel={() => this.setState({ showModalDelete: false, deleteAgentObj: null })}
          >
            {I18n.t('BEE3484', { 0: deleteAgentObj.code } /* O Agente %{0} será eliminado! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  agents: state.agent && state.agent.agents,
});

const mapDispatchToProps = (dispatch) => ({
  getAgentList: () => dispatch(getAllAgents()),
  deleteAgent: (code) => dispatch(deleteAgent(code)),
  updateActiveAgent: (agent) => dispatch(updateActiveAgent(agent)),
  updateTokenAgent: (agent) => dispatch(updateTokenAgent(agent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DevelopAgentList));
