import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getDocksList, deleteDock } from '../../../../app/store/actions/docks';

class DockList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      dockUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showDockDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditDock(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyDock(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDeleteDock(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showLinkCarrier(rows.row)}>{I18n.t('BEE2377' /* Vincular Transportadoras */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'dockType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('entrada', input)) {
            return id === 1;
          } if (_.includes('saida', input)) {
            return id === 2;
          } if (_.includes('ambos', input)) {
            return id === 3;
          }
        },
        Cell: (row) => (
          <span>
            {row.value === 1 ? I18n.t('BEE201' /* Entrada */) : row.value === 2 ? I18n.t('BEE202' /* Saída */) : I18n.t('BEE203' /* Ambos */)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE204' /* Situação */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('liberada', input)) {
            return id === 1;
          } if (_.includes('ocupada', input)) {
            return id === 2;
          } if (_.includes('bloqueada', input)) {
            return id === 3;
          }
        },
        Cell: (row) => (
          <span>
            {row.value === 1 ? I18n.t('BEE205' /* Liberada */) : row.value === 2 ? I18n.t('BEE206' /* Ocupada */) : I18n.t('BEE207' /* Bloqueado */)}
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getDocks();
    } else {
      await this.getDocks();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      dockUpdate: null,
    });
  };

  getDocks = async () => {
    await this.props.getDocksList();
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deleteDock = async (dock) => {
    const result = await this.props.deleteDock(dock._original.id);

    this.setInitState();
    if (result) {
      addNotification('success', I18n.t('BEE208' /* Eliminar Doca */), I18n.t('BEE209', { 0: dock.code } /* Doca %{0} eliminada com sucesso! */), 'top-right');
    }
    await this.getDocks();
  };

  showDockDetail = (dock) => {
    this.props.history.push({
      pathname: ROUTES.DOCKS_DETAIL,
      state: {
        dock: dock._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditDock = (dock) => {
    this.props.history.push({
      pathname: ROUTES.DOCKS_EDIT,
      state: {
        dock: dock._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyDock = (dock) => {
    this.props.history.push({
      pathname: ROUTES.DOCKS_COPY,
      state: {
        dock: dock._original,
      },
    });
  };

  showDeleteDock = (dock) => {
    this.setState({ showModalDelete: true, dockUpdate: dock });
  };

  showLinkCarrier = (dock) => {
    this.props.history.push({
      pathname: ROUTES.DOCKS_LINK_CARRIER,
      state: {
        dock: dock._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  render() {
    const { dockUpdate, showModalDelete, page } = this.state;
    const { docksList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE19' /* Docas */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE19' /* Docas */)}
          wikiHelp={ROUTES.DOCKS_HELP}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.DOCKS_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE210' /* Incluir Doca */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getDocks,
          }}
          filterable
          data={docksList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteDock(dockUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE211', { 0: dockUpdate.code } /* A doca %{0} será eliminada! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  docksList: state.docks && state.docks.docksList,
});

const mapDispatchToProps = (dispatch) => ({
  getDocksList: () => dispatch(getDocksList()),
  deleteDock: (dockId) => dispatch(deleteDock(dockId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DockList));
