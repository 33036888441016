import RestService from '../services/rest';

async function getProductsToCableCutPlan(filters) {
  return RestService.postAuthenticated(`cableCuts/products/toCutList`, { filters });
}

async function createCutCablePlan(outboundOrderProductIds) {
  return RestService.putAuthenticated('cableCuts/createPlan', { outboundOrderProductIds });
}

async function changeAllocationProduct(outboundOrderProductIds, stockBalanceId) {
  return RestService.putAuthenticated('cableCuts/changeAlloc', {
    outboundOrderProductIds,
    stockBalanceId
  });
}

export default {
  getProductsToCableCutPlan,
  createCutCablePlan,
  changeAllocationProduct
}