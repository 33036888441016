import DepartmentsRequests from '../../server/departments';
import { addLoading, removeLoading } from './loading';

export const setDepartments = departments => ({
  type: 'SET_DEPARTMENTS_LIST',
  departments,
});

export const createDepartment = department => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newDepartment = await DepartmentsRequests.createDepartment(department);
    return newDepartment;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateDepartment = (department) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DepartmentsRequests.updateDepartment(department);
  } finally {
    dispatch(removeLoading());
  }
}

export const getDepartment = (departmentId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DepartmentsRequests.getDepartment(departmentId);
  } finally {
    dispatch(removeLoading());
  }
}

export const getDepartmentsList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const departments = await DepartmentsRequests.getDepartments();
    dispatch(setDepartments(departments));
  } finally {
    dispatch(removeLoading());
  }
};

export const getDepartmentsOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DepartmentsRequests.getDepartmentsOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteDepartment = (departmentId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DepartmentsRequests.deleteDepartment(departmentId);
  } finally {
    dispatch(removeLoading());
  }
}
