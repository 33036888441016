import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';


export default class ProductsList extends React.Component {

  validCheckbox = () => {
    const { selectedCheckbox } = this.props;
    if (Object.keys(selectedCheckbox).length) {
      const outboundOrderProductIds = [];
      for (const index in selectedCheckbox) {
        if (selectedCheckbox[index]) outboundOrderProductIds.push(index);
      }
      if (outboundOrderProductIds.length) {
        this.props.setOutboundOrderProductIds(outboundOrderProductIds);
      }
    }
  }

  singleSelection = (selection) => {
    const { selectedCheckbox } = this.props;
    selectedCheckbox[selection.outboundOrderProductId] = !selectedCheckbox[selection.outboundOrderProductId];
    this.props.setSelectedCheckbox(selectedCheckbox);
    this.validCheckbox();
  }

  multipleSelection = (selectionOption) => {
    const { products, selectedCheckbox } = this.props;
    if (products && products.length > 0) {
      for (const element of products) {
        if (selectedCheckbox[element.outboundOrderProductId] !== selectionOption && element.productStatus < 10 &&
          element.pickedQuantity !== '' && parseFloat(element.pickedQuantity) !== 0) {
          selectedCheckbox[element.outboundOrderProductId] = selectionOption;
        }
      }
      this.props.setSelectedCheckbox(selectedCheckbox);
      this.validCheckbox();
    }
  }

  render() {
    const { products } = this.props;

    return (
      <div>
        <div className="row">
          <div className='col text-right'>
            <button
              type="button"
              className="btn btn-120 btn-primary p-5 m-5"
              onClick={() => this.multipleSelection(true)}
            >
              {I18n.t('BEE1908' /* Marcar Todos */)}
            </button>
            <button
              type="button"
              className="btn btn-120 btn-primary p-5 m-5"
              onClick={() => this.multipleSelection(false)}
            >
              {I18n.t('BEE1909' /* Desmarcar Todos */)}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="table-responsive">
            <table className={'table table-bordered widget-table widget-table-rounded'}>
              <thead>
                <tr className="text-center f-s-12">
                  <th width="5%">{I18n.t('BEE463' /* Linha */)}</th>
                  <th width="50%">{I18n.t('BEE225' /* Produto */)}</th>
                  <th width="15%">{I18n.t('BEE442' /* Lotes */)}</th>
                  <th width="15%">{I18n.t('BEE1134' /* Quantidade Separada */)}</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => {
                  if (product.productStatus < 10 && product.pickedQuantity !== '' && parseFloat(product.pickedQuantity) !== 0) {
                    return (
                      <tr className="text-center" key={index}>
                        <td>
                          <h5>{product.lineNumber}</h5>
                          <br />
                          <p>
                            <input
                              type="checkbox"
                              checked={this.props.selectedCheckbox[product.outboundOrderProductId] || false}
                              onChange={() => this.singleSelection(product)}
                            />
                          </p>
                        </td>
                        <td className="text-left">
                          <h4 className={"widget-table-title"}>{product.productCode}</h4>
                          <p className={"widget-table-desc m-b-15"}>{product.productName}</p>
                          <p className={"widget-table-desc m-b-15"}>{product.productFullName}</p>
                        </td>
                        <td>
                          {product.lotNumber}
                        </td>
                        <td>
                          {product.pickedQuantity}
                        </td>
                      </tr>
                    )
                  }
                  return null;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

};