import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getIndustrialScalesList, deleteIndustrialScale } from '../../../../app/store/actions/industrialScales';

class IndustrialScaleList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      industrialScaleUpdate: null,
      industrialScalesList: [],
    };

    this.defaultSorted = [
      {
        id: 'identification',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showIndustrialScaleDetail(rows.row)}>
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showEditIndustrialScale(rows.row)}>
                  {I18n.t('BEE57' /* Editar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showCopyIndustrialScale(rows.row)}>
                  {I18n.t('BEE80' /* Copiar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showDeleteIndustrialScale(rows.row)}>
                  {I18n.t('BEE81' /* Eliminar */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE3847' /* Identificação */),
        accessor: 'identification',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE3854' /* Sala de Pesagem */),
        accessor: 'weighingRoom',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE494' /* Sequência */),
        accessor: 'sequence',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE3848' /* Número da Porta */),
        accessor: 'portNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE3849' /* Controle de Fluxo  */),
        accessor: 'flowControl',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
    }

    await this.getIndustrialScales();
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      industrialScaleUpdate: null,
      industrialScalesList: [],
    });
  };

  getIndustrialScales = async () => {
    const industrialScalesList = await this.props.getIndustrialScalesList();

    await this.setState({ industrialScalesList });
  };

  deleteIndustrialScale = async (industrialScale) => {
    const result = await this.props.deleteIndustrialScale(industrialScale.id);

    this.setInitState();
    if (result) {
      addNotification(
        'success',
        I18n.t('BEE3850' /* Eliminar Balança */),
        I18n.t('BEE3851', { 0: industrialScale.id } /* Balança Industrial %{0} eliminada com sucesso! */),
        'top-right',
      );
    }
    await this.getIndustrialScales();
  };

  showIndustrialScaleDetail = (industrialScale) => {
    this.props.history.push({
      pathname: ROUTES.INDUSTRIAL_SCALE_DETAIL,
      state: {
        industrialScale: industrialScale._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditIndustrialScale = (industrialScale) => {
    this.props.history.push({
      pathname: ROUTES.INDUSTRIAL_SCALE_EDIT,
      state: {
        industrialScale: industrialScale._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyIndustrialScale = (industrialScale) => {
    this.props.history.push({
      pathname: ROUTES.INDUSTRIAL_SCALE_COPY,
      state: {
        industrialScale: industrialScale._original,
      },
    });
  };

  showDeleteIndustrialScale = async (industrialScale) => {
    const { id } = industrialScale._original;

    await this.setState({ showModalDelete: true, industrialScaleUpdate: { id } });
  };

  render() {
    const {
      industrialScaleUpdate, showModalDelete, page, industrialScalesList,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE1396' /* Equipamentos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE3846' /* Balanças Industriais */)}</li>
          </ol>
        </div>
        <Table
          wikiHelp={ROUTES.INDUSTRIAL_SCALE_HELP}
          downloadCSV
          headerTitle={I18n.t('BEE3846' /* Balanças Industriais */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.INDUSTRIAL_SCALE_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE3852' /* Incluir Balança Industrial */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getIndustrialScales,
          }}
          filterable
          data={industrialScalesList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun
            && this.props.location.state
            && this.props.location.state.dice
            && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(p) => ((p >= 0) ? this.setState({ page: p }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {showModalDelete && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteIndustrialScale(industrialScaleUpdate)}
            onCancel={async () => { this.setInitState(); await this.getIndustrialScales(); }}
          >
            {I18n.t('BEE3853', { 0: industrialScaleUpdate.code } /* A balança industrial %{0} será eliminada! */)}
          </SweetAlert>
        )}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  industrialScalesList: state.industrialScales && state.industrialScales.industrialScalesList,
});

const mapDispatchToProps = (dispatch) => ({
  getIndustrialScalesList: () => dispatch(getIndustrialScalesList()),
  deleteIndustrialScale: (industrialScaleId) => dispatch(deleteIndustrialScale(industrialScaleId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IndustrialScaleList));
