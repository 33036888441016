import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import { createEan, getEan, updateEan } from '../../../../app/store/actions/eans';
import { setInboundCheckCreateEanState } from '../../../../app/store/actions/inboundCheck';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';
import helpersBarCode from '../../../../helpers/barCode';
import ROUTES from '../../../../config/routes';
import { getProductByCode } from '../../../../app/store/actions/products';
import { getPackagesList } from '../../../../app/store/actions/packages';
import { getLabelsByTrigger } from '../../../../app/store/actions/labels';
import Form from '../../../../components/form/form';

class EanForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageTitle: '',
      pageType: 'detail',
      productCode: '',
      disableProductCode: true,
      productDescription: '',
      barCodeType: '',
      disableBarCodeType: true,
      factor: 0,
      disableFactor: true,
      barCode: '',
      disableBarCode: true,
      packageCode: null,
      packageName: '',
      listPackages: [],
      disablePackage: true,
      mainBarCode: false,
      disableMainBarCode: true,
      note: '',
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      cancelPath: null,
      separateOnlyPackaging: false,
      disableSeparateOnlyPackaging: true,
      listLabels: [],
      labelCode: '',
      disableLabelCode: true,
    };
  }

  async componentDidMount() {
    await this.setScreenMode();
    if (this.state.mode !== 'create') {
      await this.getEanSelected();
    }
    const { mode } = this.state;
    const listLabels = await this.props.getLabelsByTrigger('ean');
    if (listLabels && listLabels.length) {
      await this.setState({
        listLabels,
        ...(mode === 'create' ? { labelCode: listLabels[0].code } : {}),
      });
    }
  }

  selectTypePage = () => {
    let pageType = '';
    let pageTitle = '';

    if (this.props.isCreate) {
      pageType = 'create';
      pageTitle = I18n.t('BEE826' /* Novo EAN */);
    } else if (this.props.isCopy) {
      pageType = 'copy';
      pageTitle = I18n.t('BEE826' /* Novo EAN */);
    } else if (this.props.isEdit) {
      pageType = 'edit';
      pageTitle = I18n.t('BEE832' /* Editar EAN */);
    } else if (this.props.isDetail) {
      pageType = 'detail';
      pageTitle = I18n.t('BEE833' /* Detalhes EAN */);
    }
    return { pageType, pageTitle };
  };

  setScreenMode = async () => {
    let listPackages = [];
    try {
      await this.props.getPackagesList();
      listPackages = (this.props.packagesList || []).map((element) => ({
        value: element.code,
        label: `${element.code} - ${element.name}`,
      }));
    } catch (error) { /* null */ }
    const { pageType, pageTitle } = this.selectTypePage();
    const updateData = { pageTitle, pageType, listPackages };
    if (pageType === 'create' || pageType === 'copy') {
      updateData.disableProductCode = false;
      updateData.showCreate = true;
      updateData.showCancel = true;
      updateData.showUpdate = false;
      if (pageType === 'create' && this.props.inboundCheckCreateEanState) {
        updateData.productCode = this.props.inboundCheckCreateEanState.productCode;
        updateData.disableProductCode = true;
        updateData.productDescription = this.props.inboundCheckCreateEanState.descriptionFull;
        updateData.disableBarCodeType = false;
        updateData.disableBarCode = true;
        updateData.disableMainBarCode = true;
        updateData.disableLabelCode = true;
        updateData.disablePackage = true;
        updateData.disableNote = true;
        updateData.disableSeparateOnlyPackaging = true;
        updateData.cancelPath = ROUTES.INBOUND_CHECK;
      }
    } else if (pageType === 'edit') {
      updateData.disableProductCode = true;
      updateData.disableBarCodeType = false;
      updateData.disableBarCode = false;
      updateData.disablePackage = false;
      updateData.disableMainBarCode = false;
      updateData.disableLabelCode = false;
      updateData.disableSeparateOnlyPackaging = false;
      updateData.disableNote = false;
      updateData.showCreate = false;
      updateData.showUpdate = true;
      updateData.showCancel = true;
    }
    await this.setState({ ...updateData });

    document.getElementById(1).focus();
    document.getElementById(1).select();
  };

  getEanSelected = async () => {
    if (this.props.location.state && this.props.location.state.ean) {
      const productEan = this.props.location.state.ean;

      const eanSelected = await this.props.getEan(productEan.id);

      if (eanSelected) {
        const { listPackages = [], pageType } = this.state;
        const packages = ((eanSelected.packageCode) ? listPackages.find(
          (element) => (element.value === eanSelected.packageCode),
        ) : {}) || {};

        this.setState({
          productEanId: eanSelected.id,
          productCode: eanSelected.productCode,
          productDescription: eanSelected.product.fullName,
          barCodeType: `${eanSelected.barCodeType}`,
          factor: eanSelected.factor,
          disableFactor: (pageType === 'detail' || pageType === 'copy') ? true : eanSelected.barCodeType === 1,
          barCode: eanSelected.ean,
          mainBarCode: eanSelected.main,
          separateOnlyPackaging: eanSelected.separateOnlyPackaging,
          packageCode: packages.value,
          packageName: packages.label,
          note: eanSelected.note,
          labelCode: eanSelected.labelCode,
        });
      }
    }
  };

  goToElement = async (e, ref, attr) => {
    if (e.keyCode === 13) {
      const fieldFocus = {
        productCode: 2,
        barCodeType: 3,
        factor: 4,
        barCode: 5,
        mainBarCode: 6,
        separateOnlyPackaging: 7,
        package: 8,
      };

      if (attr === 'productCode') {
        await this.getProductDescription();
        const { pageType, barCodeType } = this.state;
        if (pageType === 'copy') {
          this.setState({
            disableBarCode: false,
            disableMainBarCode: false,
            disableFactor: (barCodeType === '1'),
            disablePackage: false,
            disableNote: false,
            disableSeparateOnlyPackaging: false,
            disableLabelCode: false,
          });
        }
      } else if (attr === 'note') {
        this.createEanSubmit();
      }

      if (fieldFocus[attr]) document.getElementById(fieldFocus[attr]).focus();
    }
  };

  setValue = async (attr, value, clean = false) => {
    let updateData = { [`${attr}`]: value };

    if (attr === 'separateOnlyPackaging' || attr === 'mainBarCode') {
      updateData = { [`${attr}`]: value.toUpperCase() === 'TRUE' };
    } else if (attr === 'barCodeType') {
      updateData.disableBarCode = false;
      updateData.disableMainBarCode = false;
      updateData.disablePackage = false;
      updateData.disableNote = false;
      updateData.disableSeparateOnlyPackaging = false;
      updateData.disableLabelCode = false;

      if (!value) {
        updateData.factor = 0;
        updateData.disableFactor = true;
        updateData.disableBarCode = true;
        updateData.disableMainBarCode = true;
        updateData.disablePackage = true;
        updateData.disableNote = true;
        updateData.disableSeparateOnlyPackaging = true;
        updateData.disableLabelCode = true;
      } else if (value === '1') {
        updateData.factor = 1;
        updateData.disableFactor = true;
      } else {
        updateData.factor = 1;
        updateData.disableFactor = false;
      }
    }

    if (attr === 'mainBarCode') {
      document.getElementById(6).focus();
    } else if (attr === 'separateOnlyPackaging') {
      document.getElementById(7).focus();
    }

    if (clean) {
      updateData.productCode = '';
      updateData.disableProductCode = false;
      updateData.productDescription = '';
      updateData.barCodeType = '';
      updateData.disableBarCodeType = true;
      updateData.factor = 0;
      updateData.disableFactor = true;
      updateData.barCode = '';
      updateData.disableBarCode = true;
      updateData.mainBarCode = false;
      updateData.disableMainBarCode = true;
      updateData.packageCode = null;
      updateData.packageName = '';
      updateData.disablePackage = true;
      updateData.disableNote = true;
      updateData.disableSeparateOnlyPackaging = true;
      updateData.separateOnlyPackaging = false;
      updateData.disableLabelCode = true;
    }

    await this.setState({ ...updateData });

    if (!updateData.disableFactor && attr === 'barCodeType') {
      document.getElementById(3).focus();
    }
  };

  setValueDrop = async (attr, value) => {
    const updateData = {
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    };
    await this.setState({ ...updateData });

    if (attr === 'package') {
      document.getElementById(8).focus();
    }
  };

  getProductDescription = async () => {
    const { productCode, pageTitle } = this.state;
    try {
      const productDetails = await this.props.getProductDetails(productCode);
      const updateData = {};
      await this.setState({
        disableProductCode: true,
        productDescription: productDetails.fullName,
        disableBarCodeType: false,
        ...updateData,
      });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;
      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification(
          'danger',
          pageTitle,
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          pageTitle,
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  createEanSubmit = async () => {
    const {
      disableProductCode,
      productCode,
      barCodeType,
      factor,
      barCode,
      mainBarCode,
      packageCode,
      note,
      separateOnlyPackaging,
      labelCode,
    } = this.state;

    if (!disableProductCode) {
      this.goToElement({ keyCode: 13 }, 1, 'productCode');
    } else {
      try {
        const newEan = await this.props.createEan({
          productCode,
          barCodeType: parseInt(barCodeType, 10),
          factor,
          barCode,
          mainBarCode,
          packageCode,
          note,
          separateOnlyPackaging,
          labelCode,
        });

        if (newEan) {
          if (newEan.success && newEan.success === false) {
            await addNotification(
              'danger',
              I18n.t('BEE826' /* Novo EAN */),
              I18n.t('BEE827', { 0: barCode } /* Erro ao incluir o EAN %{0} ! */),
              'top-right',
            );
          } else {
            await addNotification(
              'success',
              I18n.t('BEE826' /* Novo EAN */),
              I18n.t('BEE828', { 0: barCode } /* Novo EAN %{0} incluída com sucesso ! */),
              'top-right',
            );

            if (this.props.inboundCheckCreateEanState) {
              const createEanState = { ...this.props.inboundCheckCreateEanState };
              createEanState.newEan = barCode;
              this.props.setInboundCheckCreateEanState(createEanState);
              this.props.history.push(ROUTES.INBOUND_CHECK, { fromPage: 'createEan' });
            } else {
              this.props.history.push(ROUTES.EAN_LIST);
            }
          }
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;

          if (error.details || error.message) {
            await addNotification(
              'danger',
              I18n.t('BEE826' /* Novo EAN */),
              `${error.code} - ${error.details || error.message}`,
              'top-right',
            );
          } else {
            await addNotification(
              'danger',
              I18n.t('BEE826' /* Novo EAN */),
              I18n.t('BEE827', { 0: barCode } /* Erro ao incluir o EAN %{0} ! */),
              'top-right',
            );
          }
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE826' /* Novo EAN */),
            I18n.t('BEE827', { 0: barCode } /* Erro ao incluir o EAN %{0} ! */),
            'top-right',
          );
        }
      }
    }
  };

  updateEanSubmit = async () => {
    const {
      productEanId,
      productCode,
      barCodeType,
      factor,
      barCode,
      mainBarCode,
      packageCode,
      note,
      separateOnlyPackaging,
      labelCode,
    } = this.state;

    try {
      const updEan = await this.props.updateEan({
        productEanId,
        productCode,
        barCodeType: parseInt(barCodeType, 10),
        factor,
        barCode,
        mainBarCode,
        packageCode,
        note,
        separateOnlyPackaging,
        labelCode,
      });

      if (updEan) {
        if (updEan.success && updEan.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE829' /* Atualizar EAN */),
            I18n.t('BEE830', { 0: barCode } /* Erro ao atualizar o EAN %{0} ! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE829' /* Atualizar EAN */),
            I18n.t('BEE831', { 0: barCode } /* Atualização do EAN %{0} efetuada com sucesso ! */),
            'top-right',
          );

          this.props.history.push({
            pathname: ROUTES.EAN_LIST,
            state: {
              fromPage: 'createEan',
            },
          });
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE829' /* Atualizar EAN */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE829' /* Atualizar EAN */),
            I18n.t('BEE830', { 0: barCode } /* Erro ao atualizar o EAN %{0} ! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE829' /* Atualizar EAN */),
          I18n.t('BEE830', { 0: barCode } /* Erro ao atualizar o EAN %{0} ! */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      pageTitle, productCode, disableProductCode, productDescription, separateOnlyPackaging, disableLabelCode,
      barCodeType, disableBarCodeType, factor, disableFactor, barCode, disableBarCode, listLabels = [],
      packageCode, packageName, listPackages, disablePackage, mainBarCode, disableMainBarCode, labelCode,
      note, disableNote, showCancel, showUpdate, showCreate, cancelPath, disableSeparateOnlyPackaging,
    } = this.state;

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {` ${I18n.t('BEE12' /* Início */)}`}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE377' /* EAN */)}</li>
            <li className="breadcrumb-item active">{pageTitle}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {pageTitle}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  <Form
                    noPanel
                    setValue={this.setValue}
                    setValueDrop={this.setValueDrop}
                    inputs={(formContext) => ([
                      this.props.isCreate || this.props.isCopy
                        ? formContext.createInputButton(
                          productCode,
                          'productCode',
                          `${I18n.t('BEE378' /* Código do Produto */)}:`,
                          I18n.t('BEE2454' /* Informe o código do produto */),
                          'text',
                          true,
                          disableProductCode,
                          undefined,
                          undefined,
                          this.goToElement,
                          1,
                          ['form-group row m-b-15', 'col-form-label col-md-4', 'col-md-5 d-flex flex-row'],
                        )
                        : formContext.createInput(
                          productCode,
                          'productCode',
                          `${I18n.t('BEE378' /* Código do Produto */)}:`,
                          I18n.t('BEE2454' /* Informe o código do produto */),
                          'text',
                          true,
                          disableProductCode,
                          undefined,
                          undefined,
                          this.goToElement,
                          1,
                        ),
                      formContext.createTextArea(
                        productDescription,
                        'productDescription',
                        `${I18n.t('BEE758' /* Descrição do Produto */)}:`,
                        '',
                        3,
                        false,
                        true,
                      ),
                      formContext.createSelect(
                        barCodeType,
                        'barCodeType',
                        `${I18n.t('BEE200' /* Tipo */)}:`,
                        [
                          { value: '', label: '' },
                          ...helpersBarCode.barCodeType(),
                        ],
                        disableBarCodeType,
                        undefined,
                        undefined,
                        undefined,
                        true,
                        undefined,
                        2,
                      ),
                      formContext.createSelect(
                        labelCode,
                        'labelCode',
                        `${I18n.t('BEE4108' /* Modelo de Etiqueta */)}:`,
                        listLabels.map((item) => (
                          { value: item.code, label: item.code + (item.description ? ` - ${item.description}` : '') })),
                        disableLabelCode,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        5,
                      ),
                      formContext.createInput(
                        Math.abs(factor),
                        'factor',
                        `${I18n.t('BEE2591' /* Fator */)}:`,
                        '',
                        'number',
                        true,
                        disableFactor,
                        undefined,
                        undefined,
                        this.goToElement,
                        3,
                      ),
                      formContext.createInput(
                        barCode,
                        'barCode',
                        `${I18n.t('BEE377' /* EAN */)}:`,
                        I18n.t('BEE3748' /* Informe o Código de Barras */),
                        'text',
                        true,
                        disableBarCode,
                        undefined,
                        undefined,
                        this.goToElement,
                        4,
                      ),
                      formContext.createSelect(
                        mainBarCode,
                        'mainBarCode',
                        `${I18n.t('BEE836' /* O EAN é o principal para o produto */)}:`,
                        helpersBarCode.listMainBarCode(),
                        disableMainBarCode,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        5,
                      ),
                      formContext.createSelect(
                        separateOnlyPackaging,
                        'separateOnlyPackaging',
                        `${I18n.t('BEE3916' /* Separar somente embalagem fechada */)}:`,
                        helpersBarCode.listSeparateOnlySealedPackaging(),
                        disableSeparateOnlyPackaging,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        6,
                      ),
                      formContext.createSelectDropDown(
                        { value: packageCode, label: packageName },
                        'package',
                        `${I18n.t('BEE2355' /* Embalagem Padrão */)}:`,
                        listPackages,
                        disablePackage,
                        undefined,
                        undefined,
                        undefined,
                        7,
                        undefined,
                        separateOnlyPackaging,
                      ),
                      formContext.createInput(
                        note,
                        'note',
                        `${I18n.t('BEE135' /* Observação */)}:`,
                        I18n.t('BEE136' /* Informe a observação */),
                        'text',
                        false,
                        disableNote,
                        undefined,
                        undefined,
                        this.goToElement,
                        8,
                      ),
                    ])}
                  />
                </PanelBody>
                <PanelFooter>
                  <Link to={{
                    pathname: showCancel && cancelPath ? cancelPath : ROUTES.EAN_LIST,
                    state: showCancel && cancelPath ? { fromPage: 'createEan' } : { dice: this.props.location.dice },
                  }}
                  >
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-white p-5 m-5"
                    >
                      {showCancel ? I18n.t('BEE99' /* Cancelar */) : I18n.t('BEE137' /* Voltar */)}
                    </OneClickButton>
                  </Link>
                  {(showCreate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.createEanSubmit()}
                      >
                        {I18n.t('BEE138' /* Criar */)}

                      </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.updateEanSubmit()}
                      >
                        {I18n.t('BEE139' /* Atualizar */)}

                      </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  inboundCheckCreateEanState: state.inboundCheck && state.inboundCheck.createEanState,
  packagesList: state.packages && state.packages.packagesList,
});

const mapDispatchToProps = (dispatch) => ({
  getEan: (productEanId) => dispatch(getEan(productEanId)),
  createEan: (newBarCode) => dispatch(createEan(newBarCode)),
  updateEan: (newBarCode) => dispatch(updateEan(newBarCode)),
  setInboundCheckCreateEanState: (createEanProductCode) => dispatch(
    setInboundCheckCreateEanState(createEanProductCode),
  ),
  getProductDetails: (productCode) => dispatch(getProductByCode(productCode)),
  getPackagesList: () => dispatch(getPackagesList()),
  getLabelsByTrigger: (trigger) => dispatch(getLabelsByTrigger(trigger)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EanForm));
