/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';

export const returnFiltersByRules = (filters = []) => filters.reduce((acum, it) => {
  if (['and', 'or'].includes(it[1].type)) {
    if (!it[1].value) return acum;
    if (typeof it[1].value === 'object' && it[1].value instanceof Date) {
      it[1].value = moment(it[1].value, 'DD/MM/YYYY', true).format();
    }
  } else if (it[1].type === 'between') {
    if (!it[1].value || ((it[1].value[0] !== 0 && it[1].value[1] !== 0) && (!it[1].value[0] && !it[1].value[1]))) {
      return acum;
    } if (it[1].value[0] && !it[1].value[1] && !it[1].value[1] !== 0) {
      const valueFromType = typeof it[1].value[0];
      if (valueFromType === 'string') {
        it[1].value[1] = 'Z'.repeat(100);
      } else if (valueFromType === 'number') {
        it[1].value[1] = 99999999999999999999;
      } else if (valueFromType === 'object') {
        if (it[1].value[0] instanceof Date) {
          it[1].value[0] = moment(it[1].value[0], 'DD/MM/YYYY', true).format();
          it[1].value[1] = moment('31/12/9999', 'DD/MM/YYYY', true).format();
        }
      }
    } else if (it[1].value[0] && it[1].value[1]) {
      if (typeof it[1].value[0] === 'object' && it[1].value[0] instanceof Date) {
        it[1].value[0] = moment(it[1].value[0], 'DD/MM/YYYY', true).format();
        it[1].value[1] = moment(it[1].value[1], 'DD/MM/YYYY', true).format();
      }
    }
  } else if (it[1].type === 'in') {
    if (!it[1].value || !it[1].value.length) return acum;
  }

  acum[it[0]] = it[1];

  return acum;
}, {});
