import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import OneClickButton from '../../../../components/form/button';
import { createWizard, setFirstAccess, setFormErrors } from '../../../../app/store/actions/wizard';
import WizardStep from '../form/WizardStep';
import FormUserWizard from '../form/FormUserWizard';
import FormCompanyWizard from '../form/FormCompanyWizard';
import addNotification from '../../../../components/notification';
import ROUTES from '../../../../config/routes';
import validationForm from '../../validator/validateForm';

class PanelWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
    };
  }

  nextStep = () => {
    const {
      name, email, login, password, confirmPassword,
    } = this.props;
    const formData = {
      name,
      email,
      login,
      password,
      confirmPassword,
    };

    const { validateFormUser } = validationForm();

    const errors = validateFormUser(formData);

    let hasError = false;
    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([key, value]) => {
        this.props.setFormErrors(key, value);
      });
      hasError = true;
    }

    if (!hasError) {
      this.setState({ step: 2 });
    }
  };

  previewStep = () => {
    this.setState({ step: 1 });
  };

  createWizard = async () => {
    const {
      email,
      password,
      confirmPassword,
      login,
      name,
      code,
      corporateName,
      companyName,
      companyEmail,
      phone,
      cnpj,
      stateRegistration,
      postalCode,
      street,
      number,
      complement,
      district,
      city,
      state,
      country,
      ibge,
    } = this.props;

    try {
      const formData = {
        code,
        corporateName,
        companyName,
        companyEmail,
        phone,
        cnpj,
        stateRegistration,
        postalCode,
        street,
        number,
        complement,
        district,
        city,
        state,
        country,
      };

      const { validateFormCompany } = validationForm();

      const errors = validateFormCompany(formData);

      let hasError = false;
      if (Object.keys(errors).length > 0) {
        Object.entries(errors).forEach(([key, value]) => {
          this.props.setFormErrors(key, value);
        });
        hasError = true;
      }

      if (!hasError) {
        const newWizard = await this.props.createWizard({
          email,
          password,
          login,
          name,
          confirmPassword,
          code,
          corporateName,
          companyEmail,
          companyName,
          phone,
          cnpj,
          stateRegistration,
          postalCode,
          street,
          number,
          complement,
          district,
          city,
          state,
          country,
          ibge,
        });

        if (newWizard) {
          if (newWizard.success === false) {
            await addNotification(
              'danger',
              I18n.t('BEE2916' /* Erro no cadastro */),
              I18n.t('BEE2917' /* Erro ao tentar efetuar cadastro de formulário. */),
              'top-right',
            );
          } else {
            await addNotification(
              'success',
              I18n.t('BEE2919' /* Cadastro efetuado */),
              I18n.t('BEE2918' /* Cadastro realizado com sucesso. */),
              'top-right',
            );

            this.props.setFirstAccess(false);

            this.props.history.push(ROUTES.LOGIN);
          }
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE2916' /* Erro no cadastro */),
            I18n.t('BEE2917' /* Erro ao tentar efetuar cadastro de formulário. */),
            'top-right',
          );
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE2916' /* Erro no cadastro */),
            `${error.code} - ${error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE2916' /* Erro no cadastro */),
            I18n.t('BEE2917' /* Erro ao tentar efetuar cadastro de formulário. */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE2916' /* Erro no cadastro */),
          I18n.t('BEE2917' /* Erro ao tentar efetuar cadastro de formulário. */),
          'top-right',
        );
      }
    }
  };

  render() {
    const { step } = this.state;

    let firstClassName = 'disabled';
    let secondClassName = 'disabled';

    if (step === 1) {
      firstClassName = 'active';
    } else if (step > 1) {
      firstClassName = 'completed';
    }

    if (step === 2) {
      secondClassName = 'active';
    } else if (step > 2) {
      secondClassName = 'completed';
    }

    return (
      <div className="container mb-4">
        <WizardStep
          onClick={this.previewStep}
          className={`nav-link ${firstClassName} mb-5`}
          style={step === 2 ? { color: '#333' } : {}}
          className2={`nav-link ${secondClassName}`}
        />
        {step === 1 ? (
          <h1 className="mb-4 fw-lighter">{I18n.t('BEE2914' /* Cadastro de usuário */)}</h1>
        ) : (
          <h1 className="mb-4 fw-lighter">{I18n.t('BEE2915' /* Cadastro de empresa */)}</h1>
        )}

        <Panel>
          <PanelHeader style={{ height: '100px', padding: '50px' }} noButton />
          <PanelBody>
            <div className="row">
              <div className="col-xl-12">
                {step === 1 && <FormUserWizard />}
                {step === 2 && <FormCompanyWizard />}
              </div>
            </div>
          </PanelBody>
          <PanelFooter className="text-right">
            {step === 1 && (
              <OneClickButton type="button" className="btn btn-120 btn-primary p-5 m-5" onClick={this.nextStep}>
                {I18n.t('BEE96' /* Próximo */)}
              </OneClickButton>
            )}
            {step === 2 && (
              <div>
                <OneClickButton type="button" className="btn btn-120 btn-light p-5 m-5" onClick={this.previewStep}>
                  {I18n.t('BEE95' /* Anterior */)}
                </OneClickButton>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={() => this.createWizard()}
                >
                  {I18n.t('BEE138' /* Criar */)}
                </OneClickButton>
              </div>
            )}
          </PanelFooter>
        </Panel>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createWizard: (user) => dispatch(createWizard(user)),
  setFirstAccess: (state) => dispatch(setFirstAccess(state)),
  setFormErrors: (attr, value) => dispatch(setFormErrors(attr, value)),
});

const mapStateToProps = (state) => ({
  email: state.wizard.email,
  password: state.wizard.password,
  login: state.wizard.login,
  name: state.wizard.name,
  confirmPassword: state.wizard.confirmPassword,
  code: state.wizard.code,
  corporateName: state.wizard.corporateName,
  companyName: state.wizard.companyName,
  companyEmail: state.wizard.companyEmail,
  phone: state.wizard.phone,
  cnpj: state.wizard.cnpj,
  stateRegistration: state.wizard.stateRegistration,
  postalCode: state.wizard.postalCode,
  street: state.wizard.street,
  number: state.wizard.number,
  complement: state.wizard.complement,
  district: state.wizard.district,
  city: state.wizard.city,
  state: state.wizard.state,
  country: state.wizard.country,
  ibge: state.wizard.ibge,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PanelWizard));
