/* eslint-disable react/button-has-type */
import React from 'react';

export default class OneClickButton extends React.PureComponent {
  constructor() {
    super();

    this.mounted = React.createRef();

    this.state = {
      disableButton: false,
    };
  }

  componentDidMount() {
    this.mounted.current = true;
  }

  componentWillUnmount() {
    this.mounted.current = false;
  }

  lockButton = async () => {
    if (!this.state.disableButton) {
      if (this.props && this.props.onClick) {
        await this.setState({ disableButton: true });
        this.props.onClick();
        setTimeout(() => {
          if (this.mounted.current) {
            this.setState({ disableButton: false });
          }
        }, 2000);
      }
    }
  };

  render() {
    return (
      <button
        onClick={this.lockButton}
        type={this.props.type || 'button'}
        className={this.props.className}
        disabled={(this.props.disabled || this.state.disableButton)}
        style={this.props.style}
        id={this.props.id}
      >
        {this.props.children}
      </button>
    );
  }
}
