import RestService from '../services/rest';

// GET
async function getRanges() {
  return RestService.getAuthenticated('ranges/list/all');
}

async function getUserByRange() {
  return RestService.getAuthenticated('ranges/list/rangeByUser');
}

async function getRange(rangeId) {
  return RestService.getAuthenticated(`ranges/detail?rangeId=${rangeId}`);
}

async function getRangesOptions(branchCode) {
  return RestService.getAuthenticated(`ranges/list/options?branchCode=${branchCode}`);
}

// POST
async function createRange(range) {
  return RestService.postAuthenticated('ranges/create', range);
}

async function deleteRange(rangeId, rangeCode) {
  return RestService.postAuthenticated('ranges/delete', { rangeId, rangeCode });
}

async function previewLinkAddresses(filters) {
  return RestService.postAuthenticated('ranges/linkAddresses/preview', { filters });
}

async function removeLinkAddress(storageAddressId) {
  return RestService.postAuthenticated('ranges/linkAddresses/remove', { storageAddressId });
}

async function getUserList(tableParams) {
  return RestService.postAuthenticated('ranges/linkUser/list', tableParams);
}

async function createBulkImportUsersRange(jsonCsv) {
  return RestService.postAuthenticated('ranges/linkUser/bulkImportUsersRange', { jsonCsv });
}

async function createLinkUser(userId, rangeCode) {
  return RestService.postAuthenticated('ranges/linkUser/create', { userId, rangeCode });
}

async function deleteLinkUser(usersRangesId) {
  return RestService.postAuthenticated('ranges/linkUser/delete', { usersRangesId });
}

async function getRangeAddress(tableParams) {
  return RestService.postAuthenticated('ranges/RangeAddress', tableParams);
}

// PUT
async function updateRange(range) {
  return RestService.putAuthenticated('ranges/update', range);
}

async function persistLinkAddresses(rangeCode, filters) {
  return RestService.putAuthenticated('ranges/linkAddresses/persist', { rangeCode, filters });
}

export default {
  getRanges,
  createRange,
  updateRange,
  getRange,
  deleteRange,
  getRangesOptions,
  previewLinkAddresses,
  persistLinkAddresses,
  removeLinkAddress,
  createLinkUser,
  deleteLinkUser,
  getUserList,
  createBulkImportUsersRange,
  getRangeAddress,
  getUserByRange,
};
