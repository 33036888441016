import { I18n } from 'react-redux-i18n';

function inventoryStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE470' /* Pendente */);
      case 2:
        return I18n.t('BEE2115' /* Em Contagem */);
      case 3:
        return I18n.t('BEE2116' /* Contagem Finalizada */);
      case 4:
        return I18n.t('BEE2119' /* Aprovado */);
      case 5:
        return I18n.t('BEE2118' /* Atualizado */);
      case 6:
        return I18n.t('BEE64' /* Cancelado */);
      case 7:
        return I18n.t('BEE2201' /* Reprovado */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE470' /* Pendente */) },
      { value: 2, label: I18n.t('BEE2115' /* Em Contagem */) },
      { value: 3, label: I18n.t('BEE2116' /* Contagem Finalizada */) },
      { value: 4, label: I18n.t('BEE2119' /* Aprovado */) },
      { value: 5, label: I18n.t('BEE2118' /* Atualizado */) },
      { value: 6, label: I18n.t('BEE64' /* Cancelado */) },
      { value: 7, label: I18n.t('BEE2201' /* Reprovado */) },
    ];
  }
}

function inventoryType(type) {
  if (type || type === 0) {
    switch (type) {
      case 1:
        return I18n.t('BEE2085' /* Rotativo */);
      case 2:
        return I18n.t('BEE191' /* Geral */);
      case 3:
        return I18n.t('BEE2760' /* Etiqueta Seriada */);
      default:
        return type;
    }
  } else if (!type) {
    return [
      { value: 1, label: I18n.t('BEE2085' /* Rotativo */) },
      { value: 2, label: I18n.t('BEE191' /* Geral */) },
      { value: 3, label: I18n.t('BEE2760' /* Etiqueta Seriada */) },
    ];
  }
}

function inventorySheetStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE470' /* Pendente */);
      case 2:
        return I18n.t('BEE2115' /* Em Contagem */);
      case 3:
        return I18n.t('BEE2116' /* Contagem Finalizada */);
      case 4:
        return I18n.t('BEE2188' /* Aprovada */);
      case 5:
        return I18n.t('BEE2117' /* Atualizada */);
      case 6:
        return I18n.t('BEE2189' /* Cancelada */);
      case 7:
        return I18n.t('BEE2200' /* Reprovada */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE470' /* Pendente */) },
      { value: 2, label: I18n.t('BEE2115' /* Em Contagem */) },
      { value: 3, label: I18n.t('BEE2116' /* Contagem Finalizada */) },
      { value: 4, label: I18n.t('BEE2188' /* Aprovada */) },
      { value: 5, label: I18n.t('BEE2117' /* Atualizada */) },
      { value: 6, label: I18n.t('BEE2189' /* Cancelada */) },
      { value: 7, label: I18n.t('BEE2200' /* Reprovada */) },
    ];
  }
}

function inventoryCountQuantityStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE470' /* Pendente */);
      case 2:
        return I18n.t('BEE2120' /* Finalizada */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE470' /* Pendente */) },
      { value: 2, label: I18n.t('BEE2120' /* Finalizada */) },
    ];
  }
}

function inventorySheetGroupStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE470' /* Pendente */);
      case 2:
        return I18n.t('BEE2120' /* Finalizada */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE470' /* Pendente */) },
      { value: 2, label: I18n.t('BEE2120' /* Finalizada */) },
    ];
  }
}

function inventorySheetGroupCountStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE470' /* Pendente */);
      case 2:
        return I18n.t('BEE3617' /* Confirmação Seriadas */);
      case 3:
        return I18n.t('BEE2120' /* Finalizada */);
      case 4:
        return I18n.t('BEE2189' /* Cancelada */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE470' /* Pendente */) },
      { value: 2, label: I18n.t('BEE3617' /* Confirmação Seriadas */) },
      { value: 3, label: I18n.t('BEE2120' /* Finalizada */) },
      { value: 4, label: I18n.t('BEE2189' /* Cancelada */) },
    ];
  }
}

export default {
  inventoryStatus,
  inventoryType,
  inventorySheetStatus,
  inventoryCountQuantityStatus,
  inventorySheetGroupStatus,
  inventorySheetGroupCountStatus,
};
