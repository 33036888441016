import React from 'react';
import ReactDropzone from 'react-dropzone';
import './bulkImportStyles.scss';
import { I18n } from 'react-redux-i18n';
import Papa from 'papaparse';
import { connect } from 'react-redux';
import { setJsonContent } from '../../app/store/actions/bulkImport';

import { DropFile, DropFileSuccess } from '../../assets/css/material/images/svgs';
import addNotification from '../notification';

class DragAndDrop extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      file: null,
      json: null,
    };
  }

  convertJsonFormat = (results) => {
    this.setState({ json: [...results.data] });
    const array = this.state.json;

    const fields = array[0];
    fields.push('line');
    const data = array.slice(1);

    const objArray = data.map((values, indexMap) => fields.reduce((obj, field, index) => {
      if (field === 'line') {
        obj[field] = indexMap + 2;
      } else {
        obj[field] = values[index];
      }
      return obj;
    }, {}));

    this.props.setJsonContent(objArray);
  };

  onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      addNotification(
        'danger',
        I18n.t('BEE2509' /* Importação em massa */),
        I18n.t('BEE2508' /* Só é permitido selecionar um arquivo por vez! */),
        'top-right',
      );
      return;
    }
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      const fileSelected = reader.result;
      Papa.parse(fileSelected, {
        delimiter: ';',
        complete: (results) => {
          this.convertJsonFormat(results);
        },
      });
    };

    this.setState({ file });

    if (this.state.file.type !== 'text/csv') {
      this.setState({ file: null });
      await this.props.setJsonContent(this.state.file);
      return (
        addNotification(
          'danger',
          I18n.t('BEE2509' /* Importação em massa */),
          I18n.t('BEE2510' /* Apenas arquivos do formato .CSV podem ser importados! */),
          'top-right',
        )
      );
    }

    reader.readAsText(file, 'ISO-8859-1');
  };

  onClear = async () => {
    await this.props.setJsonContent(null);
    await this.setState({
      file: null,
      json: null,
    });
  };

  render() {
    const { buttonClear = false } = this.props;
    return (
      <ReactDropzone
        onDrop={this.onDrop}
        accept=".csv"
      >
        {({ getRootProps, getInputProps }) => (
          <div className={`${this.props.className || 'dragAndDropImportCaption'} position-relative`}>
            {buttonClear && this.state.file
            && (
            <div className="position-absolute  top-0 end-0 p-3" style={{ right: 0 }}>
              <button type="button" onClick={this.onClear}>X</button>
            </div>
            )}
            <div {...getRootProps()} className="dropZone">
              <input {...getInputProps()} />
              {
                this.state.file ? (
                  <DropFileSuccess
                    className="dropIcon"
                    width={125}
                    heigh={125}
                  />
                ) : (
                  <DropFile
                    className="dropIcon"
                    width={125}
                    heigh={125}
                  />
                )
              }
              <div className="dropText">
                {
                  this.state.file ? (
                    <span className="dropTextBold">{this.state.file?.name}</span>
                  )
                    : (
                      <>
                        <span className="dropTextBold">
                          {`${I18n.t('BEE2506' /* Selecione um arquivo clicando aqui */)} `}
                        </span>
                        <span>{I18n.t('BEE2507' /* ou arraste-o para cá. */)}</span>
                        <div className="dropTextOrange">
                          <span>.CSV</span>
                        </div>
                      </>
                    )
                }
              </div>
            </div>
          </div>
        )}
      </ReactDropzone>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setJsonContent: (jsonCsv) => dispatch(setJsonContent(jsonCsv)),
});

export default connect(undefined, mapDispatchToProps)(DragAndDrop);
