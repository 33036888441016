import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import ProductHelpers from '../../../../helpers/products';

import { getProductsList, deleteProduct } from '../../../../app/store/actions/products';

class ProductList extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      showModalDelete: false,
      productUpdate: null,
      pages: 0,
      totalDataLength: 0,
    };

    this.defaultSorted = [
      {
        id: 'productCode',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showProductDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditProduct(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyProduct(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                {!this.props.formIntegrations && (
                  <DropdownItem onClick={() => this.showDeleteProduct(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 400,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE695' /* Código Complementar */),
        accessor: 'complementCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE384' /* Unidade */),
        accessor: 'unitMeasure',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE773' /* Controle Estoque */),
        accessor: 'stockControlType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>{ProductHelpers.stockControlType(row.value)}</span>
        ),
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {ProductHelpers.stockControlType().map((element, index) => <option key={index} value={element.value}>{element.label}</option>)}
          </select>
        ),
      }, {
        Header: I18n.t('BEE774' /* Controle Produto */),
        accessor: 'productControlType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>{ProductHelpers.productControlType(row.value)}</span>
        ),
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {ProductHelpers.productControlType().map((element, index) => <option key={index} value={element.value}>{element.label}</option>)}
          </select>
        ),
      }, {
        Header: I18n.t('BEE710' /* Classificação Fiscal */),
        accessor: 'taxClassification',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE711' /* Unidade de Negócio */),
        accessor: 'businessUnit',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE694', { 0: 1 } /* Nível %{0} */),
        accessor: 'level1',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE694', { 0: 2 } /* Nível %{0} */),
        accessor: 'level2',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE694', { 0: 3 } /* Nível %{0} */),
        accessor: 'level3',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE694', { 0: 4 } /* Nível %{0} */),
        accessor: 'level4',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE694', { 0: 5 } /* Nível %{0} */),
        accessor: 'level5',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          const status = ProductHelpers.productStatus(id);

          if (_.includes(_.lowerCase(status), input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            <i
              className="fa fa-circle f-s-7 fa-fw m-r-10 m-t-5"
              style={{
                color: row.value === 0 ? '#ff5b57'
                  : '#00acac',
                transition: 'all .3s ease',
              }}
            />
            {' '}
            {ProductHelpers.productStatus(row.value)}
          </span>
        ),
        Filter: this.filterColumn,
      },
    ];
  }

  async componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({ page: this.props.location.state.dice.page });
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      productUpdate: null,
    });
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        const { value } = event.target;
        const { keyCode, which } = event;

        if (value !== '' && (keyCode === 13 || which === 13)) {
          onChange(value);
        }
      }}
      onKeyUp={(event) => {
        const { value } = event.target;
        const { keyCode, which } = event;

        if (value === '' && (keyCode === 8 || which === 8)) {
          onChange(value);
        }
      }}
    />
  );

  getProducts = async () => {
    const data = await this.props.getProductsList({
      page: this.page,
      pageSize: this.pageSize,
      sorted: this.sorted,
      filtered: this.filtered,
    });

    this.setState({ pages: data.pages, firstRun: true, totalDataLength: data.totalDataLength });
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deleteProduct = async (product) => {
    const result = await this.props.deleteProduct(product._original.id);

    this.setInitState();
    if (result) {
      addNotification('success', I18n.t('BEE771' /* Eliminar Produto */), I18n.t('BEE772' /* Produto eliminado com sucesso! */), 'top-right');
    }
    await this.getProducts();
  };

  showProductDetail = (product) => {
    this.props.history.push({
      pathname: ROUTES.PRODUCT_DETAIL,
      state: {
        product: product._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditProduct = (product) => {
    this.props.history.push({
      pathname: ROUTES.PRODUCT_EDIT,
      state: {
        product: product._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyProduct = (product) => {
    this.props.history.push({
      pathname: ROUTES.PRODUCT_COPY,
      state: {
        product: product._original,
      },
    });
  };

  showDeleteProduct = (product) => {
    this.setState({ showModalDelete: true, productUpdate: product });
  };

  render() {
    const { productsList = [] } = this.props;
    const {
      productUpdate, showModalDelete, isLoading, pages, page, totalDataLength,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE27' /* Produtos */)}</li>
          </ol>
        </div>
        <Table
          wikiHelp={ROUTES.PRODUCT_HELP}
          downloadCSV
          headerTitle={I18n.t('BEE27' /* Produtos */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.PRODUCT_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE359' /* Incluir Produto */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getProducts,
          }}
          loading={isLoading}
          filterable
          data={productsList}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          manual // Server - Side Table
          pages={pages}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          onFetchData={(state, instance) => {
            this.page = (state.page) ? state.page : (!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : state.page;
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;

            this.getProducts();
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteProduct(productUpdate)}
            onCancel={() => this.setInitState()}
          >
            O produto $
            {productUpdate.productCode}
            {' '}
            será eliminada!
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productsList: state.products && state.products.productsList,
  formIntegrations: state.app.permissionsCompany && state.app.permissionsCompany.formIntegrations,
});

const mapDispatchToProps = (dispatch) => ({
  getProductsList: (tableParams) => dispatch(getProductsList(tableParams)),
  deleteProduct: (productId) => dispatch(deleteProduct(productId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductList));
