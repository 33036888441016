import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import OneClickButton from '../form/button';
import addNotification from '../notification';
import { changePasswordLoggedOut, changePassword } from '../../app/store/actions/users';

// eslint-disable-next-line react/prefer-stateless-function
class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: '',
      password: '',
      confirmNewPassword: '',
      criterionMinChar: false,
      criterionMinNumber: false,
      criterionMinUpperCase: false,
      criterionMinSpecialChar: false,
      hideConfirmNewPassword: true,
      hideNewPassword: true,
      hidePassword: true,
    };
  }

  passwordValidity = (password) => {
    const characterLengthRegx = /^.{6,}$/;
    const isNumberRegx = /[0-9]/;
    const specialCharacterRegx = /[!@#$%^&*()_+=-]/;
    const upperCaseCharacterRegx = /[A-Z]/;
    this.setState({
      criterionMinChar: characterLengthRegx.test(password),
      criterionMinNumber: isNumberRegx.test(password),
      criterionMinUpperCase: upperCaseCharacterRegx.test(password),
      criterionMinSpecialChar: specialCharacterRegx.test(password),
    });
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
    if (attr === 'newPassword') {
      this.passwordValidity(value);
    }
  };

  createInputButton = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    id = null,
    buttonLabel = '',
    buttonFunction = undefined,
    keypressFunction = undefined,
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={label} className="f-w-600 f-s-13">{label}</label>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          {buttonLabel && buttonFunction && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => buttonFunction()}
              style={{ minWidth: '41px' }}
            >
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  setNewPassword = async () => {
    const { expiredPassword = false } = this.props;
    const title = expiredPassword
      ? I18n.t('BEE2586' /* Senha Expirada */)
      : I18n.t('BEE915' /* Alterar senha */);
    if (!expiredPassword && !this.state.password) {
      addNotification('danger', title, I18n.t('BEE1256' /* Senha deve ser informada ! */), 'top-right');
    } else {
      try {
        if (expiredPassword) {
          await this.props.changePasswordLoggedOut(this.props.username, this.state.newPassword);
        } else {
          await this.props.changePassword(
            this.props.userLogged.login,
            this.props.userLogged.id,
            this.state.password,
            this.state.newPassword,
          );
        }
        addNotification('success', title, I18n.t('BEE2553' /* Senha alterada com sucesso */), 'top-right');
        this.props.toClose();
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && error.message) {
          const messageError = (error.details)
            ? `${error.code} - ${error.details} | ${error.message}`
            : error.message;
          addNotification('danger', title, messageError, 'top-right');
        } else {
          addNotification('danger', title, I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');
        }
      }
    }
  };

  render() {
    const { isOpen = false, expiredPassword = false } = this.props;
    const {
      newPassword, confirmNewPassword, criterionMinChar, criterionMinNumber, hidePassword, password,
      criterionMinUpperCase, criterionMinSpecialChar, hideConfirmNewPassword, hideNewPassword,
    } = this.state;
    return (
      <Modal size="md" isOpen={isOpen}>
        <ModalHeader data-testid="change-password-modal-header">
          {expiredPassword
            ? I18n.t('BEE2585' /* Sua senha expirou e precisa ser alterada. */)
            : I18n.t('BEE915' /* Alterar senha */)}
        </ModalHeader>
        <ModalBody>
          <div
            className="col-md-8"
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            {!expiredPassword && (
              this.createInputButton(
                password,
                'password',
                I18n.t('BEE2584' /* Senha antiga */),
                '',
                (hidePassword) ? 'password' : 'text',
                true,
                false,
                'password',
                <i className={`fa fa-eye${(hidePassword) ? '' : '-slash'}`} />,
                () => this.setState({ hidePassword: !hidePassword }),
              )
            )}
            {this.createInputButton(
              newPassword,
              'newPassword',
              I18n.t('BEE2583' /* Nova senha */),
              '',
              (hideNewPassword) ? 'password' : 'text',
              true,
              false,
              'newPassword',
              <i className={`fa fa-eye${(hideNewPassword) ? '' : '-slash'}`} />,
              () => this.setState({ hideNewPassword: !hideNewPassword }),
            )}
            {this.createInputButton(
              confirmNewPassword,
              'confirmNewPassword',
              I18n.t('BEE891' /* Confirme a Senha */),
              '',
              (hideConfirmNewPassword) ? 'password' : 'text',
              true,
              false,
              'confirmNewPassword',
              <i className={`fa fa-eye${(hideConfirmNewPassword) ? '' : '-slash'}`} />,
              () => this.setState({ hideConfirmNewPassword: !hideConfirmNewPassword }),
            )}
          </div>
          <div className="col-md-12">
            <p style={{ margin: 0, color: criterionMinChar ? 'green' : 'red' }}>
              {I18n.t('BEE2578' /* A senha deve conter no mínimo 6 caracteres */)}
            </p>
            <p style={{ margin: 0, color: criterionMinNumber ? 'green' : 'red' }}>
              {I18n.t('BEE2579' /* A senha deve conter dígitos (0-9) */)}
            </p>
            <p style={{ margin: 0, color: criterionMinUpperCase ? 'green' : 'red' }}>
              {I18n.t('BEE2580' /* Senha deve conter no mínimo uma letra maiúscula (A-Z) */)}
            </p>
            <p style={{ margin: 0, color: criterionMinSpecialChar ? 'green' : 'red' }}>
              {I18n.t('BEE2581' /* A senha deve conter no mínimo um caracter especial (!@#$%^&*()_+=-) */)}
            </p>
            <p style={{ margin: 0, color: (newPassword && newPassword === confirmNewPassword) ? 'green' : 'red' }}>
              {I18n.t('BEE2582' /* A senha deve ser igual */)}
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="row">
            <div className="col text-right">
              <OneClickButton
                className="btn btn-white btn-120 p-5 "
                onClick={this.props.toClose}
              >
                {I18n.t('BEE99' /* Cancelar */)}
              </OneClickButton>
            </div>
            <div className="col text-right">
              <OneClickButton
                className="btn btn-green btn-120 p-5"
                disabled={!criterionMinChar || !criterionMinNumber
                  || !criterionMinUpperCase || !criterionMinSpecialChar
                  || !(newPassword && newPassword === confirmNewPassword)}
                onClick={this.setNewPassword}
              >
                {I18n.t('BEE493' /* Salvar */)}
              </OneClickButton>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  changePasswordLoggedOut: (login, newPassword) => dispatch(changePasswordLoggedOut(login, newPassword)),
  changePassword: (login, userId, password, newPassword) => dispatch(
    changePassword(login, userId, password, newPassword),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChangePassword));
