/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from '../../../../components/form/button';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createPrinter, updatePrinter, getPrinter,
  getAllAgentsOptions,
} from '../../../../app/store/actions/printers';

import PrinterHelpers from '../../../../helpers/printer';
import Form from '../../../../components/form/form';

class PrinterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      location: '',
      type: 1,
      note: '',
      agentCode: null,
      agentList: [],
      disableCode: true,
      disableName: true,
      disableLocation: true,
      disableType: true,
      disableNote: true,
      disableAgentCode: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getPrinterSelected();
    } else {
      const agentList = await this.props.getAllAgentsOptions();

      this.setState({ agentList: agentList || [] });
    }
  }

  descTypes(type) {
    if (type === 1) {
      return `${I18n.t('BEE200' /* Tipo */)} 1`;
    } if (type === 2) {
      return `${I18n.t('BEE200' /* Tipo */)} 2`;
    }
  }

  getPrinterSelected = async () => {
    if (this.props.location.state.printer) {
      const { printer } = this.props.location.state;
      const printerSelected = await printer;

      if (printerSelected) {
        this.setState({
          printerSelected,
          printerId: printer.id,
          code: printerSelected.code,
          name: printerSelected.name,
          location: printerSelected.location,
          type: printerSelected.type,
          note: printerSelected.note,
          agentCode: printerSelected.agentCode,
          agentList: printerSelected.agentList || [],
        });
      }
    }
  };

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableLocation: false,
        disableType: false,
        disableNote: false,
        disableAgentCode: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableLocation: false,
        disableType: false,
        disableNote: false,
        disableAgentCode: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled, maxLength = 524288) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
        />
      </div>
    </div>
  );

  createSelect = (value, attr, label, placeholder = '', items, required = false, disabled = false) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <select
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        >
          {items.map((item) => (
            <option key={`${item.label}${item.value}`} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  createPrinterSubmit = async () => {
    const {
      code, name, location, type, note, agentCode,
    } = this.state;

    try {
      const newPrinter = await this.props.createPrinter({
        code,
        name,
        location,
        type,
        note,
        agentCode,
      });

      if (newPrinter) {
        await addNotification('success', I18n.t('BEE328' /* Impressora */), I18n.t('BEE327', { 0: name } /* Impressora %{0} incluída com sucesso! */), 'top-right');

        this.props.history.push(ROUTES.PRINTER_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE328' /* Impressora */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification('danger', I18n.t('BEE328' /* Impressora */), I18n.t('BEE327', { 0: code } /* Erro ao incluir a Impressora %{0}! */), 'top-right');
        }
      } else {
        await addNotification('danger', I18n.t('BEE328' /* Impressora */), I18n.t('BEE327', { 0: code } /* Erro ao incluir a Impressora %{0}! */), 'top-right');
      }
    }
  };

  updatePrinterSubmit = async () => {
    const {
      printerId, code, name, location, type, note,
      agentCode,
    } = this.state;

    try {
      const updPrinter = await this.props.updatePrinter({
        printerId,
        name,
        location,
        type,
        note,
        agentCode,
      });

      if (updPrinter) {
        await addNotification('success', I18n.t('BEE330' /* Atualizar Impressora */), I18n.t('BEE331', { 0: code } /* Atualização da Impressora %{0} efetuada com sucesso! */), 'top-right');

        this.props.history.push(ROUTES.PRINTER_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE330' /* Atualizar Impressora */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification('danger', I18n.t('BEE330' /* Atualizar Impressora */), I18n.t('BEE331', { 0: code } /* Erro ao atualizar a Impressora %{0}! */), 'top-right');
        }
      } else {
        await addNotification('danger', I18n.t('BEE330' /* Atualizar Impressora */), I18n.t('BEE331', { 0: code } /* Erro ao atualizar a Impressora %{0}! */), 'top-right');
      }
    }
  };

  render() {
    const {
      code, name, location, type, note, agentCode,
      agentList,
    } = this.state;

    const {
      mode, disableCode, disableName, disableLocation, disableType,
      disableNote, disableAgentCode, showCancel, showCreate, showUpdate,
    } = this.state;

    let title = '';
    // let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE333' /* Nova Impressora */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE334' /* Editar Impressora */);
      //   breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE335' /* Detalhes Impressora */);
      //   breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE1396' /* Equipamentos */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE21' /* Impressoras */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE333' /* Nova Impressora */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  <Form
                    noPanel
                    setValue={this.setValue}
                    // setValueDrop={this.setValueDrop}
                    inputs={(formContext) => ([
                      formContext.createInput(
                        code,
                        'code',
                        `${I18n.t('BEE82' /* Código */)}:`,
                        I18n.t('BEE3707' /* Informe a impressora */),
                        'text',
                        true,
                        disableCode,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        20,
                      ),
                      formContext.createInput(
                        name,
                        'name',
                        `${I18n.t('BEE83' /* Nome */)}:`,
                        I18n.t('BEE3722' /* Informe o nome da impressora */),
                        'text',
                        true,
                        disableName,
                      ),
                      disableType
                        ? formContext.createInput(
                          this.descTypes(type),
                          'type',
                          `${I18n.t('BEE200' /* Tipo */)}:`,
                          '',
                          'text',
                          false,
                          disableType,
                        )
                        : formContext.createSelect(
                          type,
                          'type',
                          `${I18n.t('BEE200' /* Tipo */)}:`,
                          PrinterHelpers.listPrinterType(),
                          disableType,
                          undefined,
                          undefined,
                          undefined,
                          true,
                        ),
                      formContext.createInput(
                        location,
                        'location',
                        `${I18n.t('BEE322' /* Localização */)}:`,
                        I18n.t('BEE3724' /* Informe o endereço */),
                        'text',
                        true,
                        disableLocation,
                      ),
                      formContext.createInput(
                        note,
                        'note',
                        `${I18n.t('BEE135' /* Observação */)}:`,
                        I18n.t('BEE136' /* Informe a observação */),
                        'text',
                        false,
                        disableNote,
                      ),
                      formContext.createSelect(
                        agentCode,
                        'agentCode',
                        `${I18n.t('BEE3464' /* Agente */)}:`,
                        agentList,
                        false,
                        disableAgentCode,
                      ),
                    ])}
                  />
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.PRINTER_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createPrinterSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}
                    </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updatePrinterSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}
                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getPrinter: (printerId) => dispatch(getPrinter(printerId)),
  createPrinter: (printer) => dispatch(createPrinter(printer)),
  updatePrinter: (printer) => dispatch(updatePrinter(printer)),
  getAllAgentsOptions: () => dispatch(getAllAgentsOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrinterForm));
