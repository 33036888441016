import { I18n } from 'react-redux-i18n';

function returnPickingOrderStatus(status) {

  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE470' /* Pendente */);
      case 2:
        return I18n.t('BEE1766' /* Finalizado */);
      default:
        return status;
    }

  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE470' /* Pendente */), },
      { value: 2, label: I18n.t('BEE1766' /* Finalizado */), },
    ]
  }
};

export default {
  returnPickingOrderStatus,
}