import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  Modal,
  ModalBody, ModalFooter,
  ModalHeader,
} from 'reactstrap';

import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import FormatHelpers from '../../../helpers/format';

class ModalChangeAllocation extends React.Component {
  constructor(props) {
    super(props);

    this.tableBalanceColumns = [{
      Header: I18n.t('BEE436' /* Selecionar */),
      accessor: 'selected',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      width: 100,
      Cell: (row) => {
        const { allocationSelected } = this.props;
        const { totalSelected } = this.state;
        const { checked } = this.state;
        const disabled = row.original.availableQuantity === 0
          || ((allocationSelected && totalSelected
            >= (parseFloat(allocationSelected.allocatedQuantity) - parseFloat(allocationSelected.pickedQuantity)))
            && !(row.original.id in checked && checked[row.original.id] === true));

        return (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={this.state.checked[row.original.id] || false}
              onChange={() => this.handleSingleCheckboxChange(row.original.id, row.original.availableQuantity)}
              disabled={disabled}
            />
          </div>
        );
      },
    }, {
      Header: I18n.t('BEE182' /* Depósito */),
      accessor: 'depositCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 90,
    }, {
      Header: I18n.t('BEE428' /* Lote */),
      accessor: 'lotNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE434' /* Data de Validade */),
      accessor: 'expirationDate',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 130,
      Cell: (row) => (
        <span>{row.value && moment(row.value).format('L')}</span>
      ),
    }, {
      Header: I18n.t('BEE267' /* Endereço */),
      accessor: 'addressCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE530' /* Disponível */),
      accessor: 'availableQuantity',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Cell: (row) => (
        <span>{parseFloat(row.value)}</span>
      ),
    }, {
      Header: I18n.t('BEE1431' /* Alocado */),
      accessor: 'allocated',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Cell: (row) => (
        <span>{parseFloat(row.value)}</span>
      ),
    }, {
      Header: I18n.t('BEE504' /* Total */),
      accessor: 'quantity',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Cell: (row) => (
        <span>{parseFloat(row.value)}</span>
      ),
    },
    ];

    this.state = {
      defaultPageSize: 3,
      pageSizeOptions: [3, 4, 5],
      showConfirmAllocation: false,
      balancesSelect: [],
      checked: [],
      totalSelected: 0,
    };
  }

  handleSingleCheckboxChange = (stockBalanceId, quantity) => {
    const { checked, totalSelected } = this.state;
    const { allocationSelected } = this.props;

    this.setState((prevState) => {
      const checkedCopy = { ...prevState.checked };
      const balancesSelectCopy = [...prevState.balancesSelect];
      checkedCopy[stockBalanceId] = !checked[stockBalanceId];

      let updatedTotalSelected;
      if (checkedCopy[stockBalanceId]) {
        if (totalSelected + parseFloat(quantity)
          > (parseFloat(allocationSelected.allocatedQuantity) - parseFloat(allocationSelected.pickedQuantity))) {
          updatedTotalSelected = totalSelected
            + (parseFloat(allocationSelected.allocatedQuantity)
              - parseFloat(allocationSelected.pickedQuantity)
              - parseFloat(totalSelected));
          balancesSelectCopy.push({
            stockBalanceId,
            quantity: parseFloat(allocationSelected.allocatedQuantity)
              - parseFloat(allocationSelected.pickedQuantity)
              - parseFloat(totalSelected),
          });
        } else {
          updatedTotalSelected = totalSelected + parseFloat(quantity);
          balancesSelectCopy.push({
            stockBalanceId,
            quantity: parseFloat(quantity),
          });
        }
      } else {
        const index = balancesSelectCopy.findIndex((element) => element.stockBalanceId === stockBalanceId);
        updatedTotalSelected = totalSelected - parseFloat(balancesSelectCopy[index].quantity);

        balancesSelectCopy.splice(index, 1);
      }

      return {
        checked: checkedCopy,
        totalSelected: updatedTotalSelected,
        balancesSelect: balancesSelectCopy,
      };
    });
  };

  changeProductAllocation = () => {
    const { balancesSelect, totalSelected } = this.state;
    const { allocationSelected } = this.props;

    if (!balancesSelect || balancesSelect.length === 0) {
      addNotification(
        'danger',
        I18n.t('BEE2009' /* Alocação */),
        I18n.t('BEE2010' /* Nenhum Saldo Estoque selecionado! */),
        'top-right',
      );
    } else if (parseFloat(totalSelected)
      !== (parseFloat(allocationSelected.allocatedQuantity) - parseFloat(allocationSelected.pickedQuantity))) {
      addNotification(
        'danger',
        I18n.t('BEE2009' /* Alocação */),
        I18n.t('BEE3301' /* Quantidade selecionada diferente da quantidade alocada! */),
        'top-right',
      );
    } else if (parseFloat(totalSelected)
      === (parseFloat(allocationSelected.allocatedQuantity) - parseFloat(allocationSelected.pickedQuantity))) {
      this.setState({
        showConfirmAllocation: true,
      });
    }
  };

  changeProductAllocationSubmit = async () => {
    const { balancesSelect } = this.state;
    const { allocationSelected } = this.props;

    this.setState({ showConfirmAllocation: false });

    try {
      const result = await this.props.changeAllocationProduct(
        allocationSelected.productAllocationId,
        balancesSelect,
      );

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2009' /* Alocação */),
          I18n.t('BEE2011' /* Erro ao tentar efetuar a troca de alocação! */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2009' /* Alocação */),
          I18n.t('BEE2012' /* Troca de Alocação efetuada com sucesso! */),
          'top-right',
        );

        this.closeModalChangeAllocation(true);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE2009' /* Alocação */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE2009' /* Alocação */),
            I18n.t('BEE2011' /* Erro ao tentar efetuar a troca de alocação! */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2009' /* Alocação */),
          I18n.t('BEE2011' /* Erro ao tentar efetuar a troca de alocação! */),
          'top-right',
        );
      }
    }
  };

  closeModalChangeAllocation = (update = false) => {
    this.setState({
      showConfirmAllocation: false,
      checked: [],
      totalSelected: 0,
      balancesSelect: [],
    });
    this.props.closeModalChangeAllocation(update);
  };

  render() {
    const {
      defaultPageSize, pageSizeOptions, showConfirmAllocation, totalSelected,
    } = this.state;
    const {
      openChangeModal = false, balances = [], allocationSelected,
    } = this.props;

    return (
      <Modal size="lg" isOpen={openChangeModal} toggle={() => this.closeModalChangeAllocation()}>
        <ModalHeader
          toggle={() => this.closeModalChangeAllocation()}
        >
          <div style={{ display: 'flex' }}>
            <p className="col-md-12">
              <strong>
                {allocationSelected && `${allocationSelected.productCode} - ${allocationSelected.productName}`}
              </strong>
            </p>
          </div>
          <div style={{ display: 'flex' }}>
            <p className="col-md-12">
              <strong>
                {`${I18n.t('BEE2008' /* Quantidade Alocada */)}: `}
              </strong>
              {
                FormatHelpers.formatNumber(allocationSelected
                  && (parseFloat(allocationSelected.allocatedQuantity) || 0), 3, false)
              }
            </p>
          </div>
          <div style={{ display: 'flex' }}>
            <p className="col-md-12">
              <strong>
                {`${I18n.t('BEE1134' /* Quantidade Separada */)}: `}
              </strong>
              {
                FormatHelpers.formatNumber(allocationSelected
                  && (parseFloat(allocationSelected.pickedQuantity) || 0), 3, false)
              }
            </p>
          </div>
          <div style={{ display: 'flex' }}>
            <div>
              <p className="col-md-12">
                <strong>
                  {`${I18n.t('BEE428' /* Lote */)} : `}
                </strong>
                {allocationSelected && allocationSelected.lotNumber}
              </p>
            </div>
            <div>
              <p className="col-md-12">
                <strong>
                  {`${I18n.t('BEE434' /* Data de Validade */)} : `}
                </strong>
                {allocationSelected && allocationSelected.expirationDate
                  && moment(allocationSelected.expirationDate).format('L')}
              </p>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div>
              <p className="col-md-12">
                <strong>
                  {`${I18n.t('BEE182' /* Depósito */)} : `}
                </strong>
                {allocationSelected && allocationSelected.depositCode}
              </p>
            </div>
            <div>
              <p className="col-md-12">
                <strong>
                  {`${I18n.t('BEE267' /* Endereço */)} : `}
                </strong>
                {allocationSelected && allocationSelected.addressCode}
              </p>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Table
            panelHeaderProps={{
              noButton: true,
              children: I18n.t('BEE489' /* Saldo Estoque */).toUpperCase(),
            }}
            filterable
            data={balances}
            columns={this.tableBalanceColumns}
            expander
            pageSizeOptions={pageSizeOptions}
            defaultPageSize={defaultPageSize}
            defaultSorted={[{ id: 'lotNumber', desc: false }]}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
          />
          <div>
            <div className="row">
              <label htmlFor="totalSelected" className="col-sm-3 col-form-label f-s-16 f-w-700 m-l-5">
                {I18n.t('BEE3300' /* Total Selecionado */)}
                :
                {' '}
              </label>
              <div className="col-sm-3">
                <input
                  id="totalSelected"
                  className="form-control text-center f-s-14"
                  type="text"
                  value={totalSelected}
                  readOnly
                />
              </div>
            </div>
          </div>
          {(showConfirmAllocation
            && (
              <SweetAlert
                warning
                showCancel
                cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
                confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
                confirmBtnBsStyle="warning"
                cancelBtnBsStyle="default"
                title={I18n.t('BEE101' /* Você tem certeza? */)}
                onConfirm={() => this.changeProductAllocationSubmit()}
                onCancel={() => this.setState({
                  showConfirmAllocation: false,
                })}
              >
                {I18n.t('BEE2013' /* Será efetuada a troca das alocações selecionadas! */)}
              </SweetAlert>
            ))}

        </ModalBody>
        <ModalFooter>
          <OneClickButton
            type="button"
            className="btn btn-120 btn-primary p-5 m-5"
            onClick={() => this.changeProductAllocation()}
            disabled={allocationSelected
              && parseFloat(totalSelected)
              !== (parseFloat(allocationSelected.allocatedQuantity) - parseFloat(allocationSelected.pickedQuantity))}
          >
            {I18n.t('BEE1433' /* Alterar Alocações */)}
          </OneClickButton>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalChangeAllocation));
