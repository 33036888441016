import OutboundOrderRequests from '../../server/outboundOrders';
import { addLoading, removeLoading } from './loading';

export const setOutboundOrders = (outboundOrders) => ({
  type: 'SET_OUTBOUND_ORDERS',
  outboundOrders,
});

export const setAverageTimeCards = (averageTimeCards) => ({
  type: 'SET_AVERAGE_TIME_CARDS',
  averageTimeCards,
});

export const setOutboundOrdersJustDocument = (outboundOrders) => ({
  type: 'SET_OUTBOUND_ORDERS_JUST_DOCUMENT',
  outboundOrders,
});

export const setOutboundOrdersProducts = (outboundOrderProducts) => ({
  type: 'SET_OUTBOUND_ORDERS_PRODUCTS',
  outboundOrderProducts,
});

export const setOutboundOrderDetails = (outboundOrdersDetails) => ({
  type: 'SET_OUTBOUND_ORDERS_DETAILS',
  outboundOrdersDetails,
});

export const setDates = (dates) => ({
  type: 'SET_OUTBOUND_ORDERS_DATES',
  dates,
});

export const setChartsPanel = (chartsPanel) => ({
  type: 'SET_OUTBOUND_ORDERS_CHARTS',
  chartsPanel,
});

export const setAverageTimeCharts = (charts) => ({
  type: 'SET_OUTBOUND_AVERAGE_TIME_CHARTS',
  charts,
});

export const setUserBranches = (userBranches) => ({
  type: 'SET_OUTBOUND_ORDERS_USER_BRANCHES',
  userBranches,
});

export const setCurrentRange = (currentRange) => ({
  type: 'SET_OUTBOUND_ORDERS_CURRENT_RANGE',
  currentRange,
});

export const setSelectedBranches = (selectedBranches) => ({
  type: 'SET_OUTBOUND_ORDERS_SELECTED_BRANCHES',
  selectedBranches,
});

export const setTotalBilled = (totalOutboundOrderBilled) => ({
  type: 'SET_OUTBOUND_ORDERS_TOTAL_BILLED',
  totalOutboundOrderBilled,
});

export const setTotalNotBilled = (totalOutboundOrderNotBilled) => ({
  type: 'SET_OUTBOUND_ORDERS_TOTAL_NOT_BILLED',
  totalOutboundOrderNotBilled,
});

export const setTotalProcess = (totalOutboundOrderProcess) => ({
  type: 'SET_OUTBOUND_ORDERS_TOTAL_PROCESS',
  totalOutboundOrderProcess,
});

export const setTotalByStatus = (totalByStatusOutboundOrdersProducts) => ({
  type: 'SET_OUTBOUND_ORDERS_TOTAL_BY_STATUS',
  totalByStatusOutboundOrdersProducts,
});

export const getOutboundOrdersDashboardInfo = (
  startDate,
  endDate,
  getCharts,
  selectedBranches,
  currentRange,
  selectedOrderTypes,
  selectedCarriers,
  typeDashboard,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrdersDashboardInfo = await OutboundOrderRequests.getOutboundOrdersDashboardInfo(
      startDate,
      endDate,
      getCharts,
      selectedBranches,
      currentRange,
      selectedOrderTypes,
      selectedCarriers,
      typeDashboard,
    );
    dispatch(setOutboundOrders(outboundOrdersDashboardInfo.outboundOrders));
    dispatch(setUserBranches(outboundOrdersDashboardInfo.userBranches));
    dispatch(setChartsPanel(outboundOrdersDashboardInfo.charts));
  } finally {
    dispatch(removeLoading());
  }
};

export const getAverageTimeDashboardInfo = (
  startDate,
  endDate,
  selectedBranches,
  orderTypesToSearch,
  carriersToSearch,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const averageTimeDashboardInfo = await OutboundOrderRequests.getAverageTimeDashboardInfo(
      startDate,
      endDate,
      selectedBranches,
      orderTypesToSearch,
      carriersToSearch,
    );
    dispatch(setAverageTimeCards(averageTimeDashboardInfo.averageTimeCards));
    dispatch(setOutboundOrders(averageTimeDashboardInfo.updatedOutboundOrdersInfo));
    dispatch(setUserBranches(averageTimeDashboardInfo.userBranches));
    dispatch(setAverageTimeCharts(averageTimeDashboardInfo.charts));
  } finally {
    dispatch(removeLoading());
  }
};

export const setDashboardCurrentRange = (currentRange) => async (dispatch) => {
  dispatch(setCurrentRange(currentRange));
};

export const setSelectedUserBranches = (selectedBranches) => async (dispatch) => {
  dispatch(setSelectedBranches(selectedBranches));
};

export const setDashboardOutboundOrdersDates = (dates) => async (dispatch) => {
  dispatch(setDates(dates));
};

export const getOutboundOrdersList = (startDate, endDate, getCharts) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrdersList = await OutboundOrderRequests.getOutboundOrdersList(startDate, endDate, getCharts);
    if (getCharts) {
      dispatch(setOutboundOrders(outboundOrdersList.outboundOrders));
      dispatch(setChartsPanel(outboundOrdersList.charts));
    } else {
      dispatch(setOutboundOrders(outboundOrdersList));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrder = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrder = await OutboundOrderRequests.getOutboundOrder(id);
    return outboundOrder;
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrderDetails = (dockControlId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrder = await OutboundOrderRequests.getOutboundOrderDetails(dockControlId);
    dispatch(setOutboundOrderDetails(outboundOrder));
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrderByDate = (
  filterStartDate,
  filterEndDate,
  typeOfSearch = 1,
  restrictionPicking = false,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderRequests.getOutboundOrderByDate(
      filterStartDate,
      filterEndDate,
      typeOfSearch,
      restrictionPicking,
    );
  } finally {
    dispatch(removeLoading());
  }
};

export const getBaptismLabelProducts = (baptismLabel) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderRequests.getBaptismLabelProducts(baptismLabel);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateOutboundRange = (outboundRangeId, updateDataOutboundRange) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderRequests.updateOutboundRange(outboundRangeId, updateDataOutboundRange);
  } finally {
    dispatch(removeLoading());
  }
};

export const confirmDivergence = (productRangeId, type, quantity) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderRequests.confirmDivergence(productRangeId, type, quantity);
  } finally {
    dispatch(removeLoading());
  }
};

export const restrictionRelease = (checkedOutboundOrderIds) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderRequests.restrictionRelease(checkedOutboundOrderIds);
  } finally {
    dispatch(removeLoading());
  }
};

export const pickingRestrictionRelease = (checkedOutboundOrderIds) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderRequests.pickingRestrictionRelease(checkedOutboundOrderIds);
  } finally {
    dispatch(removeLoading());
  }
};

export const cancelOutboundOrder = (outboundOrderId) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const result = await OutboundOrderRequests.cancelOutboundOrder(outboundOrderId);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const deallocateOutboundOrder = (outboundOrderId) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const result = await OutboundOrderRequests.deallocateOutboundOrder(outboundOrderId);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const cancelOutboundProduct = (outboundOrderId, outboundProductId) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const result = await OutboundOrderRequests.cancelOutboundProduct(outboundOrderId, outboundProductId);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const deallocateOutboundProduct = (outboundOrderId, outboundProductId) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const result = await OutboundOrderRequests.deallocateOutboundProduct(outboundOrderId, outboundProductId);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const changeUserPickRange = (outboundRangeId, userPickId, pickingType) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const result = await OutboundOrderRequests.changeUserPickRange(outboundRangeId, userPickId, pickingType);
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrderListJustDocument = (startDate, endDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrders = await OutboundOrderRequests.getOutboundOrderListJustDocument(startDate, endDate);
    dispatch(setOutboundOrdersJustDocument(outboundOrders));
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrderProducts = (outboundOrderId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrders = await OutboundOrderRequests.getOutboundOrderProducts(outboundOrderId);
    dispatch(setOutboundOrdersProducts(outboundOrders));
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrdersDashboardTotal = (
  startDate,
  endDate,
  selectedBranches,
  orderTypesToSearch,
  carriersToSearch,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrdersDashboardTotal = await OutboundOrderRequests.getOutboundOrdersDashboardTotal(
      startDate,
      endDate,
      selectedBranches,
      orderTypesToSearch,
      carriersToSearch,
    );

    dispatch(setOutboundOrders(outboundOrdersDashboardTotal.outBoundOrders));
    dispatch(setUserBranches(outboundOrdersDashboardTotal.userBranches));
    dispatch(setTotalProcess(outboundOrdersDashboardTotal.totalOutboundOrderProcess));
    dispatch(setTotalBilled(outboundOrdersDashboardTotal.totalOutboundOrderBilled));
    dispatch(setTotalNotBilled(outboundOrdersDashboardTotal.totalOutboundOrderNotBilled));
    dispatch(setTotalByStatus(outboundOrdersDashboardTotal.totalByStatusOutboundOrdersProducts));
  } finally {
    dispatch(removeLoading());
  }
};

export const getPendingConfirmDelivery = (orderNumber = '', filterStartDate, filterEndDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrderRequests.getPendingConfirmDelivery(orderNumber, filterStartDate, filterEndDate);
  } finally {
    dispatch(removeLoading());
  }
};

export const confirmDelivery = (
  outboundOrderId,
  userDocument,
  withdrawnBy,
  note,
) => async (dispatch) => {
  dispatch({ type: 'modifica_visible_loadingspin', payload: true });
  try {
    return await OutboundOrderRequests.confirmDelivery(
      outboundOrderId,
      userDocument,
      withdrawnBy,
      note,
    );
  } finally {
    dispatch(removeLoading());
  }
};
