import 'react-table/react-table.css';
import React from 'react';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import InboundOrderHelpers from '../../../helpers/inboundOrders';
import Table from '../../../components/table/Table';
import UserConfirmationModal from '../../../components/pages/userConfirmationModal';
import addNotification from '../../../components/notification';

import ROUTES from '../../../config/routes';
import Permissions from '../../../app/services/permissions';

export default class OrderTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableOrderColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.modalNoteOpening(rows.row)}
                >
                  {I18n.t('BEE135' /* Observação */)}
                </DropdownItem>
                {(!rows.row._original.checkedOrder && rows.original.status <= 5
                  && (
                    <DropdownItem onClick={() => this.showAssignCheckUser(rows.row)}>
                      {
                        rows.row._original.checkOrderUser
                          ? I18n.t(
                            'BEE804',
                            { 0: rows.row._original.checkOrderUserName }, /* Trocar Conferente (Atual: %{0}) */
                          )
                          : I18n.t('BEE803' /* Atribuir Conferente */)
                      }
                    </DropdownItem>
                  )
                )}
                {(rows.original.preConference && rows.original.status <= 4) && (
                  <DropdownItem
                    onClick={() => this.showPrinterDialog(rows.row)}
                  >
                    {I18n.t('BEE1793' /* Etiquetas Pré-Conferência */)}
                  </DropdownItem>
                )}
                <DropdownItem divider />
                {(!Permissions.checkPermission(ROUTES.P_UNDO_INBOUND_CONFERENCE)
                  && (rows.original.status === 4 || rows.original.status === 5)
                  && (
                    <DropdownItem
                      onClick={() => this.undoInboundCheck(rows)}
                    >
                      {I18n.t('BEE565' /* Desfazer conferência física? */)}
                    </DropdownItem>
                  )
                )}
                {(!Permissions.checkPermission(ROUTES.P_CANCEL_INBOUND_ORDER)
                  && (rows.original.status <= 3)
                  && (
                    <DropdownItem
                      onClick={() => this.cancelInboundOrder(rows.row)}
                    >
                      {I18n.t('BEE2943' /* Cancelar Documento */)}
                    </DropdownItem>
                  )
                )}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE342' /* Fornecedor */),
        accessor: 'supplierBusinessName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE403' /* Série */),
        accessor: 'serie',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
        Cell: (row) => (
          <div>
            <span>{InboundOrderHelpers.inboundOrderStatus(row.value)}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option value={1}>{InboundOrderHelpers.inboundOrderStatus(1)}</option>
            {/* <option value={2}  >{InboundOrderHelpers.inboundOrderStatus(2)}</option> */}
            <option value={3}>{InboundOrderHelpers.inboundOrderStatus(3)}</option>
            <option value={4}>{InboundOrderHelpers.inboundOrderStatus(4)}</option>
            <option value={5}>{InboundOrderHelpers.inboundOrderStatus(5)}</option>
            <option value={6}>{InboundOrderHelpers.inboundOrderStatus(6)}</option>
            <option value={7}>{InboundOrderHelpers.inboundOrderStatus(7)}</option>
            {/* <option value={8}  >{InboundOrderHelpers.inboundOrderStatus(8)}</option> */}
            <option value={9}>{InboundOrderHelpers.inboundOrderStatus(9)}</option>
          </select>
        ),
      },
      {
        Header: I18n.t('BEE1477' /* Impressão Etiqueta */),
        accessor: 'preConferenceLabelPrinting',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
        Cell: (row) => (
          <div>
            <span>{(row.value) ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option value>{I18n.t('BEE172' /* Sim */)}</option>
            <option value={false}>{I18n.t('BEE173' /* Não */)}</option>
          </select>
        ),
      }, {
        Header: I18n.t('BEE1970' /* Data de Impressão */),
        accessor: 'preConfLabelPrintAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE2224' /* Data Integração */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {moment(row.value).format('L LTS')}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE405' /* Volumes */),
        accessor: 'expectedVolumes',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div>
            <span>{parseInt(row.value || 0, 10)}</span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE400' /* Placa do Veículo */),
        accessor: 'licensePlate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE399' /* Conferência de Placa */),
        accessor: 'checkedPlate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
        Cell: (row) => (
          <div>
            <span>
              {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
            </span>
          </div>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes(I18n.t('BEE172' /* Sim */), input)) {
            return id === true;
          } if (_.includes(I18n.t('BEE173' /* Não */), input)) {
            return id === false;
          }
        },
      }, {
        Header: I18n.t('BEE445' /* Conferido */),
        accessor: 'checkedOrder',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div>
            <span>
              {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
            </span>
          </div>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes(I18n.t('BEE172' /* Sim */), input)) {
            return id === true;
          } if (_.includes(I18n.t('BEE173' /* Não */), input)) {
            return id === false;
          }
        },
      }, {
        Header: I18n.t('BEE427' /* Armazenado */),
        accessor: 'stored',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div>
            <span>
              {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
            </span>
          </div>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes(I18n.t('BEE172' /* Sim */), input)) {
            return id === true;
          } if (_.includes(I18n.t('BEE173' /* Não */), input)) {
            return id === false;
          }
        },
      }, {
        Header: I18n.t('BEE64' /* Cancelado */),
        accessor: 'canceled',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div>
            <span>
              {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
            </span>
          </div>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes(I18n.t('BEE172' /* Sim */), input)) {
            return id === true;
          } if (_.includes(I18n.t('BEE173' /* Não */), input)) {
            return id === false;
          }
        },
      }, {
        Header: I18n.t('BEE176' /* Divergência */),
        accessor: 'divergence',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div>
            <span>
              {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
            </span>
          </div>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes(I18n.t('BEE172' /* Sim */), input)) {
            return id === true;
          } if (_.includes(I18n.t('BEE173' /* Não */), input)) {
            return id === false;
          }
        },
      }, {
        Header: I18n.t('BEE805' /* Conferente */),
        accessor: 'checkOrderUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE446' /* Usuário Conferência Placa */),
        accessor: 'checkPlateUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE447' /* Data Conferência Placa */),
        accessor: 'checkPlateAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE448' /* Usuário Início Conferência */),
        accessor: 'startCheckOrderUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE449' /* Data Início Conferência */),
        accessor: 'startCheckOrderAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE450' /* Usuário Fim Conferência */),
        accessor: 'finishCheckOrderUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE451' /* Data Fim Conferência */),
        accessor: 'finishCheckOrderAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE452' /* Usuário Início Armazenagem */),
        accessor: 'startStorageOrderUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 210,
      }, {
        Header: I18n.t('BEE453' /* Data Início Armazenagem */),
        accessor: 'startStorageOrderAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE454' /* Usuário Fim Armazenagem */),
        accessor: 'finishStorageOrderUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE455' /* Data Fim Armazenagem */),
        accessor: 'finishStorageOrderAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE458' /* Usuário Cancela */),
        accessor: 'cancelOrderUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE459' /* Data Cancelada */),
        accessor: 'cancelOrderAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE460' /* Usuário Divergência */),
        accessor: 'divergenceOrderUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE461' /* Data Divergência */),
        accessor: 'divergenceOrderAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      },
    ];

    this.userTableColumns = [
      {
        Header: I18n.t('BEE623' /* Login */),
        accessor: 'login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      },
    ];

    this.state = {
      showCancelOrderModal: false,
      showUndoCheckModal: false,
      showPartialUndoAlert: false,
      showCheckUserModal: false,
      cancelNote: '',
      checkUserOptions: [],
      selectedInboundOrderId: null,
      openModal: false,
      typeConfirmation: '',
      showPrinterDialog: false,
      printerOptions: [],
      printerSelected: null,
      showWarningDialog: false,
      showModalNote: false,
      noteIndex: '',
      note: '',
    };
  }

  async componentDidMount() {
    const printerReq = await this.props.getUserPrinterOptions();
    const { printerOptions, userPrinter } = printerReq;

    this.setState({
      printerOptions,
      printerSelected: userPrinter || '',
    });
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  createSelect = (value, attr, label, items) => (
    <div className="form-group p-2">
      <p>{label}</p>
      <select
        className="form-control"
        value={value || ''}
        onChange={(e) => this.setValue(attr, e.target.value)}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 mt-4">
      <p>{label}</p>
      <div className="col">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  showPrinterDialog = async (preConferenceLabel) => {
    const { printerOptions } = this.state;
    if (!printerOptions || printerOptions.length === 0) {
      addNotification(
        'danger',
        I18n.t('BEE1793' /* Etiquetas Pré-Conferência */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
    } else if (printerOptions && printerOptions.length > 1) {
      this.setState({
        showPrinterDialog: true,
        preConferenceLabel,
      });
    } else if (printerOptions && printerOptions.length === 1) {
      await this.setState({
        printerSelected: printerOptions[0].value,
        preConferenceLabel,
      });
      this.printLabel();
    }
  };

  printLabel = async (verification = true) => {
    const { preConferenceLabel, printerSelected, printerOptions } = this.state;
    if (preConferenceLabel._original.preConferenceLabelPrinting && verification) {
      this.setState({
        showPrinterDialog: false,
        showWarningDialog: true,
      });
    } else {
      const preConferenceLabelId = preConferenceLabel._original.id;
      try {
        await this.props.printLabel(preConferenceLabelId, printerSelected || printerOptions[0].value);
        addNotification(
          'success',
          I18n.t('BEE1793' /* Etiquetas Pré-Conferência */),
          I18n.t('BEE1427' /* Etiquetas enviadas para impressora ! */),
          'top-right',
        );
      } catch (error) {
        addNotification(
          'danger',
          I18n.t('BEE2981' /* Erro na consulta */),
          error.response.data.error.message,
          'top-right',
        );
      }

      this.setState({
        showPrinterDialog: false,
        showWarningDialog: false,
      });
      await this.props.getInboundOrders();
    }
  };

  cancelInboundOrder = (row) => {
    const inboundOrderId = row._original.id;

    this.setState({
      showCancelOrderModal: true,
      selectedInboundOrderId: inboundOrderId,
    });
  };

  undoInboundCheck = async (row) => {
    const orderStatus = row.original.status;
    if (orderStatus > 5) {
      addNotification(
        'danger',
        I18n.t('BEE440' /* Monitor de Recebimento */),
        I18n.t('BEE1058' /* Este pedido já teve a armazenagem iniciada */),
        'top-right',
      );
      return;
    }

    const inboundOrderId = row.original.id;
    const product = await this.props.getListInboundOrderProducts(inboundOrderId);
    const products = product && product.length ? product : [];

    const storedProducts = products.filter((p) => p.status > 2);
    const isPartialUndo = !!storedProducts.length;
    const undoableChecks = products.length - storedProducts.length;

    if (!undoableChecks) {
      addNotification(
        'danger',
        I18n.t('BEE440' /* Monitor de Recebimento */),
        I18n.t('BEE1059' /* Todos produtos deste documento já tiveram a armazenagem iniciada ou concluída */),
        'top-right',
      );
      return;
    }
    this.setState({
      showUndoCheckModal: true,
      showPartialUndoAlert: isPartialUndo,
      selectedInboundOrderId: inboundOrderId,
    });
  };

  confirmUndoInboundCheck = () => {
    this.setState({
      showUndoCheckModal: false,
      showPartialUndoAlert: false,
      openModal: true,
      typeConfirmation: 'undoCheck',
    });
  };

  confirmCancelInboundOrder = () => {
    this.setState({
      showCancelOrderModal: false,
      openModal: true,
      typeConfirmation: 'cancelOrder',
    });
  };

  userConfirmation = async (confirm) => {
    const { selectedInboundOrderId, typeConfirmation, cancelNote } = this.state;

    if (confirm) {
      this.setState({
        openModal: false,
      });

      if (typeConfirmation === 'undoCheck') await this.props.undoInboundOrderCheck(selectedInboundOrderId);
      if (typeConfirmation === 'cancelOrder') await this.props.cancelOrder(selectedInboundOrderId, cancelNote);

      this.setState({
        typeConfirmation: '',
        selectedInboundOrderId: null,
        cancelNote: '',
      });
    } else {
      this.setState({
        openModal: false,
        cancelNote: '',
        typeConfirmation: '',
        selectedInboundOrderId: null,
      });
    }
  };

  cancelUndoInboundCheck = () => {
    this.setState({
      showUndoCheckModal: false,
      selectedInboundOrderId: null,
    });
  };

  showAssignCheckUser = async (row) => {
    const { branchCode } = row._original;

    const brancUsers = await this.props.getBranchUsers(branchCode);
    const checkUserOptions = brancUsers.rows;

    this.setState({
      selectedInboundOrderId: row._original.id,
      showCheckUserModal: true,
      checkUserOptions,
    });
  };

  closeAssignCheckUser = () => {
    this.setState({
      showCheckUserModal: false,
      selectedInboundOrderId: null,
      checkUserOptions: [],
    });
  };

  assignCheckUser = async (user) => {
    const { selectedInboundOrderId } = this.state;
    const userId = user.id;
    await this.props.assignCheckUser(selectedInboundOrderId, userId);
    this.closeAssignCheckUser();
  };

  modalNoteOpening = (row) => {
    this.setState({
      showModalNote: true,
      noteIndex: row._index,
      note: this.props.inboundOrdersPanel[row._index].note,
    });
  };

  modalNoteClosing = () => {
    this.setState({
      showModalNote: false,
      noteIndex: '',
      note: '',
    });
  };

  updateNoteField = async () => {
    const { note, noteIndex } = this.state;
    const { inboundOrdersPanel } = this.props;

    if (note && note === inboundOrdersPanel[noteIndex].note) {
      this.modalNoteClosing();
    } else {
      try {
        const result = await this.props.updateNote(inboundOrdersPanel[noteIndex].id, note);
        if (result && !result.length) {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            I18n.t('BEE1788' /* erro ao atualizar a observação */),
            'top-right',
          );
          this.modalNoteClosing();
        } else {
          addNotification(
            'success',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            I18n.t('BEE1789' /* Atualizado com sucesso! */),
            'top-right',
          );
          this.modalNoteClosing();
          await this.props.getInboundOrders();
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;

          if (error.details || error.message) {
            addNotification(
              'danger',
              I18n.t('BEE440' /* Monitor de Recebimento */),
              `${error.code} - ${error.details || error.message}`,
              'top-right',
            );
          } else {
            addNotification(
              'danger',
              I18n.t('BEE440' /* Monitor de Recebimento */),
              I18n.t('BEE1788' /* erro ao atualizar a observação */),
              'top-right',
            );
          }
        } else {
          addNotification(
            'danger',
            I18n.t('BEE440' /* Monitor de Recebimento */),
            I18n.t('BEE1788' /* erro ao atualizar a observação */),
            'top-right',
          );
        }
        this.modalNoteClosing();
      }
    }
  };

  render() {
    const { inboundOrdersPanel = [] } = this.props;
    const {
      startDate, endDate, currentRange, inboundOrderSelect,
    } = this.props;
    const {
      showUndoCheckModal, showPartialUndoAlert, showCheckUserModal, checkUserOptions,
      showPrinterDialog, printerSelected, printerOptions, showCancelOrderModal, cancelNote, showWarningDialog,
    } = this.state;
    return (
      <span>
        <Table
          downloadCSV
          panelHeaderProps={{
            onClickReload: this.props.getInboundOrders,
            children: I18n.t('BEE462' /* Documentos */).toUpperCase(),
          }}
          datePicker={{
            startDate,
            endDate,
            currentRange,
            handleDateApplyEvent: this.props.handleDateApplyEvent,
          }}
          filterable
          data={inboundOrdersPanel}
          columns={this.tableOrderColumns}
          expander
          defaultPageSize={5}
          pageSizeOptions={[5, 10]}
          defaultSorted={[{ id: 'orderNumber', desc: false }]}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: () => {
                  this.props.setInboundOrderSelect(rowInfo.original);
                },
                style: {
                  background: inboundOrderSelect && rowInfo.original.id === inboundOrderSelect.id ? 'silver' : 'white',
                  cursor: 'pointer',
                  color: rowInfo.original.preConference && !rowInfo.original.preConferenceLabelPrinting
                    ? 'red' : 'black',
                },
              };
            }
            return {};
          }}
        />
        {(this.state.showModalNote
          && (
            <SweetAlert
              showCancel
              confirmBtnText={I18n.t('BEE493' /* Salvar */)}
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="danger"
              title={I18n.t('BEE135' /* Observação */)}
              onConfirm={() => this.updateNoteField()}
              onCancel={() => this.modalNoteClosing()}
              btnSize="sm"
            >
              <div>
                {this.createTextArea(this.state.note, 'note', I18n.t('BEE135' /* Observação */), '', 3, true, false)}
              </div>
            </SweetAlert>
          ))}

        {(showCancelOrderModal
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99')}
              confirmBtnText={I18n.t('BEE100')}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101')}
              onConfirm={() => this.confirmCancelInboundOrder()}
              onCancel={() => this.setState({ showCancelOrderModal: false, cancelNote: '' })}
            >
              <div className="my-3">
                <span>Documento será cancelado!</span>
                {this.createTextArea(
                  cancelNote,
                  'cancelNote',
                  `${I18n.t('BEE724' /* Motivo */)}:`,
                  'Informe o motivo do cancelamento',
                  3,
                  true,
                  false,
                )}
              </div>
            </SweetAlert>
          )
        )}
        {(showUndoCheckModal
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99')}
              confirmBtnText={I18n.t('BEE100')}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.confirmUndoInboundCheck()}
              onCancel={() => this.cancelUndoInboundCheck()}
            >
              {showPartialUndoAlert
                && (
                  <div className="my-3">
                    <strong>
                      {I18n.t('BEE1061' /* Atenção */)}
                      !
                    </strong>
                    {I18n.t(
                      'BEE1060',
                      /* Alguns produtos já tiveram o armazenamento iniciado e não poderão ter a conferência cancelada. */
                    )}

                  </div>
                )}
            </SweetAlert>
          )
        )}
        {(showPrinterDialog
          && (
            <SweetAlert
              confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1324' /* Selecionar Impressora */)}
              onConfirm={() => this.printLabel()}
              onCancel={() => this.setState({ showPrinterDialog: false })}
            >
              <div className="mt-4">
                {this.createSelect(printerSelected, 'printerSelected', I18n.t('BEE328' /* Impressora */), printerOptions)}
              </div>
            </SweetAlert>
          )
        )}
        {(showWarningDialog
          && (
            <SweetAlert
              warning
              showCancel
              confirmBtnText={I18n.t('BEE1800' /* prosseguir */)}
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1498' /* Aviso */)}
              onConfirm={() => { this.printLabel(false); }}
              onCancel={() => this.setState({ showWarningDialog: false })}
            >
              {I18n.t('BEE1801' /* Esta etiqueta já foi impressa, você deseja prosseguir com a impressão? */)}
            </SweetAlert>
          )
        )}
        <UserConfirmationModal
          openModal={this.state.openModal}
          confirm={(confirm) => this.userConfirmation(confirm)}
        />
        <Modal size="lg" isOpen={showCheckUserModal} toggle={() => this.closeAssignCheckUser()}>
          <ModalHeader toggle={() => this.closeAssignCheckUser()}>
            {I18n.t('BEE803' /* Atribuir Conferente */)}
          </ModalHeader>
          <ModalBody>
            <div className="p-b-15">
              <Table
                panelHeaderProps={{ noButton: true }}
                filterable
                data={checkUserOptions}
                columns={this.userTableColumns}
                expander
                defaultPageSize={10}
                pageSizeOptions={[5, 10]}
                defaultSorted={[{ id: 'login', desc: false }]}
                defaultFilterMethod={(filter, row) => {
                  const input = _.lowerCase(filter.value);
                  const value = _.lowerCase(row[filter.id]);
                  if (_.includes(value, input)) {
                    return true;
                  }
                }}
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: async () => {
                        await this.assignCheckUser(rowInfo.original);
                      },
                      style: {
                        cursor: 'pointer',
                      },
                    };
                  }
                  return {};
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      </span>
    );
  }
}
