import ManualRequest from '../../server/manualRequest';
import { addLoading, removeLoading } from './loading';

export const getManualRequestOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ManualRequest.getManualRequestOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const getManualRequestOptionsProducts = (productFilter, loading = true) => async (dispatch) => {
  if (loading) dispatch(addLoading());
  try {
    return await ManualRequest.getManualRequestOptionsProducts(productFilter);
  } finally {
    if (loading) dispatch(removeLoading());
  }
};

export const createManualRequest = (newManualRequest) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const manualRequest = await ManualRequest.createManualRequest(newManualRequest);
    return manualRequest;
  } finally {
    dispatch(removeLoading());
  }
};
