import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import moment from 'moment';

import Table from '../../../components/table/Table';

import FormatHelpers from '../../../helpers/format';

class SelectedStockBalance extends React.Component {
  constructor(props) {
    super(props);

    this.tableColumns = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
      }, {
        Header: I18n.t('BEE432' /* Endereço */),
        accessor: 'addressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE515' /* Saldo */),
        accessor: 'balance',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: row => {
          return FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false);
        },
        filterable: false,
      }, {
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: row => {
          return FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false);
        },
        filterable: false,
      }, {
        Header: I18n.t('BEE482' /* Alocada */),
        accessor: 'allocated',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: row => {
          return FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false);
        },
        filterable: false,
      }
      // , {
      //   Header: I18n.t('BEE483' /* Reservada */),
      //   accessor: 'reserved',
      //   style: { alignSelf: 'center', textAlign: 'center' },
      //   minWidth: 120,
      //   Cell: row => {
      //     return FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false);
      //   },
      //   filterable: false,
      // }
      , {
        Header: I18n.t('BEE485' /* Usuário Última Entrada */),
        accessor: 'lastEntranceName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 190,
      }, {
        Header: I18n.t('BEE484' /* Data Última Entrada */),
        accessor: 'lastEntranceAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 190,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: row => (
          <span> {row.value ? moment(row.value).format('L LTS') : ''}</span>
        ),
      }, {
        Header: I18n.t('BEE486' /* Usuário Última Contagem */),
        accessor: 'lastCountName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 190,
      }, {
        Header: I18n.t('BEE487' /* Data Última Contagem */),
        accessor: 'lastCountAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 190,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: row => (
          <span> {row.value ? moment(row.value).format('L LTS') : ''}</span>
        ),
      }, {
        Header: I18n.t('BEE434' /* Data de Validade */),
        accessor: 'expirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: row => (
          <span> {row.value ? moment(row.value).format('L') : ''}</span>
        ),
      }, {
        Header: I18n.t('BEE488' /* Data de Fabricação */),
        accessor: 'manufacturingDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: row => (
          <span> {row.value ? moment(row.value).format('L') : ''}</span>
        ),
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
      },
    ];
  }

  render() {
    const { productsStockBalanceList = [] } = this.props;

    return (
      <Table
        downloadCSV
        panelHeaderProps={{
          children: I18n.t('BEE1760' /* Detalhe por produto */).toUpperCase()
        }}
        filterable
        data={productsStockBalanceList}
        columns={this.tableColumns}
        expander
        defaultPageSize={5}
        defaultFilterMethod={(filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row[filter.id]);
          if (_.includes(value, input)) {
            return true;
          }
        }}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.original && rowInfo.original.controlExpirationDate && rowInfo.original.expirationDate) {

            const dateForCalculation = (rowInfo.original.outboundPreExpiration)
              ? moment(rowInfo.original.expirationDate).subtract(parseInt(rowInfo.original.outboundPreExpiration), 'days')
              : rowInfo.original.expirationDate;

            return {
              style: {
                color: moment().isSameOrAfter(dateForCalculation, 'day') ? '#bf4441' : 'black'
              }
            }
          }
          return {}
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  productsStockBalanceList: state.stocks && state.stocks.productsStockBalanceList,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectedStockBalance));