import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import SelectMulti from 'react-select';

import OneClickButton from '../../../components/form/button';
import { PanelPage } from '../../../components/pages/pages';
import Table from '../../../components/table/Table';
import UserConfirmationModal from '../../../components/pages/userConfirmationModal';
import addNotification from '../../../components/notification';

import { reopenInventory, approveInventorySheets, cancelInventorySheets } from '../../../app/store/actions/inventory';

import InventoryHelpers from '../../../helpers/inventory';
import FormatHelpers from '../../../helpers/format';

class InventoryMaintenance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultPageSize: 10,
      defaultSorted: [{ id: 'sheetNumber', desc: false }],
      branchName: '',
      depositName: '',
      grouperCode: null,
      sheets: [],
      showReopeningNotice: false,
      selectedSheet: {},
      checked: {},
      checkedSheetIds: [],
      showMessageNumberOfSheet: false,
      showMessageCancel: false,
      inventoryId: null,
      showUserModal: false,
      cancelNote: '',
      sheetDifferenceSelected: 0,
      leftover: 0,
      foulsValue: 0,
    };

    this.tableColumns = [{
      Header: I18n.t('BEE2109' /* Reabrir */),
      accessor: 'action',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      width: 100,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          {(row.original.status === 2 || row.original.status === 3) && (
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={() => { this.setState({ showReopeningNotice: true, selectedSheet: row.original }); }}
            >
              <span className="d-flex align-items-center text-start">
                <strong>
                  {I18n.t('BEE2109' /* Reabrir */)}
                </strong>
              </span>
            </button>
          )}
        </div>
      ),
    }, {
      Header: I18n.t('BEE436' /* Selecionar */),
      accessor: 'action',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      filterable: false,
      sortable: false,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          {(row.original.status < 4) && (
            <input
              type="checkbox"
              checked={this.state.checked[row.original.inventorySheetId] || false}
              onChange={() => this.singleSelection(row.original)}
            />
          )}
        </div>
      ),
    }, {
      Header: I18n.t('BEE2106' /* Ficha */),
      accessor: 'sheetNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
    }, {
      Header: I18n.t('BEE267' /* Endereço */),
      accessor: 'addressCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 140,
    }, {
      Header: I18n.t('BEE225' /* Produto */),
      accessor: 'productCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE204' /* Situação */),
      accessor: 'status',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 130,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{InventoryHelpers.inventorySheetStatus(row.value)}</span>
        </div>
      ),
      filterMethod: (filter, row) => {
        if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
          return true;
        }
      },
      Filter: ({ onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={(event) => { onChange(event.target.value); }}
        >
          <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
          {InventoryHelpers.inventorySheetStatus().map((elem, index) => (
            <option value={elem.value} key={index}>{elem.label}</option>
          ))}
        </select>
      ),
    }, {
      Header: I18n.t('BEE1516' /* Agrupador */),
      accessor: 'grouperCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE441' /* Quantidade */),
      accessor: 'quantity',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
      Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
    }, {
      Header: I18n.t('BEE2152' /* Diferença */),
      accessor: 'sheetDifference',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
    }, {
      Header: I18n.t('BEE2199' /* Diferença valor */),
      accessor: 'sheetDifferenceValue',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 140,
      Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 2, true),
    }, {
      Header: I18n.t('BEE2108', { 0: '1' } /* %{0} Contagem */),
      columns: [{
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'firstCountQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
      }, {
        Header: I18n.t('BEE515' /* Saldo */),
        accessor: 'firstCountBalance',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,

        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
      }, {
        Header: I18n.t('BEE2152' /* Diferença */),
        accessor: 'firstCountDifference',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,

        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
      }, {
        Header: I18n.t('BEE1' /* Usuário */),
        accessor: 'firstCountUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE2342' /* Data da Contagem */),
        accessor: 'firstCountAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }],
    }, {
      Header: I18n.t('BEE2108', { 0: '2' } /* %{0} Contagem */),
      columns: [{
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'secondCountQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
      }, {
        Header: I18n.t('BEE515' /* Saldo */),
        accessor: 'secondCountBalance',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,

        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
      }, {
        Header: I18n.t('BEE2152' /* Diferença */),
        accessor: 'secondCountDifference',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,

        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
      }, {
        Header: I18n.t('BEE1' /* Usuário */),
        accessor: 'secondCountUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE2342' /* Data da Contagem */),
        accessor: 'secondCountAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }],
    }, {
      Header: I18n.t('BEE2108', { 0: '3' } /* %{0} Contagem */),
      columns: [{
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'thirdCountQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
      }, {
        Header: I18n.t('BEE515' /* Saldo */),
        accessor: 'thirdCountBalance',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,

        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
      }, {
        Header: I18n.t('BEE2152' /* Diferença */),
        accessor: 'thirdCountDifference',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,

        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
      }, {
        Header: I18n.t('BEE1' /* Usuário */),
        accessor: 'thirdCountUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE2342' /* Data da Contagem */),
        accessor: 'thirdCountAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }],
    }, {
      Header: I18n.t('BEE182' /* Depósito */),
      accessor: 'depositName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
    }, {
      Header: I18n.t('BEE428' /* Lote */),
      accessor: 'lotNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE2170' /* Descrição Produto */),
      accessor: 'productName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 300,
    }, {
      Header: 'Data Validade',
      accessor: 'expirationDate',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
      filterMethod: (filter, row) => {
        const input = _.lowerCase(filter.value);
        const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
        const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
        if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
          return true;
        }
      },
      Cell: (row) => (
        <span>
          {row.value ? moment(row.value).format('L') : ''}
        </span>
      ),
    }, {
      Header: I18n.t('BEE1854' /* Setor */),
      accessor: 'sector',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE121' /* Rua */),
      accessor: 'street',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE281' /* Coluna */),
      accessor: 'column',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE283' /* Nível */),
      accessor: 'level',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE285' /* Gaveta */),
      accessor: 'drawer',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }];

    this.colourStyles = {
      multiValueRemove: (base) => ({ ...base, display: 'none' }),
      control: (styles) => ({ ...styles, backgroundColor: '#e9ecef' }),
      option: (styles) => ({
        ...styles,
        backgroundColor: '#ccc',
        color: 'black',
        cursor: 'not-allowed',
        textAlign: 'left',
      }),
    };
  }

  componentDidMount() {
    this.dataOrganization();
    this.props.setState({ updateData: false });
  }

  componentDidUpdate(prevProps) {
    if (this.props.updateData && this.props.updateData !== prevProps.updateData) {
      this.dataOrganization();
      this.props.setState({ updateData: false });
    }
  }

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div key={attr} className="form-group m-b-15 text-left">
      <div className="col-md-12">
        <span>
          {label}
        </span>
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control m-b-5"
          value={(value || value === 0) ? value : ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createSelectDropDownMulti = (value, attr, label, items, disabled, keypressFunction = undefined) => (
    <div className="form-group m-b-15 text-left">
      <div className="col-md-12">
        <span>{label}</span>
        <div>
          <SelectMulti
            value={value}
            isMulti
            onChange={(val) => this.setValue(attr, val)}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            noOptionsMessage={() => I18n.t('BEE561' /* Sem opções */)}
            isSearchable={false}
            isClearable={false}
            styles={this.colourStyles}
            maxMenuHeight={300}
            placeholder={I18n.t('BEE144' /* Selecione... */)}
            options={items}
            isDisabled={disabled}
            onKeyDown={keypressFunction && ((e) => keypressFunction(e, attr))}
          />
        </div>
      </div>
    </div>
  );

  singleSelection = (selection) => {
    const { checked } = this.state;
    let { sheetDifferenceSelected, leftover, foulsValue } = this.state;
    checked[selection.inventorySheetId] = !checked[selection.inventorySheetId];

    if (checked[selection.inventorySheetId]) {
      if (parseFloat(selection.sheetDifference) >= 0) {
        leftover += parseFloat(selection.sheetDifferenceValue);
      } else {
        foulsValue += parseFloat(selection.sheetDifferenceValue) * -1;
      }
    } else if (parseFloat(selection.sheetDifferenceValue) >= 0) {
      leftover -= parseFloat(selection.sheetDifferenceValue);
    } else {
      foulsValue -= parseFloat(selection.sheetDifferenceValue) * -1;
    }

    sheetDifferenceSelected = leftover - foulsValue;
    this.setState({
      checked: { ...checked }, sheetDifferenceSelected, leftover, foulsValue,
    });
  };

  multipleSelection = (selectionOption) => {
    const { checked } = this.state;
    const data = this.reactTable.getResolvedState().sortedData;
    let { sheetDifferenceSelected, leftover, foulsValue } = this.state;
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index += 1) {
        const element = data[index]._original;
        if (checked[element.inventorySheetId] !== selectionOption) {
          if (element.status < 4) checked[element.inventorySheetId] = selectionOption;
          if (element.status < 4 && checked[element.inventorySheetId]) {
            if (parseFloat(element.sheetDifferenceValue) >= 0) {
              leftover += parseFloat(element.sheetDifferenceValue);
            } else {
              foulsValue += parseFloat(element.sheetDifferenceValue) * -1;
            }
          } else if (element.status < 4) {
            if (parseFloat(element.sheetDifferenceValue) >= 0) {
              leftover -= parseFloat(element.sheetDifferenceValue);
            } else {
              foulsValue -= parseFloat(element.sheetDifferenceValue) * -1;
            }
          }
        }
      }

      sheetDifferenceSelected = leftover - foulsValue;

      this.setState({
        checked: { ...checked }, sheetDifferenceSelected, leftover, foulsValue,
      });
    }
  };

  validChecked = (type) => {
    const { checked, sheets } = this.state;

    if (!Object.keys(checked).length) {
      addNotification(
        'danger',
        I18n.t('BEE2105' /* Manutenção de Inventário */),
        I18n.t('BEE2142' /* Nenhum ficha selecionada */),
        'top-right',
      );
    } else {
      const checkedSheetIds = [];
      Object.keys(checked).forEach((index) => {
        if (checked[index]) checkedSheetIds.push(index);
      });

      if (checkedSheetIds.length) {
        let errorValidation = false;
        checkedSheetIds.forEach((id) => {
          const sheet = sheets.find((sh) => parseInt(sh.inventorySheetId, 10) === parseInt(id, 10));

          if (type === 'cancel' && sheet.status > 3) {
            addNotification(
              'danger',
              I18n.t('BEE2105' /* Manutenção de Inventário */),
              I18n.t('BEE3629', {
                0: sheet.sheetNumber,
                1: I18n.t('BEE2189' /* Cancelada */),
                2: InventoryHelpers.inventoryStatus(sheet.status),
              } /* A ficha de inventário %{0} não pode ser %{1}! Status: %{2}. */),
              'top-right',
            );
            errorValidation = true;
            return;
          } if (type === 'approve' && sheet.status !== 3) {
            addNotification(
              'danger',
              I18n.t('BEE2105' /* Manutenção de Inventário */),
              I18n.t('BEE3629', {
                0: sheet.sheetNumber,
                1: I18n.t('BEE205' /* Liberada */),
                2: InventoryHelpers.inventoryStatus(sheet.status),
              } /* A ficha de inventário %{0} não pode ser %{1}! Status: %{2}. */),
              'top-right',
            );
            errorValidation = true;
            return;
          }

          const sheetsProduct = sheets.filter((sh) => !!sh.productCode
            && sh.productCode === sheet.productCode
            && sh.grouperCode === sheet.grouperCode
            && sh.status <= 4);

          if (type === 'approve') {
            let errorApprove = false;
            sheetsProduct.forEach((productSheet) => {
              if (!checkedSheetIds.includes(productSheet.inventorySheetId.toString())) {
                errorApprove = true;
              }
            });

            if (errorApprove) {
              addNotification(
                'danger',
                I18n.t('BEE2105' /* Manutenção de Inventário */),
                I18n.t('BEE3237', { 0: sheet.productCode }),
                /* Todas as fichas do Produto %{0} devem ser selecionadas. */
                'top-right',
              );
              errorValidation = true;
            }
          }
        });

        if (errorValidation) {
          return;
        }

        this.setState({
          checkedSheetIds: [...checkedSheetIds],
          showMessageNumberOfSheet: type !== 'cancel',
          showUserModal: type === 'cancel',
        });
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE2142' /* Nenhum ficha selecionada */),
          'top-right',
        );
      }
    }
  };

  userConfirmation = async (confirm) => {
    this.setState({ showUserModal: false });
    if (confirm) this.setState({ showMessageCancel: true });
  };

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 mt-4">
      <span>{label}</span>
      <div className="col">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  dataOrganization = () => {
    const { inventory = {} } = this.props;

    if (Object.keys(inventory).length) {
      let status = '';
      let depositName = '';
      const grouperCode = [];

      if (inventory.depositName && inventory.depositName.length) {
        inventory.depositName.forEach((item) => {
          if (item) {
            depositName += (depositName ? ', ' : '') + item;
          }
        });
      }

      if (inventory.status && inventory.status.length) {
        inventory.status.forEach((item) => {
          status += (status ? ', ' : '') + InventoryHelpers.inventoryStatus(item);
        });
      }

      if (inventory.grouperCode && inventory.grouperCode.length) {
        inventory.grouperCode.forEach((group) => {
          grouperCode.push({ value: group, label: group });
        });
      }

      this.setState({
        inventoryId: inventory.inventoryId,
        branchName: inventory.branchName,
        depositName,
        grouperCode,
        sheets: [...inventory.sheets],
      });
    }
  };

  reopenInventory = async () => {
    const { selectedSheet } = this.state;
    this.setState({ showReopeningNotice: false, selectedSheet: {} });
    try {
      const result = await this.props.reopenInventory(selectedSheet.inventorySheetId);
      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE2135' /* A contagem foi Reaberta */),
          'top-right',
        );
        await this.props.inventorySheetsList();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2105' /* Manutenção de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  approveSheets = async () => {
    const { inventoryId, checkedSheetIds } = this.state;

    this.setState({
      showMessageNumberOfSheet: false,
      checkedSheetIds: [],
      checked: {},
    });

    try {
      const result = await this.props.approveInventorySheets(inventoryId, checkedSheetIds);

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1789' /* Atualizado com sucesso! */),
          'top-right',
        );
        await this.props.inventorySheetsList();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2105' /* Manutenção de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  cancelSheets = async () => {
    const { inventoryId, checkedSheetIds, cancelNote } = this.state;

    this.setState({
      showMessageNumberOfSheet: false,
      showMessageCancel: false,
      checkedSheetIds: [],
      checked: {},
      cancelNote: '',
    });

    try {
      const result = await this.props.cancelInventorySheets(inventoryId, checkedSheetIds, cancelNote);

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1789' /* Atualizado com sucesso! */),
          'top-right',
        );
        await this.props.inventorySheetsList();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2105' /* Manutenção de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2105' /* Manutenção de Inventário */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      defaultPageSize, defaultSorted, branchName, depositName, showMessageNumberOfSheet,
      grouperCode, sheets, showReopeningNotice, checkedSheetIds, leftover, foulsValue,
      showMessageCancel, cancelNote, sheetDifferenceSelected, selectedSheet,
    } = this.state;

    return (
      <>
        <PanelPage
          noButton
          pageDescription={I18n.t('BEE572' /* Inventário */).toUpperCase()}
          content={(
            <div className="row">
              <div className="col-md-4">
                {this.createInput(
                  branchName,
                  'branchName',
                  I18n.t('BEE145' /* Filial */),
                  '',
                  'text',
                  false,
                  true,
                )}
                {this.createSelectDropDownMulti(
                  grouperCode,
                  'grouperCode',
                  grouperCode && grouperCode.length > 1
                    ? I18n.t('BEE3628' /* Agrupadores */)
                    : I18n.t('BEE1516' /* Agrupador */),
                  grouperCode,
                  true,
                )}
              </div>
              <div className="col-md-4">
                {this.createInput(
                  depositName,
                  'depositName',
                  depositName && depositName.length > 1
                    ? I18n.t('BEE18' /* Depósitos */)
                    : I18n.t('BEE182' /* Depósito */),
                  '',
                  'text',
                  false,
                  true,
                )}
                {this.createInput(
                  FormatHelpers.formatNumber(sheetDifferenceSelected, 2, true),
                  'sheetDifferenceSelected',
                  I18n.t('BEE3630' /* Diferença NET */),
                  '',
                  'text',
                  false,
                  true,
                )}
              </div>
              <div className="col-md-4">
                {this.createInput(
                  FormatHelpers.formatNumber(leftover, 2, true),
                  'leftover',
                  I18n.t('BEE3631' /* Sobras */),
                  '',
                  'text',
                  false,
                  true,
                )}
                {this.createInput(
                  FormatHelpers.formatNumber(foulsValue, 2, true),
                  'foulsValue',
                  I18n.t('BEE3632' /* Faltas */),
                  '',
                  'text',
                  false,
                  true,
                )}
              </div>
            </div>
          )}
          footerContent={(
            <OneClickButton
              type="button"
              className="btn btn-100 btn-default p-5 m-5"
              onClick={() => this.props.restoreHomePage()}
            >
              {I18n.t('BEE137' /* Voltar */)}
            </OneClickButton>
          )}
        />
        <Table
          panelHeaderProps={{
            children: I18n.t('BEE2184' /* Fichas */).toUpperCase(),
            onClickReload: this.props.inventorySheetsList,
          }}
          downloadCSV
          filterable
          data={sheets}
          columns={this.tableColumns}
          expander
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          ref={(r) => {
            this.reactTable = r && r.reactTable;
          }}
          actionButtons={(
            <div className="ml-auto">
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(true)}
              >
                {I18n.t('BEE1908' /* Marcar Todos */)}
              </button>
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(false)}
              >
                {I18n.t('BEE1909' /* Desmarcar Todos */)}
              </button>
              <OneClickButton
                type="button"
                className=" btn btn-120 btn-primary p-5 m-5"
                onClick={() => this.validChecked('approve')}
              >
                {I18n.t('BEE2191' /* Liberar Fichas */)}
              </OneClickButton>
              <OneClickButton
                type="button"
                className=" btn btn-120 btn-danger p-5 m-5"
                onClick={() => this.validChecked('cancel')}
              >
                {I18n.t('BEE2190' /* Cancelar Fichas */)}
              </OneClickButton>
            </div>
          )}
        />
        {(showReopeningNotice
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1800' /* Prosseguir */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={this.reopenInventory}
              onCancel={() => this.setState({ showReopeningNotice: false, selectedSheet: {} })}
            >
              <h5>
                {
                  selectedSheet.thirdCountStatus === 2
                    ? I18n.t('BEE2426', { 0: selectedSheet.sheetNumber })
                    /* A 3a cont da ficha %{0} será exclu e reabert */
                    : I18n.t('BEE2114', { 0: selectedSheet.sheetNumber })
                    /* A ficha %{0} será reaberta para contagem! */
                }
              </h5>
            </SweetAlert>
          )
        )}
        {(showMessageNumberOfSheet
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1800' /* Prosseguir */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={this.approveSheets}
              onCancel={() => this.setState({ showMessageNumberOfSheet: false })}
            >
              <h5>
                {
                I18n.t('BEE2185', { 0: checkedSheetIds.length } /* Foram selecionadas %{0} fichas! deseja prosseguir */)
                }
              </h5>
            </SweetAlert>
          ))}
        {(showMessageCancel
          && (
            <SweetAlert
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => ((cancelNote)
                ? this.cancelSheets()
                : addNotification(
                  'danger',
                  I18n.t('BEE2105' /* Manutenção de Inventário */),
                  I18n.t('BEE1977' /* O campo motivo e obrigatório */),
                  'top-right',
                ))}
              onCancel={() => this.setState({ showMessageCancel: false, cancelNote: '' })}
            >
              <h5>
                {I18n.t('BEE2192', { 0: checkedSheetIds.length })}
                {/* Foram selecionadas %{0} fichas! Para prosseguir informe o motivo do cancelamento */}
              </h5>
              {this.createTextArea(
                cancelNote,
                'cancelNote',
                I18n.t('BEE724' /* Motivo */),
                '',
                3,
                true,
                false,
              )}
            </SweetAlert>
          ))}
        <UserConfirmationModal
          openModal={this.state.showUserModal}
          confirm={(confirm) => this.userConfirmation(confirm)}
        />
      </>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  reopenInventory: (inventorySheetId) => dispatch(reopenInventory(inventorySheetId)),
  approveInventorySheets: (
    inventoryId,
    inventorySheetIds,
  ) => dispatch(approveInventorySheets(inventoryId, inventorySheetIds)),
  cancelInventorySheets: (
    inventoryId,
    inventorySheetIds,
    cancelNote,
  ) => dispatch(cancelInventorySheets(inventoryId, inventorySheetIds, cancelNote)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventoryMaintenance));
