import React from 'react';
import { I18n } from 'react-redux-i18n';
import { PopoverHeader, UncontrolledPopover } from 'reactstrap';
import InputMask from 'react-input-mask';

export default function Inputs() {
  function createInput(value, attr, label, placeholder, type = 'text', required, disabled, onChange, errorMessage) {
    return (
      <div className="mb-3">
        <label htmlFor={attr} className="form-label validationSucess">
          {label}
        </label>

        <input
          type={type}
          className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
          value={value || ''}
          onChange={(e) => {
            onChange(attr, e.target.value);
            if (errorMessage) {
              onChange('errors', { ...errorMessage, [attr]: null });
            }
          }}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          id={attr}
          autoComplete="off"
        />
        {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
      </div>
    );
  }

  function createInputMask(
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    onChange,
    errorMessage,
    mask,
  ) {
    return (
      <div className="mb-3">
        <label htmlFor={attr} className="form-label validationSucess">
          {label}
        </label>

        <InputMask
          type={type}
          className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
          value={value || ''}
          onChange={(e) => {
            onChange(attr, e.target.value);
            if (errorMessage) {
              onChange('errors', { ...errorMessage, [attr]: null });
            }
          }}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          id={attr}
          autoComplete="off"
          mask={mask}
        />
        {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
      </div>
    );
  }

  function createInputCompany(
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    onChange,
    classNameLabel,
    classNameInput,
    errorMessage,
    onBlur,
  ) {
    return (
      <div className={classNameLabel}>
        {label === `${I18n.t('BEE119' /* CEP */)}` ? (
          <div className="d-flex justify-content-start align-items-center">
            <label htmlFor={attr} id={attr} className="form-label">
              {label}
            </label>
            <i style={{ cursor: 'pointer' }} id={attr} className="fas fa-lg fa-fw me-10px fa-info-circle mb-2 ml-1" />
            <UncontrolledPopover trigger="hover" placement="top" target={attr}>
              <PopoverHeader>
                {I18n.t(
                  'BEE2891' /*
                  Digite um CEP e saia do campo para preencher automaticamente os demais campos do formulário. */,
                )}
              </PopoverHeader>
            </UncontrolledPopover>
          </div>
        ) : (
          <label htmlFor={attr} className="form-label">
            {label}
          </label>
        )}
        <input
          type={type}
          className={`form-control ${classNameInput} 
          ${errorMessage ? 'is-invalid' : ''}`}
          value={value || ''}
          onChange={(e) => {
            onChange(attr, e.target.value);
            if (errorMessage) {
              onChange('errors', { ...errorMessage, [attr]: null });
            }
          }}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onBlur={onBlur}
          autoComplete="off"
        />

        {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
      </div>
    );
  }

  function createInputCompanyMask(
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    onChange,
    classNameLabel,
    classNameInput,
    errorMessage,
    onBlur,
    mask,
  ) {
    return (
      <div className={classNameLabel}>
        {label === `${I18n.t('BEE119' /* CEP */)}` ? (
          <div className="d-flex justify-content-start align-items-center">
            <label htmlFor={attr} id={attr} className="form-label">
              {label}
            </label>
            <i style={{ cursor: 'pointer' }} id={attr} className="fas fa-lg fa-fw me-10px fa-info-circle mb-2 ml-1" />
            <UncontrolledPopover trigger="hover" placement="top" target={attr}>
              <PopoverHeader>
                {I18n.t(
                  'BEE2891' /*
                  Digite um CEP e saia do campo para preencher automaticamente os demais campos do formulário. */,
                )}
              </PopoverHeader>
            </UncontrolledPopover>
          </div>
        ) : (
          <label htmlFor={attr} className="form-label">
            {label}
          </label>
        )}
        <InputMask
          type={type}
          className={`form-control ${classNameInput} 
          ${errorMessage ? 'is-invalid' : ''}`}
          value={value || ''}
          onChange={(e) => {
            onChange(attr, e.target.value);
            if (errorMessage) {
              onChange('errors', { ...errorMessage, [attr]: null });
            }
          }}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onBlur={onBlur}
          autoComplete="off"
          mask={mask}
        />

        {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
      </div>
    );
  }

  return {
    createInput,
    createInputCompany,
    createInputMask,
    createInputCompanyMask,
  };
}
