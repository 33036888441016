import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { ReUnixCron } from '@sbzen/re-cron';
import Construe from 'cronstrue/i18n';

import OneClickButton from './../../../../components/form/button';
import { Panel, PanelHeader, PanelBody, PanelFooter } from './../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  updateJob
} from '../../../../app/store/actions/jobs';
import I18nService from '../../../../app/services/i18n';

class JobsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      mode: 'detail',
      cron: '* * * * *',
      showUpdate: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    const { state = {} } = this.props.location;
    const { job = {} } = state;
    const { cron } = job;

    if (mode === 'edit') {
      this.setState({
        mode,
        cron: cron || this.state.cron,
        job,
        showUpdate: true,
      });
    }
  }

  updateJob = async () => {
    if (this.state.job) {
      const updatedJob = {
        ...this.state.job,
        cron: this.state.cron
      }
      try {
        await this.props.updateJob(updatedJob);
        await addNotification(
          'success', I18n.t('BEE2213' /* Editar Job */), I18n.t('BEE2228' /* Job atualizado com sucesso! */), 'top-right'
        );
        this.props.history.push(ROUTES.JOBS_LIST);
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;
  
          if (error.details || error.message) {
            await addNotification(
              'danger',
              I18n.t('BEE2213' /* Editar Job */),
              `${error.code} - ${error.details || error.message}`,
              'top-right'
            );
          } else {
            await addNotification(
              'danger', I18n.t('BEE2213' /* Editar Job */), I18n.t('BEE2226' /* Falha ao atualizar Job! */), 'top-right'
            );
          }
        } else {
          await addNotification(
            'danger', I18n.t('BEE2213' /* Editar Job */), I18n.t('BEE2226' /* Falha ao atualizar Job! */), 'top-right'
          );
        }
      }
    }
  }

  render() {
    const {
      mode,
      showUpdate,
      showCancel,
    } = this.state;

    const { state = {} } = this.props.location;
    const { job = {} } = state;
    const { name } = job;

    let title = '';
    let breadActive = '';

    if (mode === 'edit') {
      title = `${I18n.t('BEE2213' /* Editar Job */)} - ${name}`;
      breadActive = `${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE2210' /* Jobs */)}</li>
            <li className="breadcrumb-item active">{breadActive}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel className="panel-with-tabs">
                <PanelHeader
                  noButton={true}
                  pageDescription={
                    Construe.toString(
                      this.state.cron, 
                      { locale: I18nService.I18nConstrueLocale(this.props.language) }
                    )
                  }
                />
                <PanelBody>
                  <div style={{ textAlign: 'initial' }}>
                    <ReUnixCron
                      value={this.state.cron}
                      localization={this.props.localization || undefined}
                      onChange={(cronValue) => this.setState({ cron: cronValue })}
                    />
                  </div>
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.JOBS_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showUpdate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateJob()}
                    >{I18n.t('BEE139' /* Atualizar */)}</OneClickButton>
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  localization: state.app.cronLocation,
  language: state.app.locale,
});

const mapDispatchToProps = dispatch => ({
  updateJob: (job) => dispatch(updateJob(job))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JobsForm));