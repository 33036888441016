import DockVolumeRemovalRequests from '../../server/dockVolumeRemoval';
import { addLoading, removeLoading } from './loading';

// eslint-disable-next-line import/prefer-default-export
export const dockVolumeRemovalList = (data) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DockVolumeRemovalRequests.dockVolumeRemovalList(data);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateDockVolumeRemoval = (dockVolumeRemoval) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DockVolumeRemovalRequests.updateDockVolumeRemoval(dockVolumeRemoval);
  } finally {
    dispatch(removeLoading());
  }
};
