/* eslint-disable class-methods-use-this */
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import { getOutboundOrdersListCheck } from '../../../../app/store/actions/outboundOrderCheck';
import Table from '../../../../components/table/Table';
import WikiHelp from '../../../../components/tooltip/wikiHelp';
import ROUTES from '../../../../config/routes';

class ExpeditionCheckList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
      outboundOrdersList: [],
    };

    this.defaultSorted = [
      {
        id: 'orderNumber',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: () => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">
              {I18n.t('BEE55' /* Ações */)}
            </button>
            <UncontrolledButtonDropdown>
              <DropdownToggle
                color="default"
                caret
                className="btn-xs"
              />
              <DropdownMenu>
                <DropdownItem>
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
      {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customer.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE613' /* Urgente */),
        accessor: 'urgent',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
      {
        Header: I18n.t('BEE1096' /* Pedido Cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE1763' /* Data Documento */),
        accessor: 'orderDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (
            _.includes(dateFirstOption, input)
            || _.includes(dateSecondOption, input)
          ) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('DD/MM/YYYY')}
          </span>
        ),
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.getOutboundOrders();
  }

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (
          event.target.value === ''
            && (event.keyCode === 8 || event.which === 8)
        ) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getOutboundOrders = async () => {
    const { startDate, endDate } = this.state;

    const data = await this.props.getOutboundOrdersListCheck(startDate, endDate);

    this.setState({ outboundOrdersList: data });
  };

  getOutboundOrdersAfterFilter = async () => {
    const { startDate, endDate } = this.state;

    const data = await this.props.getOutboundOrdersListCheck(startDate, endDate);

    data.forEach((el) => el.urgent = el.urgent ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */));

    this.setState({ outboundOrdersList: data });
  };

  // Editar no futuro o Detalhes
  // showInboundOrderDetail = (inboundOrder) => {
  //   this.props.history.push({
  //     pathname: ROUTES.INBOUND_ORDER_DETAIL,
  //     state: {
  //       inboundOrder: inboundOrder._original,
  //     },
  //   });
  // };

  handleDateApplyEvent = async (event) => {
    const startDate = event[0];
    const endDate = event[1];

    await this.setState({
      startDate,
      endDate,
    });

    this.getOutboundOrdersAfterFilter();
  };

  render() {
    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">
              {I18n.t('BEE33' /* Consultas */)}
            </li>
            <li className="breadcrumb-item">
              {I18n.t('BEE41' /* Expedição */)}
            </li>
            <li className="breadcrumb-item active">
              {I18n.t('BEE37' /* Conferência */)}
            </li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE37' /* Conferência */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.CONSULT_OUTBOUND_CHECK_HELP} />
        </div>
        <Table
          panelHeaderProps={{
            onClickReload: this.getOutboundOrders,
          }}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          filterable
          data={this.state.outboundOrdersList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getOutboundOrdersListCheck: (startDate, endDate) => dispatch(getOutboundOrdersListCheck(startDate, endDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExpeditionCheckList));
