import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import OneClickButton from '../../components/form/button';
import addNotification from '../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../components/panel/panel';

import { getInboundDocument, savePlateToCheck } from '../../app/store/actions/volumePlate';

import WikiHelp from '../../components/tooltip/wikiHelp';
import ROUTES from '../../config/routes';

class EntranceVolumePlate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accessKey: '',
      orderNumber: '',
      inboundDocumentId: null,
      serie: '',
      supplierName: '',
      expectedVolumes: '',
      licensePlate: '',
      plateChecked: false,
    };
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value.toUpperCase())}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          min={1}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  resetForm = () => {
    this.setState({
      accessKey: '',
      orderNumber: '',
      inboundDocumentId: null,
      serie: '',
      supplierName: '',
      expectedVolumes: '',
      licensePlate: '',
      plateChecked: false,
    });
  };

  getInboundDocument = async () => {
    const { accessKey } = this.state;

    try {
      if (!accessKey.match(/^[0-9]{44}$/g)) {
        addNotification(
          'danger',
          I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */),
          I18n.t('BEE1443', { 0: accessKey } /* %{0} não é uma chave de acesso válida */),
          'top-right',
        );
      } else {
        const inboundDocument = await this.props.getInboundDocument(accessKey);

        if (inboundDocument) {
          this.setState({
            inboundDocumentId: inboundDocument.id,
            orderNumber: inboundDocument.orderNumber,
            serie: inboundDocument.serie,
            expectedVolumes: parseInt(inboundDocument.expectedVolumes, 10),
            supplierName: inboundDocument.supplierName,
            licensePlate: inboundDocument.licensePlate,
            plateChecked: inboundDocument.plateChecked,
          });
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */),
            I18n.t('BEE1442', { 0: accessKey } /* Nenhum documento encontrado com a chave %{0} */),
            'top-right',
          );
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */),
            I18n.t('BEE3229' /* Erro na Recepção da NF-e: Placa e Volumes */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */),
          I18n.t('BEE3229' /* Erro na Recepção da NF-e: Placa e Volumes */),
          'top-right',
        );
      }
    }
  };

  savePlateToCheck = async () => {
    const {
      orderNumber, inboundDocumentId, expectedVolumes, licensePlate,
    } = this.state;

    try {
      const validPlate = /^[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}$/.test(licensePlate);
      if (licensePlate !== '' && !validPlate) {
        addNotification(
          'danger',
          I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */),
          I18n.t('BEE969', { 0: licensePlate } /* A placa informada %{0} não é válida ! */),
          'top-right',
        );
        return;
      }

      const validVolumes = parseInt(expectedVolumes, 10);
      if (Number.isNaN(validVolumes) || validVolumes < 1) {
        addNotification(
          'danger',
          I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */),
          I18n.t('BEE406' /* Informe o número de volumes */),
          'top-right',
        );
        return;
      }

      const result = await this.props.savePlateToCheck(inboundDocumentId, expectedVolumes, licensePlate);

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */),
          I18n.t('BEE3230' /* Erro ao efetivar recepção! */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */),
          I18n.t('BEE1439', { 0: orderNumber } /* Documento de entrada %{0} atualizado com sucesso! */),
          'top-right',
        );
        this.resetForm();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */),
            I18n.t('BEE3229' /* Erro na Recepção da NF-e: Placa e Volumes */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */),
          I18n.t('BEE3229' /* Erro na Recepção da NF-e: Placa e Volumes */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      accessKey, orderNumber, licensePlate, serie, supplierName, expectedVolumes, inboundDocumentId, plateChecked,
    } = this.state;

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE35' /* Portaria */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.ENTRANCE_VOLUME_PLATE_HELP} />
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form onSubmit={(e) => e.preventDefault()}>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  {this.createInput(
                    accessKey,
                    'accessKey',
                    I18n.t('BEE967' /* Chave de acesso */),
                    '',
                    'text',
                    true,
                    !!inboundDocumentId,
                  )}
                  {this.createInput(serie, 'serie', I18n.t('BEE403' /* Série */), '', 'text', true, true)}
                  {this.createInput(
                    orderNumber,
                    'orderNumber',
                    I18n.t('BEE1027' /* Número do documento */),
                    '',
                    'text',
                    true,
                    true,
                  )}
                  {this.createInput(
                    supplierName,
                    'supplierName',
                    I18n.t('BEE342' /* Fornecedor */),
                    '',
                    'text',
                    true,
                    true,
                  )}
                  {this.createInput(
                    expectedVolumes,
                    'expectedVolumes',
                    I18n.t('BEE405' /* Volumes */),
                    '',
                    'number',
                    true,
                    !(inboundDocumentId && !plateChecked),
                  )}
                  {this.createInput(
                    licensePlate,
                    'licensePlate',
                    I18n.t('BEE400' /* Placa do Veículo */),
                    '',
                    'text',
                    true,
                    !(inboundDocumentId && !plateChecked),
                  )}
                </PanelBody>
                <PanelFooter>
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-white p-5 m-5"
                    onClick={() => this.resetForm()}
                  >
                    {I18n.t('BEE492' /* Limpar */)}

                  </OneClickButton>
                  {!inboundDocumentId && (
                    <OneClickButton
                      type="submit"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.getInboundDocument()}
                    >
                      {I18n.t('BEE407' /* Buscar */)}
                    </OneClickButton>
                  )}
                  {inboundDocumentId && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.savePlateToCheck()}
                    >
                      {I18n.t('BEE100' /* Confirmar */)}
                    </OneClickButton>
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getInboundDocument: (accessKey) => dispatch(getInboundDocument(accessKey)),
  savePlateToCheck: (
    id,
    expectedVolumes,
    licensePlate,
  ) => dispatch(savePlateToCheck(id, expectedVolumes, licensePlate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EntranceVolumePlate));
