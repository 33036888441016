import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import OneClickButton from '../../../components/form/button';
import { changeAllocationProduct } from '../../../app/store/actions/outboundOrderAllocations';

import Table from '../../../components/table/Table';
import addNotification from '../../../components/notification';

class ModalChangeAllocation extends React.Component {
  constructor(props) {
    super(props);

    this.tableAllocationColumns = [
      {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 90,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE267' /* Endereço */),
        accessor: 'addressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE515' /* Saldo */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      }, {
        Header: I18n.t('BEE1431' /* Alocado */),
        accessor: 'allocated',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      }, {
        Header: I18n.t('BEE530' /* Disponível */),
        accessor: 'availableQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      },
    ];

    this.tableBalanceColumns = [
      {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 90,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE267' /* Endereço */),
        accessor: 'addressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1431' /* Alocado */),
        accessor: 'allocated',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      }, {
        Header: I18n.t('BEE530' /* Disponível */),
        accessor: 'availableQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      },
    ];

    this.state = {
      defaultPageSize: 3,
      pageSizeOptions: [3, 4, 5],
      balanceSelect: {},
      showConfirmAllocation: false,
      outboundOrderProductIds: [],
    };
  }

  changeProductAllocation = () => {
    const { balanceSelect } = this.state;
    const { allocations } = this.props;

    const outboundOrderProductIds = [];

    allocations.forEach(async (element) => {
      outboundOrderProductIds.push(element.outboundOrderProductId);
    });

    if (!balanceSelect) {
      addNotification(
        'danger',
        I18n.t('BEE2009' /* Alocação */),
        I18n.t('BEE2010' /* Nenhum Saldo Estoque selecionado! */),
        'top-right',
      );
    } else {
      this.setState({
        showConfirmAllocation: true,
        outboundOrderProductIds,
      });
    }
  };

  changeProductAllocationSubmit = async () => {
    const { balanceSelect, outboundOrderProductIds } = this.state;
    this.setState({ showConfirmAllocation: false });
    try {
      const result = await this.props.changeAllocationProduct(outboundOrderProductIds, balanceSelect.stockBalanceId);

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2009' /* Alocação */),
          I18n.t('BEE2011' /* Erro ao tentar efetuar a troca de alocação! */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2009' /* Alocação */),
          I18n.t('BEE2012' /* Troca de Alocação efetuada com sucesso! */),
          'top-right',
        );

        this.setState({
          balanceSelect: {},
          outboundOrderProductIds: [],
        });
        this.props.closeModalChangeAllocation(true);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE2009' /* Alocação */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE2009' /* Alocação */),
            I18n.t('BEE2011' /* Erro ao tentar efetuar a troca de alocação! */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2009' /* Alocação */),
          I18n.t('BEE2011' /* Erro ao tentar efetuar a troca de alocação! */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      defaultPageSize, pageSizeOptions, balanceSelect, showConfirmAllocation,
    } = this.state;
    const {
      openAllocationModal = false, closeModalChangeAllocation, balances = [], allocations = [],
    } = this.props;

    return (
      <Modal size="lg" isOpen={openAllocationModal} toggle={() => closeModalChangeAllocation()}>
        <ModalHeader toggle={() => closeModalChangeAllocation()}>{I18n.t('BEE2983' /* Consulta Alocações */)}</ModalHeader>
        <ModalBody>
          <Table
            panelHeaderProps={{
              noButton: true,
              children: I18n.t('BEE2009' /* Alocação */).toLocaleUpperCase(),
            }}
            filterable
            data={allocations}
            columns={this.tableAllocationColumns}
            expander
            pageSizeOptions={pageSizeOptions}
            defaultPageSize={defaultPageSize}
            defaultSorted={[{ id: 'lotNumber', desc: false }]}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
          />

          <Table
            panelHeaderProps={{
              noButton: true,
              children: I18n.t('BEE515' /* Saldo */).toUpperCase(),
            }}
            filterable
            data={balances}
            columns={this.tableBalanceColumns}
            expander
            pageSizeOptions={pageSizeOptions}
            defaultPageSize={defaultPageSize}
            defaultSorted={[{ id: 'lotNumber', desc: false }]}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: async (e) => {
                    await this.setState({ balanceSelect: rowInfo.original });
                  },
                  style: {
                    background: balanceSelect && rowInfo.original.stockBalanceId === balanceSelect.stockBalanceId ? 'silver' : 'white',
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
          />
          {(showConfirmAllocation
            && (
            <SweetAlert
              warning
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="warning"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.changeProductAllocationSubmit()}
              onCancel={() => this.setState({
                showConfirmAllocation: false,
              })}
            >
              {I18n.t('BEE2013' /* Será efetuada a troca das alocações selecionadas! */)}
            </SweetAlert>
            ))}

        </ModalBody>
        {
          /*
            // NOTE - Remoção Temporário de Botão
            <ModalFooter>
              <OneClickButton
                type="button"
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={() => this.changeProductAllocation()}
              >
                {I18n.t('BEE1433')}
              </OneClickButton>
            </ModalFooter>
          */
        }
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  changeAllocationProduct: (outboundOrderProductIds, stockBalaceId) => dispatch(changeAllocationProduct(outboundOrderProductIds, stockBalaceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalChangeAllocation));
