import OutboundMonitorRequests from '../../server/outboundMonitor';
import { addLoading, removeLoading } from './loading';

export const getDataGrid = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const pendingCut = await OutboundMonitorRequests.getDataGrid(tableParams);
    dispatch(removeLoading());
    return pendingCut
  } catch (e) {
    dispatch(removeLoading());
    return {
      pages: 0,
      rows: [],
    }
  }
};

export const toggleOrderPriority = (orderId, docUrgent) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const updOrder = await OutboundMonitorRequests.toggleOrderPriority(orderId, docUrgent);
    dispatch(removeLoading());
    return updOrder
  } catch (e) {
    dispatch(removeLoading());
    return false
  }
};

export const allocatedProducts = (outboundRangeId) => async (dispatch) => {
  dispatch(addLoading())
  try {
    return await OutboundMonitorRequests.allocatedProducts(outboundRangeId);
  } finally {
    dispatch(removeLoading())
  }
}

export const updateOutboundRange = (outboundRangeId, fieldsOutboundRange = {}) => async (dispatch) => {
  dispatch(addLoading())
  try {
    return await OutboundMonitorRequests.updateOutboundRange(outboundRangeId, fieldsOutboundRange);
  } finally {
    dispatch(removeLoading())
  }
}

export const updateOutboundRangeProduct = (outboundRangeProductId, fieldsOutboundRangeProduct = {}) => async (dispatch) => {
  dispatch(addLoading())
  try {
    return await OutboundMonitorRequests.updateOutboundRangeProduct(outboundRangeProductId, fieldsOutboundRangeProduct);
  } finally {
    dispatch(removeLoading())
  }
}