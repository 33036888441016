import { I18n } from 'react-redux-i18n';

function optionsTrigger(triggerCode) {
  if (triggerCode || triggerCode === 0) {
    switch (triggerCode) {
      case 'address':
        return I18n.t('BEE267' /* Endereço */);
      case 'baptism':
        return I18n.t('BEE3977' /* UZ */);
      case 'cableCut':
        return I18n.t('BEE1420' /* Corte de Cabos */);
      case 'cableCutKardex':
        return I18n.t('BEE3978' /* Corte de Cabos Kardex */);
      case 'conferenceVolume':
        return I18n.t('BEE757' /* Conferência de Volume */);
      case 'volumeCheckList':
        return I18n.t('BEE4037' /* Checklist do Volume */);
      case 'conferenceVolumeIndividual':
        return I18n.t('BEE3979' /* Conferência de Volume Individual */);
      case 'consolidation':
        return I18n.t('BEE44' /* Consolidação */);
      case 'vale':
        return I18n.t('BEE3980' /* Consolidação Especial */);
      case 'customerProduct':
        return I18n.t('BEE3981' /* Conferência de Volume Cliente */);
      case 'ean':
        return I18n.t('BEE377' /* Código de Barras */);
      case 'lot':
        return I18n.t('BEE428' /* Lote */);
      case 'preConferenceDocument':
        return I18n.t('BEE3982' /* Pré-Conferência Documento */);
      case 'preConferenceProduct':
        return I18n.t('BEE3983' /* Pré-Conferência de Produtos */);
      case 'resupplyWave':
        return I18n.t('BEE2840' /* Ressuprimento KANBAN */);
      case 'serialHorizontal':
        return I18n.t('BEE3984' /* Etiqueta Seriada Horizontal */);
      case 'serialVertical':
        return I18n.t('BEE3985' /* Etiqueta Seriada Vertical */);
      case 'serialGroup':
        return I18n.t('BEE2764' /* Etiqueta Agrupadora */);
      default:
        return triggerCode;
    }
  } else {
    return [
      { value: 'address', label: I18n.t('BEE267' /* Endereço */), isMulti: true },
      { value: 'baptism', label: I18n.t('BEE3977' /* UZ */), isMulti: false },
      { value: 'cableCut', label: I18n.t('BEE1420' /* Corte de Cabos */), isMulti: false },
      { value: 'cableCutKardex', label: I18n.t('BEE3978' /* Corte de Cabos Kardex */), isMulti: false },
      { value: 'conferenceVolume', label: I18n.t('BEE757' /* Conferência de Volume */), isMulti: false },
      { value: 'volumeCheckList', label: I18n.t('BEE4037' /* Checklist do Volume */), isMulti: false },
      {
        value: 'conferenceVolumeIndividual',
        label: I18n.t('BEE3979' /* Conferência de Volume Individual */),
        isMulti: false,
      },
      { value: 'consolidation', label: I18n.t('BEE44' /* Consolidação */), isMulti: false },
      { value: 'vale', label: I18n.t('BEE3980' /* Consolidação Especial */), isMulti: false },
      { value: 'customerProduct', label: I18n.t('BEE3981' /* Conferência de Volume Cliente */), isMulti: false },
      { value: 'ean', label: I18n.t('BEE377' /* Código de Barras */), isMulti: true },
      { value: 'lot', label: I18n.t('BEE428' /* Lote */), isMulti: false },
      { value: 'preConferenceDocument', label: I18n.t('BEE3982' /* Pré-Conferência Documento */), isMulti: false },
      { value: 'preConferenceProduct', label: I18n.t('BEE3983' /* Pré-Conferência de Produtos */), isMulti: false },
      { value: 'resupplyWave', label: I18n.t('BEE2840' /* Ressuprimento KANBAN */), isMulti: false },
      { value: 'serialHorizontal', label: I18n.t('BEE3984' /* Etiqueta Seriada Horizontal */), isMulti: false },
      { value: 'serialVertical', label: I18n.t('BEE3985' /* Etiqueta Seriada Vertical */), isMulti: false },
      { value: 'serialGroup', label: I18n.t('BEE2764' /* Etiqueta Agrupadora */), isMulti: false },
    ];
  }
}

export default {
  optionsTrigger,
};
