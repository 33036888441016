import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';

import OutboundOrderHelpers from '../../../helpers/outboundOrder';
import ProductHelpers from '../../../helpers/products';

import addNotification from '../../../components/notification';
import UserConfirmationModal from '../../../components/pages/userConfirmationModal';

import {
  cancelOutboundOrder, cancelOutboundProduct, getOutboundOrderListJustDocument,
  getOutboundOrderProducts, setOutboundOrdersProducts, deallocateOutboundOrder, deallocateOutboundProduct,
} from '../../../app/store/actions/outboundOrders';
import FormatHelpers from '../../../helpers/format';
import WikiHelp from '../../../components/tooltip/wikiHelp';
import { toggleOrderPriority } from '../../../app/store/actions/outboundMonitor';
import { getBranchesOptions } from '../../../app/store/actions/branches';
import Permissions from '../../../app/services/permissions';

class OutboundOrderList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
      cancelType: '',
      deallocateType: '',
      showCancelModal: false,
      showCancelProductModal: false,
      showDeallocateModal: false,
      showDeallocateProductModal: false,
      selectedOutboundOrder: {},
      selectedOutboundProduct: null,
      showUserModal: false,
      showModalUrgent: false,
      outboundOrderUrgent: null,
      defaultSortedProducts: [{
        id: 'lineNumber',
        desc: false,
      }],
      defaultSorted: [{
        id: 'orderNumber',
        desc: false,
      }],
      showModalNote: false,
      note: '',
    };
    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => {
          const outboundRange = rows.original;
          const showCancel = this.props.isConsult
            ? false
            : !!(rows.original.status < 4 && !rows.original.pickStartAt);

          let showDeallocate = false;

          if (!this.props.isConsult) {
            if (
              (!outboundRange.depositCode && rows.original.status > 1)
                || (outboundRange.depositCode && rows.original.status >= 1)
            ) {
              showDeallocate = rows.original.status < 4 && !rows.original.pickStartAt;
            }
          }

          return (
            <div style={{ textAlign: 'center' }}>
              <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
              <UncontrolledButtonDropdown>
                <DropdownToggle color="default" caret className="btn-xs" />
                <DropdownMenu>
                  {![9, 10, 12, 13].includes(outboundRange.status) && (
                    !outboundRange.urgent ? (
                      <DropdownItem onClick={() => this.showUrgentModal(outboundRange.id)}>
                        {I18n.t('BEE613' /* Urgente */)}
                      </DropdownItem>
                    ) : (
                      <DropdownItem onClick={() => this.showModalRemoveUrgent(outboundRange.id)}>
                        {I18n.t('BEE2637' /* Remover Urgência */)}
                      </DropdownItem>
                    )
                  )}
                  <DropdownItem onClick={() => this.showOutboundOrderDetail(rows)}>
                    {I18n.t('BEE56' /* Detalhes */)}
                  </DropdownItem>
                  {showDeallocate && (
                    <DropdownItem onClick={() => this.showDeallocateOrder(rows.original)}>
                      {I18n.t('BEE3278' /* Desalocar */)}
                    </DropdownItem>
                  )}
                  {(!Permissions.checkPermission(ROUTES.P_EXPEDITION_CANCEL_ORDER)
                    && showCancel && (
                      <DropdownItem onClick={() => this.showCancelOrder(rows.original)}>
                        {I18n.t('BEE99' /* Cancelar */)}
                      </DropdownItem>
                  ))}
                  <DropdownItem onClick={() => {
                    this.setState({
                      showModalNote: true,
                      note: rows.original.note,
                    });
                  }}
                  >
                    {I18n.t('BEE135' /* Observação */)}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1096' /* Pedido cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE402' /* Nota Fiscal */),
        accessor: 'invoiceNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{OutboundOrderHelpers.outboundOrderStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OutboundOrderHelpers.outboundOrderStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE613' /* Urgente */),
        accessor: 'urgent',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (rows) => (
          <div className={{ textAlign: 'center' }}>
            {rows.original.urgent ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option value>{I18n.t('BEE172' /* Sim */)}</option>
            <option value={false}>{I18n.t('BEE173' /* Não */)}</option>
          </select>
        ),
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE53' /* Cidade */),
        accessor: 'cityName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1057' /* Entrega */),
        accessor: 'deliveryDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('DD/MM/YYYY')}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE513' /* Integração */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('L LTS')}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE3197' /* Controle da doca */),
        accessor: 'dockControlId',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE907' /* Romaneio */),
        accessor: 'waybillNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2779' /* Restrição Liberada */),
        accessor: 'restrictionPicking',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (rows) => (
          <div>
            <span>
              {(!rows.original.restrictionPicking)
                ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
            </span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option value={false}>{I18n.t('BEE172' /* Sim */)}</option>
            <option value>{I18n.t('BEE173' /* Não */)}</option>
          </select>
        ),
      }, {
        Header: I18n.t('BEE2780' /* Usuário Liberação */),
        accessor: 'userReleasePickingRestriction.login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
        Cell: (rows) => (
          <div className={{ textAlign: 'center' }}>
            {rows.original.userReleasePickingRestriction ? rows.original.userReleasePickingRestriction.login : ''}
          </div>
        ),
      }, {
        Header: I18n.t('BEE2781' /* Data e Hora liberação */),
        accessor: 'releasePickingRestriction',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.original.releasePickingRestriction
              ? moment(row.releasePickingRestriction).format('L LTS')
              : ''}
          </span>
        ),
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE3690' /* Retirado por */),
        accessor: 'withdrawalUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE444' /* Data de Entrega */),
        accessor: 'withdrawalAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {row.value ? moment(row.value).format('DD/MM/YYYY') : ''}
          </span>
        ),
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE3691' /* Documento do Recebedor */),
        accessor: 'withdrawnUserDocument',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      },
      {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'withdrawnNote',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      },
    ];

    this.productTableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => {
          let showDeallocate = false;

          const row = rows.original;

          if (this.props.isConsult) {
            showDeallocate = false;
          } else if (row.isProductionSeparation) {
            showDeallocate = row.status >= 1 && row.status < 5
              && (parseFloat(row.allocatedQuantity || 0) - parseFloat(row.pickedQuantity || 0) > 0);
          } else {
            showDeallocate = !!(row.status > 1 && row.status < 5 && !row.pickedQuantity);
          }

          return (
            <div style={{ textAlign: 'center' }}>
              <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
              <UncontrolledButtonDropdown>
                <DropdownToggle color="default" caret className="btn-xs" />
                <DropdownMenu>
                  {!Permissions.checkPermission(ROUTES.P_EXPEDITION_CANCEL_ITEM_ORDER)
                    && !this.props.isConsult && row.status < 5
                    && (
                      <DropdownItem
                        onClick={() => this.showCancelProduct(row)}
                      >
                        {I18n.t('BEE99' /* Cancelar */)}
                      </DropdownItem>
                    )}
                  {showDeallocate
                    && (
                      <DropdownItem
                        onClick={() => this.showDeallocateProduct(row)}
                      >
                        {I18n.t('BEE3278' /* Desalocar */)}
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          );
        },
      }, {
        Header: I18n.t('BEE463' /* Linha */),
        accessor: 'lineNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1897' /* Descrição */),
        accessor: 'productName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Cell: (row) => (
          <span>{OutboundOrderHelpers.outboundOrderProductStatus(row.value)}</span>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%', textAlign: 'center' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OutboundOrderHelpers.outboundOrderProductStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE1579' /* Lance Obrigatório */),
        accessor: 'mandatoryQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE701' /* Controlar Vencimento */),
        accessor: 'controlExpirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option key="all" value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option key="true" value>{I18n.t('BEE172' /* Sim */)}</option>
            <option key="false" value={false}>{I18n.t('BEE173' /* Não */)}</option>
          </select>
        ),
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}</span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE708' /* Tipo de Controle de Estoque */),
        accessor: 'stockControlType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Cell: (row) => (
          <span>{ProductHelpers.stockControlType(row.value)}</span>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value, 10) === parseInt(row.stockControlType, 10)) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {ProductHelpers.stockControlType().map((element, index) => (
              <option key={index} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE1431' /* Alocado */),
        accessor: 'allocatedQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE1368' /* Separado */),
        accessor: 'pickedQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE1721' /* Data Separação */),
        accessor: 'pickedAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE1092' /* Separador */),
        accessor: 'pickedUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE445' /* Conferido */),
        accessor: 'checkedQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE467' /* Data Conferência */),
        accessor: 'checkedAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE805' /* Conferente */),
        accessor: 'checkedUser',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE2112' /* Produto Cliente */),
        accessor: 'customerProductCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.getOutboundOrders();
  }

  showUrgentModal = (outboundOrderId) => {
    this.setState({
      showModalUrgent: true,
      outboundOrderUrgent: outboundOrderId,
    });
  };

  closeUrgentModal = () => {
    this.setState({
      showModalUrgent: false,
      showRemoveUrgent: false,
      outboundOrderUrgent: null,
    });
  };

  showModalRemoveUrgent = async (outboundOrderId) => {
    this.setState({
      showRemoveUrgent: true,
      outboundOrderUrgent: outboundOrderId,
    });
  };

  modifyUrgency = async (docUrgent) => {
    const { outboundOrderUrgent } = this.state;

    if (outboundOrderUrgent) {
      try {
        const result = await this.props.toggleOrderPriority(outboundOrderUrgent, docUrgent);

        if (result && result.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE1363' /* Monitor de Expedição */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */),
            'top-right',
          );
        } else {
          addNotification(
            'success',
            I18n.t('BEE1363' /* Monitor de Expedição */),
            (docUrgent)
              ? I18n.t('BEE2635' /* Documento alterado para Urgente com sucesso! */)
              : I18n.t('BEE2636' /* Urgencia removida com sucesso! */),
            'top-right',
          );

          this.getOutboundOrders();
        }
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && error.message) {
          const messageError = (error.details)
            ? `${error.code} - ${error.details} | ${error.message}`
            : error.message;
          addNotification('danger', I18n.t('BEE1363' /* Monitor de Expedição */), messageError, 'top-right');
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1363' /* Monitor de Expedição */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */),
            'top-right',
          );
        }
      }
    }

    this.closeUrgentModal();
  };

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 mt-4">
      <label htmlFor={label}>{label}</label>
      <div className="col">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  getOutboundOrderProducts = async () => {
    const { selectedOutboundOrder } = this.state;
    try {
      await this.props.getOutboundOrderProducts(selectedOutboundOrder.id);
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification('danger', I18n.t('BEE1087' /* Documentos de Saída */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1087' /* Documentos de Saída */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  getOutboundOrders = async () => {
    const { startDate, endDate, selectedOutboundOrder } = this.state;

    await this.props.getOutboundOrderListJustDocument(startDate, endDate);

    const orderList = this.props.outboundOrdersList;

    const existOrder = (orderList && orderList.length && Object.keys(selectedOutboundOrder).length)
      ? (orderList.find((element) => (element.id === selectedOutboundOrder.id)))
      : {};

    await this.setState({
      selectedOutboundOrder: existOrder || {},
    });

    if (existOrder && Object.keys(existOrder).length) {
      await this.getOutboundOrderProducts();
    } else {
      await this.props.setOutboundOrdersProducts([]);
    }
  };

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (
          event.target.value === ''
          && (event.keyCode === 8 || event.which === 8)
        ) {
          onChange(event.target.value);
        }
      }}
    />
  );

  showOutboundOrderDetail = (outboundOrder) => {
    const { isConsult = false } = this.props;
    this.props.history.push({
      pathname: (isConsult) ? ROUTES.CONSULT_OUTBOUND_ORDER_DETAIL : ROUTES.OUTBOUND_ORDER_DETAIL,
      state: {
        outboundOrder: outboundOrder.original,
      },
    });
  };

  showCancelOrder = () => {
    this.setState({
      showCancelModal: true,
      cancelType: 'order',
    });
  };

  showDeallocateOrder = () => {
    this.setState({
      showDeallocateModal: true,
      deallocateType: 'order',
    });
  };

  showCancelProduct = (outboundProduct) => {
    this.setState({
      showCancelProductModal: true,
      cancelType: 'product',
      selectedOutboundProduct: outboundProduct,
    });
  };

  showDeallocateProduct = (outboundProduct) => {
    this.setState({
      showDeallocateProductModal: true,
      deallocateType: 'product',
      selectedOutboundProduct: outboundProduct,
    });
  };

  cancelOrder = async () => {
    try {
      const { selectedOutboundOrder } = this.state;

      const result = await this.props.cancelOutboundOrder(selectedOutboundOrder.id);
      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1087' /* Documentos de Saída */),
          I18n.t('BEE2948' /* Erro ao cancelar Documento de Saída */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE1087' /* Documentos de Saída */),
          I18n.t('BEE2949' /* Documento cancelado com sucesso! */),
          'top-right',
        );
        this.getOutboundOrders();
      }
    } catch (error) {
      const messageError = error.response
        && error.response.data && error.response.data.error && error.response.data.error.message;

      if (messageError) {
        addNotification(
          'danger',
          I18n.t('BEE1087' /* Documentos de Saída */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1087' /* Documentos de Saída */),
          I18n.t('BEE2950' /* Não foi possível cancelar o Documento de Saída. */),
          'top-right',
        );
      }
    }

    this.setState({
      cancelType: '',
      deallocateType: '',
    });
  };

  deallocateOrder = async () => {
    try {
      const { selectedOutboundOrder } = this.state;

      const result = await this.props.deallocateOutboundOrder(selectedOutboundOrder.id);

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1087' /* Documentos de Saída */),
          I18n.t('BEE3284' /* Erro ao desalocar documento de saída. */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE1087' /* Documentos de Saída */),
          I18n.t('BEE3285' /* Documento desalocado com sucesso! */),
          'top-right',
        );
        this.getOutboundOrders();
      }
    } catch (error) {
      const messageError = error.response
        && error.response.data && error.response.data.error && error.response.data.error.message;

      if (messageError) {
        addNotification(
          'danger',
          I18n.t('BEE1087' /* Documentos de Saída */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1087' /* Documentos de Saída */),
          I18n.t('BEE3286' /* Não foi possível desalocar o Documento de Saída. */),
          'top-right',
        );
      }
    }

    this.setState({
      cancelType: '',
      deallocateType: '',
    });
  };

  cancelProduct = async () => {
    try {
      const { selectedOutboundProduct } = this.state;

      const result = await this.props.cancelOutboundProduct(
        selectedOutboundProduct.outboundOrderId,
        selectedOutboundProduct.id,
      );

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1087' /* Documentos de Saída */),
          I18n.t('BEE2952' /* Erro ao cancelar Produto */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE1087' /* Documentos de Saída */),
          I18n.t('BEE2953' /* Produto cancelado com sucesso! */),
          'top-right',
        );

        this.getOutboundOrders();
      }
    } catch (error) {
      const messageError = error.response
        && error.response.data && error.response.data.error && error.response.data.error.message;

      if (messageError) {
        addNotification(
          'danger',
          I18n.t('BEE1087' /* Documentos de Saída */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1087' /* Documentos de Saída */),
          I18n.t('BEE2954' /* Não foi possível cancelar o Produto */),
          'top-right',
        );
      }
    }

    this.setState({
      cancelType: '',
      deallocateType: '',
    });
  };

  deallocateProduct = async () => {
    try {
      const { selectedOutboundProduct } = this.state;

      const result = await this.props.deallocateOutboundProduct(
        selectedOutboundProduct.outboundOrderId,
        selectedOutboundProduct.id,
      );

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1087' /* Documentos de Saída */),
          I18n.t('BEE3287' /* Erro ao desalocar produto */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE1087' /* Documentos de Saída */),
          I18n.t('BEE3288' /* Produto desalocado com sucesso! */),
          'top-right',
        );

        this.getOutboundOrders();
      }
    } catch (error) {
      const messageError = error.response
        && error.response.data && error.response.data.error && error.response.data.error.message;

      if (messageError) {
        addNotification(
          'danger',
          I18n.t('BEE1087' /* Documentos de Saída */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1087' /* Documentos de Saída */),
          I18n.t('BEE3289' /* Não foi possível desalocar o produto. */),
          'top-right',
        );
      }
    }

    this.setState({
      cancelType: '',
      deallocateType: '',
    });
  };

  handleDateApplyEvent = async (event) => {
    const startDate = event[0];
    const endDate = event[1];

    await this.setState({
      startDate,
      endDate,
    });

    this.getOutboundOrders();
  };

  createWidgetStats = () => {
    const { outboundOrdersList = [] } = this.props;

    const stats1 = [
      {
        title: I18n.t('BEE2009' /* Alocação */).toUpperCase(),
        quantity: outboundOrdersList.filter(
          (order) => order.status === 1,
        ).length,
        color: 'yellow',
      }, {
        title: I18n.t('BEE1420' /* Corte de Cabos */).toUpperCase(),
        quantity: outboundOrdersList.filter(
          (order) => order.status === 2,
        ).length,
        color: 'dark',
      }, {
        title: I18n.t('BEE43' /* Separação */).toUpperCase(),
        quantity: outboundOrdersList.filter(
          (order) => order.status === 3 || order.status === 4,
        ).length,
        color: 'orange',
      }, {
        title: I18n.t('BEE37' /* Conferência */).toUpperCase(),
        quantity: outboundOrdersList.filter(
          (order) => order.status === 5 || order.status === 9,
        ).length,
        color: 'teal',
      }, {
        title: I18n.t('BEE199' /* Doca */).toUpperCase(),
        quantity: outboundOrdersList.filter(
          (order) => order.status === 6,
        ).length,
        color: 'aqua',
      },
    ];

    const stats2 = [
      {
        title: I18n.t('BEE907' /* Romaneio */).toUpperCase(),
        quantity: outboundOrdersList.filter(
          (order) => order.status === 7,
        ).length,
        color: 'purple',
      }, {
        title: I18n.t('BEE44' /* Consolidação */).toUpperCase(),
        quantity: outboundOrdersList.filter(
          (order) => order.status === 8,
        ).length,
        color: 'green',
      }, {
        title: I18n.t('BEE1766' /* Finalizado */).toUpperCase(),
        quantity: outboundOrdersList.filter(
          (order) => order.status === 10,
        ).length,
        color: 'blue',
      }, {
        title: I18n.t('BEE176' /* Divergência */).toUpperCase(),
        quantity: outboundOrdersList.filter(
          (order) => order.status === 11,
        ).length,
        color: 'red',
      }, {
        title: I18n.t('BEE64' /* Cancelado */).toUpperCase(),
        quantity: outboundOrdersList.filter(
          (order) => order.status === 12 || order.status === 13,
        ).length,
        color: 'silver',
      },
    ];

    return (
      <>
        <div
          className="row row-space-10 m-b-15"
          style={{ justifyContent: 'space-between' }}
        >
          {stats1.map((stat, index) => (
            <div className="col-xl-2" key={index}>
              <div
                style={{ maxHeight: '100px' }}
                className={`widget widget-stats bg-${stat.color} m-b-15 p-b-0`}
              >
                <div className="stats-content text-center">
                  <div className="stats-title f-w-800" style={{ color: '#fff', fontSize: '14px' }}>{stat.title}</div>
                  <div className="stats-number f-s-30 mb-5">{stat.quantity}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="row row-space-10 m-b-15"
          style={{ justifyContent: 'space-between' }}
        >
          {stats2.map((stat, index) => (
            <div className="col-xl-2" key={index}>
              <div
                style={{ maxHeight: '100px' }}
                className={`widget widget-stats bg-${stat.color} m-b-15 p-b-0`}
              >
                <div className="stats-content text-center">
                  <div className="stats-title f-w-800" style={{ color: '#fff', fontSize: '14px' }}>{stat.title}</div>
                  <div className="stats-number f-s-30 mb-5">{stat.quantity}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  userConfirmation = async (confirm) => {
    const { cancelType, deallocateType } = this.state;

    this.setState({ showUserModal: false });
    if (confirm && cancelType === 'order') this.cancelOrder();
    if (confirm && cancelType === 'product') this.cancelProduct();
    if (confirm && deallocateType === 'order') this.deallocateOrder();
    if (confirm && deallocateType === 'product') this.deallocateProduct();
  };

  selectedProducts = async (selectedOutboundOrder) => {
    if (!this.state.selectedOutboundOrder || (selectedOutboundOrder && this.state.selectedOutboundOrder
      && this.state.selectedOutboundOrder.id !== selectedOutboundOrder.id)) {
      await this.setState({ selectedOutboundOrder: { ...selectedOutboundOrder } });
      await this.getOutboundOrderProducts();
    }
  };

  render() {
    const { outboundOrdersList = [], outboundOrderProductsList = [], isConsult = false } = this.props;

    const {
      showCancelModal, showCancelProductModal, selectedOutboundOrder, defaultSorted,
      defaultSortedProducts, showModalNote, note, showModalUrgent, showRemoveUrgent,
      showDeallocateModal, showDeallocateProductModal,
    } = this.state;

    return (
      <>
        <div>
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              <li className="breadcrumb-item">
                <i className="fas fa-home fa-fw m-t-10 m-r-5" />
                {' '}
                {I18n.t('BEE12' /* Início */)}
              </li>
              <li className="breadcrumb-item">
                {(isConsult) ? I18n.t('BEE33' /* Consultas */) : I18n.t('BEE1400' /* Movimentação */)}
              </li>
              <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
              <li className="breadcrumb-item active">{I18n.t('BEE1087' /* Documentos de Saída */)}</li>
            </ol>
          </div>
          <div className="d-flex align-items-center mb-md-3 mb-2">
            <h1 className="page-header mb-0">
              {I18n.t('BEE1087' /* Documentos de Saída */)}
            </h1>
            <WikiHelp wikiPath={isConsult
              ? ROUTES.CONSULT_OUTBOUND_ORDER_HELP : ROUTES.OUTBOUND_ORDER_LIST_HELP}
            />
          </div>
          {!isConsult && this.createWidgetStats()}
          <Table
            downloadCSV
            panelHeaderProps={{
              onClickReload: this.getOutboundOrders,
            }}
            datePicker={{
              handleDateApplyEvent: this.handleDateApplyEvent,
            }}
            filterable
            data={outboundOrdersList}
            columns={this.tableColumns}
            expander
            defaultPageSize={5}
            defaultSorted={defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: () => {
                    this.selectedProducts(rowInfo.original);
                  },
                  style: {
                    background: selectedOutboundOrder && rowInfo.original.id === selectedOutboundOrder.id
                      ? 'silver'
                      : 'white',
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
          />
        </div>
        <Table
          downloadCSV
          panelHeaderProps={{
            children: I18n.t('BEE27' /* Produtos */).toUpperCase(),
          }}
          filterable
          data={outboundOrderProductsList}
          columns={this.productTableColumns}
          expander
          defaultPageSize={10}
          pageSizeOptions={[5, 10, 15]}
          defaultSorted={defaultSortedProducts}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        <UserConfirmationModal
          openModal={this.state.showUserModal}
          confirm={(confirm) => this.userConfirmation(confirm)}
        />
        {(showCancelModal
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1849' /* continuar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE2943' /* Cancelar Documento */)}
              onConfirm={() => this.setState({ showUserModal: true, showCancelModal: false, cancelType: 'order' })}
              onCancel={() => this.setState({ showCancelModal: false, cancelType: '' })}
            >
              {I18n.t('BEE2946' /* Documento de saída será cancelado! Deseja continuar? */)}
            </SweetAlert>
          )
        )}
        {(showDeallocateModal && (
          <SweetAlert
            info
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE1849' /* continuar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE3279' /* Desalocar documento */)}
            onConfirm={() => this.setState({
              showUserModal: true,
              showDeallocateModal: false,
              deallocateType: 'order',
            })}
            onCancel={() => this.setState({ showDeallocateModal: false, deallocateType: '' })}
          >
            {I18n.t('BEE3283' /* O documento de saída será desalocado! Deseja continuar? */)}
          </SweetAlert>
        )
        )}
        {(showCancelProductModal
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1849' /* continuar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE2944' /* Cancelar Produto */)}
              onConfirm={() => this.setState({
                showUserModal: true,
                showCancelProductModal: false,
                cancelType: 'product',
              })}
              onCancel={() => this.setState({ showCancelProductModal: false, cancelType: '' })}
            >
              {I18n.t('BEE2945' /* Produto será cancelado! Deseja continuar? */)}
            </SweetAlert>
          )
        )}
        {(showDeallocateProductModal && (
          <SweetAlert
            info
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE1849' /* continuar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE3281' /* Desalocar o produto */)}
            onConfirm={() => this.setState({
              showUserModal: true,
              showDeallocateProductModal: false,
              deallocateType: 'product',
            })}
            onCancel={() => this.setState({ showDeallocateProductModal: false, deallocateType: '' })}
          >
            {I18n.t('BEE3280' /* O produto será desalocado! Deseja continuar? */)}
          </SweetAlert>
        )
        )}
        {(showModalNote
          && (
            <SweetAlert
              confirmBtnText={I18n.t('BEE1484' /* OK */)}
              confirmBtnBsStyle="primary"
              title={I18n.t('BEE135' /* Observação */)}
              onConfirm={() => this.setState({
                showModalNote: false,
                note: '',
              })}
              btnSize="sm"
            >
              {this.createTextArea(
                note,
                'note',
                I18n.t('BEE135' /* Observação */),
                '',
                10,
                false,
                true,
              )}
            </SweetAlert>
          ))}
        {(showModalUrgent
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1849' /* Continuar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.modifyUrgency(true)}
              onCancel={() => this.closeUrgentModal()}
            >
              {I18n.t('BEE2633')}
              {/* O Documento de Saída vai ser alterado para Urgente e terá prioridade no processo de Separação */}
            </SweetAlert>
          )
        )}
        {showRemoveUrgent && (
          <SweetAlert
            info
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE1849' /* Continuar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.modifyUrgency(false)}
            onCancel={() => this.closeUrgentModal()}
          >
            {I18n.t('BEE2634')}
            {/* O Documento de Saída terá sua Urgência removida e perderá sua prioridade no processo de Separação */}
          </SweetAlert>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  outboundOrdersList: state.outboundOrders && state.outboundOrders.outboundOrdersListJustDocument,
  outboundOrderProductsList: state.outboundOrders && state.outboundOrders.outboundOrderProductsList,
  userLogged: state.app.userLogged && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getOutboundOrderListJustDocument: (
    startDate,
    endDate,
  ) => dispatch(getOutboundOrderListJustDocument(startDate, endDate)),
  getOutboundOrderProducts: (outboundOrderId) => dispatch(getOutboundOrderProducts(outboundOrderId)),
  setOutboundOrdersProducts: (outboundOrderProductsList) => dispatch(setOutboundOrdersProducts(
    outboundOrderProductsList,
  )),
  cancelOutboundOrder: (outboundOrderId) => dispatch(cancelOutboundOrder(outboundOrderId)),
  cancelOutboundProduct: (
    outboundOrderId,
    outboundProductId,
  ) => dispatch(cancelOutboundProduct(outboundOrderId, outboundProductId)),
  toggleOrderPriority: (outboundOrderId, docUrgent) => dispatch(toggleOrderPriority(outboundOrderId, docUrgent)),
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  deallocateOutboundOrder: (outboundOrderId) => dispatch(deallocateOutboundOrder(outboundOrderId)),
  deallocateOutboundProduct: (
    outboundOrderId,
    outboundProductId,
  ) => dispatch(deallocateOutboundProduct(outboundOrderId, outboundProductId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundOrderList));
