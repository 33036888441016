/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  getEmptyStorageAddresses, getInboundCheckInvoice, getInboundCheckInvoiceByNumber,
  getInboundCheckInvoices, getInboundCheckProductByCode, getInboundCheckProductByEan,
  putConfirmCount, setInboundCheckCreateEanState, getSerialGroup, getInboundOrderProductAddresses,
} from '../../../app/store/actions/inboundCheck';
import { printEanLabel } from '../../../app/store/actions/labels';
import { getUserPrinterOptions } from '../../../app/store/actions/printers';
import OneClickButton from '../../../components/form/button';
import { CustomNumberFormat } from '../../../components/form/input';
import addNotification from '../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../components/panel/panel';
import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';
import AddressSelectForm from './addressSelectForm';
import LotControlForm from './lotControlForm';
import ProductsList from './productsList';
import WikiHelp from '../../../components/tooltip/wikiHelp';

class InboundCheckForm extends React.Component {
  constructor(props) {
    super(props);

    this.tableColumns = [
      {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE342' /* Fornecedor */),
        accessor: 'supplierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
      }, {
        Header: I18n.t('BEE405' /* Volumes */),
        accessor: 'expectedVolumes',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE85' /* CNPJ */),
        accessor: 'supplierCNPJ',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 70,
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      },
    ];

    this.state = {
      invoice: '',
      invoiceId: null,
      supplierName: '',
      total: 0,
      check: 0,
      damageCount: 0,
      branchCode: null,
      currentProduct: null,
      lots: [],
      ean: '',
      quantity: 0,
      defaultAddress: '',
      suggestedAddress: '',
      emptyAddressesOptions: [],
      productCode: '',
      unitMeasure: '',
      baptism: null,
      description: '',
      descriptionFull: '',
      curve: '',
      netWeight: 0,
      grossWeight: 0,
      height: 0,
      width: 0,
      length: 0,
      requestWeightDimensions: true,
      mandatoryWeightDimensions: true,
      volume: 0,
      stickerQty: 0,
      invoiceNote: '',
      productNote: '',
      invoiceProductsList: [],
      showForm: false,
      showInvoiceSearch: false,
      showDivergenceModal: false,
      showDamagedModal: false,
      damageCountCheck: false,
      partialCheck: false,
      showPartialModal: false,
      partialInboundCheck: false,
      divergenceNote: '',
      showLotControlForm: false,
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
      showFinishCheckModal: false,
      showPrinterDialog: false,
      showCrossDockingModal: false,
      printerOptions: [],
      printerSelected: null,
      showInvoiceNoteModal: false,
      showProductNoteModal: false,
      receiveLeftover: false,
      codLote: '',
      dtValidLote: null,
      serialGroupId: null,
      prefix: '',
      preStorageStage: false,
      stage: '',
      orderType: '',
    };
  }

  async componentDidMount() {
    const { createEanState } = this.props;
    const { printerSelected } = this.state;

    const printerReq = await this.props.getUserPrinterOptions();
    const { printerOptions, userPrinter } = printerReq;

    this.setState({
      printerOptions,
      printerSelected: printerSelected === null
        ? userPrinter
        : printerSelected !== userPrinter
          ? printerSelected
          : userPrinter,
    });

    const fromPage = this.props.location.state ? this.props.location.state.fromPage : null;
    if (fromPage === 'createEan') {
      if (!createEanState) return;

      this.setState({
        invoiceId: createEanState.invoiceId,
        ean: createEanState.newEan,
      }, async () => {
        await this.detailInvoice();

        const product = {
          productCode: createEanState.productCode,
        };

        await this.getSelectedProduct(product);

        if (createEanState.newEan) await this.getProductByEan();
      });
    }
    this.props.setInboundCheckCreateEanState(null);
  }

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div className="form-group p-2">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        {!disabled && attr !== 'ean' && (
          <div className="input-group-append">
            <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, '')}>
              {' '}
              <i className="fa fa-times" />
            </button>
          </div>
        )}
        {attr === 'ean' && (
          <div className="input-group-append">
            <button type="button" className="btn btn-secondary" onClick={() => this.resetProduct()}>
              {' '}
              <i className="fa fa-times" />
            </button>
          </div>
        )}
      </div>
    </div>
  );

  createInputButton = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    buttonLabel,
    buttonFunction,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div className="form-group p-2">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && `bt-${id}`}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              this.setValue(attr, '', true);
            }}
          >
            {' '}
            <i className="fa fa-times" />
          </button>
          {buttonLabel && (
            <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  createSelect = (value, attr, label, items) => (
    <div className="form-group p-2">
      <label htmlFor={label}>{label}</label>
      <select
        className="form-control"
        value={value || ''}
        onChange={(e) => this.setValue(attr, e.target.value)}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );

  createNumberFormat = (value, attr, label, decimals, keypressFunction = undefined, id = null, disabled = false) => (
    <div className="form-group p-2">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <CustomNumberFormat
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          onFocus={attr === 'quantity' ? (() => this.showLotControl()) : (() => { })}
          className="form-control"
          thousandSeparator={id !== 3 ? '.' : ''}
          decimalSeparator=","
          decimalScale={(!this.props.fractionalQuantity) ? 0 : decimals}
          value={value || ''}
          onValueChange={(value) => {
            this.setValue(attr, value);
          }}
          fixedDecimalScale
          defaultValue={0}
          disabled={disabled}
          allowEmptyFormatting
          allowNegative={attr === 'baptism' && false}
        />
        {!disabled && (
          <div className="input-group-append">
            <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, 0)}>
              {' '}
              <i className="fa fa-times" />
            </button>
          </div>
        )}
      </div>
    </div>
  );

  createTable = (startDate, endDate, currentRange, inboundCheckInvoicesList) => (
    <Table
      panelHeaderProps={{ noButton: true }}
      datePicker={{
        handleDateApplyEvent: this.handleDateApplyEvent,
      }}
      filterable
      data={inboundCheckInvoicesList}
      columns={this.tableColumns}
      expander
      defaultPageSize={10}
      defaultSorted={this.defaultSorted}
      defaultFilterMethod={(filter, row) => {
        const input = _.lowerCase(filter.value);
        const value = _.lowerCase(row[filter.id]);
        if (_.includes(value, input)) {
          return true;
        }
      }}
      getTrProps={(state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
          return {
            onClick: async () => {
              await this.selectInvoice(rowInfo.original);
            },
            style: {
              cursor: 'pointer',
            },
          };
        }
        return {};
      }}
    />
  );

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 mt-4">
      <label htmlFor={label}>{label}</label>
      <div className="col">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  resetProduct = async () => {
    await this.setState({
      productCode: '',
      description: '',
      descriptionFull: '',
      unitMeasure: '',
      ean: '',
      defaultAddress: '',
      suggestedAddress: '',
      emptyAddressesOptions: [],
      quantity: '',
      damageCount: 0,
      curve: '',
      netWeight: 0,
      grossWeight: 0,
      divergenceNote: '',
      height: 0,
      width: 0,
      length: 0,
      requestWeightDimensions: true,
      mandatoryWeightDimensions: true,
      partialInboundCheck: false,
      damageCountCheck: false,
      partialCheck: false,
      volume: 0,
      baptism: null,
      lots: [],
      stickerQty: 0,
      currentProduct: null,
    });
  };

  setProduct = async (product) => {
    const {
      productCode, name, unitMeasure, netWeight, grossWeight, height,
      fullName, width, length, volume,
    } = product.product;

    const {
      curve, requestWeightDimensions, mandatoryWeightDimensions, partialInboundCheck, defaultAddress,
      crossDockingQuantity, crossDockingOrder, note,
    } = product;

    await this.setState({
      productCode,
      description: name,
      descriptionFull: fullName,
      unitMeasure,
      curve,
      defaultAddress,
      netWeight: parseFloat(netWeight),
      grossWeight: parseFloat(grossWeight),
      height: parseFloat(height),
      width: parseFloat(width),
      length: parseFloat(length),
      volume: parseFloat(volume),
      requestWeightDimensions,
      mandatoryWeightDimensions,
      partialInboundCheck,
      crossDockingQuantity,
      crossDockingOrder,
      productNote: note,
    });
  };

  goToElement = (e, id, attr) => {
    const { preStorageStage, requestWeightDimensions } = this.state;
    const { serialControlDeposit } = this.props;
    if (e.keyCode === 13) {
      if (attr === 'baptism' && !requestWeightDimensions) {
        if (!serialControlDeposit) {
          document.getElementById('bt-10').focus();
          document.getElementById('bt-10').select();
        } else if (preStorageStage) {
          document.getElementById(12).focus();
          document.getElementById(12).select();
        }
      } else if (attr === 'length') {
        if (!serialControlDeposit) {
          document.getElementById('bt-10').focus();
          document.getElementById('bt-10').select();
        } else if (preStorageStage) {
          document.getElementById(12).focus();
          document.getElementById(12).select();
        }
      } else if (attr === 'stickerQty') {
        document.getElementById('bt-11').focus();
        document.getElementById('bt-11').select();
      } else {
        if (attr === 'ean') {
          this.scanEan();
        }
        document.getElementById(id + 1).focus();
        document.getElementById(id + 1).select();
      }
    }
  };

  setValue = async (attr, value, reset) => {
    await this.setState({
      [`${attr}`]: value,
    });
    if (attr === 'ean' && reset) {
      this.resetProduct();
    }
  };

  resetForm = () => {
    this.setState({
      invoice: '',
      invoiceId: null,
      supplierName: '',
      total: 0,
      check: 0,
      damageCount: 0,
      branchCode: null,
      currentProduct: null,
      lots: [],
      ean: '',
      quantity: 0,
      defaultAddress: '',
      suggestedAddress: '',
      emptyAddressesOptions: [],
      productCode: '',
      unitMeasure: '',
      baptism: null,
      curve: '',
      netWeight: 0,
      grossWeight: 0,
      height: 0,
      width: 0,
      length: 0,
      mandatoryWeightDimensions: true,
      requestWeightDimensions: true,
      showDamagedModal: false,
      damageCountCheck: false,
      partialCheck: false,
      partialInboundCheck: false,
      volume: 0,
      description: '',
      descriptionFull: '',
      stickerQty: 0,
      showForm: false,
      showLotControlForm: false,
      divergenceNote: '',
      invoiceProductsList: [],
      invoiceNote: '',
      productNote: '',
      receiveLeftover: false,
      orderType: '',
    });
  };

  calculateVolume = () => {
    const { width, length, height } = this.state;

    const floatWidth = parseFloat(width);
    const floatLength = parseFloat(length);
    const floatHeight = parseFloat(height);

    if (typeof floatWidth === 'number' && typeof floatLength === 'number' && typeof floatHeight === 'number') {
      return (floatWidth * floatLength * floatHeight).toFixed(3).replace('.', ',');
    }
    return 'N/D';
  };

  selectInvoice = async (invoice) => {
    this.setState(
      {
        invoice: invoice.orderNumber,
        invoiceId: invoice.id,
        showInvoiceSearch: false,
      },
      this.detailInvoice,
    );
  };

  detailInvoice = async () => {
    const {
      invoice, invoiceId,
    } = this.state;

    try {
      if (invoiceId) {
        const invoiceData = await this.props.getInboundCheckInvoice(invoiceId);

        const product0 = invoiceData.products.find((p) => parseFloat(p.status) === 1);
        if (!product0) {
          this.setState({ showFinishCheckModal: true });

          this.resetForm();
          return;
        }

        this.setState({
          showForm: true,
          invoice: invoiceData.orderNumber,
          branchCode: invoiceData.branchCode,
          receiveLeftover: invoiceData.receiveLeftover,
          supplierName: invoiceData.supplierName,
          total: invoiceData.products.length,
          check: invoiceData.products.filter((p) => parseFloat(p.status) === 1).length || 0,
          invoiceProductsList: invoiceData.products,
          invoiceNote: invoiceData.note,
          prefix: invoiceData.prefix,
          preStorageStage: invoiceData.preStorageStage,
          orderType: invoiceData.orderType,
        });
        if (product0) await this.getSelectedProduct(product0);

        if (invoiceData && invoiceData.note.length > 0) await this.setValue('showInvoiceNoteModal', true);
      } else {
        if (invoice === '') { return; }
        const invoiceData = await this.props.getInboundCheckInvoiceByNumber(invoice);

        if (invoiceData.length === 1) {
          const invoiceId = invoiceData[0].id;
          this.setState({
            invoiceId,
          }, this.detailInvoice);

          return;
        }

        if (invoiceData.length > 1) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE977', { 0: invoice } /* Mais de uma NF %{0} localizada ! */),
            'top-right',
          );
          return;
        }
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE978', { 0: invoice } /* NF %{0} não localizada ! */),
          'top-right',
        );
        return;
      }
      document.getElementById(0).focus();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE1631' /* Erro Conferência */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE1631' /* Erro Conferência */),
          'top-right',
        );
      }
      this.resetForm();
    }
  };

  getProductByEan = async () => {
    const {
      ean, invoiceId, prefix, branchCode, orderType,
    } = this.state;
    const { serialControlDeposit } = this.props;

    const vCodEan = ean;
    let serialGroupCod = null;
    let serialGroup = {};
    let product = null;
    let addressSuggested = '';
    try {
      // ETIQUETA SERIADA
      if (serialControlDeposit) {
        serialGroupCod = ean;

        serialGroup = await this.props.getSerialGroup(vCodEan, invoiceId);

        if (serialGroup) {
          this.setState({
            codLote: serialGroup.lotNumber,
            dtValidLote: serialGroup.expirationDate,
            serialGroupId: serialGroup.serialGroupId,
            quantity: parseFloat(serialGroup.quantity),
          });

          product = await this.props.getInboundCheckProductByCode(
            `${prefix}${serialGroup.productCode}`,
            invoiceId,
          );
        } else {
          product = await (vCodEan ? this.props.getInboundCheckProductByEan(vCodEan, invoiceId) : null);
        }
      } else {
        product = await (vCodEan ? this.props.getInboundCheckProductByEan(vCodEan, invoiceId) : null);
      }

      if (!product) {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          serialControlDeposit
            ? I18n.t('BEE3170', { 0: `${prefix}${serialGroup.productCode}` })
            /* Produto %{0} não foi encontrado no documento! */
            : I18n.t('BEE979', { 0: serialGroupCod || vCodEan } /* O produto com o EAN %{0} não foi encontrado ! */),
          'top-right',
        );

        await this.resetProduct();
        return;
      }

      await this.setValue('currentProduct', product);

      await this.setProduct(product);

      if (product.product.stockControlType !== 3 && !product.product.controlExpirationDate) {
        addressSuggested = await this.props.getInboundOrderProductAddresses(
          branchCode,
          product.productCode,
          true,
          product.quantity,
          undefined, // Lote
          undefined, // Validade
          orderType,
        );

        if (addressSuggested && addressSuggested.suggestedAddress) {
          await this.setState({ suggestedAddress: addressSuggested.suggestedAddress });
        }
      }

      if (product.crossDockingOrder
        && parseFloat(product.crossDockingQuantity) > 0) await this.setValue('showCrossDockingModal', true);

      if (product.note && product.note.length > 0) await this.setValue('showProductNoteModal', true);

      document.getElementById(1).focus();
    } catch (error) {
      const errorMessage = error.response ? (error.response.data && error.response.data.error) : {};
      if (errorMessage.message) {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          `${errorMessage.code} - ${errorMessage.message || errorMessage.details}`,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE979', { 0: ean } /* O produto com o EAN %{0} não foi encontrado ! */),
          'top-right',
        );
      }

      await this.resetProduct();
    }
  };

  scanEan = async () => {
    const { ean } = this.state;
    if (ean === '') {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE980' /* Código EAN deve ser informado */),
        'top-right',
      );
      await this.resetProduct();
    } else {
      this.getProductByEan();
    }
  };

  getSelectedProductList = async (product) => {
    await this.resetProduct();
    await this.getSelectedProduct(product);
  };

  getSelectedProduct = async (product) => {
    const { invoiceId } = this.state;
    const { productCode } = product;
    const productDetails = await this.props.getInboundCheckProductByCode(productCode, invoiceId);

    await this.setProduct(productDetails);
  };

  getInvoicesByDate = async () => {
    try {
      const { startDate, endDate } = this.state;
      await this.props.getInboundCheckInvoices(startDate, endDate);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE1631' /* Erro Conferência */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE1631' /* Erro Conferência */),
          'top-right',
        );
      }
    }
  };

  setLots = async (lots) => {
    const { currentProduct, branchCode, orderType } = this.state;

    let quantity = 0;
    lots.map((l) => quantity += l.quantity);

    const damageCount = (currentProduct.product.stockControlType === 3 || currentProduct.product.controlExpirationDate)
      ? lots.reduce((acc, item) => acc += item.damageCount, 0)
      : this.state.damageCount;

    if (lots && lots.length > 0) {
      const addressSuggested = await this.props.getInboundOrderProductAddresses(
        branchCode,
        currentProduct.productCode,
        true,
        currentProduct.quantity,
        lots[0].lotNumber || '', // Lote
        lots[0].expDate || '', // Validade
        orderType,
      );

      if (addressSuggested && addressSuggested.suggestedAddress) {
        await this.setState({ suggestedAddress: addressSuggested.suggestedAddress });
      }
    }

    this.setState({
      lots,
      quantity,
      damageCount,
      showLotControlForm: false,
    });
  };

  keypressConfirmCount = (e) => {
    e.keyCode === 13 && this.confirmCount();
  };

  keypressDivergenceNote = (e) => {
    e.keyCode === 13 && this.state.divergenceNote.length > 3 && this.confirmCountSubmit();
  };

  confirmCount = () => {
    const {
      quantity, damageCount, currentProduct, baptism, mandatoryWeightDimensions, preStorageStage,
      grossWeight, netWeight, length, width, height, partialInboundCheck, damageCountCheck, stage,
    } = this.state;

    if (!baptism) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE982' /* É necessário informar o batismo */),
        'top-right',
      );
      return;
    }

    if (preStorageStage && !stage) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE3507', {
          0: I18n.t('BEE3495' /* Stage Pré-Armazenagem */),
        }), /* É necessário informar o endereço do tipo %{0}! */
        'top-right',
      );
      return;
    }

    const weightDimensionsSupplied = netWeight > 0 && grossWeight > 0 && length > 0
      && width > 0 && height > 0;

    if (mandatoryWeightDimensions) {
      if (!weightDimensionsSupplied) {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE983' /* É necessário informar as dimensões e pesos */),

          'top-right',
        );
        return;
      }
    }

    if (parseFloat(damageCount) > 0 && parseFloat(damageCount) > parseFloat(quantity)) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t(
          'BEE3231',
          { 0: damageCount, 1: quantity },
          /* A quantidade danificada %{0} não pode ser maior que a quantidade conferida %{1}. */
        ),
        'top-right',
      );
      return;
    }

    if (parseFloat(damageCount) > 0 && !damageCountCheck) {
      this.setState({
        showDamagedModal: true,
      });
      return;
    }
    this.setState({ damageCountCheck: false });

    const diff = (parseFloat(currentProduct.quantity) - parseFloat(currentProduct.quantityCheck));

    if (parseFloat(quantity) < parseFloat(diff)) {
      if (this.props.serialControlDeposit || partialInboundCheck) {
        this.setState({
          showPartialModal: true,
        });
      } else { this.setState({ showDivergenceModal: true }); }
    } else if (!this.props.serialControlDeposit
      && ((parseFloat(quantity) > parseFloat(diff)) && this.state.receiveLeftover)) {
      this.setState({ showDivergenceModal: true });
    } else if ((parseFloat(quantity) > parseFloat(diff)) && !this.state.receiveLeftover) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1880' /* Quantidade conferida superior a Quantidade disponível */),
        'top-right',
      );
    } else {
      this.confirmCountSubmit();
    }
  };

  confirmDamagedCount = async () => {
    await this.setState({
      showDamagedModal: false,
      damageCountCheck: true,
    });
    this.confirmCount();
  };

  confirmDivergence = () => {
    this.setState({
      showDivergenceModal: false,
    });
    this.confirmCountSubmit();
  };

  confirmPartial = async () => {
    await this.setState({
      showPartialModal: false,
      partialCheck: true,
    });
    this.confirmCountSubmit();
  };

  confirmCountSubmit = async () => {
    const {
      quantity, invoiceId, baptism, lots, productCode, netWeight, grossWeight, volume, currentProduct,
      width, length, height, divergenceNote, suggestedAddress, partialCheck, damageCount, showDivergenceModal,
      codLote, dtValidLote, serialGroupId, stage, preStorageStage,
    } = this.state;

    const inboundOrderProductId = this.state.currentProduct.id;

    if (!this.props.serialControlDeposit && (
      !showDivergenceModal && (currentProduct.product.stockControlType === 3) && lots.length < 1)
    ) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE984' /* É necessário informar os lotes para este produto */),
        'top-right',
      );
      return;
    }

    if (!this.props.serialControlDeposit
      && (!showDivergenceModal && (currentProduct.product.controlExpirationDate) && lots.length < 1)
    ) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE2351' /* É necessário informar a data de validade para este produto */),
        'top-right',
      );
      return;
    }

    try {
      this.setState({ showDivergenceModal: false });

      const lifeDays = currentProduct.product ? (currentProduct.product.lifeDays || 0) : 0;

      const result = await this.props.putConfirmCount(
        inboundOrderProductId,
        quantity,
        damageCount,
        baptism,
        invoiceId,
        this.props.serialControlDeposit ? [{
          damageCount: 0,
          lotNumber: codLote,
          quantity: parseFloat(quantity),
          mfgDate: dtValidLote ? moment(dtValidLote).subtract(lifeDays, 'day').toDate() : null,
          expDate: dtValidLote ? moment(dtValidLote).toDate() : null,
          expirationDivergenceNote: '',
          serialGroupId,
        }] : lots,
        netWeight,
        volume,
        grossWeight,
        width,
        length,
        height,
        divergenceNote,
        suggestedAddress.toLocaleUpperCase(),
        partialCheck,
        serialGroupId,
        preStorageStage ? stage.toLocaleUpperCase() : '',
      );

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE985' /* Erro ao conferir produto */),
          'top-right',
        );
      } else {
        this.resetProduct();

        await this.detailInvoice();

        addNotification(
          'success',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE986', { 0: productCode } /* Conferência do produto %{0} efetuada com sucesso */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE985' /* Erro ao conferir produto */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE985' /* Erro ao conferir produto */),
          'top-right',
        );
      }
    }
  };

  showPrinterDialog = async () => {
    const { printerOptions } = this.state;

    if (!printerOptions || printerOptions.length === 0) {
      addNotification(
        'danger',
        I18n.t('BEE3227' /* Impressão EAN */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
      return;
    }

    if (printerOptions && printerOptions.length > 1) {
      this.setState({
        showPrinterDialog: true,
      });
    } else {
      await this.setState({
        showPrinterDialog: false,
        printerSelected: printerOptions[0].value,
      });

      await this.printEanLabel();
    }
  };

  printEanLabel = () => {
    const { currentProduct, printerSelected } = this.state;

    this.props.printEanLabel(currentProduct.ean, printerSelected);

    addNotification(
      'success',
      I18n.t('BEE3227' /* Impressão EAN */),
      I18n.t('BEE3228' /* Etiquetas enviadas para impressora! */),
      'top-right',
    );

    this.setState({
      showPrinterDialog: false,
    });
  };

  toggleInvoiceSearchModal = async () => {
    const searchInvoices = !this.state.showInvoiceSearch;

    this.setState({ showInvoiceSearch: !this.state.showInvoiceSearch });

    if (searchInvoices) await this.getInvoicesByDate();
  };

  handleDateApplyEvent = async (event) => {
    const startDate = moment(event[0]);
    const endDate = moment(event[1]);

    await this.setState({
      startDate,
      endDate,
    });

    await this.getInvoicesByDate();
  };

  showLotControl = () => {
    const stockControlType = this.state.currentProduct && this.state.currentProduct.product.stockControlType;
    const controlExpirationDate = this.state.currentProduct && this.state.currentProduct.product.controlExpirationDate;

    if (!this.props.serialControlDeposit && (stockControlType === 3 || controlExpirationDate)) {
      document.activeElement.blur();
      this.setState({ showLotControlForm: true });
    }
  };

  goCreateEan = () => {
    const {
      productCode, invoiceId, descriptionFull,
    } = this.state;

    this.setState({
      currentProduct: null,
      ean: '',
    });

    const createEanState = {
      invoiceId,
      productCode,
      descriptionFull,
    };

    this.props.setInboundCheckCreateEanState(createEanState);
    this.props.history.push({
      pathname: ROUTES.EAN_CREATE,
    });
  };

  showAddressSelect = async () => {
    const { branchCode, productCode } = this.state;

    const emptyAddressesOptions = await this.props.getEmptyStorageAddresses(branchCode, productCode);

    this.setState({
      emptyAddressesOptions,
      showAddressSelect: true,
    });
  };

  selectSuggestedAddress = async (suggestedAddress) => {
    this.setState({
      suggestedAddress: suggestedAddress.code,
      showAddressSelect: false,
      emptyAddressesOptions: [],
    });
  };

  render() {
    const {
      invoiceId, invoice, supplierName, total, check, ean, quantity, defaultAddress, crossDockingQuantity,
      crossDockingOrder, productCode, unitMeasure, baptism, description, stickerQty, invoiceProductsList,
      curve, netWeight, grossWeight, height, width, length, divergenceNote, suggestedAddress, invoiceNote, productNote,
    } = this.state;

    const {
      showForm, showLotControlForm, showInvoiceSearch, showDivergenceModal, showAddressSelect, emptyAddressesOptions,
      startDate, endDate, currentRange, currentProduct, requestWeightDimensions, showPartialModal, showFinishCheckModal,
      showPrinterDialog, printerOptions, printerSelected, showDamagedModal, damageCount, showCrossDockingModal,
      showInvoiceNoteModal, showProductNoteModal, preStorageStage, stage,
    } = this.state;

    const { inboundCheckInvoicesList = [] } = this.props;
    const isLotControl = this.state.currentProduct
      && (this.state.currentProduct.product.stockControlType === 3
        || this.state.currentProduct.product.controlExpirationDate);

    const title = I18n.t('BEE37' /* Conferência */);
    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE36' /* Recebimento */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE37' /* Conferência */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
          <WikiHelp wikiPath={ROUTES.INBOUND_CHECK_HELP} />
        </div>
        <div className="row">
          <div className="col-xl-12">
            <form onSubmit={(e) => e.preventDefault()}>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  <div className="row">
                    <div className="col-md-4">
                      {showForm
                        ? this.createInput(
                          invoice,
                          'invoice',
                          I18n.t('BEE443' /* Documento */),
                          I18n.t('BEE1747' /* Informe o documento */),
                          'text',
                          true,
                          true,
                        )
                        : this.createInputButton(
                          invoice,
                          'invoice',
                          I18n.t('BEE443' /* Documento */),
                          I18n.t('BEE1747' /* Informe o documento */),
                          'text',
                          true,
                          false,
                          <i className="fa fa-list-alt" />,
                          this.toggleInvoiceSearchModal,
                          () => { },
                          0,
                        )}
                    </div>
                    <div className="col-md-8">
                      {showForm && this.createInput(
                        supplierName,
                        'supplierName',
                        I18n.t('BEE342' /* Fornecedor */),
                        I18n.t('BEE976' /* Erro ao buscar o nome do fornecedor */),
                        'text',
                        false,
                        true,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      {showForm && this.createInput(
                        total,
                        'total',
                        I18n.t('BEE420' /* Total */),
                        '',
                        'text',
                        true,
                        true,
                      )}
                    </div>
                    <div className="col-md-3">
                      {showForm && this.createInput(
                        check,
                        'check',
                        I18n.t('BEE421' /* Conferirido */),
                        '',
                        'text',
                        false,
                        true,
                      )}
                    </div>
                    <div className="col-md-6">
                      {showForm && this.createInputButton(
                        ean,
                        'ean',
                        this.props.serialControlDeposit
                          ? I18n.t('BEE2978' /* Agrupadora */) : I18n.t('BEE377' /* EAN */),
                        '',
                        'text',
                        false,
                        !!currentProduct,
                        !this.props.serialControlDeposit && productCode !== '' ? <i className="fa fa-plus" /> : null,
                        this.goCreateEan,
                        this.goToElement,
                        0,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className={this.props.serialControlDeposit ? 'col-md-6' : 'col-md-3'}>
                      {showForm && this.createNumberFormat(
                        quantity,
                        'quantity',
                        I18n.t('BEE422' /* Quantidade Conferida */),
                        3,
                        this.goToElement,
                        1,
                        this.props.serialControlDeposit || !currentProduct,
                      )}
                    </div>
                    {!this.props.serialControlDeposit && (
                      <div className="col-md-3">
                        {showForm && this.createNumberFormat(
                          damageCount,
                          'damageCount',
                          I18n.t('BEE1663' /* Quantidade Danificada */),
                          3,
                          this.goToElement,
                          2,
                          !(currentProduct && !isLotControl),
                        )}
                      </div>
                    )}
                    <div className="col-md-6">
                      {showForm && this.createInput(
                        defaultAddress,
                        'defaultAddress',
                        I18n.t('BEE423' /* Endereço Padrão */),
                        I18n.t('BEE976' /* Erro ao buscar o nome do fornecedor */),
                        'text',
                        false,
                        true,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      {showForm && this.createInput(
                        productCode,
                        'productCode',
                        I18n.t('BEE225' /* Produto */),
                        '1234',
                        'text',
                        true,
                        true,
                      )}
                    </div>
                    <div className="col-md-2">
                      {showForm && this.createInput(
                        unitMeasure,
                        'unitMeasure',
                        I18n.t('BEE29' /* Unidades de Medida */),
                        I18n.t('BEE976' /* Erro ao buscar o nome do fornecedor */),
                        'text',
                        false,
                        true,
                      )}
                    </div>
                    <div className="col-md-2">
                      {showForm && this.createInput(
                        curve,
                        'curve',
                        I18n.t('BEE355' /* Curva */),
                        '',
                        'text',
                        false,
                        true,
                      )}
                    </div>
                    <div className="col-md-5">
                      {showForm && this.createNumberFormat(
                        baptism,
                        'baptism',
                        I18n.t('BEE425' /* Batismo */),
                        0,
                        requestWeightDimensions || preStorageStage ? this.goToElement : this.keypressConfirmCount,
                        3,
                        !currentProduct,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      {showForm && this.createInput(
                        description,
                        'description',
                        I18n.t('BEE277' /* Descrição */),
                        I18n.t('BEE976' /* Erro ao buscar o nome do fornecedor */),
                        'text',
                        false,
                        true,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      {showForm && this.createNumberFormat(
                        netWeight,
                        'netWeight',
                        `${I18n.t('BEE563' /* Peso Líquido */)} (${I18n.t('BEE2327' /* G */)})`,
                        0,
                        this.goToElement,
                        4,
                        currentProduct ? !requestWeightDimensions : true,
                      )}
                    </div>
                    <div className="col-md-2">
                      {showForm && this.createNumberFormat(
                        grossWeight,
                        'grossWeight',
                        `${I18n.t('BEE564' /* Peso Bruto */)} (${I18n.t('BEE2327' /* G */)})`,
                        0,
                        this.goToElement,
                        5,
                        currentProduct ? !requestWeightDimensions : true,
                      )}
                    </div>
                    <div className="col-md-2">
                      {showForm && this.createNumberFormat(
                        height,
                        'height',
                        `${I18n.t('BEE386' /* Altura */)} (${I18n.t('BEE2325' /* cm */)})`,
                        0,
                        this.goToElement,
                        6,
                        currentProduct ? !requestWeightDimensions : true,
                      )}
                    </div>
                    <div className="col-md-2">
                      {showForm && this.createNumberFormat(
                        width,
                        'width',
                        `${I18n.t('BEE387' /* Largura */)} (${I18n.t('BEE2325' /* cm */)})`,
                        0,
                        this.goToElement,
                        7,
                        currentProduct ? !requestWeightDimensions : true,
                      )}
                    </div>
                    <div className="col-md-2">
                      {showForm && this.createNumberFormat(
                        length,
                        'length',
                        `${I18n.t('BEE388' /* Comprimento */)} (${I18n.t('BEE2325' /* cm */)})`,
                        0,
                        preStorageStage ? this.goToElement : this.keypressConfirmCount,
                        8,
                        currentProduct ? !requestWeightDimensions : true,
                      )}
                    </div>
                    <div className="col-md-2">
                      {showForm && this.createNumberFormat(
                        this.calculateVolume(),
                        'volume',
                        `${I18n.t('BEE562' /* Volume */)} (${I18n.t('BEE2326' /* cm³ */)})`,
                        0,
                        null,
                        9,
                        true,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    {!this.props.serialControlDeposit && (
                      <div className="col-3">
                        {showForm && currentProduct && this.createInputButton(
                          stickerQty,
                          'stickerQty',
                          I18n.t('BEE426' /* Qtde Etiquetas */),
                          I18n.t('BEE989' /* Informe a quantidade de etiquetas */),
                          'number',
                          false,
                          !currentProduct,
                          <i className="fa fa-print" />,
                          this.showPrinterDialog,
                          this.goToElement,
                          10,
                        )}
                      </div>
                    )}
                    <div className="col-3">
                      {showForm && currentProduct && this.createInputButton(
                        suggestedAddress.toLocaleUpperCase(),
                        'suggestedAddress',
                        I18n.t('BEE566' /* Endereço Sugerido */),
                        I18n.t('BEE990' /* Informe o endereço sugerido */),
                        'text',
                        false,
                        !currentProduct,
                        <i className="fa fa-list-alt" />,
                        this.showAddressSelect,
                        preStorageStage ? this.goToElement : () => {},
                        11,
                      )}
                    </div>
                    <div className="col-3">
                      {showForm && currentProduct && this.createInput(
                        description,
                        'description',
                        I18n.t('BEE277' /* Descrição */),
                        I18n.t('BEE976' /* Erro ao buscar o nome do fornecedor */),
                        'text',
                        false,
                        true,
                      )}
                    </div>
                    <div className="col-2">
                      {showForm && currentProduct && preStorageStage && this.createInput(
                        stage.toLocaleUpperCase(),
                        'stage',
                        I18n.t('BEE3506' /* Stage */),
                        I18n.t('BEE3506' /* Stage */),
                        'text',
                        true,
                        false,
                        this.keypressConfirmCount,
                        12,
                      )}
                    </div>
                  </div>
                </PanelBody>
                <PanelFooter>
                  <div className="row">
                    <div className="col text-right">
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-white p-5 m-5"
                        onClick={() => this.resetForm()}
                      >
                        {showForm
                          ? I18n.t('BEE437' /* Busca outra NF */)
                          : I18n.t('BEE99' /* Cancelar */)}
                      </OneClickButton>
                      {(!showForm
                        && (
                          <OneClickButton
                            type="submit"
                            className="btn btn-120 btn-primary p-5 m-5"
                            onClick={() => this.detailInvoice()}
                          >
                            {I18n.t('BEE407' /* Buscar */)}

                          </OneClickButton>
                        )
                      )}
                      {(showForm
                        && (
                          <OneClickButton
                            type="button"
                            className="btn btn-120 btn-primary p-5 m-5"
                            onClick={() => this.confirmCount()}
                          >
                            {I18n.t('BEE100' /* Confirmar */)}
                          </OneClickButton>
                        )
                      )}
                    </div>
                  </div>
                </PanelFooter>

                <Modal size="xl" isOpen={showInvoiceSearch} toggle={() => this.toggleInvoiceSearchModal()}>
                  <ModalHeader toggle={() => this.toggleInvoiceSearchModal()}>
                    {I18n.t('BEE1440' /* Buscar Documento */)}
                  </ModalHeader>
                  <ModalBody>
                    <div className="p-b-15">
                      {this.createTable(startDate, endDate, currentRange, inboundCheckInvoicesList)}
                    </div>
                  </ModalBody>
                </Modal>

                <Modal size="md" isOpen={showAddressSelect} toggle={() => this.setState({ showAddressSelect: false })}>
                  <ModalHeader toggle={() => this.setState({ showAddressSelect: false })}>
                    {I18n.t('BEE566' /* Endereço Sugerido */)}
                  </ModalHeader>
                  <ModalBody>
                    <AddressSelectForm
                      emptyAddressesOptions={emptyAddressesOptions}
                      selectSuggestedAddress={this.selectSuggestedAddress}
                    />
                  </ModalBody>
                </Modal>

                <Modal
                  size="xl"
                  isOpen={showLotControlForm}
                  toggle={() => this.setState({ showLotControlForm: false })}
                >
                  <ModalHeader toggle={() => this.setState({ showLotControlForm: false })}>
                    {I18n.t('BEE491' /* Conferência de Lote */)}
                  </ModalHeader>
                  <ModalBody>
                    <LotControlForm
                      fractionalQuantity={this.props.fractionalQuantity}
                      product={this.state.currentProduct}
                      lots={this.state.lots}
                      funcSetLots={this.setLots.bind(this)}
                    />
                  </ModalBody>
                </Modal>

              </Panel>
              {invoiceId && (
                <Panel>
                  <PanelBody>
                    <ProductsList
                      products={invoiceProductsList}
                      getSelectedProduct={this.getSelectedProductList}
                    />
                  </PanelBody>
                </Panel>
              )}
            </form>
            {(showDivergenceModal
              && (
                <SweetAlert
                  danger
                  showCancel
                  cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
                  confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="default"
                  title={I18n.t('BEE101' /* Você tem certeza? */)}
                  onConfirm={() => this.confirmDivergence()}
                  onCancel={() => this.setState({ showDivergenceModal: false })}
                >
                  <div>
                    <span>{I18n.t('BEE993' /* Divergência de quantidades ! */)}</span>
                    {this.createTextArea(
                      divergenceNote,
                      'divergenceNote',
                      `${I18n.t('BEE724' /* Motivo */)}:`,
                      I18n.t('BEE991' /* Informe o motivo da divergência */),
                      3,
                      true,
                      false,
                    )}
                  </div>
                </SweetAlert>
              )
            )}
            {(showDamagedModal
              && (
                <SweetAlert
                  danger
                  showCancel
                  cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
                  confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="default"
                  title={I18n.t('BEE101' /* Você tem certeza? */)}
                  onConfirm={() => this.confirmDamagedCount()}
                  onCancel={() => this.setState({ showDamagedModal: false })}
                >
                  <div>
                    {I18n.t('BEE1662', { 0: damageCount } /* Você confirma a quantidade de %{0} danificados? */)}
                  </div>
                  {(isLotControl
                    && (
                      <div>
                        {
                          I18n.t(
                            'BEE1666',
                            /* Cada lote com, pelo menos, um item danificado será considerado totalmente danificado! */
                          )
                        }
                      </div>
                    )
                  )}
                </SweetAlert>
              )
            )}
            {(showPartialModal
              && (
                <SweetAlert
                  warning
                  showCancel
                  cancelBtnText={I18n.t('BEE173' /* Não */)}
                  confirmBtnText={I18n.t('BEE172' /* Sim */)}
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="default"
                  title={I18n.t('BEE992' /* Confirma conferência parcial ? */)}
                  onConfirm={() => this.confirmPartial()}
                  onCancel={() => this.setState({
                    showDivergenceModal: true,
                    showPartialModal: false,
                    partialCheck: false,
                  })}
                >
                  {I18n.t('BEE993' /* Divergência de quantidades ! */)}
                </SweetAlert>
              )
            )}
            {(showFinishCheckModal
              && (
                <SweetAlert
                  info
                  confirmBtnText={I18n.t('BEE1484' /* OK */)}
                  confirmBtnBsStyle="info"
                  title={I18n.t('BEE1874' /* Conferência Finalizada! */)}
                  onConfirm={() => this.setState({
                    showFinishCheckModal: false,
                  })}
                >
                  {I18n.t('BEE3202' /* Todos os produtos do documento foram conferidos! */)}
                </SweetAlert>
              )
            )}
            {(showPrinterDialog
              && (
                <SweetAlert
                  confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
                  confirmBtnBsStyle="primary"
                  cancelBtnBsStyle="default"
                  title={I18n.t('BEE1324' /* Selecionar Impressora */)}
                  onConfirm={() => this.printEanLabel()}
                  onCancel={() => this.setState({
                    showPrinterDialog: false,
                  })}
                >
                  <div className="mt-4">
                    {this.createSelect(
                      printerSelected,
                      'printerSelected',
                      I18n.t('BEE328' /* Impressora */),
                      printerOptions,
                    )}
                  </div>
                </SweetAlert>
              )
            )}
            {(showCrossDockingModal
              && (
                <SweetAlert
                  info
                  confirmBtnText={I18n.t('BEE1484' /* OK */)}
                  confirmBtnBsStyle="info"
                  title={I18n.t('BEE3498' /* Produto Cross Docking! */)}
                  onConfirm={() => this.setState({
                    showCrossDockingModal: false,
                  })}
                >
                  {
                    I18n.t(
                      'BEE3241',
                      {
                        0: crossDockingOrder,
                        1: crossDockingQuantity,
                      }, /* Produto com Pedido Cross Docking %{0}. Quantidade: %{1} */
                    )
                  }
                </SweetAlert>
              )
            )}
            {(showInvoiceNoteModal
              && (
                <SweetAlert
                  info
                  confirmBtnText={I18n.t('BEE1484' /* OK */)}
                  confirmBtnBsStyle="info"
                  title={I18n.t('BEE1061' /* Atenção */)}
                  onConfirm={() => this.setState({
                    showInvoiceNoteModal: false,
                  })}
                >
                  {invoiceNote}
                </SweetAlert>
              )
            )}
            {(showProductNoteModal
              && (
                <SweetAlert
                  info
                  confirmBtnText={I18n.t('BEE1484' /* OK */)}
                  confirmBtnBsStyle="info"
                  title={I18n.t('BEE1061' /* Atenção */)}
                  onConfirm={() => this.setState({
                    showProductNoteModal: false,
                  })}
                >
                  {productNote}
                </SweetAlert>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  inboundCheckInvoicesList: state.inboundCheck && state.inboundCheck.inboundCheckInvoicesList,
  createEanState: state.inboundCheck && state.inboundCheck.createEanState,
  fractionalQuantity: state.app.permissionsCompany && state.app.permissionsCompany.fractionalQuantity,
  serialControlDeposit: state.app.permissionsCompany
    && state.app.permissionsCompany.serialControlDeposit
    && state.app.permissionsCompany.serialDeposits.depPreReceipt
    && state.app.permissionsCompany.serialDeposits.depInbound,
});

const mapDispatchToProps = (dispatch) => ({
  setInboundCheckCreateEanState: (
    createEanProductCode,
  ) => dispatch(setInboundCheckCreateEanState(createEanProductCode)),
  getInboundCheckInvoices: (
    startDate,
    endDate,
  ) => dispatch(getInboundCheckInvoices(startDate, endDate)),
  getInboundCheckInvoice: (invoiceId) => dispatch(getInboundCheckInvoice(invoiceId)),
  getInboundCheckInvoiceByNumber: (orderNumber) => dispatch(getInboundCheckInvoiceByNumber(orderNumber)),
  getInboundCheckProductByEan: (ean, invoiceId) => dispatch(getInboundCheckProductByEan(ean, invoiceId)),
  getSerialGroup: (barCode, inboundOrderId) => dispatch(getSerialGroup(barCode, inboundOrderId)),
  getInboundCheckProductByCode: (
    productCode,
    invoiceId,
  ) => dispatch(getInboundCheckProductByCode(productCode, invoiceId)),
  getEmptyStorageAddresses: (
    branchCode,
    productCode,
  ) => dispatch(getEmptyStorageAddresses(branchCode, productCode, true)),
  putConfirmCount: (
    inboundOrderProductId,
    count,
    damageCount,
    baptism,
    invoiceId,
    lots,
    netWeight,
    volume,
    grossWeight,
    width,
    length,
    height,
    divergenceNote,
    suggestedAddress,
    partialCheck,
    serialGroupId,
    stage,
  ) => dispatch(putConfirmCount(
    inboundOrderProductId,
    count,
    damageCount,
    baptism,
    invoiceId,
    lots,
    netWeight,
    volume,
    grossWeight,
    width,
    length,
    height,
    divergenceNote,
    suggestedAddress,
    partialCheck,
    serialGroupId,
    stage,
  )),
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  printEanLabel: (eanCode, printer) => dispatch(printEanLabel(eanCode, printer)),
  getInboundOrderProductAddresses: (
    branchCode,
    productCode,
    filterStatus,
    quantity,
    lotNumber,
    expirationDate,
    orderType,
  ) => dispatch(getInboundOrderProductAddresses(
    branchCode,
    productCode,
    filterStatus,
    quantity,
    lotNumber,
    expirationDate,
    orderType,
  )),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InboundCheckForm));
