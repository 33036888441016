import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import OneClickButton from '../form/button';
import { getVersionApp } from '../../app/store/actions/settings';
import pkg from '../../../package.json';
import auth from '../../app/services/auth';

// eslint-disable-next-line react/prefer-stateless-function
class ShowAboutSystem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      versionBee: '',
    };
  }

  componentDidMount() {
    if (auth.isAuthenticated()) {
      this.getSettings();
    }
  }

  getSettings = async () => {
    const response = await this.props.getVersionApp();
    this.setState({ versionBee: response });
  };

  render() {
    const { versionBee = '' } = this.state;
    const { isOpen = false } = this.props;
    return (
      <Modal size="md" isOpen={isOpen}>
        <ModalHeader data-testid="about-modal-header">
          {I18n.t('BEE2617' /* Sobre */)}
        </ModalHeader>
        <ModalBody>
          <div className="col-md-12">
            <div style={{ fontSize: '1.125rem' }} className="text-center">
              <strong>{I18n.t('BEE2627' /* Versões BeeStock */)}</strong>
            </div>
            <div style={{ fontSize: '0.95rem' }}>
              <p className="col-md-12 mt-3">
                <strong>
                  {`${I18n.t('BEE2618' /* Frontend */)} `}
                </strong>
                {pkg.version}
              </p>
            </div>
            <div style={{ fontSize: '0.95rem' }}>
              <p className="col-md-12">
                <strong>
                  {`${I18n.t('BEE2619' /* Backend */)} `}
                </strong>
                {`${versionBee.versionBackend}`}
              </p>
            </div>
            <div style={{ fontSize: '0.95rem' }}>
              <p className="col-md-12">
                <strong>{`${I18n.t('BEE2620' /* App */)} `}</strong>
                {`${versionBee.versionApp}`}
              </p>
            </div>
            <div style={{ fontSize: '1.125rem' }} className="text-center">
              <strong>{I18n.t('BEE2621' /* Contatos SZ Soluções */)}</strong>
            </div>
            <div style={{ fontSize: '0.95rem' }} className="col-md-12 mt-3">
              <div>
                <a href="mailto:suporte@szsolucoes.com.br">
                  {I18n.t('BEE2623' /* suporte@szsolucoes.com.br */)}
                </a>
              </div>
            </div>
            <div style={{ fontSize: '1.125rem' }} className="text-center mt-3">
              <strong>{I18n.t('BEE2622' /* Ajuda */)}</strong>
            </div>
            <div style={{ fontSize: '0.95rem' }} className="mt-1 col-md-12">
              <a href="https://szsolucoes.soft4.com.br/login" target="_blank" rel="noreferrer">
                {I18n.t('BEE2625' /* Abertura de chamados */)}
              </a>
            </div>
            <div style={{ fontSize: '0.95rem' }} className="col-md-12">
              <a href="http://wiki.szsolucoes.com.br/wiki/doku.php/pagina_inicial" target="_blank" rel="noreferrer">
                {I18n.t('BEE2626' /* Wiki SZ Soluções */)}
              </a>
            </div>
          </div>
          <p className="text-center text-grey-darker mt-5">
            &copy;
            {` ${I18n.t('BEE1078' /* SZ Soluções Todos os direitos reservados 2020 */)}`}
          </p>
        </ModalBody>
        <ModalFooter>
          <div className="row">
            <div className="col text-right">
              <OneClickButton
                className="btn btn-white btn-120 p-5 "
                onClick={this.props.toClose}
              >
                {I18n.t('BEE553' /* Fechar */)}
              </OneClickButton>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getVersionApp: () => dispatch(getVersionApp()),
});

export default connect(undefined, mapDispatchToProps)(withRouter(ShowAboutSystem));
