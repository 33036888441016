import UserFunctionsRequests from '../../server/userFunctions';
import { addLoading, removeLoading } from './loading';

export const setUserFunctions = userFunctions => ({
  type: 'SET_USER_FUNCTIONS_LIST',
  userFunctions,
});

export const createUserFunction = userFunction => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newUserFunction = await UserFunctionsRequests.createUserFunction(userFunction);
    return newUserFunction;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateUserFunction = (userFunction) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UserFunctionsRequests.updateUserFunction(userFunction);
  } finally {
    dispatch(removeLoading());
  }
}

export const getUserFunction = (userFunctionId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UserFunctionsRequests.getUserFunction(userFunctionId);
  } finally {
    dispatch(removeLoading());
  }
}

export const getUserFunctionsList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const userFunctions = await UserFunctionsRequests.getUserFunctions();
    dispatch(setUserFunctions(userFunctions));
  } finally {
    dispatch(removeLoading());
  }
};

export const getUserFunctionsOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UserFunctionsRequests.getUserFunctionsOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteUserFunction = (userFunctionId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UserFunctionsRequests.deleteUserFunction(userFunctionId);
  } finally {
    dispatch(removeLoading());
  }
}