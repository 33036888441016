const INITIAL_STATE = {
  inboundCheckInvoicesList: [],
  createEanState: null,
  receivesExpired: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_INBOUND_CHECK_INVOICES': {
      return { ...state, inboundCheckInvoicesList: action.inboundCheckInvoices };
    }
    case 'SET_CREATE_EAN_STATE': {
      return { ...state, createEanState: action.createEanState };
    }
    case 'SET_RECEIVES_EXPIRED': {
      return { ...state, receivesExpired: action.receivesExpired };
    }
    default:
      return state;
  }
};
