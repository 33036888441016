import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import NumberFormat from 'react-number-format';

import OneClickButton from '../../../../components/form/button';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createPackage, getPackage, updatePackage,
} from '../../../../app/store/actions/packages';
import Form from '../../../../components/form/form';

class PackageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      weight: 0,
      height: 0,
      width: 0,
      length: 0,
      note: '',
      disableCode: true,
      disableName: true,
      disableWeight: true,
      disableHeight: true,
      disableWidth: true,
      disableLength: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getPackageSelected();
    }
  }

  getPackageSelected = async () => {
    if (this.props.location.state.package) {
      const pckg = this.props.location.state.package;

      const packageSelected = await this.props.getPackage(pckg.id);

      if (packageSelected) {
        this.setState({
          // packageSelected,
          packageId: packageSelected.id,
          code: packageSelected.code,
          name: packageSelected.name,
          weight: parseFloat(packageSelected.weight),
          height: parseFloat(packageSelected.height),
          width: parseFloat(packageSelected.width),
          length: parseFloat(packageSelected.length),
          note: packageSelected.note,
        });
      }
    }
  };

  setMode = () => {
    let mode;

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableWeight: false,
        disableHeight: false,
        disableWidth: false,
        disableLength: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableWeight: false,
        disableHeight: false,
        disableWidth: false,
        disableLength: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createNumberFormat = (value, attr, label, decimals, required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <NumberFormat
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={decimals}
          value={(value === 0 || value) ? value : ''}
          onValueChange={(values) => {
            this.setValue(attr, values.floatValue);
          }}
          fixedDecimalScale
          defaultValue={0}
          allowEmptyFormatting
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  createPackageSubmit = async () => {
    const {
      code, name, weight, height, width, length, note,
    } = this.state;

    try {
      const newPackage = await this.props.createPackage({
        code,
        name,
        weight,
        height,
        width,
        length,
        note,
      });

      if (newPackage) {
        if (newPackage.success && newPackage.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE841' /* Nova embalagem */),
            I18n.t('BEE842', { 0: code } /* Erro ao incluir a embalagem %{0} ! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE841' /* Nova embalagem */),
            I18n.t('BEE843', { 0: code } /* Nova embalagem %{0} incluída com sucesso ! */),
            'top-right',
          );

          this.props.history.push(ROUTES.PACKAGE_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE841' /* Nova embalagem */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE841' /* Nova embalagem */),
            I18n.t('BEE842', { 0: code } /* Erro ao incluir a embalagem %{0} ! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE841' /* Nova embalagem */),
          I18n.t('BEE842', { 0: code } /* Erro ao incluir a embalagem %{0} ! */),
          'top-right',
        );
      }
    }
  };

  updatePackageSubmit = async () => {
    const {
      packageId, code, name, weight, height,
      width, length, note,
    } = this.state;

    try {
      const updPackage = await this.props.updatePackage({
        packageId,
        name,
        weight,
        height,
        width,
        length,
        note,
      });

      if (updPackage) {
        if (updPackage.success && updPackage.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE844' /* Atualizar embalagem */),
            I18n.t('BEE845', { 0: code } /* Erro ao atualizar a embalagem %{0} ! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE844' /* Atualizar embalagem */),
            I18n.t('BEE846', { 0: code } /* Atualização da embalagem %{0} efetuada com sucesso ! */),
            'top-right',
          );

          this.props.history.push(ROUTES.PACKAGE_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE844' /* Atualizar embalagem */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE844' /* Atualizar embalagem */),
            I18n.t('BEE845', { 0: code } /* Erro ao atualizar a embalagem %{0} ! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE844' /* Atualizar embalagem */),
          I18n.t('BEE845', { 0: code } /* Erro ao atualizar a embalagem %{0} ! */),
          'top-right',
        );
      }
    }
  };

  render() {
    let {
      weight, height, width, length,
    } = this.state;

    const { code, name, note } = this.state;

    const {
      mode, disableCode, disableName, disableWeight, disableHeight,
      disableWidth, disableLength, disableNote, showCancel, showUpdate, showCreate,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE841' /* Nova embalagem */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE848' /* Editar embalagem */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE847' /* Detalhes embalagem */);
    }

    if (mode === 'detail') {
      weight = parseFloat(weight).toFixed(3).replace('.', ',');
      height = parseFloat(height).toFixed(3).replace('.', ',');
      width = parseFloat(width).toFixed(3).replace('.', ',');
      length = parseFloat(length).toFixed(3).replace('.', ',');
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE28' /* Embalagens */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE841' /* Nova embalagem */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  <Form
                    noPanel
                    setValue={this.setValue}
                    inputs={(formContext) => ([
                      formContext.createInput(
                        code,
                        'code',
                        `${I18n.t('BEE82' /* Código */)}:`,
                        I18n.t('BEE3707' /* Informe a impressora */),
                        'text',
                        true,
                        disableCode,
                      ),
                      formContext.createInput(
                        name,
                        'name',
                        `${I18n.t('BEE277' /* Descrição */)}:`,
                        I18n.t('BEE3477' /* Informe a descrição */),
                        'text',
                        true,
                        disableName,
                      ),
                      formContext.createNumberFormat(
                        weight,
                        'weight',
                        `${I18n.t('BEE579' /* Peso */)}: (${I18n.t('BEE2327' /* G */)})`,
                        0,
                        disableWeight,
                      ),
                      formContext.createNumberFormat(
                        height,
                        'height',
                        `${I18n.t('BEE386' /* Altura */)}: (${I18n.t('BEE2325' /* cm */)})`,
                        0,
                        disableHeight,
                      ),
                      formContext.createNumberFormat(
                        width,
                        'width',
                        `${I18n.t('BEE387' /* Largura */)}: (${I18n.t('BEE2325' /* cm */)})`,
                        0,
                        disableWidth,
                      ),
                      formContext.createNumberFormat(
                        length,
                        'length',
                        `${I18n.t('BEE388' /* Comprimento */)}: (${I18n.t('BEE2325' /* cm */)})`,
                        0,
                        disableLength,
                      ),
                      formContext.createInput(
                        note,
                        'note',
                        `${I18n.t('BEE135' /* Observação */)}:`,
                        I18n.t('BEE136' /* Informe a observação */),
                        'text',
                        false,
                        disableNote,
                      ),
                    ])}
                  />
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.PACKAGE_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                          {I18n.t('BEE137' /* Voltar */)}
                        </OneClickButton>
                      ))}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createPackageSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}
                    </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updatePackageSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}
                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getPackage: (packageId) => dispatch(getPackage(packageId)),
  createPackage: (pckg) => dispatch(createPackage(pckg)),
  updatePackage: (pckg) => dispatch(updatePackage(pckg)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PackageForm));
