import CustomerBranchRequests from '../../server/customerBranch';
import { addLoading, removeLoading } from './loading';

export const setCustomerBranchesList = (customerBranches) => ({
  type: 'SET_CUSTOMER_BRANCHES',
  customerBranches,
});

export const getCustomerOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CustomerBranchRequests.getCustomerOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const getBranchOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CustomerBranchRequests.getBranchOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const customerBranchCreate = (parametersForCreation) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CustomerBranchRequests.customerBranchCreate(parametersForCreation);
  } finally {
    dispatch(removeLoading());
  }
};

export const getCustomerBranchesList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const customerBranches = await CustomerBranchRequests.getCustomerBranchesList(tableParams);
    dispatch(setCustomerBranchesList(customerBranches ? customerBranches.rows : []));
    dispatch(removeLoading());
    return customerBranches || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const customerBranchDelete = (customerBranchId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CustomerBranchRequests.customerBranchDelete(customerBranchId);
  } finally {
    dispatch(removeLoading());
  }
};

export const customerBranchUpdate = (customerBranchId, customerCode, branchCode, restriction, note) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CustomerBranchRequests.customerBranchUpdate(customerBranchId, customerCode, branchCode, restriction, note);
  } finally {
    dispatch(removeLoading());
  }
};
