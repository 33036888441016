import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

import Table from '../../../components/table/Table';

export default class AllocationTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableAllocationColumns = [
      {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 90,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE267' /* Endereço */),
        accessor: 'addressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE515' /* Saldo */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      }, {
        Header: I18n.t('BEE1431' /* Alocado */),
        accessor: 'allocated',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      }, {
        Header: I18n.t('BEE530' /* Disponível */),
        accessor: 'availableQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      },
    ];
  }

  render() {
    const { allocationProductList = [] } = this.props;
    const { allocationSelect } = this.props;

    return (
      <span>
        <Table
          panelHeaderProps={{
            noButton: true,
            children: I18n.t('BEE2009' /* Alocação */),
          }}
          filterable
          data={allocationProductList}
          columns={this.tableAllocationColumns}
          expander
          defaultPageSize={99}
          style={{
            height: '260px', // This will force the table body to overflow and scroll, since there is not enough room
          }}
          className="-striped -highlight"
          showPagination={false}
          // pageSizeOptions={[5, 10]}
          defaultSorted={[{ id: 'storageAddress', desc: false }]}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  this.props.setAllocationSelect(rowInfo.original);
                },
                style: {
                  background: allocationSelect && rowInfo.original.id === allocationSelect.id ? 'silver' : 'white',
                  cursor: 'pointer',
                },
              };
            }
            return {};
          }}
        />
      </span>
    );
  }
}
