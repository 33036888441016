import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { getUsesIndustryProcess } from '../../../app/store/actions/typeOrder';
import Table from '../../../components/table/Table';

class ModalOutboundOrder extends React.Component {
  constructor(props) {
    super(props);
    this.tableColumns = [
      {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'type',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      },
    ];
    this.state = {
      defaultPageSize: 5,
      pageSizeOptions: [5, 10],
      defaultSorted: [{ id: 'code', desc: false }],
      selectOutboundOrder: {},
      isClose: false,
    };
  }

  async componentDidMount() {
    await this.props.getUsesIndustryProcess();
  }

  componentDidUpdate(prevProps) {
    if (this.props.openModalOutboundOrder && prevProps.openModalOutboundOrder !== this.props.openModalOutboundOrder) {
      setTimeout(() => {
        document.getElementById('button_select').focus();
      }, 500);
    }
  }

  selectOutboundOrder = (selectOutboundOrder) => {
    this.setState({ selectOutboundOrder });
    this.props.onSelectOutboundOrder(selectOutboundOrder);
    document.getElementById('button_select').focus();
  };

  render() {
    const {
      defaultPageSize, defaultSorted, pageSizeOptions, selectOutboundOrder, isClose,
    } = this.state;

    const {
      openModalOutboundOrder, orderTypeListIndustry = [], type, mainType, isFinishing,
    } = this.props;

    return (
      <Modal size="lg" isOpen={openModalOutboundOrder || isClose} toggle={() => this.props.closeModalOutboundOrder()}>
        {isFinishing
          ? <ModalHeader><span className="text-center">Confirmar Embalagem</span></ModalHeader>
          : <ModalHeader><span className="text-center">{I18n.t('BEE2997' /* Tipo de Documento */)}</span></ModalHeader>}
        <ModalBody>
          <Table
            panelHeaderProps={{ noButton: true }}
            filterable
            expander
            data={orderTypeListIndustry}
            columns={this.tableColumns}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={pageSizeOptions}
            defaultSorted={defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: () => {
                    this.selectOutboundOrder(rowInfo.original);
                  },
                  style: {
                    background: rowInfo.original.id === selectOutboundOrder.id ? 'silver' : 'white',
                    color: 'black',
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
            defaultSortMethod={(a, b) => {
              if (b === mainType && a === type) return 1;
              if (a === mainType || a === type) return -1;
              return 1;
            }}
          />
          <div className="text-right">
            <button
              type="button"
              className="btn btn-120 btn-danger p-5 m-5"
              onClick={() => this.props.closeModalOutboundOrder()}
            >
              {I18n.t('BEE99' /* Cancelar */)}
            </button>
            <button
              id="button_select"
              type="button"
              className="btn btn-120 btn-primary p-5 m-5"
              onClick={() => this.props.selectedOutboundOrderConcussion()}
            >
              {I18n.t('BEE436' /* Selecionar */)}
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  orderTypeListIndustry: state.ordersTypes && state.ordersTypes.orderTypeListIndustry,
});

const mapDispatchToProps = (dispatch) => ({
  getUsesIndustryProcess: () => dispatch(getUsesIndustryProcess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalOutboundOrder));
