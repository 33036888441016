import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  deleteCableCut, finishCableCut, getCableCutProductList, printCableCutLabel, setChangeCutProductList, userChangeCutResponsible,
} from '../../../app/store/actions/cutsMonito';
import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import cableCutHelpers from '../../../helpers/cableCut';

class CableCutsTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableColumns = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE432' /* Endereço */),
        accessor: 'addressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1581' /* Corte */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1897' /* Descrição */),
        accessor: 'productName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE578' /* Data Criação */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE575' /* Usuário Criação */),
        accessor: 'createdUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1850' /* Data Corte */),
        accessor: 'finishCableCutAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1851' /* Usuário Corte */),
        accessor: 'finishCableCutUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE204' /* Situação */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{cableCutHelpers.cableCutStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value) === parseInt(row.status)) {
            return true;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {cableCutHelpers.cableCutStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      },
    ];

    this.page = 0;

    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      defaultPageSize: 5,
      pageSizeOptions: [5, 10],
      defaultSorted: [{ id: 'code', desc: false }],
      selectedCableCut: {},
      showPrinterDialog: false,
      showUserDialog: false,
      totalDataLength: 0,
      showNoticeExecution: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.filterAction && prevProps.filterAction !== this.props.filterAction) {
      this.props.setState({ filterAction: false });
      this.resetSelectedCableCut();
    }
  }

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  createSelect = (value, attr, label, items) => (
    <div className="form-group p-2">
      <label>{label}</label>
      <select
        className="form-control"
        value={value || ''}
        onChange={(e) => this.props.setValue(attr, e.target.value)}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );

  cutsMonitoList = async () => {
    await this.props.setState({
      tableParams: {
        page: this.page,
        pageSize: this.pageSize,
        sorted: this.sorted,
        filtered: this.filtered,
      },

    });

    this.props.cutsMonitoList();
  };

  selecionado = (selectedCableCut) => {
    this.setState({ selectedCableCut });
    this.props.getCableCutProductList(selectedCableCut.id);
  };

  resetSelectedCableCut = async () => {
    const { selectedCableCut } = this.state;
    if (Object.keys(selectedCableCut).length && !this.props.cutsMonitoDice.find((elem) => elem.id === selectedCableCut.id)) {
      this.setState({ selectedCableCut: { ...{} } });
      this.props.setChangeCutProductList([]);
    }
  };

  showPrinterDialog = async () => {
    await this.resetSelectedCableCut();
    const { selectedCableCut } = this.state;
    const { printerOptions } = this.props;
    if (selectedCableCut && selectedCableCut.id) {
      if (!printerOptions || printerOptions.length === 0) {
        addNotification('danger', I18n.t('BEE1793' /* Etiquetas Pré-Conferência */), I18n.t('BEE1415' /* Usuário não possui impressora relacionada */), 'top-right');
      } else if (printerOptions && printerOptions.length > 1) {
        this.setState({ showPrinterDialog: true });
      } else if (printerOptions && printerOptions.length === 1) {
        await this.setState({ printerSelected: printerOptions[0].value });
        this.printLabel();
      }
    } else {
      addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1828' /* nenhum corte selecionado */), 'top-right');
    }
  };

  printLabel = async () => {
    const { selectedCableCut } = this.state;
    const { printerSelected, printerOptions } = this.props;
    this.setState({ showPrinterDialog: false });
    try {
      const result = await this.props.printCableCutLabel(printerSelected || printerOptions[0].value, 'byCutPlan', selectedCableCut.id);
      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1419' /* Monitor de Cortes */),
          I18n.t('BEE1643' /* Falha na impressão! */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE1419' /* Monitor de Cortes */),
          I18n.t('BEE1427' /* Etiquetas enviadas para impressora ! */),
          'top-right',
        );
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.tI18n.t('BEE1419' /* Monitor de Cortes */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1419' /* Monitor de Cortes */),
          I18n.t('BEE1643' /* Falha na impressão! */),
          'top-right',
        );
      }
    }
  };

  validationDeleteCableCut = async () => {
    await this.resetSelectedCableCut();
    const { selectedCableCut } = this.state;

    if (selectedCableCut && selectedCableCut.id) {
      this.setState({ showNoticeExecution: true });
    } else {
      addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1828' /* nenhum corte selecionado */), 'top-right');
    }
  };

  deleteCableCut = async () => {
    this.setState({ showNoticeExecution: false });
    try {
      const { selectedCableCut } = this.state;

      const result = await this.props.deleteCableCut(selectedCableCut.id, '');
      if (result && result.success === false) {
        addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1832' /* não foi possível eliminar o corte */), 'top-right');
      } else {
        addNotification('success', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1830' /* corte eliminado */), 'top-right');
        await this.props.setChangeCutProductList([]);
        await this.props.cutsMonitoList();
        this.setState({ selectedCableCut: {} });
      }
    } catch (error) {
      const messageError = error.response && error.response.data && error.response.data.error && error.response.data.error.message;

      if (messageError) addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), messageError, 'top-right');
      else addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1832' /* não foi possível eliminar o corte */), 'top-right');
    }
  };

  // finishCableCut = async () => {
  //   try {
  //     const { selectedCableCut } = this.state;

  //     if (selectedCableCut && selectedCableCut.id) {
  //       await this.props.finishCableCut('', selectedCableCut.id)

  //       addNotification('success', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1803' /* Corte Finalizado */), 'top-right');

  //       await this.props.getCableCutProductList(selectedCableCut.id)
  //       await this.props.cutsMonitoList()

  //     } else {
  //       addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1828' /* nenhum corte selecionado */), 'top-right');
  //     }
  //   } catch (error) {
  //     const messageError = error.response && error.response.data && error.response.data.error && error.response.data.error.message;
  //     if (messageError) addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), messageError, 'top-right');
  //     else addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE643' /* Erro desconhecido */), 'top-right');
  //   }
  // };

  showUserDialog = async () => {
    await this.resetSelectedCableCut();
    const { selectedCableCut } = this.state;
    if (selectedCableCut && selectedCableCut.id) {
      if (selectedCableCut.addressCode) {
        await this.props.getRangeUserList({
          addressCode: selectedCableCut.addressCode,
          branchCode: selectedCableCut.branchCode,
          finishCableCutUserId: selectedCableCut.finishCableCutUserId,
        });
        const { userOptions } = this.props;
        if (userOptions.length) {
          this.setState({ showUserDialog: true });
        } else {
          addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1836' /* nenhum usuário encontrado */), 'top-right');
        }
      } else {
        addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1836' /* nenhum usuário encontrado */), 'top-right');
      }
    } else {
      addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1828' /* nenhum corte selecionado */), 'top-right');
    }
  };

  userChangeCutResponsible = async () => {
    try {
      const { selectedCableCut } = this.state;
      const { userSelected, userOptions } = this.props;
      await this.props.userChangeCutResponsible(selectedCableCut.id, userSelected || userOptions[0].value);
      await this.props.cutsMonitoList();
      this.setState({ showUserDialog: false });

      addNotification('success', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE1834' /* Usuário atualizado */), 'top-right');

      await this.props.getCableCutProductList(selectedCableCut.id);
      await this.props.cutsMonitoList();
    } catch (error) {
      const messageError = error.response && error.response.data && error.response.data.error && error.response.data.error.message;
      if (messageError) addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), messageError, 'top-right');
      else addNotification('danger', I18n.t('BEE1419' /* Monitor de Cortes */), I18n.t('BEE643' /* Erro desconhecido */), 'top-right');
    }
  };

  render() {
    const {
      cutsMonitoDice = [], printerSelected, printerOptions, userOptions, userSelected, pages,
    } = this.props;
    const {
      defaultSorted, defaultPageSize, pageSizeOptions, selectedCableCut,
      showPrinterDialog, showUserDialog, showNoticeExecution, totalDataLength,
    } = this.state;

    return (
      <>
        <Table
          panelProps={{
            className: 'm-b-0',
          }}
          panelHeaderProps={{
            onClickReload: this.cutsMonitoList,
            children: I18n.t('BEE1420' /* Corte de Cabos */).toUpperCase(),
          }}
          filterable
          expander
          data={cutsMonitoDice}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          defaultPageSize={defaultPageSize}
          pageSizeOptions={pageSizeOptions}
          defaultSorted={defaultSorted}
          manual
          pages={pages}
          onFetchData={(state, instance) => {
            this.page = state.page;
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;
            this.cutsMonitoList();
          }}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  this.selecionado(rowInfo.original);
                },
                style: {
                  background: selectedCableCut && rowInfo.original.id === selectedCableCut.id ? 'silver' : 'white',
                  cursor: 'pointer',
                },
              };
            }
            return {};
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="text-left">
            <OneClickButton
              type="button"
              className="btn btn-140 btn-primary p-5 m-5"
              onClick={() => this.showPrinterDialog()}
            >
              {I18n.t('BEE1819' /* imprimir etiquetas */)}
            </OneClickButton>
            <OneClickButton
              type="button"
              className="btn btn-140 btn-primary p-5 m-5"
              onClick={() => this.validationDeleteCableCut()}
            >
              {I18n.t('BEE1820' /* Eliminar Corte */)}
            </OneClickButton>
            { // <button
              // type="button"
              // className="btn btn-140 btn-primary p-5 m-5"
              // onClick={() => this.finishCableCut()}
              // >
              // {I18n.t('BEE1821' /* Finalizar Corte */)}
              // </button>
            }
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <button
              type="button"
              className="btn btn-140 btn-primary p-5 m-5"
              onClick={() => this.showUserDialog()}
            >
              {I18n.t('BEE1822' /* Alterar usuários */)}
            </button>
          </div>
        </div>
        {(showPrinterDialog
          && (
            <SweetAlert
              confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1324' /* Selecionar Impressora */)}
              onConfirm={() => this.printLabel()}
              onCancel={() => this.setState({ showPrinterDialog: false })}
            >
              <div className="mt-4">
                {this.createSelect(printerSelected, 'printerSelected', I18n.t('BEE328' /* Impressora */), printerOptions)}
              </div>
            </SweetAlert>
          )
        )}
        {(showUserDialog
          && (
            <SweetAlert
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="danger"
              title={I18n.t('BEE887' /* Atualizar usuário */)}
              onConfirm={() => this.userChangeCutResponsible()}
              onCancel={() => this.setState({ showUserDialog: false })}
            >
              <div className="mt-4">
                {this.createSelect(userSelected, 'userSelected', I18n.t('BEE25' /* Usuários */), userOptions)}
              </div>
            </SweetAlert>
          )
        )}
        {(showNoticeExecution
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1849' /* continuar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1820' /* Eliminar Corte */)}
              onConfirm={() => this.deleteCableCut()}
              onCancel={() => this.setState({ showNoticeExecution: false })}
            >
              {I18n.t('BEE1858' /* Esta ação irá excluir o corte! não sera possível desfazer esta ação! Deseja continuar? */)}
            </SweetAlert>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cutsMonitoDice: state.cutsMonito && state.cutsMonito.cutsMonitoList,
});

const mapDispatchToProps = (dispatch) => ({
  printCableCutLabel: (printerCode, type, code) => dispatch(printCableCutLabel(printerCode, type, code)),
  deleteCableCut: (cableCutPlanId, cableCutProductId) => dispatch(deleteCableCut(cableCutPlanId, cableCutProductId)),
  finishCableCut: (cableCutPlanId, cableCutProductId) => dispatch(finishCableCut(cableCutPlanId, cableCutProductId)),
  getCableCutProductList: (cableCutPlanId) => dispatch(getCableCutProductList(cableCutPlanId)),
  setChangeCutProductList: (cableCutproductList) => dispatch(setChangeCutProductList(cableCutproductList)),
  userChangeCutResponsible: (cableCutPlanId, responsibleCutUserId) => dispatch(userChangeCutResponsible(cableCutPlanId, responsibleCutUserId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CableCutsTable));
