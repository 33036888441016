/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { ListGroup, ListGroupItem } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PageSettings } from '../../config/page-settings';
import ROUTES from '../../config/routes';
import NotificationLeftSide from './notification-lefside';

class NotificationInbox extends React.Component {
  static contextType = PageSettings;

  componentDidMount() {
    this.context.handleSetPageContentFullHeight(true);
    this.context.handleSetPageContentFullWidth(true);
  }

  componentWillUnmount() {
    this.context.handleSetPageContentFullHeight(false);
    this.context.handleSetPageContentFullWidth(false);
  }

  onClickNotification = (item, notifType = null) => () => {
    this.props.history.push({
      pathname: ROUTES.NOTIFICATION_DETAIL,
      state: {
        item,
        notifType,
      },
    });
  };

  renderListGroupItem = (itemList = [], notifType = null) => itemList.map((item) => (
    <ListGroupItem key={item.id} className={item.readed ? '' : 'unread'}>
      <a onClick={this.onClickNotification(item, notifType)} className="email-user bg-gre cursorPointer">
        <i className={`${item.icon} text-white`} />
      </a>
      <div className="email-info">
        <a onClick={this.onClickNotification(item, notifType)} className="cursorPointer">
          <span className={`${item.readed ? '' : 'font-weight-bold'} email-sender`}>{item.firstTitle}</span>
          <span className={`${item.readed ? '' : 'font-weight-bold'} email-title`}>{item.secondTitle}</span>
          <span className={`${item.readed ? '' : 'font-weight-bold'} email-desc`}>{item.description}</span>
          <span className={`${item.readed ? '' : 'font-weight-bold'} email-time`}>{item.date}</span>
        </a>
      </div>
    </ListGroupItem>
  ));

  render() {
    const { state = {} } = this.props.location;
    const { notifType = null } = state;

    return (
      <div className="vertical-box with-grid inbox bg-light">
        <NotificationLeftSide notifType={notifType} />
        <div className="vertical-box-column">
          <div className="vertical-box">
            <div className="wrapper">
              <div className="btn-toolbar align-items-center">
                <div className="btn-group ml-auto" style={{ visibility: 'hidden' }}>
                  <button type="button" className="btn btn-white btn-sm">
                    <i className="fa fa-chevron-left" />
                  </button>
                  <button type="button" className="btn btn-white btn-sm">
                    <i className="fa fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
            <div className="vertical-box-row bg-white">
              <div className="vertical-box-cell">
                <div className="vertical-box-inner-cell">
                  <PerfectScrollbar className="height-full" options={{ suppressScrollX: true }}>
                    <ListGroup className="list-group-lg no-radius list-email">
                      {
                        this.renderListGroupItem((notifType
                          ? this.props.notifications.filter((notif) => notif.type === notifType)
                          : this.props.notifications), notifType)
                      }
                    </ListGroup>
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
            <div className="wrapper clearfix">
              <div
                className="m-t-5 text-inverse f-w-600"
              >
                {`${this.props.notifications.length} ${I18n.t('BEE5' /* Notificações */).toLowerCase()}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.list || [],
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationInbox));
